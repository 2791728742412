import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getValueFromKey } from "../../../../../../common/utils/utils";
import FormWrapper from "../../../../../ui/formComponent/form";
import { validateSLARules } from "../lanes/lane-utils";
import { getSLADetails, postLaneSLA} from "../lanes/lane.services";
import placementSlaSchema from "./placement.sla.schema";

export default function PlacementSLA (props) {

    var formRef = useRef();
    const [ formData, SetFormData ] = useState({
        shareOfBusiness: {
            businessCycle   : "MONTHLY",
            basis           : props.contractDetails.serviceType === "PTL" ? "KG" : "INDENT"
        },
        slaRuleBOs : [{}]
    });

    useEffect(() => {
        if (props.contractId && props.laneMappingID) {
            getSLADetails(props.contractId, props.laneMappingID).then(slaBO => {
                if(slaBO.slaType === "PLACEMENT") {
                    SetFormData({
                        id              : slaBO.id,
                        slaRuleBOs      : slaBO.slaRuleBOs,
                        shareOfBusiness : { 
                            businessCycle : slaBO.businessCycle,
                            basis         : slaBO.basis,
                            shareValue    : parseInt(slaBO.shareValue)
                        },
                        penalties       : {
                            nonPlacementPenalty : slaBO.nonPlacementPenalty,
                            delayedPenalty      : slaBO.delayedPenalty,
                         }
                    });
                }
            })
        }
    }, [ props.laneMappingID, props.contractId ])

    const validate = (data, errorsObject) => {
        const validationObjects = validateSLARules(data.slaRuleBOs);
        if (validationObjects?.length === 0) {
			return errorsObject;
		}
		else {
			validationObjects.map(validator => {
				if (validator[0] && validator[1]) {
					var propertyLabel = validator[1];
					var errorKeyObj = getValueFromKey(errorsObject, propertyLabel);
					if (errorKeyObj) {
						errorKeyObj.addError(validator[0]);
					}
				}
			})
			return errorsObject;
		}
    }

    const transformErrors = errorObj => {
        errorObj.map(e => {
            if (e.name.indexOf("placementSlaDay") > -1 && e.name == "type") {
                e.message = "Placement SLA Days has to be a whole number!"
            }
        })
        return errorObj;
    }
    const savePlacementSLA = data => {
        SetFormData(data);
        props.generateLaneMappingID(laneMappingBO => {
            if (!laneMappingBO) return;
            var laneEntity = laneMappingBO.find(l => l.index === props.index);
            const postObj = {
                laneMappingBO    : {
                    id           : laneEntity.id,
                    contractId   : props.contractId,
                    entityBO     : {
                        slaBO    : {
                            id          : data.id,
                            slaType     : "PLACEMENT",
                            slaRuleBOs  : [ ...data.slaRuleBOs ],
                            ...data.shareOfBusiness,
                            ...data.penalties,
                        }

                    },
                }
            }
            postLaneSLA(postObj)
            .then(_ => {
                props.onCancel();
                props.onLaneDataSaveSuccess("Placement SLA")
            })
            .catch(_ => {})
        })
    }

    const onChange = data => {
        if (data.slaRuleBOs.length <= 0) {
            data.slaRuleBOs = [{}];
        }
        SetFormData(data);
    }

    return (
        <div>
                <FormWrapper
                    {...placementSlaSchema}
                    formData={formData}
                    onSubmit={savePlacementSLA}
                    validate={validate}
                    transformErrors={transformErrors}
                    readonly={props.readonly}
                    fieldsPerRow={3}
                    onChange={onChange}
                    showColumnSplitter={false}
                    loading={props.loading}
                    hideSubmit={true}
                    bordered={true}
                    ref={formRef}
                />
                {
                    !props.readonly &&
                    <div className="flex-box flex-gap-m align-items-center justify-content-end popup-buttons-divider contract-management">
                        <Button className="secondary-btn" onClick={() => {
                            Modal.confirm({
                                title   : "Placement SLA",
                                content : "Are you sure to cancel?",
                                onOk    : _ => {
                                    Modal.destroyAll();
                                    props.onCancel();
                                }
                            })
                        }}> Cancel </Button>
                        <Button className="lsn-btn-primary" onClick={() => {
                            formRef.current.formReference?.current?.submit()
                        }}> Save </Button>
                    </div>
                }
        </div>
    )
}