import React from 'react'
import moment from 'moment'
import { Tag, Tooltip, Icon, Button } from 'antd';
import "../common.scss";
import { Mixpanel } from '../../../../../utils/mixpanel';

const formatDate = date => (moment(Number(date)).format('DD/MM/YYYY HH:mm:ss'));

export function getShippersColumn(shipperEvents) {
    return [
        {
            title: 'Shipper Code',
            dataIndex: 'contactShortCode',
            key: 'contactShortCode',
            render: (data, row) => <a type="link" className="visited-link" onClick={() => shipperEvents.openModal(row)}>{data ? data : <Icon type="eye" />}</a>
        },
        {
            title: 'Shipper Name',
            dataIndex: 'shipperName',
            key: 'shipperName'
        },

        {
            title: 'Contact Info',
            dataIndex: 'contactInfo',
            key: 'contactInfo',
            render: (data, row) => (<div><b>{row.contactPersonName}</b><br />{row.contactNumber}</div>)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: data => data === "ACTIVE" ?
                <Tag className="ant-table-tag success-tag text-align-center"> Active   </Tag> :
                <Tag className="ant-table-tag warning-tag text-align-center"> Inactive </Tag>,
            // sorter: (a, b) => a.isActive - b.isActive,
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: formatDate
        },
        // {
        //     title: 'Action',
        //     dataIndex: '',
        //     key: 'action',
        //     render: (data, row) => (

        //         <div className="flex-box align-items-center flex-gap-l">
        //             <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
        //                 <Tooltip placement="top" title={row?.status === "ACTIVE" ? "Edit" : undefined}>
        //                     {
        //                         row?.status === "ACTIVE" && shipperEvents.shipperPermission.shipperUpdate ?
        //                             <Icon type="edit" onClick={() => {
        //                                 Mixpanel.track("Clicking Edit button in Shippers Tab", { 'CustomerContractShippersViewClicked': moment().valueOf() });
        //                                 shipperEvents.setReadOnly(false);
        //                                 shipperEvents.openModal(row)
        //                             }} /> :
        //                             <Icon type="edit" style={{ cursor: "not-allowed", opacity: 0.5 }} />
        //                     }
        //                 </Tooltip>
        //             </div>
        //             <div>
        //                 {/* <Button className="lsn-btn-primary" onClick={() => {
        //                     Mixpanel.track("Clicking View button in Shippers Tab", { 'CustomerContractShippersViewClicked': moment().valueOf() });
        //                     shipperEvents.setReadOnly(true);
        //                     shipperEvents.openModal(row);
        //                 }}>
        //                     View
        //                 </Button> */}
        //             </div>
        //         </div>
        //     )
        // },
    ]
}

export function getZonesColumn(zoneEvents) {
    return [
        {
            title: 'Zone Code',
            dataIndex: 'code',
            key: 'code',
            render: (data, row) => <a type="link" className="visited-link" onClick={() => {
                Mixpanel.track("Clicking View button in Zones Tab", { 'CustomerContractZonesViewClicked': moment().valueOf() });
                zoneEvents.showZoneDetails(row);
                zoneEvents.setModal(true);
            }}>{data ? data : <Icon type="eye" />}</a>
        },
        {
            title: 'Zone Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Pincodes',
            dataIndex: 'pincodeCount',
            key: 'pincodeCount'
        },
        {
            title: 'Cities',
            dataIndex: 'cityCount',
            key: 'cityCount'
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: data => data ?
                <Tag className="ant-table-tag success-tag text-align-center"> Active   </Tag> :
                <Tag className="ant-table-tag warning-tag text-align-center"> Inactive </Tag>
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 225,
            render: formatDate
        },
        // {
        //     title: 'Action',
        //     dataIndex: '',
        //     key: 'action',
        //     width: 150,
        //     render: (data, row) =>
        //         <div className="flex-box align-items-center flex-gap-l">
        //             <div className="spacer-xs flex-box dark-grey-bg border-radius-s display-none">
        //                 <Tooltip placement="top" title={row?.status === "ACTIVE" ? "Edit" : undefined}>
        //                     {
        //                         row?.status === "ACTIVE" ?
        //                             <Icon type="edit" /> :
        //                             <Icon type="edit" style={{ cursor: "not-allowed", opacity: 0.5 }} />
        //                     }
        //                 </Tooltip>
        //             </div>
        //             <div>
        //                 <Button className="lsn-btn-primary" onClick={() => {
        //                     Mixpanel.track("Clicking View button in Zones Tab", { 'CustomerContractZonesViewClicked': moment().valueOf() });
        //                     zoneEvents.showZoneDetails(data, row);
        //                     zoneEvents.setModal(true);
        //                 }}>
        //                     View
        //                 </Button>
        //             </div>
        //         </div>
        // },
    ]
}

export function getLocationColumns(locationEvents) {
    return [
        {
            title: 'Location Code',
            dataIndex: 'shortCode',
            key: 'shortCode',
            render: (data, row) => <a type="link" className="visited-link" onClick={() => {
                locationEvents.showLocationDetails(row);
                locationEvents.setModal(true);
            }}>{data ? data : <Icon type="eye" />}</a>
        },
        {
            title: 'Location Name',
            dataIndex: 'name',
            key: 'name'
        },
        // {
        //     title: 'Contact Info',
        //     dataIndex: 'contactInfo',
        //     key: 'contactInfo',
        //     render: (data, row) => (<div><b>{row.contactPersonName}</b><br />{row.contactNumber}</div>)
        // },     
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: data => data ?
                <Tag className="ant-table-tag success-tag text-align-center"> Active   </Tag> :
                <Tag className="ant-table-tag warning-tag text-align-center"> Inactive </Tag>,
            // sorter: (a, b) => a.isActive - b.isActive,
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: formatDate
        },
        // {
        //     title: 'Action',
        //     dataIndex: '',
        //     key: 'action',
        //     render: (data, row) =>
        //         <div className="flex-box align-items-center flex-gap-l">
        //             <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
        //                 <Tooltip placement="top" title={row?.status ? "Edit" : undefined}>
        //                     {
        //                         (row?.status && locationEvents.locationPermission.locationsUpdate) ?
        //                             <Icon type="edit" onClick={() => {
        //                                 locationEvents.showLocationDetails(data, row);
        //                                 locationEvents.setReadOnly(false);
        //                                 locationEvents.setModal(true);
        //                             }} /> :
        //                             <Icon type="edit" style={{ cursor: "not-allowed", opacity: 0.5 }} />
        //                     }
        //                 </Tooltip>
        //             </div>
        //             <div>
        //                 {/* <Button className="lsn-btn-primary" onClick={() => {
        //                     Mixpanel.track("Clicking View button in Location Tab", { 'CustomerContractLocationViewClicked': moment().valueOf() });
        //                     locationEvents.showLocationDetails(data, row);
        //                     locationEvents.setModal(true);
        //                 }}>
        //                     View
        //                 </Button> */}
        //             </div>
        //         </div>
        // },
    ]
}