import * as apiUtils from '../../../../../common/utils/apiGateway';
import { checkHttpStatus, notifyApiError } from '../../../../../common/utils/utils';

export const createEditContractHeader = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: `/b2b/v1/contracts/create`,
        method: "POST",
        data: {
            contractV1BO : data
        }
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    })
})

export const postContractInfoSave = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url     : "/b2b/v1/contracts/info/save",
        method  : "POST",
        data    : data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    }).catch(e => reject(e?.data?.status?.message))
})

export const copyContract = data => new Promise((resolve, reject) => {
    if (typeof data != "object") { 
        data = { contractId : data }
    }
    apiUtils.apiRequest({
        url     : "/b2b/v1/contracts/copy",
        method  : "POST",
        data    : data
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    }).catch(e => reject(e))
})

export const sendContractForApproval = contractId => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url     : "/b2b/v1/contracts/submit",
        method  : "POST",
        data    : { contractId : contractId },
        showMessage  : false,
        ignoreStatus : true
    })
    .then(response => {
        if (response?.data?.response?.sync === false) {
            if (response?.data?.response?.dataValidations?.length > 0) {
                var dataValidations = {};
                response?.data?.response?.dataValidations.map(d => {
                    dataValidations[d.dataSection] = d.errorMessages;
                })
                resolve({
                    submitSuccess   : false,
                    dataValidations : dataValidations
                });
            }
            else {
                resolve({ submitSuccess : true });
            }
        }
        else {
            resolve({ submitSuccess : true });
        }
    }).catch(e => {
        notifyApiError(e);
        reject({ submitSuccess : false })
    })
})

export const postDiscardContract = contractId => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url     : "/b2b/v1/contracts/discard",
        method  : "POST",
        data    : { contractBO : { id : contractId } },
        showMessage : false
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    }).catch(e => {
        notifyApiError(e);
        reject(e);
    })
})
