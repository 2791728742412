import React from 'react';
import { card as Card } from '../card/Card';
import './Sparkline.scss';
import { Tooltip, Icon, Avatar, Col, Row } from 'antd';

const Sparkline = props => {
    let classnames = ['sparkline'];
    if (props.className) {
        classnames = classnames.concat(props.className.split(' '));
    }
    return (
        <div className={classnames.join(' ')}>
            <div className="float-left">
                <p className="title">{props.title}</p>
            </div>
            {
                props.infoText ?
                    <div className="title float-right">
                        <Tooltip title={props.infoText}>
                            <span ><Icon type="info-circle" /></span>
                        </Tooltip>
                    </div> : null
            }
            <div className="highlight">{props.highlightText}</div>
        </div>
    )
}
export default Sparkline;