import React, { PureComponent } from 'react';
import * as actions from './actions';
import './dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Modal, Select, notification } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect} from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { Constants } from '../../../common/utils/constants';

const { confirm } = Modal;

class DrsDraft extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      visible: false,
      runsheetUserId: null,
      draftId: null,
      draftCode: null,
      draftPermission: {
        read: canAllow(permissionTags.inventory.drsdraft.read),
        update: canAllow(permissionTags.inventory.drsdraft.update),
        create: canAllow(permissionTags.inventory.drsdraft.create),
        delete: canAllow(permissionTags.inventory.drsdraft.delete),
      },
      filters: {
        pageNo: 1,
        pageSize: 10,
      }
    }
    this.InputSearchRef = React.createRef();
    this.selectFeName = React.createRef();
    if (!this.state.draftPermission.read) {
      windowRedirect('leads/dashboard/drsdraft');
    }
  }

  componentDidMount() {
    this.props.getFeDetails();
    this.setState(state => ({
      columns: [
        {
          title: 'Draft Name',
          dataIndex: 'name',
          width: 150,
          key: 'name',
        },
        {
          title: 'AWB Count',
          dataIndex: 'consignmentsCount',
          width: 200,
          key: 'consignmentsCount'
        },
        {
          title: 'Total Weight',
          dataIndex: 'weight',
          key: 'weight',
        },
        {
          title: 'Total Distance',
          dataIndex: 'distance',
          key: 'distance',
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: data => (<div>{(data.toLowerCase()).replace(/\b\w/g, function(l){ return l.toUpperCase() })}</div>)
        },
        {
          title: 'Action',
          dataIndex: 'status',
          key: 'action',
          render: (data, row) => (<Row>
            {
              <Col span={12}><Button type="primary" disabled={row.status === 'CONVERTED' ? true : false} onClick={() => this.showModal(row.id, row.code)}>Assign</Button></Col>
            }
          </Row >)
        },
      ]
    }));
    if (!this.props ?.runsheetData){
      this.getPaginatedData(this.state.pageNo);
    }
  }

  showModal = (id, name) => {
    this.setState({
      visible : true,
      draftId : id,
      draftCode : name,
      runsheetUserId: undefined,
    });
  };

  handleOk = e => {
    const { draftId, runsheetUserId, draftCode} = this.state;
    if(draftId && runsheetUserId){
      let payload = {
                  "runsheetDraftBO": {
                    "id": draftId,
                    "code": draftCode,
                    "runsheetType": "DRS",
                  },
                  "runsheetUserId": runsheetUserId,
                  "serviceMode": "SYNC"
      }
      console.log(payload);
    this.props.assignRunsheet(payload).then(res => {
      console.log(res);
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
        notification['success']({
          message: 'SUCCESS',
          description: res ?.data ?.status ?.message,
          placement: 'bottomRight',
        });
      } else {
        notification['error']({
          message: 'ERROR',
          description: res ?.data ?.status ?.message,
          placement: 'bottomRight',
        });
      }
    });
    this.setState({
      visible: false,
      runsheetUserId: null,
    });
    }
  };

  handleCancel = e => {
    this.setState({
      visible: false,
      runsheetUserId: null,
    });
  };

  componentDidUpdate(prevProps) {
    const { PaginatedrunSheets, runsheetData } = this.props;
    if (PaginatedrunSheets !== prevProps.PaginatedrunSheets) {
      this.setState({
        PaginatedrunSheets: PaginatedrunSheets[runsheetData.currentPageNo],
      })
    }
  }

  componentWillMount(){
    this.props.fetchRunsheetDraft({ ...this.state.filters }, true);
  };

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    this.props.fetchRunsheetDraft( { ...this.state.filters, ...filters }, isReset);
  }

  applyFilter = (filters, isReset = false) => {
    if (!filters.name) {
      this.applyPagination(filters.name, true);
      return;
    }
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    this.props.fetchuserByName( { ...this.state.filters, ...filters }, isReset);
    this.setState({name : filters.name})
  }



  toTitleCase = (str) =>{
      return str.replace(
          /\w\S*/g,
          function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
  }

  reloadLocationList = () => {
    this.setState({
      pageNo: 1,
      selectedValues : {}
    })
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedrunSheets} = this.props;
    if (!PaginatedrunSheets ? !PaginatedrunSheets : !PaginatedrunSheets[pageNo]) {
      let filter = {
        pageNo: pageNo
      };

      if(this.state ?.name){
        filter['name']= this.state ?.name;
      }
      this.applyPagination(filter);
    }
    this.setState({
      pageNo
    });
  }

  onLocationChange = (e) => {
    this.setState({runsheetUserId : e})
  }



  render() {
    const { columns, draftPermission, pageNo, runsheetUserId } = this.state;
    const { PaginatedrunSheets, runsheetData } = this.props;
    // if(PaginatedrunSheets){
    //   console.log(PaginatedrunSheets);
    // }
    if(runsheetUserId){
      console.log(runsheetUserId);
     }
    const actionBtns = (
      <Row>
        <Col span={draftPermission.create ? 24 : 19}>
          <Input.Search size={'large'} placeholder="Search" allowClear ref={this.InputSearchRef} onSearch={value => this.applyFilter({ name: value.trim() }, true)} enterButton />
        </Col>
        {/* <Col span={3} style={{ marginLeft: 1 }}>
          <Tooltip placement="top" title={'Reload Draft List'}>
            <Button block size={'large'} onClick={this.reloadLocationList} type="primary">
              <Icon type="reload" />
            </Button>
          </Tooltip>
        </Col>
        {
          draftPermission.create && <Col span={8} style={{ marginLeft: 2 }}>
            <Button block size={'large'} onClick={() => this.props.history.push(`/appv2/inventory/form`)} type="primary">CREATE DRAFT</Button>
          </Col>
        } */}
      </Row>
    );
    return (
      <>
        <Card className="CustomerDashBoard filterbox" title={<h2>Draft</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            loading={runsheetData ?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: runsheetData ?.totalCount,
              position: 'bottom',
              current: pageNo,
              pageSize: runsheetData ?.currentPageSize
          }}
            dataSource={PaginatedrunSheets ? PaginatedrunSheets[this.state.pageNo] : []}
            columns={columns} />
            <Row>
            <Modal
                    title="FE SELECTION POP-UP"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                      <Button key="back" type="danger" onClick={this.handleCancel}>
                        Cancel
                      </Button>,
                      <Button key="submit" type="primary" onClick={this.handleOk}>
                        Confirm
                      </Button>,
                    ]}
                    >
                      <Row>
                          <Col className="gutter-row" span={24}>
                              <Col span={8}>Choose the FE</Col>
                              <Col span={16}>
                                <Select value={this.state.runsheetUserId}  placeholder="Choose the FE" style={{ width: "100%" }} showSearch filterOption={(input, option) =>
                                  option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  } showArrow onChange={(e) => this.onLocationChange(e)}>
                                  {this.props ?.userData ?.map(loc => (
                                    <Select.Option
                                      key={loc.id}
                                      value={loc.id}
                                      label={loc.name}
                                    >
                                      {loc.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                              <Col span={24} style={{textAlign: "center", marginTop: 10}}>To assign FE to draft press confirm</Col>
                          </Col>
                      </Row>
                    </Modal>
            </Row>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  runsheetData: state.drsDraft.runsheetDraftBOs,
  PaginatedrunSheets: state.drsDraft ?.PaginatedrunSheets,
  userData: state.drsDraft ?.users
});

const mapDispatchToProps = dispatch => ({
  fetchRunsheetDraft: (param, isReset = false) => dispatch(actions.fetchRunsheetDraft(param, isReset)),
  deleteDraft: (id, payload) => dispatch(actions.deleteDraft(id, payload)),
  getFeDetails: () => dispatch(actions.getFeDetails()),
  assignRunsheet: (payload) => dispatch(actions.assignRunsheet(payload)),
  fetchuserByName: (param, isReset = false) => dispatch(actions.fetchuserByName(param, isReset)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DrsDraft);