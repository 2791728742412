import { initialState, FETCH_PRINT_RUNSHEET_BEGIN, FETCH_PRINT_RUNSHEET_FAILURE, FETCH_PRINT_RUNSHEET_SUCCESS } from "./constants";


export const PrintReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRINT_RUNSHEET_BEGIN:
            return {
                ...state,
                loading: true
            };
        case FETCH_PRINT_RUNSHEET_SUCCESS:
            return {
                ...state,
                loading: false,
                pickupRunSheets: action.payload.pickupRunSheets
            };
        case FETCH_PRINT_RUNSHEET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                pickupRunSheets: []
            };
        default:
            return state;
    }
}