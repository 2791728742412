import { Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import debounce from 'lodash/debounce';
import { fetchZones } from "../../../../../services/common/fetch.services";

export default function ZonesSelect(props) {
    
    const [ loading, SetLoading ] = useState(false);
    const [ zoneList, SetZoneList ] = useState([]);
    const [ selectedZone, SetSelectedZone ] = useState(props.selectedZone);
    const [ notFoundContent, SetNotFoundContent ] = useState("Enter minimum of three characters to search zones.");

    useEffect(() => {
        SetSelectedZone(props.selectedZone);
    }, [ props.selectedZone, props.index ]);

    var params = {
        pageNo : 1,
        pageSize : 10,
        isActive : true,
        zoneTypes : props.zoneTypes,
        entityIds : [ props.customerId ],
        searchNameOnly : props.searchNameOnly
    }

    const fetchData = async value => {
        SetLoading(true);
        const zoneListTemp = [];
        if (value.trim() !== "") {
            params["partialContentSearch"] = value.trim();
            const zonesResponse  = await fetchZones(params); 
            if (zonesResponse && zonesResponse.zones?.length > 0) {
                zonesResponse.zones.forEach(element => {
                    zoneListTemp.push({
                        key     : element.name,
                        value   : element.id,
                        label   : element.name
                    })
                });
            }
            if (zoneListTemp.length === 0) {
                SetNotFoundContent("No zones Found");
            }
        }
        else {
            SetNotFoundContent("Enter minimum of three characters to search zones.");
        }
        SetZoneList(zoneListTemp);
        SetLoading(false);
    }

    const onZoneSelect = zone => {
        SetSelectedZone(zone);
        SetZoneList([]);
        props.onZoneSelect({
            ...zone,
            label : zone.label?.props?.children
        });
    };

    return (
        <div className="flex-column justify-content-space-between flex-3">
            <Select
                disabled={props.readonly} labelInValue={true}
                className={ props.readonly ? "read-only" : "" }
                mode={'single'} style={{ marginLeft: -8 }}
                value={selectedZone?.id ? {
                    key     : selectedZone?.id,
                    label   : selectedZone?.name
                } : undefined} showSearch={true}
                onSearch={debounce(fetchData, 800)} loading={loading}
                notFoundContent={notFoundContent}
                onBlur={() => SetZoneList([])}
                placeholder={"Select Zone"} onSelect={onZoneSelect}
                filterOption={(input, option) => option.props.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 } >
                {
                    zoneList?.map(zone => (
                        <Select.Option key={zone.key ? zone.key : zone.value} value={zone.value} disabled={props.selectedEntities.indexOf(zone.value) > -1}>
                            <Tooltip title={props.selectedEntities.indexOf(zone.value) > -1 ? "Zone already present in lane!" : undefined}>
                                {zone.label}
                            </Tooltip>
                        </Select.Option>
                    ))
                }
            </Select>
            {
                props.unselectedError &&
                <div className="error-text"> Cannot have unselected zone in lane transit!  </div>
            }
        </div>
    )
}