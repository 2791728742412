import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Ingest from './ingest/Ingest';
import FormBuilder from '../shared-components/import-export-builder/FormBuilder';
import { Constants } from '../../common/utils/constants';
import { checkForDownloadReportStatus, windowRedirect, canAllow } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import {
    Modal, Button, Icon, Checkbox,
    Input, Tabs, Row, Col, Card, Table, Empty, Radio
} from 'antd';
import * as _ from 'lodash';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { SoundNotificationService } from '../../services/sound.service';
import * as actions from '../reports/ingest/action';
import * as appActions from '../../app/appAction';
import './reports.scss';
const soundNotificationService = new SoundNotificationService;


const { TabPane } = Tabs;

class CustomHeader extends Component {
    render() {
        return (
            <div className="custom_header">
                {this.props.children}
            </div>
        )
    }
}


class Reports extends Component {

    state = {
        visible: false,
        data: {},
        sendEmail: true,
        email: Constants.sessionUser.email,
        previewEnabled: false,
        location: 'FE_WISE',
        params: {},
        customerList: [],
        employeeList: [],
        disableAction: false,
        loading: false,
        download: canAllow(permissionTags.reports.new_reports.read)
    };

    constructor(props) {
        super(props);
        if (!this.state.download) {
            windowRedirect('reports/dashboard')
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    changeDisable(value) {
        this.setState({ disableAction: value });
    }

    handleOk = e => {
        if (this.state.data.action.id === 'greport') {
            // console.log("data->>",this.state.data)
            this.props.getCustomApiCall(this.state.data.action.endPoint.url, this.state.data.action.endPoint.method, { ...this.state.data.val });
            this.setState({
                visible: false,
            }, () => {
                this.props.history.goBack();
            });

        }
        this.setState({
            visible: false,
            sendEmail: true,
            email: Constants.sessionUser.email,
        });
    };

    handleCancel = e => {
        this.changeDisable(false);
        this.setState({
            visible: false,
        });
    };



    componentDidMount() {
        this.props.getCustomerList();
        this.props.getEmplyeesList();
    }

    componentDidUpdate(oldProps) {
        // console.log("aws->>", (this.props.downloadStatus === "SUCCESS" || this.props.downloadStatus === "FAILURE") && this.state.loading)     
        // Note: Commenting to move back to Listing start here ->   
        // if ((this.props.downloadStatus === "SUCCESS" || this.props.downloadStatus === "FAILURE" || (this.props.customerError && this.props.customerError?.status?.code == 500)) && (this.state.loading || this.state.disableAction)) {
        //     this.loadingForm(false);
        //     this.changeDisable(false);
        //     if (this.state.data.action.id === 'greport') {
        //         if (this.props.downloadStatus === "SUCCESS") {
        //             soundNotificationService.playSuccess();
        //         }
        //         if (this.props.downloadStatus === "FAILURE") {
        //             soundNotificationService.playWarning();
        //         }
        //     }

        //     this.props.setS3SyncStatusReset();

        // }
        // Note: Commenting to move back to Listing start here ->

        if (this.props.customerList && this.props.customerList.length > 0 && this.state.customerList.length <= 0) {
            let customers = this.props.customerList;
            let index = customers.findIndex(x => x.id == "101")
            if (index === -1) {
                customers.unshift({ name: "All", id: "101" });
                this.setState({ customerList: customers });
            }

        }

        if (this.props.employeeList && this.props.employeeList.length > 0 && this.state.employeeList.length <= 0) {
            let employee = this.props.employeeList;
            let index = employee.findIndex(x => x.id == "101")
            if (index === -1) {
                employee.unshift({ name: "All", id: "101" });
                this.setState({ employeeList: employee })
            }

        }

        // Note: Commenting to move back to Listing start here ->
        // if (this.props.customApiResp && oldProps.customApiResp !== this.props.customApiResp) {
        //     console.log("this.state->>", this.state.data)
        //     if (this.state.data.action.id === 'greport') {
        //         this.loadingForm(true);
        //         if (_.isUndefined(this.props?.customApiResp?.response?.url)) {
        //             this.loadingForm(false);
        //             return;
        //         }
        //         const reportData = {
        //             's3Url': this.props?.customApiResp?.response?.url,
        //             'status': true
        //         };

        //         localStorage.setItem(
        //             "downloadReportInitiated",
        //             JSON.stringify(reportData)
        //         );
        //         const prop = {
        //             setS3SyncStatus: this.props.setS3SyncStatus,
        //             uploadedFileResp: {
        //                 responseUrl: this.props.customApiResp?.response?.url
        //             }
        //         }
        //         checkForDownloadReportStatus(prop);
        //     }

        //     if (this.state.data.action.id === "preview") {
        //         this.loadingForm(false);
        //         this.changeDisable(false);
        //     }
        // }
        // Note: Commenting to move back to Listing end here ->

    }

    submitForm = (payload, values, action) => {

        const previewMapping = {
            'DRS_REPORT': 'DRS_SUMMARY',
            'LAST_STATUS_REPORT': 'CONSIGNMENT_SUMMARY',
            'CRITICAL_SHIPMENTS': 'CRITICAL_CONSIGNMENT_SUMMARY',
            'RTO_DRS_REPORT': 'RTO_DRS_SUMMARY',
            'COD_COLLECTABLE_REPORT': 'CASH_ON_DELIVERY_SUMMARY',
            'BOOKING_REPORT': 'CONSIGNMENT_SUMMARY',
            'CARD_ON_DELIVERY': 'CARD_ON_DELIVERY_SUMMARY',
            'CUSTOMER_DELIVERY_REPORT': 'CUSTOMER_DELIVERY_REPORT_SUMMARY',
            'THC_SUMMARY_REPORT': 'THC_SUMMARY_REPORT',
            'USER_PERFORMANCE_LITE': 'USER_PERFORMANCE_LITE',
            'COD_IN_PENDING_REPORT': 'COD_IN_PENDING_REPORT',
            'CONSIGNMENT_POD_BY_WAYBILL': 'CONSIGNMENT_POD_BY_WAYBILL',
            'CRITICAL_SHIPMENTS_REPORT_V2': 'CRITICAL_SHIPMENTS_REPORT_V2',
            'OVERAGE_SUMMARY_REPORT': 'OVERAGE_SUMMARY_REPORT',
            'PICKUP_PENDING_REPORT': 'PICKUP_PENDING_REPORT',
            'SHIPMENT_SCAN_REPORT': 'SHIPMENT_SCAN_REPORT',
            'RTO_ELIGIBLE_CONSIGNMENT_REPORT': 'RTO_ELIGIBLE_CONSIGNMENT_REPORT',
            'MANIFEST_REPORT': 'MANIFEST_REPORT',
            'TRIP_SUMMARY_REPORT': 'TRIP_SUMMARY_REPORT',
        };
        let key = { ...values };
        let val = { ...values };

        const shappingKeys = ['partnerIds', 'destinationLocationIds', 'customerIds', 'userIds'];
        if (values.radio !== 'waybill') {
            shappingKeys.map((v, k) => {
                if (val[v]) {
                    val[v] = val[v].map(i => Number(i['key']));
                    key[v] = key[v].map(i => i['label']);
                    if (v == 'destinationLocationIds' || v == 'customerIds' || v == 'userIds') {
                        if (val[v].indexOf(101) >= 0) {
                            val[v] = null;
                        }
                    }
                }

            });

            if (val['dateRange']) {
                val['dateRange'] = _.zipObject(
                    ['from', 'to'],
                    [moment(val['dateRange'][0]).startOf('day').valueOf(), moment(val['dateRange'][1]).endOf('day').valueOf()]
                );


                key['dateRange'] = [moment(key['dateRange'][0]), moment(key['dateRange'][1])];
            }



        }




        val['documentType'] = action.id === 'greport' ? val['report_type'] : previewMapping[val['report_type']];
        delete val['report_type'];
        delete val['radio'];
        val['loggedInUserTokken'] = true;
        val['drsType'] = Constants.SELECTED_APPLICATION;
        val['partnerId'] = Constants.PARTNER_ID;
        val['locationId'] = Constants.userLocationId;
        val['userId'] = Constants.sessionUser.id;
        if (action.id === 'preview') {
            val['summaryEnum'] = this.state.location;
            val['onlyData'] = true;
        } else {
            delete val['summaryEnum'];
            delete val['onlyData'];
        }

        if (values.radio == 'waybill') {
            val['fileFilters'] = {
                's3Path': val.s3FileLink,
                'filterValueType': (val['documentType'] === 'CONSIGNMENT_POD_BY_WAYBILL' || val['documentType'] === 'TRIP_SUMMARY_REPORT' || val['documentType'] === 'TRIP_SUMMARY_REPORT_V2') ? 'WAYBILL' : 'DRS_CODE'
            }
            key['filename'] = val.filename;
        } else if (values.radio == 'byTrip') {
            val['fileFilters'] = {
                's3Path': val.s3FileLink,
                'filterValueType': 'TRIPS'
            }
            key['filename'] = val.filename;
        }

        val['documentSubType'] = null;


        this.setState({
            data: {
                val: {
                    ...val,
                    ...payload
                },
                action,
                key
            }
        }, () => {

            if (this.state.data.action.id === 'greport') {
                this.setState({ previewEnabled: false });
                this.showModal();
            } else if (this.state.data.action.id === 'preview') {
                this.setState({ previewEnabled: true });
                this.props.getCustomApiCall(this.state.data.action.endPoint.url, this.state.data.action.endPoint.method, { ...this.state.data.val })
            }
        });

    }

    generateTableData = data => {
        data = JSON.parse(data);
        const fdelivery = data["summary"];

        let someData = [];
        let columns = [];
        let titles = [];
        let objTitles = [];
        let dt = {};
        let jindex = 0;
        if (fdelivery && fdelivery.length > 0) {
            for (let i = 0; i < fdelivery.length; i++) {
                Object.keys(fdelivery[i]).map((val, index) => {
                    // dt = {};
                    if (typeof fdelivery[i][val] === 'object') {
                        if (columns.length <= 0) {
                            objTitles.push(val);
                            columns.push({
                                title: val,
                                dataIndex: val,
                                children: Object.keys(fdelivery[i][val]).map((obj, inx) => {
                                    dt[val + '_' + obj] = fdelivery[i][val][obj];
                                    return {
                                        title: obj,
                                        key: obj + jindex++,
                                        dataIndex: val + '_' + obj,
                                        rowKey: val + jindex++,
                                        align: 'center'
                                    }
                                })

                            });

                        } else {
                            if (objTitles.indexOf(val) < 0) {
                                columns.push({
                                    title: val,
                                    dataIndex: val,
                                    children: Object.keys(fdelivery[i][val]).map((obj, inx) => {
                                        dt[val + '_' + obj] = fdelivery[i][val][obj];
                                        return {
                                            title: obj,
                                            key: obj + jindex++,
                                            dataIndex: val + '_' + obj,
                                            rowKey: val + jindex++,
                                            align: 'center'
                                        }
                                    })

                                });

                                objTitles.push(val);
                            }
                        }

                    } else {


                        dt[val] = fdelivery[i][val];

                        if (columns.length <= 0) {
                            titles.push(val);
                            columns.push({
                                title: val,
                                dataIndex: val,
                                key: val + jindex++,

                                index: val,
                                align: 'center'
                            })
                        } else {
                            if (titles.indexOf(val) < 0) {
                                columns.push({
                                    title: val,
                                    dataIndex: val,
                                    key: val + jindex++,

                                    index: val,
                                    align: 'center'
                                });
                                titles.push(val);
                            }

                        }


                    }
                })

                someData[i] = { ...dt };


            };
            return [columns, someData]

        }




    }


    onChange = e => {
        this.setState({ sendEmail: !e.target.checked });
        if (e.target.checked) {
            const values = { ...this.state.data.val };
            const key = { ...this.state.data.key };
            const action = { ...this.state.data.action };

            values['emailTo'] = this.state.email;

            this.setState({ data: { val: values, action, key } });
        }

    }

    loadingForm = (state) => {
        this.setState({ loading: state })
    }



    onEmailChange = e => {
        this.setState({ email: e.target.value });

        const values = { ...this.state.data.val };
        const key = { ...this.state.data.key };
        const action = { ...this.state.data.action };

        values['emailTo'] = e.target.value;

        this.setState({ data: { val: values, action, key } });

    }

    handleModeChange = e => {
        const location = e.target.value;
        const { data } = { ...this.state };
        const { val } = { ...data }
        val['summaryEnum'] = location;
        this.setState({ data })


        this.setState({ location });
        this.props.getCustomApiCall(this.state.data.action.endPoint.url, this.state.data.action.endPoint.method, { ...this.state.data.val })

    }


    render() {
        // const data ={
        //     "Forward delivery": {"summary":[{"DEL":0,"Expected":0,"UNDEL":{"RFR":0,"CNR":0,"Others":0,"Rejected":0,"Sub-total":0},"At location":{"RFR":0,"CNR":0,"Others":2,"Rejected":0,"Sub-total":2,"Unattempted":1},"OFD":0,"Total":2}]},
        //     "Return": {"summary":[{"RTO":{"OFD":0,"BAG":0,"At location":0,"OUT":0,"DEL":0,"Sub-total":0},"RTS":{"OFD":0,"BAG":0,"At location":0,"OUT":0,"DEL":0,"Sub-total":0},"Total":0}]},
        //     "Lost": {"summary":[{"Shipment Lost":0}]}};

        let tables = [];
        let result = null;
        if (this.state.previewEnabled) {


            if (this.props.customApiResp?.response?.data?.metaInfo && !_.isEmpty(this.props.customApiResp.response.data.metaInfo)) {
                Object.keys(this.props.customApiResp.response.data.metaInfo).map(d => {
                    result = this.generateTableData(this.props.customApiResp.response.data.metaInfo[d]);
                    if (result) {
                        tables.push(
                            <>
                                <h4>{d}</h4>
                                <Table
                                    tableLayout="auto"
                                    bordered={true}
                                    pagination={false}
                                    dataSource={result[1]}
                                    columns={result[0]}
                                    scroll={{ x: 100 }}
                                />
                            </>
                        )

                    } else {
                        tables = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                    }


                })

            } else {
                tables = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
            }
        } else {
            tables = <b>Choose a report and click Preview to see the Summary</b>
        }



        let params = {};
        if (!_.isEmpty(this.props.header)) {
            const { header } = this.props;

            params = {
                "userId": header?.loggedInUser?.id,
                "locationId": header?.userInfo?.currentPartnerLocationId,
                "partnerId": header?.userInfo?.currentPartnerId,
                "reportTypeEnums": ["EXPORT"]
            }
        }



        return (
            <>
                {/* <Switch>
                    <Route exact path={`${this.props.match.path}/ingest`} component={Ingest} />
                    <Route exact path={`${this.props.match.path}/download`} component={Ingest} />
                </Switch> */}
                <Card
                    title={<><Icon type="arrow-left" onClick={() => this.props.history.goBack()} /> Download Reports</>}
                    className='Ingest'
                >


                    <Row className="form-builder">

                        <Col span={12} style={{ borderRight: '1px solid #eaeaea' }}>
                            {!_.isEmpty(params) &&
                                <FormBuilder
                                    builderTitle="Report"
                                    builderType={params}
                                    formSubmission={this.submitForm}
                                    customerList={this.state.customerList}
                                    employeeList={this.state.employeeList}
                                    disableAction={this.state.disableAction}
                                    changeDisable={(val) => this.changeDisable(val)}
                                    loadingForm={this.loadingForm}
                                    loadingDoc={this.state.loading}
                                />
                            }
                        </Col>


                        <Col span={12}>
                            <div className="right-content">

                                <p>
                                    <Icon type="info-circle" /> To download the detailed report, please click on GENERATE REPORT
                                </p>
                                <div className="tables-preview">
                                    {this.state.previewEnabled ?
                                        <div className="tables-preview-filter">
                                            <Radio.Group onChange={this.handleModeChange} buttonStyle="solid" value={this.state.location} style={{ marginBottom: 8 }}>
                                                <Radio.Button value="FE_WISE">Overall</Radio.Button>
                                                <Radio.Button value="LOCATION_WISE">Location Wise</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                        : <></>
                                    }
                                    <div className="tables-preview-data">
                                        {tables}
                                    </div>

                                </div>



                            </div>

                        </Col>
                    </Row>
                </Card>




                <Modal
                    title={<CustomHeader>CONFIRM DOWNLOAD</CustomHeader>}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    wrapClassName="report-header"
                    width={800}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.handleOk} disabled={!this.state.sendEmail && !this.state.email}>
                            {this.state.sendEmail ? 'Download Report' : 'Email & Download Report'}
                        </Button>,
                    ]}
                >
                    <div className="confirm-text">
                        <p className="confirm-text-message">
                            Are you sure you want to re-download the report, with the below filters applied?

                        </p>
                        <Icon className="confirm-text-icon" type="calendar" theme="filled" />
                    </div>
                    <div className="modal-body-wrap">
                        <ul className="li-report">
                            <li className="li-report-list">
                                <div className="li-report-label">
                                    Report Type
                                </div>
                                <div className="li-report-value">
                                    <b>{this.state.data?.key?.report_type}</b>
                                </div>
                            </li>
                            {
                                this.state?.data?.key?.filename ?
                                    <li className="li-report">
                                        <div className="li-report-label">
                                            Waybill
                                    </div>
                                        <div className="li-report-value">
                                            <b>{this.state?.data?.key?.filename}</b>
                                        </div>
                                    </li> : <></>
                            }
                            {this.state?.data?.key?.dateRange ?
                                <li className="li-report">
                                    <div className="li-report-label">
                                        Date
                                    </div>
                                    <div className="li-report-value">
                                        <b>{this.state?.data?.key?.dateRange[0].format("DD/M/YYYY")} - {this.state?.data?.key?.dateRange[1].format("DD/M/YYYY")}</b>
                                    </div>
                                </li> : <></>
                            }

                            {
                                this.state.data?.key?.destinationLocationIds ?
                                    <li className="li-report">
                                        <div className="li-report-label">
                                            Destination Branches
                                    </div>
                                        <div className="li-report-value">
                                            <b>{this.state.data?.key?.destinationLocationIds.join(", ")}</b>
                                        </div>
                                    </li> : <></>
                            }

                            {
                                this.state.data?.key?.customerIds ?
                                    <li className="li-report">
                                        <div className="li-report-label">
                                            Shippers
                                    </div>
                                        <div className="li-report-value">
                                            <b>{this.state.data?.key?.customerIds.join(", ")}</b>
                                        </div>
                                    </li> : <></>
                            }


                            <li className="li-report">
                                <div className="li-report-label">
                                    <Checkbox onChange={this.onChange}>Send Email to</Checkbox>
                                </div>
                                <div className="li-report-value">
                                    <Input
                                        placeholder="Enter Email"
                                        disabled={this.state.sendEmail}
                                        defaultValue={this.state.email}
                                        onChange={this.onEmailChange}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal>


            </>
        )
    }
}

const mapStateToProps = state => ({
    customApiResp: state.ingest.customApiResp,
    customerError: state?.ingest?.customerError,
    header: state.header,
    customerList: state.ingest.customerList,
    employeeList: state.ingest.employeeList,
    downloadStatus: state?.app?.s3Status
});


const mapDispatchToProps = dispatch => ({
    uploadIngestFile: (param1, param2) => dispatch(actions.uploadIngestFile(param1, param2)),
    getReportTypes: (type) => dispatch(actions.getReportTypes(type)),
    setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    getCustomApiCall: (url, method, payload) => dispatch(actions.customUrlApiCall(url, method, payload)),
    getCustomerList: () => dispatch(actions.getCustomerList()),
    getEmplyeesList: () => dispatch(actions.getEmplyeesList()),
    setS3SyncStatusReset: () => dispatch(appActions.setS3SyncStatusReset()),
});
// export default Reports;
export default connect(mapStateToProps, mapDispatchToProps)(Reports);