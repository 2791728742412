import { BASE_URL_TYPES, Constants, DownloadTags } from "./constants";
import {
  apiUrl,
  reportUrl,
  SERVICEABILITY_URL,
  devUrl,
  b2cUrl,
  titanUrl,
  b2cReportUrl,
  b2cErpIdUrl,
  B2CSERVICEABILITY_URL,
} from "../../environments/node-react-environment";
import { notifyApiSuccess, notifyApiError, checkHttpStatus } from "./utils";
import { angularUrl } from "../../environments/node-react-environment";
import _, { truncate } from "lodash";
const axios = require("axios");
let singletonAxios;

export function apiRequest(options = {}) {
  // check for the permissions and log the user out
  const axios = getAxiosInstance(options);

  axios.interceptors.response.use(
    response => handleAxiosResponseSuccess(response, options.showMessage !== undefined ? options.showMessage : true, options.ignoreStatus),
    error => handleAxiosResponseError(error)
  );

  return axios(options);
  // return fetch(url, options)
  //   .then(res => res.json())
}

function handleAxiosResponseSuccess(response, showMessage, ignoreStatus) {
  // console.log(response.data)
  if (
    (_.hasIn(response, "data") &&
      _.hasIn(response?.data, "status") &&
      checkHttpStatus(response?.data?.status, showMessage)) ||
    response?.data?.length > 0 || ignoreStatus === true
  ) {
    return response;
  } else {
    return false;
  }
  // switch (response?.data?.status?.code) {
  
}

function handleAxiosResponseError(error) {
  notifyApiError(error?.message, "ERROR")
  return Promise.reject(error);
}

function getAxiosInstance(options) {
  // if (singletonAxios) {
  //     error singletonAxios;
  // } else {
  // singletonAxios = axios.create({
  //     baseURL: apiUrl,
  //     headers: Constants.HTTP_HEADERS
  // });
  if (options.overrideHeader) {
    singletonAxios = axios.create({
      baseURL: getBaseURL(options),
      timeout: 1000 * Constants.HEADER_TIMEOUT_MS,
      headers: options.overrideHeader
    });
  } else {
    singletonAxios = axios.create({
      baseURL: getBaseURL(options),
      timeout: 1000 * Constants.HEADER_TIMEOUT_MS,
      headers: Constants.HTTP_HEADERS
    });
  }
  singletonAxios.defaults.timeout = 120000;
  return singletonAxios;
  // }
}

function getBaseURL(options) {
  let baseUrl = apiUrl;
  // const application = atob(localStorage.getItem('application'));
  // if (application === 'B2C') {
  //   baseUrl = b2cUrl;
  // }
  if (options && options.hasOwnProperty("baseUrlType")) {
    switch (options.baseUrlType) {
      case BASE_URL_TYPES.REPORTS:
        baseUrl = reportUrl;
        // if (application === 'B2C') {
        //   baseUrl = b2cReportUrl;
        // }
        break;
      case BASE_URL_TYPES.SERVICEABILITY:
        baseUrl = SERVICEABILITY_URL;
        // if (application === 'B2C') {
        //   baseUrl = B2CSERVICEABILITY_URL;
        // }
        break;
      case BASE_URL_TYPES.TITAN:
        baseUrl = titanUrl;
        break;
      case BASE_URL_TYPES.DEV:
        baseUrl = devUrl;
        break;
      default:
        baseUrl = reportUrl;
      // if (application === 'B2C') {
      //   baseUrl = b2cReportUrl;
      // }
    }
  }
  return baseUrl;
}

export function getPostFilterPayload() {
  return {
    filters: {
      pageNo: 0,
      pageSize: 10,
      totalCount: 0
    }
  };
}

export function thirdPartyGet(path, params, type) {
  const url = `${path}`;
  if (type === DownloadTags.type) {
    return axios({ url, method: "GET" });
  }
}
