import React, { Component } from 'react';
import { Row, Col, Button, Table, Empty,  Icon, Input, Switch, notification} from 'antd';
import { connect } from 'react-redux';
import ClusterMap from './ClusterMap';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import moment from 'moment';
import { Constants } from '../../../common/utils/constants';
// import geojson from './lsn.json';
import * as actions from './actions';
import './pickupAssist.css';

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);


class PickupAssistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      selectedAwb : 0,
      currentdraft : [], 
      getgeojson: [],
      currentWeight: 0,
      currentDistance: 0,
      remainingAwbs: 0,
      includedIds : [],
      geocodeFail: 0,
      customerIds : [],
      zoom: 1,
      filterPayload: {
        filters: {
            pageNo : 1,
            totalCount: 0,
            pageSize: 2000,
        },
      }
    };
    this.InputSearchRef = React.createRef();
    this.draftName = React.createRef();
  }

  componentDidMount() {
    this.props.getClientsName();
      this.setState(state => ({
          columnsDraft: [
              {
                title: '',
                dataIndex: '',
                key: 'dragCurrent',
                render: (data, row) => (<Icon type="drag" />)
              },
              {
                title: 'AWB Number',
                dataIndex: 'consignmentBO',
                key: 'awbNumberCurrent',
                className: 'customHead',
                render: (data, row) => (<div className="addressFont">{data ?.waybillNo}</div>)
              },
              {
                title: 'Item Weight',
                dataIndex: 'consignmentBO',
                key: 'startWeightCurrent',
                className: 'customHead',
                render: (data, row) => (<div className="addressFont">{data ?.totalWeight}</div>)
              },
              {
                title: 'Address',
                key: 'startAddressCurrent',
                dataIndex: 'consignmentBO',
                className: 'customHead',
                width: 150,
                render: (data, row) => (<div className="addressFont">{data ?.consignee ?.address ?.address1 + ", " + data ?.consignee ?.address ?.address2}</div>)
              },
              {
                  title: 'Action',
                  dataIndex: '',
                  key: 'action',
                  className: 'customHead',
                  render: (data, row) => (
                    <Row>
                        <Button className="addressFont" type="primary" onClick={() => this.deleteFromDraft(row.id)}  size="small">Delete</Button>
                    </Row >
                    )
                }
            ]
      }));
      this.setState(state => ({
        columns: [
            {
              title: 'AWB Number',
              dataIndex: 'consignmentBO',
              key: 'awbNumber',
              className: 'customHead',
              render: (data, row) => (<div className="addressFont">{data ?.waybillNo}</div>)
            },
            {
              title: 'Item Weight',
              dataIndex: 'consignmentBO',
              key: 'startWeight',
              className: 'customHead',
              render: (data, row) => (<div className="addressFont">{data ?.totalWeight}</div>)
            },
            {
              title: 'Address',
              key: 'startAddress',
              dataIndex: 'consignmentBO',
              className: 'customHead',
              width: 150,
              render: (data, row) => (<div className="addressFont">{data ?.consignee ?.address ?.address1 + ", " + data ?.consignee ?.address ?.address2}</div>)
            },
            {
              title: 'Geolocation',
              key: 'geolocation',
              dataIndex: 'consignmentBO',
              className: 'customHead',
              render: (data, row) => data ?.consignee ?.address ?.latitude ? <Col span={12}><Icon className="green" type="check" /></Col> : <Col span={12}><Icon className="red" type="close" /></Col>,
            },
            {
                title: 'Action',
                dataIndex: 'consignmentBO',
                key: 'action',
                className: 'customHead',
                render: (data, row) => (
                <Row>
                    <Button className="addressFont" type="primary" onClick={() => this.addDataToDraft(row.id)}  size="small">Add</Button>
                </Row >
                )
              }
          ]
      }));
      if (this ?.props ?.PaginatedPickups){
        if(!this.props ?.PaginatedPickups[0]){
          this.getPaginatedData(this.state.pageNo);
        }else{
          this.getgeojsonData();
        }
      } else {
        this.getPaginatedData(this.state.pageNo);
      }
  }

  componentDidUpdate(prevProps, prevState) {
    const { PaginatedPickups } = this.props;
    if (PaginatedPickups !== prevProps.PaginatedPickups) {
      this.getgeojsonData();
    }
    if (this.state.customerIds !== prevState.customerIds) {
      this.applyPagination({ customerIds : this.state.customerIds}, true);
    }
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedPickups} = this.props;
    if (!PaginatedPickups ? !PaginatedPickups : !PaginatedPickups[pageNo]) {
      let filter = {
        pageNo: pageNo
      };
      this.applyPagination(filter);
    }
    this.setState({
      pageNo
    });
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }

    this.props.getInventory({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
  }

  applySearch = (filter) => {
    const { PaginatedPickups } = this.props;
    let checkgeoJson = [...PaginatedPickups[0]];
    let filtergeoJson = [];
    if(filter.partialContentSearch){
      filtergeoJson = checkgeoJson.filter(x => x.consignmentBO.waybillNo.indexOf(filter.partialContentSearch) !== -1);
    } else {
      filtergeoJson = checkgeoJson;
    }
    this.setState({
      PaginatedPickupstables: {0 : filtergeoJson}
    })
  }

  getgeojsonData = () => {
    const { PaginatedPickups } = this.props;
    let checkgeoJson = [...PaginatedPickups[0]];
    let filtergeoJson = [];
    checkgeoJson.map(x => {
        if(x.consignmentBO.consignee.address.latitude){
          x.consignmentBO.consignee.address.marker = "blueMarker";
          filtergeoJson.push(x);
        }
    });
    let failedawb = checkgeoJson.length - filtergeoJson.length;
    this.setState({
      getgeojson: filtergeoJson,
      geocodeFail: failedawb,
      remainingAwbs: checkgeoJson.length,
      PaginatedPickups: PaginatedPickups,
      PaginatedPickupstables: {0 : checkgeoJson},
    })
  }

  addDataToDraft = (id, zoomlevel) => {
    const { PaginatedPickups } = this.props;
    let checkgeoJson = [...PaginatedPickups[0]];
    let currentDraftData = [...this.state.currentdraft];
    let currentIncludedIds = [...this.state.includedIds];
    let filtergeoJson = [];
    let currentWeight;
    if(currentIncludedIds.includes(id)){
      return;
    } else {

      checkgeoJson.map(x => {
        if(x.id === id){
          currentDraftData.push(x);
          currentIncludedIds.push(x.id);
          currentWeight = this.state.currentWeight + x.consignmentBO.totalWeight;
          // x.key = currentDraftData.length;
          if(x.consignmentBO.consignee.address.latitude){
            x.consignmentBO.consignee.address.marker = "pinkicon";
          }
        }
        if(x.consignmentBO.consignee.address.latitude){
          filtergeoJson.push(x);
        }
      });
      let filtergeoJsonTable = checkgeoJson.filter(y => !currentIncludedIds.includes(y.id))
      this.setState({
        includedIds: currentIncludedIds,
        selectedAwb : currentDraftData.length,
        currentdraft : currentDraftData,
        getgeojson: filtergeoJson,
        currentWeight: currentWeight,
        remainingAwbs: checkgeoJson.length - currentDraftData.length,
        PaginatedPickupstables: {0 : filtergeoJsonTable},
        zoom : zoomlevel
      })
    }
  }

  getPolygonCord = (cords, zoomlevel) => {
    const { PaginatedPickups } = this.props;
    let checkgeoJson = [...PaginatedPickups[0]];
    let currentDraftData = [...this.state.currentdraft];
    let currentIncludedIds = [...this.state.includedIds];
    let filtergeoJson = [];
    let currentWeight = this.state.currentWeight;
      checkgeoJson.map(x => {
          if(x.consignmentBO.consignee.address.latitude){
            let markerOne = [Number(x.consignmentBO.consignee.address.longitude), Number(x.consignmentBO.consignee.address.latitude)];
            let coordinates = this.inside( markerOne, cords.geometry.coordinates[0]);
            if(coordinates === true){
              currentDraftData.push(x);
              currentIncludedIds.push(x.id);
              currentWeight = currentWeight + x.consignmentBO.totalWeight;
              x.consignmentBO.consignee.address.marker = "pinkicon";
            }
            filtergeoJson.push(x);
          }
      });
      let filtergeoJsonTable = checkgeoJson.filter(y => !currentIncludedIds.includes(y.id))
      this.setState({
        includedIds: currentIncludedIds,
        selectedAwb : currentDraftData.length,
        currentdraft : currentDraftData,
        getgeojson: filtergeoJson,
        currentWeight: currentWeight,
        remainingAwbs: checkgeoJson.length - currentDraftData.length,
        PaginatedPickupstables: {0 : filtergeoJsonTable},
        zoom : zoomlevel
      })
  }

  deleteFromDraft = (id) => {
    const { PaginatedPickups } = this.props;
    let checkgeoJson = [...PaginatedPickups[0]];
    let currentDraftData = [...this.state.currentdraft];
    let currentIncludedIds = [...this.state.includedIds];
    let filtergeoJson = [];
    let currentWeight;
    currentDraftData = currentDraftData.filter(p => p.id !== id);
    currentIncludedIds = currentIncludedIds.filter(t => t !== id);
      checkgeoJson.map(x => {
        if(x.id === id){
          currentWeight = this.state.currentWeight - x.consignmentBO.totalWeight;
          if(x.consignmentBO.consignee.address.latitude){
            x.consignmentBO.consignee.address.marker = "blueMarker";
          }
        }
        if(x.consignmentBO.consignee.address.latitude){
          filtergeoJson.push(x);
        }
      });
      let filtergeoJsonTable = checkgeoJson.filter(y => !currentIncludedIds.includes(y.id))
      this.setState({
        includedIds: currentIncludedIds,
        selectedAwb : currentDraftData.length,
        currentdraft : currentDraftData,
        getgeojson: filtergeoJson,
        currentWeight: currentWeight,
        remainingAwbs: checkgeoJson.length - currentDraftData.length,
        PaginatedPickupstables: {0 : filtergeoJsonTable}
      })

  }

  failedGeocode = (event) => {
    const { PaginatedPickups } = this.props;
    let checkgeoJson = [...PaginatedPickups[0]];
    let currentIncludedIds = [...this.state.includedIds];
    console.log(currentIncludedIds);
    let filtergeoJson = [];
    if(event){
      let filterData = [];
      checkgeoJson.map(x => {
        if(!x.consignmentBO.consignee.address.latitude){
          filterData.push(x);
        }
      });
      console.log(filterData);
      filtergeoJson = filterData.filter(x => !currentIncludedIds.includes(x.id))
    } else {
      filtergeoJson = checkgeoJson.filter(x => !currentIncludedIds.includes(x.id));
    }
    console.log(filtergeoJson);
    this.setState({
      PaginatedPickupstables: {0 : filtergeoJson}
    })
  }

  totalfilter = (id) => {
    let customerIdsAdd = [...this.state.customerIds];
    if(!customerIdsAdd.includes(id)){
      customerIdsAdd.push(id)
    } else {
      customerIdsAdd = customerIdsAdd.filter(t => t !== id);
    }
    this.setState({
      customerIds : customerIdsAdd
    })
  }

  ButtonList = (posts) => {
    const listItems = posts.map((x, key) =>
      <Button key={key} type={this ?.state ?.customerIds.includes(x.id) ? "primary" : "default"} style={{fontSize : 10, marginTop: 5, marginBottom: 5}} shape="round" size="small" onClick={(id) => this.totalfilter(x.id)}>{(x.name.toLowerCase()).replace(/\b\w/g, function(l){ return l.toUpperCase() })}</Button>
    );
    return (
      <div>{listItems}</div>
    );
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { currentdraft } = this.state;
    const dragRow = currentdraft[dragIndex];

    this.setState(
      update(this.state, {
        currentdraft: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };

  discardToDraft = () => {
      this.applyPagination({ pageNo: 1 }, true);
      this.draftName.state.value = '';
      this.setState({
        includedIds: [],
        currentdraft : [],
        currentWeight: 0,
        selectedAwb : 0,
      })
  }

  saveToDraft = () => {
    const {  currentWeight,  currentDistance} = this.state;
    let currentDraftData = [...this.state.currentdraft];
    let consignmentArray = [];
    if(!this.draftName.state.value){
      return
    }

    currentDraftData.map(x =>{
      let consignmentObject = {
        "waybillNo": x.consignmentBO.waybillNo,
        "id": x.consignmentBO.id
      };
      consignmentArray.push(consignmentObject);
    })
    let payload = {
                    "runsheetDraftBO": {
                        "runsheetType": "DRS",
                        "name": this.draftName.state.value,
                        "distance": currentDistance,
                        "weight": currentWeight,
                        "createdAt": moment().valueOf(),
                        "consignments": consignmentArray
                    }
                }
      this.props.createDrsDraft(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
          notification['success']({
            message: 'SUCCESS',
            description: res ?.data ?.status ?.message,
            placement: 'bottomRight',
          });
          this.draftName.state.value = '';
            this.setState({
              includedIds: [],
              currentdraft : [],
              currentWeight: 0,
              selectedAwb : 0})
        } else {
          this.draftName.state.value = '';
          notification['error']({
            message: 'ERROR',
            description: res ?.data ?.status ?.message,
            placement: 'bottomRight',
          });
        }
      });
  }

  inside = (point, vs) =>{
      var x = point[0], y = point[1];
      
      var inside = false;
      for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
          var xi = vs[i][0], yi = vs[i][1];
          var xj = vs[j][0], yj = vs[j][1];
          
          var intersect = ((yi > y) != (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) inside = !inside;
      }
      
      return inside;
  };

  distanceCalculate = () => {
    let currentDraftData = [...this.state.currentdraft];
    let consignmentArray = [];
    currentDraftData.map(x => {
      if(x.consignmentBO.consignee.address.latitude){
        let consignmentObject = {
          "id": x.consignmentBO.id,
          "lat": x.consignmentBO.consignee.address.latitude,
          "long": x.consignmentBO.consignee.address.longitude
        };
        consignmentArray.push(consignmentObject);
      }
    })
    if(consignmentArray.length > 0){
      let payload = {
        "start": {
            "id": consignmentArray[0].id,
            "lat": consignmentArray[0].lat,
            "long": consignmentArray[0].long,
          },
        "consignments": consignmentArray
    }
      this.props.distanceCalculation(payload).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
      notification['success']({
      message: 'SUCCESS',
      description: res ?.data ?.status ?.message,
      placement: 'bottomRight',
      });
      this.draftName.state.value = '';
      this.setState({currentDistance: res ?.data ?.response ?.distance, distanceLength : currentDraftData.length})
      } else {
      this.draftName.state.value = '';
      notification['error']({
      message: 'ERROR',
      description: res ?.data ?.status ?.message,
      placement: 'bottomRight',
      });
      }
      });
    }

  }

    render() {
      const {  getgeojson, currentdraft, pageNo } = this.state;
      const {  pickupAssistData, clientData } = this.props;
      let buttonlistSet;
      if(clientData) {
        buttonlistSet = this.ButtonList(clientData);
      }
      
        return (
            <>
              <Row gutter={24}>
                <Col span={14} >
                    <ClusterMap geojson={getgeojson} addDataToDraft={this.addDataToDraft} polygonCoordinates={this.getPolygonCord} zoombox={this.state.zoom}/>
                    <Col className="draftInfo" style={{marginTop : 20, fontSize : 14, padding: 10, background: "#fff", textAlign: "center"}} span={4}>Selected AWBs<br/><div className="draftNumber">{this.state.selectedAwb}</div></Col>
                    <Col className="draftInfo" style={{marginTop : 20, fontSize : 14, padding: 10, background: "#fff", textAlign: "center", marginLeft: 10}} span={4}>Current Weight<br/><div className="draftNumber">{this.state.currentWeight} kg</div></Col>
                    <Col className="draftInfo" style={{marginTop : 20, fontSize : 14, padding: 10, background: "#fff", textAlign: "center", marginLeft: 10}} span={4}>Current Distance {currentdraft ?.length > 0 && <Icon type="sync" className={this.state.distanceLength !== currentdraft ?.length ? "red" : "green"} onClick={this.distanceCalculate}/>}<br/><div><span className="draftNumber">{this.state.currentDistance/1000} </span>km</div></Col>
                    <Col className="draftInfo" style={{marginTop : 20, fontSize : 14, padding: 10, background: "#fff", textAlign: "center", marginLeft: 10}} span={4}>Remaining AWBs<br/><div className="draftNumber">{this.state.remainingAwbs}</div></Col>
                    <Col className="draftInfo" style={{marginTop : 20, fontSize : 14, padding: 10, background: "#fff", textAlign: "center", marginLeft: 10}} span={4}>GeoCoding Failed<br/><div className="draftNumber">{this.state.geocodeFail}</div></Col>
                </Col>
                <Col span={10} >
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{background: "#fff", paddingTop: 10, paddingBottom:10}}>
                     <Col style={{ paddingBottom:10, fontSize : 10}}>Current Draft Details</Col>
                     <Col span={24}>
                      <DndProvider backend={HTML5Backend}>
                        <Table
                            bordered
                            locale={{
                            emptyText: (
                                <div className="emptyText addressFont">Click on location pin on the map/select <b>Remaining AWB</b> to add it to the DRS Draft</div>
                            )
                            }}
                            loading={false}
                            scroll={{ y: 200 }}
                            pagination={false}
                            dataSource={this.state.currentdraft}
                            components={this.components}
                            onRow={(record, index) => ({
                              index,
                              moveRow: this.moveRow,
                            })}
                            columns={this.state.columnsDraft} />
                        </DndProvider>
                      </Col>
                    <Col span={12} offset={6} style={{marginTop : 10}}>
                      <Input size={'small'} className="addressFont" placeholder="Enter DRS Draft Name" name="draftName" ref={(c) => this.draftName = c} />
                    </Col>
                    <Col span={12} offset={8} style={{marginTop : 10}}>
                      <Button className="addressFont" type="danger" disabled={currentdraft ?.length == 0} onClick={() => this.discardToDraft()}  size="small">Discard</Button>
                      <Button className="addressFont" type="primary" disabled={currentdraft ?.length == 0} onClick={() => this.saveToDraft()}  size="small">Save</Button>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginTop : 20, background: "#fff", paddingTop: 10, paddingBottom:10}}>
                    <Col  span={24}>
                      <Col style={{fontSize : 10, marginBottom: 2, lineHeight: 2.4}} span={24}>Clients :</Col>
                      <Col span={16}>
                        {buttonlistSet}
                      </Col>
                      <Col span={6}>
                        <Switch type="primary" checkedChildren="FailedGeocoding" unCheckedChildren="All" onChange={this.failedGeocode} />
                      </Col>
                    </Col>
                     <Col span={10} style={{ paddingBottom:10, fontSize : 10, marginTop : 15}}>Remaining AWB Details</Col>
                     <Col span={14} style={{ paddingBottom:10, fontSize : 10, marginTop : 15}}><Input.Search size={'small'} className="addressFont" placeholder="Search" allowClear ref={this.InputSearchRef} onSearch={value => this.applySearch({ partialContentSearch: value.trim() })} enterButton /></Col>
                     <Col span={24}>
                        <Table
                            bordered
                            locale={{
                            emptyText: (
                              <Empty className="addressFont"  image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                            }}
                            loading={pickupAssistData ?.loading}
                            scroll={{ y: 300 }}
                            pagination={false}
                            dataSource={this.state.PaginatedPickupstables ? this.state.PaginatedPickupstables[0] : []}
                            columns={this.state.columns} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
  pickupAssistData: state ?.pickupAssist,
  PaginatedPickups: state ?.pickupAssist ?.PaginatedPickups,
  clientData: state ?.pickupAssist ?.customers,
});

const mapDispatchToProps = dispatch => ({
    getInventory: (param, isReset = false) => dispatch(actions.getInventory(param, isReset)),
    getClientsName: () => dispatch(actions.getClientsName()),
    createDrsDraft: (payload) => dispatch(actions.createDrsDraft(payload)),
    distanceCalculation: (payload) => dispatch(actions.distanceCalculation(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PickupAssistance);