import React, { useEffect, useRef, useState } from "react";
import FormWrapper from '../../../../../ui/formComponent/form';
import otherChargesSchema from "./other.charges.schema";

function OtherCharges(props) {

    var formRef = useRef();
    const [formData, setFormData] = useState({});
   
    useEffect(() => {
        const { contractData } = props;
        setFormData(contractData)
    }, [])        
    
    useEffect(() => {
		if (props.retrieveOtherCharges) {
			formRef.current.formReference?.current?.submit()
		}
    }, [props.retrieveOtherCharges])

    return (
        <div>
            {formData &&
                <FormWrapper
                    id={"OtherCharges"}
                    {...otherChargesSchema}
                    formData={formData}
                    onSubmit={params => props.getOtherCharges(params, false)}
                    onError={_ => props.getOtherCharges(formData, true)}
                    onChange={data => {
                        setFormData(data)
                    }}
                    readonly={props.readonly}
                    fieldsPerRow={2}
                    showColumnSplitter={false}
                    loading={props.loading}
                    bordered={true}
                    hideSubmit={true}
                    ref={formRef}
                />
            }

        </div>



    );
}
export default OtherCharges;