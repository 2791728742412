import { notification, Button } from 'antd';
import './Notification.scss';
import React from 'react';
export const ErrorNotification = (props) => {
    let btn = null;
    var result = props.description;
    if (props.description.length > 100) {
        result = props.description.substring(0, 100) + '...';
    }
    if (props.description.length > 100) {

        btn = (
            <Button type="link" size="small" onClick={() => {
                notification.destroy();
                var newConfig = {
                    message: `${props.message || ''}`,
                    description: <div style={{ overflow: 'auto', height: '20vh' }}>{props.description}</div>,
                    placement: `${props.placement || 'bottomRight'}`,
                    onClose: () => {
                        if (typeof props.onClose === "function") {
                            props.onClose();
                        }
                    },
                    className: props.className
                }
                notification.error(newConfig);
            }}>
                Show More
            </Button>
        );
    }

    const config = {
        message: `${props.message || ''}`,
        description: result,
        placement: `${props.placement || 'bottomRight'}`,
        btn,
        onClose: () => {
            if (typeof props.onClose === "function") {
                props.onClose();
            }
        },
        className: props.className
    }
    if (props.error) {
        notification.error(config);
    } else if (props.warning) {
        notification.warning(config)
    } else {
        notification.open(config)
    }
};

export const destroyNotification = () => notification.destroy();
