import * as apiUtils from '../../../common/utils/apiGateway';
import { checkAPIHttpStatus, notifyApiSuccess } from '../../../common/utils/utils';

//Contracts fetch
export const fetchManifest = id => new Promise(resolve => {
    if (id) {
        
        const url = `/b2b/v1/trip/manifest-sheet/${id}`;
        apiUtils.apiRequest({
            url,
            method: "GET"            
        }).then(response => {
            let manifest = {};
            console.log("Res", response)
            manifest = response?.data?.response?.manifestSheet;
            if (Object.keys(manifest).length <= 0) {
                resolve(false);
            }
            else {
                resolve(manifest);
            }
        })
    }
    else {
        resolve(false);
    }
});