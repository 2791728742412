import React from 'react';
import IndentsNavigation from '../components/indent.navigation';

export default function IndentViewPage(props) {
    
    const goBackToListing = _ => {
        props.history.push(
            "/appv2/indent/dashboard", { ...props.location?.state }
        );
    }

    return <IndentsNavigation {...props?.location?.state}  {...props} goBackToListing={goBackToListing}/>;
}