
export const initialState = {
}




export const FETCH_SLA_CONSIGNMENTS_BEGIN = "FETCH_SLA_CONSIGNMENTS_BEGIN";
export const FETCH_SLA_CONSIGNMENTS_SUCCESS = "FETCH_SLA_CONSIGNMENTS_SUCCESS";
export const FETCH_SLA_CONSIGNMENTS_FAILURE = "FETCH_SLA_CONSIGNMENTS_FAILURE";

export const FETCH_SLA_CONSIGNMENTS_SUMMARY_BEGIN = "FETCH_SLA_CONSIGNMENTS_SUMMARY_BEGIN";
export const FETCH_SLA_CONSIGNMENTS_SUMMARY_SUCCESS = "FETCH_SLA_CONSIGNMENTS_SUMMARY_SUCCESS";
export const FETCH_SLA_CONSIGNMENTS_SUMMARY_FAILURE = "FETCH_SLA_CONSIGNMENTS_SUMMARY_FAILURE";

export const FETCH_SLA_CUSTOMERS_BEGIN = "FETCH_SLA_CUSTOMERS_BEGIN";
export const FETCH_SLA_CUSTOMERS_SUCCESS = "FETCH_SLA_CUSTOMERS_SUCCESS";
export const FETCH_SLA_CUSTOMERS_FAILURE = "FETCH_SLA_CUSTOMERS_FAILURE";


export const FETCH_SLA_REASONS_BEGIN = "FETCH_SLA_REASONS_BEGIN";
export const FETCH_SLA_REASONS_SUCCESS = "FETCH_SLA_REASONS_SUCCESS";
export const FETCH_SLA_REASONS_FAILURE = "FETCH_SLA_REASONS_FAILURE";


export const FETCH_SLA_LOCATIONS_BEGIN = "FETCH_SLA_LOCATIONS_BEGIN";
export const FETCH_SLA_LOCATIONS_SUCCESS = "FETCH_SLA_LOCATIONS_SUCCESS";
export const FETCH_SLA_LOCATIONS_FAILURE = "FETCH_SLA_LOCATIONS_FAILURE";



export const FETCH_SLA_EXPORT_BEGIN = "FETCH_SLA_EXPORT_BEGIN";
export const FETCH_SLA_EXPORT_SUCCESS = "FETCH_SLA_EXPORT_SUCCESS";
export const FETCH_SLA_EXPORT_FAILURE = "FETCH_SLA_EXPORT_FAILURE";


export const SET_SLA_REASONS_BEGIN = "SET_SLA_REASONS_BEGIN";
export const SET_SLA_REASONS_SUCCESS = "SET_SLA_REASONS_SUCCESS";
export const SET_SLA_REASONS_FAILURE = "SET_SLA_REASONS_FAILURE";