import React, { Component } from 'react';
import JsBarcode from "jsbarcode";

class Barcode extends Component {
  componentDidMount() {
    JsBarcode( this.props.barCodeId ? '#' + this.props.barCodeId : ".barcode" , this.props.value, {
      displayValue: this.props.showText,
      format: this.props.format,
      width: this.props.width ? this.props.width : 2,

    }).init();
  }

  render() {
    return <svg
      className="barcode"
      id={this.props.barCodeId ? this.props.barCodeId : "barcodeSVGElem"}
      jsbarcode-format={this.props.format}
      jsbarcode-value={this.props.value}
      jsbarcode-textmargin="0"
      jsbarcode-fontoptions="bold"
    />;
  }
}

export default Barcode;