// import { permissions } from './permissions.service';
import {
  USER_LOGGEDIN_INFO,
  ENVTYPES,
  BASE_URL
} from "../common/utils/constants";
import { expireCookie } from "../common/utils/utils";
import { logoutUrlSass } from "../environments/node-react-environment";
import { devEnvType } from "../environments/node-react-environment";

export class AuthenticationService {
  static logoutUser() {
    const appType = localStorage.getItem("isSassProduct");
    localStorage.clear();
    expireCookie(USER_LOGGEDIN_INFO.LOGGED_IN);
    window.location = BASE_URL + "appv2/login";   
  }
}
