import React, { useState, useEffect, useRef } from 'react';
import { Table, Spin, Input, Switch, Button, Icon, } from 'antd';
import { notifyApiError } from '../../../../common/utils/utils';
import { getShippersColumn } from "../common/columns/index";
import ShipperFormModal from "./shipper.form.modal";
import { permissionTags } from '../../../../configs/permissionTags';
import { canAllow } from '../../../../common/utils/utils';
import { fetchShippers } from '../../../../services/common/fetch.services';

function Shipper(props) {
    const { customerId, customer, setShipper } = props;
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false)
    const [shippers, setShippers] = useState([]);
    const [shipperModal, setShipperModal] = useState(false);
    const [selectedShipper, setSelectedShipper] = useState(undefined);
    const [partialContentSearch, setPartialContentSearch] = useState(undefined);
    const [searchData, setSearchData] = useState(undefined);
    const [createAnother, setCreateAnother] = useState(false);
    // const [shipperAction, setShipperAction] = useState(action);
    const [filters, setFilters] = useState({ "pageNo": 1, "pageSize": 10, isActive: true });
    const [refresh, setRefresh] = useState(false);
    const shipperPermission = {
        shipperCreate: canAllow(permissionTags.contract.shippers.create),
        shipperUpdate: canAllow(permissionTags.contract.shippers.update),


    }
    const shipperRef = useRef();
    useEffect(() => {

        const fetchData = async () => {
            try {
                const params = {
                    entityIds: [customerId],
                    pageNo: filters?.pageNo,
                    pageSize: filters?.pageSize,
                    isActive: filters?.isActive,
                    partialContentSearch
                }
                const data = await fetchShippers(params);
                if (!readOnly && data?.entityShipperMappingBOList.length <= 0 && shipperPermission.shipperCreate) {
                    // setShipperAction('ADD')
                    setReadOnly(false);
                    setShipperModal(true);
                    setShipper(false);
                } else {
                    setShipper(true);
                }
                setShippers(data);
                setRefresh(false);
            } catch (error) {
                notifyApiError(error, "Error")
            }
        };


        fetchData();
    }, [filters, partialContentSearch, refresh === true]);


    if (shippers.length === 0) {
        return (
            <Spin />
        )
    }
    function showModal(row) {

        setSelectedShipper(row)
        setShipperModal(true)
    }

    function hideModal() {

        setShipperModal(false);
        setSelectedShipper(undefined);
        setReadOnly(true)
        setCreateAnother(false);

    }

    function submit() {

        shipperRef.current.formReference.current.submit();
    }


    const shipperEvents = {
        openModal: showModal,
        setReadOnly: setReadOnly,
        shipperPermission: shipperPermission
    }

    return (
        <>
            <div className="flex-box flex-column flex-gap-xl">
                <div>
                    <div className="flex-box align-items-center justify-content-between flex-gap-m">
                        <div className="flex-4 flex-box align-items-center justify-content-between flex-gap-xl">
                            <div className="flex-5">
                                <Input
                                    prefix={<Icon type="search" />}
                                    placeholder={"Search by shipper code, name"}
                                    value={searchData}
                                    suffix={
                                        searchData &&
                                        <Icon
                                            type="close-circle"
                                            onClick={() => {
                                                setSearchData(undefined);
                                                setPartialContentSearch(undefined)
                                            }

                                            }
                                        />
                                    }
                                    onChange={(e) => setSearchData(e.target.value)}
                                    onPressEnter={(e) => {
                                        setPartialContentSearch(e.target.value)
                                        setFilters({ "pageNo": 1, isActive: filters?.isActive })
                                    }}
                                />
                            </div>
                            <div className="flex-1 flex-box align-items-center flex-gap-l">
                                <div> Show Active </div>
                                <Switch
                                    checked={filters?.isActive}
                                    onChange={(checked) => setFilters({ ...filters, pageNo: 1, isActive: checked })}
                                />
                            </div>
                        </div>
                        {shipperPermission.shipperCreate &&
                            <div className="flex-1 flex-box justify-content-flex-end">
                                <div>
                                    <Button block className="lsn-btn-primary" onClick={() => { setReadOnly(false); setShipperModal(true) }}>Add Shipper</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <Table
                        columns={getShippersColumn(shipperEvents)}
                        dataSource={shippers?.entityShipperMappingBOList}
                        rowKey={'id'}
                        bordered
                        pagination={{
                            onChange: (pageNo, pageSize) => setFilters({ pageNo, pageSize, isActive: filters?.isActive }),
                            total: shippers?.totalCount,
                            showTotal: (total, range) => <span className="total-pagination">{total} {total <= 1 ? "Shipper" : "Shippers"}</span>,
                            pageSize: shippers?.currentPageSize,
                            current: shippers?.currentPageNo,
                            showSizeChanger: true,
                            onShowSizeChange: (current, pageSize) => setFilters({ pageNo: current, pageSize, isActive: filters?.isActive }),
                        }}
                    />
                </div>
            </div>
            {shipperModal
                &&
                <ShipperFormModal
                    shipperModal={shipperModal}
                    customerId={customerId}
                    shippers={selectedShipper}
                    cancelShipperModal={hideModal}
                    readOnly={readOnly}
                    shipperRef={shipperRef}
                    onSave={submit}
                    customer={customer}
                    showModal={setShipperModal}
                    setRefresh={setRefresh}
                    setReadOnly={setReadOnly}
                    shipperPermission={shipperPermission}
                    createAnother={createAnother}
                    setCreateAnother={setCreateAnother}
                    openHelpLink={props.openHelpLink}
                />
            }

        </>
    )
}

export default Shipper;