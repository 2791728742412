import React, { Component } from 'react'
import './trips.scss'
import { Card, Col, Input, Row, Icon, Button, Spin, Tag, Empty, Pagination, Modal, Badge, Select } from 'antd'
import { Constants, FILTER_INPUT_TYPE } from '../../../common/utils/constants'
import moment from 'moment'
import Filters from '../../ui/filter/Filter'
import DatePickerInput from "../../ui/datepicker/DatePicker";

import FilterIcon from "../../../assets/img/icons/filter-icon.svg";
import TripDataCard from './trip.data-card'
import _ from 'lodash';
// import _Records from './data.json';
import * as apiUtils from '../../../common/utils/apiGateway';
import debounce from 'lodash/debounce';
import { canAllow, notifyApiError, notifyApiSuccess, checkAPIHttpStatus } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags'

const Option = Select.Option;
class VerificationTrips extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // tripData: _Records,
            loading: false,
            filterPayload: {
                "tripCreationDateLowerLimit": moment().subtract(30, 'd').startOf('day').valueOf(),
                "tripCreationDateUpperLimit": moment().endOf('day').valueOf(),
                "pageNo": 1,
                "pageSize": 5,
                locations: [Constants.userLocationId],
            },
            newFilterPayload: {
                "tripCreationDateLowerLimit": moment().subtract(30, 'd').startOf('day').valueOf(),
                "tripCreationDateUpperLimit": moment().endOf('day').valueOf(),
                "pageNo": 1,
                "pageSize": 5
            },
            tripDateValue: [moment().subtract(30, 'd').startOf('day'), moment().endOf('now')],
            startDate: moment().subtract(30, 'd').startOf('day'),
            endDate: moment().endOf('day'),
            tags: [],
            searchTripIds: undefined,
            vendors: [],
            selectedVendors: [],
            locations: [],
            selectedLocations: [{
                'label': localStorage.getItem('currentLocationName'),
                'key': Constants.userLocationId
            }],
            locationLoading: false,
            visible: false,
            initalFilters: setinitialFilters,
            isEndDateOpen: false,
            verificationPermission: {
                read: canAllow(permissionTags.verification.trips.read),
                update: canAllow(permissionTags.verification.trips.update),
                create: canAllow(permissionTags.verification.trips.create),
                delete: canAllow(permissionTags.verification.trips.delete),
                reverify: canAllow(permissionTags.verification.trips.reverify),
            },
        }

        this.fetchLocations = debounce(this.fetchLocations, 800);
    }

    componentDidMount() {

        if (this.props?.location?.state?.filterApplied && this.props?.location?.state?.tags && this.props?.location?.state?.initalFilters) {
            let { newFilterPayload, tags, initalFilters } = this.state;
            newFilterPayload = { ...this.props.location.state.filterApplied };
            tags = [...this.props?.location?.state?.tags];
            initalFilters = { ...this.props.location.state.initalFilters };
            if (newFilterPayload?.tripIds && newFilterPayload?.tripIds?.length > 0) {
                this.setState({ searchTripIds: newFilterPayload?.tripIds })
            }
            this.setState({ newFilterPayload, tags, initalFilters }, () => {
                // this.resetFilters();
                this.applyPagination(this.state.newFilterPayload, true);
                this.getVendors();
            });
        } else {
            this.resetFilters();
            this.getVendors();
        }
        window.scrollTo(0, 0);
        // this.applyPagination(this.state.filterPayload, true);



    }

    getVendors = () => {
        apiUtils.apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors?fetch=STRICT_BASIC&showActualData=true`,
            method: "GET"
        })
            .then(response => {

                if (checkAPIHttpStatus(response?.data?.status)) {
                    let selectedVendors = [];
                    let { vendors } = this.state;
                    vendors = response?.data?.response?.vendors;
                    vendors = vendors.map(v => {
                        if (this.props?.location?.state?.filterApplied?.billToVendorIds && this.props?.location?.state?.filterApplied?.billToVendorIds?.length > 0) {
                            const billToVendorIds = this.props?.location?.state?.filterApplied?.billToVendorIds;
                            if (billToVendorIds.indexOf(v.id) >= 0) {
                                selectedVendors.push({
                                    label: v?.name,
                                    key: v?.id,
                                    value: v?.id
                                })
                            }
                        }

                        return {
                            label: v?.name,
                            key: v?.id,
                            value: v?.id
                        }
                    })
                    this.setState({ vendors }, () => {
                        if (this.props?.location?.state?.filterApplied?.billToVendorIds && this.props?.location?.state?.filterApplied?.billToVendorIds?.length > 0) {
                            this.setState({ selectedVendors })
                            // this.setState({ selectedVendors: this.props?.location?.state?.filterApplied?.billToVendorIds })
                        }
                    })
                }

            });
    }

    fetchLocations = (value) => {
        if (value) {
            let params = {};
            // let level = Constants.sessionUser.userLevel;    
            this.setState({ locations: [], locationLoading: true })

            params = { "filters": { "entityTypes": ["PARTNER"], "entityIds": [Constants.PARTNER_ID], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }


            const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
            apiUtils.apiRequest({
                url,
                method: "POST",
                data: params
            })
                .then(response => {
                    let { locations } = this.state;
                    locations = response?.data?.response?.locations;
                    locations = locations.map(v => {
                        return {
                            label: v?.name,
                            key: v?.id,
                            value: v?.id
                        }
                    })
                    this.setState({ locations, locationLoading: false })
                })
        }

    }



    removeFilteredValue = key => {
        const filterPayload = { ...this.state.filterPayload }
        delete filterPayload.filters[key]

        this.setState({
            filterPayload,
            isFiltered: (Object.keys(filterPayload).length !== 0)
        }, () => this.applyPagination({ ...this.state.filterPayload }, true))
    }

    clearTag = t => {
        const tags = this.state.tags.filter(tag => tag?.key !== t?.key);

        this.setState({ tags, tripData: [] });

        const newFilterPayload = { ...this.state.newFilterPayload };
        delete newFilterPayload[t?.key];
        let setinitialFilters = { ...this.state.initalFilters }
        if (_.hasIn(setinitialFilters, t?.key)) {
            setinitialFilters[t?.key].map(x => {

                if (x.value) {
                    x.value = !x.value;
                }


            })
        }

        if (t?.key === "billToVendorIds") {
            this.setState({
                selectedVendors: []
            })
        }

        if (t?.key === "locationIds") {
            this.setState({
                selectedLocations: [],
                locations: []
            })
        };


        // console.log("tags", tags)

        this.setState({
            newFilterPayload,
            initalFilters: setinitialFilters,
            // tags,
        }, () => this.applyPagination(this.state.newFilterPayload, true))
    }


    resetFilters = () => {
        this.setState({
            tripDateValue: [moment().subtract(30, 'd').startOf('day'), moment().endOf('now')],
            tags: [],
            selectedVendors: [],
            selectedLocations: [{
                'label': localStorage.getItem('currentLocationName'),
                'key': Constants.userLocationId
            }],
            locations: [Constants.userLocationId],
            newFilterPayload: {
                "tripCreationDateLowerLimit": moment().subtract(30, 'd').startOf('day').valueOf(),
                "tripCreationDateUpperLimit": moment().endOf('day').valueOf(),
                "pageNo": 1,
                "pageSize": 5
            },

            visible: false,
            initalFilters: {
                tripLoadTypes: [
                    { id: 0, name: "FTL", value: false, storedValue: "FTL" },
                    { id: 1, name: "PTL", value: false, storedValue: "PTL" },
                    { id: 2, name: "PickUp", value: false, storedValue: "PICKUP" }
                ],
                tripStatuses: [
                    { id: 0, name: "Open", value: false, storedValue: "OPEN" },
                    { id: 1, name: "Started", value: false, storedValue: "STARTED" },
                    { id: 2, name: "Completed", value: false, storedValue: "FINISHED" }
                ],
                placementTypes: [
                    { id: 0, name: "Adhoc", value: false, storedValue: "ADHOC" },
                    { id: 1, name: "Planned", value: false, storedValue: "PLANNED" },
                ],
                verificationStatuses: [
                    { id: 0, name: "Verified", value: false, storedValue: "VERIFIED" },
                    { id: 1, name: "UnVerified", value: false, storedValue: "UNVERIFIED" },
                ],
                paymentStatuses: [
                    { id: 0, name: "Complete", value: false, storedValue: "COMPLETE" },
                    { id: 1, name: "Partial", value: false, storedValue: "PARTIAL" },
                    { id: 2, name: "No Payment", value: false, storedValue: "NO_PAYMENT" },
                ],
            },
        }, () => this.applyPagination(this.state.filterPayload, true), this.fetchLocations(localStorage.getItem('currentLocationName')))
    }

    applyPagination = (filter, reset) => {

        this.setState({ loading: true });
        apiUtils.apiRequest({
            url: `/b2b/v1/trip-verification/fetch`,
            data: filter,
            method: "POST"
        })
            .then(response => {

                if (checkAPIHttpStatus(response?.data?.status)) {

                    let { tripData } = this.state;
                    tripData = response?.data?.response;
                    this.setState({ tripData, loading: false })
                } else {
                    this.setState({ loading: false, tripData: [] })
                }

            });
    }

    changePage = (page, pageSize) => {
        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                pageNo: page,
                pageSize: pageSize
            },
            tripData: []
        }, () => this.applyPagination(this.state.newFilterPayload, true))
    }

    onChangeVendors = vendor => {
        let keys = vendor?.map(val => val.key);
        let values = vendor?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "billToVendorIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        tags.push({
            "label": "Vendor",
            "key": "billToVendorIds",
            value: values
        })

        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                billToVendorIds: keys
            },
            selectedVendors: vendor,
            tags

        });
    }

    onChangeLocations = location => {
        let keys = location?.map(val => val.key);
        let values = location?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "locationIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        tags.push({
            "label": "Location",
            "key": "locationIds",
            value: values
        })
        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                locationIds: keys
            },
            selectedLocations: location,
            tags

        });

    }

    changeTrip = e => {
        const value = e.target.value;

        if (!value) {
            this.setState({ searchTripIds: value }, () => {
                this.searchTrip(value)
            });

        } else {
            this.setState({ searchTripIds: value })
        }

    }

    filterValues = (type, key) => {
        let { initalFilters } = this.state;
        initalFilters[type].map(fl => {
            if (fl?.value) {
                if (fl?.storedValue === 'FINISHED') key.push("COMPLETED")
                else key.push(fl?.storedValue);
            }
        });
        return key;

    }

    removeTag = (key) => {
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === key);
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.setState({ tags });
    }

    handleOk = () => {
        let { newFilterPayload, tags } = this.state;
        let paymentStatuses = [], placementTypes = [], tripLoadTypes = [], tripStatuses = [], verificationStatuses = [];
        tripLoadTypes = this.filterValues('tripLoadTypes', tripLoadTypes);
        paymentStatuses = this.filterValues('paymentStatuses', paymentStatuses);
        placementTypes = this.filterValues('placementTypes', placementTypes);
        tripStatuses = this.filterValues('tripStatuses', tripStatuses);
        verificationStatuses = this.filterValues('verificationStatuses', verificationStatuses);





        // let findIndex = tags.findIndex(t => t?.key === "tripLoadTypes");
        // if (findIndex >= 0) {
        //     tags.splice(findIndex, 1);
        // }
        this.removeTag("tripLoadTypes");
        this.removeTag("paymentStatuses");
        this.removeTag("tripStatuses");
        this.removeTag("placementTypes");
        this.removeTag("verificationStatuses");

        // let findIndex = tags.findIndex(t => t?.key === "tripStatuses");
        // if (findIndex >= 0) {
        //     tags.splice(findIndex, 1);
        // }

        // let findIndex = tags.findIndex(t => t?.key === "paymentStatuses");

        // if (findIndex >= 0) {
        //     tags.splice(findIndex, 1);
        // }


        if (tripLoadTypes.length > 0) {
            tags.push({
                "label": "Trip Type",
                "key": "tripLoadTypes",
                value: [...tripLoadTypes]
            });

            newFilterPayload = {
                ...newFilterPayload,
                tripLoadTypes
            };
        } else {
            delete newFilterPayload['tripLoadTypes'];
        }

        if (paymentStatuses.length > 0) {
            newFilterPayload = {
                ...newFilterPayload,
                paymentStatuses
            };

            tags.push({
                "label": "Payment",
                "key": "paymentStatuses",
                value: [...paymentStatuses]
            })
        } else {
            delete newFilterPayload['paymentStatuses'];
        }

        if (tripStatuses.length > 0) {
            const newTripStatuses = tripStatuses.map(tr => {
                if (tr === "COMPLETED") {
                    return "FINISHED"
                } else {
                    return tr;
                }
            });
            newFilterPayload = {
                ...newFilterPayload,
                tripStatuses: newTripStatuses
            }

            tags.push({
                "label": "Trip Status",
                "key": "tripStatuses",
                value: [...tripStatuses]
            })
        } else {
            delete newFilterPayload['tripStatuses'];
        }

        if (placementTypes.length > 0) {
            newFilterPayload = {
                ...newFilterPayload,
                placementTypes
            };
            // let findIndex = tags.findIndex(t => t?.key === "placementTypes");
            // if (findIndex >= 0) {
            //     tags.splice(findIndex, 1);
            // }
            tags.push({
                "label": "Placement",
                "key": "placementTypes",
                value: [...placementTypes]
            })
        } else {
            delete newFilterPayload['placementTypes'];
        }

        if (verificationStatuses.length > 0) {
            newFilterPayload = {
                ...newFilterPayload,
                verificationStatuses
            }
            // let findIndex = tags.findIndex(t => t?.key === "verificationStatuses");
            // if (findIndex >= 0) {
            //     tags.splice(findIndex, 1);
            // }
            tags.push({
                "label": "Verification",
                "key": "verificationStatuses",
                value: [...verificationStatuses]
            })
        } else {
            delete newFilterPayload['verificationStatuses'];
        }
        delete newFilterPayload['tripIds'];
        this.setState({ newFilterPayload, visible: false, tags, tripData: [], searchTripIds: undefined }, () => {
            this.applyPagination(this.state.newFilterPayload, true);
        })

    }


    filterValuesChnages = (data, id) => {
        let setinitialFilters = { ...this.state.initalFilters }
        // let  { initalFilters } =  this.state 
        setinitialFilters[data].map(x => {
            if (x.id === id) {
                x.value = !x.value;
            }
        })
        this.setState({ initalFilters: setinitialFilters })
    }



    searchTrip = e => {
        let { newFilterPayload } = this.state
        delete newFilterPayload['tripLoadTypes'];
        delete newFilterPayload['tripStatuses'];
        delete newFilterPayload['placementTypes'];
        delete newFilterPayload['paymentStatuses'];
        delete newFilterPayload['verificationStatuses'];
        delete newFilterPayload['tripCreationDateLowerLimit'];
        delete newFilterPayload['tripCreationDateUpperLimit'];
        if (this.state.searchTripIds) {
            if (this.state.searchTripIds.includes(",")) {
                newFilterPayload['tripIds'] = this.state.searchTripIds.split(",");
                newFilterPayload['pageNo'] = 1;
                newFilterPayload['pageSize'] = 5;
            } else {
                newFilterPayload['tripIds'] = [this.state.searchTripIds];
                newFilterPayload['pageNo'] = 1;
                newFilterPayload['pageSize'] = 5;
            }

        } else {
            newFilterPayload["tripCreationDateLowerLimit"] = moment().subtract(30, 'd').startOf('day').valueOf();
            newFilterPayload["tripCreationDateUpperLimit"] = moment().endOf('day').valueOf();
            delete newFilterPayload['tripIds'];
        }

        this.setState({
            newFilterPayload,
            tags: []
        }, () => this.applyPagination(this.state.newFilterPayload, true))
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    disableDate = current => {
        const startNewDate = moment(current).add(31, 'days');
        const newDateEnd = moment(current).subtract(31, 'days');
        // const endNewDate = moment(current).subtract(31, 'days');
        const startDate = this.state.tripDateValue[0];
        const dayEnd = moment(startDate).add(31, 'days');
        // const range = moment().range(startDate, dayEnd);
        // return  current.isBefore(startDate) && current > moment().endOf('day');
        // if (dayEnd.isAfter(moment().endOf('day'))) {
        //     console.log("i am here")            
        //     return !((current.isSameOrAfter(newDateEnd) || current.isSameOrBefore(startNewDate)) && current <= moment().endOf('day'));
        // } else {
        //     console.log("i am there")
        //     return !(startDate.isSameOrBefore(current) && dayEnd.isAfter(current) && current.isSameOrBefore(dayEnd));
        // }
        // console.log("current", startDate, this.state.tripDateValue)
        return current && current > moment().endOf('day');

    }

    onChangeTripDateFilter = values => {
        this.setState({
            tripDateValue: values,
            newFilterPayload: {

                ...this.state.newFilterPayload,
                tripCreationDateLowerLimit: moment(values[0]).startOf('day').valueOf(),
                tripCreationDateUpperLimit: moment(values[1]).endOf('day').valueOf()

            }
        });
    }

    disabledStartDate = startValue => {
        const { startDate } = this.state;
        if (!startValue || !startDate) {
            return false;
        }

        return startValue.valueOf() && startValue.valueOf() > moment().endOf('day');
        // return startValue.valueOf() > startDate.valueOf();
    };

    disabledEndDate = endValue => {
        if (this.state.startDate) {
            const dayStart = moment(this.state.startDate);
            const dayEnd = moment(this.state.startDate).add(31, 'days');
            if (dayEnd.isAfter(moment().endOf('day'))) {

                return !(dayStart.isSameOrBefore(endValue) && endValue <= moment().endOf('day') && endValue >= moment(this.state.startDate).endOf('day'));
            } else {

                return !(dayStart.isSameOrBefore(endValue) && dayEnd.isAfter(endValue) && endValue.isSameOrBefore(dayEnd));
            }


        } else {
            // return current && current > moment().endOf('day');
        }
    }

    render() {
        const { tripData, tags, startDate, endDate, isEndDateOpen } = this.state;
        
        return (
            <div className="v-trip-list">
                <Row gutter={[12, 12]}>
                    <Col>
                        <Card>
                            <Row gutter={[12, 12]}>
                                <Col span={18}>
                                    <Input
                                        onChange={this.changeTrip}
                                        allowClear
                                        value={this.state.searchTripIds}
                                        onPressEnter={this.searchTrip}
                                        prefix={<Icon type="search" />}
                                        placeholder="Search by Trip ID."
                                        size={'large'}
                                    />
                                </Col>
                                <Col span={5} offset={1}>
                                    <div style={{ display: 'flex', textAlign: 'end' }}>
                                        {/* <Filters
                                            popupWidth={"40vw"}
                                            filterPayload={this.getFilterPayload()}
                                            applyFilter={() => { this.applyPagination(this.state.newFilterPayload, true) }}
                                            resetFilters={this.resetFilters}
                                            className={'ant-btn-lg'}
                                        /> */}
                                        <div style={{ marginRight: 5 }}>
                                            <Badge dot={this.state.tags.length > 0}>
                                                <Button onClick={() => this.setState({ visible: true })} type="default" block size={"large"}>
                                                    <img src={FilterIcon} alt="filter" />
                                                Filters
                                            </Button>
                                            </Badge>

                                        </div>

                                        <Button type="primary" block size={'large'} onClick={this.searchTrip}>
                                            Search
                                        </Button>
                                    </div>
                                </Col>
                                <Col span={24}>

                                    {/* <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue('key')} />} readOnly value={'TEST'} /> */}
                                    {tags.map(t => {
                                        return !t.value?.join(", ") ? null : (

                                            <Tag style={{ marginBottom: 10 }} closable onClose={(e) => {
                                                e.preventDefault();
                                                this.clearTag(t);
                                            }}>
                                                {t.label} :

                                                <b>{" " + t.value?.join(", ")}</b>

                                                {/* {
                                                _.isArray(t?.value) ?
                                                    t?.value?.map((val, i) => (
                                                        <b>{ val} {i < t?.value?.length && i >= 0 && ", "}</b>
                                                    ))
                                                    :
                                                    <b>{t?.value}
                                                    </b>
                                            } */}

                                            </Tag>

                                        )
                                    }
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <div style={{ textAlign: 'center' }}>

                        <Spin spinning={this.state.loading} />

                    </div>
                    {
                        tripData && tripData?.trips?.length > 0 ?
                            <>
                                {tripData?.trips?.map((trip, index) =>
                                    <Col key={index}>
                                        <TripDataCard
                                            trip={trip}
                                            key={index}
                                            filters={this.state.newFilterPayload}
                                            tags={this.state.tags}
                                            initalFilters={this.state.initalFilters}
                                        />

                                    </Col>
                                )}
                                <Col style={{ marginTop: 10, bottom: 10 }}>
                                    <Pagination
                                        onChange={this.changePage}
                                        total={tripData?.count}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        pageSize={tripData?.pageSize}
                                        current={tripData?.pageNo}
                                    />
                                </Col>
                            </>
                            :
                            <>
                                {!this.state.loading &&
                                    <Empty />
                                }
                            </>
                    }


                </Row>

                <Modal
                    title="Filters"
                    visible={this.state.visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    maskClosable={false}
                    style={{
                        top: 20
                    }}
                    footer={[
                        <Button key="back" onClick={this.resetFilters}>
                            Reset
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            Apply
                        </Button>,
                    ]}
                    width={720}
                >
                    <div className="verification-status-filters">
                        <Row>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Trip Date</div>
                                {/* <div className="boxfilterTitles">
                                    <DatePickerInput
                                        type="range"
                                        disabledDate={this.disableDate}
                                        onChange={this.onChangeTripDateFilter}
                                        value={this.state.tripDateValue}
                                        format={"YYYY/MM/DD"}
                                    />
                                </div> */}
                                <Row gutter={12}>

                                    <Col span={12} style={{ bottom: 5 }}>
                                        <span className="form-label-text">From </span>
                                        <DatePickerInput
                                            disabledDate={this.disabledStartDate}
                                            type="date"
                                            format="YYYY-MM-DD"
                                            value={startDate}
                                            placeholder="From"
                                            style={{ width: '100%' }}
                                            // onOk={this.onStartDateChange}                                            
                                            onChange={value => {
                                                if (value) {
                                                    this.setState({
                                                        startDate: value,
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            tripCreationDateLowerLimit: value.valueOf(),
                                                            tripCreationDateUpperLimit: endDate.valueOf()
                                                        }
                                                    })
                                                } else {
                                                    this.setState({
                                                        startDate: moment().subtract(30, 'd').startOf('day'),
                                                        endDate: moment().endOf(),
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            "tripCreationDateLowerLimit": moment().subtract(30, 'd').startOf('day').valueOf(),
                                                            "tripCreationDateLowerLimit": moment().endOf('day').valueOf(),
                                                        }
                                                    })
                                                }

                                            }}
                                            onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                                        // onOpenChange={this.handleStartOpenChange}
                                        />
                                    </Col>

                                    <Col span={12} style={{ bottom: 5 }}>
                                        <span className="form-label-text">To </span>
                                        <DatePickerInput
                                            type="date"
                                            disabledDate={this.disabledEndDate}
                                            format="YYYY-MM-DD"
                                            value={endDate}
                                            placeholder="To"
                                            style={{ width: '100%' }}
                                            // onOk={this.onEndDateChange}
                                            open={isEndDateOpen}
                                            onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                                            onChange={value => {
                                                if (value) {
                                                    this.setState({
                                                        endDate: value,
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            tripCreationDateLowerLimit: startDate.valueOf(),
                                                            tripCreationDateUpperLimit: value.valueOf()
                                                        }
                                                    })
                                                } else {
                                                    this.setState({
                                                        startDate: moment().subtract(30, 'd').startOf('day'),
                                                        endDate: moment().endOf(),
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            "tripCreationDateLowerLimit": moment().subtract(30, 'd').startOf('day').valueOf(),
                                                            "tripCreationDateLowerLimit": moment().endOf('day').valueOf(),
                                                        }
                                                    })
                                                }

                                            }}
                                        // onOpenChange={this.handleStartOpenChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Trip Type</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.tripLoadTypes.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("tripLoadTypes", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Trip Status</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.tripStatuses.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("tripStatuses", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Placement</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.placementTypes.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("placementTypes", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Payment</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.paymentStatuses.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("paymentStatuses", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Verification</div>
                                <div className="boxfilterTitles">
                                    {this.state?.initalFilters?.verificationStatuses?.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("verificationStatuses", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Vendor</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedVendors}
                                        showSearch
                                        labelInValue
                                        placeholder={"Select Vendors"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onChangeVendors}
                                    >
                                        {this.state?.vendors?.map((vendor, key) => (
                                            <Option key={vendor.key ? vendor.key : vendor.value} value={vendor.value}>
                                                {vendor.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Location</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedLocations}
                                        onSearch={this.fetchLocations}
                                        showSearch
                                        loading={this.state.locationLoading}
                                        labelInValue
                                        placeholder={"Select Locations"}
                                        filterOption={(input, option) =>
                                            option.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onChangeLocations}
                                    >
                                        {this.state?.locations?.map((location, key) => (
                                            <Option key={location.key ? location.key : location.value} value={location.value}>
                                                {location.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>

            </div >
        )
    }
}

export default VerificationTrips

export const TRIP_TYPES = [
    {
        label: 'FTL',
        value: 'FTL'
    },
    {
        label: 'PTL',
        value: 'PTL'
    },
    {
        label: 'PickUp',
        value: 'PICKUP'
    },
]

export const TRIP_STATUS = [
    {
        label: 'Open',
        value: 'OPEN'
    },
    {
        label: 'Started',
        value: 'STARTED'
    },
    {
        label: 'Finished',
        value: 'FINISHED'
    },
    {
        label: 'Deleted',
        value: 'DELETED'
    },
    {
        label: 'Cancelled',
        value: 'CANCELLED'
    },
    {
        label: 'Completed',
        value: 'COMPLETED'
    },
]

export const PLACEMENT = [
    {
        label: 'Adhoc',
        value: 'ADHOC'
    },
    {
        label: 'Planned',
        value: 'PLANNED'
    },
]

export const VERIFICATIONS = [
    {
        label: 'Verified',
        value: 'VERIFIED'
    },
    {
        label: 'UnVerified',
        value: 'UNVERIFIED'
    },
]

export const PAYMENT = [
    {
        label: 'No Payment',
        value: 'NO_PAYMENT'
    },
    {
        label: 'Partial',
        value: 'PARTIAL'
    },
    {
        label: 'Complete',
        value: 'COMPLETE'
    },
];

export const setinitialFilters = {
    tripLoadTypes: [
        { id: 0, name: "FTL", value: false, storedValue: "FTL" },
        { id: 1, name: "PTL", value: false, storedValue: "PTL" },
        { id: 2, name: "PickUp", value: false, storedValue: "PICKUP" }
    ],
    tripStatuses: [
        { id: 0, name: "Open", value: false, storedValue: "OPEN" },
        { id: 1, name: "Started", value: false, storedValue: "STARTED" },
        { id: 2, name: "Completed", value: false, storedValue: "FINISHED" }
    ],
    placementTypes: [
        { id: 0, name: "Adhoc", value: false, storedValue: "ADHOC" },
        { id: 1, name: "Planned", value: false, storedValue: "PLANNED" },
    ],
    verificationStatuses: [
        { id: 0, name: "Verified", value: false, storedValue: "VERIFIED" },
        { id: 1, name: "UnVerified", value: false, storedValue: "UNVERIFIED" },
    ],
    paymentStatuses: [
        { id: 0, name: "Complete", value: false, storedValue: "COMPLETE" },
        { id: 1, name: "Partial", value: false, storedValue: "PARTIAL" },
        { id: 2, name: "No Payment", value: false, storedValue: "NO_PAYMENT" },
    ],
}