
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class SLARulesService {

    getSLARulesfetch(data) {
        const url = `/b2b/v1/customerPromiseTime/rules/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data
        });
    }

    
}