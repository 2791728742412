import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../../../../ui/formComponent/form";
import { fetchDetentionCharges, postLabourDetentionCharges } from "../lanes/lane.services";
import detentionSchema from "./detention.schema";

export default function DetentionCharges (props) {

    var formRef = useRef();

    const [ formData, SetFormData ] = useState({});


    useEffect(() => {
        if (props.contractId && props.laneMappingID) {
            fetchDetentionCharges(props.contractId, props.laneMappingID)
            .then(response => {
                SetFormData({
                    ...response,
                    penalty       : response.metaInfoBO.detentionChargeBO.penalty.toString(),
                    detentionSLA  : response.metaInfoBO.detentionChargeBO.detentionDay + "," + response.metaInfoBO.detentionChargeBO.detentionTime
                })
            })
            .catch(_ => { })
        }
    }, [ props.laneMappingID, props.contractId ])

    const saveDetentionCharges = data => {
        SetFormData(data);
        props.generateLaneMappingID(laneMappingBO => {
            if (!laneMappingBO) return;
            var laneEntity = laneMappingBO.find(l => l.index === props.index);
            const postObj = {
                laneMappingBO:{
                    id           : laneEntity.id,
                    contractId   : props.contractId,
                    entityBO:{
                        labourDetentionChargesBO:{
                            chargesBO:[
                                {
                                    id          : data.id,
                                    action      : data.id ? "EDIT" : "ADD",
                                    chargeType  : "DETENTION_CHARGE",
                                    metaInfoBO:{
                                        detentionChargeBO:{
                                            detentionDay    : data.detentionSLA.split(",")[0] !== "" ? parseInt(data.detentionSLA.split(",")[0]) : 0,
                                            detentionTime   : parseInt(data.detentionSLA.split(",")[1]),
                                            penalty         : data.penalty
                                        }
                                    }
                                }
                            ]
                        }
                    }
                }
            }
            postLabourDetentionCharges(postObj)
            .then(_ => {
                props.onCancel();
                props.onLaneDataSaveSuccess("Detention Charges")
            })
            .catch(_ => { })
        })
    }

    return (
        <div>
            <FormWrapper
                {...detentionSchema}
                formData={formData}
                onSubmit={saveDetentionCharges}
                readonly={props.readonly}
                showColumnSplitter={false}
                loading={props.loading}
                hideSubmit={true}
                bordered={true}
                ref={formRef}
            />
            {
                !props.readonly &&
                <div className="flex-box flex-gap-m align-items-center justify-content-end popup-buttons-divider contract-management">
                    <Button className="secondary-btn" onClick={() => {
                            Modal.confirm({
                                title   : "Detention Charges",
                                content : "Are you sure to cancel?",
                                onOk    : _ => {
                                    Modal.destroyAll();
                                    props.onCancel();
                                }
                            })
                        }}> Cancel </Button>
                    <Button className="lsn-btn-primary" onClick={() => {
                        formRef.current.formReference?.current?.submit()
                    }}> Save </Button>
                </div>
            }
        </div>
    )
}