import React, { PureComponent } from 'react'
import { canAllow, windowRedirect } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import CompletedTrips from './completed/completed-trips';
import PendingTrips from "./pending/pending-trips";
import Trip from "../inventory/trip/trip";
import SLAReasons from "../sla-reasons/index";


class TripModule extends PureComponent {

    constructor(props) {
        super(props)
    }
    state = {
        tripPermissions: {
            tripRead: canAllow(permissionTags.trips.trips.read),
            pendingRead: canAllow(permissionTags.trips.pending.read),
            completeRead: canAllow(permissionTags.trips.completed.read),
            slaRead: canAllow(permissionTags.trips.sla.read),
        },
    }
    render() {

        if(this.state.tripPermissions.tripRead) {
            return <Trip {...this.props} />
        } else if(this.state.tripPermissions.pendingRead) {
            return <PendingTrips {...this.props} />
        } else if(this.state.tripPermissions.completeRead) {
            return <CompletedTrips {...this.props} />
        } else if(this.state.tripPermissions.slaRead) {
            return <SLAReasons {...this.props} />          
        }

        

        return (
            <></>
        )
    }
}

export default TripModule;