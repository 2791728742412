import React, { useState } from 'react';
import CustomerZoneDetails from './zone.form';
import ZoneList from './zone.list';
import { permissionTags } from '../../../../configs/permissionTags';
import { canAllow } from '../../../../common/utils/utils';


function Zone(props) {
    const { customerId, setZoneStepper } = props;
    const [zoneDetails, SetZoneDetails] = useState(false);
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false)
    const [showModal, setModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [createAnother, setCreateAnother] = useState(false);
    const zonePermissions = {
        zoneCreate: canAllow(permissionTags.contract.zones.create),
        zoneUpdate: canAllow(permissionTags.contract.zones.update),


    }
    return (
        <>
            <ZoneList
                customerId={customerId}
                showZoneDetails={zoneDetails => SetZoneDetails(zoneDetails)}
                setReadOnly={setReadOnly}
                setModal={setModal}
                refresh={refresh}
                readOnly={readOnly}
                setZoneStepper={setZoneStepper}
                zonePermissions={zonePermissions}
            />
            {
                // zoneDetails && zoneDetails.hasOwnProperty("id") &&
                showModal &&
                <CustomerZoneDetails
                    id={zoneDetails?.id}
                    name={zoneDetails?.name}
                    customer={props.customer}
                    readOnly={readOnly}
                    SetZoneDetails={SetZoneDetails}
                    showModal={showModal}
                    setModal={setModal}
                    setIndexReadOnly={setReadOnly}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    zonePermissions={zonePermissions}
                    createAnother={createAnother}
                    setCreateAnother={setCreateAnother}
                    openHelpLink={props.openHelpLink}
                />
            }
        </>
    )
}

export default Zone;