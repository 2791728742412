import { Button, Icon, Modal } from "antd";
import React, { useState } from "react";
import LaneDetails from "../details/lane.details";

export default function CreateLanePopup(props) {

    const { openModal, setModal } = props;
    const [saveLanes, SetSaveLanes] = useState(false);

    const cancelLaneCreation = _ => {
        Modal.confirm({
            title  : "Are you sure to cancel lane creation?",
            onOk   : _ => {
                Modal.destroyAll();
                setModal(false)
            },
            okText : "Yes",
            cancelText : "No"
        })
    }
    return (
        <Modal centered={true} width={900} closable={false}
         title={
            <div className="flex-box align-items-center flex-gap-l">
                <Icon onClick={cancelLaneCreation}
                 style={{ marginRight: 8 }} type="arrow-left" />
                <div> Create Lane </div>
            </div>
         }
         footer={(
            <div className="flex-box flex-gap-m align-items-center justify-content-end">
                <Button className="secondary-btn" onClick={cancelLaneCreation}> Cancel </Button>
                <Button className="lsn-btn-primary" onClick={_ => {
                    SetSaveLanes(true);
                    setTimeout(() => {
                        SetSaveLanes(false);
                    }, 500);
                }}> Create Lane </Button>
            </div>
         )} visible={openModal} >
            <LaneDetails readonly={false} customerId={props.customer?.id}
             saveLanes={saveLanes}
             laneOnly={true} noContractLane={props.noContractLane}
             lanesSaved={(laneMappingBOs, lanesData) => {
                if (laneMappingBOs) {
                    props.onLaneSave(lanesData);
                }
             }} />
        </Modal>
    )
}