import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { notifyApiError } from '../../../common/utils/utils';


function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}


export const getTripPendingList = (params) => dispatch => {
    dispatch(tripListBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/consignmentpod/fetch/lite-trips`,
        data: params
    })
        .then(response => { 
            // checkHttpStatus(response.data ?.status)           
            dispatch(tripListSuccess(response.data?.viewResponse))
        })
        .catch(error => dispatch(tripListFailure(error)));
}

export const getPendingPODUpload = (params) => dispatch => {
    dispatch(pendingPODUploadBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/consignmentpod/lite/update/podImg`,
        data: params
    })
        .then(response => {            
            dispatch(pendingPODUploadSuccess(response.data?.response?.responses))
        })
        .catch(error => dispatch(pendingPODFailure(error)));
}


function tripListBegin() {
    return {
        type: CONSTANTS.FETCH_PENDING_LIST_BEGIN,
        payload: null
    }
}

function tripListSuccess(response) {    
    return {
        type: CONSTANTS.FETCH_PENDING_LIST_SUCCESS,
        payload: response
    }
}

function tripListFailure(response) {
    return {
        type: CONSTANTS.FETCH_PENDING_LIST_SUCCESS,
        payload: response
    }
}

function pendingPODUploadBegin() {
    return {
        type: CONSTANTS.GET_UPLOAD_PENDING_POD_BEGIN,
        payload: null
    }
}

function pendingPODUploadSuccess(response) {    
    return {
        type: CONSTANTS.GET_UPLOAD_PENDING_POD_SUCCESS,
        payload: response
    }
}

function pendingPODFailure(response) {
    return {
        type: CONSTANTS.GET_UPLOAD_PENDING_POD_FAILURE,
        payload: response
    }
}
