import ScanService from "../../../services/inbound/scan.service";
import * as _Constants from './constants'
import { notifyApiError } from "../../../common/utils/utils";

const scanService = new ScanService

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchExpectedConsignments = (payload, isReset) => dispatch => {
    dispatch(fetchExpectedConsignmentsBegin());
    scanService.fetchExpectedConsignments(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchExpectedConsignmentsSuccess(res ?.data ?.response || res ?.data ?.viewResponse, isReset));
        } else {
            dispatch(fetchExpectedConsignmentsFailure(res ?.data ?.response));
        }
    });
};

function fetchExpectedConsignmentsBegin() {
    return {
        type: _Constants.FETCH_EXPECTED_CONSIGMENTS_BEGIN
    };
}

function fetchExpectedConsignmentsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_EXPECTED_CONSIGMENTS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchExpectedConsignmentsFailure(error) {
    return {
        type: _Constants.FETCH_EXPECTED_CONSIGMENTS_FAILURE,
        payload: error
    };
}

export const updateConsignment = (payload) => dispatch => {
    dispatch(updateConsigmnetBegin());
    scanService.consignmentUpdate(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(updateConsigmnetSuccess(res ?.data ?.response));
        } else {
            dispatch(updateConsigmnetFailure(res ?.data ?.response));
        }
    });
};

function updateConsigmnetBegin() {
    return {
        type: _Constants.UPDATE_CONSIGMENT_BEGIN
    };
}

function updateConsigmnetSuccess(payload) {
    return {
        type: _Constants.UPDATE_CONSIGMENT_SUCCESS,
        payload: payload,
    };
}

function updateConsigmnetFailure(error) {
    return {
        type: _Constants.UPDATE_CONSIGMENT_FAILURE,
        payload: error
    };
}
