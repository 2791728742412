import React, { Component } from "react";
import './image-slider.scss'
import { Icon, Button, Empty } from "antd";
import { NAV_DIRECTION } from "../details-navigation"
import addImage from '../../../../assets/img/icons/add_image.svg';
import { UPLOAD_BUTTON_TYPE } from "../../../../common/utils/constants";
import FileUpload from "../../../ui/file-upload/file.upload";
import Lightbox from 'react-image-lightbox-rotate-fixed';
import Magnifier from "../../../ui/magnifier/magnifier";

/*
MANDATORY PARAMS

imageArray : Array of strings of image URLs / JSON objects

galleryId  : id for the image slider - to make sure multiple slider in same page will not be bugged

imageKey   : Mandatory in case the imageArray is an array of JSON objects to mention the attribute to key to pick up the image URL

ADDITIONAL PARAMS

acceptedFiles : in case uploading new image is an option.

descriptionComponent : to show any description below the images as component

descriptionArray : Array of strings matched to images by index to show below each image.

deleteEnabled : To enable option of removing an image. Default false

uploadEnabled : To enable option of adding a new image. Default false

onUploadSuccess : callback function to call on succesful addition of image - you get the new image array and new image url as params

onDeleteImage : delete image callback. The remove logic should be handled by the user as it may vary based on use case (can be revisited)

hideThumbnailViewer : false
*/

class ImageGallery extends Component{

    constructor(props){
        super(props);
        this.state = {
            leftDisabled:true,
            rightDisabled:false,
            acceptedFiles: props.acceptedFiles ? props.acceptedFiles : [".PNG", ".png", ".jpg", ".JPEG", ".jpeg", ".JPG"],
            imageArray: props.imageArray,
            descriptionArray: false,
            rotation:0,
            scale: 1,
            fullScreenPreview: false,
            fullScreenImage: "",
            currentIndex: 0
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (typeof nextProps.goToIndex == 'number' && nextProps.goToIndex != -1 && nextProps.goToIndex <= nextProps.imageArray.length-1 &&
            document.getElementById("image-gallery-" + nextProps.galleryId) != null) {
            document.getElementById("image-gallery-" + nextProps.galleryId).style.left = (nextProps.goToIndex * -100) + "%";
            return {
                ...prevState,
                scale: 1,
                rotation:0,
                currentIndex: nextProps.goToIndex,
                imageArray: nextProps.imageArray,
                leftDisabled:nextProps.imageArray?.length <= 1,
                rightDisabled:nextProps.imageArray?.length <= 1
            }
        }
        return {
            ...prevState,
            imageArray: nextProps.imageArray,
            leftDisabled:nextProps.imageArray?.length <= 1,
            rightDisabled:nextProps.imageArray?.length <= 1
        }
    }

    slideImage = direction => {
        if(direction == NAV_DIRECTION.LEFT ){
            if(this.state.currentIndex != 0){
                this.goToImage(this.state.currentIndex - 1);
            }
        }
        else{
            if(this.state.currentIndex < this.props.imageArray.length-1){
                this.goToImage(this.state.currentIndex + 1);
            }
        }
    }

    goToImage = (index) => {
        if(this.state.currentIndex == index) return;

        let leftInt = -100 * index;
        this.setState({
            currentIndex: index,
            leftDisabled: this.state.currentIndex <= 0,
            rightDisabled: this.state.currentIndex >= this.props.imageArray.length-1,
            rotation: 0,
            scale: 1
        });
        document.getElementById("image-gallery-" + this.props.galleryId).style.left = leftInt + "%";
    }

    uploadImage = newImageUrl => {
        this.setState(currentState => {
            currentState.imageArray.push(newImageUrl);
            return currentState;
        }, () => {
            this.goToImage(this.state.imageArray.length - 1)
            if(typeof this.props.onUploadSuccess == 'function'){
                this.props.onUploadSuccess(this.state.imageArray, newImageUrl);
            }
        });
    }

    deleteImage = index => {
        this.props.onDeleteImage(index);
    }
    
    rotateImage = e => {
        if(this.props.imageArray.length <= 0) return;
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = 0;
        }
        let imageDOM = document.getElementById(this.props.galleryId + '_image_' + this.state.currentIndex);
        let scale = 1;
        if (newRotation % 180 != 0){
            scale = imageDOM.offsetHeight/ imageDOM.offsetWidth;
        }
        this.setState({
            rotation: newRotation,
            scale: scale
        });
    }

    expandImage = e => {
        if(this.props.imageArray.length <= 0) return;
        let fullScreenImage = "";
        if(this.props.imageKey){
            fullScreenImage = this.props.imageArray[this.state.currentIndex][this.props.imageKey];
        }
        else{
            fullScreenImage = this.props.imageArray[this.state.currentIndex];
        }
        this.setState({
            fullScreenPreview: true,
            fullScreenImage: fullScreenImage
        })
    }

    render() {
        
        const height = this.props.hasOwnProperty('height') ? this.props.height : 500;
        const width = this.props.hasOwnProperty('width') ? this.props.width : 'auto';
        const thumbnailWidth = this.props.hasOwnProperty('thumbnailWidth') ? this.props.thumbnailWidth : 'auto';
        const thumbnailHeight = this.props.hasOwnProperty('thumbnailHeight') ? this.props.thumbnailHeight : '50px';
        if(this.state.currentIndex >= this.props.imageArray.length){
            this.goToImage(0);
        }
        return (
            <div className='image-gallery'>
                <div className="text-align-center spacer-m"> 
                    <label className="grey-text text-bold font-size-m-3">{this.props.imageArray.length>0 ? this.state.currentIndex + 1 : 0}</label> of <label className="text-bold font-size-m-3">{this.props.imageArray.length}</label>
                </div>
                <div className='gallery-wrapper border-radius-s'>
                    <Button disabled={this.state.leftDisabled} className="nav-arrows border-radius-m text-align-center left-nav cursor-pointer" onClick={() => this.slideImage(NAV_DIRECTION.LEFT)}>
                        <Icon type="left"/>
                    </Button>
                    <div className='gallery-slider' id={"image-gallery-" + this.props.galleryId}>
                        {
                            this.props.imageArray.length > 0 ?
                            this.props.imageArray.map((imageElem, index) => {
                                return (
                                    <div key={index} className='image-wrapper text-align-center dark-grey-bg'>
                                        {
                                            this.props.imageKey ? 
                                            <img src={imageElem[this.props.imageKey]}
                                             width={width} height={height}
                                             id={this.props.galleryId + '_image_' + index}
                                             style={{transform:`rotate(${this.state.rotation}deg) scale(${this.state.scale})`}}/> :
                                            
                                            <img src={imageElem}
                                             height={height} width={width}
                                             id={this.props.galleryId + '_image_' + index}
                                             style={{transform:`rotate(${this.state.rotation}deg) scale(${this.state.scale})`}}/>
                                        }
                                        <Magnifier imageId={this.props.galleryId + '_image_' + index}/>
                                    </div>
                                )
                            }) :
                            <Empty
                                style={{
                                    height: 400,
                                    width: "auto",
                                    objectFit: "fill"
                                }}
                            />
                        }
                    </div>
                    <Button disabled={this.state.rightDisabled} className="nav-arrows border-radius-m text-align-center right-nav cursor-pointer" onClick={() => this.slideImage(NAV_DIRECTION.RIGHT)}>
                        <Icon type="right"/>
                    </Button>
                    <div className='image-action-tools flex-column flex-gap-m border-radius-m spacer-vs'>
                        <button className='cursor-pointer' onClick={this.rotateImage} disabled={this.props.imageArray.length <= 0}>
                            <Icon type='shake'/>
                        </button>
                        <button className='cursor-pointer' onClick={this.expandImage} disabled={this.props.imageArray.length <= 0}>
                            <Icon type='fullscreen'/>
                        </button>
                    </div>
                </div>
                {
                    this.props.descriptionComponent && 
                    (this.props.descriptionComponent)
                }
                {
                    this.state.descriptionArray && 
                    this.state.descriptionArray.map((desc, index) => {
                        <div className="font-size-m-2 text-align-center" key={index}>{desc}</div>
                    })
                }
                {
                    this.props.hideThumbnailViewer && this.props.deleteEnabled && this.props.imageArray?.length > 0 &&
                    <div className="spacer-m" style={{textAlign:'right'}}>
                        <Button type="danger" onClick={() => this.deleteImage(this.state.currentIndex)}>
                            <Icon type='delete'/> <span className="spacer-hs">Delete</span>
                        </Button>
                    </div>
                }
                {

                    (this.props.imageArray.length > 0 || this.props.uploadEnabled) && !this.props.hideThumbnailViewer &&
                    (
                        <div className="thumbnail-panel border-radius-m border-grey dark-grey-bg text-align-center flex-box flex-gap-xl justify-content-center align-items-center spacer-vl">
                            {
                                this.props.imageArray.length > 0 && 
                                <>
                                    <Button disabled={this.state.leftDisabled} className="nav-arrows border-radius-m text-align-center cursor-pointer" onClick={() => this.slideImage(NAV_DIRECTION.LEFT)}>
                                        <Icon type="left"/>
                                    </Button>
                                    {
                                        this.props.imageArray.map((imgSrc, index) => {
                                            return (
                                                <div key={index}
                                                    className={this.state.currentIndex == index ? 'thumbnail-image selected-image' : 'thumbnail-image'}
                                                    onClick={() => this.goToImage(index)}>
                                                    <img src={imgSrc} width={thumbnailWidth} height={thumbnailHeight} style={{height:thumbnailHeight}} className="border-radius-s"/>
                                                    {
                                                        this.props.deleteEnabled && this.state.currentIndex == index &&
                                                        <Icon type='delete' className='delete-icon' onClick={() => this.deleteImage(index)}/>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                            {
                                this.props.uploadEnabled && 
                                <FileUpload
                                    showToastMessage={false} showLoader={true}
                                    fileSizeLimit={5} fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                    uploadButtonHtml={
                                        <div className='thumbnail-image'>
                                            <img className="cursor-pointer" src={addImage} alt="Add Image"  width='25px' height='auto'/>
                                        </div>
                                    }
                                    directUpload={true} disableUploadButton={true} clearFileAfterUpload={true}
                                    // onClick={this.uploadImage}
                                    onUploadFinish={e => {
                                            this.uploadImage(e);
                                        }
                                    }
                                    beforeUpload = {e => {
                                        if (typeof this.props.beforeUpload === 'function') {
                                          this.props.beforeUpload(e);
                                        }
                                    }}
                                    path="verification" acceptTypes={this.state.acceptedFiles}
                                    objKey="pod" multiple={false}
                                />

                            }
                            {
                                this.props.imageArray.length > 0 && 
                                <Button disabled={this.state.rightDisabled} className="nav-arrows border-radius-m text-align-center cursor-pointer" onClick={() => this.slideImage(NAV_DIRECTION.RIGHT)}>
                                    <Icon type="right"/>
                                </Button>
                            }
                        </div>
                    )
                }
                {
                    this.state.fullScreenPreview && 
                    <Lightbox
                        mainSrc={this.state.fullScreenImage}
                        onCloseRequest={() => this.setState({
                            fullScreenPreview: false,
                            fullScreenImage: ""
                        })}
                    />
                }
            </div>
        )
    }
}

export default ImageGallery;