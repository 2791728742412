import React, { Component } from 'react';
import {
    Card, Row, Col, Input, Icon, Form, Radio, Result, Alert,
    Button, Table, Spin, Tooltip, Modal, DatePicker, Select, Divider
}
    from 'antd';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { Mixpanel } from '../../../utils/mixpanel';
import { permissionTags } from '../../../configs/permissionTags';
import { canAllow, notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import * as apiUtils from '../../../common/utils/apiGateway';
import "./Vendor.scss";
import * as vendorActions from './action';
import _ from 'lodash';
import { Constants } from '../../../common/utils/constants';

import BLOCK from '../../../assets/img/icons/block.png';
import UNBLOCK from '../../../assets/img/icons/unblock.png';


class VendorCardTitle extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col md={8}>
                        <h2>{this.props.title}</h2>
                    </Col>
                    <Col md={16}>
                        {this.props.children}
                    </Col>
                </Row>
            </>
        )
    }
}

class VendorList extends Component {

    state = {
        vendorPermission: {
            unblock: canAllow(permissionTags.settings.vendor.unblock),
        },
        unblockModal: false,
        vendorIsBlocked: false,
        page: 1,
        selectedFilter: 'ALL',
        unblockVendor: {},
        blockedReason: '',
        vendorSubmitIsBlocked: false,
        unblockType: "unblock",
        tempDate: new moment().endOf('day').milliseconds(0),
        vendors: [],
        vendorBlockAction: '',
        loading: false,
        applyFilter: false,
        searchData: ''
    }

    constructor(props) {
        super(props);
        this.searchHandler = this.searchHandler.bind(this);

        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        Mixpanel.identify(loggedPartnerId);
        Mixpanel.people.set({
            $first_name: user?.name,
            $email: user?.email,
            $phone_number: user?.number,
            $partner_id: user?.partner?.id,
            $partner_name: user?.partner?.name,
        });
        Mixpanel.track("Vendor Page Initiated", { 'ERPVendorIntiatted': moment().valueOf() })


    }



    componentDidMount() {

        if (this.props.location?.state) {
            const { state } = this.props.location;
            this.props.getVendors();
            // console.log("filters", filters)
            this.setState({
                selectedFilter: state?.filter,
                page: state?.page,
                unblockVendor: state?.vendor
            }, () => {
                if (state?.filter)
                    this.changeFilter(state?.filter)
                if (state?.vendor)
                    this.unblockVendor(state?.vendor)
                // const { state } = this.props.location;
                // if (state && state.vendor) {
                //     // this.duplicateSurvey(state.copySurveyId);
                //     const stateCopy = { ...state };
                //     delete stateCopy.vendor;
                //     delete stateCopy.filter;
                //     delete stateCopy.page;
                //     this.props.history.replace({ state: stateCopy });
                // }
            })
        }
        if (this.props.location.state?.isReset) {
            this.setState({ vendors: [] }, () => {
                this.props.getVendors();
            })
        } else {
            this.props.getVendors();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.unblockVendor !== prevProps.unblockVendor) {


            this.cancelUnblockModal();
            this.setState({ page: 1 }, () => {
                // this.props.reloadVendor()
                this.reloadVendor();
            })

        }
        console.log("vendors,", this.state.vendors, this.props.vendorsList)
        if (this.state.vendors.length <= 0 && (this.props.vendorsList && this.props.vendorsList.length > 0) && !this.state.applyFilter) {
            this.setState({ vendors: this.props.vendorsList })
        }
    }

    goToVendorCreate() {
        Mixpanel.track("Vendor Page Create Clicked", { 'ERPVendorCreateClick': moment().valueOf() })

        this.props.history.push("/appv2/settings/form/vendor");
    }

    changeSearch = e => {
        this.setState({ searchData: e.target.value.trim() })
    }

    searchHandler(event) {
        Mixpanel.track("Vendor Page Search", { 'ERPVendorSearchVendor': moment().valueOf() })

        let searcjQery = event.toLowerCase();
        this.setState({ searchData: searcjQery })
        // this.props.searchVendors(searcjQery);
        this.searchVendors(searcjQery);
    }


    searchVendors(searchQuery) {
        const { vendorsList } = { ...this.props };
        let vendors = vendorsList.filter((el) => {
            let searchValue = el.name.toLowerCase();
            return searchValue.indexOf(searchQuery) !== -1;
        });

        this.setState({ vendors, selectedFilter: 'ALL' });
    }


    filterVendors = (searchQuery) => {
        this.setState({ applyFilter: true })
        // if (isBlocked && (isEligibleBlock == false || (tempdate != null && tempdate >= current date)) {
        //     unblock
        // }
        // if (isBlocked && !(isEligibleBlock == false || (tempdate != null && tempdate >= current date)) {
        //     block
        // }



        let { vendorsList } = { ...this.props };
        if (_.isUndefined(vendorsList) && this.props.location?.state) {
            vendorsList = this.props.location?.state?.vendorsList
        }
        switch (searchQuery) {
            case 'ALL':
                this.setState({ vendors: vendorsList });
                break;
            case 'BLOCKED':
                // isBlocked && isEligibleToBlock) || (isBlocked && tempDate != null && tempDate < currentDate
                let blockedVendors = vendorsList.filter(el => {
                    return el.isBlocked && !(!el.isEligibleToBlock || (el.tempUnblockDate && el.tempUnblockDate >= moment().valueOf()))
                });

                this.setState({ vendors: [...blockedVendors] });
                break;
            case 'UNBLOCKED':
                // isBlocked && !isEligibleToBlock) || (isBlocked && tempDate != null && tempDate >= currentDate
                let unBlockedVendors = vendorsList.filter((el) => {
                    return el.isBlocked && (!el.isEligibleToBlock || (el.tempUnblockDate && el.tempUnblockDate >= moment().valueOf()))
                });

                this.setState({ vendors: unBlockedVendors });
                break;
            default:
                this.setState({ vendors: vendorsList });
                break;

        }

    }

    reloadVendor = () => {
        this.props.setInitialState();
        this.setState({ vendors: [], selectedFilter: 'ALL', applyFilter: false, searchData: '' }, () => {

            this.props.getVendors();
        })

    }


    changeFilter = selectedFilter => {

        this.setState({ selectedFilter: selectedFilter ? selectedFilter : 'ALL' })
        // this.props.filterVendors(selectedFilter ? selectedFilter : 'ALL');
        this.filterVendors(selectedFilter ? selectedFilter : 'ALL');
    }

    reload = () => {
        this.changeFilter("ALL")
        this.setState({ page: 1 });
        // this.props.reloadVendor()
        this.reloadVendor()
    }

    getTitle() {
        return (
            <VendorCardTitle title="Vendor List">
                <Row gutter={12}>
                    <Col md={9} push={2}>
                        <Input.Search
                            size={'large'}
                            value={this.state.searchData}
                            placeholder="Search by name"
                            allowClear
                            onChange={this.changeSearch}
                            onSearch={value => this.searchHandler(value)} enterButton
                        />
                        {/* <Input
                            placeholder="Search"
                            size="large"
                            suffix={<Icon type="search" />}
                            onChange={this.searchHandler}
                        /> */}
                    </Col>
                    <Col md={2} push={2}>
                        <Button block size={'large'} onClick={this.reload} type="primary">
                            <Icon type="reload" />
                        </Button>
                    </Col>
                    <Col md={5} push={2}>
                        <Select
                            style={{ width: '100%' }}
                            size="large"
                            allowClear={this.state.selectedFilter !== 'ALL'}
                            onChange={this.changeFilter}
                            value={this.state.selectedFilter}
                        >
                            <Select.Option value={'ALL'}> All</Select.Option>
                            <Select.Option value={'BLOCKED'}> Blocked</Select.Option>
                            <Select.Option value={'UNBLOCKED'}> UnBlocked</Select.Option>
                        </Select>
                    </Col>
                    <Col md={1} push={2}>
                        <Button type="primary" size="large" onClick={() => this.goToVendorCreate()}>CREATE VENDOR</Button>
                    </Col>
                </Row>
            </VendorCardTitle>
        )
    }

    editVendor(vendor) {
        Mixpanel.track("Vendor Page Edit Clicked", { 'ERPVendorEditClick': moment().valueOf(), vendor_edit_id: vendor.id })

        this.props.history.push(`/appv2/settings/form/vendor`, {vendorId: vendor?.id});
    }

    checkHttpStatus = (status) => {
        switch (status?.code) {
            case 403:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 401:
                notifyApiError(status?.message, "ERROR:");
                // window.location.href = '/';
                return false;
            case 500:
            case 503:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 200:
            case 201:
            case 202:
                return true
        }
    }

    unblockVendor = (vendor) => {
        // console.log("vendor", vendor)
        if (vendor) {
            if (vendor?.isBlocked) {
                this.setState({ vendorSubmitIsBlocked: true })
            } else {
                this.setState({ vendorSubmitIsBlocked: false })
            }

            if (vendor?.tempUnblockDate && moment(vendor?.tempUnblockDate).valueOf() > moment().valueOf()) {
                this.setState({ tempDate: moment(vendor?.tempUnblockDate) })
            }
            // if (vendor?.isBlocked) {
            const { id } = vendor;

            apiUtils.apiRequest({
                url: `/b2b/v1/vendors/${id}/blocked?fetchMode=STRICT_BASIC`,
            }).then(response => {
                if (this.checkHttpStatus(response.data?.status)) {

                    // if (response?.data?.response?.blockedReason) {

                    // if (isBlocked && (isEligibleBlock == false || (tempdate != null && tempdate >= current date)) {
                    //     unblock
                    // }
                    // if (isBlocked && !(isEligibleBlock == false || (tempdate != null && tempdate >= current date)) {
                    //     block
                    // }



                    if (vendor.isBlocked && (!vendor.isEligibleToBlock || (vendor?.tempUnblockDate && moment(vendor?.tempUnblockDate).valueOf() >= moment().valueOf()))) {
                        this.setState({ vendorIsBlocked: true, unblockModal: true, unblockVendor: vendor, blockedReason: response?.data?.response, vendorBlockAction: 'UNBLOCK', unblockType: 'block' })
                    } else if (vendor.isBlocked && !(!vendor.isEligibleToBlock || (vendor?.tempUnblockDate && moment(vendor?.tempUnblockDate).valueOf() >= moment().valueOf()))) {
                        this.setState({ vendorIsBlocked: true, unblockModal: true, unblockVendor: vendor, blockedReason: response?.data?.response, vendorBlockAction: 'BLOCK', unblockType: 'unblock' })
                    } else if (!vendor.isBlocked) {
                        this.setState({ vendorIsBlocked: true, unblockModal: true, unblockVendor: vendor, blockedReason: response?.data?.response, vendorBlockAction: 'NOOP' })
                    }
                    // }
                }
            })
            // } else {
            //     this.setState({ unblockModal: true, unblockVendor: vendor, vendorIsBlocked: false })
            // }
        }



    }

    gotoVendorDetailed = () => {
        this.props.history.push(`/appv2/settings/dashboard/vendor/blocked/${this.state.unblockVendor.id}`, { vendor: this.state.unblockVendor, page: this.state.page, filter: this.state.selectedFilter, vendorsList: this.props.vendorsList });
    }

    cancelUnblockModal = () => {
        this.setState({ vendorIsBlocked: false, unblockModal: false, unblockVendor: {}, blockedReason: '', unblockType: "unblock", tempDate: new moment().endOf('day').milliseconds(0) })
    }

    onChangeUnblockType = e => {
        const value = e.target.value;
        this.setState({ unblockType: value })


        // if (value === "perm") {
        //     this.setState({ tempDate: undefined })
        // } else {
        //     this.setState({ tempDate: new moment().endOf('day').milliseconds(0) })
        // }
    }

    onChangeTempDate = date => {
        this.setState({ tempDate: date })
    }

    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current <= moment().startOf('day');
        // return current && current <= moment().subtract(1, 'day').endOf('day').milliseconds(0) && current >= moment().subtract(1, 'day').endOf('day').milliseconds(0);
    }

    submitUnblock = () => {
        if ((this.state.vendorBlockAction === 'UNBLOCK' || this.state.vendorBlockAction === 'BLOCK') && this.state.unblockType === 'unblock') {
            const params = {
                "isUnblockPermanent": false,
                "isUnblockTemporarily": true,
                "temporarilyUnblockDate": this.state.tempDate.valueOf()
            };


            // console.log("params", params)

            apiUtils.apiRequest({
                method: 'put',
                url: `/b2b/v1/vendors/${this.state.unblockVendor.id}/eligible-update/true`,
            })
                .then(res => {
                    if (this.checkHttpStatus(res.data?.status)) {
                        // notifyApiSuccess("Vendor Blocked", "SUCCESS");
                        this.setState({ loading: false });
                        this.props.getUnblockVendor(this.state.unblockVendor.id, params);
                        // this.cancelUnblockModal();
                        // this.setState({ page: 1 }, () => {
                        //     // this.props.reloadVendor()
                        //     // this.reloadVendor();
                        // })
                    } else {
                        this.setState({ loading: false })
                        this.cancelUnblockModal();
                        this.setState({ page: 1 }, () => {
                            // this.props.reloadVendor()
                            this.reloadVendor();
                        })
                    }
                })

            // this.props.getUnblockVendor(this.state.unblockVendor.id, params);


        } else if (this.state.unblockType === 'block') {
            // console.log("this.vendor", this.state.unblockVendor)
            // if (this.state.vendorBlockAction === 'BLOCK') {
            this.setState({ loading: true });
            apiUtils.apiRequest({
                method: 'put',
                url: `/b2b/v1/vendors/${this.state.unblockVendor.id}/eligible-update/true`,
            })
                .then(res => {
                    if (this.checkHttpStatus(res.data?.status)) {
                        notifyApiSuccess("Vendor Blocked", "SUCCESS");
                        this.setState({ loading: false });
                        // this.cancelUnblockModal();
                        // this.setState({ page: 1 }, () => {
                        //     // this.props.reloadVendor()
                        //     // this.reloadVendor();
                        // })
                        const params = {
                            "isUnblockPermanent": false,
                            "isUnblockTemporarily": true,
                            "temporarilyUnblockDate": moment().subtract(1, 'day').valueOf()
                        };
                        this.props.getUnblockVendor(this.state.unblockVendor.id, params);
                    } else {
                        this.setState({ loading: false })
                        this.cancelUnblockModal();
                        this.setState({ page: 1 }, () => {
                            // this.props.reloadVendor()
                            this.reloadVendor();
                        })
                    }
                })
            // apiUtils.apiRequest({
            //     method: 'get',
            //     url: `/b2b/v2/partners/${Constants.PARTNER_ID}/vendors/${this.state.unblockVendor.id}?fetchMode=BASIC_WITH_IDENTITY_DOCUMENTS`,
            // }).then(response => {
            //     // console.log("response", response, this.state.unblockVendor)
            //     let vendorData = response?.data?.response;

            //     vendorData = {
            //         ...vendorData,
            //         isEligibleToBlock: true
            //     };

            //     // console.log("vendorData", vendorData)





            // })
            // }

        } else if (this.state.vendorBlockAction === 'NOOP') {
            return;
        }

    }

    changePage = (page, size) => {
        this.setState({ page })
    }

    render() {
        const columns = [
            {
                title: 'Vendor Name',
                dataIndex: 'name',
                key: 'name',
                width: 120
            },
            {
                title: 'GST',
                dataIndex: 'gstNumber',
                key: 'gstNumber',
                width: 120
            },
            {
                title: 'Contact Person',
                dataIndex: 'contactPerson',
                key: 'contactPerson',
                width: 120
            },
            {
                title: 'Contact Details',
                dataIndex: 'contactNumber',
                key: 'contactNumber',
                render: (text, row) => <span>{text}<br /> <small>{row.email}</small></span>,
                width: 120

            },
            {
                title: 'Vendor Type',
                dataIndex: 'vendorTypes',
                key: 'vendorTypes',
                render: (text, row) => text.join(","),
                width: 120
            },

            {
                title: 'Last Updated on',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text, row) => <div>{moment(text).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.updatedAt).fromNow()}</small></div>,
                width: 90
            },
            {
                title: 'Actions',
                key: 'action',
                width: 60,
                render: (text, record) =>

                    <Row gutter={12}>
                        {this.state.vendorPermission.unblock &&
                            <>

                                <Col span={8}>
                                    <Tooltip overlayClassName="ant-vendor-tooltip" title={`Block / Unblock`}>
                                        <Icon type="undo" onClick={() => this.unblockVendor(record)} />
                                    </Tooltip>

                                </Col>

                            </>
                        }
                        <Col span={8}>
                            <Tooltip overlayClassName="ant-vendor-tooltip" title="Edit">
                                <Icon type="edit" onClick={() => this.editVendor(record)} />
                            </Tooltip>

                        </Col>
                        <Col span={8}>
                            <Tooltip overlayClassName="ant-vendor-tooltip" title={"Asset Counts " + record.assetCount}>
                                <Icon type="car" />
                            </Tooltip>
                        </Col>
                    </Row>


            },
            // {
            //     title: 'Asset Counts',
            //     dataIndex: 'assetCount',
            //     key: 'assetCount'
            // },

        ];

        // console.log("this.state", this.state.vendorBlockAction)
        // console.log("this.state.vendorBlockAction", this.state.vendorBlockAction, this.state.unblockType)

        return (
            <>
                <Spin spinning={this.props.vendorslistloading}>
                    <Card
                        title={this.getTitle()}
                        className="vendor-list-wrapper"
                    >
                        <Table
                            columns={columns}
                            bordered
                            dataSource={this.state.vendors}
                            pagination={{
                                current: this.state.page,
                                onChange: this.changePage
                            }}
                        />

                        <Modal
                            title={`${this.state.unblockType === 'block' ? 'Block' : 'Unblock'} Vendor - ${this.state?.unblockVendor?.name}`}
                            visible={this.state.unblockModal}
                            centered
                            maskClosable={false}
                            closable={false}
                            footer={[
                                <Button key="cancel" onClick={this.cancelUnblockModal}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    disabled={this.state.vendorBlockAction === 'NOOP'}
                                    style={{ opacity: this.state.vendorBlockAction === 'NOOP' ? 0.5 : 1 }}
                                    loading={this.props.vendorBlockLoading || this.state.loading}
                                    type="primary"
                                    onClick={this.submitUnblock}
                                >
                                    Submit
                                </Button>

                            ]}
                        >
                            <Row>
                                <Col span={12}>
                                    <Form.Item colon={false} label={`Action`}>
                                        {/* <Radio.Group onChange={this.onChangeUnblockType} value={this.state.unblockType}>
                                            <Radio value={"unblock"}>{this.state.vendorBlockAction === 'UNBLOCK' ? 'Unblock' : 'Block'}</Radio>
                                            {this.state.vendorBlockAction === 'UNBLOCK' &&
                                                <Radio value={"block"}>{'Block'}</Radio>
                                            }
                                        </Radio.Group> */}
                                        <Radio.Group onChange={this.onChangeUnblockType} value={this.state.unblockType}>
                                            <Radio value={"unblock"}>Unblock</Radio>

                                            <Radio disabled={this.state.vendorBlockAction === 'BLOCK'} value={"block"}>{'Block'}</Radio>

                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                {this.state.unblockType === 'unblock' &&
                                    <Col span={12}>
                                        <Form.Item colon={false} label={'Unlock till'}>
                                            <DatePicker
                                                showTime
                                                disabledDate={this.disabledDate}
                                                format={"DD/MM/YYYY"}
                                                value={this.state.tempDate}
                                                // disabled={this.state.vendorBlockAction === 'BLOCK'}
                                                onChange={this.onChangeTempDate}
                                            />
                                        </Form.Item>
                                    </Col>
                                }

                            </Row>
                            {this.state.vendorIsBlocked &&
                                <Result
                                    icon={
                                        this.state.vendorBlockAction === "UNBLOCK" ? <img src={UNBLOCK} width={60} /> : this.state.vendorBlockAction === "BLOCK" ? <img src={BLOCK} width={60} /> : <Icon type="check-circle" />
                                    }

                                    extra={
                                        <>
                                            {/* {this.state?.blockedReason?.hpodNotSubmittedCount > 0 && */}
                                            <Alert
                                                message={
                                                    `EPOD Pending: ${this.state?.blockedReason?.epodNotSubmittedCount}`
                                                }
                                                type="info"
                                            />

                                            {/* } */}
                                            {/* {this.state?.blockedReason?.epodNotSubmittedCount > 0 && this.state?.blockedReason?.hpodNotSubmittedCount > 0 && */}
                                            <Divider />
                                            {/* } */}
                                            <Alert
                                                message={
                                                    `HPOD Pending: ${this.state?.blockedReason?.hpodNotSubmittedCount}`
                                                }
                                                type="info"
                                            />

                                            {/* {this.state?.blockedReason?.epodNotSubmittedCount > 0 && */}

                                            {/* } */}
                                            {(this.state?.blockedReason?.epodNotSubmittedCount > 0 || this.state?.blockedReason?.hpodNotSubmittedCount > 0) &&
                                                <Button onClick={this.gotoVendorDetailed} className="vendor-view-more" type="link">View More</Button>
                                                // <a className="vendor-view-more" href={`/appv2/settings/dashboard/vendor/blocked/${this.state.unblockVendor?.id}`} target="_blank">View More</a>
                                            }
                                        </>
                                    }
                                />
                            }



                        </Modal>
                    </Card>
                </Spin>
            </>
        )
    }
}

// export default VendorList;


const mapStateToProps = state => ({
    vendorsList: state.vendor?.vendorsList,
    vendorslistloading: state?.vendor?.vendorslistloading,
    vendorBlockLoading: state?.vendor?.vendorBlockLoading,
    unblockVendor: state?.vendor?.unblockVendor
});

const mapDispatchToProps = dispatch => ({
    getVendors: () => dispatch(vendorActions.getVendors()),
    getUnblockVendor: (id, params) => dispatch(vendorActions.getUnblockVendor(id, params)),
    setInitialState: () => dispatch(vendorActions.setInitialState())
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);