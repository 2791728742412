import React, {Component} from 'react';

import { Card, Button, Icon } from 'antd';

import { Link } from 'react-router-dom';




function AccessDenied (props)
{
    const navigateback = () => {
        props.history.goBack()
    }

    return (
        <div className="flex-column" style={{height:"100vh"}}>
            <div className="justify-content-center flex-box icon-size-header color-red"><Icon type="close-circle" /></div>
            <div className="justify-content-center flex-box font-size-header"><b>Access Denied</b></div>
            <div className="justify-content-center flex-box font-size-m-3 spacer-vs">You don't have permission to access requested page.</div>
            <div className='justify-content-center flex-box'><Button type="primary" onClick={navigateback}>Go Back </Button></div>
        </div>
        )
}



export default AccessDenied;