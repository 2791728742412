import { Alert, Button, Icon, Modal, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { UPLOAD_BUTTON_TYPE } from "../../../../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../../../../common/utils/utils";
import FileUpload from "../../../../../ui/file-upload/file.upload";
import FormWrapper from '../../../../../ui/formComponent/form';
import { fetchShippers } from "../../../../../../services/common/fetch.services";
import { removeArrayElem } from "../../../common/utils";
import { copyContract, createEditContractHeader } from "../contract.services";
import basicSchemaForm from "./basic.form.d";

function ContractBasicDetails(props) {
    
    var formRef = useRef();
    const [formData, setFormData] = useState(false);
    const [schema, setSchema] = useState(basicSchemaForm.schema);
    const [uiSchema, setUiSchema] = useState(basicSchemaForm.uiSchema);
    const [selectedShipper, SetSelectedShipper] = useState();
    const [fileUploaded, SetFileUploaded] = useState(true);
    const [proofURL, SetProofURL] = useState([]);
    const [proofURLError, SetProofURLError] = useState(false);
    const [creatingCopying, SetCreatingCopying] = useState(false);
    const [disableCreate, SetDisableCreate] = useState(false);
    const [detailsUpdated, SetDetailsUpdated] = useState(false);

    useEffect(() => {
        const { contractData } = props;

        if (!props.readonly) {
            setUiSchema(current => {
                if (contractData.id) {
                    current.shipperId.disabled   = true;
                    current.serviceType.disabled = true;
                }
                else {
                    current.shipperId.disabled   = false;
                    current.serviceType.disabled = false;
                }
                return current;
            })
            setSchema(current => {
                current.properties.shipperId["apiCall"] = value => {
                    if (value.trim() !== "") {
                        if (! (/^[a-zA-Z0-9 -_.]*$/.test(value.trim())) ) {
                            notifyApiError("Invalid search phrase entered!");
                            return new Promise(resolve => resolve([]));
                        }
                        return fetchShippers({
                            entityIds            : [ props.customerId ],
                            isActive             : true,
                            partialContentSearch : value.trim()
                        }).then(resp => resp?.entityShipperMappingBOList.map(shipper => {
                                return {
                                    value: shipper.id,
                                    label: shipper.shipperName
                                }
                            })
                        )
                    }
                    else {
                        return new Promise(resolve => resolve([]));
                    }
                }
                return current;
            })
        }

        if (!contractData.id) { 
            setFormData({ isOneTimeContract: false });
            setSchema(schema);
        }
        else {
            const basicForm = {
                ...contractData,
                shipperId: {
                    key   : contractData.shipperBO.id,
                    label : contractData.shipperBO.shipperName
                },
                contractValidity: contractData.validFrom && contractData.validTo ? contractData.validFrom + "," + contractData.validTo : ""
            }
            SetSelectedShipper({
                key   : contractData.shipperBO.id,
                label : contractData.shipperBO.shipperName
            });
            if (basicForm.contractProofUrl) {
                SetProofURL(basicForm.contractProofUrl.split(","))
            }
            else {
                SetProofURL([])
            }
            setFormData(basicForm)
        }
    }, []);

    useEffect(() => {
		if (props.saveContractHeader) {
			formRef.current.formReference?.current?.submit()
		}
    }, [props.saveContractHeader])

    const sendCreateEditHeader = data => {
        createEditContractHeader(data)
        .then(response => {
            SetCreatingCopying(false);
            if (!formData.id) {
                notifyApiSuccess("Contract created successfully!", response?.contractV1BO?.name, true);
            }
            if (response?.contractV1BO?.id) {
                props.onContractCreate(response?.contractV1BO?.id)
            }
        }).catch(_ => {
            SetCreatingCopying(false);
            props.onContractCreate(false);
        });
    }
    
    const onSubmit = data => {
        if (data.shipperId) {
            data.shipperBO = {
                id          : selectedShipper.key,
                shipperName : selectedShipper.label
            };
            delete data.shipperId;
        }
        if (data.contractValidity) {
            data.validFrom = data.contractValidity.split(",")[0];
            data.validTo = data.contractValidity.split(",")[1];
            delete data.contractValidity;
        }
        data["customerId"] = props.customerId;
        if (proofURL.length > 0) {
            data["contractProofUrl"] = proofURL.join(",");
        }
        else {
            data["contractProofUrl"] = undefined;
        }
        Modal.destroyAll();
        SetCreatingCopying(true);
        if (props.contractData.id && (props.cloning || props.contractData.status === "APPROVED") ) {
            copyContract({
                contractId : props.contractData.id,
                action     : props.cloning ? "COPY" : "EDIT"
            })
            .then(response => {
                data.id = response.contractId;
                sendCreateEditHeader(data);
            })
            .catch(e => {
                notifyApiError(e.toString())
            })
        }
        else {
            if (detailsUpdated) {
                SetDetailsUpdated(false);
                sendCreateEditHeader(data);
            }
            else if (props.contractData.id) {
                SetDetailsUpdated(false);
                SetCreatingCopying(false);
                props.onContractCreate(props.contractData?.id)
            }
        }
    }
    
    const onChange = params => {
        SetDetailsUpdated(true);
        const proceedOnChange = _ => {
            if (params?.shipperId?.key) {
                SetSelectedShipper(params.shipperId);
            }
            setFormData(params);
            props.SetHeaderDetailsUpdated(true);
        }
        if (formData.serviceType != params.serviceType && formData.id) {
            Modal.confirm({
                title    : "On changing service type any freight charges(s) saved will be removed and basis will need to be set again. Are you sure to proceed?",
                onOk     : _ => {
                    Modal.destroyAll();
                    props.onServiceTypeChange(params.serviceType)
                    proceedOnChange();
                },
                onCancel : _ => {
                    var data = JSON.parse(JSON.stringify(formData));
                    setFormData(false);
                    Modal.destroyAll();
                    setFormData(data);
                },
                okText     : "Yes",
                cancelText : "No"
            })
        }
        else {
            proceedOnChange();
        }
    }

    const validate = (data, errors) => {
        if (data.contractValidity) {
            data.validFrom = data.contractValidity.split(",")[0];
            data.validTo = data.contractValidity.split(",")[1];

            if (!data.validFrom || data.validFrom == "" || !data.validTo || data.validFrom == "" && errors.contractValidity) {
                errors["contractValidity"].addError("Contract validity is mandatory!")
            }
            else if (parseInt(data.validFrom) >= parseInt(data.validTo)) {
                errors["contractValidity"].addError("To date cannot be earlier than from date!")
            }
        }
        return errors;
    }

    const transformErrors = errors => {
        errors.map(e => {
            if (e.property === ".shipperId" && e.name === "type") {
                e.message = undefined
            }
        })
        return errors;
    }

    const onError = _ => {
        Modal.destroyAll();
        props.onContractCreate(false);
    }

    const getIconType = fileurl => {
        const fileType = fileurl.substr(fileurl.lastIndexOf(".") + 1);
        return fileType.toLowerCase() === "pdf" ? "file-pdf" : "file-image"
    }

    return (
        <div className="flex-column flex-gap-m">
            {
                formData &&
                <Spin spinning={creatingCopying} className="flex-box justify-content-center">
                    <FormWrapper id="ContractBasicDetails"
                        onError={onError}
                        schema={schema} uiSchema={uiSchema}
                        errorList={basicSchemaForm.errorList}
                        formData={formData} readonly={props.readonly}
                        onSubmit={onSubmit} onChange={onChange}
                        fieldsPerRow={2} showColumnSplitter={false}
                        loading={props.loading} bordered={true}
                        hideSubmit={true} ref={formRef}
                        transformErrors={transformErrors}
                        validate={validate}
                    />
                    <div className="flex-column flex-gap-l">
                        <div className="flex-box" style={{marginTop: 10}}>
                            <div className="flex-box flex-gap-l align-items-center" style={{flexBasis:"48%"}}>
                                {
                                    props.readonly ?
                                    <div>
                                        <div className="flex-1 flex-box flex-gap-m align-items-center upload-contract-proof justify-content-center">
                                            <div> Contract Proof </div>
                                        </div>
                                    </div> :
                                    <FileUpload showToastMessage={false} showLoader={true} showUploadList={false} beforeUpload={_ => SetDisableCreate(true)}
                                        incorrectFileType="Invalid file type. Please upload files of pdf, jpeg, jpg, png."
                                        fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                        uploadButtonHtml={
                                            <div className="flex-1 flex-box flex-gap-m align-items-center upload-contract-proof justify-content-center">
                                                <Icon type="upload" />
                                                <div> Upload Contract Proof </div>
                                            </div>
                                        }
                                        directUpload={true} disableUploadButton={true} clearFileAfterUpload={true}
                                        onUploadFinish={imageURL => {
                                            SetDisableCreate(false);
                                            SetDetailsUpdated(true);
                                            props.SetHeaderDetailsUpdated(true);
                                            SetProofURLError(false);
                                            SetFileUploaded(false);
                                            if (imageURL) {
                                                proofURL.push(imageURL);
                                                SetProofURL(proofURL);
                                            }
                                            SetFileUploaded(true);
                                        }}
                                        path="contract" acceptTypes={[ ".pdf", ".jpeg", ".jpg", ".png", ".PDF", ".JPEG", ".JPG", ".PNG" ]}
                                        objKey="proof" multiple={true} />
                                }
                                {
                                    fileUploaded ?
                                    proofURL.length > 0 &&
                                    proofURL.map((proof, index) => {
                                        return (
                                            <div className="upload-contract-proof file-icon" key={index} onClick={_ => window.open(proof, "_blank")}>
                                                <Icon type={getIconType(proof)}/>
                                                {
                                                    !props.readonly && 
                                                    <div className="file-remove" onClick={event => {
                                                            event.stopPropagation();
                                                            Modal.confirm({
                                                                title   : "Are you sure to remove?",
                                                                onOk    : _ => {
                                                                    Modal.destroyAll();
                                                                    props.SetHeaderDetailsUpdated(true);
                                                                    SetProofURL(current => {
                                                                        return removeArrayElem(current, index)
                                                                    });
                                                                },
                                                                okText  : "Yes"
                                                            })
                                                        }}>
                                                        <Icon type='delete' className='delete-icon'/>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }) :
                                    <></>
                                }
                            </div>
                        </div>
                        {
                            proofURLError &&
                            <div className="error-text"> Upload a proof file to submit the contract for approval!</div>
                        }
                    </div>
                </Spin>
            }
            {
                !props.readonly &&
                (
                    (!props.contractData.id || props.contractData.status === "APPROVED" || props.cloning) &&
                    <div className="flex-box justify-content-end">
                        <Button className="secondary-btn" onClick={props.cancelCreate}>
                            Cancel
                        </Button>
                        <Button className="lsn-btn-primary" disabled={disableCreate} onClick={() => {
                            Modal.confirm({
                                width   : 450,
                                title   : props.cloning ? <div> Are you sure copy? </div> :
                                            props.contractData.status === "APPROVED" ?
                                            <div className="flex-column flex-gap-xl">
                                                <div> Are you sure to edit? </div>
                                                <Alert message={
                                                    <div className="flex-box flex-gap-l align-items-start">
                                                        <Icon type="warning" theme="twoTone" twoToneColor="#ff9801" />
                                                        <div>
                                                            On Editing an approved contract a copy will be created and on approval of the copy, current contract will be forceclosed!
                                                        </div>
                                                    </div>
                                                } type="warning" />
                                            </div> :
                                            <div> Are you sure to create? </div>,
                                onOk    : _ => {
                                    formRef.current.formReference?.current?.submit()
                                }
                            })
                        }}>
                            { props.cloning ? "Copy Contract" : props.contractData.status === "APPROVED" ? "Edit Contract" : "Create Contract" }
                        </Button>
                    </div>
                )
            }
        </div>
    );
}
export default ContractBasicDetails;