import * as _Constants from './constants'
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import ExceptionService from '../../../services/inbound/exception.service';
import MiscService from '../../../services/misc.service';
import { Constants } from '../../../common/utils/constants';

const exceptionService = new ExceptionService

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getExceptions = (payload, isReset) => dispatch => {
    dispatch(fetchExceptionBegin());
    exceptionService.getExceptions(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchExceptionSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchExceptionFailure(res ?.data ?.response));
        }
    });
};

function fetchExceptionBegin() {
    return {
        type: _Constants.FETCH_EXCEPTION_BEGIN
    };
}

function fetchExceptionSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_EXCEPTION_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchExceptionFailure(error) {
    return {
        type: _Constants.FETCH_EXCEPTION_FAILURE,
        payload: error
    };
}

export const updateBookingService = (payload) => dispatch => {
    exceptionService.updateBookingService(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            notifyApiSuccess('List has been updated', 'Success');
            dispatch(getExceptions({ 'pageNo': 1, 'pageSize': 10, partnerId: Constants.PARTNER_ID }, true))
        } else {
            notifyApiError(res ?.response.reason, 'Error');
        }
    });
};

export const getServiceableAreaPinCodes = () => dispatch => {
    dispatch(fetchServiceableAreaPinCodesBegin());
    MiscService.getServiceableAreaPinCodes().then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchServiceableAreaPinCodesSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchServiceableAreaPinCodesFailure(res ?.data ?.response));
        }
    });
};

function fetchServiceableAreaPinCodesBegin() {
    return {
        type: _Constants.FETCH_SERVICEABLE_AREA_PINCODES_BEGIN
    };
}

function fetchServiceableAreaPinCodesSuccess(payload) {
    return {
        type: _Constants.FETCH_SERVICEABLE_AREA_PINCODES_SUCCESS,
        payload: payload,
    };
}

function fetchServiceableAreaPinCodesFailure(error) {
    return {
        type: _Constants.FETCH_SERVICEABLE_AREA_PINCODES_FAILURE,
        payload: error
    };
}