import React, { PureComponent } from 'react';
// import * as actions from './actions';
// import './dashboard.scss';
// import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Pagination, Tooltip, Spin, Form, Select, Table, Alert } from 'antd';
import { canAllow, windowRedirect, notifyApiSuccess, checkHttpStatus, notifyApiError, trimEachWaybill } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
// import { Constants } from '../../../common/utils/constants';
import debounce from 'lodash/debounce';
// import SLAReasonsService from '../../../services/sla-reasons/sla-reasons.service';
import _ from 'lodash';
import { apiRequest } from '../../../common/utils/apiGateway';
import { Mixpanel } from '../../../utils/mixpanel';
// let slaService = new SLAReasonsService();
import "./eway.scss";
import { validationRules } from '../../../common/utils/constants';
const { confirm } = Modal;
const {Option} = Select;

class PartB extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            ewayPermission: {
                
                update: canAllow(permissionTags.ewaybill.partb.update),
                
            },
            selectedRowKeys: [],
            searchData: undefined,
            ewaybillData: undefined,
            showUpdatePanel: false,
            updateText: 'Update',
            selectedDockets: [],
            selectedVehicle: undefined,
            selectedReason: undefined,
            updateLoading: false,
            searchByData: 'TRIP',
            filter: {},
            reasons: [],
            vehicleError: undefined,
            forceRender: false
        }

        // window.onbeforeunload = (event) => {
        //     // Show prompt based on state
        //     if (showExitPrompt) {
        //         const e = event || window.event;
        //         e.preventDefault();
        //         if (e) {
        //             e.returnValue = ''
        //         }
        //         return '';
        //     }
        // };

        // this.applyfilters();


    }



    componentDidMount() {

        window.onbeforeunload = (event) => {
            // Show prompt based on state
            if (this.state.updateLoading) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = ''
                }
                return '';
            }
        };


        this.setState(state => ({

            columns: [
                {
                    title: 'Docket No',
                    dataIndex: 'docketNo',
                    key: 'docketNo',

                },
                {
                    title: 'Docket Status',
                    dataIndex: 'docketStatus',
                    key: 'docketStatus',

                    // render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Docket Updated at',
                    dataIndex: 'docketUpdatedAt',
                    key: 'docketUpdatedAt',

                    render: data => data ? (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>) : ''
                },
                {
                    title: 'Docket with',
                    dataIndex: 'docketWith',
                    key: 'docketWith',

                },
                // {
                //     title: 'Docket ID',
                //     dataIndex: 'ewaybillId',
                //     key: 'ewaybillId',

                //     // render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                // },
                {
                    title: 'Trip ID',
                    dataIndex: 'tripId',
                    key: 'tripId',
                },
                {
                    title: 'E-waybill No',
                    dataIndex: 'ewaybillNo',
                    key: 'ewaybillNo',

                },
                {
                    title: 'E-waybill vehicle No',
                    dataIndex: 'ewaybillVehicleNo',
                    key: 'ewaybillVehicleNo',

                },
                {
                    title: 'E-waybill Expiry',
                    dataIndex: 'ewaybillExpiryDate',
                    key: 'ewaybillExpiryDate',
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)

                }
            ],
            updateColumns: [
                {
                    title: 'Docket Number',
                    dataIndex: 'docketNo',
                    key: 'docketNo',

                },
                {
                    title: 'E-waybill Number',
                    dataIndex: 'ewaybillNo',
                    key: 'ewaybillNo',

                },
            ]
        }));

        if (this.props?.location?.state?.filter) {
            this.setState({ selectedRowKeys: [], filter: this.props?.location?.state?.filter }, () => {
                // console.log("filter", this.props.location.state.filter)
                const filter = this.props?.location?.state?.filter?.filter;

                if (filter?.ewaybillNos) {
                    this.setState({ searchByData: 'EWAY', searchData: [...filter?.ewaybillNos] })
                } else if (filter?.tripIds) {
                    this.setState({ searchByData: 'TRIP', searchData: [...filter?.tripIds] })
                } else if (filter?.waybills) {
                    this.setState({ DOCKET: 'TRIP', searchData: [...filter?.waybills] })
                }
                this.searchData(this.props.location.state.filter)
            })
        }
    }

    applyfilters = () => {


        if (this.state.searchData) {
            // let searchData = undefined;
            // if (this.state.searchData.indexOf(" ") > 0) {
            //     searchData = this.state.searchData.split(" ");
            // }
            // if (this.state.searchData.indexOf(",") > 0) {
            //     searchData = this.state.searchData.split(",");
            // }
            let searchData = trimEachWaybill(this.state.searchData);
            Mixpanel.identify(this.props.header?.userInfo?.loggedPartnerId);
            Mixpanel.people.set({
                $first_name: this.props?.header?.userInfo?.loggedPartnerId
            });
            Mixpanel.track("Ewaybill PartB Searched By", { 'searchByUpdateB': this.state.searchByData })

            const filter = {
                "filter": {
                    ...(this.state.searchByData === 'DOCKET' && { "waybills": searchData }),
                    ...(this.state.searchByData === 'EWAY' && { "ewaybillNos": searchData }),
                    ...(this.state.searchByData === 'TRIP' && { "tripIds": searchData }),
                    "pageNo": this.state.pageNo,
                    "pageSize": this.state.pageSize
                }
            }
            this.setState({ filter, selectedRowKeys: [] }, () => {
                this.searchData(filter)
            })


        } else {
            this.setState({ ewaybillData: undefined, searchByData: 'TRIP', selectedRowKeys: [] })
        }


    }

    searchData = filter => {
        this.setState({ searchLoading: true });
        apiRequest({
            url: '/b2b/v1/ewaybill/list',
            method: "POST",
            data: filter
        }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                if (res?.data?.response?.ewaybills && res?.data?.response?.ewaybills?.length > 0) {
                    let ewaybillData = res?.data?.response;
                    this.setState({ ewaybillData, searchLoading: false });
                } else {
                    this.setState({ ewaybillData: undefined, searchLoading: false })
                    notifyApiError('No Data found!!', 'Error')
                }
            }
        });
    }

    resetFilters = () => {
        this.setState({
            searchData: undefined
        }, () => {
            this.applyfilters();
        })
    }



    changeEwayBill = e => {
        let value = e.target.value;
        this.setState({ searchData: value })
    }

    searchEwaybills = () => {
        let { searchData } = this.state;
        if (searchData) {
            this.applyfilters()
            // this.props.searchEway(value);
        } else {
            this.resetFilters();
        }

    }

    onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    goToUpdate = () => {
        let { selectedDockets, ewaybillData, selectedRowKeys, filter } = this.state;
        const selectedEwaybills = _.uniqBy(ewaybillData?.ewaybills, (e) => {
            return e.ewaybillNo;
        })
        // console.log("selected", selectedEwaybills)
        
        // selectedDockets = [...selectedRowKeys].map(docket => ewaybillData?.ewaybills.filter(eway => eway?.docketNo === docket)[0]);
        // console.log("selected", selectedDockets)
        this.props.history.push('/appv2/ewaybill/dashboard/partb/update', { "selectedDockets": selectedEwaybills, filter: filter })
        // console.log("selectedDockets", _.groupBy(selectedDockets, 'ewaybillNo'));
    }

    showUpdateModal = () => {
        let { ewaybillData, selectedDockets, selectedRowKeys, reasons } = this.state;
        if (reasons.length <= 0) {
            apiRequest({
                url: '/b2c/v2/reasons/categories/fetch',
                method: "POST",
                data: {
                    "categoryFilters": {
                        "types": [
                            "UPDATE_PART_B"
                        ],
                        "isActive": true,
                        "fetchObjects": [
                            "PARTNER_REASONS"
                        ]
                    }
                }

            }).then(res => {
                if (checkHttpStatus(res?.data?.status)) {
                    if (res?.data?.response?.reasonCategories && res?.data?.response?.reasonCategories?.length > 0) {
                        let reasonCategories = res?.data?.response?.reasonCategories;
                        let reasons = reasonCategories.map(reCat => reCat?.reasons)[0]

                        this.setState({ reasons });
                    } else {
                        this.setState({ reasons: [] })
                        // notifyApiError('No Data found!!', 'Error')
                    }
                }
            });
        }
        this.setState({ showUpdatePanel: true, selectedVehicle: undefined, selectedReason: undefined, updateText: 'Update' }, () => {
            // selectedDockets = ewaybillData?.ewaybills.filter(eway => eway?.docketNo === [...selectedDockets]);
            selectedDockets = [...selectedRowKeys].map(docket => ewaybillData?.ewaybills.filter(eway => eway?.docketNo === docket)[0]);

            this.setState({ selectedDockets })
        });
    }

    hideUpdateModal = () => {
        this.setState({ showUpdatePanel: false, selectedVehicle: undefined, selectedReason: undefined }, () => {
            let { updateColumns } = this.state;
            updateColumns = [
                {
                    title: 'Docket Number',
                    dataIndex: 'docketNo',
                    key: 'docketNo',

                },
                {
                    title: 'E-waybill Number',
                    dataIndex: 'ewaybillNo',
                    key: 'ewaybillNo',

                },
            ];
            this.setState({ updateColumns }, () => {
                this.applyfilters();
            })
        });
    }

    validateVehicle = () => {
        let { selectedVehicle } = this.state;
        let re = new RegExp(validationRules.vehicleNumber);
        if (!re.test(selectedVehicle)) {
            this.setState({ vehicleError: 'Enter valid Vehicle Number' });
        } else {
            this.setState({ vehicleError: undefined });
        }
    }



    changeSearchData = value => {
        this.setState({ searchByData: value, searchData: undefined });
    }

    changePage = (page, pageSize) => {

        this.setState({
            filter: {
                filter: {
                    ...this.state.filter.filter,
                    pageNo: page,
                    pageSize: pageSize
                }

            },
            ewaybillData: undefined
        }, () => this.searchData(this.state.filter))
    }
    onShowSizeChange = (page, pageSize) => {
        this.setState({
            filter: {
                filter: {
                    ...this.state.filter.filter,
                    pageNo: page,
                    pageSize: pageSize
                }

            },
            ewaybillData: undefined
        }, () => this.searchData(this.state.filter))
    }

    render() {
        const { columns, ewaybillData, loading, selectedRowKeys, ewayPermission, showUpdatePanel, updateText, selectedDockets, selectedVehicle, selectedReason, updateLoading, reasons, vehicleError } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };



        return (
            <div className="partb-list">
                <Card
                >
                    <>
                        <Row gutter={[12, 12]}>
                            <Col span={4}>
                                <Select
                                    showSearch
                                    value={this.state.searchByData}
                                    style={{ width: '100%' }}
                                    placeholder="Search by"
                                    onSelect={this.changeSearchData}
                                    // onSelect={props.selectCustomer}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option
                                        value={"TRIP"}
                                        label={"Trip ID"}
                                        key={"Trip"}
                                    >
                                        {"Trip ID"}
                                    </Option>
                                    <Option
                                        value={"DOCKET"}
                                        label={"Docket No."}
                                        key={"Docket"}
                                    >
                                        {"Docket No."}
                                    </Option>

                                    <Option
                                        value={"EWAY"}
                                        label={"E-WayBill"}
                                        key={"Eway"}
                                    >
                                        {"E-WayBill"}
                                    </Option>



                                </Select>
                            </Col>
                            <Col span={8}>
                                <Input
                                    placeholder={"Search"}
                                    value={this.state.searchData}
                                    onPressEnter={this.searchEwaybills}
                                    onChange={this.changeEwayBill}
                                    allowClear
                                />
                            </Col>
                            <Col span={4}>
                                <Button
                                    block
                                    className="partb-list-search"
                                    loading={this.state.searchLoading}
                                    onClick={this.searchEwaybills}
                                >
                                    Search
                                </Button>
                            </Col>
                            <Col span={4} push={4}>
                                <Button
                                    block
                                    disabled={this.state.selectedRowKeys.length <= 0 || !ewayPermission.update}
                                    className="partb-list-success"
                                    onClick={this.goToUpdate}
                                // onClick={this.showUpdateModal}
                                >
                                    Update Vehicle
                                </Button>
                            </Col>
                        </Row>
                        {ewaybillData ?
                            <Row gutter={[0, 12]}>
                                <Col span={24}>
                                    <Table
                                        bordered
                                        locale={{
                                            emptyText: (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )
                                        }}
                                        size={"small"}
                                        rowSelection={rowSelection}
                                        pagination={false}
                                        loading={loading}
                                        scroll={{ x: 1250 }}
                                        rowKey={record => record.docketNo}
                                        dataSource={ewaybillData?.ewaybills}
                                        columns={columns} />
                                </Col>
                                <Col span={24}>
                                    <Pagination
                                        onChange={this.changePage}
                                        total={ewaybillData?.totalCount}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        pageSize={ewaybillData?.currentPageSize}
                                        current={ewaybillData?.currentPageNo}
                                        showSizeChanger
                                        onShowSizeChange={this.onShowSizeChange}
                                    />
                                </Col>
                            </Row>


                            : <Empty
                                image={null}
                                description={
                                    <span className="partb-list-empty">
                                        <Icon type="search" /> Search by Trip ID, Docket No or E-Waybill No
                                    </span>
                                }
                            />
                        }

                    </>
                </Card>

            </div >
        )
    }
}

export default PartB;
// const mapStateToProps = (state, ownProps) => ({
//     ewayBills: state?.eway?.ewayBills,
//     loading: state?.eway?.loading
// });
// const mapDispatchToProps = dispatch => ({
//     fetchEway: (from, to) => dispatch(actions.fetchEway(from, to)),
//     searchEway: (value) => dispatch(actions.searchEway(value))

// });
// export default connect(mapStateToProps, mapDispatchToProps)(EwayDashboard);