import { fetchVehicleTypes } from '../../common/lovStore/lovService';
import * as apiUtils from '../../common/utils/apiGateway';
import { BASE_URL_TYPES, Constants } from '../../common/utils/constants';
import { checkAPIHttpStatus, checkHttpStatus } from '../../common/utils/utils';

const parseParams = params => {
    return {
        entityIds: params.entityIds,
        pageNo: params?.pageNo ? params?.pageNo : 1,
        pageSize: params?.pageSize ? params?.pageSize : 10,
        searchNameOnly  : params.searchNameOnly,
        ...(params.isActive !== undefined && { isActive: params?.isActive })
    }
}

export const fetchShippers = params => new Promise(resolve => {
    if (params) {
        let data = {
            filter: {
                ...parseParams(params),
                ...(params?.partialContentSearch && { searchText: params?.partialContentSearch }),
                "fetchObjects": [
                    "ADDRESS"
                ]
            }
        };
        const url = `/b2b/v1/customer/shipper-mapping/fetchAll`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchCustomers = params => new Promise(resolve => {
    if (params) {
        let data = {
            filters: {
                // ...parseParams(params),
                ...(params?.partialContentSearch && { partialContentSearch: params?.partialContentSearch }),
                "customerTypes": [
                    "CUSTOMER"
                ],
                "status": [
                    "ACTIVE"
                ],
                "fetchMode": "BASIC",
                "contractAccess": true,
                partnerIds: [Constants.PARTNER_ID]
            }
        };
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchLocations = params => new Promise(resolve => {
    
    if (params) {
        let data = {
            filters: {
                ...parseParams(params),
                ...(params?.partialContentSearch && { searchText: params?.partialContentSearch }),

                entityTypes: ["CUSTOMER"],
            }
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetchAll`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        }).catch(response => {
            resolve(response);
        })
    }
    else {
        resolve(false);
    }
});
export const fetchHubLocations = params => new Promise(resolve => {
    
    if (params) {
        let data = {
            filters: {
                ...parseParams(params),
                ...(params?.partialContentSearch && { partialContentSearch: params?.partialContentSearch }),

                entityTypes: ["PARTNER"],
            }
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        }).catch(response => {
            resolve(response);
        })
    }
    else {
        resolve(false);
    }
});

export const fetchZones = params => new Promise(resolve => {
    if (params) {
        let data = {
            filters: {
                ...parseParams(params),
                ...(params?.partialContentSearch && { partialContentSearch: params?.partialContentSearch.trim() }),

                locationSpecific: false,
                fetchObjects: ["ZONE_MAPPING", "PINCODE"],
                zoneTypes: params.zoneTypes ? params.zoneTypes : ["RATECARD"]
            }
        }

        if (params.zoneTypes && params.zoneTypes.indexOf("OPERATIONAL") > -1) {
            data.filters.entityIds = [];
        }

        var url = params?.partialContentSearch ? `/b2b/v1/zone/search` : `/b2b/v1/zone/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchLanes = params => new Promise(resolve => {
    apiUtils.apiRequest({
        url         : "/util/v1/lanes/fetch",
        method      : "POST",
        baseUrlType : BASE_URL_TYPES.DEV,
        data        : params
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            resolve(false);
        }
    }).catch(response => {
        resolve(response);
    })
});

export const fetchCustomerOptions = (params, withCode = false) => fetchCustomers(params).then(resp => 
    resp?.customers.map(customer => ({
        value: withCode ? (customer.id + "___" + customer.code) : customer.id ,
        label: customer.name
    }))
);

export const fetchShipperOptions = params => fetchShippers(params).then(resp => 
    resp?.entityShipperMappingBOList.map(shipper => ({
        value: shipper.id,
        label: shipper.shipperName
    }))
);

export const fetchLocationOptions = params => fetchLocations(params).then(resp => 
    resp?.locations.map(location => ({
        value: location.id,
        label: location.name
    }))
).catch(e => [])

export const fetchLaneOptions = params => fetchLanes(params).then(resp => {
    if (resp && resp?.laneBOs) {
        return resp?.laneBOs.map(lanes => ({
            value: lanes.id,
            label: lanes.name
        }))
    }
    else {
        return [];
    }
}).catch(_ => []);

export const callVehicleTypesAPI = _ => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url     : "b2b/v1/vehicle-types/getAllVehicleTypes",
        method  : "POST",
        data    : { filter : { isActive : true } }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response)
        }
        else {
            reject([]);
        }
    })
    .catch(e => reject(e))
})

export const fetchVehicleTypesEnums = withId => new Promise((resolve, reject) => {
    fetchVehicleTypes().then(vehicleTypes => {
        var enums = [];
        var ids = [];
        var enumNames = [];
        vehicleTypes.map(vehicle => {
            const vehicleOption = vehicle.vehicleType + "_" + vehicle.bodyType;
            if ( enums.indexOf(vehicleOption) < 0) {
                enums.push(vehicleOption);
            }
            ids.push(vehicle.id);
            enumNames.push(vehicleOption);
        });
        if (withId) {
            resolve([ids, enumNames]);
        }
        else {
            resolve(enums);
        }
    }).catch(e => reject(false))
})

export const fetchLOVs = params => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url         : "/util/v1/lov/fetch",
        method      : "POST",
        baseUrlType : BASE_URL_TYPES.DEV,
        data        : params
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(false);
        }
    }).catch(e => reject(e))
})