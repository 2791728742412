
import * as apiUtils from '../../../../../common/utils/apiGateway';
import { checkHttpStatus, notifyApiError } from '../../../../../common/utils/utils';

export const fetchBoxTypes = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: `/b2b/v1/consignments/getBoxTypes`,
        method: "POST",
        data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    })
})

export const addUpdateBoxType = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: `/b2b/v1/customer/boxType`,
        method: "POST",
        data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    })
})
export const updateCustomerAttribute = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: `/b2b/v2/customers/attributes`,
        method: "POST",
        data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.response);
        }
    })
})

