import React, { Component } from 'react';
import './delivered-loads.scss';
import { fetchLiveLoads, fetchLiveLoadsSummary, exportLiveLoads, locationLiveLoads, downloadPOD } from './actions.js';
import { connect } from 'react-redux';
import { Card, Row, Col, Input, Form, Select, Table, Empty, DatePicker, Button, Spin, Radio, Tag, Icon, Checkbox, Divider, Modal, Tooltip } from 'antd';
import Sparkline from '../../ui/sparkline/Sparkline';
import moment from 'moment';
import { reportsDownload, checkForDownloadReportStatus } from '../../../common/utils/utils';
import { DownloadTags, Constants } from '../../../common/utils/constants';
import * as appActions from '../../../app/appAction';
import { Mixpanel } from '../../../utils/mixpanel';
import PODView from "../../../assets/img/icons/pod_view.svg"
import _ from 'lodash';
import { notifyApiError } from '../../../common/utils/utils';
import Slider from "react-slick";
import { saveAs } from 'file-saver';
import "slick-carousel/slick/slick.css";
import { canAllow } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';

import "slick-carousel/slick/slick-theme.css";



class NextArrow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { className, style, onClick } = this.props;

    return (
      <Icon type="right-circle"
        className={className}
        style={{ ...style, display: "block", color: "black", zIndex: 999, fontSize: 20 }}
        onClick={onClick}
      />
    )
  }
}

class PrevArrow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { className, style, onClick } = this.props;

    return (
      <Icon type="left-circle"
        className={className}
        style={{ ...style, display: "block", color: "black", zIndex: 999, fontSize: 20 }}
        onClick={onClick}
      />
    )
  }
}



export const filteredDocketStatus = [
  // {
  //   label: 'Pending',
  //   value: 'IN',
  //   isActive: true
  // },
  // {
  //   label: 'Intransit',
  //   value: 'IN_TRANSIT',
  //   isActive: true
  // },
  // {
  //   label: 'H-POD',
  //   value: 'HPOD',
  //   isActive: true
  // },
  // {
  //   label: 'All',
  //   value: 'All',
  //   isActive: true
  // },
  // {
  //   label: 'ALL',
  //   value: 'ALL',
  //   isActive: true
  // },
  {
    label: 'E-POD',
    value: 'EPOD',
    isActive: true
  },
  {
    label: 'Without E-POD',
    value: 'WEPOD',
    isActive: true
  },
  (canAllow(permissionTags.deliver_loads.deliver_loads.hpod) && {
    label: 'H-POD',
    value: 'HPOD',
    isActive: true
  })

];

export const deliveryStatus = [
  {
    label: 'None',
    value: 'ALL',
    isActive: true
  },
  {
    label: 'Yes',
    value: 'YES',
    isActive: true
  },
  {
    label: 'No',
    value: 'NO',
    isActive: true
  }
];

class DeliverLoads extends Component {
  constructor(props) {
    super(props);
    Mixpanel.track("Deliver loads page initialized", { 'deliverLoadsIdentity': moment().valueOf() });
    let _this = this;
    this.imageRef = React.createRef();
    this.state = {
      isCardExpanded: false,
      allLocations: [],
      allDeliveryLocations: [],
      settings: {
        afterChange: function (i) {
          _this.setState({ podImageIndex: i })
        },
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      },
      deliverLoadsPermission: {
        hpod: canAllow(permissionTags.deliver_loads.deliver_loads.hpod),
      },
      pageNo: 1,
      visibeEpod: false,
      consign: {},
      podImageIndex: 0,
      consignImages: [],
      showExportDownload: false,
      newFilter: {
        consignmentFilter: {
          "pickupDate": {
            from: moment().subtract(30, 'd').startOf('day').valueOf(),
            to: moment().endOf().valueOf()
          },
          customerPickupLocationIds: [],
          destinationLocationIds: []
        }
      },
      loading: false,
      docketStatuses: ['EPOD', 'WEPOD'],
      selectedAll: true,
      searchValue: 'docket',
      deliveryBreach: 'ALL',
      startDate: moment().subtract(30, 'd').startOf('day'),
      endDate: moment().endOf('day'),
      searchByValue: '',
      searchedValue: [],
      isEndDateOpen: false,
      filterPayload: {
        filters: {
          "pageNo": 1,
          "pageSize": 10,
          "consignmentFilter": {
            "statuses": [
              "DELIVERED"
            ],
            "pickupDate": {
              from: moment().subtract(30, 'd').startOf('day').valueOf(),
              to: moment().endOf().valueOf()
            },
            "entityTypes": [
              "NONE",
              "MANIFEST"
            ],
            "customerIds": [Constants.sessionUser.location.entityId],
            "partnerIds": [Constants.PARTNER_ID],
          },
          "deviceFilter": {
            "deviceType": "DESKTOP"
          },
          ...(canAllow(permissionTags.deliver_loads.deliver_loads.hpod) ? { "fetchObjects": ["CONSIGNMENT_POD", "CONSIGNMENT_POD_SCANS"] } : { "fetchObjects": ["CONSIGNMENT_POD"] })
        }
      },
      columns: [
        {
          title: 'Docket No.',
          dataIndex: 'waybillNo',
          key: 'waybillNo',
          fixed: 'left',
          width: 150,
          render: (data, row) => (<span style={{ color: '#2c5de5' }}><a className="docket-link" onClick={() => this.gotoTrackFrom(row, 'docket')}>{data}</a></span>)
        },
        {
          title: 'Origin',
          dataIndex: 'originLocation',
          key: 'originLocation',
          width: 135,
          render: data => (<span className="bold">{data}</span>)
        },
        {
          title: 'Destination',
          dataIndex: 'destinationLocation',
          key: 'destinationLocation',
          width: 110,
          render: data => (<span className="bold">{data}</span>)
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoiceNumber',
          key: 'invoiceNumber',
          render: (data, row) => (<span>{data}</span>)
        },
        {
          title: 'No. of Pieces',
          dataIndex: 'shipmentCount',
          key: 'shipmentCount',
          width: 80,
        },
        {
          title: 'Weight (Kgs)',
          dataIndex: 'weight',
          key: 'weight',
          width: 90,
          render: data => (<span className="bold">{data}</span>)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          render: data => <div className={`status-${data}`}>{data}</div>
        },
        {
          title: 'Breach Level',
          dataIndex: 'isBreached',
          key: 'isBreached',
          align: 'center',
          width: 110,
          render: (data, record) => {
            return <>
              {data ?
                <div>
                  {/* {data} */}
                  <div>
                    {record?.breachedLevel &&
                      record?.breachedLevel === 'High' ?
                      <Tooltip title={'breach hours > 3'}>
                        <Tag color="#fe6769">{record?.breachedLevel}</Tag>
                      </Tooltip> :
                      (record?.breachedLevel === 'Low' ?
                        <Tooltip title={'breach hours < 1'}>
                          <Tag color="#b6deb5">{record?.breachedLevel}</Tag>
                        </Tooltip>
                        : record?.breachedLevel === 'Moderate' ?
                          <Tooltip title={'breach hours between 1 - 3'}>
                            <Tag color="#ffd65c">{record?.breachedLevel}</Tag>
                          </Tooltip> :
                          record?.breachedLevel)
                    }
                  </div>

                </div>
                : <></>
              }


            </>

          }
        },
        {
          title: 'E-POD Availabile',
          dataIndex: 'isEPodAvailable',
          key: 'isEPodAvailable',
          align: 'center',
          width: 120,
          render: (data, row) => <div>{data ? <div><img style={{ cursor: 'pointer' }} width={20} src={PODView} onClick={() => this.openPod(data, row)} /></div> : <></>}</div>
        },
        {
          title: 'Booked On',
          dataIndex: 'pickupDate',
          key: 'pickupDate',
          width: 120,
          render: data => data ? <div> {moment(data).format('DD/MM/YYYY hh:mm A')} </div> : <></>
        },
        {
          title: 'Delivery Promised On',
          dataIndex: 'customerPromiseDate',
          key: 'customerPromiseDate',
          width: 110,
          render: data => data ? (<span className="bold">{moment(data).format('DD/MM/YYYY hh:mm A')}</span>) : <></>
        },
        {
          title: 'Delivered On',
          dataIndex: 'deliveredDate',
          key: 'deliveredDate',
          width: 120,
          render: data => data ? <div> {moment(data).format('DD/MM/YYYY hh:mm A')} </div> : <></>
        },
        {
          title: 'Action',
          dataIndex: 'docketNo.',
          key: 'docketNo',
          fixed: 'right',
          render: (data, row) => {
            return (
              <Button type='primary' onClick={() => this.gotoTrack(row)}>Track</Button>
            )
          }
        },
      ]
    }

    this.fetchLocation();
    if (Constants.sessionUser.userLevel === 'CUSTOMER_SUPER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_USER') {
      this.applyPagination({
        pageNo: 1, consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          customerPickupLocationIds: []
        }
      }, true);
    }
  }



  openPod = (data, row) => {

    Mixpanel.track("Deliver loads view POD", { 'deliverLoadsviewPOD': moment().valueOf(), waybill: row.waybillNo });

    let images = row.podImpLink;
    let consignImages = [];
    if (images.includes(",")) {
      consignImages = images.split(",");
    } else {
      consignImages[0] = images;
    }
    this.setState({ visibeEpod: true, consign: row, consignImages, podImageIndex: 0 })
  }






  componentDidUpdate(prevProps) {
    if (this.props.locationList !== prevProps.locationList) {
      const level = Constants.sessionUser.userLevel;
      this.setState({
        filterPayload: {
          ...this.state.filterPayload,
          filters: {
            ...this.state.filterPayload.filters,
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              customerPickupLocationIds: (level === 'CUSTOMER_SUPER_ADMIN' || level === 'CUSTOMER_ADMIN' || level === 'CUSTOMER_USER') ? [] : this.props.locationList.PICKUP.map(item => (item.id))
            }
          }
        }
      })

      if (this.props.locationList && this.state.allLocations.length <= 0) {
        let allLocations = this.props.locationList && this.props.locationList['PICKUP'].map(list => {
          return {
            id: list.id,
            name: list.name,
            selected: false
          }
        });

        this.setState({ allLocations })
      }

      if (this.props.locationList && this.state.allDeliveryLocations.length <= 0) {
        let allDeliveryLocations = this.props.locationList && this.props.locationList['DELIVERY'].map(list => {
          return {
            id: list.id,
            name: list.name,
            selected: false
          }
        });

        this.setState({ allDeliveryLocations })
      }
      if (!(Constants.sessionUser.userLevel === 'CUSTOMER_SUPER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_USER')) {
        this.applyPagination({
          pageNo: 1, consignmentFilter: {
            ...this.state.filterPayload.filters.consignmentFilter,
            customerPickupLocationIds: this.props.locationList.PICKUP.map(item => (item.id))
          }
        }, true);
      }
    }
    if ((this.props.downloadStatus === "SUCCESS" || this.props.downloadStatus === "FAILURE") && this.state.loading) {
      this.setState({ loading: false, showExportDownload: false })
    }
    if (this.props.exportData !== prevProps.exportData) {
      if (this.props?.exportData?.url) {
        const prop = {
          setS3SyncStatus: this.props.setS3SyncStatus,
          uploadedFileResp: {
            responseUrl: this.props.exportData.url
          }
        }
        checkForDownloadReportStatus(prop, "Downloaded Successfully", "Success");
        // reportsDownload(this.props.exportData.url, DownloadTags.s3)
      } else {
        this.setState({ loading: false, showExportDownload: false })
      }
    }

    if (this.props.downladPODData !== prevProps.downladPODData) {
      if (this.props?.downladPODData?.url) {
        // reportsDownload(this.props.downladPODData.url, DownloadTags.s3)
        const prop = {
          setS3SyncStatus: this.props.setS3SyncStatus,
          uploadedFileResp: {
            responseUrl: this.props?.downladPODData?.url
          }
        }
        checkForDownloadReportStatus(prop, "Downloaded Successfully", "Success");
        // reportsDownload(this.props.exportData.url, DownloadTags.s3)
      } else {
        notifyApiError('No data Found, Please change filters', 'Error');
        this.setState({ loading: false, showExportDownload: false })
      }
    }



  }

  gotoTrack = (row) => {
    Mixpanel.track("Deliver loads to track", { 'deliverLoadsTrack': moment().valueOf() });
    this.props.history.push('/appv2/customer-tracking/dashboard', { waybillNo: row.waybillNo, searchedBy: this.state.searchValue })
  }

  gotoTrackFrom = (row, searchBy) => {
    Mixpanel.track("Deliver loads to track", { 'deliverLoadsTrack': moment().valueOf() });
    if (searchBy == 'docket') {
      this.props.history.push('/appv2/customer-tracking/dashboard', { waybillNo: row.waybillNo, searchedBy: searchBy })
    } else {
      this.props.history.push('/appv2/customer-tracking/dashboard', { invoiceNumber: row.invoiceNumber, searchedBy: searchBy })
    }

  }

  endConsign = () => {
    this.setState({ visibeEpod: false })
  }

  fetchLocation = () => {
    // const locationPayload = {
    //   "filters": {
    //     "entityTypes": [
    //       "CUSTOMER"
    //     ],
    //     "entityId": [Constants.sessionUser.location.entityId],
    //     "status": true,
    //     "roles": ["PICKUP", "DELIVERY"]
    //   }
    // };

    let level = Constants.sessionUser.userLevel;
    let locationPayload = {};
    if (level === 'CUSTOMER_SUPER_ADMIN' || level === 'CUSTOMER_ADMIN') {
      locationPayload = {
        "filters": {
          "deviceFilter": {
            "view": "LOCATION_LIST_ERP"
          },
          // "linkedUsers": [Constants.sessionUser.id],
          "status": true,
          "roles": ["PICKUP", "DELIVERY"],
          "isConsiderCreatorId": false,
          "entityTypes": [
            "CUSTOMER"
          ],
          "entityIds": [Constants.sessionUser.location.entityId],
          "deviceType": "DESKTOP",
          fetchObjects: []
        }
      }
    } else {
      locationPayload = {
        "filters": {
          "deviceFilter": {
            "view": "LOCATION_LIST_ERP"
          },
          "linkedUsers": [Constants.sessionUser.id],
          "status": true,
          "roles": ["PICKUP", "DELIVERY"],
          "isConsiderCreatorId": false,
          "entityTypes": [
            "CUSTOMER"
          ],
          "entityIds": [Constants.sessionUser.location.entityId],
          "deviceType": "DESKTOP",
          fetchObjects: []
        }
      }
    }

    this.props.locationLiveLoads(locationPayload);
  }


  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    let data = {};
    if (_.isEmpty(filters)) {
      data = { filters: { ...this.state.filterPayload.filters } };
    } else {
      data = { filters: { ...this.state.filterPayload.filters, ...filters } };
    }
    Mixpanel.track("Deliver Loads Filter Applied", { 'deliverLoadsFilter': moment().valueOf() })
    // data = { filters: { ...this.state.filterPayload.filters, ...filters } };
    this.props.fetchLiveLoads(data, isReset);
    this.props.fetchLiveLoadsSummary({ filters: data.filters });
  }

  reloadCustomerList = () => {
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo) => {
    const { PaginatedData, } = this.props;
    if (!PaginatedData ? !PaginatedData : !PaginatedData[pageNo]) {
      this.applyPagination({
        pageNo,
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
        }
      })
    }
    this.setState({
      pageNo
    });
  }

  onChange = (e) => {
    if (e.target.checked) {
      this.setState({ docketStatus: { statuses: filteredDocketStatus.map(v => v.value) }, selectedAll: e.target.checked });
      this.handleDocketStatusChange(filteredDocketStatus.map(v => v.value))
    } else {
      this.setState({ docketStatus: { statuses: [] } });
      this.handleDocketStatusChange([])
    }
  }

  handleDocketStatusChange = val => {
    val = val.filter(v => v != undefined);
    let newVal = [...val];
    newVal = newVal.filter(v => v != undefined);
    let isPOD = null, isHPOD = false;
    // if ((val.indexOf('EPOD') >= 0 && val.indexOf('WEPOD') >= 0 && val.indexOf('HPOD') >= 0)) {
    if ((val.indexOf('EPOD') >= 0 && val.indexOf('WEPOD') >= 0)) {
      newVal = filteredDocketStatus.map(v => v.value)
      newVal = newVal.filter(v => v != undefined);
      delete this.state.filterPayload.filters.consignmentFilter.isPodDone
      this.setState({ selectedAll: true })
    } else {
      this.setState({ selectedAll: false })
      if (val.length === 0) {
        newVal = filteredDocketStatus.filter(v => v.value !== 'HPOD').map(v => v.value)
        newVal = newVal.filter(v => v != undefined);
        val = [...newVal];
        this.setState({ selectedAll: true })
      }
      if (val.indexOf("EPOD") >= 0 && val.indexOf('WEPOD') >= 0) {
        isPOD = null;
      } else if (val.indexOf('EPOD') >= 0) {
        isPOD = true
      } else {
        isPOD = false;
      }
    }
    // if (val.indexOf('ALL') >= 0) {
    //   newVal = filteredDocketStatus.map(v => v.value);
    //   isPOD = null;
    // }




    this.setState({ docketStatuses: newVal });

    let payload = {};
    if (isPOD !== null) {
      payload = {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          isPodDone: isPOD,
          ...(canAllow(permissionTags.deliver_loads.deliver_loads.hpod) && { isPhysicalScanDone: (val.indexOf('HPOD') >= 0) })

        }
      }

    } else {
      const data = {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...this.state.newFilter.consignmentFilter
      };
      delete data.isPodDone
      payload = {
        consignmentFilter: {
          ...data,
          ...(canAllow(permissionTags.deliver_loads.deliver_loads.hpod) && { isPhysicalScanDone: (val.indexOf('HPOD') >= 0) })
        }
      }
    }


    this.setState({
      newFilter: { ...payload }
    }, this.applyPagination({ ...payload }, true))
  }

  handleDeliveryStatusChange = val => {
    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...this.state.newFilter.consignmentFilter
      }
    };
    if (val === 'YES') {
      payload.consignmentFilter.isDeliveryBreached = true
    } else if (val === 'NO') {
      payload.consignmentFilter.isDeliveryBreached = false
    } else {
      delete payload.consignmentFilter.isDeliveryBreached;
    }
    const ValueSet = {
      filterPayload: {
        ...this.state.filterPayload,
        filters: {
          ...this.state.filterPayload.filters,
          ...payload
        }
      }
    }
    if (val === 'ALL') {
      delete ValueSet.filterPayload.filters.consignmentFilter.isDeliveryBreached
    }



    this.setState({
      newFilter: { ...payload },
      deliveryBreach: val
    }, this.applyPagination({ ...payload }, true))
  }

  handleSearch = val => {
    let filter = {};
    if (val === this.state.searchedValue.join(',')) return false
    if (!val) {
      val = []
    } else
      val = val.split(',').map(v => (v.trim()))
    if (this.state.searchValue == 'docket') {
      filter = {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          waybills: val
        }
      }
    } else {
      filter = {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          invoiceNumbers: val
        }
      }
    }


    if (val.length === 0) {
      delete filter.consignmentFilter.waybills;
      delete filter.consignmentFilter.invoiceNumbers;
    }

    this.setState({ newFilter: filter, searchedValue: val });
    this.applyPagination(filter, true)
  }

  exportData = () => {
    this.props.setS3SyncStatusReset();
    this.setState({ loading: true, showExportDownload: true });
    Mixpanel.track("Delivery loads export initialized", { 'deliverLoadsExport': moment().valueOf() });
    let { consignmentFilter } = this.state.newFilter;


    // if (_.isEmpty(consignmentFilter)) {
    //   consignmentFilter = this.state.filterPayload.filters.consignmentFilter;
    // }

    this.props.exportLiveLoads({
      consignmentFilter: {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...consignmentFilter
      },
      "fetchObjects": this.state.filterPayload.filters.fetchObjects
    })
  }

  downloadPOD = () => {
    Mixpanel.track("Deliver loads download POD", { 'deliverLoadsDownload': moment().valueOf() });
    this.setState({ showExportDownload: true });
    let { consignmentFilter } = this.state.newFilter;
    // let consignmentNewFilter =  {
    //   "statuses": consignmentFilter.statuses ? consignmentFilter.statuses : this.state.filterPayload.filters.consignmentFilter.statuses,
    //   "isPodDone": consignmentFilter.isPodDone ? consignmentFilter.isPodDone : false,
    //   "isPhysicalScanDone": consignmentFilter.isPhysicalScanDone ? consignmentFilter.isPhysicalScanDone : false,
    //   "isDeliveryBreached": consignmentFilter.isDeliveryBreached ? consignmentFilter.isDeliveryBreached : false,
    //   "invoiceNumbers": consignmentFilter.invoiceNumbers ? consignmentFilter.invoiceNumbers : [],
    //   "destinationLocationIds": consignmentFilter.destinationLocationIds ? consignmentFilter.destinationLocationIds : []
    // }



    this.props.setS3SyncStatusReset();
    let params = {
      "partnerId": Constants.PARTNER_ID,
      "waybills": consignmentFilter.waybills ? consignmentFilter.waybills : this.state.filterPayload.filters.consignmentFilter.waybills,
      // "locationId": Constants.LOGGED_PARTNER_LOCATION_ID,
      "userId": Constants.sessionUser.id,
      "isDatePickup": true,
      "partnerIds": [
        Constants.PARTNER_ID
      ],
      // "customerPickupLocationIds": this.state.newFilter?.consignmentFilter?.customerPickupLocationIds ? [...this.state.newFilter?.consignmentFilter?.customerPickupLocationIds] : [],
      "customerPickupLocationIds": consignmentFilter.customerPickupLocationIds,
      "customerIds": consignmentFilter.customerIds,
      "dateRange": {
        "from": this.state.startDate.valueOf(),
        "to": this.state.endDate.valueOf()
      },
      "documentType": "CONSIGNMENT_POD",
      "documentSubType": null,
      "drsType": "B2B",
      "appendCustomer": true,
      // "locationIds": [
      //   Constants.LOGGED_PARTNER_LOCATION_ID
      // ],
      "loggedInUserTokken": true,
      "consignmentLiveLoadFilters": {
        "consignmentFilter": {
          "statuses": consignmentFilter.statuses ? consignmentFilter.statuses : this.state.filterPayload.filters.consignmentFilter.statuses,
          // "isPodDone": consignmentFilter.isPodDone ? consignmentFilter.isPodDone : false,
          // "isPhysicalScanDone": consignmentFilter.isPhysicalScanDone ? consignmentFilter.isPhysicalScanDone : false,
          // "isDeliveryBreached": consignmentFilter.isDeliveryBreached ? consignmentFilter.isDeliveryBreached : false,
          "invoiceNumbers": consignmentFilter.invoiceNumbers ? consignmentFilter.invoiceNumbers : [],
          "destinationLocationIds": consignmentFilter.destinationLocationIds ? consignmentFilter.destinationLocationIds : [],
          ...(consignmentFilter.isPodDone && consignmentFilter.isPodDone == true || consignmentFilter.isPodDone == false && { isPodDone: consignmentFilter.isPodDone }),
          ...(canAllow(permissionTags.deliver_loads.deliver_loads.hpod) && consignmentFilter.isPhysicalScanDone && consignmentFilter.isPhysicalScanDone == true || consignmentFilter.isPhysicalScanDone == false && { isPhysicalScanDone: consignmentFilter.isPhysicalScanDone }),
          ...(consignmentFilter.isDeliveryBreached && consignmentFilter.isDeliveryBreached == true || consignmentFilter.isDeliveryBreached == false && { isDeliveryBreached: consignmentFilter.isDeliveryBreached }),
        }
      }
    }
    // console.log("params->>", params);
    this.props.downloadPOD(params);
    this.setState({ loading: true });

  }

  disabledStartDate = startValue => {
    const { startDate } = this.state;
    if (!startValue || !startDate) {
      return false;
    }

    return startValue.valueOf() && startValue.valueOf() > moment().endOf('day');
    // return startValue.valueOf() > startDate.valueOf();
  };

  disabledEndDate = endValue => {
    if (this.state.startDate) {
      const dayStart = moment(this.state.startDate);
      const dayEnd = moment(this.state.startDate).add(31, 'days');
      if (dayEnd.isAfter(moment().endOf('day'))) {
        return !(dayStart.isSameOrBefore(endValue) && endValue <= moment().endOf('day') && endValue >= moment(this.state.startDate).endOf('day'));
      } else {
        return !(dayStart.isSameOrBefore(endValue) && dayEnd.isAfter(endValue) && endValue.isSameOrBefore(dayEnd));
      }


    } else {
      // return current && current > moment().endOf('day');
    }
  }


  disabledDate = current => {

    if (this.state.startDate) {
      const dayStart = moment(this.state.startDate).add(-31, 'days');
      const dayEnd = moment(this.state.startDate).add(31, 'days');
      if (dayEnd.isAfter(moment().endOf('day'))) {
        return !(dayStart.isSameOrBefore(current) && current < moment().endOf('day'));
      } else {
        return !(dayStart.isSameOrBefore(current) && dayEnd.isAfter(current));
      }


    } else {
      // return current && current > moment().endOf('day');
    }

  }

  handleFilterSet = (key, value, field, fieldValue) => {

    if (key == 'customerPickupLocationIds' && value == 'All') {

      let level = Constants.sessionUser.userLevel;
      if (level === 'CUSTOMER_SUPER_ADMIN') {
        this.setState({
          newFilter: {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
              [key]: []
            }
          }
        });
      } else {
        let locations = this.props.locationList['PICKUP'].map(list => {
          return list.id;
        });
        this.setState({
          newFilter: {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
              [key]: locations
            }
          }
        });

      }
    } else {
      if (key === 'destinationLocationIds') {
        if (value.length > 0) {
          let selectedValues = this.state.allDeliveryLocations.map(list => {
            if (value.includes(list.id)) {
              list = {
                ...list,
                selected: true
              }

              return list;
            } else {
              list = {
                ...list,
                selected: false
              }
              return list
            }
          });
          var sortedLocations = selectedValues.sort((a, b) => {
            return b.selected - a.selected;
          });

          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            },
            allDeliveryLocations: [...sortedLocations]
          });
        } else {
          let { newFilter } = this.state;
          let { consignmentFilter } = newFilter;
          if (_.hasIn(consignmentFilter, 'destinationLocationIds')) {
            delete consignmentFilter['destinationLocationIds'];

          }

          newFilter = {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
            }
          }

          this.setState({ newFilter })


        }

      } else {
        if (key == 'customerPickupLocationIds') {
          let selectedValues = this.state.allLocations.map(list => {
            if (value.includes(list.id)) {
              list = {
                ...list,
                selected: true
              }

              return list;
            } else {
              list = {
                ...list,
                selected: false
              }
              return list
            }
          });
          var sortedLocations = selectedValues.sort((a, b) => {
            return b.selected - a.selected;
          });
          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            },
            allLocations: [...sortedLocations]
          });
        } else {
          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            }
          });
        }

      }

    }

    if (field && fieldValue) {
      this.onChangeDate(field, fieldValue)
    }

  }

  onChangeDate = (field, value) => {
    // console.log("startDate->>", startDate)
    // let { startDate } = this.state;
    // startDate = startDate.valueOf();
    // console.log("startDate->>", startDate)
    this.setState({ [field]: value, })
  }

  onDateChange = (date, dateString) => {
    if (date[0]) {
      this.setState({ startDate: date[0], endDate: date[1] })
    }
  }

  onChangeSearch = e => {
    this.setState({ searchValue: e.target.value })
  }

  changeSearch = e => {
    this.setState({ searchByValue: e.target.value })
  }

  applyfilters = () => {
    this.setState({
      docketStatuses: ['EPOD', 'WEPOD'],
      searchValue: 'docket',
      searchByValue: '',
      searchedValue: [],
      deliveryBreach: 'ALL'
    })
    const { filterPayload, newFilter } = this.state;
    delete filterPayload.filters.consignmentFilter.isDeliveryBreached
    delete newFilter.consignmentFilter.isDeliveryBreached
    delete filterPayload.filters.consignmentFilter.waybills
    delete newFilter.consignmentFilter.waybills
    delete filterPayload.filters.consignmentFilter.invoiceNumbers
    delete newFilter.consignmentFilter.invoiceNumbers
    delete filterPayload.filters.consignmentFilter.isPhysicalScanDone
    delete newFilter.consignmentFilter.isPhysicalScanDone
    delete filterPayload.filters.consignmentFilter.isPodDone
    delete newFilter.consignmentFilter.isPodDone

    this.applyPagination({
      consignmentFilter: {
        ...filterPayload.filters.consignmentFilter,
        ...newFilter.consignmentFilter,

      }
    }, true)
  }

  resetFilters = e => {
    this.setState({
      docketStatuses: ['EPOD', 'WEPOD'],
      selectedAll: true,
      searchValue: 'docket',
      startDate: moment().subtract(30, 'd').startOf('day'),
      endDate: moment().endOf('day'),
      searchByValue: '',
      searchedValue: [],
      deliveryBreach: 'ALL',
      newFilter: {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter,
          "pickupDate": {
            from: moment().subtract(30, 'd').startOf('day').valueOf(),
            to: moment().endOf().valueOf()
          },
          customerPickupLocationIds: [],
          destinationLocationIds: []
        }
      }
    }, () => {
      this.applyPagination({ ...this.state.newFilter }, true)
    })
  }

  download = (img) => {
    Mixpanel.track("Deliver loads download clicked", { 'deliverLoadsDownloadPOD': moment().valueOf() });

    fetch(img, {
      headers: {
        'Content-Type': 'image/*',
        'Content-Disposition': 'attachment; filename=image.jpg',
        'Accept': 'image/*',
        'responseType': 'blob'
      }
    }).then(response => {
      if (_.hasIn(response, 'config')) {
        saveAs(response.config.url, "downlaod");
      } else {
        saveAs(response.url, "downlaod");
      }

    })
  }

  render() {
    const { columns, pageNo, isCardExpanded, isEndDateOpen, newFilter, startDate, endDate, filterPayload } = this.state;
    const { PaginatedData, liveLoadsData, locationList, summaryList } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      labelAlign: "left",
      colon: false
    };
    return (
      <div className="LiveLoads">
        <Card>
          {
            isCardExpanded ? (
              <>
                {/* <Tooltip placement="top" title={"Colapase Filters"}>
                <Button style={{ float: 'right', zIndex: 5 }} onClick={e => this.setState({ isCardExpanded: !isCardExpanded })}> <Icon type={'shrink'} /> </Button>
              </Tooltip> */}
                <Form {...formItemLayout}>
                  <Form.Item colon={false} label="Booking Date" wrapperCol={{ span: 12 }}>
                    <Input.Group style={{ display: 'inline' }}>
                      {/* <DatePicker.RangePicker onCalendarChange={this.onDateChange} disabledDate={this.disabledDate} value={[startDate, endDate]} onChange={value => this.handleFilterSet('bookingDate', { from: value[0].valueOf(), to: value[1].valueOf() })} /> */}
                      <Row>
                        <Col span={12} style={{ bottom: 5 }}>
                          <span className="form-label-text">From </span>
                          <DatePicker
                            disabledDate={this.disabledStartDate}
                            format="YYYY-MM-DD"
                            value={startDate}
                            placeholder="From"
                            style={{ width: '95%' }}
                            // onOk={this.onStartDateChange}
                            onChange={value => { this.handleFilterSet('pickupDate', { from: value.valueOf(), to: endDate.valueOf() }, 'startDate', value) }}
                            onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                          // onOpenChange={this.handleStartOpenChange}
                          />
                        </Col>
                        <Col span={12} style={{ bottom: 5 }}>
                          <span className="form-label-text">To </span>
                          <DatePicker
                            disabledDate={this.disabledEndDate}
                            format="YYYY-MM-DD"
                            value={endDate}
                            placeholder="To"
                            style={{ width: '95%' }}
                            // onOk={this.onEndDateChange}
                            open={isEndDateOpen}
                            onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                            onChange={value => {

                              this.handleFilterSet('pickupDate', { from: startDate.valueOf(), to: value.valueOf() }, 'endDate', value)
                            }
                            }

                          // onOpenChange={this.handleStartOpenChange}
                          />
                        </Col>
                      </Row>


                      {/* <span style={{ marginLeft: '5px' }}></span>
                    <DatePicker onChange={this.onChange} /> */}
                    </Input.Group>
                  </Form.Item>
                  <Form.Item colon={false} label="Origin (Pickup)" wrapperCol={{ span: 6 }}>
                    <Select placeholder="All Location" mode='multiple' value={newFilter.consignmentFilter?.customerPickupLocationIds} onChange={value => this.handleFilterSet('customerPickupLocationIds', value)} style={{ width: '95%' }} showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {/* <Select.Option value={'All'}>All Location</Select.Option> */}
                      {
                        this.state.allLocations && this.state.allLocations.map(list => (
                          <Select.Option value={list.id}>
                            <Tooltip title={list.name}>
                              {list.name}
                            </Tooltip>
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item colon={false} label="Destination" wrapperCol={{ span: 6 }}>
                    <Select placeholder="All Location" mode='multiple' value={newFilter.consignmentFilter?.destinationLocationIds} onChange={value => this.handleFilterSet('destinationLocationIds', value)} style={{ width: '95%' }} showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        this.state.allDeliveryLocations && this.state.allDeliveryLocations.map(list => (
                          <Select.Option value={list.id}>
                            <Tooltip title={list.name}>
                              {list.name}
                            </Tooltip>
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col span={3} offset={4}>
                      <Button onClick={e => this.applyfilters()} type="primary">Apply Filters</Button>
                    </Col>
                    <Col span={3}>
                      <Button onClick={e => this.resetFilters(e)} style={{ width: 104, backgroundColor: '#f2f2f5' }}>Clear</Button>
                    </Col>
                    <Col span={3} offset={10}>
                      <div style={{ position: 'relative', left: 40 }}>
                        <a style={{ float: 'right', zIndex: 5, color: '#1e6ee3' }} onClick={e => this.setState({ isCardExpanded: !isCardExpanded })}> Show Less </a>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </>
            )
              : (
                <Row>
                  <Col span={2}>
                    <Form.Item colon={false} label="Booking From">
                      {/* <DatePicker.RangePicker onCalendarChange={this.onDateChange} disabledDate={this.disabledDate} value={[startDate, endDate]} onChange={value => this.handleFilterSet('bookingDate', { from: value[0].valueOf(), to: value[1].valueOf() })} /> */}

                      <DatePicker
                        disabledDate={this.disabledStartDate}

                        format="YYYY-MM-DD"
                        value={startDate}
                        placeholder="From"

                        style={{ width: "150px" }}
                        // onOk={this.onStartDateChange}
                        onChange={value => { this.handleFilterSet('pickupDate', { from: value.valueOf(), to: endDate.valueOf() }, 'startDate', value) }}
                        onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                      // onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true, endDate: moment(startDate).endOf('day') }) : {}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={2}>
                    <Form.Item colon={false} label="Booking To">
                      <DatePicker
                        disabledDate={this.disabledEndDate}

                        format="YYYY-MM-DD"
                        value={endDate}
                        placeholder="To"
                        style={{ width: "150px" }}
                        open={isEndDateOpen}
                        onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                        // onOk={this.onEndDateChange}
                        onChange={value => {

                          this.handleFilterSet('pickupDate', { from: startDate.valueOf(), to: value.valueOf() }, 'endDate', value)
                        }
                        }


                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={2}>
                    <Form.Item colon={false} label="Origin (Pickup)">
                      <Select style={{ width: '150px' }} placeholder="All Location" mode='multiple' value={newFilter.consignmentFilter?.customerPickupLocationIds} onChange={value => this.handleFilterSet('customerPickupLocationIds', value)} maxTagCount="1" showSearch maxTagTextLength="5"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {/* <Select.Option value={'All'}>All Location</Select.Option> */}
                        {
                          this.state.allLocations && this.state.allLocations.map(list => (
                            <Select.Option value={list.id}>
                              <Tooltip title={list.name}>
                                {list.name}
                              </Tooltip>
                            </Select.Option>
                          ))
                        }
                      </Select>
                      {/* <Input readOnly style={{ width: "150px" }} disabled value="All Location" suffix={<>{newFilter.consignmentFilter?.customerPickupLocationIds?.length > 0 ? <Tag>{newFilter.consignmentFilter?.customerPickupLocationIds?.length}</Tag> : <></>}</>} /> */}
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={2}>
                    <Form.Item colon={false} label="Destinations">
                      <Select style={{ width: '150px' }} mode='multiple' placeholder="All Location" value={newFilter.consignmentFilter?.destinationLocationIds} onChange={value => this.handleFilterSet('destinationLocationIds', value)} maxTagCount="1" showSearch maxTagTextLength="5"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>

                        {
                          this.state.allDeliveryLocations && this.state.allDeliveryLocations.map(list => (
                            <Select.Option value={list.id}>
                              <Tooltip title={list.name}>
                                {list.name}
                              </Tooltip>
                            </Select.Option>
                          ))
                        }
                      </Select>
                      {/* <Input readOnly style={{ width: "150px" }} disabled value="All Location" suffix={<>{newFilter.consignmentFilter?.destinationLocationIds?.length > 0 ? <Tag>{newFilter.consignmentFilter?.destinationLocationIds?.length}</Tag> : <></>}</>} /> */}

                    </Form.Item>
                  </Col>
                  {/* <Col span={5}>
                    <Button onClick={e => this.applyPagination({ ...newFilter }, true)} type="primary">Apply Filters</Button>
                  </Col> */}
                  <Col span={1} push={2}>
                    <Form.Item colon={false} label="&nbsp;">
                      <Button style={{ marginLeft: 5 }} onClick={e => this.applyfilters()} type="primary">Apply Filters</Button>

                    </Form.Item>
                  </Col>
                  <Col span={2} push={3}>
                    <Form.Item colon={false} label="&nbsp;">
                      <Button onClick={e => this.resetFilters(e)} style={{ width: 104, backgroundColor: '#f2f2f5', marginLeft: 50 }}>Clear</Button>
                    </Form.Item>
                  </Col>
                  <Col span={4} push={4}>

                    <Form.Item colon={false} label="&nbsp;">
                      {/* <Row gutter={24}>
                      <Col span={16}>
                        <Button block onClick={e => this.applyPagination({ consignmentFilter:{...filterPayload.filters.consignmentFilter,...newFilter.consignmentFilter} }, true)} type="primary">Apply Filters</Button>
                      </Col>
                      <Col span={2}>
                        <Tooltip placement="top" title={"Expand Filters"}>
                          <Button onClick={e => this.setState({ isCardExpanded: true })}><Icon type="arrows-alt" /></Button>
                        </Tooltip>
                      </Col>
                    </Row> */}
                      <a onClick={e => this.setState({ isCardExpanded: true })} style={{ color: '#1e6ee3', marginLeft: 50 }}>Show More</a>
                    </Form.Item>
                  </Col>

                </Row>
              )
          }
        </Card>
        <Card className="margin-10">
          <Row>
            <Col span={10}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Search By">
                <Row>
                  <Col span={12}>
                    <Radio.Group onChange={this.onChangeSearch} value={this.state.searchValue}>
                      <Radio value={"docket"}>Docket No.</Radio>
                      <Radio value={"invoice"}>Invoice No.</Radio>
                    </Radio.Group>
                  </Col>
                  <Col span={12} pull={0}>
                    <Input.Search value={this.state.searchByValue} onChange={this.changeSearch} placeholder={`Search by ${this.state.searchValue} no.`} onSearch={this.handleSearch} enterButton allowClear />
                  </Col>
                </Row>


              </Form.Item>
            </Col>
            <Col span={3} push={1}>
              <Form.Item labelCol={{ span: 24, pull: 2 }} wrapperCol={{ span: 24, pull: 2 }} style={{ marginTop: -5 }} label="Delivery Breach">
                <Select showArrow style={{ width: '100%' }} value={this.state.deliveryBreach} maxTagCount="1" onChange={this.handleDeliveryStatusChange}>
                  {
                    deliveryStatus.filter(s => s.isActive).map(status => (
                      <Select.Option value={status.value}>{status.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} push={2}>
              <Form.Item labelCol={{ span: 24, pull: 8 }} wrapperCol={{ span: 24, pull: 8 }} style={{ marginTop: -5 }} label="POD Status">
                {/* <Select value={this.state.filterPayload.filters.consignmentFilter.statuses} style={{ width: '100%' }} onChange={this.handleDocketStatusChange} defaultValue="All" mode="multiple" maxTagCount="1" maxTagPlaceholder={<>{this.state.filterPayload.filters.consignmentFilter.statuses.length}</>}>
                  {
                    docketStatus.filter(s => s.isActive).map(status => (
                      <Select.Option value={status.value}>{status.label}</Select.Option>
                    ))
                  }
                </Select> */}
                <Select showArrow value={this.state.docketStatuses} style={{ width: '175px' }} onChange={this.handleDocketStatusChange} mode="multiple" maxTagCount="1"
                  dropdownRender={menu => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div onMouseDown={e => e.preventDefault()}>
                        <Checkbox onClick={this.onChange} onChange={this.onChange} checked={this.state.selectedAll} defaultChecked>Select All</Checkbox>

                      </div>
                    </div>
                  )}
                >
                  {
                    filteredDocketStatus.filter(s => s.isActive).map(status => (
                      <Select.Option value={status.value}>{status.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} push={2}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
                <Button style={{ color: 'white', bottom: 5, marginLeft: 15 }} disabled={liveLoadsData.totalCount == 0} type="primary" onClick={this.downloadPOD} >Download PODs</Button>
              </Form.Item>
            </Col>
            <Col span={2} push={2}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
                <Button style={{ marginLeft: 30, bottom: 5 }} disabled={liveLoadsData.totalCount == 0} onClick={this.exportData} ><Icon type="download" />Export</Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            {/* <Col span={6}>
            <Spin spinning={liveLoadsData?.loading}>
              <Sparkline
                title="Pending"
                highlightText={<span style={{ color: '#e86825' }}> {summaryList?.length ? summaryList.filter(item => item.status === 'PENDING')[0]?.count : '0'} </span>}
              />
            </Spin>
          </Col>
          <Col span={6}>
            <Spin spinning={liveLoadsData?.loading}>
              <Sparkline
                title="Intransit"
                highlightText={<span style={{ color: '#2c5de5' }}>{summaryList?.length ? summaryList.filter(item => item.status === 'IN_TRANSIT')[0]?.count : '0'}</span>}
              />
            </Spin>
          </Col> */}
            <Col span={6}>
              <Spin spinning={liveLoadsData?.deliverSummaryloading}>
                <Sparkline
                  title="Delivered"
                  highlightText={<span style={{ color: '#e86825' }}> {summaryList?.length ? summaryList.filter(item => item.status === 'DELIVERED')[0]?.count : '0'} </span>}
                />
              </Spin>
            </Col>
            <Col span={6}>
              <Spin spinning={liveLoadsData?.deliverSummaryloading}>
                <Sparkline
                  title="E-POD"
                  highlightText={<span style={{ color: '#2c5de5' }}>{summaryList?.length ? summaryList.filter(item => item.status === 'EPOD')[0]?.count : '0'}</span>}
                />
              </Spin>
            </Col>
            {canAllow(permissionTags.deliver_loads.deliver_loads.hpod) &&
              <Col span={6}>
                <Spin spinning={liveLoadsData?.deliverSummaryloading}>
                  <Sparkline
                    title="H-POD"
                    highlightText={<span style={{ color: '#008556' }}>{summaryList?.filter(item => item.status === 'HPOD')?.length ? summaryList.filter(item => item.status === 'HPOD')[0]?.count : '0'}</span>}
                  />
                </Spin>
              </Col>
            }

            <Col span={6}>
              {/* <Spin spinning={liveLoadsData?.loading}>
                <Sparkline
                  title="Exp. Delivery Compliance"
                  highlightText={'100%'}
                  infoText="Exp. Delivery Compliance"
                />
              </Spin> */}
            </Col>
            <Col span={24} className="margin-10">
              <Table
                bordered
                locale={{
                  emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                loading={liveLoadsData?.deliverLoadsloading}
                pagination={{
                  onChange: this.getPaginatedData,
                  total: liveLoadsData?.totalCount,
                  position: 'bottom',
                  current: pageNo,
                  pageSize: liveLoadsData?.pageSize
                }}
                scroll={{ x: 1410 }}
                dataSource={PaginatedData ? PaginatedData[pageNo] : []}
                columns={columns} />
            </Col>
          </Row>
          <Modal
            title={false}
            visible={this.state.showExportDownload}
            centered
            maskClosable={false}
            closable={false}
            footer={false}
          >
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" tip={<span style={{ color: 'black' }}>Download In Progress</span>} />
            </div>

          </Modal>

          <Modal
            width={800}
            title={this.state?.consign?.waybillNo}
            visible={this.state.visibeEpod}
            onCancel={this.endConsign}
            footer={[]}
          >
            <Row>
              {/* <Col span={2}>
                <Icon type="left-circle" style={{ textAlign: 'center', padding: "5px", fontSize: 20, marginTop: 254, cursor: 'pointer' }} />
              </Col> */}

              <Col span={20} push={2}>
                <div style={{ textAlign: 'center' }}>
                  {/* <img style={{ height: 450 }} src={this.state?.consign?.podImpLink} /> */}
                  <Slider {...this.state.settings}>
                    {/* <img style={{ height: 450 }} src={this.state?.consign?.podImpLink} /> */}

                    {/* {this.state.consignImages.map(img => (
                      <div>
                        <img style={{ height: 450 }} src={img} />
                      </div>
                    ))} */}


                    {this.state.consignImages.map((img, index) => (
                      <div>
                        <div style={{ height: 400 }}>
                          <div style={{
                            fontSize: "16px",
                            fontWeight: 700
                          }}>E-POD - {index + 1}</div>
                          <img style={{ maxHeight: '100%', width: '100%', objectFit: 'contain' }} src={img} />
                        </div>
                      </div>
                    ))}




                  </Slider>
                </div>

              </Col>
              {/* <Col span={2}>
                <Icon type="right-circle" style={{ textAlign: 'center', padding: "5px", fontSize: 20, marginTop: 254, cursor: 'pointer' }} />
              </Col> */}
              <Col span={1} push={2}>
                {/* <a onClick={() => this.download(image)}  ref={node => {this.imageRef = node;}}><DownloadOutlined className="downloadImage"/></a> */}

                <a
                  // href={this.state?.consignImages[this.state.podImageIndex]}
                  // download={this.state?.consignImages[this.state.podImageIndex]}
                  // target="_blank"
                  onClick={() => this.download(this.state?.consignImages[this.state.podImageIndex])}
                  ref={node => { this.imageRef = node; }}
                // onClick={e => this.download(this.state?.consign?.podImpLink)}
                >
                  <Icon style={{
                    fontSize: "16px",
                    background: "#1890ff",
                    padding: "4px",
                    color: "#fff",
                    borderRadius: "4px",
                    cursor: "pointer"
                  }} type="download" />
                </a>
              </Col>


            </Row>


          </Modal>
        </Card>
      </div >
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  liveLoadsData: state.deliverLoads,
  PaginatedData: state.deliverLoads?.PaginatedData,
  exportData: state.deliverLoads?.export,
  downladPODData: state?.deliverLoads?.downladPOD,
  locationList: state.deliverLoads?.location,
  summaryList: state.deliverLoads?.consignmentSummaryCountBOs,
  downloadStatus: state?.app?.s3Status
});
const mapDispatchToProps = dispatch => ({
  fetchLiveLoads: (payload, isReset) => dispatch(fetchLiveLoads(payload, isReset)),
  fetchLiveLoadsSummary: payload => dispatch(fetchLiveLoadsSummary(payload)),
  exportLiveLoads: payload => dispatch(exportLiveLoads(payload)),
  downloadPOD: payload => dispatch(downloadPOD(payload)),
  locationLiveLoads: (key, payload) => dispatch(locationLiveLoads(key, payload)),
  setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
  setS3SyncStatusReset: () => dispatch(appActions.setS3SyncStatusReset()),

});
export default connect(mapStateToProps, mapDispatchToProps)(DeliverLoads);