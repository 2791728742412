import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import PickupAssistService from '../../../services/leads/pickupAssist.service';
const pickupAssistanceService = new PickupAssistService;


export const getInventory = (payload, isReset) => dispatch => {
    dispatch(inventoryBegin());
    pickupAssistanceService.fetchInventory(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(inventorySuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(inventoryFailure(res ?.data ?.response));
        }
    });
};


function inventoryBegin() {
    return {
        type: _Constants.GET_INVENTORY_BEGIN,
    }
}

function inventorySuccess(payload, isReset) {
    return {
        type: _Constants.GET_INVENTORY_SUCCESS,
        payload: payload,
        isReset
    }
}

function inventoryFailure(error) {
    return {
        type: _Constants.GET_INVENTORY_FAILURE,
        payload: error
    }
}

export const getClientsName = () => dispatch => {
    dispatch(fetchClientBegin());
    pickupAssistanceService.fetchClients().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchClientSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchClientFailure(res ?.data ?.response));
        }
    });
};

function fetchClientBegin() {
    return {
        type: _Constants.GET_CLIENT_BEGIN,
    }
}

function fetchClientSuccess(payload) {
    return {
        type: _Constants.GET_CLIENT_SUCCESS,
        payload: payload
    }
}

function fetchClientFailure(error) {
    return {
        type: _Constants.GET_CLIENT_FAILURE,
        payload: error
    }
}
  
export const createDrsDraft = (payload) => dispatch => {
    return pickupAssistanceService.createDrsDrafts(payload).then(res => {
        let payloadBox = {
            filters:{
                pageNo: 1,
                pageSize: 2000,
                totalCount: 0,
            }
      }
        dispatch(getInventory(payloadBox, true));
        return res;
    });
};

export const distanceCalculation = (payload) => dispatch => {
    return pickupAssistanceService.distanceCalculate(payload).then(res => {
        return res;
    });
};
