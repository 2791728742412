import React, { Component, createRef } from 'react';
import L from 'leaflet';
import { Map as LeafletMap, TileLayer, FeatureGroup } from 'react-leaflet';
import Pins from './Pins.js';
import { EditControl } from "react-leaflet-draw";
import './map-widget.css';
import deepEqual from 'fast-deep-equal/es6';
import LeafletEventForwarder from 'leaflet-event-forwarder';
import yellowMarker from '../../../assets/img/icons/Location-Pin.png';


const blueMarker = L.icon({
  iconUrl: yellowMarker,
  iconSize: [16, 31],
  iconAnchor: [10, 30],
});

class MapWidget extends Component {
  constructor() {
    super()
    this.state = {
        lat: 12.934186,
        lng: 77.629908,
        maxZoom: 18,
        minZoom: 0,
        zoom: 1,
      }
    this.assistMap = React.createRef()
  }


  componentDidMount() {
    this.prepareEventBubbler();
    if(this.props.geojson && this.props.geojson.length > 0 && this.props.zoombox === 1){
      this.getBounds();
    }
    // this.setDrawOptions();
  }

  componentDidUpdate(prevProps) {
    const { mapBounds, geojson } = this.props;

    if(geojson !== prevProps.geojson && this.props.zoombox === 1){
      this.getBounds();
    }
    const { mapBounds: prevMapBounds } = prevProps;
    if (!deepEqual(mapBounds, prevMapBounds)) {
      this.fitCustomBounds(mapBounds);
    }
  }

  zoomToBounds = (mapInstance, ...rest) => {
    console.log(mapInstance);
    var promise = new Promise((resolve, reject) => {
      if (mapInstance) {
        window.map = mapInstance;
        mapInstance.once('zoomend', function() {
          setTimeout(function() {
            resolve();
          }, 10);
        });
      }
    });
    mapInstance.fitBounds.apply(mapInstance, rest);
    return promise;
  }

  getBounds = () => {
    const { geojson } = this.props;
    let jsonData = [];
    const mapInstance = this.assistMap && this.assistMap.leafletElement;
    if(geojson && geojson.length > 0){
      geojson.map(x => {
        if(x.consignmentBO.consignee.address.latitude){
          let coordinates = L.marker([Number(x.consignmentBO.consignee.address.latitude), Number(x.consignmentBO.consignee.address.longitude)], {icon: blueMarker});
          jsonData.push(coordinates);
        }
      })
      var featureGroup = L.featureGroup(jsonData).addTo(mapInstance);;
      mapInstance.fitBounds(featureGroup.getBounds());
      featureGroup.clearLayers();
    }
  }

  _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer( (layer) => {
      numEdited += 1;
    });
    console.log(`_onEdited: edited ${numEdited} layers`, e);

    this._onChange();
  }

  _onCreated = (e) => {
    // const { geojson } = this.props;
    let type = e.layerType;
    let layer = e.layer;
    if (type === 'marker') {
      // Do marker specific actions
      console.log("_onCreated: marker created", e);
    }
    else {
      // console.log("_onCreated: something else created:", type, e);
      // let addedData = [];
      let shape = layer.toGeoJSON();
      let shape_for_db = JSON.stringify(shape);
      // var mypolygon = L.polygon(JSON.parse(shape_for_db).geometry.coordinates[0]);
      // if(geojson){
      //   geojson.map((x, key) => {
      //     if(x.consignmentBO.consignee.address.latitude){
      //       let p1 = L.marker([Number(x.consignmentBO.consignee.address.latitude), Number(x.consignmentBO.consignee.address.longitude)]);
      //       console.log(p1.getLatLng());
      //       let coordinates = mypolygon.getBounds().contains(p1.getLatLng());
      //       console.log(coordinates);
      //       if(coordinates){
      //         addedData.push(x);
      //       }
      //     }
      //   })
      // }
      this.props.polygonCoordinates(JSON.parse(shape_for_db), this.assistMap.leafletElement.getZoom());
    }
    // Do whatever else you need to. (save to db; etc)

    this._onChange();
  }

  _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer( (layer) => {
      console.log(layer);
      numDeleted += 1;
    });
    console.log(`onDeleted: removed ${numDeleted} layers`, e);

    this._onChange();
  }

  _onChange = () => {
    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API

    const { onChange } = this.props;

    if (!this._editableFG || !onChange) {
      return;
    }

    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
      // console.log(geojsonData);
      onChange(geojsonData);
    }

  prepareEventBubbler() {
    const map = this.assistMap.leafletElement;
    if (map) {
      const myEventForwarder = new L.eventForwarder({
        // ref to leaflet map
        map: map,
        // events to forward
        events: {
          click: false,
          mousemove: true
        },
        // throttle options for mousemove events (same as underscore.js)
        throttleMs: 100,
        throttleOptions: {
          leading: true,
          trailing: false
        }
      });
      myEventForwarder.enable();
    }
  }

  zoomHandling = (e) => {
    console.log(this.assistMap.leafletElement.getZoom())
    this.setState({zoom : this.assistMap.leafletElement.getZoom()})
  }

  render() {
    return (
      <div className="assistbox">
      <LeafletMap
        attributionControl={false}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        center={[this.state.lat, this.state.lng]} 
        zoom={this.state.zoom}
        // boundsOptions={{padding: [50, 50]}}
        // bounds={this.getBounds()}
        ref={ref => (this.assistMap = ref)}
        onzoomend={this.zoomHandling}
      >
        <TileLayer
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
        />
        <FeatureGroup>
        <EditControl
          position='topright'
          onEdited={this._onEdited}
          onCreated={this._onCreated}
          onDeleted={this._onDeleted}
          draw={{
            polyline: false,
            polygon: true,
            circle: false,
            rectangle: false,
            circlemarker: false,
            marker: {
              icon: blueMarker
            }
          }}
          />
          <Pins {...this.props} zoomSet={this.state.zoom}/>
        </FeatureGroup>
      </LeafletMap>
      </div>
    );
  }
}

export default MapWidget;