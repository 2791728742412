import { Col, Icon, Tooltip, Modal, Tag, Row } from 'antd'
import React from 'react'
import { dataGet } from '../../../common/utils/utils'
import moment from 'moment'
import ImageViewer from './ImageViewer'
export const formatDate = date => (moment(Number(date)).format('DD MMM YY HH:mm:ss'))
const { confirm } = Modal;




export function getColumn(isBool, updateValues) {
    return [
        {
            label: 'Trip ID',
            dataIndex: 'tripId',
            key: 1
        },
        {
            label: 'Trip Type',
            dataIndex: 'tripLoadType',
            key: 2
        },
        {
            label: 'Placement',
            dataIndex: 'tripPlacementType',
            key: 3,
            render: (head, trip) => <>
                <Col span={12}>
                    {head.label}:
                 </Col>
                <Col span={12}>
                    <b style={{ wordWrap: 'break-word' }}>{dataGet(trip, head.dataIndex) ? trip?.tripPlacementType === "PLANNED" ? 'Planned' : 'Adhoc' : '-'}</b>
                </Col>
            </>
        },
        {
            label: 'Vehicle No',
            dataIndex: 'vehicleNo',
            key: 4,
            render: (head, trip) => (
                <>
                    <Col span={12}>
                        {head.label}:
                    </Col>
                    <Col span={12}>
                        <b style={{ wordWrap: 'break-word' }}>
                            {trip?.vehicleNo || '-'}

                        </b>
                        {trip?.billingVehicleNo && trip?.billingVehicleNo !== trip?.vehicleNo  &&
                            <span style={{ float: 'right' }}>
                                <Tooltip title={`Billing vehicle number: ${trip?.billingVehicleNo}`}>
                                    <Icon type={"info-circle"} theme="twoTone" twoToneColor={"rgb(0, 82, 204)"} />
                                </Tooltip>
                            </span>
                        }

                    </Col>

                </>
            )
        },
        {
            label: 'Billable Vehicle Category',
            dataIndex: 'vehicleCategory',
            key: 5,
            render: (head, trip) => (
                <>
                    <Col span={12}>
                        {head.label}:
    </Col>
                    <Col span={12}>
                        <b style={{ wordWrap: 'break-word' }}>
                            {trip?.vehicleCategory || '-'}

                        </b>

                        {isBool &&
                            <div style={{ float: 'right' }}> <Icon type="edit" onClick={() => updateValues?.openVehicleCategoryModal(true)} /> </div>
                        }
                    </Col>
                </>
            )
        },
        {
            label: 'Vendor',
            dataIndex: 'vendorName',
            key: 6
        },
        {
            label: 'Trip Status',
            dataIndex: 'status',
            key: 7
        },
    ]
}

export function getColumn2(isBool, updateValues = {}) {
    return [
        {
            label: 'PRS',
            dataIndex: 'prses',
            render: (head, trip) => {

                return <>
                    <Col span={6}>
                        PRS:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfPRSs}</b>
                    </Col>
                    <Col span={6}>
                        Dockets:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDocketsInPRSs}</b>
                    </Col>
                </>
            }
        },
        {
            label: 'Manifest',
            dataIndex: 'thcbos',
            render: (head, trip) => {
                return <>
                    <Col span={7}>
                        Manifest:
                                </Col>
                    <Col span={5}>
                        <b>{trip?.noOfManifests}</b>
                    </Col>
                    <Col span={6}>
                        Dockets:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDocketsInManifests}</b>
                    </Col>
                </>
            }
        },
        {
            label: 'DRS',
            dataIndex: 'drsbos',
            render: (head, trip) => {
                return <>
                    <Col span={6}>
                        DRS:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDRSs || '-'}</b>
                    </Col>
                    <Col span={6}>
                        Dockets:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDocketsInDRSs}</b>
                    </Col>
                </>
            }
        },
        {
            title: 'KM',
            label: 'Start',
            dataIndex: 'start',
            render: (head, trip) => {
                return <>
                    <Col span={6}>
                        <h4><b style={{ float: 'left' }}>KM</b> <br /></h4>
                        Start:
                                </Col>
                    <Col span={6}>
                        <br />
                        <b>{trip?.startKms || '-'}</b>
                    </Col>
                    <Col span={6}>
                        <br />
                        End:
                                </Col>
                    <Col span={6}>
                        <div style={{ float: 'right' }}>{isBool && <Icon type="edit" onClick={() => updateValues?.showDistanceModal(true)} />} </div><br />
                        <b>{trip?.endKms || '-'}</b>
                    </Col>
                </>
            }
        },
        {
            title: 'Duration',
            label: 'Start',
            dataIndex: 'start',
            render: (head, trip) => {
                const tripStartTime = dataGet(trip, 'startTime', undefined)
                const tripEndTime = dataGet(trip, 'endTime', undefined)
                return <div style={{ width: '100%' }}>
                    <Col span={8}>
                        <h4><b>Duration</b><br /></h4>
                        Start:
                                </Col>
                    <Col span={16}>
                        <h4 style={{ textAlign: 'right' }}> {isBool && <Icon type="edit" onClick={() => updateValues?.showDurationModal(true)} />} <br /></h4>
                        <b>{trip?.startTime ? formatDate(trip?.startTime) : '-'}</b>
                    </Col><br />
                    <Col span={8}>
                        End:
                                </Col>
                    <Col span={16}>
                        <b>{trip?.endTime ? formatDate(trip?.endTime) : '-'}</b>
                    </Col>
                </div>
            }
        },
        {
            title: 'Assisting Person',
            label: 'Name',
            dataIndex: 'name',
            render: (head, trip) => { 
                return <div style={{ width: '100%' }}>
                    <Col span={8}>
                        <h4><b>Assisting Person</b><br /></h4>
                        Name:
                                </Col>
                    <Col span={16}>
                        <h4 style={{ textAlign: 'right' }}> {isBool && <Icon type="edit" onClick={() => updateValues?.showAssistingPersonModal(true)} />} <br /><br/></h4>
                        <b>{trip?.assistingPersonName || '-'}</b>
                    </Col><br /><br />
                    <Col span={8}>
                        Mobile No:
                                </Col>
                    <Col span={16}>
                        <b>{trip?.assistingPersonMobile || '-'}</b>
                    </Col>
                </div>
            }
        }
    ]
}

export function getColumn3(isBool, updateValues = {}) {
    return [
        {
            label: 'PRS',
            dataIndex: 'prses',
            render: (head, trip) => {

                return <>
                    <Col span={6}>
                        PRS:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfPRSs}</b>
                    </Col>
                    <Col span={6}>
                        Dockets:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDocketsInPRSs}</b>
                    </Col>
                </>
            }
        },
        {
            label: 'Manifest',
            dataIndex: 'thcbos',
            render: (head, trip) => {
                return <>
                    <Col span={7}>
                        Manifest:
                                </Col>
                    <Col span={5}>
                        <b>{trip?.noOfManifests}</b>
                    </Col>
                    <Col span={6}>
                        Dockets:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDocketsInManifests}</b>
                    </Col>
                </>
            }
        },
        {
            label: 'DRS',
            dataIndex: 'drsbos',
            render: (head, trip) => {
                return <>
                    <Col span={6}>
                        DRS:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDRSs || '-'}</b>
                    </Col>
                    <Col span={6}>
                        Dockets:
                                </Col>
                    <Col span={6}>
                        <b>{trip?.noOfDocketsInDRSs}</b>
                    </Col>
                </>
            }
        },
        {
            title: 'KM',
            label: 'Start',
            dataIndex: 'start',
            render: (head, trip) => {
                return <>
                    <Col span={6}>
                        <h4><b style={{ float: 'left' }}>KM</b> <br /></h4>
                        Start:
                                </Col>
                    <Col span={6}>
                        <br />
                        <b>{trip?.startKms || '-'}</b>
                    </Col>
                    <Col span={6}>
                        <br />
                        End:
                                </Col>
                    <Col span={6}>
                        <div style={{ float: 'right' }}>{isBool && <Icon type="edit" onClick={() => updateValues?.showDistanceModal(true)} />} </div><br />
                        <b>{trip?.endKms || '-'}</b>
                    </Col>
                </>
            }
        },
        {
            title: 'Duration',
            label: 'Start',
            dataIndex: 'start',
            render: (head, trip) => {
                const tripStartTime = dataGet(trip, 'startTime', undefined)
                const tripEndTime = dataGet(trip, 'endTime', undefined)
                return <div style={{ width: '100%' }}>
                    <Col span={8}>
                        <h4><b>Duration</b><br /></h4>
                        Start:
                                </Col>
                    <Col span={16}>
                        <h4 style={{ textAlign: 'right' }}> {isBool && <Icon type="edit" onClick={() => updateValues?.showDurationModal(true)} />} <br /></h4>
                        <b>{trip?.startTime ? formatDate(trip?.startTime) : '-'}</b>
                    </Col><br />
                    <Col span={8}>
                        End:
                                </Col>
                    <Col span={16}>
                        <b>{trip?.endTime ? formatDate(trip?.endTime) : '-'}</b>
                    </Col>
                </div>
            }
        }
    ]
}

export const PODColumns = [
    {
        title: 'Docket',
        dataIndex: 'waybillNo',
        key: 1
    },
    {
        title: 'Customer Location',
        dataIndex: 'customerLocationName',
        key: 2
    },
    {
        title: 'Delivered Date',
        dataIndex: 'deliveryDate',
        key: 3,
        render: data => data && formatDate(data)
    },
    {
        title: 'ePod Status',
        dataIndex: 'epod',
        key: 4,
        render: data => data && data === 'PENDING' ? 'Pending' : 'Received'
    },
    {
        title: 'Images',
        dataIndex: 'podLinks',
        key: 5,
        render: (data, row) => <ImageViewer fileList={data && data?.length > 0 ? data?.map(d => {
            return { url: d }
        }) : []} />
    },
]

export const paymentColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 1
    },
    {
        title: "Created Date",
        dataIndex: 'createdAt',
        key: 2,
        render: data => formatDate(data)
    },
    {
        title: "Type",
        dataIndex: 'paymentRequestType',
        key: 3
    },
    {
        title: "Value",
        dataIndex: 'amount',
        key: 4,
        render: (data) => data ? `Rs. ${data}` : ''
    },
    {
        title: "Status",
        dataIndex: 'status',
        key: 5,
        render: (data) => {
            switch (data) {
                case 'REJECTED':
                    return <Tag color={"#f50"}>{data}</Tag>
                    break;
                case 'PAID':
                    return <Tag color={"#87d068"}>{data}</Tag>
                    break;
                case 'PENDING':
                    return <Tag color={"orange"}>{data}</Tag>
                    break;
                case 'VERIFIED':
                    return <Tag color={"#108ee9"}>{data}</Tag>
                    break;
                default:
                    return <Tag color={"orange"}>{data}</Tag>
                    break;
            }
        }

    },
    {
        title: "Updated Date",
        dataIndex: 'updatedAt',
        key: 6,
        render: data => data ? formatDate(data) : ''
    },
    {
        title: "Transaction Ref",
        dataIndex: 'transactionRef',
        key: 7,
        render: (data) => data ? data : ''
    },
]


export function getCostColumn(updateValues, type) {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 1
        },
        {
            title: "Type",
            dataIndex: 'chargeType',
            key: 2
        },
        {
            title: "Value",
            dataIndex: 'amount',
            key: 3
        },
        {
            title: "Date",
            dataIndex: 'transactionDate',
            key: 4,
            render: data => formatDate(data)
        },
        {
            title: "Attachments",
            dataIndex: 'attachments',
            key: 5,
            render: (data, row) => <ImageViewer fileList={data} />
        },
        {
            title: "",
            dataIndex: 'linkedTransactionId',
            key: 6,
            render: (data, row) => data && (<Tooltip placement="top" title={`linked to revenue ID: ${data}`}><Icon type="link" /></Tooltip>)
        },
        {
            title: "",
            dataIndex: 'action',
            key: 7,
            render: (data, row) => (<Tooltip placement="top" title="Remove"> <Icon onClick={() => updateValues?.removeCostRevenue(row, type)} style={{ color: 'red' }} type="minus-circle" /> </Tooltip>)
        },
    ]
}




export const Col3Data = [
    {
        title: 'Revenue',
        label: 'Base',
        dataIndex: 'baseRevenue'
    },
    {
        label: 'Total',
        dataIndex: 'totalRevenue'
    },
    {
        title: 'Cost',
        label: 'Base',
        dataIndex: 'baseCost'
    },
    {
        label: 'Total',
        dataIndex: 'totalCost'
    },
]
export const Col4Data = [
    {
        title: 'Payment',
        label: 'Pending',
        dataIndex: 'totalPendingPayment'
    },
    {
        label: 'Paid',
        dataIndex: 'totalPaidPayment'
    }
]

export const Col5Data = [
    {
        title: 'Payment',
        label: 'Pending',
        dataIndex: 'totalPendingPayment'
    },
    {
        label: 'Paid',
        dataIndex: 'totalPaidPayment'
    },
    {
        title: 'Assisting Person',
        label: 'Name',
        dataIndex: 'name',
        render: (head, trip) => { 
            return <div style={{ width: '100%' }}>
                <Col span={24} style={{marginBottom:"-25px"}}>
                    <h4><b>Assisting Person</b></h4>
                </Col>
                <Col span={8}>
                    Name:
                </Col>
                <Col span={16}>
                    <b>{trip?.assistingPersonName || '-'}</b>
                </Col>
                <Col span={8}>
                    Mobile No:
                </Col>
                <Col span={16}>
                    <b>{trip?.assistingPersonMobile || '-'}</b>
                </Col>
            </div>
        }
    }
]