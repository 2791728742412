export const IndentListingConstants = {
    "FETCH_INDENTS_LISTING_BEGIN": "FETCH_INDENTS_LISTING_BEGIN",
    "FETCH_INDENTS_LISTING_SUCCESS": "FETCH_INDENTS_LISTING_SUCCESS",
    "FETCH_INDENTS_LISTING_FAILURE": "FETCH_INDENTS_LISTING_FAILURE",
    "FETCH_LOVS_BEGIN": "FETCH_LOVS_BEGIN",
    "FETCH_LOVS_SUCCESS": "FETCH_LOVS_SUCCESS",
    "FETCH_LOVS_FAILURE": "FETCH_LOVS_FAILURE",
    "SET_INDENT_COPY_BEGIN":"SET_INDENT_COPY_BEGIN",
    "SET_INDENT_COPY_SUCCESS":"SET_INDENT_COPY_SUCCESS",
    "SET_INDENT_COPY_FAILURE":"SET_INDENT_COPY_FAILURE",
    "INDENT_LISTING_LOADING_START":"INDENT_LISTING_LOADING_START",
    "INDENT_LISTING_LOADING_STOP":"INDENT_LISTING_LOADING_STOP"
}

export const IndentStatusLOV = {
    "30020" : "DRAFT",
    "30030" : "NON_SERVICEABLE",
    "30040" : "APPROVAL_PENDING",
    "30050" : "APPROVED",
    "30060" : "REJECTED",
    "30070" : "EXPIRED",
    "30080" : "CANCELLED"
    // "30090" : "VEHICLE_ASSIGNED",
    // "30100" : "VEHICLE_ARRIVED",
    // "30110" : "PICKUP_COMPLETED",
    // "30120" : "DISPATCHED"
}