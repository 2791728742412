import { apiRequest } from '../../common/utils/apiGateway';
import axios from 'axios';

export default class ExceptionService {

  getExceptions(params) {
    let url = `/b2c/v1/bookings/exceptions/partners/${params.partnerId}`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

  updateBookingService(params) {
    let url = `/b2c/v1/bookings/exceptions/partners/${params.data[0]['bookingRequests'][0]['partnerId']}/update`;
    return apiRequest({
      url,
      method: "PUT",
      data: params
    });
  }

  getExceptionsBySearch(params) {
    let url = `/b2c/v1/bookings/exceptions/partners/${params.partnerId}/`;
    return apiRequest({
      url,
      method: "POST",
      data: params
    });
  }

}
