import * as CONSTANTS from './constants';
import * as apiUtils from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
import { notifyApiError, notifyApiSuccess } from '../../common/utils/utils';

const partnerId = localStorage.getItem('loggedPartnerId');

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getLMSList = (params) => dispatch => {
    dispatch(getLMSBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/fetch`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(getLMSSuccess(response.data ?.response))
        })
        .catch(error => dispatch(getLMSFailure(error)));
}

export const getLMSCreate = (params, text) => dispatch => {
    dispatch(getLMSCreateBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status)) {
                if(text == "create")
                    notifyApiSuccess("Module Created Successfully",  "Success: ");
                else
                notifyApiSuccess("Module Updated Successfully",  "Success: ");
                dispatch(getLMSCreateSuccess(response.data ?.response))
            }            
                
        })
        .catch(error => dispatch(getLMSCreateFailure(error)));
}

export const getLMSUsersList = (params) => dispatch => {
    dispatch(getLMSUsersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/fetch`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(getLMSUsersSuccess(response.data ?.response))
        })
        .catch(error => dispatch(getLMSUsersFailure(error)));
}

export const getLMSEditList = (params) => dispatch => {
    dispatch(getLMSEditBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/fetch`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(getLMSEditSuccess(response.data ?.response))
        })
        .catch(error => dispatch(getLMSEditFailure(error)));
}

export const updateStatus = (params) => dispatch => {
    dispatch(getUpdateStatusBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/status`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(getUpdateStatusSuccess(response.data))
        })
        .catch(error => dispatch(getUpdateStatusFailure(error)));
}


export const updateUserStatus = (params) => dispatch => {
    dispatch(getUpdateUserStatusBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/user/update`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            
            if (checkHttpStatus(response.data ?.status))                
                dispatch(getUpdateUserStatusSuccess(response.data))
        })
        .catch(error => dispatch(getUpdateUserStatusFailure(error)));
}

export const getRefreshusers = (lmsId) => dispatch => {

    dispatch(getUserRefreshBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/lms/${lmsId}/user/refresh`,        
        

    })
        .then(response => {
            
            if (checkHttpStatus(response.data ?.status))                
                dispatch(getUserRefreshSuccess(response.data))
        })
        .catch(error => dispatch(getUserRefreshFailure(error)));

}

export const getEditReset = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_LMS_EDIT_LIST_RESET
    })
}

export const resetUserUpdate = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_USER_RESET
    })
}


function getLMSBegin() {
    return {
        type: CONSTANTS.GET_LMS_LIST_BEGIN,
        payload: null
    }
}

function getLMSSuccess(response) {
    return {
        type: CONSTANTS.GET_LMS_LIST_SUCCESS,
        payload: response
    }
}

function getLMSFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_LIST_FAILURE,
        payload: response
    }
}

function getLMSCreateBegin() {
    return {
        type: CONSTANTS.GET_LMS_CREATE_BEGIN,
        payload: null
    }
}

function getLMSCreateSuccess(response) {
    return {
        type: CONSTANTS.GET_LMS_CREATE_SUCCESS,
        payload: response
    }
}

function getLMSCreateFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_CREATE_FAILURE,
        payload: response
    }
}

function getLMSUsersBegin() {
    return {
        type: CONSTANTS.GET_LMS_USERS_LIST_BEGIN,
        payload: null
    }
}

function getLMSUsersSuccess(response) {
    return {
        type: CONSTANTS.GET_LMS_USERS_LIST_SUCCESS,
        payload: response
    }
}

function getLMSUsersFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_USERS_LIST_FAILURE,
        payload: response
    }
}

function getLMSEditBegin() {
    return {
        type: CONSTANTS.GET_LMS_EDIT_LIST_BEGIN,
        payload: null
    }
}

function getLMSEditSuccess(response) {
    return {
        type: CONSTANTS.GET_LMS_EDIT_LIST_SUCCESS,
        payload: response
    }
}

function getLMSEditFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_EDIT_LIST_FAILURE,
        payload: response
    }
}


function getUpdateStatusBegin() {
    return {
        type: CONSTANTS.GET_LMS_UPDATE_STATUS_BEGIN,
        payload: null
    }
}

function getUpdateStatusSuccess(response) {
    return {
        type: CONSTANTS.GET_LMS_UPDATE_STATUS_SUCCESS,
        payload: response
    }
}

function getUpdateStatusFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_UPDATE_STATUS_FAILURE,
        payload: response
    }
}

function getUpdateUserStatusBegin() {
    return {
        type: CONSTANTS.GET_LMS_USER_UPDATE_STATUS_BEGIN,
        payload: null
    }
}

function getUpdateUserStatusSuccess(response) {
    console.log("response->>", response)
    return {
        type: CONSTANTS.GET_LMS_USER_UPDATE_STATUS_SUCCESS,
        payload: response
    }
}

function getUpdateUserStatusFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_USER_UPDATE_STATUS_FAILURE,
        payload: response
    }
}


function getUserRefreshBegin() {
    return {
        type: CONSTANTS.GET_LMS_USER_REFRESH_STATUS_BEGIN,
        payload: null
    }
}

function getUserRefreshSuccess(response) {
    
    return {
        type: CONSTANTS.GET_LMS_USER_REFRESH_STATUS_SUCCESS,
        payload: response
    }
}

function getUserRefreshFailure(response) {
    return {
        type: CONSTANTS.GET_LMS_USER_REFRESH_STATUS_FAILURE,
        payload: response
    }
}