
import { initialState } from './constants';
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const MailReducer = (state = initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_MAILS_BEGIN: return fetchMailsBegin(state);
        case _Constants.FETCH_MAILS_SUCCESS: return fetchMailsSuccess(state, action);
        case _Constants.FETCH_MAILS_FAILURE: return fetchMailsFailure(state, action);

        case _Constants.GET_CUSTOMERS_BEGIN: return getCustomersBegin(state);
        case _Constants.GET_CUSTOMERS_SUCCESS: return getCustomersSuccess(state, action);
        case _Constants.GET_CUSTOMERS_FAILURE: return getCustomersFailure(state, action);

        case _Constants.SEND_MAIL_BEGIN: return sendMailBegin(state);
        case _Constants.SEND_MAIL_SUCCESS: return sendMailSuccess(state, action);
        case _Constants.SEND_MAIL_FAILURE: return sendMailFailure(state, action);

        default:
            return state;
    }
}

function fetchMailsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchMailsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedMails: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedMails ? state.PaginatedMails : {}, action ?.payload ?.responses , action.isReset),
        ...action.payload
    };
}

function fetchMailsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        responses: []
    };
}

function getCustomersBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function getCustomersSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function getCustomersFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

function sendMailBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function sendMailSuccess(state, action) {
    return {
        ...state,
        loading: false,
        emailStatus: [...action.payload]
    };
}

function sendMailFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        emailStatus: []
    };
}
