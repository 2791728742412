export const GET_LOGGEDIN_USER_DATA = 'GET_LOGGEDIN_USER_DATA';
export const GET_LOCATION_FETCH_BEGIN = 'GET_LOCATION_FETCH_BEGIN';

export const GET_LOCATION_FETCH_SUCCESS = 'GET_LOCATION_FETCH_SUCCESS';
export const GET_LOCATION_FETCH_FAILURE = 'GET_HEADER_PARTNER_FETCH_FAILURE';

export const GET_PARTNER_FETCH_BEGIN = 'GET_PARTNER_FETCH_BEGIN';
export const GET_PARTNER_FETCH_SUCCESS = 'GET_PARTNER_FETCH_SUCCESS';
export const GET_PARTNER_FETCH_FAILURE = 'GET_PARTNER_FETCH_FAILURE';

export const LOGGED_OUT_BEGIN = 'LOGGED_OUT_BEGIN';
export const LOGGED_OUT_SUCCESS = 'LOGGED_OUT_SUCCESS';
export const LOGGED_OUT_FAILURE = 'LOGGED_OUT_FAILURE';

export const APP_LOGIN_BEGIN = 'APP_LOGIN_BEGIN';
export const APP_LOGIN_SUCCESS = 'APP_LOGIN_SUCCESS';
export const APP_LOGIN_FAILURE = 'APP_LOGIN_FAILURE';

export const UPDATE_LOCALITEM_REDUX_SUCCESS = 'UPDATE_LOCALITEM_REDUX_SUCCESS';

export const GET_SET_LOCALITEM_REDUX_SUCCESS = 'GET_SET_LOCALITEM_REDUX_SUCCESS';

export const USER_LOGGEDIN_INFO_KEYS = {
    LOGGEDIN_PARTNER_ID:
        {
            'tagNameInRedux': 'loggedPartnerId',
            'tagHolderLocalStorage': 'user',
            'tagNameHolderPathLocalStorage': 'partner.id',
            'parse': true,
            'storeLocalStorageData': true
        },
    LOGGEDIN_USER_LOCATION_ID:
        {
            'tagNameInRedux': 'loggedPartnerLocationId',
            'tagHolderLocalStorage': 'user',
            'tagNameHolderPathLocalStorage': 'location.id', 
            'parse': true,
            'storeLocalStorageData': true
        },
    CURRENT_PARTNER_ID:
        {
            'tagNameInRedux': 'currentPartnerId',
            'tagHolderLocalStorage': 'user',
            'tagNameHolderPathLocalStorage': 'partner.id', 'parse': true,
            'storeLocalStorageData': true,
            'lookup': { 'localStorageTag': 'currentPartnerId', 'parse': true }
        },
    CURRENT_USER_LOCATION_ID:
        {
            'tagNameInRedux': 'currentPartnerLocationId',
            'tagHolderLocalStorage': 'user',
            'tagNameHolderPathLocalStorage': 'location.id', 'parse': true,
            'storeLocalStorageData': true,
            'lookup': { 'localStorageTag': 'currentPartnerLocationId', 'parse': true }
        }
}

export const TOGGLE_WAYBILL_MODAL = 'TOGGLE_WAYBILL_MODAL';