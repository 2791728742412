import * as CONSTANTS from './constants';
import * as apiUtils from '../../../../common/utils/apiGateway';
import { notifyApiError } from '../../../../common/utils/utils';

const partnerId = localStorage.getItem('loggedPartnerId');

function customerListBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_BEGIN,
        payload: null
    }
}

function customerListSuccess(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_SUCCESS,
        payload: response
    }
}

function customerListFailure(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_FAILURE,
        payload: response
    }
}

function shipperListBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_SHIPPER_BOOKING_LIST_BEGIN,
        payload: null
    }
}

function shipperListSuccess(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_SHIPPER_BOOKING_LIST_SUCCESS,
        payload: response
    }
}

function shipperListFailure(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_SHIPPER_BOOKING_LIST_FAILURE,
        payload: response
    }
}

function bookingOfficeBegin() {
    return {
        type: CONSTANTS.GET_BOOKING_OFFICE_BEGIN,
        payload: null
    }
}

function bookingOfficeSuccess(response) {
    return {
        type: CONSTANTS.GET_BOOKING_OFFICE_SUCCESS,
        payload: response
    }
}

function bookingOfficeFailure(response) {
    return {
        type: CONSTANTS.GET_BOOKING_OFFICE_FAILURE,
        payload: response
    }
}

function generateWaybillBegin() {
    return {
        type: CONSTANTS.GENERATE_WAYBILL_BEGIN,
        payload: null
    }
}

function generateWaybillSuccess(response) {
    return {
        type: CONSTANTS.GENERATE_WAYBILL_SUCCESS,
        payload: response
    }
}

function generateWaybillFailure(response) {
    return {
        type: CONSTANTS.GENERATE_WAYBILL_FAILURE,
        payload: response
    }
}

function originLocationBegin() {
    return {
        type: CONSTANTS.ORIGIN_LOCATION_BEGIN,
        payload: null
    }
}

function originLocationSuccess(response) {
    return {
        type: CONSTANTS.ORIGIN_LOCATION_SUCCESS,
        payload: response
    }
}

function originLocationFailure(response) {
    return {
        type: CONSTANTS.ORIGIN_LOCATION_FAILURE,
        payload: response
    }
}

function designationLocationBegin() {
    return {
        type: CONSTANTS.DESIGNATION_LOCATION_BEGIN,
        payload: null
    }
}

function designationLocationSuccess(response) {
    return {
        type: CONSTANTS.DESIGNATION_LOCATION_SUCCESS,
        payload: response
    }
}

function designationLocationFailure(response) {
    return {
        type: CONSTANTS.DESIGNATION_LOCATION_FAILURE,
        payload: response
    }
}

function shipmentDimensionsBegin() {
    return {
        type: CONSTANTS.SHIPMENT_DIMENSIONS_BEGIN,
        payload: null
    }
}

function shipmentDimensionsSuccess(response) {
    return {
        type: CONSTANTS.SHIPMENT_DIMENSIONS_SUCCESS,
        payload: response
    }
}

function shipmentDimensionsFailure(response) {
    return {
        type: CONSTANTS.SHIPMENT_DIMENSIONS_FAILURE,
        payload: response
    }
}

function goodsTypesBegin() {
    return {
        type: CONSTANTS.GOODS_TYPES_BEGIN,
        payload: null
    }
}

function goodsTypesSuccess(response) {
    return {
        type: CONSTANTS.GOODS_TYPES_SUCCESS,
        payload: response
    }
}

function goodsTypesFailure(response) {
    return {
        type: CONSTANTS.GOODS_TYPES_FAILURE,
        payload: response
    }
}

function pickupBegin() {
    return {
        type: CONSTANTS.PICKUP_BEGIN,
        payload: null
    }
}

function pickupSuccess(response) {
    return {
        type: CONSTANTS.PICKUP_SUCCESS,
        payload: response
    }
}

function pickupFailure(response) {
    return {
        type: CONSTANTS.PICKUP_FAILURE,
        payload: response
    }
}

function BookingCreationBegin() {
    return {
        type: CONSTANTS.BOOKING_CREATION_BEGIN,
        payload: null
    }
}

function BookingCreationSuccess(response) {
    return {
        type: CONSTANTS.BOOKING_CREATION_SUCCESS,
        payload: response
    }
}

function BookingCreationFailure(response) {
    return {
        type: CONSTANTS.BOOKING_CREATION_FAILURE,
        payload: response
    }
}

function EditBookingDataBegin() {
    return {
        type: CONSTANTS.BOOKING_EDIT_BEGIN,
        payload: null
    }
}

function EditBookingDataSuccess(response) {
    return {
        type: CONSTANTS.BOOKING_EDIT_SUCCESS,
        payload: response
    }
}

function EditBookingDataFailure(response) {
    return {
        type: CONSTANTS.BOOKING_EDIT_FAILURE,
        payload: response
    }
}


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getCustomerList = () => dispatch => {
    dispatch(customerListBegin);
    apiUtils.apiRequest({
        method: 'get',
        url: `/b2b/v1/partners/${partnerId}/customers/fetch?view=LITE_CUSTOMER_LIST&filterCustomers=true`
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(customerListSuccess(response.data?.viewResponse?.viewResponse))
        })
        .catch(error => dispatch(customerListFailure(error)));
}

export const getShipperList = (params) => dispatch => {
    dispatch(shipperListBegin);
    apiUtils.apiRequest({
        method: 'POST',
        url: `/b2b/v1/customer/shipper-mapping/fetch`,
        data: params
    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(shipperListSuccess(response.data ?.response))
        })
        .catch(error => dispatch(shipperListFailure(error)));
}



export const getBookingOffice = (data) => dispatch => {
    dispatch(bookingOfficeBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/partners/${partnerId}/locations/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(bookingOfficeSuccess(response.data?.viewResponse?.viewResponse))
        })
        .catch(error => dispatch(bookingOfficeFailure(error)));
}

export const getOriginLocation = (data) => dispatch => {
    dispatch(originLocationBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/partners/${partnerId}/location-groups/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(originLocationSuccess(response.data?.response?.locationsGroupedByRole?.PICKUP))
        })
        .catch(error => dispatch(originLocationFailure(error)));
}

export const getDesignationLocation = (data) => dispatch => {
    dispatch(designationLocationBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/contacts/favs/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(designationLocationSuccess(response.data?.response?.consignmentContactFavs))
        })
        .catch(error => dispatch(designationLocationFailure(error)));
}

export const getShippingDimensions = (data) => dispatch => {
    dispatch(shipmentDimensionsBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/consignments/getBoxTypes`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(shipmentDimensionsSuccess(response.data?.response?.boxTypes))
        })
        .catch(error => dispatch(shipmentDimensionsFailure(error)));
}

export const getGoodsTypes = (data) => dispatch => {
    dispatch(goodsTypesBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/goods-types/get`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(goodsTypesSuccess(response.data?.response?.goodsTypes.filter(list => list.isActive)))
        })
        .catch(error => dispatch(goodsTypesFailure(error)));
}

export const getPickupList = (data) => dispatch => {
    dispatch(pickupBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/leads/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(pickupSuccess(response.data.viewResponse.viewResponse))
        })
        .catch(error => dispatch(pickupFailure(error)));
}

export const liteBookingCreate_Update = (data) => dispatch => {
    dispatch(BookingCreationBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/consignments/lite/update`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(BookingCreationSuccess(response.data.response.responses))
        })
        .catch(error => dispatch(BookingCreationFailure(error)));
}

export const generateWaybillID = () => dispatch => {
    dispatch(generateWaybillBegin);
    apiUtils.apiRequest({
        method: 'get',
        url: `/b2b/v1/consignments/generate/waybill`
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(generateWaybillSuccess(response.data.response?.waybill))
        })
        .catch(error => dispatch(generateWaybillFailure(error)));
}

export const getConsignmentsById = (id) => dispatch => {
    dispatch(EditBookingDataBegin);
    apiUtils.apiRequest({
        method: 'get',
        url: `b2b/v1/consignments/${id}`
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(EditBookingDataSuccess(response.data.response.consignments[0]))
        })
        .catch(error => dispatch(EditBookingDataFailure(error)));
}