import * as apiUtils from '../../../common/utils/apiGateway';
import { checkHttpStatus, notifyApiError } from '../../../common/utils/utils';

export const approveRejectContract = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url          : "b2b/v1/contracts/approve",
        method       : "POST",
        data         : data,
        ignoreStatus : true
    }).
        then(response => {
            if (response?.data?.response?.sync === false) {
                if (response?.data?.response?.dataValidations?.length > 0) {
                    var dataValidations = {};
                    response?.data?.response?.dataValidations.map(d => {
                        dataValidations[d.dataSection] = d.errorMessages;
                    })
                    resolve({
                        approveRejectSuccess   : false,
                        dataValidations : dataValidations
                    });
                }
                else {
                    resolve({ approveRejectSuccess : true });
                }
            }
            else {
                resolve({ approveRejectSuccess : true });
            }
        })
        .catch(e => reject(e))
})

export const rejectReasons = () => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: `/b2b/v1/consignmentVerifications/rejectedReasons/CONTRACT_REJECT`,
        method: "GET",        
    }).
        then(response => {
            if (checkHttpStatus(response?.data?.status)) {
                
                const { consignmentVerificationRejectedReasonsList } = response.data.response;
                if (!consignmentVerificationRejectedReasonsList) {
                    reject(response.data);
                }
                else {
                    resolve(consignmentVerificationRejectedReasonsList);
                }
            }
            else {
                reject(response.data);
            }
        })
})

