import React from "react";
import {
  Upload,
  Icon,
  Modal,
  Empty,
  Row,
  Col,
  Card,
  Button,
  Tooltip
} from "antd";
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";
import Lightbox from 'react-image-lightbox-rotate-fixed';
import 'react-image-lightbox/style.css'

import "./trips.scss";
import _ from "lodash";
import { UPLOAD_BUTTON_TYPE } from "../../../common/utils/constants";
import addImage from '../../../assets/img/icons/add_image.svg';
import FileUpload from "../../ui/file-upload/file.upload";
import Magnifier from "../../ui/magnifier/magnifier";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const images = [
  "https://frontend-collective.github.io/react-image-lightbox/1.0d2c9d24.jpg",
  "https://frontend-collective.github.io/react-image-lightbox/4.f0b0636a.jpg",
  "https://frontend-collective.github.io/react-image-lightbox/3.07f63430.jpg",
  "https://frontend-collective.github.io/react-image-lightbox/2.a8dae120.jpg"
];

class ImageViewerNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: this.props.photoIndex ? this.props.photoIndex : 0,
      previewVisible: false,
      previewImage: "",
      fileList: props.fileList || [],
      mode: props.mode || "ICON",
      setImages: false,
      imageData: props?.imageData,
      rotation: 0,
      step: 1,
      acceptedFiles: [".PNG", ".png", ".jpg", ".JPEG", ".jpeg", ".JPG"]
    };

  }

  setPhotoIndex = photoIndex => {
    this.setState({ photoIndex }, () => {
      this.props?.updatePhotoIndex ? this.props.updatePhotoIndex(photoIndex) : () => { }
    });
  };

  componentDidMount() {
    this.setState({
      photoIndex: this.props.photoIndex ? this.props.photoIndex : 0,
      step: 1
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.imageData?.length !== prevProps?.imageData?.length) {
      this.setState({ step: 1, rotation: 0 })
    }
    if (this.props.photoIndex !== prevProps?.photoIndex) {

      this.setState({
        photoIndex: this.props.photoIndex ? this.props.photoIndex : 0,
        step: 1
      });
    }
    if (
      (!_.isUndefined(this.props.photoIndex) &&
        this.state.photoIndex >= this.props.imageData.length) && !this.state.setImages
    ) {
      this.setState({ photoIndex: 0, setImages: true, step: 1 });
    }

    if (!_.hasIn(this.props.imageData[this.state.photoIndex], 's3Url') && this.props?.imageData?.length > 0) {
      this.setState({ photoIndex: this.props?.imageData?.length - 1, step: 1 })
    }
  }
  shakeImage = () => {

    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({
      rotation: newRotation,
    })
    let newStep = this.state.step + 1;
    if (newStep === 5) {
      newStep = 1;
    }
    this.setState({
      step: newStep,
    })
  }

  render() {
    const { photoIndex, previewVisible } = this.state;

    return (
      <>
        <div className="image-card">
          <Row>
            <Col span={12}>
              <div class="image-card-title">{this.props?.title}</div>
            </Col>
            <Col span={12}>
              <div className="image-card-pagination">
                {this.props?.imageData?.length > 0 ? photoIndex + 1 : 0} of {this.props?.imageData?.length}
              </div>
            </Col>
          </Row>

          <div
            style={{
              maxWidth: "100%",
              flexGrow: 1,
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: 8
            }}
          >
            {/* <div className="action-left">
              <Icon
                style={{
                  color: "#ffffff"
                }}
                type="left"
                onClick={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + this.props.imageData?.length - 1) %
                      this.props.imageData?.length
                  })
                }
              />
            </div>

            <div className="img-card-container">
              {this.props.imageData?.length > 0 ?
                <div>
                  <div>
                    <img
                      style={{
                        height: 500,
                        width: "auto",
                        // maxWidth: 500,
                        objectFit: "fill",
                        transform: `rotate(${this.state.rotation}deg)`,
                      }}
                      className={this.state.step === 2 || this.state.step === 4 ? 'step2' : 'image'}
                      src={this.props.imageData[this.state.photoIndex]?.s3Url}
                    />
                  </div>
                  <div className="action-right-reasons">


                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {
                        this.props.imageData[this.state.photoIndex]?.rejectedReasons?.map(reason => (
                          <li className="docket-verify-left-block-invoice-header-data-reaons">{reason?.documentRejectedReason}</li>
                          // <li className="docket-verify-left-block-invoice-header-data-reject">{reason?.message}</li>
                        ))
                      }
                    </ul>


                  </div>

                </div>
                :
                <Empty
                  style={{
                    height: 400,
                    width: "auto",
                    maxWidth: 900,
                    objectFit: "fill"
                  }}
                />
              }

            </div>

            <div className="action-right">

              <Icon
                style={{
                  color: "#ffffff"
                }}
                type="right"
                onClick={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % this.props.imageData?.length
                  })
                }
              />
              <div className="action-right-tools">
                <Row>
                  <Col span={24}>
                    <Tooltip title="Rotate">
                      <Icon
                        style={{
                          color: '#ffffff',
                          fontSize: 20,
                          position: "relative",
                          top: 10,
                          left: 10
                        }}
                        type="shake"
                        onClick={this.shakeImage}
                      />
                    </Tooltip>
                  </Col>
                  <Col span={24}>
                    <Tooltip title="Fullscreen">
                      <Icon
                        type="fullscreen"
                        onClick={e => this.setState({ previewVisible: true })}
                        style={{
                          color: '#ffffff',
                          fontSize: 20,
                          position: "relative",
                          top: 20,
                          left: 10
                        }}

                      />
                    </Tooltip>
                  </Col>
                </Row>
              </div>
            </div>
             */}


            <div className="flex-container">
              <div className="flex-left">
                <div className="action-left">
                  {_.hasIn(this.props.imageData[photoIndex], 's3Url') ?
                    <Icon
                      style={{
                        color: "#ffffff"
                      }}
                      type="left"
                      onClick={() => {
                        if (photoIndex >= 0) {
                          this.setState({
                            photoIndex:
                              (photoIndex + this.props.imageData?.length - 1) %
                              this.props.imageData?.length
                          }, () => {
                            this.props?.updatePhotoIndex ? this.props.updatePhotoIndex(this.state.photoIndex) : () => { }
                          });
                        }


                      }


                      }
                    />
                    : <Icon
                      style={{
                        color: "#ffffff",
                        opacity: 0.5,
                        cursor: 'not-allowed'
                      }}
                      type="left"

                    />
                  }
                </div>
              </div>
              <div className="flex-center">
                <div className="img-card-container">
                  {this.props.imageData?.length > 0 ?
                  <>
                    <img
                      style={{
                        height: 500,
                        width: "auto",
                        maxWidth: 750,
                        objectFit: "fill",
                        transform: `rotate(${this.state.rotation}deg)`,
                      }}
                      className={this.state.step === 2 || this.state.step === 4 ? 'step2' : 'image'}
                      src={this.props.imageData[this.state.photoIndex]?.s3Url}
                      id={"docket_image"}
                    />
                    <Magnifier imageId={"docket_image"}/>
                  </>
                    :
                    <Empty
                      style={{
                        height: 400,
                        width: "auto",
                        maxWidth: 900,
                        objectFit: "fill"
                      }}
                    />
                  }

                </div>
              </div>
              <div className="flex-right">
                <div className="flex-right-item1">
                  {
                    this.props.imageData[this.state.photoIndex]?.rejectedReasons?.length > 0 &&
                    <div className="action-right-reasons">


                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {
                          this.props.imageData[this.state.photoIndex]?.rejectedReasons?.map(reason => (
                            <li className="action-right-reasons-list">{reason?.documentRejectedReason}</li>
                            // <li className="docket-verify-left-block-invoice-header-data-reject">{reason?.message}</li>
                          ))
                        }
                      </ul>


                    </div>
                  }

                </div>
                <div className="flex-right-item2">
                  <div className="action-right">
                    {_.hasIn(this.props.imageData[photoIndex], 's3Url') ?
                      <Icon
                        style={{
                          color: "#ffffff"
                        }}
                        type="right"
                        onClick={() => {
                          if (photoIndex + 1 < this.props.imageData?.length) {
                            this.setState({
                              photoIndex: (photoIndex + 1) % this.props.imageData?.length
                            }, () => {
                              this.props?.updatePhotoIndex ? this.props.updatePhotoIndex(this.state.photoIndex) : () => { }
                            })
                          }

                        }
                        }
                      />
                      :
                      <Icon
                        style={{
                          color: "#ffffff",
                          opacity: 0.5,
                          cursor: 'not-allowed'
                        }}
                        type="right"
                      />
                    }
                  </div>
                </div>
                <div className="flex-right-item3">
                  <div className="action-right-tools">
                    <Row>
                      <Col span={24}>
                        <Tooltip title="Rotate">
                          {_.hasIn(this.props.imageData[photoIndex], 's3Url') ?
                            <Icon
                              style={{
                                color: '#ffffff',
                                fontSize: 20,
                                position: "relative",
                                top: 10,
                                left: 10
                              }}
                              type="shake"
                              onClick={this.shakeImage}
                            /> :
                            <Icon
                              style={{
                                color: '#ffffff',
                                fontSize: 20,
                                position: "relative",
                                cursor: 'not-allowed',
                                top: 10,
                                left: 10,
                                opacity: 0.5
                              }}
                              type="shake"
                            // onClick={this.shakeImage}
                            />
                          }
                        </Tooltip>
                      </Col>
                      <Col span={24}>
                        <Tooltip title="Fullscreen">
                          {_.hasIn(this.props.imageData[photoIndex], 's3Url') ?
                            < Icon
                              type="fullscreen"
                              onClick={e => this.setState({ previewVisible: true  })}
                              style={{
                                color: '#ffffff',
                                fontSize: 20,
                                position: "relative",
                                top: 20,
                                left: 10
                              }}

                            />
                            :
                            < Icon
                              type="fullscreen"
                              // onClick={e => this.setState({ previewVisible: true })}
                              style={{
                                color: '#ffffff',
                                fontSize: 20,
                                position: "relative",
                                cursor: 'not-allowed',
                                top: 20,
                                left: 10,
                                opacity: 0.5
                              }}

                            />
                          }
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>






            {this.props.imageData[this.state.photoIndex]?.message &&
              <div className="image-card-warning">
                <div className="docket-verify-left-block-invoice-header-data-comment">
                  {this.props.imageData[this.state.photoIndex]?.message}
                </div>


              </div>
            }
            <div className="image-card-action">
              <div style={{ float: 'left' }}>
                <FileUpload
                  showToastMessage={false}
                  showLoader={true}
                  fileSizeLimit={5}
                  fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                  uploadButtonHtml={(
                    // <Icon type="folder-add" style={{ fontSize: 25, cursor: 'pointer' }} />

                    <Button disabled={this.props.disableReplace || !_.hasIn(this.props.imageData[photoIndex], 's3Url')} className="image-card-action-replace">Replace</Button>

                  )}
                  directUpload={true}
                  disableUploadButton={true}
                  clearFileAfterUpload={true}
                  onUploadFinish={e => this.props.replaceImage(this.state.photoIndex, this.props.imageType ? this.props.imageType : 'DOCKET', e)}
                  path="verification"
                  acceptTypes={this.state.acceptedFiles}
                  objKey="docket"
                  multiple={false}
                />
              </div>

              <Button
                onClick={() => this.props?.openRejectModal(0, this.props.imageType, true, this.state.photoIndex)}
                className="image-card-action-reject"
                disabled={this.props.disableReject || !_.hasIn(this.props.imageData[photoIndex], 's3Url')}
              >Reject</Button>
            </div>

          </div>

          <div className="image-slider">
            <div className="image-slider-container">
              {this.props.imageData?.map((img, index) => (
                <div className="image-slider-container-item">
                  {this.state?.photoIndex === index &&
                    <>
                      {this.props.disableReplace
                        ?
                        <div
                          className="image-slider-container-item-image-delete"
                          style={{ opacity: '0.5' }}
                        >
                          <Icon type="delete" />
                        </div>
                        : <div
                          className="image-slider-container-item-image-delete"
                          onClick={e => {
                            !!this.props.onFileRemoved && this.props.onFileRemoved(index)
                          }}
                        >
                          <Icon type="delete" />
                        </div>
                      }

                    </>
                  }
                  <img
                    style={{
                      cursor: "pointer"
                    }}
                    className={
                      this.state?.photoIndex === index
                        ? "image-slider-container-item-image image-slider-container-item-selected"
                        : "image-slider-container-item-image image-slider-container-item-viewer"
                    }
                    src={img?.s3Url}
                    onClick={() => this.setPhotoIndex(index)}
                    alt={index}
                  />
                </div>
              ))}
              {Number(this.props.uploadLimit) !==
                this.props.imageData.length && (
                  <div className="image-upload">
                    <FileUpload
                      showToastMessage={false}
                      showLoader={true}
                      fileSizeLimit={5}
                      fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                      uploadButtonHtml={
                        // <Icon type="folder-add" style={{ fontSize: 25, cursor: 'pointer' }} />
                        <div className="image-slider-container-item">
                          {this.props.disableReplace ?
                            <>


                            </>
                            :
                            <img
                              style={{
                                cursor: "pointer"
                              }}
                              className="image-slider-container-item-image-upload"
                              src={
                                addImage
                              }
                              alt={"placehoder"}
                            />
                          }

                        </div>
                      }
                      directUpload={true}
                      disableUploadButton={true}
                      clearFileAfterUpload={true}
                      onUploadFinish={e => {
                        this.props.onFileUploaded(e);
                        this.setPhotoIndex(this.props.imageData.length - 1)
                      }
                      }
                      path="verification"
                      acceptTypes={this.state.acceptedFiles}
                      objKey="docket"
                      multiple={false}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="clearfix">
            {
              previewVisible && <Lightbox
                mainSrc={this.props.imageData[photoIndex]?.s3Url}
                // nextSrc={this.props.imageData[(photoIndex + 1) % this.props.imageData.length]}
                // prevSrc={this.props.imageData[(photoIndex + this.props.imageData.length - 1) % this.props.imageData.length]}
                onCloseRequest={() => this.setState({ previewVisible: false })}
                // onMovePrevRequest={() =>
                //   this.setState({
                //     photoIndex: (photoIndex + this.props.imageData.length - 1) % this.props.imageData.length,
                //   })
                // }
                // onMoveNextRequest={() =>
                //   this.setState({
                //     photoIndex: (photoIndex + 1) % this.props.imageData.length,
                //   })
                // }
              />
            }
          </div>
        </div>
      </>
    );
  }
}
export default ImageViewerNew;
