import React, { Component } from 'react';
import ImportExportBuilder from '../../shared-components/import-export-builder/import-export-builder';

class Import extends Component {

    componentDidCatch(){
    }

    render() {
        return (
            <div>
                <ImportExportBuilder builderTitle="Import" builderType="SETTINGS_IMPORT" />
            </div>
        )
    }
}

export default Import;