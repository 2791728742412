import { Icon, Tooltip } from "antd";
import React from "react";

export const RatesTooltip = ( { rateBO, fixed, amount } ) => {
    return (
        <Tooltip placement={"right"} overlayClassName={"charges-rate-tooltip " + (fixed ? "fixed-charges" : "slab-charges")} title={
            <div className="flex-column flex-gap-s">
                {
                    fixed ?
                    <div> Rate (in Rs.) </div> :
                    <div className="flex-box flex-gap-l justify-content-space-between">
                        <div className="flex-1 flex-box flex-gap-s">
                            <div className="flex-1 flex-box justify-content-center"> From </div> 
                            <div className="flex-1 flex-box justify-content-center"> - </div>
                            <div className="flex-1 flex-box justify-content-center"> To </div>
                        </div>
                        <div className="flex-1 flex-box justify-content-start">
                            <div> - Rate (in Rs.) </div>
                        </div>
                    </div>
                }
                
                {
                    fixed ?
                    amount :
                    rateBO.map((rate, index) => {
                        return (
                            <div className="flex-box flex-gap-l justify-content-space-between" key={index}>
                                <div className="flex-1 flex-box flex-gap-s">
                                    <div className="flex-1 flex-box justify-content-center"> { rate.fromRange ? rate.fromRange : 0 } </div> 
                                    <div className="flex-1 flex-box justify-content-center"> - </div>
                                    <div className="flex-1 flex-box justify-content-center"> { rate.toRange ? rate.toRange : "--" } </div>
                                </div>
                                <div className="flex-1 flex-box justify-content-start">
                                    <div> - Rs. { rate.amount } </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        }>
            <Icon type="info-circle" className="cursor-pointer" />
        </Tooltip>
    )
}