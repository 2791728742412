import * as apiUtils from '../../../../../common/utils/apiGateway';
import { BASE_URL_TYPES } from '../../../../../common/utils/constants';
import { checkAPIHttpStatus } from '../../../../../common/utils/utils';

export const fetchIndents = data => new Promise(resolve => {
    if (data) {        
        const url = `/b2b/v1/indent/indentmaster`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data,
            baseUrlType: BASE_URL_TYPES.DEV
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        }).catch(err => {
            resolve(false);
        })
    }
    else {
        resolve(false);
    }
});

export const fetchLovs = data => new Promise(resolve => {
    if (data) {        
        const url = `/util/v1/lov/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data,
            baseUrlType: BASE_URL_TYPES.DEV
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        }).catch(err => {
            resolve(false);
        })
    }
    else {
        resolve(false);
    }
});

export const indentCopy = id => new Promise(resolve => {
    if (id) {        
        const url = `/b2b/v1/indent/copy/${id}`;
        apiUtils.apiRequest({
            url,
            method: "POST",            
            baseUrlType: BASE_URL_TYPES.DEV     
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        }).catch(err => {
            resolve(false);
        })
    }
    else {
        resolve(false);
    }
});
