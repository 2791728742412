import React, { Component } from 'react'
import './trips.scss'
import { Card, Col, Input, Row, Icon, Button, Spin, Tag, Empty, Pagination, Modal, Badge, Select, Table, Tooltip } from 'antd'
import { Constants, FILTER_INPUT_TYPE } from '../../../common/utils/constants'
import moment from 'moment'
import Filters from '../../ui/filter/Filter'
import DatePickerInput from "../../ui/datepicker/DatePicker";

import FilterIcon from "../../../assets/img/icons/filter-icon.svg";
import DocketDataCard from './docket.data-card'
import _ from 'lodash';
// import _Records from './data.json';
import * as apiUtils from '../../../common/utils/apiGateway';
import debounce from 'lodash/debounce';
import { canAllow, notifyApiError, notifyApiSuccess, checkAPIHttpStatus, reportsDownload, downloadFile } from '../../../common/utils/utils';
import { getHistoryTable } from "./column.d";
import { openNotification } from '../../ui/notification/Notification'
import ImageViewer from "./ImageViewer";
const Option = Select.Option;
class VerificationDockets extends Component {
    constructor(props) {
        super(props);
        const defaultOrder = 'DESC';
        this.state = {
            // tripData: _Records,
            docketData: [],
            loading: false,
            filterPayload: {
                "filter": {
                    "bookingDate": {
                        "from": moment().subtract(7, 'd').startOf('day').valueOf(),
                        "to": moment().endOf().valueOf()
                    },
                    "bookingLocationIds": [],
                    "movementTypes": [],
                    "flowTypes": [],
                    "consignmentVerificationStatuses": [],
                    "consignmentStatuses": [],
                    "customerIds": [],
                    "waybills": [],
                    "pageSize": 10,
                    "pageNo": 1
                },
                "orderBy": defaultOrder
            },
            defaultOrderBy: defaultOrder,
            tripDateValue: [moment().subtract(7, 'd').startOf('day'), moment().endOf('now')],
            startDate: moment().subtract(7, 'd').startOf('day'),
            endDate: moment().endOf('day'),
            tags: [],
            searchTripIds: undefined,
            locations: [],
            selectedLocations: [],
            locationLoading: false,
            customers: [],
            selectedCustomers: [],
            customerLoading: false,
            visible: false,
            initalFilters: setinitialFilters,
            isEndDateOpen: false,
            columns: [],
            sortIcon: 'align-right',
            history: [],
            showHistoryModal: false,
            consignmentStatus: {
                "INWARD": "In Transit",
                "OFD": "In Transit",
                "DRS_LINK": "In Transit",
                "IN_TRANSIT": "In Transit",
                "BOOKED": "Booked",
                "IN": "Inventory",
                "DELIVERED": "Delivered"
            },
        }

        this.fetchLocations = debounce(this.fetchLocations, 800);
        this.fetchCustomers = debounce(this.fetchCustomers, 800);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        // this.applyPagination(this.state.filterPayload, true);
        this.resetFilters();
        // this.getVendors();
    }




    fetchLocations = (value) => {
        if (value) {
            let params = {};
            // let level = Constants.sessionUser.userLevel;    
            this.setState({ locations: [], locationLoading: true })

            params = { "filters": { "entityTypes": ["PARTNER"], "entityIds": [Constants.PARTNER_ID], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }


            const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
            apiUtils.apiRequest({
                url,
                method: "POST",
                data: params
            })
                .then(response => {
                    let { locations } = this.state;
                    locations = response?.data?.response?.locations;
                    locations = locations.map(v => {
                        return {
                            label: v?.name,
                            key: v?.id,
                            value: v?.id
                        }
                    })
                    this.setState({ locations, locationLoading: false })
                })
        }

    }

    resetLocations = () => {
        this.setState({ locations: [] })
    }

    fetchCustomers = value => {
        if (value) {
            let params = {};
            // let level = Constants.sessionUser.userLevel;    
            this.setState({ customers: [], customerLoading: true })

            params = { "filters": { "pageNo": 1, "pageSize": 10, "totalCount": 0, "customerTypes": ["CUSTOMER"], "status": ["ACTIVE"], "fetchMode": "BASIC", "partialContentSearch": value, "partnerIds": [Constants.PARTNER_ID] } }


            const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`;
            apiUtils.apiRequest({
                url,
                method: "POST",
                data: params
            })
                .then(response => {
                    let { customers } = this.state;
                    customers = response?.data?.response?.customers;
                    customers = customers.map(v => {
                        return {
                            label: v?.name,
                            key: v?.id,
                            value: v?.id
                        }
                    })
                    this.setState({ customers, customerLoading: false })
                })
        }
    }


    removeFilteredValue = key => {
        const filterPayload = { ...this.state.filterPayload }
        delete filterPayload.filters[key]

        this.setState({
            filterPayload,
            isFiltered: (Object.keys(filterPayload).length !== 0)
        }, () => this.applyPagination({ ...this.state.filterPayload }, true))
    }

    clearTag = t => {
        const tags = this.state.tags.filter(tag => tag?.key !== t?.key);

        this.setState({ tags, tripData: [] });

        const newFilterPayload = { ...this.state.newFilterPayload };
        delete newFilterPayload['filter'][t?.key];
        if (t?.key === 'docketFlowTypes') delete newFilterPayload['filter']['flowTypes'];
        let setinitialFilters = { ...this.state.initalFilters }
        if (_.hasIn(setinitialFilters, t?.key)) {
            setinitialFilters[t?.key].map(x => {

                if (x.value) {
                    x.value = !x.value;
                }


            })
        }



        if (t?.key === "bookingLocationIds") {
            this.setState({
                selectedLocations: [],
                locations: []
            })
        };

        if (t?.key === "customerIds") {
            this.setState({
                selectedCustomers: [],
                customers: []
            })
        };


        // console.log("tags", tags)

        this.setState({
            newFilterPayload,
            initalFilters: setinitialFilters,
            // tags,
        }, () => this.applyPagination(this.state.newFilterPayload, true))
    }


    resetFilters = () => {
        this.setState({
            tripDateValue: [moment().subtract(7, 'd').startOf('day'), moment().endOf('now')],
            tags: [],
            startDate: moment().subtract(7, 'd').startOf('day'),
            endDate: moment().endOf('day'),
            selectedVendors: [],
            selectedLocations: [],
            locations: [],
            searchTripIds: undefined,
            selectedCustomers: [],
            newFilterPayload: {

                "filter": {
                    "bookingDate": {
                        "from": moment().subtract(7, 'd').startOf('day').valueOf(),
                        "to": moment().endOf().valueOf()
                    },
                    "bookingLocationIds": [],
                    "movementTypes": [],
                    "flowTypes": [],
                    "consignmentVerificationStatuses": [],
                    "consignmentStatuses": [],
                    "customerIds": [],
                    "waybills": [],
                    "pageSize": 10,
                    "pageNo": 1
                },
                "orderBy": "DESC"

            },

            visible: false,
            initalFilters: {
                movementTypes: [
                    { id: 0, name: "FTL", value: false, storedValue: "FTL" },
                    { id: 1, name: "PTL", value: false, storedValue: "PTL" }
                ],
                docketFlowTypes: [
                    { id: 0, name: "Forward", value: false, storedValue: "FORWARD" },
                    { id: 1, name: "Reverse", value: false, storedValue: "RTO" }
                ],
                consignmentStatuses: [
                    { id: 0, name: "Booked", value: false, storedValue: "BOOK" },
                    { id: 1, name: "Inventory", value: false, storedValue: "IN" },
                    { id: 2, name: "In Transit", value: false, storedValue: "IN_TRANSIT" },
                    // { id: 3, name: "INWARD", value: false, storedValue: "INWARD" },
                    // { id: 4, name: "OFD", value: false, storedValue: "OFD" },
                    // { id: 5, name: "DRS_LINK", value: false, storedValue: "DRS_LINK" },
                    { id: 3, name: "Delivered", value: false, storedValue: "DEL" },
                ],
                consignmentVerificationStatuses: [
                    // { id: 0, name: "Approved", value: false, storedValue: "APPROVED" },
                    { id: 0, name: "Document Pending", value: false, storedValue: "DOCUMENT_PENDING" },
                    { id: 1, name: "Pending Verification ", value: false, storedValue: "DOCUMENT_VERIFICATION_PENDING" },
                    { id: 2, name: "Rejected", value: false, storedValue: "REJECTED" },
                    { id: 3, name: "Required Correction", value: false, storedValue: "CORRECTION_REQUIRED" },
                    { id: 4, name: "Verified", value: false, storedValue: "VERIFIED" },
                ],
            },
        }, () => this.applyPagination(this.state.filterPayload, true))
    }

    applyPagination = (newFilter, reset) => {
        if (reset) {
            let { filter } = newFilter;
            newFilter = {
                ...newFilter,
                filter: {
                    ...filter,
                    pageNo: 1
                }
            }
        }
        this.setState({ loading: true, docketData: [] });
        apiUtils.apiRequest({
            url: `/b2b/v1/consignments/list`,
            data: newFilter,
            method: "POST"
        })
            .then(response => {

                if (checkAPIHttpStatus(response?.data?.status)) {

                    let { docketData } = this.state;
                    docketData = response?.data?.response;

                    this.setState({ docketData, loading: false })
                } else {
                    this.setState({ loading: false, docketData: [] })
                }

            });
    }

    changePage = (page, pageSize) => {

        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                filter: {
                    ...this.state.newFilterPayload.filter,
                    pageNo: page,
                    pageSize: pageSize
                }

            },
            docketData: []
        }, () => this.applyPagination(this.state.newFilterPayload, false))
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                filter: {
                    ...this.state.newFilterPayload.filter,
                    pageNo: current,
                    pageSize: pageSize
                }

            },
            docketData: []
        }, () => this.applyPagination(this.state.newFilterPayload, false))
    }


    onChangeLocations = location => {
        let keys = location?.map(val => val.key);
        let values = location?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "bookingLocationIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.removeTag("bookingLocationIds");
        if (values.length > 0) {
            tags.push({
                "label": "Location",
                "key": "bookingLocationIds",
                value: values
            })
        }

        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                filter: {
                    ...this.state.newFilterPayload.filter,
                    bookingLocationIds: keys
                }

            },
            selectedLocations: location,
            tags

        });
    }

    onChangeCustomers = customer => {
        let keys = customer?.map(val => val.key);
        let values = customer?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "customerIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.removeTag("customerIds");
        if (values.length > 0) {
            tags.push({
                "label": "Customer",
                "key": "customerIds",
                value: values
            })
        }

        this.setState({
            newFilterPayload: {
                ...this.state.newFilterPayload,
                filter: {
                    ...this.state.newFilterPayload.filter,
                    customerIds: keys
                }

            },
            selectedCustomers: customer,
            tags

        });
    }

    resetCustomers = () => {
        this.setState({ customers: [] })
    }

    changeTrip = e => {
        const value = e.target.value;

        if (!value) {
            this.setState({ searchTripIds: value }, () => {
                this.searchTrip(value)
            });

        } else {
            this.setState({ searchTripIds: value })
        }

    }

    filterValues = (type, key) => {
        let { initalFilters } = this.state;
        initalFilters[type].map(fl => {
            if (fl?.value) {
                if (type === "docketFlowTypes" && fl?.storedValue === 'RTO') {
                    key.push('Reverse');
                } else if (type === 'consignmentStatuses' && fl?.storedValue === 'IN_TRANSIT') {
                    key.push(fl?.storedValue);
                    key.push('INWARD');
                    key.push('OFD');
                    key.push('DRS_LINK');


                } else {
                    key.push(fl?.storedValue);
                }

            }
        });
        return key;

    }

    removeTag = (key) => {
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === key);
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.setState({ tags });
    }


    changeOrder = () => {

        let order = "DESC";
        let sortIcon = "align-right";

        if (this.state.defaultOrderBy === 'DESC') {
            order = 'ASC';
            sortIcon = 'align-left';
        } else {
            order = 'DESC';
            sortIcon = 'align-right';
        }
        let { columns } = this.state;
        columns.map(c => {
            if (c?.dataIndex === "verificationTat") {
                c.title = <>Ageing <Icon onClick={() => this.changeOrder()} style={{ cursor: 'pointer', marginLeft: 25 }} type={sortIcon} /></>
            }
        });


        this.setState({ defaultOrderBy: order, sortIcon, columns }, () => {

            let { newFilterPayload } = this.state;
            let { filter } = newFilterPayload;
            newFilterPayload = {
                ...newFilterPayload,
                orderBy: order,
                filter: {
                    ...filter
                }
            }
            this.setState({ newFilterPayload }, () => {
                this.applyPagination(this.state.newFilterPayload, true);
            })
        })
    }
    filterValuesChnages = (data, id) => {
        let setinitialFilters = { ...this.state.initalFilters }
        // let  { initalFilters } =  this.state 
        setinitialFilters[data].map(x => {
            if (x.id === id) {
                x.value = !x.value;
            }
        })
        this.setState({ initalFilters: setinitialFilters })
    }
    handleOk = () => {
        let { newFilterPayload, tags } = this.state;
        let { filter } = newFilterPayload;
        let docketFlowTypes = [], movementTypes = [], consignmentStatuses = [], consignmentVerificationStatuses = [];
        movementTypes = this.filterValues('movementTypes', movementTypes);
        docketFlowTypes = this.filterValues('docketFlowTypes', docketFlowTypes);
        consignmentStatuses = this.filterValues('consignmentStatuses', consignmentStatuses);
        consignmentVerificationStatuses = this.filterValues('consignmentVerificationStatuses', consignmentVerificationStatuses);

        this.removeTag("movementTypes");
        this.removeTag("docketFlowTypes");
        this.removeTag("consignmentStatuses");
        this.removeTag("consignmentVerificationStatuses");




        if (movementTypes.length > 0) {
            tags.push({
                "label": "Movement Type",
                "key": "movementTypes",
                value: [...movementTypes]
            });
            filter = {
                ...filter,
                movementTypes
            }

            newFilterPayload = {
                ...newFilterPayload,
                filter: {
                    ...filter,
                }

            };

        } else {
            delete newFilterPayload['filter']['movementTypes'];
        }
        if (docketFlowTypes.length > 0) {

            filter = {
                ...filter,
                flowTypes: docketFlowTypes
            }
            newFilterPayload = {
                ...newFilterPayload,
                filter: {
                    ...filter,
                }

            };

            tags.push({
                "label": "Flow Types",
                "key": "docketFlowTypes",
                value: [...docketFlowTypes]
            })

        } else {
            delete newFilterPayload['filter']['flowTypes'];
        }




        if (consignmentStatuses.length > 0) {
            filter = {
                ...filter,
                consignmentStatuses
            }
            newFilterPayload = {
                ...newFilterPayload,
                filter: {
                    ...filter,

                }

            };

            tags.push({
                "label": "Placement",
                "key": "consignmentStatuses",
                value: [...consignmentStatuses]
            })
        } else {
            delete newFilterPayload['filter']['consignmentStatuses'];
        }

        if (consignmentVerificationStatuses.length > 0) {
            filter = {
                ...filter,
                consignmentVerificationStatuses
            }
            newFilterPayload = {
                ...newFilterPayload,
                filter: {
                    ...filter,
                }

            }

            tags.push({
                "label": "Verification",
                "key": "consignmentVerificationStatuses",
                value: [...consignmentVerificationStatuses]
            })
        } else {
            delete newFilterPayload['filter']['consignmentVerificationStatuses'];
        }
        delete newFilterPayload['filter']['waybills'];

        this.setState({ newFilterPayload, visible: false, tags, docketData: [], searchTripIds: undefined }, () => {
            this.applyPagination(this.state.newFilterPayload, true);
        })

    }






    searchTrip = e => {

        let { newFilterPayload } = this.state

        if (this.state.searchTripIds) {
            if (this.state.searchTripIds.includes(",")) {
                newFilterPayload['filter']['waybills'] = this.state.searchTripIds.split(",");
            } else {
                newFilterPayload['filter']['waybills'] = [this.state.searchTripIds];
            }
            delete newFilterPayload['filter']['movementTypes'];
            delete newFilterPayload['filter']['bookingDate'];
            delete newFilterPayload['filter']['bookingLocationIds'];
            delete newFilterPayload['filter']['customerIds'];
            delete newFilterPayload['filter']['flowTypes'];
            delete newFilterPayload['filter']['consignmentStatuses'];
            delete newFilterPayload['filter']['consignmentVerificationStatuses'];
            this.setState({
                newFilterPayload,
                tags: [],
                selectedCustomers: [],
                customers: [],
                selectedLocations: [],
                locations: [],
                initalFilters: {
                    movementTypes: [
                        { id: 0, name: "FTL", value: false, storedValue: "FTL" },
                        { id: 1, name: "PTL", value: false, storedValue: "PTL" }
                    ],
                    docketFlowTypes: [
                        { id: 0, name: "Forward", value: false, storedValue: "FORWARD" },
                        { id: 1, name: "Reverse", value: false, storedValue: "RTO" }
                    ],
                    consignmentStatuses: [
                        { id: 0, name: "Booked", value: false, storedValue: "BOOK" },
                        { id: 1, name: "Inventory", value: false, storedValue: "IN" },
                        { id: 2, name: "In Transit", value: false, storedValue: "IN_TRANSIT" },
                        // { id: 3, name: "INWARD", value: false, storedValue: "INWARD" },
                        // { id: 4, name: "OFD", value: false, storedValue: "OFD" },
                        // { id: 5, name: "DRS_LINK", value: false, storedValue: "DRS_LINK" },
                        { id: 3, name: "Delivered", value: false, storedValue: "DEL" },
                    ],
                    consignmentVerificationStatuses: [
                        // { id: 0, name: "Approved", value: false, storedValue: "APPROVED" },
                        { id: 0, name: "Document Pending", value: false, storedValue: "DOCUMENT_PENDING" },
                        { id: 1, name: "Pending Verification ", value: false, storedValue: "DOCUMENT_VERIFICATION_PENDING" },
                        { id: 2, name: "Rejected", value: false, storedValue: "REJECTED" },
                        { id: 3, name: "Required Correction", value: false, storedValue: "CORRECTION_REQUIRED" },
                        { id: 4, name: "Verified", value: false, storedValue: "VERIFIED" },
                    ],
                }
            }, () => this.applyPagination(this.state.newFilterPayload, true))

        } else {
            delete newFilterPayload['filter']['waybills'];
            this.setState({
                newFilterPayload,
                tags: [],
                selectedCustomers: [],
                customers: [],
                selectedLocations: [],
                locations: [],
                initalFilters: {
                    movementTypes: [
                        { id: 0, name: "FTL", value: false, storedValue: "FTL" },
                        { id: 1, name: "PTL", value: false, storedValue: "PTL" }
                    ],
                    docketFlowTypes: [
                        { id: 0, name: "Forward", value: false, storedValue: "FORWARD" },
                        { id: 1, name: "Reverse", value: false, storedValue: "RTO" }
                    ],
                    consignmentStatuses: [
                        { id: 0, name: "Booked", value: false, storedValue: "BOOK" },
                        { id: 1, name: "Inventory", value: false, storedValue: "IN" },
                        { id: 2, name: "In Transit", value: false, storedValue: "IN_TRANSIT" },
                        // { id: 3, name: "INWARD", value: false, storedValue: "INWARD" },
                        // { id: 4, name: "OFD", value: false, storedValue: "OFD" },
                        // { id: 5, name: "DRS_LINK", value: false, storedValue: "DRS_LINK" },
                        { id: 3, name: "Delivered", value: false, storedValue: "DEL" },
                    ],
                    consignmentVerificationStatuses: [
                        // { id: 0, name: "Approved", value: false, storedValue: "APPROVED" },
                        { id: 0, name: "Document Pending", value: false, storedValue: "DOCUMENT_PENDING" },
                        { id: 1, name: "Pending Verification ", value: false, storedValue: "DOCUMENT_VERIFICATION_PENDING" },
                        { id: 2, name: "Rejected", value: false, storedValue: "REJECTED" },
                        { id: 3, name: "Required Correction", value: false, storedValue: "CORRECTION_REQUIRED" },
                        { id: 4, name: "Verified", value: false, storedValue: "VERIFIED" },
                    ],
                }
            }, () => this.resetFilters())

        }


    }

    searchDocket = () => {
        let { newFilterPayload } = this.state
        delete newFilterPayload['filter']['movementTypes'];
        delete newFilterPayload['filter']['flowTypes'];
        delete newFilterPayload['filter']['bookingDate'];
        delete newFilterPayload['filter']['bookingLocationIds'];
        delete newFilterPayload['filter']['customerIds'];
        delete newFilterPayload['filter']['consignmentStatuses'];
        delete newFilterPayload['filter']['consignmentVerificationStatuses'];
        if (this.state.searchTripIds) {
            if (this.state.searchTripIds.includes(",")) {
                newFilterPayload['filter']['waybills'] = this.state.searchTripIds.split(",");
            } else {
                newFilterPayload['filter']['waybills'] = [this.state.searchTripIds];
            }
            this.setState({
                newFilterPayload,
                tags: [],
                selectedCustomers: [],
                customers: [],
                selectedLocations: [],
                locations: []
            }, () => this.applyPagination(this.state.newFilterPayload, true))

        }

    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    disableDate = current => {
        const startNewDate = moment(current).add(31, 'days');
        const newDateEnd = moment(current).subtract(31, 'days');
        // const endNewDate = moment(current).subtract(31, 'days');
        const startDate = this.state.tripDateValue[0];
        const dayEnd = moment(startDate).add(31, 'days');
        // const range = moment().range(startDate, dayEnd);
        // return  current.isBefore(startDate) && current > moment().endOf('day');
        // if (dayEnd.isAfter(moment().endOf('day'))) {
        //     console.log("i am here")            
        //     return !((current.isSameOrAfter(newDateEnd) || current.isSameOrBefore(startNewDate)) && current <= moment().endOf('day'));
        // } else {
        //     console.log("i am there")
        //     return !(startDate.isSameOrBefore(current) && dayEnd.isAfter(current) && current.isSameOrBefore(dayEnd));
        // }
        // console.log("current", startDate, this.state.tripDateValue)
        return current && current > moment().endOf('day');

    }

    onChangeTripDateFilter = values => {
        this.setState({
            tripDateValue: values,
            newFilterPayload: {

                ...this.state.newFilterPayload,
                tripCreationDateLowerLimit: moment(values[0]).startOf('day').valueOf(),
                tripCreationDateUpperLimit: moment(values[1]).endOf('day').valueOf()

            }
        });
    }

    disabledStartDate = startValue => {
        const { startDate } = this.state;
        if (!startValue || !startDate) {
            return false;
        }

        return startValue.valueOf() && startValue.valueOf() > moment().endOf('day');
        // return startValue.valueOf() > startDate.valueOf();
    };

    disabledEndDate = endValue => {
        if (this.state.startDate) {
            const dayStart = moment(this.state.startDate);
            const dayEnd = moment(this.state.startDate).add(31, 'days');
            if (dayEnd.isAfter(moment().endOf('day'))) {

                return !(dayStart.isSameOrBefore(endValue) && endValue <= moment().endOf('day') && endValue >= moment(this.state.startDate).endOf('day'));
            } else {

                return !(dayStart.isSameOrBefore(endValue) && dayEnd.isAfter(endValue) && endValue.isSameOrBefore(dayEnd));
            }


        } else {
            // return current && current > moment().endOf('day');
        }
    }

    showHistory = (id) => {
        this.setState(
            {
                history: []
            },
            () => {
                apiUtils
                    .apiRequest({
                        url: `/b2b/v1/consignmentVerifications/events/${id}`,
                        method: "GET"
                    })
                    .then(res => {
                        let history = [];
                        this.setState({ history }, () => {
                            let historyData = res?.data?.response?.consignmentVerificationEvents;
                            if (historyData && historyData.length > 0) {
                                history = historyData.map(hist => {
                                    return this.UpdateTypeMessages(hist);
                                });
                            }
                            if (history.length > 0) {
                                this.setState({ history }, () => {
                                    this.setState({ showHistoryModal: true });
                                });
                            }
                        })
                    });
            }
        );
    };

    cancelHistory = () => {
        this.setState({ showHistoryModal: false, history: [] });
    };

    UpdateTypeMessages = data => {
        let user = data?.user?.name;

        let s3 = data?.s3Url ? data?.s3Url : null;
        let historyData = {};
        historyData = {
            message: <>
                <span style={{ display: 'inline-block', position: 'relative' }}>{data?.message} </span>

                {s3 ?
                    <span style={{ display: 'inline-block', position: 'relative', top: 7, left: 5 }}><ImageViewer fileList={[{ url: s3 }]} /></span>
                    : <span style={{ display: 'inline-block', position: 'relative', top: 7, left: 5 }}></span>
                }




            </>,
            user,
            timestamp: (
                <>
                    {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
                    <small>{moment(data?.createdAt).fromNow()}</small>
                </>
            )
        }

        return historyData;

    }

    download = () => {
        this.setState({ loading: true });
        apiUtils.apiRequest({
            url: `/b2b/v1/consignments/download`,
            method: "POST",
            data: this.state.newFilterPayload
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                // reportsDownload(
                //     response?.data?.response?.url,
                //     "s3_report_url"
                // );
                downloadFile(response?.data?.response?.url, '_blank')
                openNotification({
                    message: "Successfully",
                    description: "Request for downloading success",
                    className: "success-notification"
                });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        });
    }

    render() {
        const { docketData, tags, startDate, endDate, isEndDateOpen, newFilterPayload, history } = this.state;

        const { totalCount, currentPageSize, currentPageNo, totalDockets, consignments, maxPage } = docketData;


        const docketDataCardData = [
            {
                title: 'Total Dockets',
                value: docketData?.totalDockets
            },
            {
                title: 'Document Pending',
                value: docketData?.documentPending
            },
            {
                title: 'Pending Verification',
                value: docketData?.pendingVerification
            },
            {
                title: 'Document Rejected',
                value: docketData?.documentRejected
            },
            {
                title: 'Required Correction',
                value: docketData?.requiredCorrection
            },
            {
                title: 'Verified',
                value: docketData?.verified
            },
        ]

        const columns = [
            {
                title: 'Docket No.',
                dataIndex: 'waybillNo',
                key: 'waybillNo',
                fixed: 'left',
                width: 150,
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                render: (data, row) => data?.name
            },
            {
                title: 'Docket Type',
                dataIndex: 'movementType',
                key: 'movementType'
            },
            {
                title: 'Flow type',
                dataIndex: 'flowType',
                key: 'flowType'
            },
            {
                title: 'Pickup Date',
                dataIndex: 'pickupDate',
                key: 'pickupDate',
                render: (data) => moment(data).format('DD/MM/YYYY')
            },
            {
                title: 'Booking Office',
                dataIndex: 'bookingOfficeLoc',
                key: 'bookingOfficeLoc',
                render: (data, row) => data?.name
            },
            {
                title: 'Docket Status',
                dataIndex: 'consignmentStatus',
                key: 'consignmentStatus',
                render: (data) => this.state.consignmentStatus[data]
            },
            {
                // title: <>Ageing {this.state.defaultOrderBy === 'DESC' ? <Icon style={{ cursor: 'pointer' }} type="align-right" onClick={() => this.changeOrder('ASC')} /> : <Icon style={{ cursor: 'pointer' }} type="align-left" onClick={() => this.changeOrder('DESC')} />}</>,
                title: <>Ageing <Icon onClick={() => this.changeOrder()} style={{ cursor: 'pointer', marginLeft: 25 }} type={this.state.sortIcon} /></>,
                // title: 'Ageing',
                dataIndex: 'verificationTat',
                key: 'verificationTat',
                render: (data, row) => <>{data} days</>,

            },
            {
                title: 'Verification Status',
                dataIndex: 'consignmentFilterBO',
                key: 'consignmentFilterBO',
                width: 160,
                align: 'center',
                render: (data) => {

                    if (data?.verificationStatus === 'DOCUMENT_VERIFICATION_PENDING') {
                        return <Tag color="#faeddc" style={{ borderColor: '#ff9801' }}><span style={{ color: '#ff9801' }}>Verification Pending</span></Tag>
                    } else if (data?.verificationStatus === 'DOCUMENT_PENDING') {
                        return <Tag color="#faeddc" style={{ borderColor: '#ff9801' }}><span style={{ color: '#ff9801' }}>Document Pending</span></Tag>
                    } else if (data?.verificationStatus === 'CORRECTION_REQUIRED') {
                        return <Tag color="#e3e5f2" style={{ borderColor: '#4b5bb9' }}><span style={{ color: '#4b5bb9' }}>Correction Required</span></Tag>
                    } else if (data?.verificationStatus === 'REJECTED') {
                        return <Tag color="#feecec" style={{ borderColor: '#f44337' }}><span style={{ color: '#f44337' }}>Document Rejected</span></Tag>
                    } else if (data?.verificationStatus === 'VERIFIED') {
                        return <Tag color="#ebf5ea" style={{ borderColor: '#50b154' }}><span style={{ color: '#50b154' }}>Verified</span></Tag>
                    }
                }

            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'action',
                fixed: 'right',
                render: (data, row, index) => (<Row>
                    {
                        <Col span={8}><Tooltip placement="top" title={'History'}>
                            <Icon onClick={() => this.showHistory(row?.id)} type="history" /></Tooltip>
                        </Col>
                    }
                    {
                        <Col span={8}><Tooltip placement="top" title={'View'}>
                            <Button onClick={() => this.props.history.push('/appv2/verification/form/docket', { consignmentId: row?.id, filters: newFilterPayload, totalCount, currentPageSize, currentPageNo, totalDockets, index, consignments, maxPage })} type="primary">View</Button>
                        </Tooltip>
                        </Col>
                    }

                </Row >)
            },
        ]

        return (
            <div className="v-docket-list">
                <Row gutter={[12, 12]}>
                    <Col>
                        <Card>
                            <Row gutter={[6, 12]}>
                                <Col span={14}>
                                    <Input
                                        onChange={this.changeTrip}
                                        allowClear
                                        value={this.state.searchTripIds}
                                        onPressEnter={this.searchTrip}
                                        prefix={<Icon type="search" />}
                                        placeholder="Search by Docket No."
                                        size={'large'}
                                    />
                                </Col>
                                <Col span={9} offset={1}>
                                    <div style={{ display: 'flex', textAlign: 'end' }}>
                                        {/* <Filters
                                            popupWidth={"40vw"}
                                            filterPayload={this.getFilterPayload()}
                                            applyFilter={() => { this.applyPagination(this.state.newFilterPayload, true) }}
                                            resetFilters={this.resetFilters}
                                            className={'ant-btn-lg'}
                                        /> */}
                                        <Button type="default" block size={'large'} onClick={this.searchDocket}>
                                            Apply
                                        </Button>
                                        <div style={{ marginRight: 5 }}>
                                            <Badge dot={this.state.tags.length > 0}>
                                                <Button onClick={() => this.setState({ visible: true })} type="default" block size={"large"}>
                                                    <img src={FilterIcon} alt="filter" />
                                                Filters
                                            </Button>
                                            </Badge>

                                        </div>
                                        <Button onClick={this.download} className={"atlas-primary-button"} block size={'large'} disabled={!this.state.docketData?.consignments?.length}>
                                            Download
                                        </Button>


                                    </div>
                                </Col>
                                <Col span={24}>

                                    {/* <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue('key')} />} readOnly value={'TEST'} /> */}
                                    {tags.map(t => {

                                        return (

                                            <Tag style={{ marginBottom: 10 }} closable onClose={(e) => {
                                                e.preventDefault();
                                                this.clearTag(t);
                                            }}>
                                                {t.label} :

                                                <b>{" " + t.value?.join(", ")}</b>

                                                {/* {
                                                _.isArray(t?.value) ?
                                                    t?.value?.map((val, i) => (
                                                        <b>{ val} {i < t?.value?.length && i >= 0 && ", "}</b>
                                                    ))
                                                    :
                                                    <b>{t?.value}
                                                    </b>
                                            } */}

                                            </Tag>

                                        )
                                    }
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <div style={{ textAlign: 'center' }}>

                        <Spin spinning={this.state.loading} />

                    </div>
                    {
                        docketData && docketData?.consignments?.length > 0 ?
                            <>
                                <Col span={24}>
                                    <DocketDataCard
                                        summaryData={docketDataCardData}
                                        className="docket-card-verified"
                                        newtitle="Verified"
                                    />
                                </Col>
                                <Col span={24}>
                                    <Card>
                                        <Table
                                            columns={columns}
                                            rowKey={record => record?.id}
                                            bordered
                                            pagination={false}
                                            dataSource={docketData?.consignments}
                                            scroll={{ x: 1250 }}
                                        />
                                        <Col style={{ marginTop: 10, bottom: 10 }}>
                                            <Pagination
                                                onChange={this.changePage}
                                                total={docketData?.totalCount}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                pageSize={docketData?.currentPageSize}
                                                current={docketData?.currentPageNo}
                                                showSizeChanger
                                                onShowSizeChange={this.onShowSizeChange}
                                            />
                                        </Col>
                                    </Card>
                                </Col>


                            </>
                            :
                            <>
                                {!this.state.loading &&
                                    <Empty />
                                }
                            </>
                    }


                </Row>

                <Modal
                    title="Filters"
                    visible={this.state.visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    maskClosable={false}
                    style={{
                        top: 20
                    }}
                    footer={[
                        <Button key="back" onClick={this.resetFilters}>
                            Reset
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            Apply
                        </Button>,
                    ]}
                    width={500}
                >
                    <div className="verification-status-filters">
                        <Row>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Pickup Date</div>
                                {/* <div className="boxfilterTitles">
                                    <DatePickerInput
                                        type="range"
                                        disabledDate={this.disableDate}
                                        onChange={this.onChangeTripDateFilter}
                                        value={this.state.tripDateValue}
                                        format={"YYYY/MM/DD"}
                                    />
                                </div> */}
                                <Row gutter={12}>

                                    <Col span={12} style={{ bottom: 5 }}>
                                        <span className="form-label-text">From </span>
                                        <DatePickerInput
                                            disabledDate={this.disabledStartDate}
                                            type="date"
                                            format="YYYY-MM-DD"
                                            value={startDate}
                                            placeholder="From"
                                            style={{ width: '100%' }}
                                            // onOk={this.onStartDateChange}                                            
                                            onChange={value => {
                                                if (value) {
                                                    this.setState({
                                                        startDate: value,
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            filter: {
                                                                ...this.state.newFilterPayload.filter,
                                                                "bookingDate": {
                                                                    from: value.valueOf(),
                                                                    to: endDate.valueOf()
                                                                }
                                                            }

                                                        }
                                                    })
                                                } else {
                                                    this.setState({
                                                        startDate: moment().subtract(30, 'd').startOf('day'),
                                                        endDate: moment().endOf(),
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            filter: {
                                                                ...this.state.newFilterPayload.filter,
                                                                "bookingDate": {
                                                                    from: moment().subtract(30, 'd').startOf('day').valueOf(),
                                                                    to: moment().endOf().valueOf(),
                                                                }
                                                            }

                                                        }
                                                    })
                                                }

                                            }}
                                            onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                                        // onOpenChange={this.handleStartOpenChange}
                                        />
                                    </Col>

                                    <Col span={12} style={{ bottom: 5 }}>
                                        <span className="form-label-text">To </span>
                                        <DatePickerInput
                                            type="date"
                                            disabledDate={this.disabledEndDate}
                                            format="YYYY-MM-DD"
                                            value={endDate}
                                            placeholder="To"
                                            style={{ width: '100%' }}
                                            // onOk={this.onEndDateChange}
                                            open={isEndDateOpen}
                                            onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                                            onChange={value => {
                                                if (value) {
                                                    this.setState({
                                                        endDate: value,

                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            filter: {
                                                                ...this.state.newFilterPayload.filter,
                                                                "bookingDate": {
                                                                    from: startDate.valueOf(),
                                                                    to: value.valueOf()
                                                                }
                                                            }

                                                        }

                                                    })
                                                } else {
                                                    this.setState({
                                                        startDate: moment().subtract(30, 'd').startOf('day'),
                                                        endDate: moment().endOf(),
                                                        newFilterPayload: {
                                                            ...this.state.newFilterPayload,
                                                            filter: {
                                                                ...this.state.newFilterPayload.filter,
                                                                "bookingDate": {
                                                                    from: moment().subtract(30, 'd').startOf('day').valueOf(),
                                                                    to: moment().endOf().valueOf(),
                                                                }
                                                            }

                                                        }
                                                    })
                                                }

                                            }}
                                        // onOpenChange={this.handleStartOpenChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Booking office</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedLocations}
                                        onSearch={this.fetchLocations}
                                        showSearch
                                        loading={this.state.locationLoading}
                                        notFoundContent={"Start Typing to search (3 charachters)..."}
                                        labelInValue
                                        onBlur={this.resetLocations}
                                        placeholder={"Select Booking office"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onChangeLocations}
                                    >
                                        {this.state?.locations?.map((location, key) => (
                                            <Option key={location.key ? location.key : location.value} value={location.value}>
                                                {location.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Customer</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedCustomers}
                                        onSearch={this.fetchCustomers}
                                        showSearch
                                        onBlur={this.resetCustomers}
                                        loading={this.state.customerLoading}
                                        notFoundContent={"Start Typing to search (3 charachters)..."}
                                        labelInValue
                                        placeholder={"Select Customers"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onChangeCustomers}
                                    >
                                        {this.state?.customers?.map((customer, key) => (
                                            <Option key={customer.key ? customer.key : customer.value} value={customer.value}>
                                                {customer.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Docket Type</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.movementTypes.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("movementTypes", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Flow Type</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.docketFlowTypes.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("docketFlowTypes", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Docket Status</div>
                                <div className="boxfilterTitles">
                                    {this.state.initalFilters.consignmentStatuses.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("consignmentStatuses", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>



                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Verification Status</div>
                                <div className="boxfilterTitles">
                                    {this.state?.initalFilters?.consignmentVerificationStatuses?.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("consignmentVerificationStatuses", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.showHistoryModal}
                    title="History"
                    style={{ top: 20 }}
                    onOk={this.cancelHistory}
                    onCancel={this.cancelHistory}
                    width={1200}
                    footer={null}
                >
                    <Table
                        bordered
                        pagination={false}
                        columns={getHistoryTable()}
                        dataSource={history}
                    />
                </Modal>

            </div >
        )
    }
}

export default VerificationDockets

export const TRIP_TYPES = [
    {
        label: 'FTL',
        value: 'FTL'
    },
    {
        label: 'PTL',
        value: 'PTL'
    },
    {
        label: 'PickUp',
        value: 'PICKUP'
    },
]

export const TRIP_STATUS = [
    {
        label: 'Open',
        value: 'OPEN'
    },
    {
        label: 'Started',
        value: 'STARTED'
    },
    {
        label: 'Finished',
        value: 'FINISHED'
    },
    {
        label: 'Deleted',
        value: 'DELETED'
    },
    {
        label: 'Cancelled',
        value: 'CANCELLED'
    },
    {
        label: 'Completed',
        value: 'COMPLETED'
    },
]

export const PLACEMENT = [
    {
        label: 'Adhoc',
        value: 'ADHOC'
    },
    {
        label: 'Planned',
        value: 'PLANNED'
    },
]

export const VERIFICATIONS = [
    {
        label: 'Verified',
        value: 'VERIFIED'
    },
    {
        label: 'UnVerified',
        value: 'UNVERIFIED'
    },
]

export const PAYMENT = [
    {
        label: 'No Payment',
        value: 'NO_PAYMENT'
    },
    {
        label: 'Partial',
        value: 'PARTIAL'
    },
    {
        label: 'Complete',
        value: 'COMPLETE'
    },
];

export const setinitialFilters = {
    movementTypes: [
        { id: 0, name: "FTL", value: false, storedValue: "FTL" },
        { id: 1, name: "PTL", value: false, storedValue: "PTL" }
    ],
    docketFlowTypes: [
        { id: 0, name: "Forward", value: false, storedValue: "FORWARD" },
        { id: 1, name: "Reverse", value: false, storedValue: "RTO" }
    ],
    consignmentStatuses: [
        { id: 0, name: "Booked", value: false, storedValue: "BOOK" },
        { id: 1, name: "Inventory", value: false, storedValue: "IN" },
        { id: 2, name: "In Transit", value: false, storedValue: "IN_TRANSIT" },
        // { id: 3, name: "INWARD", value: false, storedValue: "INWARD" },
        // { id: 4, name: "OFD", value: false, storedValue: "OFD" },
        // { id: 5, name: "DRS_LINK", value: false, storedValue: "DRS_LINK" },
        { id: 3, name: "Delivered", value: false, storedValue: "DEL" },
    ],
    consignmentVerificationStatuses: [
        // { id: 0, name: "Approved", value: false, storedValue: "APPROVED" },
        { id: 0, name: "Document Pending", value: false, storedValue: "DOCUMENT_PENDING" },
        { id: 1, name: "Pending Verification ", value: false, storedValue: "DOCUMENT_VERIFICATION_PENDING" },
        { id: 2, name: "Rejected", value: false, storedValue: "REJECTED" },
        { id: 3, name: "Required Correction", value: false, storedValue: "CORRECTION_REQUIRED" },
        { id: 4, name: "Verified", value: false, storedValue: "VERIFIED" },
    ],
}