
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const EwayReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_EWAY_BEGIN: return fetchEwayBegin(state);
        case _Constants.FETCH_EWAY_SUCCESS: return fetchEwaySuccess(state, action);
        case _Constants.FETCH_EWAY_FAILURE: return fetchEwayFailure(state, action);    
        
        
        case _Constants.FETCH_SEARCH_EWAY_BEGIN: return fetchEwayBegin(state);
        case _Constants.FETCH_SEARCH_EWAY_SUCCESS: return fetchEwaySuccess(state, action);
        case _Constants.FETCH_SEARCH_EWAY_FAILURE: return fetchEwayFailure(state, action);    
        default:
            return state;
    }
}


function fetchEwayBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchEwaySuccess(state, action) {
    return {
        ...state,
        loading: false,
        ewayBills: action?.payload?.transporterEwaybillResponse
    };
}

function fetchEwayFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        rules: []
    };
}
