import React, { Component } from "react";
import { Modal, Table, Switch, Row, Col, Spin } from 'antd';

class PodHistory extends Component {

    constructor(props){
        super(props);
        this.state = {
            showRejectHistory: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            showHistoryModal : nextProps.showHistoryModal
        }
    }
    
    getHistoryTable = () => {
        return [
            {
                title: 'Updated Date & Time',
                dataIndex: 'timestamp',
                key: 'timestamp',
            },
            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
            },
            {
                title: 'Changes details',
                dataIndex: 'message',
                key: 'message'
            }
        ]
    }

    changeRejectHistory = showRejectHistory => {
        this.setState({ showRejectHistory }, () => {
            this.props.viewPodHistory(showRejectHistory)
        })
    }

    cancelHistory = () => {
        this.setState({
            showHistoryModal: false,
            showRejectHistory: false
        }, () => {
            this.props.cancelHistory();
        })
    }
    
    render(){
        return (
            <Modal
                visible={this.state.showHistoryModal}
                title="History"
                onCancel={this.cancelHistory}
                width={900}
                style={{ top: 20 }}
                footer={null}
            >
                <Spin spinning={this.props.fetchingHistory}>
                    <Row gutter={[0, 12]}>
                        <Col span={24}>
                            Rejection History <Switch checked={this.state.showRejectHistory} onChange={this.changeRejectHistory} />
                        </Col>
                        <Col span={24}>
                            <Table
                                bordered
                                pagination={false}
                                columns={this.getHistoryTable()}
                                dataSource={this.props.history}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        )
    }
    
}

export default PodHistory;