
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const SLARulesReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_SLA_RULES_BEGIN: return fetchSLARulesBegin(state);
        case _Constants.FETCH_SLA_RULES_SUCCESS: return fetchSLARulesSuccess(state, action);
        case _Constants.FETCH_SLA_RULES_FAILURE: return fetchSLARulesFailure(state, action);        
        default:
            return state;
    }
}


function fetchSLARulesBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchSLARulesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        rules: action.payload
    };
}

function fetchSLARulesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        rules: []
    };
}
