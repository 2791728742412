
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';

export default class DrsService {

    getAllDrs(application = Constants.SELECTED_APPLICATION.toLowerCase()) {
        const url = `/${application}/v1/drs/all`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    getDrsBylimit(application = Constants.SELECTED_APPLICATION.toLowerCase(), params) {
        const url = `/${application}/v1/drs/all`;
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    updateDrs(data, application = Constants.SELECTED_APPLICATION.toLowerCase()) {
        const url = `/${application}/v1/drs/update`;
        return apiRequest({
            url,
            method: "POST",
            data,
        });
    }

    getDrs(drsId, application = Constants.SELECTED_APPLICATION.toLowerCase()) {
        const url = `/${application}/v1/drs/${drsId}`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    getAllDrsByUser(params) {
        const app = (params.application) ? params.application : 'b2b';
        const url = `/${app}/v1/drs/all/${params.userId}`;
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    closeDrs(data, application = Constants.SELECTED_APPLICATION.toLowerCase()) {
        const url = `/${application}/v1/drs/close`;
        return apiRequest({
            url,
            method: "POST",
            data
        });
    }

    validateDrs(params, application = Constants.SELECTED_APPLICATION.toLowerCase()) {
        const url = `/${application}/v1/drs/validate`;
        return apiRequest({
            url,
            method: "POST",
            data: params,
        });
    }

    validateConsignment(params, application = Constants.SELECTED_APPLICATION.toLowerCase()) {
        const url = `/${application}/v1/drs/validate/consignment`;
        return apiRequest({
            url,
            method: "POST",
            data: params,
        });
    }

    fetchDrs(application = Constants.SELECTED_APPLICATION.toLowerCase(), params) {
        const url = `/${application}/v1/drs/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: params,
        });
    }

    updateStatus(params) {
        const url = `/${Constants.SELECTED_APPLICATION.toLowerCase()}/v1/drs/status`;
        return apiRequest({
            url,
            method: "PUT",
            data: params,
        });
    }
}
