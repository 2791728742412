import React, { useState, useEffect } from "react";
import { Button, Icon, Input, Spin } from "antd";
import {
  getPotentialBrachColumns,
  AtRiskColumns,
  OnTimeColumns,
} from "../state/columns";
import SLAListingTable from "../components/Listing/SLAListingTable";
import {
  downloadSlAReport,
  getSLACustomers,
  getSLAListing,
  getSLALocations,
  getSLASummary,
} from "../services/sla-monitoring.service";
import SLASummary from "../components/Listing/SLASummary";
import SLAAlerts from "../components/Listing/SLAAlerts";
import SelectCheckbox from "../../ui/SelectCheckbox/SelectCheckbox";
import "./sla-listing-page.scss";
import { Constants } from "../../../common/utils/constants";
import _ from "lodash";
import { reportsDownload } from "../../../common/utils/utils";

const SLAListing = (props) => {
  const filters = props.location.state?.filters;
  console.log("inside listing", filters);
  const [dockets, setDockets] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [breachedStatus, setBreachedStatus] = useState(
    filters?.slaBreachStatus ? filters?.slaBreachStatus : "BREACHED"
  );
  const [waybill, setWaybill] = useState("");
  const [showSummary, setShowSummary] = useState(true);
  const [customersData, setCustomersData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationIdsFilter, setLocationIDsFilter] = useState(
    filters?.locationIds ? filters?.locationIds : []
  );
  const [customerIdsFilter, setCustomerIDsFilter] = useState(
    filters?.customerIds ? filters?.customerIds : []
  );
  const [doReset, setDoReset] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isAdmin, setAdmin] = useState(
    JSON.parse(atob(localStorage.getItem("user")))
  );
  const [pagination, setPagination] = useState({
    pageNo: filters?.pageNo ? filters?.pageNo : 1,
    pageSize: filters?.pageSize ? filters?.pageSize : 10,
  });
  const [waybillValue, setWaybillValue] = useState(undefined);
  useEffect(() => {
    const filters = {
      filters: {
        customerIds: customerIdsFilter,
        locationIds: locationIdsFilter,
        slaBreachStatus: breachedStatus,
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize,
      },
    };
    if (!waybill) {
      getDockets(filters, undefined);
    }
  }, [pagination, locationIdsFilter, customerIdsFilter]);
  useEffect(() => {
    getSummary({
      filters: {
        customerIds: customerIdsFilter,
        locationIds: locationIdsFilter,
      },
    });
  }, [locationIdsFilter, customerIdsFilter]);
  useEffect(() => {
    getCustomers();
    getLocations();
  }, []);
  useEffect(() => {
    if (waybillValue) {
      const filters = {
        customerIds: customerIdsFilter,
        locationIds: locationIdsFilter,
        slaBreachStatus: breachedStatus,
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize,
      };
      props.history.push("/appv2/sla-monitoring/detailed", {
        waybillno: waybillValue,
        filters: filters,
      });
    }
  }, [waybillValue]);
  const getDockets = async (payload, isWaybill) => {
    setLoading(true);
    const docketResponse = await getSLAListing(payload);

    setDockets(docketResponse);
    setLoading(false);

    if (isWaybill && !_.isEmpty(docketResponse)) {
      if (docketResponse.slaBreachDocketResponses[0].slaBreachStatus) {
        const status =
          docketResponse.slaBreachDocketResponses[0].slaBreachStatus;
        setBreachedStatus(status);

        if (status === "BREACHED" || status === "CPD_BREACHED") {
          setColumns(getPotentialBrachColumns(slaEvents));
        } else if (status === "AT_RISK") {
          setColumns(AtRiskColumns(slaEvents));
        } else {
          setColumns(OnTimeColumns(slaEvents));
        }
      }
    }
  };

  const getSummary = async (payload) => {
    const summaryResponse = await getSLASummary(payload);

    setSummary(summaryResponse);
  };

  const getCustomers = async (payload) => {
    console.log("filyets", filters);
    const customerResponse = await getSLACustomers();
    const newCustomerResponse = customerResponse.map((cr) => {
      return {
        id: cr.id,
        label: cr.name,
        selected:
          filters?.customerIds?.length > 0
            ? filters?.customerIds?.indexOf(cr.id) >= 0
            : false,
      };
    });
    setCustomersData(newCustomerResponse);
  };
  const getLocations = async (payload) => {
    const locationResponse = await getSLALocations();
    const newLocationsResponse = locationResponse.map((cr) => {
      return {
        id: cr.id,
        label: cr.alias,
        selected:
        filters?.locationIds?.length > 0
          ? filters?.locationIds?.indexOf(cr.id) >= 0
          : false,
      };
    });
    setLocationData(newLocationsResponse);
  };

  const onSearchDocket = (value) => {
    setWaybillValue(value);
    // props.history.push("/appv2/sla-monitoring/detailed", { waybillno: value });
  };
  const slaEvents = {
    onSearchDocket: onSearchDocket,
  };
  const [columns, setColumns] = useState(getPotentialBrachColumns(slaEvents));
  const onChangeSummary = (sum) => {
    switch (sum) {
      case "breachedCount":
        setColumns(getPotentialBrachColumns(slaEvents));

        setBreachedStatus("BREACHED");
        setPagination((pagination) => {
          return {
            ...pagination,
            pageNo: 1,
            pageSize: 10,
          };
        });

        break;
      case "atRiskCount":
        setColumns(AtRiskColumns(slaEvents));

        setBreachedStatus("AT_RISK");
        setPagination((pagination) => {
          return {
            ...pagination,
            pageNo: 1,
            pageSize: 10,
          };
        });
        break;
      case "onTimeCount":
        setColumns(OnTimeColumns(slaEvents));

        setBreachedStatus("ON_TIME");
        setPagination((pagination) => {
          return {
            ...pagination,
            pageNo: 1,
            pageSize: 10,
          };
        });
        break;
      default:
        setColumns(getPotentialBrachColumns(slaEvents));

        setBreachedStatus("BREACHED");
        setPagination((pagination) => {
          return {
            ...pagination,
            pageNo: 1,
            pageSize: 10,
          };
        });
        break;
    }
  };
  const searchByWaybillNumber = (e) => {
    const value = e;

    if (value) {
      const payload = {
        filters: {
          waybillNos: [value.trim()],
        },
      };
      setShowSummary(false);

      setDoReset(true);
      getDockets(payload, value);
      setLocationIDsFilter([]);
      setCustomerIDsFilter([]);
      // setWaybill(value);
    } else {
      const filters = {
        filters: {
          customerIds: [],
          locationIds: [],
          slaBreachStatus: "BREACHED",
          pageNo: pagination.pageNo,
          pageSize: pagination.pageSize,
        },
      };
      setWaybill(undefined);
      setShowSummary(true);
      setBreachedStatus("BREACHED");
      setColumns(getPotentialBrachColumns(slaEvents));
      getDockets(filters, undefined);

      setDoReset(false);
    }
  };

  const onAppy = (payload) => {
    setWaybill("");
    setShowSummary(true);
    setDoReset(false);
    if (payload.filterFor === "locationIds") {
      if (props.isAllSet) {
        setLocationIDsFilter([]);
      } else {
        const getIds = payload.locationIds.map((loc) => loc.id);
        setLocationIDsFilter(getIds);
      }
    } else {
      if (props.isAllSet) {
        setCustomerIDsFilter([]);
      } else {
        const getIds = payload.customerIds.map((cs) => cs.id);
        setCustomerIDsFilter(getIds);
      }
    }
  };
  const onChangeText = (value) => {
    setWaybill(value);
    // if(!value) {
    //   const filters = {
    //     filters: {
    //       customerIds: [],
    //       locationIds: [],
    //       slaBreachStatus: "BREACHED",
    //       pageNo: pagination.pageNo,
    //       pageSize: pagination.pageSize,
    //     },
    //   };
    //   setShowSummary(true);
    //   setColumns(getPotentialBrachColumns(slaEvents));
    //   setBreachedStatus("BREACHED");

    //   setDoReset(false);
    //   getDockets(filters);
    // }
  };

  const downloadSla = async () => {
    setDownloadLoading(true);
    const payload = {
      partnerId: Constants.PARTNER_ID,
      locationId: Constants.LOGGED_PARTNER_LOCATION_ID,
      slaBreachStatus: breachedStatus,
      ...(customerIdsFilter.length > 0 && { customerIds: customerIdsFilter }),
      ...(locationIdsFilter.length > 0 && { locationIds: locationIdsFilter }),
    };
    const downloadReport = await downloadSlAReport(payload);

    reportsDownload(downloadReport.url, "s3_report_url");
    setDownloadLoading(false);
  };

  return (
    <div className="flex-column spacer-s flex-gap-l sla-listing-container">
      <div className="white-bg flex-box border-radius-s white-bg flex-gap-l spacer-m sla-listing-container_filters">
        <div className="flex-1">
          <Input
            placeholder="Search by waybill number"
            prefix={<Icon type="search" />}
            value={waybill}
            onChange={(e) => onChangeText(e.target.value)}
            onPressEnter={(e) => searchByWaybillNumber(e.target.value)}
            suffix={
              <>
                {waybill && (
                  <Icon
                    type="close-circle"
                    onClick={() => searchByWaybillNumber(undefined)}
                  />
                )}
              </>
            }
          />
        </div>
        <div className="flex-1">
          <SelectCheckbox
            key="hubs"
            allPlaceholder="hubs"
            label="Actionable hub"
            placeholder="Select actionable hubs"
            dataSource={locationData}
            isAllSet={isAdmin.userLevel === "ADMIN"}
            onApply={onAppy}
            filterFor={"locationIds"}
            doReset={doReset}
            
          />
        </div>
        <div className="flex-1">
          <SelectCheckbox
            key="customers"
            allPlaceholder="customers"
            label="Customer"
            isAllSet={isAdmin.userLevel === "ADMIN"}
            placeholder="Select customers"
            dataSource={customersData}
            onApply={onAppy}
            filterFor={"customerIds"}
            doReset={doReset}
            
          />
        </div>
      </div>
      <div className="white-bg flex-column border-radius-s white-bg flex-gap-l spacer-m">
        {showSummary && (
          <SLASummary
            summary={summary}
            onSummaryClick={onChangeSummary}
            breahcedStatus={breachedStatus}
          />
        )}
        <div className="flex-box sla-alerts flex-gap-l">
          <div className="flex-6 text-align-center">
            <SLAAlerts
              slaStatus={
                // !showSummary && dockets
                //   ? dockets.slaBreachDocketResponses[0].slaBreachStatus
                //   : breachedStatus
                breachedStatus
              }
            />
          </div>
          <div className="flex-1">
            <Button
              onClick={downloadSla}
              loading={downloadLoading}
              disabled={
                _.isEmpty(dockets) ||
                (dockets && dockets?.slaBreachDocketResponses?.length <= 0)
              }
            >
              Download Excel <Icon type="download" />
            </Button>
          </div>
        </div>

        <Spin spinning={isLoading}>
          {dockets && (
            <SLAListingTable
              columns={columns}
              data={dockets}
              slaEvents={slaEvents}
              setPagination={setPagination}
              pagination={pagination}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default SLAListing;
