import { Icon, Modal } from "antd";
import React from "react";
import { Mixpanel } from "../../../../../../utils/mixpanel";
import moment from "moment";
import { RatesTooltip } from "./box.charges.components";

export default function ChargeListRow(props) {

    const { charge, serviceType, chargeType } = props;
    return (
        <div className="box-charges-list-row flex-box">
            <div className="flex-2">
                { 
                    (serviceType === "FTL" && chargeType === "FREIGHT_CHARGE")? 
                    charge.chargeBasisValue :
                    charge.details?.itemsBO ? charge.details?.itemsBO?.label : "--" 
                }
            </div>
            
            {
                serviceType === "PTL" ?
                <>
                    <div className="flex-2">
                        {
                            charge.freightCharge ?
                            <div className="flex-box flex-gap-m align-items-center">
                                <div> { charge.freightCharge?.rateStructure === "FIXED" ? "Fixed" : "Multiple" } </div>  
                                <RatesTooltip amount={charge.freightCharge?.amount} rateBO={charge.freightCharge?.rateBOs} fixed={charge.freightCharge?.rateStructure === "FIXED"} />
                            </div> : 
                            <> -- </>
                        }
                    </div>
        
                    <div className="flex-2">
                        {
                            charge.labourCharge ?
                            <div className="flex-box flex-gap-m align-items-center">
                                <div> { charge.labourCharge?.rateStructure === "FIXED" ? "Fixed" : "Multiple" } </div>  
                                <RatesTooltip amount={charge.labourCharge?.amount} rateBO={charge.labourCharge?.rateBOs} fixed={charge.labourCharge?.rateStructure === "FIXED"} />
                            </div> :
                            <>--</>
                        }
                    </div>
                </> :
                serviceType === "FTL" ?
                <div className="flex-2">
                    <div className="flex-box flex-gap-m align-items-center">
                        {
                            chargeType === "LOADING_CHARGE" ?
                            <>
                                <div> { charge.labourCharge?.rateStructure === "FIXED" ? "Fixed" : "Multiple" } </div>  
                                <RatesTooltip amount={charge.labourCharge?.amount} rateBO={charge.labourCharge?.rateBOs} fixed={charge.labourCharge?.rateStructure === "FIXED"} />
                            </> :
                            chargeType === "FREIGHT_CHARGE" ?
                            <> Rs. {charge.itemRatesBO?.rateBOs?.[0].amount} </> :
                            <> -- </>
                        }
                    </div>
                </div> : 
                <> -- </>
            }

            <div className="flex-1 flex-box align-items-center flex-gap-l">
                {
                    !props.readonly &&
                    <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
                        <Icon type="edit" onClick={() => {                            
                            Mixpanel.track("Clicking Edit button in Charges BO", { 'ContractChargesBOEditClicked': moment().valueOf() });
                            props.onViewEditClick({
                                charge   : charge,
                                readonly : false,
                                index    : props.rowIndex
                            });
                        }} />
                    </div>
                }
                <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
                    <Icon type="eye" onClick={() => {
                        props.onViewEditClick({
                            charge   : charge,
                            readonly : true,
                            index    : props.rowIndex
                        });
                    }} />
                </div>
                {
                    !props.readonly &&
                    <div className="spacer-xs flex-box">
                        <Icon type="minus-circle" theme="twoTone" twoToneColor="#de1c22" onClick={() => {
                            Modal.confirm({
                                title   : "Remove box charge",
                                content : <> Are you sure to remove charges? </>,
                                onOk    : _ => {
                                    Mixpanel.track("Remove box charge", { 'ContractChargesBORemoveBox': moment().valueOf() });
                                    props.onRemove({
                                        charge   : charge,
                                        readonly : true,
                                        index    : props.rowIndex
                                    })
                                    Modal.destroyAll();
                                },
                                okText  : "Yes"
                            })
                        }} />
                    </div>
                }
            </div>
        </div>
    )
}

export function ChargeListHeader(props) {
    const { serviceType, chargeType } = props;
    return (
        <div className="box-charges-list-header flex-box">
            {
                serviceType === "PTL" &&
                <>
                    <div className="flex-2"> <strong> Box Type </strong> </div>
                    <div className="flex-2"> <strong> Freight Charge </strong> </div>
                    <div className="flex-2"> <strong> Labour Charge </strong> </div>
                    <div className="flex-1"> <strong> Actions </strong> </div>
                </>
            }
            {
                serviceType === "FTL" && chargeType === "LOADING_CHARGE" &&
                <>
                    <div className="flex-2"> <strong> Box Type </strong> </div>
                    <div className="flex-2"> <strong> Labour Charge </strong> </div>
                    <div className="flex-1"> <strong> Actions </strong> </div>
                </>
            }
            {
                serviceType === "FTL" && chargeType === "FREIGHT_CHARGE" &&
                <>
                    <div className="flex-2"> <strong> Vehicle Type </strong> </div>
                    <div className="flex-2"> <strong> Freight Charge </strong> </div>
                    <div className="flex-1"> <strong> Actions </strong> </div>
                </>
            }
        </div>
    )
}