const schema = {
    "type"        : "object",
    "properties"  : {
        "rejectReasonsObj" : {
            "required"    : [ "rejectReasons" ],
            "type"        : "object",
            "title"       : "Select Reject Reason",
            "properties": {
                "rejectReasons": {
                    "type"          : "string",
                    "searchEnabled" : true,
                    "multiple"      : true
                }
            }
        },
        "remarksObj" : {
            "type"       : "object",
            "properties": {
                "remarks": {
                    "type"          : "string"
                }
            }
        }
    }
}

const uiSchema = {
    "rejectReasonsObj"    : {
        "rejectReasons": {
            "classNames"          : "hide-title flex-1",
            "ui:placeholder"      : "Select Reject Reason"
        }
    },
    "remarksObj" : {
        "classNames"      : "hide-title",
        "remarks"         : {
            "classNames"  : "hide-title flex-1",
            "ui:widget"   : "TextareaWidget",
            "placeholder" : "Additional Remarks/Instructions",
            "rows"        : 3,
            "resize"      : false,
            "maxLength"   : 254
        }
    }
}
const errorList = {
    "rejectReasonsObj"    : {
        "rejectReasons"   : {
            "required": "Reject Reasons are mandatory!"
        }
    }
}

export default { schema, uiSchema, errorList };