import React from 'react';
import './inventory-drs-info.scss';
import { dataGet } from '../../../../common/utils/utils';
import { FlowTypes } from '../../../../common/utils/constants';
import { Icon, Tag, Row, Col } from 'antd';
import moment from 'moment';

export default function InventoryDrsInformation({ consignmentDetail }) {

    const flowTypes = FlowTypes;
    let shipmentLength = 0
    let con = "";
    if (consignmentDetail)
        con = consignmentDetail?.consignments?.map(consignmentDetails => {
            if (consignmentDetails.shipmentBOs) {
                shipmentLength += consignmentDetails.totalShipmentCount;
            }

            return consignmentDetails
        });
    else return ''
    const printPage = () => {
        (window).print();
    }

    const isReturnDrs = () => {
        const drsFlowType = dataGet(consignmentDetail, 'flowType');
        return (drsFlowType === flowTypes.RTO || drsFlowType === flowTypes.RTS);
    }
    const getStatus = () => {
        switch (consignmentDetail.drsStatus) {
            case 'CLOSED': return <Tag color="#93BE52">{consignmentDetail.drsStatus}</Tag>
            case 'PENDING': return <Tag color="#FC6180">{consignmentDetail.drsStatus}</Tag>
            case 'HANDED_OVER': return <Tag color="#69CEC6">{consignmentDetail.drsStatus}</Tag>
            case 'OPEN': return <Tag color="#FE8A7D">{consignmentDetail.drsStatus}</Tag>
            case 'COMPLETED': return <Tag color="#FFB64D">{consignmentDetail.drsStatus}</Tag>
            case 'IN_TRANSIT': return <Tag color="#3B5A98">{consignmentDetail.drsStatus}</Tag>
        }
    }
    return (
        <div className="inventory-drs-info">
            <div className="waybill-header-info">
                <span className="awb-number">{consignmentDetail.drsCode}</span>
                <span className="print" onClick={e => printPage()}> <Icon type="printer" /> </span>
                <span className="status">{getStatus()}</span>
                <ul className="quick_info" style={{ padding: 0 }}>
                    <li>Waybill - <span> {consignmentDetail?.consignments?.length || '0'}</span><span className="splitter">|</span></li>
                    <li>Shipment - {shipmentLength ? <span> {shipmentLength}</span> : <span>1</span>}   </li>
                    <li className="float-right">{consignmentDetail.cityPincode}</li>
                </ul>
            </div>
            <div className="driver-details">
                <span className="fn-bold">Driver: </span>
                {
                    consignmentDetail.asset && <span className="info-value">{consignmentDetail.asset.driverName} | {consignmentDetail.asset.contactNumber} | {consignmentDetail.asset.vehicleType} | {consignmentDetail.asset.registrationNumber}</span>
                }
            </div>
            <ul className="more-info-list">
                {
                    !!consignmentDetail.consignments && consignmentDetail.consignments.map(congsignment => {
                        return <li>
                            <Row>
                                <Col span={12}>
                                    <div className="waybill-details">
                                        <div className="fn-bold">
                                            {congsignment.waybillNo} - {congsignment.location.name}
                                        </div>
                                        <div className="wabill-count">
                                            Shipments-
                                    {
                                                (!congsignment.totalShipmentCount) ? <span>1</span> : <span>{congsignment.totalShipmentCount}}</span>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    {
                                        !(isReturnDrs()) && <div className="destination">
                                            CPD: {moment(consignmentDetail.effectiveDate).format('DD/MM/YYYY HH:MM')} hrs
                              </div>
                                    }
                                </Col>
                            </Row>
                        </li>
                    })
                }
            </ul>

        </div >
    )
}
