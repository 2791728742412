import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RouteWithTitle from "../RouteWithTitle";
import "../app.scss";
import AccessDenied from "../../components/authentication/access/AccessDenied";
import { canAllow } from "../../common/utils/utils";

class BlankLayout extends Component {

  render() {
    const { path, component, name, access} = this.props;
    return (
      <>
              <Layout>
                <Layout>
                    {path && component && <RouteWithTitle key={name} exact={true} path={path} 
                    component={access === undefined || canAllow(access) ? component : AccessDenied } name={name}/>}
                </Layout>
              </Layout>
      </>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(BlankLayout))
);
