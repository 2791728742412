import { notifyApiError } from "../../common/utils/utils";
import { apiRequest } from "../../common/utils/apiGateway";

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getDashboardUrl = (type) => dispatch => {
    dispatch(getDashboardUrlBegin());
    apiRequest({
        url: `/v1/tpdashboard`,
        method: "GET",
        params: {
            type
        }
    }).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(getDashboardUrlSuccess(res?.data?.response.dashboards));
        } else {
            dispatch(getDashboardUrlFailure(res?.data?.response));
        }
    });
};

function getDashboardUrlBegin() {
    return {
        type: 'GET_DASHBOARD_BEGIN'
    };
}

function getDashboardUrlSuccess(payload) {
    return {
        type: 'GET_DASHBOARD_SUCCESS',
        payload: payload
    };
}

function getDashboardUrlFailure(error) {
    return {
        type: 'GET_DASHBOARD_FAILURE',
        payload: error
    };
}