import { Modal } from "antd";
import { isEmpty } from "lodash";
import { apiRequest } from "../../../../common/utils/apiGateway";
import { BASE_URL_TYPES } from "../../../../common/utils/constants";
import { checkAPIHttpStatus, checkHttpStatus, notifyApiError } from "../../../../common/utils/utils";

export const getIndentById = id => new Promise((resolve, reject) => {
    apiRequest({
        url         : `/b2b/v1/indent/${id}`,
        method      : "GET",
        baseUrlType : BASE_URL_TYPES.DEV
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response);
        }
    }).catch(e => reject(e))
})

export const createIndent = data => new Promise((resolve, reject) => {
    apiRequest({
        url         : "/b2b/v1/indent/",
        method      : data.id ? "PUT" : "POST",
        baseUrlType : BASE_URL_TYPES.DEV,
        data        : data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response);
        }
    }).catch(e => reject(e.data?.status))
})

export const dataToPostObj = (data, status) => {
    const user = JSON.parse(atob(localStorage.user));
    const postObj = {
        id                : data.id,
        indentDate        : data.details.indentDate,
        userId            : user?.id,
        customerId        : typeof data.details?.customer?.key === "string" && data.details.customer?.key.indexOf("___") > -1 ?
                            parseInt(data.details.customer?.key.split("___")[0]) :
                            data.details.customer?.key,
        shipperId         : data.details.shipper?.key,
        vehicleTypeId     : data.details.vehicleType?.key,
        serviceTypeCode   : data.details.serviceType?.key,
        pickupLocationId  : data.details.pickupLocation?.key,
        remarks           : data.remarksObj?.remarks,
        status            : status ? "SUBMIT" : undefined,
        indentLoads       : (data.indentLoads ? data.indentLoads.map(load => {
            if (!validateIndividualLoad(load) || load.location?.id) {
                return {
                    destinationLocationId : load.location?.id,
                    indentId   : load.id && data.id ?  data.id : undefined,
                    id         : load.id ?  load.id : undefined,
                    volume     : load.volume   !== undefined ? parseFloat(load.volume) : undefined,
                    weight     : load.weight   !== undefined ? parseFloat(load.weight) : undefined,
                    quantity   : load.quantity !== undefined ? parseFloat(load.quantity) : undefined,
                    weightUnit : "Kg",
                    volumeUnit : "CFT"
                }
            }
            else {
                return null;
            }
        }) : []).filter(l => l !== null),
        "isAdhoc":data.details.isAdhoc ? data.details.isAdhoc: false,
        "revenue":data.details.isAdhoc ? data.details.revenue: undefined,
        "hubLocationId":data.details.hubLocationId
    }

    if (data.details.serviceType.key == "10110") {
        // postObj.laneId = data.ftlData.lane?.key;
        postObj.lane = data.ftlData.lane;
        postObj.pickupAt = data.ftlData.pickupAt;
    }    
    return postObj
}

export const responseToData = response => {
    const data = {};
    Object.keys(response).map(key => {        
        if (key === "customerBO") {
            data[key] = {
                key   : response[key].id + "___" + response[key].code,
                value : response[key].id + "___" + response[key].code,
                label : response[key].name
            }
        }
        else if ( [ "shipperBO", "vehicleTypeBO", "pickupLocationBO", "laneBO" ].indexOf(key) > -1 ) {
            data[key] = {
                key   : response[key].id,
                value : response[key].id,
                label : response[key].name
            }
        }
        else if(key === "serviceTypeBO") {
            data[key] = {
                key     : response[key].lovCode,
                value   : response[key].lovCode,
                label   : response[key].lovValue
            }
        }       
        else {
            data[key] = response[key]
        }
    })
    const formData = {
        id      : data.id,
        status  : data.status,
        details : {
            customer       : data.customerBO,
            shipper        : data.shipperBO,
            serviceType    : data.serviceTypeBO,
            pickupLocation : data.pickupLocationBO,
            vehicleType    : data.vehicleTypeBO,
            indentDate     : data.indentDate,
            "isAdhoc"      : data.isAdhoc,
            "revenue"      : data.revenue,
            "hubLocationId": data.hubLocationId    
        },
        remarksObj  : {
            remarks : data.remarks
        },
        reasonBOList: data.reasonBOList,
        indentLoads : data.indentLoads ? data.indentLoads.map(i => ({
            ...i,
            location : i.destinationLocationBO ? i.destinationLocationBO : {}
        })) : []
    }
    if (data.serviceTypeBO.key == "10110") {
        formData["ftlData"] = {
            lane     : data.lane,
            pickupAt : data.pickupAt
        }
    }
    return formData;
}

const validateIndividualLoad = load => {
    return ( isEmpty(load.weight?.toString()) && isEmpty(load.volume?.toString()) && isEmpty(load.quantity?.toString()) )
}

export const validateIndentLoads = (indentLoads, isPTL, showErrorMessage = true) => {
    var loadsInvalid = false;
    var invalidLoadIndices = [];
    indentLoads.map((load, index) => {
        if ( (isPTL && validateIndividualLoad(load)) || (!isPTL && (validateIndividualLoad(load) && !load?.location?.id)) ) {
            loadsInvalid = true;
            invalidLoadIndices.push(index + 1);
        }
    });
    if (loadsInvalid && showErrorMessage) {
        Modal.error({
            title : "Destination load(s) " + invalidLoadIndices.join(", ") +  (invalidLoadIndices.length <= 1 ? " is" : " are") +" invalid! Kindly enter at least one expected load."
        })
    }
    return (!loadsInvalid);
}

export const updateIndentStatus = data => new Promise((resolve, reject) => {
    apiRequest({
        url         : "/b2b/v1/indent/status",
        method      : "POST",
        baseUrlType : BASE_URL_TYPES.DEV,
        data        : data
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response);
        }
    }).catch(e => reject(e))
})

export const submitDraftIndent = id => new Promise((resolve, reject) => {
    apiRequest({
        url         : `b2b/v1/indent/submit/${id}`,
        method      : "POST",
        baseUrlType : BASE_URL_TYPES.DEV
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response);
        }
    }).catch(e => reject(e))
})

export const convertToNoContractLanes = data => {

    data.laneBO.laneMappingBOs = data.laneBO.laneMappingBOs.map(lane => {
        return {
            entityId    : lane.entityBO.id,
            entityType  : data.laneBO.laneType,
            index       : lane.index
        }
    })

    return data;
}

export const downloadIndentList = params => new Promise((resolve, reject) => {
    apiRequest({
        url         : `/b2b/v1/indent/export`,
        method      : 'POST',
        baseUrlType : BASE_URL_TYPES.DEV,
        data        : params
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response?.url)
        }
        else {
            reject(response);
        }
    }).catch(e => notifyApiError(e?.data?.status?.message))
})