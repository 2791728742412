import { Button, Modal, Spin, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../../../../ui/formComponent/form";
import ChargeListRow, { ChargeListHeader } from "../box-charges/box.charge.row";
import { fetchPTLCharges, itemsListToSelectList, removeCharges, saveContractCharges } from "../contract.details.services";
import "./box.charges.scss"
import { groupChargesByBox, formChargesArray } from "./charges.utils";
import { ptlSchema } from "../box-charges/box.charges.schema";
import { getMaxFromIndex, validateChargeBO } from "../box-charges/box.charges.service";
import { getValueFromKey, notifyApiError } from "../../../../../../common/utils/utils";

export default function PTLCharges(props) {
    
    var formRef = useRef();
    const [ loading, SetLoading ]     = useState(false);
    const [ readonly, SetReadonly ]   = useState(false);
    const [ chargesBO, SetChargesBO ] = useState([]);
    const [ selectedCharge, SetSelectedCharge ]   = useState(false);
    const [ isMultipartRate, SetIsMultipartRate ] = useState(false);

    const loadCharges = _ => {
        SetLoading(true);
        const { contractData } = props;
        fetchPTLCharges(contractData?.id)
        .then(contractCharges => {
            var tempChargesBO = contractCharges?.chargesBO.map(charge => {
                charge.itemsBO = {
                    ...charge.itemsBO,
                    uniqueIdentifier : charge.itemsBO?.itemType + "_" + charge.itemsBO?.itemId
                }
                return charge;
            });
            tempChargesBO = groupChargesByBox(tempChargesBO);
            SetChargesBO(tempChargesBO);
            SetLoading(false);
        })
        .catch(_ => SetLoading(false))
    }

    useEffect(() => {
        const { contractData } = props;
        ptlSchema.schema.properties.details.properties.itemsBO["apiCall"] = value => {
            if (value.trim() === "") {
              return new Promise(resolve => {
                resolve([{
                  value: "OTHER_0",
                  key: "OTHER_0",
                  label: "Default"
                }])
              })
            }
            return itemsListToSelectList(contractData?.customerId, value.trim())
        }
        loadCharges();
    }, []);

    const onViewEditClick = params => {
        SetSelectedCharge(_ => {
            var tempCharge = {...params.charge};
            if (!tempCharge.labourCharge) {
                tempCharge.labourCharge = { rateBOs : [{}] };
            }
            return tempCharge;
        });
        SetReadonly(params.readonly);
        SetIsMultipartRate(params.charge?.freightCharge ? params.charge?.freightCharge?.isMultipartRate : false);
    }

    const addCharge = _ => {
        SetReadonly(false);
        SetIsMultipartRate(false);
        SetSelectedCharge({
            freightCharge     : {
                rateBOs       : [{}]
            },
            labourCharge : {
                rateBOs       : [{}]
            }
        })
    }

    const onRemove = params => {
        removeCharges(props?.contractData?.id, [
            params.charge?.freightCharge?.chargeId,
            params.charge?.labourCharge?.chargeId
        ])
        .then(_ => loadCharges());
    }

    const validate = (data, errorObj) => {
		var validationObjects = [];
        if (data?.freightCharge?.rateStructure) {
            validationObjects = validateChargeBO(data?.labourCharge, "labourCharge");
        }
        if (data?.labourCharge?.rateStructure) {
            validationObjects = [ ...validationObjects, validateChargeBO(data?.labourCharge, "labourCharge") ];
        }
		if (validationObjects?.length === 0) {
			return errorObj;
		}
		else {
			validationObjects.map(validator => {
				const propertyLabel = validator[1];
				if (validator[0] && propertyLabel) {
					var errorKeyObj = getValueFromKey(errorObj, propertyLabel);
					if (errorKeyObj) {
						errorKeyObj.__errors = [validator[0]];
					}
				}
				else if (propertyLabel && validator[0] === undefined) {
					var errorKeyObj = getValueFromKey(errorObj, propertyLabel);
					if (errorKeyObj) {
						errorKeyObj.__errors = [];
					}
				}
			})
			return errorObj;
		}
	}

    const transformErrors = errors => {
		const maxFromIndexProperty = ".freightCharge.rateBOs[" + getMaxFromIndex(selectedCharge?.freightCharge) + "].toRange";
        var maxFromIndexPropertyLabour = undefined;
        if (selectedCharge?.labourCharge && selectedCharge?.labourCharge?.rateStructure) {
            maxFromIndexPropertyLabour = ".labourCharge.rateBOs[" + getMaxFromIndex(selectedCharge?.labourCharge) + "].toRange";
        }
		var noErrorFields = [ ".itemRatesBO", ".itemRatesBO.rateBOs", maxFromIndexProperty, maxFromIndexPropertyLabour]
		errors.map(error => {
			if (error.property && noErrorFields.indexOf(error.property) != -1) {
				error.message = undefined;
			}
			else if (error.property.indexOf("fromRange") > -1 && error.name == "required") {
				error.message = "From range is mandatory!";
			}
			else if (error.property.indexOf("toRange") > -1 && error.name == "required") {
				error.message = "To range is mandatory!";
			}
			else if (error.property === ".boxBO.id" && error.name == "type") {
				error.message = undefined;
			}
        })
        return errors;
	}

    const onSubmit = data => {
        if (!data?.labourCharge?.rateStructure && !data?.freightCharge?.rateStructure) {
            return Modal.warn({
                title : "Add at least one charge to save!"
            });
        }
        const boxExists = chargesBO.find(charge => charge?.details?.itemsBO?.key === data?.details?.itemsBO?.key)
        const isCreate  = data?.freightCharge.chargeId === undefined;
        if (boxExists && isCreate) {
            return Modal.warn({
                title : "Charge(s) for box already exists. Choose specific row to edit its charges."
            })
        }
        const updatedChargesBO = formChargesArray(data, false, isMultipartRate);
        saveContractCharges(props?.contractData?.id, updatedChargesBO)
        .then(_ => {
            loadCharges();
            SetSelectedCharge(false);
        })
        .catch(exception => {
            if (exception?.errorMessage && exception?.errorMessage.indexOf("_") > -1) {
                exception.errorMessage = exception?.errorMessage.split("_").join(" ");
            }
            notifyApiError(exception?.errorMessage)
        })
    }

    return (
        <div className="flex-column spacer-m flex-gap-xl">
            <div className="text-bold font-size-m-3"> Charges </div>
            <div className="box-charges-list flex-column spacer-m flex-gap-l border-grey border-radius-m">
                <Spin spinning={loading}>
                    {
                        !props.readonly &&
                        <div className="flex-box"> <Button type="primary" className="lsn-btn-primary" onClick={addCharge}> Add Charge </Button> </div>
                    }
                    <ChargeListHeader serviceType={"PTL"} />
                    {
                        chargesBO && chargesBO.length > 0 &&
                        chargesBO.map((charge, index) => (
                            <ChargeListRow key={index} charge={charge} rowIndex={index} serviceType={"PTL"} 
                            onViewEditClick={onViewEditClick} onRemove={onRemove} readonly={props.readonly} />
                        ))
                    }
                </Spin>
            </div>

            {
                selectedCharge && 
                <Modal visible={true} title={"Add Charges"} width={1000}
                 onOk={_ => formRef?.current?.formReference?.current?.submit()}
                 onCancel={_ => SetSelectedCharge(undefined)} okText={"Save"} >
                    <div className="flex-column flex-gap-l">
                        <div className="flex-box justify-content-end flex-gap-m">
                            <div> Multi-Part </div>
                            <Switch onChange={SetIsMultipartRate} disabled={readonly} checked={isMultipartRate} />
                        </div>
                        <FormWrapper {...ptlSchema} ref={formRef} id="ptlCharges"
                        formData={selectedCharge} transformErrors={transformErrors}
                        readonly={readonly} fieldsPerRow={2} bordered={true}
                        hideSubmit={true} onSubmit={onSubmit} validate={validate}
                        onChange={data => {
                            var freightRateStructure = data.freightCharge?.rateStructure,
                            laboutRateStructure = data.labourCharge?.rateStructure,
                            freightRateBOs = data.freightCharge?.rateBOs,
                            laboutRateBOs = data.labourCharge?.rateBOs;

                            if ((freightRateStructure && freightRateStructure != "FIXED") && (!freightRateBOs || freightRateBOs?.length <= 0)) {
                                data.freightCharge.amount = undefined;
                                data.freightCharge["rateBOs"] = [{}]
                            }
                            if ((laboutRateStructure && laboutRateStructure != "FIXED") && (!laboutRateBOs || laboutRateBOs?.length <= 0) ) {
                                data.labourCharge.amount = undefined;
                                data.labourCharge["rateBOs"] = [{}]
                            }
                            SetSelectedCharge(data);
                        }} />
                    </div>
                </Modal>
            }

        </div>
    )
}