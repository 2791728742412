import ScanWarning from '../assets/audio/scan-warning.mp3';
import ScanSuccess from '../assets/audio/scan-success.mp3';
import ScanGSuccess from '../assets/audio/scan-general-success.m4a';
import ScanGWarning from '../assets/audio/scan-general-warning.m4a';

export class SoundNotificationService {
    soundWarning;
    soundSuccess;
    soundGeneralSuccess;
    soundGeneralFailure;

    constructor() {
        this.soundWarning = new Audio(ScanWarning);
        this.soundSuccess = new Audio(ScanSuccess);
        this.soundGeneralSuccess = new Audio(ScanGSuccess);
        this.soundGeneralFailure = new Audio(ScanGWarning);
    }

    playWarning() {
        this.soundWarning.play();
    }

    playSuccess() {
        this.soundSuccess.play();
    }

    playGeneralSuccess() {
        this.soundGeneralSuccess.play();
    }

    playGeneralWarning() {
        this.soundGeneralFailure.play();
    }

}