import React from 'react'
import { Map as LeafletMap, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Button, Icon } from 'antd';
import "./locationMap.scss";


import GooglePlaceAutoComplete from '../ui/google-place-autocomplete/google.place-autocomplete';
const pinkicon = L.icon({
  iconUrl:
    "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
  iconSize: [30, 30],
  iconAnchor: [10, 30],
});

class LocationMap extends React.Component {
  
  constructor(props) {
    
    super(props);
    this.state = {
      draggable: typeof this.props.draggable === undefined ? true : this.props.draggable,
      height: "200px"
    }
    this.refmarker = React.createRef();
  }

  updateDimensions() {
    const height = window.innerWidth >= 992 ? window.innerHeight : 400
    this.setState({ height: height })
  }
  componentDidMount() {
    this.setState({ lat: this.props.lat, lng: this.props.lng });
    window.addEventListener("resize", this.updateDimensions.bind(this))

  }
  componentWillMount() {
    this.updateDimensions()
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }


  componentDidUpdate(prevProps, prevState) {
    if(this.props.draggable !== prevProps.draggable) {
      this.setState({ lat: this.props.lat, lng: this.props.lng, draggable: this.props.draggable })
    }
    if (this.props.lat !== prevProps.lat) {
      this.setState({ lat: this.props.lat })
    }
    if (this.props.lng !== prevProps.lng) {
      this.setState({ lng: this.props.lng })
    }
  }

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      
      this.setState({
        lat: marker.leafletElement.getLatLng().lat,
        lng: marker.leafletElement.getLatLng().lng,
      })
      this.props.updateMarker({ lat: marker.leafletElement.getLatLng().lat, lng: marker.leafletElement.getLatLng().lng });
      

    }

  }

  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable })
  }

  
  render() {
    let position;
    if (this.state.lat && this.state.lng) {
      position = [this.state.lat, this.state.lng];
    }
    
    return (
      <>

        <LeafletMap
          style={{ height: 200 }}
          center={position}
          zoom={15}
          maxZoom={18}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
        >
          {this.props?.showFullScreen &&
            <div className="flex-box align-items-center justify-content-flex-end">
              <Button
                className="leaflet-fullscreen"
                onClick={() => typeof this.props?.fullScreenCallback === 'function' ? this.props?.fullScreenCallback() : () => console.log("fullscreen")}
              >

                <Icon type="fullscreen" />
              </Button>
            </div>
          }
          <TileLayer
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
          />
          {this.state.lat && <Marker ref={this.refmarker} position={position} icon={pinkicon} draggable={this.state.draggable}
            onDragend={this.updatePosition}>
            <Popup width={150} direction="auto" className="locationPopform">
              <div className="tooltiplsn">
                <div className="tooltipLocation">{this.props.selectedAddress}</div>
              </div>
            </Popup>
            <Circle
              center={{ lat: position[0], lng: position[1] }}
              fillColor="#f09924"
              color={"#f09924"}
              fillOpacity={0.3}
              opacity={0.4}
              // pathOptions={{ fillColor: 'blue', stroke: false }}
              radius={300} />
          </Marker>}
        </LeafletMap>
        {
          this.props?.showCordinates &&
          <div
            className="flex-column"
            style={{
              background: "#FFFFFF",
              border: "1px solid #E6E7E7",
              boxSizing: "border-box",
              borderRadius: "0px 0px 8px 8px",
              color: "#3E3F42",
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "24px"
            }}
          >
            <div>
              Coordinates: {this.state.lat}, {this.state.lng}
            </div>
            <div>
              Geofence Radius: 300m
            </div>
          </div>
        }

      </>
    );
  }
}

export default LocationMap