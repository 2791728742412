import { Button, Icon, Input, Modal, Select, Tooltip } from 'antd';
import React , { Component } from 'react';
import { capitalizeString, cloneObject } from '../../../../common/utils/utils'
import editPenCil from '../../../../assets/img/icons/edit_pencil.svg';
import DepsImages from './deps-images';

class DepsRow extends Component{

    depsImageMinCount = JSON.parse(atob(localStorage.getItem('_configrations'))).imageValidation?.deps?.minCount
    constructor(props){
        super(props);
        this.state = {
            edit: false,
            newDeps: props.defaultDepsItem === undefined || props.defaultDepsItem.depsType === false,
            depsItem: props.defaultDepsItem,
            updatedDepsItem : cloneObject(props.depsItem)
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.consignmentDetailsUpdated || nextProps.depsDeleted) {
            var defaultDepsItem = nextProps.defaultDepsItem;
            if(nextProps.defaultDepsItem == undefined) {
                defaultDepsItem = {
                    depsType: false,
                    remarks: '',
                    images: []
                };
            }
            return {
                ...prevState,
                edit: false,
                depsItem: defaultDepsItem,
                updatedDepsItem : cloneObject(nextProps.depsItem),
                newDeps: defaultDepsItem == undefined || defaultDepsItem.depsType == false
            }
        }
        return prevState;
    }

    componentDidMount(){
        const { depsType, remarks } = this.props.depsItem;
        if(depsType == false || remarks == '' || depsType == undefined || remarks == undefined){
            this.setState({ edit: true });
            this.props.toggleEditDeps(true);
        }
    }

    depsTypes = [ "Damage", "Excess", "Pilferage", "Shortage", "Mismatch"]

    handleTypeChange = depsType => {
        this.setState(current => {
            // if(current.depsItem?.depsType != depsType){
            //     delete current.updatedDepsItem.id;
            // }
            // else{
            //     current.updatedDepsItem.id = current.depsItem?.id ? current.depsItem.id : undefined;
            // }
            current.updatedDepsItem.depsType = depsType
            return current;
        })
    }
    
    onImageUpload = (imageURL) => {
        this.setState(current => {
            current.updatedDepsItem.images.push(imageURL);
            return current;
        });
    }

    onImageDelete = (delIndex) => {
        this.setState(current => {
            current.edit = this.props.verificationPermissions.editPermissions;
            current.updatedDepsItem.images = current.updatedDepsItem.images.filter((image, index) => index != delIndex)
            return current;
        }, () => {
            this.props.onDepsUpdate(this.state.updatedDepsItem, this.props.index, true);
        });
    }

    handleRemarksInput = e => {
        let remarks = e.target.value;
        if(remarks.length > 128){
            remarks = remarks.substr(0,127);
        }
        this.setState(current => {
            current.updatedDepsItem.remarks = remarks
            return current;
        })
    }

    deleteDepsItem = _ => {
        this.setState({ edit: false});
        this.props.toggleEditDeps(false);
        this.props.onDepsDeleteItem(this.props.index);
    }

    confirmDeleteDepsItem = _ => {
        Modal.confirm({
            title: 'Delete DEPS!',
            content: 'Do you want to delete this DEPS?',
            onOk: () => this.deleteDepsItem(),
            onCancel: Modal.destroyAll,
            okText: 'Yes',
            cancelText: 'No'
        })
    }

    saveUpdatedDeps = () => {
        const existingDepsTypes = this.props.existingDepsTypes;
        const { depsType, remarks, images } = this.state.updatedDepsItem;
        if (depsType == false || depsType == undefined) {
            return Modal.error({
                title: "Invalid DEPS details",
                content: "Choose a DEPS type!"
            });
        }
        else if (remarks == '' || remarks == undefined) {
            return Modal.error({
                title: "Invalid DEPS details",
                content: "DEPS Remarks cannot be empty!"
            });
        }
        else if (this.depsImageMinCount > 0 && images.length < this.depsImageMinCount) {
            return Modal.error({
                title: "Invalid DEPS details",
                content: "DEPS should contain at least " + this.depsImageMinCount + " images!"
            });
        }
        else if ( existingDepsTypes.includes(depsType) && existingDepsTypes.indexOf(depsType) != this.props.index) {
            return Modal.error({
                title: "DEPS Type exists",
                content: "The DEPS type " + capitalizeString(depsType.toLowerCase()) + " is already added!"
            });
        }
        this.setState({ edit:false }, () => {
            this.props.onDepsUpdate(this.state.updatedDepsItem, this.props.index, false)
        });
    }

    cancelDepsChanges = () => {
        const resetChanges = () => {
            this.setState(current => {
                return {
                    ...current,
                    updatedDepsItem: cloneObject(current.depsItem),
                    edit:false
                }
            }, () => {
                this.props.onDepsUpdate(this.state.updatedDepsItem, this.props.index, false)
            });
        }

        const defaultDepsType = this.state.depsItem?.depsType;
        const defaultDepsRemarks = this.state.depsItem?.remarks;
        const defaultDepsImages = this.state.depsItem?.images;
        const defaultDepsTypeChosen = defaultDepsType == false || defaultDepsType == undefined;
        const defaultDepsRemarksEntered = defaultDepsRemarks == '' || defaultDepsRemarks == undefined;
        if (defaultDepsTypeChosen && defaultDepsRemarksEntered){
            return Modal.confirm({
                title: "Empty DEPS reset",
                content: "No DEPS details present. Do you want to delete this DEPS?",
                onOk : () => {
                    Modal.destroyAll();
                    this.deleteDepsItem();
                    return true;
                },
                onCancel: Modal.destroyAll,
                okText: 'Yes',
                cancelText: 'No'
            })
        }
        else if (defaultDepsTypeChosen) {
            return Modal.error({
                title: "DEPS Type",
                content: "No DEPS type chosen!"
            })
        }
        else if (defaultDepsRemarksEntered) {
            return Modal.error({
                title: "DEPS Remarks",
                content: "No DEPS Remarks entered!"
            })
        }
        else if (defaultDepsType !== this.state.updatedDepsItem.depsType || defaultDepsRemarks !== this.state.updatedDepsItem.remarks
                 || defaultDepsImages?.join(',') != this.state.updatedDepsItem?.images?.join(',')) {
            return Modal.confirm({
                title: "Reset changes",
                content: "Do you want to reset the changes made?",
                onOk: resetChanges
            })
        }
        resetChanges();
    }

    editTooltip = () => {
        if(this.props.disableEdit){
            return  "Save the open DEPS to edit!"
        }
        else if(this.props.verifiedPod){
            return "Verified POD cannot be modified!"
        }
        else if(!this.props.verificationPermissions.editPermissions){
            return  "Restricted!"
        }
        return undefined;
    }

    render(){

        const { depsType, remarks } = this.state.updatedDepsItem;
        const { edit } = this.state;
        const { editPermissions, deletePermissions, createPermissions } = this.props.verificationPermissions;

        return (
            <div className="flex-box flex-gap-l align-items-center spacer-m grey-bg" style={{minHeight: 75}}>
                <div className="flex-2">
                    {
                        edit ?
                        <Select style={{ width: '100%' }} defaultValue={depsType ? depsType : undefined}
                         placeholder="Select DEPS Type" onChange={this.handleTypeChange}>
                            {
                                this.depsTypes.map((type, index) => {
                                    return (
                                        <Select.Option value={type.toUpperCase()} key={index}> { type } </Select.Option>
                                    )
                                })
                            }
                        </Select> :
                        <span className={depsType != this.state.depsItem?.depsType || this.state.newDeps ? "info-color" : ""}>
                            { depsType ? capitalizeString(depsType.toLowerCase()) : <>--</> }
                        </span>

                    }
                </div>
                <div style={{wordBreak:'break-word'}} className="flex-2">
                    {
                        edit ?
                        <Input value={remarks} placeholder="Enter remarks" onChange={this.handleRemarksInput}/> :
                        <span className={remarks != this.state.depsItem?.remarks || this.state.newDeps ? "info-color" : ""}>
                            { remarks ? remarks : <>--</> }
                        </span>
                    }
                </div>
                <div className="flex-4">
                    <DepsImages edit={edit} images={this.state.updatedDepsItem?.images} defaultImages={this.state.depsItem?.images}
                     imageGalleryId={this.state.updatedDepsItem.depsType + "-PodDepsImages"}
                     onImageUpload={this.onImageUpload} onImageDelete={this.onImageDelete} verifiedPod={this.props.verifiedPod}
                     verificationPermissions={this.props.verificationPermissions} disableEdit={this.props.disableEdit}/>
                </div>
                {
                    (editPermissions || deletePermissions || createPermissions) && 
                    <div className="flex-1 flex-box flex-gap-l justify-content-end align-items-center">
                        {
                            edit? 
                            <>
                                <Icon type="check-circle" theme="twoTone" twoToneColor="#4caf50" style={{ fontSize: 24 }}
                                    onClick={ this.saveUpdatedDeps }/>
                                <Icon type="close-circle" theme="twoTone" title="Reset changes" twoToneColor="#ff9801"
                                    style={this.state.newDeps ? { fontSize: 24, opacity: .5, cursor: 'not-allowed' } : { fontSize: 24 }}
                                    onClick={ !this.state.newDeps ? this.cancelDepsChanges : undefined }/>
                            </> :
                            <Tooltip title={this.editTooltip()}>
                                <Button className="deps-ant-button cursor-pointer"
                                 disabled={this.props.disableEdit || !editPermissions || this.props.verifiedPod}
                                 size="small" shape="circle" onClick={() => this.setState({edit: true}, () => this.props.toggleEditDeps(true))}>
                                    <img src={editPenCil} />
                                </Button>
                            </Tooltip>
                        }
                        {
                            deletePermissions && !this.props.verifiedPod &&
                            <Icon type="minus-circle" theme="twoTone" twoToneColor="#e6492d" style={{ fontSize: 24 }}
                                onClick={() => this.confirmDeleteDepsItem()}/>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default DepsRow;