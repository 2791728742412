import React from 'react';
import { Table, Spin, Card, Button, Icon, Tag, Badge } from 'antd';
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../ui/notification/Notification';
import FilterIcon from "../../assets/img/icons/filter-icon.svg";
import ReportFilters from "./report.filters";
import { Constants } from '../../common/utils/constants';
import { canAllow, windowRedirect } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
const formatValidity = date => (moment(Number(date)).format('DD/MM/YYYY hh:mm:ss a'));
function ReportListing(props) {
    const { params, setParams, visible, setVisible, filterIndicator } = props;
    const b2cOldRepoert = {
        report: canAllow(permissionTags.reports.reports.read)
    };
    const columns = [
        {
            title: 'Report Type',
            dataIndex: 'reportType',
            key: 'reportType',
            align: 'left'

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: data => listingTransformed(data)

        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: data => formatValidity(data)

        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            align: 'center',
            render: data => formatValidity(data)
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            align: 'center',
            render: (data, record) => (
                <div className="flex-box align-items-center justify-content-flex-end">
                    {
                        record.status === 'COMPLETED' &&
                        <Button className="primary-btn" block onClick={() => {
                            window.open(record.s3Url, "_blank")
                        }}><Icon type="download" /> Download</Button>
                    }
                    <div>
                        <CopyToClipboard text={record.s3Url}
                            onCopy={notifyCopyEvent}>

                            <Button className="activity-btn"><Icon type="copy" /> Copy</Button>

                        </CopyToClipboard>
                    </div>
                </div>
            )
        }
    ];

    const listingTransformed = data => {

        if (data === 'INITIATED') {
            return <Tag className="ant-table-tag info-tag text-align-center"><span>In Progress</span></Tag>
        } else if (data === 'COMPLETED') {
            return <Tag className="ant-table-tag success-tag text-align-center"><span>Completed</span></Tag>
        }
        return <Tag className="ant-table-tag warning-tag text-align-center"><span>In Progress</span></Tag>
    }

    const notifyCopyEvent = () => {
        openNotification({
            message: 'Copied',
            description: 'Link has been copied',
            className: 'warning'
        })
    }

    const goToReport = () => {
        if (Constants.SELECTED_APPLICATION === 'B2B') {
            props.history.push('/appv2/reports/dashboard/download/report');
        } else {
            if (b2cOldRepoert) {
                windowRedirect('reports/dashboard/download-report')
            } else {
                props.history.push('/appv2/reports/dashboard/download/report');
            }
        }

    }

    return (
        <Spin spinning={props.loading}>
            {!props.loading &&
                <Card>
                    <div className="flex-column flex-gap-m">
                        <div className="flex-box">
                            <div>
                                <h3>Report Listing</h3>
                            </div>
                            <div className="flex-box justify-content-flex-end align-items-center flex-1">
                                <Badge dot={filterIndicator} offset={[-4, 1]}>
                                    <Button type="default" onClick={() => setVisible(true)}><img src={FilterIcon} alt="filter" /> Filters</Button>
                                </Badge>
                                <Button className="primary-btn" onClick={() => props.reportReload(params)}><Icon type="reload" /> Refresh</Button>
                                <Button className="primary-btn" onClick={() => goToReport()}><Icon type="plus-circle" /> Generate New Report</Button>
                            </div>
                        </div>
                        <div>
                            <Table
                                columns={columns}
                                dataSource={props?.reportListing?.documents}
                                rowKey={record => record.id}
                                pagination={{
                                    onChange: (page, pageSize) => setParams({ ...params, pageNo: page }),
                                    total: props.reportListing?.totalCount,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                    pageSize: props.reportListing?.currentPageSize,
                                    current: props.reportListing?.currentPageNo,
                                    showSizeChanger: true,
                                    onShowSizeChange: (_, size) => setParams({ ...params, pageSize: size }),
                                }}
                            />
                        </div>
                    </div>
                    {
                        visible &&
                        <ReportFilters
                            visible={visible}
                            {...props}
                        />
                    }
                </Card>
            }
        </Spin>
    )
}
export default ReportListing;