import React, { PureComponent } from 'react';
import './trip.scss';
import moment from 'moment';
import FileUpload from "../../ui/file-upload/file.upload";
import GooglePlaceAutoComplete from "../../ui/google-place-autocomplete/google.place-autocomplete";
import { getTripUpStatus, cancelTrip, updateETA, updateLead } from "./actions";

import {
    Card, Button, Table, Icon, Input, Spin, Checkbox, Radio, Tooltip, Descriptions, Switch, Tag,
    Row, Col, Modal, DatePicker, Select, Menu, Dropdown, Form, Alert, Collapse, InputNumber, Empty, Divider
} from 'antd';
import { UPLOAD_BUTTON_TYPE } from '../../../common/utils/constants';
import { permissionTags } from '../../../configs/permissionTags';
import { canAllow, notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import GPSIcon from '../../../assets/img/icons/gps.svg';
import Filters from '../../../assets/img/icons/filters.svg';
import phone from '../../../assets/img/icons/phone.svg';
import KirteGreen from '../../../assets/img/icons/near_me_green.svg';
import KirteRed from '../../../assets/img/icons/near_me_red.svg';
import PickupIcon from '../../../assets/img/icons/pickup_icon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../ui/notification/Notification';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import TimeTemplate from '../../cod/templates/time'
import CurrencyTemplate from '../../cod/templates/Currency';
import EwayGreen from '../../../assets/img/icons/eway_green.svg';
import EwayRed from '../../../assets/img/icons/eway_red.svg';
import EwayOrange from '../../../assets/img/icons/eway_orange.svg';
import * as apiUtils from '../../../common/utils/apiGateway';
import { Mixpanel } from '../../../utils/mixpanel';
import TripSummaryModal from './trip-summary';
import NonMarketAsset from "../../../assets/img/icons/non_market_asset.svg";
import MarketAsset from "../../../assets/img/icons/market_asset.svg";
import VehicleAvailable from "../../../assets/img/icons/vehicle_available.svg";
import SimAvailable from "../../../assets/img/icons/sim_available.svg";
import SimUnAvailable from "../../../assets/img/icons/sim_unavailable.svg";




const { Panel } = Collapse;
const InputGroup = Input.Group;
const { confirm, warning } = Modal;



function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

class TripList extends PureComponent {
    constructor(props) {
        super(props);
        // Mixpanel.identify(this.props.header.userInfo.loggedPartnerLocationId);
        this.refForm = React.createRef();
        Mixpanel.identify(this.props.header?.userInfo?.loggedPartnerId);
        Mixpanel.people.set({
            $first_name: this.props?.header?.userInfo?.loggedPartnerId
        });
        this.state = {
            clearFileMarkDelivery: false,
            startTripLoading: false,
            markDelLoading: false,
            setDelayReason: undefined,
            updatedTime: undefined,
            tripLists: [],
            newAdhocCharges: [],
            docketNo: null,
            nextLocation: null,
            pendingTrips: [],
            tripid: null,
            tableLoading: false,
            handOverVisible: false,
            markDeliveryVisible: false,
            updateStatusVisible: false,
            handOverFilters: {},
            markDelFilters: { podCreatedDate: undefined },
            dataLocation: [],
            startTripVisible: false,
            completeTripVisible: false,
            acceptTypes: [".png", ".jpg", ".jpeg"],
            startTripFilters: {},
            manifestFilters: {},
            endTripFilters: {},
            updateStatusLoading: false,
            cancelVisible: false,
            cancelTripData: '',
            delayHours: 0,
            enableClose: false,
            wayBillInput: '',
            editTripVisible: false,
            editTripData: {},
            editManifestData: [],
            deletedManifestDeleted: [],
            editLeadData: [],
            deletedLeadManifest: [],
            showFilters: false,
            showManifestModal: false,
            clHandOverModal: false,
            clHandOverData: {},
            clManifestFilters: null,
            clStartTripFilters: null,
            ewayBills: [],
            endKMValue: undefined,
            actualTripEndTime: moment(),
            tripFTLType: false,
            deps: false,
            startFTLRevenue: {
                validateStatus: 'success',
                help: ''
            },
            uploadFTLRevenueProofError: {
                validateStatus: 'success',
                help: ''
            },
            startFTLAdvance: {
                validateStatus: 'success',
                help: ''
            },
            startFTLCost: {
                validateStatus: 'success',
                help: ''
            },
            startMeterReading: {
                validateStatus: 'success',
                help: ''
            },
            uploadMeterProofError: {
                validateStatus: 'success',
                help: ''
            },
            startTripCost: {
                validateStatus: 'success',
                help: ''
            },
            uploadTripCostProofError: {
                validateStatus: 'success',
                help: ''
            },
            endTripKMS: {
                validateStatus: 'success',
                help: ''
            },
            actualTripDateEndTime: {
                validateStatus: 'success',
                help: ''
            },
            endTripCost: {
                validateStatus: 'success',
                help: ''
            },
            endTripCostImage: {
                validateStatus: 'success',
                help: ''
            },
            endTripKMSImage: {
                validateStatus: 'success',
                help: ''
            },
            endTripCustomerLoad: {
                validateStatus: 'success',
                help: ''
            },
            endTripVendorLoad: {
                validateStatus: 'success',
                help: ''
            },
            endTripCustomerDemrruage: {
                validateStatus: 'success',
                help: ''
            },
            endTripVendorDemrruage: {
                validateStatus: 'success',
                help: ''
            },
            advanceAmountPaidError: {
                validateStatus: 'success',
                help: ''
            },
            uploadProofError: {
                validateStatus: 'success',
                help: ''
            },
            deliveryDateTime: {
                validateStatus: 'success',
                help: ''
            },
            uploadPODImages: {
                validateStatus: 'success',
                help: ''
            },
            signatureImage: {
                validateStatus: 'success',
                help: ''
            },
            lastKnownLocationError: {
                validateStatus: 'success',
                help: ''
            },
            updateTimeError: {
                validateStatus: 'success',
                help: ''
            },
            delayHoursError: {
                validateStatus: 'success',
                help: ''
            },
            delayReasonError: {
                validateStatus: 'success',
                help: ''
            },
            fields: {
                vehiclenumber: { value: undefined },
                gpsdevice: { value: 'gps' },
                mobilenumber: { value: undefined },
                gpsid: { value: undefined },
                drivername: { value: undefined },
                trackingnumber: { value: undefined },
                serviceprovider: { value: undefined },
                vendor: { value: undefined },
                reasonCategory: { value: undefined },
                reason: { value: undefined },
            },
            printTripID: null,
            consolidateModal: false,
            printModal: false,
            tripsPermission: {
                read: canAllow(permissionTags.trips.trips.read),
                update: canAllow(permissionTags.trips.trips.update),
                create: canAllow(permissionTags.trips.trips.create),
                delete: canAllow(permissionTags.trips.trips.delete),
                undel_return: canAllow(permissionTags.trips.trips.undel_return),
                coloader: canAllow(permissionTags.trips.trips.coloader)
            },

            listingFilters: {
                bookingFrom: '',
                bookingTo: '',
                tripId: '',
                tripStatusValue: '',
                location: undefined,
                assetFilter: undefined
            },
            downloadLink: null,
            startTripAdhoc: false,
            endTripAdhoc: false,
            adhocCharges: [
                { "id": 1, "name": "Loading Charges", value: "LoadingCharges", disabled: true, chargeValue: undefined, vendorCustomer: undefined },
                { "id": 2, "name": "Unloading Charges", value: "UnloadingCharges", disabled: true, chargeValue: undefined, vendorCustomer: undefined },
                { "id": 3, "name": "Toll Charges", disabled: true, value: "TollCharges", chargeValue: undefined, vendorCustomer: undefined },
                { "id": 4, "name": "Incentives", disabled: true, value: "Incentives", chargeValue: undefined, vendorCustomer: undefined },
                { "id": 5, "name": "Penalties", disabled: true, value: "Penalties", chargeValue: undefined, vendorCustomer: undefined },
                { "id": 6, "name": "Detention Charges", disabled: true, value: "DetentionCharges", chargeValue: undefined, vendorCustomer: undefined },
                { "id": 7, "name": "Other Charges", disabled: true, value: "OtherCharges", chargeValue: undefined, vendorCustomer: undefined },
                { "id": 8, "name": "Union Charges", disabled: true, value: "UnionCharges", chargeValue: undefined, vendorCustomer: undefined },
            ],
            charges: {},
            pickupWeight: undefined,
            editWeightClicked: false,
            editCountClicked: false,
            pickupCount: undefined,
            updatePickup: false,
            leadPickup: {},
            disablePickupCount: true,
            pickupButton: false,
            disablePickupWeight: true,
            expectedEta: '',
            showTripSettlement: false,
            selectedTripId: 0,
            tripSettlementColumns: [
                {
                    title: 'UTR No.',
                    dataIndex: 'utr',
                    key: 'utr',
                },
                {
                    title: 'Date and Time',
                    dataIndex: 'paymentDate',
                    key: 'paymentDate',
                    render: data => <TimeTemplate time={data} />
                },
                {
                    title: 'Activity',
                    dataIndex: 'Activity',
                    key: 'Activity',
                    render: (data, row) => row?.vendor?.name
                },
                {
                    title: 'User',
                    dataIndex: 'User',
                    key: 'User',
                    render: (data, row) => row?.user?.name
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: amt => <CurrencyTemplate data={amt} />
                },
                {
                    title: 'Payment Type',
                    dataIndex: 'paymentMode',
                    key: 'paymentMode',
                },
                {
                    title: 'Remarks',
                    dataIndex: 'paymentDescription',
                    key: 'paymentDescription',
                },
            ],

            EndTripLoading: false,
            handOverLoading: false,

            reasonCategories: [],
            reasons: [],
            editTripUpdateLoding: false,
            startKms: undefined,
            selectedPickupCancelReason: undefined,
            shipmentScan: false,
            shipmentBarCodes: [],
            scannedBarCodes: [],
            barcode: undefined,
            barcodeModal: false,
            shipmentScans: [],
            manifestId: undefined,
            markdelError: undefined,
            shipmentTableColumns: [
                {
                    title: 'Sr. No.',
                    dataIndex: 'sr',
                    key: 'sr',
                    render: (data, row, index) => index + 1
                },
                {
                    title: 'Refrence No',
                    dataIndex: 'shipmentCode',
                    key: 'shipmentCode',
                },
                {
                    title: 'Status',
                    dataIndex: 'action',
                    key: 'action',
                    render: (data, row, index) => (

                        <Row gutter={12}>
                            <Col span={6}>
                                <Icon onClick={() => this.deleteShipmentCode(data, row, index)} type="delete"></Icon>
                            </Col>
                            <Col span={6}>
                                <Icon style={{ color: 'green' }} type="check-circle"></Icon>
                            </Col>
                        </Row>
                    )
                }],


            reasonCategoryUndelReturn: undefined,
            reasonUndelReturn: undefined,
            undelFilter: {
                undelVisble: false,
                error: undefined,
                loading: false
            },

            undeliveredCount: 0,
            undeliveredTotal: 0,

            startTripAttributesPormpt: false,

            tripEndCost: undefined,
            tripEndImage: undefined,
            showTripEndCost: false,
            endtripCustomerCharges: undefined,
            endtripCustomerDemurrageCharges: undefined,
            endtripVendorCharges: undefined,
            endtripVendorDemurrageCharges: undefined,
            tripEndCostDisabled: false,
            showEndTripKMSPhoto: false,
            toPayAmtColledted: undefined,

            sim_tracking_enable: false,
            definedTrackingNumber: undefined,
            billingModel: [],
            // actualBillingModel: undefined,
            tripBillingModel: undefined,
            selectedBillingModel: undefined,

            replaceVehicleToggle: false,
            showToggle: false,
            billingVehiclesList: [],
            selectedVehicleBillingModel: undefined,

            showRateCard: false,
            warningMessage: [],
            showWarning: false,
            warningData: undefined


        }

        this.handleOverCancel = this.handleOverCancel.bind(this);
        this.handleOverOk = this.handleOverOk.bind(this);
        this.markDeliverOk = this.markDeliverOk.bind(this);
        this.markDeliverCancel = this.markDeliverCancel.bind(this);
        this.onChangePod = this.onChangePod.bind(this);


        this.endCancelModal = this.endCancelModal.bind(this);
        this.okCancelModal = this.okCancelModal.bind(this);


        this.toggleTripSettlememtModal = this.toggleTripSettlememtModal.bind(this);
        this.fetchTripSettlements = this.fetchTripSettlements.bind(this);

        this.getBillingModel();
    }

    componentDidUpdate(oldProps, prevState) {


        if ((!this.state.downloadLink && this.props.startTripManifestData?.response) ||
            (this.props.startTripManifestData?.response &&
                oldProps.startTripManifestData?.response &&
                oldProps.startTripManifestData?.response?.url != this.props.startTripManifestData?.response?.url)) {
            this.setState({ downloadLink: this.props.startTripManifestData?.response?.url });
        }

        if (this.props.endTripData !== oldProps.endTripData) {
            this.EndTripCancel();
            this.props.paginatedFilters(10);
        }




        // if (!_.isNull(this.state.printTripID) && this.props.printEwayData) {
        //     this.props.printEwayData.ewaybillPdfLinks.map(eway => {
        //         notifyApiSuccess(eway.pdfLink, `E-WAYBill - ${eway.ewaybillNo}`);
        //     });
        //     this.setState({ printTripID: null });
        //     this.props.clearPrintEway();
        // }
    }

    clHandleOverCancel = () => {

        this.setState({ clHandOverModal: false, clStartTripFilters: null, clManifestFilters: null, clHandOverData: {}, showWarning: false, warningData: undefined }, () => {
            // this.props.paginatedFilters(10);
        })
    }

    clHandOverOk = () => {
        // this.props.startTrip(this.state.clStartTripFilters, this.state.clManifestFilters);
        // setTimeout(() => {
        //     this.clHandleOverCancel();
        // }, 15000)
        console.log("this", this.state.clStartTripFilters, this.state.clHandOverData.data)
        const dockets = this.state.clHandOverData.data.map(cl => cl?.waybillNo);
        console.log("dockets", dockets)
        this.setState({ startTripLoading: true });
        apiUtils.apiRequest({
            url: `/b2b/v1/thc/status`,
            data: this.state.clStartTripFilters,
            method: "PUT"
        })
            .then(res => {
                console.log("response", res)
                if (res?.data?.status?.code === 500) {
                    const _that = this;
                    warning({
                        title: "Action Blocked",
                        icon: <Icon type="warning" theme="twoTone" twoToneColor="#FF0000" />,
                        content:
                            <>
                                <p>{res?.data?.status?.message}</p>


                                <CopyToClipboard text={[...dockets]} onCopy={_that.notifyCopyDocketEvent}>
                                    <div>
                                        <Icon type="copy" /> Copy Dockets
                                    </div>
                                </CopyToClipboard>


                            </>,
                        onOk: () => {
                            _that.setState({ startTripLoading: false });
                        }
                    })
                } else if (res?.data?.status?.code === 1001) {
                    const _that = this;
                    confirm({
                        title: "Warning",
                        content: res?.data?.status?.message,
                        icon: <Icon type="warning" theme="twoTone" twoToneColor="#FF0000" />,
                        okText: 'Yes',
                        cancelText: 'No',
                        onCancel: () => { _that.cancelWarning(res?.data) },
                        onOk: () => { _that.proceedWarning(this.state.clStartTripFilters) }
                    })
                }
            })

    }

    cancelWarning = (data) => {
        // let clData = this.state.clHandOverData.data;
        // clData.map(cl => {
        //     if(cl?.id === )
        // })
        this.setState({ showWarning: true, warningData: data });
    }

    proceedWarning = (filters) => {
        this.setState({ startTripLoading: true });
        filters = {
            ...filters,
            ignoreWarningMessage: true
        };
        apiUtils.apiRequest({
            url: `/b2b/v1/thc/status`,
            data: this.state.clStartTripFilters,
            method: "PUT"
        })
            .then(res => {
                if(res?.data?.status?.code === 200) {
                    this.clHandleOverCancel();
                    this.setState({ startTripLoading: false });
                } else {
                    this.setState({ startTripLoading: false });
                }
            });

    }


    startTrip = tripId => {
        if (tripId?.attributes?.tripType === 'CL') {
            const columns = [
                {
                    title: 'Docket ID',
                    dataIndex: 'waybillNo',
                    key: 'waybillNo',
                    // ...this.getColumnSearchProps('waybillNo')
                    render: (data, row) => {
                        if (this.state.showWarning, this.state.warningData) {

                            return this.state?.warningData?.response?.consignmentIdErrorMappings?.map(con => (
                                con.consignmentId === row.id &&
                                <>
                                    {row.waybillNo} <br />
                                    <Icon type="info-circle" theme="twoTone" twoToneColor="#FF0000" />{con?.message}
                                </>

                            ))
                        } else {
                            return <>{data}</>

                        }
                    }

                },
                {
                    title: <>Origin <Icon type="caret-right" /> Destination</>,
                    dataIndex: 'destination',
                    key: 'destination',
                    render: destination => <>{destination}</>
                },
                {
                    title: 'Customer',
                    dataIndex: 'customerName',
                    key: 'customerName'
                },
                {
                    title: <>Quantity / <br />Shipment Counts / <br />Total chargeable wt.</>,
                    dataIndex: 'quantity',
                    key: 'quantity'
                },
                {
                    title: 'Created User',
                    dataIndex: 'userName',
                    key: 'userName'
                },
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                }
            ];

            const data = [];
            let totalDockets = 0, selectedDockets = 0, totalQuantity = 0, totalWeight = 0;

            if (tripId.thcbos && tripId.thcbos.length > 0) {

                tripId.thcbos.map(thc => {

                    thc.manifests.map((manifest, index) => {

                        return manifest.consignments && manifest.consignments.length > 0 && manifest.consignments.map(consign => {
                            totalQuantity += consign.totalShipmentCount;
                            totalWeight += consign.totalWeight;

                            data.push({
                                'key': tripId.id + consign.waybillNo + index,
                                'waybillNo': consign.waybillNo,
                                'customerName': consign.customer.name,
                                'userName': consign.user.name,
                                'destination': <>{consign.customerPickupLoc.address.pincode.city.cityCode}-{consign.consignee.address.pincode.city.cityCode}</>,
                                'quantity': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                                'date': moment(consign.bookingDate).format("DD-MMM-YYYY hh:MM"),
                                'manifestCode': manifest.manifestCode,
                                'manifestId': manifest.id,
                                'assetId': tripId.asset.id,
                                'tripId': tripId.id,
                                'handOverPartnerId': manifest.destinationLoc.entityId,
                                ...consign
                            })

                        })
                    })
                });

            }

            if (tripId.drsbos && tripId.drsbos.length > 0) {
                tripId.drsbos.map(thc => {

                    return thc?.consignments?.map(consign => {
                        totalQuantity += consign.totalShipmentCount;
                        totalWeight += consign.totalWeight;
                        data.push({
                            'key': tripId.id + consign.waybillNo,
                            'waybillNo': consign.waybillNo,
                            'userName': consign.user.name,
                            'customerName': consign.customer.name,
                            'destination': <>{consign.customerPickupLoc.address.pincode.city.cityCode}-{consign.consignee.address.pincode.city.cityCode}</>,
                            'quantity': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                            'date': moment(consign.bookingDate).format("DD-MMM-YYYY hh:MM"),
                            'tripId': tripId.id,
                            ...consign
                        })
                    })

                });
            }

            totalDockets = data.length;
            selectedDockets = data.length;

            const rowSelection = {
                onChange: (selectedRowKeys, selectedRows) => {
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: record => ({
                    disabled: record, // Column configuration not to be checked
                    checked: record,
                }),
            };

            let filters = {
                "tripId": tripId.id,
                "force": true,
                "status": "ENROUTE"
            };

            let manifest = {
                "commCode": "MANIFEST_SHEET_LITE",
                "tripIds": [`${tripId.id}`],
                "documentType": "MANIFEST_SHEET_LITE",
                "locationId": this.props.header.loggedInUser.location.id,
                "partnerId": this.props.header.loggedInUser.partner.id,
                "userId": this.props.header.loggedInUser.id
            }



            this.setState({
                clHandOverModal: true, clStartTripFilters: filters, clManifestFilters: manifest,
                clHandOverData: {
                    columns: columns,
                    data: data,
                    rowSelection: rowSelection,
                    totalDockets,
                    selectedDockets,
                    totalQuantity,
                    totalWeight,
                }
            });


        } else {
            if (tripId?.attributes?.isAdhocTrip === "true") {
                this.setState({ startTripAdhoc: true })
            } else {
                this.setState({ startTripAdhoc: false })
            }
            if (tripId?.attributes?.tripLoadType === 'FTL') {
                this.setState({ tripFTLType: true });
                let filters = {
                    "routeId": tripId?.routeName?.id,
                    "assetId": tripId?.asset?.id,
                    "tripLoadType": tripId?.attributes?.tripLoadType,
                    "customerId": tripId?.attributes?.customerId,
                    "vehicleType": tripId?.asset?.vehicleType,
                    "tripStatus": "STARTED"
                }

                this.props.getTripRateCard(filters);
            } else {
                this.setState({ tripFTLType: false });
            }
            this.setState({ startTripVisible: true });
            let filters = {
                "tripId": tripId.id,
                "force": true,
                "attributes": {
                    "truckPhoto": null,
                    "startKms": null
                },
                "status": "ENROUTE"
            };

            let manifest = {
                "commCode": "MANIFEST_SHEET_LITE",
                "tripIds": [`${tripId.id}`],
                "documentType": "MANIFEST_SHEET_LITE",
                "locationId": this.props.header.loggedInUser.location.id,
                "partnerId": this.props.header.loggedInUser.partner.id,
                "userId": this.props.header.loggedInUser.id
            }

            this.setState({ startTripFilters: filters, manifestFilters: manifest });
        }




    }
    startTripOk = () => {
        if (!this.state?.startTripAttributesPormpt) {
            this.startTripCancel();
        } else {
            let errors = true;
            if (this.state.startTripAdhoc) {
                if (this.state.startTripFilters.attributes.tripCost == null) {
                    let { startTripCost } = this.state;
                    startTripCost = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    };

                    this.setState({ startTripCost });
                    return;
                } else {
                    let { startTripCost } = this.state;
                    startTripCost = {
                        validateStatus: 'success',
                        help: ''
                    };

                    this.setState({ startTripCost })
                }

                if (this.state.startTripFilters.attributes.advanceAmountPaid == null || this.state.startTripFilters.attributes.advanceAmountPaid < 0) {
                    let { advanceAmountPaidError } = this.state;
                    advanceAmountPaidError = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    };

                    this.setState({ advanceAmountPaidError });
                    return;
                } else {
                    let { advanceAmountPaidError } = this.state;
                    advanceAmountPaidError = {
                        validateStatus: 'success',
                        help: ''
                    };

                    this.setState({ advanceAmountPaidError })
                }

            }
            let { uploadProofError } = this.state;
            if (this.props.app.configurations.enable_trip_start_photo == "true") {
                if (!_.hasIn(this.state.startTripFilters.attributes, 'truckPhoto') || this.state.startTripFilters.attributes.truckPhoto == null) {
                    // let { uploadProofError } = this.state;
                    uploadProofError = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }

                    errors = false;
                    // this.setState({ uploadProofError });
                    // return;
                } else {
                    // let { uploadProofError } = this.state;
                    uploadProofError = {
                        validateStatus: 'success',
                        help: ''
                    }

                    // errors = true;
                    // this.setState({ uploadProofError });
                }
            }


            let { startMeterReading, startFTLCost, startFTLRevenue, startFTLAdvance, uploadTripCostProofError, uploadFTLRevenueProofError, uploadMeterProofError } = this.state;
            if (this.state.startTripFilters.attributes.startKms == null || this.state.startTripFilters.attributes.startKms < 0) {

                startMeterReading = {
                    validateStatus: 'error',
                    help: 'Field is Required'
                }
                errors = false;

                // this.setState({ startMeterReading });
                // return;
            } else {
                // let { startMeterReading } = this.state;
                startMeterReading = {
                    validateStatus: 'success',
                    help: ''
                }
                // errors = true;
                // this.setState({ startMeterReading });

            }

            if (this.state.tripFTLType) {

                if (this.state.startTripFilters.attributes.truckPhotoTripStart == null) {
                    uploadMeterProofError = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }
                    errors = false;
                } else {
                    uploadMeterProofError = {
                        validateStatus: 'success',
                        help: ''
                    }

                }

                if (this.state.startTripFilters.attributes.tripCost == null || this.state.startTripFilters.attributes.tripCost < 0) {

                    startFTLCost = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }
                    errors = false;

                    // this.setState({ startMeterReading });
                    // return;
                } else {
                    // let { startMeterReading } = this.state;
                    startFTLCost = {
                        validateStatus: 'success',
                        help: ''
                    }
                    // errors = true;
                    // this.setState({ startMeterReading });

                }

                if (this.state.startTripFilters.attributes.tripCostImage == null) {
                    uploadTripCostProofError = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }
                    errors = false;
                } else {
                    uploadTripCostProofError = {
                        validateStatus: 'success',
                        help: ''
                    }
                }


                if (this.state.startTripFilters.attributes.tripRevenue == null || this.state.startTripFilters.attributes.tripRevenue < 0) {

                    startFTLRevenue = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }
                    errors = false;

                    // this.setState({ startMeterReading });
                    // return;
                } else {
                    // let { startMeterReading } = this.state;
                    startFTLRevenue = {
                        validateStatus: 'success',
                        help: ''
                    }
                    // errors = true;
                    // this.setState({ startMeterReading });

                }

                if (this.state.startTripFilters.attributes.tripRevenueImage == null) {
                    uploadFTLRevenueProofError = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }
                    errors = false;
                } else {
                    uploadFTLRevenueProofError = {
                        validateStatus: 'success',
                        help: ''
                    }
                }


                if (this.state.startTripFilters.attributes.advanceAmountPaid == null || this.state.startTripFilters.attributes.advanceAmountPaid < 0) {

                    startFTLAdvance = {
                        validateStatus: 'error',
                        help: 'Field is Required'
                    }
                    errors = false;

                    // this.setState({ startMeterReading });
                    // return;
                } else {
                    // let { startMeterReading } = this.state;
                    startFTLAdvance = {
                        validateStatus: 'success',
                        help: ''
                    }
                    // errors = true;
                    // this.setState({ startMeterReading });

                }
            }


            this.setState({ startMeterReading, uploadProofError, startFTLCost, startFTLRevenue, startFTLAdvance, uploadTripCostProofError, uploadFTLRevenueProofError, uploadMeterProofError })
            if (errors) {
                // this.props.startTrip(this.state.startTripFilters, this.state.manifestFilters);
                // setTimeout(() => {
                //     this.startTripCancel();
                // }, 1500)
                this.setState({ startTripLoading: true }, () => {
                    apiUtils.apiRequest({
                        url: `/b2b/v1/thc/status`,
                        data: this.state.startTripFilters,
                        method: "PUT"
                    })
                        .then(response => {
                            if (checkHttpStatus(response.data?.status)) {
                                notifyApiSuccess("Trip Started Delivered", "SUCCESS:");
                                this.startTripCancel();
                                this.props.paginatedFilters(10);
                                this.setState({ startTripLoading: false })
                            }
                            else {
                                notifyApiError(response?.data?.message, "ERROR:");
                            }
                        })
                        .catch(error => notifyApiError(error, "ERROR:"));
                })
            }
        }




    }

    startTripCancel = () => {
        let { startTripCost, startMeterReading, uploadProofError, advanceAmountPaidError, startFTLCost, startFTLRevenue, startFTLAdvance, uploadTripCostProofError, uploadFTLRevenueProofError, uploadMeterProofError } = this.state;
        startTripCost = {
            validateStatus: 'success',
            help: ''
        };

        startMeterReading = {
            validateStatus: 'success',
            help: ''
        };

        startFTLCost = {
            validateStatus: 'success',
            help: ''
        };
        startFTLRevenue = {
            validateStatus: 'success',
            help: ''
        };
        startFTLAdvance = {
            validateStatus: 'success',
            help: ''
        }


        uploadProofError = {
            validateStatus: 'success',
            help: ''
        };
        uploadTripCostProofError = {
            validateStatus: 'success',
            help: ''
        };
        uploadFTLRevenueProofError = {
            validateStatus: 'success',
            help: ''
        };
        uploadMeterProofError = {
            validateStatus: 'success',
            help: ''
        };

        advanceAmountPaidError = {
            validateStatus: 'success',
            help: ''
        };




        this.setState({
            startKms: undefined,
            tripCost: undefined,
            advanceAmountPaid: undefined,
            tripRevenue: undefined,
            startTripLoading: false,
            startTripVisible: false,
            startTripCost,
            startMeterReading,
            uploadProofError,
            uploadTripCostProofError,
            uploadFTLRevenueProofError,
            uploadMeterProofError,
            advanceAmountPaidError,
            tripFTLType: false,
            startFTLAdvance,
            startFTLRevenue,
            startFTLCost,
            startTripFilters: {
                ...this.state.startTripFilters,
                attributes: {}
            }
        },
            () => {
                // this.props.paginatedFilters(10);
            })
    }

    completeTripNew = tripId => {
        // console.log("trip", tripId)
        if (tripId?.attributes?.tripType == "CL") {
            let filters = {
                "tripId": tripId.id,
                "force": true,
                "status": "COMPLETED"
            };
            this.setState({ endTripFilters: filters }, () => {

                apiUtils.apiRequest({
                    url: `/b2b/v1/thc/status`,
                    data: this.state.endTripFilters,
                    method: "PUT"
                })
                    .then(response => {
                        if (checkHttpStatus(response.data?.status)) {
                            notifyApiSuccess("End Trip Successfull", "Success");
                            this.EndTripCancel();
                            this.props.paginatedFilters(10);
                            this.setState({ EndTripLoading: false })
                        }
                        else {

                        }
                    })
                    .catch(error => {
                        notifyApiError(error, "Error")
                    });

                // this.props.endTrip(filters);
                // setTimeout(() => {
                //     this.EndTripCancel();
                // }, 2500)

            });

        } else {
            if (tripId?.attributes?.tripLoadType === 'FTL') {
                let { tripEndCost } = this.state;
                // console.log("tripId", tripId)
                // endTripFilters.attributes.tripCost = tripId?.attributes?.tripCost;

                tripEndCost = tripId?.attributes?.tripCost;
                this.setState({ tripEndCost, tripFTLType: true, showTripEndCost: true });

                let filters = {
                    "routeId": tripId?.routeName?.id,
                    "assetId": tripId?.asset?.id,
                    "tripLoadType": tripId?.attributes?.tripLoadType,
                    "customerId": tripId?.attributes?.customerId,
                    "vehicleType": tripId?.asset?.vehicleType,
                    "tripStatus": "FINISHED"
                }

                this.props.getTripRateCard(filters);
            } else {
                this.setState({ tripFTLType: false });
                if (tripId?.attributes?.tripLoadType === 'PTL' && tripId?.attributes?.isAdhocTrip === "true") {
                    let { tripEndCost } = this.state;
                    // console.log("tripId", tripId, endTripFilters)

                    tripEndCost = tripId?.attributes?.tripCost;
                    this.setState({ showTripEndCost: true, tripEndCost });
                }

                if (tripId?.attributes?.tripType === 'PICKUP') {
                    if (tripId?.attributes?.tripCost) {
                        this.setState({ showTripEndCost: true });
                    }
                    this.setState({ showEndTripKMSPhoto: true });
                }


            }


            if (this.props.app.configurations.odometerNeededForTrip == "true") {
                this.setState({ completeTripVisible: true });
                if (tripId.attributes.isAdhocTrip === "true") {
                    this.setState({ endTripAdhoc: true })
                } else {
                    this.setState({ endTripAdhoc: false })
                }
                let filters = {
                    "tripId": tripId.id,
                    "force": true,
                    "attributes": {
                        "endKms": null,
                        "actualTripEndTime": moment().valueOf(),
                        ...(tripId?.attributes?.tripLoadType === 'PTL' && tripId?.attributes?.isAdhocTrip === "true" && {
                            'tripCost': tripId?.attributes?.tripCost,
                            'tripCostImage': tripId?.attributes?.tripCostImage
                        })

                    },
                    "status": "COMPLETED"
                };
                // if (this.state?.showTripEndCost) {
                //     let { attributes } = filters;
                //     attributes = {
                //         ...attributes,
                //         'tripCost': tripId?.attributes?.tripCost,
                //         'tripCostImage': tripId?.attributes?.tripCostImage
                //     }
                //     filters = {
                //         ...filters,
                //         attributes: {
                //             ...attributes,

                //         }
                //     }
                // }
                // console.log("filters", filters)
                this.setState({ endTripFilters: filters, tripEndCost: tripId?.attributes?.tripCost, tripEndImage: tripId?.attributes?.tripCostImage, tripEndCostDisabled: true });
            } else {
                let filters = {
                    "tripId": tripId.id,
                    "force": true,
                    "attributes": {
                        "endKms": null
                    },
                    "status": "COMPLETED"
                };
                apiUtils.apiRequest({
                    url: `/b2b/v1/thc/status`,
                    data: filters,
                    method: "PUT"
                })
                    .then(response => {
                        if (checkHttpStatus(response.data?.status)) {
                            notifyApiSuccess("End Trip Successfull", "Success");
                            this.EndTripCancel();
                            this.props.paginatedFilters(10);
                            this.setState({ EndTripLoading: false })
                        }
                        else {

                        }
                    })
                    .catch(error => {
                        notifyApiError(error, "Error")
                    });
                // this.props.endTrip(filters);
                // this.EndTripCancel();
            }
        }

    }

    showSuffix = i => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    completeTrip = trip => {
        const filters = {
            "filter": {
                "tripIds": [trip?.id],
                "reasonCategoryIds": [],
                "reasonIds": [],
                "entityTypes": []
            }
        }
        apiUtils.apiRequest({
            url: `/b2b/v1/undel/fetch`,
            data: filters,
            method: "POST"
        })
            .then(response => {
                if (response.data) {
                    if (response?.data?.response?.bo?.undelReturnCount > 0) {
                        const data = response?.data?.response?.bo;
                        this.setState({ undeliveredCount: data?.undelReturnCount, undeliveredTotal: data?.totalCount });
                        const consign = _.uniqBy(data?.consignmentSummaryBos, 'docketNo');
                        const count = _.countBy(data?.consignmentSummaryBos, 'docketNo');
                        let index = 0;

                        Modal.info({
                            title: `End Trip - ${trip?.id}`,
                            icon: <></>,
                            okText: 'Proceed',
                            width: 500,
                            content: <>
                                <Row>
                                    <Col span={18}>
                                        <b>Return/Undelivered Summary</b>
                                    </Col>
                                    <Col span={6}>
                                        {data?.undelReturnCount} / {data?.totalCount}
                                    </Col>


                                </Row>
                                <div style={{ height: data?.undelReturnCount > 3 ? 400 : 300, overflow: 'scroll' }}>
                                    {
                                        consign?.map(consign => (
                                            <>
                                                <Divider />
                                                <Row>
                                                    <Col span={18}>
                                                        Docket no - {consign?.docketNo} &nbsp;

                                                    </Col>
                                                    <Col span={6}>
                                                        <Tag color="red">{consign?.entityType} ({count[consign?.docketNo]})</Tag>
                                                        <span>
                                                            <Tooltip
                                                                overlayClassName="undel-reasons"
                                                                placement={"bottom"}
                                                                title={
                                                                    count[consign?.docketNo] > 1 ?
                                                                        <Card>
                                                                            <ul>
                                                                                {index = 0, data?.consignmentSummaryBos.reverse().map((c, i) => {

                                                                                    if (c?.docketNo === consign?.docketNo) {

                                                                                        return <li>
                                                                                            {this.showSuffix(++index)} Attempt ({moment(c?.timestamp).format("DD/MM/YYYY hh:mm a")})
                                                                                            <ul>
                                                                                                <li><b>Category</b>: {c?.reasonCategoryBO?.description}</li>
                                                                                                <li><b>Reason</b>: {c?.reasonBO?.reasonDescription}</li>
                                                                                            </ul>
                                                                                        </li>
                                                                                    }

                                                                                })}
                                                                            </ul>
                                                                        </Card>


                                                                        :
                                                                        <Card>
                                                                            <ul>
                                                                                <li>
                                                                                    1st Attempt ({moment(consign?.timestamp).format("DD/MM/YYYY hh:mm a")})
                                                                                    <ul>
                                                                                        <li><b>Category</b>: {consign?.reasonCategoryBO?.description}</li>
                                                                                        <li><b>Reason</b>: {consign?.reasonBO?.reasonDescription}</li>
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </Card>

                                                                }>
                                                                <Icon type="info-circle" />
                                                            </Tooltip>
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={16}>
                                                        {consign?.customer?.name} ({consign?.source?.cityCode} - {consign?.destination?.cityCode})
                                                </Col>
                                                    <Col span={8}>
                                                        {consign?.totalShipmentCount} Pcs / {consign?.totalChargeableWeight.toFixed(2)} Kgs
                                                </Col>
                                                </Row>
                                                <Divider />
                                            </>
                                        ))
                                    }
                                </div>
                                <Row>
                                    <Col span={24}>
                                        <b>Note: All the undelivered dockets will be moved to inventory on end trip</b>
                                    </Col>
                                </Row>


                            </>,
                            onOk: () => {
                                this.completeTripNew(trip);
                            }
                        })
                    } else {
                        this.setState({ undeliveredCount: 0 });
                        this.completeTripNew(trip);
                    }
                }
                else {
                    this.setState({ undeliveredCount: 0 });
                }
            })
            .catch(error => {
                notifyApiError(error, "Error")
            });

    }

    onChangePod = (date, dateString) => {

        let { markDelFilters } = this.state;
        let { request } = markDelFilters;
        let { podCreatedDate } = markDelFilters;
        podCreatedDate = date;

        request[0].consignmentPodBO.podCreatedDate = date.valueOf();
        request[0].consignmentPodBO.podUpdatedDate = date.valueOf();
        markDelFilters.request = request;
        this.setState({ markDelFilters: { ...this.state.markDelFilters, podCreatedDate } });


    }

    handOver = docket => {
        this.setState({
            handOverVisible: true
        });



        let filters = {
            "request": [
                {
                    "manifestPodBO": {
                        "manifestCode": docket.manifestCode,
                        "partnerId": this.props.partnerId,
                        "manifestId": docket.manifestId.toString(),
                        "shipmentStatus": "COMPLETED"
                    },
                    "manifestCode": docket.manifestCode,
                    "action": "CREATE",
                    "manifestId": docket.manifestId.toString(),
                    "referenceId": docket.waybillNo,
                    "handoverPODBO": {
                        "entityCode": docket.waybillNo,
                        "entityStatus": "HANDEDOVER",
                        "handoverAssetId": docket.assetId.toString(),
                        "entityType": "CONSIGNMENT",
                        "entityId": docket.id.toString(),
                        "handoverPartnerId": docket.handOverPartnerId.toString()
                    }
                }

            ]
        };



        this.setState({ handOverFilters: filters });
    }

    returnDocket = (docket, action) => {

        Mixpanel.track("Docket Marked Initated: " + action, { 'markedUndelReturn': moment().valueOf(), docket: docket?.id })
        this.renderModalForReason(docket, action)
    }

    renderModalForReason = (docket, action) => {

        let promise = new Promise((resolve, reject) => {
            if (this.state.reasonCategories?.length <= 0) {
                apiUtils.apiRequest({
                    url: `/b2c/v2/reasons/categories/fetch`,
                    data: { "categoryFilters": { "types": ["UNDEL_RETURN_B2B"], "isActive": true, "fetchObjects": ["PARTNER_REASONS"] } },
                    method: "POST"
                }).then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        const reasonCategories = response?.data?.response?.reasonCategories;
                        this.setState({ reasonCategories });
                        resolve(reasonCategories)
                    }
                });
            } else {
                resolve(this.state.reasonCategories)
            }
        });

        promise.then(res => {
            const { undelFilter } = this.state;
            this.setState({
                undelFilter: {
                    ...undelFilter,
                    action,
                    docket,
                    undelVisble: true,
                }
            })
        })

    }

    cancelUndelModal = () => {
        const { undelFilter } = this.state;
        this.setState({
            undelFilter: {
                undelVisble: false,
                loading: false
            },
            reasonUndelReturn: undefined,
            reasonCategoryUndelReturn: undefined
        })
    }

    onUploadEndTripCostImage = e => {
        console.log("e", e)
        let { endTripFilters } = this.state;
        endTripFilters.attributes.tripCostImage = e;


        this.setState({ endTripFilters });
    }

    onUploadEndTripKMSImage = e => {
        console.log("e", e)
        let { endTripFilters } = this.state;
        endTripFilters.attributes.truckPhoto = e;


        this.setState({ endTripFilters });
    }

    okUndelModal = () => {

        const { undelFilter } = this.state;
        if (_.isUndefined(this.state.reasonCategoryUndelReturn) || _.isUndefined(this.state.reasonUndelReturn)) {
            this.setState({
                undelFilter: {
                    ...undelFilter,
                    error: '* Reason Category and Reason is mandatory'
                }
            })
        } else {
            this.setState({
                undelFilter: {
                    ...undelFilter,
                    error: undefined,
                    loading: true
                }
            });
            const params = {
                "bo": {
                    "consignmentId": undelFilter?.docket?.id,
                    "entityType": undelFilter?.action,
                    "reasonCategoryId": this.state.reasonCategoryUndelReturn?.key,
                    "reasonId": this.state.reasonUndelReturn?.key,
                    "locationId": undelFilter?.docket?.bookingOfficeLoc?.id,
                    "tripId": undelFilter?.docket?.tripId
                }
            }

            apiUtils.apiRequest({
                url: `/b2b/v1/undel/markUndel`,
                data: params,
                method: "POST"
            }).then(response => {
                if (checkHttpStatus(response.data?.status)) {
                    Mixpanel.track("Docket Marked Successfult: " + undelFilter?.action, { 'markedUndelReturn': moment().valueOf(), docket: undelFilter?.docket?.id })
                    if (undelFilter?.action === "RETURN") {
                        notifyApiSuccess(response.data?.status?.message, "SUCCESS");
                    }
                    if (undelFilter?.action === "UNDEL") {
                        notifyApiSuccess(response.data?.status?.message, "SUCCESS");
                    }
                    this.cancelUndelModal();
                    this.props.paginatedFilters(10);
                }
            });

        }
    }

    selectReasonCategoryUndelReturn = option => {
        this.setState({ reasons: [], reasonCategoryUndelReturn: option, reasonUndelReturn: undefined }, () => {
            let reasons = this.state.reasonCategories.filter(r => r.id == option.key)[0]?.reasons;

            this.setState({ reasons })
        })
    }

    selectReasonUndelReturn = option => {
        this.setState({ reasonUndelReturn: option })
    }

    onUploadPODFinish = e => {
        let { handOverFilters } = this.state;
        handOverFilters.request[0].manifestPodBO['podImpLink'] = e;
        handOverFilters.request[0].handoverPODBO['podImpLink'] = e;

        this.setState({ handOverFilters });
    }

    onUploadSignFinish = e => {
        let { handOverFilters } = this.state;
        handOverFilters.request[0].handoverPODBO['signatureURL'] = e;

        this.setState({ handOverFilters });
    }

    handleOverCancel() {

        let { uploadPODImages, signatureImage } = this.state;
        uploadPODImages = {
            validateStatus: 'success',
            help: ''
        }

        signatureImage = {
            validateStatus: 'success',
            help: ''
        }


        setTimeout(() => {
            this.setState({
                signatureImage,
                uploadPODImages,
                handOverVisible: false
            }, () => {
                this.props.paginatedFilters(10);
            });
        }, 1000)
    }

    handleOverOk() {

        let { handOverFilters } = this.state;
        if (this.props.app?.configurations?.needHandoverPod == "true" && handOverFilters.request[0].manifestPodBO['podImpLink'] == null) {
            let { uploadPODImages } = this.state;

            uploadPODImages = {
                validateStatus: 'error',
                help: 'Field is Required'
            }




            this.setState({ uploadPODImages });
            return;
        } else {
            let { uploadPODImages } = this.state;
            uploadPODImages = {
                validateStatus: 'success',
                help: ''
            }


            this.setState({ uploadPODImages });
        }

        if (this.props.app?.configurations?.needHandoverSignature == "true" && handOverFilters.request[0].handoverPODBO['signatureURL'] == null) {
            let { signatureImage } = this.state;
            signatureImage = {
                validateStatus: 'error',
                help: 'Field is Required'
            }


            this.setState({ signatureImage });
            return;
        } else {
            let { signatureImage } = this.state;
            signatureImage = {
                validateStatus: 'success',
                help: ''
            }


            this.setState({ signatureImage });
        }





        // this.props.handOver(this.state.handOverFilters);
        // this.handleOverCancel();
        this.setState({ handOverLoading: true });
        apiUtils.apiRequest({
            url: `/b2b/v1/manifestpod/handover/update`,
            data: this.state.handOverFilters,
            method: "POST"
        })
            .then(response => {
                if (checkHttpStatus(response.data?.status)) {
                    notifyApiSuccess("Hand Over Done", "Success");
                    this.setState({ handOverLoading: false });
                    this.handleOverCancel();
                }

                else {
                    notifyApiError("Hand Over Failed", "Error");

                    this.handleOverCancel();
                    this.setState({ handOverLoading: false });
                }
            })
            .catch(error => {
                notifyApiError(error, "Error");
                this.setState({ handOverLoading: false });
                this.handleOverCancel();
            });

        // this.setState({
        //     handOverVisible: false
        // }, () => {
        //     this.props.paginatedFilters(10);
        // });
    }



    onChangeDeps = value => {

        this.setState({ deps: value })
    }

    changeBarcode = (e) => {
        this.setState({ barcode: e.target.value })
    }

    submitBarcode = () => {
        const barcodeErr = []
        if (this.state.scannedBarCodes.indexOf(this.state.barcode) >= 0) {
            notifyApiError('Barcode already have scanned', 'Error:')
            return;
        } else {
            let consignmentId = this.state.markDelFilters?.request[0]?.consignmentPodBO?.consignmentId;
            // console.log("bardo", this.state.shipmentBarCodes)
            let scan = this.state.shipmentBarCodes?.map(shipment => {
                return shipment.barcodes?.map(barcode => {
                    // console.log("bardo", barcode)
                    if (this.state.barcode === barcode.barcode) {
                        barcodeErr.push(true)
                        this.setState(prevState => ({
                            barcode: undefined,
                            scannedBarCodes: [...prevState.scannedBarCodes, this.state.barcode],
                            shipmentScans: [...prevState.shipmentScans, {
                                "shipmentCodeType": "BARCODE",
                                "scanInputType": "BARCODE_SCANNER",
                                "shipmentCode": this.state.barcode,
                                "shipmentId": shipment.id.toString(),
                                "entityType": "MANIFEST",
                                "consignmentId": consignmentId.toString(),
                                "entityId": this.state?.manifestId.toString(),
                                "scanType": "POD_SCAN"
                            }]
                        }));
                    } else {
                        barcodeErr.push(false)
                    }

                })
            })

            if (!barcodeErr.includes(true)) {
                notifyApiError('No such barcode found', 'Error:');
            }
        }
    }

    deleteShipmentCode = (docket, row, index) => {


        let scanCode = [...this.state.scannedBarCodes];
        let shipmentCode = [...this.state.shipmentScans];
        scanCode.splice(index, 1);
        shipmentCode.splice(index, 1);

        this.setState({
            shipmentScans: shipmentCode,
            scannedBarCodes: scanCode
        })
    }

    markDel = (docket, tripToPayBO) => {

        this.setState({
            markDeliveryVisible: true,
            deps: false,
            clearFileMarkDelivery: false,
            docketNo: docket.waybillNo,
            tripToPayBO,
            manifestId: docket?.manifestId,
            toPayAmtColledted: undefined
        });
        // console.log("docket", docket)
        if (this.props.appConfig.barcode.forward.includes(docket?.customer?.id?.toString())) {
            this.setState({ shipmentBarCodes: docket?.shipmentBOs, shipmentScan: true })
        }





        let filters = {
            "request": [
                {
                    "action": "CREATE",
                    "tripId": docket.tripId.toString(),
                    "wayBillNo": docket.waybillNo.toString(),
                    "consignmentPodBO": {
                        "entityCode": docket.manifestCode,
                        "entityType": "MANIFEST",
                        "shipmentCount": docket.totalShipmentCount,
                        "consignmentId": docket.id.toString(),
                        "podImpLink": null,
                        "entityId": docket.manifestId,
                        "podCreatedDate": this.state.podDate,
                        "partnerId": this.props.partnerId,
                        "podUpdatedDate": this.state.podDate,
                        "waybillNo": docket.waybillNo.toString(),
                        "shipmentStatus": "DEL",
                        "deliveryUserId": this.props.header.loggedInUser.id
                    },
                    "referenceId": docket.waybillNo.toString()
                }


            ]
        }


        this.setState({ markDelFilters: filters });
    }

    markDeliverOk() {

        if (this.state.shipmentScan) {
            if (this.state?.shipmentBarCodes?.length == this.state?.shipmentScans?.length) {
                this.setState({ markdelError: undefined });
            } else {
                this.setState({ markdelError: "Please scan all barcodes" });
                return;
            }
        }

        let { markDelFilters } = this.state;
        let { request } = markDelFilters;
        let { consignmentPodBO } = request[0];
        // request[0] = {
        //     ...request[0],
        //     consignmentPodBO: {
        //         ...consignmentPodBO,
        //         "attributes": {
        //             "ignoreBarcodeCreation": "true",
        //             "manifest": "false",
        //             "invoice": "false",
        //             "DEPS": this.state.deps,
        //             "docket": "true"
        //         }
        //     },

        // }
        request[0] = {
            ...request[0],
            consignmentPodBO: {
                ...consignmentPodBO,
                "attributes": {
                    "ignoreBarcodeCreation": "true",
                    "manifest": "false",
                    "invoice": "false",
                    "DEPS": this.state.deps,
                    "docket": "true"
                }
            },

        }

        if (this.state.tripToPayBO.isToPay)
            if (this.state.toPayAmtColledtedError === "") {
                request[0] = {
                    ...request[0],
                    consignmentPodBO: {
                        ...consignmentPodBO,
                        amountPaidOnDelivery: this.state.toPayAmtColledted
                    },

                }
            } else {
                return false
            }

        if (request[0]?.consignmentPodBO?.podCreatedDate == null) {
            let { deliveryDateTime } = this.state;
            deliveryDateTime = {
                validateStatus: 'error',
                help: 'Field is Required'
            }


            this.setState({ deliveryDateTime });
            return;
        } else {
            let { deliveryDateTime } = this.state;
            deliveryDateTime = {
                validateStatus: 'success',
                help: ''
            }
            this.setState({ deliveryDateTime, markDelLoading: true });
        }

        // let filters = this.state.markDelFilters.request;
        let filters = {
            request: [this.state.markDelFilters.request[0]]
        }

        if (this.state.shipmentScan) {
            let request = this.state.markDelFilters.request[0];
            request = {
                ...request,
                shipmentScans: this.state.shipmentScans
            }

            filters = {
                request: [request]
            }

            // console.log("filter", filters)
        }
        // const req = request[0];
        // const newfilter = filters;
        // const req = newfilter.request;

        // filters = {
        //     ...newfilter,
        //     "action": "CREATE",
        //     "referenceId": consignmentPodBO?.waybillNo,
        //     "tripId": "186499",
        //     "wayBillNo": consignmentPodBO?.waybillNo,
        //     request: [
        //         ...req
        //     ]
        // }

        console.log("filters", filters);
        // filters = {
        //     ...filters,
        //     request: [
        //         ...req,

        //     ]

        // }

        apiUtils.apiRequest({
            url: `/b2b/v1/consignmentpod/lite/update`,
            data: filters,
            method: "POST"
        })
            .then(response => {

                if (checkHttpStatus(response.data?.status)) {
                    notifyApiSuccess("Mark Delivered", "SUCCESS:");
                    let { deliveryDateTime, markDelFilters } = this.state;
                    markDelFilters.podCreatedDate = undefined;
                    deliveryDateTime = {
                        validateStatus: 'success',
                        help: ''
                    }
                    this.setState({
                        deliveryDateTime,
                        markDelLoading: false,
                        markDeliveryVisible: false,
                        markDelFilters,
                        deps: false,
                        shipmentScan: false,
                        shipmentBarCodes: [],
                        clearFileMarkDelivery: true,
                        scannedBarCodes: [],
                        shipmentScans: [],
                        barcode: undefined,
                        manifestId: undefined,
                        markdelError: undefined

                    }, () => {
                        this.props.paginatedFilters(10);
                    });
                }

                else {
                    notifyApiError(response?.data?.message, "ERROR:");
                }
            })
            .catch(error => notifyApiError(error, "ERROR:"));


        // this.props.markDel(filters);
        // setTimeout(() => {
        //     this.markDeliverCancel();
        // }, 7000)


    }




    markDeliverCancel() {
        let { deliveryDateTime, markDelFilters } = this.state;
        markDelFilters.podCreatedDate = undefined;
        deliveryDateTime = {
            validateStatus: 'success',
            help: ''
        }
        this.setState({
            deliveryDateTime,
            markDeliveryVisible: false,
            deps: false,
            clearFileMarkDelivery: true,
            markDelFilters,
            shipmentScan: false,
            shipmentBarCodes: [],
            scannedBarCodes: [],
            barcode: undefined,
            shipmentScans: [],
            manifestId: undefined,
            markdelError: undefined,
            toPayAmtColledted: undefined
        }, () => {

            // this.props.paginatedFilters(10);
        });
    }





    EndTripCancel = () => {
        let { endTripCostImage, endTripKMSImage, endTripCost, actualTripDateEndTime, endTripKMS, endTripCustomerLoad, endTripVendorLoad, endTripCustomerDemrruage, endTripVendorDemrruage } = this.state;
        endTripKMS = {
            validateStatus: 'success',
            help: ''
        };

        endTripKMSImage = {
            validateStatus: 'success',
            help: ''
        }

        endTripCustomerLoad = {
            validateStatus: 'success',
            help: ''
        };

        endTripVendorLoad = {
            validateStatus: 'success',
            help: ''
        };
        endTripCustomerDemrruage = {
            validateStatus: 'success',
            help: ''
        };
        endTripVendorDemrruage = {
            validateStatus: 'success',
            help: ''
        }

        actualTripDateEndTime = {
            validateStatus: 'success',
            help: ''
        };

        endTripCostImage = {
            validateStatus: 'success',
            help: ''
        };
        endTripCost = {
            validateStatus: 'success',
            help: ''
        };



        // this.setState({ completeTripVisible: false, endKMValue: undefined, endTripKMS, endTripCustomerLoad, endTripVendorLoad, endTripCustomerDemrruage, endTripVendorDemrruage }, () => {
        // this.props.paginatedFilters(10);
        this.setState({ endTripCost, endTripCostImage, endTripKMSImage, completeTripVisible: false, endKMValue: undefined, endTripKMS, endTripCustomerLoad, endTripVendorLoad, endTripCustomerDemrruage, endTripVendorDemrruage, newAdhocCharges: [], actualTripDateEndTime, actualTripEndTime: moment(), showTripEndCost: false, showEndTripKMSPhoto: false, tripEndCost: undefined, endtripCustomerCharges: undefined, endtripCustomerDemurrageCharges: undefined, endtripVendorCharges: undefined, endtripVendorDemurrageCharges: undefined }, () => {
            // this.props.paginatedFilters(10);
        });
    }


    EndTripOk = () => {
        // Removed for trip verification
        // if (this.state.showTripEndCost) {
        //     console.log("this.state.endTripFilters.attributes.tripCost", this.state.endTripFilters.attributes.tripCost)
        //     if (this.state.endTripFilters.attributes.tripCost == null || this.state.endTripFilters.attributes.tripCost == undefined) {
        //         let { endTripCost } = this.state;
        //         endTripCost = {
        //             validateStatus: 'error',
        //             help: 'Field is Required'
        //         }


        //         this.setState({ endTripCost });
        //         return;
        //     }
        //     if (this.state.endTripFilters.attributes.tripCostImage == null || this.state.endTripFilters.attributes.tripCostImage == undefined) {
        //         let { endTripCostImage } = this.state;
        //         endTripCostImage = {
        //             validateStatus: 'error',
        //             help: 'Field is Required'
        //         }


        //         this.setState({ endTripCostImage });
        //         return;
        //     }
        // }
        if (this.state.endTripFilters.attributes.actualTripEndTime == null || this.state.endTripFilters.attributes.actualTripEndTime == undefined) {
            let { actualTripDateEndTime } = this.state;
            actualTripDateEndTime = {
                validateStatus: 'error',
                help: 'Field is Required'
            }


            this.setState({ actualTripDateEndTime });
            return;
        }


        if (this.state.endTripFilters.attributes.endKms == null || this.state.endTripFilters.attributes.endKms < 0) {
            let { endTripKMS } = this.state;
            endTripKMS = {
                validateStatus: 'error',
                help: 'Field is Required'
            }


            this.setState({ endTripKMS });
            return;
        } else {
            let { endTripKMS } = this.state;
            endTripKMS = {
                validateStatus: 'success',
                help: ''
            }
            this.setState({ endTripKMS });

        }

        // Removed for trip verificatino

        // if (this.state.tripFTLType) {
        //     if (this.state.endTripFilters.attributes.customerLoadingCharges == null || this.state.endTripFilters.attributes.customerLoadingCharges < 0) {
        //         let { endTripCustomerLoad } = this.state;
        //         endTripCustomerLoad = {
        //             validateStatus: 'error',
        //             help: 'Field is Required'
        //         }


        //         this.setState({ endTripCustomerLoad });
        //         return;
        //     } else {
        //         let { endTripCustomerLoad } = this.state;
        //         endTripCustomerLoad = {
        //             validateStatus: 'success',
        //             help: ''
        //         }
        //         this.setState({ endTripCustomerLoad });

        //     }

        //     if (this.state.endTripFilters.attributes.vendorLoadingCharges == null || this.state.endTripFilters.attributes.vendorLoadingCharges < 0) {
        //         let { endTripVendorLoad } = this.state;
        //         endTripVendorLoad = {
        //             validateStatus: 'error',
        //             help: 'Field is Required'
        //         }


        //         this.setState({ endTripVendorLoad });
        //         return;
        //     } else {
        //         let { endTripVendorLoad } = this.state;
        //         endTripVendorLoad = {
        //             validateStatus: 'success',
        //             help: ''
        //         }
        //         this.setState({ endTripVendorLoad });

        //     }

        //     if (this.state.endTripFilters.attributes.customerDemurrageCharges == null || this.state.endTripFilters.attributes.customerDemurrageCharges < 0) {
        //         let { endTripCustomerDemrruage } = this.state;
        //         endTripCustomerDemrruage = {
        //             validateStatus: 'error',
        //             help: 'Field is Required'
        //         }


        //         this.setState({ endTripCustomerDemrruage });
        //         return;
        //     } else {
        //         let { endTripCustomerDemrruage } = this.state;
        //         endTripCustomerDemrruage = {
        //             validateStatus: 'success',
        //             help: ''
        //         }
        //         this.setState({ endTripCustomerDemrruage });

        //     }

        //     if (this.state.endTripFilters.attributes.vendorDemurrageCharges == null || this.state.endTripFilters.attributes.vendorDemurrageCharges < 0) {
        //         let { endTripVendorDemrruage } = this.state;
        //         endTripVendorDemrruage = {
        //             validateStatus: 'error',
        //             help: 'Field is Required'
        //         }


        //         this.setState({ endTripVendorDemrruage });
        //         return;
        //     } else {
        //         let { endTripVendorDemrruage } = this.state;
        //         endTripVendorDemrruage = {
        //             validateStatus: 'success',
        //             help: ''
        //         }
        //         this.setState({ endTripVendorDemrruage });

        //     }



        // }

        // Removed for trip verification

        // let newAttributes = {};
        // this.state.newAdhocCharges.map(charge => {
        //     // console.log("charge", charge)
        //     if (charge.label === 'customer') {
        //         let lab = charge.label + charge.chargeLabel;
        //         newAttributes[lab] = charge[lab];
        //     } else {
        //         let lab = "vendor" + charge.chargeLabel;
        //         newAttributes[lab] = charge[lab]
        //     }
        // });

        this.setState(prevState => ({
            ...prevState,
            endTripFilters: {
                ...prevState.endTripFilters,
                attributes: {
                    ...prevState.endTripFilters.attributes,
                    // ...newAttributes   Removed for trip verification
                }
            },
            EndTripLoading: true
        }), () => {
            // console.log("filtressss", this.state.endTripFilters)
            apiUtils.apiRequest({
                url: `/b2b/v1/thc/status`,
                data: this.state.endTripFilters,
                method: "PUT"
            })
                .then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        notifyApiSuccess("End Trip Successfull", "Success");
                        this.EndTripCancel();
                        this.props.paginatedFilters(10);
                        this.setState({ completeTripVisible: false, newAdhocCharges: [], EndTripLoading: false })
                    }
                    else {
                        this.setState({ completeTripVisible: false, newAdhocCharges: [], EndTripLoading: false })
                    }
                })
                .catch(error => {
                    notifyApiError(error, "Error")
                });
            // // this.props.endTrip(this.state.endTripFilters);
            // setTimeout(() => {
            //     this.EndTripCancel();
            // }, 2800);

        })

        // let {endTripFilters} = this.state;
        // let attributes = {endTripFilters};
        // attributes = {...attributes, ...newAttributes};
        // endTripFilters = {
        //     attributes: {...attributes}
        // }

        // this.setState({endTripFilters})



        // console.log("attributes", newAttributes, this.state.endTripFilters);


    }

    customExpandIcon = props => {

        if (props.expanded) {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><Icon type="caret-down" /></a>
        } else {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><Icon type="caret-right" /></a>
        }
    }

    showEway = (eway) => {

        this.setState({ ewayData: eway, showEwayModal: true });
    }

    cancelEwayModal = () => {
        this.setState({ ewayData: {}, showEwayModal: false });
    }

    showAllEwayBills = (eways) => {
        this.setState({ ewayBillsData: eways, showEwayBillsModal: true });
    }

    showAllEwayTripBills = (eways) => {

        this.setState({ ewayData: eways, showEwayModal: true, waybiltrip: <><h3>Hello</h3></> });
    }

    cancelEwayBillsModal = () => {
        this.setState({ ewayBillsData: [], showEwayBillsModal: false });
    }

    expandedRowRender = (trip, index, indent, expanded) => {
        let EWAY_ENUMS = {
            EwayGreen: 0,
            EwayRed: 0,
            EwayOrange: 0,
        };
        let ewayKey = null, waybll = <></>;

        const columns = [
            {
                title: 'Docket No', width: 120, dataIndex: 'waybillNo', key: 'waybillNo', render: (docket, row) => {
                    let Waybill = docket
                    if (row.markDel && row?.undelConsignmentBos?.length > 0) {
                        Waybill = <>{row?.waybillNo} <Tag color="#108ee9">Undel ({row?.undelConsignmentBos?.length})</Tag></>
                    } else if (row?.handover && row?.returnConsignmentBos?.length > 0) {
                        Waybill = <>{row?.waybillNo} <Tag color="#108ee9">Return ({row?.returnConsignmentBos?.length})</Tag></>
                    } else {
                        Waybill = docket;
                    }
                    return <>
                        {Waybill}
                        {(trip?.tripToPayBO?.isToPay && trip?.tripToPayBO?.toPayConsignmentList?.find(item => item.waybillNo === docket)) && <span style={{ marginLeft: 10 }}> <TripSummaryModal title="Docket Summary" data={trip?.tripToPayBO?.toPayConsignmentList?.filter(item => item.waybillNo === docket)} /> </span>}
                    </>
                }
            },
            { title: 'Customer', dataIndex: 'customerName', key: 'customerName' },
            { title: 'Destination', dataIndex: 'destination', key: 'destinatino' },
            { title: 'Pieces/Weight', dataIndex: 'quantity', key: 'quantity' },
            { title: 'Date', dataIndex: 'date', key: 'date' },
            {
                title: 'E-waybill', key: 'eway', render: consign => <>
                    {
                        consign.ewaybillBOs && consign.ewaybillBOs.length > 0 ?
                            <>{consign.ewaybillBOs.map(eway => {
                                if (eway.validUpto) {
                                    var validUpto = moment(eway.validUpto);
                                    // waybll = <></>;
                                    var today = moment();
                                    var difference = validUpto.diff(today, 'hours');
                                    // console.log("difference->>", difference, this.props.app.configurations, this.props.app.configurations.ewaybill.waringHours)
                                    if (moment(eway.validUpto) <= moment()) {
                                        EWAY_ENUMS.EwayRed += 1;
                                        // return (
                                        //     <img src={EwayRed} style={{ cursor: 'pointer' }} onClick={() => this.showEway(eway)} />
                                        // )
                                    } else if (difference <= this.props?.app?.configurations?.ewaybill?.waringHours) {
                                        EWAY_ENUMS.EwayOrange += 1;
                                        // return <img src={EwayOrange} style={{ cursor: 'pointer' }} onClick={() => this.showEway(eway)} />
                                    } else {
                                        EWAY_ENUMS.EwayGreen += 1;
                                        // return <img src={EwayGreen} style={{ cursor: 'pointer' }} onClick={() => this.showEway(eway)} />
                                    }
                                } else {
                                    EWAY_ENUMS.EwayGreen += 1;
                                }


                            }),

                                ewayKey = Object.keys(EWAY_ENUMS).reduce((a, b) => EWAY_ENUMS[a] > EWAY_ENUMS[b] ? a : b),
                                // console.log("eway->>", ewayKey, EWAY_ENUMS),
                                EWAY_ENUMS = {
                                    EwayGreen: 0,
                                    EwayRed: 0,
                                    EwayOrange: 0,

                                },
                                ewayKey == 'EwayRed' ?
                                    <img src={EwayRed} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(consign.ewaybillBOs)} />
                                    :
                                    ewayKey == 'EwayOrange' ?
                                        <img src={EwayOrange} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(consign.ewaybillBOs)} /> :
                                        ewayKey == 'EwayGreen' ?

                                            <img src={EwayGreen} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(consign.ewaybillBOs)} />

                                            : <></>


                            }
                                &nbsp;  {consign.ewaybillBOs.length}</>
                            : <>NA</>
                    }
                </>
            },
            {
                title: 'Actions',
                key: 'operation',
                render: docket => <>
                    {
                        docket.markDel ?
                            <>
                                <Button className="trip-load-button" onClick={() => this.markDel(docket, trip?.tripToPayBO)}>Mark Delivery</Button>
                                {this.state?.tripsPermission?.undel_return && docket?.showUndel &&
                                    <Button type="primary" onClick={() => this.returnDocket(docket, "UNDEL")}>Undelivered</Button>
                                }
                            </> :
                            docket.handover ?
                                <>
                                    <Button className="trip-load-button" onClick={() => this.handOver(docket)}>Hand over</Button>
                                    {this.state?.tripsPermission?.undel_return && docket?.showReturn &&
                                        <Button type="primary" onClick={() => this.returnDocket(docket, "RETURN")}>Return</Button>
                                    }
                                </>
                                : <></>
                    }
                </>
            }
        ];

        const data = [];

        if (trip.thcbos && trip.thcbos.length > 0) {

            trip.thcbos.map(thc => {

                thc.manifests.map((manifest, index) => {
                    let manifestId = manifest.id;
                    return manifest.consignments && manifest.consignments.length > 0 && manifest.consignments.map(consign => {


                        data.push({
                            ...consign,
                            'key': trip.id + consign.waybillNo + index,
                            'waybillNo': consign.waybillNo,
                            'customerName': consign.customer.name,
                            'destination': <>{consign.customerPickupLoc.address.pincode.city.cityCode}-{consign.consignee.address.pincode.city.cityCode}</>,
                            'quantity': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                            'date': moment(consign.bookingDate).format("DD-MMM-YYYY hh:MM"),
                            'manifestCode': manifest.manifestCode,
                            'manifestId': manifestId,

                            'assetId': trip.asset.id,
                            'tripId': trip.id,
                            'handOverPartnerId': manifest.destinationLoc.entityId,

                        })

                        // console.log("data", data)

                    })
                })
            });

        }

        if (trip.drsbos && trip.drsbos.length > 0) {
            trip.drsbos.map(thc => {

                return thc?.consignments?.map(consign => {

                    data.push({
                        'key': trip.id + consign.waybillNo + index,
                        'waybillNo': consign.waybillNo,
                        'customerName': consign.customer.name,
                        'manifestId': thc.id,
                        'destination': <>{consign.customerPickupLoc.address.pincode.city.cityCode}-{consign.consignee.address.pincode.city.cityCode}</>,
                        'quantity': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                        'date': moment(consign.bookingDate).format("DD-MMM-YYYY hh:MM"),
                        'tripId': trip.id,
                        ...consign
                    })
                })

            });
        }

        return (
            <Card
            // style={{ backgroundColor: 'transparent' }}
            >

                <Table
                    bordered
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
                <br />
                <div>
                    {trip.prses && trip.prses.length > 0 && trip.prses.map(prs => {
                        return prs.leads && prs.leads.length > 0 && prs.leads.map(lead => {

                            return (
                                <>
                                    <Row>
                                        <Col span={6}>
                                            <h3>{lead.code}</h3>
                                        </Col>
                                        <Col span={6}>
                                            <div>{moment(lead.createdAt).format("DD MM YYYY hh:mm")} <span>{trip.status}</span></div>
                                        </Col>
                                        <Col span={6}>
                                            {lead.status === 'ARRIVED_AT_PL' ?
                                                <Row gutter={24}>
                                                    <Col span={12}>
                                                        <Button type="danger" onClick={() => this.startPickup(lead)}>START PICKUP</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="danger" onClick={() => this.cancelPickup(lead)}>CANCEL PICKUP</Button>
                                                    </Col>
                                                </Row>


                                                :
                                                lead.status === 'OPEN' ?
                                                    <Button type="danger" onClick={() => this.updateArrivedAtPTL(lead)}>ARRIVED AT PL</Button> :
                                                    lead.status === 'STARTED' ?
                                                        <Button type="danger" onClick={() => this.updatePickedUp(lead)}>PICKED UP</Button> :
                                                        lead.status === 'PICKEDUP' ?
                                                            <Button type="danger" onClick={() => this.updateArriveAtHub(lead)}>ARRIVED AT HUB</Button> : <></>
                                            }
                                        </Col>
                                        <Col span={6} push={2}>
                                            <div><Tag color="green">{lead.status}</Tag></div>
                                        </Col>
                                    </Row>



                                </>
                            )
                        })
                    })}
                </div>
            </Card>
        )

    }

    updateStatus = trip => {
        this.setState({ dataLocation: undefined })

        getTripUpStatus(trip.id)
            .then(data => {
                let pendingTrips = [];
                let index = 0;

                if (data.data.status.code === 200) {

                    data.data.response.thcRouteMappingBOS.map(thc => {

                        if (thc.status === 'PENDING') {
                            thc = {
                                ...thc,
                                'checked': true,
                                'partnerName': trip.routeName.asset.partner.name
                            };
                            // stat['partnerName'] = trip.routeName.asset.partner.name;                         
                            pendingTrips.push(thc);
                            if (index === 0) {


                                this.setState({ nextLocation: _.hasIn(thc, 'locationBO') ? thc.locationBO.name : thc.consignmentContactBO.name });
                                ++index;
                            }
                        }
                    });

                }

                this.setState({ tripid: trip.id, updateStatusVisible: true, pendingTrips })
            })

    }

    updateStatusOk = () => {



        this.setState({ updateStatusLoading: true });
        let filters = {};
        let requests = [];
        this.state.pendingTrips.map(ptrips => {
            if (ptrips.checked) {
                requests.push({
                    "etaDetail": {
                        "etaAt": ptrips.expectedEta,
                        "reasonId": this.state.setDelayReason.key,
                        "entityType": ptrips.thcEntityType,
                        "locationId": ptrips.locationId,
                        "lastKnownLocation": this.state.dataLocation,
                        "entityId": ptrips.entityId,
                        "tripId": ptrips.tripId,
                        "routeMappingId": ptrips.routeMappingId,
                        "delayTime": this.state.delayHours * 60,
                        "reasonDetails": this.state.setDelayReason.label
                    }
                })
            }
        });
        if (requests.length > 0) {
            filters = {
                requests: requests
            };


            updateETA(filters)
                .then(data => {

                    if (data.data.status.code === 200) {
                        // console.log("Data->>>>", data);
                        this.setState({ updateStatusLoading: false, setDelayReason: undefined, updateStatusVisible: false, delayHours: 0, dataLocation: [], expectedEta: '', updatedTime: undefined });
                        this.props.paginatedFilters(10);
                        // this.updateStatusCancel();
                        // this.setState({ updateStatusVisible: false });
                        // window.location.reload();
                    }
                })


        }
    }

    updateStatusCancel = () => {
        this.setState({ updateStatusVisible: false, delayHours: 0, dataLocation: [], expectedEta: '', setDelayReason: undefined, updatedTime: undefined }, () => {
            // this.props.paginatedFilters(10);
        })
    }

    updatePickedUp = lead => {

        this.setState({ updatePickup: true, leadPickup: lead })
    }
    endUpdatePickup = () => {
        this.setState({ disablePickupCount: true, disablePickupWeight: true, updatePickup: false, leadPickup: {}, pickupCount: undefined, pickupWeight: undefined, pickupButton: false, editCountClicked: false, editWeightClicked: false })
    }

    updatePickupCount = e => {
        this.setState({ pickupCount: e }, () => {
            if (this.state.editCountClicked) {
                if (this.state.pickupCount >= 0 && this.state.pickupWeight >= 0) {
                    this.setState({ pickupButton: false })
                }
            }
        })

    }

    updatePickupWeight = e => {
        this.setState({ pickupWeight: e }, () => {
            if (this.state.editCountClicked) {
                if (this.state.pickupCount >= 0 && this.state.pickupWeight >= 0) {
                    this.setState({ pickupButton: false })
                }
            } else if (this.state.editWeightClicked) {
                if (this.state.pickupWeight >= 0) {
                    this.setState({ pickupButton: false })
                }
            }
        })
    }


    okUpdatePickup = () => {
        let params = {
            "force": true,
            "leadId": this.state.leadPickup.id,
            "status": "PICKEDUP"
        };
        if (this.state.editCountClicked) {
            params = {
                ...params,
                count: this.state.pickupCount,
                weight: this.state.pickupWeight
            }
        } else if (this.state.editWeightClicked) {
            params = {
                ...params,
                // count: this.state.pickupCount,
                weight: this.state.pickupWeight
            }
        }
        let _self = this;
        updateLead(params)
            .then(res => {
                if (res.data.status.code === 200) {
                    this.setState({ disablePickupCount: true, disablePickupWeight: true, updatePickup: false, leadPickup: {}, pickupCount: undefined, pickupWeight: undefined, pickupButton: false, editCountClicked: false, editWeightClicked: false })
                    _self.props.paginatedFilters(10, 1);
                } else {
                    _self.props.paginatedFilters(10, 1);
                }
            })



    }


    updateArriveAtHub = lead => {
        let _self = this;
        confirm({
            content: 'Are you sure you want to update the status?',
            okText: 'YES',
            cancelText: 'CANCEL',
            onOk() {
                let params = {
                    "force": true,
                    "leadId": lead.id,
                    "status": "ARRIVED_AT_HUB"
                };

                updateLead(params)
                    .then(res => {
                        if (res.data.status.code === 200) {
                            _self.props.paginatedFilters(10, 1);
                        } else {
                            _self.props.paginatedFilters(10, 1);
                        }
                    })
            }
        })
    }

    updateArrivedAtPTL = lead => {
        let _self = this;
        confirm({
            content: 'Are you sure you want to update the status?',
            okText: 'YES',
            cancelText: 'CANCEL',
            onOk() {
                let params = {
                    "force": true,
                    "leadId": lead.id,
                    "status": "ARRIVED_AT_PL"
                };

                updateLead(params)
                    .then(res => {
                        if (res.data.status.code === 200) {
                            _self.props.paginatedFilters(10, 1);
                        } else {
                            _self.props.paginatedFilters(10, 1);
                        }
                    })
            }
        })
    }

    startPickup = lead => {
        let _self = this;
        confirm({
            content: 'Are you sure you want to update the status?',
            okText: 'YES',
            cancelText: 'CANCEL',
            onOk() {
                let params = {
                    "force": true,
                    "leadId": lead.id,
                    "status": "STARTED"
                };

                updateLead(params)
                    .then(res => {
                        if (res.data.status.code === 200) {
                            _self.props.paginatedFilters(10, 1);
                        } else {
                            _self.props.paginatedFilters(10, 1);
                        }
                    })
            }
        })
    }

    selectPickupReason = value => {
        // console.log("value", value)
        this.setState({ selectedPickupCancelReason: value })
    }

    cancelPickup = lead => {
        let reasons = [];
        let _self = this;
        apiUtils.apiRequest({
            url: `/b2c/v2/reasons/fetch`,
            data: { "filters": { "isActive": true, "statusShortCodes": ["PCANC"] } },
            method: "POST"
        })
            .then(response => {
                if (checkHttpStatus(response.data?.status)) {
                    reasons = response?.data?.response?.reasons;
                    confirm({
                        title: 'Select Cancel Reason',
                        content: <>
                            <Select
                                style={{ width: '100%' }}
                                labelInValue
                                placeholder={"Select Reason"}
                                // value={this.state.selectedPickupCancelReason}
                                onSelect={this.selectPickupReason}
                            >
                                {
                                    reasons.map(r => (
                                        <Select.Option

                                            value={r?.reasonId}
                                            label={r?.reasonDescription}
                                            key={r?.reasonId}
                                        >
                                            {r.reasonDescription}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </>,
                        okText: 'CANCEL PICKUP',
                        cancelText: 'BACK',
                        onOk() {
                            const reason = reasons?.filter(r => r?.reasonId == _self.state?.selectedPickupCancelReason?.key)[0];

                            let params = {
                                "leadId": lead.id,
                                "reasonId": _self.state?.selectedPickupCancelReason?.key,
                                "remarks": _self.state?.selectedPickupCancelReason?.label,
                                "reasonCode": reason?.reasonCode,

                            };
                            apiUtils.apiRequest({
                                url: `/b2b/v1/leads/cancel`,
                                data: params,
                                method: "PUT"
                            }).then(response => {
                                if (checkHttpStatus(response.data?.status)) {
                                    notifyApiSuccess("Pickup Cancelled", "SUCCESS!!");
                                    _self.props.paginatedFilters(10, 1);
                                }
                            })

                            // updateLead(params)
                            //     .then(res => {
                            //         if (res.data.status.code === 200) {
                            //             _self.props.paginatedFilters(10, 1);
                            //         } else {
                            //             _self.props.paginatedFilters(10, 1);
                            //         }
                            //     })
                        }
                    })
                }

            })
            .catch(error => notifyApiError(error, 'Error'));


    }

    onUploadFinish = e => {

        let { markDelFilters } = this.state;
        let { request } = markDelFilters;

        request[0].consignmentPodBO.podImpLink = e;
        markDelFilters.request = request;
        this.setState({ markDelFilters });
    }

    onUploadTripFinish = (e, type) => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes[type] = e;
        this.setState({ startTripFilters });
    }

    onChangeKMS = e => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes.startKms = e;
        this.setState({ startTripFilters, startKms: e }, () => console.log(this.state.startTripFilters));
    }

    onChangeRevenue = e => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes.tripRevenue = e;
        this.setState({ startTripFilters, tripRevenue: e });
    }

    onChangeCost = e => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes.tripCost = e;
        this.setState({ startTripFilters, tripCost: e });
    }

    onChangeAmountPaid = e => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes.advanceAmountPaid = e;
        this.setState({ startTripFilters, advanceAmountPaid: e });
    }

    onChangeAdvance = e => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes.advanceAmountPaid = e;
        this.setState({ startTripFilters, advanceAmountPaid: e });
    }

    onChangeTripCost = e => {
        let { startTripFilters } = this.state;
        startTripFilters.attributes.tripCost = e.target.value;
        this.setState({ startTripFilters, tripCost: e.target.value });
    }

    onChangeEndKMS = e => {
        let { endTripFilters, endKMValue } = this.state;
        endTripFilters.attributes.endKms = e;
        endKMValue = e;
        this.setState({ endTripFilters, endKMValue });
    }

    onChangeEndDateTime = date => {
        let { endTripFilters, actualTripEndTime } = this.state;
        endTripFilters.attributes.actualTripEndTime = date.valueOf();
        actualTripEndTime = date;
        this.setState({ endTripFilters, actualTripEndTime });
    }
    enableCost = () => {
        this.setState({ tripEndCostDisabled: false })
    }

    onChangeEndTripCost = e => {
        let { endTripFilters, tripEndCost } = this.state;
        endTripFilters.attributes.tripCost = e?.target?.value;
        tripEndCost = e?.target?.value;
        this.setState({ endTripFilters, tripEndCost });
    }

    onChangeCustomerLoad = e => {
        let { endTripFilters, endtripCustomerCharges } = this.state;
        endTripFilters.attributes.customerLoadingCharges = e;
        endtripCustomerCharges = e;
        this.setState({ endTripFilters, endtripCustomerCharges });
    }

    onChangeCustomerDemurrage = e => {
        let { endTripFilters, endtripCustomerDemurrageCharges } = this.state;
        endTripFilters.attributes.customerDemurrageCharges = e;
        endtripCustomerDemurrageCharges = e;
        this.setState({ endTripFilters, endtripCustomerDemurrageCharges });
    }

    onChangeVendorLoad = e => {
        let { endTripFilters, endtripVendorCharges } = this.state;
        endTripFilters.attributes.vendorLoadingCharges = e;
        endtripVendorCharges = e;
        this.setState({ endTripFilters, endtripVendorCharges });
    }

    onChangeVendorDemurrage = e => {
        let { endTripFilters, endtripVendorDemurrageCharges } = this.state;
        endTripFilters.attributes.vendorDemurrageCharges = e;
        endtripVendorDemurrageCharges = e;
        this.setState({ endTripFilters, endtripVendorDemurrageCharges });
    }



    onUpdateDateTimeOk = value => {

        this.setState({ updatedTime: value })
    }


    setDelayHours = e => {
        let pendingTrips = [...this.state.pendingTrips];

        pendingTrips = pendingTrips.map(thc => {
            let item = { ...thc };
            if (item?.checked) {
                item.expectedEta = moment(thc.actualEta).add(e, 'h').valueOf();
            }


            return item;
        });

        this.setState({ pendingTrips, delayHours: e })

    };
    changePage = (page, pageSize) => {
        // console.log("page, page", page)
        this.props.changePage(page)
        this.setState({ tableLoading: true });

        this.props.paginatedFilters(pageSize, page)
    }

    goCreatTrip = () => {
        this.props.goCreatTrip();
        // this.props.history.push("/appv2/trips/form/trips", {});
    }

    loadDocketsRoute(trip) {

        let obj = {
            ...trip.attributes,
            id: trip.id,
            asset: trip.asset,
            thcbos: trip.thcbos,
            drsbos: trip.drsbos
        }

        localStorage.setItem("props", JSON.stringify(obj))
        this.props.history.push("/appv2/trips/form/trips", { "props": true });
    }

    editTripCancelModal = () => {
        this.setState({ editTripData: {}, }, () => {
            this.setState({ editTripVisible: false, editManifestData: [], reasonCategory: undefined, reason: undefined, editTripData: {}, replaceVehicleToggle: false, selectedVehicleBillingModel: undefined, showToggle: false, warningMessage: [], editTripUpdateLoding: false });
            // this.props.paginatedFilters(10);
        })
        // this.setState({ editTripVisible: false, editTripData: {} })
    }

    cancelWarning = () => {

    }

    editTripOkModal = () => {
        // console.log("editTripData->>", this.state.editTripData)
        // console.log("editManifestData->>", this.state.editManifestData, this.state.deletedLeadManifest)
        let { editTripData, deletedManifestDeleted, deletedLeadManifest } = this.state;
        if (this.state.fields.gpsdevice.value === 'gps') {
            if (!this.state.fields.gpsid.value) {
                notifyApiError('GPS ID is required', 'Warning');
                return 0;
            }
        } else if (this.state.fields.gpsdevice.value === 'sim') {
            if (!this.state.fields.serviceprovider.value) {
                notifyApiError('Service Provider is required', 'Warning');
                return 0;
            }
            if (!this.state.fields.trackingnumber.value) {
                notifyApiError('Tracking Number is required', 'Warning');
                return 0;
            }
        } else {
            if (!this.state.fields.reason.value) {
                notifyApiError('Reason field is required', 'Warning');
                return 0;
            }
        }
        let filters = {
            attributes: editTripData.attributes,
            tripId: editTripData.id,
            asset: {
                id: editTripData.asset.id,
                "vehicleBillingType": {
                    "id": `${this.state.selectedBillingModel}`
                }
            }
        };
        if (this.state.fields.serviceprovider.value) {
            let { asset, attributes } = filters;
            asset['deviceDetails'] = { "provider": this.state.fields.serviceprovider.value, "imei": this.state.fields.trackingnumber.value };
            filters['imei'] = this.state.fields.trackingnumber.value;
            attributes['imei'] = this.state.fields.trackingnumber.value;
            filters = { ...filters, asset, attributes }
        }
        // console.log("this.state.fields.gpsdevice.value", this.state)
        if (this.state.fields.gpsdevice.value === 'nodevice') {
            let { attributes } = filters;
            attributes['noDeviceReasonCategoryId'] = this.state?.fields?.reasonCategory?.value?.key;
            attributes['noDeviceReasonCategoryName'] = this.state?.fields?.reasonCategory?.value?.label;
            attributes['noDeviceResonId'] = this.state?.fields?.reason?.value?.key;
            attributes['noDeviceResonName'] = this.state?.fields?.reason?.value?.label;
            filters = { ...filters, attributes }
        }
        if (this.state.showToggle) {
            let { attributes } = filters;
            attributes['billingAssetId'] = editTripData.asset.id;
            filters = { ...filters, attributes }
        } else {
            let { attributes } = filters;
            attributes['billingAssetId'] = this.state?.selectedVehicleBillingModel;
            filters = { ...filters, attributes }
        }
        let { attributes } = filters;

        // attributes['actualBillingModel'] = this.state?.actualBillingModel;
        attributes['tripBillingModel'] = this.state?.tripBillingModel;
        delete attributes?.actualBillingModel;
        filters = { ...filters, attributes }
        console.log("filters", filters)
        if (deletedManifestDeleted.length > 0) {
            filters['consignmentsToDelink'] = deletedManifestDeleted.map(delManifest => { return { "id": delManifest.id } });
        }

        if (deletedLeadManifest.length > 0) {
            filters['leadsToDelink'] = deletedLeadManifest.map(delManifest => { return { "id": delManifest.id } });
        }

        if (this.state.sim_tracking_enable) {
            if (this.state.definedTrackingNumber !== this.state.fields.trackingnumber.value) {
                const _this = this;
                confirm({
                    title: 'Tracking Consent is already approved.',
                    content: 'Do you want to change the tracking number?',
                    onOk: () => {
                        _this.setState({ editTripUpdateLoding: true }, () => {
                            apiUtils.apiRequest({
                                url: `/b2b/v1/trip/lite/update`,
                                data: filters,
                                method: "POST"
                            })
                                .then(response => {
                                    if (checkHttpStatus(response.data?.status)) {
                                        if (response.data?.status.code == 200 || response.data?.status.code == 202) {
                                            // notifyApiSuccess(response.data?.status ?.message, "Success::");
                                            _this.setState({ editTripUpdateLoding: false });
                                            notifyApiSuccess(response.data?.status?.message, "Success::");
                                            _this.props.paginatedFilters(10);
                                            _this.editTripCancelModal();
                                            // dispatch(getEditTripUpdateSuccess(response.data?.response));
                                        }
                                    } else if (response.data?.status.code == 206) {
                                        const _that = _this;
                                        confirm({
                                            title: 'Warning',
                                            icon: <Icon type="warning" theme="twoTone" twoToneColor="#FF0000" />,
                                            okText: 'Proceed',
                                            cancelText: 'Cancel',
                                            content:
                                                <>
                                                    <p>{response.data?.status?.message}</p>
                                                    <br />
                                                    <p>{response.data?.status?.secondaryMessage}</p>




                                                </>,
                                            onOk: () => {
                                                filters = {
                                                    ...filters,
                                                    "ignoreWarningMessage": true,
                                                }
                                                apiUtils.apiRequest({
                                                    url: `/b2b/v1/trip/lite/update`,
                                                    data: filters,
                                                    method: "POST"
                                                }).then(res => {
                                                    if (checkHttpStatus(res.data?.status)) {
                                                        if (res.data?.status.code == 200 || response.res?.status.code == 202) {
                                                            // notifyApiSuccess(response.data?.status ?.message, "Success::");
                                                            _this.setState({ editTripUpdateLoding: false });
                                                            notifyApiSuccess(res.data?.status?.message, "Success::");
                                                            _this.props.paginatedFilters(10);
                                                            _this.editTripCancelModal();
                                                            // dispatch(getEditTripUpdateSuccess(response.data?.response));
                                                        }
                                                    }
                                                })
                                                // _that.props.createTrip(filters);


                                            },
                                            onCancel: () => { _this.setState({ showRateCard: true, editTripUpdateLoding: false, warningMessage: response.data?.response?.consignmentIdErrorMappings }) }

                                        })



                                    }
                                    else {
                                        // dispatch(getEditTripUpdateFailure(response.data))
                                        notifyApiError("Trip Editing Failed", 'Error');
                                        _this.setState({ editTripUpdateLoding: false });
                                        _this.editTripCancelModal();
                                    }
                                })
                                .catch(error => notifyApiError(error, 'Error'));
                        })

                    },
                    okText: 'Confirm',
                    cancelText: 'Cancel'
                })
            }

        } else {
            this.setState({ editTripUpdateLoding: true }, () => {
                apiUtils.apiRequest({
                    url: `/b2b/v1/trip/lite/update`,
                    data: filters,
                    method: "POST"
                })
                    .then(response => {
                        console.log("res", response)
                        if (checkHttpStatus(response.data?.status)) {
                            if (response.data?.status.code == 200 || response.data?.status.code == 202) {
                                // notifyApiSuccess(response.data?.status ?.message, "Success::");
                                this.setState({ editTripUpdateLoding: false });
                                notifyApiSuccess(response.data?.status?.message, "Success::");
                                this.props.paginatedFilters(10);
                                this.editTripCancelModal();
                                // dispatch(getEditTripUpdateSuccess(response.data?.response));
                            }
                        } else if (response.data?.status.code == 206) {
                            const _that = this;
                            confirm({
                                title: 'Warning',
                                icon: <Icon type="warning" theme="twoTone" twoToneColor="#FF0000" />,
                                okText: 'Proceed',
                                cancelText: 'Cancel',
                                content:
                                    <>
                                        <p>{response.data?.status?.message}</p>
                                        <br />
                                        <p>{response.data?.status?.secondaryMessage}</p>




                                    </>,
                                onOk: () => {
                                    filters = {
                                        ...filters,
                                        "ignoreWarningMessage": true,
                                    }
                                    apiUtils.apiRequest({
                                        url: `/b2b/v1/trip/lite/update`,
                                        data: filters,
                                        method: "POST"
                                    }).then(res => {
                                        if (checkHttpStatus(res.data?.status)) {
                                            if (res.data?.status.code == 200 || response.res?.status.code == 202) {
                                                // notifyApiSuccess(response.data?.status ?.message, "Success::");
                                                _that.setState({ editTripUpdateLoding: false });
                                                notifyApiSuccess(res.data?.status?.message, "Success::");
                                                _that.props.paginatedFilters(10);
                                                _that.editTripCancelModal();
                                                // dispatch(getEditTripUpdateSuccess(response.data?.response));
                                            }
                                        }
                                    })
                                    // _that.props.createTrip(filters);


                                },
                                onCancel: () => { _that.setState({ showRateCard: true, editTripUpdateLoding: false, warningMessage: response.data?.response?.consignmentIdErrorMappings }) }

                            })



                        }
                        else {
                            // dispatch(getEditTripUpdateFailure(response.data))
                            notifyApiError("Trip Editing Failed", 'Error');
                            this.setState({ editTripUpdateLoding: false });
                            this.editTripCancelModal();
                        }
                    })
                    .catch(error => notifyApiError(error, 'Error'));
            })
        }








        // console.log("Filters->>", filters)
        // this.props.editTrip(filters);
        // setTimeout(() => {
        //     this.editTripCancelModal();
        // }, 2000)


    }

    selectBillingModelCreate = bm => {
        this.setState(state => ({
            tripBillingModel: bm,
            selectedBillingModel: bm
        }), () => {
            console.log("selectedBillingModel select->>", this.state.selectedBillingModel)
        })
    }

    getBillingModel = () => {
        let { billingModel } = this.state;

        if (billingModel.length <= 0) {
            apiUtils.apiRequest({
                url: `/b2b/v1/vehicle-types/getAllVehicleTypes`,
                method: 'POST',
                data: {
                    filter: {}
                }
            }).then(res => {
                let { categories } = this.state;
                // categories = res?.data?.response?.vehicleTypes?.filter(cat => cat?.isDefault && cat?.isActive);
                categories = res?.data?.response?.vehicleTypes;
                this.setState({ billingModel: categories })
            });
        }

    }


    editTrip(trip) {
        // console.log("trip", trip)
        if (trip.status === 'STARTED' || trip.status === 'FINISHED' || trip.status === 'COMPLETED' || trip?.attributes?.tripType == "CL") {
            return;
        }
        this.props.getServiceProviders();
        this.getBillingModel();

        let data = [], leadData = [];


        trip.thcbos.map(thc => {

            if (thc.manifests && thc.manifests.length > 0) {

                thc.manifests.map(manifest => {

                    if (manifest.consignments && manifest.consignments.length > 0) {

                        manifest.consignments.map(consign => {

                            data.push(consign)
                        })
                    }
                })
            }

        });


        trip.prses.length > 0 && trip.prses.map(prs => {
            prs.leads.length > 0 && prs.leads.map(lead => {
                leadData.push(lead)
            })
        });

        trip.drsbos.length > 0 && trip.drsbos.map(drs => {
            if (drs.consignments && drs.consignments.length > 0) {
                drs.consignments.map(consign => {
                    data.push(consign)
                })
            }
        });

        console.log("trip", trip)
        this.setState({ editTripData: {}, selectedBillingModel: trip?.attributes?.tripBillingModel ? Number(trip?.attributes?.tripBillingModel) : undefined }, () => {
            // console.log("selectedBillingModel->>", this.state.selectedBillingModel)
            if (_.hasIn(trip.routeName, 'attributes')) {
                if (!this.props.assets || this.props.assets.length <= 0) {

                    this.props.getAllAssets(trip.routeName.attributes.routeId);
                }
            }


            this.setState({ editTripData: { ...trip }, editManifestData: data, editLeadData: leadData }, () => {
                let { fields, sim_tracking_enable, definedTrackingNumber } = this.state;
                sim_tracking_enable = false;
                if ((this.state.editTripData.attributes.billingAssetId) || ((this.state.editTripData?.attributes?.tripLoadType === 'FTL' && this.state.editTripData?.attribtes?.tripType === 'PLANNED') || this.state.editTripData?.attribtes?.tripLoadType === 'PTL')) {
                    // this.setState({ showToggle: true });
                    console.log("artrrrr", this.state.editTripData.attributes.billingAssetId !== this.state.editTripData.asset.id)
                    if (Number(this.state.editTripData.attributes.billingAssetId) !== Number(this.state.editTripData.asset.id)) {
                        this.setState({ showToggle: true, replaceVehicleToggle: true, selectedVehicleBillingModel: Number(this.state.editTripData.attributes.billingAssetId) });
                    } else {
                        this.setState({ showToggle: false, replaceVehicleToggle: false, selectedVehicleBillingModel: undefined });
                    }

                    if (this.state.billingVehiclesList?.length <= 0) {
                        apiUtils.apiRequest({
                            url: `/b2b/v1/assets/ratecard`,
                            method: 'GET',
                        }).then(res => {
                            let billingVehiclesList = [];

                            billingVehiclesList = res?.data?.response?.assets;
                            this.setState({ billingVehiclesList })


                        });
                    }
                }
                this.setState({
                    // actualBillingModel: this.state?.editTripData?.asset?.vehicleTypesBO?.id,
                    tripBillingModel: this.state?.editTripData?.attributes?.tripBillingModel

                }, () => {
                    // let { billingModel } = this.state;
                    // let findVehicleCategory = billingModel.find(bm => bm?.id == this.state?.editTripData?.attributes?.tripBillingModel);
                    // console.log("dd", findVehicleCategory, billingModel)
                    // if (_.isUndefined(findVehicleCategory)) {
                    //     billingModel.push(this.state?.editTripData?.asset?.vehicleTypesBO);
                    // }
                    // this.setState({ billingModel });
                })
                if (_.hasIn(trip.routeName, 'attributes') && trip.attributes.is_sim_tracking == "true") {
                    sim_tracking_enable = true;
                    fields.gpsdevice.value = 'sim';
                    fields.trackingnumber.value = trip.asset.deviceDetails.imei;
                    definedTrackingNumber = trip.asset.deviceDetails.imei;
                    fields.serviceprovider.value = trip.asset.deviceDetails.provider;
                    fields.mobilenumber.value = _.hasIn(trip.routeName, 'attributes') && trip.attributes.mobileNumber;

                    fields.drivername.value = _.hasIn(trip.routeName, 'attributes') && trip.attributes.name;
                }


                else if (trip.attributes.is_sim_tracking == "false" && _.hasIn(trip.asset, 'deviceDetails')) {
                    sim_tracking_enable = false;
                    definedTrackingNumber = undefined;
                    fields.gpsdevice.value = 'gps';
                    fields.gpsid.value = trip.asset.deviceDetails.imei;
                } else if (!_.hasIn(trip.routeName, 'attributes') || (trip.attributes.is_sim_tracking == "false" || !_.hasIn(trip.asset, 'deviceDetails'))) {
                    sim_tracking_enable = false;
                    definedTrackingNumber = undefined;
                    fields.gpsdevice.value = 'nodevice';
                    // if (this.state.reasonCategories.length <= 0) {
                    apiUtils.apiRequest({
                        url: `/b2c/v2/reasons/categories/fetch`,
                        data: { "categoryFilters": { "types": ["NOT_TRACKED"], "isActive": true, "fetchObjects": ["PARTNER_REASONS"] } },
                        method: "POST"
                    })
                        .then(response => {
                            if (checkHttpStatus(response.data?.status)) {
                                const reasonCategories = response?.data?.response?.reasonCategories;
                                this.setState({ reasonCategories }, () => {

                                    fields.reasonCategory.value = {
                                        "key": trip?.attributes?.noDeviceReasonCategoryId,
                                        "label": trip?.attributes?.noDeviceReasonCategoryName
                                    };
                                    const reasons = reasonCategories.filter(v => v.id == trip?.attributes?.noDeviceReasonCategoryId)[0]?.reasons;
                                    this.setState({ reasons }, () => {

                                        const reason = reasons.filter(r => r.reasonId == trip?.attributes?.noDeviceResonId)[0];

                                        fields.reason.value = {
                                            "key": reason?.reasonId,
                                            "label": reason?.reasonDescription
                                        }
                                    });


                                });
                            }
                            else {
                                notifyApiError(response?.data?.message, "ERROR:");
                            }
                        })
                        .catch(error => notifyApiError(error, "ERROR:"));
                    // }

                    // fields.mobilenumber.value = _.hasIn(trip.routeName, 'attributes') ? trip.attributes.mobileNumber : undefined;
                    // fields.drivername.value = _.hasIn(trip.routeName, 'attributes') ? trip.attributes.name : undefined;
                }
                if (_.hasIn(trip.attributes, 'vendorId')) {
                    fields.vendor.value = { "key": trip.attributes.vendorId, "label": trip.attributes.vendorName };
                } else {
                    fields.vendor.value = { key: -1, label: "Self" };
                }
                this.setState({ fields, editTripVisible: true, sim_tracking_enable, definedTrackingNumber });
            })
        })

    }

    onSelectReasonCategory = option => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                reason: {
                    value: undefined
                },
                reasonCategory: {
                    value: {
                        label: option?.label,
                        key: option?.key
                    }
                }
            },
            reasons: []
        }), () => {
            const reasons = this.state?.reasonCategories.filter(v => v.id == option?.key)[0]?.reasons;
            this.setState({ reasons });
        });

    }

    selectReason = option => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                reason: {
                    value: {
                        label: option?.label,
                        key: option?.key
                    }
                }
            },
            reasons: []
        }));
    }



    closeTrip(trip) {
        if (trip.status === 'STARTED' || trip.status === 'FINISHED' || trip.status === 'COMPLETED') {
            return;
        }
        this.setState({ cancelVisible: true, cancelTripData: trip });
    }

    okCancelModal() {
        cancelTrip(this.state.cancelTripData.id)
            .then(data => {
                if (data.data.status.code === 200) {
                    this.setState({ cancelTripData: '' });
                    this.setState({ cancelVisible: false }, () => {
                        this.props.paginatedFilters(10);
                    })
                }
            })
    }

    endCancelModal() {
        this.setState({ cancelVisible: false }, () => {
            // this.props.paginatedFilters(10);
        })
    }


    generateManifest(trip) {

        this.setState({ downloadLink: null }, () => {
            let filters = {
                "commCode": "MANIFEST_SHEET_LITE",
                "tripIds": [`${trip.id}`],
                "documentType": "MANIFEST_SHEET_LITE",
                "locationId": `${this.props.header.loggedInUser.location.id}`,
                "partnerId": `${this.props.header.loggedInUser.partner.id}`,
                "userId": `${this.props.header.loggedInUser.id}`
            }

            this.props.getManifestSheet(filters);
            this.setState({ showManifestModal: true });
        })

    }

    okManifestModal = () => {
        this.setState({ showManifestModal: false, downloadLink: null });
    }

    onChangeUpdateStatus = (ptripsId, e) => {

        let pendingTrips = [...this.state.pendingTrips];

        pendingTrips = pendingTrips.map(thc => {
            let item = { ...thc };

            if (item.id === ptripsId) {
                if (e.target.checked) {
                    item.expectedEta = moment(thc.actualEta).add(this.state.delayHours, 'h').valueOf();
                    item.checked = true;
                } else {
                    item.expectedEta = thc.actualEta;
                    item.checked = false;
                }
            }


            return item;
        });

        this.setState({ pendingTrips })

    }

    setDelayReason = reason => {

        this.setState({ setDelayReason: reason });
    }



    searchWayBill = e => {
        let text = e.target.value;
        this.setState({ wayBillInput: text });
        if (text.length == 0) {
            this.setState({ enableClose: false });
            return;
        }

        this.setState({ enableClose: true });


    }

    keyPressed = e => {

        if (e.key === "Enter") {
            this.props.searchWayBills(1, 10, this.state.wayBillInput);
        }
    }

    clearSearch = () => {
        this.setState({ enableClose: false, wayBillInput: '' });
        this.props.searchWayBills(1, 10, '')
    }

    modalShowFilters = () => {
        this.setState({ showFilters: true })
    }

    cancelFilters = () => {
        this.setState({ showFilters: false })
    }




    changeFilterTripID = e => {
        let { tripId } = this.state.listingFilters;
        tripId = e.target.value;

        this.setState({ listingFilters: { ...this.state.listingFilters, tripId } });
    }

    selectBookingTo = (date, dateString) => {
        let { bookingTo } = this.state.listingFilters;
        bookingTo = date;

        this.setState({ listingFilters: { ...this.state.listingFilters, bookingTo } });
    }

    selectBookingFrom = (date, dateString) => {
        let { bookingFrom } = this.state.listingFilters;
        bookingFrom = date;



        this.setState({ listingFilters: { ...this.state.listingFilters, bookingFrom } });
    }

    onChangeTripStatus = e => {
        let { tripStatusValue } = this.state.listingFilters;
        tripStatusValue = e.target.value;
        this.setState({ listingFilters: { ...this.state.listingFilters, tripStatusValue } });

    }

    selectLocationFilter = locations => {
        let { location } = this.state.listingFilters;
        location = locations;
        this.setState({ listingFilters: { ...this.state.listingFilters, location } });

    }

    setAssetFilters = asset => {
        let { assetFilter } = this.state.listingFilters;
        assetFilter = asset;
        this.setState({ listingFilters: { ...this.state.listingFilters, assetFilter } });
    }


    applyFilters = () => {
        let bookingFilters = {};
        let isEligibleToComplete = null;

        if (_.isDate(new Date(this.state.listingFilters.bookingFrom)) || _.isDate(new Date(this.state.listingFilters.bookingTo))) {

            bookingFilters = {
                "bookingDate": {
                    "from": this.state.listingFilters.bookingFrom,
                    "to": this.state.listingFilters.bookingTo,
                }
            };
        }



        let mapFilter = {};

        if (!_.isEmpty(this.state.listingFilters.tripId)) {
            mapFilter = {
                tripIds: [`${this.state.listingFilters.tripId}`]
            }
        };
        let statuses = [];
        if (!_.isEmpty(this.state.listingFilters.tripStatusValue)) {
            if (this.state.listingFilters.tripStatusValue === 'STARTED_NOT_FINISHED') {
                statuses.push('STARTED');
                isEligibleToComplete = true;
            } else if (this.state.listingFilters.tripStatusValue === 'STARTED') {
                statuses.push('STARTED');
                isEligibleToComplete = false;
            } else {
                statuses.push(this.state.listingFilters.tripStatusValue);
                isEligibleToComplete = null;
            }

        }

        let locations = null;
        if (!_.isUndefined(this.state.listingFilters.location)) {
            locations = this.state.listingFilters.location;
        }
        let entityIds = [];
        if (!_.isUndefined(this.state.listingFilters.assetFilter)) {
            entityIds.push(this.state.listingFilters.assetFilter);
        }


        this.props.searchWayBills(1, 10, '', bookingFilters, mapFilter, statuses, isEligibleToComplete, locations, entityIds);
        this.setState({ showFilters: false })

    }

    resetFilters = () => {
        let { tripId, bookingFrom, bookingTo, tripStatusValue, location, assetFilter } = this.state.listingFilters;
        tripId = '';
        bookingFrom = '';
        bookingTo = '';
        tripStatusValue = '';
        location = undefined;
        assetFilter = undefined;

        this.setState({ listingFilters: { ...this.state.listingFilters, bookingFrom, bookingTo, tripStatusValue, tripId, location, assetFilter }, showFilters: false, wayBillInput: undefined }, () => {
            this.props.searchWayBills(1, 10, '', {}, {}, []);
        })

    }

    selectVendor = option => {

        // let {editTripData} = this.state;
        // editTripData.asset.id = value;
        // this.setState({editTripData: {...editTripData}}) 
        // let { fields } = this.state;
        // fields.vendor.value = option;
        // this.setState({ fields });

        let { fields } = this.state;
        const { vendorData } = this.props;
        const vendorFetchData = vendorData.filter(v => v.id === option.key);
        if (vendorFetchData[0].isBlocked) {
            this.props.getVendorBlockReason(option.key)
            let { editTripData } = this.state;
            editTripData.attributes.vendorId = undefined;
            // editTripData.attributes.vendorName = option.label;
            this.setState({ editTripData: { ...editTripData } })
        } else {
            let { editTripData } = this.state;
            editTripData.attributes.vendorId = option.key;
            fields.vendor.value.key = option.key;
            fields.vendor.value.label = option.label;
            editTripData.attributes.vendorName = option.label;
            this.setState({ editTripData: { ...editTripData, fields } })
        }


    }

    selectServiceProvider = option => {
        let { editTripData, fields } = this.state;
        // "provider": this.state.fields.serviceprovider.value.label, "imei": this.state.fields.trackingnumber.value
        editTripData.attributes.provider = option;
        fields.serviceprovider.value = option
        // editTripData.attributes.vendorName = option.label;
        this.setState({ editTripData: { ...editTripData }, fields: { ...fields } })
    }


    selectVehicle = value => {
        let vendorData = null;
        let blocked = false;
        this.props.assets.map(val => {

            if (val["id"] === value.key) {
                let d = this.props?.vendorData.map(vendor => {
                    if (val["vendorId"] == vendor.id) {
                        if (vendor.isBlocked) {
                            this.props.getVendorBlockReason(vendor.id);
                            blocked = true;
                        } else {

                            vendorData = vendor;
                            blocked = false;
                        }

                    }



                })
            }
        });

        return this.props?.assets?.map(val => {
            if (val["id"] === value.key && !blocked) {
                let { editTripData } = this.state;
                editTripData.asset.id = value.key;
                editTripData.asset.vehicleNumber = value.label;

                // vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                editTripData.attributes.vendorId = vendorData ? vendorData.id : -1;
                editTripData.attributes.vendorName = vendorData ? vendorData.name : "Self";
                this.setState({ editTripData: { ...editTripData } })
                this.setState(state => ({
                    fields: {
                        ...state.fields,
                        selectedBillingModel: {
                            value: val?.vehicleTypesBO?.id
                        }
                    },
                    // actualBillingModel: val?.vehicleTypesBO?.id,
                    tripBillingModel: val?.vehicleTypesBO?.id,
                }))
                // break;
            } else if (val["id"] === value.key && blocked) {
                let { editTripData } = this.state;
                editTripData.asset.id = undefined;
                editTripData.asset.vehicleNumber = undefined;
                this.setState(state => ({
                    fields: {
                        ...state.fields,
                        selectedBillingModel: {
                            value: val?.vehicleTypesBO?.id
                        }
                    },
                    // actualBillingModel: val?.vehicleTypesBO?.id,
                    tripBillingModel: val?.vehicleTypesBO?.id,
                }))
                // vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                editTripData.attributes.vendorId = undefined;
                // editTripData.attributes.vendorName =  undefined;
                this.setState({ editTripData: { ...editTripData } })
            }
        })



        // let { editTripData } = this.state;
        // editTripData.asset.id = value.key;
        // editTripData.asset.vehicleNumber = value.label;
        // this.setState({ editTripData: { ...editTripData } })
    }


    onChangeDevice = e => {

        let { fields } = this.state;
        fields.gpsdevice.value = e.target.value;
        this.setState({ fields: { ...fields } });
    }

    editImei = e => {

        let { fields } = this.state;
        fields.gpsid.value = e.target.value;
        this.setState({ fields: { ...fields } });
    }

    driverNameValue = e => {
        let { fields } = this.state;
        fields.drivername.value = e.target.value;
        this.setState({ fields: { ...fields } });
    }

    mobilenumberValue = e => {
        let { fields } = this.state;
        fields.mobilenumber.value = e.target.value;
        this.setState({ fields: { ...fields } });
    }

    trackingNumberValue = e => {
        let { fields, editTripData } = this.state;
        fields.trackingnumber.value = e.target.value;
        editTripData.attributes.imei = e.target.value;
        this.setState({ fields: { ...fields }, editTripData: { ...editTripData } });
    }



    renderEditDockets() {

        const columns = [
            {
                title: 'Docket No.',
                key: 'waybillNo',
                width: 180,
                dataIndex: 'waybillNo',
                render: (data, row) => {
                    if (this.state.showRateCard && this.state.warningMessage.length > 0) {
                        return this.state.warningMessage?.map(con => (
                            con.consignmentId === row.id &&
                            <>
                                {row.waybillNo} <br />
                                <Icon type="info-circle" theme="twoTone" twoToneColor="#FF0000" /><span style={{ color: '#FF0000' }}>{con?.message}</span>
                            </>

                        ))
                    } else {
                        return <>{data}</>
                    }
                }

            },
            {
                title: 'Customer', key: 'customerName', width: 120, dataIndex: 'customerName', render: customerName => customerName.name
            },
            {
                title: 'Created By', key: 'user', width: 120, dataIndex: 'user', render: user => user.name
            },
            {
                title: 'Pieces/weight', key: 'pieces', width: 150, dataIndex: 'pieces'
            },
            {
                title: 'Date', key: 'date', width: 180, dataIndex: 'date'
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'x',
                fixed: 'right',
                render: (text, record) => (
                    <Button type="danger" onClick={(e) => { this.onDelete(e, record.key, record.lead); }}>DeLink</Button>
                ),
            },

        ];

        let data = [];
        this.state.editManifestData.map(consign => {
            data.push(
                {
                    'key': consign.waybillNo,
                    'waybillNo': consign.waybillNo,
                    'customerName': consign.customer,
                    'user': consign.thcUser,
                    'pieces': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                    'date': moment(consign.bookingDate).format("DD-MMM-YYYY hh:MM"),
                    'lead': false,
                    ...consign
                }
            )
        });

        this.state.editLeadData.length > 0 && this.state.editLeadData.map(lead => {
            data.push(
                {
                    'key': lead.id,
                    'waybillNo': lead.code,
                    'customerName': '',
                    'user': '',
                    'pieces': '',
                    'lead': true,
                    'date': moment(lead.createdAt).format("DD-MMM-YYYY hh:MM"),

                    ...lead
                }
            )
        });
        // this.state.editManifestData.prses.length > 0 && this.state.data.editManifestData.prses.map(lead => {
        //     data.push(
        //         {
        //             'key': lead.id,
        //             'waybillNo': lead.code,


        //             'date': moment(lead.createdAt).format("DD-MMM-YYYY hh:MM"),

        //             ...lead
        //         }
        //     )
        // })




        return (
            <>
                <Table
                    pagination={false}
                    columns={columns}
                    scroll={{ x: 850 }}
                    dataSource={data}
                />
            </>
        )
    }

    onDelete = (e, key, lead) => {

        e.preventDefault();

        if (lead) {
            let { deletedLeadManifest } = this.state;
            const d = this.state.editLeadData.filter(item => item.id !== key);
            const notDeleted = this.state.editLeadData.filter(item => item.id === key);
            this.setState({ editLeadData: d, deletedLeadManifest: [...deletedLeadManifest, ...notDeleted] });
        } else {
            let { deletedManifestDeleted } = this.state;

            const d = this.state.editManifestData.filter(item => item.waybillNo !== key);
            const notDeleted = this.state.editManifestData.filter(item => item.waybillNo === key);
            this.setState({ editManifestData: d, deletedManifestDeleted: [...deletedManifestDeleted, ...notDeleted] });
        }


    }

    enableCharge = (e, adhoc) => {


        let adhocNewCharges = this.state.adhocCharges.map(charge => {
            if (charge.id == adhoc.id) {
                charge.disabled = !e.target.checked;
            }
            return charge
        });


        this.setState({ adhocCharges: adhocNewCharges })
    }

    onChangeCharges = (e, id) => {
        let adhocNewCharges = this.state.adhocCharges.map(charge => {
            if (charge.id == id) {
                if (e.target.value) {
                    charge.error = "succes"
                    charge.msg = ""
                    charge.chargeValue = e.target.value;
                }
                else {
                    charge.error = "error"
                    charge.msg = "Field is required"
                }
            }
            return charge
        });
        this.setState({ adhocCharges: adhocNewCharges })
    }

    onSelectVendorCustomer = (val, id) => {

        let { endTripFilters } = this.state;


        let adhocNewCharges = this.state.adhocCharges.map(charge => {
            if (charge.id == id) {
                let vendorCustomerCharges = val + charge.value;
                charge.vendorCustomer = val;
                endTripFilters.attributes[vendorCustomerCharges] = charge.chargeValue;
            }
            return charge
        });

        this.setState({ adhocCharges: adhocNewCharges, endTripFilters })
    }

    redirectVehicleTracking = trip => {

        this.props.history.push(`/appv2/tracking/dashboard/vehicle-tracking/${trip.id}`);
    }

    changeUpdateTime = (date, ptrips) => {

        this.setState({ expectedEta: date })

        let pendingTrips = [...this.state.pendingTrips];

        pendingTrips = pendingTrips.map(thc => {
            let item = { ...thc };

            if (item.id === ptrips.id) {
                item.expectedEta = date.valueOf();
                item.checked = true;
            }


            return item;
        });

        this.setState({ pendingTrips })
    }

    notifyCopyEvent = () => {
        openNotification({
            message: 'Copied',
            description: 'Phone number has been copied to clipboard',
            className: 'warning',
        })
    }

    notifyCopyDocketEvent = () => {
        openNotification({
            message: 'Copied',
            description: 'Docket has been copied to clipboard',
            className: 'warning',
        })
    }


    disabledDate = current => {
        // const numberOfDays = this.props.app.configurations.restrictTripCreation.pickupDate.dayOfClosingMonth;
        return current && current > moment().endOf("day");
    }

    toggleTripSettlememtModal = (isOpen = false) => {
        this.setState({
            showTripSettlement: isOpen
        })
    }

    fetchTripSettlements = row => {
        this.setState({
            selectedTripId: row.id
        })
        const payload = {
            "filters": {
                "ids": [],
                "tripIds": [row.id],
                "entityIds": [],
                "utrs": [

                ],
                "paymentTypes": [],
                "entityTypes": [],
                "paymentDate": {
                    "from": "",
                    "to": ""
                },
                "uploadIds": [],
                "amount": {
                    "min": "",
                    "max": ""
                }
            }
        }
        this.props.fetchTripSettlements(payload)
    }

    printEwayBill = (trip) => {
        this.setState({ printTripID: trip.id, printModal: true })
        this.props.printEwayBill(trip);
    }

    cancelPrint = () => {
        this.props.clearPrintEway();
        this.setState({ printModal: false });
    }

    consolidateWayBill = (trip) => {

        let ewayBills = [];
        trip.thcbos && trip.thcbos.map(thc => {
            thc.manifests.map(manifest => {
                manifest.consignments.map(consign => {
                    consign.ewaybillBOs && consign.ewaybillBOs.length > 0 && consign.ewaybillBOs.map(eway => {
                        ewayBills.push(eway.ewaybillNo);
                    })
                })
            })
        });

        trip.drsbos && trip.drsbos.map(thc => {
            return thc.consignments.map(consign => {
                return consign.ewaybillBOs && consign.ewaybillBOs.length > 0 && consign.ewaybillBOs.map(eway => {
                    ewayBills.push(eway.ewaybillNo);


                })
            })
        });

        this.setState({ consolidateModal: true, consolidateTripID: trip.id, ewayBills: ewayBills });


        this.props.consolidateWayBill(trip);
    }

    cancelConsolidate = () => {
        this.props.getResetConsolidate();
        this.setState({ consolidateModal: false });
    }

    remove = index => {


        var charges = [...this.state.newAdhocCharges];
        charges.splice(index, 1);

        // console.log("index",  index);
        // let { newAdhocCharges } = this.state;
        // let charges = [...newAdhocCharges]
        // charges.splice(index, 0);

        this.setState({ newAdhocCharges: charges })
        // const { form } = this.props;
        // // can use data-binding to get
        // const keys = form.getFieldValue('keys');
        // // We need at least one passenger
        // if (keys.length === 1) {
        //     return;
        // }

        // // can use data-binding to set
        // form.setFieldsValue({
        //     keys: keys.filter(key => key !== k),
        // });
    };

    addAdhocCharges = (value) => {
        let charges = [];
        let { newAdhocCharges } = this.state;
        let custVal = 'customer' + value;
        let vendorVal = 'vendor' + value
        charges.push({
            chargeLabel: value,
            [custVal]: 0,
            [vendorVal]: 0,
            label: 'customer'
        });


        let count = 0;
        newAdhocCharges.map(charge => {
            // count = 0;

            if (charge.chargeLabel === value) ++count;
        });

        if (count === 2) return;
        // let charges = {

        // };
        // charges.concat(newAdhocCharges);
        // newAdhocCharges.push(charges)
        // newAdhocCharges.push(...charges)

        this.setState({ newAdhocCharges: [...newAdhocCharges, ...charges] });
    }


    add = (value) => {
        this.addAdhocCharges(value)
        // console.log("value->>", value)
        // let { newAdhocCharges } = this.state;
        // let charges = {
        //     chargeLabel: value
        // };
        // // charges.concat(newAdhocCharges);
        // newAdhocCharges.push(charges)
        // console.log("value->>", newAdhocCharges)
        // this.setState({ newAdhocCharges });

    };

    onChangeNewCharge = (e, index, label) => {
        let charges = [...this.state.newAdhocCharges];
        let chargeLabel = 'customer' + label;
        let vendorChargeLabel = 'vendor' + label;
        charges[index][chargeLabel] = parseInt(e.target.value);
        charges[index][vendorChargeLabel] = parseInt(e.target.value);
        this.setState({ newAdhocCharges: charges })

    }

    newCustomerVendor = (value, i, label) => {
        // console.log("value", value, this.state.newAdhocCharges)
        let charges = [...this.state.newAdhocCharges];
        charges[i]['label'] = value;
        this.setState({ newAdhocCharges: charges })
        // this.setState({adhocChargesSelected[index]: value})

    }

    disableActualDate = current => {
        return current && current > moment().endOf('day');

    }


    onTopayAmtChange = val => {
        const self = this;
        this.setState({
            toPayAmtColledted: val,
            toPayAmtColledtedError: ''
        }, () => {
            const { tripToPayBO, docketNo, toPayAmtColledted } = self.state
            let toPayObj = tripToPayBO.toPayConsignmentList.find(item => item.waybillNo === docketNo)
            let toPayAmtColledtedError = '';
            if (toPayObj) {
                if (toPayObj.amountToBePaid > toPayAmtColledted) {
                    toPayAmtColledtedError = `Outstanding Amount is received less, please collect the full amount Rs. ${toPayObj.amountToBePaid}`
                } else if (toPayObj.amountToBePaid < toPayAmtColledted) {
                    toPayAmtColledtedError = `Outstanding Amount is received more, please collect only Rs. ${toPayObj.amountToBePaid}`
                } else {
                    toPayAmtColledtedError = ''
                }
            }
            self.setState({
                toPayAmtColledtedError
            })
        })
    }




    selectBillingVehicle = vehicleId => {
        this.setState({
            selectedVehicleBillingModel: vehicleId,
            fields: {
                ...this.state.fields,
                vendor: {
                    value: undefined
                }
            }
        }, () => {
            let vendorData = null;
            let blocked = false;
            this.state.billingVehiclesList.map(val => {
                if (val["id"] === vehicleId) {
                    this.setState({ selectedBillingModel: val?.vehicleTypesBO?.id })
                    let d = this.props?.vendorData.map(vendor => {
                        if (val["vendorId"] == vendor.id) {
                            if (vendor.isBlocked) {
                                this.props.getVendorBlockReason(vendor.id);
                                blocked = true;
                            } else {

                                vendorData = vendor;
                                blocked = false;
                            }

                        }



                    })
                }
            })
            if (this.state.editTripData?.attribtes?.tripLoadType === 'PTL' || this.state.editTripData?.attribtes?.tripLoadType === 'FTL') {
                return this.state.billingVehiclesList?.map(val => {
                    if (val["id"] === vehicleId && !blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,

                                vendor: {
                                    value: vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                                },
                            },
                        }))
                        // break;
                    } else if (val["id"] === vehicleId && blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,

                                vendor: {
                                    value: undefined
                                }
                            },
                        }));
                    }
                })
            } else {
                return this.state.billingVehiclesList?.map(val => {
                    if (val["id"] === vehicleId && !blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vendor: {
                                    value: vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                                }
                            },
                        }))
                        // break;
                    } else if (val["id"] === vehicleId && blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vendor: {
                                    value: undefined
                                }
                            },
                        }));
                    }
                })
            }
        });
    }
    changeVehicleToggle = value => {
        this.setState({ replaceVehicleToggle: value, showToggle: value, selectedVehicleBillingModel: undefined, selectedBillingModel: undefined }, () => {
            if (value) {

                if (this.state.billingVehiclesList.length <= 0) {
                    apiUtils.apiRequest({
                        url: `/b2b/v1/assets/ratecard`,
                        method: 'GET',
                    }).then(res => {
                        let billingVehiclesList = [];

                        billingVehiclesList = res?.data?.response?.assets;
                        this.setState({ billingVehiclesList })


                    });
                    console.log("ths", this.state.selectedVehicleBillingModel, this.state.selectedBillingModel)

                    this.setState(state => ({
                        fields: {
                            ...state.fields,
                            vendor: {
                                value: undefined
                            }

                        }
                    }))
                }

            } else {
                this.setState({ replaceVehicleToggle: value, selectedBillingModel: Number(this.state.editTripData?.asset?.vehicleTypesBO?.id) }, () => {
                    this.selectVehicle({ value: this.state.editTripData.asset.id, key: this.state.editTripData.asset.vehicleNumber })
                })
            }
        })
    }

    render() {
        // console.log("this.props->>", this.props.consolidateData, this.props.consolidateError,this.props.consolidateData?.consolidatedEwaybillUrl, (this.props.consolidateData && _.hasIn('consolidatedEwaybillUrl', this.props.consolidateData)))

        // let thcLength = 0, thcTotalWeights = 0, drbTotalWeights = 0, thcSinglePiece = 0, drbSinglePiece = 0, drbsLength = 0, totalSingle = 0, totalNewPcs = 0, totalWeights = 0;
        const { Option } = Select;

        const { SubMenu } = Menu;
        const driverMenu = (trip) => (
            <Menu>
                <Menu.Item key="1">
                    <div>{trip?.vehicleNumber?.attributes?.name}</div>
                </Menu.Item>
                <Menu.Item key="2">
                    <CopyToClipboard text={trip?.vehicleNumber?.attributes?.mobileNumber}
                        onCopy={this.notifyCopyEvent}>
                        <div>
                            {trip?.vehicleNumber?.attributes?.mobileNumber}
                            <Icon type="copy" />
                        </div>
                    </CopyToClipboard>
                </Menu.Item >

            </Menu >
        )

        const menu = (trip, record) => (
            <Menu>
                {/* {trip?.attributes?.tripType == "DV" &&
                <SubMenu
                    key="sub1"
                    title={
                        <span>
                            <Icon type="phone" />
                            <span>Driver Info </span>
                        </span>
                    }
                >
                    <Menu.ItemGroup>
                        <Menu.Item key="1">
                            <div>{trip?.vehicleNumber?.attributes?.name}</div>
                            <div>{trip?.vehicleNumber?.attributes?.mobileNumber}<Icon type="copy" /></div>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </SubMenu>} */}



                <Menu.Item key="2">
                    <div onClick={() => this.generateManifest(trip)}><Icon type="share-alt" /> Share Manifest </div>
                </Menu.Item>
                {/* {this.state.tripsPermission.update &&
                    <Menu.Item key="3" disabled={trip.status === 'STARTED' || trip.status === 'FINISHED'}>
                        <div onClick={() => this.editTrip(trip)}><Icon type="edit" /> Edit Trip</div>
                    </Menu.Item>
                } */}
                <Menu.Item key="3" disabled={trip.status === 'STARTED' || trip.status === 'FINISHED' || trip.status === 'COMPLETED' || trip?.attributes?.tripType == "CL"}>
                    <div onClick={() => this.editTrip(trip)}><Icon type="edit" /> Edit Trip</div>
                </Menu.Item>
                {this.state.tripsPermission.update &&

                    <Menu.Item key="4" disabled={trip.status === 'STARTED' || trip.status === 'FINISHED' || trip.status === 'COMPLETED'}>
                        <div onClick={() => this.closeTrip(trip)}><Icon type="stop" /> Cancel Trip</div>
                    </Menu.Item>
                }
                {trip.status === 'STARTED' && trip?.attributes?.tripType == "DV" && record.hasEwaybill &&

                    <Menu.Item key="6">
                        <div onClick={() => { this.consolidateWayBill(trip) }}><Icon type="fork" /> Consolidated E-waybill</div>
                    </Menu.Item>
                }
                {
                    trip.status === 'STARTED' && trip?.attributes?.tripType == "DV" && record.hasEwaybill &&
                    <Menu.Item key="5">
                        <div onClick={() => this.printEwayBill(trip)}><Icon type="printer" /> Print E-waybill</div>
                    </Menu.Item>
                }
            </Menu>
        );
        const columns = [
            {
                title: 'Trip ID', key: 'id', width: 170,
                render: trip =>
                    <>
                        {trip.id}
                        <br />
                        <span className="form-label-text">{moment(trip.createdAt).format("DD MMM YYYY hh:mm")}</span>
                        <br />
                        <div className="trip-listing-consent">
                            {trip.status !== "FINISHED" && trip.simTrackingDTO.isSimTrackingEnabled && trip.simTrackingDTO.isSimTrackingApproved ?
                                <p className="trip-listing-consent-approved"><img src={KirteGreen} /> Tracking Consent: Approved</p>
                                : trip.status !== "FINISHED" && trip.simTrackingDTO.isSimTrackingEnabled ? <p className="trip-listing-consent-pending"><img src={KirteRed} /> Tracking Consent: Pending</p> : <></>}
                        </div>
                    </>
            },
            {
                title: (<>Trip Type/ <br /> Load Type</>),
                dataIndex: 'tripType',
                width: 100,
                render: (trip, data) =>
                    <>
                        {trip?.attributes?.tripType !== 'CL' ? <> {trip?.attributes?.isAdhocTrip === "true" ? 'ADHOC' : 'Planned'}</> : <></>}
                        <br />
                        {trip?.attributes?.tripType}  {trip?.attributes?.tripType !== 'CL' ? <> / {trip?.attributes?.tripLoadType} </> : <></>}
                        <br />
                        {trip?.prses?.length > 0 ? <img src={PickupIcon} /> : <></>}
                        <Icon type="info-circle" onClick={() => { this.fetchTripSettlements(data); this.toggleTripSettlememtModal(true) }} />
                    </>
            },
            {
                title: (<>Vehicle No./ <br /> Coloading Parnter</>), dataIndex: 'vehicleNumber', key: 'vehicleNumber', render: trip =>
                    <>{trip?.attributes?.tripType == 'CL' ? trip.asset?.partner?.name : trip.asset.vehicleNumber}</>,
                width: 130
            },
            { title: (<>Route / <br />Coloading Location</>), dataIndex: 'routeName', key: 'routeName', render: trip => trip?.attributes?.routeName ? trip?.attributes?.routeName : trip.asset.partner?.name, width: 200 },
            {
                title: (<>No. of Dockets / <br />Shipment Counts / <br />Total chargeable wt.</>), key: 'dockets', width: 150,
                render: trip =>
                    <>
                        {trip?.totalSingle} / {trip?.totalNewPcs} Pcs
                        <br />
                        <span className="form-label-text">{trip?.totalWeights} Kgs</span>
                    </>
            },

            {
                title: 'Created By', key: 'user', render: trip => <>
                    {trip.user.thcbos && trip.user.thcbos.length > 0 ? trip.user.thcbos[0]?.thcUser?.name : trip?.user?.attributes?.name}</>,
                width: 100
            },
            {
                title: 'Actions',
                key: 'operation',
                width: 200,
                render: (trip, record) =>
                    <>
                        <Row gutter={[12, 2]}>
                            <Col span={12}>
                                <Row>
                                    {trip.status === 'STARTED' ?
                                        trip.tripActions.map(action => {
                                            // if (action === "LOAD_TRIP") return <Col order={1}><Button className="trip-load-button" onClick={() => this.loadDocketsRoute(trip)}>Load</Button></Col>
                                            if (action === "UPDATE_ETA") return <Col order={2}><Button onClick={() => this.updateStatus(trip)}>Update Status</Button></Col>
                                            if (action === "COMPLETE_TRIP") return <><Col order={3}><Button type="primary" onClick={() => this.completeTrip(trip)}>Complete Trip</Button></Col></>
                                        }) : trip.status === 'OPEN' ?
                                            trip.tripActions.map(action => {
                                                if (action === "LOAD_TRIP") return <Col order={1}><Button className="trip-load-button" onClick={() => this.loadDocketsRoute(trip)}>Load</Button></Col>
                                                if (action === "START_TRIP") return <Col order={2}><Button type="primary" onClick={() => { this.startTrip(trip) }}>{trip?.attributes?.tripType === 'CL' ? 'HANDOVER' : 'Start Trip'}</Button></Col>

                                            }) : <></>

                                    }
                                </Row>
                            </Col>
                            <Col span={6} offset={6}>
                                <Row gutter={[2, 6]}>

                                    <Col span={24}>
                                        <div className="floating-right">
                                            <Dropdown overlay={menu(trip, record)} trigger={['click']}>
                                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                    <Icon type="more" />
                                                </a>

                                            </Dropdown>
                                        </div>
                                    </Col>
                                    {trip?.attributes?.tripType == "DV" ?
                                        <Col span={24}>
                                            <div className="floating-right">
                                                <Dropdown overlay={driverMenu(trip)} trigger={['click']}>
                                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                        {/* <Icon type="phone" /> */}
                                                        <img className="gps-icon" src={phone} />
                                                    </a>

                                                </Dropdown>
                                            </div>
                                        </Col>
                                        : <></>
                                    }
                                    {
                                        trip?.trackingEnabled == "true" ?
                                            <Col span={24}>
                                                <Link to={`/appv2/tracking/dashboard/vehicle-tracking/${trip.id}`} target="_blank"><img className="gps-icon" src={GPSIcon} alt="GPS" /></Link>
                                                {/* <img className="gps-icon" src={GPSIcon} alt="GPS" onClick={() => this.redirectVehicleTracking(trip)} /> */}
                                            </Col>
                                            :
                                            <></>
                                    }
                                    {
                                        trip?.tripToPayBO?.isToPay && <Col span={24} offset={6}>
                                            <TripSummaryModal data={trip?.tripToPayBO?.toPayConsignmentList} />
                                        </Col>
                                    }
                                    <Col span={24} offset={6}>
                                        {record.wabill}
                                    </Col>



                                </Row>
                            </Col>
                        </Row>

                        {/* <Row type="flex" className="floting-left">

                            {trip.status === 'STARTED' ?
                                trip.tripActions.map(action => {
                                    // if (action === "LOAD_TRIP") return <Col order={1}><Button className="trip-load-button" onClick={() => this.loadDocketsRoute(trip)}>Load</Button></Col>
                                    if (action === "UPDATE_ETA") return <Col order={2}><Button onClick={() => this.updateStatus(trip)}>Update Status</Button></Col>
                                    if (action === "COMPLETE_TRIP") return <><Col order={3}><Button type="primary" onClick={() => this.completeTrip(trip)}>Complete Trip</Button></Col></>
                                }) : trip.status === 'OPEN' ?
                                    trip.tripActions.map(action => {
                                        if (action === "LOAD_TRIP") return <Col order={1}><Button className="trip-load-button" onClick={() => this.loadDocketsRoute(trip)}>Load</Button></Col>
                                        if (action === "START_TRIP") return <Col order={2}><Button type="primary" onClick={() => { this.startTrip(trip) }}>Start Trip</Button></Col>

                                    }) : <></>

                            }
                            {
                                trip.simTrackingDTO.isSimTrackingEnabled ?
                                    <Col span={6} order={5}>
                                        <img src={GPSIcon} alt="GPS" onClick={() => this.redirectVehicleTracking(trip)} />
                                    </Col> :
                                    <></>
                            }
                            <Col span={1}>
                                <div className="floating-right">
                                    <Dropdown overlay={menu(trip)} trigger={['click']}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <Icon type="more" />
                                        </a>

                                    </Dropdown>
                                </div>
                            </Col>



                        </Row> */}


                        {/* <Row className="floating-right">
                            <Col span={2}>


                            </Col>
                        </Row> */}
                    </>
            },
        ];
        let tripListing = null;

        let data = [];

        let manifestLength = 0, totalWeight = 0, totalPcs = 0;



        let tripsData = this.props.tripLists?.viewResponse ? this.props.tripLists?.viewResponse : [];

        let hasEwaybill = false;

        // if (tripsData && tripsData.length > 0) {

        // let thcIndividual = 0, drsindividual = 0, thcIndividualWeight = 0, drsIndividualWeight = 0;
        tripsData.length > 0 && tripsData.map(trip => {
            let wabill = null;
            let EWAY_ENUM = {
                EwayRed: 0,
                EwayOrange: 0,
                EwayGreen: 0
            }
            let thcLength = 0, thcTotalWeights = 0, drbTotalWeights = 0, thcSinglePiece = 0, drbSinglePiece = 0, drbsLength = 0, totalSingle = 0, totalNewPcs = 0, totalWeights = 0;


            trip.thcbos.map(thc => {
                thcLength = 0;
                thcSinglePiece = 0;
                thcTotalWeights = 0;
                thc.manifests.map(manifest => {

                    thcSinglePiece += manifest?.consignments?.length ? manifest.consignments.length : 0;

                    return manifest.consignments && manifest.consignments.map(consign => {
                        thcTotalWeights += consign.totalWeight;
                        thcLength += consign.totalShipmentCount;
                        hasEwaybill = false;
                        if (consign.ewaybillBOs && consign.ewaybillBOs.length > 0) {
                            hasEwaybill = true;
                            consign.ewaybillBOs.map(eway => {
                                if (eway.validUpto) {
                                    var validUpto = moment(eway.validUpto);
                                    var today = moment();
                                    var difference = today.diff(validUpto, 'hours');

                                    if (moment(eway.validUpto) <= moment()) {
                                        EWAY_ENUM.EwayRed += 1;
                                        // wabill =  <img src={EwayRed} />

                                    } else if (Math.abs(difference) <= this.props?.app?.configurations?.ewaybill?.waringHours) {
                                        // wabill =  <img src={EwayOrange} />
                                        EWAY_ENUM.EwayOrange += 1;
                                    } else {
                                        EWAY_ENUM.EwayGreen += 1;
                                        // wabill =  <img src={EwayGreen} />
                                    }
                                } else {
                                    EWAY_ENUM.EwayGreen += 1;
                                }


                            });
                            let ewayKey = Object.keys(EWAY_ENUM).reduce((a, b) => EWAY_ENUM[a] > EWAY_ENUM[b] ? a : b);

                            EWAY_ENUM = {
                                EwayGreen: 0,
                                EwayRed: 0,
                                EwayOrange: 0,

                            };
                            if (ewayKey == 'EwayGreen') {
                                wabill = <img src={EwayGreen} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayTripBills(trip)} />
                            } else if (ewayKey == 'EwayOrange') {
                                wabill = <img src={EwayOrange} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayTripBills(trip)} />
                            } else {
                                wabill = <img src={EwayRed} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayTripBills(trip)} />
                            }

                        } else {
                            wabill = <></>
                        }
                    })
                })
            });

            trip.drsbos.map(thc => {
                drbSinglePiece = 0
                drbsLength = 0;
                drbTotalWeights = 0;
                //  length = 0;

                drbSinglePiece += thc?.consignments?.length ? thc.consignments.length : 0;
                thc.consignments && thc.consignments.length > 0 && thc.consignments.map(consign => {
                    drbTotalWeights += consign.totalWeight;
                    drbsLength += consign.totalShipmentCount;
                })

            })

            totalSingle = thcSinglePiece + drbSinglePiece;
            totalWeights = thcTotalWeights + drbTotalWeights;
            totalNewPcs = thcLength + drbsLength;



            data.push(
                {
                    id: trip,
                    vehicleNumber: trip,
                    tripType: trip,
                    routeName: trip,
                    dockets: trip,
                    user: trip,
                    wabill,
                    totalSingle,
                    totalWeights: totalWeights.toFixed(2),
                    totalNewPcs,
                    hasEwaybill,
                    ...trip
                }
            )


        });
        if (this.state.tableLoading) {
            this.setState({ tableLoading: false })
        }
        tripListing = <Table
            bordered
            loading={this.props.loading}
            expandIcon={(props) => this.customExpandIcon(props)}
            columns={columns}
            // expandedRowKeys={[]}
            dataSource={data}
            expandedRowRender={this.expandedRowRender}
            pagination={{
                total: this.props.tripLists?.totalCount,
                current: this.props.tripLists?.currentPageNo,
                onChange: this.changePage
            }}
        />
        // }

        const formItemLayout = {
            layout: 'vertical'
        };




        const fields = { ...this.state.fields };

        const createMenu = (
            <Menu>
                <Menu.Item key="0" onClick={() => this.props.history.push('/appv2/trips/form/trips')}>
                    Create Trip
              </Menu.Item>
                {this.state.tripsPermission?.coloader &&
                    <Menu.Item key="1" onClick={() => this.props.history.push('/appv2/trips/form/trips/coloader')}>
                        Create CoLoader trip
                </Menu.Item>
                }
            </Menu>
        );

        const actionBtns = (
            <Row gutter={6}>

                <Col span={4}>
                    <Button block type="primary" onClick={() => { this.resetFilters() }}><Icon type="reload" /></Button>
                </Col>
                <Col span={10}>
                    <Button block onClick={this.modalShowFilters}><img src={Filters} /> Filters</Button>
                </Col>
                <Col span={10}>
                    {/* <Button disabled={!this.state.tripsPermission.create} type="primary" onClick={this.goCreatTrip}>Create Trip</Button> */}
                    {this.state.tripsPermission.create &&
                        <Dropdown overlay={createMenu} trigger={['click']}>
                            <Button block type="primary" onClick={e => e.preventDefault()}>
                                Create Trip <Icon type="down" />
                            </Button>

                        </Dropdown>
                    }
                </Col>
            </Row>
        );

        return (
            <>
                <Card
                    title={
                        <Input
                            onChange={this.searchWayBill}
                            onKeyPress={this.keyPressed}
                            style={{ width: '50%' }}
                            value={this.state.wayBillInput}
                            prefix={<Icon type="search" />}
                            placeholder="Search by WayBill No."
                            suffix={this.state.enableClose ? <Icon onClick={this.clearSearch} type="close-circle" /> : <></>}
                        />
                    }
                    extra={actionBtns}
                >

                    <br />
                    {tripListing}


                    <Modal
                        title="Filters"
                        width={400}
                        visible={this.state.showFilters}
                        onCancel={this.cancelFilters}
                        footer={[
                            <Button key="submitapplyFilters" type="primary" onClick={this.applyFilters}>
                                Apply
                            </Button>,
                            <Button key="submitresetFilters" type="primary" onClick={this.resetFilters}>
                                Reset Filters
                            </Button>
                        ]}
                    >
                        <Collapse defaultActiveKey={['1']} accordion>
                            <Panel header="DATE AND TIME" key="1">
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <span className="form-label-text">Booking Date (From)</span>
                                        <DatePicker style={{ width: '100%' }} onChange={this.selectBookingFrom} value={this.state.listingFilters.bookingFrom} />

                                    </Col>
                                    <Col span={24}>
                                        <span className="form-label-text">Booking Date (To)</span>
                                        <DatePicker style={{ width: '100%' }} onChange={this.selectBookingTo} value={this.state.listingFilters.bookingTo} />
                                    </Col>
                                    <Col span={24}>
                                        <span className="form-label-text">Trip ID</span>
                                        <Input
                                            placeholder="Trip ID"
                                            onChange={this.changeFilterTripID}
                                            value={this.state.listingFilters.tripId}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <span className="form-label-text">Trip Status</span>
                                        <Radio.Group onChange={this.onChangeTripStatus} value={this.state.listingFilters.tripStatusValue}>
                                            <Radio value={'OPEN'}>Open</Radio>
                                            <Radio value={'STARTED'}>In Transit</Radio>
                                            <Radio value={'STARTED_NOT_FINISHED'}>Not Finished</Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel header="DESTINATION LOCATIONS" key="2">
                                <Select
                                    showSearch
                                    placeholder="Select Location"
                                    onSelect={this.selectLocationFilter}
                                    value={this.state.listingFilters.location}
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.locationGroups?.response?.locationsGroupedByRole?.DELIVERY.concat(this.props.locationGroups?.response?.locationsGroupedByRole?.PICKUP).map((location, key) => (
                                        <Option
                                            value={location.id}
                                            label={location.name}
                                            key={location.id}
                                        >
                                            {location.name}
                                        </Option>
                                    ))}
                                </Select>


                            </Panel>
                            <Panel header="ASSETS" key="3">
                                <Select
                                    showSearch
                                    placeholder="Select Assets"
                                    onSelect={this.setAssetFilters}
                                    value={this.state.listingFilters.assetFilter}
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props?.partnerAssets?.map((pAssets, key) => (
                                        <Option
                                            value={pAssets.id}
                                            label={pAssets.vehicleNumber}
                                            key={pAssets.id}
                                        >
                                            {pAssets.vehicleNumber}
                                        </Option>
                                    ))}
                                </Select>
                            </Panel>
                        </Collapse>

                    </Modal>



                    <Modal
                        title="Hand Over"
                        visible={this.state.handOverVisible}
                        onCancel={this.handleOverCancel}
                        footer={[
                            <Button key="back" onClick={this.handleOverCancel}>
                                Cancel
                            </Button>,
                            <Button loading={this.state.handOverLoading} disabled={this.state.handOverLoading} key="submit" type="primary" onClick={this.handleOverOk}>
                                Handover
                            </Button>,
                        ]}
                    >
                        <p>Are you sure you want to Hand over?</p>
                        {this.props.app?.configurations?.needHandoverPod == "true" ?
                            <Form.Item
                                className="form-item-row"
                                label="POD Images"
                                {...this.state.uploadPODImages}
                            >
                                <FileUpload
                                    fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                    directUpload={true}
                                    disableUploadButton={false}
                                    onUploadFinish={($events) => this.onUploadPODFinish($events)}
                                    path="trips"
                                    acceptTypes={this.state.acceptTypes}
                                    objKey="trips"
                                />

                            </Form.Item> : <></>
                        }



                        <br />
                        {this.props.app?.configurations?.needHandoverSignature == "true" ? <Form.Item
                            className="form-item-row"
                            label="Signature Images"
                            {...this.state.signatureImage}
                        >
                            <FileUpload
                                fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                directUpload={true}
                                disableUploadButton={false}
                                onUploadFinish={($events) => this.onUploadSignFinish($events)}
                                path="trips"
                                acceptTypes={this.state.acceptTypes}
                                objKey="trips"
                            />

                        </Form.Item> : <></>}





                    </Modal>

                    <Modal
                        title="Hand Over"
                        visible={this.state.clHandOverModal}
                        onCancel={this.clHandleOverCancel}
                        width={1000}

                        footer={[
                            <Button key="back" onClick={this.clHandleOverCancel}>
                                Cancel
                            </Button>,
                            <Button loading={this.state.startTripLoading} disabled={this.state.startTripLoading} key="submit" type="primary" onClick={this.clHandOverOk}>
                                Handover
                            </Button>,
                        ]}
                    >
                        <Descriptions layout="vertical" size="small" colon={false} column={{ xs: 24, sm: 16, md: 6 }}>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Total Dockets</small>}>
                                <strong>{this.state.clHandOverData.totalDockets}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Selected Dockets</small>}>
                                <strong>{this.state.clHandOverData.selectedDockets}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small"><>Quantity / Shipment Counts / <br />Total chargeable wt.</></small>}>
                                <strong>{this.state.clHandOverData.totalQuantity} Pcs ({this.state.clHandOverData.totalWeight} Kgs)</strong>
                            </Descriptions.Item>

                        </Descriptions>

                        <br />
                        {
                            this.state.clHandOverModal ?
                                <>
                                    <Table
                                        pagination={false}
                                        bordered
                                        columns={this.state.clHandOverData.columns}
                                        dataSource={this.state.clHandOverData.data}
                                        rowSelection={this.state.clHandOverData.rowSelection}
                                    />
                                </> : <></>

                        }



                    </Modal>



                    <Modal
                        title={<>
                            {`Mark Delivery : Docket No - ${this.state.docketNo}`}
                            {
                                this.state?.tripToPayBO?.isToPay && <span style={{ marginLeft: 5 }}> <TripSummaryModal title="Docket Summary" data={this.state?.tripToPayBO?.toPayConsignmentList?.filter(item => item.waybillNo === this.state.docketNo)} /> </span>
                            }
                        </>}
                        visible={this.state.markDeliveryVisible}
                        onCancel={this.markDeliverCancel}
                        width={400}
                        footer={[
                            <Button key="back" onClick={this.markDeliverCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" loading={this.state.markDelLoading} disabled={this.state.markDelLoading} type="primary" onClick={this.markDeliverOk}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {/* <span>DEPS <br />(D - Damages; E-Excess; P - Pilferage; S - Shortage)</span> */}
                                <Form.Item
                                    className="form-item-row"
                                    label={<>DEPS (D - Damages; E-Excess; P - Pilferage; S - Shortage)</>}
                                >
                                    <Switch checked={this.state.deps} onChange={this.onChangeDeps} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>


                                <Form.Item
                                    className="form-item-row"
                                    label="Delivery Date and Time"
                                    {...this.state.deliveryDateTime}
                                >
                                    <DatePicker value={this.state.markDelFilters.podCreatedDate} showTime style={{ width: '100%' }} onChange={this.onChangePod} />
                                </Form.Item>

                            </Col>
                            {
                                this.state?.tripToPayBO?.isToPay && <Col span={24}>
                                    <Form.Item
                                        className="form-item-row"
                                        label="To Pay Amount Collected"
                                        {...this.state.deliveryDateTime}
                                    >
                                        <InputNumber value={this.state.toPayAmtColledted} onChange={val => this.onTopayAmtChange(val)} style={{ width: '100%' }} />
                                        {!!this.state.toPayAmtColledtedError && <small style={{ color: 'red' }}> {this.state.toPayAmtColledtedError}  </small>}
                                    </Form.Item>

                                </Col>
                            }
                            {this.state?.shipmentScan &&

                                <Col span={24}>
                                    <div onClick={() => this.setState({ barcodeModal: true })} style={{ cursor: 'pointer' }}><b>Scan bags to recieve</b><span style={{ float: 'right' }}>{this.state?.scannedBarCodes?.length} / {this.state?.shipmentBarCodes?.length} <Icon type="scan" /></span></div>
                                </Col>
                            }
                            <Col span={24}>
                                <span className="form-label-text">POD Images</span>
                                <FileUpload
                                    fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                    directUpload={true}
                                    disableUploadButton={false}
                                    onUploadFinish={($events) => this.onUploadFinish($events)}
                                    path="trips"
                                    acceptTypes={this.state.acceptTypes}
                                    objKey="trips"
                                    clearFileAfterUpload={this.state.clearFileMarkDelivery}
                                />
                            </Col>
                        </Row>
                        {this.state?.markdelError &&
                            <Alert message={this.state?.markdelError} type={"error"} />
                        }

                        {this.props.markDelData && this.props.markDelData.length > 0 ?
                            this.props.markDelData.map(res => {
                                return <><br /><Alert message={res.reason} type={res.sync ? "success" : "error"} /></>
                            }) : <></>
                        }
                    </Modal>
                    <Modal
                        title={`Docket No - ${this.state.docketNo}`}
                        visible={this.state.barcodeModal}
                        maskClosable={false}
                        closable={false}
                        width={400}
                        footer={[
                            <Button key="submitmark" onClick={() => this.setState({ barcodeModal: false })} type="primary" >
                                OK
                            </Button>,
                        ]}
                    >
                        <Row gutter={4}>
                            <Col span={24}>
                                <Input
                                    onChange={this.changeBarcode}
                                    value={this.state.barcode}
                                    onPressEnter={this.submitBarcode}
                                    suffix={<Icon type="scan" />}
                                    style={{ width: '100%' }}
                                />
                                <p>Use Barcode Scanner for faster process or enter manually</p>
                            </Col>
                            <Col span={24}>
                                <div>
                                    <span>Recent Scans</span>
                                    <span style={{ float: 'right' }}>{this.state?.scannedBarCodes?.length} / {this.state?.shipmentBarCodes?.length}</span>
                                </div>
                            </Col>
                            {this.state?.scannedBarCodes?.length > 0 &&
                                <Col span={24}>
                                    <Table
                                        pagination={false}
                                        columns={this.state.shipmentTableColumns}
                                        dataSource={this.state?.shipmentScans}
                                    />
                                </Col>
                            }
                        </Row>

                    </Modal>

                    <Modal
                        title={`Update Status : Trip ID - ${this.state.tripid}`}
                        visible={this.state.updateStatusVisible}
                        onCancel={this.updateStatusCancel}
                        width={400}
                        footer={[
                            <Button key="back" onClick={this.updateStatusCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" loading={this.state.updateStatusLoading} disabled={this.state.updateStatusLoading} type="primary" onClick={this.updateStatusOk}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item-row"
                                    label="Last Known Location"
                                    {...this.state.lastKnownLocationError}
                                >
                                    <GooglePlaceAutoComplete
                                        addressMode={true}
                                        onSelection={(pincode, address) => this.setState({ dataLocation: address })}
                                        onChange={value => this.setState({ dataLocation: value })}
                                        selectedAddress={this.state.dataLocation}
                                    ></GooglePlaceAutoComplete>
                                </Form.Item>


                            </Col>
                            <Col span={24}>

                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item-row"
                                    label="Update Time"
                                    {...this.state.updateTimeError}
                                >
                                    <DatePicker disabledDate={this.disabledDate} style={{ width: '100%' }} showTime placeholder="Select Time" onChange={this.onUpdateDateTimeOk} value={this.state.updatedTime} />
                                </Form.Item>


                            </Col>
                            <Col span={24}>
                                <span className="form-label-text">Next Location</span>
                                <Input disabled value={this.state.nextLocation} />

                            </Col>
                            <Col span={24}>

                                <Form.Item
                                    className="form-item-row"
                                    label="Delay Hrs"
                                    {...this.state.delayHoursError}
                                >
                                    <InputNumber style={{ width: '100%' }} onChange={this.setDelayHours} value={this.state.delayHours} suffix={"Hrs"} />
                                </Form.Item>

                            </Col>
                            <Col span={24}>

                                <Form.Item
                                    className="form-item-row"
                                    label="Delay Reason"
                                    {...this.state.delayReasonError}
                                >
                                    <Select labelInValue style={{ width: '100%' }} placeholder="Enter Reason" onSelect={this.setDelayReason} value={this.state.setDelayReason}>
                                        {
                                            this.props.app.configurations?.reasons.ETA && this.props.app.configurations?.reasons.ETA.length > 0 &&
                                            this.props?.app.configurations?.reasons.ETA.map(reason => (
                                                <Option
                                                    value={reason.reasonId}
                                                    key={reason.reasonId}
                                                    label={reason.reasonDescription}
                                                >
                                                    {reason.reasonDescription}
                                                </Option>
                                            ))
                                        }
                                    </Select>

                                </Form.Item>

                            </Col>
                        </Row>
                        <br />
                        <Row gutter={[0, 4]}>
                            {this.state.pendingTrips.length > 0 && this.state.pendingTrips.map(ptrips => {

                                return (
                                    <>
                                        <Col span={2} style={{ marginTop: '4rem' }}>
                                            <Checkbox onChange={(e) => this.onChangeUpdateStatus(ptrips.id, e)} defaultChecked checked={ptrips.checked} />
                                        </Col>
                                        <Col span={22}>

                                            <Card style={{ width: 300 }}>
                                                <h5>{ptrips.tripId} -  {_.hasIn(ptrips, 'locationBO') ? `(${ptrips.locationBO.name + ' ' + ptrips.locationBO.address.pincode.city.name + ')'}` : `(${ptrips.consignmentContactBO.name + ' ' + ptrips.consignmentContactBO.address.pincode.city.name + ')'}`}</h5>
                                                <small>VIA - {ptrips.partnerName}</small><br />
                                                <small>Current Est Arrival : </small><b>{moment(ptrips.actualEta).format("DD MMM YYYY hh:MM")}</b><br />
                                                <small>Newest Arrival : </small><b>{(ptrips.actualEta === ptrips.expectedEta && !ptrips.checked) ? <DatePicker value={this.state.expectedEta} style={{ width: '50%' }} showTime onChange={(date, dateString) => { this.changeUpdateTime(date, ptrips) }} /> : moment(ptrips.expectedEta).format("DD MMM YYYY hh:MM")}</b>
                                            </Card>
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </Modal>
                    <Modal
                        title={`Start Trip`}
                        visible={this.state.startTripVisible}
                        onCancel={this.startTripCancel}
                        width={400}
                        footer={[
                            <Button key="back" onClick={this.startTripCancel}>
                                Cancel
                            </Button>,
                            <Button loading={this.state.startTripLoading} key="submit" type="primary" onClick={this.startTripOk}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        {!this.state?.startTripAttributesPormpt ?
                            <p>Please Use APP to Start the trip</p>
                            :
                            <Row>
                                <Col span={24}>
                                    {/* <span className="form-label-text">Meter Reading</span> */}
                                    {/* <InputNumber onChange={this.onChangeKMS} style={{ width: '100%' }} /> */}
                                    <Form.Item
                                        className="form-item-row"
                                        label="Meter Reading"
                                        {...this.state.startMeterReading}
                                    >
                                        <InputNumber style={{ width: '100%' }} value={this.state.startKms} onChange={this.onChangeKMS} />
                                    </Form.Item>
                                </Col>
                                {this.state.tripFTLType ?
                                    <>
                                        <Col span={24}>
                                            <Form.Item
                                                className="form-item-row"
                                                label="Meter Reading Upload Proof"
                                                {...this.state.uploadMeterProofError}
                                            >

                                                <FileUpload
                                                    fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                                    directUpload={true}
                                                    disableUploadButton={true}
                                                    onUploadFinish={$events => this.onUploadTripFinish($events, 'truckPhotoTripStart')}
                                                    path="trips"
                                                    acceptTypes={this.state.acceptTypes}
                                                    objKey="trips"
                                                    multiple={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {_.hasIn(this?.props?.rateCard, 'revenue') &&
                                            <>
                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Trip Revenue"
                                                        {...this.state.startFTLRevenue}
                                                    >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.tripRevenue} onChange={this.onChangeRevenue} />
                                                    </Form.Item></Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Trip Revenue Upload Proof"
                                                        {...this.state.uploadFTLRevenueProofError}
                                                    >

                                                        <FileUpload
                                                            fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                                            directUpload={true}
                                                            disableAll={false}
                                                            disableUploadButton={true}
                                                            onUploadFinish={($events) => this.onUploadTripFinish($events, 'tripRevenueImage')}
                                                            path="trips"
                                                            acceptTypes={this.state.acceptTypes}
                                                            objKey="trips"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>}
                                        {_.hasIn(this?.props?.rateCard, 'cost') &&
                                            <>
                                                <Col span={24}>

                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Trip Cost"
                                                        {...this.state.startTripCost}
                                                    >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.tripCost} onChange={this.onChangeCost} />
                                                    </Form.Item></Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Trip Cost Upload Proof"
                                                        {...this.state.uploadTripCostProofError}
                                                    >

                                                        <FileUpload
                                                            fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                                            directUpload={true}
                                                            disableAll={false}
                                                            disableUploadButton={true}
                                                            onUploadFinish={($events) => this.onUploadTripFinish($events, 'tripCostImage')}
                                                            path="trips"
                                                            acceptTypes={this.state.acceptTypes}
                                                            objKey="trips"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>}
                                        {_.hasIn(this?.props?.rateCard, 'vendorCharges') &&
                                            <>
                                                <Col span={24}>

                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Trip Advance Amount"
                                                        {...this.state.startFTLAdvance}
                                                    >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.advanceAmountPaid} onChange={this.onChangeAdvance} />
                                                    </Form.Item></Col>
                                            </>}
                                    </>

                                    : <></>
                                }
                                {this.state?.startTripAdhoc ?
                                    <>
                                        <Col span={24}>
                                            <Form.Item
                                                className="form-item-row"
                                                label="Trip Cost"
                                                {...this.state.startTripCost}
                                            >
                                                <Input value={this.state.tripCost} onChange={this.onChangeTripCost} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>

                                            <Form.Item
                                                className="form-item-row"
                                                label="Advance Amount Paid"
                                                {...this.state.advanceAmountPaidError}
                                            >
                                                <InputNumber style={{ width: '100%' }} value={this.state.advanceAmountPaid} onChange={this.onChangeAmountPaid} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                    :
                                    <></>}
                                {this.props.app.configurations.enable_trip_start_photo == "true" ? <Col span={24}>


                                    <Form.Item
                                        className="form-item-row"
                                        label="Upload Proof"
                                        {...this.state.uploadProofError}
                                    >

                                        <FileUpload
                                            fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                            directUpload={true}
                                            disableAll={true}
                                            disableUploadButton={false}
                                            onUploadFinish={($events) => this.onUploadTripFinish($events, 'truckPhoto')}
                                            path="trips"
                                            acceptTypes={this.state.acceptTypes}
                                            objKey="trips"
                                        />



                                    </Form.Item>
                                    <p className="trip-truck-photo">Please use the Atlas lite app for truck photo and start the trip</p>
                                </Col> : <></>}

                            </Row>

                        }

                    </Modal>

                    <Modal
                        title={<>End Trip - {this?.state?.endTripFilters?.tripId} {this.state?.undeliveredCount > 0 ? <span style={{ float: 'right', marginRight: 15 }}>Undelivered {this.state?.undeliveredCount} / {this.state?.undeliveredTotal}</span> : <></>}</>}
                        visible={this.state.completeTripVisible}
                        onCancel={this.EndTripCancel}
                        style={{ top: 20 }}
                        width={600}
                        footer={[
                            <Button key="back" onClick={this.EndTripCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" loading={this.state.EndTripLoading} type="primary" onClick={this.EndTripOk}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={24}>

                                <Form.Item
                                    label="Reading KMs"
                                    {...this.state.endTripKMS}
                                >
                                    <InputNumber style={{ width: '100%' }} value={this.state.endKMValue} onChange={this.onChangeEndKMS} />
                                </Form.Item>
                            </Col>
                            {this.state.showEndTripKMSPhoto &&
                                <Col span={24}>

                                    <Form.Item
                                        label="End Trip KMS Photo"
                                        {...this.state.endTripKMSImage}
                                    >
                                        <FileUpload
                                            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                            directUpload={true}
                                            disableUploadButton={false}
                                            onUploadFinish={($events) => this.onUploadEndTripKMSImage($events)}
                                            path="trips"
                                            acceptTypes={this.state.acceptTypes}
                                            objKey="trips"
                                        />

                                    </Form.Item>
                                </Col>
                            }
                            <Col span={24}>

                                <Form.Item
                                    label="Actual Trip Completion Date & time"
                                    {...this.state.actualTripDateEndTime}
                                >
                                    <DatePicker
                                        showTime
                                        style={{ width: '100%' }}
                                        value={this.state.actualTripEndTime}
                                        disabledDate={this.disableActualDate}
                                        onChange={this.onChangeEndDateTime}
                                    />
                                </Form.Item>
                            </Col>
                            {/* Removed Cost for verification */}
                            {/* {
                                this.state?.showTripEndCost &&
                                <>
                                    <Col span={24}>

                                        <Form.Item
                                            label="Trip Cost"
                                            {...this.state.endTripCost}
                                        >
                                            <Input addonAfter={<Icon type={'edit'} onClick={this.enableCost} />} disabled={this.state.tripEndCostDisabled} style={{ width: '100%' }} value={this.state.tripEndCost} onChange={this.onChangeEndTripCost} />
                                        </Form.Item>
                                    </Col>
                                    {!this.state.tripEndCostDisabled &&
                                        <Col span={24}>

                                            <Form.Item
                                                label="Trip Cost Image"
                                                {...this.state.endTripCostImage}
                                            >
                                                <FileUpload
                                                    fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                                    directUpload={true}
                                                    disableUploadButton={false}
                                                    onUploadFinish={($events) => this.onUploadEndTripCostImage($events)}
                                                    path="trips"
                                                    acceptTypes={this.state.acceptTypes}
                                                    objKey="trips"
                                                />

                                            </Form.Item>

                                            <div>
                                                <a target={"_blank"} href={this.state?.tripEndImage}>{this.state?.tripEndImage}</a>
                                            </div>
                                        </Col>
                                    }
                                </>
                            } */}

                            {/* End Here */}

                            {/* Removed Cost for Verification */}

                            {/* {this.state.tripFTLType ?
                                <>
                                    <>
                                        {_.hasIn(this?.props?.rateCard, 'customerCharges') &&
                                            <>
                                                <b>Customer Charges</b>
                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Loading/Unloading Charges"
                                                        {...this.state.endTripCustomerLoad}
                                                    >
                                                        <InputNumber value={this.state.endtripCustomerCharges} style={{ width: '100%' }} onChange={this.onChangeCustomerLoad} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Demurrage Charges"
                                                        {...this.state.endTripCustomerDemrruage}
                                                    >
                                                        <InputNumber value={this.state.endtripCustomerDemurrageCharges} style={{ width: '100%' }} onChange={this.onChangeCustomerDemurrage} />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        }
                                    </>
                                    <>
                                        {_.hasIn(this?.props?.rateCard, 'vendorCharges') &&
                                            <>
                                                <Col span={24}>
                                                    <Divider />
                                                    <b>Vendor Charges</b>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form-item-row"
                                                        label="Loading/Unloading Charges"
                                                        {...this.state.endTripVendorLoad}
                                                    >
                                                        <InputNumber value={this.state.endtripVendorCharges} style={{ width: '100%' }} onChange={this.onChangeVendorLoad} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        className="form- item-row"
                                                        label="Demurrage Charges"
                                                        {...this.state.endTripVendorDemrruage}
                                                    >
                                                        <InputNumber value={this.state.endtripVendorDemurrageCharges} style={{ width: '100%' }} onChange={this.onChangeVendorDemurrage} />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        }
                                    </>
                                </>

                                : <></>
                            } */}

                            {/* End here */}

                            {/* Removed for trip verification */}
                            {/* {!this.state.tripFTLType ?
                                <>

                                    <Col span={24} offset={1}>
                                        {this.state.newAdhocCharges.map((charges, index) => (
                                            <div key={index}>
                                                <Row gutter={[6, 6]}>
                                                    <div><b>{charges.chargeLabel}:</b></div>
                                                    <Col span={10}>
                                                        <Input placeholder={charges.chargeLabel} onChange={(e) => this.onChangeNewCharge(e, index, charges.chargeLabel)} />

                                                    </Col>
                                                    <Col span={10}>
                                                        <Select style={{ width: '100%' }} defaultValue={"Customer"} onSelect={(value) => this.newCustomerVendor(value, index, charges.chargeLabel)}>
                                                            <Option value={'Customer'}>
                                                                Customer
                                                            </Option>
                                                            <Option value={'Vendor'}>
                                                                Vendor
                                                            </Option>
                                                        </Select>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Button onClick={() => this.remove(index)}><Icon type="minus" /></Button>
                                                    </Col>
                                                </Row>
                                                <br />
                                            </div>
                                        ))}
                                    </Col>



                                    <Col span={12} offset={8}>
                                        <Select
                                            style={{ width: '50%' }}
                                            placeholder="Add Charges"
                                            onSelect={this.add}

                                        >
                                            {
                                                this.state.adhocCharges.map(adhoc => {
                                                    return (
                                                        <Option value={adhoc.value} key={adhoc.id}>{adhoc.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Col>


                                   
                                </>


                                : <></>
                                } */}

                            {/* End Here */}
                        </Row>

                        {this.props.endTripData?.response?.updated ?
                            <><br /><Alert message="Trip Ended Successfully" type="success" /> </> : <></>
                        }
                    </Modal>
                    <Modal
                        title={"Exit"}
                        visible={this.state.cancelVisible}
                        onCancel={this.endCancelModal}
                        footer={[
                            <Button key="back" onClick={this.endCancelModal}>
                                No
                            </Button>,
                            <Button key="submit" type="danger" onClick={this.okCancelModal}>
                                Cancel Trip
                            </Button>,
                        ]}
                    >
                        <p>Are you sure you want to cancel the trip?</p>

                    </Modal>
                    <Modal
                        title={"Update Pickup"}
                        visible={this.state.updatePickup}
                        onCancel={this.endUpdatePickup}
                        footer={[
                            <Button key="submitPickup" disabled={this.state.pickupButton} type="primary" onClick={this.okUpdatePickup}>
                                COMPLETE PICKUP
                            </Button>
                        ]}
                    >
                        <Form.Item
                            className="form-item-row"
                            label="No of Pcs"
                        >
                            <InputNumber
                                type="text"
                                style={{ width: '80%' }}
                                placeholder="Count already set"
                                value={this.state.pickupCount}
                                disabled={this.state.disablePickupCount}
                                onChange={this.updatePickupCount}
                            />
                            {!this.state.editCountClicked &&
                                <Icon type="edit" onClick={() => this.setState({ disablePickupCount: false, disablePickupWeight: false, pickupButton: true, editCountClicked: true })} />
                            }
                        </Form.Item>
                        <Form.Item
                            className="form-item-row"
                            label="Total Weight"
                        >
                            <InputNumber
                                type="text"
                                style={{ width: '80%' }}
                                placeholder="Weight already set"
                                value={this.state.pickupWeight}
                                disabled={this.state.disablePickupWeight}
                                onChange={this.updatePickupWeight}
                            />
                            {this.state.editCountClicked === false && this.state.editWeightClicked === false &&
                                <Icon type="edit" onClick={() => this.setState({ disablePickupWeight: false, pickupButton: true, editWeightClicked: true })} />
                            }
                        </Form.Item>

                    </Modal>
                    <Modal
                        title={"Consoidate"}
                        visible={this.state.consolidateModal}
                        onCancel={this.cancelConsolidate}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.cancelConsolidate}>
                                OK
                            </Button>,
                        ]}
                    >
                        <Spin spinning={this.props.consolidateLoading}>
                            <p>
                                E-Waybill(Consolidated) Part-B Printable Copies for Trip  {this.state.consolidateTripID}
                            </p>
                            <div>
                                List Of E-Waybills included in consolidated Part-B:
                            {this.state.ewayBills.map(eway => {
                                return (
                                    <div><b>{eway}</b></div>

                                )


                            })}
                            </div>
                            <br />
                            <div>
                                Link:
                        </div>
                            <p>{this.props.consolidateData ? <a target="_blank" href={`http://${this.props.consolidateData?.consolidatedEwaybillUrl}`}>{this.props.consolidateData?.consolidatedEwaybillUrl}</a> : this.props.consolidateError ? this.props.consolidateError : null}</p>
                            <p>Please use Print E-Waybill for Part-B Printable Copies of E-waybills not included in consolidated against the trip</p>
                        </Spin>
                    </Modal>
                    <Modal
                        title={"EwayBill"}
                        visible={this.state.showEwayModal}
                        onCancel={this.cancelEwayModal}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.cancelEwayModal}>
                                OK
                            </Button>,
                        ]}
                    >
                        <p>
                            {/* {this.state.waybiltrip} */}
                            {this.state.ewayData && this.state.ewayData.thcbos ? this.state.ewayData.thcbos.map(thc => {
                                return thc.manifests.map(manifest => {
                                    return manifest.consignments.map(consign => {
                                        return consign.ewaybillBOs && consign.ewaybillBOs.length > 0 && consign.ewaybillBOs.map(eway => {

                                            // return (
                                            //     <h3>Hello</h3>
                                            // )
                                            return (
                                                <ul>
                                                    <li>Ewaybill No: {eway.ewaybillNo} -
                                                    {
                                                            eway.validUpto ?
                                                                <>
                                                                    {eway.validUpto && moment(eway.validUpto) < moment() ? ' Expired on: ' : ' Valid Upto: '}
                                                                    {moment(eway.validUpto).format("DD-MMM-YYYY H:mm")}
                                                                </>
                                                                : <> Validity Date not available</>

                                                        }</li>
                                                </ul>
                                            )
                                        })
                                    })
                                })
                            }) : <></>
                            }
                            {this.state.ewayData && this.state.ewayData.drsbos ? this.state.ewayData.drsbos.map(thc => {
                                return thc.consignments.map(consign => {
                                    return consign.ewaybillBOs && consign.ewaybillBOs.length > 0 && consign.ewaybillBOs.map(eway => {

                                        // return (
                                        //     <h3>Hello</h3>
                                        // )
                                        return (
                                            <ul>
                                                <li>Ewaybill No: {eway.ewaybillNo} -
                                                {
                                                        eway.validUpto ?
                                                            <>
                                                                {eway.validUpto && moment(eway.validUpto) < moment() ? ' Expired on: ' : ' Valid Upto: '}
                                                                {moment(eway.validUpto).format("DD-MMM-YYYY HH:mm")}
                                                            </>
                                                            : <> Validity Date not available</>

                                                    }</li>
                                            </ul>
                                        )
                                    })
                                })
                            }) : <></>
                            }

                            {/* {this.state.ewayData && this.state.ewayData.consignments ? 
                                 this.state.ewayData.consignments.map(consign => {
                                console.log("consign->>", consign)
                                console.log("this.state.ewayData->>", this.state.ewayData)
                                return consign.ewaybillBOs && consign.ewaybillBOs.length > 0 && consign.ewaybillBOs.map(eway => {
                                    console.log("eway->>", eway)
                                    // return (
                                    //     <h3>Hello</h3>
                                    // )
                                    return (
                                        <ul>
                                            <li>Ewaybill No: {eway.ewaybillNo}</li>
                                            <li>{moment(eway.validUpto) < moment() ? 'Expired on: ' : 'Valid Upto: '}
                                                {moment(eway.validUpto).format("DD-MMM-YYYY hh:MM")}</li>
                                        </ul>
                                    )
                                })
                            }):<></>

                            } */}

                            {/* {this.state.ewayBillsData && this.state.ewayBillsData.length > 0 ? this.state.ewayBillsData.map(eway => (
                                    <li>
                                        <li>Ewaybill No: {eway.ewaybillNo}</li>
                                        <li>{moment(eway.validUpto) < moment() ? 'Expired on: ' : 'Valid Upto: '}
                                            {moment(eway.validUpto).format("DD-MMM-YYYY hh:MM")}</li>
                                    </li>
                                )) : <></>} */}

                        </p>

                    </Modal>

                    <Modal
                        title={"EwayBill"}
                        visible={this.state.showEwayBillsModal}
                        onCancel={this.cancelEwayBillsModal}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.cancelEwayBillsModal}>
                                OK
                            </Button>,
                        ]}
                    >
                        <p>
                            <ul>
                                {this.state.ewayBillsData && this.state.ewayBillsData.length > 0 ? this.state.ewayBillsData.map(eway => (
                                    <li>
                                        <li>Ewaybill No: {eway.ewaybillNo} -
                                        {
                                                eway.validUpto ?
                                                    <>
                                                        {eway.validUpto && moment(eway.validUpto) < moment() ? ' Expired on: ' : ' Valid Upto: '}
                                                        {moment(eway.validUpto).format("DD-MMM-YYYY HH:mm")}
                                                    </>
                                                    : <> Validity Date not available</>

                                            }


                                        </li>
                                        {/* <li>{!eway.validUpto ? ' Validity Date not available': <></>}</li> */}
                                    </li>
                                )) : <></>}
                            </ul>
                        </p>

                    </Modal>

                    <Modal
                        title={"Print EwayBill"}
                        visible={this.state.printModal}
                        onCancel={this.cancelPrint}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.cancelPrint}>
                                OK
                            </Button>,
                        ]}
                    >
                        <Spin spinning={this.props.printEwayLoading}>
                            <p>E-Waybill Part-B Printable Copies for Trip {this.state.printTripID}</p>
                            {/* <p>{this.props.printEwayData ? this.props.printEwayData : this.props.printEwayError ? this.props.printEwayError: null }</p> */}
                            {this.props.printEwayData && this.props.printEwayData.ewaybillPdfLinks.length > 0 ? this.props.printEwayData.ewaybillPdfLinks.map(eway => {
                                return (
                                    <>
                                        <h5>E-Waybill Part-B :{eway.ewaybillNo}:</h5>
                                        <a target="_blank" href={`http://${eway.pdfLink}`}>{eway.pdfLink}</a>
                                    </>
                                )
                            }) : <></>}
                        </Spin>
                    </Modal>

                    <Modal
                        title={`Edit Trip: Trip ID -  ${this.state.editTripData.id}`}
                        visible={this.state.editTripVisible}
                        // onCancel={this.editTripCancelModal}
                        closable={false}
                        width={790}
                        footer={[
                            <Button key="back" onClick={this.editTripCancelModal}>
                                Cancel
                            </Button>,
                            <Button loading={this.state.editTripUpdateLoding} key="submit" type="primary" onClick={this.editTripOkModal}>
                                Confirm
                            </Button>,
                        ]}
                    >
                        <div style={{ background: '#ebeff2', padding: '10px' }}>
                            <Row gutter={[8, 0]}>
                                <Col span={10}>
                                    <Card size="small">
                                        <div className="edit-trip-title-route">Route</div>
                                        <p><b>{this.state.editTripData?.routeName?.asset?.partner.name}</b></p>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card size="small">
                                        <div className="edit-trip-title-details">Total Dockets</div>
                                        <p>
                                            <b>{this.state.editManifestData && this.state.editManifestData.length > 0 ? `${this.state.editManifestData.length}` : `0`}</b>
                                        </p>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card size="small">
                                        <div className="edit-trip-title-details">Quantity</div>
                                        <p><b>
                                            {this.state.editManifestData && this.state.editManifestData.length > 0 && this.state.editManifestData.map(consign => {
                                                // length += manifest?.consignments?.length ? manifest.consignments.length : 0;

                                                totalPcs += consign.totalShipmentCount;
                                                totalWeight += consign.totalWeight;


                                            })}

                                            {`${totalPcs} Pcs (${totalWeight} Kgs)`}
                                        </b></p>
                                    </Card>
                                </Col>
                            </Row>




                        </div>
                        <br />

                        <h3>Vehicle Information</h3>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <span className="form-label-text field-required">Vehicle Number</span>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    value={{ "key": this.state.editTripData.asset?.id, "label": this.state.editTripData.asset?.vehicleNumber }}
                                    labelInValue
                                    onSelect={this.selectVehicle}
                                    placeholder="Enter Vehicle number"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props?.assets?.length && this.props?.assets?.map((asset, key) => (
                                        <Option
                                            value={asset.id}
                                            label={asset.vehicleNumber}
                                            key={asset.id}
                                        >
                                            {asset.vehicleNumber}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={12}>
                                <span className="form-label-text">Replacement Vehicle</span>
                                <br />
                                <Switch
                                    checked={this.state.replaceVehicleToggle}
                                    onChange={this.changeVehicleToggle}
                                />
                            </Col>

                        </Row>
                        <Row gutter={[16, 16]}>
                            {this.state.showToggle &&
                                <Col span={12}>
                                    <div style={{ fontSize: 12 }}><Icon type="info-circle" />Select the actual contracted vehicle in the below field for vendor biiling</div>
                                    <span className="form-label-text field-required">Billing Vehicle Number</span>
                                    <Select
                                        // loading={props.assetLoading}
                                        showSearch
                                        style={{ width: '100%' }}
                                        onSelect={this.selectBillingVehicle}
                                        optionLabelProp="label"
                                        value={this.state.selectedVehicleBillingModel}
                                        placeholder="Select Billing Vehicle"
                                        // onSearch={props.setVehicle}                                    
                                        filterOption={(input, option) => {
                                            return option?.props?.label.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }

                                        }
                                    >
                                        {this.state.editTripData?.attributes?.tripLoadType === 'FTL' ?
                                            this.state?.billingVehiclesList?.map((asset, key) => (
                                                <Option
                                                    value={asset.id}
                                                    label={asset.vehicleNumber}
                                                    key={asset.id}
                                                    disabled={asset.id === this.state.editTripData.asset.id}
                                                >


                                                    <div className="demo-option-label-item">

                                                        {asset.vehicleNumber} <span style={{ float: 'right' }}>{asset?.assetType === 'ADHOC_ASSET' ? <img src={MarketAsset} /> : <img src={NonMarketAsset} />} {asset?.isAvailable && <img src={VehicleAvailable} />} {_.hasIn(asset, 'simTrackingConsentStatus') ? asset?.simTrackingConsentStatus === "ALLOWED" ? <img src={SimAvailable} /> : <img src={SimUnAvailable} /> : <></>}</span>
                                                        <span role="vehicle" className="vehicle-gps" aria-label={asset.vehicleNumber}>
                                                            {_.hasIn(asset, 'deviceDetails') ? 'GPS Available' : <></>}
                                                        </span>

                                                    </div>
                                                </Option>
                                            )) :
                                            this.state?.billingVehiclesList?.map((asset, key) => (
                                                <Option
                                                    value={asset.id}
                                                    label={asset.vehicleNumber}
                                                    key={asset.id}
                                                    disabled={asset?.id === this.state?.editTripData?.asset?.id}
                                                >

                                                    <div className="demo-option-label-item">

                                                        {asset.vehicleNumber} <span style={{ float: 'right' }}>{asset?.assetType === 'ADHOC_ASSET' ? <img src={MarketAsset} /> : <img src={NonMarketAsset} />} {asset?.isAvailable && <img src={VehicleAvailable} />} {_.hasIn(asset, 'simTrackingConsentStatus') ? asset?.simTrackingConsentStatus === "ALLOWED" ? <img src={SimAvailable} /> : <img src={SimUnAvailable} /> : <></>}</span>
                                                        <span role="vehicle" className="vehicle-gps" aria-label={asset.vehicleNumber}>
                                                            {_.hasIn(asset, 'deviceDetails') ? 'GPS Available' : <></>}
                                                        </span>

                                                    </div>
                                                </Option>
                                            ))
                                        }

                                    </Select>
                                </Col>
                            }

                            <Col span={12}>
                                <span className="form-label-text field-required">Billed Model</span>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    value={this.state.selectedBillingModel}
                                    // onSelect={this.selectVendor}
                                    placeholder="Select Billing Model"
                                    onSelect={this.selectBillingModelCreate}
                                    filterOption={(input, option) => {
                                        // let data = input.toString();
                                        // console.log("input input", input, typeof input)
                                        return (
                                            option.props.children?.toString()?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                        )


                                    }

                                    }
                                >

                                    {this.state?.billingModel?.map((bModel, key) => (
                                        <Option
                                            value={bModel.id}
                                            label={bModel.vehicleType}
                                            key={bModel.id}
                                        >
                                            {bModel.vehicleType} - {bModel?.vehicleCapacity} {bModel?.vehicleCapacityUnit} - {bModel?.bodyType}
                                        </Option>
                                    ))
                                    }


                                    {/* <Option
                                        value={this.state?.editTripData?.asset?.vehicleTypesBO?.id}
                                        label={this.state?.editTripData?.asset?.vehicleTypesBO?.id}
                                        key={this.state?.editTripData?.asset?.vehicleTypesBO?.id}
                                    >
                                        {this.state?.editTripData?.asset?.vehicleTypesBO?.length} x {this.state?.editTripData?.asset?.vehicleTypesBO?.breadth} x {this.state?.editTripData?.asset?.vehicleTypesBO?.height}
                                    </Option> */}


                                </Select>
                            </Col>
                            <Col span={24}>
                                <span className="form-label-text field-required">GPS Device</span>
                                <div>
                                    <Radio.Group onChange={this.onChangeDevice} value={this.state.fields.gpsdevice.value}>
                                        <Radio value={"gps"}>Portable GPS</Radio>
                                        <Radio value={"sim"}>Sim Tracking</Radio>
                                        {this.state.sim_tracking_enable ?
                                            <Tooltip title="Sim Tracking Available">
                                                <Radio disabled value={"nodevice"}>No device</Radio>
                                            </Tooltip>

                                            :
                                            <Radio value={"nodevice"}>No device</Radio>
                                        }

                                    </Radio.Group>
                                </div>
                            </Col>
                            {this.state.fields.gpsdevice.value === 'gps' ?
                                <Col span={12}>
                                    <span className="form-label-text field-required">GPS ID</span>
                                    <Input
                                        placeholder="GPS ID"
                                        value={this.state.fields.gpsid.value}
                                        onChange={this.editImei}
                                    />
                                </Col>
                                : <></>
                            }
                            {this.state.fields.gpsdevice.value === 'nodevice' ?
                                <Col span={24}>
                                    <InputGroup>
                                        <Row gutter={2}>
                                            <Col span={10}>
                                                <span className="form-label-text field-required">Reason Category</span>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    labelInValue
                                                    value={this.state.fields.reasonCategory?.value}
                                                    // value={{
                                                    //     "key": this.state?.editTripData?.attributes?.noDeviceReasonCategoryId,
                                                    //     "label": this.state?.editTripData?.attributes?.noDeviceReasonCategoryName,
                                                    // }}
                                                    onSelect={this.onSelectReasonCategory}
                                                    placeholder="Select Reason Category"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >


                                                    {this.state?.reasonCategories?.map((vendor, key) => (
                                                        <Option
                                                            value={vendor.id}
                                                            label={vendor.name}
                                                            key={vendor.id}
                                                        >
                                                            {vendor.name}
                                                        </Option>
                                                    ))
                                                    }

                                                </Select>

                                            </Col>
                                            <Col span={12} offset={1}>
                                                <span className="form-label-text field-required">Reason</span>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    showSearch
                                                    labelInValue
                                                    value={this.state.fields.reason?.value}
                                                    onSelect={this.selectReason}
                                                    // value={{
                                                    //     "key": this.state?.editTripData?.attributes?.noDeviceResonId,
                                                    //     "label": this.state?.editTripData?.attributes?.noDeviceResonName,
                                                    // }}
                                                    placeholder="Select Reason Category"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >


                                                    {this.state?.reasons?.map((vendor, key) => (
                                                        <Option
                                                            value={vendor.reasonId}
                                                            label={vendor.reasonDescription}
                                                            key={vendor.reasonId}
                                                        >
                                                            {vendor.reasonDescription}
                                                        </Option>
                                                    ))
                                                    }

                                                </Select>
                                            </Col>
                                        </Row>
                                    </InputGroup>
                                    {/* <InputGroup>
                                        <Row gutter={2}>
                                            <Col span={8}>
                                                <span className="form-label-text field-required">Mobile number</span>
                                                <Input
                                                    placeholder="Enter Mobile number"
                                                    value={this.state.fields.mobilenumber.value}
                                                    onChange={this.mobilenumberValue}
                                                    readOnly
                                                />

                                            </Col>
                                            <Col span={12} offset={1}>
                                                <span className="form-label-text">Driver Name</span>
                                                <Input
                                                    placeholder="Enter Driver name"
                                                    value={this.state.fields.drivername.value}
                                                    onChange={this.driverNameValue}
                                                    readOnly
                                                />
                                            </Col>
                                        </Row>
                                    </InputGroup> */}
                                </Col>
                                : <></>
                            }

                            {
                                this.state.fields.gpsdevice.value === 'sim' ?
                                    <>
                                        {/* <Col span={12}>
                                            <span className="form-label-text">Driver Name</span>
                                            <Input
                                                placeholder="Driver Name"
                                                value={this.state.fields.drivername.value}
                                                onChange={this.driverNameValue}
                                                readOnly
                                            />
                                        </Col> */}
                                        <Col span={12}>
                                            <span className="form-label-text field-required">Service Providers</span>
                                            <Select
                                                showSearch
                                                value={this.state.fields.serviceprovider.value}
                                                style={{ width: 350 }}
                                                onSelect={this.selectServiceProvider}

                                                placeholder="Enter Service Provider"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.props.sproviders && this.props?.sproviders?.map((provider, key) => (
                                                    <Option
                                                        value={provider.providerName}
                                                        label={provider.providerName}
                                                        key={provider.id}
                                                    >
                                                        {provider.providerName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        {/* <Col span={12}>
                                            <span className="form-label-text">Mobile number</span>
                                            <Input
                                                placeholder="Mobile number"
                                                value={this.state.fields.mobilenumber.value}
                                                onChange={this.mobilenumberValue}
                                                readOnly
                                            />
                                        </Col> */}
                                        <Col span={12}>
                                            <span className="form-label-text field-required">Tracking number</span>
                                            <Input
                                                placeholder="Tracking number"
                                                value={this.state.fields.trackingnumber.value}
                                                onChange={this.trackingNumberValue}
                                            />
                                        </Col>
                                    </>
                                    : <></>
                            }



                        </Row>



                        <br />
                        <h3>Vendor Details</h3>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <span className="form-label-text field-required">Billed to Vendor</span>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    labelInValue
                                    // value={this.state?.editTripData?.attributes?.vendorId ?
                                    //     {
                                    //         "key": this.state.editTripData.attributes.vendorId,
                                    //         "label": this.state.editTripData.attributes.vendorName
                                    //     } :
                                    //     this.state.fields.vendor.value ? { "key": this.state.fields.vendor.value.key, "label": this.state.fields.vendor.value.label } : undefined}

                                    value={
                                        this.state.fields.vendor.value ? { "key": this.state.fields.vendor.value.key, "label": this.state.fields.vendor.value.label }
                                            : this.state?.editTripData?.attributes?.vendorId ? {
                                                "key": Number(this.state.editTripData.attributes.vendorId),
                                                "label": this.state.editTripData.attributes.vendorName
                                            } : undefined
                                    }
                                    onSelect={this.selectVendor}
                                    placeholder="Select Vendor"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option
                                        value={-1}
                                        label={"Self"}
                                        key={-1}
                                    >
                                        Self
                                    </Option>
                                    {this.props?.vendorData?.map((vendor, key) => (
                                        <Option
                                            value={vendor.id}
                                            label={vendor.name}
                                            key={vendor.id}
                                        >
                                            {vendor.name}
                                        </Option>
                                    ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        {
                            !_.isEmpty(this.state.editTripData) && this.renderEditDockets()
                        }



                    </Modal>
                    <Modal
                        title={`Manifest Sheet`}
                        visible={this.state.showManifestModal}
                        onCancel={this.okManifestModal}
                        width={400}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.okManifestModal}>
                                OK
                            </Button>,
                        ]}
                    >
                        <Row>
                            <Col>
                                <p>Download Link</p> <a href={this.state.downloadLink} target="_blank">{this.state.downloadLink}</a>
                            </Col>
                        </Row>

                    </Modal>

                    <Modal
                        title={`Payment History - ${this.state.selectedTripId}`}
                        visible={this.state.showTripSettlement}
                        onCancel={() => { this.toggleTripSettlememtModal(false) }}
                        width={1000}
                        footer={[
                            <Button key="submit" type="primary" onClick={() => { this.toggleTripSettlememtModal(false) }}>
                                CLOSE
                            </Button>,
                        ]}
                    >
                        <Spin spinning={this.props?.tripSettlements?.tripSettlementLoding}>
                            <Table
                                bordered
                                columns={this.state.tripSettlementColumns}
                                dataSource={this.props.tripSettlements?.tripSettlementsBO}
                                pagination={false}
                            />
                        </Spin>

                    </Modal>

                    <Modal
                        title={`Docket - ${this.state?.undelFilter?.docket?.waybillNo}`}
                        visible={this.state.undelFilter?.undelVisble}
                        closable={false}
                        width={400}
                        footer={[
                            <Button key="cancelUndel" type="danger" onClick={this.cancelUndelModal}>
                                Close
                            </Button>,
                            <Button key="okUndel" loading={this.state?.undelFilter?.loading} type="primary" onClick={this.okUndelModal}>
                                Submit
                            </Button>
                        ]}

                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    labelInValue
                                    placeholder="Select Reason Category"
                                    value={this.state.reasonCategoryUndelReturn}
                                    onSelect={this.selectReasonCategoryUndelReturn}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >


                                    {this.state.reasonCategories?.map((vendor, key) => (
                                        <Select.Option
                                            value={vendor.id}
                                            label={vendor.name}
                                            key={vendor.id}
                                        >
                                            {vendor.name}
                                        </Select.Option>
                                    ))
                                    }

                                </Select>
                            </Col>
                            <Col span={24}>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    labelInValue
                                    value={this.state.reasonUndelReturn}
                                    placeholder="Select Reason"
                                    onSelect={this.selectReasonUndelReturn}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >


                                    {this.state?.reasons?.map((vendor, key) => (
                                        <Select.Option
                                            value={vendor.reasonId}
                                            label={vendor.reasonDescription}
                                            key={vendor.reasonId}
                                        >
                                            {vendor.reasonDescription}
                                        </Select.Option>
                                    ))
                                    }

                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            {this.state?.undelFilter?.error &&
                                <Tag color="red">{this.state?.undelFilter?.error}</Tag>
                            }
                        </Row>

                    </Modal>
                </Card>

            </>
        )
    }
}

export default TripList;