import React, { useEffect, useState, useRef } from "react";
import { Tabs, Card, Icon, Divider, Steps, Radio, Button, Modal, Tag } from "antd";
import { permissionTags } from "../../../configs/permissionTags";
import { canAllow, notifyApiError } from "../../../common/utils/utils";
import Customer from "./customer/index";
import Shipper from "./shipper/index";
import { Mixpanel } from "../../../utils/mixpanel";
import moment from "moment";
import { getCustomerData, fetchHistory } from "./common/services/index";
import _ from "lodash";

import Zone from "./zone/index";
import ContractsTab from "./contracts/contracts.tab";
import Locations from "./locations/index";
import ViewHistory from "./contarct-history";
import { Constants } from "../../../common/utils/constants";
import { s3Connections } from "../../../environments/node-react-environment";

const { TabPane } = Tabs;
const { Step } = Steps;
const { confirm } = Modal;

function CustomerDetails(props) {
  // if (!props.match?.params?.customerId) {
  //     props.history.push("/appv2/settings/dashboard/customer");
  //     return <></>;
  // }

  // const [action, setAction] = useState(props.location?.state?.action ? props.location?.state?.action : 'VIEW');

  const customerId = props.match?.params?.customerId;
  const getReadOnly = props.location?.state?.readOnly !== undefined
    ? props.location?.state?.readOnly
    : props.match?.params?.customerId
      ? true
      : false;
  const [readOnly, setReadOnly] = useState(getReadOnly);
  const [customerReadOnly, setCustomerReadOnly] = useState(getReadOnly);
  const [isCancel, setCancel] = useState(false);
  const customerRef = useRef();
  const zoneRef = useRef();
  const locationRef = useRef();

  let steps = [];
  let [currentStep, setCurrentStep] = useState(props.location?.state?.currentStep ? props.location?.state?.currentStep : 0);
  let name = props?.location?.state?.name;

  const [customerName, setCustomerName] = useState(name ? name : undefined);
  const [customer, setCustomer] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [isShipperSet, setShipper] = useState(false);
  const [isZoneSet, setZoneStepper] = useState(false);
  const [isLocationSet, setLocationStepper] = useState(false);
  const [isContractSet, setContractStepper] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const contractPermissions = {
    customer: canAllow(permissionTags.contract.customer.view),
    customer_update: canAllow(permissionTags.contract.customer.update),
    shippers: canAllow(permissionTags.contract.shippers.view),
    zones: canAllow(permissionTags.contract.zones.view),
    locations: canAllow(permissionTags.contract.locations.view),
    lanes: canAllow(permissionTags.contract.lanes.view),
    contractdetails: canAllow(permissionTags.contract.contractdetails.view)
  };
  if (!readOnly) {
    steps = [
      {
        title: "BASIC DETAILS",
        content: (
          <Customer
            readOnly={readOnly}
            customerId={customerId ? customerId : customer?.id}
            customerRef={customerRef}
            currentStep={currentStep}
            setCustomer={setCustomer}
            setCurrentStep={setCurrentStep}
            isCancel={isCancel}
            setCancel={setCancel}
          />
        )
      }
    ];

    if (contractPermissions.shippers) {
      steps.push({
        title: "SHIPPERS",
        content: (
          <Shipper
            readOnly={readOnly}
            customerId={customer?.id}
            customer={customer}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            openHelpLink={openHelpLink}
            setShipper={setShipper}
          />
        )
      })
    }
    if (contractPermissions.zones) {
      steps.push(
        {
          title: "ZONES",
          content: (
            <Zone
              zoneRef={zoneRef}
              readOnly={readOnly}
              customerId={customer?.id}
              customer={customer}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              openHelpLink={openHelpLink}
              setZoneStepper={setZoneStepper}
            />
          )
        }
      )
    }
    if (contractPermissions.locations) {
      steps.push(
        {
          title: "LOCATIONS",
          content: (
            <Locations
              locationRef={locationRef}
              customerId={customer?.id}
              customer={customer}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              openHelpLink={openHelpLink}
              setLocationStepper={setLocationStepper}
            />
          )
        }
      )
    }
    if (contractPermissions?.contractdetails) {
      steps.push(
        {
          title: "CONTRACTS",
          content: <ContractsTab
            customerId={customer?.id}
            setRefresh={setRefresh}
            refresh={refresh}
            customer={customer}
            readOnly={readOnly}
            openHelpLink={openHelpLink}
            setContractStepper={setContractStepper}
            backFromDetails={props?.location?.state?.backFromDetails}
            createContract={() => {
              props.history.push(
                `/appv2/settings/dashboard/customer/contracts/${customer?.id}`,
                { customerId: customer?.id, view: false, readOnly, customer: customer }
              );
            }} />
        }
      )
    }
  }

  // Need to revisit the logic from here -> 2 API calls are happening one here and one customer
  useEffect(() => {
    if (customerId) {
      // if (!customerName || _.isEmpty(customer)) {
      callCustomerAPI(customerId).then(data => {

        setCustomer(data[0]);
        if (!customerName || _.isEmpty(customer)) {
          name = data[0].name;
          setCustomerName(name);
        }
      })
      .catch(_ => {
        console.log('test dd');
        notifyApiError("Customer not found!")
        props.history.push("/appv2/settings/dashboard/customer");
      });
      // }
    }
  }, [refresh])
  

  async function callCustomerAPI(customerId) {
    // const params = {
    //   customerId
    // };
    const payload = {
      "filters": {
        "customerTypes": [
          "CUSTOMER"
        ],
        "status": [
          "ACTIVE"
        ],
        "fetchMode": "BASIC",
        "partnerIds": [
          Constants.PARTNER_ID
        ],
        "ids": [customerId]
      }
    }
    return await getCustomerData(payload);
  }
  // Need to revisit the logic till here
  // const { customerId, name } = props?.location?.state;
  function goBack() {
    props.history.push("/appv2/settings/dashboard/customer");
  }

  const defaultKey = props.match?.params?.page
    ? props.match?.params?.page
    : props?.location?.state?.defaultKey
      ? props?.location?.state?.defaultKey
      : "basic";
  const [key, setKey] = useState(defaultKey);
  const onTabSwitch = key => {

    props.history.push(
      `/appv2/settings/dashboard/customer/details/${customerId}/${key}`,
      { customerId, name: customerName, defaultKey: key, readOnly }
    );
    setKey(key);
    if (key === "shippers") {
      Mixpanel.track("Clicking Shippers tab", {
        CustomerContractShippersTabClicked: moment().valueOf()
      });
    } else if (key === "zones") {
      Mixpanel.track("Clicking Zones tab", {
        CustomerContractZonesTabClicked: moment().valueOf()
      });
    } else if (key === "location") {
      Mixpanel.track("Clicking Location tab", {
        CustomerContractLocationTabClicked: moment().valueOf()
      });
    } else if (key === "contract") {
      Mixpanel.track("Clicking Contracts tab", {
        CustomerContractsTabClicked: moment().valueOf()
      });
    }
  };

  const clearFilters = () => {
    props.history.push("/appv2/settings/dashboard/customer/contract", {
      customerId,
      name,
      defaultKey
    });
  };
  useEffect(() => {
    Mixpanel.track("Landing to Basic Info Tab", {
      ERPCustomerBasicInfoPage: moment().valueOf()
    });
    window.addEventListener("beforeunload", clearFilters);
    return () => {
      window.removeEventListener("beforeunload", clearFilters);
    };
  }, []);

  const next = () => {
    if (currentStep === 0) {
      customerRef.current.formReference.current.submit();
    } else {
      setCurrentStep(currentStep + 1);
    }

  };

  const prev = () => {
    currentStep -= 1;
    setCurrentStep(currentStep);
  };
  const customDot = (dot, { status, index }) => {
    return (
      <>
        {currentStep === index ? (
          <Radio checked className="contract-radio" />
        ) : currentStep > index ? (
          <>
            {(index === 0) ?
              <Icon type="check-circle" />
              : (index === 1 && isShipperSet) ?
                <Icon type="check-circle" /> :
                (index === 2 && isZoneSet) ?
                  <Icon type="check-circle" /> :
                  (index === 3 && isLocationSet) ?
                    <Icon type="check-circle" /> :
                    (index === 4 && isContractSet) ?
                      <Icon type="check-circle" /> :
                      <Radio checked={false} className="contract-radio" />
            }
          </>

        ) : (
              <Radio checked={false} className="contract-radio" />
            )}
      </>
    );
  };

  const openHelpLink = () => {
    switch (key) {
      case 'basic':
        window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/customer-create-guide.pdf`, '_blank').focus();
        break;
      case 'shippers':
        window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/customer-shipper-create-guide.pdf`, '_blank').focus();
        break;
      case 'zones':
        window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/customer-zone-create-guide.pdf`, '_blank').focus();
        break;
      case 'location':
        window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/customer-location-create-guide.pdf`, '_blank').focus();
        break;
      case 'contract':
        window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/contract-create-guide.pdf`, '_blank').focus();
        break;
    }
  }


  return (
    <div className="spacer-m page-wrapper">
      <Card className="contract-management">
        <div className="flex-box">
          <div>
            <h3>
              <Icon
                type="arrow-left"
                onClick={() => {

                  if (isCancel) {
                    confirm({
                      title: "Are you sure to cancel the changes made?",
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        goBack();
                        setCancel(false);
                      }
                    });
                  } else {
                    goBack();
                  }
                }}
              />{" "}
              <b>{readOnly && customerId ? customerName : "Add Customer"}</b>
            </h3>
          </div>
          {key === "basic" && readOnly && (
            <div className="actions flex-1  flex-box justify-content-flex-end">
              {customerReadOnly ? (
                <Button
                  disabled={!contractPermissions.customer_update}
                  className="lsn-btn-primary"
                  onClick={() => {
                    setCustomerReadOnly(false);
                  }}
                >
                  Edit
                </Button>
              ) : (
                  <div className="flex-box align-items-center">
                    <Tag className="cursor-pointer" color="#87d068" onClick={openHelpLink}><Icon type="question-circle" /> Help</Tag>
                    <Button style={{ marginLeft: 8 }} onClick={() => {

                      if (isCancel) {
                        confirm({
                          title: 'Are you sure to cancel the changes made?',
                          okText: 'Yes',
                          cancelText: 'No',
                          onOk: () => {
                            goBack();
                            setCancel(false);
                          }
                        })
                      } else {
                        goBack()
                      }


                    }
                    }>
                      Cancel
                    </Button>
                    <Button
                      disabled={!isCancel}
                      className="lsn-btn-primary"
                      onClick={() => {

                        customerRef.current.formReference.current.submit();
                        // setCustomerReadOnly(true);
                      }}
                    >
                      Update</Button>
                  </div>
                )}
            </div>
          )}
          {!readOnly && (
            <div className="actions flex-1  flex-box justify-content-flex-end">
              <div className="steps-action">
                {currentStep === 0 ? (
                  <>
                    <Tag className="cursor-pointer" color="#87d068" onClick={openHelpLink}><Icon type="question-circle" /> Help</Tag>
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={() => {
                        if (isCancel) {
                          confirm({
                            title: "Are you sure to cancel the changes made?",
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                              goBack();
                              setCancel(false);
                            }
                          });
                        } else {
                          goBack();
                        }
                      }}
                    >
                      Cancel
                  </Button>
                  </>
                ) : (
                    currentStep > 0 && (
                      <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
                        Previous
                      </Button>
                    )
                  )}
                {/* {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => goBack()}
                  >
                    Done
                  </Button>
                )} */}
                {currentStep == 0 ? (
                  <Button className="lsn-btn-primary" onClick={() => next()}>
                    Save & Next
                  </Button>
                ) : (
                    currentStep < steps.length - 1 && (
                      <Button className="lsn-btn-primary" onClick={() => next()}>
                        Next
                      </Button>
                    )
                  )}
              </div>
            </div>
          )}
        </div>
        <Divider />
        {readOnly && customerId ? (
          <Tabs activeKey={key} onChange={onTabSwitch}>
            {contractPermissions?.customer && (
              <TabPane tab="BASIC DETAILS" key="basic">
                {key === "basic" && (
                  <Customer
                    customerId={customerId}
                    readOnly={customerReadOnly}
                    customerRef={customerRef}
                    setCustomer={setCustomer}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    setCustomerReadOnly={setCustomerReadOnly}
                    isCancel={isCancel}
                    setCancel={setCancel}
                  />
                )}
              </TabPane>
            )}
            {contractPermissions?.shippers && (
              <TabPane tab="SHIPPERS" key="shippers">
                {key === "shippers" && (
                  <Shipper
                    customerId={customerId}
                    setCustomerReadOnly={setCustomerReadOnly}
                    readOnly={readOnly}
                    customer={customer}
                    openHelpLink={openHelpLink}
                    setShipper={setShipper}

                  />
                )}
              </TabPane>
            )}
            {contractPermissions?.zones && (
              <TabPane tab="ZONES" key="zones">
                {key === "zones" && (
                  <Zone
                    readOnly={readOnly}
                    setCustomerReadOnly={setCustomerReadOnly}
                    customerId={customerId}
                    openHelpLink={openHelpLink}
                    customer={customer}
                    setZoneStepper={setZoneStepper}
                  />
                )}
              </TabPane>
            )}
            {contractPermissions?.locations && (
              <TabPane tab="LOCATIONS" key="location">
                {key === "location" && (
                  <Locations
                    customerId={customerId}
                    setCustomerReadOnly={setCustomerReadOnly}
                    readOnly={readOnly}
                    customer={customer}
                    openHelpLink={openHelpLink}
                    setLocationStepper={setLocationStepper}
                  />
                )}
              </TabPane>
            )}
            {contractPermissions?.contractdetails && (
              <TabPane tab="CONTRACTS" key="contract">
                {key === "contract" && (
                  <ContractsTab
                    setCustomerReadOnly={setCustomerReadOnly}
                    customerId={customerId}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    customer={customer}
                    filterParams={props?.location?.state?.filterParams}
                    filterData={props?.location?.state?.filterData}
                    filters={props?.location?.state?.filters}
                    openHelpLink={openHelpLink}
                    setContractStepper={setContractStepper}
                    backFromDetails={props?.location?.state?.backFromDetails}
                    redirectToCustomerDetals={(
                      id,
                      filterParams,
                      filterData,
                      filters
                    ) => {
                      // props.history.push(`/appv2/settings/dashboard/customer/contracts/${customerId}/${id}`, { contractid: id, customerId, name: customerName, contractName, view: true, filterParams, filterData, filters })
                      props.history.push(
                        `/appv2/settings/dashboard/customer/contracts/${customerId}/${id}`,
                        {
                          contractid: id,
                          customerId,
                          name,
                          view: true,
                          filterParams,
                          filterData,
                          filters,
                          customer:customer
                        }
                      );
                    }}
                    createContract={() => {
                      props.history.push(
                        `/appv2/settings/dashboard/customer/contracts/${customerId}`,
                        { customerId, view: false, customer: customer }
                      );
                    }}
                    editContractDetails={(
                      id,
                      filterParams,
                      filterData,
                      filters,
                      clone
                    ) => {
                      props.history.push(
                        `/appv2/settings/dashboard/customer/contracts/${customerId}/${id}`,
                        {
                          contractid: id,
                          customerId,
                          filterParams,
                          filterData,
                          filters,
                          clone,
                          customer: customer
                        }
                      );
                    }}
                  />
                )}
              </TabPane>
            )}
          </Tabs>
        ) : (
            <div className="flex-column flex-gap-xl">
              <Steps
                className="contract-steps"
                size={"small"}
                current={currentStep}
                progressDot={customDot}
              >
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">{steps[currentStep].content}</div>
            </div>
          )}
      </Card>
      <>
        {showHistory &&
          <ViewHistory
            showHistoryModal={showHistory}
            history={history}
            cancelHistory={() => setHistory(false)}
          />
        }
      </>
    </div >
  );
}

export default CustomerDetails;
