import { notifyApiError } from "../../../common/utils/utils";
import * as CONSTANTS from './constants';
import { apiRequest } from '../../../common/utils/apiGateway';
import { Constants } from "../../../common/utils/constants";

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getInvoicesList = (data, isReset) => dispatch => {
    dispatch(invoicesListBegin());
    apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoices/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(invoicesListSuccess(response.data?.response, isReset))
            else
                dispatch(invoicesListFailure(response.data?.status))
        })
        .catch(error => dispatch(invoicesListFailure(error)));
}

function invoicesListBegin() {
    return {
        type: CONSTANTS.GET_INVOICE_LIST_BEGIN,
        payload: null
    }
}

function invoicesListSuccess(response, isReset) {
    return {
        type: CONSTANTS.GET_INVOICE_LIST_SUCCESS,
        payload: response,
        isReset
    }
}

function invoicesListFailure(response) {
    return {
        type: CONSTANTS.GET_INVOICE_LIST_FAILURE,
        payload: response
    }
}

export const getInvoicesPaymentsList = (data) => dispatch => {
    dispatch(invoicesPaymentsListBegin());
    apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoice/tracking`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(invoicesPaymentsListSuccess(response.data?.response))
            else
                dispatch(invoicesPaymentsListFailure(response.data?.status))
        })
        .catch(error => dispatch(invoicesPaymentsListFailure(error)));
}

function invoicesPaymentsListBegin() {
    return {
        type: CONSTANTS.GET_INVOICE_PAYMENTS_LIST_BEGIN,
        payload: null
    }
}

function invoicesPaymentsListSuccess(response) {
    return {
        type: CONSTANTS.GET_INVOICE_PAYMENTS_LIST_SUCCESS,
        payload: response
    }
}

function invoicesPaymentsListFailure(response) {
    return {
        type: CONSTANTS.GET_INVOICE_PAYMENTS_LIST_FAILURE,
        payload: response
    }
}

export const updateCrDrNote = (data) => dispatch => {
    return apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoice/createNote`,
        data
    })
        .then(response => {
            return response
        })
        .catch(error => (error));
}
export const fetchReasonNotes = (data) => dispatch => {
    return apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoice/reasonNote/fetch`,
    })
        .then(response => {
            return response
        })
        .catch(error => (error));
}

export const getCrDrNoteList = (data) => dispatch => {
    dispatch(getCrDrNoteListBegin());
    apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoice/createNote/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getCrDrNoteListSuccess(response.data?.response))
            else
                dispatch(getCrDrNoteListFailure(response.data?.status))
        })
        .catch(error => dispatch(getCrDrNoteListFailure(error)));
}

function getCrDrNoteListBegin() {
    return {
        type: CONSTANTS.GET_CREDIT_DEBIT_NOTE_BEGIN,
        payload: null
    }
}

function getCrDrNoteListSuccess(response) {
    return {
        type: CONSTANTS.GET_CREDIT_DEBIT_NOTE_SUCCESS,
        payload: response
    }
}

function getCrDrNoteListFailure(response) {
    return {
        type: CONSTANTS.GET_CREDIT_DEBIT_NOTE_FAILURE,
        payload: response
    }
}

export const getInvoicesDocketsList = (invoiceId) => dispatch => {
    dispatch(invoicesDocketsListBegin());
    apiRequest({
        method: 'GET',
        url: `/b2b/v1/invoice/fetchDockets/${invoiceId}`
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(invoicesDocketsListSuccess(response.data?.response))
            else
                dispatch(invoicesDocketsListFailure(response.data?.status))
        })
        .catch(error => dispatch(invoicesDocketsListFailure(error)));
}

function invoicesDocketsListBegin() {
    return {
        type: CONSTANTS.GET_INVOICE_DOCKET_LIST_BEGIN,
        payload: null
    }
}

function invoicesDocketsListSuccess(response) {
    return {
        type: CONSTANTS.GET_INVOICE_DOCKET_LIST_SUCCESS,
        payload: response
    }
}

function invoicesDocketsListFailure(response) {
    return {
        type: CONSTANTS.GET_INVOICE_DOCKET_LIST_FAILURE,
        payload: response
    }
}

export const updateInvoiceDueDate = (data) => dispatch => {
    dispatch(updateInvoiceDueDateBegin());
    apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoice/updateInvoiceDueDate`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(updateInvoiceDueDateSuccess(response.data?.status))
            else
                dispatch(updateInvoiceDueDateFailure(response.data?.status))
        })
        .catch(error => dispatch(updateInvoiceDueDateFailure(error)));
}

function updateInvoiceDueDateBegin() {
    return {
        type: CONSTANTS.UPDATE_INVOICE_DUE_DATE_BEGIN,
        payload: null
    }
}

function updateInvoiceDueDateSuccess(response) {
    return {
        type: CONSTANTS.UPDATE_INVOICE_DUE_DATE_SUCCESS,
        payload: response
    }
}

function updateInvoiceDueDateFailure(response) {
    return {
        type: CONSTANTS.UPDATE_INVOICE_DUE_DATE_FAILURE,
        payload: response
    }
}

export const uploadCustomerInvoice = (data) => dispatch => {
    dispatch(uploadCustomerInvoiceBegin());
    return apiRequest({
        method: 'POST',
        url: `/b2b/v1/invoice/pdfUpload`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(uploadCustomerInvoiceSuccess(response.data?.status))
            else
                dispatch(uploadCustomerInvoiceFailure(response.data?.status))

            return response
        })
        .catch(error => dispatch(uploadCustomerInvoiceFailure(error)));
}

function uploadCustomerInvoiceBegin() {
    return {
        type: CONSTANTS.UPLOAD_CUSTOMER_INVOICE_BEGIN,
        payload: null
    }
}

function uploadCustomerInvoiceSuccess(response) {
    return {
        type: CONSTANTS.UPLOAD_CUSTOMER_INVOICE_SUCCESS,
        payload: response
    }
}

function uploadCustomerInvoiceFailure(response) {
    return {
        type: CONSTANTS.UPLOAD_CUSTOMER_INVOICE_FAILURE,
        payload: response
    }
}

export const uploadPayment = (data) => dispatch => {
    dispatch(uploadPaymentBegin());
    apiRequest({
        method: 'POST',
        url: `/b2b/v1/uploads/invoice/settlements`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(uploadPaymentSuccess(response.data?.status))
            else
                dispatch(uploadPaymentFailure(response.data?.status))
        })
        .catch(error => dispatch(uploadPaymentFailure(error)));
}

function uploadPaymentBegin() {
    return {
        type: CONSTANTS.UPLOAD_PAYMENT_BEGIN,
        payload: null
    }
}

function uploadPaymentSuccess(response) {
    return {
        type: CONSTANTS.UPLOAD_PAYMENT_SUCCESS,
        payload: response
    }
}

function uploadPaymentFailure(response) {
    return {
        type: CONSTANTS.UPLOAD_PAYMENT_FAILURE,
        payload: response
    }
}

export const getCustomerList = () => dispatch => {
    dispatch(customerListBegin());
    apiRequest({
        method: 'GET',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/fetch?view=LITE_CUSTOMER_LIST`
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(customerListSuccess(response.data?.viewResponse?.viewResponse))
            else
                dispatch(customerListFailure(response.data?.status))
        })
        .catch(error => dispatch(customerListFailure(error)));
}

function customerListBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_BEGIN,
        payload: null
    }
}

function customerListSuccess(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_SUCCESS,
        payload: response
    }
}

function customerListFailure(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_FAILURE,
        payload: response
    }
}

export const getShipperList = (data) => dispatch => {
    dispatch(shipperListBegin());
    apiRequest({
        method: 'POST',
        url: `/b2b/v1/customer/shipper-mapping/fetch`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(shipperListSuccess(response.data?.response?.mappingBOs))
            else
                dispatch(shipperListFailure(response.data?.status))
        })
        .catch(error => dispatch(shipperListFailure(error)));
}

function shipperListBegin() {
    return {
        type: CONSTANTS.GET_SHIPPER_LIST_BEGIN,
        payload: null
    }
}

function shipperListSuccess(response) {
    return {
        type: CONSTANTS.GET_SHIPPER_LIST_SUCCESS,
        payload: response
    }
}

function shipperListFailure(response) {
    return {
        type: CONSTANTS.GET_SHIPPER_LIST_FAILURE,
        payload: response
    }
}

export const downloadAnnexure = (data) => dispatch => {
    dispatch(downloadAnnexureBegin());
    apiRequest({
        method: 'POST',
        url: `b2b/v1/reports/CONSIGNMENT_ANNEXURE_REPORT/get`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(downloadAnnexureSuccess(response.data?.response))
            else
                dispatch(downloadAnnexureFailure(response.data?.status))
        })
        .catch(error => dispatch(downloadAnnexureFailure(error)));
}

function downloadAnnexureBegin() {
    return {
        type: CONSTANTS.DOWNLOAD_ANNEXURE_BEGIN,
        payload: null
    }
}

function downloadAnnexureSuccess(response) {
    return {
        type: CONSTANTS.DOWNLOAD_ANNEXURE_SUCCESS,
        payload: response
    }
}

function downloadAnnexureFailure(response) {
    return {
        type: CONSTANTS.DOWNLOAD_ANNEXURE_FAILURE,
        payload: response
    }
}

export const createOpenInvoice = (data) => dispatch => {
    // dispatch(createOpenInvoiceBegin());
    return apiRequest({
        method: 'POST',
        url: `b2b/v1/invoice/createOpenInvoice`,
        data
    })
    // .then(response => {
    //     if (checkHttpStatus(response.data?.status))
    //         dispatch(createOpenInvoiceSuccess(response.data?.response))
    //     else
    //         dispatch(createOpenInvoiceFailure(response.data?.status))
    // })
    // .catch(error => dispatch(createOpenInvoiceFailure(error)));
}

function createOpenInvoiceBegin() {
    return {
        type: CONSTANTS.CREATE_OPEN_INVOICE_BEGIN,
        payload: null
    }
}

function createOpenInvoiceSuccess(response) {
    return {
        type: CONSTANTS.CREATE_OPEN_INVOICE_SUCCESS,
        payload: response
    }
}

function createOpenInvoiceFailure(response) {
    return {
        type: CONSTANTS.CREATE_OPEN_INVOICE_FAILURE,
        payload: response
    }
}

export const exportInvoiceData = (data) => dispatch => {
    dispatch(exportInvoiceDataBegin());
    apiRequest({
        method: 'POST',
        url: `b2b/v1/reports/AR_REPORT/get`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(exportInvoiceDataSuccess(response.data?.response))
            else
                dispatch(exportInvoiceDataFailure(response.data?.status))
        })
        .catch(error => dispatch(exportInvoiceDataFailure(error)));
}

function exportInvoiceDataBegin() {
    return {
        type: CONSTANTS.EXPORT_INVOICE_BEGIN,
        payload: null
    }
}

function exportInvoiceDataSuccess(response) {
    return {
        type: CONSTANTS.EXPORT_INVOICE_SUCCESS,
        payload: response
    }
}

function exportInvoiceDataFailure(response) {
    return {
        type: CONSTANTS.EXPORT_INVOICE_FAILURE,
        payload: response
    }
}

export const uploadAnnexure = (data) => dispatch => {
    dispatch(uploadAnnexureBegin());
    apiRequest({
        method: 'POST',
        url: `b2b/v1/uploads/invoice/annexure/update`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(uploadAnnexureSuccess(response.data?.response))
            else
                dispatch(uploadAnnexureFailure(response.data?.status))
        })
        .catch(error => dispatch(uploadAnnexureFailure(error)));
}

function uploadAnnexureBegin() {
    return {
        type: CONSTANTS.UPLOAD_ANNEXURE_BEGIN,
        payload: null
    }
}

function uploadAnnexureSuccess(response) {
    return {
        type: CONSTANTS.UPLOAD_ANNEXURE_SUCCESS,
        payload: response
    }
}

function uploadAnnexureFailure(response) {
    return {
        type: CONSTANTS.UPLOAD_ANNEXURE_FAILURE,
        payload: response
    }
}

export const delete_CancelInvoice = (type, data) => dispatch => {
    return apiRequest({
        method: 'POST',
        url: `b2b/v1/invoice/${type}`,
        data
    })
    // .then(response => {
    //     if (checkHttpStatus(response.data?.status))
    //         dispatch(uploadAnnexureSuccess(response.data?.response))
    //     else
    //         dispatch(uploadAnnexureFailure(response.data?.status))
    // })
    // .catch(error => dispatch(uploadAnnexureFailure(error)));
}

export const delinkDocket = (data) => dispatch => {
    return apiRequest({
        method: 'POST',
        url: `b2b/v1/invoice/delinkDockets`,
        data
    })
    // .then(response => {
    //     if (checkHttpStatus(response.data?.status))
    //         dispatch(uploadAnnexureSuccess(response.data?.response))
    //     else
    //         dispatch(uploadAnnexureFailure(response.data?.status))
    // })
    // .catch(error => dispatch(uploadAnnexureFailure(error)));
}