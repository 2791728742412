import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import DrsDraftService from '../../../services/leads/drs.service';
const drsDraftService = new DrsDraftService;

export const assignRunsheet = (payload) => dispatch => {
    return drsDraftService.assignRunsheet(payload).then(res => {
        let filterPayload = {
                pageNo: 1,
                pageSize: 10,
        }
        dispatch(fetchRunsheetDraft(filterPayload, true));
        return res;
    });
};

export const fetchRunsheetDraft = (payload, isReset) => dispatch => {
    dispatch(fetchRunsheetBegin());
    drsDraftService.fetchRunsheetDraft(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchRunsheetSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchRunsheetFailure(res ?.data ?.response));
        }
    });
};

export const fetchuserByName = (payload, isReset) => dispatch => {
    dispatch(fetchRunsheetBegin());
    drsDraftService.fetchuserByName(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchRunsheetSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchRunsheetFailure(res ?.data ?.response));
        }
    });
};

export const deleteDraft = (id, payload) => dispatch => {
    dispatch(fetchRunsheetBegin());
    drsDraftService.deleteLocation(id).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            fetchRunsheetSuccess(payload);
        } else {
            dispatch(fetchRunsheetFailure(res ?.data ?.response));
        }
    });
}

function fetchRunsheetBegin() {
    return {
        type: _Constants.FETCH_RUNSHEET_BEGIN
    };
}

function fetchRunsheetSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_RUNSHEET_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchRunsheetFailure(error) {
    return {
        type: _Constants.FETCH_RUNSHEET_FAILURE,
        payload: error
    };
}

export const getFeDetails = () => dispatch => {
    dispatch(fetchlocationUserBegin());
    drsDraftService.getUsersByLocation().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchlocationUserSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchlocationUserFailure(res ?.data ?.response));
        }
    });
}

function fetchlocationUserBegin() {
    return {
        type: _Constants.FETCH_LOCATION_USER_BEGIN
    };
}

function fetchlocationUserSuccess(payload) {
    return {
        type: _Constants.FETCH_LOCATION_USER_SUCCESS,
        payload: payload
    };
}

function fetchlocationUserFailure(error) {
    return {
        type: _Constants.FETCH_LOCATION_USER_FAILURE,
        payload: error
    };
}
