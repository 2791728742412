import React, { Component } from 'react';
import { Map, Marker, TileLayer, Polyline , Popup} from 'react-leaflet';
import L from 'leaflet';
import {carIcon,truckIcon,createCustomMarker,createCustomMarkerHtml,greenMarkerIcon,redMarkerIcon,blueMarkerIcon,orangeMarkerIcon} from '../maps/CustomIcon'
import img from '../../assets/img/truck_0.svg';
import "leaflet-polylinedecorator";
import 'leaflet/dist/leaflet.css';
import * as utils from "../../common/utils/utils";
import Truck from '../../assets/img/delivery-truck.svg';
import '../maps/map-widget.css';
import {
  Icon,Card
} from 'antd';

var moment = require("moment-timezone")

const pinkicon = L.icon({
  iconUrl:
    "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});

const markerStyle = {
  color: '#091c9b',
  fillColor: "#091c9b",
  opacity: 1,
  radius: 8,
};

const markerStyle2 = {
  color: '#3b5a98',
  opacity: 1,
  radius: 8,
  fill: "#fff",
  fillRule: "a"
};

//   const redMarker = L.divIcon({
//     iconUrl: img,
//     iconSize: [40, 40],
//     iconAnchor: [20, 40],
//   });

const redMarker = L.divIcon({
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  className: `u-turn-icon`,
  html: `<img src=${img} />`
});


export default class HeliosRoute extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isMapInit: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.routing !== nextProps.routing;
  }

  getBounds = (simTrackerResponses) => {
    let jsonData = [];
      simTrackerResponses.map((x, key) => {
        let coordinates = [x['latitude'], x['longitude']];
        if (coordinates !== null) {
          jsonData.push(coordinates);
        }
      })
    return jsonData;
  }

  getGPSBounds = (gpsTrackerResponses) => {
    let jsonData = [];
      gpsTrackerResponses.map((x, key) => {
        let coordinates = [x['latitude'], x['longitude']];
        if (coordinates !== null) {
          jsonData.push(coordinates);
        }
      })
    return jsonData;
  }



  markerBounds = (simTracking,startingLocation,stoppagePoints,customerLocations,lastKnownLocation) => {
    let setDummy = 65;
    let runningKey = 0;
    let customerMarkerLocations = [];
      if (!utils.isEmpty(startingLocation) && startingLocation.latitude != 0 && startingLocation.longitude != 0) {
        runningKey = runningKey + 1;
        let coordinates = [Number(startingLocation['latitude']), Number(startingLocation['longitude'])];
        customerMarkerLocations.push(<>
          <Marker key={runningKey} position={coordinates} interactive={true} keyboard={true} numberSet={setDummy} icon={greenMarkerIcon}>
            <Popup>
              Location: {startingLocation.address} <br />
                Started at: {utils.dateFormat(startingLocation.timeStamp)}
            </Popup>
          </Marker>
        </>)
      }

      stoppagePoints.forEach((x) => {
        let coordinates = [Number(x['latitude']), Number(x['longitude'])];
        runningKey = runningKey + 1;
        if (coordinates !== null && !x.customerLocation) {
          setDummy = setDummy + 1;
          customerMarkerLocations.push(
            <>
              <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={orangeMarkerIcon}>
              </Marker>
            </>
          )
        } else if (coordinates !== null && x.customerLocation) {
          setDummy = setDummy + 1;
          customerMarkerLocations.push(
            <>
              <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={blueMarkerIcon}>
                <Popup>
                  Location: {x.location} <br />
                Arrived at: {utils.dateFormat(x.startTime)}
                </Popup>
              </Marker>
            </>
          )
        }

      })

      customerLocations.forEach((x) => {
        let coordinates = [Number(x['latitude']), Number(x['longitude'])];
        runningKey = runningKey + 1;
        if (coordinates !== null && !x.reached) {
          setDummy = setDummy + 1;
          customerMarkerLocations.push(
            <>
              <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={redMarkerIcon}>
                <Popup>
                  Location: {x.name} <br />
                </Popup>
              </Marker>
            </>
          )
        } else if (coordinates !== null && x.reached) {
          setDummy = setDummy + 1;
          customerMarkerLocations.push(
            <>
              <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={blueMarkerIcon}>
                <Popup>
                  Location: {x.name} <br />
            Arrived at: {utils.dateFormat(x.arriveAt)}
                </Popup>
              </Marker>
            </>
          )
        }

      })

      if (!utils.isEmpty(lastKnownLocation) && lastKnownLocation.latitude != 0 && lastKnownLocation.longitude != 0) {
        runningKey = runningKey + 1;
        setDummy = setDummy + 1;
        let coordinates = [Number(lastKnownLocation['latitude']), Number(lastKnownLocation['longitude'])];
        customerMarkerLocations.push(<>
          <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={redMarker} >
            <Popup>
              Location: {lastKnownLocation.address} <br />
                Arrived at: {utils.dateFormat(lastKnownLocation.timeStamp)}
            </Popup>
          </Marker>
        </>)
      }
      return customerMarkerLocations;
  }


  saveMap = (map: any) => {
    this.map = map;
    this.setState({
      isMapInit: true
    })
  }

  urlDecode = (encoded) => {
    var points = []
    var index = 0, len = encoded.length;
    var lat = 0, lng = 0;
    while (index < len) {
      var b, shift = 0, result = 0;
      do {

        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);


      var dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
      lat += dlat;
      shift = 0;
      result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      var dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
      lng += dlng;

      points.push([(lat / 1E5), (lng / 1E5)])

    }
    return points
  }

  getTimeStamp = (data) =>{
    if(data.simTracking){
      return moment(data.response.vehicleTrackingContractVO.startDate).format('x')
    }else{
      return parseInt(data.response.tripStart.startTime)
    }
  }
  
  constructPolyline(startLocation,endLocation){
    let position = [];
    position.push([startLocation['latitude'], startLocation['longitude']]);
    position.push([endLocation['latitude'], endLocation['longitude']]);
    return <Polyline positions={position} color="red"/>
  }

  render() {
    const { routing } = this.props;
    let position;
    let markerlet = [];
    let trackerMarkers = [];
    let polyLineMarkers = [];
    let startingLocation = {};
    let lastKnownLocation = {};
    let stoppagePoints = [];
    let customerLocations=[];
    let markerlist;
    let futurePolyLine;
    let tripId = {};
    let isSimTracking = false;
    let isFuturePolyLineRequired = true;
    let isPreviousTripNonTrackable = false;
    if(routing && routing?.responses?.length > 0){
        var sortedResponses = routing.responses.sort((a,b) =>{
          let first = this.getTimeStamp(a);
          let second = this.getTimeStamp(b);
          return first - second;
        });
        var stepSize = 0;
        var lastLocationOfATrip = {};
        var sourceHubLocation = {};
        sortedResponses.forEach((data,key)=>{
          if(data.tripType != "CL"){
            sourceHubLocation = data.sourceHubLocation;
            if(key==0){
              startingLocation = data.startingLocation;
            }
            if(key == sortedResponses.length-1){
              lastKnownLocation = data.lastKnownLocation;
              futurePolyLine = data.futurePolyLine
              tripId = data.tripId
              isSimTracking = data.simTracking
            }

            if(key-stepSize > 0){
              if(key-1 == 0){
                if(!utils.isEmpty(sourceHubLocation) && !utils.isEmpty(lastLocationOfATrip) ){
                  polyLineMarkers.push(this.constructPolyline(lastLocationOfATrip,sourceHubLocation))
                }
              }
              else{
                var startLocation =  {};
                if(data.response?.reportResponse && data.response.reportResponse.length > 0){
                  startLocation = data.response.reportResponse[0]
                }else if(data.response?.locationPoints && data.response.locationPoints.length > 0){
                  trackerMarkers = trackerMarkers.concat(data.response.locationPoints)
                  startLocation = data.response.reportResponse[0]
                }
                if(!utils.isEmpty(lastLocationOfATrip) && !utils.isEmpty(startLocation) ){
                  polyLineMarkers.push(this.constructPolyline(lastLocationOfATrip,startLocation))
                }
                stepSize = key;
              }
            }

            if(isPreviousTripNonTrackable){
              var currentLocation = {};
              if(data.response?.reportResponse && data.response.reportResponse.length > 0){
                currentLocation = data.response.reportResponse[0]
              }else if(data.response?.locationPoints && data.response.locationPoints.length > 0){
                currentLocation = data.response.locationPoints[0]
              }
              if(!utils.isEmpty(currentLocation) && !utils.isEmpty(lastLocationOfATrip)){
                polyLineMarkers.push(<Polyline positions={futurePosition} color='grey'  weight="3" dashArray="4"/>)
              }
            }

            if(data.response?.reportResponse && data.response.reportResponse.length > 0){
              trackerMarkers = trackerMarkers.concat(data.response.reportResponse)
              lastLocationOfATrip = data.response.reportResponse[data.response.reportResponse.length-1]
              isPreviousTripNonTrackable = false;
            }else if(data.response?.locationPoints && data.response.locationPoints.length > 0){
              trackerMarkers = trackerMarkers.concat(data.response.locationPoints)
              lastLocationOfATrip = data.response.locationPoints[data.response.locationPoints.length-1]
              isPreviousTripNonTrackable = false;
            }else{
              isPreviousTripNonTrackable = true;
            }
            
            if(data?.customerLocations && data.customerLocations.length > 0 && key == sortedResponses.length-1){
              customerLocations = customerLocations.concat(data.customerLocations)
            }else if(data?.customerLocations && data.customerLocations.length > 0 ){
              if(data.simTracking){  
                var reachedCustomerLocations = data.customerLocations.filter(data=>{
                  return data.reached
                })
                customerLocations = customerLocations.concat(reachedCustomerLocations)
              }

            }
  
             if(!data.simTracking &&  data.stoppagePoints.length > 0){
              stoppagePoints = stoppagePoints.concat(data.stoppagePoints)
             }
             stepSize = stepSize+1;
          }
          
        })
        if(sortedResponses.length - stepSize > 0 || isPreviousTripNonTrackable){
          var unReachedLocations = customerLocations.filter(data=>{
            return !data.reached
          })
          if(!utils.isEmpty(lastLocationOfATrip)){
            unReachedLocations.push(lastLocationOfATrip);
          }
          var futurePosition =  this.getBounds(unReachedLocations);
          isFuturePolyLineRequired = false
          polyLineMarkers.push(<Polyline positions={futurePosition} color='#7f7f80'  weight="3" dashArray="4"/>)
        }
        markerlet = this.getBounds(trackerMarkers);
        position = markerlet;
        if(utils.isEmpty(markerlet)){
          markerlet = this.getBounds(customerLocations);
        }
        markerlist = this.markerBounds(isSimTracking,startingLocation,stoppagePoints,customerLocations,lastKnownLocation);  
    }

    return (
      <div className="directions">
        {routing && ( !utils.isEmpty(position) || !utils.isEmpty(futurePolyLine)) ?
          <div className="boxapprox">
            <Map
              ref={this.saveMap}
              id='map'
              animate={true}
              onZoomEnd={this.updateZoom}
              onClick={this.updatePosition}
              bounds={markerlet && markerlet.length > 0 ? markerlet : [[20.593700, 78.962900]]}
              boundsOptions={{ padding: [50, 50] }}>

              <TileLayer
                url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
              />
              {polyLineMarkers.length>0 && polyLineMarkers}
              {position.length>0 &&<Polyline positions={position} />}
              {futurePolyLine && isFuturePolyLineRequired && <Polyline color='#7f7f80'  weight="3" dashArray="4" positions={this.urlDecode(futurePolyLine)}/>}
              {markerlist && markerlist.length > 0 ?markerlist:[]}
            </Map>
            {tripId && this.props.visibleFullScreen && !isPreviousTripNonTrackable && <div onClick={e => this.props.history.push(`/appv2/customer-tracking/dashboard/customer-route-tracking/${tripId}`)} className="Route-Activity"
              style={
                {
                  float: 'right', cursor: 'pointer',
                  position: "relative",
                  bottom: "285px",
                  zIndex: "9999"
                }
              }>
              <div
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: 6,
                  boxShadow: "0 4px 8px 0 rgba(115, 115, 115, 0.1)",
                  backgroundColor: "#ffffff"
                }}
              >
                <Icon type="fullscreen" style={{ fontSize: 20, position: 'relative', top:3, left: 2 }} />
              </div>

            </div>}
            {/* <Icon type="search" /> */}
          </div>:
          <Card style={{ textAlign: 'center' }}>
          <div style={{
              margin: "auto",
              width: "50%",
              padding: "15%"
          }}>
              <img src={Truck} />
          </div>
          <p>Live tracking not available</p>


      </Card>
      }
      </div>
    )
  }
}