import React, { PureComponent } from 'react';
import { Card, Table, Icon, Input, Row, Col, Switch, DatePicker, Modal, Button, Spin } from 'antd';
import * as actions from './actions';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

class UserDashboard extends PureComponent {

    state = {
        userDataSource: [],
        userMainDataSource: [],
        trainingCompletedDateModal: false,
        trainingFilters: {},
        trainingCompletedDate: undefined,
        applyTrainingFilters: {},

        disabledButton: true,

        handsOnDateModal: false,
        handsOnFilters: {},
        handsOnCompletedDate: undefined,
        applyHandsOnFilters: {},
        pageNo: 1,
        isSearch: false,

        minValue: 0,
        maxValue: 10
    }

    componentDidMount() {
        let userID = this.props.location?.state?.userID;
        this.InputSearchRef = React.createRef();
        let params = {
            "filter": {
                "pageNo": this.state.pageNo,
                "pageSize": 10,
                "ids": [userID],
                "fetchObjects": [
                    "USER_ROLES",
                    "USER_LMS"
                ]
            }
        }

        this.props.getLMSUsersList(params);
    }

    componentWillUnmount() {
        this.props.resetUserUpdate();
        this.setState({})
    }

    componentDidUpdate() {

        if (this.props.lmsUSERUpdateStatus) {
            let userID = this.props.location?.state?.userID;
            let params = {
                "filter": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "ids": [userID],
                    "fetchObjects": [
                        "USER_ROLES",
                        "USER_LMS"
                    ]
                }
            }
            this.props.resetUserUpdate();
            this.setState({ userDataSource: [], disabledButton: true })
            setTimeout(() => {
                this.props.getLMSUsersList(params);
            }, 600)


        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    getPaginatedData = (pageNo, paginatedData) => {

        let userID = this.props.location?.state?.userID;
        let params = {
            "filter": {
                "pageNo": pageNo,
                "pageSize": 10,
                "ids": [userID],
                "fetchObjects": [
                    "ROLES",
                    "USER_STRICT_BASIC",
                    "USER_LMS"
                ]
            }
        }

        this.props.getLMSUsersList(params);

    }

    search = (value) => {

        if (value) {

            let values = this.state.userDataSource.filter(user => {

                return user.user.toLowerCase().indexOf(value.toLowerCase()) >= 0 || user.contactNumber.indexOf(value) >= 0;
            });


            this.setState({ userDataSource: values, isSearch: true, minValue: 0, maxValue: 10 });
            // if (values) {
            //     this.setState({ userDataSource: values });
            // }
        } else {
            this.setState({ userDataSource: [], isSearch: false })
        }
    }

    changetrainingCompletedDate = (status, user) => {
        this.setState({ trainingCompletedDateModal: true, trainingFilters: user });

    }

    hideTrainingCompleted = () => {
        this.setState({ trainingCompletedDateModal: false, trainingFilters: {}, applyTrainingFilters: {}, trainingCompletedDate: undefined });
    }

    onChangeTrainingCompleted = (value) => {
        let { trainingFilters } = this.state;
        let filters = {
            "lmsUser": {
                "lmsId": trainingFilters.lmsId,
                "user": {
                    "id": trainingFilters.user.id
                },
                "trainingCompletedDate": value.valueOf()
            }
        }

        this.setState({
            trainingCompletedDate: value,
            applyTrainingFilters: filters
        });
    }

    applyTrainingCompletedDate = () => {

        this.props.updateUserStatus(this.state.applyTrainingFilters);

        this.setState({ trainingCompletedDateModal: false, trainingCompletedDate: undefined });
    }





    changeHandsOnCompletedDate = (status, user) => {
        this.setState({ handsOnDateModal: true, handsOnFilters: user });

    }

    hideHandsOnCompleted = () => {
        this.setState({ handsOnDateModal: false, handsOnFilters: {}, applyHandsOnFilters: {}, handsOnCompletedDate: undefined, disabledButton: false });
    }

    onChangeHandsOnCompleted = (value) => {
        let { handsOnFilters } = this.state;
        let filters = {
            "lmsUser": {
                "lmsId": handsOnFilters.lmsId,
                "user": {
                    "id": handsOnFilters.user.id
                },
                "handsonCompletedDate": value.valueOf()
            }
        }

        this.setState({
            handsOnCompletedDate: value,
            applyHandsOnFilters: filters,
            disabledButton: false
        });
    }

    applyHandsOnCompletedDate = () => {

        this.props.updateUserStatus(this.state.applyHandsOnFilters);

        this.setState({ handsOnDateModal: false, handsOnCompletedDate: undefined });
    }

    ChangePaginated = (page) => {
        this.setState({ pageNo: page }, () => {

            let { userDataSource, userMainDataSource } = this.state;
            // userMainDataSource =  userDataSource.slice(page, 10);
            // this.setState({userMainDataSource:userMainDataSource});



            userMainDataSource = [...userDataSource];
            userMainDataSource.splice(this.state.pageNo, 10);
            this.setState({ userMainDataSource });
        })
    }

    handleChange = (value, numEachPage) => {
        this.setState({
            minValue: (value - 1) * numEachPage,
            maxValue: value * numEachPage
        });
    };

    userRefresh = () => {
        let userID = this.props.location?.state?.userID;
        let params = {
            "filter": {
                "pageNo": this.state.pageNo,
                "pageSize": 10,
                "ids": [userID],
                "fetchObjects": [
                    "USER_ROLES",
                    "USER_LMS"
                ]
            }
        }

        this.InputSearchRef.current.input.state.value = '';
        this.props.getRefreshusers(userID);
        // this.search('')

        this.props.getLMSUsersList(params);
    }

    disableDate = current => {

        return current && current.valueOf() > Date.now();
    }


    render() {


        const userColumns = [
            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
                width: 30,
            },
            {
                title: 'Contact Number',
                dataIndex: 'contactNumber',
                key: 'contactNumber',
                width: 100,
            },
            {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                width: 150,
            },
            {
                title: 'Training Completed',
                dataIndex: 'training',
                key: 'training',
                width: 30,
            },
            {
                title: 'Training Completed Date',
                dataIndex: 'trainingDate',
                key: 'trainingDate',
                width: 30,
            },
            {
                title: 'Hands on Completed',
                dataIndex: 'handsonCompleted',
                key: 'handsonCompleted',
                width: 30,
            },
            {
                title: 'Hands on Completed Date',
                dataIndex: 'handsonCompletedDate',
                key: 'handsonCompletedDate',
                width: 30,
            }
        ];

        let userDataSource = [];

        let lmsuserData = this.props?.lmsUsers?.lmsData?.lms && this.props?.lmsUsers?.lmsData?.lms?.map(lms => {
            lms.userMappings && lms.userMappings.map(lmsUser => {
                userDataSource.push(
                    {
                        key: lmsUser.id,
                        user: lmsUser.user.name,
                        contactNumber: lmsUser.user.contactNumber,
                        roles: lmsUser?.user?.rolesBOs?.map(role => role.name).join(","),
                        training: _.hasIn(lmsUser, 'trainingCompletedDate') ?
                            <>Yes {_.hasIn(this.props.app.configurations, 'trainingMandatoryDate') && this.props.app.configurations.trainingcompletionstatus == 'true' ?
                                <Switch size="small" checked={true} disabled /> : <></>} </> :
                            <>No  {_.hasIn(this.props.app.configurations, 'trainingcompletionstatus') && this.props.app.configurations.trainingcompletionstatus == 'true' ?
                                <Switch size="small" checked={false} onChange={(checked) => this.changetrainingCompletedDate(checked, lmsUser)} /> : _.hasIn(lms, 'trainingMandatoryDate') ?
                                    <Switch disabled checked={false} /> : <></>} </>,
                        // training: _.hasIn(lmsUser, 'trainingCompletedDate') ? 'Yes' : 'No',


                        trainingDate: _.hasIn(lmsUser, 'trainingCompletedDate') ? moment(lmsUser.trainingCompletedDate).format("DD/MM/YYYY HH:MM") : 'N/A',
                        handsonCompleted: _.hasIn(lmsUser, 'handsonCompletedDate') ? <>Yes <Switch size="small" checked={true} disabled /></> : <>No {_.hasIn(lms, 'handsOnMandatoryDate') ? <Switch size="small" checked={false} onChange={(checked) => this.changeHandsOnCompletedDate(checked, lmsUser)} /> : <Switch size="small" checked={false} disabled={true} />}  </>,
                        handsonCompletedDate: _.hasIn(lmsUser, 'handsonCompletedDate') ? moment(lmsUser.handsonCompletedDate).format("DD/MM/YYYY hh:mm") : 'N/A'
                        // status: 'Active'
                    }
                )
            })

        });
        if (this.state.userDataSource.length <= 0 && userDataSource.length > 0 && !this.state.isSearch) {

            this.setState({ userDataSource })
        }









        return (
            <Spin spinning={this.props?.lmsUsers?.loading}>
                <Card>
                    <h3> <Icon onClick={this.goBack} style={{ margin: 15, marginLeft: 0 }} type="arrow-left" />  View Users Details</h3>
                    <Row>
                        <Col span={12}>
                            <Input.Search allowClear size={'large'} placeholder="Search by name and contact number" ref={this.InputSearchRef} onSearch={value => this.search(value.trim())} enterButton />
                        </Col>
                        <Col span={12} push={8}>
                            <Button onClick={this.userRefresh} style={{ marginLeft: '75px' }}><Icon type="reload" />Refresh</Button>
                        </Col>
                    </Row>

                    <Table
                        bordered
                        columns={userColumns}
                        dataSource={this.state.userDataSource.slice(this.state.minValue, this.state.maxValue)}
                        pagination={{
                            total: this.props?.lmsUsers?.lmsData?.lms[0]?.userMappings?.length,
                            position: 'bottom',
                            onChange: this.handleChange,
                            pageSize: 10,
                        }}
                    />

                    <Modal
                        title={'Training Completed Date'}
                        width={400}
                        maskClosable={false}
                       
                        visible={this.state.trainingCompletedDateModal}
                        onCancel={this.hideTrainingCompleted}
                        footer={[

                            <Button key="back" onClick={this.hideTrainingCompleted}>
                                Cancel
                        </Button>,
                            <Button key="submit" type="primary" onClick={this.applyTrainingCompletedDate}>
                                Apply
                        </Button>

                        ]}
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <div className="form-label-text">Training Completed Date</div>
                                <DatePicker
                                    showTime
                                    value={this.state.trainingCompletedDate}
                                    onChange={this.onChangeTrainingCompleted}
                                />
                            </Col>

                        </Row>
                    </Modal>




                    <Modal
                        title={'Hands on Completed Date'}
                        width={400}
                       
                        maskClosable={false}
                        visible={this.state.handsOnDateModal}
                        onCancel={this.hideHandsOnCompleted}
                        footer={[

                            <Button key="back" onClick={this.hideHandsOnCompleted}>
                                Cancel
                        </Button>,
                            <Button key="submit" type="primary" disabled={this.state.disabledButton} onClick={this.applyHandsOnCompletedDate}>
                                Apply
                        </Button>

                        ]}
                    >
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <div className="form-label-text">HandsOnCompleted Date</div>
                                <DatePicker
                                    showTime
                                    disabledDate={this.disableDate}
                                    value={this.state.handsOnCompletedDate}
                                    onChange={this.onChangeHandsOnCompleted}
                                />
                            </Col>

                        </Row>
                    </Modal>
                </Card>
            </Spin>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    lmsUsers: state?.lms?.lmsUsers,
    lmsUSERUpdateStatus: state?.lms?.lmsUSERUpdateStatus,
    app: state?.app,

});
const mapDispatchToProps = dispatch => ({
    getLMSUsersList: (params) => dispatch(actions.getLMSUsersList(params)),
    updateUserStatus: (params) => dispatch(actions.updateUserStatus(params)),
    resetUserUpdate: () => dispatch(actions.resetUserUpdate()),
    getRefreshusers: (lmsID) => dispatch(actions.getRefreshusers(lmsID))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
// export default UserDashboard;