import { Icon, Modal, TimePicker } from 'antd';
import moment from 'moment';
import React , { Component } from 'react';
import DatePickerInput from '../../../ui/datepicker/DatePicker';
import editPencil from '../../../../assets/img/icons/edit_box.svg';

class DeliveryDate extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            deliveryDatePicker: false,
            deliveryDateTime: props.consignment?.podCreatedDate,
            consignmentId: props.consignment?.consignmentId,
            deliveryDateTimeCopy: props.consignment?.podCreatedDate
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.consignmentId != nextProps.consignment.consignmentId){
            return {
                ...prevState,
                deliveryDateTime: nextProps.consignment?.podCreatedDate,
                consignmentId: nextProps.consignment?.consignmentId,
                deliveryDateTimeCopy: nextProps.consignment?.podCreatedDate
            }
        }
        return prevState;
    }

    handleDeliveryDateTimeChange = deliveryDateTime => {
        var deliveryDateTime = deliveryDateTime.valueOf();
        this.setState({ deliveryDateTime })
    }

    saveDeliveryTimeChanges = _ => {
        const deliveryDateTime = this.state.deliveryDateTime;
        Modal.destroyAll();
        if(deliveryDateTime > moment().valueOf()){
            return Modal.error({
                title:"Invalid Delivery Date and Time",
                content:"Cannot set a future date/time as Delivery Time!"
            });
        }
        if(this.props.consignment?.pickupDate >= deliveryDateTime) {
            return Modal.error({
                title:"Invalid Delivery Date and Time",
                content:"Delivery Date and Time cannot be earlier than pickup date!"
            });
        }
        this.setState({
            deliveryDatePicker : false,
            deliveryDateTimeCopy: deliveryDateTime
        }, () => {
            this.props.toggleActionButtons(false);
            setTimeout(() => {
                this.props.onDeliveryDateChange('podCreatedDate', deliveryDateTime);
            }, 0);
            document.removeEventListener('click', this.disableEdit);
        })
    }

    disabledDeliveryDate = startDate => {
        const deliveryDate = moment(this.state.deliveryDateTime).format("DD/MM/YYYY");
        if (!startDate || !deliveryDate) { return false; }
        return startDate.valueOf() && startDate.valueOf() > moment().endOf('day');
    }

    disableEdit = e => {
        const testClickIn = selector => {
            const clickIn = document.querySelector(selector) && document.querySelector(selector).contains(e.target);
            return clickIn;
        }
        if (!this.state.deliveryDatePicker || testClickIn(".ant-calendar-picker-container") ||
            testClickIn(".ant-time-picker-panel") || testClickIn(".ant-time-picker") ||
            testClickIn(".ant-modal.ant-modal-confirm.ant-modal-confirm-error") || testClickIn("#deliveryDateTimeActionIcons")) {
            return;
        }
        this.saveDeliveryTimeChanges();
    }

    cancelDeliveryTimeChange = () => {
        this.setState(current => {
            return {
                deliveryDatePicker: false,
                deliveryDateTime: current.deliveryDateTimeCopy
            }
        }, () =>  {
            this.props.toggleActionButtons(true)
            document.removeEventListener('click', this.disableEdit);
        });
    }

    render(){

        const { deliveryDateTime, deliveryDatePicker } = this.state;
        const { editPermissions, podCreatedDate } = this.props;
        const podNotVerified = this.props.consignment?.podVerificationStatus != "POD_VERIFIED"

        return (
            <div className="flex-1 flex-column flex-gap-m">
                <div>Delivery Date and Time</div>
                <div className="flex-box spacer-s grey-bg border-radius-s justify-content-space-between align-items-center"
                     style={{width:535}}>
                    {
                        deliveryDatePicker ?
                        <>
                            <DatePickerInput disabledDate={this.disabledDeliveryDate} id="deliveryDatePicker"
                                type="date" format="DD/MM/YYYY" value={moment(deliveryDateTime)}
                                open={deliveryDatePicker} onChange={this.handleDeliveryDateTimeChange}
                                popupStyle={{
                                    width: 200
                                }}/>
                            <div className="flex-box flex-gap-l align-items-center justify-content-space-between" style={{width: 220}}>
                                <TimePicker use12Hours format="hh:mm A" value={moment(deliveryDateTime)}
                                    onChange={this.handleDeliveryDateTimeChange}/>
                                <div id="deliveryDateTimeActionIcons" className="flex-box flex-gap-l align-items-center justify-content-space-between">
                                    <Icon type="check-circle" theme="twoTone" twoToneColor="#4caf50" style={{ fontSize: 15 }}
                                        onClick={() => this.saveDeliveryTimeChanges(deliveryDateTime)} />    
                                    <Icon type="close-circle" theme="twoTone" twoToneColor="#ff9801" style={{ fontSize: 15 }}
                                        onClick={this.cancelDeliveryTimeChange} />
                                </div>    
                            </div>
                        </> :
                        <>
                            <span style={{width:300}} className={"text-bold" + (podCreatedDate != deliveryDateTime ? " info-color" : "")}>
                                {moment(deliveryDateTime).format('DD/MM/YYYY hh:mm A')}
                            </span>
                            {
                                editPermissions && podNotVerified &&
                                <img src={editPencil} width={15} height={15}
                                    onClick={() => { 
                                        this.setState({deliveryDatePicker : true});
                                        this.props.toggleActionButtons(true);
                                        document.addEventListener('click', this.disableEdit);
                                    }}
                                />
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default DeliveryDate;