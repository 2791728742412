import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";

import EwayService from "../../../services/eway/eway.serrvice";
import { removeDuplicateLocations, notifyApiError } from '../../../common/utils/utils';

const ewayService = new EwayService;

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchEway = (fromDate, toDate) => dispatch => {
    dispatch(fetchEwayBegin());
    ewayService.getEwayBills(fromDate, toDate).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchEwaySuccess(res ?.data ?.response));
        } else {
            dispatch(fetchEwayFailure(res ?.data ?.response));
        }
    });
};


function fetchEwayBegin() {
    return {
        type: _Constants.FETCH_EWAY_BEGIN
    };
}

function fetchEwaySuccess(payload) {
    return {
        type: _Constants.FETCH_EWAY_SUCCESS,
        payload: payload       
    };
}

function fetchEwayFailure(error) {
    return {
        type: _Constants.FETCH_EWAY_FAILURE,
        payload: error
    };
}



export const searchEway = (value) => dispatch => {
    dispatch(searchEwayBegin());
    ewayService.getSearchWayBill(value).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(searchEwaySuccess(res ?.data ?.response));
        } else {
            dispatch(searchEwayFailure(res ?.data ?.response));
        }
    });
};


function searchEwayBegin() {
    return {
        type: _Constants.FETCH_EWAY_BEGIN
    };
}

function searchEwaySuccess(payload) {
    return {
        type: _Constants.FETCH_EWAY_SUCCESS,
        payload: payload       
    };
}

function searchEwayFailure(error) {
    return {
        type: _Constants.FETCH_EWAY_FAILURE,
        payload: error
    };
}

