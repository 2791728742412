import React, { Component } from "react";
import "./scan.scss";
import {
  Row,
  Col,
  Card,
  Radio,
  Input,
  Table,
  Empty,
  Icon,
  Spin,
  Modal,
} from "antd";
import BulkOperation from "../../shared-components/bulk-operation";
import WaybillLink from "../../shared-components/waybill-link";
import moment from "moment";
import ScanSticker from "../../shared-components/scan-sticker";
import {
  notifyApiError,
  trimByConfig,
  isScanedOrKeyboardEntry,
  dataGet,
  scanResponseDataMap,
  getLocationData,
} from "../../../common/utils/utils";
import { Constants } from "../../../common/utils/constants";
import { SoundNotificationService } from "../../../services/sound.service";
import Sound from "../../../assets/audio/scan-warning.mp3";
import ScanService from "../../../services/inbound/scan.service";
import * as apiUtils from "../../../common/utils/apiGateway";
import _ from "lodash";

const soundNotificationService = new SoundNotificationService();
const { confirm, warning } = Modal;
class NewScanUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: "AWB No",
          dataIndex: "awbNo",
          key: "awbNo",
          render: (data, row) => <WaybillLink>{data}</WaybillLink>,
        },
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
        },
        {
          title: "Destination",
          dataIndex: "destination",
          key: "destination",
        },
        {
          title: "Customer",
          dataIndex: "customer",
          key: "customer",
        },
        {
          title: "Flow Type",
          dataIndex: "flowType",
          key: "flowType",
        },
        {
          title: "Scan Time",
          dataIndex: "scanTime",
          key: "scanTime",
          render: (data) => (
            <div>
              {moment(data).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data).fromNow()}</small>{" "}
            </div>
          ),
        },
        {
          title: "Created On",
          dataIndex: "createdOn",
          key: "createdOn",
          render: (data) => (
            <div>
              {moment(data).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data).fromNow()}</small>{" "}
            </div>
          ),
        },
      ],
      waybillScanEnabled: true,
      barcodeEnabled: false,
      ornEnabled: false,
      selectedScanView: "",
      scanEntry: true,
      inputEntryTimeList: [],
      inBoundScanning: false,
      currentScanEntryTime: 0,
      scanResponseStatus: { success: false, error: false },
      scanResponse: {},
      rowsFilter: [],
      tempFilter: [],
      lastScanTime: 0,
      totalScannedItems: 0,
      sampleInscanDownloadLink:
        "https://loadshare-v2.s3.ap-south-1.amazonaws.com/dev/orn_bulk_upload.xlsx",
    };
    this.InputSearchRef = React.createRef();
    // this.soundWarning = new Audio(Sound).play();
  }

  componentDidMount() {
    this.focusInput();
    if (Constants.SELECTED_APPLICATION === "B2B") {
      this.setState((state) => ({
        columns: [
          ...state.columns,
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (data, row) => {
              const id = row?.consingment?.id || row?.consingmentBO?.id;
              return row?.consingment?.id ? (
                <a
                  href={`/appv2/print_information?consignmentId=${row?.consingment?.id}&formtype=consignment`}
                  target="_blank"
                >
                  {" "}
                  <Icon type="eye"></Icon>{" "}
                </a>
              ) : (
                <Icon style={{ opacity: 0.5 }} type="eye"></Icon>
              );
            },
          },
        ],
      }));
    }
    if (this.props.permissionList.waybill_scan) {
      this.radioCheck("waybillScanEnabled");
    }
    if (
      this.props.permissionList.barcode_scan &&
      this.props.application == "B2B"
    ) {
      this.radioCheck("barcodeEnabled");
    }
    if (this.props.permissionList.orn_scan) {
      this.radioCheck("ornEnabled");
    }
    // this.InputSearchRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scanedInfo !== prevProps.scanedInfo) {
      this.consignmentScanRepsonse(this.props.scanedInfo);
    }
  }

  focusInput = () => {
    this.setState(
      {
        inputEntryTimeList: [],
        currentScanEntryTime: new Date().getTime(),
        waybillNumber: "",
      },
      () => {
        this.InputSearchRef.current.focus();
        // this.InputSearchRef.focus();
      }
    );
  };

  consignmentScanRepsonse = (responseData) => {
    if (responseData) {
      let scanResponseStatus,
        totalScannedItems = 0;
      const scanResponse = responseData[0];
      let scanResponseDataCurated = {};
      if (scanResponse?.sync) {
        if (dataGet(scanResponse, "consignmentScanBO.consignment.id") != null) {
          scanResponseStatus = { success: true, error: false };
          let scanResponsetemp = {};
          if (scanResponse.consignmentScanBO) {
            scanResponsetemp = {
              cityName:
                scanResponse.consignmentScanBO.consignment.pincode.city
                  .cityCode,
              cityPincode:
                scanResponse.consignmentScanBO.consignment.pincode.zipcode,
              consineeName:
                scanResponse.consignmentScanBO.consignment.customer.name,
              consineeType:
                scanResponse.consignmentScanBO.consignment.paymentType,
              scanTime: moment(),
              waybillNo: scanResponse.awbNo,
              criticalShipment: scanResponse.consignmentScanBO.consignment
                .isCritical
                ? "YES"
                : "NO",
              cpd: scanResponse.consignmentScanBO.consignment
                .expectedDeliveryDate,
              pincode:
                scanResponse.consignmentScanBO.consignment.pincode.zipcode,
              bookingDate:
                scanResponse.consignmentScanBO.consignment.bookingDate,
              nextLocation: scanResponse.consignmentScanBO.consignment
                .nextLocation
                ? scanResponse.consignmentScanBO.consignment.nextLocation.name
                : "",
              localService: scanResponse.consignmentScanBO.consignment
                .isLocalServiceable
                ? "YES"
                : "NO",
            };
          }
          const consingment = scanResponse?.consignmentScanBO?.consignment;
          const consingeePincode = consingment?.consignee?.pincode
            ? consingment.consignee.pincode
            : consingment.pincode;
          const shipperPincode =
            consingment?.customerPickupLoc?.address?.pincode;

          const rowsFilterNew = {
            awbNo: scanResponse.awbNo,
            source: getLocationData(shipperPincode),
            destination: getLocationData(consingeePincode),
            customer: scanResponsetemp.consineeName,
            flowType: dataGet(consingment, "flowType", "NA"),
            consingment,
            scanTime: scanResponsetemp.scanTime,
            createdOn: scanResponse.consignmentScanBO.consignment.bookingDate,
            barcode: scanResponse?.consignmentScanBO?.barcode,
          };
          // if (!isDuplicated)
          let rowsFilter = [...this.state.rowsFilter];
          rowsFilter.unshift(rowsFilterNew);

          const rows = _.uniqBy(rowsFilter, "awbNo");
          // totalScannedItems = rowsFilter.length;
          // let totalScannedItems = _.uniqBy(rowsFilter, 'awbNo').length;
          let { totalScannedItems } = this.state;
          totalScannedItems = 0;
          // if (rows.length > 0) {
          //     _.forEach(rows, (row, key) => {

          //         if (_.hasIn(row, 'consingment')) {
          //             console.log("row", row)
          //             let { shipmentBOs } = row?.consingment;
          //             totalScannedItems += _.uniqBy(shipmentBOs, 'id').length;
          //         }
          //     })

          // }

          if (rowsFilter.length > 0) {
            _.forEach(rowsFilter, (row, key) => {
              if (_.hasIn(row, "consingment")) {
                // console.log("row", row)
                // let { shipmentBOs } = row?.consingment;
                totalScannedItems += 1;
              }
            });
          }

          // let totalScannedItems = rowsFilter.length;

          let tempscanResponseDataCurated = {};
          if (scanResponse.scanResponse) {
            tempscanResponseDataCurated = scanResponseDataMap(
              scanResponse.scanResponse
            );
            if (
              scanResponse.consignmentScanBO &&
              scanResponse.consignmentScanBO.consignment &&
              scanResponse.consignmentScanBO.consignment.nextLocation
            ) {
              tempscanResponseDataCurated["nextLocation"] = {
                value: scanResponse.consignmentScanBO.consignment.nextLocation
                  ? scanResponse.consignmentScanBO.consignment.nextLocation
                      .entity.custObject.name +
                    " | " +
                    scanResponse.consignmentScanBO.consignment.nextLocation.name
                  : "",
                key: "Next Location",
              };
            }

            scanResponseDataCurated = Object.assign(
              {},
              tempscanResponseDataCurated
            );

            if (
              scanResponseDataCurated.category.value &&
              scanResponseDataCurated.category.value.indexOf([
                "HIGH",
                "CRITICAL",
              ])
            ) {
              soundNotificationService.playSuccess();
            } else {
              soundNotificationService.playSuccess();
            }
            //send this row filter back to parent component;
            // this.bindDataToParent();
          } else {
            soundNotificationService.playSuccess();
          }
          this.setState(
            {
              scanResponseDataCurated,
              rowsFilter,
              totalScannedItems,
              scanResponse: scanResponsetemp,
              scanResponseStatus,
              inBoundScanning: false,
            },
            () => {
              this.focusInput();
            }
          );
        } else {
          scanResponseStatus = { success: false, error: true };
          soundNotificationService.playWarning();
          const rowsFilterNew = {
            awbNo: scanResponse.awbNo,
            source: "No Booking Data",
            destination: "No Booking Data",
            customer: "No Booking Data",
            scanTime: moment(),
            createdOn: moment(),
          };

          let rowsFilter = [...this.state.rowsFilter];
          rowsFilter.unshift(rowsFilterNew);

          // const rows = _.uniqBy(rowsFilter, 'awbNo');
          // let { totalScannedItems } = this.state;
          // if (rows.length > 0) {
          //     _.forEach(rows, (row, key) => {

          //         if (_.hasIn(row, 'consingment')) {
          //             // totalScannedItems = 0;
          //             let { shipmentBOs } = row?.consingment;
          //             totalScannedItems += _.uniqBy(shipmentBOs, 'id').length;
          //             console.log("rpw", row, totalScannedItems)
          //         }

          //     })
          // }
          // console.log("totalScannedItems",totalScannedItems, rows)

          // let { shipmentBOs } = rowsFilter?.consignment;

          // // let totalScannedItems = rowsFilter.length;
          // let totalScannedItems = _.uniqBy(shipmentBOs, 'id').length;
          // let totalScannedItems = _.uniqBy(rowsFilter, 'awbNo').length;
          this.setState(
            {
              rowsFilter,
              // totalScannedItems,
              scanResponseStatus,
              inBoundScanning: false,
            },
            () => {
              this.focusInput();
            }
          );

          //send this row filter back to parent component;
          // this.bindDataToParent();
        }
      } else {
        this.setState({ inBoundScanning: false }, () => {
          this.focusInput();
        });
        notifyApiError(scanResponse.reason, "Error");
        soundNotificationService.playWarning();
      }
    }
  };

  radioCheck = (scanType) => {
    this.setState({
      waybillScanEnabled: false,
      barcodeEnabled: false,
      ornEnabled: false,
      [scanType]: true,
      selectedScanView: scanType,
      scanResponseStatus: { success: false, error: false },
      inputPlaceholder:
        scanType === "waybillScanEnabled"
          ? "Waybill"
          : scanType === "barcodeEnabled"
          ? "Barcode"
          : "ORN",
    });
  };

  scanDrsByKeyboard = (event, value) => {
    const waybillNumber = value.trim();
    // const inputEntryTimeList = [];
    if (!waybillNumber || waybillNumber.length === 0) {
      this.setState({
        scanEntry: false,
        waybillNumber,
      });
      return;
    }

    // let lastScanTime = this.state.currentScanEntryTime
    //     ? this.state.currentScanEntryTime
    //     : new Date().getTime();
    // let currentScanEntryTime = new Date().getTime();

    // if (waybillNumber && waybillNumber.length > 1) {
    //     inputEntryTimeList.push(
    //         currentScanEntryTime - lastScanTime
    //     );
    // }

    this.setState({
      waybillNumber,
      // lastScanTime,
      // currentScanEntryTime,
      // inputEntryTimeList: [...this.state.inputEntryTimeList, ...inputEntryTimeList]
    });
  };

  scanWayBillBarcode = (data) => {
    return apiUtils.apiRequest({
      url: `/b2b/v2/consignments/scan`,
      method: "POST",
      data,
    });
  };

  scanWayBill = (entityType) => {
    this.setState({
      inBoundScanning: true,
      scanResponseStatus: { success: false, error: false },
      scanResponseDataCurated: {},
    });
    if (
      this.state.waybillNumber == null ||
      this.state.waybillNumber.trim() === ""
    ) {
      return "";
    }
    let waybillNumber = this.state.waybillNumber.trim();
    if (this.props.config !== null) {
      waybillNumber = trimByConfig(
        waybillNumber,
        this.props.config["waybillLengthRestriction"]
      );
    }
    // let scanEntry = isScanedOrKeyboardEntry(
    //     this.state.inputEntryTimeList
    // );

    let scanEntry = true;

    // this.setState({
    //     scanEntry
    // })
    if (this.checkForDuplicate(waybillNumber, entityType)) {
      notifyApiError(
        `${
          entityType === "waybill" ? "Waybill" : "Barcode"
        } was already scanned`,
        "Duplicate"
      );

      this.setState({
        waybillNumber: null,
        inBoundScanning: false,
        scanResponseStatus: { success: false, error: false },
        scanResponseDataCurated: {},
        // rowsFilter: this.state.rowsFilter.filter(item => entityType === 'waybill' ? item.awbNo !== waybillNumber:  item?.barcode !== waybillNumber)
      });
      // isDuplicated = true;
      return false;
    }
    let consignment;
    if (entityType === "waybill") {
      consignment = { waybillNo: waybillNumber, isBarcodeScan: false };
    } else {
      consignment = { barcode: waybillNumber, isBarcodeScan: true };
    }

    if (Constants.SELECTED_APPLICATION === "B2B") {
      let data = {
        request: [
          {
            type: "IN_SCAN",
            consignmentScan: {
              isBarcodeScan: entityType === "waybill" ? false : true,
              scanType: "IN_SCAN",
              ...(entityType === "waybill"
                ? { waybillNo: waybillNumber }
                : { barcode: waybillNumber }),
            },
            referenceId: waybillNumber,
          },
        ],
      };
      this.scanWayBillBarcode(data).then((res) => {
        console.log("response", res);
        if (res) {
          let response = res?.data;
          if (
            response?.status?.code === 202 &&
            response?.response?.responses?.length > 0
          ) {
            this.consignmentScanRepsonse(response?.response?.responses);
          } else if (
            response?.status?.code === 206 &&
            response?.response?.consignmentIdErrorMappings?.length > 0
          ) {
            const _this = this;
            confirm({
              title: "Warning",
              icon: (
                <Icon type="warning" theme="twoTone" twoToneColor="#FF0000" />
              ),
              okText: "Proceed",
              cancelText: "Cancel",
              content: (
                <>
                  <p>{response?.status?.message}</p>
                  <br />
                  <p>{response?.status?.secondaryMessage}</p>
                </>
              ),
              onOk: () => {
                let newData = {
                  request: [
                    {
                      type: "IN_SCAN",
                      ignoreWarningMessage: true,
                      consignmentScan: {
                        isBarcodeScan: entityType === "waybill" ? false : true,
                        scanType: "IN_SCAN",
                        ...(entityType === "waybill"
                          ? { waybillNo: waybillNumber }
                          : { barcode: waybillNumber }),
                      },
                      referenceId: waybillNumber,
                    },
                  ],
                };
                _this.scanWayBillBarcode(newData).then((newRes) => {
                  let newResponse = newRes?.data;
                  if (
                    newResponse?.status?.code === 202 &&
                    newResponse?.response?.responses?.length > 0
                  ) {
                    _this.consignmentScanRepsonse(
                      newResponse?.response?.responses
                    );
                  }
                });
                // _that.props.createTrip(filters);
              },
              onCancel: () => {
                // console.log("this", _this.state.rowsFilter, response)
                let { rowsFilter } = _this.state;
                let { consignmentIdErrorMappings } = response?.response;
                consignmentIdErrorMappings.map((con) => {
                  rowsFilter = [
                    ...rowsFilter,
                    {
                      awbNo: (
                        <>
                          {con?.referenceId}
                          <br />{" "}
                          <Icon
                            type="info-circle"
                            theme="twoTone"
                            twoToneColor="#FF0000"
                          />
                          <span style={{ color: "#FF0000", cursor: "default" }}>
                            {con?.message}
                          </span>
                        </>
                      ),
                    },
                  ];
                });
                _this.setState({ rowsFilter, inBoundScanning: false }, () => {
                  let data = consignmentIdErrorMappings.map((con) => {
                    return {
                      ...con,
                      reason: con?.message,
                      sync: false,
                    };
                  });
                  _this.consignmentScanRepsonse(data);
                });
              },
            });
          } else if (
            response?.status?.code === 500 &&
            response?.response?.consignmentIdErrorMappings?.length > 0
          ) {
            // const _this = this;
            this.setState({ inBoundScanning: false });
            warning({
              title: "Action Blocked",
              // content: _that.props.createTripError?.status?.message,
              content: (
                <>
                  <p>{response?.status?.message}</p>
                  <br />
                  <p>{response?.status?.secondaryMessage}</p>
                </>
              ),
            });
          }
        } else {
            this.setState({ inBoundScanning: false });
        }
      });
    } else {
      let scanInput = {
        request: [
          {
            consignmentScan: {
              scanType: "IN_SCAN",
              scanInputType: scanEntry ? "BARCODE_SCANNER" : "KEYBOARD",
              ...consignment,
            },
            type: "IN_SCAN",
            referenceId: waybillNumber,
            isWaybill: this.state.waybillScanEnabled ? true : false,
            shipmentScans: [
              {
                shipmentCodeType: "BARCODE",
                scanType: "IN_SCAN",
                scanInputType: scanEntry ? "BARCODE_SCANNER" : "KEYBOARD",
                shipmentCode: waybillNumber,
              },
            ],
          },
        ],
      };

      if (this.props.permissionList.locationSelection) {
        scanInput["request"][0]["custPickLoc"] =
          this.customerPickupLocation && this.customerPickupLocation.value
            ? this.customerPickupLocation.value
            : null;
      }

      scanInput.request[0].shipmentScans = Object.assign(
        scanInput.request[0].shipmentScans,
        consignment
      );
      delete consignment.isBarcodeScan;
      scanInput.request[0].shipmentScans = Object.assign(
        scanInput.request[0].shipmentScans,
        consignment
      );

      this.props.consigmentScan(scanInput);
    }

    // console.log(this.InputSearchRef);
  };

  checkForDuplicate = (waybillNumber, entity) => {
    const isScaned = this.state.rowsFilter.find((consignment) =>
      entity === "waybill"
        ? consignment.awbNo === waybillNumber
        : consignment?.barcode === waybillNumber
    );
    return !!isScaned;
  };

  callbackAfterBulkInscan = () => {};

  render() {
    const { permissionList, application, scanLoading } = this.props;
    const {
      columns,
      selectedScanView,
      sampleInscanDownloadLink,
      inBoundScanning,
      barcodeEnabled,
      waybillNumber,
      totalScannedItems,
      rowsFilter,
      inputPlaceholder,
      scanResponseStatus,
      scanResponse,
    } = this.state;
    return (
      <>
        <Card title={"Scan Shipment"}>
          <div className=" w-full">
            <div className=" max-w-[1240px] mb-5">
              <Radio.Group
                onChange={(e) => this.radioCheck(e.target.value)}
                value={selectedScanView}
              >
                {permissionList.waybill_scan && (
                  <Radio value={"waybillScanEnabled"}>Scan WayBill</Radio>
                )}
                {permissionList.barcode_scan && application == "B2B" && (
                  <Radio value={"barcodeEnabled"}>Barcode Scan</Radio>
                )}
                {permissionList.orn_scan && (
                  <Radio value={"ornEnabled"}>Scan Reference ID</Radio>
                )}
              </Radio.Group>
            </div>
            <div className=" max-w-[1240px] m-auto grid md:grid-flow-col gap-2">
              <div className="  w-44 md:w-76 lg:w-96 gap-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.scanWayBill(barcodeEnabled ? "barcode" : "waybill");
                  }}
                >
                  <Input
                    size="large"
                    disabled={
                      this.state.inBoundScanning ||
                      this.props.auditStatus?.isAuditOn
                    }
                    placeholder={`Scan ${inputPlaceholder}`}
                    onChange={(e) => this.scanDrsByKeyboard(e, e.target.value)}
                    ref={this.InputSearchRef}
                    // ref={(input) => { this.InputSearchRef = input; }}
                    value={waybillNumber}
                  />
                  <small>
                    Use Barcode Scanner for faster process or enter manually
                  </small>
                </form>
              </div>
              <div className=" w-10 gap-2">
                {permissionList.bulk_inscan && (
                  <div className="element-splitter">{"OR"}</div>
                )}
              </div>
              <div className=" w-28 gap-2">
                {permissionList.bulk_inscan && (
                  <BulkOperation
                    buttonLabel="Bulk Inscan"
                    title="Bulk Inscan"
                    bulkType="Inscan"
                    sampleDownloadLink={sampleInscanDownloadLink}
                    objKey="inscan"
                    path="bulk_inscans"
                    syncToServerAction="UPLOAD_INSCAN"
                    uploadMethod={new ScanService().uploadInscan}
                    uploadMethodParams={this.props.uploadInscanMethodParams}
                    callback={this.callbackAfterBulkInscan}
                  />
                )}
              </div>
              <div className=" w-52 gap-2">
                <h2>Count: {this.state.totalScannedItems}</h2>
              </div>
            </div>
          </div>
          {/* <Row className="scan-row" gutter={24}>
                    <Col span={24}>
                        <Radio.Group onChange={e => this.radioCheck(e.target.value)} value={selectedScanView}>
                            {
                                permissionList.waybill_scan && <Radio value={'waybillScanEnabled'}>Scan WayBill</Radio>
                            }
                            {
                                (permissionList.barcode_scan && application == 'B2B') && <Radio value={'barcodeEnabled'}>Barcode Scan</Radio>
                            }
                            {
                                permissionList.orn_scan && <Radio value={'ornEnabled'}>Scan Reference ID</Radio>
                            }
                        </Radio.Group>
                    </Col>
                    <Col span={10}>
                        <form onSubmit={e => { e.preventDefault(); this.scanWayBill(barcodeEnabled ? 'barcode' : 'waybill') }}>
                            <Input
                                size="large"
                                disabled={(this.state.inBoundScanning || this.props.auditStatus?.isAuditOn)}
                                placeholder={`Scan ${inputPlaceholder}`}
                                onChange={e => this.scanDrsByKeyboard(e, e.target.value)}
                                ref={this.InputSearchRef}
                                // ref={(input) => { this.InputSearchRef = input; }}
                                value={waybillNumber}
                            />
                            <small>Use Barcode Scanner for faster process or enter manually</small>
                        </form>
                    </Col>
                    <Col span={1}>
                        {
                            permissionList.bulk_inscan && <div className="element-splitter">
                                {'OR'}
                            </div>
                        }
                    </Col>
                    <Col span={3}>
                        {
                            permissionList.bulk_inscan && <BulkOperation
                                buttonLabel="Bulk Inscan"
                                title="Bulk Inscan"
                                bulkType="Inscan"
                                sampleDownloadLink={sampleInscanDownloadLink}
                                objKey="inscan"
                                path="bulk_inscans"
                                syncToServerAction="UPLOAD_INSCAN"
                                uploadMethod={(new ScanService).uploadInscan}
                                uploadMethodParams={this.props.uploadInscanMethodParams}
                                callback={this.callbackAfterBulkInscan}
                            />
                        }
                    </Col>
                    <Col span={5} offset={5}>
                        <h2>Count:  {this.state.totalScannedItems}</h2>
                    </Col>
                </Row> */}
          {/* <div className=' max-w-[1240px] grid md:grid-flow-col gap-2 text-xl'>
                
                    {
                        (!this.state.scanResponseDataCurated?.locationCode && application === 'B2B') && <div className="">
                            {
                                scanResponseStatus.success && <div className=' w-14 gap-2'>{'Current Scan'}</div>
                            }
                            {
                                scanResponseStatus.success && <div className=' w-20 gap-2'>
                                    <div className="details">
                                        <span className="destination">
                                            {
                                                scanResponse?.cityName
                                                    ? `${scanResponse.cityName} -`
                                                    : ""
                                            }
                                            {scanResponse?.cityPincode}
                                        </span>
                                        <span className="timestamp p-t-5 p-r-5">
                                            {
                                                moment(scanResponse?.scanTime).format('DD/MM/YYYY HH:mm')
                                            }
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                scanResponseStatus.success && <div className=' w-44 text-center gap-2'>
                                    <Icon style={{ marginRight: '15px' }} theme="filled" type="check-circle" />
                                    {'Successfully In-Scanned!'}
                                </div>
                            }
                        </div>
                    }
                    {
                        scanResponseStatus.error ? <div className=' w-full md:w-24 gap-2'>
                            {'Current Scan  '}
                            <span style={{ color: "rgb(245, 0, 0)", marginLeft: "10px" }}>Added to Overage</span>
                        </div> : <></>
                    }
                </div> */}

          <div
            className="scan-row curernt-scan mt-1"
            style={{ fontSize: "20px" }}
          >
            {!this.state.scanResponseDataCurated?.locationCode &&
              application === "B2B" && (
                <div className=" grid grid-flow-row lg:grid-flow-col gap-2">
                  {scanResponseStatus.success && (
                    <div className="w-32">{"Current Scan"}</div>
                  )}
                  {scanResponseStatus.success && (
                    <div className="w-full md:w-80">
                      <div className="details grid w-44 md:w-full lg:w-full md:inline-block">
                        <span className="destination curreent-font-size">
                          {scanResponse?.cityName
                            ? `${scanResponse.cityName} -`
                            : ""}
                          {scanResponse?.cityPincode}
                        </span>
                        <span className="timestamp p-t-5 p-r-5">
                          {moment(scanResponse?.scanTime).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {scanResponseStatus.success && (
                    <div className="w-64 md:w-72">
                      <Icon
                        style={{ marginRight: "15px" }}
                        theme="filled"
                        type="check-circle"
                      />
                      {"Successfully In-Scanned!"}
                    </div>
                  )}
                </div>
              )}
            {scanResponseStatus.error ? (
              <div className="w-full">
                {"Current Scan  "}
                <span style={{ color: "rgb(245, 0, 0)", marginLeft: "10px" }}>
                  Added to Overage
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* <Row className="scan-row curernt-scan" style={{ fontSize: '20px' }} gutter={24}>
                    {
                        (!this.state.scanResponseDataCurated?.locationCode && application === 'B2B') && <div className="">
                            {
                                scanResponseStatus.success && <Col span={4}>{'Current Scan'}</Col>
                            }
                            {
                                scanResponseStatus.success && <Col span={6}>
                                    <div className="details">
                                        <span className="destination">
                                            {
                                                scanResponse?.cityName
                                                    ? `${scanResponse.cityName} -`
                                                    : ""
                                            }
                                            {scanResponse?.cityPincode}
                                        </span>
                                        <span className="timestamp p-t-5 p-r-5">
                                            {
                                                moment(scanResponse?.scanTime).format('DD/MM/YYYY HH:mm')
                                            }
                                        </span>
                                    </div>
                                </Col>
                            }
                            {
                                scanResponseStatus.success && <Col className="text-center" span={10}>
                                    <Icon style={{ marginRight: '15px' }} theme="filled" type="check-circle" />
                                    {'Successfully In-Scanned!'}
                                </Col>
                            }
                        </div>
                    }
                    {
                        scanResponseStatus.error ? <Col span={24}>
                            {'Current Scan  '}
                            <span style={{ color: "rgb(245, 0, 0)", marginLeft: "10px" }}>Added to Overage</span>
                        </Col> : <></>
                    }
                </Row> */}
        </Card>
        {!this.state.inBoundScanning ? (
          <ScanSticker
            scanResponseStatus={this.state.scanResponseStatus}
            isBarcode={selectedScanView}
            scanResponseDataCurated={this.state.scanResponseDataCurated}
          />
        ) : (
          <div className="scan-box">
            <Spin spinning={scanLoading}></Spin>
          </div>
        )}
        <Card title={"Previous Scan"}>
          <div className=" overflow-auto">
            <Table
              bordered
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
              }}
              loading={false}
              pagination={false}
              dataSource={rowsFilter}
              columns={columns}
              className=" w-full"
            />
          </div>
        </Card>
      </>
    );
  }
}

export default NewScanUI;
