import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';

export default class LiveLoadsService {

  getLiveLoads(payload) {
    const url = `/b2b/v1/consignments/live-loads-customer`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getLiveLoadsSummary(payload) {
    const url = `/b2b/v1/consignments/live-loads-summary-customer`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  fetchLocations(payload) {
    const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/location-groups/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getExportReports(type, reports) {
    let url = `/b2b/v1/reports/${type}/get`;
    const user = Constants.sessionUser;

    let params = {
      userId: user.id,
      consignmentLiveLoadFilters: { ...reports }
    };

    // let params = {
    //   "userId": 14048,
    //   "consignmentLiveLoadFilters": {
    //     "consignmentFilter": {
    //       "statuses": [
    //         "IN",
    //         "IN_TRANSIT",            
    //       ],
    //       "bookingDate": {
    //         "from": "1593023401963",
    //         "to": "1593455399883"
    //       },
    //       "entityTypes": [
    //         "NONE",
    //         "MANIFEST"
    //       ],
    //       "customerPickupLocationIds": [
    //         "238725"
    //       ],
    //       "customerIds": [
    //         "2710"
    //       ]                    
    //     }
    //   }
    // }

    return apiRequest({
      method: 'POST',
      url,
      data: params
    });
  }

  getDownloadPOD(params) {
    return apiRequest({
      method: 'POST',
      url: '/b2b/v1/document/CONSIGNMENT_POD/download/',
      data: params
    });
  }

}
