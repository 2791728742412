import * as apiUtils from '../../../../../common/utils/apiGateway';
import { checkAPIHttpStatus, notifyApiSuccess } from '../../../../../common/utils/utils';
import CustomerService from "../../../../../services/settings/customer.service";
import LocationService from "../../../../../services/settings/location.service";
import UserService from '../../../../../services/settings/user.service';
const locationService = new LocationService();
const userService = new UserService();

//Contracts fetch
export const fetchContracts = params => new Promise(resolve => {
    if (params) {
        if (params.isActive === false) {
            delete params.isActive;
        }
        const url = `/b2b/v1/contracts/list`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data: params
        }).then(response => {
            let contracts = [];
            contracts = response?.data?.response;
            if (!contracts) {
                resolve(false);
            }
            else {
                resolve(contracts);
            }
        })
    }
    else {
        resolve(false);
    }
});

// export const getCustomerData = params => new Promise(resolve => {
//     if (params.customerId) {
//         CustomerService.getCustomer(params.customerId).then(response => {
//             if (checkAPIHttpStatus(response?.data?.status)) {
//                 let customer = response.data.response;
//                 if (customer) {
//                     resolve(customer);
//                 } else {
//                     resolve(false);
//                 }
//             } else {
//                 resolve(false);
//             }

//         })
//     }
//     else {
//         resolve(false);
//     }
// });
export const getCustomerData = payload => new Promise((resolve, reject) => {
    if (payload) {
        CustomerService.getCustomersFetch(payload, false).then(response => {
            if (checkAPIHttpStatus(response?.data?.status, false)) {
                let customer = response?.data?.response?.customers;
                if (customer) {
                    resolve(customer);
                } else {
                    reject(response?.data?.status);
                }
            } else {
                resolve(response?.data?.status);
            }

        })
    }
    else {
        resolve(false);
    }
});

export const createCustomer = data => new Promise(resolve => {
    if (data) {
        CustomerService.createCustomerV2(data).then(response => {
            // if (checkAPIHttpStatus(response?.data?.status)) {
            let customer = response?.data?.response;
            if (customer) {
                notifyApiSuccess("Customer Created!!", "SUCCESS");
                resolve(customer);
            } else {
                resolve(false);
            }
            // } else {
            //     resolve(false);
            // }
        })
    }
    else {
        resolve(false);
    }
});

export const updateCustomer = (id, data) => new Promise(resolve => {
    if (data) {
        CustomerService.updateCustomerV2(id, data).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                let customer = response.data.response;
                if (customer) {
                    notifyApiSuccess("Customer Updated!!", "SUCCESS");
                    resolve(customer);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const updateShipper = (data) => new Promise(resolve => {
    if (data) {
        CustomerService.updateShippers(data).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                let shippers = response.data.response.entityShipperMappingBO;
                if (shippers) {
                    resolve(shippers);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchPincode = params => new Promise(resolve => {
    if (params) {
        const url = `/b2b/v1/pincodes/${params.pincode}`;
        apiUtils.apiRequest({
            url,
            method: "GET",
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchLocationZones = params => new Promise(resolve => {
    if (params) {
        const url = `/b2b/v1/zone/fetch`;
        const data = {
            "filters": {
                "isActive": true,
                "fetchObjects": [],
                "entityTypes": ["CUSTOMER"],
                "entityIds": [params.entityId],
                "zipCode": params.zipCode,
                "zoneTypes": ["RATECARD"]
            }

        }
        apiUtils.apiRequest({
            url,
            method: "POST",
            data: data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const createLocation = data => new Promise(resolve => {
    if (data) {
        locationService.createLocationV2(data).then(response => {
            if (response?.data?.status.code === 200 || response?.data?.status.code === 201 || response?.data?.status.code === 202) {
                let location = response.data.response;
                if (location) {
                    notifyApiSuccess("Location Created!!", "SUCCESS");
                    resolve(location);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const updateLocation = (locationId, data) => new Promise(resolve => {
    if (data) {
        locationService.updateLocationV2(locationId, data).then(response => {
            if (response?.data?.status.code === 200 || response?.data?.status.code === 201 || response?.data?.status.code === 202) {
                let location = response.data;
                if (location) {
                    notifyApiSuccess("Location Updated!!", "SUCCESS");
                    resolve(location);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchHistory = (data) => new Promise(resolve => {
    if (data) {
        const url = `/b2b/v1/contracts/history`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data
        }).then(response => {
            if (checkAPIHttpStatus(response?.data?.status)) {
                resolve(response?.data?.response);
            }
            else {
                resolve(false);
            }
        })
    }
    else {
        resolve(false);
    }
});

export const fetchUsers = (params) => new Promise(resolve => {
    let data = {
        filters: {
            ...(params?.partialContentSearch && { partialContentSearch: params?.partialContentSearch }),
            "fetchObjects": [
                "ADDRESS"
            ]
        }
    };

    userService.getUsersfetch(data).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        }
        else {
            resolve(false);
        }
    })
});
