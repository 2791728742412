

import { apiRequest } from '../common/utils/apiGateway';
import { Constants } from '../common/utils/constants';

export default class SupportService {

    tripCorrectionLoadDockets(payload) {
        const url = `/v1/support/trip-correction/load-dockets`;
        return apiRequest({
            url,
            method: "PUT",
            data: payload
        });
    }
    tripCorrectionMarkDel(payload) {
        const url = `/v1/support/trip-correction/mark-del`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    }
    tripCorrectionHangover(payload) {
        const url = `/v1/support/trip-correction/handover`;
        return apiRequest({
            url,
            method: "POST",
            data: payload
        });
    }
}