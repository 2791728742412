import * as apiUtils from '../../../../../../common/utils/apiGateway';
import { checkHttpStatus } from '../../../../../../common/utils/utils';

export const fetchDetentionCharges = (contractId, laneMappingId) => new Promise((resolve, reject) => {
    fetchLaneChargesDetails(contractId, laneMappingId).then(boxChargesListResp => {
        var detentionCharges = false;
        if (boxChargesListResp.chargesBO) {
            detentionCharges = boxChargesListResp.chargesBO.find(charge => charge.chargeType == "DETENTION_CHARGE");
        }
        if (detentionCharges) {
            resolve(detentionCharges);
        }
        else {
            reject(boxChargesListResp)
        }
    }).catch(_ => reject(false))
})

export const fetchLaneChargesDetails = (contractId, laneMappingId) => new Promise(resolve => {
    apiUtils.apiRequest({
        url: `/b2b/v1/contracts/lanes/charges?contractId=${contractId}&laneMappingId=${laneMappingId}`,
        method: "GET"
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            const { labourDetentionChargesBO } = response.data.response;
            if (!labourDetentionChargesBO) {
                resolve(false);
            }
            else {
                resolve(labourDetentionChargesBO);
            }
        }
        else {
            resolve(false);
        }
    })
})

export const getSLADetails = (contractId, laneMappingId) => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url         : `/b2b/v1/contracts/lanes/sla?contractId=${contractId}&laneMappingId=${laneMappingId}`,
        method      : "GET",
        showMessage : false
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            if (response?.data?.response?.slabo) {
                resolve(response?.data?.response?.slabo);
            }
            else  {
                reject(response?.data?.response);
            }
        }
        else {
            reject(response?.data?.response);
        }
    })
})

export const postLaneSLA = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url         : "/b2b/v1/contracts/sla/save",
        method      : "POST",
        data        : data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            if (response?.data?.response?.laneMappingBO?.entityBO?.slaBO) {
                resolve(response?.data?.response?.laneMappingBO?.entityBO?.slaBO);
            }
            else  {
                reject(response?.data?.response);
            }
        }
        else {
            reject(response?.data?.response);
        }
    })
})

export const postLabourDetentionCharges = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url         : "/b2b/v1/contracts/labourCharges/save",
        method      : "POST",
        data        : data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            if (response?.data?.response?.laneMappingBO?.entityBO?.labourDetentionChargesBO) {
                resolve(response?.data?.response?.laneMappingBO?.entityBO?.labourDetentionChargesBO);
            }
            else  {
                reject(response?.data?.response);
            }
        }
        else {
            reject(response?.data?.response);
        }
    })
})