import React, { Component } from "react";
import { formatDate, formatDateoption } from "../../../../common/utils/utils";

export default function RiskTemplate(props) {
  const { time } = props;
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: true,
  };
  return <span>{formatDate(time, formatDateoption(options))}</span>;
}
