import React, { Component } from "react";
import {
  Layout,
  Menu,
  
  Icon as AntdIcon,  
} from "antd";
import { menuconfig } from "../../../configs/menu-config";
import {
  angularUrl,
  reactUrl,
} from "../../../environments/node-react-environment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { icon as Icon } from "../../ui/icon/Icon";
import "./Sidebar.scss";

import { AuthenticationService } from "../../../services/authentication.service";
import { setSidebarState } from "../../../app/appAction";

const { Sider } = Layout;
const { SubMenu } = Menu;
class Sidebar extends Component {
  state = {
    menuItems: [],
    selectedMenuKey: null,
    collapsed: false,
    configFlag: false,
    confirmModalVisible: false,
    selectedConfirmModal: "",
    submenuSelected: "",
    isResize:0
    
  };
  onCollapse = () => {
    
    // this.setState({collapsedSidebar: !this.state.collapsedSidebar});
    if (window.screen.availWidth > 530) 
    {
      this.props.setSidebarState(!this.props.collapsedSidebar);
    }
    
  };
  componentDidMount() {
    
    if (this.props.location.pathname !== "/appv2/") {
      this.selectMenuAndTabForValidUrl();
    }
    window.addEventListener("resize", this.resize.bind(this), false);
    this.resize();
  }

  selectMenuAndTabForValidUrl = () => {
    if (!this.props.history.location.pathname) {
      return;
    }
    const pathParts = this.props.history.location.pathname.split("/");
    const tabPath = pathParts[pathParts.length - 1];
    const menuPath = pathParts.slice(2, 3).join("/");
    const menuToBeSelected = menuconfig.find((menu) => {
      return menu.path === menuPath;
    });
    if (menuToBeSelected) {
      menuToBeSelected.children.find((child, index) => {
        return child.path === tabPath;
      });
      const allowedMenus = menuconfig.filter((menu) => {
        return menu.canAllow;
      });
      if (tabPath === "") {
        let isFirst = true;
        menuToBeSelected.children.map((child, index) => {
          if (child.canAllow && isFirst) {
            isFirst = false;
          }
        });
      }      
      this.setState({
        selectedMenuKey: menuToBeSelected.key,
        menuItems: allowedMenus,
        submenuSelected: tabPath,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configurations !== this.props.configurations) {
      if (this.props.location.pathname !== "/appv2/") {
        this.selectMenuAndTabForValidUrl();
      }
    }
  }

  resize() {
    if (this.props.collapsedSidebar == false) 
    {
      // setTimeout(() => {
        if (window.screen.availWidth <= 530) 
        {
          this.props.setSidebarState(!this.props.collapsedSidebar);
        }
      // }, 1500);  
    }
  }

  getUrlDirection = (path, type) => {
    if (type) {
      this.props.history.push(`/appv2/${path}/dashboard`);
    } else {
      window.location.replace(`${angularUrl}${path}/dashboard`);
    }
  };

  getNewUrlDirection = (path, newPath) => {
    this.props.history.push(`/appv2/${path}/dashboard/${newPath}`);
  };

  getMenuHtml = (data) => {
    return data.map((item, i) => {
      let redirectUrl;
      redirectUrl = item.isReact
        ? reactUrl + "/appv2/" + item.path + "/dashboard"
        : angularUrl + item.path + "/dashboard";
      let newTab = false;
      let isSelected = false;
      if (item.type === "external-link") {
        newTab = true;
      }
      isSelected = item.key === this.state.selectedMenuKey;
      let classes = ["menu-item-container"];
      let aHtml = null;
      if (item.isReact) {
        aHtml = (
          <a
            className="menu-item-label"
            onClick={() => this.getUrlDirection(item.path, "react")}
            target={newTab ? "_blank" : "_self"}
          >
            {item.label}
          </a>
        );
      }
      // let aHtml = <a className="menu-item-label" href={redirectUrl} target={newTab ? '_blank' : '_self'}>{item.label}</a>;
      if (isSelected) {
        classes.push("menu-item-container-active");
        aHtml = <span className="menu-item-label">{item.label}</span>;
      }
      return (
        <SubMenu
          key={`${item.path}${i}`}
          className={
            isSelected
              ? "menu-item-container-active menu-item-container-submenu"
              : "menu-item-container-submenu"
          }
          title={
            <>
              {/* <Tooltip title={item.label}> */}
              <Icon
                // type="menu-fold"
                img={isSelected ? item.activeIcon : item.inactiveIcon}
                className=" inline-grid max-w-none p-1 mr-4 w-9 rounded"
              /> 
              {/* <img src={isSelected ? item.activeIcon : '../../assets/img/icons/'+item.inactiveIcon} className=" inline-grid p-1 mr-4 w-9 rounded"/> */}
              
              {item.label}
              {/* {isSelected ? (
                <span className="menu-item-label">{item.label}</span>
              ) : (
                item.label
              )}   */}
              {/* </Tooltip> */}
            </>
          }
        >
          {item.children.map((subItem, index) => {
            if (subItem.canAllow) {
              return (
                <Menu.Item
                  key={`${subItem.path}${index}`}
                  className={`${
                    this.state.submenuSelected === subItem.path
                      ? "menu-item-container menu-item-sub-child submenu-selected"
                      : "menu-item-container menu-item-sub-child"
                  }`}
                  onClick={() =>
                    this.getNewUrlDirection(item.path, subItem.path)
                  }
                >
                  {subItem.label}
                </Menu.Item>
              );
            }
          })}
        </SubMenu>
        // <Menu.Item key={`${item.path}${i}`} className={classes.join(" ")}>
        //   <Icon
        //     img={isSelected ? item.activeIcon : item.inactiveIcon}
        //     className="menu-item-img"
        //   />
        //   {aHtml}
        // </Menu.Item>
      );
    });
  };

  logout = () => {
    this.props.userLogout();
    AuthenticationService.logoutUser();
  };
  loadConfig = () => {
    this.setState({
      configFlag: true,
      confirmModalVisible: true,
      selectedConfirmModal: "Config & Permissions",
    });
  };

  confirmed = () => {
    this.setState(
      {
        confirmLoader: true,
      },
      () => {
        this.props.getPermissions();
        this.props.getConfigurations();
      }
    );
  };

  render() {
    const { menuItems } = this.state;
    let menuItemHtml = [];
    if (menuItems && menuItems.length > 0) {
      menuItemHtml = this.getMenuHtml(menuItems);
    }
    
    menuItemHtml.unshift(
      // <Menu.Item key={`sidebar-trigger`} onClick={this.props.onCollapse}>
      //   {this.props.collapsedSidebar ? (
      //     <AntdIcon type="menu-fold" className="sidebar-menu-icon " />
      //   ) : (
      //     <>
      //       <AntdIcon type="menu-unfold" className="sidebar-menu-icon" /> Menu{" "}
      //     </>
      //   )}
      // </Menu.Item>
      <div
        onClick={this.onCollapse}
        key="sidebar-collpase"
        className={this.props.collapsedSidebar ? " sidebar-menu-container sidebar-menu-container-collapse cursor-pointer" : "sidebar-menu-container sidebar-menu-container-opened cursor-pointer"}
      >
        {this.props.collapsedSidebar ? (
          <AntdIcon type="menu-fold" className="sidebar-menu-icon" />
        ) : (
          <>
            <AntdIcon type="menu-unfold" className="sidebar-menu-icon" /> <span className="sidebar-menu-text">Menu</span>
          </>
        )}
      </div>
    );
    // let customLogo = null;
    // const { configurations, header } = this.props;
    // if (
    //   configurations &&
    //   header.userInfo &&
    //   header?.userInfo?.loggedPartnerId
    // ) {
    //   let logoData = `https://loadshare-v2.s3.ap-south-1.amazonaws.com/prod/partner-logo/${this.props?.userInfo?.loggedPartnerId}/partner-logo.jpg`;
    //   customLogo =
    //     _.hasIn(this.props.configurations, "logoURL") &&
    //     Constants.sessionUser?.location?.entityType == "PARTNER"
    //       ? this.props.configurations?.logoURL
    //       : Logo;
    //   // customLogo = (this.props.loggedInUser?.userLevel === 'CUSTOMER_ADMIN' || this.props.loggedInUser?.userLevel === 'CUSTOMER_SUPER_ADMIN' || this.props.loggedInUser?.userLevel === 'CUSTOMER_USER') && !this.props.configurations["replaceLogo"] ? Logo : logoData;
    // }

    return (
      <>
        {menuItemHtml && menuItemHtml.length > 0 && (
          <Sider
            width={200}
            style={{ background: "#fff" }}
            trigger={null}
            collapsed={this.props.collapsedSidebar}
            // onCollapse={this.onCollapse}
            collapsible={true}
            className="sidebar-custom"
          >
            {/* <div
              onClick={this.props.onCollapse}
              className="sidebar-menu-container cursor-pointer"
            >
              {this.props.collapsedSidebar ? (
                <AntdIcon type="menu-fold" className="sidebar-menu-icon " />
              ) : (
                <>
                  <AntdIcon type="menu-unfold" className="sidebar-menu-icon" />{" "}
                  Menu{" "}
                </>
              )}
            </div> */}
            <Menu
              mode="inline"
              defaultSelectedKeys={["pickup"]}
              style={{ height: "100%", borderRight: 0 }}
            >
              {menuItemHtml}
            </Menu>
          </Sider>
        )}
      </>
    );
  }
}

// export default Sidebar;
const mapStateToProps = (state) => ({
  collapsedSidebar: state.app.collapsedSidebar
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarState: state => dispatch(setSidebarState(state))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
