import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import B2bLitePTLBooing from '../inbound-create/b2b-lite-booking/b2b-lite-ptl-booking';

class InboundForm extends Component {

    componentDidMount() {
        // console.log(this.props.match.params);

    }

    render() {
        return (
            <>
                <B2bLitePTLBooing {...this.props} key={1} formId={this.props.match.params.id ? this.props.match.params.id : 0} />
            </>
        )
    }
}

export default InboundForm;