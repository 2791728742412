import React from 'react';
export const columns = [
  {
    title: "Shipper",
    dataIndex: "shipper",
    key: "shipper",
  },
  {
    title: "Origin",
    dataIndex: "origin",
    key: "origin",
  },
  {
    title: "Consignee",
    dataIndex: "consignee",
    key: "consignee",
  },
  {
    title: "Destination",
    dataIndex: "destination",
    key: "destination",
  },
  {
    title: () => {
      return (
        <div>
          Docket Nos. <br /> (CN/LR)
        </div>
      );
    },
    dataIndex: "docketnos",
    key: "docketnos",
  },
  {
    title: () => {
      return (
        <div>
          Qty. <br /> (Nos)
        </div>
      );
    },
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Weight",
    dataIndex: "weight",
    key: "weight",
  },
  {
    title: "Receiving",
    children: [
      {
        title: "Counts",
        dataIndex: "counts",
        key: "counts",
      },
      {
        title: "Sign",
        dataIndex: "sign",
        key: "sign",
      },
    ],
  },
];
