
export const initialState = {
}

export const FETCH_MAILS_BEGIN = "FETCH_MAILS_BEGIN";
export const FETCH_MAILS_SUCCESS = "FETCH_MAILS_SUCCESS";
export const FETCH_MAILS_FAILURE = "FETCH_MAILS_FAILURE";

export const GET_CUSTOMERS_BEGIN = "GET_CUSTOMERS_BEGIN";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const SEND_MAIL_BEGIN = "SEND_MAIL_BEGIN";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAILURE = "SEND_MAIL_FAILURE";