import React, { Component } from "react";
import PodHeader from "./pod-details/pod-header";
import PodImageViewer from "./pod-details/pod.image-view";
import { Button, Modal, Spin, Tooltip } from 'antd';
import { permissionTags } from '../../../configs/permissionTags';
import { canAllow, capitalizeString, cloneObject, notifyApiError, notifyApiSuccess } from '../../../common/utils/utils'
import { updatePodDetails, getPODHistory, verifyPod, getRejectReasons, rejectPOD } from './pods.utils';
import DeliveryDate from "./pod-details/delivery-date";
import VerificationRemarks from "./pod-details/verification-remarks";
import PodDeps from './pod-details/pod-deps';
import NameValuePair from "../components/name-value-pair";
import { UpdateTypeMessages } from "./pod-status.transformer";
import PodHistory from "./pod-details/pod-history";
import PodRejectionModal from "./pod.rejection-modal";
import { Mixpanel } from "../../../utils/mixpanel";
import moment from "moment";
import { NAV_DIRECTION } from "../components/details-navigation";

class PodDetails extends Component {

    podImageMinCount = JSON.parse(atob(localStorage.getItem('_configrations'))).imageValidation?.epod?.minCount
    constructor(props) {
        super(props);
        this.state = {
            verificationPermissions: {
                approvePermissions: canAllow(permissionTags.verification.pod.approve),
                createPermissions: canAllow(permissionTags.verification.pod.create),
                editPermissions: canAllow(permissionTags.verification.pod.edit),
                deletePermissions: canAllow(permissionTags.verification.pod.delete)
            },
            actionsDisabled: false,
            showHistoryModal: false,
            showRejectModal: false,
            podRejectedReasons: [],
            fetchingHistory: false,
            updatingPod: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps?.consignmentDetailsUpdated && nextProps?.consignment.consignmentId) {
            const consignment = nextProps.consignment;
            if (consignment.podImages) {
                consignment.podImages = consignment.podImages.filter(elem => elem.trim() != "");
            }
            else{
                consignment.podImages = [];
            }
            if (!consignment.deps) {
                consignment.deps = [];
            }
            else if(consignment.deps.length > 0){
                consignment.deps.forEach(depsItem => {
                    if(typeof depsItem.images == "string"){
                        depsItem.images = depsItem.images.split(',');
                        depsItem.images = depsItem.images.filter(img => img.trim() != "");
                    }
                })
            }
            return {
                podDetailsUpdated: false,
                consignmentDetails: cloneObject(consignment),
                updatedConsignmentDetails: cloneObject(consignment),
                actionsDisabled: false,
                showHistoryModal: false,
                updatingPod: false,
                goToIndex: 0,
                depsUpdateFlag : Array(consignment.deps.length).fill(false)
            }
        }
        return { 
            ...prevState,
            goToIndex: false
        };
    }

    viewPodHistory = rejectFilter => {
        if(rejectFilter){
            Mixpanel.track("POD Verification: Clicking View Rejection History", { "podVerificationViewRejectionHistory": moment().valueOf() });
        }
        this.setState({
            fetchingHistory: true,
            showHistoryModal: true
        }, () => {
            const payload = {
                consignmentIds: [this.props.consignment.consignmentId],
                ...(rejectFilter && { activityTypes: ["POD_IMAGE_REJECTED"] })
            }
            getPODHistory(payload).then(response => {
                if (response?.consignmentPodHistory && response?.consignmentPodHistory?.length > 0) {
                    let history = response?.consignmentPodHistory?.map(hist => {
                        return UpdateTypeMessages(hist);
                    });
                    this.setState({ fetchingHistory: false, history });
                } else {
                    this.setState({ fetchingHistory: false, history: [] });
                    notifyApiError("No Events Found!", "Error")
                }
            })
        });
    }

    cancelHistory = () => {
        this.setState({ showHistoryModal: false, fetchingHistory: false })
    }

    //pod data update handlers
    onImageUpdate = newImages => {
        let podDetailsUpdated = false;
        let ogPodImages = this.props.consignment.podImages;
        if (newImages?.length != ogPodImages?.length) {
            podDetailsUpdated = true;
        }
        else {
            for (var i = 0; i < newImages.length; i++) {
                if (ogPodImages.indexOf(newImages[i]) < 0) {
                    podDetailsUpdated = true;
                    break;
                }
            }
        }
        this.setState(current => {
            return {
                ...current,
                podDetailsUpdated,
                updatedConsignmentDetails: {
                    ...current.updatedConsignmentDetails,
                    podImages: newImages
                }
            }
        }, () => {
            this.props.toggleNavigation(podDetailsUpdated);
        });
    }

    onPodDateUpdate = (key, value) => {
        const initialData = this.props.consignment[key];
        let podDetailsUpdated = true;
        if (initialData == value) {
            podDetailsUpdated = false;
        }
        this.setState(current => {
            current.updatedConsignmentDetails[key] = value;
            return current;
        }, () => {
            this.setState({ podDetailsUpdated });
            this.props.toggleNavigation(podDetailsUpdated);
        })
    }

    onDepsUpdate = (updatedDeps, index) => {
        var podDetailsUpdated = false;
        const depsItem = this.props.consignment.deps[index];
        var updated = false;
        if (depsItem == undefined) {
            updated = true;
        }
        else if (depsItem.depsType != updatedDeps.depsType || depsItem.remarks != updatedDeps.remarks || depsItem.images.join(',') != updatedDeps.images.join(',')) {
            updated = true;
        }
        if(updated){
            podDetailsUpdated = true;
        }
        else{
            for(var  i = 0; i<this.state.depsUpdateFlag.length; i++){
                if(this.state.depsUpdateFlag[i] !== undefined && this.state.depsUpdateFlag[i] === true && i != index){
                    podDetailsUpdated = true;
                    break;
                }
            }
        }
        this.setState(current => {
            current.updatedConsignmentDetails.deps[index] = updatedDeps;
            current.depsUpdateFlag[index] = updated;
            return current
        }, () => {
            this.setState({ podDetailsUpdated });
            this.props.toggleNavigation(podDetailsUpdated);
        });
    }

    onDepsDelete = newDeps => {
        this.setState(current => {
            current.updatedConsignmentDetails.deps = newDeps;
            return current
        }, () => {
            this.setState({ podDetailsUpdated: true });
            this.props.toggleNavigation(true);
        });
    }

    trackDepsAdded = _ => {
        if(this.state.consignmentDetails.deps?.length > this.state.updatedConsignmentDetails.deps?.length){
            Mixpanel.track("POD Verification: Added DEPS Successfully", { "podVerificationAddedDepsSuccessfully": moment().valueOf() });
        }
        else if(this.state.consignmentDetails.deps?.length > 0){
            const oldDepsTypes = this.state.consignmentDetails.deps.reduce((current, next) => {
                current.push(next.depsType);
                return current;
            }, []);
            if(this.state.updatedConsignmentDetails.deps?.length > 0){
                for(var i = 0; i<this.state.updatedConsignmentDetails.deps?.length; i++){
                    var depsItem = this.state.updatedConsignmentDetails.deps[i];
                    if(depsItem && oldDepsTypes.indexOf(depsItem.depsType) == -1){
                        Mixpanel.track("POD Verification: Added DEPS Successfully",
                        { "podVerificationAddedDepsSuccessfully": moment().valueOf() });
                        break;
                    }
                }
            }
        }
    }
    //pod action handlers
    savePod = () => {
        Mixpanel.track("POD Verification: Clicking Save", { "podVerificationClickingSave": moment().valueOf() });
        if(!this.state.podDetailsUpdated){
            return Modal.error({
                title : "Save changes",
                content : "No changes are made"
            })
        }
        if(this.podImageMinCount > 0 && this.state.updatedConsignmentDetails?.podImages?.length < this.podImageMinCount){
            return Modal.error({
                title : "POD Images",
                content : "POD should contain at least " + this.podImageMinCount + " images!"
            })
        }
        Modal.confirm({
            title : "Save changes",
            content : "Do you want to save changes made?",
            onOk: () => {
                let verificationRemarks = this.state.updatedConsignmentDetails.verificationRemarks;
                if(verificationRemarks == this.state.consignmentDetails.verificationRemarks ||
                verificationRemarks == "" && !this.state.consignmentDetails.hasOwnProperty('verificationRemarks')){
                    verificationRemarks = undefined;
                }
                let userRemarks = this.state.updatedConsignmentDetails.userRemarks;
                if(userRemarks == this.state.consignmentDetails.userRemarks ||
                userRemarks == "" && !this.state.consignmentDetails.hasOwnProperty('userRemarks')){
                    userRemarks = undefined;
                }
                this.setState(current => {
                    current.updatingPod = true;
                    current.updatedConsignmentDetails = {
                        ...current.updatedConsignmentDetails,
                        userRemarks: userRemarks,
                        verificationRemarks: verificationRemarks
                    };
                    return current;
                }, () => {
                    updatePodDetails(this.state.updatedConsignmentDetails).then(response => {
                        Mixpanel.track("POD Verification: Saved Successfully", { "podVerificationSavedSuccessfully": moment().valueOf() });
                        notifyApiSuccess('POD details successfully updated!', 'SUCCESS');
                        this.trackDepsAdded();
                        this.setState({ 
                            consignmentDetails: this.state.updatedConsignmentDetails,
                            podDetailsUpdated: false,
                            updatingPod: false
                        });
                        this.props.onPodUpdate(this.state.updatedConsignmentDetails);
                    });
                });
            },
            cancelText : "No",
            okText : "Yes"
        })
    }

    podAction = (action, verify, reject) => {
        const onOkAction = () => {
            if(verify) {
                this.setState({ updatingPod: true });
                verifyPod(this.state.updatedConsignmentDetails).then(_ => {
                    notifyApiSuccess('POD details verified successfully!', 'SUCCESS');
                    Mixpanel.track("POD Verification: Verified Successfully", { "podVerificationVerifiedSuccessfully": moment().valueOf() });
                    this.trackDepsAdded();
                    this.setState(current => {
                        current.updatedConsignmentDetails.podVerificationStatus = 'POD_VERIFIED';
                        current.consignmentDetails = current.updatedConsignmentDetails;
                        current.podDetailsUpdated = false;
                        current.updatingPod = false;
                        return current;
                    }, () => {
                        this.props.onPodUpdate(this.state.updatedConsignmentDetails);
                        Modal.success({
                            title: "POD details successfully updated!",
                            onOk: () => {
                                this.props.navigate(NAV_DIRECTION.RIGHT);
                            }
                        })
                    });
                });
            }
            else if (reject){
                this.setState({ showRejectModal: true }, () => {
                    if (this.state.podRejectedReasons.length <= 0) {
                        getRejectReasons().then(response => {
                            this.setState({ podRejectedReasons: response?.consignmentVerificationRejectedReasonsList })
                        })
                    }
                });
            }
        }
        if (this.state.podDetailsUpdated) {
            if(verify){
                Mixpanel.track("POD Verification: Clicking Verify", { "podVerificationClickingVerify": moment().valueOf() });
            }
            else if(reject){
                Mixpanel.track("POD Verification: Clicking Reject", { "podVerificationClickingReject": moment().valueOf() });
            }
            Modal.confirm({
                title: 'Unsaved Changes Found',
                content: 'Do you want to save the changes made and ' + action + '?',
                onOk: onOkAction
            })
        }
        else {
            Modal.confirm({
                title: capitalizeString(action) + ' POD',
                content: 'Are you sure you want to ' + action + ' the POD?',
                onOk: onOkAction
            })
        }
    }

    rejectPod = (rejectSelectedValue, rejectComment) => {
        this.setState({
            loading: true,
            updatingPod: true
        }, () => {
            const { podRejectedReasons } = this.state;
            const rejectedPodReasonsArray = podRejectedReasons.filter(reason => rejectSelectedValue.includes(reason.id));
            let postObjReasons = rejectedPodReasonsArray.map(reason => {
                return {
                    id: reason.id,
                    documentRejectedReason: reason.documentRejectedReason
                }
            });
            rejectPOD(this.state.updatedConsignmentDetails, postObjReasons, rejectComment).then(response => {
                Mixpanel.track("POD Verification: Rejected Successfully", { "podVerificationRejectedSuccessfully": moment().valueOf() });
                this.trackDepsAdded();
                const result = response?.responses[0];
                if (result?.sync) {
                    notifyApiSuccess("POD verification rejected successfully!", "SUCCESS");
                } else {
                    notifyApiError("POD verification rejection failed", "ERROR");
                }
                this.setState(current => {
                    Mixpanel.track("POD Verification: Verified Successfully", { "podVerificationVerifiedSuccessfully": moment().valueOf() });
                    current.updatedConsignmentDetails.podVerificationStatus = 'POD_REJECTED';
                    current.consignmentDetails = current.updatedConsignmentDetails;
                    current.podDetailsUpdated = false;
                    current.loading = false;
                    current.updatingPod = true;
                    return current;
                });
                this.cancelRejectModal();
                this.props.onPodUpdate(this.state.updatedConsignmentDetails);
            });
        })
    }

    //handle deps actions
    toggleActionButtons = (status) => {
        this.setState({
            actionsDisabled: status
        });
        if(status){
            this.props.toggleNavigation(true);
        }
    }

    saveButtonTooltip = () => {
        if(this.state.actionsDisabled){
            return "Save or reset DEPS changes!"
        }
        else if(this.state.consignmentDetails.podVerificationStatus == "POD_VERIFIED"){
            return "Verified POD cannot be modified!"
        }
        else if(!this.state.podDetailsUpdated){
            return "No changes are made to save!"
        }
        return undefined;
    }

    approveButtonTooltip = () => {
        if(this.state.actionsDisabled){
            return "Save or reset DEPS changes!"
        }
        else if(this.state.consignmentDetails.podVerificationStatus == "POD_VERIFIED"){
            return "Verified POD cannot be modified!"
        }
        else if(this.state.consignmentDetails.podVerificationStatus != "POD_VERIFICATION_PENDING"){
            return "No POD document uploaded!"
        }
        return undefined;
        
    }

    cancelRejectModal = _ => {
        this.setState({ showRejectModal: false })
    }

    disableApproveActions = _ => {
        return (
            this.state.actionsDisabled || 
            this.state.consignmentDetails.podVerificationStatus != "POD_VERIFICATION_PENDING" ||
            this.state.consignmentDetails.podVerificationStatus == "POD_VERIFIED"
        )
    }

    disableSaveAction = _ => {
        return (
            !this.state.podDetailsUpdated || this.state.actionsDisabled ||
            this.state.consignmentDetails.podVerificationStatus == "POD_VERIFIED"
        )
    }

    render() {

        const { consignment, consignmentIndex } = this.props;
        const { consignmentDetails, updatedConsignmentDetails, loading, verificationPermissions, goToIndex } = this.state;
        const { createPermissions, editPermissions, approvePermissions, deletePermissions } = verificationPermissions;
        const savePermissions = editPermissions || createPermissions || deletePermissions || approvePermissions;

        return (
            <>
                <Spin spinning={this.state.updatingPod}>
                    <div className="spacer-l white-bg border-radius-m flex-column flex-gap-l">
                        <PodHeader consignment={updatedConsignmentDetails} consignmentIndex={consignmentIndex}
                            handleHistoryClick={() => {                    
                                Mixpanel.track("POD Verification: Clicking View History",
                                 { "podVerificationViewHistory": moment().valueOf() });
                                this.viewPodHistory(false) 
                            }} />

                        <PodImageViewer consignment={updatedConsignmentDetails} onImageUpdate={this.onImageUpdate} consignmentDetails={consignmentDetails}
                            verificationPermissions={verificationPermissions} onRemarksUpdate={this.onPodDateUpdate}
                            updateRemarksPermissions={editPermissions && !approvePermissions} goToIndex={goToIndex}
                            toggleImageUploadState={this.props.toggleImageUploadState}/>

                        <div className="flex-box justify-content-space-evenly flex-gap-l border-radius-m border-grey spacer-m">

                            <DeliveryDate consignment={updatedConsignmentDetails} podCreatedDate={consignment?.podCreatedDate} toggleActionButtons={this.toggleActionButtons}
                                onDeliveryDateChange={this.onPodDateUpdate} editPermissions={approvePermissions} />

                            <VerificationRemarks consignment={updatedConsignmentDetails} initalVerificationRemarks={consignment?.verificationRemarks}
                                onVerificationRemarksUpdate={this.onPodDateUpdate} editPermissions={approvePermissions} />

                        </div>

                        <PodDeps consignment={updatedConsignmentDetails} onDepsUpdate={this.onDepsUpdate} onDepsDelete={this.onDepsDelete}
                            onEditEnabled={this.toggleActionButtons} consignmentDetailsUpdated={this.props.consignmentDetailsUpdated}
                            verificationPermissions={verificationPermissions}/>
                        
                        <div className="flex-box align-items-start">
                            <div className="flex-column flex-gap-l flex-1">
                                <div className="text-bold"> Mark Delivered User details </div>
                                <div className="flex-box">
                                    <div className="flex-column flex-1 flex-gap-m">
                                        { consignmentDetails?.deliveredUser &&
                                         <NameValuePair name="Mark Delivered User" value={consignmentDetails?.deliveredUser}/>}
                                        { consignmentDetails?.deliveredPartner &&
                                         <NameValuePair name="Partner Name" value={consignmentDetails?.deliveredPartner} enableCopy={true}/>}
                                    </div>
                                    <div className="flex-column flex-1 flex-gap-m">
                                        { consignmentDetails?.markDelDetails?.contactNumber &&
                                         <NameValuePair name="Mobile Number" value={consignmentDetails?.markDelDetails?.contactNumber} enableCopy={true}/>}
                                        { consignmentDetails?.markDelDetails?.email &&
                                         <NameValuePair name="E-mail ID" value={consignmentDetails?.markDelDetails?.email} enableCopy={true}/>}
                                    </div>
                                </div>
                            </div>

                            <div className="pod-buttons flex-box flex-gap-m flex-1 justify-content-end">
                                {
                                    savePermissions &&
                                    <Tooltip title={this.saveButtonTooltip()}>
                                        <Button type={!this.disableSaveAction() ? "primary" : undefined }
                                        disabled={this.disableSaveAction()}
                                        onClick={this.savePod} > Save </Button>
                                    </Tooltip>
                                }
                                {
                                    approvePermissions &&
                                    <>
                                        <Tooltip title={this.approveButtonTooltip()}>
                                            <Button className="verify-button" 
                                            onClick={() => this.podAction('verify', true, false)}
                                            disabled={this.disableApproveActions()}>
                                                Verify
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={this.approveButtonTooltip()}>
                                            <Button className="reject-button"
                                            onClick={() => this.podAction('reject', false, true)}
                                            disabled={this.disableApproveActions()}>
                                                Reject
                                            </Button>
                                        </Tooltip>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Spin>

                <PodHistory showHistoryModal={this.state.showHistoryModal} history={this.state.history}
                viewPodHistory={this.viewPodHistory} cancelHistory={this.cancelHistory}
                fetchingHistory={this.state.fetchingHistory} />

                <PodRejectionModal showRejectModal={this.state.showRejectModal} cancelRejectModal={this.cancelRejectModal}
                rejectPod={this.rejectPod} loading={loading} podRejectedReasons={this.state.podRejectedReasons}/>
            </>
        );
    }
}

export default PodDetails;