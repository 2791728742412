import React, { Component } from 'react';
import {
    Select, Row, Col, Steps,
    Descriptions, Table, Spin, Switch, Icon
} from 'antd';
import { Mixpanel } from '../../../../utils/mixpanel';
import * as apiUtils from '../../../../common/utils/apiGateway';
import { Constants } from '../../../../common/utils/constants';
import debounce from 'lodash/debounce';


import _ from 'lodash';
const { Option } = Select;

class TripSummary extends Component {

    constructor(props) {
        super(props);
        this.totalPcs = 0;
        this.totalKgs = 0;
        this.defaultVal = {};
        this.state = {
            docketDataSummary: [],
            totalKgs: 0,
            totalPcs: 0,
            tansit: true,
            hubLocation: {},
            hubLocationMap: {},
            createdTrip: false,
            switchSelectDisabled: false,
            locations: [],
            fetchingLocations: false,
            showLMPWarnig: false,
            LMPWarning: undefined
        }
        this.switchTransit = this.switchTransit.bind(this);
        this.fetchLocations = debounce(this.fetchLocations, 800);
        console.log("this", this.props.selectedDockets)
    }

    componentDidMount() {
        if (this.props.selectedDockets && this.props.selectedDockets.length > 0 && this.state.docketDataSummary.length <= 0) {
            this.setState({ docketDataSummary: this.props.selectedDockets }, () => {
                console.log("this", this.state.docketDataSummary)
                this.props.onChangeDocketsSummary(this.state.docketDataSummary)
            });
        }
    }

    componentDidUpdate(oldProps) {




        if (this.props.createTripFlow && this.props.createTripFlow.responses && this.props.createTripFlow.responses.length > 0 && !this.state.createdTrip) {
            this.props.createTripFlow.responses.map(res => {
                if (res.sync === true) {
                    this.setState({ createdTrip: true }, () => {
                        Mixpanel.track("Create trip Finished", { 'tripIdentity': this.props.trackingIdentity })
                        this.props.history.push('/appv2/trips/dashboard');
                        this.props.setInitialState()
                    })
                }
            })
        }



    }

    fetchLocations = (value) => {
        if (value) {
            let params = { "filters": { "entityTypes": ["PARTNER"], "entityIds": [], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } };
            let level = Constants.sessionUser.userLevel;
            this.setState({ locations: [], fetchingLocations: true }, () => {
                apiUtils.apiRequest({
                    url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
                    data: params,
                    method: "POST"
                }).then(res => {
                    this.setState({ locations: res?.data?.response?.locations, fetchingLocations: false })
                })
            });




        }

    }

    changeHub = (value, option, waybill, consignmentId) => {
        console.log("value", value, option, waybill, consignmentId, this.state.dockets)
        let destinationLocation = null;
        let isBlocked = false;
        let allowSelection = true;
        let checkAvailability = false;
        this.state.locations.map(d => {
            // console.log("d", d)
            if (d.name === value) {
                destinationLocation = d.id;
                // destination = d.id;
            }
            if (d.entityId !== Constants.PARTNER_ID) {
                checkAvailability = true;
            }
        })






        this.setState({
            hubLocation: {
                ...this.state.hubLocation,
                [waybill]: value
            },
            hubLocationMap: {
                ...this.state.hubLocationMap,
                [consignmentId]: option.key.toString()
            }
        }, () => {

            this.props.onChangeDocketSummaryHub(this.state.hubLocationMap);
            this.props.onChangeSelectedDockets(Object.keys(this.state.hubLocation))

            // check if disable can be implemented
            // if (this.props.docketsSummary.docketLocationAllocations.length == Object.keys(this.state.hubLocationMap).length) {
            //     let checkIfEmpty = false;

            //     for (let val in this.state.hubLocationMap) {
            //         if (_.isUndefined(this.state.hubLocationMap[val])) {
            //             checkIfEmpty = true;
            //         }
            //     }

            //     if (!checkIfEmpty) {
            //         this.props.changeCreateTripDisabled(false);
            //     } else {
            //         this.props.changeCreateTripDisabled(true);
            //     }


            // }

            if (checkAvailability) {

                apiUtils
                    .apiRequest({
                        url: `/b2b/v1/rateCard/checkAvailability`,
                        method: "POST",
                        data: {
                            "consignmentId": consignmentId,
                            "locationId": destinationLocation

                        }
                    }).then(res => {
                        console.log("res", res)

                        if (res?.data?.status?.code === 500) {
                            this.setState({ LMPWarning: res?.data, showLMPWarnig: true });
                            allowSelection = false;
                            return;
                        } else if (res?.data?.status?.code === 1001) {
                            this.setState({ LMPWarning: res?.data, showLMPWarnig: true });
                            return;
                        } else {
                            this.setState({ LMPWarning: undefined, showLMPWarnig: false });
                        }
                    }).finally(info => {
                        if (!allowSelection) {
                            this.setState({
                                hubLocation: {
                                    ...this.state.hubLocation,
                                    [waybill]: undefined
                                },
                                hubLocationMap: {
                                    ...this.state.hubLocationMap,
                                    [consignmentId]: option.key.toString()
                                }
                            }, () => {
                                this.props.onChangeDocketSummaryHub(this.state.hubLocationMap);
                                this.props.onChangeSelectedDockets(Object.keys(this.state.hubLocation))
                                let keys = Object.keys(this.state.hubLocation);
                                // console.log("keys", keys)
                                let disableCreateTrip = false;
                                if (keys && keys.length > 0) {
                                    keys.map(k => {
                                        // console.log("k", k, this.state.hubLocation[k])
                                        if (this.state.hubLocation[k] === undefined) {
                                            disableCreateTrip = true;
                                        }
                                    });
                                }

                                if (keys && keys.length < this.state.docketDataSummary.length) {
                                    disableCreateTrip = true;
                                }




                                this.props.changeCreateTripDisabled(disableCreateTrip);
                            })
                        } else {
                            let keys = Object.keys(this.state.hubLocation);
                            // console.log("keys", keys)
                            let disableCreateTrip = false;
                            if (keys && keys.length > 0) {
                                keys.map(k => {
                                    // console.log("k", k, this.state.hubLocation[k])
                                    if (this.state.hubLocation[k] === undefined) {
                                        disableCreateTrip = true;
                                    }
                                });
                            }

                            if (keys && keys.length < this.state.docketDataSummary.length) {
                                disableCreateTrip = true;
                            }




                            this.props.changeCreateTripDisabled(disableCreateTrip);
                        }
                    })

            }

        });


    }

    switchTransit = (value, wayBill, row, index, consignmentId) => {
        // console.log("value, value", value, index)
        this.setState({ [wayBill]: value }, () => {
            // console.log("this.state", this.state)
            let keys = Object.keys(this.state.hubLocation);
            // console.log("keys", keys)
            let disableCreateTrip = false;
            if (keys && keys.length > 0) {
                keys.map(k => {
                    // console.log("k", k, this.state.hubLocation[k])
                    if (this.state.hubLocation[k] === undefined) {
                        disableCreateTrip = true;
                    }
                });
            }

            if (keys && keys.length < this.state.docketDataSummary.length) {
                disableCreateTrip = true;
            }


            this.props.changeCreateTripDisabled(disableCreateTrip);
        });


        // if (!value) {
        //     this.setState({
        //         hubLocation: {
        //             ...this.state.hubLocation,
        //             [wayBill]: 'Direct Delivery'
        //         }
        //     })

        // }

        if (!value) {
            this.setState({
                hubLocation: {
                    ...this.state.hubLocation,
                    [wayBill]: 'Direct Delivery'
                },
                hubLocationMap: {
                    ...this.state.hubLocationMap,
                    [consignmentId]: Constants.userLocationId
                },
                switchSelectDisabled: true
            }, () => {
                this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                this.props.onChangeSelectedDockets(Object.keys(this.state.hubLocation))

            })
        } else {
            this.setState({
                hubLocation: {
                    ...this.state.hubLocation,
                    [wayBill]: undefined
                },
                hubLocationMap: {
                    ...this.state.hubLocationMap,
                    [consignmentId]: undefined
                },
                switchSelectDisabled: false
            }, () => {
                this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                this.props.onChangeSelectedDockets(Object.keys(this.state.hubLocation))

            })
        }

        console.log("this.", this.state.hubLocationMap)




    }



    renderDocketsSummary() {
        const { locations } = this.state;
        const columns = [
            {
                title: 'S No',
                dataIndex: 'index',
                key: 'index'
            },
            {
                title: 'Docket ID',
                key: 'waybillNo',
                dataIndex: 'waybillNo',
                render: (data, row) => {
                    if (this.state.showLMPWarnig) {
                        return this.state?.LMPWarning?.response?.consignmentIdErrorMappings?.map(con => (
                            con.consignmentId === row.id &&
                            <>
                                {row.waybillNo} <br />
                                <Icon type="info-circle" theme="twoTone" twoToneColor="#FF0000" /><span style={{color: '#FF0000'}}>{con?.message}</span>
                            </>

                        ))
                    } else {
                        if (this.props.rateWarning && this.props.createTripFlow) {
                            return this.props?.createTripFlow?.response?.consignmentIdErrorMappings?.map(con => (
                                con.consignmentId === row.id &&
                                <>
                                    {row.waybillNo} <br />
                                    <Icon type="info-circle" theme="twoTone" twoToneColor="#FF0000" /><span style={{color: '#FF0000'}}>{con?.message}</span>
                                </>

                            ))
                        } else if (this.props.rateWarning && this.props.createTripError) {
                            return this.props?.createTripError?.response?.consignmentIdErrorMappings?.map(con => (
                                con.consignmentId === row.id &&
                                <>
                                    {row.waybillNo} <br />
                                    <Icon type="info-circle" theme="twoTone" twoToneColor="#FF0000" /><span style={{color: '#FF0000'}}>{con?.message}</span>
                                </>

                            ))
                        } else {
                            return <>{data}</>
                        }
                    }

                }
                // render: (data, row) => {
                //     <>

                //         {this.props.rateWarning ?
                //             <>
                //                 {this.props.createTripFlow.response.consignmentIdErrorMappings.map(con => (

                //                     con.consignmentId === row.id &&
                //                     <>
                //                         {row.waybillNo} <br />
                //                         <Icon type="info-circle" theme="twoTone" twoToneColor="#FF0000" />{con?.message}
                //                     </>


                //                 ))
                //                 }


                //             </>
                //             : <>{row.waybillNo}</>
                //         }
                //     </>
                // }
            },
            {
                title: 'Origin',
                dataIndex: 'origin',
                key: 'origin'
            },
            {
                title: 'Destination',
                dataIndex: 'destination',
                key: 'destination'
            },
            {
                title: <>Quantity / <br />Shipment Counts / <br />Total chargeable wt.</>,
                dataIndex: 'quantityInKgs',
                key: 'quantityInKgs'
            },
            {
                title: 'Direct / Transit Via',
                dataIndex: 'transit',

                key: 'transit',
                render: (hubs, row) => {
                    // console.log("hubssss", hubs)
                    return (
                        <Row>
                            <Col span={18}>
                                <Select
                                    showSearch
                                    placeholder="Select Hub"
                                    disabled={
                                        _.hasIn(this.state, hubs['waybillNo']) && this.state[hubs['waybillNo']] === false ? true : false
                                        //  _.hasIn(this.state, hubs['waybillNo'] && console.log(this.state,hubs['waybillNo'], this.state[hubs['waybillNo']]) && !this.state[hubs['waybillNo']])
                                    }
                                    // disabled={row.candidateLocations.length <= 0}
                                    // disabled={!this.state[hubs['waybillNo']]}
                                    onSearch={this.fetchLocations}
                                    onSelect={(value, option) => this.changeHub(value, option, hubs['waybillNo'], hubs['id'])}
                                    defaultActiveFirstOption
                                    defaultValue={this.state.hubLocation[hubs['waybillNo']]}
                                    value={this.state.hubLocation[hubs['waybillNo']]}
                                    notFoundContent={"Search Location"}
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        locations.map((hub, key) => {


                                            return (
                                                <Option
                                                    value={hub.name}
                                                    label={hub.name}
                                                    key={hub.id}
                                                >
                                                    {hub.name}
                                                </Option>
                                            )


                                        })
                                    }
                                </Select>
                            </Col>
                            {/* <Col span={6}>
                                <span>Transit Via</span><Switch size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row)} />
                            </Col> */}
                        </Row>

                    )


                }

            },
            {
                key: 'action',
                render: (hubs, row, index) => {
                    // console.log("hubs", hubs)
                    return <><span>Transit Via</span><Switch defaultChecked size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row, index, row?.id)} /></>


                }


            }
        ];
        const data = [];
        let totalPcs = 0, totalKgs = 0;
        this.state.docketDataSummary.map((docket, key) => {


            let origin = docket?.customerPickupLoc?.address?.pincode?.city?.name ? docket?.customerPickupLoc?.address?.pincode?.city?.name : docket?.bookingOfficeLoc?.address?.pincode?.city?.name;
            // let destination = docket?.destinationShortCode?.favLocationBO?.address?.pincode?.city?.name ? docket?.destinationShortCode?.favLocationBO?.address?.pincode?.city?.name : docket?.pincode?.city?.name;
            let destination = docket?.city?.destination;
            totalPcs += docket.totalShipmentCount;
            totalKgs += docket.totalWeight;
            // console.log("totalOcs", totalPcs, totalKgs)
            this.totalKgs = totalKgs;
            this.totalPcs = totalPcs;
            console.log("docket", docket)
            // this.setState({totalPcs: totalPcs, totalKgs: totalKgs})
            data.push(
                {
                    'waybillNo': docket.waybillNo,
                    'index': key + 1,
                    'origin': origin,
                    'destination': destination,
                    'quantityInKgs': <>{docket.totalShipmentCount} Pcs  <small>({docket.totalWeight} Kgs)</small></>,
                    'transit': docket,
                    ...docket
                }
            );
        });

        // this.props.changeTotalPrice(totalPcs, totalKgs)





        return (
            <Table
                bordered
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        )
    }

    removeDocketItem = row => {
        console.log(row)
        const { docketDataSummary } = this.state
        let hubLocation = {};
        let hubLocationMap = {};
        let switchSelectDisabled = false

        this.setState({
            docketDataSummary: docketDataSummary.filter(item => item.waybillNo !== row.waybillNo)
        }, () => {
            this.props.onChangeDocketsSummary(this.state.docketDataSummary)
            if (this.state.docketDataSummary.length <= 0) {
                this.props.goBack()
            } else
                this.state.docketDataSummary.map(hub => {
                    hub.candidateLocations.map(hub => {
                        if (hub.isDirectDelivery) {
                            hubLocation = {
                                ...this.state.hubLocation,
                                [hub.waybillNo]: 'Direct Delivery'
                            }
                            hubLocationMap = {
                                ...this.state.hubLocationMap,
                                [hub['consignmentId']]: hub.deliveryPartnerLocationId.toString()
                            }
                            switchSelectDisabled = true
                        } else {
                            hubLocation = {
                                ...this.state.hubLocation,
                                [hub.waybillNo]: undefined
                            }
                            hubLocationMap = {
                                ...this.state.hubLocationMap,
                                [hub['consignmentId']]: undefined
                            }
                            switchSelectDisabled = false
                        }

                    });
                    this.setState({
                        hubLocation,
                        hubLocationMap,
                        switchSelectDisabled
                    }, () => {
                        this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                        this.props.onChangeSelectedDockets(Object.keys(this.state.hubLocation))

                        let keys = Object.keys(this.state.hubLocation);
                        let disableCreateTrip = false;
                        if (keys && keys.length > 0) {
                            keys.map(k => {
                                if (this.state.hubLocation[k] === undefined) {
                                    disableCreateTrip = true;
                                }
                            });
                        }

                        if (keys && keys.length < this.state.docketDataSummary.length) {
                            disableCreateTrip = true;
                        }

                        this.props.changeCreateTripDisabled(disableCreateTrip);
                    })
                })
        })
    }
    render() {

        return (
            <>
                {this.state.docketDataSummary.length > 0 ?
                    <>
                        <Descriptions layout="vertical" size="small" colon={false} column={{ xs: 24, sm: 16, md: 6 }}>
                            {this.props.fields.dispatched.value === 'DV' ? <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Route</small>}>
                                <strong>{this.props.fields.route.value.label}</strong>
                            </Descriptions.Item> : <></>}

                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Total Dockets</small>}>
                                <strong>{this.state.docketDataSummary.length}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small"><>Quantity / Shipment Counts / <br />Total chargeable wt.</></small>}>
                                <strong>{this.totalPcs} Pcs ({this.totalKgs} Kgs)</strong>
                            </Descriptions.Item>

                        </Descriptions>
                        <br />
                        {this.renderDocketsSummary()}
                    </>
                    : <Spin />}
            </>


        )
    }
}

export default TripSummary;