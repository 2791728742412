import { Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../../../common/utils/apiGateway';
import { manifestColumns } from './manifest.columns';
import "../dockets/print-dockets.scss"
import moment from 'moment';
import ReactDOM from 'react-dom';

const THCManifestTemplate = props =>{

    const [data, SetData] = useState(false);
    const [dataSource, SetDataSource] = useState(false);
    const [shipmentCount, SetShipmentCount] = useState(false);
    const [bagsCount, SetBagsCount] = useState(false);
    const [totalWeight, SetTotalWeight] = useState(false);


    useEffect(() => {
        if (props?.consignmentId) {
            apiRequest({
                url    : "/b2b/v1/thc/fetch",
                method : "POST",
                data   : {
                    filters: {
                        fetchObjects: [
                            "MANIFEST",
                            "CONSIGNMENTS",
                            "ASSEST",
                            "CONSIGNMENT_CONTACTS",
                            "DONOT_FETCH_CONSIGNMENTS",
                            "DONOT_FETCH_VENDORS",
                            "ADDRESS"
                        ],
                        isActive: true,
                        thcIds: [ props?.consignmentId ]
                    }
                }
            }).then(response => {
                var thcs = response.data.response['thcs'];
                var bagCount = 0;
                var shipmentCount = 0;
                var totalWeight = 0;
                var list = [];
                if (thcs.length > 0) {
                    var manifests =  thcs[0].manifests ? thcs[0].manifests : [];
                    bagCount = manifests?.length
                    manifests.forEach(manifest => {
                        if (manifest.consignments) {
                            manifest.consignments.forEach(consignment => {
                                consignment["bagId"] = manifest.manifestCode;
                                totalWeight += consignment.totalWeight;
                                shipmentCount += consignment.totalShipmentCount;
                            })
                            list = [ ...list, ...manifest.consignments ]
                        }
                    });
                }

                SetShipmentCount(shipmentCount);
                SetTotalWeight(totalWeight);
                SetBagsCount(bagCount);
                SetDataSource(list);
                SetData(thcs.length > 0 ? thcs[0] : { thcCode: props.consignmentId });
            })
        }
    }, [ props.consignmentId ] );

    manifestColumns.forEach(col => {
        col["render"] = data => <div style={{ maxWidth: 100, wordBreak: "break-all" }}> {data} </div>
    });

    useEffect(() => {
        if (document.getElementById('thcmanifest') && dataSource.length > 0) {
            const tableElem = document.querySelector("#thcmanifest table");
            const tfoot = document.createElement("tfoot");
            tableElem.appendChild(tfoot);
            const tfooter = () => {
                return (
                    <tr>
                        <th class="ant-table-row-cell-last" colspan="18">
                            <span class="ant-table-header-column">
                                <div className="spacer-vs">
                                    <span class="ant-table-column-title"> Trip Code: {data.thcCode} </span>
                                </div>
                            </span>
                        </th>
                    </tr>
                )
            }
            ReactDOM.render( tfooter(), document.querySelector("#thcmanifest table tfoot") );
        }
    }, [data])

    const tableCols = [
        {
            title: () => "",
            children: manifestColumns
        }
    ]

    return (
        <>
            {
                data.thcCode ?
                <>
                    <table className="thc-details">
                        <thead>
                            <tr>
                                <th colSpan="4">
                                    <div className="text-align-center">
                                        Trip Code: {data.thcCode}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> Destination City </td>
                                <td>  </td>
                                <td> Origin City </td>
                                <td>  </td>
                            </tr>
                            
                            <tr>
                                <td> Driver Name </td>
                                <td>  </td>
                                <td> Creation Date </td>
                                <td> {moment(data.createdAt).format('DD/MM/YY hh:mm')} </td>
                            </tr>

                            <tr>
                                <td> Driver Mobile Number </td>
                                <td>  </td>
                                <td> Bags Count </td>
                                <td> {bagsCount} </td>
                            </tr>

                            <tr>
                                <td> Transporter </td>
                                <td>  </td>
                                <td> Shipment Count </td>
                                <td> {shipmentCount} </td>
                            </tr>

                            <tr>
                                <td> Dispatch Executive </td>
                                <td>  </td>
                                <td> Total Weight </td>
                                <td> {totalWeight} </td>
                            </tr>

                            <tr>
                                <td> Vehicle Number </td>
                                <td>  </td>
                                <td> Receiver Name </td>
                                <td>  </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{marginTop:25}}>
                        <Table className="print-table"
                            columns={tableCols}
                            dataSource={dataSource}
                            bordered={true}
                            pagination={false}
                            id="thcmanifest"
                            rowKey={"id"} />
                    </div>

                </> :
                <div className="flex-box justify-content-center">
                    <Spin spinning={!data}/>
                </div>
            }
        </>
    )
}

export default THCManifestTemplate;