import React, { Component } from 'react'
import { Modal, Form, Select, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { Constants, DownloadTags } from '../../../common/utils/constants';
import { arrangeWaybill, reportsDownload } from '../../../common/utils/utils';
import { printHistoryByWaybill } from '../../inbound/print-history/actions';

const PrintForm = Form.create({
    name: 'Print Form'
})(props => {
    const { getFieldDecorator } = props.form;
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const waybills = arrangeWaybill(
                    values.waybill
                );
                let data = {
                    commCode: values.shipmentFormat,
                    locationId: Constants.CURRENT_USER_LOCATION_ID,
                    userId: Constants.sessionUser.id,
                    documentType: values.shipmentFormat,
                    partnerId: Constants.CURRENT_USER_ID,
                    waybills: waybills
                };

                props.printHistoryByWaybill(data).then(res => {
                    if (res?.data?.response?.url) {
                        reportsDownload(res?.data?.response?.url, DownloadTags.s3)
                        props.togglePrintModal(false)
                    }
                });
            }
        })
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item label="Shipment Format" style={{ marginBottom: 0 }}>
                {getFieldDecorator('shipmentFormat', {
                    rules: [
                        {
                            required: true,
                            message: "Shipment Format is required"
                        }
                    ],
                })(
                    <Select placeholder="Select Awb Sticker" showSearch filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } showArrow>
                        {props?.shipmentFormat?.map(format => (
                            <Select.Option
                                key={format.name}
                                value={format.value}
                                label={format.name}
                            >
                                {format.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label={"Waybill"}>
                {getFieldDecorator('waybill', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Waybill is required' }],
                })(<Input placeholder="Enter Waybill" />)}
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
                <Button loading={props.loading} style={{ float: 'right' }} htmlType="submit" type="primary">Confirm Print</Button>
                <Button style={{ float: 'right' }} onClick={e => props.togglePrintModal(false)}>Cancel</Button>

            </Form.Item>
        </Form>
    )
})

class PrintOperation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPrintModal: false
        }
    }
    togglePrintModal = (isOpen) => {
        this.setState({
            showPrintModal: isOpen
        })
    }
    render() {
        const { shipmentFormat, buttonLabel, totalScannedItems, printType } = this.props;
        return (
            <>
                <Button onClick={e => this.togglePrintModal(true)} type="primary"> {buttonLabel} </Button>
                <Modal
                    onCancel={e => this.togglePrintModal(false)}
                    visible={this.state.showPrintModal}
                    width="30vw"
                    footer={null}
                    title={'Print Operation'}
                    centered
                    destroyOnClose={true}
                >
                    <PrintForm {...this.props} togglePrintModal={this.togglePrintModal} />
                </Modal>
            </>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    printData: state.printHistory,
    PaginatedPrintHistory: state.printHistory?.PaginatedPrintHistory,
    header: state?.header,
});
const mapDispatchToProps = dispatch => ({
    printHistoryByWaybill: data => dispatch(printHistoryByWaybill(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PrintOperation);