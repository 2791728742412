import React, { useState } from 'react';
import { Modal, Button, Select } from "antd";
import DatePickerInput from "../../../../ui/datepicker/DatePicker";
import { cloneObject } from '../../../../../common/utils/utils';
import { fetchUsers } from "../../common/services/index";
import _ from "lodash";
import "../../common/common.scss";
import { fetchZones, fetchShippers, fetchLocations,fetchCustomers } from '../../../../../services/common/fetch.services';

const Option = Select.Option;
function ContractFilters(props) {

    let { customerId, visible, setFilterModal, filters, filterData, flowType } = props;
    const { setFilterData, applyFilters, resetFilters, setFilters } = props;
    const [endOpen, setEndOpen] = useState(false);

    const [notFoundContentShippers, SetNotFoundContentShippers] = useState("Start Typing to search (min 3 characters)...");
    const [notFoundContentCusomters, SetNotFoundContentCustomers] = useState("Start Typing to search (min 3 characters)...");
    const [notFoundContentLocation, SetNotFoundContentLocation] = useState("Start Typing to search (min 3 characters)...");
    const [notFoundContentZone, SetNotFoundContentZone] = useState("Start Typing to search (min 3 characters)...");
    const [notFoundUserData, setNoFoundUserData] = useState("Start Typing to search (min 3 characters)...");

    const disableEndDate = endValue => {
        const { validFrom } = filters;
        if (!endValue || !validFrom) {
            return false;
        }
        return endValue.valueOf() <= validFrom.valueOf();
    }
    const disableStartDate = startValue => {
        const { validTo } = filters;
        if (!startValue || !validTo) {
            return false;
        }
        return startValue.valueOf() >= validTo.valueOf();
    }
    const getParams = value => {
        return {
            entityIds: flowType !== "VERIFICATION" ? [customerId] : filters.customerFilters.map(c => c.key),
            isActive: true,
            partialContentSearch: value
        }
    }

    const resetFilterOptions = () => {
        setFilterData({
            locationData: [],
            zoneData: [],
            shipperData: [],
        });
        SetNotFoundContentLocation("Start Typing to search (min 3 characters)...");
        SetNotFoundContentShippers("Start Typing to search (min 3 characters)...");
        SetNotFoundContentCustomers("Start Typing to search (min 3 characters)...");
        SetNotFoundContentZone("Start Typing to search (min 3 characters)...");
    }
    const callShippers = _.debounce(value => {
        if (value.trim() === "") {
            return resetFilterOptions();
        }
        fetchShippers(getParams(value)).then(data => {
            SetNotFoundContentShippers("No shippers found");
            setFilterData({
                ...filterData,
                shipperData: data?.entityShipperMappingBOList
            });
        })
    }, 800);
    const callCustomers = _.debounce(value => {
        if (value.trim() === "") {
            return resetFilterOptions();
        }
        fetchCustomers(getParams(value)).then(data => {
            SetNotFoundContentCustomers("No Customer found");
            setFilterData({
                ...filterData,
                customersData: data?.customers
            });
        })
    }, 800);

    const callLocations = _.debounce(value => {
        if (value.trim() === "") {
            return resetFilterOptions();
        }
        fetchLocations(getParams(value)).then(data => {
            SetNotFoundContentLocation("No Locations found.")
            setFilterData({
                ...filterData,
                locationData: data?.locations
            });
        })
    }, 800);

    const callZones = _.debounce(value => {
        if (value.trim() === "") {
            resetFilterOptions();
            return;
        }
        fetchZones(getParams(value)).then(data => {
            SetNotFoundContentZone("No Zone found.")
            setFilterData({
                ...filterData,
                zoneData: data?.zones
            });
        })
    }, 800);

    const callUsers = _.debounce(value => {
        if (value.trim() === "") {
            setNoFoundUserData("Start Typing to search (min 3 characters)...")
        }
        fetchUsers(getParams(value)).then(data => {
            console.log("data", data)
            setNoFoundUserData("No users found");
            setFilterData({
                ...filterData,
                usersData: data?.users
            });
        })
    }, 800);

    const setFilteredData = (filterKey, filteredData) => {
        var tempFilters = { ...filters };
        tempFilters[filterKey] = filteredData;
        if (filterKey === "customerFilters" && (!filteredData || filteredData.length === 0)) {
            setFilters(c => ({
                ...c,
                customerFilters :[],
                shipperFilters  : [],
                zoneFilter      : [],
                locationFilter  : []
            }))
        }
        else {
            setFilters(tempFilters);
        }
    }
    const billingCycle = [
        {
            "key": "Daily",
            "value": "DAILY"
        },
        {
            "key": "Monthly",
            "value": "MONTHLY"
        },
        {
            "key": "Weekly",
            "value": "WEEKLY"
        },
        {
            "key": "Bi-Weekly",
            "value": "BI_WEEKLY"
        }
    ];


    return (
        <Modal
            visible={visible}
            width={'30%'}
            closable={true}
            onCancel={() => setFilterModal(false)}
            style={{ top: 20 }}
            footer={[
                <Button key="back" onClick={_ => resetFilters()}> Reset </Button>,
                <Button key="submit" type="primary" onClick={applyFilters}> Apply </Button>
            ]}
            title="Filters">

            <div className="flex-box flex-column flex-gap-l contract-filters">
                {flowType === "VERIFICATION" && <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Customer</div>
                        <Select
                            mode={'multiple'}
                            style={{ width: "100%" }}
                            value={filters.customerFilters}
                            onChange={value => {
                                resetFilterOptions();
                                setFilteredData('customerFilters', value)
                            }}
                            onSearch={callCustomers}
                            notFoundContent={notFoundContentCusomters}
                            showSearch={true} showArrow={true}
                            placeholder={"Select Customer"}
                            onBlur={resetFilterOptions}
                            filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                            labelInValue={true}
                        >
                            {filterData?.customersData?.map(cust => (
                                <Option key={cust.id} value={cust.id}>{cust.name}</Option>
                            ))}
                        </Select>
                    </div>
                </div>}
                <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Shipper</div>
                        <Select
                            mode={'multiple'}
                            style={{ width: "100%" }}
                            value={filters.shipperFilters}
                            onChange={value => {
                                resetFilterOptions();
                                setFilteredData('shipperFilters', value)
                            }}
                            disabled={flowType === "VERIFICATION" && filters.customerFilters.length == 0}
                            onBlur={resetFilterOptions}
                            onSearch={callShippers}
                            notFoundContent={notFoundContentShippers}
                            showSearch={true} showArrow={true}
                            placeholder={"Select Shippers"}
                            filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                            labelInValue={true}
                        >
                            {filterData?.shipperData?.map(shipper => (
                                <Option key={shipper.id} value={shipper.id}>{shipper.shipperName}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Service Type</div>
                        <div className="box-filters flex-box justify-content-between flex-gap-m">
                            {filters?.serviceTypeData?.map((item, index) => (
                                <div
                                    key={index}
                                    className={item.value === false ? "boxType" : "boxTypeSelected"}
                                    onClick={_ => {
                                        let statusFilters = cloneObject(filters);
                                        statusFilters['serviceTypeData'].map(status => {
                                            if (status.id === index) {
                                                status.value = !status.value;
                                            }
                                        })
                                        setFilteredData('serviceTypeData', statusFilters['serviceTypeData'])
                                    }}
                                >
                                    {item.storedValue}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Zone</div>
                        <div>
                            <Select
                                mode={'multiple'}
                                style={{ width: "100%" }}
                                value={filters.zoneFilter}
                                disabled={filters.locationFilter.length > 0}
                                onBlur={resetFilterOptions}
                                onChange={value => {
                                    resetFilterOptions();
                                    setFilteredData('zoneFilter', value);
                                }}
                                onSearch={callZones}
                                notFoundContent={notFoundContentZone}
                                showSearch={true}
                                showArrow={true}
                                placeholder={"Select Zones"}
                                filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                                labelInValue={true}
                            >
                                {filterData?.zoneData?.map(zone => (
                                    <Option key={zone.id} value={zone.id}>{zone.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Location</div>
                        <Select
                            mode={'multiple'}
                            style={{ width: "100%" }}
                            value={filters.locationFilter}
                            onChange={value => {
                                resetFilterOptions();
                                setFilteredData('locationFilter', value)
                            }}
                            disabled={filters.zoneFilter.length > 0}
                            onBlur={resetFilterOptions}
                            onSearch={callLocations}
                            notFoundContent={notFoundContentLocation}
                            showSearch={true} showArrow={true}
                            placeholder={"Select Locations"}
                            filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                            labelInValue={true}
                        >
                            {filterData?.locationData?.map(location => (
                                <Option key={location.id} value={location.id}>{location.name}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Billing Cycle</div>
                        <Select
                            style={{ width: "100%" }}
                            value={filters.billingCycles}
                            mode={"multiple"}
                            showArrow={true}
                            onChange={value => setFilteredData('billingCycles', value)}
                            placeholder={"Select Billing Cycle"}
                            filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                            labelInValue={true}
                        >
                            {
                                billingCycle.map(option => (
                                    <Option key={option.key} value={option.value}>
                                        {option.key}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                {
                    flowType !== "VERIFICATION" && 
                    <>
                        <div>
                            <div className="flex-box flex-column justify-content-between flex-gap-m">
                                <div className="u-font-bold font-size-m-1">Fuel Status</div>
                                <div className="box-filters flex-box justify-content-between flex-gap-m">
                                    {filters?.fuelStatus?.map((item, index) => (
                                        <div
                                            key={index}
                                            className={item.value === false ? "boxType" : "boxTypeSelected"}
                                            onClick={_ => {
                                                let statusFilters = cloneObject(filters);
                                                statusFilters['fuelStatus'].map(status => {
                                                    if (status.id === index) {
                                                        status.value = !status.value;
                                                    }
                                                })
                                                setFilteredData('fuelStatus', statusFilters['fuelStatus']);
                                            }}
                                        >
                                            {item.storedValue}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="flex-box flex-column justify-content-between flex-gap-m">
                                <div className="u-font-bold font-size-m-1">Contract Status</div>
                                <div className="box-filters flex-box justify-content-between flex-gap-m" style={{ flexWrap: "wrap" }}>
                                    {filters?.status?.map((item, index) => (
                                        <div
                                            key={index}
                                            className={item.value === false ? "boxType" : "boxTypeSelected"}
                                            onClick={_ => {
                                                let statusFilters = cloneObject(filters);
                                                statusFilters['status'].map(status => {
                                                    if (status.id === index) {
                                                        status.value = !status.value;
                                                    }
                                                })
                                                setFilteredData('status', statusFilters['status']);
                                            }}
                                        >
                                            {item.storedValue}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div>
                    <div className="flex-box justify-content-between flex-gap-m">
                        <div className="flex-box flex-column justify-content-between flex-gap-m">
                            <div className="u-font-bold font-size-m-1">Validity</div>
                            <div>
                                <DatePickerInput
                                    disabledDate={disableStartDate}
                                    type="date"
                                    placeholder="From"
                                    format="YYYY-MM-DD"
                                    value={filters.validFrom ? filters.validFrom : undefined}
                                    onChange={value => {
                                        setFilteredData('validFrom', value ? value.startOf('day') : undefined);
                                    }}
                                    style={{ width: '100%' }}
                                    onOpenChange={(open) => {
                                        if (!open)
                                            setEndOpen(true)
                                    }}


                                />
                            </div>
                        </div>

                        <div className="flex-box flex-column justify-content-between flex-gap-m">
                            <div className="u-font-bold font-size-m-1">&nbsp;</div>
                            <div>
                                <DatePickerInput
                                    disabledDate={disableEndDate}
                                    type="date"
                                    format="YYYY-MM-DD"
                                    placeholder="To"
                                    value={filters.validTo ? filters.validTo : undefined}
                                    open={endOpen}
                                    onChange={value => {
                                        setFilteredData('validTo', value ? value.endOf('day') : undefined);
                                    }}
                                    style={{ width: '100%' }}
                                    onOpenChange={(open) => setEndOpen(open)}


                                />
                            </div>
                        </div>
                    </div>
                </div>

                {
                    flowType === "VERIFICATION" && 
                    <div>
                        <div className="flex-box flex-column justify-content-between flex-gap-m">
                            <div className="u-font-bold font-size-m-1">Contract Type</div>
                            <div className="box-filters flex-box justify-content-between flex-gap-m">
                                {filters?.contractTypesData?.map((item, index) => (
                                    <div
                                        key={index}
                                        className={item.value === false ? "boxType" : "boxTypeSelected"}
                                        onClick={_ => {
                                            let statusFilters = cloneObject(filters);
                                            statusFilters['contractTypesData'].map(status => {
                                                if (status.id === index) {
                                                    status.value = !status.value;
                                                }
                                            })
                                            setFilteredData('contractTypesData', statusFilters['contractTypesData'])
                                        }}
                                    >
                                        {item.storedValue}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
                {
                    flowType === "VERIFICATION" && 
                    <div>
                        <div className="flex-box flex-column justify-content-between flex-gap-m">
                            <div className="u-font-bold font-size-m-1">Requested By</div>
                            <Select
                                mode={'multiple'}
                                style={{ width: "100%" }}
                                value={filters.requestedFilters}
                                onChange={value => {                                
                                    setFilteredData('requestedFilters', value)
                                }}
                                onBlur={resetFilterOptions}
                                onSearch={callUsers}
                                notFoundContent={notFoundContentShippers}
                                showSearch={true} showArrow={true}
                                placeholder={"Select Requesters"}
                                filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                                labelInValue={true}
                            >
                                {filterData?.usersData?.map(user => (
                                    <Option key={user.id} value={user.id}>{user.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                }

            </div>
        </Modal>
    )
}

export default ContractFilters;