import React from 'react';
import moment from 'moment';
import LSN_B_W from '../../../../assets/img/LSN_B_W.svg';
import Barcode from '../../../ui/barcode';

const DocketsTemplate = (props) => {
  const consignmentType = props.consignments?.consignmentType ? props.consignments?.consignmentType : "B2B";
  const isB2BconsignmentType = consignmentType === "B2B";

  const getNameValue = (name, value) => {
    return (
      <div className="flex-box align-items-center flex-gap-m font-size-sm-2">
        <div> {name} </div> :
        <div className="break-all"> {value} </div>
      </div>
    )
  }

  const wayBillTemplate = copyOf => {
    return (
      <div className="waybill-template flex-box flex-column black-border flex-1">
        <div className="flex-box align-items-stretch black-border border-bottom">
          <div className="flex-1 spacer-xs black-border border-right-bottom flex-column justify-content-space-between" style={{paddingBottom: 0}}>
          {isB2BconsignmentType ? (
              <div>
                <img width="225px" src={LSN_B_W} />
                <div className="spacer-vxs font-size-sm-2">
                  LoadShare Networks Pvt Ltd., <br/> 509, 6th Cross Rd, 6th Block, Koramangala,  <br/> Bengaluru - 560047 <br/>
                  {getNameValue("GSTIN", "29AADCL2205J1ZM")}
                </div>
              </div>
              ):(
                <div className="flex-box flex-column flex-gap-l">
                  {getNameValue("Transporter", props.consignments?.transporter)}
                  {getNameValue("Driver Details", props.consignments?.driverDetails)}
                </div>
              )
            }
            
            <div className="print-waybill-booking-date font-size-m-2">
              {getNameValue("Booking Date", (
                <span className="font-size-m-1">
                  {moment(props.consignments?.bookingDate).format('DD/MM/YYYY HH:mm:ss A')}
                </span>
              ))}
            </div>
          </div>
  
          <div className="flex-1 flex-column align-items-center spacer-xs flex-gap-m" style={{maxWidth:'50%'}}>
            <div className="font-size-sm-2"> {isB2BconsignmentType ? "Docket" : "Connection"} No: </div>
            <div className="font-size-m-3">
              {isB2BconsignmentType ? props.consignments?.waybillNo : props.consignments?.connectionCode}
            </div>
            <div className="waybill-barcode">
                {
                    props.consignments?.waybillNo &&
                    <Barcode width={2} format="CODE128" value={props.consignments?.waybillNo} showText={false}
                    barCodeId={copyOf.toLowerCase()}/>
                }
            </div>
          </div>
        </div>
  
        <div className="flex-box align-items-stretch black-border border-bottom">
          <div className="flex-1 spacer-xs black-border border-right-bottom flex-column flex-gap-m">
            {getNameValue("Consignor", props.consignments?.shipper.name)}
            <div className="overflow-ellipsis font-size-sm-2"> {props.consignments?.shipper.address?.address1} </div>
            {!isB2BconsignmentType && <div className="overflow-ellipsis font-size-sm-2"> {props.consignments?.shipper.address?.address2} </div>}
            <div className="font-size-m-2">
              {props.consignments?.shipper.address?.pincode?.city?.name} -
              {props.consignments?.shipper?.address?.pincode?.zipcode}
            </div>
          </div>
          <div className="flex-1 spacer-xs flex-column flex-gap-m">
            {getNameValue("Consignee", props.consignments?.consignee.name)}
            <div className="overflow-ellipsis font-size-sm-2"> {props.consignments?.consignee?.address?.address1} </div>
            <div className="font-size-m-2">
              {props.consignments?.consignee?.address?.pincode?.city.name} -
              {props.consignments?.consignee?.address?.pincode?.zipcode}
            </div>
          </div>
        </div>
  
        <div className="flex-box align-items-stretch">
          <div className="flex-1 flex-coumn black-border spacer-xs border-right-bottom">
            {!isB2BconsignmentType && getNameValue("Bags", props.consignments?.totalBagCount)}
            {isB2BconsignmentType && getNameValue("Movement Type", props.consignments?.movementType)}
            {getNameValue(isB2BconsignmentType ? "Items" : "Shipments", props.consignments?.totalShipmentCount)}
            {getNameValue("Weight(KG)", props.consignments?.totalWeight)}
            {isB2BconsignmentType && getNameValue("Total Value", props.consignments?.attributes?.totalAmount ? "Rs." + props.consignments?.attributes?.totalAmount : "--")}
            {!isB2BconsignmentType && getNameValue("Dispatch Executive", props.consignments?.dispatchExecutive)}
          </div>
  
          <div className="flex-1 flex-column flex-box justify-content-space-between spacer-xs" style={{paddingBottom:0, minHeight:125}}>
            <div className="font-size-sm-2"> MATERIAL RECEIVED IN GOOD CONDITION <br/> Signature and Stamp of consignee </div>
            <div className="print-waybill-booking-date font-size-sm-3 text-align-center"> {copyOf} copy </div>
          </div>
  
        </div>
      </div>
    )
  }

  return (
    <div className="flex-column flex-gap-l spacer-xs page-breaker docket-template-wrapper">

      {
        props.consignments?.waybillNo &&
        <>
          <div className="flex-box flex-gap-l">
            {wayBillTemplate("Consignor")}
            {wayBillTemplate("Consignee")}
          </div>
    
          <div className="flex-box flex-gap-l">
            {wayBillTemplate("Transporter")}
            {wayBillTemplate("Accounts")}
          </div>
        </>
      }

    </div>
  )
}

export default DocketsTemplate;