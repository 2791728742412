import React, { Component } from "react";
import { Row, Col, Icon, Spin, Button, Popover,Card } from "antd";
import { connect } from "react-redux";
import HeliosStepper from "./HeliosStepper"
import * as utils from "../../../common/utils/utils";
import { getRoutDate , resetFields} from '../actions';
import HeliosMapRoute from '../../maps/HeliosMapRoute';
import "./RouteTracking.scss";
import img from '../../../assets/img/spinner.svg';
import Truck from '../../../assets/img/delivery-truck.svg';


class RouteTracking extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        copySuccess: ''
    }
    this.copyArea = React.createRef();
    this.props.dispatch(resetFields());
  }
  
  componentDidMount() {    
    let activePath = this.props.location.pathname;    
    if(activePath){
      let pathValue = activePath.split('/');
      this.props.dispatch(getRoutDate(pathValue[pathValue.length -1]));
    }
  }


  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.copyArea.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copySuccess: 'Copied!' }, ()=>{
      setTimeout(() => {
        this.setState({copySuccess: ''});
      }, 1000)
    });
  };

  getNextStop = (data) => {
      let nextStop;
        data.some(x => {
          let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
          if(x.status === "PENDING") {
            return nextStop = x[setConsignMent]['name'];
          }
        })
      return nextStop;
  }

  reloadApp = () => {
    window.location.reload();
  }

  vehicleTracking = () => {
    this.props.history.goBack()
    // this.props.history.push(`/appv2/tracking/dashboard/vehicle-tracking`)
  }

  render() {
      const {routingData } = this.props;
      let nextStop;
      let content;
      let isShowLoader = !routingData;
      if(isShowLoader) {
        return (<Row><div className="noData"><img className="loading" src={img} alt="Loading"/></div></Row>)
      }
      
    return (
        <>
        <Row>
            <Col xs={14}>
                <Row>
                  {routingData && <div className="arrowLeft"><Icon type="arrow-left" onClick={this.vehicleTracking} /></div>}
                  {routingData && routingData.simTracking && (routingData.response.reportResponse?.length > 0 || routingData.response.locationPoints?.length > 0) && <div className="vehicleTitle"> {routingData.response.vehicleTrackingContractVO.vehicle.regNo.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</div>}
                  {routingData && routingData.simTracking && (routingData.response.reportResponse?.length > 0 || routingData.response.locationPoints?.length > 0) && <div className="vehicleDes">● Started at: {utils.dateFormat(new Date(routingData.response.vehicleTrackingContractVO.startDate).getTime())}</div>}
                  {routingData && !routingData.simTracking && (routingData.response.reportResponse?.length > 0 || routingData.response.locationPoints?.length > 0) && <div className="vehicleTitle"> {routingData.response.vehicle.regNo.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</div>}
                  {routingData && !routingData.simTracking && (routingData.response.reportResponse?.length > 0 || routingData.response.locationPoints?.length > 0) &&  <div className="vehicleDes">● Started at: {utils.dateFormat(parseInt(routingData.response.tripStart.startTime))}</div>}
                </Row>
                <Row className="pt_20">
                    <Col className="gutter-row" span={24}>
                        <div className="gutter-box">
                        {routingData && (routingData.response.reportResponse?.length > 0 || routingData.response.locationPoints?.length > 0) ?<div><HeliosMapRoute routing={routingData}/></div>:
                        <Card style={{ textAlign: 'center' }}>
                        <div style={{
                            margin: "auto",
                            width: "50%",
                            padding: "15%"
                        }}>
                            <img src={Truck} />
                        </div>
                        <p>Live tracking not available</p>


                      </Card>
                    } 
                        </div>
                    </Col> 
                </Row>
            </Col>
            <Col xs={1}></Col>
            <Col xs={9}>
            <div className="Route-Activity">Route Activity</div>
            <div className="route-card">
              <div className="stepper">
                  {routingData && (routingData.response.reportResponse?.length > 0 || routingData.response.locationPoints?.length > 0) && <HeliosStepper tripData={routingData}></HeliosStepper>
                  }
              </div>
            </div>
            </Col>
        </Row>
        </>
    );
  }
}
const mapStateToProps = state => ({
    routingData: state.customerTracking.heliosRouteData,
});

export default connect(mapStateToProps)(RouteTracking);
