
import * as CONSTANTS from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const InvoiceCustomerSettlementReducer = (state = CONSTANTS.initialState, action) => {
    switch (action.type) {
        case CONSTANTS.GET_CUSTOMER_LIST_BEGIN: return getCustomerListBegin(state);
        case CONSTANTS.GET_CUSTOMER_LIST_SUCCESS: return getCustomerListSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_LIST_FAILURE: return getCustomerListFailure(state);

        case CONSTANTS.GET_SHIPPER_LIST_BEGIN: return getShipperListBegin(state);
        case CONSTANTS.GET_SHIPPER_LIST_SUCCESS: return getShipperListSuccess(state, action);
        case CONSTANTS.GET_SHIPPER_LIST_FAILURE: return getShipperListFailure(state);

        case CONSTANTS.GET_INVOICE_LIST_BEGIN: return getInvoicesListBegin(state);
        case CONSTANTS.GET_INVOICE_LIST_SUCCESS: return getInvoicesListSuccess(state, action);
        case CONSTANTS.GET_INVOICE_LIST_FAILURE: return getInvoicesListFailure(state, action);

        case CONSTANTS.GET_INVOICE_PAYMENTS_LIST_BEGIN: return getInvoicesPaymentsListBegin(state);
        case CONSTANTS.GET_INVOICE_PAYMENTS_LIST_SUCCESS: return getInvoicesPaymentsListSuccess(state, action);
        case CONSTANTS.GET_INVOICE_PAYMENTS_LIST_FAILURE: return getInvoicesPaymentsListFailure(state, action);

        case CONSTANTS.GET_CREDIT_DEBIT_NOTE_BEGIN: return getCrDrNoteListBegin(state);
        case CONSTANTS.GET_CREDIT_DEBIT_NOTE_SUCCESS: return getCrDrNoteListSuccess(state, action);
        case CONSTANTS.GET_CREDIT_DEBIT_NOTE_FAILURE: return getCrDrNoteListFailure(state, action);

        case CONSTANTS.GET_INVOICE_DOCKET_LIST_BEGIN: return getInvoicesDocketsListBegin(state);
        case CONSTANTS.GET_INVOICE_DOCKET_LIST_SUCCESS: return getInvoicesDocketsListSuccess(state, action);
        case CONSTANTS.GET_INVOICE_DOCKET_LIST_FAILURE: return getInvoicesDocketsListFailure(state, action);

        case CONSTANTS.DOWNLOAD_ANNEXURE_BEGIN: return downloadAnnexureBegin(state);
        case CONSTANTS.DOWNLOAD_ANNEXURE_SUCCESS: return downloadAnnexureSuccess(state, action);
        case CONSTANTS.DOWNLOAD_ANNEXURE_FAILURE: return downloadAnnexureFailure(state, action);

        case CONSTANTS.EXPORT_INVOICE_BEGIN: return exportInvoiceBegin(state);
        case CONSTANTS.EXPORT_INVOICE_SUCCESS: return exportInvoiceSuccess(state, action);
        case CONSTANTS.EXPORT_INVOICE_FAILURE: return exportInvoiceFailure(state, action);

        case CONSTANTS.UPLOAD_ANNEXURE_BEGIN: return uploadAnnexureBegin(state);
        case CONSTANTS.UPLOAD_ANNEXURE_SUCCESS: return uploadAnnexureSuccess(state, action);
        case CONSTANTS.UPLOAD_ANNEXURE_FAILURE: return uploadAnnexureFailure(state, action);

        case CONSTANTS.UPDATE_INVOICE_DUE_DATE_BEGIN: return updateInvoiceDueDateBegin(state);
        case CONSTANTS.UPDATE_INVOICE_DUE_DATE_SUCCESS: return updateInvoiceDueDateSuccess(state, action);
        case CONSTANTS.UPDATE_INVOICE_DUE_DATE_FAILURE: return updateInvoiceDueDateFailure(state, action);

        case CONSTANTS.UPLOAD_CUSTOMER_INVOICE_BEGIN: return uploadCustomerInvoiceBegin(state);
        case CONSTANTS.UPLOAD_CUSTOMER_INVOICE_SUCCESS: return uploadCustomerInvoiceSuccess(state, action);
        case CONSTANTS.UPLOAD_CUSTOMER_INVOICE_FAILURE: return uploadCustomerInvoiceFailure(state, action);

        case CONSTANTS.UPLOAD_PAYMENT_BEGIN: return uploadPaymentBegin(state);
        case CONSTANTS.UPLOAD_PAYMENT_SUCCESS: return uploadPaymentSuccess(state, action);
        case CONSTANTS.UPLOAD_PAYMENT_FAILURE: return uploadPaymentFailure(state, action);


        default:
            return state;
    }
}

function uploadPaymentBegin(state, action) {
    return {
        ...state,
        uploadPaymentloading: true
    };
}

function uploadPaymentSuccess(state, action) {
    return {
        ...state,
        uploadPaymentloading: false,
        uploadPayment: { ...action.payload }
    };
}

function uploadPaymentFailure(state, action) {
    return {
        ...state,
        uploadPaymentloading: false,
        uploadPaymentError: action.payload,
        uploadPayment: {}
    };
}

function uploadCustomerInvoiceBegin(state, action) {
    return {
        ...state,
        uploadCustomerInvoiceloading: true
    };
}

function uploadCustomerInvoiceSuccess(state, action) {
    return {
        ...state,
        uploadCustomerInvoiceloading: false,
        uploadCustomerInvoice: { ...action.payload }
    };
}

function uploadCustomerInvoiceFailure(state, action) {
    return {
        ...state,
        uploadCustomerInvoiceloading: false,
        uploadCustomerInvoiceError: action.payload,
        uploadCustomerInvoice: {}
    };
}

function updateInvoiceDueDateBegin(state, action) {
    return {
        ...state,
        updateInvoiceDueDateloading: true
    };
}

function updateInvoiceDueDateSuccess(state, action) {
    return {
        ...state,
        updateInvoiceDueDateloading: false,
        updateInvoiceDueDate: { ...action.payload }
    };
}

function updateInvoiceDueDateFailure(state, action) {
    return {
        ...state,
        updateInvoiceDueDateloading: false,
        updateInvoiceDueDateError: action.payload,
        updateInvoiceDueDate: {}
    };
}

function uploadAnnexureBegin(state, action) {
    return {
        ...state,
        uploadAnnexureloading: true
    };
}

function uploadAnnexureSuccess(state, action) {
    return {
        ...state,
        uploadAnnexureloading: false,
        uploadAnnexure: { ...action.payload }
    };
}

function uploadAnnexureFailure(state, action) {
    return {
        ...state,
        uploadAnnexureloading: false,
        uploadAnnexureError: action.payload,
        uploadAnnexure: {}
    };
}

function exportInvoiceBegin(state, action) {
    return {
        ...state,
        exportInvoiceloading: true
    };
}

function exportInvoiceSuccess(state, action) {
    return {
        ...state,
        exportInvoiceloading: false,
        exportInvoice: { ...action.payload }
    };
}

function exportInvoiceFailure(state, action) {
    return {
        ...state,
        exportInvoiceloading: false,
        exportInvoiceError: action.payload,
        exportInvoice: {}
    };
}

function downloadAnnexureBegin(state, action) {
    return {
        ...state,
        downloadAnnexureloading: true
    };
}

function downloadAnnexureSuccess(state, action) {
    return {
        ...state,
        downloadAnnexureloading: false,
        downloadAnnexure: { ...action.payload }
    };
}

function downloadAnnexureFailure(state, action) {
    return {
        ...state,
        downloadAnnexureloading: false,
        downloadAnnexureError: action.payload,
        downloadAnnexure: {}
    };
}

function getInvoicesDocketsListBegin(state, action) {
    return {
        ...state,
        docketListloading: true
    };
}

function getInvoicesDocketsListSuccess(state, action) {
    return {
        ...state,
        docketListloading: false,
        ...action.payload
    };
}

function getInvoicesDocketsListFailure(state, action) {
    return {
        ...state,
        docketListloading: false,
        invoicesError: action.payload,
        consignmentDetails: []
    };
}

function getCrDrNoteListBegin(state, action) {
    return {
        ...state,
        noteloading: true
    };
}

function getCrDrNoteListSuccess(state, action) {
    return {
        ...state,
        noteloading: false,
        ...action.payload
    };
}

function getCrDrNoteListFailure(state, action) {
    return {
        ...state,
        noteloading: false,
        invoicesError: action.payload,
        noteMappingBOS: [],
        noteBO: {}
    };
}

function getInvoicesPaymentsListBegin(state, action) {
    return {
        ...state,
        paymentListloading: true
    };
}

function getInvoicesPaymentsListSuccess(state, action) {
    return {
        ...state,
        paymentListloading: false,
        ...action.payload
    };
}

function getInvoicesPaymentsListFailure(state, action) {
    return {
        ...state,
        paymentListloading: false,
        invoicesError: action.payload,
        invoiceTransactions: []
    };
}

function getInvoicesListBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function getInvoicesListSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedInvoices: storePaginatedData(action?.payload?.currentPageNo, state.PaginatedInvoices ? state.PaginatedInvoices : {}, action?.payload?.invoiceBOs, action.isReset),
        invoices: { ...action.payload }
    };
}

function getInvoicesListFailure(state, action) {
    return {
        ...state,
        loading: false,
        invoicesError: action.payload,
        invoices: {}
    };
}

function getShipperListBegin(state) {
    return {
        ...state,
        shipperList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function getShipperListSuccess(state, action) {
    return {
        ...state,
        shipperList: {
            loading: false,
            data: action?.payload,
            metaData: null
        }
    }
}
function getShipperListFailure(state, action) {
    return {
        ...state,
        shipperList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function getCustomerListBegin(state) {
    return {
        ...state,
        customerList: {
            loading: true,
            data: []
        }
    }
}

function getCustomerListSuccess(state, action) {
    return {
        ...state,
        customerList: {
            loading: false,
            data: action?.payload
        }
    }
}
function getCustomerListFailure(state, action) {
    return {
        ...state,
        customerList: {
            loading: false,
            data: action && action.payload
        }
    }
}
