import React, { useState, useEffect } from 'react';
import { Modal, Button, Icon, Table, Input, Form, Spin } from "antd";
import boxTypeColumns from './boxtype.column';
import { fetchBoxTypes, updateCustomerAttribute } from "./boxtype.service";
import { Constants } from '../../../../../common/utils/constants';
import BoxTypeForm from "./boxtype.form";
import { notifyApiSuccess } from '../../../../../common/utils/utils';
function BoxTypeListing(props) {
    const [boxTypes, setBoxTypes] = useState([]);
    const [showBoxForm, setBoxForm] = useState(false);
    const [formData, setFormData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [loader, setLoader] = useState(false);
    const [commonKg, setCommonKg] = useState(props?.customer?.attributes?.kgPerCft ? props?.customer?.attributes?.kgPerCft : undefined);
    const [allowUpdate, setAllowUpdate] = useState(false);
    const [updatedCFG, setUpdatedCFG] = useState(props?.customer?.attributes?.kgPerCft ? props?.customer?.attributes?.kgPerCft : undefined);
    const [createAnother, setCreateAnother] = useState(false);
    useEffect(() => {
        setLoader(true);
        const data = {
            "partnerIds": [Constants.PARTNER_ID],
            "isAdhocBox": false,
            "customerIds": [props.customerId],
            "isActive": true
        }
        fetchBoxTypes(data).then(response => {

            setBoxTypes(response.boxTypes);
            setLoader(false);
        })
    }, [refresh]);

    const onCancel = () => {
        setBoxForm(false);
        setReadOnly(true);
        setFormData({});
    }
    return (
        <Spin spinning={loader}>
            <Modal
                closable={false}
                visible={props.visible}
                width={'60%'}
                style={{ top: 20 }}
                onCancel={props.onCancel}
                footer={null}
            >
                <div className="flex-column boxtype-modal">
                    <div className="flex-box align-items-center">
                        <div>
                            <Icon className="cursor-pointer" type="arrow-left" onClick={props.onCancel} /> {props.customer.name}
                        </div>
                        <div className="flex-box flex-1 justify-content-end">
                            <Button className="lsn-btn-primary" onClick={() => { setReadOnly(false); setFormData({}); setBoxForm(true) }}>Add Box Type</Button>
                        </div>
                    </div>
                    <div>
                        <Form.Item label="Common Kg/ CFT">
                            <Input
                                placeholder="Common KG/ CFT"                                
                                value={commonKg}
                                onChange={e => {
                                    const value = e.target.value;
                                    if (value) {
                                        if (new RegExp("^[.\\d]+$").test(value)) {
                                            setCommonKg(value)
                                            setAllowUpdate(true);
                                        }
                                    } else {
                                        setCommonKg(value);
                                        setAllowUpdate(false);
                                    }


                                }}
                                suffix={
                                    allowUpdate ?
                                        <Icon
                                            type="check-circle"
                                            onClick={() => {                                                
                                                const params = {
                                                    "entityId": props.customer.id,
                                                    "attributeKey": "kgPerCft",
                                                    "attributeValue": commonKg
                                                }
                                                updateCustomerAttribute(params).then(resp => {
                                                    if(resp.id) {
                                                        setUpdatedCFG(resp.attributeValue);
                                                        notifyApiSuccess("kgPerCft Updated", "SUCCESS!!")
                                                        setAllowUpdate(false);
                                                        props.setRefresh(!props.refresh);                                                        
                                                    }
                                                })

                                            }}
                                        />
                                        : <></>
                                }
                                style={{ width: '30%' }}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Table
                            rowKey={"id"}
                            bordered
                            columns={boxTypeColumns({
                                setBoxForm: setBoxForm,
                                setFormData: setFormData,
                                setReadOnly: setReadOnly
                            })}
                            dataSource={boxTypes}
                        />
                    </div>
                </div>

            </Modal>
            {showBoxForm &&
                <BoxTypeForm
                    visible={showBoxForm}
                    onCancel={onCancel}
                    customerId={props.customerId}
                    formData={formData}
                    readOnly={readOnly}
                    setBoxForm={setBoxForm}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    defaultKgCfg={updatedCFG}
                    setReadOnly={setReadOnly}
                    createAnother={createAnother} 
                    setCreateAnother={setCreateAnother}
                />
            }
        </Spin>
    )
}

export default BoxTypeListing;