import { Spin, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { fetchContracts } from "../../settings/contract/common/services";
import ContractDetails from "../../settings/contract/contracts/details/contract.details";
import DetailsNavigation, { NAV_DIRECTION } from "../components/details-navigation";
import { approveRejectContract, rejectReasons } from "./services";
import ContractRejectionModal from "./contract.rejection-modal";
import { notifyApiError } from "../../../common/utils/utils";

export default function ContractsNavigation(props) {

    const [loading, SetLoading] = useState(true);
    const [leftDisabled, SetLeftDisabled] = useState(false);
    const [rightDisabled, SetRightDisabled] = useState(false);
    const [contractsData, SetContractsData] = useState([]);
    const [contractParams, SetContractParams] = useState({});
    const [pageLocationParams, SetPageLocationParams] = useState(props?.location);
    const [currentIndex, SetCurrentIndex] = useState(0);
    const [showRejectModal, SetRejectModal] = useState(false);
    const [rejectLoader, SetRejectLoader] = useState(false);
    const [contractRejectedReasons, SetReasons] = useState([]);
    const [params, SetParams] = useState(props?.location?.state?.params);

    useEffect(() => {
        if (!params) return;
        fetchContracts(params).then(data => {
            if (data?.contracts?.length > 0) {
                SetContractsData(data);
                SetContractParams({
                    match: {
                        params: {
                            customerId: props?.location?.state?.customerId,
                            contractId: props?.location?.state?.contractid
                        }
                    }
                })
                SetPageLocationParams(props?.location)
                data.contracts.map((contract, index) => {
                    if (props?.location?.state?.contractid === contract.id) {
                        SetCurrentIndex(index)
                        SetRightDisabled(index + contractsData.offset === contractsData.totalCount)
                        SetLeftDisabled(index === 0)
                    }
                })
                SetLoading(false);
            }
            else {
                goBackToListing();
            }

        })
            .catch(error => {
                SetContractsData([]);
                SetLoading(false);
            })
    }, [])

    const updateContractDetails = (nextPage, nextIndex) => {
        // if (params.filter.pageNo == contractsData.maxPage) {
        //     goBackToListing();
        // }
        params.filter.pageNo = nextPage;
        SetParams(params);
        fetchContracts(params).then(data => {
            if (data?.contracts?.length > 0) {
                SetContractsData(data);
                var nextContract = {};
                if (nextIndex !== undefined) {
                    nextContract = data.contracts[nextIndex];
                    SetCurrentIndex(nextIndex)
                    SetRightDisabled(nextIndex + contractsData.offset === contractsData.totalCount)
                    SetLeftDisabled(nextIndex === 0)
                }
                else if (props?.location?.state?.contractid) {
                    nextContract = data.contracts.find((contract, index) => {
                        if (props?.location?.state?.contractid === contract.id) {
                            SetCurrentIndex(index)
                            SetRightDisabled(index + contractsData.offset === contractsData.totalCount)
                            SetLeftDisabled(index === 0)
                        }
                        return current;
                    })
                }
                SetContractParams({
                    match: {
                        params: {
                            customerId: nextContract?.customerId,
                            contractId: nextContract?.id
                        }
                    }
                })
                SetLoading(false);
            }
            else {
                goBackToListing();
            }

        })
            .catch(error => {
                SetContractsData([]);
                SetLoading(false);
            })
    }

    const goBackToListing = _ => {
        props.history.push(
            "/appv2/verification/dashboard/contract", { ...props.location?.state }
        );
    }

    const navigate = navDirection => {
        var nextContract = {};
        var nextIndex = currentIndex;
        if (navDirection === NAV_DIRECTION.RIGHT) {
            nextIndex = nextIndex + 1;
        }
        else if (navDirection === NAV_DIRECTION.LEFT) {
            nextIndex = nextIndex - 1;
        }
        if (nextIndex + contractsData.offset <= contractsData.totalCount) {
            SetLoading(true);
            SetRightDisabled(nextIndex + contractsData.offset === contractsData.totalCount)
            SetLeftDisabled(nextIndex + contractsData.offset === 1)
            if (nextIndex == contractsData.currentPageSize) {
                updateContractDetails(params.filter.pageNo + 1, 0);
                
            }
            else if (nextIndex == -1) {
                updateContractDetails(params.filter.pageNo - 1, 0);
                
            }
            else {
                SetCurrentIndex(nextIndex);
                nextContract = contractsData.contracts[nextIndex];
                SetContractParams({
                    match: {
                        params: {
                            customerId: nextContract?.customerId,
                            contractId: nextContract?.id
                        }
                    }
                });
                setTimeout(() => {
                    SetLoading(false);
                }, 100);
            }
        }
    }

    const rejectContract = _ => {
        rejectReasons().then(response => {

            if (response.length > 0) {
                SetReasons(response);
                SetRejectModal(true);
            }
        })


    }

    const reject = (rejectSelectedValue, rejectComment) => {
        SetRejectLoader(true);
        const rejectedContractsReasonsArray = contractRejectedReasons.filter(reason => rejectSelectedValue.includes(reason.id));
        let postObjReasons = rejectedContractsReasonsArray.map(reason => {
            return {
                id: reason.id,
                reasonDescription: reason.documentRejectedReason
            }
        });
        const data = {
            "contractBO": {
                "id": contractsData.contracts[currentIndex].id,
                "status": "REJECTED"
            },
            "reasonBO": {
                "remarks": rejectComment,
                reasons: postObjReasons
            }
        }

        approveRejectContract(data).then(response => {
            if (response.approveRejectSuccess) {
                SetRejectLoader(false);
                SetRejectModal(false);
                if (contractsData.totalCount - contractsData.offset > currentIndex) {
                    navigate(NAV_DIRECTION.RIGHT);
                } else {
                    goBackToListing();
                }
            } else {
                SetRejectLoader(false);
                SetRejectModal(false);
            }
        })
            .catch(e => notifyApiError(e ? e.toString() : "Action Failed"))
    }

    const approveSuccess = _ => {
        Modal.success({
            title: "Contract has been approved successfully!",
            onOk: _ => {
                Modal.destroyAll();
                if (contractsData.totalCount - contractsData.offset > currentIndex) {
                    navigate(NAV_DIRECTION.RIGHT);
                } else {
                    goBackToListing();
                }
            }
        })
    }

    const toggleEdit = toggle => {
        SetLoading(true);
        SetPageLocationParams({
            ...props?.location,
            state: {
                ...props?.location?.state,
                view: !toggle
            }
        })
        setTimeout(() => SetLoading(false), 100);
    }

    const onDetailsUpdate = _ => {
        SetLoading(true);
        SetPageLocationParams({
            ...props?.location,
            state: {
                ...props?.location?.state,
                view: true
            }
        })
        setTimeout(() => SetLoading(false), 100);
    }

    const fetchContractFail = _ => {
        goBackToListing();
    }
    
    return (
        <>
            <DetailsNavigation goBack={goBackToListing} navigate={navigate} restrictKeyboardNav={true}
                totalCount={contractsData.totalCount} index={currentIndex + contractsData.offset}
                leftDisabled={leftDisabled} rightDisabled={rightDisabled}
            />

            <Spin spinning={loading} wrapperClassName={"pod-spin"}>
                {
                    !loading &&
                    <ContractDetails match={contractParams.match} location={pageLocationParams}
                        verificationView={true} rejectContract={rejectContract} approveSuccess={approveSuccess}
                        fetchContractFail={fetchContractFail} history={props.history}
                        editContract={_ => toggleEdit(true)} cancelEdit={_ => toggleEdit(false)} onDetailsUpdate={onDetailsUpdate} />
                }
            </Spin>
            {showRejectModal &&
                <ContractRejectionModal
                    showRejectModal={showRejectModal}
                    contractRejectedReasons={contractRejectedReasons}
                    cancelRejectModal={() => SetRejectModal(false)}
                    loading={rejectLoader}
                    rejectContract={reject}
                />
            }
        </>
    )
}