import { validationRules } from "../../../../common/utils/constants";
const schema = {
    "type": "object",
    "properties": {
        "locationDetails": {
            "type": "object",
            "required": ["name", "code", "pincode", "address", "state"],
            "properties": {
               
                "code": {
                    "type": "string",
                    "title": "Location Code",
                    minLength: 3,
                    maxLength: 6,
                    pattern: validationRules.shortCode
                },
                "name": {
                    "type": "string",
                    "title": "Location Name",
                    pattern: validationRules.nameNumber
                },
                "address": {
                    "type": "string",
                    "title": "Address"
                },
                "pincode": {
                    "type": "number",
                    "title": "Pincode",
                    pattern: validationRules.pincode
                },
                "state": {
                    "type": "string",
                    "title": "State/City"
                },
                "zone": {
                    "type": "string",
                    "title": "Zone"
                },
            }
        }
    }
}

const uiSchema = {
    "locationDetails": {
        "classNames": "multi-row-field-objects hide-title",
        "name": {
            "ui:placeholder": "Enter Location Name",
            "maxLength": 50
        },
        "address": {
            "ui:placeholder": "Enter Address",
            "ui:widget": "GoogleAutoFillAddress",
            "maxLength": 500
        },
        "pincode": {
            "ui:placeholder": "Enter Pincode",
            maxLength: 6
        },
        "state": {
            "ui:placeholder": "Enter State/City",
            disabled: true
        },
        "zone": {
            "ui:placeholder": "Enter Zone",
            "ui:widget": "TextareaWidget",
            disabled: true
        },
        "code": {
            "minLength": "3",
            "maxLength": 6
        }
    }
}

const errorList = {
    "locationDetails": {
        name: {
            required: "Location name cannot be empty!",
            pattern: "Location name accepts only characters (a-z,A-Z,_,-,:)",    

        },
        code: {
            required: "Location code cannot be empty!",
            maxLength: "Location code length 3-6 characters",
            minLength: "Location code length 3-6 characters",
           
            pattern: "Location code accepts only characters (a-z,A-Z,_,-,:)",        
        },
        pincode: {
            required: "Pincode cannot be empty!",
            pattern: "Enter valid pincode"
        },
        address: {
            required: "Address cannot be empty!"
        },
        state: {
            required: "State/City cannot be empty!"
        },

    }

}

const validations = {
    name: {
        required: true,
        minLength: 3
    }
}

export default { schema, uiSchema, errorList, validations };