import React, { Component } from 'react';
import {
    Button, Input, Icon, Row, Col, Alert, Tooltip,
    Descriptions, Table, Spin, Modal, Divider, Empty, DatePicker
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import EwayGreen from '../../../assets/img/icons/eway_green.svg';
import EwayRed from '../../../assets/img/icons/eway_red.svg';
import EwayOrange from '../../../assets/img/icons/eway_orange.svg';

import FileUpload from "../../ui/file-upload/file.upload";
import { UPLOAD_BUTTON_TYPE } from '../../../common/utils/constants';
class LoadTrip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalKgs: 0,
            totalPcs: 0,
            visibleScan: false,
            visibleFilter: false,
            paginated: false,
            loading: false,
            hasMore: true,
            selectedRowDockets: [],
            selectedPendingRowDockets: [],
            selectedConsigns: [],
            selectedDockets: 0,
            dataSource: [],
            barcode: '',
            filter: '',
            scannerMode: false,
            setBack: false,
            createdTrip: false,
            maxPage: 0,
            totalCount: 0,
            barcodes: [],
            shipmentScans: [],
            scanDisabled: true,
            totalShipmentPcs: 0,
            shipmentError: undefined,
            searchText: '',
            searchedColumn: '',
            applyFilter: false,
            searchedFilters: [],
            noDataFoundMsg: null,
            pendingTripDeliveryModal: false,
            markDelFile: undefined,
            datepickervalue: moment(),
            acceptTypes: [".png", ".jpg", ".jpeg"],
            pendingWayBill: null,
            showRefresh: true,
            searchText: true,
        };
        this.props.changeButtonDisabled(true);
        this.props.changeScannerMode(false)

    }

    componentDidMount() {
        if (this.props.rowKeys.length > 0) {
            this.props.changeButtonDisabled(false);
            let sumPcs = 0, sumWeight = 0, totalShipmentPcs = 0;
            this.props.docketSummary.map(row => {

                if (this.props.appConfig?.barcode?.forward?.includes(row.consignment.customer.id.toString())) {

                    if (this.props.fields.dispatched.value == 'CL') {
                        this.props.changeCreateTripDisabled(true);
                    }
                    this.setState({ scannerMode: true }, () => {
                        this.props.changeScannerMode(true)
                    });
                    totalShipmentPcs += row.consignment.totalShipmentCount;
                }
                sumPcs += row.consignment.totalShipmentCount;
                sumWeight += row.consignment.totalWeight;
            });
            this.setState({
                totalPcs: sumPcs,
                totalKgs: sumWeight,
                totalShipmentPcs: totalShipmentPcs
            });
        }


    }


    componentDidUpdate(oldProps) {
        if (this.props.dockets?.viewResponse && this.props.dockets.viewResponse.length > 0 &&
            (this.state.dataSource.length <= 0 ||
                (oldProps.dockets?.viewResponse && oldProps.dockets.currentPageNo != this.props.dockets.currentPageNo && !this.state.applyFilter))) {
            this.setState({ dataSource: this.props.dockets.viewResponse, maxPage: this.props.dockets.maxPage, totalCount: this.props.dockets.totalCount });
        }
        if (this.props.dockets?.viewResponse &&
            (this.state.dataSource.length > 0 &&
                (this.props.dockets.currentPageNo > 1 && oldProps.dockets.currentPageNo != this.props.dockets.currentPageNo && !this.state.applyFilter))) {
            let dataSource = [...this.state.dataSource];
            dataSource = dataSource.concat(this.props.dockets.viewResponse);
            this.setState({
                dataSource,
                loading: false,
                maxPage: this.props.dockets.maxPage
            })

        }

        if (this.state.dataSource.length > 0 && this.state.applyFilter && this.props.dockets?.viewResponse) {
            // console.log("props->>", this.props.dockets?.viewResponse)
            let dataSource = [...this.state.dataSource];
            let searchedFilters = [...this.state.searchedFilters];
            console.log("search", this.state.searchedFilters, this.state.filter, this.props.dockets)
            if ((this.state.searchedFilters.length <= 0 || this.state.searchedFilters.indexOf(this.state.filter) < 0) && this.props.dockets?.viewResponse.length > 0) {
                dataSource.unshift(...this.props.dockets.viewResponse);
                if (this.state.searchText)
                    notifyApiSuccess('Dockets found!', "SUCCESS: ");
                searchedFilters.push(this.state.filter);
                this.setState({
                    dataSource,
                    applyFilter: false,
                    searchedFilters
                });
            } else {

                if (this.state.searchText)
                    notifyApiError('No Dockets found!', "Error: ");
                this.setState({ applyFilter: false, noDataFoundMsg: null })
            }

        }
        if (this.state.dataSource.length > 0 && this.state.applyFilter && _.isUndefined(this.props.dockets)) {
            let message = 'No Records Found';
            this.setState({ noDataFoundMsg: message, applyFilter: false });
        }


        if (this.state.scannerMode && this.state.selectedConsigns.length > 0) {
            this.setState({ scannerMode: false }, () => {
                this.props.changeScannerMode(false)
            });
            this.props.changeButtonDisabled(false);
        }

        if (!this.props.location?.state?.pending && !this.props.location?.state?.completed && this.props.docketError && this.props.docketError?.status?.code === 500 && !this.state.setBack) {
            this.setState({ setBack: true }, () => {
                this.props.goBack();
            });

        }

        if (!this.props.location?.state?.pending && !this.props.location?.state?.completed && this.props.createTripFlow && this.props.createTripFlow.responses && this.props.createTripFlow.responses.length > 0 && !this.state.createdTrip) {
            this.props.createTripFlow.responses.map(res => {
                if (res.sync === true) {
                    this.setState({ createdTrip: true }, () => {
                        this.props.history.push('/appv2/trips/dashboard');
                        this.props.setInitialState();
                    })
                }
            })
        }

        if (this.props.location?.state?.pending && this.props.createTripFlow && this.props.createTripFlow.responses && this.props.createTripFlow.responses.length > 0 && !this.state.createdTrip) {
            this.props.createTripFlow.responses.map(res => {
                if (res.sync === true) {
                    this.setState({ createdTrip: true }, () => {
                        this.props.history.push('/appv2/trips/dashboard/pending-trips');
                        this.props.setInitialState();
                    })
                }
            })
        }

        if (this.props.location?.state?.completed && this.props.createTripFlow && this.props.createTripFlow.responses && this.props.createTripFlow.responses.length > 0 && !this.state.createdTrip) {
            this.props.createTripFlow.responses.map(res => {
                if (res.sync === true) {
                    this.setState({ createdTrip: true }, () => {
                        this.props.history.push('/appv2/trips/dashboard/completed-trips');
                    })
                }
            })
        }


    }


    handleInfiniteOnLoad = () => {

        if (this.props.page == this.state.maxPage) {
            this.setState({ hasMore: false })
            return;
        }
        // let page = this.props.page;
        // for (let i = page + 1; i <= this.state.maxPage; i++) {
        //     setTimeout(() => {
        //         this.props.loadDockets(i, 10);
        //     });

        //     if (i == 2) {
        //         this.setState({
        //             loading: true,
        //             hasMore: true
        //         });
        //     }

        // }
        this.props.loadDockets(this.props.page + 1, 10);
        this.setState({
            loading: true,
            hasMore: true
        });


        // }

    }




    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            this.resetClear = clearFilters;
            return (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
            </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
            </Button>
                </div>
            )
        },
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : '#1890ff' }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };



    searchDockets = (event) => {
        // console.log("event ->>", event.target.value)
        this.setState({ filter: event.target.value, searchText: true });

    }

    loadSearchedDockets = e => {
        if (!_.isEmpty(this.state.filter)) {
            this.props.loadDockets(1, 10, this.state.filter);
            this.setState({ applyFilter: true, noDataFoundMsg: null })
        }

    }

    cleartextFilters = () => {
        this.setState({ filter: '', noDataFoundMsg: null, applyFilter: false });
    }

    onClose = () => {
        this.setState({ noDataFoundMsg: null });
    }
    showAllEwayBills = (eways) => {
        this.setState({ ewayBillsData: eways, showEwayBillsModal: true });
    }

    cancelEwayBillsModal = () => {
        this.setState({ ewayBillsData: [], showEwayBillsModal: false });
    }

    renderDockets() {
        let countShipments = {};
        let EWAY_ENUMS = {
            EwayRed: 0,
            EwayOrange: 0,
            EwayGreen: 0
        };
        let ewayKey = null, waybll = <></>;
        const columns = [
            {
                title: 'Docket ID',

                key: 'waybillNo',
                render: consign =>
                    <>
                        {consign.waybillNo} &nbsp; &nbsp;
                        {
                            consign.ewaybillBOs && consign.ewaybillBOs.length > 0 ?
                                <>{consign.ewaybillBOs.map(eway => {
                                    var validUpto = moment(eway.validUpto);
                                    var today = moment();
                                    var difference = validUpto.diff(today, 'hours');

                                    if (moment(eway.validUpto) <= moment()) {
                                        EWAY_ENUMS.EwayRed += 1;
                                        // return (
                                        //     <img src={EwayRed} />
                                        // )
                                    } else if (difference <= this.props.app.configurations.ewaybill.waringHours) {
                                        EWAY_ENUMS.EwayOrange += 1;
                                        // return <img src={EwayOrange} />
                                    } else {
                                        EWAY_ENUMS.EwayGreen += 1;
                                        // return <img src={EwayGreen} />
                                    }
                                }),

                                    ewayKey = Object.keys(EWAY_ENUMS).reduce((a, b) => EWAY_ENUMS[a] > EWAY_ENUMS[b] ? a : b),
                                    EWAY_ENUMS = {
                                        EwayRed: 0,
                                        EwayOrange: 0,
                                        EwayGreen: 0
                                    },

                                    ewayKey == 'EwayRed' ?
                                        <img src={EwayRed} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(consign.ewaybillBOs)} />
                                        :
                                        ewayKey == 'EwayOrange' ?
                                            <img src={EwayOrange} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(consign.ewaybillBOs)} /> :
                                            ewayKey == 'EwayGreen' ?

                                                <img src={EwayGreen} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(consign.ewaybillBOs)} />

                                                : <></>


                                }
                                    &nbsp; {consign.ewaybillBOs.length}</>
                                : <></>
                        }
                    </>
                // ...this.getColumnSearchProps('waybillNo')

            },
            {
                title: <>Origin <Icon type="caret-right" /> Destination</>,
                dataIndex: 'city',
                key: 'city',
                render: city => <>{city.origin} <Icon type="caret-right" /> {city.destination}</>
            },
            {
                title: 'Customer',
                dataIndex: 'customerName',
                key: 'customerName',
                render: customer => <>{customer.name} {!this.props.location?.state?.pending && !this.props.location?.state?.completed && this.props.appConfig.barcode.forward.includes(customer?.id?.toString()) ? <Icon type="scan" /> : <></>}</>
            },
            {
                title: <>Quantity / <br />Shipment Counts / <br />Total chargeable wt.</>,
                dataIndex: 'quantity',
                key: 'quantity'
            },
            {
                title: 'Created User',
                dataIndex: 'userName',
                key: 'userName'
            },
            {
                title: 'CPT (Customer Promise Time)',
                dataIndex: 'updatedAtCPT',
                key: 'updatedAtCPT',
            }
        ];
        const data = [];
        let dataSource = [...this.state.dataSource];
        let dataFiltered = dataSource.filter((ele, ind) => ind === dataSource.findIndex(elem => elem.consignmentBO.waybillNo === ele.consignmentBO.waybillNo))

        dataFiltered.map((docket, key) => {
            countShipments = Object.assign({}, countShipments, { [docket.consignmentBO.waybillNo]: 0 });

            data.push(
                {
                    'waybillNo': docket.consignmentBO.waybillNo,
                    'city': {
                        origin: docket.consignmentBO.customerPickupLoc?.address?.pincode?.city?.name,
                        destination: docket.consignmentBO.consignee?.address?.pincode?.city?.name
                    },
                    'customerName': docket.consignmentBO.customer,
                    'quantity': <> {docket.consignmentBO.totalShipmentCount} Pcs / {docket.consignmentBO.totalChargableWeight} Kgs </>,
                    'userName': docket.consignmentBO.user.name,
                    'updatedAtCPT': moment(docket.consignmentBO.updatedAt).format("DD MMM YYYY hh:MM"),
                    'scannable': docket.consignmentBO.customer,
                    'key': key,
                    ...docket.consignmentBO
                }
            );

            // let dataKeys = {};
            // let indexes = [];
            // data.map((d,i)=> {
            //     if(!dataKeys[d.waybillNo]) {
            //         dataKeys[d.waybillNo] = d;

            //     } else {
            //         indexes.push(i)
            //     }
            // });
            // let tempData = data;
            // if(indexes.length > 0) {
            //     indexes.map(i => {
            //         data.splice(i, 1);
            //     })
            // }

            // let someData = data.filter(d => {
            //     // console.log("d->>", d.waybillNo, this.state.searchedFilters.indexOf(d.waybillNo.toString()))

            //     return this.state.searchedFilters && this.state.searchedFilters.indexOf(d.waybillNo.toString())>=0;
            // });

            // console.log("somedata->>", dataKeys, indexes)






        });
        const rowSelection = {
            selectedRowKeys: this.props.rowKeys,
            onChange: (selectedRowKeys, selectedRows) => {

                if (this.props.location?.state?.pending || this.props.location?.state?.completed) {
                    // console.log(selectedRowKeys, selectedRows)

                    this.props.onChangeRowKeys(selectedRowKeys);
                    let sumPcs = 0, sumWeight = 0, totalShipmentPcs = 0;

                    if (selectedRows.length <= 0) {
                        this.props.changeCreateTripDisabled(true);
                    } else {
                        this.props.changeCreateTripDisabled(false);
                    }
                    selectedRows.map(row => {

                        // if (this.props.appConfig?.barcode?.forward?.includes(row.customer.id.toString())) {
                        //     this.props.changeButtonDisabled(true);
                        //     if (this.props.fields.dispatched.value == 'CL') {
                        //         this.props.changeCreateTripDisabled(true);
                        //     }
                        //     this.setState({ scannerMode: true }, () => {
                        //         this.props.changeScannerMode(true)
                        //     });
                        //     totalShipmentPcs += row.totalShipmentCount;
                        // }
                        sumPcs += row.totalShipmentCount;
                        sumWeight += row.totalWeight;
                    });

                    // if (selectedRows.length <= 0) {
                    //     this.setState({ scannerMode: false }, () => {
                    //         this.props.changeScannerMode(false)
                    //     });
                    // } else {
                    //     this.props.changeScannerMode(false)
                    // }
                    this.setState({
                        selectedPendingRowDockets: selectedRows,
                        totalPcs: sumPcs,
                        totalKgs: sumWeight,
                        totalShipmentPcs: totalShipmentPcs
                    }, () => {
                        // this.props.onChange(this.state.selectedRowDockets);
                        // this.props.onChangePendingDockets(this.state.selectedPendingRowDockets) // add this function in confirm ok
                        this.props.changeTotalPrice(sumPcs, sumWeight, totalShipmentPcs);
                        if (this.state.selectedRowDockets.length > 0)
                            this.props.changeCreateTripDisabled(false);
                    });



                } else {
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

                    this.props.onChangeRowKeys(selectedRowKeys);
                    // let  selectedRowDockets  = [...this.state.selectedRowDockets];
                    let sumPcs = 0, sumWeight = 0, totalShipmentPcs = 0;

                    if (selectedRows.length <= 0) {
                        this.props.changeButtonDisabled(true);
                        if (this.props.fields.dispatched.value == 'CL') {
                            this.props.changeCreateTripDisabled(true);
                        }
                    } else {
                        this.props.changeButtonDisabled(false);
                        if (this.props.fields.dispatched.value == 'CL') {
                            this.props.changeCreateTripDisabled(false);
                        }
                    }
                    selectedRows.map(row => {

                        if (this.props.appConfig?.barcode?.forward?.includes(row?.customer?.id?.toString())) {
                            this.props.changeButtonDisabled(true);
                            if (this.props.fields.dispatched.value == 'CL') {
                                this.props.changeCreateTripDisabled(true);
                            }
                            this.setState({ scannerMode: true }, () => {
                                this.props.changeScannerMode(true)
                            });
                            totalShipmentPcs += row.totalShipmentCount;
                        }
                        sumPcs += row.totalShipmentCount;
                        sumWeight += row.totalWeight;
                    });

                    if (selectedRows.length <= 0) {
                        this.setState({ scannerMode: false }, () => {
                            this.props.changeScannerMode(false)
                        });
                    } else {
                        this.props.changeScannerMode(false)
                    }
                    this.setState({
                        selectedRowDockets: selectedRows,
                        totalPcs: sumPcs,
                        totalKgs: sumWeight,
                        totalShipmentPcs: totalShipmentPcs
                    }, () => {
                        this.props.onChange(this.state.selectedRowDockets);
                        this.props.changeTotalPrice(sumPcs, sumWeight, totalShipmentPcs);
                        if (this.state.selectedRowDockets.length > 0)
                            this.props.changeButtonDisabled(false);
                    });
                }



                // selectedRowDockets.push(selectedRows);
                // this.setState({selectedRowDockets})
                // selectedRows[0]['quantity'] = countShipments[selectedRows[0]['waybillNo']] + 1 + '/' + selectedRows[0]['totalShipmentCount'];
            },
            onSelect: (record, selected, selectedRows, nativeEvent) => {
                // console.log("onselect->>", record, selected, selectedRows, nativeEvent);
                if (this.props.location?.state?.pending || this.props.location?.state?.completed) {
                    if (selected) {
                        this.setState({ pendingTripDeliveryModal: true, pendingWayBill: record.waybillNo });
                    } else {
                        let consigns = this.props.pendingConsigments.filter(docket => {
                            return docket.id !== record.id;
                        });


                        this.props.onChangePendingDockets(consigns);


                    }
                }
            },
            getCheckboxProps: record => {
                // console.log("record->>", record)
                // disabled: record.name === 'Disabled User', // Column configuration not to be checked
                // console.log("record->>", record)
            },
        };

        // const loadDockets = (page, pageSize) => {
        //     this.props.loadDockets(page, pageSize);
        // }




        return (
            <div style={{ height: "300px", overflow: "auto" }}>
                {/* <Spin spinning={this.state.applyFilter}> */}
                <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.handleInfiniteOnLoad}
                    hasMore={!this.state.loading && this.state.hasMore}
                    useWindow={false}
                >
                    <Spin spinning={this.state.applyFilter}>
                        <Table
                            rowKey={(row) => (row.waybillNo)}
                            columns={columns}
                            dataSource={data}
                            rowSelection={rowSelection}
                            pagination={false}
                        // pagination={{
                        //     total: this.props.dockets.totalCount,
                        //     current: this.props.dockets.currentPageNo,
                        //     pageSize: this.props.dockets.currentPageSize,
                        //     onChange: loadDockets
                        // }}
                        />
                    </Spin>
                    {this.state.loading && this.state.hasMore && (
                        <div className="has-more-loading" style={{
                            textAlign: 'center',
                            marginTop: 12,
                            height: 32,
                            lineHeight: '32px',
                        }}>
                            <Spin />
                            {/* <Button onClick={this.handleInfiniteOnLoad}>Load More</Button> */}
                        </div>
                    )}
                </InfiniteScroll>
                {/* </Spin> */}

            </div>

        )
    }


    openModal = (filter) => {
        this.setState({ [filter]: true })
    }

    handleCancel = (filter, state) => {
        this.setState({ [filter]: false })
        if (state) {
            if (this.state[state].length > 0) {
                this.setState({ [state]: [], shipmentError: undefined, scanDisabled: true, barcodes: [], barcode: '' });
            }
        }

    }

    changeBarcode = (e) => {
        this.setState({ barcode: e.target.value })
    }

    onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    }

    onCloseShipment = () => {
        this.setState({ shipmentError: undefined });
    }

    submitBarcode = () => {
        // console.log(this.state.barcode, this.state.selectedRowDockets, this.state.barcodes, this.state.barcode, this.state.barcodes.indexOf(this.state.barcode));
        if (this.state.barcodes.indexOf(this.state.barcode) >= 0) {
            return;
        } else {
            this.state.selectedRowDockets.map((consigns) => {
                consigns.shipmentBOs.map(consign => {
                    consign.barcodes.map(barcode => {
                        if (this.state.barcode === barcode.barcode) {
                            this.setState(prevState => ({
                                selectedConsigns: [...prevState.selectedConsigns, consigns],
                                barcodes: [...prevState.barcodes, this.state.barcode],
                                shipmentError: '',
                                shipmentScans: [...prevState.shipmentScans, {
                                    "shipmentCodeType": "BARCODE",
                                    "scanInputType": "BARCODE_SCANNER",
                                    "shipmentCode": this.state.barcode,
                                    "shipmentId": consign.id,
                                    "entityType": "TRIP",
                                    "consignmentId": consigns.id,
                                    "scanType": "TRIP"
                                }]
                            }), () => {
                                if (this.state.barcodes.length === this.state.totalShipmentPcs) {
                                    this.setState({ scanDisabled: false })
                                }
                            });
                            // this.setState({selectedConsigns: [consigns]});
                        } else {
                            // this.setState({shipmentError: 'Invalid BarCode'})
                        }
                    })
                })
            })
        }
    }

    submitShipmentScans = (filter) => {
        // console.log("this.state->>", this.state.shipmentScans);
        this.props.onChangeShipmentScans(this.state.shipmentScans);
        this.setState({ [filter]: false })
    }

    onChangePod = (date, dateString) => {
        this.setState({ datepickervalue: date })
    }

    markDeliverCancel = () => {
        this.setState({ pendingTripDeliveryModal: false })
    }

    onUploadFinish = (e) => {
        this.setState({ markDelFile: e });
    }

    markDeliverOk = () => {
        // console.log(this.state.markDelFile, this.state.datepickervalue, this.state.selectedPendingRowDockets);
        let consignments = [];
        let pendingDocket = [];
        consignments = this.state.selectedPendingRowDockets.map(dockets => {

            if (dockets.waybillNo == this.state.pendingWayBill) {
                let docket = {};
                if (_.isUndefined(this.state.markDelFile)) {
                    pendingDocket.push({
                        "id": dockets.id,
                        "consignmentPOD": {
                            "podCreatedDate": this.state.datepickervalue.valueOf()
                        }
                    });
                } else {
                    pendingDocket.push({
                        "id": dockets.id,
                        "consignmentPOD": {
                            "podImpLink": this.state.markDelFile,
                            "podCreatedDate": this.state.datepickervalue.valueOf()
                        }
                    });
                }
                // if (_.isUndefined(this.state.markDelFile)) {
                //     pendingDocket = {
                //         "id": dockets.id,
                //         "consignmentPOD": {
                //             "podCreatedDate": this.state.datepickervalue.valueOf()
                //         }
                //     };
                // } else {
                //     pendingDocket = {
                //         "id": dockets.id,
                //         "consignmentPOD": {
                //             "podImpLink": this.state.markDelFile,
                //             "podCreatedDate": this.state.datepickervalue.valueOf()
                //         }
                //     };
                // }


                return pendingDocket;
            }


        });
        // return dockets.waybillNo == this.state.pendingWayBill;    
        let newDockets = [];
        if (this.props.pendingConsigments && this.props.pendingConsigments.length > 0) {
            newDockets = [...this.props.pendingConsigments];
            pendingDocket = [...pendingDocket, ...newDockets];
        } else {
            pendingDocket = [...pendingDocket];
        }


        console.log("props-", this.props)

        this.props.onChangePendingDockets(pendingDocket);

        this.setState({ pendingTripDeliveryModal: false, datepickervalue: moment(), markDelFile: undefined });
    }


    disabledDate = current => {
        // const numberOfDays = this.props.app.configurations.restrictTripCreation.pickupDate.dayOfClosingMonth;
        return current && current > moment().endOf("day");
    }

    refreshDockets = () => {
        this.props.getRefreshDockets();
        this.props.onChangeRowKeys([]);
        this.setState({ showRefresh: false, applyFilter: true, searchText: false });
    }




    render() {

        return (
            <>
                {this.props.location?.state?.pending || this.props.location?.state?.completed ?
                    <Row>
                        {/* <Col span={14}>
                                {this.state.searchText.length > 0
                                    ? <Button type="primary" onClick={this.handleResetClear}>Reset</Button> : <></>
                                }
                            </Col> */}
                        <Col span={14}>
                            <Input
                                placeholder="Search"
                                onChange={this.searchDockets}
                                value={this.state.filter}
                                onPressEnter={this.loadSearchedDockets}
                                suffix={
                                    this.state.filter.length > 0 ?
                                        <Icon type="close" onClick={this.cleartextFilters} />
                                        : <></>
                                }
                                prefix={<Icon type="search" />}
                            />



                        </Col>

                        {/* <Col span={5} offset={5}>
                                <Button onClick={() => this.openModal("visibleScan")} disabled={!this.state.scannerMode}><Icon type="scan" />Scan</Button>
                                <Button onClick={() => this.openModal("visibleFilter")}><Icon type="filter" />Filters</Button>
                            </Col> */}
                    </Row> : <></>
                }
                {this.state.dataSource && this.state.dataSource?.length > 0 ?
                    <>
                        <Descriptions layout="vertical" size="small" colon={false} column={{ xs: 24, sm: 16, md: 6 }}>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Total Dockets</small>}>
                                <strong>{this.state.totalCount}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Selected Dockets</small>}>
                                <strong>{this.state.selectedRowDockets.length > 0 ? this.state.selectedRowDockets.length : this.props.rowKeys.length}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small"><>Quantity / Shipment Counts / <br />Total chargeable wt.</></small>}>
                                <strong>{this.state.totalPcs} Pcs ({this.state.totalKgs} Kgs)</strong>
                            </Descriptions.Item>
                            {!this.props.location?.state?.pending && !this.props.location?.state?.completed && this.props.fields.dispatched.value == 'DV' ?
                                <Descriptions.Item
                                    className="trip-descriptions-item"
                                    label={<small className="trip-description-small">Route</small>}>
                                    <strong>{this.props.fields.route.value.label}</strong>
                                </Descriptions.Item> : <></>
                            }

                        </Descriptions>
                        <br />
                        <Row>
                            {/* <Col span={14}>
                                {this.state.searchText.length > 0
                                    ? <Button type="primary" onClick={this.handleResetClear}>Reset</Button> : <></>
                                }
                            </Col> */}
                            {this.props.location?.state?.pending || this.props.location?.state?.completed ? <></> :
                                <>
                                    <Col span={14}>
                                        <Input
                                            placeholder="Search"
                                            onChange={this.searchDockets}
                                            value={this.state.filter}
                                            onPressEnter={this.loadSearchedDockets}
                                            suffix={
                                                this.state.filter.length > 0 ?
                                                    <Icon type="close" onClick={this.cleartextFilters} />
                                                    : <></>
                                            }
                                            prefix={<Icon type="search" />}
                                        />



                                    </Col>
                                    <>
                                        {this.state.showRefresh &&
                                            <Col span={3}>
                                                <Tooltip title={'Refresh'}>
                                                    <Button type="primary" onClick={this.refreshDockets}><Icon type="reload" /></Button>
                                                </Tooltip>
                                            </Col>}
                                    </>
                                </>


                            }

                            {/* <Col span={5} offset={5}>
                                <Button onClick={() => this.openModal("visibleScan")} disabled={!this.state.scannerMode}><Icon type="scan" />Scan</Button>
                                <Button onClick={() => this.openModal("visibleFilter")}><Icon type="filter" />Filters</Button>
                            </Col> */}
                        </Row>
                        <br />
                        {!_.isNull(this.state.noDataFoundMsg) ? <Row><Col span={14}><Alert closable message={this.state.noDataFoundMsg} type="error" onClose={this.onClose} /></Col></Row> : <></>}

                        <br />
                        {this.renderDockets()}
                        <Modal
                            title={"EwayBill"}
                            visible={this.state.showEwayBillsModal}
                            onCancel={this.cancelEwayBillsModal}
                            footer={[
                                <Button key="submit" type="primary" onClick={this.cancelEwayBillsModal}>
                                    OK
                            </Button>,
                            ]}
                        >
                            <p>
                                <ul>
                                    {this.state.ewayBillsData && this.state.ewayBillsData.length > 0 ? this.state.ewayBillsData.map(eway => (
                                        <li>
                                            <li>Ewaybill No: {eway.ewaybillNo}</li>
                                            <li>{moment(eway.validUpto) < moment() ? 'Expired on: ' : 'Valid Upto: '}
                                                {moment(eway.validUpto).format("DD-MMM-YYYY HH:mm")}</li>
                                        </li>
                                    )) : <></>}
                                </ul>
                            </p>

                        </Modal>


                        {/* <Modal
                            visible={this.state.visibleScan}
                            title="Scan Dockets"
                            width={1000}
                            onOk={this.handleOk}
                            onCancel={() => this.handleCancel("visibleScan", "selectedConsigns")}
                            footer={
                                this.state.selectedConsigns.length > 0 ?
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={4}>Docket ID</Col>
                                            <Col span={4}>Route</Col>
                                            <Col span={4}>Customer</Col>
                                            <Col span={4}>Qunatity</Col>
                                            <Col span={4}>Created User</Col>
                                            <Col span={4}>CPT (Customer Promise Time)</Col>
                                        </Row>

                                        {
                                            this.state.selectedConsigns.length >= 0 && this.state.selectedConsigns.map((consign, index) => (
                                                <>
                                                    <Row gutter={[8, 16]}>
                                                        <Col span={4}>{consign.waybillNo}</Col>
                                                        <Col span={4}>{consign.city.origin} <Icon type="caret-right" /> {consign.city.destination}</Col>
                                                        <Col span={4}>{consign.customerName.name}</Col>
                                                        <Col span={4}>{consign.totalShipmentCount}</Col>
                                                        <Col span={4}>{consign.userName}</Col>
                                                        <Col span={4}>{consign.updatedAtCPT}</Col>
                                                    </Row>
                                                    <Divider />
                                                </>
                                            ))
                                        }
                                    </> : <></>
                            }
                        >

                            <Input
                                onChange={this.changeBarcode}
                                value={this.state.barcode}
                                onPressEnter={this.submitBarcode}
                                suffix={<Icon type="scan" />}
                                style={{ width: '50%' }}
                            />
                            <p>Use Barcode Scanner for faster process or enter manually</p>
                            {this.state.shipmentError ?
                                <Alert
                                    style={{ width: '50%' }}
                                    message={this.state.shipmentError}
                                    type="warning"
                                    closable
                                    onClose={this.onCloseShipment}
                                /> : <></>
                            }
                            <br />

                            <div>
                                <Button type="primary" disabled={this.state.scanDisabled} onClick={() => this.submitShipmentScans("visibleScan")}>Submit</Button>
                            </div>


                        </Modal> */}

                        {/* <Modal
                            visible={this.state.visibleFilter}
                            title="Filters"
                            width={360}
                            onOk={this.handleOk}
                            onCancel={() => this.handleCancel("visibleFilter")}
                            footer={[
                                <Button key="submit" type="primary" >
                                    Apply
                                </Button>,
                            ]}
                        >
                            <Row gutter={[8, 16]}>
                                <Col span={24}>
                                    <div>Select Date</div>
                                    <DatePicker style={{ width: "100%" }} onChange={this.onChangeDate} />
                                </Col>
                                <Col span={24}>
                                    <div>Destination Locations</div>
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select location"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option>
                                    </Select>

                                </Col>
                                <Col span={24}>
                                    <div>Customer Name</div>
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select Customer"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option>
                                    </Select>
                                </Col>
                            </Row>




                        </Modal> */}
                    </>
                    : <div className="loading-table" style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 200,
                        lineHeight: '32px',
                    }}>
                        <Spin spinning={this.props.docketLoading} />
                        {!this.props.docketLoading ? <Empty /> : <></>}
                    </div>
                }

                {
                    this.state.pendingTripDeliveryModal ?
                        <Modal
                            title={<><br /></>}
                            visible={this.state.pendingTripDeliveryModal}
                            onCancel={this.markDeliverCancel}
                            width={400}
                            footer={[
                                <Button key="back" onClick={this.markDeliverCancel}>
                                    Cancel
                            </Button>,
                                <Button key="submit" type="primary" onClick={this.markDeliverOk}>
                                    Confirm
                            </Button>,
                            ]}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <div className="form-label-text">Delivery Date and time</div>
                                    <DatePicker disabledDate={this.disabledDate} value={this.state.datepickervalue} showTime style={{ width: '100%' }} onChange={this.onChangePod} />


                                </Col>
                                <Col span={24}>
                                    <span className="form-label-text">Photo</span>
                                    <FileUpload
                                        fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                        directUpload={true}
                                        disableUploadButton={false}
                                        onUploadFinish={($events) => this.onUploadFinish($events)}
                                        path="trips"
                                        acceptTypes={this.state.acceptTypes}
                                        objKey="trips"
                                    />
                                </Col>
                            </Row>

                        </Modal>

                        : <></>
                }
            </>
        )
    }
}

export default LoadTrip;