
export const initialState = {
    loading: false
}

export const FETCH_RUNSHEET_BEGIN = "FETCH_RUNSHEET_BEGIN";
export const FETCH_RUNSHEET_SUCCESS = "FETCH_RUNSHEET_SUCCESS";
export const FETCH_RUNSHEET_FAILURE = "FETCH_RUNSHEET_FAILURE";

export const FETCH_LOCATION_USER_BEGIN = "FETCH_LOCATION_USER_BEGIN";
export const FETCH_LOCATION_USER_SUCCESS = "FETCH_LOCATION_USER_SUCCESS";
export const FETCH_LOCATION_USER_FAILURE = "FETCH_LOCATION_USER_FAILURE";

// export const FETCH_ASSIGN_FE_BEGIN = "FETCH_ASSIGN_FE_BEGIN";
// export const FETCH_ASSIGN_FE_SUCCESS = "FETCH_ASSIGN_FE_SUCCESS";
// export const FETCH_ASSIGN_FE_FAILURE = "FETCH_ASSIGN_FE_FAILURE";
