import React, { Component } from 'react';
import './sla.scss';
import { fetchSLAConsignments, fetchSLACustomers, fetchSLAReasons, setSLAReasons, fetchSLAConsignmentsSummary, fetchSLAConsignmentsExport, fetchSLALocations } from './actions.js';
import { connect } from 'react-redux';
import { Card, Row, Col, Input, Form, Select, Table, Empty, DatePicker, Button, Spin, Radio, Tooltip, Icon, Checkbox, Alert, Tag, Modal } from 'antd';
import Sparkline from '../ui/sparkline/Sparkline';
import moment from 'moment';
import { reportsDownload, checkForDownloadReportStatus, notifyApiSuccess } from '../../common/utils/utils';
import { DownloadTags, Constants } from '../../common/utils/constants';
import * as appActions from '../../app/appAction';
import * as trackingActions from "../tracking/action";
import { canAllow } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import _ from 'lodash';
import noAction from "../../assets/img/icons/noaction.png";
import { Mixpanel } from '../../utils/mixpanel';

// import TextArea from 'antd/lib/input/TextArea';
const { TextArea } = Input;


export const docketStatus = [
  {
    label: 'Pending',
    value: 'IN',
    isActive: true
  },
  {
    label: 'IN_TRANSIT',
    value: 'IN_TRANSIT',
    isActive: true
  },
  // {
  //   label: 'Delivered',
  //   value: 'DELIVERED',
  //   isActive: true
  // }
];

export const filterDocketStatus = [
  // {
  //   label: 'All',
  //   value: 'All',
  //   isActive: true
  // },
  {
    label: 'Pending',
    value: 'IN',
    isActive: true
  },
  {
    label: 'IN_TRANSIT',
    value: 'IN_TRANSIT',
    isActive: true
  },
  // {
  //   label: 'Delivered',
  //   value: 'DELIVERED',
  //   isActive: true
  // }
];

export const deliveryStatus = [
  {
    label: 'Yes',
    value: 'YES',
    isActive: true
  },
  {
    label: 'No',
    value: 'NO',
    isActive: true
  }
];

class SLAReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardExpanded: true,
      noBreachAvailability: true,
      showReasonModal: false,
      docketRow: {},
      reasonCategory: undefined,
      reasonFilter: undefined,
      breachCategoryFilter: undefined,
      deliveryBreachLevel: undefined,
      bookingOffice: undefined,
      showExportDownload: false,
      reasons: undefined,
      customerValue: undefined,
      remarks: undefined,
      customerRemarks: undefined,
      checked: false,
      editDelay: false,
      slaReasons: [],
      pageNo: 1,
      Breachvalue: false,
      docketType: 'All',
      allLocations: [],
      allDeliveryLocations: [],
      slaReasonPermission: {
        read: canAllow(permissionTags.trips.sla.read),
        update: canAllow(permissionTags.trips.sla.update),
        create: canAllow(permissionTags.trips.sla.create)
      },
      newFilter: {
        consignmentFilter: {
          "pickupDate": {
            from: moment().subtract(7, 'd').startOf('day').valueOf(),
            to: moment().endOf('day').valueOf()
          },

          // "onlyRootConsignment": true,
          "partnerIds": [Constants.PARTNER_ID],
        },
        "slaDelayReasonFilter": {
          "reasonCategoryIds": [],
          "reasonIds": []
        }
      },
      selectedAll: true,
      loading: false,
      startDate: moment().subtract(7, 'd').startOf('day'),
      endDate: moment().endOf('day').milliseconds(0),
      docketStatuses: ['IN', 'IN_TRANSIT'],
      deliveryBreachStatus: 'YES',
      searchValue: 'docket',
      searchByValue: '',
      searchedValue: [],
      isEndDateOpen: false,
      filterPayload: {
        consignmentFilter: {
          "pageNo": 1,
          "pageSize": 10,
          "statuses": [
            "DELIVERED"
          ],
          // "fetchObjects": ["SLA_DELAY_REASON", "CUSTOMER", "CONTACT_FAV_LOCATION", "WAYBILL_POD", "PINCODE", "CONSIGNMENT_B2B_SHORTCODE"],
          "fetchObjects": [
            "SLA_DELAY_REASON",
            "CUSTOMER",
            "CONTACT_FAV_LOCATION",
            "WAYBILL_POD",
            "PINCODE",
            "CONSIGNMENT_B2B_SHORTCODE",
            "REASON_PARTNER"
          ],
          // "onlyRootConsignment": true,
          "pickupDate": {
            from: moment().subtract(7, 'd').startOf('day').milliseconds(0).valueOf(),
            to: moment().endOf('day').milliseconds(0).valueOf()
          },
          // "deviceFilter": {
          //   "view": "ERP_CONSIGNMENT_SLA_DELAY_REASON"
          // },

          "bookingOfficeLocationIds": [],
          "customerIds": [],
          // "isDeliveryBreached": true,
          isBreachReasonAvailable: false,
          "partnerIds": [Constants.PARTNER_ID],
        },
        "slaDelayReasonFilter": {
          "reasonCategoryIds": [],
          "reasonIds": []
        }
      },
      columns: [
        {
          title: 'Docket No.',
          dataIndex: 'waybillNo',
          key: 'waybillNo',
          fixed: 'left',
          width: 120,
          render: (data, row) => (<span style={{ color: '#2c5de5' }}>{data}</span>),
          // render: (data, row) => (<div onClick={() =>  this.props.getWaybillTrackingDetails({
          //   waybillNo: data,
          //   source: Constants.HEADER_CLASSNAME
          // })}>{data}</div>)
        },
        {
          title: 'Pickup Date',
          dataIndex: 'pickupDate',
          key: 'pickupDate',
          width: 110,
          render: (data) => moment(data).format('DD/MM/YYYY hh:mm A')
        },
        {
          title: 'Booking Date',
          dataIndex: 'bookingDate',
          key: 'bookingDate',
          width: 130,
          render: (data) => moment(data).format('DD/MM/YYYY hh:mm A')
        },
        {
          title: 'Customer Name',
          dataIndex: 'customerName',
          key: 'customerName',
          width: 110
        },
        {
          title: 'Booking Office',
          dataIndex: 'bookingOffice',
          key: 'bookingOffice',
          width: 110
        },
        {
          title: 'Docket Type',
          dataIndex: 'docketType',
          key: 'docketType',
          width: 110
        },
        {
          title: 'Origin',
          dataIndex: 'origin',
          key: 'origin',
          width: 110
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
          key: 'destination',
          width: 110
        },
        {
          title: 'CPT',
          dataIndex: 'cpt',
          key: 'cpt',
          width: 110,
          render: data => data ? (<span>{moment(data).format('DD/MM/YYYY hh:mm A')}</span>) : <></>
        },
        {
          title: 'Breached Level',
          dataIndex: 'breachStatus',
          key: 'isBreached',
          width: 130,
          render: (data, row) => {
            let str = "";
            let tooltip = "";
            // str = row.breachStatus;
            if (row.breachLevel) {
              if (row?.breachLevel === 'High') {
                tooltip = <Tooltip title={'breach hours > 3'}>
                  <Tag color="#fe6769">{row?.breachLevel}</Tag>
                </Tooltip>
              } else if (row?.breachLevel === 'Low') {
                tooltip = <Tooltip title={'breach hours < 1'}>
                  <Tag color="#b6deb5">{row?.breachLevel}</Tag>
                </Tooltip>
              } else if (row?.breachLevel === 'Moderate') {
                tooltip = <Tooltip title={'breach hours between 1 - 3'}>
                  <Tag color="#ffd65c">{row?.breachLevel}</Tag>
                </Tooltip>
              } else {
                tooltip = <>{row.breachLevel}</>
              }
            }
            return <>{tooltip}</>
          }
          // render: (data) => data == "true" ? "Breached" : "Not Breached"
        },
        // {
        //   title: 'Breach Level',
        //   dataIndex: 'isBreached',
        //   key: 'isBreached',
        //   align: 'center',
        //   width: 120,
        //   render: (data, record) => {
        //     return <>
        //       {data ?
        //         <div>
        //           {/* {data} */}
        //           <div>
        //             {record?.breachedLevel &&
        //               record?.breachedLevel === 'High' ?
        //               <Tooltip title={'breach hours > 3'}>
        //                 <Tag color="#fe6769">{record?.breachedLevel}</Tag>
        //               </Tooltip> :
        //               (record?.breachedLevel === 'Low' ?
        //                 <Tooltip title={'breach hours < 1'}>
        //                   <Tag color="#b6deb5">{record?.breachedLevel}</Tag>
        //                 </Tooltip>
        //                 : record?.breachedLevel === 'Moderate' ?
        //                   <Tooltip title={'breach hours between 1 - 3'}>
        //                     <Tag color="#ffd65c">{record?.breachedLevel}</Tag>
        //                   </Tooltip> :
        //                   record?.breachedLevel)
        //             }
        //           </div>

        //         </div>
        //         : <></>
        //       }


        //     </>

        //   }
        // },
        {
          title: <div className="category">Delayed Category</div>,
          dataIndex: 'categoryId',
          key: 'categoryId',
          width: 150,
          render: (data) => {
            if (data) {
              return this.props?.slaBreachReasons?.reasonCategories?.map(r => {
                if (r.id === data) {
                  return <>{r.name}</>
                } else {
                  return <></>
                }
              })
            }
          }
        },
        {
          title: 'Delayed Reason',
          dataIndex: 'reasonId',
          key: 'reasonId',
          width: 110,
          render: (data, row) => {
            if (data, row) {
              return this.props?.slaBreachReasons?.reasonCategories?.map(r => {
                if (r.id === row.categoryId) {

                  return r.reasons.map(reason => {
                    // console.log("r, ",reason, r)
                    if (reason.reasonId === data) {
                      return reason.reasonDescription;
                    }
                  })
                } else {
                  return <></>
                }
              })
            }
          }
        },

        {
          title: 'Remarks',
          dataIndex: 'remarks',
          key: 'remarks',
          width: 130,
          render: (data) => {
            if (data && data.length > 0) {
              if (data.length < 30)
                return data
              else {
                let str = data.substring(0, 30);
                return <>{str} <Tooltip title={data}>...</Tooltip></>
              }

            }
          }
        },
        {
          title: 'Action',
          dataIndex: 'action.',
          key: 'action',
          fixed: 'right',
          render: (data, row) => {

            if (row.breachLevel === "Low" || row.breachLevel === "Moderate" || row.breachLevel === "High") {
              if (this.state.slaReasonPermission.create && !_.hasIn(row, "reasonId")) {
                Mixpanel.track("SLA Reasons Adding Reason Initiate", { 'ERPSLAReasonAdd': moment().valueOf(), "waybillNo": row?.waybillNo })
                return <Button type="primary" onClick={() => this.showDelayModal(row, false)}>Add</Button>
              }
              if (this.state.slaReasonPermission.update && _.hasIn(row, "reasonId")) {
                Mixpanel.track("SLA Reasons Updated Reason Initiate", { 'ERPSLAReasonUpdated': moment().valueOf(), "waybillNo": row?.waybillNo })
                return <Button type="danger" onClick={() => this.showDelayModal(row, true)}>Update</Button>
              } else if (this.state.slaReasonPermission.read) {
                return <img style={{ opacity: '0.5', cursor: 'not-allowed' }} src={noAction} width={30} />
              } else {
                return <Button type="danger" style={{ opacity: '0.5', cursor: 'not-allowed' }}>Update</Button>
              }
            } else {
              return <img style={{ opacity: '0.5', cursor: 'not-allowed' }} src={noAction} width={30} />
              // return <Button type="primary" style={{opacity: '0.5', cursor: 'not-allowed'}}>Add</Button> 
            }

            // console.log("row", row)
            // if(row.slaDelayReasons.length<=0) {
            //   return <Button onClick={() => this.showDelayModal(row, false)}><Icon type="edit"  /></Button>
            // } else if(row.slaDelayReasons.length>0) {

            //   return (
            //     <Button onClick={() => this.showDelayModal(row, true)}><Icon type="edit" /></Button>
            //     // <Button type='primary' onClick={() => this.gotoTrack(row)}>Track</Button>
            //   )
            // }

          }
        }
      ],
      docketColumns: [
        {
          title: 'Docket No.',
          dataIndex: 'waybillNo',
          key: 'waybillNo',
          fixed: 'left',
          width: 120,
          render: (data, row) => (<span style={{ color: '#2c5de5' }}>{data}</span>)
        },
        {
          title: 'Pickup Date',
          dataIndex: 'pickupDate',
          key: 'pickupDate',
          width: 110,
          render: (data) => moment(data).format('DD/MM/YYYY hh:mm A')
        },
        {
          title: 'Booking Date',
          dataIndex: 'bookingDate',
          key: 'bookingDate',
          width: 130,
          render: (data) => moment(data).format('DD/MM/YYYY hh:mm A')
        },
        {
          title: 'Customer Name',
          dataIndex: 'customerName',
          key: 'customerName',
          width: 110
        },
        {
          title: 'Booking Office',
          dataIndex: 'bookingOffice',
          key: 'bookingOffice',
          width: 110
        },
        {
          title: 'Origin',
          dataIndex: 'origin',
          key: 'origin',
          width: 110
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
          key: 'destination',
          width: 110
        },
        {
          title: 'CPT',
          dataIndex: 'cpt',
          key: 'cpt',
          width: 110,
          render: data => data ? (<span>{moment(data).format('DD/MM/YYYY hh:mm A')}</span>) : <></>
        },
        {
          title: 'Breached Level',
          dataIndex: 'breachStatus',
          key: 'isBreached',
          width: 130,
          render: (data, row) => {
            let str = "";
            let tooltip = "";
            // str = row.breachStatus;
            if (row.breachLevel) {
              if (row?.breachLevel === 'High') {
                tooltip = <Tooltip title={'breach hours > 3'}>
                  <Tag color="#fe6769">{row?.breachLevel}</Tag>
                </Tooltip>
              } else if (row?.breachLevel === 'Low') {
                tooltip = <Tooltip title={'breach hours < 1'}>
                  <Tag color="#b6deb5">{row?.breachLevel}</Tag>
                </Tooltip>
              } else if (row?.breachLevel === 'Moderate') {
                tooltip = <Tooltip title={'breach hours between 1 - 3'}>
                  <Tag color="#ffd65c">{row?.breachLevel}</Tag>
                </Tooltip>
              } else {
                tooltip = <>{row.breachLevel}</>
              }
            }
            return <>{tooltip}</>
          }
          // render: (data) => data == "true" ? "Breached" : "Not Breached"
        },
      ],
      customerRemarksIsMandatory: false,
      customerRemarksError: undefined,
      customerRemarksMandatoryReasons: ["Others (LCF)", "Others (NCF)", "Others (RF)", "Others (SF)"]
    }

    this.applyPagination({}, true);
    const params = {
      "filters": {
        "customerTypes": ["CUSTOMER"],
        "status": ["ACTIVE"],
        "fetchMode": "STRICT_BASIC",
        "partnerIds": [Constants.PARTNER_ID]
      }
    }

    this.props.fetchSLACustomers(params);

    const filters = {
      "categoryFilters": {
        "types": ["DEL_DELAY"],
        "isActive": true,
        "fetchObjects": ["PARTNER_REASONS"]
      }
    };

    this.props.fetchSLAReasons(filters);




    this.fetchLocation();


    const user = JSON.parse(atob(localStorage.getItem('user')));
    const loggedPartnerId = localStorage.getItem('loggedPartnerId');
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name,
    });
    Mixpanel.track("SLA Reasons Page Initiated", { 'ERPSLAReasonsIntiatted': moment().valueOf() })

    // if (Constants.sessionUser.userLevel === 'CUSTOMER_SUPER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_USER') {
    //   this.applyPagination({
    //     pageNo: 1, consignmentFilter: {
    //       ...this.state.filterPayload.filters.consignmentFilter,
    //       customerPickupLocationIds: []
    //     }
    //   }, true);
    // }
  }



  componentDidUpdate(prevProps) {

    if ((this.props.downloadStatus === "SUCCESS" || this.props.downloadStatus === "FAILURE") && this.state.loading) {
      this.setState({ loading: false, showExportDownload: false })
    }

    if (this.props.exportError && this.state.loading) {
      this.setState({ loading: false, showExportDownload: false })
    }
    if (this.props.slaExportData !== prevProps.slaExportData) {

      if (this.props?.slaExportData?.url) {
        const prop = {
          setS3SyncStatus: this.props.setS3SyncStatus,
          uploadedFileResp: {
            responseUrl: this.props.slaExportData.url
          }
        }
        checkForDownloadReportStatus(prop);
        // reportsDownload(this.props.exportData.url, DownloadTags.s3)
      }
    }


    if (this.props.slaDelayReasons) {
      notifyApiSuccess("Delay Reaons Added", "SUCCESS");

      const payload = {
        consignmentFilter: {
          ...this.state.filterPayload.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          pageNo: this.state.pageNo
        },
        slaDelayReasonFilter: {
          ...this.state.filterPayload.slaDelayReasonFilter,
          ...this.state.newFilter.slaDelayReasonFilter,
        }
      };
      this.applyPagination(payload, true, false)
      this.cancelDelayReason();


    }
  }

  showDelayModal = (row, edit = false) => {

    const { form } = this.props;
    const { setFieldsValue } = form;
    this.setState({ slaReasons: [] })
    setFieldsValue({ reasonCategory: undefined, reasons: [] }, () => {
      form.validateFields();

      if (edit) {

        this.selectReasonCategory(row.categoryId)
        setFieldsValue({ reasonCategory: row.categoryId, reasons: row.reasonId })
        this.setState({ reasons: row.reasonId, remarks: row.remarks, customerRemarks: row?.customerRemarks })
      }
      this.setState({ showReasonModal: true, docketRow: row, editDelay: edit, customerRemarksIsMandatory: false, customerRemarksError: undefined })
    })

  }

  cancelDelayReason = () => {
    this.setState({ showReasonModal: false, docketRow: {}, reasonCategory: undefined, reasons: undefined, remarks: undefined, customerRemarks: undefined, checked: false })
  }

  submitDelayReason = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      if (this.state.customerRemarksIsMandatory && _.isUndefined(this.state.remarks)) {
        return;
      }
      const { docketRow } = this.state;
      const slaDelayReasons = [];
      slaDelayReasons.push({
        "rootConsignmentId": docketRow.id,
        "entityType": "PARTNER",
        "reasonCategoryId": this.state.reasonCategory,
        "reasonId": this.state.reasons,
        "reason": this.state.remarks
      },
        {
          "rootConsignmentId": docketRow.id,
          "entityType": "CUSTOMER",
          "reasonCategoryId": this.state.reasonCategory,
          "reasonId": this.state.reasons,
          "reason": this.state.checked ? this.state.remarks : this.state.customerRemarks
        });
      const payload = {
        slaDelayReasons
      }

      Mixpanel.track("SLA Reasons Updated", { 'ERPSLAReasonUpdated': moment().valueOf(), "rootConsignmentId": docketRow.id })



      this.props.setSLAReasons(payload)
    })

  }

  selectBreachCategory = value => {

    this.setState({ breachCategoryFilter: value, slaReasonsFilters: [], reasonFilter: undefined }, () => {
      this.props.slaBreachReasons.reasonCategories.map(r => {
        if (r.id === value && r.reasons) {
          this.setState({ slaReasonsFilters: [...r.reasons] });

          const payload = {
            consignmentFilter: {
              ...this.state.filterPayload.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,

            },
            slaDelayReasonFilter: {
              reasonCategoryIds: [value]
            }
          };


          this.setState({
            newFilter: payload
          })

        }
      })
    })
  }

  selectReasonCategory = (value) => {
    this.props.form.setFieldsValue({ reasons: undefined })
    this.setState({ reasonCategory: value, slaReasons: [], reasons: undefined }, () => {
      this.props.slaBreachReasons.reasonCategories.map(r => {
        if (r.id === value && r.reasons) {
          this.setState({ slaReasons: [...r.reasons] })
        }
      })
    })

  }

  selectReasonFilter = value => {
    this.setState({ reasonFilter: value })
    const { filterPayload, newFilter } = this.state;

    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,

      },
      slaDelayReasonFilter: {
        ...newFilter.slaDelayReasonFilter,
        reasonIds: [value]
      }
    };

    this.setState({
      newFilter: payload
    })
  }

  selectReason = value => {
    this.setState({ reasons: value }, () => {
      let { slaReasons, customerRemarksMandatoryReasons, customerRemarksIsMandatory, customerRemarksError } = this.state;
      const newReason = slaReasons.filter(r => r.id === value)[0];
      if (customerRemarksMandatoryReasons.includes(newReason?.reasonDescription)) {
        customerRemarksIsMandatory = true;
        customerRemarksError = "Remarks (internal) is mandatory";
      } else {
        customerRemarksIsMandatory = false;
        customerRemarksError = undefined;
      }
      this.setState({ customerRemarksIsMandatory, customerRemarksError })
    });

  }

  onChangeRemarks = e => {
    this.setState({ remarks: e.target.value })
  }

  onChangeCustomerRemarks = e => {
    this.setState({ customerRemarks: e.target.value })
  }

  onChangeCheck = e => {
    this.setState({ checked: e.target.checked })
  }

  fetchLocation = () => {
    // const locationPayload = {
    //   "filters": {
    //     "entityTypes": [
    //       "CUSTOMER"
    //     ],
    //     "entityId": [Constants.sessionUser.location.entityId],
    //     "status": true,
    //     "roles": ["PICKUP", "DELIVERY"]
    //   }
    // };

    // let level = Constants.sessionUser.userLevel;

    // let locationPayload = {};
    // if (level === 'ADMIN') {
    //   locationPayload = {
    //     "filters": {
    //       "deviceFilter": {
    //         "view": "LOCATION_LIST_ERP"
    //       },
    //       // "linkedUsers": [Constants.sessionUser.id],
    //       "status": true,
    //       // "roles": ["PICKUP", "DELIVERY"],
    //       "isConsiderCreatorId": false,
    //       "entityTypes": [
    //         "PARTNER"
    //       ],
    //       "entityIds": [Constants.sessionUser.location.entityId],
    //       "deviceType": "DESKTOP",
    //       fetchObjects: []
    //     }
    //   }
    // } else {
    //   locationPayload = {
    //     "filters": {
    //       "deviceFilter": {
    //         "view": "LOCATION_LIST_ERP"
    //       },
    //       "linkedUsers": [Constants.sessionUser.id],
    //       "status": true,
    //       // "roles": ["PICKUP", "DELIVERY"],
    //       "isConsiderCreatorId": false,
    //       "entityTypes": [
    //         "CUSTOMER"
    //       ],
    //       "entityIds": [Constants.sessionUser.location.entityId],
    //       "deviceType": "DESKTOP",
    //       fetchObjects: []
    //     }
    //   }
    // }

    // this.props.locationLiveLoads(locationPayload);
    let params = {};
    let level = Constants.sessionUser.userLevel;
    // console.log("lev", level)
    if (level === 'ADMIN' || level === 'HEAD_OFFICE')
      params = { "filters": { "entityTypes": ["PARTNER"], "entityIds": [Constants.PARTNER_ID], "isConsiderCreatorId": false, "fetchObjects": [], "status": true } }
    else
      params = { "filters": { "entityTypes": ["PARTNER"], "entityIds": [Constants.PARTNER_ID], "linkedUsers": [Constants.sessionUser.id], "isConsiderCreatorId": false, "fetchObjects": [], "status": true } }
    // console.log("params", params)
    this.props.fetchSLALocations(params)
  }

  applyPagination = (filters, isReset = false, setPage = true) => {
    if (isReset && setPage) {
      this.setState({
        pageNo: 1
      })
    }



    let data = {};
    // const {consignmentFilter} = this.state.filterPayload;
    // console.log("consig", consignmentFilter)
    // let payload = Object.assign({}, consignmentFilter, {...filters.consignmentFilter})



    // console.log("payload", filters)


    if (_.isEmpty(filters)) {
      data = { consignmentFilter: { ...this.state.filterPayload.consignmentFilter }, slaDelayReasonFilter: { ...this.state.filterPayload.slaDelayReasonFilter } };
    } else {
      data = {
        consignmentFilter: {
          bookingOfficeLocationIds: filters?.consignmentFilter?.bookingOfficeLocationIds ? filters?.consignmentFilter?.bookingOfficeLocationIds : this.state.filterPayload.consignmentFilter.bookingOfficeLocationIds,
          customerIds: filters?.consignmentFilter?.customerIds ? filters?.consignmentFilter?.customerIds : this.state.filterPayload.consignmentFilter.customerIds,
          // deviceFilter: this.state.filterPayload.consignmentFilter.deviceFilter,
          fetchObjects: this.state.filterPayload.consignmentFilter.fetchObjects,
          isDeliveryBreached: _.hasIn(filters?.consignmentFilter, "isDeliveryBreached") ? filters?.consignmentFilter?.isDeliveryBreached : this.state.filterPayload.consignmentFilter.isDeliveryBreached,
          isBreachReasonAvailable: _.hasIn(filters?.consignmentFilter, "isBreachReasonAvailable") ? filters?.consignmentFilter?.isBreachReasonAvailable : this.state.filterPayload.consignmentFilter.isBreachReasonAvailable,          
          // onlyRootConsignment: this.state.filterPayload.consignmentFilter.onlyRootConsignment,
          partnerIds: this.state.filterPayload.consignmentFilter.partnerIds,
          pageNo: filters?.consignmentFilter?.pageNo ? filters.consignmentFilter.pageNo : this.state.filterPayload.consignmentFilter.pageNo,
          pageSize: filters?.consignmentFilter?.pageSize ? filters?.consignmentFilter?.pageSize : this.state.filterPayload.consignmentFilter.pageSize,
          pickupDate: filters?.consignmentFilter?.pickupDate ? filters?.consignmentFilter?.pickupDate : this.state.filterPayload.consignmentFilter.pickupDate,
          ...(_.hasIn(filters.consignmentFilter, "deliveryBreachLevel") && { "deliveryBreachLevel": filters?.consignmentFilter?.deliveryBreachLevel }),
          ...(_.hasIn(filters.consignmentFilter, "consignmentFlowTypes") && { "consignmentFlowTypes": [filters?.consignmentFilter?.consignmentFlowTypes] }),
          statuses: this.state.filterPayload.consignmentFilter.statuses,
          ...(this.state.searchByValue && this.state.searchByValue.length > 0 && { waybills: filters.consignmentFilter.waybills })
        },
        slaDelayReasonFilter: {
          reasonCategoryIds: filters?.slaDelayReasonFilter?.reasonCategoryIds ? filters?.slaDelayReasonFilter?.reasonCategoryIds : this.state.filterPayload.slaDelayReasonFilter.reasonCategoryIds,
          reasonIds: filters?.slaDelayReasonFilter?.reasonIds ? filters?.slaDelayReasonFilter?.reasonIds : this.state.filterPayload?.slaDelayReasonFilter?.reasonIds
        }
      }

      // data = { consignmentFilter: {   ...this.state.filterPayload.consignmentFilter, ...filters.consignmentFilter }, slaDelayReasonFilter: {...this.state.filterPayload.slaDelayReasonFilter, ...filters.slaDelayReasonFilter}  };
    }





    if (this.state.searchByValue && this.state.searchByValue.length > 0) {
      // console.log("data", data)


      delete data.consignmentFilter.waybills;

      this.props.fetchSLAConsignments(data, isReset);

      this.setState({ searchByValue: [], searchedValue: [] });

      const { consignmentFilter, slaDelayReasonFilter } = data;
      const { fetchObjects, isDeliveryBreached, deliveryBreachLevel, onlyRootConsignment, partnerIds, pickupDate, statuses, customerIds, bookingOfficeLocationIds, waybills, isBreachReasonAvailable } = consignmentFilter

      const newData = {
        consignmentFilter: {
          // onlyRootConsignment,
          isDeliveryBreached,
          fetchObjects,
          partnerIds,
          pickupDate,
          statuses,
          customerIds,
          bookingOfficeLocationIds,
          ...(_.hasIn(consignmentFilter, "deliveryBreachLevel") && { "deliveryBreachLevel": consignmentFilter?.deliveryBreachLevel }),
          ...(_.hasIn(filters.consignmentFilter, "consignmentFlowTypes") && { "consignmentFlowTypes": [filters?.consignmentFilter?.consignmentFlowTypes] }),
          isBreachReasonAvailable: _.hasIn(consignmentFilter, "isBreachReasonAvailable") ? consignmentFilter?.isBreachReasonAvailable : this.state.filterPayload.consignmentFilter.isBreachReasonAvailable,
        },
        slaDelayReasonFilter
      }
      // console.log("newData", newData)

      this.props.fetchSLAConsignmentsSummary(newData)
    } else {
      this.props.fetchSLAConsignments(data, isReset);


      const { consignmentFilter, slaDelayReasonFilter } = data;
      const { fetchObjects, isDeliveryBreached, deliveryBreachLevel, onlyRootConsignment, partnerIds, pickupDate, statuses, customerIds, bookingOfficeLocationIds, waybills, isBreachReasonAvailable } = consignmentFilter

      const newData = {
        consignmentFilter: {
          // onlyRootConsignment,
          isDeliveryBreached,
          fetchObjects,
          partnerIds,
          pickupDate,
          statuses,
          customerIds,
          bookingOfficeLocationIds,
          ...(_.hasIn(consignmentFilter, "deliveryBreachLevel") && { "deliveryBreachLevel": consignmentFilter?.deliveryBreachLevel }),
          ...(_.hasIn(filters.consignmentFilter, "consignmentFlowTypes") && { "consignmentFlowTypes": [filters?.consignmentFilter?.consignmentFlowTypes] }),
          isBreachReasonAvailable: _.hasIn(consignmentFilter, "isBreachReasonAvailable") ? consignmentFilter?.isBreachReasonAvailable : this.state.filterPayload.consignmentFilter.isBreachReasonAvailable,
        },
        slaDelayReasonFilter
      }
      // console.log("newData", newData)

      this.props.fetchSLAConsignmentsSummary(newData)

      // this.props.fetchSLAConsignmentsSummary(newData)
    }
    // console.log("data", data)

    // data = { filters: { ...this.state.filterPayload.filters, ...filters } };
    // this.props.fetchLiveLoads(data, isReset);
    // this.props.fetchLiveLoadsSummary({ filters: data.filters });
  }

  reloadCustomerList = () => {
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo) => {
    const { PaginatedData, } = this.props;
    if (!PaginatedData ? !PaginatedData : !PaginatedData[pageNo]) {
      const payload = {
        consignmentFilter: {
          ...this.state.filterPayload.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          pageNo,
        },
        slaDelayReasonFilter: {
          ...this.state.filterPayload.slaDelayReasonFilter,
          ...this.state.newFilter.slaDelayReasonFilter,
        }
      };
      this.applyPagination(payload, true)

      // this.applyPagination({

      //   ...this.state.filterPayload.consignmentFilter,
      //   ...this.state.newFilter.consignmentFilter,
      //   pageNo,

      // })
    }
    this.setState({
      pageNo
    });
  }

  onChange = (e) => {
    if (e.target.checked) {
      this.setState({ docketStatus: { statuses: ["All", "IN", "IN_TRANSIT"] }, selectedAll: e.target.checked });
      this.handleDocketStatusChange(["All", "IN", "IN_TRANSIT"])
    } else {
      this.setState({ docketStatus: { statuses: [] } });
      this.handleDocketStatusChange([])
    }
  }



  handleDocketStatusChange = val => {
    if (val.length <= 0 || val.indexOf('All') >= 0) {
      val = ["IN", "IN_TRANSIT"]
    } else {
      this.setState({ selectedAll: false })
    }

    this.setState({ docketStatuses: val });

    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        statuses: val
      }
    }
    this.setState({
      newFilter: payload
    }, this.applyPagination({ ...payload }, true))
  }

  handleCustomersChange = val => {
    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        customerIds: val
      },
      slaDelayReasonFilter: {
        ...this.state.filterPayload.slaDelayReasonFilter,
        ...this.state.newFilter.slaDelayReasonFilter,
      }
    };

    this.setState({
      newFilter: payload,
      customerValue: val
    })

  }

  handleBookingOffice = val => {
    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        bookingOfficeLocationIds: val
      },
      slaDelayReasonFilter: {
        ...this.state.filterPayload.slaDelayReasonFilter,
        ...this.state.newFilter.slaDelayReasonFilter,
      }
    };

    this.setState({
      newFilter: payload,
      bookingOffice: val
    })
  }
  handleDeliveryStatusChange = val => {
    // const payload = {
    //   filters: {
    //     ...this.state.filterPayload.filters,

    //   }
    // };
    // if (val === 'YES') {
    //   payload.consignmentFilter.isBreached = true
    // } else if (val === 'NO') {
    //   payload.consignmentFilter.isBreached = false
    // } else {
    //   delete payload.consignmentFilter.isBreached;
    // }
    // const ValueSet = {
    //   filterPayload: {
    //     ...this.state.filterPayload,
    //     filters: {
    //       ...this.state.filterPayload.filters,
    //       ...payload
    //     }
    //   }
    // }
    // if (val === 'ALL') {
    //   delete ValueSet.filterPayload.filters.consignmentFilter.isBreached
    // }


    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        isDeliveryBreached: val === 'YES' ? true : false
      },
      slaDelayReasonFilter: {
        ...this.state.filterPayload.slaDelayReasonFilter,
        ...this.state.newFilter.slaDelayReasonFilter,
      }
    };

    if (val === "NO") {
      delete payload.consignmentFilter.deliveryBreachLevel;
      this.setState({ deliveryBreachLevel: undefined });
      let e = {
        target: {
          value: "All"
        }
      }
      this.onChangeBreachType(e)
    }






    this.setState({
      newFilter: payload,
      deliveryBreachStatus: val
    })


    // this.setState({
    //   ...ValueSet,
    //   deliveryBreach: val
    // }, this.applyPagination({ ...payload }, true))
  }

  changeBreachAvailability = e => {
    this.setState({ noBreachAvailability: e.target.checked });
    if (!e.target.checked) {
      this.handleBreachLevel("ALL", true);

    } else {

      let payload = {};
      payload = {
        consignmentFilter: {
          ...this.state.filterPayload.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
        },
        slaDelayReasonFilter: {
          ...this.state.filterPayload.slaDelayReasonFilter,
          ...this.state.newFilter.slaDelayReasonFilter,
        }
      };
      delete payload.consignmentFilter.isDeliveryBreached;
      this.setState({
        newFilter: payload,
        deliveryBreachLevel: undefined
      })
    }

    // set to undefined

    // if breach level selected uncheck this check box
  }

  handleBreachLevel = (value, filterBool = false) => {

    if (!filterBool) {
      this.setState({ noBreachAvailability: false });
    }
    let payload = {};
    if (value === 'HIGHLY_BREACHED' || value === 'MODERATELY_BREACHED' || value === 'SLIGHTLY_BREACHED') {
      payload = {
        consignmentFilter: {
          ...this.state.filterPayload.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          deliveryBreachLevel: value,
          isDeliveryBreached: true
        },
        slaDelayReasonFilter: {
          ...this.state.filterPayload.slaDelayReasonFilter,
          ...this.state.newFilter.slaDelayReasonFilter,
        }
      };
    }

    if (value === 'ALL') {
      payload = {
        consignmentFilter: {
          ...this.state.filterPayload.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          isDeliveryBreached: true
        },
        slaDelayReasonFilter: {
          ...this.state.filterPayload.slaDelayReasonFilter,
          ...this.state.newFilter.slaDelayReasonFilter,
        }
      };
      delete payload.consignmentFilter.deliveryBreachLevel;
    }





    this.setState({
      newFilter: payload,
      deliveryBreachLevel: value
    })

  }
  onChangeBreachType = e => {

    const value = e;

    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        isBreachReasonAvailable: value === true ? true : false
      }
    };

    if (value === 'All') {
      const { filterPayload, newFilter } = this.state;
      delete filterPayload?.consignmentFilter?.isBreachReasonAvailable
      delete payload?.consignmentFilter?.isBreachReasonAvailable
    }

    this.setState({
      newFilter: payload,
      Breachvalue: value
    })
  }

  onChangeDocketType = value => {
    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        consignmentFlowTypes: value
      }
    };

    if (value === 'All') {
      const { filterPayload, newFilter } = this.state;
      delete filterPayload?.consignmentFilter?.isBreachReasonAvailable
      delete payload?.consignmentFilter?.consignmentFlowTypes
    }

    this.setState({
      newFilter: payload,
      docketType: value
    })
  }



  handleSearch = e => {
    Mixpanel.track("SLA Reasons Searched", { 'ERPSLAReasonSearched': moment().valueOf(), "searchedDockets": e?.target?.value })
    let val = e.target.value;
    let filter = {};
    if (val === this.state.searchedValue.join(',')) return false
    if (!val) {
      val = []
    } else
      val = val.split(',').map(v => (v.trim()))

    filter = {
      consignmentFilter: {
        ...this.state.filterPayload.consignmentFilter,
        waybills: val
      },
      slaDelayReasonFilter: {
        ...this.state.filterPayload.slaDelayReasonFilter,
        ...this.state.newFilter.slaDelayReasonFilter,
      }
    }





    delete filter.consignmentFilter.pickupDate;

    delete filter.consignmentFilter.isDeliveryBreached;
    delete filter.consignmentFilter.isBreachReasonAvailable;
    delete filter.consignmentFilter.customerIds;
    delete filter.consignmentFilter.bookingOfficeLocationIds;


    if (val.length === 0) {

      this.resetFilters();
      delete filter.consignmentFilter.waybills;
      return;
      // delete filter.consignmentFilter.invoiceNumbers;
    }


    this.setState({ newFilter: filter, searchedValue: val, searchByValue: val, pageNo: 1 }, () => {
      this.props.fetchSLAConsignments(filter, true);
      const { consignmentFilter, slaDelayReasonFilter } = filter;
      const { fetchObjects, onlyRootConsignment, partnerIds, statuses, waybills } = consignmentFilter
      const newData = {
        consignmentFilter: {
          // onlyRootConsignment,

          fetchObjects,
          partnerIds,

          statuses,


          waybills,

        },
        slaDelayReasonFilter
      }
      // console.log("newData", newData)

      this.props.fetchSLAConsignmentsSummary(newData)


      // this.props.fetchSLAConsignmentsSummary(filter)
    });

  }

  exportData = () => {
    const { confirm } = Modal;
    const _self = this;
    confirm({
      title: "Export",
      content: 'Are you sure you want to download?',
      onOk() {
        _self.props.setS3SyncStatusReset();
        _self.setState({ loading: true, showExportDownload: true })
        let { consignmentFilter } = _self.state.newFilter;


        // if (_.isEmpty(consignmentFilter)) {
        //   consignmentFilter = {...this.state.filterPayload.filters.consignmentFilter, ...this.state.newFilter.consignmentFilter,};
        // }
        let filters = {
          consignmentFilter: {
            ..._self.state.filterPayload.consignmentFilter,
            ...consignmentFilter,


            "fetchObjects": [
              "SLA_DELAY_REASON",
              "CUSTOMER",
              "CONTACT_FAV_LOCATION",
              "WAYBILL_POD",
              "PINCODE",
              "CONSIGNMENT_B2B_SHORTCODE",
              "REASON_PARTNER"
            ],
          },
          slaDelayReasonFilter: {
            ..._self.state.filterPayload.slaDelayReasonFilter,
            ..._self.state.newFilter.slaDelayReasonFilter,
          },
          "userIds": [_self.props.header.loggedInUser.id],
        }
        delete filters.consignmentFilter.pageNo;
        delete filters.consignmentFilter.pageSize;
        if (_self.state.searchByValue && _self.state.searchByValue.length <= 0) {
          delete filters.consignmentFilter.waybills
        }
        // ...(this.state.searchByValue && this.state.searchByValue.length > 0 && {waybills: consignmentFilter.waybills}),
        // console.log("filters", filters);
        Mixpanel.track("SLA Reasons Export Initiated", { 'ERPSLAReasonExport Initiated': moment().valueOf(), "userID": _self.props.header.loggedInUser.id })
        _self.props.fetchSLAConsignmentsExport(filters)

      }
    })



    // this.props.fetchSLAConsignmentsExport({

    // })
  }

  disabledStartDate = startValue => {
    const { startDate } = this.state;
    if (!startValue || !startDate) {
      return false;
    }

    return startValue.valueOf() && startValue.valueOf() > moment().endOf('day');
    // return startValue.valueOf() > startDate.valueOf();
  };

  disabledEndDate = endValue => {
    if (this.state.startDate) {
      const dayStart = moment(this.state.startDate);
      const dayEnd = moment(this.state.startDate).add(30, 'days');
      if (dayEnd.isAfter(moment().endOf('day'))) {

        return !(dayStart.isSameOrBefore(endValue) && endValue <= moment().endOf('day') && endValue >= moment(this.state.startDate).endOf('day'));
      } else {

        return !(dayStart.isSameOrBefore(endValue) && dayEnd.isAfter(endValue) && endValue.isSameOrBefore(dayEnd));
      }


    } else {
      // return current && current > moment().endOf('day');
    }
  }

  disabledDate = current => {

    if (this.state.startDate) {
      const dayStart = moment(this.state.startDate).add(-31, 'days');
      const dayEnd = moment(this.state.startDate).add(31, 'days');
      if (dayEnd.isAfter(moment().endOf('day'))) {
        return !(dayStart.isSameOrBefore(current) && current < moment().endOf('day'));
      } else {
        return !(dayStart.isSameOrBefore(current) && dayEnd.isAfter(current));
      }


    } else {
      // return current && current > moment().endOf('day');
    }

  }

  handleFilterSet = (key, value, field, fieldValue) => {

    if (key == 'customerPickupLocationIds' && value == 'All') {

      let level = Constants.sessionUser.userLevel;
      if (level === 'CUSTOMER_SUPER_ADMIN') {
        this.setState({
          newFilter: {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
              [key]: []
            }
          }
        });
      } else {
        let locations = this.props.locationList['PICKUP'].map(list => {
          return list.id;
        });
        this.setState({
          newFilter: {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
              [key]: locations
            }
          }
        });

      }
    } else {
      if (key === 'destinationLocationIds') {
        if (value.length > 0) {
          let selectedValues = this.state.allDeliveryLocations.map(list => {
            if (value.includes(list.id)) {
              list = {
                ...list,
                selected: true
              }

              return list;
            } else {
              list = {
                ...list,
                selected: false
              }
              return list
            }
          });
          var sortedLocations = selectedValues.sort((a, b) => {
            return b.selected - a.selected;
          });

          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            },
            allDeliveryLocations: [...sortedLocations]
          });

        } else {
          let { newFilter } = this.state;
          let { consignmentFilter } = newFilter;
          if (_.hasIn(consignmentFilter, 'destinationLocationIds')) {
            delete consignmentFilter['destinationLocationIds'];

          }

          newFilter = {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
            }
          }

          this.setState({ newFilter })


        }

      } else {
        if (key == 'customerPickupLocationIds') {
          let selectedValues = this.state.allLocations.map(list => {
            if (value.includes(list.id)) {
              list = {
                ...list,
                selected: true
              }

              return list;
            } else {
              list = {
                ...list,
                selected: false
              }
              return list
            }
          });
          var sortedLocations = selectedValues.sort((a, b) => {
            return b.selected - a.selected;
          });

          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            },
            allLocations: [...sortedLocations]
          });
        } else {
          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            }
          });
        }
      }

    }

    if (field && fieldValue) {
      this.onChangeDate(field, fieldValue)
    }

  }

  onChangeDate = (field, value) => {
    // console.log("startDate->>", startDate)
    // let { startDate } = this.state;
    // startDate = startDate.valueOf();
    // console.log("startDate->>", startDate)
    this.setState({ [field]: value, })
  }

  onEndDateChange = (endDate) => {

    // let { endDate } = this.state;
    // endDate = endDate.valueOf();

    this.setState({ endDate })
  }

  onDateChange = (date, dateString) => {

    if (date[0]) {
      this.setState({ startDate: date[0], endDate: date[1] })
    }
  }

  onChangeSearch = e => {
    this.setState({ searchValue: e.target.value })
  }

  changeSearch = e => {

    this.setState({ searchByValue: e.target.value });

    let val = e.target.value;
    let filter = {};
    if (val === this.state.searchedValue.join(',')) return false
    if (!val) {
      val = []
    } else
      val = val.split(',').map(v => (v.trim()))



    if (val.length === 0) {

      this.resetFilters();
      // delete filter.consignmentFilter.waybills;
      // return;
      // delete filter.consignmentFilter.invoiceNumbers;
    }
  }

  applyfilters = () => {
    // this.setState({
    //   docketStatuses: ['IN', 'IN_TRANSIT'],
    //   searchValue: 'docket',
    //   searchByValue: '',
    //   searchedValue: [],
    //   deliveryBreach: 'ALL'
    // })
    const { filterPayload, newFilter } = this.state;
    console.log("newFilter", newFilter)
    // delete filterPayload.filters;

    // delete newFilter.consignmentFilter.isBreached
    // delete filterPayload.filters.consignmentFilter.waybills
    // delete newFilter.consignmentFilter.waybills
    // delete filterPayload.filters.consignmentFilter.invoiceNumbers
    // delete newFilter.consignmentFilter.invoiceNumbers
    Mixpanel.track("SLA Reasons Filters Applied", { 'ERPSLAReasonFilters': moment().valueOf(), "filters": newFilter })


    this.applyPagination(newFilter, true)

    // this.applyPagination({
    //   filters: {
    //     ...filterPayload.filters,
    //     ...newFilter.filters,
    //     "statuses": [
    //       "DELIVERED"
    //     ],

    //   }
    // }, true)
  }

  hasErrors = (fieldsError) => {

    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  resetFilters = e => {
    this.setState({
      // docketStatuses: ['IN', 'IN_TRANSIT'],
      // searchValue: 'docket',
      startDate: moment().subtract(7, 'd').startOf('day'),
      endDate: moment().endOf('day'),
      searchByValue: '',
      searchedValue: [],
      noBreachAvailability: true,
      deliveryBreachStatus: 'YES',
      Breachvalue: false,
      customerValue: undefined,
      bookingOffice: undefined,
      deliveryBreachLevel: undefined,
      breachCategoryFilter: undefined,
      reasonFilter: undefined,
      slaReasonsFilters: [],
      newFilter: {
        consignmentFilter: {
          ...this.state.filterPayload.filters,
          ...this.state.newFilter.filters,
          "pickupDate": {
            from: moment().subtract(7, 'd').startOf('day').valueOf(),
            to: moment().endOf('day').valueOf()
          },
          customerIds: [],
          // isDeliveryBreached: true,
          isBreachReasonAvailable: false,
          // "onlyRootConsignment": true,
        }
      }
    }, () => {
      // console.log("this", this.state.newFilter)

      this.applyPagination({ ...this.state.newFilter }, true)
    })
  }

  render() {
    const { columns, pageNo, isEndDateOpen, isCardExpanded, newFilter, startDate, endDate, filterPayload, allLocations } = this.state;
    const { PaginatedData, slaData, locationList, summaryList, slaCustomers, slaSummary } = this.props;
    const { getFieldDecorator, getFieldError, isFieldTouched, getFieldsError } = this.props.form;
    let categoryerror = isFieldTouched('reasonCategory') && getFieldError('reasonCategory');
    let reasonerror = isFieldTouched('reasons') && getFieldError('reasons');
    // console.log("pagi", PaginatedData)

    const formItemLayout = {
      labelCol: {
        xs: { span: 4 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      labelAlign: "left",
      colon: false
    };
    return (
      <div className="SLAReasons">
        <Row gutter={3}>
          <Col span={18}>
            <Card size={"small"}>
              {

                <>
                  <Form {...formItemLayout}>
                    <Form.Item colon={false} label={" "} labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>


                      <Row gutter={8}>
                        <Input.Group style={{ display: 'inline' }}>
                          <Col span={5} style={{ top: 0 }}>
                            <div className="ant-col ant-form-item-label"><label className="ant-form-item-no-colon">Pickup Date From </label></div>
                            <DatePicker
                              disabledDate={this.disabledStartDate}

                              format="YYYY-MM-DD"
                              value={startDate}
                              placeholder="From"
                              style={{ width: '100%' }}
                              // onOk={this.onStartDateChange}
                              onChange={value => { this.handleFilterSet('pickupDate', { from: value.valueOf(), to: endDate.milliseconds(0).valueOf() }, 'startDate', value) }}
                              onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                            // onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true, endDate: moment(startDate).endOf('day') }) : {}}
                            // onOpenChange={this.handleStartOpenChange}
                            />
                          </Col>

                          <Col span={5} style={{ top: 0 }}>
                            <div className="ant-col ant-form-item-label"><label className="ant-form-item-no-colon">Pickup Date To </label></div>
                            <DatePicker
                              disabledDate={this.disabledEndDate}
                              format="YYYY-MM-DD"
                              value={endDate}
                              placeholder="To"
                              style={{ width: '100%' }}
                              // onOk={this.onEndDateChange}
                              open={isEndDateOpen}
                              onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                              onChange={value => {

                                this.handleFilterSet('pickupDate', { from: startDate.valueOf(), to: value.endOf('day').milliseconds(0).valueOf() }, 'endDate', value)
                              }
                              }

                            // onOpenChange={this.handleStartOpenChange}
                            />
                          </Col>
                        </Input.Group>
                        <Col span={4} style={{ bottom: 5 }}>
                          <Form.Item colon={false} label="Breach reason availability" labelCol={6} wrapperCol={18}>
                            {/* <Radio.Group onChange={this.onChangeBreachType} value={this.state.Breachvalue}>
                              <Radio value={'All'}>All</Radio>
                              <Radio value={true}>Present</Radio>
                              <Radio value={false}>Not Present</Radio>
                            </Radio.Group> */}
                            <Select style={{ width: '100%' }} placeholder="Breach reason availability" value={this.state.Breachvalue} onChange={this.onChangeBreachType}
                              filterOption={(input, option) => {
                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                              }
                              }
                            >
                              <Select.Option
                                value={"All"}
                                key={"All"}
                              >
                                All
                              </Select.Option>
                              <Select.Option
                                value={true}
                                key={"true"}
                              >
                                Present
                              </Select.Option>
                              <Select.Option
                                value={false}
                                key={"false"}
                              >
                                Not Present
                              </Select.Option>
                            </Select>

                          </Form.Item>
                        </Col>
                        <Col span={4} style={{ bottom: 5 }} push={1}>
                          <Form.Item colon={false} label="Docket Type" labelCol={6} wrapperCol={18}>
                            <Select style={{ width: '100%' }} placeholder="Docket type"
                              filterOption={(input, option) => {
                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                              }
                              }
                              onChange={this.onChangeDocketType}
                              value={this.state.docketType}
                            >
                              <Select.Option
                                value={"All"}
                                key={"All"}
                              >
                                All
                              </Select.Option>
                              <Select.Option
                                value={"FORWARD"}
                                key={"FORWARD"}
                              >
                                Forward
                              </Select.Option>
                              <Select.Option
                                value={"REVERSE"}
                                key={"REVERSE"}
                              >
                                Reverse
                                </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6} push={1} style={{ top: 35 }}>
                          <Checkbox checked={this.state.noBreachAvailability} onChange={this.changeBreachAvailability}>No Breach Availability</Checkbox>
                        </Col>


                      </Row>


                      {/* <span style={{ marginLeft: '5px' }}></span>
                        <DatePicker onChange={this.onChange} /> */}

                    </Form.Item>

                    <Row gutter={12}>


                      <Col span={4}>

                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Booking Office">
                          <Select style={{ width: '100%' }} placeholder="All Locations" mode="multiple" value={this.state.bookingOffice} maxTagCount="1" onChange={this.handleBookingOffice}
                            filterOption={(input, option) => {
                              // console.log("ito", option)
                              return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                            }
                            }
                          >
                            {
                              this.props?.slaLocations?.locations?.map(status => (
                                <Select.Option value={status.id} key={status.id}>
                                  <Tooltip title={status.name}>
                                    {status.name}
                                  </Tooltip>

                                </Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>

                      </Col>
                      <Col span={4}>

                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Customers">
                          <Select placeholder="All Customers" value={this.state.customerValue} style={{ width: '100%' }} mode="multiple" maxTagCount="1" onChange={this.handleCustomersChange} filterOption={(input, option) =>
                            option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                          }>
                            {
                              slaCustomers?.customers.map(cs => (
                                <Select.Option label={cs.name} key={cs.id} value={cs.id.toString()}>
                                  <Tooltip title={cs.name}>
                                    {cs.name}
                                  </Tooltip>
                                </Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>

                      </Col>
                      <Col span={4}>
                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Breach Level">
                          <Select placeholder="Levels" style={{ width: '100%' }} value={this.state.deliveryBreachLevel} maxTagCount="1" onChange={(val) => this.handleBreachLevel(val, false)}>
                            <Select.Option value="ALL">All</Select.Option>
                            <Select.Option value="HIGHLY_BREACHED">High</Select.Option>
                            <Select.Option value="MODERATELY_BREACHED">Moderate</Select.Option>
                            <Select.Option value="SLIGHTLY_BREACHED">Low</Select.Option>

                          </Select>
                        </Form.Item>

                      </Col>
                      {/* <Col span={4}>
                        
                            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}  label="Breach Status">
                              <Select style={{ width: '100%' }} disabled={this.state.Breachvalue === true || this.state.Breachvalue === false} value={this.state.deliveryBreachStatus} maxTagCount="1" onChange={this.handleDeliveryStatusChange} filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                  deliveryStatus.filter(s => s.isActive).map(status => (
                                    <Select.Option value={status.value}>{status.label}</Select.Option>
                                  ))
                                }
                              </Select>
                            </Form.Item>
                        
                          </Col> */}
                      <Col span={6}>

                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Breach Category">
                          <Select
                            showSearch
                            disabled={this.state.Breachvalue === false}
                            placeholder="Select breach category"
                            onChange={this.selectBreachCategory}
                            value={this.state.breachCategoryFilter}
                            allowClear
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props.slaBreachReasons?.reasonCategories?.map((reason, key) => (
                              <Select.Option
                                value={reason.id}
                                label={reason.name}
                                key={reason.id}
                              >
                                <Tooltip title={reason.name}>
                                  {reason.name}
                                </Tooltip>

                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                      </Col>
                      <Col span={6}>

                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Breach Reasons">
                          <Select
                            showSearch
                            disabled={this.state.Breachvalue === false}
                            placeholder="Select reasons"
                            onSelect={this.selectReasonFilter}
                            value={this.state.reasonFilter}
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.state.slaReasonsFilters?.map((reason, key) => (
                              <Select.Option
                                value={reason.reasonId}
                                label={reason.reasonDescription}
                                key={reason.reasonId}
                              >
                                <Tooltip title={reason.reasonDescription}>
                                  {reason.reasonDescription}
                                </Tooltip>

                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                      </Col>




                    </Row>

                    <Row>
                      <Col span={3} offset={17}>
                        <Button onClick={e => this.resetFilters(e)} style={{ width: 104, backgroundColor: '#f2f2f5' }}>Reset</Button>
                      </Col>
                      <Col span={3} offset={1}>
                        <Button style={{ width: 104 }} loading={this.props?.slaData?.slaLoading} onClick={e => this.applyfilters()} type="primary">Apply</Button>
                      </Col>


                    </Row>
                  </Form>
                </>


              }
            </Card>

          </Col>
          <Col span={6}>
            <Card bodyStyle={{ height: 280 }}>
              <Form.Item style={{ top: 60 }} colon={false} label="Search Docket">
                <Input
                  suffix={
                    // <Button type="primary">
                    <Icon type="search" />
                    // </Button>


                  }
                  value={this.state.searchByValue}
                  onChange={this.changeSearch}
                  placeholder={`Search Docket`}
                  onPressEnter={this.handleSearch}

                  allowClear={this.state.searchByValue.length > 0} />
              </Form.Item>

            </Card>

          </Col>
        </Row>

        <Card className="margin-10">
          <Row gutter={24}>

          </Row>
          <Row gutter={6}>
            <Col span={6}>
              <Spin spinning={false}>
                <Sparkline
                  title="Delivered"
                  highlightText={<span style={{ color: '#488551' }}> {slaSummary?.consignmentSummaryCountBOs?.length > 0 ? slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "DELIVERED")[0]?.count : '0'} </span>}
                />
              </Spin>
            </Col>
            <Col span={6}>
              <Spin spinning={false}>
                <Sparkline
                  title="Breached"
                  highlightText={<span style={{ color: '#e86825' }}>{slaSummary?.consignmentSummaryCountBOs?.length > 0 ? slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "BREACHED")[0]?.count : '0'} </span>}
                />
              </Spin>
            </Col>
            <Col span={6}>
              <Spin spinning={false}>
                <Sparkline
                  title="Not Breached"
                  highlightText={<span style={{ color: '#2c5de5' }}>{slaSummary?.consignmentSummaryCountBOs?.length > 0 ? slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "NOT_BREACHED")[0]?.count : '0'} </span>}
                />
              </Spin>
            </Col>
            <Col span={6}>
              <Spin spinning={false}>
                <Sparkline
                  title="No SLA"
                  highlightText={<span style={{ color: '#2c5de5' }}>{slaSummary?.consignmentSummaryCountBOs?.length > 0 ? slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "NO_SLA")[0]?.count : '0'} </span>}
                />
              </Spin>
            </Col>

            {/* <Col span={2}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
                <Button type="primary" onClick={() => this.exportData()}><Icon type="download" />Export</Button>
              </Form.Item>
            </Col> */}
          </Row>
          <div style={{ marginBottom: 70 }}>
            <div style={{ float: 'right' }}>
              <div>
                {/* <b style={{top: 45, left: 2, float: "right"}}>Breach level<br /> <span style={{fontSize: 13, color: '#fe6769'}}>High &gt; 3hrs</span> <span style={{fontSize: 13, color: '#e86825'}}>Moderate &gt; 1hr &lt;  3hrs</span> <span style={{fontSize: 13, color: '#25e85c'}}>Low &lt; 1hr</span></b> */}

                <div style={{ position: 'relative', top: 40, right: 170 }}>
                  <Tag color="#fe6769">High &gt; 3hrs</Tag>
                  <Tag color="#ffd65c">Moderate &gt; 1hr &lt;  3hrs</Tag>
                  <Tag color="#b6deb5">Low &lt; 1hr</Tag>
                </div>

                <Button
                  disabled={
                    (slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "BREACHED")[0]?.count === 0) &&
                    (slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "NOT_BREACHED")[0]?.count === 0) &&
                    (slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "NO_SLA")[0]?.count === 0) &&
                    (slaSummary?.consignmentSummaryCountBOs.filter(item => item.status === "DELIVERED")[0]?.count === 0)
                  }
                  style={{ top: 10, left: 2, float: "right" }}
                  type="primary"
                  onClick={() => this.exportData()}
                >
                  <Icon type="download" />
                      Export
                  </Button>

              </div>
            </div>
          </div>
          <Row>
            <Col span={24} className="margin-10">
              <Table
                className="sla-table"
                bordered
                locale={{
                  emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                loading={slaData?.slaLoading}
                pagination={{
                  onChange: this.getPaginatedData,
                  total: slaData?.totalCount,
                  position: 'bottom',
                  current: pageNo,
                  pageSize: slaData?.pageSize
                }}

                scroll={{ x: 1350 }}
                dataSource={PaginatedData ? PaginatedData[pageNo] : []}
                columns={columns} />
            </Col>
          </Row>
          <Modal
            title={false}
            visible={this.state.showExportDownload}
            centered
            maskClosable={false}
            closable={false}
            footer={false}
          >
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" tip={<span style={{ color: 'black' }}>Download In Progress</span>} />
            </div>

          </Modal>
          <Modal
            title={`${this.state.editDelay ? 'Edit' : 'Add'} reason for delay - #${this.state?.docketRow?.waybillNo}`}
            visible={this.state.showReasonModal}
            closable={false}
            style={{ top: 10 }}
            width={1000}
            centered
            footer={[
              <Button key="cancel" onClick={this.cancelDelayReason}>
                Cancel
              </Button>,
              <Button key="submit" loading={this.props.slaDelayReasonLoading} disabled={this.props.slaDelayReasonLoading || this.hasErrors(getFieldsError())} type="primary" onClick={this.submitDelayReason}>
                {this.state.editDelay ? 'Update' : 'Submit'}
              </Button>

            ]}
          >
            <Row gutter={[6, 6]}>
              <Col span={24}>
                <Table
                  columns={this.state.docketColumns}
                  dataSource={[this.state.docketRow]}
                  pagination={false}
                  bordered
                  scroll={{ x: 1000 }}
                />


              </Col>
              <Col span={24}>
                <Row gutter={2}>
                  <Col span={6}>
                    <Form.Item label="Reason Category" validateStatus={categoryerror ? 'error' : ''} help={categoryerror || ''}>
                      {getFieldDecorator('reasonCategory', {
                        rules: [{ required: true, message: 'Please select reason category' }],
                      })(
                        <Select
                          showSearch
                          placeholder="Select reasons category"
                          onSelect={this.selectReasonCategory}
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.props.slaBreachReasons?.reasonCategories?.map((reason, key) => (
                            <Select.Option
                              value={reason.id}
                              label={reason.name}
                              key={reason.id}
                            >
                              {reason.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}

                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Reasons" validateStatus={reasonerror ? 'error' : ''} help={reasonerror || ''}>

                      {getFieldDecorator('reasons', {
                        rules: [{ required: true, message: 'Please select reason' }],
                      })(

                        <Select
                          showSearch
                          placeholder="Select reasons"
                          onSelect={this.selectReason}
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.slaReasons?.map((reason, key) => (
                            <Select.Option
                              value={reason.reasonId}
                              label={reason.reasonDescription}
                              key={reason.reasonId}
                            >
                              <Tooltip title={reason.reasonDescription}>
                                {reason.reasonDescription}
                              </Tooltip>

                            </Select.Option>
                          ))}
                        </Select>
                      )}

                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 4]}>
                  <Col span={10}>
                    <span>Remarks (internal)</span>
                    <span style={{ float: 'right' }}><Checkbox checked={this.state.checked} onChange={this.onChangeCheck}><b>Use it for customer as well</b></Checkbox></span>
                    <TextArea rows={2} onChange={this.onChangeRemarks} value={this.state.remarks} />
                    {this.state?.customerRemarksIsMandatory &&
                      <Alert style={{ top: 10 }} type="error" message={this.state.customerRemarksError} />
                    }
                  </Col>
                  <Col span={10} style={{ float: 'right' }}>
                    <span>Remarks (for Customer)</span>
                    <TextArea rows={2} onChange={this.onChangeCustomerRemarks} disabled={this.state.checked} value={this.state.checked ? this.state.remarks : this.state.customerRemarks} />
                  </Col>
                </Row>
              </Col>
            </Row>





          </Modal>
        </Card>

      </div >
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  PaginatedData: state.slaReasons?.PaginatedData,
  slaCustomers: state?.slaReasons?.slaCustomers,
  slaData: state?.slaReasons,
  slaBreachReasons: state?.slaReasons?.slaBreachReasons,
  slaDelayReasonLoading: state?.slaReasons?.slaDelayReasonLoading,
  slaDelayReasons: state?.slaReasons?.slaDelayReasons,
  slaSummary: state?.slaReasons?.slaSummary,
  slaSummaryLoading: state?.slaReasons?.slaSummaryLoading,
  slaExportLoading: state?.slaReasons?.slaExportLoading,
  slaExportData: state?.slaReasons?.slaExportData,
  header: state?.header,
  slaLocations: state?.slaReasons?.slaLocations,
  exportError: state?.slaReasons?.exportError,
  downloadStatus: state?.app?.s3Status


})
const mapDispatchToProps = dispatch => ({
  fetchSLAConsignments: (payload, isReset) => dispatch(fetchSLAConsignments(payload, isReset)),
  fetchSLACustomers: (payload) => dispatch(fetchSLACustomers(payload)),
  fetchSLAReasons: (payload) => dispatch(fetchSLAReasons(payload)),
  setSLAReasons: (payload) => dispatch(setSLAReasons(payload)),
  fetchSLAConsignmentsSummary: (payload) => dispatch(fetchSLAConsignmentsSummary(payload)),
  fetchSLAConsignmentsExport: (payload) => dispatch(fetchSLAConsignmentsExport(payload)),
  setS3SyncStatusReset: () => dispatch(appActions.setS3SyncStatusReset()),
  fetchSLALocations: (payload) => dispatch(fetchSLALocations(payload)),
  setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
  getWaybillTrackingDetails: param =>
    dispatch(
      trackingActions.getWaybillTrackingDetails(param.waybillNo, param.source)
    ),

});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'SLAReasons', onValuesChange: (props, val) => { } })(SLAReasons));
// export default connect(mapStateToProps, mapDispatchToProps)(SLAReasons);