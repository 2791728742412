import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { systemStatusUrl } from '../../../environments/node-react-environment'

export const getConfig = () => {
    return dispatch => {
        const now = new Date();
        const secondsSinceEpoch = Math.round(now.getTime() / 1000);
        return apiUtils.apiRequest({
            url: `${systemStatusUrl}?${secondsSinceEpoch}`
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.CONFIG_DATA,
                    payload: response.data
                })
                return response.data;
            })
            .catch(error => dispatch({
                type: CONSTANTS.CONFIG_DATA_FAILURE,
                payload: error
            }));
    }
}