import { Alert, Icon } from "antd";
import React from "react";
import "./sla-listing.scss";
const SLAAlerts = ({ slaStatus }) => {
  if (slaStatus === "BREACHED" || slaStatus === "CPD_BREACHED") {
    return (
      <Alert
        message={
          <div className="flex-box justify-content-center align-items-baseline flex-gap-m">
            These Dockets will breach SLA if no corrective action is taken
          </div>
        }
        type="alert-error"
      />
    );
  } else if (slaStatus === "AT_RISK") {
    return (
      <Alert
        message={
          <div className="flex-box justify-content-center align-items-baseline flex-gap-m">
            These Dockets could breach SLA if any unplanned delay happens
          </div>
        }
        type="alert-warning"
      />
    );
  } else {
    return (
      <Alert
        message={
          <div className="flex-box justify-content-center align-items-baseline flex-gap-m">
            These Dockets are going on time{" "}
          </div>
        }
        type="alert-success"
      />
    );
  }
};

export default SLAAlerts;
