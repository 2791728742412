import { Button, Modal, Spin, Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { canAllow, notifyApiError, notifyApiSuccess } from "../../../../../common/utils/utils";
import { permissionTags } from "../../../../../configs/permissionTags";
import { submitDraftIndent, updateIndentStatus } from "../../../common/services/indent";

export default function IndentActions(props) {

    const { readonly, onEdit, onReject, onCancel, status } = props;
    const [ disableActions, SetDisableActions ] = useState(false);
    const serviceTypeConstants = useSelector(state => state.lovStore.lovConstants["ServiceType"])
    const formData = useSelector(state => state.indentCreateReducer.indentData)

    const onApprove = _ => {
        Modal.confirm({
            title    : "Are you sure to approve this Indent?",
            onOk     : _ => {
                if (props.indentId) {
                    SetDisableActions(true);
                    const postData = {
                        indentId : props.indentId,
                        status   : "APPROVED"
                    }
                    updateIndentStatus(postData)
                    .then(_ => {
                        notifyApiSuccess("Indent Approved successfully!!", undefined);
                        props.onIndentApprove();
                        SetDisableActions(false);
                        Modal.destroyAll();
                    })
                    .catch(_ => SetDisableActions(false))
                }
                else {
                    Modal.destroyAll();
                }
            }
        })
    }

    const onSubmit = _ => {
        Modal.confirm({
            title    : "Are you sure to submit this Indent?",
            onOk     : _ => {
                if (formData.details.serviceType.key == serviceTypeConstants.PTL && (!formData.indentLoads || formData.indentLoads.length < 1)) {
                    Modal.destroyAll();
                    return Modal.error({
                        title : "PTL indents cannot be submitted with no Indent loads!"
                    })
                }
                if (props.indentId) {
                    SetDisableActions(true);
                    Modal.destroyAll();
                    const submittingModal = Modal.info({
                        title  : "Submitting indent...",
                        icon   : false,
                        okText : "",
                        okButtonProps : {
                            loading  : true,
                            disabled : true
                        }
                    })
                    submitDraftIndent(props.indentId).then(_ => {
                        notifyApiSuccess("Indent Submitted successfully!!", undefined);
                        props.onIndentSubmit();
                        SetDisableActions(false);
                        submittingModal.destroy();
                    })
                    .catch(_ => {
                        SetDisableActions(false);
                        submittingModal.destroy();
                        notifyApiError("Indent submission failed!")
                    });
                }
                else {
                    Modal.destroyAll();
                }
            }
        })
    }

    return (
        <div className="flex-box justify-content-flex-end align-items-center flex-gap-l">  
            {
                status !== "CANCELLED" && status !== "EXPIRED" && canAllow(permissionTags.indent.indent.update) &&
                readonly && <Button className="secondary-btn" onClick={onCancel} disabled={disableActions}> Cancel Indent </Button>
            }


            {
                (status == "DRAFTED" || status == "DRAFT") && readonly && canAllow(permissionTags.indent.indent.update) &&
                <Button className="lsn-btn-primary" onClick={onSubmit} disabled={disableActions || !readonly}>
                    Submit
                </Button>
            }

            {
                status == "APPROVAL_PENDING" && canAllow(permissionTags.indent.indent.approve) &&
                <>
                    <Tooltip title={!readonly ? "Save changes made to reject indent." : undefined}>
                        <Button className={!readonly ? "" : "lsn-btn-error"} onClick={onReject} disabled={disableActions || !readonly}>
                            Reject
                        </Button>
                    </Tooltip>
        
                    <Tooltip title={!readonly ? "Save changes made to approve indent." : undefined}>
                        <Button className={!readonly ? "" : "lsn-btn-success"} onClick={onApprove} disabled={disableActions || !readonly}>
                            Approve
                        </Button>
                    </Tooltip>
                </>
            }

            {
                (status == "DRAFT" || status == "APPROVAL_PENDING" || status == "NON_SERVICEABLE") && canAllow(permissionTags.indent.indent.update) &&
                readonly && <Button className="lsn-btn-primary" onClick={onEdit} > Edit </Button>
            }
        </div>
    )
}