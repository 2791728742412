import React, { useState, useEffect } from "react";
import { fetchContracts } from "../../common/services/index";
import { Table, Spin, Input, Switch, Button, Icon, Badge } from "antd";
import { canAllow, notifyApiError } from "../../../../../common/utils/utils";
import getContractsColumns from "./columns";
import FilterIcon from "../../../../../assets/img/icons/filter-icon.svg";
import { permissionTags } from "../../../../../configs/permissionTags";

function ContractList(props) {
  const { filterIndicator, flowType } = props;
  const [contracts, setContracts] = useState([]);

  const [searchData, setSearchData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      fetchContracts(props.params).then(data => {
        if (data?.currentPageNo > data?.maxPage) {
          let { params } = props;
          let { filter } = params;
          params = {
            ...params,
            filter: {
              ...filter,
              pageNo: data?.maxPage
            }
          }
          props.setParams(params);
        }
        else if (data?.contracts?.length > 0) {
          setContracts(data);
          setSearchData(props.params?.filter?.searchText);
          setSelectedContracts([]);
          setLoading(false);
        } else {
          setContracts([]);
          if (!props.backFromDetails && false === props.readOnly) {
            props.createContract();
          }
          setLoading(false);
        }

      })
        .catch(error => {
          setContracts([]);
          setLoading(false);
          notifyApiError(error, "Error")
        })
    };
    fetchData();
  }, [props.params]);

  const updateNewFilters = obj => {
    let { params } = props;
    let { filter } = params;

    setSelectedContracts([]);
    params = {
      ...params,
      filter: {
        ...filter,
        ...obj
      }
    };

    props.setParams(params);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContracts(selectedRowKeys);
    },
    selectedRowKeys: selectedContracts
  };

  return (
    <Spin
      spinning={loading}
      className="flex-box justify-content-center"
      style={{ display: "flex" }}
    >
      <div className="flex-box flex-column flex-gap-xl">
        <div className="flex-box align-items-center justify-content-between flex-gap-m">
          <div className="flex-6 flex-box align-items-center justify-content-between flex-gap-xl">
            <div className="flex-4">
              <Input
                prefix={<Icon type="search" />}
                placeholder={
                  "Search by Contract code or name, Location name or code, Zone name or code, Lane name"
                }
                value={searchData}
                suffix={
                  searchData && (
                    <Icon
                      type="close-circle"
                      onClick={() => {
                        let { params } = props;
                        let { filter } = params;
                        delete filter.searchText;

                        updateNewFilters(filter);
                        setSearchData(undefined);
                      }}
                    />
                  )
                }
                onChange={e => setSearchData(e.target.value)}
                onPressEnter={e => {
                  // setPartialContentSearch(e.target.value.trim()); searchBy
                  if (e.target.value.trim().length < 3) {
                    return notifyApiError("Enter minimum 3 characters to search!");
                  } 
                  updateNewFilters({
                    pageNo: 1,
                    pageSize: 10,
                    searchText: e.target.value.trim()
                  });
                }}
              />
            </div>
            <div className="flex-1 flex-box align-items-center flex-gap-l">
              <div>
                <Badge dot={filterIndicator}>
                  <Button
                    className="lsn-btn-secondary"
                    onClick={() => props.setFilterModal(true)}
                  >
                    <img src={FilterIcon} alt="filter" />
                    Filters
                  </Button>
                </Badge>
              </div>
            </div>
            {flowType !== "VERIFICATION" && (
              <div className="flex-1 flex-box align-items-center flex-gap-l">
                <div> Show Active </div>
                <Switch
                  checked={props?.params?.filter?.isActive == 1}
                  onChange={checked =>
                    updateNewFilters({
                      isActive: checked ? checked : undefined
                    })
                  }
                />
              </div>
            )}
          </div>
          {flowType !== "VERIFICATION" &&
            canAllow(permissionTags.contract.contractdetails.create) && (
              <div className="flex-1 flex-box justify-content-flex-end">
                <div>
                  <Button
                    className="lsn-btn-primary"
                    onClick={props?.openBoxTypes}
                  >
                    Manages Box
                  </Button>
                </div>
              </div>
            )}
          {flowType !== "VERIFICATION" &&
            canAllow(permissionTags.contract.contractdetails.create) && (
              <div className="flex-1 flex-box justify-content-flex-end">
                <div>
                  <Button
                    className="lsn-btn-primary"
                    onClick={props?.createContract}
                  >
                    Add Contract
                  </Button>
                </div>
              </div>
            )}
          {flowType === "VERIFICATION" && canAllow(permissionTags.verification.contract.approve) && (
            <div className="flex-1 flex-box justify-content-flex-end">
              <div>
                <Button
                  className="lsn-btn-error"
                  onClick={() => {
                    props?.approveContract(contracts.contracts.reduce((c, n) => {
                      if (selectedContracts.indexOf(n.id) > -1) {
                        c.push(n);
                      }
                      return c;
                    }, []), 'REJECTED')
                  }}
                >
                  Reject
                </Button>
              </div>
              <div>
                <Button
                  className="lsn-btn-success"
                  onClick={() => props?.approveContract(contracts.contracts.reduce((c, n) => {
                    if (selectedContracts.indexOf(n.id) > -1) {
                      c.push(n);
                    }
                    return c;
                  }, []), 'APPROVED')}
                >
                  Approve
                </Button>
              </div>
            </div>
          )}
        </div>
        <Table
          rowSelection={flowType === "VERIFICATION" ? rowSelection : undefined}
          columns={getContractsColumns({
            showContractDetails: props.showContractDetails,
            editContractDetails: props.editContractDetails,
            flowType: flowType
          })}
          dataSource={contracts?.contracts}
          rowKey={"id"}
          bordered
          scroll={{ x: "auto" }}
          pagination={{
            onChange: pageNo => updateNewFilters({ pageNo: pageNo }),
            total: contracts?.totalCount,
            showTotal: (total, range) => (
              <span className="total-pagination">
                {total} {total <= 1 ? "Contract" : "Contracts"}
              </span>
            ),
            pageSize: contracts?.currentPageSize,
            current: contracts?.currentPageNo,
            showSizeChanger: true,
            onShowSizeChange: (current, pageSize) =>
              updateNewFilters({ pageSize: pageSize })
          }}
        />
      </div>
    </Spin>
  );
}

export default ContractList;
