import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { notifyApiError, notifyApiSuccess } from "../../../../../../common/utils/utils";
import FormWrapper from '../../../../../ui/formComponent/form';
import { getCities, getStates } from "../contract.details.services";
import fuelChargesSchema from "./fuel.charges.schema";
import { fetchFuelCharges, postFuelClause } from "./fuel.services";

function FuelCharges(props) {

    var formRef = useRef();

    const [formData, SetFormData] = useState(false);
    const [formSchema, SetFormSchema] = useState(JSON.parse(JSON.stringify(fuelChargesSchema.schema)));
    const [loading, SetLoading] = useState(false);
    const [dataUpdated, SetDataUpdated] = useState(false);
    const [emptyFields, SetEmptyFields] = useState(true);
   
    const fetchCityDetails = value => {
        if (value.trim().length < 3) {
            return new Promise(resolve => resolve([]));
        }
        return getCities(value.trim())
                .then(resp => resp?.citiesDetails?.map(city => {
                        return {
                            value: city.id,
                            label: city.name
                        }
                    })
                )
                .catch(e => notifyApiError(e))
    }
    const fetchStateDetails = value => {
        if (value.trim().length < 3) {
            return new Promise(resolve => resolve([]));
        }
        return getStates(value)
            .then(resp => resp?.states?.map(state => {
                    return {
                        value: state.id,
                        label: state.state
                    }
                })
            )
            .catch(e => notifyApiError(e))
    }
    
    useEffect(() => {
        const { contractId } = props;
        if (contractId) {
            SetLoading(true);
            fetchFuelCharges(contractId).then(data => {
                const tempSchema = JSON.parse(JSON.stringify(fuelChargesSchema.schema));
                var locationType = data.sourceLocations[0]?.locationType;
                if (!locationType && data.sourceLocations[0]?.locationName == data.sourceLocations[0]?.state) {
                    locationType = "STATE";
                }
                else {
                    locationType = "CITY";
                }
                data["sourceLocationType"]    = locationType;
                data["selectSourceLocations"] = data.sourceLocations.map(loc => {
                    return {
                        key   : loc.locationId,
                        value : loc.locationId,
                        label : loc.locationName
                    }
                });
                if (!data.lastFuelFetchPrice) {
                    data["lastFuelFetchPrice"] = data.fuelPrice;
                }
                if (data.sourceLocationType == "CITY") {
                    tempSchema.properties.selectSourceLocations["apiCall"] = fetchCityDetails;
                }
                else if (data.sourceLocationType == "STATE") {
                    tempSchema.properties.selectSourceLocations["apiCall"] = fetchStateDetails;
                }
                SetFormSchema(tempSchema)
                SetFormData(data);
                SetLoading(false);
            }).catch( _ => {
                const tempSchema = { ...formSchema };
                tempSchema.properties.selectSourceLocations["apiCall"] = _ => new Promise(resolve => resolve([]));
                delete tempSchema.properties.lastFuelFetchPrice;
                delete tempSchema.properties.updatedAt;
                SetFormData({});
                SetFormSchema(tempSchema);
                setTimeout(() => {
                    SetLoading(false);
                }, 0);
            })
        }
        else {
            const tempSchema = { ...formSchema };
            tempSchema.properties.selectSourceLocations["apiCall"] = _ => new Promise(resolve => resolve([]));
            delete tempSchema.properties.lastFuelFetchPrice;
            delete tempSchema.properties.updatedAt;
            SetFormData({});
            SetFormSchema(tempSchema);
            setTimeout(() => {
                SetLoading(false);
            }, 0);
        }
    }, [])    
    
    useEffect(() => {
		if (props.saveFuelCharges) {
			formRef.current.formReference?.current?.submit()
		}
    }, [props.saveFuelCharges])

    const transformErrors = errors => {
        var tempEmptyFields = 0;
        errors.map(error => {
            if (error.name === "required") {
                tempEmptyFields++;
            }
            else if (error.name === "type" && error.property == ".selectSourceLocations") {
                error.message = undefined;
            }
        })
        if (tempEmptyFields === 9) {
            return [];
        }
        SetEmptyFields(tempEmptyFields === 9);
        return errors
    }

    const saveFuelClause = data => {
        if (!emptyFields && dataUpdated) {
            var sourceLocations = data.selectSourceLocations && data.selectSourceLocations.length > 0 ? data.selectSourceLocations.map(s => {
                return {
                    locationId      : s.key,
                    locationName    : s.label,
                    locationType    : data.sourceLocationType
                }
            }) : []
            var postObject = {
                contractBO  : {
                    id      : props.contractId,
                    fuelClausesBO   :{
                        id  : formData.id,
                        ...data,
                        sourceLocations      : sourceLocations
                    }
                }
            }
            postObject.selectSourceLocations = undefined;
            postObject.sourceLocationType    = undefined;
            postObject.updatedAt             = undefined;
            postFuelClause(postObject)
            .then(response => {
                if (response.contractBO.fuelClausesBO) {
                    SetFormData({
                        ...formData,
                        lastFuelFetchPrice : formData.fuelPrice,
                        updatedAt : moment().valueOf()
                    });
                }
                SetLoading(true);
                const tempSchema = JSON.parse(JSON.stringify(fuelChargesSchema.schema));
                SetFormSchema(tempSchema);
                setTimeout(() => SetLoading(false), 0);
                notifyApiSuccess("Fuel clause successfully updated!", "Fuel Clause", true);
                props.fuelChargesSaved(true);
            })
            .catch(e => {
                notifyApiError(e.toString());
                props.fuelChargesSaved(false);
            })
        }
        else {
            props.fuelChargesSaved(true);
        }
    }

    const onChange = data => {
        SetDataUpdated(true);
        if (data.sourceLocationType != formData.sourceLocationType) {
            const tempSchema = { ...formSchema };
            if (data.sourceLocationType === "CITY") {
                tempSchema.properties.selectSourceLocations["apiCall"] = fetchCityDetails;
            }
            else if (data.sourceLocationType === "STATE") {
                tempSchema.properties.selectSourceLocations["apiCall"] = fetchStateDetails;
            }
            SetFormData({
                ...data,
                selectSourceLocations : undefined
            });
            SetFormSchema(tempSchema);
        }
        else if (data.selectSourceLocations?.length <= 0) {
            SetFormData({
                ...data,
                selectSourceLocations : undefined
            });
        }
        else {
            SetFormData(data)
        }
    }

    const validate = (data, errorObj) => {
        if ( data.fuelThresholdMin && data.fuelThresholdMax &&
             data.fuelThresholdMin >= data.fuelThresholdMax ) {
             errorObj["fuelThresholdMin"].addError("Threshold Price Minimum cannot be greater than Threshold Price Maximum!")
        }
        return errorObj;
    }

    return (
        <div>
            {
                loading ?
                <Spin spinning={loading}/> : 
                <FormWrapper
                    id={"FuelCharges"}
                    schema={formSchema}
                    uiSchema={fuelChargesSchema.uiSchema}
                    errorList={fuelChargesSchema.errorList}
                    formData={formData}
                    onChange={onChange}
                    onSubmit={saveFuelClause}
                    onError={_ => {
                        props.fuelChargesSaved(false)
                    }}
                    transformErrors={transformErrors}
                    readonly={props.readonly}
                    fieldsPerRow={3}
                    showColumnSplitter={false}
                    loading={loading} bordered={true}
                    hideSubmit={true}
                    ref={formRef}
                    validate={validate}
                />
            }

        </div>
    );
}
export default FuelCharges;