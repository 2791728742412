import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addArrayElem, removeArrayElem } from '../../../settings/contract/common/utils';
import { validateIndentLoads } from '../services/indent';
import { indentCreateActionTypes } from '../state/actions';
import DestinationLoadRow from './destination.load.row';

function DestinationLoads(props) {


    const formData = useSelector(state => state.indentCreateReducer.indentData)
    const serviceTypeConstants = useSelector(state => state.lovStore.lovConstants["ServiceType"])
    const dispatch = useDispatch();
    
    const [ loads, SetLoads ] = useState(formData.indentLoads ? formData.indentLoads : []);
    const [ selectedEntities, SetSelectedEntities ] = useState([]);
    
    useEffect(() => {
        if (formData.indentLoads) {
            SetLoads(formData.indentLoads)
        }
    }, [ formData ]);

    const updateLoads = updatedLoads => {
        dispatch({
            type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
            payload : {
                ...formData,
                indentLoads : updatedLoads
            }
        });

    }
   
    const addDestinationLoad = _ => {
        if (validateIndentLoads(loads,formData?.details?.serviceType?.key == serviceTypeConstants.PTL )) {
            var newLoad =  {
                location : {},
                weight   : "",
                quantity : "",
                volume   : ""
            };
            SetLoads(addArrayElem(loads, newLoad, loads.length ));
        }
    } 

    const saveDestinationLoad = (data, index) => {
        const updatedLoads = [...loads];
        updatedLoads[index] = data;
        SetSelectedEntities(c => {
            if (data.location?.id && c.indexOf(data.location?.id) < 0) {
                c[index] = data.location?.id;
            }
            return c;
        })
        setTimeout(() => updateLoads(updatedLoads), 0);
    }

    const deleteDestinationLoad = index => {
        if ( loads.length >1 ) {
            const updatedLoads = removeArrayElem(loads, index);
            SetSelectedEntities(c => removeArrayElem(c, index))
            setTimeout(() => updateLoads(updatedLoads), 0);
        }
        else if ( loads.length === 1 && index === 0) {
            SetSelectedEntities([]);
            setTimeout(() => updateLoads([{
                location : {},
                weight   : "",
                quantity : "",
                volume   : ""
            }]), 0);
        }
    }

    useEffect(() => {
        if (loads.length <= 0) {
            addDestinationLoad();
        }
    }, [ loads ])

    return (
        <div className="border-grey spacer-l flex-column flex-gap-l border-radius-m" style={{marginTop : 20}}>
            <div className="flex-box flex-gap-xl text-bold font-size-sm-2 spacer-hm">
                <div className="flex-3"> {"Destination Location"} </div>
                <div className="flex-3"> {"Expected Weight (in Kg)"} </div>
                <div className="flex-3"> {"Expected Volume (in CFT)"} </div>
                <div className="flex-3"> {"Expected Quantity (in Pieces)"} </div>
                {
                    !props.readonly &&
                    <div className="flex-1 flex-box justify-content-end"> Actions </div>
                }
            </div>
            {
                loads.length > 0 &&
                loads.map((load, index) => {
                    return  <DestinationLoadRow {...props}  index={index} load={load} key={index}
                     deleteDestinationLoad={deleteDestinationLoad} selectedEntities={selectedEntities}
                     onSave={saveDestinationLoad}/>
                })
            }
            {
                (!props.readonly && formData?.details?.customer?.key) &&
                <div>
                    <Button className="secondary-btn" onClick={addDestinationLoad}> Add Destination Load</Button>
                </div>
            }
        </div>
    )
}

export default  DestinationLoads;