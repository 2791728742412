import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';

export default class CommunictionService {

  sendEmail(params, application = 'b2b') {
    const uri = `/${application}/v1/email/sendEmails`;
    return apiRequest({
      url: uri,
      method: "POST",
      data: params
    });
  }

  fetchEmails(params, application = 'b2b') {
    const uri = `/${application}/v1/email/fetchEmail`;
    return apiRequest({
      url: uri,
      method: "POST",
      data: params
    });
  }
}
