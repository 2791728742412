import React, { Component, PureComponent } from "react";
import { Card } from "antd";
import Steps from "../../ui/steps/steps";
import { typography as Typography } from "../../ui/typography/Typography";
import { table as Table } from "../../ui/table/Table";
import TrackingPopup from "../../TrackingPopup/TrackingPopup";
// import { Modal } from 'antd';
import { modal as Modal } from "../../ui/modal/Modal";
import { Icon } from "antd";
import { input as Input } from "../../ui/input/input";
import { Constants, DownloadTags } from "../../../common/utils/constants";
import { button as Button } from "../../ui/button/Button";
import { connect } from "react-redux";
import * as actions from "./action";
import * as utils from "../../../common/utils/utils";
import "./TrackingListing.scss";
import ManifestTrackingPopUp from "../../TrackingPopup/ManifestTrackingPopup";
import { openNotification } from "../../ui/notification/Notification";

class TrackingListing extends PureComponent {
  state = {
    showTrackingPopup: false,
    selectedWaybill: "",
    searchText: "",
    columns: [
      {
        title: "WayBill No.",
        dataIndex: "waybillNo",
        render: text => {
          return (
            <a
              className="selected-waybill"
              onClick={this.showPopup.bind(this, text)}
            >
              {text}
            </a>
          );
        },
        className: "large-col"
      },
      {
        title: "Booking Date & Location",
        dataIndex: "bookingDate",
        render: (text, record) => {
          return (
            <div>
              <p>{new Intl.DateTimeFormat("en-GB").format(text)}</p>
              <p>{record?.bookingOfficeLoc?.name}</p>
            </div>
          );
        },
        className: "large-col"
      },
      {
        title: "Partner Name",
        dataIndex: "partner.name"
      },
      {
        title: "Pickup Location",
        dataIndex: "customerPickupLoc.name",
        className: "large-col"
      },
      {
        title: "CPD",
        dataIndex: "customerPromiseDate",
        render: text => text && utils.formateDateTime(text, "DD/MM/YYYY HH:MM:ss"),
        className: "small-col",
        width: 110
      },
      {
        title: "Attempts",
        dataIndex: "attempts",
        className: "small-col"
      },
      {
        title: "Current Location",
        dataIndex: "location.name",
        className: "small-col"
      },
      {
        title: "Status",
        dataIndex: "consignmentStatus",
        className: "small-col",
        render: (text, row) => {
          return (
            <div>
              <p>{text}</p>
              <p className="small-text">{utils.formatDate(row.updatedAt)}</p>
              <p className="small-text">{utils.formatTime(row.updatedAt)}</p>
            </div>
          );
        }
      },
      {
        title: "Action",
        dataIndex: "id",
        className: "small-col",
        render: (id, row) => {
          return (
            <div className="flex-box align-items-center flex-gap-l">
              {
                Constants.SELECTED_APPLICATION === "B2B" &&
                <>
                  <a href={`/appv2/print_information?consignmentId=${id}&formtype=docket`}
                  target="_blank"> <Icon type="printer" /> </a>
                  {
                    row?.customer?.attributes?.eligibleForThirdPartyTrackingEvents &&
                    <a href={`/appv2/print_information?consignmentId=${row.waybillNo}&formtype=docketconsignments`}
                      target="_blank"> <Icon type="book" /> </a>
                  }
                </>
              }
            </div>
          );
        }
      }
        ],
      manifestsColumns: [
        {
          title: "Manifest No",
          dataIndex: "manifestCode",
          render: text => {
            return (
              <span
                className="selected-waybill"
                onClick={this.showPopup.bind(this, text)}
              >{text}
              </span>
            )
          },
          className: "large-col"
        },
        {
          title: "Created Date & Location",
          "dataIndex": "createdAt",
          render: (text, record) => {
            return (
              <div>
                <p>{new Intl.DateTimeFormat("en-GB").format(text)}</p>
                <p>{record.originLocation}</p>
              </div>
            );
          },
        },
        {
          title: "Partner",
          dataIndex: "partnerName"
        },
        {
          title: "Next Location",
          dataIndex: "nextLocation",
          className: "large-col"
        },
        {
          title: "Destination Location",
          dataIndex: "destinationLocation",
          className: "large-col"
        },
        {
          title: "Status",
          dataIndex: "status",
          className: "small-col",
          render: (text, row) => {
            return (
              <div>
                <p>{text}</p>
                <p className="small-text">{utils.formatDate(row.eventTime)}</p>
                <p className="small-text">{utils.formatTime(row.eventTime)}</p>
              </div>
            );
          }
        }
      ] 
  };
  componentDidMount() {
    Constants.TRACKING_CLASSNAME = this.constructor.name;
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.downloadWaybillsLoading &&
      !this.props.downloadWaybillsLoading &&
      !this.props.downloadWaybillError &&
      this.props.downloadWaybillsUrl
    ) {
      utils.reportsDownload(this.props.downloadWaybillsUrl, DownloadTags.s3);
    }
  }
  showPopup = selectedWaybill => {
    this.setState({
      showTrackingPopup: true,
      selectedWaybill
    });
  };

  handleCancel = e => {
    this.setState({
      showTrackingPopup: false
    });
  };
  onChangeSearchText = e => {
    const searchInput = e.target.value;
    this.setState({
      searchText: searchInput
    });
    this.props.filterWaybills(this.props.tableDataShallowCopy, searchInput);
  };
  
  printAllWaybillChallans = _ => {
    if (this.props.tableData && this.props.tableData.length > 0) {
      var selectedIds = ""
      this.props.tableData.map(waybill => {
        selectedIds += waybill.id + ","
      })
      var encodedIds = btoa(selectedIds);
      window.open(`/appv2/print_information?consignmentIds=${encodedIds}&formtype=multiplechallans`, "_blank");
    }
    else {
      openNotification({
        message: 'No waybills to print!'
      });
    }
  }

  downloadSearchedWaybills = () => {
    console.log("downloading");
    if (!this.props.tableData?.length) {
      return;
    }
    const waybillsToDownload = [];
    this.props.tableData.forEach(row => {
      if(this.props.selectedRadioValue === "manifests"){
        if (row.manifestCode) {
          waybillsToDownload.push(row.manifestCode);
        }
      } else {
        if (row.waybillNo) {
          waybillsToDownload.push(row.waybillNo);
        }
      }
    });
    const data = {
      partnerId: Constants.PARTNER_ID,
      locationId: Constants.userLocationId, // you can use userInfo from props
      userId: Constants.sessionUser.id,
      partnerIds: [Constants.PARTNER_ID],
      documentType: "BOOKING_REPORT",
      drsType: Constants.SELECTED_APPLICATION
    };
    data[this.props.selectedRadioValue] = waybillsToDownload;
    this.props.downloadSearchedWaybills(data);
  };
  render() {
    const { searchText } = this.state;
    const tableLength =
      this.props.filteredTableData ?.length ||
        this.props ?.tableData ?.length ||
          0;
    return (
      <React.Fragment>
        <Card bordered={false} className="TrackingDetails Card gutter">
          <div className="tracking-details-header">
            <Typography
              styles={{
                fontSize: "24px",
                fontWeight: "bold",
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "1.21",
                letterSpacing: "normal",
                color: "#000000",
                width: "220px",
                margin: "0px"
              }}
            >
              Track Waybills
            </Typography>
            <div className="flex-box align-items-center flex-gap-m">
              <Input
                className="search-bar"
                placeholder="Search"
                suffix={
                  <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                value={searchText}
                onChange={this.onChangeSearchText}
              />
              <Button onClick={this.printAllWaybillChallans} style={{margin:0, color:"#1c6ce1"}} disabled={this.props.selectedRadioValue === "manifests"}> Print All Challans </Button>
              <Button onClick={this.downloadSearchedWaybills} style={{margin:0, color:"#1c6ce1"}} disabled={this.props.selectedRadioValue === "manifests"}> Download All </Button>
            </div>
          </div>
          <Table
            columns={this.props.selectedRadioValue === "manifests" ? this.state.manifestsColumns : this.state.columns}
            dataSource={this.props.filteredTableData || this.props.tableData}
            scroll={{ x: true }}
            footer={() => `${tableLength} Total`}
          />
        </Card>
        <Modal
          onCancel={this.handleCancel}
          visible={this.state.showTrackingPopup}
          width={this.props.selectedRadioValue === "manifests" ? "70vw" : "85vw"}
          footer={<div></div>}
          centered
          destroyOnClose={true}
        >
        {
          this.props.selectedRadioValue === "manifests" ?
            (
              <ManifestTrackingPopUp 
                manifestNo={this.state.selectedWaybill}
              />
            ) : (
              <TrackingPopup
                getDetailInput={this.state.selectedWaybill}
                waybillNo={this.state.selectedWaybill}
                source={Constants.TRACKING_CLASSNAME}
            />
            )
        }
          
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  downloadWaybillsLoading: state.trackingListing.downloadWaybillsLoading,
  downloadWaybillsUrl: state.trackingListing.downloadWaybillsUrl,
  downloadWaybillError: state.trackingListing.downloadWaybillError,
  filteredTableData: state.trackingListing.filteredTableData
});
const mapDispatchToProps = dispatch => ({
  downloadSearchedWaybills: params =>
    dispatch(actions.downloadSearchedWaybills(params)),
  filterWaybills: (fullTableData, searchInput) =>
    dispatch(actions.filterWaybills(fullTableData, searchInput))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingListing);
