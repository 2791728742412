import { Button, Modal, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../../../../ui/formComponent/form";
import ChargeListRow, { ChargeListHeader } from "../box-charges/box.charge.row";
import { fetchFreightCharges, fetchLabourCharges, itemsListToSelectList, removeCharges, saveContractCharges } from "../contract.details.services";
import "./box.charges.scss"
import { formChargesArray, groupChargesByBox } from "./charges.utils";
import { ftlSchema } from "../box-charges/box.charges.schema";
import ftlFreightSchema from "./ftl-freight/ftl.freight.schema";
import { getMaxFromIndex, validateChargeBO } from "../box-charges/box.charges.service";
import { getValueFromKey, notifyApiError } from "../../../../../../common/utils/utils";
import { fetchVehicleTypesEnums } from "../../../../../../services/common/fetch.services";
import FTLFreightCharges from "./ftl-freight/ftl.freight.charges";

export default function FTLCharges(props) {
    
    var boxFormRef = useRef();
    const [ freightChargesBO, SetFreightChargesBO ] = useState([]);
    const [ labourChargesBO, SetLabourChargesBO ] = useState([]);
    const [ boxCharge, SetBoxCharge ] = useState(false);
    const [ freightCharge, SetFreightCharge ] = useState(false);
    const [ readonly, SetReadonly ] = useState(false);

    const loadLabourCharges = _ => {
        SetLabourChargesBO(undefined);
        const { contractData } = props;
        fetchLabourCharges(contractData?.id)
        .then(contractCharges => {
            var tempChargesBO = contractCharges?.chargesBO.map(charge => {
                charge.itemsBO = {
                    ...charge.itemsBO,
                    uniqueIdentifier : charge.itemsBO?.itemType + "_" + charge.itemsBO?.itemId
                }
                return charge;
            });
            tempChargesBO = groupChargesByBox(tempChargesBO, true);
            SetLabourChargesBO(tempChargesBO);
        })
        .catch(_ => SetLabourChargesBO([]))
    }

    const loadFreightCharges = _ => {
        SetFreightChargesBO(undefined);
        const { contractData } = props;
        fetchFreightCharges(contractData?.id)
        .then(contractCharges => {
            var tempChargesBO = contractCharges?.chargesBO.map(charge => {
                charge.amount = charge.itemRatesBO?.rateBOs?.[0]?.amount
                return charge;
            });
            SetFreightChargesBO(tempChargesBO);
        })
        .catch(_ => SetFreightChargesBO([]))
    }

    useEffect(() => {
        const { contractData } = props;
        fetchVehicleTypesEnums()
        .then(vehicleTypes => {
            ftlFreightSchema.schema.properties.chargeBasisValue.enum = vehicleTypes;
        })
        ftlSchema.schema.properties.details.properties.itemsBO["apiCall"] = value => {
            if (value.trim() === "") {
              return new Promise(resolve => {
                resolve([{
                  value: "OTHER_0",
                  key: "OTHER_0",
                  label: "Default"
                }])
              })
            }
            return itemsListToSelectList(contractData?.customerId, value.trim())
        }
        loadLabourCharges();
        loadFreightCharges();
    }, []);

    const labourChargeAction = params => {
        SetBoxCharge(params.charge);
        SetReadonly(params.readonly);
    }

    const freightChargeAction = params => {
        SetFreightCharge(params.charge);
        SetReadonly(params.readonly);
    }

    const addLabourCharge = _ => {
        SetReadonly(false);
        SetBoxCharge({
                labourCharge : {
                    rateStructure : "FIXED",
                    rateBOs       : [{}]
                }
        })
    }

    const addFreightCharge = _ => {
        SetReadonly(false);
        SetFreightCharge({});
    }

    const onRemove = params => {
        removeCharges(props?.contractData?.id, [
            params.charge?.id,
            params.charge?.labourCharge?.chargeId
        ])
        .then(_ => {
            if (params.charge?.id) {
                loadFreightCharges();
            }
            else {
                loadLabourCharges();
            }
        });
    }

    const validate = (data, errorObj) => {
		var validationObjects = validateChargeBO(data?.labourCharge, "labourCharge") ;
		if (validationObjects?.length === 0) {
			return errorObj;
		}
		else {
			validationObjects.map(validator => {
				const propertyLabel = validator[1];
				if (validator[0] && propertyLabel) {
					var errorKeyObj = getValueFromKey(errorObj, propertyLabel);
					if (errorKeyObj) {
						errorKeyObj.__errors = [validator[0]];
					}
				}
				else if (propertyLabel && validator[0] === undefined) {
					var errorKeyObj = getValueFromKey(errorObj, propertyLabel);
					if (errorKeyObj) {
						errorKeyObj.__errors = [];
					}
				}
			})
			return errorObj;
		}
	}

    const transformErrors = errors => {
        var maxFromIndexPropertyLabour =  ".labourCharge.rateBOs[" + getMaxFromIndex(boxCharge?.labourCharge) + "].toRange";
		var noErrorFields = [ ".itemRatesBO", ".itemRatesBO.rateBOs", maxFromIndexPropertyLabour]
		errors.map(error => {
			if (error.property && noErrorFields.indexOf(error.property) != -1) {
				error.message = undefined;
			}
			else if (error.property.indexOf("fromRange") > -1 && error.name == "required") {
				error.message = "From range is mandatory!";
			}
			else if (error.property.indexOf("toRange") > -1 && error.name == "required") {
				error.message = "To range is mandatory!";
			}
			else if (error.property === ".boxBO.id" && error.name == "type") {
				error.message = undefined;
			}
        })
        return errors;
	}

    const onSubmit = data => {
        const boxExists = labourChargesBO.find(charge => charge?.details?.itemsBO?.key === data?.details?.itemsBO?.key)
        const isCreate  = data?.labourCharge.chargeId === undefined;
        if (boxExists && isCreate) {
            return Modal.warn({
                title : "Charge for box already exists. Choose specific row to edit its charge."
            })
        }
        const updatedChargesBO = formChargesArray(data, true);
        saveContractCharges(props?.contractData?.id, updatedChargesBO)
        .then(_ => {
            loadLabourCharges();
            SetBoxCharge(false);
        })
        .catch(exception => {
            if (exception?.errorMessage && exception?.errorMessage.indexOf("_") > -1) {
                exception.errorMessage = exception?.errorMessage.split("_").join(" ");
            }
            notifyApiError(exception?.errorMessage)
        })
    }

    return (
        <div className="flex-column spacer-m flex-gap-xl">
            <div className="text-bold font-size-m-3"> Charges </div>
            <div className="flex-box flex-gap-l">
                <fieldset className="box-charges-list flex-1 spacer-s border-grey border-radius-m">
                    <legend className="initial-legend font-size-m-3"> Freight Charge </legend>
                    <Spin spinning={freightChargesBO === undefined}>
                        {
                            !props.readonly && 
                            <div className="flex-box"> <Button type="primary" className="lsn-btn-primary" onClick={addFreightCharge}> Add Freight Charge </Button> </div>
                        }
                        <ChargeListHeader serviceType={"FTL"} chargeType={"FREIGHT_CHARGE"} />
                        {
                            freightChargesBO && freightChargesBO.length > 0 &&
                            freightChargesBO.map((charge, index) => (
                                <ChargeListRow key={index} charge={charge} rowIndex={index} readonly={props.readonly}
                                onViewEditClick={freightChargeAction} onRemove={onRemove}
                                serviceType={"FTL"} chargeType={"FREIGHT_CHARGE"} />
                            ))
                        }
                    </Spin>
                </fieldset>
                <fieldset className="box-charges-list flex-1 spacer-s border-grey border-radius-m">
                    <legend className="initial-legend font-size-m-3"> Labour Charge </legend>
                    <Spin spinning={labourChargesBO === undefined}>
                        {
                            !props.readonly && 
                            <div className="flex-box"> <Button type="primary" className="lsn-btn-primary" onClick={addLabourCharge}> Add Labour Charge </Button> </div>
                        }
                        <ChargeListHeader serviceType={"FTL"} chargeType={"LOADING_CHARGE"} />
                        {
                            labourChargesBO && labourChargesBO.length > 0 &&
                            labourChargesBO.map((charge, index) => (
                                <ChargeListRow key={index} charge={charge} rowIndex={index} readonly={props.readonly}
                                onViewEditClick={labourChargeAction} onRemove={onRemove}
                                serviceType={"FTL"} chargeType={"LOADING_CHARGE"} />
                            ))
                        }
                    </Spin>
                </fieldset>
            </div>

            {
                boxCharge && 
                <Modal visible={true} title={"Add Labour Charge"} width={1000}
                 onOk={_ => boxFormRef?.current?.formReference?.current?.submit()}
                 onCancel={_ => SetBoxCharge(undefined)} okText={"Save"}
                 footer={readonly ? false : undefined} >
                    <FormWrapper {...ftlSchema} ref={boxFormRef} id="ptlCharges"
                     formData={boxCharge} transformErrors={transformErrors}
                     readonly={readonly} fieldsPerRow={2} bordered={true}
                     hideSubmit={true} onSubmit={onSubmit} validate={validate}
                     onChange={data => {
                        var  laboutRateStructure = data.labourCharge?.rateStructure,
                        laboutRateBOs = data.labourCharge?.rateBOs;
                        if ((laboutRateStructure && laboutRateStructure != "FIXED") && (!laboutRateBOs || laboutRateBOs?.length <= 0) ) {
                            data.labourCharge.amount = undefined;
                            data.labourCharge["rateBOs"] = [{}]
                        }
                        SetBoxCharge(data);
                     }} />
                </Modal>
            }

            {
                freightCharge && 
                <FTLFreightCharges freightCharge={freightCharge} SetFreightCharge={SetFreightCharge}
                readonly={readonly} contractData={props?.contractData} freightChargesBO={freightChargesBO}
                 onSaveSuccess={_ => {
                    loadFreightCharges();
                    SetFreightCharge(undefined);
                 }}/>
            }

        </div>
    )
}