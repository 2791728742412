import React, { Component } from "react";
import { Tabs } from "antd";
import { menuconfig } from "../../../configs/menu-config";
import { angularUrl } from "../../../environments/node-react-environment";
import "./TabsPane.scss";

const TabPane = Tabs.TabPane;

class TabsPane extends Component {
  state = {
    selectedTabIndex: "0",
    menuItems: [],
    selectedMenuKey: null,
    selectedTabs: [],
  };

  componentDidMount(){
    if (this.props.location.pathname !== "/appv2/") {
      this.selectMenuAndTabForValidUrl();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configurations !== this.props.configurations) {
      if (this.props.location.pathname !== "/appv2/") {
        this.selectMenuAndTabForValidUrl();
      }
    }
  }

  selectMenuAndTabForValidUrl = () => {
    if (!this.props.history.location.pathname) {
      return;
    }
    const pathParts = this.props.location.pathname.split("/");
    let tabPath1 = pathParts[pathParts.indexOf("form") + 1];
    let tabPath2 = pathParts[pathParts.lastIndexOf("dashboard") + 1];
    let tabPath = tabPath1 ? tabPath1 : tabPath2;
    const menuPath = pathParts.slice(2, 3).join("/");
    const menuToBeSelected = menuconfig.find(menu => {
      return menu.path === menuPath;
    });
    if (menuToBeSelected) {
      let selectedTabIndex = 0;
      menuToBeSelected.children.find((child, index) => {
        selectedTabIndex = index + "";
        return child.path === tabPath;
      });
      const allowedMenus = menuconfig.filter(menu => {
        return menu.canAllow;
      });
      if (tabPath === undefined || tabPath === '') {
        let isFirst = true;
        menuToBeSelected.children.map((child, index) => {
          if (child.canAllow && isFirst) {
            selectedTabIndex = index + "";
            isFirst = false;
          }
        })
      }
      this.setState({
        selectedTabs: menuToBeSelected.children,
        selectedMenuKey: menuToBeSelected.key,
        selectedTabIndex,
        menuItems: allowedMenus
      });
    }
  };

  onTabChange = (tabIndex = 0) => {
    const selectedMenu = this.state.menuItems.find(menu => {
      return menu.key === this.state.selectedMenuKey;
    });
    if (!selectedMenu) {
      return;
    }
    const selectedChild = this.state.selectedTabs[tabIndex];
    if (!selectedChild) {
      return;
    }
    if (!selectedChild.isReact) {
      window.location = `${angularUrl}${selectedMenu.path}/dashboard/${selectedChild.path}`;
    } else {
      if (selectedMenu.type === "external-link") {
        window.open(selectedMenu.path, "_blank");
      } else {
        this.setState({ selectedTabIndex : tabIndex });
        this.props.history.push(`/appv2/${selectedMenu.path}/dashboard/${selectedChild.path}`);
      }
    }
  };

  render() {
    const TabsHtml = this.state.selectedTabs.map((tab, index) => {
      if (tab.canAllow) {
        return <TabPane tab={tab.label} key={index} />;
      }
      return null;
    });
    return (
      <Tabs
        defaultActiveKey="0"
        activeKey={this.state.selectedTabIndex.toString()}
        onChange={this.onTabChange}
        className="tabs-pane tabspane-custom"
      >
        {TabsHtml}
      </Tabs>
      )
  }
}

export default TabsPane;