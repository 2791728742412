
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const DrsdraftReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_RUNSHEET_BEGIN: return fetchRunsheetBegin(state);
        case _Constants.FETCH_RUNSHEET_SUCCESS: return fetchRunsheetSuccess(state, action);
        case _Constants.FETCH_RUNSHEET_FAILURE: return fetchRunsheetFailure(state, action);
        case _Constants.FETCH_LOCATION_USER_BEGIN: return fetchlocationUserBegin(state);
        case _Constants.FETCH_LOCATION_USER_SUCCESS: return fetchlocationUserSuccess(state, action);
        case _Constants.FETCH_LOCATION_USER_FAILURE: return fetchlocationUserFailure(state, action);
        default:
            return state;
    }
}


function fetchRunsheetBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchRunsheetSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedrunSheets: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedrunSheets ? state.PaginatedrunSheets : {}, action ?.payload ?.runsheetDraftBOs , action.isReset),
        ...action.payload
    };
}

function fetchRunsheetFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        runsheetDraftBOs: []
    };
}

function fetchlocationUserBegin(state, action) {
    return {
        ...state
    };
}

function fetchlocationUserSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchlocationUserFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        users : []
    };
}

