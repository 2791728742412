import * as CONSTANTS from './constants';


export const LMSReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.GET_LMS_LIST_BEGIN: return getLMSBegin(state);
        case CONSTANTS.GET_LMS_LIST_SUCCESS: return getLMSSuccess(state, action);
        case CONSTANTS.GET_LMS_LIST_FAILURE: return getLMSFailure(state);


        case CONSTANTS.GET_LMS_CREATE_BEGIN: return getLMSCreateBegin(state);
        case CONSTANTS.GET_LMS_CREATE_SUCCESS: return getLMSCreateSuccess(state, action);
        case CONSTANTS.GET_LMS_CREATE_FAILURE: return getLMSCreateFailure(state);

        case CONSTANTS.GET_LMS_USERS_LIST_BEGIN: return getLMSUsersBegin(state);
        case CONSTANTS.GET_LMS_USERS_LIST_SUCCESS: return getLMSUsersSuccess(state, action);
        case CONSTANTS.GET_LMS_USERS_LIST_FAILURE: return getLMSUsersFailure(state);

        case CONSTANTS.GET_LMS_EDIT_LIST_BEGIN: return getLMSEditBegin(state);
        case CONSTANTS.GET_LMS_EDIT_LIST_SUCCESS: return getLMSEditSuccess(state, action);
        case CONSTANTS.GET_LMS_EDIT_LIST_FAILURE: return getLMSEditFailure(state);
        case CONSTANTS.GET_LMS_EDIT_LIST_RESET: return getLMSEditReset(state);


        case CONSTANTS.GET_LMS_UPDATE_STATUS_BEGIN: return getLMSUpdateBegin(state);
        case CONSTANTS.GET_LMS_UPDATE_STATUS_SUCCESS: return getLMSUpdateSuccess(state, action);
        case CONSTANTS.GET_LMS_UPDATE_STATUS_FAILURE: return getLMSUpdateFailure(state);


        case CONSTANTS.GET_LMS_USER_UPDATE_STATUS_BEGIN: return getLMSUserUpdateBegin(state);
        case CONSTANTS.GET_LMS_USER_UPDATE_STATUS_SUCCESS: return getLMSUserUpdateSuccess(state, action);
        case CONSTANTS.GET_LMS_USER_UPDATE_STATUS_FAILURE: return getLMSUserUpdateFailure(state);


        case CONSTANTS.GET_LMS_USER_REFRESH_STATUS_BEGIN: return getLMSUserRefreshBegin(state);
        case CONSTANTS.GET_LMS_USER_REFRESH_STATUS_SUCCESS: return getLMSUserRefreshSuccess(state, action);
        case CONSTANTS.GET_LMS_USER_REFRESH_STATUS_FAILURE: return getLMSUserRefreshFailure(state);
        case CONSTANTS.GET_USER_RESET: return getUserReset(state);

        default:
            return state;
    }
}

function getLMSBegin(state) {
    return {
        ...state,
        lms: {
            loading: true
        },
        lmsCreate: undefined
    }
}

function getLMSSuccess(state, action) {
    return {
        ...state,
        lms: {
            lmsData: action.payload,
            loading: false
        },
        lmsCreate: undefined
    }
}

function getLMSFailure(state) {
    return {
        ...state,
        lms: {
            loading: false
        }
    }
}

function getLMSCreateBegin(state) {
    return {
        ...state,
        lmsCreate: {
            loading: true
        }
    }
}

function getLMSCreateSuccess(state, action) {
    return {
        ...state,
        lmsCreate: {
            lmsData: action.payload,
            loading: false
        }
    }
}

function getLMSCreateFailure(state) {
    return {
        ...state,
        lmsCreate: {
            loading: false
        }
    }
}

function getLMSUsersBegin(state) {
    return {
        ...state,
        lmsUsers: {
            loading: true
        },
        lmsCreate: undefined
    }
}

function getLMSUsersSuccess(state, action) {
    return {
        ...state,
        lmsUsers: {
            lmsData: action.payload,
            loading: false
        },
        lmsCreate: undefined
    }
}

function getLMSUsersFailure(state) {
    return {
        ...state,
        lmsUsers: {
            loading: false
        }
    }
}

function getLMSEditBegin(state) {
    return {
        ...state,
        lmsEditLoading: true,
        lmsCreate: undefined
    }
}

function getLMSEditSuccess(state, action) {
    
    return {
        ...state,        
        lmsEditData: action.payload,
        lmsEditLoading: false,
        lmsCreate: undefined
    }
}

function getLMSEditFailure(state) {
    return {
        ...state,
        lmsEditLoading: false
    }
}

function getLMSEditReset(state) {
    return {
        ...state,
        lmsEditData: undefined,
    }
}



function getLMSUpdateBegin(state) {
    return {
        ...state,
        lmsUpdateLoading: false
    }
}

function getLMSUpdateSuccess(state, action) {
    
    return {
        ...state,        
        lmsUpdateStatus: action.payload,
        lmsUpdateLoading: false       
    }
}

function getLMSUpdateFailure(state) {
    return {
        ...state,
        lmsUpdateLoading: false
    }
}



function getLMSUserUpdateBegin(state) {
    return {
        ...state,
        lmsUSERUpdateLoading: false
    }
}

function getLMSUserUpdateSuccess(state, action) {
    
    return {
        ...state,        
        lmsUSERUpdateStatus: action.payload,
        lmsUserUpdateLoading: false       
    }
}

function getLMSUserUpdateFailure(state) {
    return {
        ...state,
        lmsUSERUpdateLoading: false
    }
}

function getUserReset(state) {
    return {
        ...state,
        lmsUSERUpdateStatus: undefined,
        lmsUsers: {
            lmsData: undefined
        }
    }
}




function getLMSUserRefreshBegin(state) {
    return {
        ...state,
        lmsRefreshLoading: false
    }
}

function getLMSUserRefreshSuccess(state, action) {
    
    return {
        ...state,        
        lmsRefreshData: action.payload,
        lmsRefreshLoading: false       
    }
}

function getLMSUserRefreshFailure(state) {
    return {
        ...state,
        lmsRefreshLoading: false
    }
}
