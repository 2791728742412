import React, { Component } from 'react';
import {
    Card, Icon, Divider,
    Row, Col, Button, Select,
    Form, Input, Alert, InputNumber, DatePicker, Radio
}
    from 'antd';
import * as moment from 'moment';
import '../../reports/ingest/Ingest.scss';
import FileUpload from './../../ui/file-upload/file.upload';
import { UPLOAD_BUTTON_TYPE, S3_REPORT_STATUS, validationRules, Constants } from '../../../common/utils/constants';
import * as actions from '../../reports/ingest/action';
import * as appActions from '../../../app/appAction';
import { connect } from 'react-redux';
import { notifyApiError, notifyApiSuccess, checkForDownloadReportStatus, dataGet, JSONToCSVConvertor, checkHttpStatus } from '../../../common/utils/utils';
import * as _ from 'lodash';

const { RangePicker } = DatePicker;


class ImportExportBuilder extends Component {
    state = {
        acceptTypes: [".xlsx", ".xls"],
        sampleDownloadLink: '',
        isFormValid: false,
        reportType: undefined,
        s3FileLink: '',
        clearFile: false,
        btnStatus: 'Submit',
        dropDownsList: [],
        inputsList: [],
        endPoint: null,
        payload: {},
        result: [],
        formList: [],
        showResponseFields: [],
        selectedRadio: '',
        startDate: ''
    }

    componentDidMount() {
        const payload = {
            userId: Constants.sessionUser.id,
            locationId: Constants.userLocationId,
            partnerId: Constants.PARTNER_ID,
            "reportTypeEnums": [this.props.builderType]
        }
        this.props.getReportTypes(payload)

    }

    componentDidUpdate(oldProps) {
        if (this.props.uploadedFileResp && oldProps.uploadedFileResp !== this.props.uploadedFileResp) {
            checkForDownloadReportStatus(this.props)
            this.setState({
                clearFile: true,
                reportType: undefined,
                btnStatus: 'Submit',
                dropDownsList: [],
                inputsList: [],
                endPoint: null,
                payload: {},
                formList: [],
            })
        }
        if (this.props.customApiResp && oldProps.customApiResp !== this.props.customApiResp) {
            const resp = this.props.customApiResp
            let result = []
            // if (resp?.response?.sync) {
            if (this.state.responseDataFormat === 'JSON') {
                if (this.state.showResponseFields.length > 0) {
                    this.state.showResponseFields.forEach(field => {
                        const fieldArr = field.split('.');
                        result.push({
                            key: this.capitalize(fieldArr[fieldArr.length - 1]),
                            value: dataGet(resp, field, 0),
                            type: resp?.response?.sync ? 'success' : 'error'
                        })
                    })
                }
                this.setState({
                    result,
                    clearFile: true,
                    reportType: undefined,
                    btnStatus: 'Submit',
                    dropDownsList: [],
                    inputsList: [],
                    endPoint: null,
                    payload: {},
                    formList: [],
                    ...this.getMetaDataProperties({})
                });

            } else if (this.state.responseDataFormat === 'FILE') {
                if (resp?.response?.sync)
                    checkForDownloadReportStatus({ ...this.props, uploadedFileResp: { responseUrl: resp?.response?.responseUrl || resp?.response?.url } })
                else if (checkHttpStatus(resp?.status)) {
                    checkForDownloadReportStatus({ ...this.props, uploadedFileResp: { responseUrl: resp?.response?.responseUrl || resp?.response?.url } })
                }
                else
                    notifyApiError(resp?.response?.message)
                this.setState({
                    clearFile: true,
                    reportType: undefined,
                    btnStatus: 'Submit',
                    dropDownsList: [],
                    inputsList: [],
                    endPoint: null,
                    payload: {},
                    formList: [],
                })
            } else if (this.state.responseDataFormat === 'CONVERT_TO_CSV') {
                if (resp?.response?.sync)
                    JSONToCSVConvertor(resp.response[this.state.showResponseFields], '', true)
                else if (checkHttpStatus(resp?.status)) {
                    if (resp.response[this.state.showResponseFields]?.length > 0) {
                        JSONToCSVConvertor(resp.response[this.state.showResponseFields], '', true)
                    } else {
                        notifyApiError('No data found', 'ERROR');
                    }
                }
                else
                    notifyApiError(resp?.response?.message)
                this.setState({
                    clearFile: true,
                    reportType: undefined,
                    btnStatus: 'Submit',
                    dropDownsList: [],
                    inputsList: [],
                    endPoint: null,
                    payload: {},
                    formList: [],
                })
            }
            // }
            // else {
            //     if (!checkHttpStatus(resp?.status)) {
            //         notifyApiError(resp?.response?.message)
            //     } else {
            //         notifyApiSuccess(resp?.response?.message, 'SUCESS')
            //     }
            //     this.setState({
            //         clearFile: true,
            //         reportType: undefined,
            //         btnStatus: 'Submit',
            //         dropDownsList: [],
            //         inputsList: [],
            //         endPoint: null,
            //         payload: {},
            //         formList: [],
            //     })
            // }
        }
        // window.open(this.props.uploadedFileResp.responseUrl)
    }

    seperateValueByDot(strArr) {
        let temp = [];
        temp.push(strArr.map(str => {
            const arr = str.split('.');
            return arr;
        }))
        return temp;
    }

    handleReportChange = (val) => {
        // this.sampleDownloadLink = val
        this.setState({
            reportType: val,
            result: []
        })
        this.props.reportTypes.map(type => {
            if (type.code === val) {
                this.setState({
                    ...this.getMetaDataProperties(type)
                }, this.callDropDownApi(type.metaInfo?.dropDowns))

            }
        })
    }

    callDropDownApi = (dropDownsList) => {
        if (dropDownsList)
            dropDownsList.forEach((val, key) => {
                switch (val.type) {
                    case 'customers':
                        this.props.getCustomerList();
                        dropDownsList[key]['options'] = this.props.customerList
                        break;
                    case 'partners':
                        this.props.getCustomerList();
                        dropDownsList[key]['options'] = this.props.partnerList
                        break;
                    case 'locations':
                        this.props.getCustomerList();
                        dropDownsList[key]['options'] = this.props.locationList
                        break;
                    default: break;
                }
            })
        this.setState({
            dropDownsList: dropDownsList
        })
    }
    callDropDownApi_v2 = (type) => {

        switch (type) {
            case 'customers':
                this.props.getCustomerList();
                break;
            default: break
        }
    }

    getMetaDataProperties = (type) => {
        return {
            sampleDownloadLink: type.metaInfo?.sampleFormate,
            formList: type.metaInfo?.formList ? type.metaInfo.formList : [],
            acceptTypes: type.metaInfo?.requestFileTypes,
            dropDownsList: type.metaInfo?.dropDowns || [],
            inputsList: type.metaInfo?.inputs || [],
            endPoint: type.metaInfo?.endPoint,
            payload: { ...type.metaInfo?.endPoint?.payload },
            showResponseFields: type.metaInfo?.showResponseFields,
            responseDataFormat: type.metaInfo?.responseDataFormat,
        }
    }
    handleChange = (val, selectedObj) => {

        this.setState(state => ({
            [selectedObj.payloadProperty]: selectedObj.payloadPropertyType === 'ARRAY' ? [Number(val)] : val,
            payload: {
                ...state.payload,
                [selectedObj.payloadProperty]: selectedObj.payloadPropertyType === 'ARRAY' ? [Number(val)] : val,
            }
        }), () => {
            if (selectedObj.payloadProperty === 'partnerIds') {
                this.props.getLocationList(this.state.payload[selectedObj.payloadProperty]);
            }
        })
    }

    onUploadFinish = (e, type) => {
        this.setState({
            s3FileLink: e,
            isFormValid: true
        })

        this.props.form.setFieldsValue({
            [type]: e
        })
    }

    postToUploadApi = () => {
        const _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                _this.setState({
                    btnStatus: 'PROCESSING...'
                })
                const payload = {
                    ..._this.state.payload,
                    s3FileLink: _this.state.s3FileLink,
                    ...values
                }
                if (_this.state.reportType) {
                    if (_this.state.endPoint) {
                        // if (_this.state.endPoint.payloadProperty) {
                        //     delete payload.s3FileLink;
                        // }
                        if (_.hasIn(_this.state.endPoint, 'payloadProperty')) {
                            delete payload.s3FileLink;
                            _this.props.getCustomApiCall(_this.state.endPoint.url, _this.state.endPoint.method, { ...payload, [_this.state.endPoint.payloadProperty]: _this.state.s3FileLink });

                        } else {
                            if (this.state.endPoint.s3fileRequired) {
                                _this.props.getCustomApiCall(_this.state.endPoint.url, _this.state.endPoint.method, { ...payload, [_this.state.endPoint.payloadProperty]: _this.state.s3FileLink });
                            } else {
                                if (_.hasIn(payload, 'bookingDate')) {
                                    payload.bookingDate = {
                                        from: values.bookingDate[0].startOf('day').valueOf(),
                                        to: values.bookingDate[1].endOf('day').valueOf(),
                                    };

                                    // payload['rootPartnerIds'] = [Constants.PARTNER_ID];

                                    delete payload.s3FileLink;

                                    _this.props.getCustomApiCall(_this.state.endPoint.url, _this.state.endPoint.method, { ...payload });
                                } else {
                                    _this.props.getCustomApiCall(_this.state.endPoint.url, _this.state.endPoint.method, { ..._this.state.payload, ...values });
                                }



                            }

                        }


                    } else {
                        _this.props.uploadIngestFile(_this.state.reportType, payload)
                    }
                } else {
                    notifyApiError("One or more Field are empty.", "Error:")
                }
                // console.log('Received values of form: ', values);
            }
        });
    }

    capitalize(comp) {
        return comp[0].toUpperCase() + comp.substring(1, comp.length);
    }

    optionHtmlCreator = type => {
        switch (type) {
            case 'customers':
                if (!this.props.customerList) {
                    this.callDropDownApi_v2(type);
                }
                return (
                    this.props?.customerList?.map(cust => (
                        <Select.Option value={cust.id} key={cust.id} label={cust.name} >
                            {cust.name}
                        </Select.Option>
                    ))
                )
            case 'partners':
                return (
                    this.props?.partnerList?.map(partner => (
                        <Select.Option key={partner.linkPartnerBO.id} value={partner.linkPartnerBO.id} label={partner.linkPartnerBO.name}>
                            {partner.linkPartnerBO.name}
                        </Select.Option>
                    ))
                )
            case 'locations':
                return (
                    this.props?.locationList?.map(loc => (
                        <Select.Option key={loc.id} value={loc.id} label={loc.name}>
                            {loc.name}
                        </Select.Option>
                    ))
                )
            default:
                break;
        }
    }

    onChange = value => {
        // console.log("Selected value is =>", value)
    }

    radioCreator = options => {
        let radios = [];
        for (let k in options) {
            radios.push(
                <Radio value={k}>{options[k]}</Radio>
            )
        }

        return radios;
    }

    disabledDate = (current, days = 31) => {
        if (this.state.startDate) {
            const negateDays = -(days);
            const dayStart = moment(this.state.startDate).add(negateDays, 'days');
            const dayEnd = moment(this.state.startDate).add(days, 'days');
            if (dayEnd.isAfter(moment().endOf('day'))) {
                return !(dayStart.isSameOrBefore(current) && current < moment().endOf('day'));
            } else {
                return !(dayStart.isSameOrBefore(current) && dayEnd.isAfter(current));
            }


        } else {
            return current && current > moment().endOf('day');
        }

    }

    onDateChange = (date, dateString) => {
        if (date[0]) {
            this.setState({ startDate: date[0] })
        }
    }

    formHtmlCreator = list => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return list.map((item, key) => {
            switch (item.type) {
                case 'text':
                case 'email':
                    return <Row key={key}>
                        <Col span={14}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please enter your ${item.title}`
                                        },
                                        { pattern: item.type === 'email' ? new RegExp(validationRules.email) : '', message: 'Please input a valid email!' }
                                    ]
                                })(
                                    <Input type={item.type} placeholder={item.title} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>;

                case 'number':
                    return <Row key={key}>
                        <Col span={14}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.title, {
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please enter your ${item.title}`
                                        }
                                    ]
                                })(
                                    <InputNumber />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>;

                case 'select':
                    return <Row key={key}>
                        <Col span={14}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please select your ${item.title}`
                                        }
                                    ]
                                })(
                                    <Select
                                        showSearch
                                        mode={item.multiple ? 'multiple' : 'single'}
                                        placeholder={`Select ${item.title}`}
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }

                                    >
                                        {
                                            this.optionHtmlCreator(item.fetchOption)
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                case 'file':
                    return <Row key={key}>
                        <Col span={24}>
                            {
                                this.state.sampleDownloadLink ?
                                    <Row>
                                        <br />
                                        <div className="download-sample-data">
                                            <h5>For accurate results</h5>
                                            <p>
                                                Download sample upload format
                                                <a href={this.state.sampleDownloadLink} target='_blank'> here</a>
                                            </p>
                                        </div>
                                    </Row> : ''
                            }
                        </Col>
                        <Col span={24} className={item.required ? 'ant-form-item-required' : ''}>Select file:</Col>
                        <Col span={24}>
                            <Form.Item>
                                {getFieldDecorator(item.payloadProperty, {
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please select your ${item.title}`
                                        }
                                    ]
                                })(
                                    <FileUpload
                                        fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                        directUpload={true}
                                        disableUploadButton={false}
                                        onUploadFinish={($events) => this.onUploadFinish($events, item.payloadProperty)}
                                        path="ingest"
                                        acceptTypes={this.state.acceptTypes}
                                        objKey="reports"
                                        clearFileAfterUpload={this.state.clearFile}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                case 'date':
                    return <Row key={key}>
                        <Col span={14}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please enter your ${item.title}`
                                        }
                                    ]
                                })(
                                    <RangePicker
                                        ranges={{}}
                                        allowClear={false}
                                        onCalendarChange={this.onDateChange}
                                        disabledDate={(current) => this.disabledDate(current, item.disableDays)}
                                        format="YYYY-MM-DD"
                                    />

                                )}
                            </Form.Item>
                        </Col>
                    </Row>;

                case 'radio':
                    // console.log("item->>>.", getFieldValue(item.payloadProperty))
                    return <Row key={key}>
                        <Col span={14}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    initialValue: item?.default,
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please enter your ${item.title}`
                                        }
                                    ]
                                })(
                                    <Radio.Group>
                                        {
                                            this.radioCreator(item.radioOptions)
                                        }
                                    </Radio.Group>

                                )}
                            </Form.Item>
                        </Col>
                    </Row>;
                default:
                    return null;
            }
        })
    }

    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <Card
                    title={this.props.builderTitle}
                    className='Ingest'
                >
                    <Form {...formItemLayout}>
                        <Row>
                            {/* <Col span={4}></Col> */}
                            <Col span={14}>
                                <Form.Item label={`${this.props.builderTitle} Type:`}>
                                    <Select
                                        showSearch
                                        placeholder="Select Report Type"
                                        style={{ width: '100%' }}
                                        onChange={this.handleReportChange}
                                        value={this.state.reportType}
                                    >
                                        {
                                            this.props?.reportTypes?.map(type => (
                                                <Select.Option value={type.code} key={type.code} >{type.name}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            this.formHtmlCreator(this.state.formList)
                        }
                        {
                            this.state?.dropDownsList?.length > 0
                                ? this.state.dropDownsList.map(item => {
                                    return (
                                        <Row className="">
                                            {/* <Col span={4}>{item.type}:</Col> */}
                                            <Col span={14}>
                                                <Form.Item label={this.capitalize(item.type)}>
                                                    {getFieldDecorator(item.type, {
                                                        rules: [
                                                            {
                                                                required: item.required,
                                                                message: `Please select your ${item.type}`
                                                            }
                                                        ]
                                                    })(
                                                        <Select
                                                            showSearch
                                                            mode={[''].includes(item.type) ? 'multiple' : 'single'}
                                                            placeholder={`Select ${item.type}`}
                                                            style={{ width: '100%' }}
                                                            onChange={(value) => this.handleChange(value, item)}
                                                            value={this.state[item.payloadProperty]}
                                                        >
                                                            {
                                                                this.optionHtmlCreator(item.type)
                                                            }
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                                })
                                : ''
                        }
                        {
                            this.state.inputsList.length > 0
                                ? this.state.inputsList.map(item => {
                                    return (
                                        <Row className="">
                                            {/* <Col span={4}>{item.type}:</Col> */}
                                            <Col span={14}>
                                                <Form.Item label={this.capitalize(item.type)}>
                                                    {getFieldDecorator(item.type, {
                                                        rules: [
                                                            {
                                                                required: item.required,
                                                                message: `Please enter your ${item.type}`
                                                            }
                                                        ]
                                                    })(
                                                        <Input type={item.type} placeholder={item.placeholder} onChange={(value) => this.handleChange(value, item)} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )
                                }) : ''
                        }
                        {/* <Row>
                            <Col span={24}>Select file:</Col>
                            <Col span={24}>
                                <FileUpload
                                    fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                    directUpload={true}
                                    disableUploadButton={false}
                                    onUploadFinish={($events) => this.onUploadFinish($events)}
                                    path="ingest"
                                    acceptTypes={this.state.acceptTypes}
                                    objKey="reports"
                                    clearFileAfterUpload={this.state.clearFile}
                                />
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={4}>
                                {/* <Button type="primary" onClick={() => this.postToUploadApi()} className="margin-top-15" disabled={!this.state.isFormValid || this.props.s3SyncStatus === S3_REPORT_STATUS.BEGIN}> */}
                                <Button type="primary" onClick={() => this.postToUploadApi()} className="margin-top-15">
                                    {
                                        this.props.s3SyncStatus === S3_REPORT_STATUS.BEGIN
                                            ? 'PROCESSING...'
                                            : this.props.s3SyncStatus === S3_REPORT_STATUS.FAILURE
                                                ? 'Re-Sync'
                                                : 'Submit'
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {
                        this.state.result.length > 0
                            ? this.state.result.map((result, index) => (
                                <Alert key={index} style={{ margin: 10 }} message={`${result.key}: ${result.value ? result.value : 0}`} type={result.type} />
                            ))
                            : null
                    }
                </Card>
            </>
        )
    }
}

const mapStateToProps = state => ({
    reportTypes: state.ingest.reportType,
    customerList: state.ingest.customerList,
    partnerList: state.header?.partnerHeaderDetails?.partners,
    locationList: state.ingest.locationList,
    uploadedFileResp: state.ingest.uploadedFileResp,
    s3SyncStatus: state.app.s3Status,
    customApiResp: state.ingest.customApiResp,
    header: state.header
});

const mapDispatchToProps = dispatch => ({
    uploadIngestFile: (param1, param2) => dispatch(actions.uploadIngestFile(param1, param2)),
    getReportTypes: (type) => dispatch(actions.getReportTypes(type)),
    setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    getCustomerList: () => dispatch(actions.getCustomerList()),
    getLocationList: (val) => dispatch(actions.getLocationList(val)),
    getCustomApiCall: (url, method, payload) => dispatch(actions.customUrlApiCall(url, method, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ingest' })(ImportExportBuilder));