import React, {Component} from 'react';
import './mail.scss';
import {fetchMails} from './actions.js';
import {connect} from 'react-redux';
import { Card,Table,Col, Row, Tooltip,Spin,Button ,Icon,Empty } from 'antd';
import moment from 'moment';

class MailDashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      columns: [
        {
          title: 'To',
          dataIndex: 'toList',
          key: 'to',
          render: (data, row) => (<> {data.split(',').map(id => (<div>{id}<br /></div>))} </>)
        },
        {
          title: 'Subject',
          dataIndex: 'emailSubject',
          key: 'subject',
        },
        {
          title: 'Sent at',
          dataIndex: 'emailSentTime',
          key: 'emailSentTime',
          render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
        },
      ],
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
        }
      },
      pageNo: 1,
    }

    this.applyPagination({pageNo: this.state.pageNo});
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    this.props.fetchMails({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
  }

  reloadMailList = () => {
    this.setState({
      pageNo: 1
    })
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedMails, } = this.props;
    if (!PaginatedMails ? !PaginatedMails : !PaginatedMails[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  render(){
    const { columns, pageNo } = this.state;
    const { PaginatedMails, mailData } = this.props;
    const actionBtns = (
      <Spin spinning={mailData ?.loading} indicator={<div></div>} >
        <Row>
        <Col span={18}>
            <Button size={'large'} onClick={() => this.props.history.push(`/appv2/communication/form/mail`)} type="primary" >COMPOSE MAIL</Button>
          </Col>
          <Col span={4}>
            <Tooltip placement="top" title={'Reload Mail List'}>
              <Button size={'large'} onClick={this.reloadMailList} style={{marginRight: '4px'}} type="primary">
                <Icon type="reload" />
              </Button>
            </Tooltip>
          </Col>
          
        </Row>
      </Spin>
    );
    return (
      <>
        <Card className="MailDashBoard" title={<h2>Mail List</h2>}
          extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            loading={mailData ?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: mailData ?.totalCount,
              position: 'bottom',
              current: pageNo,
              pageSize: mailData ?.pageSize
          }}
            dataSource={PaginatedMails ? PaginatedMails[pageNo] : []}
            columns={columns} />
        </Card>
      </>
    )
  }
}


const mapStateToProps = (state, ownProps) =>  ({
  mailData: state.communicationMail,
  PaginatedMails: state.communicationMail.PaginatedMails
});
const mapDispatchToProps = dispatch => ({
  fetchMails: (payload, isReset) => dispatch(fetchMails(payload, isReset))
});
export default connect(mapStateToProps, mapDispatchToProps)(MailDashboard);