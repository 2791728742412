import React from 'react';
import {
    Select, Form, Radio, DatePicker, Switch,
    Divider, Input, Icon, Row, Col, InputNumber, Collapse, Modal, Button
} from 'antd';
import scannerIcon from '../../../../assets/img/icons/scanner.svg';
import MarketAsset from "../../../../assets/img/icons/market_asset.svg";
import NonMarketAsset from "../../../../assets/img/icons/non_market_asset.svg";
import VehicleAvailable from "../../../../assets/img/icons/vehicle_available.svg";
import FileUpload from "../../../ui/file-upload/file.upload";
import { UPLOAD_BUTTON_TYPE } from '../../../../common/utils/constants';
import './trip.scss';
import _ from 'lodash';
const { Option } = Select;
const InputGroup = Input.Group;





const TripCreateStep1 = Form.create({
    name: 'tripcreate',
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        return {
            destination: Form.createFormField({
                ...props.destination,
                value: props.destination.value,
            }),
            pcs: Form.createFormField({
                ...props.pcs,
                value: props.pcs.value,
            }),
            dispatched: Form.createFormField({
                ...props.dispatched,
                value: props.dispatched.value,
            }),
            triptype: Form.createFormField({
                ...props.triptype,
                value: props.triptype.value,
            }),
            loadtype: Form.createFormField({
                ...props.loadtype,
                value: props.loadtype.value,
            }),
            route: Form.createFormField({
                ...props.route,
                value: props.route.value,
            }),
            vehiclenumber: Form.createFormField({
                ...props.vehiclenumber,
                value: props.vehiclenumber.value,
            }),
            gpsdevice: Form.createFormField({
                ...props.gpsdevice,
                value: props.gpsdevice.value,
            }),
            gpsid: Form.createFormField({
                ...props.gpsid,
                value: props.gpsid.value,
            }),
            mobilenumber: Form.createFormField({
                ...props.mobilenumber,
                value: props.mobilenumber.value,
            }),
            drivername: Form.createFormField({
                ...props.drivername,
                value: props.drivername.value,
            }),
            customer: Form.createFormField({
                ...props.customer,
                value: props.customer.value || undefined,
            }),
            coloadpartner: Form.createFormField({
                ...props.coloadpartner,
                value: props.coloadpartner.value,
            }),
            trackingnumber: Form.createFormField({
                ...props.trackingnumber,
                value: props.trackingnumber.value,
            }),
            serviceprovider: Form.createFormField({
                ...props.serviceprovider,
                value: props.serviceprovider.value,
            }),
            pickupTime: Form.createFormField({
                ...props.pickupTime,
                value: props.pickupTime.value,
            }),
            vendor: Form.createFormField({
                ...props.vendor,
                value: props.vendor.value,
            }),
            vehiclePic: Form.createFormField({
                ...props.vehiclePic,
                value: props.vehiclePic.value,
            }),
            reverse: Form.createFormField({
                ...props.reverse,
                value: props.reverse.value,
            }),
            assetContactName: Form.createFormField({
                ...props.assetContactName,
                value: props.assetContactName.value,
            }),
            assetDrivertName: Form.createFormField({
                ...props.assetDrivertName,
                value: props.assetDrivertName.value,
            }),
            assetVehicleNumber: Form.createFormField({
                ...props.assetVehicleNumber,
                value: props.assetVehicleNumber.value,
            }),
            assetVehicleType: Form.createFormField({
                ...props.assetVehicleType,
                value: props.assetVehicleType.value,
            }),
            assetCapacityUnits: Form.createFormField({
                ...props.assetCapacityUnits,
                value: props.assetCapacityUnits.value,
            }),
            assetCapacityTons: Form.createFormField({
                ...props.assetCapacityTons,
                value: props.assetCapacityTons.value,
            }),
            assetPhoneNumber: Form.createFormField({
                ...props.assetPhoneNumber,
                value: props.assetPhoneNumber.value,
            }),
            shipper: Form.createFormField({
                ...props.shipper,
                value: props.shipper.value,
            }),
            reasonCategory: Form.createFormField({
                ...props.reasonCategory,
                value: props.reasonCategory.value,
            }),
            reason: Form.createFormField({
                ...props.reason,
                value: props.reason.value,
            }),


        };
    },
    onValuesChange(_, values) {

    },
})(props => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const vendorValue = getFieldValue('triptype');
    const rouuteValue = getFieldValue('route');
    const vehicle = getFieldValue('vehiclenumber');
    let assetType = getFieldValue('assetVehicleType');
    let units = undefined;
    const vehiclePic = getFieldValue('vehiclePic');
    let gpsAvailable = false;
    if (_.hasIn(props, 'assets')) {
        let data = props.assets?.map(asset => {
            if (asset.id === vehicle) {
                if (_.hasIn(asset, 'deviceDetails')) {
                    gpsAvailable = true;
                }
            }
        })
    }




    const labelColLayout = {
        span: 8,
        offset: 1
    };

    const textColLayout = {
        span: 8
    };

    const openAsset = () => {

        props.openAssetModal();
    }



    return (
        <>
            <h3>Trip Details</h3>
            <br />

            <Form.Item wrapperCol={labelColLayout} label={<span className="form-label-text">Dispatched By</span>}>
                {getFieldDecorator('dispatched', {
                    rules: [{
                        required: true,
                        message: 'Field cannot be blank!'
                    }]
                })(
                    <Radio.Group onChange={props.changeDispatched}>
                        {/* <Radio value="DV">Dedicated Vehicles</Radio> */}

                        <Radio value="CL">Co-Loader</Radio>
                    </Radio.Group>,
                )}
            </Form.Item>
            {getFieldValue('dispatched') === 'DV' ?
                <>
                    <Form.Item wrapperCol={labelColLayout} label={<span className="form-label-text">Trip Type</span>}>
                        {getFieldDecorator('triptype', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(
                            <Radio.Group>
                                <Radio value="PLANNED">Planned Trip</Radio>
                                <Radio value="ADHOC">Ad-Hoc</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={labelColLayout} label={<span className="form-label-text">Load Type</span>}>
                        {getFieldDecorator('loadtype', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(
                            <Radio.Group onChange={props.changeLoadType}>
                                <Radio value="PTL">PTL</Radio>
                                <Radio value="FTL">FTL</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    {
                        getFieldValue('loadtype') === 'FTL' ?
                            <>
                                <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Customer</span>}>
                                    {getFieldDecorator('customer', {
                                        rules: [{
                                            required: true,
                                            message: 'Field cannot be blank!'
                                        }]
                                    })(
                                        <Select
                                            showSearch
                                            labelInValue
                                            placeholder="Select Customer"
                                            onSelect={props.selectCustomer}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {props?.customers?.map((customer, key) => (
                                                <Option
                                                    value={customer.id}
                                                    label={customer.name}
                                                    key={customer.id}
                                                >
                                                    {customer.name}
                                                </Option>
                                            ))
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>

                                <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Shipper</span>}>
                                    {getFieldDecorator('shipper', {
                                        rules: [{
                                            required: true,
                                            message: 'Field cannot be blank!'
                                        }]
                                    })(
                                        <Select
                                            showSearch
                                            labelInValue
                                            onSelect={props.selectShipper}
                                            loading={props.shipperLoading}
                                            placeholder="Select Shipper"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {props?.shippers?.mappingBOs?.map((shipper, key) => (
                                                <Option
                                                    value={shipper.id}
                                                    label={shipper.shipperName}
                                                    key={shipper.id}
                                                >
                                                    {shipper.shipperName}
                                                </Option>
                                            ))
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                            </>
                            : <></>

                    }
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Select Route</span>}>
                        {getFieldDecorator('route', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(

                            <Select
                                placeholder="Select Route"
                                showSearch
                                labelInValue
                                onChange={props.onChangeRoute}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {props?.routes?.map((route, key) => (
                                    <Option
                                        label={route.name}
                                        key={route.id}
                                    >
                                        {route.name}
                                    </Option>
                                ))
                                }
                            </Select>,
                        )}
                    </Form.Item>
                    <Divider />
                    <h3>Vehicle Information</h3>
                    <br />
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Vehicle Number</span>}>
                        {getFieldDecorator('vehiclenumber', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(
                            <Select
                                loading={props.assetLoading}
                                showSearch
                                onSelect={props.selectVehicle}
                                optionLabelProp="label"
                                placeholder="Enter Vehicle number"
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        {vendorValue === 'ADHOC' && rouuteValue ? <><Divider style={{ margin: '4px 0' }} />
                                            <div onMouseDown={e => e.preventDefault()} style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }} onClick={openAsset}>

                                                <Button
                                                    style={{ flex: 'none', display: 'block', cursor: 'pointer' }}


                                                >
                                                    Add Asset
                                        </Button>
                                            </div></> : <></>}

                                    </div>
                                )}
                                filterOption={(input, option) => {
                                    return option?.props?.label.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                }

                                }
                            >
                                {getFieldValue('loadtype') === 'FTL' ?
                                    props?.assets?.map((asset, key) => (
                                        <Option
                                            value={asset.id}
                                            label={asset.vehicleNumber}
                                            key={asset.id}
                                        >

                                            <div className="demo-option-label-item">

                                                {asset.vehicleNumber}
                                                <span role="vehicle" className="vehicle-gps" aria-label={asset.vehicleNumber}>
                                                    {_.hasIn(asset, 'deviceDetails') ? 'GPS Available' : <></>}
                                                </span>

                                            </div>
                                        </Option>
                                    )) :
                                    props?.assets?.map((asset, key) => (
                                        <Option
                                            value={asset.assetId}
                                            label={asset.vehicleNumber}
                                            key={asset.assetId}
                                        >

                                            <div className="demo-option-label-item">

                                                {asset.vehicleNumber} <span style={{ float: 'right' }}>{asset?.assetType === 'ADHOC_ASSET' ? <img src={MarketAsset} /> : <img src={NonMarketAsset} />} {asset?.isAvailable && <img src={VehicleAvailable} />}</span>
                                                <span role="vehicle" className="vehicle-gps" aria-label={asset.vehicleNumber}>
                                                    {_.hasIn(asset, 'deviceDetails') ? 'GPS Available' : <></>}
                                                </span>

                                            </div>
                                        </Option>
                                    ))
                                }

                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Billed to Vendor</span>}>
                        {getFieldDecorator('vendor', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }],
                        })(
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                labelInValue
                                onSelect={props.onSelectVendor}
                                placeholder="Select Vendor"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option
                                    value={-1}
                                    label={"Self"}
                                    key={-1}
                                >Self</Option>
                                {props?.vendorData?.map((vendor, key) => (
                                    <Option
                                        value={vendor.id}
                                        label={vendor.name}
                                        key={vendor.id}
                                    >
                                        {vendor.name}
                                    </Option>
                                ))
                                }

                            </Select>
                        )}
                    </Form.Item>
                    {!gpsAvailable ?
                        <Form.Item wrapperCol={labelColLayout} label={<span className="form-label-text">GPS Device</span>}>
                            {getFieldDecorator('gpsdevice', {
                                rules: [{
                                    required: true,
                                    message: 'Field cannot be blank!'
                                }]
                            })(
                                <Radio.Group onChange={props.changeGPSTracking}>
                                    <Radio value="pgps">Portable GPS</Radio>
                                    <Radio value="sim">Sim Tracking</Radio>
                                    <Radio value="nodevice">No device</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item> : <></>
                    }

                    {!gpsAvailable && getFieldValue('gpsdevice') === 'pgps' ?
                        <>
                            <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">GPS ID</span>}>
                                {getFieldDecorator('gpsid', {
                                    rules: [{ validator: props.handleIMEI }]
                                })(
                                    <Input
                                        placeholder="Enter GPS ID"
                                        addonAfter={<img src={scannerIcon} />}
                                    />
                                )}
                            </Form.Item>
                            <InputGroup>
                                <Row gutter={2}>
                                    <Col span={8}>
                                        <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Mobile Number</span>}>
                                            {getFieldDecorator('mobilenumber')(
                                                <Input
                                                    placeholder="Enter Mobile number"
                                                />

                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} offset={1}>
                                        <Form.Item wrapperCol={{ span: 18 }} label={<span className="form-label-text">Driver Name</span>}>
                                            {getFieldDecorator('drivername')(
                                                <Input
                                                    placeholder="Enter driver name"
                                                />

                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </> :
                        getFieldValue('gpsdevice') === 'nodevice' ?
                            <>

                                <InputGroup>
                                    <Row gutter={2}>
                                        <Col span={8}>
                                            <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Reason Category</span>}>
                                                {getFieldDecorator('reasonCategory', {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Field cannot be blank!'
                                                    }]
                                                })(
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        showSearch
                                                        labelInValue
                                                        onSelect={props.onSelectReasonCategory}
                                                        placeholder="Select Reason Category"
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >


                                                        {props?.reasonCategories?.map((vendor, key) => (
                                                            <Option
                                                                value={vendor.id}
                                                                label={vendor.name}
                                                                key={vendor.id}
                                                            >
                                                                {vendor.name}
                                                            </Option>
                                                        ))
                                                        }

                                                    </Select>

                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} offset={1}>
                                            <Form.Item wrapperCol={{ span: 18 }} label={<span className="form-label-text">Reason</span>}>
                                                {getFieldDecorator('reason', {
                                                    rules: [{
                                                        required: true,
                                                        message: 'Field cannot be blank!'
                                                    }]
                                                })(
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        showSearch
                                                        labelInValue
                                                        placeholder="Select Reason"
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >


                                                        {props?.reasons?.map((vendor, key) => (
                                                            <Option
                                                                value={vendor.reasonId}
                                                                label={vendor.reasonDescription}
                                                                key={vendor.reasonId}
                                                            >
                                                                {vendor.reasonDescription}
                                                            </Option>
                                                        ))
                                                        }

                                                    </Select>

                                                )}
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={12} offset={1}>
                                            <Form.Item wrapperCol={{ span: 18 }} label={<span className="form-label-text">Driver Name</span>}>
                                                {getFieldDecorator('drivername')(
                                                    <Input
                                                        placeholder="Enter driver name"
                                                    />

                                                )}
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                </InputGroup>
                                {/* <InputGroup>
                                    <Row gutter={2}>
                                        <Col span={8}>
                                            <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Mobile Number</span>}>
                                                {getFieldDecorator('mobilenumber')(
                                                    <Input
                                                        placeholder="Enter Mobile number"
                                                    />

                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} offset={1}>
                                            <Form.Item wrapperCol={{ span: 18 }} label={<span className="form-label-text">Driver Name</span>}>
                                                {getFieldDecorator('drivername')(
                                                    <Input
                                                        placeholder="Enter driver name"
                                                    />

                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </InputGroup> */}
                            </> :
                            <>
                                <InputGroup>
                                    <Row gutter={2}>
                                        <Col span={8}>
                                            <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Driver Name</span>}>
                                                {getFieldDecorator('drivername')(
                                                    <Input
                                                        placeholder="Enter Driver Name"
                                                    />

                                                )}
                                            </Form.Item>
                                        </Col>
                                        {!gpsAvailable ? <Col span={9} offset={1}>
                                            <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Service Provider</span>}>
                                                {getFieldDecorator('serviceprovider')(
                                                    <Select
                                                        showSearch
                                                        labelInValue
                                                        style={{ width: '100%' }}
                                                        onSelect={props.selectServiceProvider}
                                                        placeholder="Enter Service Provider"
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {props.sproviders && props?.sproviders?.map((provider, key) => (
                                                            <Option
                                                                value={provider.id}
                                                                label={provider.providerName}
                                                                key={provider.id}
                                                            >
                                                                {provider.providerName}
                                                            </Option>
                                                        ))}
                                                    </Select>,

                                                )}
                                            </Form.Item>
                                        </Col> : <></>}

                                    </Row>
                                </InputGroup>
                                <InputGroup>
                                    <Row gutter={2}>
                                        <Col span={8}>
                                            <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Mobile Number</span>}>
                                                {getFieldDecorator('mobilenumber')(
                                                    <Input
                                                        placeholder="Enter Mobile Number"
                                                    />

                                                )}
                                            </Form.Item>
                                        </Col>
                                        {!gpsAvailable ? <Col span={12} offset={1}>
                                            <Form.Item wrapperCol={{ span: 18 }} label={<span className="form-label-text">Tracking Mobile Number</span>}>
                                                {getFieldDecorator('trackingnumber')(
                                                    <Input
                                                        placeholder="Enter Mobile Number"
                                                    />

                                                )}
                                            </Form.Item>
                                        </Col> : <></>}

                                    </Row>
                                </InputGroup>

                            </>
                    }


                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Date and time of Dispatch</span>}>
                        {getFieldDecorator('pickupTime', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }],
                        })(
                            <DatePicker
                                showTime
                                disabledDate={props.disabledDate}
                                onChange={props.changePickupTime}
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item>





                </>
                :
                <>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Co loader Partner</span>}>
                        {getFieldDecorator('coloadpartner', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(
                            <Select
                                showSearch
                                onSelect={props.selectPartner}
                                placeholder="Co loader-Partner"
                                filterOption={(input, option) =>
                                    option.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }
                            >
                                {props?.partners?.map((partner, key) => (
                                    <Option
                                        value={partner.linkPartnerBO.id}
                                        label={partner.linkPartnerBO.name}
                                        key={partner.linkPartnerBO.id}
                                    >
                                        {partner.linkPartnerBO.name}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Destination Location</span>}>
                        {getFieldDecorator('destination', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(
                            <Select
                                showSearch
                                loading={props.coloaderloading}
                                placeholder="Select Destination Location"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {props?.coloaders?.map((coloader, key) => (
                                    <Option
                                        value={coloader.id}
                                        label={coloader.name}
                                        key={coloader.id}
                                    >
                                        {coloader.name}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Chargeable Pcs</span>}>
                        {getFieldDecorator('pcs', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }]
                        })(
                            <InputNumber
                                placeholder={'Chargeable Pcs'}
                                style={{ width: 300 }}
                            />

                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Date and time of handOver</span>}>
                        {getFieldDecorator('pickupTime', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }],
                        })(
                            <DatePicker
                                showTime
                                disabledDate={props.disabledDate}
                                onChange={props.changePickupTime}
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Reverse</span>}>
                        {getFieldDecorator('reverse', {
                            rules: [{
                                required: false,
                                message: 'Field cannot be blank!'
                            }],
                        })(
                            <Switch onChange={props.onChangeReverse} />
                        )}
                    </Form.Item>
                    <Form.Item wrapperCol={textColLayout} label={<span className="form-label-text">Photo</span>}>
                        {getFieldDecorator('vehiclePic', {
                            rules: [{
                                required: true,
                                message: 'Field cannot be blank!'
                            }],
                        })(
                            <FileUpload
                                fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                directUpload={true}
                                initialFile={vehiclePic}
                                disableUploadButton={false}
                                onUploadFinish={($events) => props.onUploadFinish($events, 'vehiclePic')}
                                path="trip"
                                acceptTypes={[".jpg", ".jpeg", ".png"]}
                                objKey="trips"
                            />
                        )}
                        <div>
                            {vehiclePic &&
                                <span>File Uploaded!</span>
                            }
                        </div>
                    </Form.Item>

                </>
            }

            <Modal
                title="Asset"
                width={400}
                visible={props.showAssetsModal}
                style={{ top: 20 }}
                onCancel={props.cancelAssetModal}
                footer={[
                    <Button key="submitAsset" type="primary" onClick={props.submitAsset}>
                        Create Asset
                            </Button>,
                    <Button key="cancelAsset" type="default" onClick={props.cancelAssetModal}>
                        Cancel
                    </Button>
                ]}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Owner</span>}>
                            {getFieldDecorator('vendor', {
                                rules: [{
                                    required: false,
                                    message: 'Field cannot be blank!'
                                }],
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    labelInValue
                                    onSelect={props.onSelectVendor}
                                    placeholder="Select Vendor"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props?.vendorData?.map((vendor, key) => (
                                        <Option
                                            value={vendor.id}
                                            label={vendor.name}
                                            key={vendor.id}
                                        >
                                            {vendor.name}
                                        </Option>
                                    ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Contact Name</span>}>
                            {getFieldDecorator('assetContactName', {
                                rules: [{
                                    required: false,
                                    message: 'Field cannot be blank!'
                                }],
                            })(
                                <Input placeholder="Enter Owner Name" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item wrapperCol={{ span: 24 }} label={<span className="form-label-text">Driver Name</span>}>
                            {getFieldDecorator('assetDrivertName', {
                                rules: [{
                                    required: false,
                                    message: 'Field cannot be blank!'
                                }],
                            })(
                                <Input placeholder="Enter Driver Name" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Vehicle Number</span>}>
                            {getFieldDecorator('assetVehicleNumber', {
                                rules: [{
                                    required: false,
                                    message: 'Field cannot be blank!'
                                }],
                            })(
                                <Input placeholder="Enter Vehicle Number" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Vehicle Type</span>}>
                            {getFieldDecorator('assetVehicleType', {
                                rules: [{
                                    required: false,
                                    message: 'Field cannot be blank!'
                                }],
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    onSelect={props.onSelectVehicleType}
                                    placeholder="Select Vehicle Type"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {props?.appConfig.assetTypes?.map((vehicle, key) => (
                                        <Option
                                            value={vehicle.vehicleType}
                                            label={vehicle.vehicleType}
                                            key={key}
                                        >
                                            {vehicle.vehicleType}
                                        </Option>
                                    ))
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                        <InputGroup>
                            <Row gutter={2}>
                                <Col span={8}>
                                    <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Units</span>}>
                                        {getFieldDecorator('assetCapacityUnits')(
                                            <Input
                                                placeholder="Units"
                                                disabled
                                            />

                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={2}>
                                    <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Ton</span>}>
                                        {getFieldDecorator('assetCapacityTons')(
                                            <Input
                                                placeholder="Ton"
                                                disabled
                                            />

                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col> */}
                    <Col span={24}>
                        <Form.Item className="asset-form-item" wrapperCol={{ span: 24 }} label={<span className="form-label-text">Phone Number</span>}>
                            {getFieldDecorator('assetPhoneNumber', {
                                rules: [{
                                    required: false,
                                    message: 'Field cannot be blank!'
                                }, {
                                    len: 10,
                                    message: 'Invalid phone number!'
                                }
                                ],
                            })(
                                <Input placeholder="Enter Phone Number" />
                            )}
                        </Form.Item>
                    </Col>

                </Row>
            </Modal>
        </>
    );
});

export default TripCreateStep1;
