const schema = {
    "type"  : "object",
    "properties": {
        "fuelType": {
            "type"      : "string",
            "title"     : "Fuel type",
            "enum"      : ["PETROL", "DIESEL"],
            "enumNames" : ["Petrol", "Diesel"]
        },
        "fuelThresholdMin": {
            "type"      : "number",
            "title"     : "Threshold Price Minimum"
        },
        "fuelThresholdMax": {
            "type"      : "number",
            "title"     : "Threshold Price Maximum"
        },
        "fuelPrice": {
            "type"      : "number",
            "title"     : "Contracted Fuel Price"
        },
        "frequencyToCheck": {
            "type"      : "string",
            "title"     : "Check Frequency",
            "enum"      : [ "DAILY", "WEEKLY", "BI_WEEKLY", "MONTHLY", "QUARTERLY" ],
            "enumNames" : [ "Daily", "Weekly", "Bi-Weekly", "Monthly", "Quarterly" ]
        },
        "fuelPriceFetchRule": {
            "type"      : "string",
            "title"     : "Fetch Rule",
            "enum"      : [ "LOWEST", "GREATEST", "AVERAGE" ],
            "enumNames" : [ "Minimum", "Maximum", "Average" ]
        },
        "sourceLocationType": {
            "type"      : "string",
            "title"     : "Location Type",
            "enum"      : [ "CITY", "STATE" ],
            "enumNames" : [ "City", "State" ]
        },
        "selectSourceLocations": {
            "type"      : "string",
            "title"     : "Locations",
            "multiple"  : true,
            "allowExternalClear"  : true
        },
        "rule"  : {
            "type"      : "string",
            "title"     : "Calculation Rule",
            "maxLength" : 230

        },
        "lastFuelFetchPrice": {
            "type"      : "number",
            "title"     : "Current Fuel Price"
        },
        "updatedAt": {
            "type" : "number",
            "title": "Last Updated At"
        }
    }
}

const uiSchema = {
    "classNames": "hide-title multi-row-field-objects",
    "fuelType": {
        "ui:placeholder"    : "Select Fuel Type",
        "ui:widget"         : "select"
    },
    "fuelThresholdMin": {
        "ui:placeholder"    : "Enter minimum price",
        "type"              : "number",
        "classNames"        : "hide-spinners",
        "ui:suffix"         : "Rs"
    },
    "fuelThresholdMax": {
        "ui:placeholder"    : "Enter maximum price",
        "type"              : "number",
        "classNames"        : "hide-spinners",
        "ui:suffix"         : "Rs"
    },
    "fuelPrice": {
        "ui:placeholder"    : "Enter contracted fuel price",
        "type"              : "number",
        "classNames"        : "hide-spinners",
        "ui:suffix"         : "Rs"
    },
    "frequencyToCheck": {
        "ui:placeholder"    : "Select Check Frequency",
        "ui:widget"         : "select"
    },
    "fuelPriceFetchRule": {
        "ui:placeholder"    : "Select Fetch Rule",
        "ui:widget"         : "select"
    },
    "sourceLocationType": {
        "ui:placeholder"    : "Select Location Type",
        "ui:widget"         : "select"
    },
    "selectSourceLocations" : {
        "ui:placeholder"    : "Select Locations",
        "ui:widget"         : "SelectAPI",
        "labelInValue"      : true,
        "dropdownPlaceholder" : "Enter minimum 3 chars to search"
    },
    "rule"  : {
        "ui:placeholder"    : "Enter Calculation Rule",
        "maxLength"         : 230
    },
    "lastFuelFetchPrice": {
        "ui:placeholder"    : "",
        "type"              : "number",
        "classNames"        : "hide-spinners",
        "ui:disabled"       : true,
        "ui:readOnly"       : false
    },
    "updatedAt": {
        "ui:widget"         : "DateWidget",
        "ui:format"         : "DD/MM/YYYY HH:mm A",
        "ui:disabled"       : true,
        "ui:readOnly"       : false
    }
}

const errorList = {
    "fuelType" : {
        "required" : "Fuel Type is mandatory!"
    },
    "fuelThresholdMin"  : {
        "required" : "Threshold Price Minimum is mandatory!"
    },
    "fuelThresholdMax"  : {
        "required" : "Threshold Price Maximum is mandatory!"
    },
    "fuelPrice"  : {
        "required" : "Contracted Fuel Price is mandatory!"
    },
    "frequencyToCheck"  : {
        "required" : "Check Frequency is mandatory!"
    },
    "fuelPriceFetchRule"  : {
        "required" : "Fetch Rule is mandatory!"
    },
    "sourceLocationType"  : {
        "required" : "Location Type is mandatory!"
    },
    "selectSourceLocations"  : {
        "required" : "Location(s) are mandatory!"
    },
    "rule" : {
        "required" : "Calculation Rule is mandatory!"
    }
}
export default { schema, uiSchema, errorList };