import React, { useState, createRef } from 'react';
import CustomeLocationDetails from './location.form';
import LocationList from './location.list';
import { permissionTags } from '../../../../configs/permissionTags';
import { canAllow } from '../../../../common/utils/utils';
import { Modal } from 'antd';
import _ from 'lodash';
const { confirm } = Modal;
function Locations(props) {
    const { customerId, setLocationStepper } = props;
    const [locationDetails, SetLocationDetails] = useState(false);
    const [openModal, setModal] = useState(false);
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);
    const [refresh, setRefresh] = useState(false);
    const [createAnother, setCreateAnother] = useState(false);
    const locationRef = createRef();
    const [isCancel, setCancel] = useState(false);
    const [draggable, setDraggable] = useState(false);
    const locationPermission = {

        locationsCreate: canAllow(permissionTags.contract.locations.create),
        locationsUpdate: canAllow(permissionTags.contract.locations.update),

    }
    return (
        <>
            { openModal &&
                <CustomeLocationDetails
                    {...locationDetails}
                    openModal={openModal}
                    setModal={setModal}
                    customer={props.customer}
                    setCancel={setCancel}
                    isCancel={isCancel}
                    goBack={() => {

                        if (isCancel) {
                            confirm({
                                title: 'Are you sure to cancel?',
                                okText: 'Yes',
                                cancelText: 'No',
                                onOk: () => {
                                    SetLocationDetails(false);
                                    setReadOnly(true);
                                    setModal(false);
                                    setCancel(false);
                                    setCreateAnother(false);
                                }
                            })
                        } else {
                            SetLocationDetails(false);
                            setReadOnly(true);
                            setModal(false);
                            setCancel(false);
                            setCreateAnother(false);
                        }


                    }}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                    locationRef={locationRef}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    locationPermission={locationPermission}
                    createAnother={createAnother}
                    setCreateAnother={setCreateAnother}
                    openHelpLink={props.openHelpLink}
                    draggable={draggable}
                    setDraggable={setDraggable}
                />
            }
            <LocationList
                setLocationStepper={setLocationStepper}
                customerId={customerId}
                showLocationDetails={locationDetails => SetLocationDetails(locationDetails)}
                setModal={setModal}
                setDraggable={setDraggable}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                refresh={refresh}
                locationPermission={locationPermission}
            />

        </>
    )
}

export default Locations;