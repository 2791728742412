import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Divider, Descriptions, Input, Alert, Icon, Button, Col, Row } from 'antd';
import { notifyApiError } from '../../../common/utils/utils';

export class ScanDocketBarcode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalCount: 0,
            barcode: undefined,
            selectedConsigns: [...this.props.selectedDockets].map(con => ({ ...con, scannedBarcodeNo: 0 })),
            barcodes: [],
            shipmentScans: []
        }
        this.props.changeButtonDisabled(true);
    }

    changeBarcode = (e) => {
        this.setState({ barcode: e.target.value })
    }

    onCloseShipment = () => {
        this.setState({ shipmentError: undefined });
    }

    submitBarcode = () => {
        const barcodeErr = []
        console.log(this.state.barcode, this.state.selectedDockets, this.state.barcodes, this.state.barcode, this.state.barcodes.indexOf(this.state.barcode));
        if (this.state.barcodes.indexOf(this.state.barcode) >= 0) {
            notifyApiError('Barcode already have scanned', 'Error:')
            return;
        } else {
            this.state.selectedConsigns.map((consigns) => {
                let scanBarcodes = consigns ?.scannedBarcodeNo ? consigns.scannedBarcodeNo : 0
                consigns.shipmentBOs.map(consign => {
                    consign.barcodes.map(barcode => {
                        if (this.state.barcode === barcode.barcode) {
                            barcodeErr.push(true)
                            ++scanBarcodes;
                            this.props.changeShipmentScansCount(1)
                            this.setState(prevState => ({
                                selectedConsigns: [...prevState.selectedConsigns].map(c => (c.waybillNo === consigns.waybillNo ? { ...consigns, scannedBarcodeNo: scanBarcodes } : c)),
                                barcodes: [...prevState.barcodes, this.state.barcode],
                                shipmentError: '',
                                shipmentScans: [...prevState.shipmentScans, {
                                    "shipmentCodeType": "BARCODE",
                                    "scanInputType": "BARCODE_SCANNER",
                                    "shipmentCode": this.state.barcode,
                                    "shipmentId": consign.id,
                                    "entityType": "TRIP",
                                    "consignmentId": consigns.id,
                                    "scanType": "TRIP"
                                }]
                            }), () => {
                                if (this.state.barcodes.length === this.props.totalShipmentPcs) {
                                    this.setState({ scanDisabled: false })
                                    this.props.changeButtonDisabled(false);
                                    console.log("this.state->>", this.state.shipmentScans);
                                    this.props.onChangeShipmentScans(this.state.shipmentScans);
                                    // this.props.changeShipmentScansCount(scanBarcodes)
                                    // this.setState({ [filter]: false })
                                }
                            });
                            // this.setState({selectedConsigns: [consigns]});
                        } else {
                            barcodeErr.push(false)
                            // this.setState({shipmentError: 'Invalid BarCode'})
                        }
                    })
                })
            })

            if(!barcodeErr.includes(true)) {
                notifyApiError('No such barcode found', 'Error:');
            }
        }

    }

    skipScan = () => {
        this.props.changeButtonDisabled(false);
        this.props.onChangeShipmentScans(this.state.shipmentScans);
        this.props.onSkip()
    }

    render() {
        return (
            <>
                <Descriptions layout="vertical" size="small" colon={false} column={{ xs: 24, sm: 16, md: 6 }}>
                    <Descriptions.Item
                        className="trip-descriptions-item"
                        label={<small className="trip-description-small">Selected Dockets</small>}>
                        <strong>{this.props.selectedDockets.length}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item
                        className="trip-descriptions-item"
                        label={<small className="trip-description-small">Quantity</small>}>
                        <strong>{this.props.totalPcs} Pcs ({this.props.totalKgs?.toFixed(2)} Kgs)</strong>
                    </Descriptions.Item>
                    {this.props.fields.dispatched.value == 'DV' ?
                        <Descriptions.Item
                            className="trip-descriptions-item"
                            label={<small className="trip-description-small">Route</small>}>
                            <strong>{this.props.fields.route.value.label}</strong>
                        </Descriptions.Item> : <></>
                    }
                </Descriptions>
                <Divider />
                <Row gutter={4}>
                    <Col span={14}>
                        <Input
                            onChange={this.changeBarcode}
                            value={this.state.barcode}
                            onPressEnter={this.submitBarcode}
                            suffix={<Icon type="scan" />}
                            style={{ width: '100%' }}
                        />
                        <p>Use Barcode Scanner for faster process or enter manually</p>
                    </Col>
                    <Col span={5}>
                        <Button onClick={this.skipScan} type="primary" >Skip</Button>
                    </Col>
                </Row>
                {this.state.shipmentError ?
                    <Alert
                        style={{ width: '100%' }}
                        message={this.state.shipmentError}
                        type="warning"
                        closable
                        onClose={this.onCloseShipment}
                    /> : <></>
                }
                <br />

                <div>
                    {/* <Button type="primary" disabled={this.state.scanDisabled} onClick={() => this.submitShipmentScans("visibleScan")}>Submit</Button> */}
                </div>
                <Divider />
                {
                    this.state.selectedConsigns.length > 0 ?
                        <>
                            <Row gutter={[8, 8]}>
                                <Col span={4}>Docket ID</Col>
                                <Col span={4}>Route</Col>
                                <Col span={4}>Customer</Col>
                                <Col span={4}>Qunatity</Col>
                                <Col span={4}>Created User</Col>
                                <Col span={4}>CPT (Customer Promise Time)</Col>
                            </Row>

                            {
                                this.state.selectedConsigns.length >= 0 && this.state.selectedConsigns.map((consign, index) => (
                                    <>
                                        <Row gutter={[8, 16]}>
                                            <Col span={4}>{consign?.waybillNo}</Col>
                                            <Col span={4}>{consign?.city?.origin} <Icon type="caret-right" /> {consign?.city?.destination}</Col>
                                            <Col span={4}>{consign?.customerName?.name}</Col>
                                            <Col span={4}>{consign?.scannedBarcodeNo}/{consign?.totalShipmentCount}</Col>
                                            <Col span={4}>{consign?.userName}</Col>
                                            <Col span={4}>{consign?.updatedAtCPT}</Col>
                                        </Row>
                                        <Divider />
                                    </>
                                ))
                            }
                        </> : <></>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ScanDocketBarcode)
