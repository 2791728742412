const schema = {
    "type"  : "object",
    "properties": {
        "shareOfBusiness" : {
            "type"      : "object",
            "title"     : "Share of Business",
            "required"      : [ "shareValue" ],
            "properties"    : {
                "businessCycle" : {
                    "type"      : "string",
                    "enum"      : ['DAILY', 'WEEKLY', 'BI-WEEKLY', 'MONTHLY', 'QUARTERLY', 'HALF-YEARLY', 'YEARLY'],
                    "enumNames" : ['Daily', 'Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Half-Yearly', 'Yearly']
                },
                "shareValue"    : {
                    "type"             : "integer",
                    "exclusiveMinimum" : 0
                },
                "basis"    : {
                    "type"      : "string",
                    "enum"      : ["KG", "PIECE", "CFT", "INDENT"],
                    "enumNames" : ["Kg", "Piece", "CFT", "Trip"]
                }
            }
        },
        "slaRuleBOs" : {
            "type"      : "array",
            "title"     : "SLA Rule",
            "items"     : {
                "required"      : ["fromTime", "toTime", "placementSlaDay", "placementSlaTime"],
                "properties"    : {
                    "fromTime" : {
                        "type"  : "number",
                        "title" : "Indent Time"
                    },
                    "toTime"    : {
                        "type"  : "number"
                    },
                    "placementSlaDay" : {
                        "type"      : "integer",
                        "title"     : "Placement SLA",
                        "minimum"   : 0
                    },
                    "placementSlaTime"    : {
                        "type"  : "number",
                        "title" : "Placement Time - Hour(s)"
                    }
                }
            }
        },
        "penalties" : {
            "type"      : "object",
            "title"     : "Penalties",
            "required"      : ["nonPlacementPenalty", "delayedPenalty"],
            "properties"    : {
                "nonPlacementPenalty" : {
                    "type"      : "string",
                    "title"     : "Non Placement"
                },
                "delayedPenalty"    : {
                    "type"      : "string",
                    "title"     : "Delayed Placement"
                }
            }
        }
    }
}

const uiSchema = {
    "shareOfBusiness"   : {
        "classNames": "multi-row-field-objects",
        "businessCycle" : {
            "ui:placeholder"    : "Select Cycle",
            "classNames"        : "hide-title"
        },
        "shareValue"    : {
            "ui:placeholder"    : "Enter Share Value",
            "classNames"        : "hide-title",
            "type"              : "number"
        },
        "basis"    : {
            "ui:placeholder"    : "Select Basis",
            "classNames"        : "hide-title"
        }
    },
    "slaRuleBOs"           : {
        "classNames"    : "array-columns",
        "ui:options": {
            "orderable" : false
        },
        "items"         : {
            "classNames" : "flex-1",
            "fromTime"    : {
                "classNames" : "flex-column flex-1",
                "ui:widget"  : "TimePickerWidget",
                "ui:placeholder" : "Select From Time"
            },
            "toTime" : {
                "classNames" : "flex-column flex-1 hide-label",
                "ui:widget"  : "TimePickerWidget",
                "ui:placeholder" : "Select To Time"
            },
            "placementSlaDay" : {
                "classNames"     : "flex-column flex-1 hide-spinners",
                "ui:placeholder" : "Day(s) till placement",
                "ui:suffix"      : "Day(s)",
                "ui:type"        : "number",
                "ui:min"         : 0
            },
            "placementSlaTime" : {
                "classNames" : "flex-column flex-1 hide-label",
                "ui:widget"  : "TimePickerWidget"
            }
        }
    },
    "penalties" : {
        "nonPlacementPenalty" : {
            "classNames"        : "flex-1",
            "ui:placeholder"    : "Enter Non-Placement Penalty"
        },
        "delayedPenalty"    : {
            "classNames"        : "flex-1",
            "ui:placeholder"    : "Enter Delayed Placement Penalty"
        }
    }
}

const errorList = {
    shareOfBusiness : {
        shareValue : {
            required         : "Share value cannot be empty!",
            exclusiveMinimum : "Share value must be more than 0",
            type             : "Share value must be a whole number!"
        }
    },
    slaRuleBOs : {
        fromTime: {
            required  : "Indent from time cannot be empty!"
        },
        toTime : {
            required  : "Indent to time cannot be empty!"
        },
        placementSlaTime : {
            required  : "SLA Time cannot be empty!"
        }
    },
    penalties : {
        nonPlacementPenalty        : {
            required  : "Non Placement penalty cannot be empty!",
            format    : "Non Placement penalty must be a alpha numeric string"
        },
        delayedPenalty    : {
            required  : "Delayed Placement penalty cannot be empty!",
            format    : "Delayed Placement penalty must be a alpha numeric string"
        }
    }
}

export default { schema, uiSchema, errorList };