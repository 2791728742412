import "./Tracking.scss";
import React, { Component } from "react";
import { Card, Icon, Select, Row, Col } from "antd";
import { typography as Typography } from "../ui/typography/Typography";
import Radio from "../ui/radio/radio";
import { generateID } from "../../common/utils/utils";
import { input as Input, InputApi } from "../ui/input/input";
import { button as Button } from "../ui/button/Button";
import TrackingListing from "./TrackingListing/TrackingListing";
import * as actions from "./action";
import { connect } from "react-redux";
import TrackingIcon from "../../assets/img/icons/4_w.svg";
import TrackingSpinner from "../../assets/img/icons/ripple.svg";
import { Constants } from "../../common/utils/constants";
import * as utils from "../../common/utils/utils";

const { TextArea } = InputApi;

const trackByValues = [
  {
    id: generateID(),
    label: "WayBill / Docket No.",
    value: "waybills"
  },
  {
    id: generateID(),
    label: "Manifest No",
    value: "manifests"
  }
];
if (Constants.SELECTED_APPLICATION === "B2C") {
  trackByValues.push({
    id: generateID(),
    label: "Consignee Phone No.",
    value: "consigneeNumber"
  });
}

class Tracking extends Component {
  state = {
    searchType: trackByValues[0].value,
    inputData: [],
    inputPlaceholder: "Enter Waybill No./Docket No."
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.consignments &&
      this.props.consignments !== prevProps.consignments
    ) {
      if (this.props.consignments.length === 0) {
        utils.notifyApiError(
          `No Tracking details found for ${this.state.inputData}`,
          "Data not found"
        );
      }
    }
  }
  onRadioChange = event => {
    const trackingType = trackByValues.find(type => {
      return type.value === event.target.value;
    });
    const nextState = {
      searchType: event.target.value
    };
    if (trackingType) {
      nextState["inputPlaceholder"] = `Enter ${trackingType.label}`;
    }
    this.setState(nextState);
  };
  onTrackClick = () => {
    if (!this.state.inputData.trim()) {
      return;
    }
    const waybillsInput = utils.trimEachWaybill(this.state.inputData);
    if(this.state.searchType === "manifests"){
      this.props.getManifestDetails({
        manifestCodes: waybillsInput,
      });
    } else {
      this.props.getWaybillDetails({
        input: waybillsInput,
        searchType: this.state.searchType
      });
    }
  };
  onInputChange = event => {
    this.setState({
      inputData: event.target.value
    });
  };
  // onTrackClick = () => {
  //   //   if (this.state.inputData > 0) {
  //   //     return;
  //   //   }
  //   // const waybillsInput = utils.trimEachWaybill(this.state.inputData[0]);
  //   this.props.getWaybillDetails({
  //     input: this.state.inputData,
  //     searchType: this.state.searchType
  //   });
  // };
  // onInputChange = inputData => {
  //   let waybillsInput = []
  //   inputData.map(item => {
  //     waybillsInput = [...waybillsInput, ...utils.trimEachWaybill(item)]
  //   })
  //   this.setState({
  //     inputData: waybillsInput.filter((value, index, self) => (self.indexOf(value) === index))
  //   });
  // };
  render() {
    return (
      <div className="Tracking">
        <Card bordered={false} className="Card gutter">
          <Typography
            styles={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "26px"
            }}
          >
            Enter your waybills & track
          </Typography>
          <div className="flex justify-space-around gutter">
            <Radio
              values={trackByValues}
              onChange={this.onRadioChange}
              defaultValue={this.state.searchType}
            />
          </div>
          <div className="flex justify-space-around">
            <div className="width-45">
              <Row gutter={[12, 12]}>
                <Col span={18}>
                  <TextArea
                  placeholder={this.state.inputPlaceholder}
                  autosize
                  className="waybills-input"
                  style={{width: "100%"}}
                  value={this.state.inputData}
                  onChange={this.onInputChange}
                />
                  {/* <Select value={this.state.inputData} mode="tags" style={{ width: '100%', maxHeight: 100, overflowY: 'scroll' }} placeholder={this.state.inputPlaceholder} onChange={this.onInputChange}>
                  </Select> */}
                </Col>
                <Col span={1}>
                  <Button
                    onClick={this.onTrackClick}
                    className="tracking-primary-btn"
                  >
                    {" "}
                    <img
                      src={
                        this.props.loadingWaybills ? TrackingSpinner : TrackingIcon
                      }
                      className="track-icon"
                      alt="track waybill"
                    />{" "}
                Track
              </Button>
                </Col>
              </Row>
            </div>
          </div>
          <div className="flex justify-space-around smallMessage gutter">
            <Typography className="small-text">
              You can track multiple values with comma ( , ) or adding next line
            </Typography>
          </div>
        </Card>
        <TrackingListing
          tableData={this.props.consignments}
          selectedRadioValue={this.state.searchType}
          inputData={this.state.inputData}
          tableDataShallowCopy={this.props.consignments?.slice()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  consignments: state.tracking.consignments,
  loadingWaybills: state.tracking.loadingWaybills
});
const mapDispatchToProps = dispatch => ({
  getWaybillDetails: params => dispatch(actions.getWaybillDetails(params)),
  getWaybillTrackingDetails: params =>
    dispatch(actions.getWaybillTrackingDetails(params)),
  getManifestDetails: payload => dispatch(actions.getManifestDetails(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tracking);
