import React, { Component } from 'react'
import moment from 'moment';
import { Form, Input, Icon, DatePicker, Button, InputNumber, Spin, Row, Col, Radio } from 'antd';
import FileUpload from '../../../ui/file-upload/file.upload';
import RadioButton from '../../../ui/radio/radio';
import { FILTER_INPUT_TYPE, INPUT_TYPE, UPLOAD_BUTTON_TYPE } from '../../../../common/utils/constants';
import { downloadFile, notifyApiError, checkForDownloadReportStatus, notifyApiSuccess } from '../../../../common/utils/utils';

export class SettleInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileAcceptTypes: [".jpg", ".png", ".jpeg", ".pdf"],
            remittanceAmount: '',
            "dateOfPayment": moment(new Date(), 'DD/MM/YYYY'),
            "paymentMode": this.getPaymentTypes()[0].value,
            referenceNumber: '',
            bankName: '',
            bankRemarks: '',
            uploadedFileUrl: '',
            downloading: false
        }
    }
    componentDidUpdate(prevProp) {
        if (prevProp.downloadStatus !== this.props.downloadStatus) {
            switch (this.props.downloadStatus) {
                case 'BEGIN':
                    this.setState({
                        downloading: true
                    }); break;
                case 'SUCCESS':
                    this.setState({
                        downloading: false
                    }, () => {
                        notifyApiSuccess('File downloaded successfully.', 'SUCCESS');
                        this.props.closeModal()
                    });
                    break;
                case 'FAILURE':
                    this.setState({
                        downloading: false
                    }, () => {
                        notifyApiError('Something went wrong. Please retry.', 'ERROR:')
                        this.props.closeModal()
                    });

            }
            if (this.props.downloadStatus === 'SUCCESS') {
            }
        }
        if (prevProp.debitNoteLink !== this.props.debitNoteLink) {
            if (!this.props.debitNoteLink.sync) {
                notifyApiError(this.props.debitNoteLink.message, 'ERROR');
            } else {
                const reportData = {
                    's3Url': this.props.debitNoteLink.responseUrl,
                    'status': true
                };

                localStorage.setItem(
                    "downloadReportInitiated",
                    JSON.stringify(reportData)
                );
                const prop = {
                    setS3SyncStatus: this.props.setS3SyncStatus,
                    uploadedFileResp: {
                        responseUrl: this.props.debitNoteLink.responseUrl
                    }
                }
                checkForDownloadReportStatus(prop);
                // downloadFile(this.props.debitNoteLink.responseUrl, '_blank')
            }
        }
    }
    getPaymentTypes() {
        return [
            {
                id: 1,
                value: "Cash",
                label: "Cash"
            },
            {
                id: 3,
                value: "NEFT/RTGS",
                label: "NEFT/RTGS"
            },
            {
                id: 4,
                value: "Cheque",
                label: "Cheque"
            },
            {
                id: 5,
                value: "Other",
                label: "Other"
            }
        ];
    }
    changeRemittanceAmount = (event) => {
        this.setState({
            remittanceAmount: event.target.value,
        });
    }
    onChangeValue = (type, val) => {
        this.setState({
            [type]: val
        })
    }
    settleInvoice = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    invoiceTransactionId: this.props.selectedPayment.invoiceTransactionId,
                    invoiceId: this.props?.data?.id,
                    'remittingAmount': values.remittanceAmount,
                    'paymentDate': values.dateOfPayment.valueOf(),
                    paymentMode: values.paymentMode,
                    referenceNo: values.referenceNumber,
                    imageProof: values.uploadedFileUrl,
                    bankName: values.bankName,
                    remarks: values.bankRemarks
                };
                this.props.settleInvoice(payload)
            }
        })
        // const cost = this.props?.data?.paidAmount ? (this.props?.data?.totalAmount - this.props?.data?.paidAmount) : this.props?.data?.totalAmount;
        // if (!this.state.paymentMode) {
        //     notifyApiError('Payment mode is required');
        //     return
        // }
        // if (!this.state.remittanceAmount) {
        //     notifyApiError('Collecting Amount is required');
        //     return
        // }
        // if (this.state.remittanceAmount > cost) {
        //     notifyApiError('Collect Amount can\'t be greater then net outstanding amount');
        //     return
        // }
        // if (!this.state.dateOfPayment) {
        //     notifyApiError('Date Of Payment is required');
        //     return
        // }
        // if (!this.state.referenceNumber) {
        //     notifyApiError('Reference No is required');
        //     return
        // }
        // if (!this.state.uploadedFileUrl) {
        //     notifyApiError('Please upload proof file.');
        //     return
        // }

    }
    debitNoteUpload = url => {
        const payload = {
            url,
            "invoiceId": this.props?.data?.invoiceId
        };

        this.setState({
            downloading: true
        }, () => {
            this.props.uploadDebitNote(payload);
        })
    }
    render() {
        const uploadButtonHtml = (
            <div className="fileUploadContainer">
                <div className="fileTextCenter">
                    <div>
                        <Icon type="picture" style={{ marginRight: "10px" }} />
                        <span>Click here to Upload Proof</span>
                    </div>
                    <div>Max. size 5MB</div>
                </div>
            </div>
        );
        const cost = this.props?.data?.paidAmount ? (this.props?.data?.totalAmount - this.props?.data?.paidAmount) : this.props?.data?.totalAmount;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        return (
            <Spin spinning={this.state.downloading} tip="DOWNLOADING...">
                <div className="settleInvoiceHeader">
                    <h3>Settle Invoice - {this.props.data?.invoiceCode}</h3>
                </div>
                <div className="settleDetails">
                    <Row gutter={12}>
                        <Col span={6}>
                            <div className="detailEntity">
                                <div className="light-text">Customer Name</div>
                                <div className="bold">{this.props?.data?.customer?.name}</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="detailEntity">
                                <div className="light-text">Invoice Amount</div>
                                <div className="bold">INR {this.props?.data?.totalAmount}</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="detailEntity">
                                <div className="light-text">Outstanding Amount</div>
                                <div className="bold">INR {cost}</div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="detailEntity">
                                <div className="light-text">Invoice Due Date</div>
                                <div className="bold">{this.props?.data?.invoiceDueDate ? moment(this.props?.data?.invoiceDueDate).format('DD/MM/YYYY') : 'N/A'}</div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="detailEntity">
                                <div className="light-text">Payment Date</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('dateOfPayment', {
                                        initialValue: this.state.dateOfPayment,
                                        rules: [{ required: true, message: 'Payment Date is required' }],
                                    })(
                                        <DatePicker
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="detailEntity">
                                <div className="light-text">Payment Amount</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('remittanceAmount', {
                                        initialValue: this.props.selectedPayment.amount,
                                        rules: [
                                            { required: true, message: 'Payment Amount is required' },
                                            {
                                                required: true, message: 'Payment Amount can\'t be greater then net outstanding amount',
                                                validator: (rule, value) => { return this.props.selectedPayment.amount ? (value <= this.props?.data?.totalAmount) : (value <= cost) }
                                            },
                                        ],
                                    })(
                                        <InputNumber
                                            placeholder="enter amount in INR"
                                            className="remittance-input"
                                            style={{ width: '100%' }}
                                            min={1}
                                        />
                                    )}
                                </Form.Item>

                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="detailEntity">
                                <div className="light-text">Reference No. *</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('referenceNumber', {
                                        initialValue: this.props.selectedPayment.referenceId,
                                        rules: [{ required: true, message: 'Reference Number is required' }],
                                    })(
                                        <Input
                                            placeholder="Reference No"
                                            className="remittance-input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="detailEntity">
                                <div className="light-text">Remarks (Optional)</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('bankRemarks', {
                                        initialValue: this.props.selectedPayment.remarks,
                                    })(
                                        <Input
                                            type={INPUT_TYPE.TEXTAREA}
                                            placeholder="Addional Informations ( if any )"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="detailEntity">
                                <div className="light-text">Payment Mode</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('paymentMode', {
                                        initialValue: this.props.selectedPayment.paymentMode,
                                        rules: [{ required: true, message: 'Payment mode is required' }],
                                    })(
                                        <Radio.Group>
                                            {
                                                this.getPaymentTypes().map(item => (
                                                    <Radio.Button key={item.id} value={item.value}>{item.label}</Radio.Button>
                                                ))
                                            }
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="detailEntity">
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('uploadedFileUrl', {
                                        initialValue: this.props.selectedPayment.imageProof,
                                        rules: [{ required: false, message: 'Proof is required' }],
                                    })(
                                        <FileUpload
                                            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                            acceptTypes={this.state.fileAcceptTypes}
                                            directUpload={true}
                                            disableUploadButton={false}
                                            path="settle-invoices"
                                            objKey="settle-invoices"
                                            multiple={false}
                                            onUploadFinish={url => setFieldsValue({ uploadedFileUrl: url })}
                                            uploadButtonHtml={uploadButtonHtml}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>


                </div>
                {/* <div className="bankDetails">
                    <div className="detailEntity">
                        <div className="light-text">Bank Name (Optional)</div>
                        <Input
                            placeholder="Enter Bank Name"
                            value={this.state.bankName}
                            onChange={e => this.onChangeValue('bankName', e.target.value)}
                            className="remittance-input"
                        />
                    </div>
                    <div className="detailEntity">
                        <div className="light-text">Remarks (Optional)</div>
                        <Input
                            type={INPUT_TYPE.TEXTAREA}
                            placeholder="Addional Informations ( if any )"
                            value={this.state.bankRemarks}
                            onChange={e => this.onChangeValue('bankRemarks', e.target.value)}
                        />
                    </div>
                </div> */}
                <div className="settleInvoiceFooter">
                    <Button className="action-button" onClick={this.props?.closeModal}>
                        Cancel
                        </Button>
                    <Button className="action-button primary" onClick={this.settleInvoice}>
                        Confirm
                        </Button>

                </div>
            </Spin>
        )
    }
}

export default Form.create({ name: 'Settle Invoice', onValuesChange: (props, val) => { console.log(val) } })(SettleInvoice);