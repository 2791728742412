import { Button, Icon, Modal, Spin, Input, Checkbox, Tag } from "antd";
import React, { useEffect, useState } from "react";
import ZoneMappingContracts from '../../../shared-components/zone-mapping-contracts/ZoneMappingContracts';
import ZoneService from "../../../../services/billing/zone.service";
import "./zone.scss";
import { checkAPIHttpStatus, notifyApiSuccess } from "../../../../common/utils/utils";
import _ from 'lodash';
import { validationRules } from "../../../../common/utils/constants";
const zoneService = new ZoneService();
const { confirm } = Modal;
function CustomerzoneData(props) {

    const [zoneData, SetZoneData] = useState({});
    const [loading, SetLoading] = useState(true);
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [loader, setLoader] = useState(false);
    const { createAnother, setCreateAnother } = props;
    // const [createAnother, setCreateAnother] = useState(false);

    const [formData, setFormData] = useState({
        code: undefined,
        zoneName: undefined
    });
    const [errors, setErrors] = useState({
        codeError: undefined,
        zoneNameError: undefined,
        selectedAreasError: undefined
    })
    const { showModal, setModal, customer, refresh, setRefresh, setIndexReadOnly, zonePermissions, openHelpLink } = props;

    useEffect(() => {
        if (props.id) {

            zoneService.getZone(props.id).then(response => {
                let newCode = response.data.response.zone.code;
                var splitter = newCode ? newCode.replace(`ZON-${customer.code}-`, '') : newCode;
                // var splitter = newCode.split("-");
                // if (splitter.length > 1) {
                //     splitter.splice(0, 2);
                //     newCode = "-" + splitter.join("-");
                // }
                SetZoneData(response.data.response.zone);
                setFormData({
                    zoneName: response.data.response.zone.name,
                    code: splitter,
                })


            })
        }
        return () => {
            SetZoneData({})
        }

    }, [])

    useEffect(() => {
        if (zoneData?.id == props?.id) {

            SetLoading(false);
        }
    }, [zoneData])


    const onSelectedAreaAdd = selectedAreas => {

        setSelectedAreas(selectedAreas);
    };
    const prepareZoneMapping = () => {
        const zoneMapping = [];

        selectedAreas.map(state => {
            state.cities.map(city => {
                city.pincodes.map(pin => {
                    zoneMapping.push({
                        "mappingEntityType": "PINCODE",
                        "entityId": pin.id
                    })
                })
            })
        });

        return zoneMapping;
    }
    const onSave = () => {
        setErrors({
            codeError: undefined,
            zoneNameError: undefined,
            selectedAreasError: undefined
        });

        if (_.isUndefined(formData.code)) {
            setErrors({
                ...errors,
                codeError: 'Code cannot be Empty'
            });
            return;
        } else if (!new RegExp(validationRules.shortCode).test(formData.code)) {
            setErrors({
                ...errors,
                codeError: 'Enter valid code name'
            });
            return;
        } else if (formData.code.length < 3 || formData.code.length > 6) {
            setErrors({
                ...errors,
                codeError: 'Code length should be 3-6 characters'
            });
            return;
        }

        setLoader(false);
        if (_.isUndefined(formData.zoneName)) {
            setErrors({
                ...errors,
                zoneNameError: 'Zone name cannot be Empty'
            });
            return;
        } else if (!new RegExp(validationRules.nameNumber).test(formData.zoneName)) {
            setErrors({
                ...errors,
                zoneNameError: 'Enter valid zone name'
            });
            return;
        } else if (formData.zoneName.length < 1 || formData.zoneName.length > 50) {
            setErrors({
                ...errors,
                zoneNameError: 'Name length should be 1-50 characters'
            });
            return;
        } else {
            setErrors({
                ...errors,
                zoneNameError: undefined
            });
            setLoader(false);
        }
        if (selectedAreas.length <= 0) {
            setErrors({
                ...errors,
                selectedAreasError: 'Select Pincodes to proceed!'
            });
            return;
        } else if (!_.isUndefined(formData.zoneName)) {
            setErrors({
                ...errors,
                selectedAreasError: undefined
            });
        }
        confirm({
            title: 'Are you sure to submit?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                setLoader(true);
                let payload = {
                    zone: {
                        name: formData.zoneName,
                        code: `ZON-${customer.code}-${formData.code?.toUpperCase()}`,
                        zoneMapping: prepareZoneMapping(),
                        zoneType: "RATECARD",
                        entityType: "CUSTOMER",
                        entityId: customer?.id
                    },
                    "validationRequired": true
                }
                if (props.id) {
                    payload.zone.id = props.id;
                    zoneService.updateZone(props.id, payload).then(response => {
                        if (response?.data?.response?.updated) {


                            setLoader(false);
                            setRefresh(!refresh);
                            setModal(false);
                            setIndexReadOnly(true);
                            notifyApiSuccess("Zone Updated Successfully", "SUCCESS");

                        } else {
                            setLoader(false);
                        }
                    })
                } else {
                    zoneService.createZone(payload).then(response => {

                        if (response?.data?.response?.created) {
                            setReadOnly(false);
                            setLoader(false);
                            setRefresh(!refresh);
                            setModal(false);
                            setIndexReadOnly(false);
                            notifyApiSuccess("Zone Created Successfully", "SUCCESS");
                            if (createAnother) {

                                // setIndexReadOnly(false);
                                setModal(true);

                                setCreateAnother(true);

                            }

                        } else {
                            setLoader(false);
                        }
                    })
                }
            }
        })



    }

    const checkAnother = e => {
        // const oldFormData = { ...shipperRef.current.formReference.current.state.formData };
        setCreateAnother(e.target.checked)

        // SetFormData({
        //     shipperDetails: {
        //         ...oldFormData.shipperDetails,
        //     }
        // })
    }

    return (
        <Modal
            visible={showModal}
            title={
                <div className="flex-box flex-gap-xl align-items-center justify-content-space-between zone-modal">
                    <div className="flex-box align-items-center flex-gap-m flex-5">
                        <Icon onClick={() => {
                            // setModal(false);
                            // setIndexReadOnly(true);
                            // props.SetZoneDetails(false)
                            if (!readOnly && (formData.zoneName || formData.code)) {
                                confirm({
                                    title: 'Are you sure to cancel?',
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk: () => {
                                        setModal(false);
                                        setIndexReadOnly(true);
                                        props.SetZoneDetails(false)
                                        setCreateAnother(false);
                                    }
                                })
                            } else {
                                setModal(false);
                                setIndexReadOnly(true);
                                props.SetZoneDetails(false);
                                setCreateAnother(false);
                            }
                        }}
                            style={{ marginRight: 8 }}
                            type="arrow-left"
                        />
                        <div className="text-bold font-size-m-2">
                            {(readOnly || props.name) ?
                                <>Zone : {props.name}</> : <>Create Zone</>
                            }

                        </div>
                    </div>

                    <div className="flex-1 flex-box justify-content-flex-end flex-gap-m">
                        {(!readOnly) &&
                            <Tag className="cursor-pointer" color="#87d068" onClick={openHelpLink}><Icon type="question-circle" /> Help</Tag>
                        }
                        {(readOnly && zonePermissions.zoneUpdate) &&
                            <>
                                <div>
                                    <Button block className="lsn-btn-primary" onClick={() => {

                                        setIndexReadOnly(false);
                                        setReadOnly(false);

                                    }
                                    }>
                                        Edit
                                    </Button>
                                </div>
                                <div>
                                    <Button block type="default" onClick={() => {
                                        setModal(false);
                                        setIndexReadOnly(true);
                                        props.SetZoneDetails(false);
                                        setCreateAnother(false);
                                    }
                                    }>
                                        Close
                                </Button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
            onCancel={() => {
                setModal(false);
                setIndexReadOnly(true);
                props.SetZoneDetails(false);
                setCreateAnother(false);
            }}
            width={'85%'}
            closable={false}
            footer={
                !readOnly &&
                <div className="flex-box flex-gap-m justify-content-end align-items-center zone-modal">
                    <div className="flex-box">
                        {(!readOnly && !props.id) &&
                            <Checkbox
                                checked={createAnother}
                                onChange={checkAnother}
                            >
                                Create Another
                            </Checkbox>
                        }

                    </div>
                    <div className="flex-box">
                        <Button block onClick={() => {
                            if (formData.zoneName || formData.code) {
                                confirm({
                                    title: 'Are you sure to cancel?',
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk: () => {
                                        setModal(false);
                                        setIndexReadOnly(true);
                                        setCreateAnother(false);
                                    }
                                })
                            } else {
                                setModal(false);
                                setIndexReadOnly(true);
                                setCreateAnother(false);
                            }

                        }} >
                            Cancel
                        </Button>
                    </div>
                    <div className="flex-box">
                        <Button loading={loader} block className="lsn-btn-primary" onClick={() => onSave()}>
                            {formData.code ? 'Save' : 'Add'}
                        </Button>
                    </div>



                </div>
            }>
            <Spin spinning={loading} className="flex-box justify-content-center" style={{ display: "flex" }}>
                <div className="flex-box flex-column flex-gap-s">
                    {!readOnly &&
                        <div className="zone-add">
                            <div className="flex-column flex-gap-m">
                                <div className="flex-box  flex-gap-m">
                                    <label style={{ width: '10%' }}>Zone Code <span className="zone-error">*</span></label>
                                    <div className="flex-column flex-2">
                                        <div>
                                            <Input
                                                style={{ width: '25%' }}
                                                placeholder={"Enter Zone Code"}
                                                disabled={props.id}
                                                onChange={e => {
                                                    setFormData({
                                                        ...formData,
                                                        code: e.target.value.toUpperCase()
                                                    })
                                                    setErrors({
                                                        ...errors,
                                                        codeError: undefined
                                                    })
                                                }}
                                                addonBefore={
                                                    !readOnly &&
                                                    `ZON-${customer.code}-`
                                                }
                                                value={formData.code}
                                            />
                                        </div>
                                        <div>

                                            <p className="zone-error">{errors.codeError}</p>

                                        </div>
                                    </div>

                                </div>

                                <div className="flex-box flex-gap-m">
                                    <label style={{ width: '10%' }}>Zone Name <span className="zone-error">*</span></label>
                                    <div className="flex-column flex-2">
                                        <div>
                                            <Input
                                                style={{ width: '25%' }}
                                                placeholder={"Enter Zone Name"}
                                                maxLength="50"
                                                onChange={e => {
                                                    setFormData({
                                                        ...formData,
                                                        zoneName: e.target.value
                                                    })
                                                    setErrors({
                                                        ...errors,
                                                        zoneNameError: undefined
                                                    })
                                                }
                                                }
                                                value={formData.zoneName}
                                            />
                                        </div>
                                        <div>


                                            <p className="zone-error">{errors.zoneNameError}</p>

                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>
                    }
                    <div className={"contract-zone-mapping " + (readOnly ? "zone-mapping-read-only" : "spacer-vm")}>
                        <ZoneMappingContracts disabled={readOnly}
                            formData={formData}
                            style={{ height: 300, overflow: 'scroll', bottom: 5 }}
                            onSelectedAreaAdd={onSelectedAreaAdd} readOnly={readOnly}
                            selectedPincodes={(zoneData && _.hasIn(zoneData, 'zoneMapping')) ? zoneData?.zoneMapping?.map(zone => zone.entityId) : []}
                        // selectedPincodes={newZoneData}
                        />
                        <div className="pincode-error">


                            <p className="zone-error">{errors.selectedAreasError}</p>

                        </div>

                    </div>
                </div>
            </Spin>
        </Modal >
    )
}

// const WrappedNormalLoginForm = Form.create({ name: 'zoneForm' })(CustomerzoneData);
// export default WrappedNormalLoginForm;
export default CustomerzoneData;