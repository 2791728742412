import { Button, Modal } from "antd";
import React from "react";

export default function DetailsActions (props) {
    
    return (
        <div className="flex-box justify-content-end align-items-center flex-gap-l spacer-vm">
            {
                props.contractDetails?.status === "DRAFT" &&
                <Button type="danger" onClick={() => {
                    Modal.confirm({
                        title  : "Discarded contracts can no longer be edited or used. Are you sure to discard?",
                        onOk   : props.discardContract,
                        okText : "Yes"
                    })
                }}>
                    Discard Contract
                </Button>
            }
            <Button className="lsn-btn-success" onClick={() => {
                Modal.confirm({
                    title  : "Are you sure to submit for approval?",
                    onOk   : _ => props.saveDraftContract(true),
                    okText : "Yes"
                })
            }}>
                Submit for Approval
            </Button>

            <Button className="secondary-btn" onClick={_ => props.goBackToListing(false)}> Cancel </Button>

            <Button className="lsn-btn-primary" onClick={() => {
                Modal.confirm({
                    title  : "Are you sure to save?",
                    onOk   : _ => props.saveDraftContract(false),
                    okText : "Yes"
                })
            }}>
                Save
            </Button>
        </div>
    )
}