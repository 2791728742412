import React, { Component } from 'react'
import { Card, Avatar, Button } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { connect } from 'react-redux';
import { getReport } from '../../../app/appAction';
import { reportsDownload } from '../../../common/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import { DownloadTags } from '../../../common/utils/constants';


class DataCard extends Component {
    state = {
        showDownload: false,
        primaryColor: '#A9A9A9',
        width: '90%'
    }

    toggleDownload = isActive => {
        if (isActive)
            this.setState({ showDownload: true, primaryColor: '#8A9CC2' })
        else
            this.setState({ showDownload: false, primaryColor: '#ACAFB5' })
    }

    downloadReport = subtype => {
        if (_.hasIn(this.props, 'getMixpanelInfo') && _.hasIn(this.props, 'mixPanel')) {
            this.props.mixPanel.track("Zone Page Download Initiated", { [this.props.mixPanelKey]: moment().valueOf() });
        }
        this.props.getReports({
            subtype,
            type: 'SPARKLINE'
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.link !== prevProps.link) {
            reportsDownload(this.props.link, DownloadTags.s3)
        }
    }
    render() {
        const { title, description, icon, documentType } = this.props;
        this.state.width = this.props.width == undefined ? '90%' : this.props.width;
        const { showDownload, primaryColor, width } = this.state;
        return (
            <Card style={{ width: `${width}`, height: 100, marginTop: 10, borderBottom: `3px solid  ${primaryColor}` }} onMouseLeave={() => this.toggleDownload(false)} onMouseEnter={() => this.toggleDownload(true)} >
                <Meta
                    avatar={
                        <Avatar shape="square" size="large" src={icon} style={{ filter: `grayscale(${showDownload ? '0%' : '100%'})` }} />
                    }
                    title={title}
                    description={description || '0'}
                />
                {
                    (showDownload && description) ? <Button type="primary" onClick={() => this.downloadReport(!documentType ? title.replace(' ', '_') : documentType)} style={{ float: 'right', margin: '-4px 0px', borderRadius: 0 }}>DOWNLOAD</Button> : null
                }

            </Card>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    link: state.app?.reportResp?.url
});
const mapDispatchToProps = dispatch => ({
    getReports: report => dispatch(getReport(report))
});
export default connect(mapStateToProps, mapDispatchToProps)(DataCard);