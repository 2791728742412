import { pModules, permissionTags, paTags } from "./permissionTags";
import * as utils from "../common/utils/utils";
import {
  Constants,
  checkConfigurationsForPermission
} from "../common/utils/constants";


let pincodePermissionCheckInConfiguration = congiValue => {
  const data = utils.checkConfigurationsForPermission(congiValue);

  if (
    data &&
    data.length &&
    data.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  ) {
    return true;
  }

  return false;
};

let configPermissionCheckInConfiguration = congiValue => {
  const data = utils.checkConfigurationsForPermission(congiValue);

  if (
    data &&
    data.length &&
    data.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  ) {
    return true;
  }

  return false;
};

// path : path of menu/tab to be appended in url
// label: string to be displayed on UI for a menu/tab
// name : key for a menu/tab present in permissions object
export const menuconfig = [ 
  {
    key: utils.generateID(),
    path: "sla-monitoring",
    label: "SLA Breach pre-alert",
    name: pModules.sla_monitoring.title,
    inactiveIcon: "indent_inactive.svg",
    activeIcon: "indent_active.svg",
    canAllow: utils.canAllow(permissionTags.sla_monitoring.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "SLA Breach pre-alert",
        path: "",
        name: pModules.sla_monitoring.subModule.sla_monitoring,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.sla_monitoring.sla_monitoring.subModule)
      },     
    ]
  },
  {
    key: utils.generateID(),
    path: "inbound",
    label:
      Constants.SELECTED_APPLICATION === "B2B" ? "Inbound View" : "Inbound",
    name:
      Constants.SELECTED_APPLICATION === "B2B"
        ? pModules.inboudView.title
        : pModules.inboud.title,
    inactiveIcon: "2_b.svg",
    activeIcon: "2_w.svg",
    canAllow: utils.canAllow(
      Constants.SELECTED_APPLICATION === "B2B"
        ? permissionTags.inboundView.module
        : permissionTags.inbound.module
    ),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Inbound Scan",
        path: "scan",
        name: pModules.inboudView.subModule.scan,
        canAllow: utils.canAllow(
          Constants.SELECTED_APPLICATION === "B2B"
            ? permissionTags.inboundView.scan.subModule
            : permissionTags.inbound.scan.subModule
        ),
        isReact: true,
      },
      // {
      //   label: Constants.SELECTED_APPLICATION === "B2C" ? "Expected" : "",
      //   path: "expected",
      //   name: pModules.inboud.subModule.expected,
      //   canAllow: utils.canAllow(permissionTags.inbound.expected.subModule),
      //   isReact: true,
      // },
      // {
      //   label: Constants.SELECTED_APPLICATION === "B2C" ? "Exceptions" : "",
      //   path: "exceptions",
      //   name: pModules.inboud.subModule.exceptions,
      //   canAllow: utils.canAllow(permissionTags.inbound.exceptions.subModule),
      //   isReact: true,
      // },
      {
        label: "Print History",
        path: "print_history",
        name: pModules.inboud.subModule.print_history,
        canAllow: utils.canAllow(permissionTags.inbound.print_history.subModule),
        isReact: true,
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "load",
    label: "Load View",
    name: pModules.load.title,
    inactiveIcon: "2_b.svg",
    activeIcon: "2_w.svg",
    canAllow: utils.canAllow(permissionTags.load.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Expected",
        path: "expected",
        name: pModules.load.subModule.expected,
        canAllow: utils.canAllow(permissionTags.load.expected.subModule),
        isReact: true,
      },
      {
        label: "Exceptions",
        path: "exceptions",
        name: pModules.load.subModule.exceptions,
        canAllow: utils.canAllow(permissionTags.load.exceptions.subModule),
        isReact: true,
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "inventory",
    label: "Inventory",
    name: pModules.inventory.title,
    inactiveIcon: "3_b.svg",
    activeIcon: "3_w.svg",
    canAllow: utils.canAllow(permissionTags.inventory.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: Constants.SELECTED_APPLICATION === "B2B" ? "AWB" : "WAYBILL",
        path: "awb",        
        name: pModules.inventory.subModule.awb,        
        canAllow: utils.canAllow(permissionTags.inventory.awb.subModule),
        isReact: true
      }      
      
    ]
  },
  {
    key: utils.generateID(),
    path: "tracking",
    label: "Tracking",
    name: pModules.tracking.title,
    inactiveIcon: "4_b.svg",
    activeIcon: "4_w.svg",
    canAllow: utils.canAllow(permissionTags.tracking.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Tracking",
        path: "tracking",
        name: pModules.tracking.subModule.tracking,
        canAllow: utils.canAllow(permissionTags.tracking.tracking.subModule),
        isReact: true
      },      
      {
        label: "Vehicle Tracking",
        path: "atlas-tracking",
        name: pModules.tracking.subModule.heliosVehicleTracking,
        canAllow: utils.canAllow(permissionTags.tracking.heliosVehicleTracking.subModule),
        //canAllow: true,
        isReact: true
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "trips",
    label: "Trips",
    name: pModules.trips.title,
    inactiveIcon: "trip_inactive.svg",
    activeIcon: "trip_active.svg",
    canAllow: utils.canAllow(permissionTags.trips.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Trips",
        path: "trips",
        name: pModules.trips.subModule.trips,
        canAllow: utils.canAllow(permissionTags.trips.trips.subModule),
        isReact: true
      },
      {
        label: "Pending PODs",
        path: "pending-trips",
        name: pModules.trips.subModule.pending,
        canAllow: utils.canAllow(permissionTags.trips.pending.subModule),
        isReact: true
      },
      {
        label: "Completed Trips",
        path: "completed-trips",
        name: pModules.trips.subModule.completed,
        canAllow: utils.canAllow(permissionTags.trips.completed.subModule),
        isReact: true
      },
      {
        label: "Delivery Delay",
        path: "sla-reason",
        name: pModules.trips.subModule.sla,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.trips.sla.subModule)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "verification",
    label: "Verification",
    name: pModules.verification.title,
    inactiveIcon: "verification_inactive.svg",
    activeIcon: "verification_active.svg",
    canAllow: utils.canAllow(permissionTags.verification.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Trips",
        path: "vtrips",
        name: pModules.verification.subModule.trips,
        canAllow: utils.canAllow(permissionTags.verification.trips.subModule),
        isReact: true
      },
      {
        label: "Docket",
        path: "docket",
        name: pModules.verification.subModule.docket,
        canAllow: utils.canAllow(permissionTags.verification.docket.subModule),
        isReact: true
      },
      {
        label: "Contracts",
        path: "contract",
        name: pModules.verification.subModule.contract,
        canAllow: utils.canAllow(permissionTags.verification.contract.subModule),
        isReact: true
      },
      {
        label: "POD",
        path: "pod",
        name: pModules.verification.subModule.pod,
        canAllow: utils.canAllow(permissionTags.verification.pod.subModule),
        isReact: true
      },
    ]
  },  
  {
    key: utils.generateID(),
    path: "invoice",
    label: "Invoices",
    name: pModules.invoice.title,
    inactiveIcon: "6_b.svg",
    activeIcon: "6_w.svg",
    canAllow: utils.canAllow(permissionTags.invoice.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Customer Settlement",
        path: "customer-settlement",
        name: pModules.invoice.subModule.customer_settlement,
        canAllow: utils.canAllow(permissionTags.invoice.customer_settlement.subModule),
        isReact: true
      }
    ]
  }, 
  {
    key: utils.generateID(),
    path: "settings",
    label: "Settings",
    name: pModules.settings.title,
    inactiveIcon: "7_b.svg",
    activeIcon: "7_w.svg",
    canAllow: utils.canAllow(permissionTags.settings.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "CUSTOMER",
        path: "customer",
        name: pModules.settings.subModule.customer,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.customer.subModule)
      },
      {
        label: "VENDOR",
        path: "vendor",
        name: pModules.settings.subModule.vendor,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.vendor.subModule)
      },
      {
        label: "USER",
        path: "user",
        name: pModules.settings.subModule.user,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.user.subModule)
      },
      {
        label: "ASSET",
        path: "asset",
        isReact: true,
        name: pModules.settings.subModule.asset,
        canAllow: localStorage.getItem('currentPartnerId') !== '5' ? utils.canAllow(permissionTags.settings.asset.subModule) : false,
      },
      {
        label: "ROUTE",
        path: "route",
        isReact: true,
        name: pModules.settings.subModule.route,
        canAllow: utils.canAllow(permissionTags.settings.route.subModule)
      },
      {
        label: "LOCATION",
        path: "location",
        isReact: true,
        name: pModules.settings.subModule.location,
        canAllow: utils.canAllow(permissionTags.settings.location.subModule)
      },
      {
        label: "ZONE",
        path: "zone",
        name: pModules.settings.subModule.zone,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.zone.subModule)
      },
      {
        label: "CUSTOMER SLAs",
        path: "sla-rules",
        name: pModules.settings.subModule.slaRules,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.slaRules.subModule)
      },
      {
        label: "PINCODE",
        path: "pincode",
        name: pModules.settings.subModule.pincode,
        canAllow: pincodePermissionCheckInConfiguration("pincodeEditPermission"),
        isReact: true
      },
      {
        label: "PARTNERS ONBOARDING",
        path: "partners-onboarding",
        name: pModules.settings.subModule.partners_onboarding,
        canAllow: utils.canAllow(
          permissionTags.settings.partners_onboarding.subModule,
          true
        ),
        isReact: true
      },
      {
        label: "ZOHO",
        path: "zoho-dashboard",
        name: pModules.settings.subModule.zoho,
        canAllow: utils.canAllow(permissionTags.settings.zoho.subModule),
        isReact: true
      },
      {
        label: "CUSTOM CONFIG",
        path: "custom-config",
        name: pModules.settings.subModule.custom_config,
        isReact: true,
        canAllow: utils.canAllow(
          permissionTags.settings.custom_config.subModule,
          true
        )
        // canAllow: configPermissionCheckInConfiguration("authorizedUserIdsToUpdateApplicationConfig")
      },
      {
        label: "RATE CARD CONFIG",
        path: "ratecard-config",
        name: pModules.settings.subModule.ratecard_config,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.ratecard_config.subModule),       
        // canAllow: configPermissionCheckInConfiguration("authorizedUserIdsToUpdateApplicationConfig")
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "indent",
    label: "Indent",
    name: pModules.settings.title,
    inactiveIcon: "indent_inactive.svg",
    activeIcon: "indent_active.svg",
    canAllow: utils.canAllow(permissionTags.indent.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "INDENT",
        path: "",
        name: pModules.indent.subModule.indent,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.indent.indent.subModule)
      },     
    ]
  },
  
  {
    key: utils.generateID(),
    path: `import-export`,
    label: "Import/Export",
    name: pModules.importExport.title,
    inactiveIcon: "3_b.svg",
    activeIcon: "3_w.svg",
    canAllow: utils.canAllow(permissionTags.importExport.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Import",
        path: "import",
        isReact: true,
        name: pModules.importExport.subModule.import,
        canAllow: utils.canAllow(permissionTags.importExport.import.subModule),
      },
      {
        label: "Export",
        path: "export",
        isReact: true,
        name: pModules.importExport.subModule.export,
        canAllow: utils.canAllow(permissionTags.importExport.export.subModule),
      },
      {
        label: "Docket To Finish Trip",
        path: "docket-to-end-trip",
        isReact: true,
        name: pModules.importExport.subModule.docketToFinishTrip,
        canAllow: utils.canAllow(permissionTags.importExport.docketToFinishTrip.subModule),
      },
    ]
  },  
  {
    key: utils.generateID(),
    path: "reports",
    label: "Reports",
    name: pModules.reports.title,
    inactiveIcon: "10_b.svg",
    activeIcon: "10_w.svg",
    canAllow: utils.canAllow(permissionTags.reports.module),
    type: "link",
    isReact: true,
    children: [      
      {
        label: 'DOWNLOAD',
        path: 'download',
        name: pModules.reports.subModule.new_reports,
        canAllow: utils.canAllow(permissionTags.reports.new_reports.subModule),
        isReact: true
      },
      {
        label: 'INGEST',
        path: 'ingest',
        name: pModules.reports.subModule.ingest,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.reports.ingest.subModule)
      },
      {
        label: 'MANIFEST SHEET',
        path: 'manifest-sheet',
        name: pModules.reports.subModule.manifestSheet,
        canAllow: utils.canAllow(permissionTags.reports.manifestSheet.subModule),
        isReact: true,
      }
    ]
  },
  {
    key: utils.generateID(),
    path: `dashboard`,
    label: "Dashboard",
    name: pModules.dashboard.title,
    inactiveIcon: "3_b.svg",
    activeIcon: "3_w.svg",
    canAllow: utils.canAllow(permissionTags.dashboard.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Dashboard",
        path: "",
        isReact: true,
        name: pModules.dashboard.title,
        canAllow: utils.canAllow(permissionTags.dashboard.module)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "communication",
    label: "Communication",
    name: pModules.communication.title,
    inactiveIcon: "11_b.svg",
    activeIcon: "11_w.svg",
    canAllow: utils.canAllow(permissionTags.communication.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "MAIL",
        path: "mail",
        name: pModules.communication.subModule.mail,
        canAllow: utils.canAllow(permissionTags.communication.mail.subModule),
        isReact: true,
      }
    ]
  }, 
  {
    key: utils.generateID(),
    path: "lms",
    label: "LMS Setup",
    name: pModules.lms.title,
    inactiveIcon: "lmsInactive.svg",
    activeIcon: "lmsActive.svg",
    canAllow: utils.canAllow(permissionTags.lms.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "LMS Setup",
        path: "",
        name: pModules.lms.subModule.setup,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.lms.setup.subModule)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "live-loads",
    label: "Live Loads",
    name: pModules.live_loads.subModule.live_loads,
    inactiveIcon: "live_loads_inactive.svg",
    activeIcon: "live_loads_inactive.svg",
    canAllow: utils.canAllow(permissionTags.live_loads.live_loads.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Live Loads",
        path: "dashboard",
        name: pModules.live_loads.subModule.live_loads,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.live_loads.live_loads.read)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "deliver-loads",
    label: "Delivered Loads",
    name: pModules.deliver_loads.subModule.deliver_loads,
    inactiveIcon: "deliver_loads_inactive.svg",
    activeIcon: "deliver_loads_inactive.svg",
    canAllow: utils.canAllow(permissionTags.deliver_loads.deliver_loads.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Delivered Loads",
        path: "dashboard",
        name: pModules.deliver_loads.subModule.deliver_loads,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.deliver_loads.deliver_loads.read)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "customer-tracking",
    label: "Tracking",
    name: pModules.customer_tracking.subModule.docket_tracking,
    inactiveIcon: "customer_tracking_inactive.svg",
    activeIcon: "customer_tracking_inactive.svg",
    canAllow: utils.canAllow(permissionTags.customer_tracking.docket_tracking.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Tracking",
        path: "tracking",
        name: pModules.customer_tracking.subModule.docket_tracking,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.customer_tracking.docket_tracking.read)
      },
      {
        label: "Vehicle Tracking",
        path: "customer-vehicle-tracking",
        name: pModules.customer_tracking.subModule.vehicle_tracking,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.customer_tracking.vehicle_tracking.read)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "customer-users",
    label: "Users",
    name: pModules.customer_settings.subModule.user_settings,
    inactiveIcon: "user_inactive.svg",
    activeIcon: "user_inactive.svg",
    canAllow: utils.canAllow(permissionTags.customer_settings.user_settings.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Users",
        path: "dashboard",
        name: pModules.customer_settings.subModule.user_settings,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.customer_settings.user_settings.subModule)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "ewaybill",
    label: "EwayBills",
    name: pModules.ewaybill.title,
    inactiveIcon: "eway_inactive.svg",
    activeIcon: "eway_active.svg",
    canAllow: utils.canAllow(permissionTags.ewaybill.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Ewaybills Issued",
        path: "dashboard",
        name: pModules.ewaybill.ewaybill,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.ewaybill.ewaybill.subModule)
      },
      {
        label: "Update Part B",
        path: "partb",
        name: pModules.ewaybill.subModule.partb,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.ewaybill.partb.subModule)
      },
    ]
  } 
];
