import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getValueFromKey } from "../../../../../../common/utils/utils";
import FormWrapper from "../../../../../ui/formComponent/form";
import { validateSLARules } from "../lanes/lane-utils";
import { getSLADetails, postLaneSLA } from "../lanes/lane.services";
import transitSLASchema from "./transit.sla.schema";

export default function TransitSLA (props) {

    var formRef = useRef();

    const [ formData, SetFormData ] = useState({
        slaRuleBOs     : [{}],
        penalties   : {}
    });

    useEffect(() => {
        if (props.contractId && props.laneMappingID) {
            getSLADetails(props.contractId, props.laneMappingID).then(slaBO => {
                if(slaBO.slaType === "TRANSIT") {
                    SetFormData({
                        id          : slaBO.id,
                        slaRuleBOs  : slaBO.slaRuleBOs,
                        penalties   : { 
                            breachPenalty   : slaBO.breachPenalty
                        }
                    });
                }
            })
        }
    }, [ props.laneMappingID, props.contractId ])

    const validate = (data, errorsObject) => {
        const validationObjects = validateSLARules(data.slaRuleBOs);
        if (validationObjects?.length === 0) {
			return errorsObject;
		}
		else {
			validationObjects.map(validator => {
				if (validator[0] && validator[1]) {
					var propertyLabel = validator[1];
					var errorKeyObj = getValueFromKey(errorsObject, propertyLabel);
					if (errorKeyObj) {
						errorKeyObj.addError(validator[0]);
					}
				}
			})
			return errorsObject;
		}
    }

    const saveTransitSLA = data => {
        SetFormData(data);
        props.generateLaneMappingID(laneMappingBO => {
            if (!laneMappingBO) return;
            var laneEntity = laneMappingBO.find(l => l.index === props.index);
            const postObj = {
                laneMappingBO    : {
                    id           : laneEntity.id,
                    contractId   : props.contractId,
                    entityBO     : {
                        slaBO    : {
                            id          : data.id,
                            slaType     : "TRANSIT",
                            slaRuleBOs  : [ ...data.slaRuleBOs ],
                            ...data.penalties
                        }
                    },
                }
            }
            postLaneSLA(postObj)
            .then(_ => {
                props.onCancel();
                props.onLaneDataSaveSuccess("Transit SLA")
            })
            .catch(_ => {})
        })
    }

    const onChange = data => {
        if (data.slaRuleBOs.length <= 0) {
            data.slaRuleBOs = [{}];
        }
        SetFormData(data);
    }

    return (
        <div>
                <FormWrapper
                    {...transitSLASchema}
                    formData={formData}
                    onSubmit={saveTransitSLA}
                    readonly={props.readonly}
                    validate={validate}
                    fieldsPerRow={2}
                    onChange={onChange}
                    showColumnSplitter={false}
                    hideSubmit={true}
                    bordered={true}
                    ref={formRef}
                />
                {
                    !props.readonly &&
                    <div className="flex-box flex-gap-m align-items-center justify-content-end popup-buttons-divider contract-management">
                        <Button className="secondary-btn" onClick={() => {
                            Modal.confirm({
                                title   : "Transit SLA",
                                content : "Are you sure to cancel?",
                                onOk    : _ => {
                                    Modal.destroyAll();
                                    props.onCancel();
                                }
                            })
                        }}> Cancel </Button>
                        <Button className="lsn-btn-primary" onClick={() => {
                            formRef.current.formReference?.current?.submit()
                        }}> Save </Button>
                    </div>
                }
        </div>
    )
}