import React from 'react';
import { Tag } from "antd";
import ImageViewer from '../docket/ImageViewer';
import moment from 'moment';

export function PodStatusTransformer(props) {
    const verificationStatus = props?.verificationStatus || props;
    if (verificationStatus === 'POD_VERIFICATION_PENDING') {
        return <Tag className="ant-table-tag info-tag text-align-center"><span>POD Verification Pending</span></Tag>
    } else if (verificationStatus === 'POD_DOCUMENT_PENDING') {
        return <Tag className="ant-table-tag warning-tag text-align-center"><span>POD Document Pending</span></Tag>
    } else if (verificationStatus === 'POD_REJECTED') {
        return <Tag className="ant-table-tag error-tag text-align-center"><span>POD Rejected</span></Tag>
    } else if (verificationStatus === 'POD_VERIFIED') {
        return <Tag className="ant-table-tag success-tag text-align-center"><span>POD Verified</span></Tag>
    }
    return <Tag className="ant-table-tag warning-tag text-align-center"><span>POD Document Pending</span></Tag>
}

export function UpdateTypeMessages(data) {
    let user = data?.userName;
    let s3 = data?.podImpLink ? data?.podImpLink : false;
    let arrayImg = s3 ?
        s3.split(',').reduce((current, next) => {
            current.push({url : next});
            return current;
        }, [])
     :[];
    let historyData = {};
    const multiLineRemarks = (data?.activityType == 'DEPS_UPDATED' ||
                              data?.activityType == 'DOCKET_DELIVERED_WITH_POD' ||
                              data?.activityType == 'DOCKET_DELIVERED_WITHOUT_POD' ||
                              data?.activityType == 'POD_IMAGE_REJECTED' );
    let remarkStyle = { wordBreak:'break-word' };
    if(multiLineRemarks){
        remarkStyle['whiteSpace'] = 'pre-line';
    }
    if(s3 && data?.activityType != 'POD_IMAGE_REJECTED' && data?.activityType != 'POD_IMAGE_VERIFIED'){
        remarkStyle['maxWidth'] = '90%';
    }
    var podHistoryEventClass = "pod-history-event";
    if(data?.activityType == 'DOCKET_DELIVERED_WITH_POD'){
        podHistoryEventClass += " flex-box flex-gap-m align-items-end";
    }
    else if(data?.activityType != 'DEPS_UPDATED' && data?.activityType != 'POD_IMAGE_REJECTED'){
        podHistoryEventClass += " flex-box flex-gap-m align-items-center";
    }

    if (data?.activityType == 'POD_IMAGE_REJECTED' && data?.reasonsBOS) {
        var reasons = [];
        data?.reasonsBOS.map(reason => {
            reasons.push(reason.documentRejectedReason);
        })
        reasons = reasons.join(', ');
        var remarks = 'Reason : ' + reasons +'\nRemarks : ' + data?.remarks;
        data.remarks = remarks;
    }
    historyData = {
        message: <>
            {
                data?.remarks &&
                <div className={podHistoryEventClass}>
                    {
                        data?.activityType == 'POD_IMAGE_REJECTED' && 
                        <div className="flex-box flex-gap-m align-items-end">
                            <span> POD Rejected </span>
                            { s3 && <ImageViewer fileList={arrayImg}/> }
                        </div>
                    }
                    <span style={remarkStyle}>{data?.remarks}</span>
                    {
                        s3 && data?.activityType != 'POD_IMAGE_REJECTED' && data?.activityType != 'POD_IMAGE_VERIFIED' &&
                        (
                            data?.activityType == 'DEPS_UPDATED' ?
                            <>
                                <br/>
                                <div className="flex-box flex-gap-m align-items-end">
                                    DEPS Proofs : <ImageViewer fileList={arrayImg} />
                                </div> 
                            </>:
                            <ImageViewer fileList={arrayImg} />
                        )
                    
                    }
                </div>
            }

        </>,
        user,
        timestamp: (
            <>
                {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
                <small>{moment(data?.createdAt).fromNow()}</small>
            </>
        )
    };
    return historyData;
}