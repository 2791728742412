export const INITIAL_STATE = {
    loading: false,
    error: "",
    errorModal: {
        show: false,
        title: "",
        msg: ""
    },
    selectedEntityType: undefined,
    entityInfo: null,
    scannedEntities: [],
    currentScannedEntityInfo: null,
    reasons: null,
    sparkLineDialogData: {
        statusCount: 0,
        totalCount: 0,
        manifests: null,
        consignments: null
    },
    isValidated: false,
};

export const ENTITY_TYPES = {
    connection: {
        label: "Connection",
        name: "connection",
    },
    manifest: {
        label: "Manifest",
        name: "manifest",
    }
};

export const ENTITY_STATUS = {
    connection: {
        started: "STARTED",
        unloading: "UNLOADING",
        arrived: "ARRIVED",
    },
    manifest: {
        arrived: "ARRIVED",
        unloading: "UNLOADING",
        overage: "OVERAGE",
        
    }
}

export const SPARK_LINE_TYPES = {
    connection: {
        pending: {
            label: "Pending Manifests",
        },
        scanned: {
            label: "Scanned Manifests",
        },
        overage: {
            label: "Overage Manifests",
        }
    },
    manifest: {
        pending: {
            label: "Pending Shipments",
        },
        scanned: {
            label: "Scanned Shipments",
        },
        overage: {
            label: "Overage Shipments",
        }   
    }
};

export const REASONS = {
    pending: "pending",
    overage: "overage",
    scanned: "scanned",
};

export const STATUS_MAPPING = {
    pending: "PENDING",
    overage: "OVERAGE",
    scanned: "SCANNED"
};

export const ACTION_TYPES = {
    SET_ENTITY_TYPE: "SET_ENTITY_TYPE",
    FETCH_CONNECTION_INFO_BEGIN: "FETCH_CONNECTION_INFO_BEGIN",
    FETCH_CONNECTION_INFO_SUCCESS: "FETCH_CONNECTION_INFO_SUCCESS",
    FETCH_CONNECTION_INFO_FAILURE: "FETCH_CONNECTION_INFO_FAILURE",
    ENTITY_UNLOAD_BEGIN: "ENTITY_UNLOAD_BEGIN",
    ENTITY_UNLOAD_SUCCESS: "ENTITY_UNLOAD_SUCCESS",
    ENTITY_UNLOAD_FAILURE: "ENTITY_UNLOAD_FAILURE",
    SCAN_ENTITY_BEGIN: "SCAN_ENTITY_BEGIN",
    SCAN_ENTITY_SUCCESS: "SCAN_ENTITY_SUCCESS",
    SCAN_ENTITY_FAILURE: "SCAN_ENTITY_FAILURE",
    FETCH_REASONS_BEGIN: "FETCH_REASONS_BEGIN",
    FETCH_REASONS_SUCCESS: "FETCH_REASONS_SUCCESS",
    FETCH_REASONS_FAILURE: "FETCH_REASONS_FAILURE",
    UPDATE_REASON_BEGIN: "UPDATE_REASON_BEGIN",
    UPDATE_REASON_SUCCESS: "UPDATE_REASON_SUCCESS",
    UPDATE_REASON_FAILURE: "UPDATE_REASON_FAILURE",
    FETCH_MANIFEST_CONSIGNMENT_DATA_BEGIN: "FETCH_MANIFEST_CONSIGNMENT_DATA_BEGIN",
    FETCH_MANIFEST_CONSIGNMENT_DATA_SUCCESS: "FETCH_MANIFEST_CONSIGNMENT_DATA_SUCCESS",
    FETCH_MANIFEST_CONSIGNMENT_DATA_FAILURE: "FETCH_MANIFEST_CONSIGNMENT_DATA_FAILURE",
    UPDATE_REASONS_IN_LIST_BEGIN: "UPDATE_REASONS_IN_LIST_BEGIN",
    UPDATE_REASONS_IN_LIST_SUCCESS: "UPDATE_REASONS_IN_LIST_SUCCESS",
    UPDATE_REASONS_IN_LIST_FAILURE: "UPDATE_REASONS_IN_LIST_FAILURE",
    RESET_SELECTED_SPARK_LINE_DATA: "RESET_SELECTED_SPARK_LINE_DATA",
    VALIDATE_ENTITY_BEGIN: "VALIDATE_ENTITY_BEGIN",
    VALIDATE_ENTITY_SUCCESS: "VALIDATE_ENTITY_SUCCESS",
    VALIDATE_ENTITY_FAILURE: "VALIDATE_ENTITY_FAILURE",
    CLOSE_CONNECTION_BEGIN: "CLOSE_CONNECTION_BEGIN",
    CLOSE_CONNECTION_SUCCESS: "CLOSE_CONNECTION_SUCCESS",
    CLOSE_CONNECTION_FAILURE: "CLOSE_CONNECTION_FAILURE",
    INCREMENT_SCAN_COUNT: "INCREMENT_SCAN_COUNT",
    INCREMENT_OVERAGE_COUNT: "INCREMENT_OVERAGE_COUNT",
    CLOSE_ERROR_DIALOG_INFO: "CLOSE_ERROR_DIALOG_INFO",
    UPDATE_ENTITY_COUNT: "UPDATE_ENTITY_COUNT",
};