import React, { Component } from 'react'
import { Form, Card, Input, Row, Col, Button } from 'antd';
import { Constants, DownloadTags } from '../../../common/utils/constants';
import ReportsService from '../../../services/reports/reports.service';
import { reportsDownload, checkHttpStatus, notifyApiError } from '../../../common/utils/utils';

class ManifestSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manifestSheetData: null
        };
    }

    submitManifestSheet = () => {
        this.props.form.validateFieldsAndScroll((err, value) => {
            if (!err) {
                this.setState({
                    manifestSheetData: null,
                    loading: true
                }, () => {
                    const tripId = value.tripId;
                    const params = {
                        'commCode': 'MANIFEST_SHEET_LITE_GROUPED',
                        'tripIds': [tripId],
                        'documentType': 'MANIFEST_SHEET_LITE_GROUPED',
                        'locationId': Constants.userLocationId,
                        'partnerId': Constants.PARTNER_ID,
                        'userId': Constants.sessionUser.id
                    };
                    (new ReportsService).getManifestSheet(params).then(({ data }) => {
                        if (data.response && checkHttpStatus(data.status)) {
                            this.setState({
                                loading: false,
                                manifestSheetData: data.response
                            })
                        } else {
                            notifyApiError('trip id not found', 'Error')
                        }
                        this.props.form.resetFields()
                    });
                })
            }
        });
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { manifestSheetData } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        return (
            <div className="manifest-sheet">
                <Card title="Manifest Sheet">
                    <Form {...formItemLayout}>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item label="Trip ID">
                                    {getFieldDecorator('tripId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Trip ID!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter Trip ID"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="&nbsp;">
                                    <Button style={{ float: 'right' }} htmlType="button" onClick={this.submitManifestSheet} type="primary">Download Report</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            manifestSheetData !== null &&
                            <span style={{ cursor: 'pointer', color: 'var(--primary)' }} htmlType="button" type="link" onClick={() => reportsDownload(manifestSheetData?.url, DownloadTags.s3)}>{
                                manifestSheetData?.url
                            }</span>
                        }
                    </Form>
                </Card>
            </div>
        )
    }
}

export default Form.create({ name: 'Manifest-Sheet form', onValuesChange: (props, val) => { } })(ManifestSheet);