
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class SLARulesService {

    getEwayBills(formDate, toDate) {
        const url = `/b2b/v1/transporterEwaybill/getEwaybillDetailsBydaterange?fromDate=${formDate}&toDate=${toDate}`;
        return apiRequest({
            url,
            method: "GET"            
        });
    }

    getSearchWayBill(value) {
        const url = `/b2b/v1/transporterEwaybill/getEwaybillDetails?ewaybill_number=${value}`;
        return apiRequest({
            url,
            method: "GET"            
        });
    }

    
}