import React, { PureComponent } from 'react';
import * as actions from './actions';

import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, Form, PageHeader, Divider, Select, Checkbox, Radio, Spin, Switch, InputNumber } from 'antd';
import { canAllow, dataGet, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { validationRules, Constants } from '../../../common/utils/constants';
import { notifyApiSuccess } from '../../../common/utils/utils';
import _ from 'lodash';
import "./zoho.scss"
const { confirm } = Modal;
let id = 0;
class ZohoForm extends PureComponent {

    state = {
        isEdit: false,
        editId: null,
        isAssigned: false,
        showCustomPermission: false,
    }

    componentDidMount() {
        const application = atob(localStorage.getItem('application'));
        const params = {
            "application": [
                application
            ],
            "module": [
                "DASHBOARD"
            ],
            "submodule": [
            ],
            "action": [
            ],
            "status": [

                true
            ]
        }

        this.props.getZohoPermissions(params);
        if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            this.setState({ isEdit: true, editId: id, isAssigned: true });
            this.props.getZohoByID(id)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.zohoCreate && this.props?.zohoCreate?.tpDashboardId) {
            if (this.props.zohoCreate.tpDashboardId !== prevProps?.zohoCreate?.tpDashboardId) {
                notifyApiSuccess("Dashboard Created Successfully", "SUCCESS")
                this.goBack();
            }
        }
        if (this.props.zohoUpdate && this.props?.zohoUpdate?.tpDashboardId) {
            if (this.props.zohoUpdate.tpDashboardId !== prevProps?.zohoUpdate?.tpDashboardId) {
                notifyApiSuccess("Dashboard Updated Successfully", "SUCCESS")
                this.goBack();
            }
        }

        if (this.state.isEdit && this.props.zohoData && this.state.isAssigned && this.props.permissions) {
            this.props.form.resetFields();
            const { setFieldsValue } = this.props.form;
            let zohoData = this.props.zohoData.dashboards[0];

            this.setState({ showCustomPermission: false }, () => {
                setFieldsValue({
                    ...zohoData,

                }, () => {
                    setFieldsValue({
                        permissionName: { key: zohoData.permissionId, label: zohoData.permissionName }
                    }, () => {
                        this.setState({ isAssigned: false })
                    })

                })
            })

        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleSubmit = e => {
        e.preventDefault();
        const { isEdit } = this.state;
        const application = atob(localStorage.getItem('application'));
        if (isEdit) {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (err) return;



                let params = {
                    "tpdashboardId": this.state.editId,
                    "tpdashboard": {
                        ...values,
                        permissionId: values['permissionName']["key"],
                        permissionName: values['permissionName']["label"]

                    }
                }

                // console.log("params", params);
                delete params.tpdashboard.customPermission;

                // delete params.tpdashboard.keys;
                // delete params.tpdashboard.names;
                // delete params.tpdashboard.permissionName;
                if (_.isUndefined(params.tpdashboard.criteriaKey_1)) {
                    delete params.tpdashboard.criteriaKey_1;
                }
                if (_.isUndefined(params.tpdashboard.criteriaKey_2)) {
                    delete params.tpdashboard.criteriaKey_2;
                }

                // console.log("params", params)

                this.props.updateZoho(params);
            });

        } else {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (err) return;
                // let cKeys = {};

                // if (values.names.length > 0) {
                //     let names = [...values.names];
                //     names = names.filter(Boolean);
                //     names.map((v, index) => {
                //         cKeys = {
                //             ...cKeys,
                //             [`criteriaKey_${index + 1}`]: v
                //         }
                //     })
                // };

                // console.log("cKeys", cKeys)
                let params = {};
                if (this.state.showCustomPermission) {
                    params = {
                        "tpdashboard": {
                            ...values,

                        },
                        permission: {
                            "application": application,
                            "module": "DASHBOARD",
                            "submodule": values['permission'],
                            "action": "READ"
                        }

                    }

                    delete params.tpdashboard.permissionName;

                } else {
                    params = {
                        "tpdashboard": {
                            ...values

                        },
                        permission: {
                            "id": values['permissionName']["key"],
                            "application": application,
                            "module": "DASHBOARD",
                            "submodule": values['permissionName']["label"],
                            "action": "READ"
                        }
                    }

                    delete params.tpdashboard.permissionName;
                }
                // console.log("params", params);
                delete params.tpdashboard.customPermission;
                // delete params.tpdashboard.keys;
                // delete params.tpdashboard.names;
                // delete params.tpdashboard.permissionName;
                if (_.isUndefined(params.tpdashboard.criteriaKey_1)) {
                    delete params.tpdashboard.criteriaKey_1;
                }
                if (_.isUndefined(params.tpdashboard.criteriaKey_2)) {
                    delete params.tpdashboard.criteriaKey_2;
                }
                // console.log("params", params);

                this.props.createZoho(params);
            });
        }
    }

    remove = k => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');

        // We need at least one passenger
        if (keys.length === 1) {
            //   return;
            id = 0;
        }


        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k)
        });

    };

    add = () => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    changeCustomerPermission = () => {
        this.props.form.setFieldsValue({ permissionName: undefined }, () => {

            this.setState(prevState => ({
                showCustomPermission: !prevState.showCustomPermission
            }));
        })

    }


    // changeCriteriaKey = e => {
    //     if (e.target.value && id <= 0) {
    //         this.add();
    //     }
    // }
    render() {
        const { form, permissions } = this.props
        const { getFieldDecorator, getFieldValue } = form;

        const { isEdit } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            labelAlign: "left",
            colon: false
        };

        const formItemLayoutWithOutLabel = {
            labelCol: {
                xs: { span: 4 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };



        // getFieldDecorator('keys', { initialValue: [] });
        // const keys = getFieldValue('keys');
        // const formItems = keys.map((k, index) => (
        //     <Form.Item
        //         {...formItemLayoutWithOutLabel}
        //         label={index >= 0 ? `Criteria Key_${index + 1}` : ''}
        //         required={false}
        //         key={k}
        //     >
        //         {getFieldDecorator(`names[${k}]`, {
        //             validateTrigger: ['onChange', 'onBlur'],
        //             rules: [
        //                 {
        //                     required: true,
        //                     whitespace: true,
        //                     message: `Please enter Criteria Key`,
        //                 },
        //             ],
        //         })(<Input placeholder={`Enter Criteria Key`} style={{ width: '50%' }} />)}
        //         {keys.length > 0 ? (
        //             <Icon
        //                 className="dynamic-delete-button"
        //                 type="minus-circle-o"
        //                 onClick={() => this.remove(k)}
        //             />
        //         ) : null}
        //     </Form.Item>
        // ));

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                <Card className="ZohoDashboard" title={<h3> <Icon onClick={this.goBack} style={{ margin: 15, marginLeft: 0 }} type="arrow-left" /> {isEdit ? 'Update' : 'Create'} Zoho Dashboard</h3>}>

                    <Row gutter={12}>
                        <Col span="12">
                            <Form.Item label="Type">
                                {getFieldDecorator('type', {
                                    initialValue: "ZOHO",
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input type!',
                                        },
                                    ],
                                })(
                                    <Select
                                        disabled
                                        showArrow={false}
                                        showSearch
                                        placeholder="Select Type"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >

                                        <Select.Option
                                            value={"ZOHO"}
                                        >
                                            Zoho
                                        </Select.Option>

                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item label="URL">
                                {getFieldDecorator('url', {
                                    initialValue: 'https://analytics.zoho.com/open-view',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input URL!',
                                        },
                                    ],
                                })(
                                    <Input
                                        disabled
                                        placeholder="Enter URL"

                                    />
                                )}
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={12}>
                        <Col span="12">
                            <Form.Item label="Open View ID">
                                {getFieldDecorator('openViewId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input View ID!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Enter View ID"

                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item label="Open View SubID">
                                {getFieldDecorator('openViewSubId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input View SubID!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Enter View SubID"

                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span="12">
                            <Form.Item label="Criteria Key">
                                {getFieldDecorator('criteriaKey', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please input Criteria Key!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Enter Criteria Key"
                                    // onChange={this.changeCriteriaKey}

                                    />
                                )}
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row gutter={12}>
                        <Col span="12">
                            <Form.Item label="Criteria Key 1">
                                {getFieldDecorator('criteriaKey_1', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please input Criteria Key 1!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Enter Criteria Key 1"
                                    // onChange={this.changeCriteriaKey}

                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item label="Criteria Key 2">
                                {getFieldDecorator('criteriaKey_2', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please input Criteria Key 2!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Enter Criteria Key 2"
                                    // onChange={this.changeCriteriaKey}

                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col span={24}>
                            {formItems}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item {...formItemLayout}>
                                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                                    <Icon type="plus" /> Add Criteria Keys
                                    </Button>
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Row gutter={12}>
                        <Col span="12">
                            <Form.Item label="Permission Name" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                {getFieldDecorator('permissionName', {
                                    rules: [
                                        {
                                            required: !this.state.showCustomPermission,
                                            message: 'Please input Permission Name!',
                                        },
                                    ],
                                })(

                                    <Select
                                        showSearch
                                        placeholder="Select Permissions"
                                        labelInValue
                                        disabled={this.state.showCustomPermission || this.state.isEdit}
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {permissions?.map((p, key) => (
                                            <Select.Option
                                                value={p.id}
                                                label={p.submodule}
                                                key={p.id}
                                            >
                                                {p.submodule}
                                            </Select.Option>
                                        ))}
                                    </Select>




                                )}
                            </Form.Item>

                            {/* <Form.Item label=" ">
                                {getFieldDecorator('customPermission', {
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please input Permission Name!',
                                        },
                                    ],
                                })(
                                    <Checkbox disabled={this.state.editId} checked={this.state.showCustomPermission} onChange={this.changeCustomerPermission}>Add new Permission</Checkbox>


                                )}
                            </Form.Item> */}


                        </Col>
                        {!this.state.isEdit &&
                            <Col span={4}>

                                <div onClick={this.changeCustomerPermission} className="show-add">
                                    <span><Icon type="plus-circle" /> Add Permission</span>
                                </div>



                            </Col>
                        }
                    </Row>

                    {this.state.showCustomPermission &&
                        <Row>
                            <Col span="24">
                                <Form.Item label="Permission Name" labelCol={{ span: 3 }}>
                                    {getFieldDecorator('permission', {
                                        rules: [
                                            {
                                                required: this.state.showCustomPermission,
                                                message: 'Please input permission name!',
                                            },
                                        ],
                                    })(


                                        <Row gutter={12}>
                                            <Col span={12}>
                                                <Input
                                                    placeholder="Enter Permission"

                                                />
                                            </Col>
                                            <Col span={6}>

                                                <div onClick={this.changeCustomerPermission} className="show-minus">
                                                    <span><Icon type="minus-circle" /> Cancel</span>
                                                </div>



                                            </Col>

                                        </Row>

                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col span="12">
                            <Form.Item label="Index">
                                {getFieldDecorator('index', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input Index!',
                                        },
                                    ],
                                })(
                                    <InputNumber
                                        style={{ width: 120 }}
                                        min={1}
                                        placeholder="Enter Index"

                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span="6">
                            <Form.Item label="Active">
                                {getFieldDecorator('isActive', {
                                    initialValue: true
                                })(
                                    <Switch defaultChecked />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                </Card>
                <Card>

                    <Row>
                        <Col span={3} style={{ float: 'right' }}>
                            <Button htmlType="submit" type="primary">{isEdit ? 'UPDATE' : 'CREATE'}</Button>
                        </Col>
                        <Col span={3} style={{ float: 'right' }}>
                            <Button onClick={this.goBack} >CANCEL</Button>
                        </Col>




                    </Row>

                </Card>
            </Form>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    zohoCreate: state?.zoho?.zohoCreate,
    zohoData: state?.zoho?.zohoData,
    permissions: state?.zoho?.permissions,
    zohoUpdate: state?.zoho?.zohoUpdate,
});
const mapDispatchToProps = dispatch => ({
    createZoho: (params) => dispatch(actions.createZoho(params)),
    getZohoByID: (id) => dispatch(actions.getZohoByID(id)),
    getZohoPermissions: (payload) => dispatch(actions.getZohoPermissions(payload)),
    updateZoho: (params) => dispatch(actions.updateZoho(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ZohoForm', onValuesChange: (props, val) => { } })(ZohoForm));