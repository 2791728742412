
export const initialState = {
}

export const FETCH_EXPECTED_CONSIGMENTS_BEGIN = 'FETCH_EXPECTED_CONSIGMENTS_BEGIN';
export const FETCH_EXPECTED_CONSIGMENTS_SUCCESS = 'FETCH_EXPECTED_CONSIGMENTS_SUCCESS';
export const FETCH_EXPECTED_CONSIGMENTS_FAILURE = 'FETCH_EXPECTED_CONSIGMENTS_FAILURE';

export const UPDATE_CONSIGMENT_BEGIN = 'UPDATE_CONSIGMENT_BEGIN';
export const UPDATE_CONSIGMENT_SUCCESS = 'UPDATE_CONSIGMENT_SUCCESS';
export const UPDATE_CONSIGMENT_FAILURE = 'UPDATE_CONSIGMENT_FAILURE';