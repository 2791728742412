import React, { useEffect, useState, useRef } from 'react';
import customerForm from './customer.form';
import FormWrapper from '../../../ui/formComponent/form';
import * as apiUtils from '../../../../common/utils/apiGateway';
import { Constants } from '../../../../common/utils/constants';
import { createCustomer, updateCustomer, getCustomerData } from "../common/services/index";
import { Spin, Modal } from 'antd';

const { confirm } = Modal;

function Customer(props) {

    const [loaded, SetLoaded] = useState(false);
    const [schema, SetSchema] = useState(customerForm.schema);
    const [uiSchema, setUISchema] = useState(customerForm.uiSchema);
    const [formData, SetFormData] = useState({});

    const { readOnly, customerRef, currentStep, customerId, isCancel } = props;
    const { setCustomer, setCurrentStep, setCancel } = props;

    const onSubmit = (formProps) => {
        if (formProps) {
            
            confirm({
                title: 'Are you sure to submit?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    const { name, code, phoneNumber, contactPerson, emailId, gstin } = formProps.customerDetails;
                    const { attributes } = formProps.customerDetails;
                   
                    let params = {
                        customerName: name,
                        code: code?.toUpperCase(),
                        contactNumber: phoneNumber,
                        email: emailId,
                        contactPerson,
                        ...(gstin && { gstNumber: gstin }),
                        attributes: {
                            'threePlReferenceNumber': attributes?.threePlReferenceNumber || attributes?.threePlReferenceNumber === 'true' ? true : false,
                            'online': attributes?.online || attributes?.online === 'true' ? true : false,
                            ...(attributes?.kgPerCft && {"kgPerCft": attributes?.kgPerCft})
                        },
                        "customerType": "CUSTOMER"

                    }

                    const create = async () => {
                        const data = await createCustomer(params);

                        SetLoaded(true);
                        if (data) {
                            setCustomer(data.customer);

                            // setCurrentStep(currentStep + 1);                    
                            SetFormData({
                                id: data.customer.id,
                                customerDetails: {
                                    ...data.customer,
                                    "attributes": {
                                        "online": (data?.customer.attributes?.online === true || data?.customer.attributes?.online === "true") ? true : false,
                                        "threePlReferenceNumber": (data?.customer.attributes?.threePlReferenceNumber === true || data?.customer.attributes?.threePlReferenceNumber === "true") ? true : false,
                                    }
                                },
                                // insuranceDetails: {
                                //     insurance: response[0]?.insurance
                                // }
                            });
                            setCurrentStep(currentStep + 1)
                        }


                    }
                    const update = async () => {
                       
                        params = {
                            ...params,
                            id: customerId,
                            organisationId: formProps.customerDetails.organisationId
                        }

                        const data = await updateCustomer(customerId, params);
                      
                        if (data) {
                            setCustomer(data);
                            setCurrentStep(currentStep + 1);
                            if (typeof props.setCustomerReadOnly === "function") {
                                props.setCustomerReadOnly(true);
                            }
                            SetFormData({
                                id: data.id,
                                customerDetails: {
                                    ...data,
                                    "attributes": {
                                        "online": (data?.attributes?.online === true || data?.attributes?.online === "true") ? true : false,
                                        "threePlReferenceNumber": (data?.attributes?.threePlReferenceNumber === true || data?.attributes?.threePlReferenceNumber === "true") ? true : false,
                                    }
                                }
                            });
                         

                        }


                    }

                    if (customerId) {
                        update();
                    } else {
                        create();
                    }
                }
            })



        }
    }



    useEffect(() => {
        if (props.customerId) {
            const params = {
                "filters": {
                    "customerTypes": [
                        "CUSTOMER"
                    ],
                    "status": [
                        "ACTIVE"
                    ],
                    "fetchMode": "BASIC",
                    "partnerIds": [
                        Constants.PARTNER_ID
                    ],
                    "ids": [props.customerId]
                }
            }
            const getCustomerDetails = new Promise(resolve => {
                getCustomerData(params).then(response => {

                    if (response) {
                        resolve(response[0]);
                    }

                });
            })

            const getOrganisations = new Promise(resolve => {
                apiUtils.apiRequest({
                    method: "GET",
                    url: "/b2b/v1/organisations/"
                }).then(response => {
                    const enums = [];
                    const enumNames = [];
                    response.data.response.organisations.map(org => {
                        enums.push(org.id);
                        enumNames.push(org.name);
                    })
                    resolve({ enum: enums, enumNames });
                });
            })

            const loadData = async function () {
                const response = await Promise.all([getCustomerDetails]);

                setCustomer(response[0]); // check later
                SetSchema(current => {
                    current.properties.customerDetails.properties.organisationId = {
                        ...current.properties.customerDetails.properties.organisationId,
                        ...response[1]
                    }
                    return current;
                });
               
                SetFormData({
                    id: response[0]?.id,
                    customerDetails: {
                        ...response[0],
                        "attributes": {
                            "online": (response[0]?.attributes?.online === true || response[0]?.attributes?.online === "true") ? true : false,
                            "threePlReferenceNumber": (response[0]?.attributes?.threePlReferenceNumber === true || response[0]?.attributes?.threePlReferenceNumber === "true") ? true : false,
                            ...(response[0]?.attributes?.kgPerCft && {"kgPerCft": response[0]?.attributes?.kgPerCft})
                        }
                    },
                    // insuranceDetails: {
                    //     insurance: response[0]?.insurance
                    // }
                });
                setUISchema({
                    "customerDetails": {
                        ...uiSchema.customerDetails,
                        "code": {
                            ...uiSchema.customerDetails.code,
                            disabled: true
                        }
                    }
                })
            }



            loadData.call(this);
        }

    }, []);

    useEffect(() => {

        if (formData.id == props.customerId) {
            SetLoaded(true)
        }
      
    }, [formData]);
    const onChange = params => {
        SetFormData(params);
        if (!isCancel) {
            
            setCancel(true);
            
        } 
    }
   
    return (
        <Spin spinning={!loaded} className="flex-box justify-content-center" style={{ display: "flex" }}>
            {
                loaded &&
                <FormWrapper schema={schema} uiSchema={uiSchema} ref={customerRef}
                    onChange={onChange}
                    
                    hideSubmit={true}
                    errorList={customerForm.errorList} validations={customerForm.validations}
                    formData={formData} onSubmit={onSubmit} readonly={readOnly}
                    fieldsPerRow={2} showColumnSplitter={true} bordered={true} />
            }
        </Spin>
    )
}

export default Customer;