import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class DrsDraftService {

    fetchRunsheetDraft(payload = {}){
        return apiRequest({
            url: `/b2c/v1/runsheetdraft/list`,
            method: "POST",
            data: payload
        })
    }

    fetchuserByName(payload = {}){
        return apiRequest({
            url: `/b2c/v1/runsheetdraft/fetch`,
            method: "POST",
            data: payload
        })
    }

    getUsersByLocation() {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${Constants.userLocationId}/users?loggedInUserTokken=true`;
        return apiRequest({
            url,
            method: "GET"
        })
    }

    assignRunsheet(payload = {}){
        return apiRequest({
            url: `/b2c/v1/runsheetdraft/convert-to-runsheet`,
            method: "POST",
            data: payload
        })
    }

    // createLocation(location){
    //     const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations`;
    //     return apiRequest({
    //         url,
    //         method: "POST",
    //         data: location
    //     })
    // }

    // updateLocation(locationId, location){
    //     const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${locationId}`;
    //     return apiRequest({
    //         url,
    //         method: "PUT",
    //         data: location
    //     })
    // }

    // deleteLocation(locationId) {
    //     return apiRequest({
    //         url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${locationId}`,
    //         method: "DELETE",
    //     })
    // }

}
