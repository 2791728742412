export const initialState = {
    loading: false
}

export const GET_INVENTORY_BEGIN = 'GET_INVENTORY_BEGIN';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAILURE = 'GET_INVENTORY_FAILURE';

export const GET_CLIENT_BEGIN = 'GET_CLIENT_BEGIN';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

// export const GET_DRS_DRAFT_BEGIN = 'GET_DRS_DRAFT_BEGIN';
// export const GET_DRS_DRAFT_SUCCESS = 'GET_DRS_DRAFT_SUCCESS';
// export const GET_DRS_DRAFT_FAILURE = 'GET_DRS_DRAFT_FAILURE';
