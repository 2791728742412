import React from 'react';
import Barcode from '../../../ui/barcode';
import ScanService from "../../../../services/inbound/scan.service";
import { Empty, Icon, Tag } from 'antd';
import './inventory-manifest.scss';
import moment from 'moment';
import { dataGet, getLocationData } from '../../../../common/utils/utils';


const scanService = new ScanService;


const InventoryManifest = ({ consignmentDetail }) => {

  let shipmentLength = 0;
  if (consignmentDetail?.consignments) {
    const waybills = [];

    consignmentDetail.consignments.map(consignmentDetails => {
      waybills.push(consignmentDetails.waybillNo);
      shipmentLength += consignmentDetails.totalShipmentCount;
    });
    if (waybills.length) {
      scanService.getWaybills({ waybills: waybills }).then(responseData => {
        if (responseData) {
          shipmentLength = 0;

          responseData.data.response.consignments.map(consignment => {
            shipmentLength += consignment.totalShipmentCount;
            for (let i = 0; i < consignmentDetail.consignments.length; i++) {
              if (consignmentDetail.consignments[i].waybillNo === consignment.waybillNo) {
                consignmentDetail.consignments[i] = consignment;
                break;
              }
            }
          });
        }
      });
    }
  }
  const printPage = () => {
    (window).print();
  }

  const getStatus = () => {
    switch (consignmentDetail.manifestStatus) {
      case 'LINKED': return <Tag color="#93be52">{consignmentDetail.manifestStatus}</Tag>
      case 'CLOSED': return <Tag color="#fe8a7d">{consignmentDetail.manifestStatus}</Tag>
      case 'PENDING': return <Tag color="#fc6180">{consignmentDetail.manifestStatus}</Tag>
      case 'COMPLETED': return <Tag color="#ffb64d">{consignmentDetail.manifestStatus}</Tag>
      case 'PARTIAL_COMPLETED' || consignmentDetail.manifestStatus == 'DELETED': return <Tag color="#3b5a98">{consignmentDetail.manifestStatus}</Tag>
    }
  }
  return (
    <>
      {consignmentDetail ?
        <div className="inventory-manifest">
          <div className="inventory_drs_holder">
            <div className="waybill-header-info">

              <span class="awb-number">{consignmentDetail?.manifestCode}</span>

              <span className="label">
                {getStatus()}
              </span>
              <Icon type="printer" onClick={() => printPage()} />
              <ul className="quick_info">
                {
                  consignmentDetail.consignments ? <li>Waybill - {consignmentDetail.consignments.length}<span class="splitter">|</span></li> : <li>Waybill - <span class="splitter">|</span></li>

                }
                {
                  shipmentLength ? <li>Shipment - {shipmentLength} </li> : <li>Shipment - 1</li>
                }
                {
                  consignmentDetail.originatedLoc && <li className="float-right">{consignmentDetail.originatedLoc.name} </li>
                }

              </ul>
              <ul class="other-info">
                <li>
                  <span class="fn-bold">Create On: </span>
                  <span className="info-value">{moment(consignmentDetail.createdAt).format("DD/MM/YYYY HH:mm")} hrs</span>
                </li>
              </ul>
              <div class="driver-details">
                <span class="fn-bold">Driver: </span>
                {consignmentDetail.thc && consignmentDetail.thc.asset &&
                  <span className="info-value">
                    {consignmentDetail.thc.asset.driverName} | {consignmentDetail.thc.asset.contactNumber} | {consignmentDetail.thc.asset.vehicleType} | {consignmentDetail.thc.asset.registrationNumber}
                  </span>
                }

              </div>
              <ul class="more-info-list">
                {
                  consignmentDetail?.consignments && consignmentDetail.consignments.map(congsignment => (
                    <li>
                      <div class="">
                        <div class="info-left">
                          <div class="waybill-details">
                            <div class="fn-bold">
                              AWB: {congsignment.waybillNo}
                            </div>
                            <div class="wabill-count">
                              Shipments: {congsignment.totalShipmentCount} | {dataGet(congsignment, 'totalWeight')} Kg(s)
                            </div>
                          </div>
                        </div>
                        <div class="info-right text-right">
                          <div class="destination">
                            {dataGet(congsignment, 'consignee.name')} | {getLocationData(dataGet(congsignment, 'consignee.address.pincode'))}
                          </div>
                          <div class="destination">
                            ETA: {moment(congsignment.expectedDeliveryDate).format('DD/MM/YYYY HH:mm')} hrs
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                }

              </ul>
            </div >
          </div >
        </div>
        : <Empty
          description={
            <span>
              Loading Manifest
            </span>
          }
        />
      }
    </>
  )
}

export default InventoryManifest;