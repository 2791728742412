import React, { Component } from "react";
import ImageGallery from "../../components/image-gallery/image-gallery";
import { Modal } from "antd";
import UserRemarks from "./user-remarks";

const { confirm } = Modal;
class PodImageViewer extends Component{
     
    podImageMaxCount =  JSON.parse(atob(localStorage.getItem('_configrations'))).imageValidation?.epod?.maxCount ?
                        JSON.parse(atob(localStorage.getItem('_configrations'))).imageValidation?.epod?.maxCount :
                        5
    constructor(props){
        super(props);
    }

    onImageUpload = (updatedImages) => {
        this.props.onImageUpdate(updatedImages);
        this.props.toggleImageUploadState(false);
    }

    onDeleteImage = delIndex => {
        confirm({
            title: 'Do you want to delete the proof?',
            onOk: () => {
                let podImages = this.props.consignment?.podImages?.length>0 ? this.props.consignment.podImages : [];
                podImages = podImages.reduce((current, next, index) => {
                    if(delIndex != index){
                        current.push(next);
                    }
                    return current;
                }, [])
                this.props.onImageUpdate(podImages);
            }
        });
    }

    userRemarksComponent = () => {
        const podNotVerified = this.props.consignment?.podVerificationStatus != "POD_VERIFIED";
        return (
            <UserRemarks consignment={this.props.consignment} initialUserRemarks={this.props.consignmentDetails?.userRemarks}
             onRemarksUpdate={this.props.onRemarksUpdate} updatePermissions={this.props.updateRemarksPermissions && podNotVerified}/>
        )
    }

    render(){

        const { createPermissions, deletePermissions } = this.props.verificationPermissions;
        const podNotVerified = this.props.consignment?.podVerificationStatus != "POD_VERIFIED";

        return (
            <div className="border-grey spacer-l border-radius-m">
                <ImageGallery imageArray={this.props.consignment?.podImages?.length>0 ? this.props.consignment.podImages : []}
                 onUploadSuccess={this.onImageUpload} onDeleteImage={this.onDeleteImage} descriptionComponent={this.userRemarksComponent()}
                 deleteEnabled={deletePermissions && podNotVerified}
                 galleryId="PodImages" goToIndex={this.props.goToIndex} 
                 beforeUpload={() => this.props.toggleImageUploadState(true)}
                 uploadEnabled={createPermissions && this.props.consignment.podImages.length < (this.podImageMaxCount?this.podImageMaxCount:5) && podNotVerified} />
            </div>
        );
    }
}

export default PodImageViewer;