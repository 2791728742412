import React, { useEffect, useState } from 'react';
import { Card, Input, Button, Icon, Spin, Badge } from 'antd';
import IndentTable from '../components/indent.table';
import IndentSummary from "../components/indent.summary";
import FilterIcon from "../../../../../assets/img/icons/filter-icon.svg";
import _ from 'lodash';
import "../indent.listing.scss";
import IndentFilters from '../components/indent.filters';
import { IndentStatusLOV } from "../state/indent.listing.constants"
import { canAllow } from '../../../../../common/utils/utils';
import { permissionTags } from '../../../../../configs/permissionTags';

function IndentListing(props) {
    const { setFilterModal, setFilterData, applyFilters, resetFilters, setFilters, setParams, selectedSummaryIds, setSelectedSummaryIds, filtersIndicator } = props;
    const { filterModal, filterData, filters, indentData, params } = props;
    const [searchText, setSearchText] = useState(undefined);
    const [summaries, setSummaries] = useState(props.summaries);

    useEffect(_ => {
        var temp = props.summaries.reduce((c, n) => {
            if (!isNaN(n.value)) {
                c.push(n)
            }
            n["nonClickable"] = n.lovCode == 30010;
            return c;
        }, []);
        setSummaries(temp);
    }, [props.summaries])

    const onUpdatedSummary = _.debounce((selectedSummary) => {
        const lengthCheck = selectedSummaryIds.length != selectedSummary.length;
        const keysCheck = selectedSummaryIds.filter(s => selectedSummary.indexOf(s) < 0).length > 0;
        setSelectedSummaryIds(selectedSummary);
        if (lengthCheck || keysCheck) {
            let newParams = {
                ...params,
                pageNo : 1,
                "status": selectedSummary.reduce((c, n) => {
                    if (IndentStatusLOV[n.toString()]) {
                        c.push(IndentStatusLOV[n.toString()])
                    }
                    return c;
                }, [])
            }
            setParams(newParams)
        }

    }, 1000);
    const createIndent = _ => props.history.push("/appv2/indent/create")
    
    const changeTable = (sorter, pagination) => {
                
        const { columnKey, order } = sorter;
        let newParams = { ...params };
        const { orderBy } = newParams;
        if (order) {
            const setOrder = order === 'ascend' ? 'ASC' : 'DESC';
            newParams = {
                ...newParams,
                orderBy: {
                    ...orderBy,
                    orderByParams: [{
                        column: columnKey,
                        direction: setOrder
                    }]
                }
            }

        } else {
            newParams = {
                ...newParams,
                orderBy: {
                    ...orderBy,
                    orderByParams: [{
                        column: "id",
                        direction: "DESC"
                    }]
                }
            }
        }
        const { current, pageSize } = pagination;
        newParams = {
            ...newParams,
            "pageNo": current,
            pageSize
        }

        setParams(newParams);
    }
    return (
        <div className="flex-column flex-gap-l indent">
            <div className="indent_listing-title">
                Indent Management
            </div>
            <Card className="indent_listing-header">
                <div className="flex-box flex-gap-l justify-content-space-between align-items-center">
                    <div className="flex-box flex-5 justify-content-space-between flex-gap-m">
                        <div className="flex-3">
                            <Input
                                placeholder="Search by Indent, Customer, Shipper, Placement, Destination, Indent Status or Docket"
                                prefix={<Icon type="search" />}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                suffix={
                                    searchText ?
                                        <Icon type="close-circle"
                                            onClick={() => {
                                                setSearchText(undefined);
                                                let newParams = {
                                                    ...params,
                                                    pageNo : 1,
                                                    'partialContentSearch': null
                                                }
                                                setParams(newParams)
                                            }}
                                        />
                                        : ""
                                }
                                onPressEnter={(e) => {
                                    let newParams = {
                                        ...params,
                                        pageNo : 1,
                                        "partialContentSearch": e.target.value?.trim() === "" ? null : e.target.value
                                    }
                                    setParams(newParams)
                                }}
                            />
                        </div>
                        <Badge dot={filtersIndicator}>
                            <Button className="lsn-btn-secondary" onClick={() => setFilterModal(true)}>
                                <img src={FilterIcon} alt="filter" />
                                Filters
                            </Button>
                        </Badge>
                        <Button loading={props.downloadStatus === 'BEGIN'} className="lsn-btn-secondary" onClick={props.download}>Download</Button>
                    </div>
                    {
                        canAllow(permissionTags.indent.indent.create) &&
                        <div className="flex-box flex-1 justify-content-end">
                            <Button className="lsn-btn-primary" onClick={createIndent}>Create Indent</Button>
                        </div>
                    }
                </div>
            </Card>
            <Card>
                <Spin spinning={props.loading}>
                    <IndentSummary
                        defaultSelected={[]}
                        selected={selectedSummaryIds}
                        data={summaries}
                        {...props}
                        onUpdatedSummary={onUpdatedSummary}
                        clickable={true}
                    />
                </Spin>
            </Card>
            <Spin spinning={props.loading}>
                <IndentTable
                    data={indentData}                    
                    changeTable={changeTable}
                    openViewPage={(id, index) => {
                        props.history.push("/appv2/indent/view", { id, index, params })
                    }}
                    copyIndent={props.copyIndent}
                    {...props}
                />
            </Spin>
            {filterModal &&
                <IndentFilters
                    setFilterModal={setFilterModal}
                    visible={filterModal}
                    setFilterData={setFilterData}
                    applyFilters={applyFilters}
                    resetFilters={resetFilters}
                    setFilters={setFilters}
                    filters={filters}
                    filterData={filterData}
                />
            }
        </div>
    )
}

export default IndentListing;