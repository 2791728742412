import React, { PureComponent } from 'react';
import './b2b-lite-booking.scss'
import { Card, Form, Icon, Input, Button, InputNumber, Radio, Select, Row, Collapse, Divider, Checkbox, Steps, Modal, Tooltip } from 'antd';
import { Col } from 'antd';
import { Panel } from '../../../ui/accordian/Accordian';
import { customerTypes, Constants, UPLOAD_BUTTON_TYPE, validationRules } from '../../../../common/utils/constants';
import RetailerFormModal from './customer-management/customer-management';
import { getCustomerList, getBookingOffice, generateWaybillID, getOriginLocation, getDesignationLocation, getShippingDimensions, getGoodsTypes, getPickupList, liteBookingCreate_Update, getConsignmentsById, getShipperList } from './actions';
import { connect } from 'react-redux';
import { localStorageUtils, notifyApiSuccess, notifyApiError, windowRedirect, getAllPersmission, dataGet, canAllow } from '../../../../common/utils/utils';
import { DatePicker } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import editSvg from '../../../../assets/img/icons/edit.svg';
import { permissionTags } from '../../../../configs/permissionTags';
import scannerIcon from '../../../../assets/img/icons/scanner.svg';
import ImageAdd from '../../../../assets/img/icons/image_add.svg';
import InvoiceTotal from '../../../../assets/img/icons/invoice_total.svg';
import EwayTotal from '../../../../assets/img/icons/eway_verify_icon.svg';
import RupeeIcon from '../../../../assets/img/icons/rupee_icon.svg';

import * as apiUtils from '../../../../common/utils/apiGateway';
import { Mixpanel } from '../../../../utils/mixpanel';
import FileUpload from '../../../ui/file-upload/file.upload';
import ImageViewer from '../../../verification/trips/ImageViewer'
import CurrencyTemplate from '../../../cod/templates/Currency';

const InputGroup = Input.Group;
let id = 0;
let newId = 0;
const { Step } = Steps;
const { confirm } = Modal;

class B2bLitePTLBooing extends PureComponent {

    constructor(props) {
        super(props);
        const inboundTag = Constants.SELECTED_APPLICATION === 'B2B' ? permissionTags.inboundView : permissionTags.inbound;
        if (!canAllow(inboundTag?.booking?.create)) {
            this.props.history.goBack();
            // windowRedirect('load/dashboard/expected');
        }
    }

    state = {
        retailerCustomer: null,
        retailNewCustomer: null,
        customerTypesOptions: customerTypes,
        isModalVisible: false,
        selectedCusotmer: {},
        isGoodsMandatory: false,
        selectedDimensions: [],
        selectedDimensionsId: [],
        isSubmitDisabled: false,
        checkList: ['Docket', 'Invoice', 'Manifest'],
        customerList: {
            loading: false,
            data: []
        },
        shipperList: {
            loading: false,
            data: []
        },
        bookingOfficeList: {
            loading: false,
            data: []
        },
        originLocationList: {
            loading: false,
            data: []
        },
        designationLocationList: {
            loading: false,
            data: []
        },
        shipmentDimensionsList: {
            loading: false,
            data: []
        },
        goodsTypesList: {
            loading: false,
            data: []
        },
        genaratedWaybill: {
            loading: false,
            data: []
        },
        pickupList: {
            loading: false,
            data: []
        },
        bookingEdit: {
            loading: false,
            data: []
        },
        ewayBills: [],
        showRDSInfo: false,
        paymentTypes: [],
        current: 0,
        fields: {
            'shipper': undefined,
            'OriginLocation': undefined,
            'CustomerName': undefined,
            'DestinationLocation': undefined,
            'WaybillNumber': undefined,
            'docketVerifiedURL': undefined
        },
        mandatoryFields: [],
        isMandatoryDimensions: false,
        isDisabledAddedBox: false,
        showGoodTypes: true,
        showDimesions: true,
        showAny: false,
        clearWaybill: true,
        docketVerifiedURL: [],
        invoiceTotal: 0,
        imagesCount: 0,
        show3PLDocket: false,
        acceptedFiles: ['.jpeg', '.jpg', '.png', ".JPEG", ".JPG", ".PNG"]
    }

    componentDidMount() {
        Mixpanel.identify(this.props.header?.userInfo?.loggedPartnerId);
        Mixpanel.people.set({
            $first_name: this.props?.header?.userInfo?.loggedPartnerId
        });
        Mixpanel.track("PTL Booking initiated", { 'ptlBooking': moment().valueOf() })
        if (this.props.formId > 0) {
            this.props.getConsignmentsById(this.props.formId);
        }
        this.props.getCustomerList()
        this.loadBookingOfficeList();
        this.loadPickupList();
    }

    loadOriginLocation(customerId) {
        const payload = {
            "filters": {
                "roles": [
                    "DELIVERY",
                    "PICKUP"
                ],
                "entityTypes": [
                    "CUSTOMER"
                ],
                "deviceFilter": {
                    "deviceType": "MOBILE",
                    "view": "LITE_LOCATION_LIST"
                },
                "fetchObjects": [
                    "LOCATION_SHORTCODE",
                    "ADDRESS"
                ],
                "entityIds": [
                    customerId
                ],
                "status": true
            }
        }
        this.props.getOriginLocationList(payload);
    }

    loadDesignationLocation(customerId) {
        const payload = {
            "filters": {
                "customerIds": [
                    customerId
                ],
                "deviceFilter": {
                    "deviceType": "MOBILE"
                },
                "fetchObjects": [
                    "CONTACT_FAV_CONSIGNMENT_CONTACT",
                    "ADDRESS",
                    "PINCODE",
                    "CONSIGNMENT_CONTACT_PINCODE",
                    "CONSIGNMENT_CONTACT_ADDRESS",
                    "CONTACT_FAV_LOCATION"
                ],
                "status": true
            }
        }
        this.props.getDesignationLocation(payload);
    }

    loadBookingOfficeList() {
        const payload = {
            "filters": {
                "entityTypes": [
                    "PARTNER"
                ],
                "deviceFilter": {
                    "deviceType": "MOBILE",
                    "view": "LITE_LOCATION_LIST"
                },
                "fetchObjects": [
                    "LOCATION_SHORTCODE",
                    "ADDRESS"
                ],
                "status": true
            }
        };
        this.props.getBookingOfficeList(payload);
    }

    loadShipmentDimensionsList(customerId) {
        const payload = {
            "partnerIds": [
                localStorage.getItem('loggedPartnerId')
            ],
            "isAdhocBox": false,
            "customerIds": [
                customerId
            ],
            "isActive": true
        };
        this.props.getShippingDimensions(payload);
    }

    loadGoodsTypesList(customerId) {
        const payload = {
            "partnerIds": [
                localStorage.getItem('loggedPartnerId')
            ],
            "customerIds": [
                customerId
            ],
            "isActive": true
        };
        this.props.getGoodsTypes(payload);
    }

    loadPickupList() {
        const payload = {
            "filters": {
                "locationIds": [Constants.userLocationId],
                "statuses": ["ARRIVED_AT_HUB"],
                "deviceFilter": {
                    "view": "LITE_LEAD_LIST"
                }
            }
        };
        this.props.getPickupList(payload);
    }

    componentDidUpdate(oldProps) {
        if (this.props.customerList && oldProps.customerList !== this.props.customerList) {
            this.setState({
                customerList: this.props.customerList
            })
        }

        if (this.props.shipperList && oldProps.shipperList !== this.props.shipperList) {
            this.setState({
                shipperList: this.props.shipperList
            })
        }

        if (this.props.bookingOfficeList && oldProps.bookingOfficeList !== this.props.bookingOfficeList) {

            this.setState({
                bookingOfficeList: this.props.bookingOfficeList
            })
        }
        if (this.props.originLocationList && oldProps.originLocationList !== this.props.originLocationList) {
            this.setState({
                originLocationList: this.props.originLocationList
            })
            if (this.state.selectedPickup)
                this.props.form.setFieldsValue({
                    OriginLocation: this.state.selectedPickup['pickupLocation']['id'],
                })
        }

        if (this.props.designationLocationList && oldProps.designationLocationList !== this.props.designationLocationList) {
            this.setState({
                designationLocationList: this.props.designationLocationList
            })
        }

        if (this.props.shipmentDimensionsList && oldProps.shipmentDimensionsList !== this.props.shipmentDimensionsList) {
            this.setState({
                shipmentDimensionsList: this.props.shipmentDimensionsList
            })
        }

        if (this.props.goodsTypesList && oldProps.goodsTypesList !== this.props.goodsTypesList) {
            this.setState({
                goodsTypesList: this.props.goodsTypesList
            })
        }

        if (this.props.genaratedWaybill && oldProps.genaratedWaybill !== this.props.genaratedWaybill) {
            this.setState({
                genaratedWaybill: this.props.genaratedWaybill
            })
            this.props.form.setFieldsValue({
                WaybillNumber: this.props.genaratedWaybill.data
            })
        }

        if (this.props.pickupList && oldProps.pickupList !== this.props.pickupList) {
            this.setState({
                pickupList: this.props.pickupList
            })
        }

        if (this.props.bookingEdit && oldProps.bookingEdit !== this.props.bookingEdit) {
            this.setState({
                bookingEdit: this.props.bookingEdit
            }, () => {
                this.loadEditableFormData(this.state.bookingEdit.data);
            })
        }

        if (this.props.bookingCreation && oldProps.bookingCreation !== this.props.bookingCreation) {
            if (this.props.bookingCreation.data[0].sync) {
                this.setState({ loadBooking: false });
                Mixpanel.track("PTL Booking Creation Successfult", { 'ptlBookingCreationSuccess': moment().valueOf() })
                notifyApiSuccess('Booking Successfully Created.', 'SUCCESS');
                this.props.form.resetFields();
                this.props.history.goBack();
                // windowRedirect('load/dashboard/expected');
            } else {
                this.setState({ isSubmitDisabled: false, loadBooking: false })
                notifyApiError(this.props.bookingCreation.data[0].reason, 'FAILED');
            }
        }
    }

    onCustomerTypeChanged = e => {
        if (e.target.value === 'RETAIL') {
            let paymentTypes = [];

            paymentTypes.push({
                id: "TOPAY",
                label: 'To Pay'
            }, {
                id: "PREPAID",
                label: 'Paid'
            });

            this.setState({
                isModalVisible: true,
                paymentTypes
            })
            this.props.form.resetFields();
        }
        this.props.form.resetFields();
    }

    onRetailerCustomerEdit = () => {
        this.setState({
            isModalVisible: true
        });
        this.props.form.setFieldsValue({
            CustomerType: 'RETAIL'
        })

    }

    onCustomerManagementCanceled = e => {
        this.setState({
            isModalVisible: false
        })
        this.props.form.setFieldsValue({
            CustomerType: 'CONTRACT'
        })
        if (this.props.formId === 0 || this.props.bookingEdit.data.customer.customerType !== 'ADOHC_CUSTOMER') {

            // this.props.form.setFieldsValue({
            //     CustomerType: 'CONTRACT'
            // })
        }
    }

    onCustomerManagementSuccess = RetailerInfo => {
        this.setState({
            isModalVisible: false,
            retailerCustomer: RetailerInfo,
            retailNewCustomer: {
                "customer": RetailerInfo
            }
        })

        this.props.form.setFieldsValue({
            CustomerType: 'RETAIL',
            CustomerName: RetailerInfo.name,
            DestinationLocation: RetailerInfo.retailerSelectedAddress,
            OriginLocation: Number(Constants.userLocationId)
        }, () => {
            this.loadOriginLocation(RetailerInfo?.id);
            this.loadDesignationLocation(RetailerInfo?.id);
        })
    }

    onBookingCancel = () => {
        this.props.history.goBack()
    }

    onBookingReset = () => {
        this.props.form.resetFields();
    }


    onShipperSelected = id => {
        // console.log("id", id)
    }

    onCustomerSelected = id => {
        const { setFieldsValue } = this.props.form;
        this.setState({ paymentTypes: [] })
        setFieldsValue({
            'shipper': undefined,
            'OriginLocation': undefined,
            'DestinationLocation': undefined
        })

        //check wigh backend
        // const { goods_type_mandatory_customer_ids } = this.props.configurations;
        // if (goods_type_mandatory_customer_ids && goods_type_mandatory_customer_ids?.find(val => val == id)) {
        //     this.setState({
        //         isGoodsMandatory: true
        //     })
        // } else {
        //     this.setState({
        //         isGoodsMandatory: false
        //     })
        // }
        const selectedCustomer = this.state.customerList.data.filter(list => list.id === id)[0];
        if (_.hasIn(selectedCustomer, 'attributes') && _.hasIn(selectedCustomer?.attributes, "threePlReferenceNumber") && selectedCustomer?.attributes?.threePlReferenceNumber === "true") {
            this.setState({ show3PLDocket: true });
        } else {
            this.setState({ show3PLDocket: false });
        }
        let paymentTypes = [];
        const types = selectedCustomer?.paymentTypes?.map(ptype => {
            if (ptype === "CREDIT") {
                paymentTypes.push({
                    id: ptype,
                    label: 'To be billed'
                });
            }
            if (ptype === "TOPAY") {
                paymentTypes.push({
                    id: ptype,
                    label: 'To Pay'
                });
            }
            if (ptype === "PREPAID") {
                paymentTypes.push({
                    id: ptype,
                    label: 'Paid'
                });
            }

        });
        this.setState({
            selectedCustomer,
            paymentTypes
        });

        const params = {
            "entityType": "CUSTOMER",
            "entityId": id
        };

        this.props.getShipperList(params);
        this.loadOriginLocation(id);
        this.loadDesignationLocation(id);
        this.loadBookingOfficeList();
        this.loadShipmentDimensionsList(id);
        this.loadGoodsTypesList(id);

        if (_.hasIn(this.props.configurations, 'restrictBooking') && _.hasIn(this.props.configurations?.restrictBooking, 'rds')) {
            const { restrictBooking } = this.props.configurations;
            const { rds } = restrictBooking;
            if (rds.includes(id.toString())) {
                this.setState({ showRDSInfo: true });
            } else {
                this.setState({ showRDSInfo: false });
            }

        }


    }

    onPickupSelected = id => {
        if (id) {
            const selectedPickup = this.state.pickupList.data.filter(list => list.id === id)[0];
            this.setState({
                selectedPickup
            })
            this.onCustomerSelected(selectedPickup['customer']['id']);
            this.props.form.setFieldsValue({
                CustomerName: selectedPickup['customer']['id'],
                ShippingCount: selectedPickup['shipments'][0]['count'],
                TotalWeight: selectedPickup['shipments'][0]['weight'],
                ChargableWeight: selectedPickup['shipments'][0]['weight'],
            })
        } else {
            this.props.form.resetFields();
        }
    }

    remove = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const key = keys.filter(key => key.id !== keys[k].id);
        form.setFieldsValue({
            keys: key,
            ShipmentDimensions: key.map(item => (item.id))
        });



        this.setState({ selectedDimensionsId: key.map(k => k.id) }, () => {
            if (this.state.selectedDimensionsId.length <= 0) {
                // let { showGoodTypes } = this.state;
                // showGoodTypes = true;
                // this.setState({ showGoodTypes })

                if (this.state.showAny) {
                    let { isGoodsMandatory } = this.state;
                    if (_.isUndefined(this.props.form.getFieldValue('GoodsTypes'))) {
                        isGoodsMandatory = true;
                        this.setState({ isGoodsMandatory })
                    }
                }
            }
        })
    };

    add = (type) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat({ id: id++, type: type });
        form.setFieldsValue({
            keys: nextKeys,
        });
        // let { showGoodTypes } = this.state;
        // if (_.isUndefined(this.props.form.getFieldValue('GoodsTypes'))) {
        //     showGoodTypes = false;
        //     this.setState({ showGoodTypes })
        // }

        if (this.state.showAny) {
            let { isGoodsMandatory } = this.state;
            if (_.isUndefined(this.props.form.getFieldValue('GoodsTypes'))) {
                isGoodsMandatory = false;
                this.setState({ isGoodsMandatory })
            }
        }
    };

    onDimensionsSelected = ids => {

        const formKeys = this.props.form.getFieldValue('keys');
        const selectedDimensions = this.state.shipmentDimensionsList.data.filter(list => list.id === ids[ids.length - 1])[0];

        this.props.form.setFieldsValue({
            keys: selectedDimensions ? formKeys.concat(selectedDimensions) : formKeys,
            dimensions: selectedDimensions ? formKeys.concat(selectedDimensions) : formKeys,
        });
        this.setState({
            selectedDimensions: selectedDimensions ? formKeys.concat(selectedDimensions) : formKeys,
            selectedDimensionsId: ids
        })

        if (this.state.showAny) {
            let { isGoodsMandatory } = this.state;
            if (_.isUndefined(this.props.form.getFieldValue('GoodsTypes'))) {
                isGoodsMandatory = false;
                this.setState({ isGoodsMandatory })
            }
        }




    }


    selectGoodType = option => {

        let { isMandatoryDimensions } = this.state;
        if (this.state.showAny && option) {


            if (_.isUndefined(this.props.form.getFieldValue('ShipmentDimensions')) || this.props.form.getFieldValue('ShipmentDimensions')?.length <= 0) {
                isMandatoryDimensions = false;
                this.setState({ isMandatoryDimensions })
            }
        } else {
            if (_.isUndefined(this.props.form.getFieldValue('ShipmentDimensions')) || this.props.form.getFieldValue('ShipmentDimensions')?.length <= 0) {
                isMandatoryDimensions = true;
                this.setState({ isMandatoryDimensions })
            }
        }
    }

    getBoxTypes = (oldDimensions, newDimensions) => {
        const boxtypes = []
        oldDimensions.map((val, i) => {
            if (Object.keys(oldDimensions[i]).length === 2) {
                delete oldDimensions[i].id;
            }
            boxtypes[i] = { ...oldDimensions[i], ...newDimensions[i] }
        })
        return boxtypes;
    }

    handleSubmit = () => {

        let { fields } = this.state;
        const { shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber, docketVerifiedURL } = fields;
        const { form } = this.props;
        const { setFieldsValue } = form;

        const _this = this;
        setFieldsValue({
            shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber, docketVerifiedURL
        }, () => {

            if (_this.state.fields.CustomerType === 'RETAIL') {

                form.validateFieldsAndScroll(['isReverseBooking', 'RDSPinCode', 'RDSName', 'RDSCode', 'RDSCity', 'WaybillNumber', 'DestinationLocation', 'PaymentType', 'CustomerType', 'ChargableWeight', 'GoodsTypes', 'InvoiceNo', 'InvoiceValue', 'OriginLocation', 'ShipmentDimensions', 'ShippingCount', 'TotalAmount', 'TotalPaid', 'TotalWeight', 'keys', 'BookingDate', 'checkList'], (err, values) => {

                    let origin = this.state.originLocationList.data.filter(list => {
                        return list?.pincodeId == values['OriginLocation'];
                    })[0]


                    if (!err) {


                        Mixpanel.track("PTL Booking Creation Intiated", { 'ptlBookingCreation': moment().valueOf() })
                        let invoicesDetails = []
                        if (values['invoice'] && values['invoice']?.length > 0) {

                            invoicesDetails = values['invoice'].map(inv => {
                                if (inv?.number && inv?.value) {
                                    return {
                                        "verificationType": "INVOICE",
                                        "invoiceNumber": inv?.number,
                                        "invoiceValue": inv?.value,
                                        "ewaybillBO": {
                                            "ewaybillNo": inv?.eway
                                        },
                                        consignmentVerificationDocumentMappings: inv?.images.map(im => {
                                            return {
                                                "s3Url": im?.url
                                            }
                                        })
                                    }
                                }
                            });
                        }
                        let docketImages = this.state.docketVerifiedURL.map(img => {
                            return {
                                "s3Url": img?.url
                            }
                        })
                        let consignmentVerifications = [
                            {
                                "verificationType": "DOCKET",
                                "consignmentVerificationDocumentMappings": docketImages
                            },
                            ..._.compact(invoicesDetails)

                        ];





                        _this.setState({
                            isSubmitDisabled: true,
                            loadBooking: true
                        })
                        const _formData = values;
                        let boxTypes = [];
                        if (_formData['keys'].length > 0) {
                            boxTypes = _this.getBoxTypes(_formData['keys'], _formData['dimensions']);
                        }

                        if (_formData['ShippingCount'] < boxTypes.length) {
                            _this.props.form.setFields({
                                ShippingCount: {
                                    value: _formData['ShippingCount'],
                                    errors: [new Error("BoxTypes count can't be greater then shipment")]
                                }
                            });
                            notifyApiError('BoxTypes count can\'t be greater then shipment', 'Error');
                            return;
                        }
                        const consignee = _this.state.fields['CustomerType'] === 'RETAIL' ? {
                            "address": {
                                "address1": _formData['DestinationLocation'],
                                "latitude": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO?.latitude : _this.state.bookingEdit.data.consignee?.pincodeBO?.latitude,
                                "longitude": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO?.longitude : _this.state.bookingEdit.data.consignee?.pincodeBO?.longitude,
                                "pincode": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO : _this.state.bookingEdit.data.consignee?.pincodeBO
                            },
                            "contactCode": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.state.retailerCustomer?.code : _this.state.bookingEdit.data.customer.code,
                            "pincode": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO : _this.state.bookingEdit.data.consignee?.pincodeBO
                        } :
                            _this.state.designationLocationList.data.filter(list => {
                                // console.log("list", list)
                                if (list.consignmentContactBO?.id === _formData['DestinationLocation']) {
                                    return list;
                                }
                                // return list.consignmentContactBO?.id === _formData['DestinationLocation']
                            })[0]['consignmentContactBO'];
                        const goodsTypeName = _this.state.goodsTypesList.data.filter(list => list.id === _formData['GoodsTypes'] ? _formData['GoodsTypes'] : 0)[0];
                        const payload = {
                            request: [
                                {
                                    consignment: {
                                        consignee,
                                        consignmentVerifications,
                                        customerPickupLoc: {
                                            id: origin?.id
                                        },
                                        boxTypes,
                                        totalChargableWeight: _formData['ChargableWeight'],
                                        goodsType: _formData['GoodsTypes'],
                                        totalShipmentCount: _formData['ShippingCount'],
                                        totalWeight: _formData['TotalWeight'],
                                        pickupDate: moment(_formData['BookingDate']).valueOf(),
                                        bookingDate: moment(_formData['BookingDate']).valueOf(),
                                        attributes: {
                                            consignmentFlowType: _formData['isReverseBooking'] ? 'REVERSE' : 'FORWARD',
                                            goodsTypeName: goodsTypeName ? goodsTypeName['code'] : '',
                                            invoiceNumber: _formData['InvoiceNo'],
                                            invoiceAmount: _formData['InvoiceValue'],
                                            invoice: _formData['InvoiceNo'] ? true : false,
                                            goodsType: _formData['GoodsTypes'],
                                            paymentType: _formData['PaymentType'],
                                            totalAmount: _formData['TotalAmount'],
                                            collectedAmount: _formData['TotalPaid'],
                                            "RDSPinCode": _formData['RDSPinCode'],
                                            "RDSName": _formData['RDSName'],
                                            "RDSCode": _formData['RDSCode'],
                                            "RDSCity": _formData['RDSCity'],
                                            "manifest": _formData['checkList'].filter(c => c === 'Manifest').length > 0,
                                            "docket": _formData['checkList'].filter(c => c === 'Docket').length > 0,
                                            "invoice": _formData['checkList'].filter(c => c === 'Invoice').length > 0,
                                        },
                                        bookingOfficeLoc: {
                                            id: Constants.userLocationId
                                        },
                                        waybillNo: _formData['WaybillNumber'],
                                        customer: {
                                            id: _this.state.fields['CustomerType'] === 'RETAIL' ? _this.state.retailerCustomer?.id : _formData['CustomerName']
                                        },
                                        "consignmentFilterBO": {
                                            "threePlReferenceNumber": _formData['threePlReferenceNumber']
                                        },
                                        // "customerShipper": {
                                        //     "id": _formData['shipper']
                                        // },
                                        ignoreBarcodeCreation: !_formData['ignoreBarcodeCreation'],
                                        "movementType": "PTL",
                                        "partnerId": localStorage.getItem('loggedPartnerId'),
                                    },
                                    referenceId: Date.now(),

                                    isLiteRequest: true,
                                    // allowInscan: false,
                                    action: "CREATE"
                                }
                            ]
                        };
                        if (_this.props.formId > 0) {
                            payload.request[0].action = "UPDATE";

                            payload.request[0].consignment = Object.assign(_this.state.bookingEdit.data, payload.request[0].consignment)
                        }
                        if (this.props.configurations.ewaybill_configuration == "true") {
                            payload.request[0]['ewaybillNos'] = [...this.state.ewayBills];
                        }
                        if (_formData['Pickup']) {
                            const filteredPickup = this.state.pickupList.data.filter(item => item.id === _formData['Pickup'])[0];
                            if (filteredPickup)
                                payload['request'][0]['consignment']['lead'] = { "code": filteredPickup.code, "id": filteredPickup.id }
                        }

                        _this.props.createBooking(payload);
                        // const { keys, names } = values;
                        // console.log(Received values of form: ", values);
                        // console.log("Merged values:", keys.map(key => names[key]));
                    } else {
                        this.setState({
                            isSubmitDisabled: false,
                            loadBooking: false
                        })
                    }
                });
            }


            else {
                form.validateFieldsAndScroll((err, values) => {

                    let origin = this.state.originLocationList.data.filter(list => {
                        return list?.pincodeId == values['OriginLocation'];
                    })[0]


                    if (!err) {

                        let invoicesDetails = []
                        if (values['invoice'] && values['invoice']?.length > 0) {
                            invoicesDetails = values['invoice'].map(inv => {
                                if (inv?.number && inv?.value) {
                                    return {
                                        "verificationType": "INVOICE",
                                        "invoiceNumber": inv?.number,
                                        "invoiceValue": inv?.value,
                                        "ewaybillBO": {
                                            "ewaybillNo": inv?.eway
                                        },
                                        consignmentVerificationDocumentMappings: inv?.images.map(im => {
                                            return {
                                                "s3Url": im?.url
                                            }
                                        })
                                    }
                                }
                            });
                        }

                        let docketImages = this.state.docketVerifiedURL.map(img => {
                            return {
                                "s3Url": img?.url
                            }
                        })


                        let consignmentVerifications = [
                            {
                                "verificationType": "DOCKET",
                                "consignmentVerificationDocumentMappings": docketImages
                            },
                            ..._.compact(invoicesDetails)

                        ];





                        _this.setState({
                            isSubmitDisabled: true,
                            loadBooking: true
                        })
                        const _formData = values;

                        const boxTypes = _this.getBoxTypes(_formData['keys'], _formData['dimensions']);
                        if (_formData['ShippingCount'] < boxTypes.length) {
                            _this.props.form.setFields({
                                ShippingCount: {
                                    value: _formData['ShippingCount'],
                                    errors: [new Error("BoxTypes count can't be greater then shipment")]
                                }
                            });
                            notifyApiError('BoxTypes count can\'t be greater then shipment', 'Error');
                            return;
                        }
                        const consignee = _formData['CustomerType'] === 'RETAIL' ? {
                            "address": {
                                "address1": _formData['DestinationLocation'],
                                "latitude": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO?.latitude : _this.state.bookingEdit.data.consignee?.pincodeBO?.latitude,
                                "longitude": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO?.longitude : _this.state.bookingEdit.data.consignee?.pincodeBO?.longitude,
                                "pincode": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO : _this.state.bookingEdit.data.consignee?.pincodeBO
                            },
                            "contactCode": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.state.retailerCustomer?.code : _this.state.bookingEdit.data.customer.code,
                            "pincode": Object.keys(_this.props.serviceablePinocode).length > 0 ? _this.props.serviceablePinocode?.pincodeBO : _this.state.bookingEdit.data.consignee?.pincodeBO
                        } :
                            _this.state.designationLocationList.data.filter(list => {
                                // console.log("list", list)
                                if (list.consignmentContactBO?.id === _formData['DestinationLocation']) {
                                    return list;
                                }
                                // return list.consignmentContactBO?.id === _formData['DestinationLocation']
                            })[0]['consignmentContactBO'];
                        const goodsTypeName = _this.state.goodsTypesList.data.filter(list => list.id === _formData['GoodsTypes'] ? _formData['GoodsTypes'] : 0)[0];
                        const payload = {
                            request: [
                                {
                                    consignment: {
                                        consignee,
                                        consignmentVerifications,
                                        customerPickupLoc: {
                                            id: origin?.id
                                        },
                                        boxTypes,
                                        totalChargableWeight: _formData['ChargableWeight'],
                                        goodsType: _formData['GoodsTypes'],
                                        totalShipmentCount: _formData['ShippingCount'],
                                        totalWeight: _formData['TotalWeight'],
                                        pickupDate: moment(_formData['BookingDate']).valueOf(),
                                        bookingDate: moment(_formData['BookingDate']).valueOf(),
                                        attributes: {
                                            consignmentFlowType: _formData['isReverseBooking'] ? 'REVERSE' : 'FORWARD',
                                            goodsTypeName: goodsTypeName ? goodsTypeName['code'] : '',
                                            invoiceNumber: _formData['InvoiceNo'],
                                            invoiceAmount: _formData['InvoiceValue'],
                                            invoice: _formData['InvoiceNo'] ? true : false,
                                            goodsType: _formData['GoodsTypes'],
                                            paymentType: _formData['PaymentType'],
                                            totalAmount: _formData['TotalAmount'],
                                            collectedAmount: _formData['TotalPaid'],
                                            "RDSPinCode": _formData['RDSPinCode'],
                                            "RDSName": _formData['RDSName'],
                                            "RDSCode": _formData['RDSCode'],
                                            "RDSCity": _formData['RDSCity'],
                                            "manifest": _formData['checkList'].filter(c => c === 'Manifest').length > 0,
                                            "docket": _formData['checkList'].filter(c => c === 'Docket').length > 0,
                                            "invoice": _formData['checkList'].filter(c => c === 'Invoice').length > 0,
                                        },
                                        bookingOfficeLoc: {
                                            id: Constants.userLocationId
                                        },
                                        waybillNo: _formData['WaybillNumber'],
                                        customer: {
                                            id: _formData['CustomerType'] === 'RETAIL' ? _this.state.retailerCustomer?.id : _formData['CustomerName']
                                        },
                                        "consignmentFilterBO": {
                                            "threePlReferenceNumber": _formData['threePlReferenceNumber']
                                        },
                                        "customerShipper": {
                                            "id": _formData['shipper']
                                        },
                                        ignoreBarcodeCreation: !_formData['ignoreBarcodeCreation'],
                                        "movementType": "PTL",
                                        "partnerId": localStorage.getItem('loggedPartnerId'),
                                    },
                                    referenceId: Date.now(),

                                    isLiteRequest: true,
                                    // allowInscan: false,
                                    action: "CREATE"
                                }
                            ]
                        };
                        if (_this.props.formId > 0) {
                            payload.request[0].action = "UPDATE";

                            payload.request[0].consignment = Object.assign(_this.state.bookingEdit.data, payload.request[0].consignment)
                        }
                        // if (this.props.configurations.ewaybill_configuration == "true") {
                        //     payload.request[0]['ewaybillNos'] = [...this.state.ewayBills];
                        // }
                        if (_formData['Pickup']) {
                            const filteredPickup = this.state.pickupList.data.filter(item => item.id === _formData['Pickup'])[0];
                            if (filteredPickup)
                                payload['request'][0]['consignment']['lead'] = { "code": filteredPickup.code, "id": filteredPickup.id }
                        }

                        _this.props.createBooking(payload);
                        // const { keys, names } = values;
                        // console.log(Received values of form: ", values);
                        // console.log("Merged values:", keys.map(key => names[key]));
                    } else {
                        this.setState({
                            isSubmitDisabled: false,
                            loadBooking: false
                        })
                    }
                });
            }


        })


    };


    loadEditableFormData(editData) {
        if (editData) {
            if (['BOOKED', 'IN'].includes(editData['consignmentStatus'])) {
                // this.setState({
                //     selectAddress
                // })
                this.onCustomerSelected(editData['customerId']);
                this.props.form.setFieldsValue({
                    CustomerType: editData['customer']['customerType'] === 'ADOHC_CUSTOMER' ? 'RETAIL' : 'CONTRACT',
                    CustomerName: editData['customer']['customerType'] === 'ADOHC_CUSTOMER' ? editData?.customer?.name : editData['customerId'],
                    BookingOffice: editData['bookingOfficeLoc']['id'],
                    DestinationLocation: editData['customer']['customerType'] === 'ADOHC_CUSTOMER' ? editData['consignee']?.address?.address1 : editData['consignee']['id'],
                    bookingDate: editData['bookingDate'],
                    WaybillNumber: editData['waybillNo'],
                    keys: editData['boxTypes'],
                    dimensions: editData['boxTypes'],
                    TotalWeight: editData['totalWeight'],
                    GoodsTypes: editData['attributes']['goodsType'],
                    InvoiceValue: editData['attributes']['invoiceAmount'],
                    InvoiceNo: editData['attributes']['invoiceNumber'],
                    BookingDate: moment(editData['bookingDate']),
                    ShippingCount: editData['totalShipmentCount'],
                    Insurance: editData['insuranceInfo'],
                    PaymentType: editData['attributes']['paymentType'],
                    ChargableWeight: editData['totalChargableWeight'],
                    OriginLocation: editData['customerPickupLoc']['id'],
                    Pickup: editData['lead']['id']
                })
            } else {
                // windowRedirect('load/dashboard/expected');
                this.props.history.goBack();
            }
        }
    }




    allowedDates = (curr) => {
        const { restrictBooking } = this.props.configurations || '';
        const dayOfClosingMonth = dataGet(restrictBooking, "bookingDate.dayOfClosingMonth", '');
        const d = new Date();

        if (dayOfClosingMonth) {
            const configDate = moment(`${d.getFullYear()}-${d.getMonth() + 1}-${dayOfClosingMonth}`);

            if (configDate < moment().endOf("day")) {
                return curr.isBefore(moment().startOf('month')) || curr.isAfter(moment().endOf("day"));
            }
        }
        return curr > moment().endOf('day');
    }

    enterEwaybill = e => {

        let { ewayBills } = this.state;
        ewayBills.push(e.target.value);
        this.setState({ ewayBills });
        let { resetFields } = this.props.form;
        resetFields(['ewaybillNos']);
    }

    removeEwaybill = index => {
        let { ewayBills } = this.state;
        ewayBills = ewayBills.filter((eway, i) => {
            return i !== index;
        });

        this.setState({ ewayBills });
    }
    checkHttpStatus = (status) => {
        switch (status?.code) {
            case 403:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 401:
                notifyApiError(status?.message, "ERROR:");
                // window.location.href = '/';
                return false;
            case 500:
            case 503:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 200:
            case 201:
            case 202:
                return true
        }
    }

    next = () => {

        const { form } = this.props;
        const { getFieldValue } = form;


        form.validateFieldsAndScroll(['shipper', 'OriginLocation', 'CustomerName', 'DestinationLocation', 'WaybillNumber', 'CustomerType'], (err, values) => {


            if (err) return;
            const shipper = this.state.shipperList?.data?.filter(s => s.id === getFieldValue('shipper'))[0];


            let destination;
            if (this.props.form.getFieldValue('CustomerType') !== 'RETAIL') {
                destination = this.state.designationLocationList.data.filter(list => {
                    // console.log("list", list)
                    if (list.consignmentContactBO?.id === values['DestinationLocation']) {
                        return list;
                    }
                    // return list.consignmentContactBO?.id === _formData['DestinationLocation']
                })[0]['consignmentContactBO'];

            } else {
                destination = this.state?.retailerCustomer?.destinationAddress;
            }

            if (this.props.form.getFieldValue('CustomerType') !== 'RETAIL') {

                if (shipper?.isMandatory) {

                    const params = {
                        "customerShipperId": values['shipper'],
                        "originPincodeId": values['OriginLocation'],
                        "customerId": values['CustomerName'],
                        "destinationPincodeId": this.props.form.getFieldValue('CustomerType') === 'RETAIL' ? destination?.pincodeBO?.id : destination?.pincode?.id,
                    }
                    apiUtils.apiRequest({
                        method: 'POST',
                        url: `/b2b/v1/rateCard/getRateCardMandatoryField`,
                        data: params
                    }).then(response => {
                        if (response?.data?.response?.isRateCardAvailable) {
                            const current = this.state.current + 1;
                            let { isGoodsMandatory, isMandatoryDimensions, isDisabledAddedBox, showDimesions, showGoodTypes, showAny } = this.state;
                            // mandatoryFields.push('GOODS_TYPE');
                            // mandatoryFields.push('DIMENSIONS');
                            // mandatoryFields.push('NEITHER');
                            // mandatoryFields.push('ANY');
                            let { fields } = this.state;
                            fields = {
                                "shipper": values['shipper'],
                                "OriginLocation": values['OriginLocation'],
                                "CustomerName": values['CustomerName'],
                                "DestinationLocation": values['DestinationLocation'],
                                'WaybillNumber': values['WaybillNumber'],
                                'CustomerType': values['CustomerType'],
                                'docketVerifiedURL': values['docketVerifiedURL']
                            };
                            this.setState({ current, fields }, () => {

                                const { form } = this.props;
                                const { setFieldsValue } = form;
                                const { shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber } = this.state.fields;
                                setFieldsValue({
                                    shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber
                                });
                                let mandatoryFields = response?.data?.response?.mandatoryFields;
                                if (mandatoryFields.includes('NEITHER')) {
                                    isGoodsMandatory = false;
                                    isMandatoryDimensions = false;
                                    this.setState({ isGoodsMandatory, isMandatoryDimensions })
                                } else if (mandatoryFields.includes('ANY')) {
                                    showAny = true;
                                    isGoodsMandatory = true;
                                    isMandatoryDimensions = true;
                                    this.setState({ showAny, isGoodsMandatory, isMandatoryDimensions });
                                } else if (mandatoryFields.includes('DIMENSIONS')) {
                                    isGoodsMandatory = false;
                                    isMandatoryDimensions = true;
                                    this.setState({ isGoodsMandatory, isMandatoryDimensions })
                                } else if (mandatoryFields.includes('GOODS_TYPE')) {
                                    isGoodsMandatory = true;
                                    isMandatoryDimensions = false;
                                    this.setState({ isGoodsMandatory, isMandatoryDimensions })
                                } else if (mandatoryFields.includes('GOODSTYPE_AND_DIMENSIONS')) {
                                    isGoodsMandatory = true;
                                    isMandatoryDimensions = true;
                                    isDisabledAddedBox = true;
                                    this.setState({ isGoodsMandatory, isMandatoryDimensions, isDisabledAddedBox })
                                }



                            });

                        } else {
                            Modal.error({
                                title: 'Action Blocked',
                                content: 'No rate cards available for the selected Customer/ Shipper&Origin / Destination',
                            });
                        }



                    })



                } else {
                    let { fields } = this.state;
                    fields = {
                        "shipper": values['shipper'],
                        "OriginLocation": values['OriginLocation'],
                        "CustomerName": values['CustomerName'],
                        "DestinationLocation": values['DestinationLocation'],
                        'WaybillNumber': values['WaybillNumber'],
                        'CustomerType': values['CustomerType'],
                        'docketVerifiedURL': values['docketVerifiedURL']
                    };
                    const current = this.state.current + 1;
                    this.setState({ current, fields }, () => {
                        const { form } = this.props;
                        const { setFieldsValue } = form;
                        const { shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber } = fields;
                        setFieldsValue({
                            shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber
                        }, () => {
                            let { isGoodsMandatory, isMandatoryDimensions, isDisabledAddedBox, showDimesions, showGoodTypes, showAny } = this.state;


                            this.setState({ current }, () => {

                                const { form } = this.props;
                                const { setFieldsValue } = form;
                                const { shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber } = this.state.fields;
                                setFieldsValue({
                                    shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber
                                });

                                const params = {
                                    "customerShipperId": values['shipper'],
                                    "originPincodeId": values['OriginLocation'],
                                    "customerId": values['CustomerName'],
                                    "destinationPincodeId": destination?.pincode?.id,
                                }

                                apiUtils.apiRequest({
                                    method: 'POST',
                                    url: `/b2b/v1/rateCard/getRateCardMandatoryField`,
                                    data: params
                                }).then(response => {

                                    let mandatoryFields = response?.data?.response?.mandatoryFields;


                                    if (mandatoryFields.includes('NEITHER')) {
                                        isGoodsMandatory = false;
                                        isMandatoryDimensions = false;
                                        this.setState({ isGoodsMandatory, isMandatoryDimensions })
                                    } else if (mandatoryFields.includes('ANY')) {
                                        isGoodsMandatory = true;
                                        isMandatoryDimensions = true;
                                        showAny = true;
                                        this.setState({ showAny, isGoodsMandatory, isMandatoryDimensions });
                                    } else if (mandatoryFields.includes('DIMENSIONS')) {
                                        isGoodsMandatory = false;
                                        isMandatoryDimensions = true;
                                        this.setState({ isGoodsMandatory, isMandatoryDimensions })
                                    } else if (mandatoryFields.includes('GOODS_TYPE')) {
                                        isGoodsMandatory = true;
                                        isMandatoryDimensions = false;
                                        this.setState({ isGoodsMandatory, isMandatoryDimensions })
                                    } else if (mandatoryFields.includes('GOODSTYPE_AND_DIMENSIONS')) {
                                        isGoodsMandatory = true;
                                        isMandatoryDimensions = true;
                                        isDisabledAddedBox = true;
                                        this.setState({ isGoodsMandatory, isMandatoryDimensions, isDisabledAddedBox })
                                    }

                                })

                            });
                        })
                    });
                }
            } else {
                let fields = {
                    "shipper": values['shipper'],
                    "OriginLocation": values['OriginLocation'],
                    "CustomerName": values['CustomerName'],
                    "DestinationLocation": values['DestinationLocation'],
                    'WaybillNumber': values['WaybillNumber'],
                    'CustomerType': values['CustomerType'],
                    'docketVerifiedURL': values['docketVerifiedURL']
                };
                const current = this.state.current + 1;
                this.setState({ current, fields }, () => {

                });
            }



        });





    }

    prev = () => {
        let { fields } = this.state;
        const { shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber, CustomerType, docketVerifiedURL } = fields;
        const current = this.state.current - 1;
        this.setState({ current, imagesCount: 0, invoiceTotal: 0 }, () => {
            const { form } = this.props;
            const { setFieldsValue, getFieldDecorator } = form;
            // getFieldDecorator('newKeys', { initialValue: [] });
            setFieldsValue({
                shipper, OriginLocation, CustomerName, DestinationLocation, WaybillNumber, CustomerType, docketVerifiedURL, newKeys: []
            })
        });
    }

    onUploadFinish = e => {
        let { docketVerifiedURL } = this.state;
        docketVerifiedURL.push({
            url: e,
            "uid": "1-111", "status": "done", "name": "waybill.png"
        })
        this.setState({ docketVerifiedURL });
        this.props.form.setFieldsValue({
            'docketVerifiedURL': e
        })
    }

    onRemoveFile = file => {

        // let { docketVerifiedURL } = this.state;
        // let newFiles = docketVerifiedURL?.filter(fl => fl?.url !== file?.url);
        // this.setState({ docketVerifiedURL: newFiles }, () => {
        //     this.props.form.setFieldsValue({ 'docketVerifiedURL': newFiles })
        // })

        const _this = this;
        confirm({
            title: 'Are you sure to delete it?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                let { docketVerifiedURL } = _this.state;
                let newFiles = docketVerifiedURL?.filter(fl => fl?.url !== file?.url);
                _this.setState({ docketVerifiedURL: newFiles })
            },
        });

        // this.setState({ docketVerifiedURL: undefined }, () => {
        //     this.props.form.setFieldsValue({ 'docketVerifiedURL': undefined })
        // })
    }
    checkdocketVerifiedURL = (rule, value, callback) => {
        // const { form } = this.props;

        // if (form.getFieldValue('docketVerifiedURL')) {
        //     callback();

        // } else {
        //     callback('Please upload Image');
        // }
        callback();
    };

    checkdocketConfirmVerifiedURL = (rule, value, callback) => {
        const { form } = this.props;
        if (value) {
            form.validateFields(['WaybillNumber'], { force: false });
        }
        callback();
    };




    removeInvoice = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('newKeys');

        const key = keys.filter(key => key.newId !== k);
        form.setFieldsValue({
            newKeys: key,
        }, () => {
            this.getInvoiceAmountTotal();
            this.getInvoiceImagesTotal();
        });

    };


    addInvoice = (type) => {
        const { form } = this.props;
        const keys = form.getFieldValue('newKeys');
        const nextKeys = keys.concat({ newId: newId++, type: type });
        form.setFieldsValue({
            newKeys: nextKeys,
        }, () => {
            this.getInvoiceAmountTotal();
            this.getInvoiceImagesTotal();
        });
    };

    uploadInvoicImage = (e, index, type) => {
        const { form } = this.props;

        let value = form.getFieldValue(type);
        let values = [
            ...value,
            { url: e, uid: index, name: 'invoice' + index + ".png", "status": "done" }
        ]
        form.setFieldsValue({ [type]: values }, () => {
            this.getInvoiceAmountTotal();
            this.getInvoiceImagesTotal();
        })


    }

    compareInvoiceValue = (rule, value, callback, type) => {
        const invoiceValue = this.props.form.getFieldValue(type);
        if (invoiceValue > 50000) {
            if (!value || value < 0) {
                callback('Waybill is mandatory');
            } else {
                callback();
            }
        } else {
            callback();
        }

    }
    removeInvoiceFile = (file, index) => {
        const _this = this;
        confirm({
            title: 'Are you sure to delete it?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                let invoiceDeatils = _this.props.form.getFieldValue(`invoice`)[index]['images'];

                const newIndex = invoiceDeatils.findIndex(fl => fl?.url == file);

                invoiceDeatils.splice(newIndex, 1);
                let invoice = _this.props.form.getFieldValue(`invoice`);
                invoice[index]["images"] = invoiceDeatils;

                _this.props.form.setFieldsValue({
                    invoice
                }, () => {
                    _this.getInvoiceAmountTotal();
                    _this.getInvoiceImagesTotal();
                })
            }
        })

    }

    getInvoiceImagesTotal = () => {
        const { form } = this.props;
        const invoiceDetails = form.getFieldValue('invoice');
        let count = 0;
        // console.log("count", invoiceDetails)
        if (invoiceDetails && invoiceDetails?.length > 0) {
            for (let i = 0; i < invoiceDetails.length; i++) {
                count += invoiceDetails[i]?.eway ? 1 : 0;
            }
        }
        this.setState({ imagesCount: count })

        // return count;
    }

    getInvoiceAmountTotal = () => {
        const { form } = this.props;
        const invoiceDetails = form.getFieldValue('invoice');
        let count = 0;

        if (invoiceDetails && invoiceDetails?.length > 0) {
            for (let i = 0; i < invoiceDetails.length; i++) {
                count += invoiceDetails[i]?.value ? Number(invoiceDetails[i]?.value) : 0;
            }
        }

        this.setState({ invoiceTotal: count })

        // return count;
    }
    compareToDocket = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value === form.getFieldValue('WaybillNumber')) {
            callback('Docket no. and 3PL docket no. cannot be the same');
        } else {
            callback();
        }
    };

    compareToTotal = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value > form.getFieldValue('TotalAmount')) {
            callback(`${form.getFieldValue('PaymentType') !== 'PREPAID' ? 'Advance Amount' : 'Freight Amount'} should be less than Advance Amount`);
        } else {
            callback();
        }
    };








    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const configWeight = dataGet(this.props.configurations, "restrictBooking.weight", '');
        const invoiceAllowedImagesUpload = dataGet(this.props.configurations, "docketVerificationProofConfig.invoiceProofSize", '');
        const docketAllowedImagesUoload = dataGet(this.props.configurations, "docketVerificationProofConfig.docketProofSize", '');
        getFieldDecorator('newKeys', { initialValue: [] });

        const newKeys = getFieldValue('newKeys');
        const invoiceItems = newKeys.map((k, index) => {
            getFieldDecorator(`invoice[${k.newId}]['images']`, { initialValue: [] });
            return (

                <>
                    <Col span={24}>
                        <Row>
                            <Col span={4}>
                                <Form.Item

                                    label={index === 0 ? 'Invoice Number' : ''}
                                    required={false}
                                    key={k}
                                >
                                    {getFieldDecorator(`invoice[${k.newId}]['number']`, {

                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input invoice number",
                                            },
                                            {
                                                pattern: validationRules.nameNumber,
                                                message: "Please enter valid invoice number"

                                            },
                                        ],
                                    })(<Input placeholder="Invoice Number" style={{ marginRight: 8 }} />)}

                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item

                                    label={index === 0 ? 'Invoice Value' : ''}
                                    required={false}
                                    key={k}
                                >
                                    {getFieldDecorator(`invoice[${k.newId}]['value']`, {

                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input invoice value",
                                            },
                                            {
                                                pattern: validationRules.decimal,
                                                message: "Please enter valid invoice value"

                                            },


                                        ],
                                    })(
                                        <Input
                                            placeholder="Invoice Value"
                                            style={{ marginRight: 8 }}
                                            prefix={
                                                "₹"
                                            }
                                            onBlur={() => {
                                                this.getInvoiceImagesTotal();
                                                this.getInvoiceAmountTotal();
                                            }}
                                        />
                                    )}

                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item

                                    label={index === 0 ? 'Ewaybill' : ''}
                                    required={false}
                                    key={k}
                                >
                                    {getFieldDecorator(`invoice[${k.newId}]['eway']`, {

                                        rules: [
                                            {
                                                validator: (rule, value, callback) => this.compareInvoiceValue(rule, value, callback, `invoice[${k.newId}]['value']`),
                                            },
                                            {
                                                pattern: validationRules.decimal,
                                                message: "Please enter valid eway"

                                            },

                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter Ewaybill"
                                            style={{ marginRight: 8 }}
                                            addonAfter={<img src={scannerIcon} />}
                                            onBlur={() => {
                                                this.getInvoiceImagesTotal();
                                            }}
                                        />
                                    )}

                                </Form.Item>
                            </Col>
                            {getFieldValue(`invoice[${k.newId}]['images']`).length > 0 &&
                                <Col span={getFieldValue(`invoice[${k.newId}]['images']`).length}>
                                    <div className={index == 0 ? "invoice-images-index-0" : "invoice-images"}>

                                        <ImageViewer onRemoveFile={(fl) => this.removeInvoiceFile(fl, k.newId)} mode="AVATAR" listType="picture" fileList={getFieldValue(`invoice[${k.newId}]['images']`)} />

                                    </div>
                                </Col>
                            }
                            {getFieldValue(`invoice[${k.newId}]['images']`).length < invoiceAllowedImagesUpload &&
                                <Col span={1}>
                                    <>




                                        <Form.Item

                                            label={index === 0 ? '' : ''}
                                            required={false}
                                            key={k}
                                        >
                                            {getFieldDecorator(`invoice[${k.newId}]['image']`, {

                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Please input eway",
                                                    }
                                                ],
                                            })(
                                                <div
                                                    className={index === 0 ? "invoice-fileupload-index-0" : "invoice-fileupload"}
                                                >
                                                    <FileUpload
                                                        showToastMessage={false}
                                                        showLoader={true}
                                                        fileSizeLimit={5}
                                                        fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                                        uploadButtonHtml={(
                                                            // <Icon type="folder-add" style={{ fontSize: 25, cursor: 'pointer' }} />
                                                            <img src={ImageAdd} style={{ cursor: 'pointer', color: '#091c9b' }} />
                                                        )}
                                                        directUpload={true}
                                                        disableUploadButton={true}
                                                        clearFileAfterUpload={this.state.clearWaybill}
                                                        onUploadFinish={$events => this.uploadInvoicImage($events, index, `invoice[${k.newId}]['images']`)}
                                                        path="booking"
                                                        // acceptTypes={['.jpeg', '.jpg', '.png']}
                                                        acceptTypes={this.state.acceptedFiles}
                                                        objKey="ftl"
                                                        multiple={false}
                                                    />
                                                </div>
                                            )}

                                        </Form.Item>
                                    </>

                                </Col>
                            }
                            <Col span={4}>
                                {newKeys.length >= 1 ? (
                                    <Icon
                                        className={index === 0 ? "invoice-delete-button-index-0" : "invoice-delete-button"}
                                        type="minus-circle-o"
                                        onClick={() => this.removeInvoice(k.newId)}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </Col>



                </>
            )
        });







        getFieldDecorator('keys', { initialValue: [] });
        let invoiceValue = getFieldValue('InvoiceNo');
        const keys = getFieldValue('keys');
        const filterShipmentDimensionsList = this.state.shipmentDimensionsList.data
        const formItems = keys.length > 0 ? keys.map((k, index) => (
            <Col sm={24} md={24} lg={24} xl={24}>
                <Col className="input-margin-lr" xl={4} xxl={4} lg={4} sm={24}>
                    <Form.Item
                        label={index === 0 ? 'BOX / BAG NAME' : ''}
                        required={true}
                        key={k.id}
                    >
                        <Col className="input-margin-lr" xl={20} xxl={20} lg={20}>
                            {getFieldDecorator(`dimensions[${index}]['name']`, {
                                initialValue: k.name,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: `Please input ${k.type} Name`,
                                    },
                                ],

                            })(
                                <Input
                                    size="large"
                                    suffix={k.type}
                                    disabled={k?.name ? true : false}
                                />
                            )}
                        </Col>
                    </Form.Item>
                </Col>
                <Col className="input-margin-lr" xl={6} xxl={6} lg={10} sm={24}>
                    <Form.Item
                        label={index === 0 ? 'DIMENSIONS' : ''}
                        required={true}
                        key={k.id}
                    >
                        <InputGroup size="large">
                            <Col xl={8} lg={8}>
                                {getFieldDecorator(`dimensions[${index}][${k.type === 'BOX' ? 'length' : 'kgPerCft'}]`, {
                                    initialValue: k[k.type === 'BOX' ? 'length' : 'kgPerCft'],
                                    rules: [
                                        {
                                            required: true,
                                            message: `Please input ${k.type === 'BOX' ? 'length' : 'Kgs'}.`,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        suffix={k.type === 'BOX' ? 'L' : 'Kg/Cft'}
                                        disabled={k?.name ? true : false}
                                        type="number"
                                    />
                                )}
                            </Col>
                            {
                                k.type === 'BOX'
                                    ? (
                                        <>
                                            <Col xl={8} lg={8}>
                                                {getFieldDecorator(`dimensions[${index}][breadth]`, {
                                                    initialValue: k['breadth'],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: `Please input breadth.`,
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        size="large"
                                                        suffix="B"
                                                        disabled={k?.name ? true : false}
                                                        type="number"
                                                    />
                                                )}
                                            </Col>
                                            <Col xl={8} lg={8}>
                                                {getFieldDecorator(`dimensions[${index}][height]`, {
                                                    initialValue: k['height'],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please input height.",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        size="large"
                                                        suffix="H"
                                                        disabled={k?.name ? true : false}
                                                        type="number"
                                                    />
                                                )}
                                            </Col>
                                        </>
                                    ) : null
                            }
                        </InputGroup>
                    </Form.Item>
                </Col>
                <Col className="input-margin-lr" xl={2} lg={4} xxl={2} sm={24}>
                    {
                        k['type'] === 'BOX'
                            ? (
                                <Form.Item
                                    label={index === 0 ? 'UNIT' : ''}
                                    required={true}
                                    key={k.id}
                                >
                                    {getFieldDecorator(`dimensions[${index}][dimensionUnit]`, {
                                        initialValue: k['type'] === 'BAG' ? 'KG/CFT' : k['dimensionUnit'] ? k['dimensionUnit'] : 'INCH',
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input dimension unit",
                                            },
                                        ],

                                    })(
                                        <Select
                                            disabled={k?.name ? true : false}
                                            size="large"
                                        >
                                            <Select.Option value="INCH">Inch</Select.Option>
                                            <Select.Option value="CENTIMETER">Centimeter</Select.Option>
                                            {/* <Select.Option value="KG/CFT">Kg/Cft</Select.Option> */}
                                        </Select>
                                    )}
                                </Form.Item>
                            )
                            : null
                    }
                </Col>
                <Col className="input-margin-lr" xl={2} lg={4} xxl={2} sm={24}>
                    <Form.Item
                        label={index === 0 ? 'TOTAL UNIT' : ''}
                        required={true}
                        key={k.id}
                    >
                        <Col className="input-margin-lr" span={16}>
                            {getFieldDecorator(`dimensions[${index}][count]`, {
                                initialValue: k['count'],
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input total unit",
                                    },
                                ],
                            })(
                                <InputNumber
                                    placeholder="Total Unit"
                                    size="large"
                                    min="0"
                                />
                            )}
                        </Col>
                        <Col className="input-margin-lr" xl={1}>
                            <Icon
                                className="dynamic-delete-button"
                                type="minus-circle-o"
                                onClick={() => this.remove(index)}
                            />
                        </Col>
                    </Form.Item>
                </Col>
            </Col>
        )) : null;
        const title = <div>
            <h2 style={{ float: 'left' }}>New Booking - PTL</h2>
            {/* <div style={{ float: 'right' }} className="pickup-select">
                <Form.Item>
                    <Col>
                        {getFieldDecorator('Pickup', {
                            rules: [{ required: false, message: 'Please select Pickup ID' }],
                        })(

                            <Select
                                placeholder="Select a Pickup ID (Optional)"
                                onChange={val => this.onPickupSelected(val)}
                                allowClear
                            >
                                {
                                    this.state.pickupList.loading
                                        ? <Select.Option key={0} value="">Loading..</Select.Option>
                                        : this.state.pickupList.data?.length > 0
                                            ? this.state.pickupList.data.map(list => {
                                                return <Select.Option value={list.id}>{list.code}</Select.Option>
                                            })
                                            : <Select.Option key={0} value="">Empty List</Select.Option>
                                }
                            </Select>
                        )}
                    </Col>
                </Form.Item> 
            </div>*/}
        </div>
        const formItemLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };




        const steps = [
            {
                title: 'PTL Step (1/2)',
                content: <>
                    <Row>
                        <Col sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="Customer Type">
                                {getFieldDecorator('CustomerType', {
                                    initialValue: this.state.customerTypesOptions[0].value,
                                    rules: [{ required: true, message: 'Please input your Customer Type!' }],
                                })(
                                    <Radio.Group onChange={(e) => this.onCustomerTypeChanged(e)}>
                                        {
                                            this.state.customerTypesOptions.map(opt => {
                                                return <Radio key={opt.name} value={opt.value}>{opt.name}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                        {
                            getFieldValue('CustomerType') !== 'RETAIL'
                                ?
                                <>
                                    <Col sm={24} md={10} lg={8} xl={8}>
                                        <Form.Item label="Customer Name">
                                            {getFieldDecorator('CustomerName', {
                                                rules: [{ required: true, message: 'Please input your Customer Name!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder={this.state.customerList.loading ? "Loading.." : this.state.customerList.data?.length > 0 ? 'Select a Customer' : 'No Customer Found'}
                                                    loading={this.state.customerList.loading}
                                                    onChange={(e) => this.onCustomerSelected(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        this.state.customerList.loading
                                                            ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                            : this.state.customerList.data?.length > 0
                                                                ? this.state.customerList.data.map(cust => {
                                                                    return <Select.Option key={cust.id} value={cust.id}>{cust.name}</Select.Option>
                                                                })
                                                                : <Select.Option key={0} value="">Empty List</Select.Option>
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={10} lg={10} xl={10}>
                                        <Form.Item label="Shipper Name">
                                            {/* <Col xm={24} sm={24} md={24} lg={8} xl="8"> */}
                                            {getFieldDecorator('shipper', {
                                                rules: [{ required: true, message: 'Please Select Shipper!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder={this.state.shipperList.loading ? "Loading.." : this.state.shipperList.data?.length > 0 ? 'Select a Shipper' : 'No Shipper Found'}
                                                    loading={this.state.shipperList.loading}
                                                    onChange={(e) => this.onShipperSelected(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        this.state.shipperList.loading
                                                            ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                            : this.state.shipperList.data?.length > 0
                                                                ? this.state.shipperList.data.map(shipper => {
                                                                    return <Select.Option key={shipper.id} value={shipper.id}>{shipper.shipperName}</Select.Option>
                                                                })
                                                                : <Select.Option key={0} value="">Empty List</Select.Option>
                                                    }
                                                </Select>
                                            )}
                                            {/* </Col> */}
                                        </Form.Item>
                                    </Col>
                                </>
                                : <Col sm={24} md={10} lg={10} xl={10}>
                                    <Form.Item label="Retail Customer Name">
                                        <InputGroup>
                                            {getFieldDecorator('CustomerName', {
                                                rules: [{ required: true, message: 'Please input your Customer Name!' }],
                                            })
                                                (
                                                    <Input
                                                        placeholder="Retailer Customer Name"
                                                        disabled={true}
                                                        style={{ width: '300px' }}
                                                    />
                                                )}
                                            <Button onClick={this.onRetailerCustomerEdit} type="default" size="default" style={{ color: '#3B5A98' }} >
                                                <img src={editSvg} />
                                            </Button>
                                        </InputGroup>
                                    </Form.Item>
                                </Col>
                        }
                        {/* Insurance not in app */}
                        {/* <Col sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="Insurance">
                                    {getFieldDecorator('Insurance', {
                                        initialValue: getFieldValue('Insurance') ? getFieldValue('Insurance') : 'PARTNER_RISK',
                                        rules: [{ required: true, message: 'Please select Insurance!' }],
                                    })(
                                        <Radio.Group>
                                            <Radio value={'PARTNER_RISK'}>Partner Risk</Radio>
                                            <Radio value={'OWNER_RISK'}>Owner Risk</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col> */}
                        <Col sm={24} md={24} lg={24} xl={24}>

                            {/* <Form.Item label="Waybill Number"> */}
                            {/* <InputGroup>
                                    {getFieldDecorator('WaybillNumber', {
                                        initialValue: '',
                                        rules: [{ required: true, message: 'Please select Waybill Number!' }, {
                                            pattern: validationRules.nameNumber,
                                            message: "Please enter valid Waybill Number!"

                                        }],
                                    })(
                                        <Input
                                            placeholder="Enter Waybill Number"
                                            size="default"
                                            style={{ width: '280px' }}
                                            disabled={getFieldValue('WaybillNumber') && this.props.formId > 0 ? true : false}
                                        />
                                    )}
                                    
                                </InputGroup> */}
                            <InputGroup>
                                <Row gutter={2}>
                                    <Col span={24}>
                                        <Row gutter={2}>
                                            <Col span={5}>
                                                <Form.Item label="Waybill Number">
                                                    <InputGroup>
                                                        {getFieldDecorator('WaybillNumber', {
                                                            initialValue: '',
                                                            rules: [
                                                                { required: true, message: 'Please enter Waybill Number!' },
                                                                {
                                                                    validator: this.checkdocketVerifiedURL,
                                                                }
                                                            ],
                                                        })(
                                                            <Input
                                                                placeholder="Enter Waybill Number"
                                                                size="default"
                                                                style={{ width: '280px' }}
                                                                disabled={getFieldValue('WaybillNumber') && this.props.formId > 0 ? true : false}

                                                            />
                                                        )}


                                                    </InputGroup>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                {getFieldDecorator('docketVerifiedURL', {
                                                    rules: [{ required: false, message: 'Please upload waybill!' },
                                                        // {
                                                        //     validator: this.checkdocketConfirmVerifiedURL,
                                                        // }
                                                    ],
                                                })(
                                                    <div
                                                        className="booking-fileupload"
                                                    >
                                                        {this.state.docketVerifiedURL.length < docketAllowedImagesUoload &&
                                                            <FileUpload
                                                                showToastMessage={false}
                                                                showLoader={true}
                                                                fileSizeLimit={5}
                                                                fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                                                uploadButtonHtml={(
                                                                    // <Icon type="folder-add" style={{ fontSize: 25, cursor: 'pointer' }} />
                                                                    <img src={ImageAdd} style={{ cursor: 'pointer' }} />
                                                                )}
                                                                directUpload={true}
                                                                disableUploadButton={true}
                                                                clearFileAfterUpload={this.state.clearWaybill}
                                                                onUploadFinish={$events => this.onUploadFinish($events)}
                                                                path="booking"
                                                                // acceptTypes={['.jpeg', '.jpg', '.png']}
                                                                acceptTypes={this.state.acceptedFiles}
                                                                objKey="ftl"
                                                                multiple={false}
                                                            />
                                                        }
                                                    </div>
                                                )}
                                            </Col>
                                            <Col span={5}>
                                                {this.state?.docketVerifiedURL?.length > 0 &&
                                                    <div
                                                        className="booking-imageviewer"
                                                    >
                                                        {/* <ImageViewer onRemoveFile={this.onRemoveFile} mode="GRID" fileList={[{ url: this.state.docketVerifiedURL, "uid": "1-111", "status": "done", "name": "waybill.png" }]} /> */}
                                                        <ImageViewer onRemoveFile={this.onRemoveFile} mode="GRID" fileList={this.state.docketVerifiedURL} />
                                                    </div>
                                                }
                                            </Col>

                                        </Row>

                                    </Col>

                                </Row>
                            </InputGroup>
                            {/* </Form.Item> */}
                        </Col>

                        {
                            getFieldValue('CustomerType') !== 'RETAIL'
                                ? <Col span={24}>
                                    <Form.Item label="Origin Location">
                                        <Col xm={24} sm={24} md={24} lg={8} xl="8">
                                            {getFieldDecorator('OriginLocation', {
                                                rules: [{ required: true, message: 'Please input your Origin Location!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder="Select a Origin Location"
                                                    loading={this.state.originLocationList.loading}
                                                    disabled={(getFieldValue('OriginLocation') > 0 && this.props.formId > 0) ? true : false}
                                                    filterOption={(input, option) => {
                                                        return option.props.children[0] ? option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 : 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        this.state.originLocationList.loading
                                                            ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                            : this.state.originLocationList.data?.length > 0
                                                                ? this.state.originLocationList.data.map(list => {

                                                                    return <Select.Option value={list.pincodeId}>{list.name} ({list.shortCode})</Select.Option>
                                                                })
                                                                : <Select.Option key={0} value="">Empty List</Select.Option>
                                                    }
                                                </Select>
                                            )}
                                        </Col>
                                    </Form.Item>
                                </Col>
                                : <Col span={24}>
                                    <Form.Item label="Origin Location">
                                        <Col xm={24} sm={24} md={24} lg={8} xl="8">
                                            {getFieldDecorator('OriginLocation', {
                                                rules: [{ required: true, message: 'Please input your Origin Location!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    loading={this.state.bookingOfficeList.loading}
                                                    disabled
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        this.state.bookingOfficeList.loading
                                                            ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                            : this.state.bookingOfficeList.data?.length > 0
                                                                ? this.state.bookingOfficeList.data.map(list => {
                                                                    return <Select.Option value={list.id}>{list.name}</Select.Option>
                                                                })
                                                                : <Select.Option key={0} value="">Empty List</Select.Option>
                                                    }
                                                </Select>
                                            )}
                                        </Col>
                                    </Form.Item>
                                </Col>
                        }
                        {
                            getFieldValue('CustomerType') !== 'RETAIL'
                                ? <Col span={24}>
                                    <Form.Item label="Destination Location">
                                        <Col sm={24} md={8} lg={8} xl={8}>
                                            {getFieldDecorator('DestinationLocation', {
                                                rules: [{ required: true, message: 'Please input your Destination Location!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    style={{ width: 200 }}
                                                    placeholder="Select a Destination Location"
                                                    loading={this.state.designationLocationList.loading}
                                                    filterOption={(input, option) => {
                                                        return option.props.children[0] ? option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 : 0
                                                    }
                                                    }
                                                >
                                                    {
                                                        this.state.designationLocationList.loading
                                                            ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                            : this.state.designationLocationList.data?.length > 0
                                                                ? this.state.designationLocationList.data.map(list => {

                                                                    return <Select.Option value={list.consignmentContactBO?.id}>{list.consignmentContactBO.name} ({list.contactCode}) </Select.Option>
                                                                })
                                                                : <Select.Option key={0} value="">Empty List</Select.Option>
                                                    }
                                                </Select>
                                            )}
                                        </Col>
                                    </Form.Item>
                                </Col>
                                : <Col span={24}>
                                    <Form.Item label="Destination Location">
                                        <Col sm={24} md={8} lg={8} xl={8}>
                                            {getFieldDecorator('DestinationLocation', {
                                                rules: [{ required: true, message: 'Please input your Destination Location!' }],
                                            })(
                                                <Input placeholder="Destination location" disabled={getFieldValue('CustomerType') === 'RETAIL'} />
                                            )}
                                        </Col>
                                    </Form.Item>
                                </Col>
                        }
                    </Row>
                </>
            },
            {
                title: 'PTL Step (2/2)',
                content: <>
                    <Row>
                        <Col sm={24} md={8} lg={8} xl={8}>
                            <Form.Item label="No. of Bags/ Boxes/ Shipments">
                                {getFieldDecorator('ShippingCount', {
                                    initialValue: '',
                                    rules: [{ required: true, message: 'Please select No. of Bags/ Boxes/ Shipments!' }],
                                })(
                                    <InputNumber
                                        placeholder="Enter No. of Bags/ Boxes/ Shipments"
                                        size="large"
                                        min='0'
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Col sm={24} md={5} lg={4} xl={4} className="mr-3">
                                <Form.Item label="Total Weight">
                                    {getFieldDecorator('TotalWeight', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true, message: 'Please select Total Weight!'
                                            },
                                        ],
                                    })(
                                        <InputNumber
                                            placeholder="Enter Total Weight"
                                            size="large"
                                            suffix="KG"

                                            min={configWeight?.min ? configWeight.min : 0}
                                            max={configWeight?.max ? configWeight.max : 999999}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={5} lg={4} xl={4}>
                                <Form.Item label="Chargable Weight">
                                    {getFieldDecorator('ChargableWeight', {
                                        initialValue: getFieldValue('TotalWeight'),
                                        rules: [
                                            {
                                                required: true, message: 'Please select Chargable Weight!'
                                            },
                                            {
                                                validator: (rule, value) => {

                                                    return value <= Number(getFieldValue('TotalWeight'))
                                                },
                                                message: 'total weight cannot be less than chargeable weight'
                                            }
                                        ],
                                    })(
                                        <InputNumber
                                            placeholder="Enter Chargable Weight"
                                            size="large"
                                            suffix="KG"
                                            min="1"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={24}>
                            <Collapse bordered={false}>
                                <Panel header="Finance Options" key="2" style={{ border: 0 }}>
                                    {
                                        this.state.fields?.CustomerName &&

                                        <Col sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item label="Payment Type">
                                                {getFieldDecorator('PaymentType', {
                                                    // initialValue: getFieldValue('PaymentType') ? getFieldValue('PaymentType') : getFieldValue('CustomerType') !== 'RETAIL' ? 'CREDIT' : 'TOPAY',
                                                    initialValue: this.state.paymentTypes.length > 0 ? this.state.paymentTypes[0]?.id : 'CREDIT',
                                                    rules: [{ required: true, message: 'Please select Payment Type!' }],
                                                })(
                                                    <Radio.Group>
                                                        {/* {
                                                getFieldValue('CustomerType') !== 'RETAIL' ?
                                                    <Radio value={'CREDIT'}>Credit</Radio> : null
                                            }
                                            <Radio value={'TOPAY'}>To Pay</Radio>
                                            <Radio value={'PREPAID'}>Paid</Radio> */}
                                                        {this.state.paymentTypes.map(ptype => (
                                                            <Radio value={ptype.id}>{ptype.label}</Radio>
                                                        ))}
                                                    </Radio.Group>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    }
                                    {
                                        this.state.fields?.CustomerName && getFieldValue('PaymentType') !== 'CREDIT'
                                            ? (
                                                <>
                                                    {
                                                        getFieldValue('PaymentType') !== 'PREPAID'
                                                            ? <Col span={4} className="mr-3">
                                                                <Form.Item label="Freight Amount">
                                                                    <Col span={24}>
                                                                        {getFieldDecorator('TotalAmount', {
                                                                            initialValue: getFieldValue('TotalAmount') ? getFieldValue('TotalAmount') : undefined,
                                                                            rules: [{ required: true, message: 'Please enter Freight Amount!' }],
                                                                        })(
                                                                            <InputNumber
                                                                                placeholder="Enter Freight Amount"
                                                                                size="large"
                                                                                suffix="INR"
                                                                                min="0"
                                                                            />
                                                                        )}
                                                                    </Col>
                                                                </Form.Item>
                                                            </Col>
                                                            : null
                                                    }
                                                    <Col span={4}>
                                                        <Form.Item label={
                                                            getFieldValue('PaymentType') !== 'PREPAID' ? "Advance Amount" : "Freight Amount"}>
                                                            <Col span={24}>
                                                                {getFieldDecorator('TotalPaid', {
                                                                    initialValue: getFieldValue('TotalPaid') ? getFieldValue('TotalPaid') : undefined,
                                                                    rules: [{ required: true, message: `Please select ${
                                                                        getFieldValue('PaymentType') !== 'PREPAID' ? 'Advance Amount' : 'Freight Amount'}!` },
                                                                    {
                                                                        validator: this.compareToTotal,
                                                                    }],
                                                                })(
                                                                    <InputNumber
                                                                        placeholder={`Enter ${getFieldValue('PaymentType') !== 'PREPAID' ? 'Advance Amount' : 'Freight Amount'}`}
                                                                        size="large"
                                                                        suffix="INR"
                                                                        min="0"
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : null
                                    }
                                    <Col span={24}>
                                        <Row>
                                            <Col span={2}>
                                                <div className="invoice-details-text">Invoice Details</div>
                                            </Col>
                                            <Col span={1}>
                                                <Tooltip title="Total invoices">
                                                    <img src={InvoiceTotal} width={16} /> {getFieldValue('newKeys').length}
                                                </Tooltip>
                                            </Col>
                                            <Col span={1}>
                                                <Tooltip title="Total Eway">
                                                    <img src={EwayTotal} width={16} />
                                                    {_.isNaN(this.state.imagesCount) ? 0 : this.state.imagesCount}
                                                    {/* {this.getInvoiceImagesTotal()} */}
                                                </Tooltip>
                                            </Col>
                                            <Col span={4}>
                                                <Tooltip title="Total amount">
                                                    <img src={RupeeIcon} width={16} /> <CurrencyTemplate data={this.state.invoiceTotal} />
                                                </Tooltip>
                                            </Col>

                                        </Row>
                                    </Col>
                                    {invoiceItems}
                                    <Col span={4}>
                                        <Button className="add-invoice" style={{ backgroundColor: "#091c9b", color: "#ffffff" }} onClick={() => this.addInvoice('invoice')} style={{ width: '60%' }}>
                                            Add Invoice
                                         </Button>
                                    </Col>

                                    {/* <Col span={24}>
                                        <Form.Item label="Invoice No(s)">
                                            <Col span={8}>
                                                {getFieldDecorator('InvoiceNo', {
                                                    rules: [{ required: false, message: 'Please input your Invoice No!' }],
                                                })(
                                                    <Input
                                                        placeholder="Enter Invoice Number"
                                                        size="large"
                                                    />
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Invoice Value">
                                            <Col sm={24} md={8} lg={8} xl={8}>
                                                {getFieldDecorator('InvoiceValue', {
                                                    rules: [{ required: false, message: 'Please input your Invoice Value!' }],
                                                })(
                                                    <Input
                                                        placeholder="Enter Invoice Value"
                                                        size="large"
                                                    />
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col> */}

                                    {/* {this.props.configurations.ewaybill_configuration == 'true' ?
                                        <Col span={24}>
                                            <Form.Item label="E-Waybill Number">
                                                <Col sm={24} md={8} lg={8} xl={8}>
                                                    {getFieldDecorator('ewaybillNos', {
                                                        // rules: [{ required: getFieldValue('InvoiceNo') > 50000, message: 'Please Enter E-WayBill!' }],
                                                        rules: [{
                                                            validator: async (rule, value) => {
                                                                // throw new Error('Something wrong!');                                                            
                                                                if (this.state.ewayBills.length <= 0 && getFieldValue('InvoiceValue') > 50000) {
                                                                    throw new Error('Please Enter E-WayBill!');
                                                                }
                                                            }
                                                        }]
                                                    })(
                                                        <Input
                                                            placeholder="E-Waybill Number"
                                                            onPressEnter={this.enterEwaybill}
                                                            allowClear
                                                            addonAfter={<img src={scannerIcon} />}
                                                        />
                                                    )}

                                                    {this.state.ewayBills && this.state.ewayBills.length > 0 ?
                                                        this.state.ewayBills.map((ewaybill, index) => (
                                                            <><li>{ewaybill} <span onClick={() => this.removeEwaybill(index)}><Icon style={{ cursor: 'pointer', color: '#de1c22' }} type="minus-circle" /></span></li> </>
                                                        )) :
                                                        <></>}
                                                </Col>
                                            </Form.Item>
                                        </Col> : <></>} */}
                                </Panel>

                                <Panel header="Advanced Options" key="1" style={{ border: 0 }}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Col sm={24} md={8} lg={8} xl={8}>
                                                {getFieldDecorator('isReverseBooking', {
                                                    initialValue: false,
                                                    rules: [{ required: true }],
                                                })(
                                                    <Checkbox>
                                                        Reverse Booking
                                                    </Checkbox>
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col>
                                    {this.state.showDimesions &&
                                        <Col span={24}>
                                            <Form.Item label="Shipment Dimensions">
                                                <Col sm={24} md={8} lg={8} xl={8}>
                                                    {getFieldDecorator('ShipmentDimensions', {
                                                        rules: [{ required: this.state.isMandatoryDimensions, message: 'Please add Shipment Dimensions!' }],
                                                    })(
                                                        <Select
                                                            style={{ width: 200 }}
                                                            allowClear={false}
                                                            placeholder="Add a Shipment Dimensions"
                                                            onChange={val => this.onDimensionsSelected(val)}
                                                            mode="multiple"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {
                                                                this.state.shipmentDimensionsList.loading
                                                                    ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                                    : filterShipmentDimensionsList.length > 0
                                                                        ? filterShipmentDimensionsList.map(list => {
                                                                            return <Select.Option disabled={this.state.selectedDimensionsId.includes(list?.id)} value={list?.id}>{list?.name}</Select.Option>
                                                                        })
                                                                        : <Select.Option key={0} value="">Empty List</Select.Option>
                                                            }
                                                        </Select>
                                                    )}
                                                </Col>
                                            </Form.Item>
                                        </Col>
                                    }
                                    {formItems}
                                    {this.state.showDimesions &&

                                        <Col span={24}>
                                            <Col sm={24} md={12} lg={4} xl={2}>
                                                <Button disabled={this.state.isDisabledAddedBox} type="link" onClick={() => this.add('BOX')}>
                                                    <Icon type="plus" />
                                                Add Box
                                        </Button>
                                            </Col>
                                            <Col sm={24} md={12} lg={4} xl={2}>
                                                <Button disabled={this.state.isDisabledAddedBox} type="link" onClick={() => this.add('BAG')}>
                                                    <Icon type="plus" />
                                                Add Bag
                                        </Button>
                                            </Col>
                                        </Col>
                                    }
                                    {
                                        this.state.showGoodTypes && !this.state.isGoodsMandatory
                                            ? <Col span={24}>
                                                <Form.Item label="Goods Types">
                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                        {getFieldDecorator('GoodsTypes', {
                                                            rules: [{ required: false, message: 'Please input your Goods Types!' }],
                                                        })(
                                                            <Select
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                placeholder="Select a Goods Types"
                                                            >
                                                                {
                                                                    this.state.goodsTypesList.loading
                                                                        ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                                        : this.state.goodsTypesList.data?.length > 0
                                                                            ? this.state.goodsTypesList.data.map(type => {
                                                                                return <Select.Option value={type.id}>{type.code}</Select.Option>
                                                                            })
                                                                            : <Select.Option key={0} value="">Empty List</Select.Option>
                                                                }
                                                            </Select>
                                                        )}
                                                    </Col>
                                                </Form.Item>
                                            </Col>
                                            : null
                                    }
                                    {
                                        this.state.showGoodTypes && this.state.isGoodsMandatory
                                            ? <Col span={24}>
                                                <Form.Item label="Goods Types">
                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                        {getFieldDecorator('GoodsTypes', {
                                                            rules: [{ required: this.state.isGoodsMandatory, message: 'Please input your Goods Types!' }],
                                                        })(
                                                            <Select
                                                                allowClear
                                                                onChange={this.selectGoodType}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                placeholder="Select a Goods Types"
                                                            >
                                                                {
                                                                    this.state.goodsTypesList.loading
                                                                        ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                                        : this.state.goodsTypesList.data?.length > 0
                                                                            ? this.state.goodsTypesList.data.map(type => {
                                                                                return <Select.Option value={type.id}>{type.code}</Select.Option>
                                                                            })
                                                                            : <Select.Option key={0} value="">Empty List</Select.Option>
                                                                }
                                                            </Select>
                                                        )}
                                                    </Col>
                                                </Form.Item>
                                            </Col>
                                            : null
                                    }
                                    {/* in app not visible */}
                                    {/* <Col span={24}>
                                        <Form.Item label="Booking Office">
                                            <Col xm={24} sm={24} md={24} lg={8} xl="8">
                                                {getFieldDecorator('BookingOffice', {
                                                    initialValue: Number(Constants.userLocationId),
                                                    rules: [{ required: true, message: 'Please input your Booking Office!' }],
                                                })(
                                                    <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        disabled={getFieldValue('CustomerType') === 'RETAIL'}
                                                        loading={this.state.bookingOfficeList.loading}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            this.state.bookingOfficeList.loading
                                                                ? <Select.Option key={0} value="">Loading..</Select.Option>
                                                                : this.state.bookingOfficeList.data?.length > 0
                                                                    ? this.state.bookingOfficeList.data.map(list => {
                                                                        return <Select.Option value={list.id}>{list.name}</Select.Option>
                                                                    })
                                                                    : <Select.Option key={0} value="">Empty List</Select.Option>
                                                        }
                                                    </Select>
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col> */}

                                    <Col span={24}>
                                        <Form.Item label="Booking Date">
                                            <Col sm={24} md={8} lg={8} xl={8}>
                                                {getFieldDecorator('BookingDate', {
                                                    initialValue: moment(),
                                                    rules: [{ required: true, message: 'Please select booking date!' }],
                                                })(
                                                    <DatePicker disabledDate={this.allowedDates} showToday={false} />
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item label="Shipment Identifier Code">
                                            <Col sm={24} md={8} lg={8} xl={8}>
                                                {getFieldDecorator('shipmentIdentifier', {
                                                    rules: [{ required: false, message: 'Please Shipment Identifier Code!' }],
                                                })(
                                                    <Input
                                                        placeholder="Enter Shipment Identifier Code"
                                                        size="large"
                                                    />
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item label="3PL Docket No.">
                                            <Col sm={24} md={8} lg={8} xl={8}>
                                                {getFieldDecorator('threePlReferenceNumber', {
                                                    rules: [
                                                        { required: this.state.show3PLDocket, message: 'Please Enter 3PL Docket Reference Code!' },
                                                        {
                                                            pattern: validationRules.nameNumber,
                                                            message: "Please enter valid 3PL Docket"

                                                        },
                                                        {
                                                            validator: this.compareToDocket,
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        placeholder="3PL reference Code"
                                                        addonAfter={<img src={scannerIcon} />}
                                                    />
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col>

                                    {this.state.showRDSInfo &&
                                        <>
                                            <Col span={24}>
                                                <Form.Item label="RDS Code">
                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                        {getFieldDecorator('RDSCode', {
                                                            rules: [{ required: true, message: 'Please input RDS Code!' }],
                                                        })(
                                                            <Input
                                                                placeholder="Enter RDS Code"
                                                                size="large"
                                                            />
                                                        )}
                                                    </Col>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label="RDS Name">
                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                        {getFieldDecorator('RDSName', {
                                                            rules: [{ required: true, message: 'Please input RDS Name!' }],
                                                        })(
                                                            <Input
                                                                placeholder="Enter RDS Name"
                                                                size="large"
                                                            />
                                                        )}
                                                    </Col>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label="RDS Pin code">
                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                        {getFieldDecorator('RDSPinCode', {
                                                            rules: [{ required: true, message: 'Please input RDS Pin code!' }],
                                                        })(
                                                            <Input
                                                                placeholder="Enter RDS Pin code"
                                                                size="large"
                                                            />
                                                        )}
                                                    </Col>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label="RDS City">
                                                    <Col sm={24} md={8} lg={8} xl={8}>
                                                        {getFieldDecorator('RDSCity', {
                                                            rules: [{ required: true, message: 'Please input RDS City!' }],
                                                        })(
                                                            <Input
                                                                placeholder="Enter RDS City"
                                                                size="large"
                                                            />
                                                        )}
                                                    </Col>
                                                </Form.Item>
                                            </Col>

                                        </>

                                    }
                                    <Col span={24}>
                                        <Form.Item label="Check List">
                                            <Col sm={24} md={8} lg={8} xl={8}>
                                                {getFieldDecorator('checkList', {
                                                    initialValue: ['Docket', 'Manifest'],
                                                })(
                                                    <Checkbox.Group options={this.state.checkList} />
                                                )}
                                            </Col>
                                        </Form.Item>
                                    </Col>
                                </Panel>

                            </Collapse>
                        </Col>

                    </Row>
                </>
            }
        ];
        const { current } = this.state;

        return (
            <div className="b2b-lite-booking">
                <Form className="login-form" {...formItemLayout}>
                    <Card
                        className='booking mt-5'
                        title={title}
                        actions={[
                            <>
                                <div className="steps-action" style={{ float: 'right' }}>
                                    {current < steps.length - 1 && (
                                        <Button key="next" type="primary" onClick={() => this.next()}>
                                            Next
                                        </Button>
                                    )}
                                    {current === steps.length - 1 && (
                                        <Button loading={this.state.loadBooking} type="primary" onClick={() => this.handleSubmit()}>
                                            Create Booking
                                        </Button>
                                    )}
                                    {current > 0 && (
                                        <Button key="back" style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                            Back
                                        </Button>
                                    )}
                                </div>
                                <div className="steps-action" style={{ float: 'left' }}>
                                    <Form.Item>
                                        <Button className="pull-right mr-3" key="back" onClick={() => this.onBookingCancel()}>
                                            Cancel
                                    </Button>
                                        <Button className="pull-right mr-3" key="back" onClick={() => this.onBookingReset()}>
                                            Reset
                                    </Button>
                                    </Form.Item>
                                </div>
                            </>

                        ]}
                    >
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <Divider />
                        <div className="steps-content">{steps[current].content}</div>

                    </Card>
                </Form>

                <RetailerFormModal
                    isVisible={this.state.isModalVisible}
                    onSubmitRetailer={this.onCustomerManagementSuccess}
                    defaultData={this.state.retailNewCustomer}
                    onCanceled={this.onCustomerManagementCanceled}
                />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    customerList: state.b2bLiteBooking.customerList,
    shipperList: state?.b2bLiteBooking?.shipperList,
    bookingOfficeList: state.b2bLiteBooking.bookingOfficeList,
    genaratedWaybill: state.b2bLiteBooking.genaratedWaybill,
    originLocationList: state.b2bLiteBooking.originLocationList,
    designationLocationList: state.b2bLiteBooking.desigationLocationList,
    shipmentDimensionsList: state.b2bLiteBooking.shipmentDimensionsList,
    goodsTypesList: state.b2bLiteBooking.goodsTypesList,
    pickupList: state.b2bLiteBooking.pickupList,
    bookingCreation: state.b2bLiteBooking.bookingCreation,
    bookingEdit: state.b2bLiteBooking.bookingEdit,
    serviceablePinocode: state.RetailerCustomer.validServiceables.data,
    configurations: state.app.configurations,
    header: state?.header,
});

const mapDispatchToProps = dispatch => ({
    getCustomerList: () => dispatch(getCustomerList()),
    generateWaybillID: () => dispatch(generateWaybillID()),
    getBookingOfficeList: (payload) => dispatch(getBookingOffice(payload)),
    getOriginLocationList: (payload) => dispatch(getOriginLocation(payload)),
    getDesignationLocation: (payload) => dispatch(getDesignationLocation(payload)),
    getShippingDimensions: (payload) => dispatch(getShippingDimensions(payload)),
    getGoodsTypes: (payload) => dispatch(getGoodsTypes(payload)),
    getPickupList: (payload) => dispatch(getPickupList(payload)),
    createBooking: (payload) => dispatch(liteBookingCreate_Update(payload)),
    getConsignmentsById: (id) => dispatch(getConsignmentsById(id)),
    getShipperList: (params) => dispatch(getShipperList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({
    name: 'B2bLitePTLBookingForm',
    onFieldsChange: (props, newChanges, allFields) => {
        // console.log(allFields);
        // console.log("newChnges", newChanges, allFields)
    },
    onValuesChange: (props, newChanges, allFields) => {
        // console.log(allFields);
    }
})(B2bLitePTLBooing));