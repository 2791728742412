import React, { Component } from "react";
import InventoryRunsheetInfo from "./templates/inventory-runsheet-info/index";
import { Constants } from "../../common/utils/constants";
import { fetchPrintRunSheet } from "./actions";
import { connect } from "react-redux";
import "./print.scss"
import { getConsignment, getManifest } from "../inventory/awb/actions";
import Barcode from "../ui/barcode/index";
import Consignments from "./templates/consignments/index";
import DocketsTemplate from "./templates/dockets/dockets.template";
import DocketsConsignments from "./templates/dockets/dockets.consignments";
import DrsService from "../../services/inventory/drs.service";
import InventoryDrsInformation from "./templates/inventory_drs_information";
import THCManifestTemplate from "./templates/thcmanifest/thc.manifest.template";
import ReverseManifestTemplate from "./templates/thcmanifest/reverse.manifest.template";
import InventoryManifest from "./templates/inventory-manifest/index";
import ScanService from "../../services/inbound/scan.service";

import { apiRequest } from "../../common/utils/apiGateway";
import { checkAPIHttpStatus } from "../../common/utils/utils";
import MultipleDocketsChallans from "./templates/dockets/multiple.dockets";

const BarcodeGen = props => {
  const { barcodes } = props;
  return <div className="lead-information" style={{ marginTop: 50 }}>
    <div className="text-center">
      {
        barcodes.length > 0
          ? barcodes.map(barcode => (<Barcode width={2} format="CODE128" showText={true} value={barcode} />))
          : "NO Barcode Found"
      }
    </div>
  </div>
}

class Print extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: new URLSearchParams(props.location.search)
    }
  }

  componentDidMount() {
    this.setState(state => ({
      consignmentId: state.params.get("consignmentId"),
      consignmentIds : state.params.get("consignmentIds"),
      formType: state.params.get("formtype"),
      requestedBarCode: state.params.get("requestedBarCode"),
      leadId: state.params.get("leadId"),
      prsId: state.params.get("prsId"),
      error: {},
      barcodeList: []
    }), () => this.getRequiredData());
  }

  componentDidUpdate(prevProps) {
    const { consigments } = this.props;
    const { consignmentId } = this.state;
    if (consigments !== prevProps.consigments) {
      if (consigments[consignmentId]) {
        this.setState({
          barcodeList: this.getBarCodes(consigments[consignmentId])
        })
      }
    }
  }

  getHeaderText = () => {
    const { formType, consignmentId } = this.state;
    switch (formType) {
      case "consignment": return "Inventory Consignment Info";
      case "forwardconsignment": return false;
      case "inventory_challan_information": return "Inventory Challan Info";
      case "inventory_manifest_information": return "Inventory Manifest Info"; // to do
      case "inventory_drs_information": return "Inventory DRS Info";
      case "downloadInventoryWaybill": return `List Of Barcodes for ${consignmentId}`;
      case "lead_information": return "Inventory Lead Info";
      case "runsheet_information": return "Inventory Runsheet Info";
      case "docketconsignments" : return false;
      case "thcmanifest" : return false;
      case "docket" : return false;
      case "reversemanifest" : return false;
      case "reversemanifestreports" : return false;
      case "multiplechallans" : return false
      default: this.setState({
        message: "Requested details not available, please contact loadshare"
      });
    }
  }

  getcontext = () => {
    const { formType, barcodeList, consignmentId, reverseManifests, consignmentsList, consignmentIds } = this.state;
    const { pickupRunSheets } = this.props.printData;
    const consigment = this.props.consigments ? this.props.consigments[consignmentId] : {}
    switch (formType) {
      case "consignment": return <Consignments {...consigment} />;
      case "inventory_challan_information": return "Inventory Challan Info";
      case "inventory_manifest_information": return <InventoryManifest consignmentDetail={this.state?.consignmentDetail} />;
      case "inventory_drs_information": return <InventoryDrsInformation consignmentDetail={this.state?.consignmentDetail} />;
      case "downloadInventoryWaybill": return <BarcodeGen barcodes={barcodeList} />;
      case "lead_information": return "Inventory Lead Info";
      case "runsheet_information": return <InventoryRunsheetInfo runSheetDetails={pickupRunSheets} />;
      case "docket": return <DocketsTemplate {...consigment} />;
      case "multiplechallans": return <MultipleDocketsChallans consignmentIds={consignmentIds} />;
      case "docketconsignments": return <DocketsConsignments consignmentId={consignmentId} />;
      case "thcmanifest" : return <THCManifestTemplate consignmentId={consignmentId} />;
      case "reversemanifest" : return <DocketsTemplate consignments={reverseManifests} />;
      case "reversemanifestreports" : return <ReverseManifestTemplate consignmentId={consignmentId} />;
      case "forwardconsignment": return (
        <div>
          {consignmentsList && consignmentsList.map((consignment, index) => {
            return (
              <div key={`docket-${index}`} style={{height: "100vh", marginBottom: index <= consignmentsList.length - 2 ? "100%" : undefined}}>
                <DocketsTemplate key={`docket-${index}`} consignments={consignment} />
              </div>
            )
          })}
        </div>
      );
      default: this.setState({
        message: "Requested details not available, please contact loadshare"
      });
    }
  }

  getRequiredData = () => {
    
    const { formType } = this.state;

    switch (formType) {
      case "consignment": this.processConsignment(); break;
      case "forwardconsignment": this.getReverseManifestReportData(); break;
      case "inventory_challan_information": this.processChallan(); break;
      case "inventory_manifest_information": this.processManifest(); break;
      case "inventory_drs_information": this.processDrs(); break;
      case "downloadInventoryWaybill": this.processWaybill(); break;
      case "lead_information": this.processLead(); break;
      case "runsheet_information": this.processRunSheet(); break;
      case "docket": this.processConsignment(); break;
      case "reversemanifest": this.getReverseManifestReportData(); break;
      default: this.setState({
        message: "Requested details not available, please contact loadshare"
      });
    }
  }

  processDrs = () => {
    const { consignmentId } = this.state;
    (new DrsService()).getDrs(consignmentId).then(({ data }) => {
      this.setState({
        consignmentDetail: {
          ...data.response.drses[0],
          cityPincode: data.response.drses[0].loc.address.pincode.city.stateCode +
            " "
            + data.response.drses[0].loc.address.pincode.zipcode
        }
      })
      // this.consignmentDetail = ;
      // this.consignmentValueHolder['cityPincode'] = this.consignmentDetail.loc.address.pincode.city.stateCode +
      //   ' '
      //   + this.consignmentDetail.loc.address.pincode.zipcode;
    });
  }

  processWaybill = () => {
    const { requestedBarCode, consignmentId } = this.state
    const { consigments } = this.props;
    let barcodes = [];
    if (requestedBarCode && requestedBarCode === "all") {
      if (!consigments[consignmentId]) {
        this.props.getConsignment(consignmentId)
      } else {
        barcodes = this.getBarCodes(consigments[consignmentId])
      }
    } else {
      barcodes.push(requestedBarCode)
    }

    this.setState({
      barcodeList: [...barcodes]
    })
  }

  getBarCodes = consignment => {
    const barcodeList = []
    consignment.consignments["shipmentBOs"].map((data, index) => {
      data["barcodes"].map((barcode) => {
        barcodeList.push(barcode.barcode);
      });
    });
    return barcodeList
  }

  processConsignment = () => {
    const { consigments } = this.props;
    const { consignmentId } = this.state;

    if (!consigments[consignmentId]) {
      this.props.getConsignment(consignmentId)
    }
  }

  processRunSheet = () => {
    if (this.state.prsId) {
      const payload = {
        "filters": {
          "partnerIds": [Constants.PARTNER_ID],
          "locationIds": [Constants.userLocationId],
          // 'userIds': [Constants.sessionUser.id],
          "fetchObjects": [
            "PRS",
            "PRS_LEADS", "PRS_ASSET",
            "LEAD_CUSTOMER",
            "LEAD_PICKUP_LOCATION",
            "LEAD_ASSET",
            "LEAD_SHIPMENT",
            "LOCATION_ADDRESS",
            "ADDRESS",
            "ADDRESS_PINCODE",
            "PINCODE_CITIES",
            "LEADS_CONSIGNMENTS",
            "PRS_PARTNER",
            "CONSIGNMENTS",
            "CONSIGNMENT_CONTACTS",
            "SHIPMENTS"],
          "prsIds": [this.state.prsId]
        }
      };
      this.props.fetchPrintRunSheet(payload);
    } else {
      this.setState({
        message: "Requested details not available, please contact loadshare"
      })
    }
  }

  processManifest() {
    const { consignmentId } = this.state;
    (new ScanService()).getManifest(consignmentId).then(({ data }) => {
      this.setState({
        consignmentDetail: {
          ...data.response.manifests[0],
          cityPincode: data.response.manifests[0].loc.address.pincode.city.stateCode +
            " "
            + data.response.manifests[0].loc.address.pincode.zipcode
        }
      })
    });

    // getManifest(this.consignmentId).then(data => {
    //   this.consignmentDetail = data.response.manifests[0];
    //   if (this.consignmentDetail.loc && this.consignmentDetail.loc.address) {
    //     this.consignmentValueHolder['cityPincode'] = this.consignmentDetail.loc.address.pincode.city.stateCode +
    //       ' '
    //       + this.consignmentDetail.loc.address.pincode.zipcode;
    //   }
    // });
  }

  processDocketConsignments = () => {
    const { consigments } = this.props;
    const { docketId } = this.state;

    if (!consigments[docketId]) {
      this.props.getConsignment(docketId)
    }
  }
  
  getReverseManifestReportData = () => {
    const { consignmentId } = this.state;
    apiRequest({
      url    : `/b2b/v1/thc/${consignmentId}/fetch/docketDetails`,
      method : "GET"
    }).then(response => {
        if (response.hasOwnProperty("data") && response.data.hasOwnProperty("status") && 
            checkAPIHttpStatus(response?.data?.status)) {
            var consignments = response.data.response.consignments;
            var consignmentsList = consignments;
            if (consignments) {
              consignments = consignments[0]
            }
            else {
              consignments = {};
              consignmentsList = [];
            }
            this.setState({ reverseManifests : consignments, consignmentsList: consignmentsList})
        }
        else {
          this.setState({ reverseManifests : {}, consignmentsList: []});
        }
    })
  }

  render() {
    return (
      <div className="Print">
        <div className="row">
          <div className=" center-structure">
            <div className="waybill_information">
              <div className="ant-card padding-10">
                {
                  this.getHeaderText() &&
                  <div className="card-header text-center waybill_heading" style={{ marginTop: 30, marginBottom: 30 }}>
                    <span>
                      {this.getHeaderText()}
                    </span>
                  </div>
                }
                <div className="card-body">
                  {this.getcontext()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  printData: state.print,
  consigments: state.inventoryAwb.selectedConsigment,
});

const mapDispatchToProps = dispatch => ({
  fetchPrintRunSheet: (payload) => dispatch(fetchPrintRunSheet(payload)),
  getConsignment: payload => dispatch(getConsignment(payload)),
  getManifest: payload => dispatch(getManifest(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(Print);