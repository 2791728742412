
export const initialState = {
    loading: false
}

export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const FETCH_USERS_PICKUP_LOCATION_BEGIN = "FETCH_USERS_PICKUP_LOCATION_BEGIN";
export const FETCH_USERS_PICKUP_LOCATION_SUCCESS = "FETCH_USERS_PICKUP_LOCATION_SUCCESS";
export const FETCH_USERS_PICKUP_LOCATION_FAILURE = "FETCH_USERS_PICKUP_LOCATION_FAILURE";


export const FETCH_USER_BEGIN = "FETCH_USER_BEGIN";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const DO_FORCE_LOGOUT_BEGIN = "DO_FORCE_LOGOUT_BEGIN";
export const DO_FORCE_LOGOUT_SUCCESS = "DO_FORCE_LOGOUT_SUCCESS";
export const DO_FORCE_LOGOUT_FAILURE = "DO_FORCE_LOGOUT_FAILURE";

export const USER_MODIFICATION_BEGIN = "USER_MODIFICATION_BEGIN";
export const USER_MODIFICATION_SUCCESS = "USER_MODIFICATION_SUCCESS";
export const USER_MODIFICATION_FAILURE = "USER_MODIFICATION_FAILURE";

export const FETCH_USERS_LOCATIONS_BEGIN = "FETCH_USERS_LOCATIONS_BEGIN";
export const FETCH_USERS_LOCATIONS_SUCCESS = "FETCH_USERS_LOCATIONS_SUCCESS";
export const FETCH_USERS_LOCATIONS_FAILURE = "FETCH_USERS_LOCATIONS_FAILURE";

export const FETCH_CUSTOMER_PICKUP_LOCATIONS_BEGIN = "FETCH_CUSTOMER_PICKUP_LOCATIONS_BEGIN";
export const FETCH_CUSTOMER_PICKUP_LOCATIONS_SUCCESS = "FETCH_CUSTOMER_PICKUP_LOCATIONS_SUCCESS";
export const FETCH_CUSTOMER_PICKUP_LOCATIONS_FAILURE = "FETCH_CUSTOMER_PICKUP_LOCATIONS_FAILURE";



export const RESET_USER_CREATE = "RESET_USER_CREATE";