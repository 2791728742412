import React from 'react';
import { Table, Card } from 'antd';
import getIndentsColumns from '../state/indent.listing.column';
import moment from 'moment';
import '../indent.listing.scss';
function IndentTable(props) {
    let setColor = '';
    const changeTable = (pagination, filters, sorter, extra) => {
        props.changeTable(sorter, pagination)

    }

    const addColor = (record, _) => {
        // const newRecord = moment.duration(moment().diff(moment(record.indentDate))).asSeconds() <= 10 ? 'indent-listing_table-color' : '';
        // return newRecord;
        if (Math.abs(moment.duration(moment().diff(moment(record.indentDate))).asSeconds()) <= 10) {
            // if (setColor) {
            //     clearTimeout(setColor);
            //     setColor = undefined;
            // }
            // if (!setColor) {
            const nodes = document.querySelectorAll('tr.ant-table-row.indent-listing_table-color');
            setTimeout(() => {

                if (nodes && nodes.length > 0) {
                    nodes.forEach(node => {
                        node.classList.remove('indent-listing_table-color');
                    });
                }
            }, 10000);
            // }
            return 'indent-listing_table-color';

        } else {
            return '';
        }
    }
    return (
        <Card>
            <div className="indent-listing_table">
                <Table                    
                    columns={getIndentsColumns({
                        showIndent: props.openViewPage,
                        copyIndent: props.copyIndent
                    })}
                    dataSource={props?.data?.indents}
                    rowKey={row => row.id}
                    rowClassName={addColor}
                    onChange={changeTable}
                    bordered={true}
                    scroll={{ x : "auto" }}
                    pagination={{
                        // onChange: (page, pageSize) => props.setParams({ ...props.params, "pageNo": page }),
                        // onChange: changePage,
                        total: props?.data?.totalCount,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        pageSize: props?.data?.limit,
                        current: props?.data?.currentPageNo,
                        showSizeChanger: true,
                        // onShowSizeChange: (_, size) => props.setParams({ ...props.params, "pageSize": size })
                    }}
                />
            </div>
        </Card>
    )
}

export default IndentTable;