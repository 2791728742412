import React from 'react';
import { Tag } from 'antd';
import * as apiUtils from '../../../../../../common/utils/apiGateway';
import { capitalizeString, checkAPIHttpStatus, checkHttpStatus } from '../../../../../../common/utils/utils';

export const fetchFuelCharges = contractId => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url         : `/b2b/v1/contracts/fuelClause?contractId=${contractId}`,
        method      : "GET",
        showMessage : false
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            if (response.data.response) {
                const { fuelClauses } = response.data.response;
                resolve(fuelClauses);
            }
            else {
                reject(response.data);
            }
        }
        else {
            reject(response.data);
        }
    })
})

export const fuelStatusTransformer = status => {
    var tagClassName = 'success-tag';
    if (status == "INACTIVE") {
        tagClassName = 'warning-tag';
    }
    else if (status == "ALARM") {
        tagClassName = 'error-tag';
    }
    else if (status == "NOT_AVAILABLE") {
        tagClassName = 'info-tag';
    }
    else if (!status) {
        return <></>
    }
    return (
        <Tag className={tagClassName}>
            { (status.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ") }
        </Tag>
    )
}

export const postFuelClause = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url         : "/b2b/v1/contracts/fuelClause/save",
        method      : "POST",
        data        : data
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status, false)) {
            if (response.data.response) {
                resolve(response.data.response);
            }
            else {
                reject(response?.data?.response);
            }
        }
        else {
            reject(response?.data?.response);
        }
    })
})
