import React, { Component, useState } from 'react';
import './customer-settlement.scss';
import { getCustomerList, getShipperList, getInvoicesList, downloadAnnexure, uploadAnnexure, updateInvoiceDueDate, uploadCustomerInvoice, exportInvoiceData, uploadPayment, getInvoicesPaymentsList, delete_CancelInvoice, getInvoicesDocketsList, delinkDocket, createOpenInvoice, getCrDrNoteList, updateCrDrNote, fetchReasonNotes } from './actions.js';
import { connect } from 'react-redux';
import { Card, Input, Table, Modal, Button, Row, Col, Icon, Select, Form, DatePicker, Spin, Dropdown, Menu, Tag, Empty, InputNumber, Radio, Switch } from 'antd';
import Sparkline from '../../ui/sparkline/Sparkline.js';
import Filters from '../../ui/filter/Filter.js';
import { formatCurrency, downloadFile, reportsDownload, notifyApiSuccess, checkHttpStatus, notifyApiError } from '../../../common/utils/utils.js';
import { FILTER_INPUT_TYPE, Constants, UPLOAD_BUTTON_TYPE, DownloadTags, validationRules } from '../../../common/utils/constants';
import moment from 'moment';
import FileUpload from '../../ui/file-upload/file.upload';
import SettleInvoice from './settle-invoice/settleInvoice';
import { uploadDebitNote, settleCustomerInvoice } from '../../billing/customer-settlements/actions';
import { setS3SyncStatus } from '../../../app/appAction';


const Payment_Status = [
  // {
  //   label: 'Open',
  //   value: 'OPEN',
  //   color: '#2db7f5'
  // },
  {
    label: 'Partially Paid',
    value: 'PARTIALLY_PAID',
    color: '#fa8c16',
  },
  {
    label: 'Not Settled',
    value: 'NOT_SETTLED',
    color: '#f50',
  },
  {
    label: 'Settled',
    value: 'SETTLED',
    color: '#87d068'
  },
  // {
  //   label: 'Cleared',
  //   value: 'CLEARED',
  //   color: '#87d068'
  // }
];

const Invoice_Status = [
  // {
  //   label: 'Open',
  //   value: 'OPEN',
  //   color: '#2db7f5'
  // },
  {
    label: 'Active',
    value: 'ACTIVE',
    color: '#fa8c16',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
    color: '#f50',
  },
  {
    label: 'Fulfilled',
    value: 'FULFILLED',
    color: '#87d068'
  },
  // {
  //   label: 'Cleared',
  //   value: 'CLEARED',
  //   color: '#87d068'
  // }
];

const Cust_Ack_Status = [
  {
    label: 'Ready for Submission',
    value: 'READY_FOR_SUBMISSION',
    color: '#f50',
  },
  {
    label: 'Waiting for Ack',
    value: 'WAITING_FOR_ACK',
    color: '#fa8c16',
  },
  {
    label: 'Rejected By Customer',
    value: 'REJECTED_BY_CUSTOMER',
    color: '#fa8c16',
  },
  {
    label: 'Ack Recieved',
    value: 'ACK_RECEIVED',
    color: '#87d068'
  }
]

class InvoiceCustomerSettlement extends Component {
  state = {
    columns: [
      // {
      //   title: '',
      //   dataIndex: 'annexureDocUrl',
      //   render: data => (<Icon type="file-image" />),
      //   key: 0
      // },
      {
        title: "Actions",
        dataIndex: "action",
        width: 140,
        className: 'border-bold',
        fixed: 'left',
        key: 1,
        render: (data, row) => {
          const EDIT = (
            <Menu.Item key="0" onClick={e => this.openEditInvoice(row)}>
              Edit Invoice
            </Menu.Item>
          );
          const EDIT_CR_DR_FORM = (
            <Menu.Item key="1" onClick={e => this.toggleCrDrForm({ ...row, noteAction: 'CREATE' })}>
              Add Credit/Debit Note
            </Menu.Item>
          );
          const VIEW_CR_DR_NOTE = (
            <Menu.Item key="2" onClick={e => this.toggleCrDrNote(row)}>
              View Credit/Debit Notes
            </Menu.Item>
          );
          const UPLOAD_CUST_INVOICE = (
            <Menu.Item key={3} onClick={e => this.openEditCustInvoice(row, true, 'Upload Customer Invoice')}>
              Upload Customer Invoice
            </Menu.Item>
          )
          const EDIT_CUST_INVOICE = (
            <Menu.Item key={4} onClick={e => this.openEditCustInvoice(row, true, 'Edit Customer Invoice')}>
              Edit Customer Invoice
            </Menu.Item>
          )
          const UPLOAD_CUST_ACK_MAIL = (
            <Menu.Item key={5} onClick={e => this.openEditCustInvoice(row, false, 'Upload Customer Ack Mail')}>
              Upload Customer Ack Mail
            </Menu.Item>
          )
          const EDIT_CUST_ACK_MAIL = (
            <Menu.Item key={6} onClick={e => this.openEditCustInvoice(row, false, 'Edit Customer Ack Mail')}>
              Edit Customer Ack Mail
            </Menu.Item>
          )
          const SETTLE_INVOICE = (
            <Menu.Item key={7} onClick={e => this.openSettleInvoice(row)}>
              Settle Invoice
            </Menu.Item>
          )
          const DOWNLOAD_ANNEXURE = true ? null : (
            <Menu.Item key={8} onClick={e => this.downloadS3Link(row?.annexureDocUrl)}>
              Download Annexure
            </Menu.Item>
          )
          const VIEW_DOCKET = (
            <Menu.Item key={9} onClick={e => this.openDocketModal(row)}>
              View Dockets
            </Menu.Item>
          )
          const VIEW_DELINKED_DOCKET = (
            <Menu.Item key={10} onClick={e => this.openDelinkedDocketModal(row)}>
              View Delinked Dockets
            </Menu.Item>
          )
          const VIEW_PAYMENTS = (
            <Menu.Item key={11} onClick={e => this.openPaymentsModal(row)}>
              View Payments
            </Menu.Item>
          )
          const CANCEL = (row.paymentStatus === 'NOT_SETTLED' && !(['DELETED', 'CANCELLED'].includes(row.invoiceStatus))) ? (
            <Menu.Item key={12} onClick={e => this.openCancelModal('cancel', row)}>
              Cancel Invoice
            </Menu.Item>
          ) : null
          const DELETE = (row.paymentStatus === 'NOT_SETTLED' && row.invoiceStatus !== 'DELETED') ? (
            <Menu.Item key={13} onClick={e => this.openCancelModal('delete', row)}>
              Delete Invoice
            </Menu.Item>
          ) : null
          const REJECT = (
            <Menu.Item key={14} onClick={e => this.openCustomerRejectModal(row)}>
              {(row.customerAckStatus !== 'REJECTED_BY_CUSTOMER') ? 'Rejected By Customer' : 'Edit Customer Reject Reason'}
            </Menu.Item>
          )
          let menu = (
            <Menu>
              <Menu.Item key={15}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Menu.Item>
            </Menu>
          );



          if (row.parentInvoiceId) {
            if (['NOT_SETTLED', 'PARTIALLY_PAID'].includes(row.paymentStatus) && row.customerAckStatus === 'READY_FOR_SUBMISSION') {
              menu = (
                <Menu>
                  {EDIT}
                  {/* {UPLOAD_CUST_INVOICE} */}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {/* {REJECT} */}
                  {SETTLE_INVOICE}
                  {/* {VIEW_DOCKET} */}
                  {/* {VIEW_DELINKED_DOCKET} */}
                  {VIEW_PAYMENTS}
                  {EDIT_CR_DR_FORM}
                  {VIEW_CR_DR_NOTE}
                  {/* {CANCEL} */}
                  {/* {DELETE} */}
                </Menu>
              )
            } else if (['NOT_SETTLED', 'PARTIALLY_PAID'].includes(row.paymentStatus) && row.customerAckStatus === 'WAITING_FOR_ACK') {
              menu = (
                <Menu>
                  {EDIT}
                  {EDIT_CUST_INVOICE}
                  {/* {UPLOAD_CUST_ACK_MAIL} */}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {/* {REJECT} */}
                  {SETTLE_INVOICE}
                  {/* {VIEW_DOCKET} */}
                  {/* {VIEW_DELINKED_DOCKET} */}
                  {VIEW_PAYMENTS}
                  {EDIT_CR_DR_FORM}
                  {VIEW_CR_DR_NOTE}
                  {/* {CANCEL} */}
                  {/* {DELETE} */}
                </Menu>
              )
            } else if (['NOT_SETTLED', 'PARTIALLY_PAID'].includes(row.paymentStatus) && row.customerAckStatus === 'ACK_RECEIVED') {
              menu = (
                <Menu>
                  {EDIT}
                  {EDIT_CUST_INVOICE}
                  {/* {EDIT_CUST_ACK_MAIL} */}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {SETTLE_INVOICE}
                  {/* {VIEW_DOCKET}
                {VIEW_DELINKED_DOCKET} */}
                  {VIEW_PAYMENTS}
                  {EDIT_CR_DR_FORM}
                  {VIEW_CR_DR_NOTE}
                  {/* {CANCEL}
                {DELETE} */}
                </Menu>
              )
            } else if (['NOT_SETTLED', 'SETTLED'].includes(row.paymentStatus)) {
              menu = (
                <Menu>
                  {EDIT}
                  {EDIT_CUST_INVOICE}
                  {/* {EDIT_CUST_ACK_MAIL} */}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {/* {REJECT}
                {VIEW_DOCKET}
                {VIEW_DELINKED_DOCKET} */}
                {SETTLE_INVOICE}
                  {VIEW_PAYMENTS}
                  {EDIT_CR_DR_FORM}
                  {VIEW_CR_DR_NOTE}
                  {/* {CANCEL}
                {DELETE} */}
                </Menu>
              )
            }
            if (row.numberOfConsignment === 0) {
              menu = (
                <Menu>
                  {EDIT}
                  {SETTLE_INVOICE}
                  {VIEW_PAYMENTS}
                  {EDIT_CR_DR_FORM}
                  {VIEW_CR_DR_NOTE}
                  {/* {CANCEL}
                {DELETE} */}
                </Menu>
              )
            }
          } else {
            if (['NOT_SETTLED', 'PARTIALLY_PAID'].includes(row.paymentStatus) && row.customerAckStatus === 'READY_FOR_SUBMISSION') {
              menu = (
                <Menu>
                  {EDIT}
                  {UPLOAD_CUST_INVOICE}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {REJECT}
                  {SETTLE_INVOICE}
                  {VIEW_DOCKET}
                  {VIEW_DELINKED_DOCKET}
                  {VIEW_PAYMENTS}
                  {/* {EDIT_CR_DR_FORM} */}
                  {/* {VIEW_CR_DR_NOTE} */}
                  {CANCEL}
                  {DELETE}
                </Menu>
              )
            } else if (['NOT_SETTLED', 'PARTIALLY_PAID'].includes(row.paymentStatus) && row.customerAckStatus === 'WAITING_FOR_ACK') {
              menu = (
                <Menu>
                  {EDIT}
                  {EDIT_CUST_INVOICE}
                  {UPLOAD_CUST_ACK_MAIL}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {REJECT}
                  {SETTLE_INVOICE}
                  {VIEW_DOCKET}
                  {VIEW_DELINKED_DOCKET}
                  {VIEW_PAYMENTS}
                  {/* {EDIT_CR_DR_FORM} */}
                  {/* {VIEW_CR_DR_NOTE} */}
                  {CANCEL}
                  {DELETE}
                </Menu>
              )
            } else if (['NOT_SETTLED', 'PARTIALLY_PAID'].includes(row.paymentStatus) && row.customerAckStatus === 'ACK_RECEIVED') {
              menu = (
                <Menu>
                  {EDIT}
                  {EDIT_CUST_INVOICE}
                  {EDIT_CUST_ACK_MAIL}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {SETTLE_INVOICE}
                  {VIEW_DOCKET}
                  {VIEW_DELINKED_DOCKET}
                  {VIEW_PAYMENTS}
                  {/* {EDIT_CR_DR_FORM} */}
                  {/* {VIEW_CR_DR_NOTE} */}
                  {CANCEL}
                  {DELETE}
                </Menu>
              )
            } else if (['NOT_SETTLED', 'SETTLED'].includes(row.paymentStatus)) {
              menu = (
                <Menu>
                  {EDIT}
                  {EDIT_CUST_INVOICE}
                  {EDIT_CUST_ACK_MAIL}
                  {
                    row.annexureDocUrl ? DOWNLOAD_ANNEXURE : null
                  }
                  {REJECT}
                  {VIEW_DOCKET}
                  {VIEW_DELINKED_DOCKET}
                  {VIEW_PAYMENTS}
                  {SETTLE_INVOICE}
                  {/* {EDIT_CR_DR_FORM} */}
                  {/* {VIEW_CR_DR_NOTE} */}
                  {CANCEL}
                  {DELETE}
                </Menu>
              )
            }
            if (row.numberOfConsignment === 0) {
              menu = (
                <Menu>
                  {EDIT}
                  {SETTLE_INVOICE}
                  {VIEW_PAYMENTS}
                  {/* {EDIT_CR_DR_FORM} */}
                  {/* {VIEW_CR_DR_NOTE} */}
                  {CANCEL}
                  {DELETE}
                </Menu>
              )
            }
          }
          return <Dropdown overlay={menu} trigger={['click']}>
            <Button block>Action</Button>
          </Dropdown>
        }
      },
      {
        title: "Invoice No.",
        dataIndex: "invoiceCode",
        fixed: 'left',
        className: 'border-bold',
        width: 140,
        key: 2
      },
      {
        title: "Customer Name",
        dataIndex: "customer",
        fixed: 'left',
        className: 'border-bold',
        key: 3,
        width: 140,
        render: (data, row) => (<div>{row?.customer?.name ? row?.customer?.name : 'N/A'}</div>)
      },
      {
        title: "Shipper Name",
        dataIndex: "customerShipperMappings",
        fixed: 'left',
        className: 'border-bold',
        key: 29,
        width: 140,
        render: (data, row) => row?.customerShipperMappings?.length > 0 ? (<div>{row.customerShipperMappings[0] ? row.customerShipperMappings[0].shipperName : 'N/A'}</div>) : 'N/A'
      },
      {
        title: "Invoice Date",
        dataIndex: "invoiceDate",
        className: 'border-bold-left',
        key: 4,
        width: 140,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
      {
        title: "Total Invoice Amount",
        dataIndex: "totalAmount",
        key: 5,
        width: 140,
        render: data => data ? (<div>{formatCurrency(data)}</div>) : 'N/A'
      },
      {
        title: "Base Amount",
        dataIndex: "baseAmount",
        key: 23,
        width: 140,
        render: (data, row) => row?.additionalDetails?.baseAmount ? formatCurrency(row?.additionalDetails?.baseAmount) : 'N/A'
      },
      {
        title: "Pending Recievable",
        dataIndex: "paidAmount",
        key: 6,
        width: 140,
        render: (data, row) => data ? (<div>{formatCurrency((row.totalAmount - row.paidAmount))}</div>) : (<div>{row.totalAmount ? `${formatCurrency(row.totalAmount)}` : 'N/A'} </div>)
      },
      {
        title: "Payment Status",
        dataIndex: "paymentStatus",
        key: 7,
        width: 150,
        className: 'text-center',
        render: data => (<Tag color={Payment_Status.find(item => item.value === data)?.color}>{Payment_Status.find(item => item.value === data)?.label}</Tag>)
      },
      {
        title: "Customer Ack Status",
        dataIndex: "customerAckStatus",
        key: 8,
        width: 170,
        className: 'text-center',
        render: data => data ? (<Tag color={Cust_Ack_Status.find(item => item.value === data)?.color}>{Cust_Ack_Status.find(item => item.value === data)?.label}</Tag>) : 'N/A'
      },
      {
        title: "Invoice Status",
        dataIndex: "invoiceStatus",
        key: 9,
        width: 140,
        className: 'text-center',
      },
      {
        title: "No of Dockets",
        dataIndex: "numberOfConsignment",
        key: 10,
        width: 140,
        render: data => data ? data : 'N/A'
      },
      {
        title: "No of Dockets with EPOD",
        dataIndex: "numberOfConsignmentEPOD",
        key: 11,
        width: 140,
        render: (data, row) => row?.additionalDetails?.totalPodLink ? row?.additionalDetails?.totalPodLink : 'N/A'
      },
      {
        title: "No. of Docket with HPOD",
        dataIndex: "numberOfConsignmentHPOD",
        key: 28,
        width: 140,
        render: (data, row) => row?.additionalDetails?.totalHPodLink ? row?.additionalDetails?.totalHPodLink : 'N/A'
      },
      {
        title: "Customer Enabled for POD",
        dataIndex: "docketHPOD",
        key: 27,
        width: 140,
        render: (data, row) => row?.additionalDetails?.isCustomerEnabledForPod ? row?.additionalDetails?.isCustomerEnabledForPod : 'N/A'
      },
      {
        title: "Due Date",
        dataIndex: "invoiceDueDate",
        key: 12,
        width: 140,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
      {
        title: "Delivery Date Range",
        dataIndex: "customerAckSubmissionDate",
        key: 13,
        width: 140,
        render: (data, row) => (<div>{row?.additionalDetails?.oldestDeliveryDate ? this.formatDate(row?.additionalDetails?.oldestDeliveryDate) : 'N/A'} - {row?.additionalDetails?.latestDeliveryDate ? this.formatDate(row?.additionalDetails?.latestDeliveryDate) : 'N/A'}</div>)
      },
      {
        title: "Customer Submission Date",
        dataIndex: "customerAckSubmissionDate",
        key: 14,
        width: 140,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
      {
        title: "Customer Ack Date",
        dataIndex: "customerAckDate",
        key: 15,
        width: 140,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
      {
        title: "Customer GST No",
        dataIndex: "customerGstNumber",
        key: 16,
        width: 140,
        render: data => (<div>{data ? data : 'N/A'}</div>)
      },
      {
        title: "CGST%",
        dataIndex: "invoiceTaxChargeMappingBOS",
        key: 17,
        width: 140,
        render: (data, row) => (<div>{row?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'CGST')?.taxPercentage || 'N/A'}</div>)
      },
      {
        title: "SGST%",
        dataIndex: "invoiceTaxChargeMappingBOS",
        key: 18,
        width: 140,
        render: (data, row) => (<div>{row?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'SGST')?.taxPercentage || 'N/A'}</div>)
      },
      {
        title: "IGST%",
        dataIndex: "invoiceTaxChargeMappingBOS",
        key: 19,
        width: 140,
        render: (data, row) => (<div>{row?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'IGST')?.taxPercentage || 'N/A'}</div>)
      },
      {
        title: "Tax Amount",
        dataIndex: "taxAmount",
        key: 20,
        width: 140,
        render: data => (data || data === 0) ? (<div>{formatCurrency(data)}</div>) : 'N/A'
      },
      {
        title: "TDS%",
        dataIndex: "invoiceTaxChargeMappingBOS",
        key: 22,
        width: 140,
        render: (data, row) => (<div>{row?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'TDS')?.taxPercentage || 'N/A'}</div>)
      },
      {
        title: "TDS Amount",
        dataIndex: "tdsAmount",
        key: 24,
        width: 140,
        render: data => (<div>{(data || data === 0) ? formatCurrency(data) : 'N/A'}</div>)
      },
      {
        title: "Total Credit Notes Amount",
        dataIndex: "totalCreditAmount",
        key: 25,
        width: 140,
        render: (data, row) => (<div>{(row?.invoiceCreditDebitNoteMappingBOS?.length > 0) ? formatCurrency(row.invoiceCreditDebitNoteMappingBOS.filter(i => i.transactionType === 'CREDIT').reduce((a, b) => a + Number(b.amount), 0)) : formatCurrency(0)}</div>)
      },
      {
        title: "Total Debit Notes Amount",
        dataIndex: "totalDebitAmount",
        key: 26,
        width: 140,
        render: (data, row) => (<div>{(row?.invoiceCreditDebitNoteMappingBOS?.length > 0) ? formatCurrency(row.invoiceCreditDebitNoteMappingBOS.filter(i => i.transactionType === 'DEBIT').reduce((a, b) => a + Number(b.amount), 0)) : formatCurrency(0)}</div>)
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: 21,
        width: 140,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
    ],
    docketColumns: [
      {
        title: "Action",
        width: 120,
        fixed: 'left',
        dataIndex: "action",
        key: 1,
        render: (data, row) => {
          let menu = (
            <Menu>
              <Menu.Item key={1} onClick={e => this.delinkDocket(row)} >
                De-Link
              </Menu.Item>
              <Menu.Item key={2} onClick={e => {
                if (row.podLink) {
                  this.downloadS3Link(row.podLink)
                } else {
                  notifyApiError('POD not avaiable')
                }
              }}>
                View POD
              </Menu.Item>
            </Menu>
          );
          return <Dropdown overlay={menu} trigger={['click']}>
            <Button>Action</Button>
          </Dropdown>
        }
      },
      {
        title: "Id",
        width: 140,
        dataIndex: "consignmentId",
        key: 2
      },
      {
        title: "Waybill No",
        width: 140,
        dataIndex: "waybillNo",
        key: 3
      },
      {
        title: "Source Location",
        width: 140,
        dataIndex: "bookingOfficeLocation",
        key: 4
      },
      {
        title: "Destination Location",
        width: 140,
        dataIndex: "destinationLocation",
        key: 5
      },
      {
        title: "Booking Date",
        width: 140,
        dataIndex: "bookingDate",
        key: 6,
        render: (data, row) => (<><div>{data ? this.formatDate(data) : 'N/A'}</div> </>)
      },
      {
        title: "Delivery Date",
        width: 140,
        dataIndex: "deliveryDate",
        key: 7,
        render: (data, row) => (<><div>{data ? this.formatDate(data) : 'N/A'}</div> </>)
      },
      {
        title: "Calculated date",
        width: 140,
        dataIndex: "calculatedDate",
        key: 8,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
      {
        title: "No of Shipments",
        width: 140,
        dataIndex: "totalShipmentCount",
        key: 9
      },
      {
        title: "Rate Card Type",
        width: 140,
        dataIndex: "ratecardType",
        key: 10
      },
      {
        title: "Rate",
        width: 140,
        dataIndex: "rate",
        key: 11
      },
      {
        title: "Total Charges",
        width: 140,
        dataIndex: "totalCharges",
        key: 12
      },
      {
        title: "Invoiceable Amount",
        width: 140,
        dataIndex: "invoiceableAmount",
        key: 13,
        render: data => data ? formatCurrency(data) : 'N/A'
      },
      {
        title: "Manual Revenue Amount",
        width: 140,
        dataIndex: "manualRevenueAmount",
        key: 14,
        render: data => data ? formatCurrency(data) : 'N/A'
      },
      {
        title: "Manual Revenue",
        width: 140,
        dataIndex: "considerManualRevenue",
        key: 15,
        render: data => (data ? `${data}`?.toUpperCase() : 'N/A')
      },
      {
        title: "Invoicing Location Name",
        width: 140,
        dataIndex: "invoicingLocationName",
        key: 16
      },
      {
        title: "Shipper Reference",
        width: 140,
        dataIndex: "shipperName",
        key: 17
      },
    ],
    delinkedDocketColumns: [
      // {
      //   title: "Action",
      //   width: 120,
      //   fixed: 'left',
      //   dataIndex: "action",
      //   key: 14,
      //   render: (data, row) => {
      //     let menu = (
      //       <Menu>
      //         <Menu.Item key={1} onClick={e => this.delinkDocket(row)} >
      //           De-Link
      //         </Menu.Item>
      //         <Menu.Item key={2} onClick={e => {
      //           if (row.podLink) {
      //             this.downloadS3Link(row.podLink)
      //           } else {
      //             notifyApiError('POD not avaiable')
      //           }
      //         }}>
      //           View POD
      //         </Menu.Item>
      //       </Menu>
      //     );
      //     return <Dropdown overlay={menu} trigger={['click']}>
      //       <Button>Action</Button>
      //     </Dropdown>
      //   }
      // },
      {
        title: "Id",
        width: 140,
        dataIndex: "consignmentId",
        key: 1
      },
      {
        title: "Waybill No",
        width: 140,
        dataIndex: "waybillNo",
        key: 2
      },
      {
        title: "Source Location",
        width: 140,
        dataIndex: "bookingOfficeLocation",
        key: 3
      },
      {
        title: "Destination Location",
        width: 140,
        dataIndex: "destinationLocation",
        key: 4
      },
      {
        title: "Delinked Date",
        width: 140,
        dataIndex: "delinkedDate",
        key: 5,
        render: (data, row) => (<><div>{data ? this.formatDate(data) : 'N/A'}</div> </>)
      },
      {
        title: "Booking Date",
        width: 140,
        dataIndex: "bookingDate",
        key: 6,
        render: (data, row) => (<><div>{data ? this.formatDate(data) : 'N/A'}</div> </>)
      },
      {
        title: "Delivery Date",
        width: 140,
        dataIndex: "deliveryDate",
        key: 7,
        render: (data, row) => (<><div>{data ? this.formatDate(data) : 'N/A'}</div> </>)
      },
      {
        title: "Calculated date",
        width: 140,
        dataIndex: "calculatedDate",
        key: 8,
        render: data => (<div>{data ? this.formatDate(data) : 'N/A'}</div>)
      },
      {
        title: "No of Shipments",
        width: 140,
        dataIndex: "totalShipmentCount",
        key: 9
      },
      {
        title: "Rate Card Type",
        width: 140,
        dataIndex: "ratecardType",
        key: 10
      },
      {
        title: "Rate",
        width: 140,
        dataIndex: "rate",
        key: 11
      },
      {
        title: "Total Charges",
        width: 140,
        dataIndex: "totalCharges",
        key: 12,
        render: data => data ? formatCurrency(data) : 'N/A'
      },
      {
        title: "Invoiceable Amount",
        width: 140,
        dataIndex: "invoiceableAmount",
        key: 13,
        render: data => data ? formatCurrency(data) : 'N/A'
      },
      {
        title: "Manual Revenue Amount",
        width: 140,
        dataIndex: "manualRevenueAmount",
        key: 14,
        render: data => data ? formatCurrency(data) : 'N/A'
      },
      {
        title: "Manual Revenue",
        width: 140,
        dataIndex: "considerManualRevenue",
        key: 15,
        render: data => `${data}`?.toUpperCase() || 'N/A'
      },
      {
        title: "Invoicing Location Name",
        width: 140,
        dataIndex: "invoicingLocationName",
        key: 16
      },
      {
        title: "Shipper Reference",
        width: 140,
        dataIndex: "shipperName",
        key: 17
      },
    ],
    paymentColumns: [
      {
        title: "Payment Ref No",
        dataIndex: "referenceId",
        key: 1,
        render: data => data ? (<div>{data}</div>) : '-'
      },
      {
        title: "Payment Date",
        dataIndex: "createdOn",
        key: 2,
        render: data => (<div>{data ? this.formatDate(data) : '-'}</div>)
      },
      {
        title: "Settled Amount",
        dataIndex: "amount",
        key: 3,
        render: data => data ? (<div>{formatCurrency(data)}</div>) : '-'
      },
      {
        title: "Payment Receipt",
        dataIndex: "imageProof",
        key: 4,
        render: data => data ? <div style={{ cursor: 'pointer' }} onClick={e => this.downloadS3Link(data, '_blank')}> <Icon type="download" /> </div> : '-'
      },
      {
        title: "Action",
        dataIndex: "action",
        key: 5,
        render: (data, row) => {
          let menu = (
            <Menu>
              <Menu.Item key={1} onClick={e => this.openEditSettlement(row)}>
                Edit
              </Menu.Item>
              <Menu.Item key={2} onClick={e => this.onPaymentDetele(row)}>
                Delete
              </Menu.Item>
            </Menu>
          );
          return <Dropdown overlay={menu} trigger={['click']}>
            <Button>Action</Button>
          </Dropdown>
        }
      },
    ],
    invoiceCrDrNoteColumns: [
      {
        title: "System Id",
        dataIndex: "id",
        key: 1,
        render: data => data ? (<div>{data}</div>) : '-'
      },
      {
        title: "Transcation Type",
        dataIndex: "transactionType",
        key: 2,
        render: data => data ? (<div>{data}</div>) : '-'
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: 3,
        render: data => (<div>{data ? this.formatDate(data) : '-'}</div>)
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: 4,
        render: data => data ? (<div>{formatCurrency(data)}</div>) : '-'
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: 5,
        render: (data, row) => row?.reason?.reasonDescription ? (<div>{row?.reason?.reasonDescription}</div>) : '-'
      },
      {
        title: "Support Docs",
        dataIndex: "docUrl",
        key: 6,
        render: data => data ? <div style={{ cursor: 'pointer' }} onClick={e => this.downloadS3Link(data, '_blank')}> <Icon type="download" /> </div> : '-'
      },
      {
        title: "Action",
        dataIndex: "action",
        key: 7,
        render: (data, row) => {
          let menu = (
            <Menu>
              <Menu.Item key={1} onClick={e => this.toggleCrDrForm({ ...this.state.selectedInvoice, noteRow: row, noteAction: 'UPDATE' })}>
                Edit
              </Menu.Item>
              <Menu.Item key={2} onClick={e => this.onCrDrNoteDetele({ ...this.state.selectedInvoice, noteRow: row, noteAction: 'DELETE' })}>
                Delete
              </Menu.Item>
            </Menu>
          );
          return <Dropdown overlay={menu} trigger={['click']}>
            <Button>Action</Button>
          </Dropdown>
        }
      },
    ],

    filterPayload: {
      isChildInvoice: false
    },
    payload: {
      filters: {
        invoiceDate: { from: null, to: null },
        pageSize: 10,
        fetchInvoiceSparkline: true,
        isActive: true,
        searchChildInvoice: false
      }
    },
    pageNo: 1,
    CustomerList: [],
    shipperList: [],
    selectedInvoice: {},
    showDownloadAnnexure: false,
    downloadAnnexureloading: false,
    showUploadAnnexure: false,
    uploadAnnexureloading: false,
    showUploadPayment: false,
    uploadPaymentloading: false,
    showEditInvoice: false,
    editInvoiceloading: false,
    showSettleInvoice: false,
    isFiltered: false,
    showEditCustInvoice: false,
    exportInvoiceloading: false,
    isCustInvoice: true,
    modalTitle: '',
    showViewDocket: false,
    showViewDelinkedDocket: false,
    selectedPayment: {},
    isDirectSettleOpen: false,
    showOpenInvoice: false,
    showCustomerReject: false,
    viewDocketDownloadLoader: false,
    showCrDrForm: false,
    showCrDrNote: false
  };
  constructor(props) {
    super(props);

    this.props.getCustomerList()
  }

  openEditSettlement = row => {
    const that = this;
    this.setState({
      selectedPayment: row,
      showViewPayments: false,
      isDirectSettleOpen: false
    }, () => {
      that.openSettleInvoice(that.state.selectedInvoice);
    })
  }

  delinkDocket = row => {
    const payload = {
      "consignmentIds": [row.consignmentId],
      "invoiceId": this.state.selectedInvoice.id
    }
    this.props.delinkDocket(payload)
      .then(resp => {
        if (checkHttpStatus(resp.data.status)) {
          this.applyPagination({ pageNo: this.state.pageNo, ...this.state.filterPayload }, true)
          this.props.getInvoicesDocketsList(this.state.selectedInvoice.id)
          notifyApiSuccess('Successfully de-link this docket')
        }
      })
      .catch(e => {
        notifyApiError(e, 'Error')
      })
  }

  onPaymentDetele = row => {
    const that = this
    Modal.confirm({
      title: 'Do you Want to delete these payment?',
      centered: true,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const payload = {
          "invoiceTransactionId": row.invoiceTransactionId,
          "invoiceId": that.state.selectedInvoice.id,
          "remittingAmount": 0
        }

        let info = that.props.settleCustomerInvoice(payload)
      },
      onCancel() { },
    });
  }
  onCrDrNoteDetele = row => {
    const that = this
    Modal.confirm({
      title: 'Do you Want to delete these Note?',
      centered: true,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const payload = {
          noteBO: {
            ...row.noteRow,
            isActive: false
          },
          action: 'DELETE'
        }

        that.props.updateCrDrNote(payload)
          .then(resp => {
            if (checkHttpStatus(resp?.data?.status)) {
              notifyApiSuccess(resp.data.status.message, 'Success')
              that.applyPagination({ pageNo: 1, ...this.state.filterPayload }, true)
            }
          })
          .catch(e => {
            console.log(e)
          })
      },
      onCancel() { },
    });
  }

  openCancelModal = (type, row) => {
    let reason = ''
    const input = (
      <Input placeholder="Enter your reason here" onChange={e => {
        reason = e.target.value
      }} />
    )
    const that = this;
    Modal.confirm({
      title: `Why you want to ${type} these invoice?`,
      content: input,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const payload = {
          "invoiceId": row.id,
          "reason": reason
        }
        that.props.delete_CancelInvoice(type, payload)
          .then(resp => {
            if (checkHttpStatus(resp.data?.status)) {
              if (type === 'delete') {
                notifyApiError(resp.data.status.message, 'Info')
              } else {
                notifyApiSuccess(resp.data.status.message, 'Success')
              }
              that.applyPagination({ pageNo: that.state.pageNo, ...that.state.filterPayload }, true)
            } else {
              notifyApiError('Something went wrong. Please try again.', 'Failed')
            }
          })
          .catch(e => {
            console.log(e);
            notifyApiError('Something went wrong. Please try again.', 'Failed');
          })
      },
      onCancel() { },
    });
  }

  formatDate = date => (moment(date).format('DD/MM/YYYY'))

  componentDidMount() {
    this.applyPagination({ pageNo: this.state.pageNo }, true)
  }

  downloadS3Link = link => {
    reportsDownload(link, DownloadTags.s3);
  }

  componentDidUpdate(prevProps) {
    if (this.props.invoiceData !== prevProps.invoiceData) {
      if (this.state.selectedInvoice?.id) {
        this.setState({
          selectedInvoice: this.props?.PaginatedInvoices[this.state.pageNo]?.find(item => item.id === this.state.selectedInvoice?.id)
        })
      }
    }
    if (this.props.settleInvoiceResp !== prevProps.settleInvoiceResp) {
      if (this.props.settleInvoiceResp === 'SUCCESS')
        notifyApiSuccess('Successfully settled.', 'Success');
      this.openPaymentsModal(this.state.selectedInvoice);
      this.hideSettleInvoice();
    }
    if (this.props.updateInvoiceDueDateData !== prevProps.updateInvoiceDueDateData) {
      if (this.props.updateInvoiceDueDateData.code === 200) {
        this.hideEditInvoice()
        this.applyPagination({ pageNo: this.state.pageNo, ...this.state.filterPayload }, true)
      }
    }
    if (this.props.uploadCustomerInvoiceData !== prevProps.uploadCustomerInvoiceData) {
      if (this.props.uploadCustomerInvoiceData.code === 200) {
        this.hideEditCustInvoice()
        this.applyPagination({ pageNo: this.state.pageNo, ...this.state.filterPayload }, true)
      }
    }
    if (this.props.uploadPaymentData !== prevProps.uploadPaymentData) {
      if (this.props.uploadPaymentData.code === 202) {
        this.hideUploadPayment()
        this.applyPagination({ pageNo: this.state.pageNo, ...this.state.filterPayload }, true)
      }
    }
    if (this.props.annexureData !== prevProps.annexureData) {
      if (this.props.annexureData?.url) {
        this.setState({
          viewDocketDownloadLoader: false
        }, () => this.downloadS3Link(this.props.annexureData.url, '_blank'))
        this.hideDownloadAnnexure()
      }
      this.toggleDownloadAnnexureLoader(false)
    }
    if (this.props.uploadAnnexureData !== prevProps.uploadAnnexureData) {
      if (this.props.uploadAnnexureData?.url) {
        this.applyPagination({ pageNo: this.state.pageNo }, true)
        this.downloadS3Link(this.props.uploadAnnexureData.url, '_blank')
        this.hideUploadAnnexure()
      }
      this.toggleUploadAnnexureLoader(false)
    }
    if (this.props.exportInvoiceData !== prevProps.exportInvoiceData) {
      if (this.props.exportInvoiceData?.url) {
        this.toggleExportInvoiceLoader(false)
        this.downloadS3Link(this.props.exportInvoiceData.url, '_blank')
      } else {
        this.toggleExportInvoiceLoader(false)
      }
    }
    if (this.props.customerList !== prevProps.customerList) {
      this.setState({
        CustomerList: this.props.customerList.data
      })
    }
    if (this.props.shipperList !== prevProps.shipperList) {
      this.setState({
        shipperList: this.props.shipperList.data
      })
    }
  }

  disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  getFilterPayload = () => {
    return [
      {
        title: "Select Invoice Due Date Range",
        type: FILTER_INPUT_TYPE.DATERANGE,
        onChange: event => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              invoiceDueDate: event
            }
          })
        },
        metadata: {
          defaultValue: this.state.filterPayload?.invoiceDueDate
        }
      },
      {
        title: `${this.state.filterPayload?.searchChildInvoice ? 'Parent' : ''} Invoice No`,
        type: FILTER_INPUT_TYPE.INPUT,
        onChange: e => {

          this.setState({
            filterInvoiceNo: e.target.value,
            filterPayload: {
              ...this.state.filterPayload,
              invoiceCodes: e.target.value.split(',')
            }
          }, () => {
            this.filterInvoiceNoInput.focus();
          })
        },
        setRef: input => {
          this['filterInvoiceNoInput'] = input
        },
        metadata: {
          defaultValue: this.state.filterInvoiceNo,
          value: this.state.filterInvoiceNo
        }
      },
      {
        title: "Ungroup Bulk Invoice",
        type: FILTER_INPUT_TYPE.SWITCH,
        onChange: val => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              searchChildInvoice: val
            }
          })
        },
        metadata: {
          defaultValue: this.state.filterPayload?.searchChildInvoice || false,
          checkedChildren: "YES",
          unCheckedChildren: "NO"
        }
      },
      // {
      //   title: "Waybill No",
      //   type: FILTER_INPUT_TYPE.INPUT,
      //   onChange: e => {
      //     this.setState({
      //       filterPayload: {
      //         ...this.state.filterPayload,
      //         selectedWaybillNo: e.target.value
      //       }
      //     })
      //   },
      //   metadata: {
      //     defaultValue: this.state.filterPayload?.selectedWaybillNo,
      //     value: this.state.filterPayload?.selectedWaybillNo
      //   }
      // },
      {
        title: "Invoice Status",
        type: FILTER_INPUT_TYPE.SELECT,
        onChange: values => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              invoiceStatus: values
            }
          });
        },
        metadata: {
          defaultValue: this.state.filterPayload.invoiceStatus,
          options: Invoice_Status
        }
      },
      {
        title: "Payment Status",
        type: FILTER_INPUT_TYPE.SELECT,
        onChange: values => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              paymentStatus: values
            }
          });
        },
        metadata: {
          defaultValue: this.state.filterPayload.paymentStatus,
          options: Payment_Status
        }
      },
      {
        title: "Customer Ack Status",
        type: FILTER_INPUT_TYPE.SELECT,
        onChange: values => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              customerAckStatus: values
            }
          });
        },
        metadata: {
          defaultValue: this.state.filterPayload.customerAckStatus,
          options: Cust_Ack_Status
        }
      },
      {
        title: "Customer Name",
        type: FILTER_INPUT_TYPE.SELECT,
        mode: 'default',
        onChange: ids => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              customerIds: [ids]
            }
          }, () => this.props.getShipperList({
            "entityType": "CUSTOMER",
            "entityId": this.state.filterPayload.customerIds[0]
          }));
        },
        metadata: {
          defaultValue: this.state.filterPayload?.customerIds ? this.state.filterPayload?.customerIds[0] : undefined,
          options: this.state?.CustomerList?.map(item => ({ label: item.name, value: item.id }))
        }
      },
      {
        title: "Shipper Name",
        type: FILTER_INPUT_TYPE.SELECT,
        onChange: values => {
          this.setState({
            filterPayload: {
              ...this.state.filterPayload,
              customerShipperIds: values
            }
          });
        },
        metadata: {
          defaultValue: this.state.filterPayload.customerShipperIds,
          options: this.state.shipperList.map(item => ({ label: item.shipperName, value: item.id }))
        }
      }
    ];
  }

  resetFilters = () => {
    // this.filterInvoiceNoInput.state.value = "";
    // console.log(this.filterInvoiceNoInput)
    let { filterPayload } = this.state;
    filterPayload = {};
    this.setState({
      filterPayload,
      isFiltered: false,
      filterInvoiceNo: ''
    }, () => {
      this.applyPagination({ ...this.state.filterPayload }, true)
    })
  }

  hideDownloadAnnexure = () => {
    this.setState({
      showDownloadAnnexure: false
    })
  }

  openDownloadAnnexure = () => {
    this.setState({
      showDownloadAnnexure: true
    })
  }

  hideCustomerRejectModal = () => {
    this.setState({
      showCustomerReject: false
    })
  }

  openCustomerRejectModal = (row) => {
    this.setState({
      showCustomerReject: true,
      selectedInvoice: row
    })
  }

  hideEditInvoice = () => {
    this.setState({
      showEditInvoice: false
    })
  }

  toggleCrDrForm = (row = {}) => {
    this.setState({
      showCrDrForm: !this.state.showCrDrForm,
      selectedInvoice: row
    })
  }

  toggleCrDrNote = (row = {}) => {
    this.setState({
      showCrDrNote: !this.state.showCrDrNote,
      selectedInvoice: row,
    }, () => {
      const payload = {
        "noteBO": {
          invoiceId: row.id
        }
      }
      // if (this.state.showCrDrNote)
      //   this.props.getCrDrNoteList(payload)
    })
  }

  openEditInvoice = (row) => {
    this.setState({
      showEditInvoice: true,
      selectedInvoice: row
    })
  }

  hideEditCustInvoice = () => {
    this.setState({
      showEditCustInvoice: false
    })
  }

  openEditCustInvoice = (row, isCustInvoice, modalTitle) => {
    this.setState({
      showEditCustInvoice: true,
      selectedInvoice: row,
      isCustInvoice,
      modalTitle
    })
  }

  hideUploadAnnexure = () => {
    this.setState({
      showUploadAnnexure: false
    })
  }

  openUploadAnnexure = () => {
    this.setState({
      showUploadAnnexure: true
    })
  }

  hideUploadPayment = () => {
    this.setState({
      showUploadPayment: false
    })
  }

  openPaymentUpload = () => {
    this.setState({
      showUploadPayment: true
    })
  }

  hideOpenInvoice = () => {
    this.setState({
      showOpenInvoice: false
    })
  }

  openInvoiceModal = () => {
    this.setState({
      showOpenInvoice: true
    })
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    if (filters?.invoiceDate?.length) {
      filters.invoiceDate = { from: moment(filters.invoiceDate[0]).startOf('day').valueOf(), to: moment(filters.invoiceDate[1]).endOf('day').valueOf() }
    } else {
      filters.invoiceDate = { from: null, to: null }
    }
    if (filters?.invoiceDueDate?.length) {
      filters.invoiceDueDate = { from: moment(filters.invoiceDueDate[0]).startOf('day').valueOf(), to: moment(filters.invoiceDueDate[1]).endOf('day').valueOf() }
    } else {
      filters.invoiceDueDate = { from: null, to: null }
    }
    this.props.getInvoicesList({ filters: { ...this.state.payload.filters, ...filters } }, isReset);
  }

  toggleDownloadAnnexureLoader = isloading => {
    this.setState({
      downloadAnnexureloading: isloading
    })
  }

  toggleExportInvoiceLoader = isloading => {
    this.setState({
      exportInvoiceloading: isloading
    })
  }

  toggleUploadAnnexureLoader = isloading => {
    this.setState({
      uploadAnnexureloading: isloading
    })
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedInvoices, } = this.props;
    if (!PaginatedInvoices ? !PaginatedInvoices : !PaginatedInvoices[pageNo]) {
      this.applyPagination({ pageNo, ...this.state.filterPayload })
    }
    this.setState({
      pageNo
    });
  }

  removeFilteredValue = key => {
    const filterPayload = { ...this.state.filterPayload }
    delete filterPayload[key]

    this.setState({
      filterPayload,
      isFiltered: (Object.keys(filterPayload).length !== 0)
    }, () => this.applyPagination({ ...this.state.filterPayload }, true))
  }

  hideSettleInvoice = () => {
    this.setState({
      showSettleInvoice: false,
      isDirectSettleOpen: false
    })
    this.applyPagination({ pageNo: this.props.invoiceData.currentPageNo, ...this.state.filterPayload }, true);
  }

  openSettleInvoice = (row) => {
    this.setState({
      showSettleInvoice: true,
      selectedInvoice: row,
      isDirectSettleOpen: true
    })
  }

  hideDocketModal = () => {
    this.setState({
      showViewDocket: false
    })
    // this.applyPagination({ pageNo: this.props.invoiceData.currentPageNo, ...this.state.filterPayload }, true);
  }

  openDocketModal = (row) => {
    this.setState({
      showViewDocket: true,
      selectedInvoice: row
    }, () => {
      this.props.getInvoicesDocketsList(row.id)
    })
  }

  hideDelinkedDocketModal = () => {
    this.setState({
      showViewDelinkedDocket: false
    })
    // this.applyPagination({ pageNo: this.props.invoiceData.currentPageNo, ...this.state.filterPayload }, true);
  }

  openDelinkedDocketModal = (row) => {
    this.setState({
      showViewDelinkedDocket: true,
      selectedInvoice: row
    }, () => {
      this.props.getInvoicesDocketsList(row.id)
    })
  }

  hidePaymentsModal = () => {
    this.setState({
      showViewPayments: false,
      selectedPayment: {},
      isDirectSettleOpen: false
    })
    // this.applyPagination({ pageNo: this.props.invoiceData.currentPageNo, ...this.state.filterPayload }, true);
  }

  openPaymentsModal = (row) => {
    if (!row) return false;
    this.setState({
      showViewPayments: !this.state.isDirectSettleOpen,
      selectedInvoice: row
    }, () => {
      const payload = {
        "invoiceId": row.id,
        "activityTypes": ["SETTLEMENT_MADE"]
      }

      this.props.getInvoicesPaymentsList(payload);
    })
  }

  exportData = () => {
    this.toggleExportInvoiceLoader(true)
    const filters = { ...this.state.filterPayload }

    if (filters.invoiceDate) {
      filters['dateRange'] = {
        from: moment(filters.invoiceDate[0]).startOf('day').valueOf(),
        to: moment(filters.invoiceDate[1]).endOf('day').valueOf()
      }
    } else {
      filters['dateRange'] = {
        from: null,
        to: null
      }
    }
    if (filters.invoiceDueDate) {
      filters['invoiceDueDate'] = {
        from: moment(filters.invoiceDueDate[0]).startOf('day').valueOf(),
        to: moment(filters.invoiceDueDate[1]).endOf('day').valueOf()
      }
    } else {
      filters['invoiceDueDate'] = {
        from: null,
        to: null
      }
    }
    if (filters?.customerIds) {
      filters.customerId = filters.customerIds[0]
    }
    delete filters.customerIds;
    delete filters.invoiceDate;
    const payload = {
      consignmentStatus: "DELIVERED",
      documentType: "AR_REPORT",
      partnerId: Constants.PARTNER_ID,
      ...filters
    };

    this.props.exportInvoice(payload)
  }

  handleOpenInvoiceResp = () => {
    this.applyPagination({ pageNo: 1, ...this.state.filterPayload }, true)
    this.hideOpenInvoice()
  }

  downloadViewDocket = () => {
    const payload = {
      "partnerId": Constants.PARTNER_ID,
      "customerId": this.state.selectedInvoice?.customer?.id,
      'invoiceIds': this.state.selectedInvoice?.id ? [this.state.selectedInvoice?.id] : [],
      "consignmentStatus": "DELIVERED",
      "documentType": "CONSIGNMENT_ANNEXURE_REPORT"
    }

    this.setState({
      viewDocketDownloadLoader: true
    }, () => this.props.downloadAnnexure(payload))
  }

  render() {
    const { PaginatedInvoices, updateInvoiceDueDateLoading, uploadCustomerInvoiceLoading, invoiceloading, invoiceData, invoiceTransactions, invoiceTransactionsLoading, consignmentsLoading, consignmentDetails, delinkedConsignmentDetails, noteloading, noteMappingBOS } = this.props;
    const { columns, docketColumns, delinkedDocketColumns, paymentColumns, pageNo, filterPayload, selectedInvoice, isFiltered, exportInvoiceloading, isCustInvoice, viewDocketDownloadLoader, invoiceCrDrNoteColumns } = this.state;
    // const { totalDueAmount, totalReceivedAmount, totalReceivable, readyForSubmission, waitingForAck, ackReceived } = invoiceData.invoiceSparklineData | {}
    return (
      <Card className="InvoiceCustomerSettlement">
        <Row gutter={12}>
          <Col span={5}>
            <Sparkline
              title="Due Amount"
              highlightText={formatCurrency(this.props?.invoiceData?.invoiceSparklineData?.totalDueAmount)}
            />
          </Col>
          {/* <Col span={4}>
            <Sparkline
              title="Total Received Amount"
              highlightText={formatCurrency(this.props?.invoiceData?.invoiceSparklineData?.totalReceivedAmount)}
            />
          </Col> */}
          <Col span={5}>
            <Sparkline
              title="Receivable Amount"
              highlightText={formatCurrency(this.props?.invoiceData?.invoiceSparklineData?.totalReceivable)}
            />
          </Col>
          <Col span={5}>
            <Sparkline
              title="Pending Submission"
              highlightText={this.props?.invoiceData?.invoiceSparklineData?.readyForSubmission ? this.props?.invoiceData?.invoiceSparklineData?.readyForSubmission : 0}
            />
          </Col>
          <Col span={5}>
            <Sparkline
              title="Waiting for Acknowledgment"
              highlightText={this.props?.invoiceData?.invoiceSparklineData?.waitingForAck ? this.props?.invoiceData?.invoiceSparklineData?.waitingForAck : 0}
            />
          </Col>
          <Col span={4}>
            <Button onClick={e => this.openDownloadAnnexure()} block>Download Annexure</Button>
            <Button onClick={e => this.openUploadAnnexure()} block>Upload Annexure</Button>
            <Button onClick={e => this.openPaymentUpload()} block>Upload Payments</Button>
          </Col>
        </Row>
        <Row gutter={12} className="mt-5">
          <Col span={12}></Col>
          <Col span={4}>
            <Button onClick={e => this.openInvoiceModal()} type='primary' block>Create Open Invoice</Button>
          </Col>
          <Col span={4}>
            <Button loading={exportInvoiceloading} onClick={e => this.exportData()} type='primary' block>Export Invoices</Button>
          </Col>
          <Col span={4}>
            <Filters
              filterPayload={this.getFilterPayload()}
              applyFilter={() => { this.setState({ isFiltered: true }); this.applyPagination({ ...this.state.filterPayload }, true) }}
              resetFilters={this.resetFilters}
            />
          </Col>
          <Col span={24}>
            {
              (Object.keys(filterPayload).length && isFiltered)
                ? Object.keys(filterPayload).map(key => {
                  switch (key) {
                    case 'invoiceDate':
                      return filterPayload[key]?.length ? <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={this.formatDate(filterPayload[key][0].valueOf()) + ' - ' + this.formatDate(filterPayload[key][1].valueOf())} /> : null
                    case 'invoiceDueDate':
                      return filterPayload[key]?.length ? <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={this.formatDate(filterPayload[key][0].valueOf()) + ' - ' + this.formatDate(filterPayload[key][1].valueOf())} /> : null
                    case 'invoiceCodes':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={'INV | ' + filterPayload[key]} />
                    case 'searchChildInvoice':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={`Ungroup Docket INV | ${filterPayload[key] === true ? "Yes" : "No"}`} />
                    case 'selectedWaybillNo':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={'Waybill | ' + filterPayload[key]} />
                    case 'customerAckStatus':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={filterPayload[key].join(' | ')} />
                    case 'invoiceStatus':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={filterPayload[key].join(' | ')} />
                    case 'paymentStatus':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={filterPayload[key].join(' | ')} />
                    case 'customerShipperIds':
                      return filterPayload[key].length ? <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={this.state.shipperList.filter(item => filterPayload[key].includes(item.id)).map(item => (item.shipperName)).join(' | ')} /> : null
                    case 'customerIds':
                      return <Input className="tag-input" suffix={<Icon type="close" onClick={e => this.removeFilteredValue(key)} />} readOnly value={this.state?.CustomerList.find(item => item.id === filterPayload[key][0]).name} />
                    default: return null
                  }
                })
                : null
            }
          </Col>
        </Row>
        <Row className="mt-5">
          <Col span={24}>
            <Spin spinning={invoiceloading}>
              <Table
                columns={columns}
                pagination={{
                  onChange: this.getPaginatedData,
                  total: invoiceData?.totalCount,
                  position: 'bottom',
                  current: pageNo,
                  pageSize: invoiceData?.currentPageSize
                }}

                bordered
                rowKey={row => row.id}
                dataSource={PaginatedInvoices ? PaginatedInvoices[pageNo] : []}
                loading={invoiceloading}
                scroll={{ x: 1300, y: 350 }}
              />
            </Spin>
          </Col>
        </Row>

        <Modal
          onCancel={this.hideDownloadAnnexure}
          visible={this.state.showDownloadAnnexure}
          width="30vw"
          footer={null}
          title="Download Annexure"
          centered
          destroyOnClose={true}
          className="download-annexure"
        >
          <DownloadAnnexureForm close={this.hideDownloadAnnexure} downloadAnnexureloading={this.state.downloadAnnexureloading} toggleDownloadAnnexureLoader={this.toggleDownloadAnnexureLoader} />
        </Modal>
        <Modal
          onCancel={this.hideUploadAnnexure}
          visible={this.state.showUploadAnnexure}
          width="30vw"
          footer={null}
          title="Upload Annexure"
          centered
          destroyOnClose={true}
          className="download-annexure"
        >
          <UploadAnnexureForm close={this.hideUploadAnnexure} uploadAnnexureloading={this.state.uploadAnnexureloading} toggleUploadAnnexureLoader={this.toggleUploadAnnexureLoader} />
        </Modal>
        <Modal
          onCancel={this.hideEditInvoice}
          visible={this.state.showEditInvoice}
          width="30vw"
          className="download-annexure"
          footer={null}
          title="Edit Invoice"
          centered
          destroyOnClose={true}
        >
          {
            <EditInvoiceForm loading={updateInvoiceDueDateLoading} selectedInvoice={selectedInvoice} hideEditInvoice={this.hideEditInvoice} />
          }
        </Modal>
        <Modal
          onCancel={() => this.toggleCrDrForm(this.state.selectedInvoice)}
          visible={this.state.showCrDrForm}
          width="40vw"
          className="download-annexure"
          footer={null}
          title={`${selectedInvoice?.noteRow ? 'Edit' : 'Add'} Credit/Debit Note`}
          centered
          destroyOnClose={true}
        >
          {
            <Spin spinning={false}>
              <EditCrDrNoteForm applyPagination={this.applyPagination} filterPayload={this.state.filterPayload} selectedInvoice={selectedInvoice} toggleCrDrForm={this.toggleCrDrForm} />
            </Spin>
          }
        </Modal>
        <Modal
          onCancel={this.hideEditCustInvoice}
          visible={this.state.showEditCustInvoice}
          width="30vw"
          className="download-annexure"
          footer={null}
          title={this.state.modalTitle}
          centered
          destroyOnClose={true}
        >
          {
            <EditCustInvoiceForm isCustInvoice={isCustInvoice} loading={uploadCustomerInvoiceLoading} selectedInvoice={selectedInvoice} hideEditInvoice={this.hideEditCustInvoice} />
          }
        </Modal>
        <Modal
          onCancel={this.hideDocketModal}
          visible={this.state.showViewDocket}
          width="90vw"
          className="download-annexure"
          footer={null}
          title={'View Dockets'}
          destroyOnClose={true}
          style={{ top: '50px' }}
        >
          <Row gutter={12}>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>{selectedInvoice?.customer?.name}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>INV : {selectedInvoice?.invoiceCode}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Total Dockets : {selectedInvoice?.numberOfConsignment}</Tag>
            </Col>
            <Col span={6}>
              <Button loading={viewDocketDownloadLoader} type="primary" onClick={e => this.downloadViewDocket()} style={{ float: "right" }}>Download</Button>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Table
                columns={docketColumns}
                pagination={false}
                bordered
                rowKey={row => row.consignmentId}
                dataSource={consignmentDetails ? consignmentDetails : []}
                loading={consignmentsLoading}
                scroll={{ x: 1300, y: 340 }}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          onCancel={this.hideDelinkedDocketModal}
          visible={this.state.showViewDelinkedDocket}
          width="90vw"
          className="download-annexure"
          footer={null}
          title={'View Delinked Dockets'}
          destroyOnClose={true}
          style={{ top: '50px' }}
        >
          <Row gutter={12}>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>{selectedInvoice?.customer?.name}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>INV : {selectedInvoice?.invoiceCode}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Total Delinked Dockets : {delinkedConsignmentDetails ? delinkedConsignmentDetails?.length : 0}</Tag>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Table
                columns={delinkedDocketColumns}
                pagination={false}
                bordered
                rowKey={row => row.consignmentId}
                dataSource={delinkedConsignmentDetails ? delinkedConsignmentDetails : []}
                loading={consignmentsLoading}
                scroll={{ x: 1300, y: 340 }}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          onCancel={this.hidePaymentsModal}
          visible={this.state.showViewPayments}
          width="70vw"
          className="download-annexure"
          footer={null}
          title={'View Payments'}
          destroyOnClose={true}
          style={{ top: "50px" }}
        >
          <Row gutter={12}>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>{selectedInvoice?.customer?.name}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>INV : {selectedInvoice?.invoiceCode}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Payment Received : {formatCurrency(selectedInvoice?.paidAmount)}</Tag>
            </Col>
            <Col span={6}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Pending Receivable :  {selectedInvoice?.paidAmount ? formatCurrency((selectedInvoice?.totalAmount - selectedInvoice?.paidAmount)) : formatCurrency(selectedInvoice?.totalAmount)}</Tag>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Table
                columns={paymentColumns}
                bordered
                rowKey={row => row.id}
                dataSource={invoiceTransactions ? invoiceTransactions : []}
                loading={invoiceTransactionsLoading}
                scroll={{ y: 340 }}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          onCancel={this.toggleCrDrNote}
          visible={this.state.showCrDrNote}
          width="70vw"
          className="download-annexure"
          footer={null}
          title={'Credit/Debit Notes'}
          destroyOnClose={true}
          style={{ top: "50px" }}
        >
          <Row gutter={12}>
            <Col span={4}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>{selectedInvoice?.customer?.name}</Tag>
            </Col>
            <Col span={5}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>INV : {selectedInvoice?.invoiceCode}</Tag>
            </Col>
            <Col span={5}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Payment Received : {formatCurrency(selectedInvoice?.paidAmount)}</Tag>
            </Col>
            <Col span={5}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Total Credit Amt :  {(selectedInvoice?.invoiceCreditDebitNoteMappingBOS?.length > 0) ? formatCurrency(selectedInvoice.invoiceCreditDebitNoteMappingBOS.filter(i => i.transactionType === 'CREDIT').reduce((a, b) => a + Number(b.amount), 0)) : '0'}</Tag>
            </Col>
            <Col span={5}>
              <Tag style={{ width: '100%', textAlign: 'center', padding: 10 }}>Total Debit Amt :  {(selectedInvoice?.invoiceCreditDebitNoteMappingBOS?.length > 0) ? formatCurrency(selectedInvoice.invoiceCreditDebitNoteMappingBOS.filter(i => i.transactionType === 'DEBIT').reduce((a, b) => a + Number(b.amount), 0)) : '0'}</Tag>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Table
                columns={invoiceCrDrNoteColumns}
                bordered
                rowKey={row => row.id}
                dataSource={selectedInvoice?.invoiceCreditDebitNoteMappingBOS ? selectedInvoice.invoiceCreditDebitNoteMappingBOS : []}
                loading={invoiceloading}
                scroll={{ y: 340 }}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          onCancel={this.hideOpenInvoice}
          visible={this.state.showOpenInvoice}
          width="30vw"
          className="download-annexure"
          footer={null}
          title={'Open Invoice'}
          centered
          destroyOnClose={true}
        >
          <OpenInvoiceForm submitOpenInvoiceForm={this.handleOpenInvoiceResp} hideOpenInvoice={this.hideOpenInvoice} />
        </Modal>
        <Modal
          onCancel={this.hideCustomerRejectModal}
          visible={this.state.showCustomerReject}
          width="30vw"
          className="download-annexure"
          footer={null}
          title={'Invoice Rejected By Customer'}
          centered
          destroyOnClose={true}
        >
          <CustomerRejectForm selectedInvoice={selectedInvoice} hideCustomerRejectModal={this.hideCustomerRejectModal} />
        </Modal>
        <Modal
          onCancel={this.hideUploadPayment}
          visible={this.state.showUploadPayment}
          width="30vw"
          className="download-annexure"
          footer={null}
          title={'Payment Upload'}
          centered
          destroyOnClose={true}
        >
          <UploadPaymentForm hideUploadPayment={this.hideUploadPayment} />
        </Modal>
        <Modal
          onCancel={this.hideSettleInvoice}
          visible={this.state.showSettleInvoice}
          width="45vw"
          footer={null}
          centered
          destroyOnClose={true}
        >
          <SettleInvoice
            settleInvoice={this.props.settleCustomerInvoice}
            data={this.state.selectedInvoice}
            closeModal={this.hideSettleInvoice}
            uploadDebitNote={this.props.uploadDebitNote}
            debitNoteLink={this.props.debitNoteLink}
            debitloading={this.props.debitloading}
            setS3SyncStatus={this.props.setS3SyncStatus}
            downloadStatus={this.props.downloadStatus}
            selectedPayment={this.state.selectedPayment}
          />
        </Modal>
      </Card>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerList: state.invoiceCustomerSettlement.customerList,
  shipperList: state.invoiceCustomerSettlement.shipperList,
  PaginatedInvoices: state.invoiceCustomerSettlement.PaginatedInvoices,
  invoiceData: state.invoiceCustomerSettlement.invoices,
  invoiceloading: state.invoiceCustomerSettlement.loading,
  annexureData: state.invoiceCustomerSettlement.downloadAnnexure,
  annexureDataLoading: state.invoiceCustomerSettlement.downloadAnnexureloading,
  uploadAnnexureData: state.invoiceCustomerSettlement.uploadAnnexure,
  uploadAnnexureDataLoading: state.invoiceCustomerSettlement.uploadAnnexureloading,
  updateInvoiceDueDateData: state.invoiceCustomerSettlement.updateInvoiceDueDate,
  updateInvoiceDueDateLoading: state.invoiceCustomerSettlement.updateInvoiceDueDateloading,
  uploadCustomerInvoiceData: state.invoiceCustomerSettlement.uploadCustomerInvoice,
  uploadCustomerInvoiceLoading: state.invoiceCustomerSettlement.uploadCustomerInvoiceloading,
  uploadPaymentData: state.invoiceCustomerSettlement.uploadPayment,
  uploadPaymentLoading: state.invoiceCustomerSettlement.uploadPaymentloading,
  exportInvoiceData: state.invoiceCustomerSettlement.exportInvoice,
  exportInvoiceLoading: state.invoiceCustomerSettlement.exportInvoiceloading,
  debitNoteLink: state?.customerSettlement?.debitNoteLink,
  debitloading: state?.customerSettlement?.debitloading,
  downloadStatus: state?.app?.s3Status,
  settleInvoiceResp: state.customerSettlement?.settleInvoiceResp,
  invoiceTransactions: state.invoiceCustomerSettlement?.invoiceTransactions,
  invoiceTransactionsLoading: state.invoiceCustomerSettlement?.paymentListloading,
  consignmentDetails: state.invoiceCustomerSettlement?.consignmentDetails,
  consignmentsLoading: state.invoiceCustomerSettlement?.docketListloading,
  delinkedConsignmentDetails: state.invoiceCustomerSettlement?.delinkedConsignmentDetails,
  noteloading: state.invoiceCustomerSettlement?.noteloading,
  noteMappingBOS: state.invoiceCustomerSettlement?.noteMappingBOS,
});
const mapDispatchToProps = dispatch => ({
  getCustomerList: () => dispatch(getCustomerList()),
  getShipperList: payload => dispatch(getShipperList(payload)),
  getInvoicesList: (payload, isReset) => dispatch(getInvoicesList(payload, isReset)),
  downloadAnnexure: payload => dispatch(downloadAnnexure(payload)),
  uploadAnnexure: payload => dispatch(uploadAnnexure(payload)),
  updateInvoiceDueDate: payload => dispatch(updateInvoiceDueDate(payload)),
  uploadCustomerInvoice: payload => dispatch(uploadCustomerInvoice(payload)),
  exportInvoice: payload => dispatch(exportInvoiceData(payload)),
  uploadDebitNote: (payload) => dispatch(uploadDebitNote(payload)),
  setS3SyncStatus: (status) => dispatch(setS3SyncStatus(status)),
  settleCustomerInvoice: payload => dispatch(settleCustomerInvoice(payload)),
  uploadPayment: payload => dispatch(uploadPayment(payload)),
  getInvoicesPaymentsList: payload => dispatch(getInvoicesPaymentsList(payload)),
  delete_CancelInvoice: (type, payload) => dispatch(delete_CancelInvoice(type, payload)),
  getInvoicesDocketsList: (id) => dispatch(getInvoicesDocketsList(id)),
  delinkDocket: (payload) => dispatch(delinkDocket(payload)),
  createOpenInvoice: (payload) => dispatch(createOpenInvoice(payload)),
  getCrDrNoteList: payload => dispatch(getCrDrNoteList(payload)),
  updateCrDrNote: payload => dispatch(updateCrDrNote(payload)),
  fetchReasonNotes: payload => dispatch(fetchReasonNotes(payload)),
});

const UploadPaymentForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'Upload Payment Form'
})(props => {
  const { getFieldDecorator } = props.form;
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = {
          ...values
        }


        props.uploadPayment(payload);
      }
    })
  }
  const onUploadFinish = url => {
    props.form.setFieldsValue({
      url: url
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator('url', {
          rules: [
            {
              required: true,
              message: "Please select file"
            }
          ],
        })(
          <FileUpload
            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
            directUpload={true}
            disableUploadButton={false}
            onUploadFinish={$events => onUploadFinish($events)}
            path="partners"
            acceptTypes={['.xlsx', '.xls', '.csv']}
            objKey="partners"
            multiple={false}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={props.uploadPaymentLoading} style={{ float: 'right' }} htmlType="submit" type="primary">Save</Button>
        <Button style={{ float: 'right' }} onClick={props.hideUploadPayment}>Cancel</Button>

      </Form.Item>
    </Form>
  )
}));

const EditInvoiceForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'Edit Invoice Form'
})(props => {
  const { getFieldDecorator } = props.form;
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // invoiceDate: values.invoiceDate,
        const invoiceTaxChargeMappingBOS = [];
        const payload = {
          id: props.selectedInvoice.id,
          invoiceDueDate: values.invoiceDueDate,
          customerGstNumber: values.customerGstNumber
        }
        if (values.cgst) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "CGST", "taxPercentage": values.cgst })
        }
        if (values.sgst) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "SGST", "taxPercentage": values.sgst })
        }
        if (values.igst) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "IGST", "taxPercentage": values.igst })
        }
        if (values.tds) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "TDS", "taxPercentage": values.tds })
        }
        if (invoiceTaxChargeMappingBOS.length !== 0) {
          payload['invoiceTaxChargeMappingBOS'] = invoiceTaxChargeMappingBOS;
        }
        props.updateInvoiceDueDate(payload);
      }
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Invoice No">
        {getFieldDecorator('invoiceNumber', {
          initialValue: props.selectedInvoice.invoiceCode,
          rules: [{ required: true, message: 'Invoice No is required!' }],
        })(<Input placeholder="Enter Invoice No" disabled={true} />)}
      </Form.Item>
      {/* <Form.Item label="Invoice Date">
        {getFieldDecorator('invoiceDate', {
          initialValue: moment(props.selectedInvoice.invoiceDate),
          rules: [{ required: true, message: 'Invoice Date is required!' }],
        })(<DatePicker />)}
      </Form.Item> */}
      <Form.Item label="Invoice Due Date">
        {getFieldDecorator('invoiceDueDate', {
          initialValue: moment(props.selectedInvoice.invoiceDueDate),
          rules: [{ required: true, message: 'Invoice Due Date is required!' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item label="TDS%">
        {getFieldDecorator('tds', {
          initialValue: props.selectedInvoice?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'TDS')?.taxPercentage,
          rules: [{ required: false, message: 'TDS% is required!' }],
        })(<Input placeholder="TDS %" />)}
      </Form.Item>
      <Form.Item label="GST Number">
        {getFieldDecorator('customerGstNumber', {
          initialValue: props.selectedInvoice?.customerGstNumber,
          rules: [{ required: false, message: 'GST Number is required!' }, { pattern: validationRules.gstNumber, message: 'GST Number is not valid!' }],
        })(<Input placeholder="Enter GST No" />)}
      </Form.Item>
      <Row gutter={12}>
        <Col span={8}>
          <Form.Item label="CGST%">
            {getFieldDecorator('cgst', {
              initialValue: props.selectedInvoice?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'CGST')?.taxPercentage,
              rules: [{ required: false, message: 'CGST% is required!' }],
            })(<Input placeholder="CGST%" />)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="SGST%">
            {getFieldDecorator('sgst', {
              initialValue: props.selectedInvoice?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'SGST')?.taxPercentage,
              rules: [{ required: false, message: 'SGST% is required!' }],
            })(<Input placeholder="SGST" />)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="IGST%">
            {getFieldDecorator('igst', {
              initialValue: props.selectedInvoice?.invoiceTaxChargeMappingBOS?.find(item => item.chargeType === 'IGST')?.taxPercentage,
              rules: [{ required: false, message: 'IGST is required!' }],
            })(<Input placeholder="IGST%" />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button loading={props.loading} style={{ float: 'right' }} htmlType="submit" type="primary">Save</Button>
        <Button style={{ float: 'right' }} onClick={props.hideEditInvoice}>Cancel</Button>
      </Form.Item>
    </Form>
  )
}));

const EditCrDrNoteForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'Edit Credit/Debit Form'
})(props => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [isReasonApiCalled, setReasonApiCall] = useState(false)

  if (!isReasonApiCalled) {
    props.fetchReasonNotes().then(({ data }) => {
      if (data && data?.length > 0) {
        setReasons(data)
      }
      setReasonApiCall(true)
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true)
        const preSelectedData = props?.selectedInvoice?.noteRow ? props.selectedInvoice.noteRow : {};
        const payload = {
          noteBO: {
            ...preSelectedData,
            invoiceId: props.selectedInvoice.id,
            transactionType: values.transactionType,
            reasonId: values.reasonId,
            amount: values.amount,
            docUrl: values.docUrl,
            isActive: true,
            transactionDate: moment(values.transactionDate).valueOf(),
          },
          action: props?.selectedInvoice?.noteAction || 'CREATE'
        }

        props.updateCrDrNote(payload)
          .then(resp => {
            setLoading(false)
            if (checkHttpStatus(resp?.data?.status)) {
              notifyApiSuccess(resp.data.status.message, 'Success')
              props.applyPagination({ pageNo: 1, ...props.filterPayload }, true)
              // props.getCrDrNoteList({ noteBO: { invoiceId: props?.selectedInvoice?.id } })
              props.toggleCrDrForm(props?.selectedInvoice)
            }
          })
          .catch(e => {
            setLoading(false)
            console.log(e)
          })
      }
    })
  }
  const onUploadFinish = url => {
    props.form.setFieldsValue({
      docUrl: url
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Spin spinning={loading}>
        <Row gutter={[12, 6]}>
          <Col span={6}>
            <div className="detailEntity">
              <div className="light-text">Customer Name</div>
              <div className="bold">{props.selectedInvoice?.customer?.name}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="detailEntity">
              <div className="light-text">Invoice Amt</div>
              <div className="bold">{props.selectedInvoice?.totalAmount ? formatCurrency(props.selectedInvoice?.totalAmount) : 'N/A'}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="detailEntity">
              <div className="light-text">Outstanding Amt</div>
              <div className="bold">{props.selectedInvoice?.paidAmount ? (<div>{formatCurrency((props.selectedInvoice?.totalAmount - props.selectedInvoice?.paidAmount))}</div>) : (<div>{props.selectedInvoice?.totalAmount ? `${formatCurrency(props.selectedInvoice?.totalAmount)}` : 'N/A'} </div>)}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="detailEntity">
              <div className="light-text">Invoice Due Date</div>
              <div className="bold">{props.selectedInvoice?.invoiceDueDate ? moment(props.selectedInvoice?.invoiceDueDate).format('DD/MM/YYYY') : 'N/A'}</div>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item label="Transaction Type">
              {getFieldDecorator('transactionType', {
                initialValue: props.selectedInvoice?.noteRow?.transactionType ? props.selectedInvoice?.noteRow?.transactionType : undefined,
                rules: [{ required: true, message: 'Transaction Type is required!' }],
              })(
                <Radio.Group>
                  <Radio value={'CREDIT'}>Credit Note</Radio>
                  <Radio value={'DEBIT'}>Debit Note</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Transaction Date">
              {getFieldDecorator('transcationDate', {
                initialValue: props.selectedInvoice?.noteRow?.transactionDate ? moment(props.selectedInvoice?.noteRow?.transactionDate) : moment(),
                rules: [{ required: true, message: 'Transaction Date is required!' }],
              })(<DatePicker />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Amount">
              {getFieldDecorator('amount', {
                initialValue: props.selectedInvoice?.noteRow?.amount,
                rules: [{ required: false, message: 'Amount is required!' }],
              })(<InputNumber min={1} placeholder="Amount" />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Reason">
              {getFieldDecorator('reasonId', {
                initialValue: props.selectedInvoice?.noteRow?.reason?.id,
                rules: [{ required: true, message: 'Reason is required!' }],
              })(
                <Select placeholder="Select Reason" showSearch filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } showArrow >
                  {reasons?.map(reason => (
                    <Select.Option
                      key={reason.id}
                      value={reason.id}
                      label={reason.reasonDescription}
                    >
                      {reason.reasonDescription}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('docUrl', {
                initialValue: props.selectedInvoice?.noteRow?.docUrl,
                rules: [
                  {
                    required: false,
                    message: "Please select file"
                  }
                ],
              })(
                <FileUpload
                  fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                  directUpload={true}
                  disableUploadButton={false}
                  onUploadFinish={$events => onUploadFinish($events)}
                  path="partners"
                  acceptTypes={['.pdf', '.jpg', '.jpeg', '.png']}
                  objKey="partners"
                  multiple={false}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button loading={props.loading} style={{ float: 'right' }} htmlType="submit" type="primary">Save</Button>
              <Button style={{ float: 'right' }} onClick={e => props.toggleCrDrForm({})}>Cancel</Button>
              {
                props?.selectedInvoice?.noteRow?.docUrl
                  ? <Button style={{ float: 'right' }} onClick={e => downloadFile(props.selectedInvoice.noteRow.docUrl, '_blank')}>Download</Button>
                  : null
              }
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </Form>
  )
}));

const EditCustInvoiceForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'Edit Customer Invoice Form'
})(props => {
  const { getFieldDecorator } = props.form;
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = {}
        if (props.isCustInvoice) {
          payload = {
            data: [
              {
                invoiceNumber: props.selectedInvoice.invoiceCode,
                invoicePDFURL: values.url,
                customerAckStatus: "WAITING_FOR_ACK",
                invoiceDueDate: props.selectedInvoice.invoiceDueDate,
                customerAckSubmissionDate: moment(values.CustAckSubDate).valueOf()
              }
            ]
          }
        } else {
          payload = {
            data: [
              {
                invoiceNumber: props.selectedInvoice.invoiceCode,
                customerAckUrl: values.url,
                invoicePDFURL: props.selectedInvoice.invoiceDocUrl,
                customerAckStatus: "ACK_RECEIVED",
                invoiceDueDate: props.selectedInvoice.invoiceDueDate,
                customerAckSubmissionDate: moment(props.selectedInvoice.customerAckSubmissionDate).valueOf(),
                customerAckDate: moment(values.CustAckSubDate).valueOf()
              }
            ]
          }
        }

        props.uploadCustomerInvoice(payload);
      }
    })
  }
  const onUploadFinish = url => {
    props.form.setFieldsValue({
      url: url
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={props.isCustInvoice ? "Customer Submission Date" : "Customer Ack Date"}>
        {getFieldDecorator('CustAckSubDate', {
          initialValue: props.selectedInvoice[props.isCustInvoice ? 'customerAckSubmissionDate' : 'customerAckDate'] ? moment(props.selectedInvoice[props.isCustInvoice ? 'customerAckSubmissionDate' : 'customerAckDate']) : undefined,
          rules: [{ required: true, message: props.isCustInvoice ? 'Customer Submission Date is required!' : 'Customer Ack Date is required' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('url', {
          rules: [
            {
              required: true,
              message: "Please select file"
            }
          ],
        })(
          <FileUpload
            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
            directUpload={true}
            disableUploadButton={false}
            onUploadFinish={$events => onUploadFinish($events)}
            path="partners"
            acceptTypes={['.pdf', '.jpg', '.jpeg', '.png']}
            objKey="partners"
            multiple={false}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={props.loading} style={{ float: 'right' }} htmlType="submit" type="primary">Save</Button>
        <Button style={{ float: 'right' }} onClick={props.hideEditInvoice}>Cancel</Button>
        {
          props.selectedInvoice[props.isCustInvoice ? 'invoiceDocUrl' : 'customerAckUrl']
            ? <Button style={{ float: 'right' }} onClick={e => downloadFile(props.selectedInvoice[props.isCustInvoice ? 'invoiceDocUrl' : 'customerAckUrl'], '_blank')}>Download</Button>
            : null
        }

      </Form.Item>
    </Form>
  )
}));

const DownloadAnnexureForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'download annexure form'
})(props => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const onCustomerChange = id => {
    const payload = {
      "entityType": "CUSTOMER",
      "entityId": id
    }
    props.getShipperList(payload);
  }
  const payload = {
    "partnerId": Constants.PARTNER_ID,
    "customerId": null,
    "customerShipperIds": null,
    "dateRange": {
      "from": null,
      "to": null
    },
    "consignmentStatus": "DELIVERED",
    "documentType": "CONSIGNMENT_ANNEXURE_REPORT",
    "movementTypes": ["PTL"]
  }
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        props.toggleDownloadAnnexureLoader(true);
        payload.customerId = values.customerId
        payload.customerShipperIds = values.customerShipperId || []
        payload.dateRange = {
          from: values.dateRange[0].startOf('day').valueOf(),
          to: values.dateRange[1].endOf('day').valueOf()
        }
        payload.consignmentStatus = !!values.consignmentStatus ? values.consignmentStatus === 'DEL' ? 'DELIVERED' : values.consignmentStatus : null
        payload.consignmentsWithRateMandatory = values.consignmentStatus === 'DEL' ? true : false
        payload.consignmentsDateRangeFilter = values.consignmentsDateRangeFilter;
        props.downloadAnnexure(payload)
      }
    })
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Select Date Range Type">
        {getFieldDecorator('consignmentsDateRangeFilter', {
          initialValue: 'BOOKING_DATE',
          rules: [{ required: true, message: 'Booking Date is required!' }],
        })(<Radio.Group>
          <Radio value={'BOOKING_DATE'}>Booking Date</Radio>
          <Radio value={'PICKUP_DATE'}>Pickup Date</Radio>
        </Radio.Group>)}
      </Form.Item>
      <Form.Item label={`Select ${getFieldValue('consignmentsDateRangeFilter') === 'BOOKING_DATE' ? 'Booking Date' : 'Pickup Date'}`}>
        {getFieldDecorator('dateRange', {
          rules: [{ required: true, message: `${getFieldValue('consignmentsDateRangeFilter') === 'BOOKING_DATE' ? 'Booking Date' : 'Pickup Date'} is required!` }],
        })(<DatePicker.RangePicker disabledDate={disabledDate} />)}
      </Form.Item>
      <Form.Item label="Customer">
        {getFieldDecorator('customerId', {
          rules: [
            {
              required: true,
              message: "Please select Customer"
            }
          ],
        })(
          <Select placeholder="Select Customer" showSearch filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } showArrow onChange={id => onCustomerChange(id)}>
            {props?.customerList?.data?.map(cust => (
              <Select.Option
                key={cust.id}
                value={cust.id}
                label={cust.name}
              >
                {cust.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Shipper">
        {getFieldDecorator('customerShipperId', {
          rules: [
            {
              required: false,
              message: "Please select Shipper"
            }
          ],
        })(
          <Select mode="multiple" placeholder={props?.shipperList?.loading ? 'Loading..' : "Select Shipper"} showSearch filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } showArrow>
            {props?.shipperList?.data?.map(shipper => (
              <Select.Option
                key={shipper.id}
                value={shipper.id}
                label={shipper.shipperName}
              >
                {shipper.shipperName}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Dockets Status">
        {getFieldDecorator('consignmentStatus', {
          initialValue: null,
          rules: [{ required: false, message: 'Delivered Dockets is required!' }],
        })(<Radio.Group>
          <Radio value={null}>All</Radio>
          <Radio value={'DELIVERED'}>Delivered</Radio>
          <Radio value={'DEL'}>Delivered & Revenue Calculated</Radio>
        </Radio.Group>)}
      </Form.Item>
      {/* <Form.Item label="System Rate Applied">
        {getFieldDecorator('consignmentsWithRateMandatory', {
          initialValue: false,
          rules: [{ required: false, message: 'System Rate Applied is required!' }],
        })(<Radio.Group>
          <Radio value={true}>YES</Radio>
          <Radio value={false}>NO</Radio>
        </Radio.Group>)}
      </Form.Item> */}
      <Form.Item>
        <Button loading={props.downloadAnnexureloading} htmlType="submit" type="primary" style={{ float: 'right' }}>Download</Button>
        <Button onClick={props.close} style={{ float: 'right' }}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}));

const OpenInvoiceForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'download annexure form'
})(props => {
  const { getFieldDecorator } = props.form;
  const onCustomerChange = id => {
    const payload = {
      "entityType": "CUSTOMER",
      "entityId": id
    }
    props.getShipperList(payload);
  }
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          "invoiceCode": values.invoiceCode,
          "totalAmount": values.totalAmount,
          "customer": {
            "id": values.customerId
          },
          "customerShipperIds": values.customerShipperIds,
          "invoiceDueDate": moment(values.invoiceDueDate).valueOf(),
          "invoiceDate": moment(values.invoiceDate).valueOf()
        }

        props.createOpenInvoice(payload)
          .then(resp => {
            if (checkHttpStatus(resp.data.status)) {
              notifyApiSuccess(resp.data.status.message, 'Success')
              props.submitOpenInvoiceForm()
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Customer">
        {getFieldDecorator('customerId', {
          rules: [
            {
              required: true,
              message: "Please select Customer"
            }
          ],
        })(
          <Select placeholder="Select Customer" showSearch filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } showArrow onChange={id => onCustomerChange(id)}>
            {props?.customerList?.data?.map(cust => (
              <Select.Option
                key={cust.id}
                value={cust.id}
                label={cust.name}
              >
                {cust.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Shipper">
        {getFieldDecorator('customerShipperIds', {
          rules: [
            {
              required: true,
              message: "Please select Shipper"
            }
          ],
        })(
          <Select mode="multiple" placeholder={props?.shipperList?.loading ? 'Loading..' : "Select Shipper"} showSearch filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } showArrow>
            {props?.shipperList?.data?.map(shipper => (
              <Select.Option
                key={shipper.id}
                value={shipper.id}
                label={shipper.shipperName}
              >
                {shipper.shipperName}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Invoice No">
        {getFieldDecorator('invoiceCode', {
          rules: [{ required: true, message: 'invoice no is required!' }],
        })(<Input placeholder="Invoice No" />)}
      </Form.Item>
      <Form.Item label="Invoice Amount">
        {getFieldDecorator('totalAmount', {
          rules: [{ required: true, message: 'invoice amount is required!' }],
        })(<Input placeholder="Invoice Amount" />)}
      </Form.Item>
      <Form.Item label="Invoice Date">
        {getFieldDecorator('invoiceDate', {
          rules: [{ required: true, message: 'invoice date is required!' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item label="Due Date">
        {getFieldDecorator('invoiceDueDate', {
          rules: [{ required: true, message: 'due date is required!' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item>
        <Button loading={props.downloadAnnexureloading} htmlType="submit" type="primary" style={{ float: 'right' }}>Submit</Button>
        <Button onClick={props.hideOpenInvoice} style={{ float: 'right' }}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}));

const CustomerRejectForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'download annexure form'
})(props => {
  const { getFieldDecorator } = props.form;
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          "data": [
            {
              "invoiceNumber": props.selectedInvoice.invoiceCode,
              "customerAckStatus": "REJECTED_BY_CUSTOMER",
              "remarks": values.CustReason,
              customerAckSubmissionDate: moment(values.CustDate).valueOf(),
              customerAckUrl: values.url
            }
          ]
        }

        props.uploadCustomerInvoice(payload)
          .then(resp => {
            if (checkHttpStatus(resp.data.status)) {
              notifyApiSuccess(resp.data.status.message, 'Success')
              props.hideCustomerRejectModal()
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    })
  }
  const onUploadFinish = url => {
    props.form.setFieldsValue({
      url: url
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Customer Rejected Date">
        {getFieldDecorator('CustDate', {
          initialValue: props.selectedInvoice.customerAckSubmissionDate ? moment(props.selectedInvoice.customerAckSubmissionDate) : undefined,
          rules: [{ required: true, message: 'customer rejected date is required!' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item label="Customer Rejected Reason">
        {getFieldDecorator('CustReason', {
          rules: [{ required: true, message: 'customer rejection reason is required!' }],
        })(<Input placeholder="Customer Rejected Reason" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('url', {
          initialValue: props.selectedInvoice.customerAckUrl ? props.selectedInvoice.customerAckUrl : '',
          rules: [
            {
              required: false,
              message: "Please select file"
            }
          ],
        })(
          <FileUpload
            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
            directUpload={true}
            disableUploadButton={false}
            onUploadFinish={$events => onUploadFinish($events)}
            path="partners"
            acceptTypes={['.pdf', '.png', '.jpg', '.jpeg']}
            objKey="partners"
            multiple={false}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={props.downloadAnnexureloading} htmlType="submit" type="primary" style={{ float: 'right' }}>Submit</Button>
        <Button onClick={props.hideCustomerRejectModal} style={{ float: 'right' }}>Cancel</Button>
        {
          props.selectedInvoice.customerAckUrl
            ? <Button style={{ float: 'right' }} onClick={e => reportsDownload(props.selectedInvoice.customerAckUrl, DownloadTags.s3)}>Download file</Button>
            : null
        }
      </Form.Item>
    </Form>
  );
}));

const UploadAnnexureForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({
  name: 'upload annexure form'
})(props => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const onCustomerChange = id => {
    const payload = {
      "entityType": "CUSTOMER",
      "entityId": id
    }
    props.getShipperList(payload);
  }
  let payload = {
    invoiceDate: '',
    invoiceDueDate: '',
    customerId: '',
    customerShipperIds: [],
    invoiceNumber: '',
    url: ''
  }
  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        props.toggleUploadAnnexureLoader(true);
        const invoiceTaxChargeMappingBOS = []
        payload = {
          invoiceDate: values.invoiceDate?.valueOf(),
          invoiceDueDate: values.invoiceDueDate?.valueOf(),
          customerId: values.customerId,
          customerShipperIds: values.customerShipperId,
          invoiceNumber: values.invoiceNumber,
          url: values.url,
          customerGstNumber: values.customerGstNumber,
          isChildInvoice: values.isChildInvoice
        }

        if (values.cgst) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "CGST", "taxPercentage": values.cgst })
        }
        if (values.sgst) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "SGST", "taxPercentage": values.sgst })
        }
        if (values.igst) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "IGST", "taxPercentage": values.igst })
        }
        if (values.tds) {
          invoiceTaxChargeMappingBOS.push({ "chargeType": "TDS", "taxPercentage": values.tds })
        }
        if (invoiceTaxChargeMappingBOS.length !== 0) {
          payload['invoiceTaxChargeMappingBOS'] = invoiceTaxChargeMappingBOS;
        }

        props.uploadAnnexure(payload)
      }
    })
  }
  const onUploadFinish = url => {
    props.form.setFieldsValue({
      url: url
    })
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Bulk Invoice Upload">
        {getFieldDecorator('isChildInvoice', {
          initialValue: false,
          rules: [{ required: true, message: 'Bulk Invoice Upload is required!' }],
        })(<Switch checkedChildren="TRUE" unCheckedChildren="FALSE" />)}
      </Form.Item>
      <Form.Item label={`${getFieldValue('isChildInvoice') ? 'Parent' : ''} Invoice No`}>
        {getFieldDecorator('invoiceNumber', {
          rules: [{ required: true, message: 'Invoice No is required!' }],
        })(<Input placeholder="Enter Invoice No" />)}
      </Form.Item>
      <Form.Item label="Invoice Date">
        {getFieldDecorator('invoiceDate', {
          rules: [{ required: false, message: 'Invoice Date is required!' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item label="Due Date">
        {getFieldDecorator('invoiceDueDate', {
          rules: [{ required: false, message: 'Due Date is required!' }],
        })(<DatePicker />)}
      </Form.Item>
      <Form.Item label="Customer">
        {getFieldDecorator('customerId', {
          rules: [
            {
              required: true,
              message: "Please select Customer"
            }
          ],
        })(
          <Select placeholder="Select Customer" showSearch filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } showArrow onChange={id => onCustomerChange(id)}>
            {props?.customerList?.data?.map(cust => (
              <Select.Option
                key={cust.id}
                value={cust.id}
                label={cust.name}
              >
                {cust.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Shipper">
        {getFieldDecorator('customerShipperId', {
          rules: [
            {
              required: false,
              message: "Please select Shipper"
            }
          ],
        })(
          <Select mode="multiple" placeholder={props?.shipperList?.loading ? 'Loading..' : "Select Shipper"} showSearch filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } showArrow>
            {props?.shipperList?.data?.map(shipper => (
              <Select.Option
                key={shipper.id}
                valuthen we dont need bank e={shipper.id}
                label={shipper.shipperName}
              >
                {shipper.shipperName}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="TDS%">
        {getFieldDecorator('tds', {
          rules: [{ required: false, message: 'TDS% is required!' }],
        })(<Input placeholder="TDS %" />)}
      </Form.Item>
      <Form.Item label="GST Number">
        {getFieldDecorator('customerGstNumber', {
          rules: [{ required: true, message: 'GST Number is required!' }, { pattern: validationRules.gstNumber, message: 'GST Number is not valid!' }],
        })(<Input placeholder="Enter GST No" />)}
      </Form.Item>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="CGST%">
            {getFieldDecorator('cgst', {
              rules: [{ required: false, message: 'CGST% is required!' }],
            })(<Input placeholder="CGST%" />)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="SGST%">
            {getFieldDecorator('sgst', {
              rules: [{ required: false, message: 'SGST% is required!' }],
            })(<Input placeholder="SGST" />)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="IGST%">
            {getFieldDecorator('igst', {
              rules: [{ required: false, message: 'IGST is required!' }],
            })(<Input placeholder="IGST%" />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        {getFieldDecorator('url', {
          rules: [
            {
              required: true,
              message: "Please select file"
            }
          ],
        })(
          <FileUpload
            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
            directUpload={true}
            disableUploadButton={false}
            onUploadFinish={$events => onUploadFinish($events)}
            path="partners"
            acceptTypes={['.csv', '.xls', '.xlsx']}
            objKey="partners"
            multiple={false}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button loading={props.uploadAnnexureloading} htmlType="submit" type="primary" style={{ float: 'right' }}>Upload</Button>
        <Button onClick={props.close} style={{ float: 'right' }}>Cancel</Button>
      </Form.Item>
    </Form>
  );
}));

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCustomerSettlement);