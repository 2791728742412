import { Constants } from '../../../common/utils/constants';
import * as apiUtils from '../../../common/utils/apiGateway';

export const fetchCustomersUtil = value => new Promise(resolve => {
    if (value) {
        let params = { 
            "filters": { 
                "pageNo": 1, 
                "pageSize": 10, 
                "totalCount": 0, 
                "customerTypes": ["CUSTOMER"], 
                "status": ["ACTIVE"], 
                "fetchMode": "BASIC", 
                "partialContentSearch": value, 
                "partnerIds": [Constants.PARTNER_ID] 
            } 
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data: params
        }).then(response => {
            let customers = false;
            customers = response?.data?.response?.customers;
            if(!customers){
                resolve(false);
            }
            else{
                customers = customers.map(v => {
                    return {
                        label: v?.name,
                        key: v?.id,
                        value: v?.id
                    }
                });
                resolve(customers);
            }
        })
    }
    else{
        resolve(false);
    }
});

export const fetchLocationsUtil = value => new Promise(resolve => {
    if (value) {
        let params = { 
            filters: { 
                entityTypes: ["PARTNER"],
                entityIds: [Constants.PARTNER_ID],
                isConsiderCreatorId: false,
                fetchObjects: [],
                status: true,
                pageNo: 1,
                pageSize: 10,
                partialContentSearch: value 
            }
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data: params
        }).then(response => {
            let locations = false;
            locations = response?.data?.response?.locations;
            if(!locations){
                resolve(false);
            }
            else{
                locations = locations.map(v => {
                    return {
                        label: v?.name,
                        key: v?.id,
                        value: v?.id
                    }
                });
                resolve(locations);
            }
        });
    }
    else{
        resolve(false);
    }
});

export const fetchDeliveryLocationsUtil = (value, customerIds) => new Promise(resolve => {
    if (value) {
        let params = { 
            filters: { 
                roles: ["DELIVERY"],
                entityTypes: ["CUSTOMER"],
                entityIds: customerIds,
                isConsiderCreatorId: false,
                fetchObjects: [],
                status: true,
                pageNo: 1,
                pageSize: 10,
                partialContentSearch: value
            }
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/location-groups/fetch`;
        apiUtils.apiRequest({
            url,
            method: "POST",
            data: params
        }).then(response => {
            let locations = false;
            locations = response?.data?.response?.locationsGroupedByRole?.DELIVERY;
            if(!locations){
                resolve(false);
            }
            else{
                locations = locations.map(v => {
                    return {
                        label: v?.name,
                        key: v?.id,
                        value: v?.id
                    }
                });
                resolve(locations);
            }
        });
    }
    else{
        resolve(false);
    }  
})