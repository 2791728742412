export const GET_REPORT_TYPE_BEGIN = 'GET_REPORT_TYPE_BEGIN';
export const GET_REPORT_TYPE_SUCCESS = 'GET_REPORT_TYPE_SUCCESS';
export const GET_REPORT_TYPE_FAILURE = 'GET_REPORT_TYPE_FAILURE';

export const GET_CUSTOMER_LIST_BEGIN = 'GET_CUSTOMER_LIST_BEGIN';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const GET_LOCATION_LIST_BEGIN = 'GET_LOCATION_LIST_BEGIN';
export const GET_LOCATION_LIST_SUCCESS = 'GET_LOCATION_LIST_SUCCESS';
export const GET_LOCATION_LIST_FAILURE = 'GET_LOCATION_LIST_FAILURE';

export const GET_PARTNER_LIST_BEGIN = 'GET_PARTNER_LIST_BEGIN';
export const GET_PARTNER_LIST_SUCCESS = 'GET_PARTNER_LIST_SUCCESS';
export const GET_PARTNER_LIST_FAILURE = 'GET_PARTNER_LIST_FAILURE';

export const GET_CUSTOM_API_CALL_BEGIN = 'GET_CUSTOM_API_CALL_BEGIN';
export const GET_CUSTOM_API_CALL_SUCCESS = 'GET_CUSTOM_API_CALL_SUCCESS';
export const GET_CUSTOM_API_CALL_FAILURE = 'GET_CUSTOM_API_CALL_FAILURE';

export const POST_UPLOAD_REPORT_TYPE_FILE_BEGIN = "POST_UPLOAD_REPORT_TYPE_FILE_BEGIN"; 
export const POST_UPLOAD_REPORT_TYPE_FILE_SUCCESS = "POST_UPLOAD_REPORT_TYPE_FILE_SUCCESS";
export const POST_UPLOAD_REPORT_TYPE_FILE_FAILURE = "POST_UPLOAD_REPORT_TYPE_FILE_FAILURE";


export const GET_EMPLOYEES_LIST_BEGIN = 'GET_EMPLOYEES_LIST_BEGIN';
export const GET_EMPLOYEES_LIST_SUCCESS = 'GET_EMPLOYEES_LIST_SUCCESS';
export const GET_EMPLOYEES_LIST_FAILURE = 'GET_EMPLOYEES_LIST_FAILURE';


export const GET_REPORT_LISTING_BEGIN = 'GET_REPORT_LISTING_BEGIN';
export const GET_REPORT_LISTING_SUCCESS = 'GET_REPORT_LISTING_SUCCESS';
export const GET_REPORT_LISTING_FAILURE = 'GET_REPORT_LISTING_FAILURE';

