import React, { useState } from "react";
import { button as Button } from "../button/Button";
import FilterIcon from "../../../assets/img/icons/filter-icon.svg";
import { modal as Modal } from "..//modal/Modal";
import DatePickerInput from "../datepicker/DatePicker";
import * as Constants from "../../../common/utils/constants";
import RadioButton from "../../ui/radio/radio";
import { select as Select } from "../../ui/select/Select";
import "./Filter.scss";
import { Input, Switch, Spin } from "antd";
import { generateID } from "../../../common/utils/utils";

const Option = Select.Option;
const Filters = props => {
  const buttonLabel = props.label ? props.label : "Filter";
  const [showFilterPopup, updateFilterPopupBoolean] = useState(false);
  const popupWidth = props.popupWidth ? props.popupWidth : "30vw";
  const popupTitle = props.popupTitle ? props.popupTitle : "Filters";
  const filterButtonParentClass = props.className;
  function openFilterPopup() {
    updateFilterPopupBoolean(true);
  }
  function closeFilterPopup() {
    updateFilterPopupBoolean(false);
  }
  function generateFilterHtml() {
    let filterHtml = [];
    props.filterPayload &&
      props.filterPayload.map(filterInput => {
        switch (filterInput.type) {
          case Constants.FILTER_INPUT_TYPE.DATERANGE:
            filterHtml.push(getDateRangeHtml(filterInput));
            break;
          case Constants.FILTER_INPUT_TYPE.RADIO_BUTTON:
            filterHtml.push(getRadioButtonHtml(filterInput));
            break;
          case Constants.FILTER_INPUT_TYPE.SELECT:
            filterHtml.push(getSelectHtml(filterInput));
            break;
          case Constants.FILTER_INPUT_TYPE.INPUT:
            filterHtml.push(getInputButtonHtml(filterInput));
            break;
          case Constants.FILTER_INPUT_TYPE.SWITCH:
            filterHtml.push(getSwitchButtonHtml(filterInput));
            break
          default:
            return filterHtml;
        }
      });
    return filterHtml;
  }
  function getDateRangeHtml(filterInput) {
    let startDate;
    let endDate;
    let startDateStr;
    let endDateStr;
    let defaultValue = [];
    const format = "YYYY/MM/DD";
    if (filterInput.metadata.defaultValue) {
      defaultValue = filterInput.metadata.defaultValue;
      startDate = filterInput.metadata.defaultValue[0];
      endDate = filterInput.metadata.defaultValue[1];
      // startDateStr = `${startDate?.year()}/${startDate?.month() +
      //   1}/${startDate?.date()}`;
      // endDateStr = `${endDate?.year()}/${endDate?.month() + 1}/${endDate?.date()}`;
    }
    return (
      <div key={generateID(5)} className="margin-bottom-10">
        <p className="filter-label">{filterInput.title}</p>
        <DatePickerInput
          type="range"
          onChange={filterInput.onChange}
          defaultValue={defaultValue}
          format={format}
        />
      </div>
    );
  }
  function getRadioButtonHtml(filterInput) {
    return (
      <div key={generateID(5)} className="margin-bottom-10">
        <p className="filter-label">{filterInput.title}</p>
        <RadioButton
          values={filterInput.metadata.values}
          type={filterInput.type}
          onChange={filterInput.onChange}
          defaultValue={filterInput?.metadata?.defaultValue}
          buttonStyle={filterInput?.metadata?.buttonStyle}
        />
      </div>
    );
  }
  function getInputButtonHtml(filterInput) {
    return (
      <div key={generateID(5)} className="margin-bottom-10">
        <p className="filter-label">{filterInput.title}</p>
        <Input
          onChange={filterInput.onChange}
          defaultValue={filterInput?.metadata?.defaultValue}
          value={filterInput?.metadata?.value}
          placeholder={filterInput.title}
          ref={input => filterInput?.setRef && filterInput.setRef(input)}
        />
      </div>
    );
  }
  function getSwitchButtonHtml(filterInput) {
    return (
      <div key={generateID(5)} className="margin-bottom-10">
        <p className="filter-label">{filterInput.title}</p>
        <Switch checkedChildren={filterInput?.metadata?.checkedChildren} unCheckedChildren={filterInput?.metadata?.unCheckedChildren} onChange={filterInput.onChange} defaultChecked={filterInput?.metadata?.defaultValue} />
      </div>
    );
  }
  function getSelectHtml(filterInput) {
    let options;
    if (typeof filterInput.metadata.options === "object") {
      options = filterInput.metadata.options;
    } else if (typeof filterInput.metadata.options === "function") {
      options = filterInput.metadata.options();
    }
    let selectOptionsHtml = options?.map(option => {
      return (
        option && (
          <Option key={option.key ? option.key : option.value} value={option.value}>
            {option.label}
          </Option>
        )
      );
    });
    return (
      <div key={generateID(5)} className="margin-bottom-10">
        <p className="filter-label">{filterInput.title}</p>
        <Select
          mode={filterInput.mode ? filterInput.mode : 'multiple'}
          style={{ width: "100%" }}
          value={filterInput.metadata.defaultValue}
          showSearch
          notFoundContent={filterInput.metadata?.onSearch && filterInput.metadata?.locationLoading  ? <Spin size="small" /> : null}
          filterOption={filterInput.metadata?.onSearch ? false : true}
          onSearch={filterInput.metadata?.onSearch ? filterInput.metadata?.onSearch : undefined}
          labelInValue={filterInput?.metadata?.labelInValue ? filterInput?.metadata?.labelInValue: false}
          placeholder={filterInput.title}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={filterInput.onChange}
        >
          {selectOptionsHtml}
        </Select>
      </div>
    );
  }

  function applyFilters() {
    props.applyFilter();
    closeFilterPopup();
  }
  function resetFilters() {
    props.resetFilters();
    closeFilterPopup();
  }
  return (
    <div className="Filter">
      {props.resetFilter ? (
        <Button
          onClick={props.resetFilter}
          className="ant-btn transparent-btn inline-block"
        >
          Reset Filter
        </Button>
      ) : null}
      <Button
        className={"filter-btn inline-block " + filterButtonParentClass}
        block
        onClick={openFilterPopup}
      >
        <img src={FilterIcon} alt="filter" />
        <span className="filter-txt">{buttonLabel}</span>
      </Button>
      <Modal
        onCancel={closeFilterPopup}
        visible={showFilterPopup}
        width={popupWidth}
        centered
        destroyOnClose={true}
        title={popupTitle}
        footer={[
          <Button
            key="reset"
            onClick={resetFilters}
            className="filter-popup"
          >
            Reset
          </Button>,
          <Button
            key="apply"
            onClick={applyFilters}
            className="filter-popup-btn"
          >
            Apply
          </Button>
        ]}
      >
        {generateFilterHtml()}
      </Modal>
    </div>
  );
};

export default Filters;