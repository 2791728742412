import {
  Menu,
  Dropdown,
  Button,
  Icon,
  message,
  Checkbox,
  Divider,
  Input,
} from "antd";
import React, { useState, useEffect } from "react";
import "./SelectCheckbox.scss";
import _ from "lodash";
const SelectCheckbox = (props) => {
  const [dataSource, setDataSource] = useState([...props.dataSource]);
  const [searchedValue, setSearchValue] = useState(undefined);
  const [isDisabled, setDisabled] = useState(false);
  const [placeholder, setPlaceholder] = useState(props.placeholder);
  const [isAllSet, setAllSet] = useState(
    props.isAllSet === true ? props.isAllSet : false
  );

  useEffect(() => {
    if (props.doReset) {
      setSearchValue("");
      setPlaceholder(props.placeholder);
      const newData = props.dataSource.map((u) => {
        if (props.isAllSet) {
          u.selected = true;
        } else {
          u.selected = false;
        }
        return u;
      });
      if (props.isAllSet) {
        setAllSet(true);
      } else {
        setAllSet(false);
      }

      setDataSource(newData);
    }
  }, [props.doReset]);

  useEffect(() => {
    if (props.isAllSet) {
      const newData = props.dataSource.map((u) => {
        if (isAllSet) {
          u.selected = true;
        } else {
          u.selected = false;
        }
        return u;
      });

      setDataSource(newData);
      setDisabled(false);
    } else {
      setDisabled(true);
      setDataSource(props.dataSource);
    }
  }, [props.dataSource]);

  useEffect(() => {
    if (!isAllSet) {
      const selected = dataSource.filter((ds) => ds.selected);
      console.log("seleced", selected);
      if (selected && selected.length <= 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      if (selected && selected.length > 0) {
        if (selected.length === 1) {
          setPlaceholder(truncate(selected[0].label, 20));
        } else if (selected.length > 1) {
          setPlaceholder(
            `${truncate(selected[0].label, 20)} &  ${selected.length - 1} more`
          );
        }
      } else {
        setPlaceholder(props.placeholder);
      }
    }
  }, [dataSource]);

  const updateData = (data) => {
    // e.stopPropagation();
    const newData = dataSource.map((u) => {
      if (u.label === data.label) {
        u.selected = !u.selected;
      }
      return u;
    });
    setDataSource(newData);

    if (isAllSet) {
      setAllSet(false);
    }
  };
  const onSearch = (values) => {
    const value = values;
    if (value) {
      const newData = props.dataSource.map((u) => {
        if (u.label.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
          // if (u.label.toLowerCase() === value.toLowerCase()) {
          return u;
        } else {
          return;
        }
      });

      setDataSource(_.compact(newData));
      setSearchValue(value);
    } else {
      setSearchValue(undefined);
      setDataSource([]);
      setDataSource(props.dataSource);
    }
  };
  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  };

  const onApply = () => {
    if (isAllSet) {
      setPlaceholder(`All ${props.allPlaceholder}`);
    } else {
      const selected = dataSource.filter((ds) => ds.selected);
      if (selected && selected.length > 0) {
        if (selected.length === 1) {
          setPlaceholder(truncate(selected[0].label, 20));
        } else if (selected.length > 1) {
          setPlaceholder(
            `${truncate(selected[0].label, 20)} &  ${selected.length - 1} more`
          );
        }
      } else {
        setPlaceholder(props.placeholder);
      }
    }

    let payload = {};
    if (isAllSet) {
      payload = {
        filterFor: props.filterFor,
        [props.filterFor]: [],
        isAllSet: isAllSet,
      };
      props.onApply(payload);
    } else {
      const selectedIds = dataSource.filter((ds) => ds.selected);
      payload = {
        filterFor: props.filterFor,
        [props.filterFor]: selectedIds,
        isAllSet: false,
      };
      props.onApply(payload);
    }
  };
  const onReset = () => {
    setSearchValue("");
    setPlaceholder(props.placeholder);
    let payload = {};

    payload = {
      filterFor: props.filterFor,
      [props.filterFor]: [],
      isAllSet: isAllSet,
    };
    props.onApply(payload);

    const newData = props.dataSource.map((u) => {
      if (props.isAllSet) {
        u.selected = true;
      } else {
        u.selected = false;
      }
      return u;
    });
    if (props.isAllSet) {
      setAllSet(true);
    } else {
      setAllSet(false);
    }

    setDataSource(newData);
  };
  const selectAll = (bool) => {
    const newData = props.dataSource.map((u) => {
      if (isAllSet) {
        u.selected = false;
      } else {
        u.selected = true;
      }
      return u;
    });

    setDataSource(newData);
    setAllSet(!bool);
    setDisabled(false);
  };
  const clickMe = ({ item, key, keyPath, domEvent }) => {
    domEvent.stopPropagation();
    if (key === "search") {
      return;
    } else if (key === "all") {
      selectAll(isAllSet);
    } else {
      const newData = dataSource.map((u) => {
        if (u.id.toString() === key.toString()) {
          u.selected = !u.selected;
        }
        return u;
      });
      setDataSource(newData);

      if (isAllSet) {
        setAllSet(false);
      }
    }
  };
  const menu = (
    <div className="white-bg spacer-s">
      <Menu className="checkbox-ul" onClick={clickMe}>
        <Menu.Item key="search">
          <Input
            placeholder={`Search by ${props.allPlaceholder} `}
            value={searchedValue}
            onChange={(e) => onSearch(e.target.value)}
          />
        </Menu.Item>
        {!searchedValue && (
          <Menu.Item
            key="all"
            className="sla-menu-item"
            // onClick={() => selectAll(isAllSet)}
          >
            <Checkbox
              checked={isAllSet}
              // onChekced={(e) => selectAll(e.target.checked)}
            />{" "}
            All {props.allPlaceholder}
          </Menu.Item>
        )}
        {dataSource.map((data) => {
          return (
            <Menu.Item
              key={data?.id}
              // onClick={(e) => updateData(e, data)}
              className="sla-menu-item"
            >
              <Checkbox
                // onChekced={(e) => updateData(data)}
                checked={data.selected}
              />{" "}
              {data.label}
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="flex-box justify-content-end flex-gap-m spacer-s white-bg">
        <Button onClick={onReset}>Reset</Button>
        <Button
          disabled={isDisabled}
          className="sla-action-apply"
          onClick={onApply}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <div className={`select-checkbox-container`}>
      <Dropdown
        className={`select-checkbox`}
        overlay={menu}
        trigger={["click"]}
      >
        <Button block className={`select-checkbox-btn`}>
          <div className="flex-box flex-gap-l justify-content-space-around">
            <div>{props.label}:</div>
            <div>{placeholder}</div>
            <div>
              <Icon type="down" />
            </div>
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};

export default SelectCheckbox;
