import React, { Component } from 'react';
import './exception.scss';
import { getExceptions, getServiceableAreaPinCodes, updateBookingService } from './actions.js';
import { connect } from 'react-redux';
import { PageHeader, Row, Col, Spin, Card, Input, Tooltip, Button, Icon, Table, Empty, Radio, Divider, Select } from 'antd';
import FileUpload from '../../ui/file-upload/file.upload';
import { UPLOAD_BUTTON_TYPE, Constants } from '../../../common/utils/constants';
import WaybillLink from '../../shared-components/waybill-link/index';
import moment from 'moment';

class Exception extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: Constants.SELECTED_APPLICATION,
      pageNo: 1,
      searchValue: null,
      apiPayload: { 'pageNo': 1, 'pageSize': 10, partnerId: Constants.PARTNER_ID },
      selectedRowKeys: [],
      bookingRequests: [],
      rtsFilePath: '',
      radioBtn: 'zipCode',
      validatorObj: {
        'selectWaybill': false,
        'zipCodeError': false,
        'submitError': false,
        'fileMissing': false
      },
      columns: [
        {
          title: 'Waybill No',
          dataIndex: 'waybillNo',
          key: 'waybillNo',
          render: (data, row) => {
            return (
              <WaybillLink>{data}</WaybillLink>
            )
          }
        },
        {
          title: 'Shipper',
          dataIndex: 'shipper',
          key: 'shipper',
          render: (data, row) => {
            return (
              row.consignmentBo.customer.organisation.name
            )
          }
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
          key: 'destination',
          render: (data, row) => {
            return (
              row.consignmentBo.consignee.address.address1
            )
          }
        },
        {
          title: 'Booking Date',
          dataIndex: 'booking_Date',
          key: 'booking_Date',
          render: (data, row) => (<div>{moment(row.consignmentBo.bookingDate).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(row.consignmentBo.bookingDate).fromNow()}</small> </div>)
        },
        {
          title: 'Exception',
          dataIndex: 'exceptionMessage',
          key: 'exceptionMessage',
          render: (data, row) => {
            return (
              data
            )
          }
        },
        {
          title: 'ACTIONS',
          dataIndex: 'id',
          key: 'id',
          width: '200px',
          render: (data, row) => {
            if (this.state.selectedRowKeys.indexOf(row.id) !== -1 && this.state.radioBtn === 'zipCode')
              return (
                <Select placeholder="Select Action" style={{ width: '100%', borderColor: (!row.hasNewZip && this.state.validatorObj.submitError) ? 'red' : 'blue' }} onChange={val => this.selectedPincode(val, row)} showSearch={true}>
                  {
                    this.props ?.serviceableArea && this.props.serviceableArea.map(opt => (
                      <Select.Option key={opt.id} value={opt.toObject.zipcode} > {opt.toObject.zipcode} </Select.Option>
                    ))
                  }
                </Select>
              )
          }
        },

      ],
    }
    this.InputSearchRef = React.createRef();
    this.props.getServiceableAreaPinCodes();
    this.applyPagination({ pageNo: this.state.pageNo })
  }

  selectedPincode(value, rowData) {
    if (this.state.bookingRequests.length) {
      for (let key in this.state.bookingRequests) {
        if (Number(this.state.bookingRequests[key]['id']) === Number(rowData['id'])) {
          this.state.bookingRequests[key]['correctedZipcode'] = value;
          rowData['hasNewZip'] = true;
        }
      }
    }
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    if (filters ?.waybillNos) {
      this.setState({ searchValue: filters ?.waybillNos})
      if (filters.waybillNos[0] === "") {
        delete filters.waybillNos;
        this.setState({ searchValue: null })

      }
    }
    this.props.getExceptions({ ...this.state.apiPayload, ...filters }, isReset);
  }

  reloadExceptionList = () => {
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedException, } = this.props;
    if (!PaginatedException ? !PaginatedException : !PaginatedException[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  onViewChange = e => {
    this.setState({
      radioBtn: e.target.value
    })
  }

  whenRowSelected = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys })

    const req = selectedRows.map(row => ({
      "id": row.id,
      "partnerId": Constants.PARTNER_ID,
      "correctedZipcode": '',//new pincode
      "rtsReason": "",
      "attributes": { "s3Url": this.state.rtsFilePath }
    }));
    this.setState({
      bookingRequests: req
    })
  }

  onUploadFinish = (url) => {
    this.setState({
      rtsFilePath: url
    })
    this.sumbitException();
  }

  updateSelectedZipCodes = (data, selected, selectedRows, event) => {
    this.setState({
      validatorObj: {
        ...this.state.validatorObj,
        selectWaybill: false,
        submitError: false
      }
    })
    if (selected) {
      this.status = true;

    }
  }

  uploadData = () => {

    this.setState({
      validatorObj: {
        selectWaybill: false,
        submitError: false,
        zipCodeError: false,
        fileMissing: false,
      }
    })

    if (!this.state.bookingRequests.length) {
      this.setState({
        validatorObj: {
          ...this.state.validatorObj,
          selectWaybill: true,
          submitError: true,
        }
      })
      return;
    }
    if (this.state.radioBtn === 'rts') {
      if (this.state.rtsFilePath) {
        this.loading = true;
      } else {
        this.setState({
          validatorObj: {
            ...this.state.validatorObj,
            fileMissing: true,
            submitError: true,
          }
        })
        return
      }
    } else if (this.state.radioBtn === 'zipCode') {
      for (let key in this.state.bookingRequests) {
        if (!this.state.bookingRequests[key]['correctedZipcode'].length) {
          this.setState({
            validatorObj: {
              ...this.state.validatorObj,
              zipCodeError: true,
              submitError: true,
            }
          })
          return
        }
      }
      this.sumbitException();
    }
  }

  sumbitException = () => {
    this.setState({
      isLoading: true
    })
    let config;

    if (this.state.radioBtn === 'rts') {
      config = {
        "data": [{
          "eventAction": "RTS",
          "bookingRequests": this.state.bookingRequests
        }]
      }
    } else if (this.state.radioBtn === 'zipCode') {
      config = {
        "data": [{
          "eventAction": "UPDATE_DETAILS",
          "bookingRequests": this.state.bookingRequests
        }]
      }
    }
    let vlaue = this.props.updateBookingService(config)
  }


  render() {

    const { pageNo, columns, radioBtn, application, selectedRowKeys, validatorObj, searchValue } = this.state;
    const { exceptionData, PaginatedException } = this.props;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    const actionBtns = (
      <Spin spinning={exceptionData ?.loading} indicator={<div></div>}>
        <Row>
          <Col span={20}>
            <Input.Search allowClear size={'large'} placeholder="Search" ref={this.InputSearchRef} onSearch={value => this.applyPagination({ waybillNos: [value.trim()] }, true)} enterButton />
          </Col>
          <Col span={2}>
            <Tooltip placement="top" title={'Reload Exception List'}>
              <Button size={'large'} onClick={this.reloadExceptionList} type="primary">
                <Icon type="reload" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Spin>
    );

    return (
      <>
        {
          application === 'B2C' && <PageHeader
            style={{
              padding: 0,
              paddingBottom: '24px'
            }}
            onBack={false}
            ghost
          >
            <Card>
              <Row gutter={12}>
                <Col span={4}>
                  <Radio.Group onChange={this.onViewChange} value={radioBtn}>
                    <Radio style={radioStyle} value={'zipCode'}>
                      {`Zipcode Correction`}
                    </Radio>
                    <Radio style={radioStyle} value={'rts'}>
                      {`Mark Shipment as RTS`}
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col span={1}>
                  <Divider style={{ height: '4rem' }} type="vertical" />
                </Col>
                {
                  (radioBtn === 'zipCode') && <>
                    <Col span={14}>
                      <p style={{ marginTop: '15px' }}>{`Please choose list of waybill(s) to modify zipcode(s) from below table`}</p>
                      <div>
                        <small style={{ color: 'red' }}>
                          {
                            validatorObj.zipCodeError && 'Please select pincode against selected waybill no.s'
                          }
                          {
                            validatorObj.selectWaybill && 'Please select waybills from the below table'
                          }
                        </small>
                      </div>
                    </Col>
                    <Col span={5}>
                      <Button size={'large'} onClick={this.uploadData} type="primary">
                        Correct ZipCode and Book
                    </Button>
                    </Col>
                  </>
                }
                {
                  (radioBtn === 'rts') && <>
                    <Col span={14}>
                      <label>{`Uploaded pod will be attached to all selected waybills`}</label>
                      <div style={{ marginTop: '6px' }}>
                        <FileUpload
                          fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                          acceptTypes={['.xls', '.xlsx']}
                          directUpload={true}
                          disableUploadButton={true}
                          path="exception"
                          objKey="exception"
                          multiple={false}
                          onUploadFinish={url => this.onUploadFinish(url)}
                        />
                      </div>
                      <div>
                        <small style={{ color: 'red' }}>
                          {
                            validatorObj.selectWaybill && 'Please select waybills from the below table'
                          }
                          {
                            validatorObj.fileMissing && 'Attachment is missing'
                          }
                        </small>
                      </div>
                    </Col>
                    <Col span={5}>
                      <Button type="primary" onClick={this.uploadData} size="large">
                        Mark Shipment as RTS
                    </Button>
                    </Col>
                  </>
                }

              </Row>
            </Card>
          </PageHeader>
        }

        <Card className="exception" title={<h2>Exception List</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            rowKey={(row => (row.id))}
            loading={exceptionData ?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: exceptionData ?.totalCount,
              position: 'bottom',
              current: pageNo,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: this.whenRowSelected,
              onSelect: this.updateSelectedZipCodes
            }}
            dataSource={searchValue ? exceptionData.data : PaginatedException ? PaginatedException[pageNo] : []}
            columns={columns} />
        </Card>
      </>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  exceptionData: state.exception,
  PaginatedException: state.exception.PaginatedException,
  serviceableArea: state.exception.serviceableArea,
});
const mapDispatchToProps = dispatch => ({
  getExceptions: (params) => dispatch(getExceptions(params)),
  getServiceableAreaPinCodes: () => dispatch(getServiceableAreaPinCodes()),
  updateBookingService: (payload) => dispatch(updateBookingService(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Exception);