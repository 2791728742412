import { validationRules } from "../../../../../../common/utils/constants";

const schema = {
    "type"      : "object",
    "required"  : ["detentionSLA", "penalty"],
    "properties": {
        "detentionSLA" : {
            "type"    : "string",
            "title"   : "Detention Free Period",
            "pattern" : validationRules.durationWidgetRegex
        },
        "penalty"   : {
            "type"  : "string",
            "title" : "Penalty"
        }
    }
}

const uiSchema = {
    "classNames": "hide-title",
    "detentionSLA" : {
        "daysplaceholder"   : "Enter Days",
        "timeplaceholder"   : "Select Hours",
        "ui:widget"         : "DurationWidget",
        "classNames"        : "flex-1"
    },
    "penalty" : {
        "ui:placeholder"    : "Enter detention penalty",
        "classNames"        : "flex-1"
    }
}

const errorList = {
    detentionSLA : {
        required : "Detention SLA time cannot be empty!",
        pattern  : "Detention SLA is ivalid!"
    },
    penalty : {
        required : "Detention penalty cannot be empty!"
    }
}


export default { schema, uiSchema, errorList };