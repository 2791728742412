import {
    INITIAL_STATE,
    ACTION_TYPES
} from "./constants";

export const InventoryClosureReducer = (state = INITIAL_STATE, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.SET_ENTITY_TYPE:
            return {
                ...INITIAL_STATE,
                selectedEntityType: payload,
            };
        case ACTION_TYPES.FETCH_CONNECTION_INFO_BEGIN:
            return {
                ...state,
                loading: true,
                isValidated: INITIAL_STATE.isValidated,
                currentScannedEntityInfo: INITIAL_STATE.currentScannedEntityInfo,
                sparkLineDialogData: {
                    ...INITIAL_STATE.sparkLineDialogData
                }
                
            };
        case ACTION_TYPES.ENTITY_UNLOAD_BEGIN:
        case ACTION_TYPES.SCAN_ENTITY_BEGIN:
        case ACTION_TYPES.FETCH_REASONS_BEGIN:
        case ACTION_TYPES.UPDATE_REASON_BEGIN:
        case ACTION_TYPES.FETCH_MANIFEST_CONSIGNMENT_DATA_BEGIN:
        case ACTION_TYPES.UPDATE_REASONS_IN_LIST_BEGIN:
        case ACTION_TYPES.VALIDATE_ENTITY_BEGIN:
        case ACTION_TYPES.CLOSE_CONNECTION_BEGIN:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPES.FETCH_CONNECTION_INFO_SUCCESS:
        case ACTION_TYPES.ENTITY_UNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                entityInfo: {
                    ...state.entityInfo,
                    ...payload
                }
            };
        case ACTION_TYPES.VALIDATE_ENTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                entityInfo: {
                    ...state.entityInfo,
                    ...payload
                },
                isValidated: true,
            };
        case ACTION_TYPES.VALIDATE_ENTITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
                errorModal: {
                    ...state.errorModal,
                    show: true,
                    title: "Validation Unsuccessful!",
                    msg: payload
                }
            };
        case ACTION_TYPES.UPDATE_ENTITY_COUNT:
            return {
                ...state,
                entityInfo: {
                    ...state.entityInfo,
                    ...payload
                }
            };
        case ACTION_TYPES.FETCH_CONNECTION_INFO_FAILURE:
        case ACTION_TYPES.ENTITY_UNLOAD_FAILURE:
        case ACTION_TYPES.FETCH_REASONS_FAILURE:
        case ACTION_TYPES.UPDATE_REASON_FAILURE:
        case ACTION_TYPES.FETCH_MANIFEST_CONSIGNMENT_DATA_FAILURE:
        case ACTION_TYPES.UPDATE_REASONS_IN_LIST_FAILURE:
        case ACTION_TYPES.CLOSE_CONNECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case ACTION_TYPES.FETCH_REASONS_SUCCESS:
            return {
                ...state,
                loading: false,
                reasons: payload
            };
        case ACTION_TYPES.SCAN_ENTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                currentScannedEntityInfo: payload,
                scannedEntities: [
                    ...state.scannedEntities,
                    {...payload}
                ]
            };
        case ACTION_TYPES.UPDATE_REASON_SUCCESS:
            return {
                ...state,
                loading: false,
                currentScannedEntityInfo: payload,
            };
        case ACTION_TYPES.SCAN_ENTITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: payload,
                currentScannedEntityInfo: null
            };
        case ACTION_TYPES.FETCH_MANIFEST_CONSIGNMENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                sparkLineDialogData: {
                    ...state.sparkLineDialogData,
                    ...payload
                }
            };
        case ACTION_TYPES.UPDATE_REASONS_IN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                ...payload
            };
        case ACTION_TYPES.INCREMENT_SCAN_COUNT:
            return {
                ...state,
                entityInfo: {
                    ...state.entityInfo,
                    scannedCount: state.entityInfo.scannedCount + 1,
                    pendingCount: state.entityInfo.pendingCount - 1,
                }
            };
        case ACTION_TYPES.INCREMENT_OVERAGE_COUNT:
            return {
                ...state,
                entityInfo: {
                    ...state.entityInfo,
                    scannedCount: state.entityInfo.scannedCount + 1,
                    overageCount: state.entityInfo.overageCount + 1,
                }
            };
        case ACTION_TYPES.RESET_SELECTED_SPARK_LINE_DATA:
            return {
                ...state,
                sparkLineDialogData: {
                    ...state.sparkLineDialogData,
                    statusCount: 0,
                    totalCount: 0,
                    manifests: null,
                    consignments: null
                }
            };
        case ACTION_TYPES.RESET:
            return {
                ...INITIAL_STATE
            };
        case ACTION_TYPES.CLOSE_CONNECTION_SUCCESS:
            return {
                ...INITIAL_STATE,
                selectedEntityType: state.selectedEntityType,
                reasons: state.reasons,
            };
        case ACTION_TYPES.CLOSE_ERROR_DIALOG_INFO:
            return {
                ...state,
                errorModal: {
                    ...state.errorModal,
                    show: false,
                    title: "",
                    msg: ""
                }
            };
        default:
            return state;
    }
};

