
import { apiRequest } from '../../common/utils/apiGateway';
export default class ProductService {
    getAllProducts() {
        let url = '/b2b/v1/productType';
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getProduct(id) {
        let url = '/b2b/v1/productType/' + id;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    deleteProduct(id) {
        let url = '/b2b/v1/productType/' + id;
        return apiRequest({
            url,
            method: "DELETE"
        });
    }

    updateProduct(productTypeId, params) {
        let url = `/b2b/v1/productType/${productTypeId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: params
        });
    }

    createProduct(params) {
        let url = '/b2b/v1/productType';
        return apiRequest({
            url,
            method: "POST",
            data: params
        });

    }
}