import React, { useState, useEffect } from 'react';
import { Table, Spin, Input, Switch, Button, Icon } from 'antd';
import { notifyApiError } from '../../../../common/utils/utils';
import { getLocationColumns } from "../common/columns/index";
import { fetchLocations } from '../../../../services/common/fetch.services';

function LocationList(props) {
    const { customerId, locationPermission, setReadOnly, readOnly, setModal, setLocationStepper } = props;
    const [locations, setLocations] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isActive, setStatus] = useState(true);
    const [partialContentSearch, setPartialContentSearch] = useState(undefined);
    const [searchData, setSearchData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const params = {
                    pageNo,
                    pageSize,
                    isActive,
                    partialContentSearch,
                    entityIds: [customerId]
                }

                const response = await fetchLocations(params);
                const data = await response;
                if (data?.locations?.length > 0) {
                    setLoading(false);
                    setLocations(data);
                    setLocationStepper(true);
                   
                } else {
                    setLocations([]);
                    setLocationStepper(false);
                    setLoading(false);
                    if (!readOnly && data?.locations.length <= 0 && locationPermission.locationsCreate) {
                        // setShipperAction('ADD')
                        setReadOnly(false);
                        setModal(true);
                        props.setDraggable(true);
                    }
                }

            } catch (error) {
                setLocations([]);
                setLoading(false);
                notifyApiError(error, "Error")
            }
        };

        fetchData();
    }, [pageSize, isActive, pageNo, partialContentSearch, props.refresh]);



    return (
        <Spin spinning={loading} className="flex-box justify-content-center" style={{ display: "flex" }}>
            <div className="flex-box flex-column flex-gap-xl">
                <div className="flex-box align-items-center justify-content-between flex-gap-m">
                    <div className="flex-4 flex-box align-items-center justify-content-between flex-gap-xl">
                        <div className="flex-5">
                            <Input
                                prefix={<Icon type="search" />}
                                placeholder={"Search by location code, name, state, city, pincode"}
                                value={searchData}
                                suffix={
                                    searchData &&
                                    <Icon type="close-circle"
                                        onClick={() => {
                                            setSearchData(undefined);
                                            setPartialContentSearch(undefined)
                                        }} />
                                }
                                onChange={(e) => setSearchData(e.target.value)}
                                onPressEnter={(e) => {
                                    setPartialContentSearch(e.target.value.trim());
                                    setPageNo(1);
                                    setPageSize(10);
                                }}
                            />
                        </div>
                        <div className="flex-1 flex-box align-items-center flex-gap-l">
                            <div> Show Active </div>
                            <Switch
                                checked={isActive}
                                onChange={checked => setStatus(checked)}
                            />
                        </div>
                    </div>
                    <div className="flex-1 flex-box justify-content-flex-end">
                        {locationPermission.locationsCreate &&
                            <div>
                                <Button
                                    block
                                    className="lsn-btn-primary"
                                    onClick={() => {
                                        props.setModal(true);
                                        props.setReadOnly(false);
                                        props.setDraggable(true);
                                    }}
                                >
                                    Add Location
                            </Button>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <Table columns={getLocationColumns({
                        showLocationDetails: props.showLocationDetails,
                        setModal: props.setModal,
                        setReadOnly: props.setReadOnly,
                        locationPermission: locationPermission
                    })}
                        dataSource={locations?.locations}
                        rowKey={'id'}
                        bordered
                        pagination={{
                            onChange: (pageNo) => setPageNo(pageNo),
                            total: locations?.totalCount,
                            showTotal: (total, range) => <span className="total-pagination">{total} {total <= 1 ? "Location" : "Locations"}</span>,
                            pageSize: locations?.currentPageSize,
                            current: locations?.currentPageNo,
                            showSizeChanger: true,
                            onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
                        }} />
                </div>
            </div>
        </Spin>
    )
}

export default LocationList;