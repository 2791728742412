import { Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import debounce from 'lodash/debounce';
import { fetchLocations } from "../../../../../services/common/fetch.services";

export default function LocationsSelect(props) {
    
    const [ loading, SetLoading ] = useState(false);
    const [ locationList, SetLocationList ] = useState([]);
    const [ selectedLocation, SetSelectedLocation ] = useState(props.selectedLocation);
    const [ notFoundContent, SetNotFoundContent ] = useState("Enter minimum of three characters to search locations.");

    useEffect(() => {
        SetSelectedLocation(props.selectedLocation);
    }, [ props.selectedLocation ]);

    var params = {
        pageNo      : 1,
        pageSize    : 10,
        isActive    : true,
        entityTypes : [ "CUSTOMER" ],
        entityIds   : [ props.customerId ],
        searchNameOnly : props.searchNameOnly
    }

    const fetchData = async value => {
        SetLoading(true);
        const locationListTemp = [];
        if (value.trim() !== "") {
            params["partialContentSearch"] = value.trim();
            const locationsResponse  = await fetchLocations(params); 
            if (locationsResponse && locationsResponse.locations?.length > 0) {
                locationsResponse.locations.forEach(element => {
                    locationListTemp.push({
                        key     : element.name,
                        value   : element.id,
                        label   : element.name
                    })
                });
            }
            if (locationListTemp.length === 0) {
                SetNotFoundContent("No locations Found");
            }
        }
        else {
            SetNotFoundContent("Enter minimum of three characters to search locations.");
        }
        SetLocationList(locationListTemp);
        SetLoading(false);
    }

    const onLocationSelect = location => {
        SetSelectedLocation(location);
        SetLocationList([]);
        props.onLocationSelect({
            ...location,
            label : location.label?.props?.children
        })
    };

    return (
        <div className="lsn-form flex-column justify-content-space-between flex-3">
            <Select
                disabled={props.disabled || props.readonly} labelInValue={true}
                mode={'single'} style={props.removeMargin ? {} : { marginLeft: -8 }}
                className={(props.readonly ? "read-only" : "")}
                value={selectedLocation?.id ? {
                    key     : selectedLocation?.id,
                    label   : selectedLocation?.name
                } : undefined} showSearch={true}
                onSearch={debounce(fetchData, 800)} loading={loading}
                notFoundContent={notFoundContent}
                onBlur={() => SetLocationList([])}
                placeholder={"Select Location"} onSelect={onLocationSelect}
                filterOption={(input, option) => option.props.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 } >
                {
                    locationList?.map(location => (
                        <Select.Option key={location.key ? location.key : location.value} value={location.value} disabled={props.selectedEntities.indexOf(location.value) > -1}>
                            <Tooltip
                             title={props.selectedEntities.indexOf(location.value) > -1 ?
                             (props.disableText ? props.disableText : "Location already present in lane!") :
                             undefined}>
                                {location.label}
                            </Tooltip>
                        </Select.Option>
                    ))
                }
            </Select>
            {
                props.unselectedError &&
                <div className="error-text"> {props.unselectedErrorMessage || "Cannot have unselected locations in lane transit!"}  </div>
            }
        </div>
    )
}