import React, { PureComponent } from 'react';
// import * as actions from './actions';
// import './dashboard.scss';
// import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Pagination, Tooltip, Spin, Form, Select, Table, Alert } from 'antd';
import { canAllow, windowRedirect, notifyApiSuccess, checkHttpStatus, notifyApiError, trimEachWaybill } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
// import { Constants } from '../../../common/utils/constants';
import debounce from 'lodash/debounce';
// import SLAReasonsService from '../../../services/sla-reasons/sla-reasons.service';
import _ from 'lodash';
import { apiRequest } from '../../../common/utils/apiGateway';
// let slaService = new SLAReasonsService();
import "./eway.scss";
import { validationRules } from '../../../common/utils/constants';
const { confirm } = Modal;

class PartBUpdate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            userPermission: {
                read: canAllow(permissionTags.settings.user.read),
                update: canAllow(permissionTags.settings.user.update),
                create: canAllow(permissionTags.settings.user.create),
                delete: canAllow(permissionTags.settings.user.delete),
                view: canAllow(permissionTags.settings.user.view),
            },
            selectedDockets: [],
            selectedVehicle: undefined,
            selectedReason: undefined,
            updateText: 'Update',
            updateLoading: false,
            reasons: [],
            updateColumns: [],
            data: [],
            loading: false,
            // visible: false,
            vehicleError: undefined,
            docketLength: 0,
            ewayLength: 0,
            editingDisabled: false
        }



    }
    


    componentDidMount() {
        this.getReasons();
        this.setState({ loading: true });
        let { selectedDockets, data } = this.state;
        let fetchedSelected = this.props.location.state.selectedDockets;
        let docketNo = _.map(fetchedSelected, 'docketNo');
        const filter = {
            "filter": {
                "waybills": docketNo,
                isPaginationRequired: false
            }
        }
        apiRequest({
            url: '/b2b/v1/ewaybill/list',
            method: "POST",
            data: filter
        }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                if (res?.data?.response?.ewaybills && res?.data?.response?.ewaybills?.length > 0) {
                    let ewaybillData = res?.data?.response;
                    console.log("eway", ewaybillData)
                    this.setState({ selectedDockets: ewaybillData?.ewaybills }, () => {

                        this.state.selectedDockets.map(docket => {
                            data.push({
                                ewaybillNo: docket?.ewaybillNo,
                                docketNo: _.map(this.state.selectedDockets.filter(slDocket => slDocket.ewaybillNo === docket.ewaybillNo), 'docketNo')
                            })


                        })
                        data = _.uniqBy(data, 'ewaybillNo');
                        let docketLength = _.sum(_.map(_.map(data, 'docketNo'), 'length'));
                        // console.log("seleced", _.sum(_.map(_.map(data, 'docketNo'), 'length')))
                        this.setState({ data, loading: false, ewayLength: data?.length, docketLength })
                    });
                }
            }
        });


        window.onbeforeunload = (event) => {
            // Show prompt based on state
            if (this.state.updateLoading) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = ''
                }
                return '';
            }
        };

     




        this.setState(state => ({


            updateColumns: [
                {
                    title: 'E-waybill Number',
                    dataIndex: 'ewaybillNo',
                    key: 'ewaybillNo',

                },
                {
                    title: 'Docket Number',
                    dataIndex: 'docketNo',
                    key: 'docketNo',
                    render: (data, row) => data.join(", ")

                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',

                },
                {
                    title: 'Response',
                    dataIndex: 'response',
                    key: 'response',

                },

            ]
        }));

    }









    getReasons = () => {
        let { reasons } = this.state;
        if (reasons.length <= 0) {
            apiRequest({
                url: '/b2c/v2/reasons/categories/fetch',
                method: "POST",
                data: {
                    "categoryFilters": {
                        "types": [
                            "UPDATE_PART_B"
                        ],
                        "isActive": true,
                        "fetchObjects": [
                            "PARTNER_REASONS"
                        ]
                    }
                }

            }).then(res => {
                if (checkHttpStatus(res?.data?.status)) {
                    if (res?.data?.response?.reasonCategories && res?.data?.response?.reasonCategories?.length > 0) {
                        let reasonCategories = res?.data?.response?.reasonCategories;
                        let reasons = reasonCategories.map(reCat => reCat?.reasons)[0]
                        console.log("reasons", reasons)
                        this.setState({ reasons });
                    } else {
                        this.setState({ reasons: [] })
                        // notifyApiError('No Data found!!', 'Error')
                    }
                }
            });
        }
        this.setState({ selectedVehicle: undefined, selectedReason: undefined, updateText: 'Update' }, () => {
            // // selectedDockets = ewaybillData?.ewaybills.filter(eway => eway?.docketNo === [...selectedDockets]);
            // selectedDockets = [...selectedRowKeys].map(docket => ewaybillData?.ewaybills.filter(eway => eway?.docketNo === docket)[0]);
            // console.log("selected", selectedDockets)
            // this.setState({ selectedDockets })
        });
    }



    validateVehicle = () => {
        let { selectedVehicle } = this.state;
        let re = new RegExp(validationRules.newVehicle);
        if (!re.test(selectedVehicle)) {
            this.setState({ vehicleError: 'Enter valid Vehicle Number' });
        } else {
            this.setState({ vehicleError: undefined });
        }
    }

    testApiCall = async (i) => {
        // api call to local host
        let showRetry = false;
        const res = await apiRequest({
            url: '/b2b/v1/ewaybill/updateVehicleNumber',
            method: "POST",
            data: {
                ewaybillNo: i,
                "vehicleNo": this.state.selectedVehicle,
                "reasonForUpdate": this.state.selectedReason
            }
        })



        if (res?.data?.status?.code === 500) {
            showRetry = true;
            let newData = this.state.data.map(docket => {

                if (docket.ewaybillNo === res?.data?.response?.ewaybillNo) {
                    docket['status'] = <Icon type="close-circle" theme="twoTone" twoToneColor="#f44336" style={{ fontSize: 20 }} />;
                    docket['response'] = res?.data?.status?.message;
                    docket['success'] = false;
                }

                return docket;
            })
            this.setState({ data: [...newData] })
        }
        if (res?.data?.status?.code === 200) {
            showRetry = false;
            let newData = this.state.data.map(docket => {

                if (docket.ewaybillNo === res?.data?.response?.ewaybillNo) {
                    docket['status'] = <Icon type="check-circle" theme="twoTone" twoToneColor="#01a870" style={{ fontSize: 20 }} />
                    docket['response'] = '';

                    docket['success'] = true;
                }

                return docket;
            })
            this.setState({ data: [...newData] })
        }
        this.setState({ updateText: showRetry ? "Retry" : "Done" })

    }



    updateEway = () => {
        let { data, vehicleError } = this.state;
        if (vehicleError) {
            return;
        }
        // let showRetry = false;
        let newData = [...data];


        const requests = _.map(data, 'ewaybillNo');

        newData = newData.map(docket => {
            docket['status'] = <Spin spinning />
            docket['response'] = '';
            // requests.push(docket.ewaybillNo);
            return docket;
        });
        this.setState({ data: [...newData], updateLoading: true, editingDisabled: true }, () => {
            Promise.all(requests.map(async userId =>
                await this.testApiCall(userId)))
                .then(() => this.setState({ updateLoading: false })
                );

        })


    }

    retry = () => {
        const requests = [];

        let { data, vehicleError } = this.state;
        if (vehicleError) {
            return;
        }
        let newData = data.map(docket => {
            if (!docket.success) {
                docket['status'] = <Spin spinning />
                docket['response'] = ''
                requests.push(docket.ewaybillNo);
            }


            return docket;
        });

        this.setState({ data: [...newData], updateLoading: true, editingDisabled: true }, () => {
            Promise.all(requests.map(async userId =>
                await this.testApiCall(userId)))
                .then(() => this.setState({ updateLoading: false })
                );

        })

    }

    goBack = () => {
        this.setState({updateText: 'Update'})
        this.props.history.push('/appv2/ewaybill/dashboard/partb', { filter: this.props.location.state.filter })
        // this.props.history.goBack();
    }



    render() {
        const { editingDisabled, data, loading, ewayLength, updateColumns, docketLength, updateText, selectedDockets, selectedVehicle, selectedReason, updateLoading, reasons, vehicleError } = this.state;
        console.log("  this.setState({updateText: 'Update'})", updateText)
        const actions = (
            <Row type="flex" justify={"flex-end"}>

                <Col span={12}>
                    <Button
                        type={"default"}
                        onClick={() => this.goBack()}
                        disabled={updateLoading}
                    >
                        Close
                        </Button>
                </Col>
                {(updateText === 'Update' || updateText === 'Retry') &&


                    <Col span={6}>

                        <Button
                            className="partb-list-success"
                            onClick={
                                updateText === 'Update' ? this.updateEway :
                                    this.retry
                            }
                            loading={updateLoading}
                            disabled={
                                _.isUndefined(selectedVehicle) ||
                                _.isUndefined(selectedReason) || updateText === 'Done'
                            }
                        >
                            {updateText}
                        </Button>
                    </Col>
                }

            </Row>
        )


        return (
            <Spin spinning={loading}>
                <div className="partb-list">
                    <Card
                        title={
                            <h2>
                                {updateLoading ?
                                    <Icon type="arrow-left" style={{ opacity: 0.5, cursor: 'not-allowed' }} />
                                    : <Icon type="arrow-left" onClick={() => this.goBack()} />
                                }

                                Review Request
                            </h2>
                        }
                        extra={
                            actions
                        }
                    >
                        <>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        colon={false}
                                        // labelCol={{ span: 12 }}
                                        // wrapperCol={{ span: 12 }}
                                        label={<b>Transfer ewaybill(s) to Vehicle Number <small style={{ color: 'rgb(244, 67, 54)' }}>*</small></b>}
                                    >
                                        <Input
                                            value={selectedVehicle}
                                            onBlur={this.validateVehicle}
                                            onChange={
                                                e => this.setState({ selectedVehicle: e.target.value })
                                            }
                                            disabled={editingDisabled}

                                            placeholder={"Enter Vehicle Number"}
                                        />
                                    </Form.Item>
                                    {vehicleError &&
                                        <Alert
                                            style={{ bottom: 20 }}
                                            message={vehicleError}
                                            type="error"
                                        />
                                    }

                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        colon={false}
                                        // labelCol={{ span: 12 }}
                                        // wrapperCol={{ span: 12 }}
                                        // formL
                                        label={<b>Reason for Transfer ewaybill <small style={{ color: 'rgb(244, 67, 54)' }}>*</small></b>}
                                    >
                                        <Select

                                            style={{ width: '100%' }}
                                            placeholder="Select Reason"
                                            value={selectedReason}
                                            allowClear
                                            disabled={editingDisabled}
                                            showSearch
                                            onSelect={
                                                val => this.setState({ selectedReason: val })
                                            }
                                            filterOption={(input, option) => {
                                                // console.log("ito", option)
                                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                            }
                                            }
                                        >
                                            {
                                                reasons?.map(reason => (
                                                    <Select.Option value={reason.reasonId} key={reason.reasonId}>
                                                        <Tooltip title={reason.reasonDescription}>
                                                            {reason.reasonDescription}
                                                        </Tooltip>

                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item
                                        colon={false}
                                        // labelCol={{ span: 12 }}
                                        // wrapperCol={{ span: 12 }}
                                        label={<b>Dockets</b>}
                                    >
                                        <div style={{ marginLeft: 20 }}>
                                            {docketLength}
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item
                                        colon={false}
                                        // labelCol={{ span: 12 }}
                                        // wrapperCol={{ span: 12 }}
                                        label={<b>E-WayBills</b>}
                                    >
                                        <div style={{ marginLeft: 20 }}>
                                            {ewayLength}
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Table
                                        bordered
                                        columns={updateColumns}
                                        dataSource={data}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>


                        </>

                    </Card>

                </div >
            </Spin>
        )
    }
}

export default PartBUpdate;
