import { capitalizeString } from "../../../../../../common/utils/utils";

export const basisToText = basis => {
    if (basis) {
        return (basis.split("_").map(s => capitalizeString(s?.toLowerCase()))).join(" ");
    }
    return "";
}

export const validateChargeBO = (itemRatesBO, chargeType) => {
    const validationObjects = [];
    var maxFromIndex = 0;
    var maxFrom = -1;
    if (itemRatesBO?.rateStructure !== "FIXED" ) {
        if (itemRatesBO?.rateBOs && itemRatesBO?.rateBOs?.length > 0) {
            itemRatesBO?.rateBOs.map((rate, index) => {
                if (maxFrom < rate.fromRange) {
                    maxFrom = rate.fromRange;
                    maxFromIndex = index;
                }
                
                if (rate.fromRange >= rate.toRange) {
                    validationObjects.push([ ChargeBOErrors.FROM_GREATER, chargeType + ".rateBOs." + index + ".fromRange"]);
                    validationObjects.push([ ChargeBOErrors.FROM_GREATER, chargeType + ".rateBOs." + index + ".toRange"]);
                }
        
                itemRatesBO?.rateBOs.map((checkRate, checkIndex) => {
                    if (checkRate.fromRange <= rate.fromRange && checkRate.toRange >= rate.fromRange && index != checkIndex) {
                        validationObjects.push([ ChargeBOErrors.INVALID_FROM, chargeType + ".rateBOs." + index + ".fromRange"]);
                    }
                })
            })
            
            const maxToRange = chargeType + ".rateBOs." + maxFromIndex + ".toRange";
            validationObjects.push([undefined, maxToRange])
        }
    }

    return validationObjects;
}

export const getMaxFromIndex = itemRatesBO => {
    var maxFromIndex = 0;
    var maxFrom = -1;
    
    if (itemRatesBO?.rateBOs && itemRatesBO?.rateBOs?.length > 0) {
        itemRatesBO?.rateBOs.map((rate, index) => {
            if (maxFrom < rate.fromRange) {
                maxFrom = rate.fromRange;
                maxFromIndex = index;
            }
        })
    }

    return maxFromIndex;
}

const ChargeBOErrors = {
    WEIGHTMULTIPLIER : "Weight Multiplier cannot be empty",
    RATEBO_EMPTY     : "Rate structure cannot be empty!",
    MISSING_BOX      : "Box Type is missing!",
    AMOUNT_MISSTING  : "Rate cannot be empty!",
    AMOUNT_INVALID   : "Invalid Rate for charges!",
    RATE_MISSTING    : "Rate of a slab cannot be empty!",
    FROM_MISSING     : "From Range of a slab cannot be empty!",
    TO_MISSING       : "To Range of a slab cannot be empty!",
    INVALID_FROM     : "Rate slab is overlapping!",
    FROM_GREATER     : "To Range has to be greater than From Range!"
}

export const boxChargesType = ["LOADING_CHARGE", "UNLOADING_CHARGE", "LABOUR_CHARGE", "FREIGHT_CHARGE"];