
import { apiRequest } from '../../common/utils/apiGateway';
export default class LeadsService {

    getLeads(params = null) {
        const url = '/b2b/v1/leads';
        return apiRequest({
            url,
            method: "GET",
            params
        });
    }

    getExpectedLeads() {
        const url = '/b2b/v1/leads/expected';
        return apiRequest({
            url,
            method: "GET"
        });
    }

    createLead(params) {
        const url = '/b2b/v1/leads';
        return apiRequest({
            url,
            method: "POST",
            data: params
        });
    }

    assignVechile(params) {
        const url = '/b2b/v1/leads/assign';
        return apiRequest({
            url,
            method: "PUT",
            data: params
        });
    }

    updateLead(params) {
        const url = '/b2b/v1/leads';
        return apiRequest({
            url,
            method: "PUT",
            data: params
        });
    }

    deleteLead(id) {
        const url = `/b2b/v1/leads/${id}`;
        return apiRequest({
            url,
            method: "DELETE"
        });
    }

    fetchLeads(data) {
        const url = `/b2b/v1/leads/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: data
        });
    }

    leadStatus(data) {
        const url = `/b2b/v1/leads/status`;
        return apiRequest({
            url,
            method: "PUT",
            data: data
        });
    }

    runSheetListAll(data) {
        const url = `/b2b/v1/prs/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: data
        });
    }

    getWaybillList(data) {
        const url = `/b2b/v1/consignments/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: data
        });
    }
    uploadBookings(data) {
        const url = '/b2b/v1/leads/assignLead';
        return apiRequest({
            url,
            method: "POST",
            data: data
        });
    }
}