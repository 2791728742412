import { validationRules } from "../../../../../common/utils/constants";

const schema = {
    "type": "object",
    "required": ["name", "type", "kgPerCft"],
    "properties": {
        "name": {
            "type": "string",
            "title": "Box Name",
            pattern: validationRules.shortCode
        },
        "type": {
            "type": "string",
            "title": "Box Type",
            "enum": ["BOX", "BAG"]
        },
        "kgPerCft": {
            "type": "number",
            "title": "Kg/ CFT"
        }
    },
    "dependencies": {
        "type": {
            "oneOf": [
                {
                    "properties": {
                        "type": {
                            "enum": [
                                "BAG"
                            ]
                        },
                    }
                },
                {
                    "properties": {
                        "type": {
                            "enum": [
                                "BOX"
                            ]
                        },
                        "dimensionUnit": {
                            "type": "string",
                            "title": "Dimension Unit",
                            "enum": ["METER", "FEET", "CENTIMETER", "MILLIMETER"],
                            "enumNames": ["Meter", "Feet", "Centimeter", "Millimeter"]
                        },
                        "lbh": {
                            "type": "string",
                            "title": "LBH"
                        },
                    },
                    "required": [
                        "dimensionUnit", "lbh"
                    ]
                },
            ]
        }
    }
}

const uiSchema = {
    "classNames": "multi-row-field-objects hide-title",
    "ui:order": ["name", "type", "dimensionUnit", "lbh", "kgPerCft"],
    "name": {
        "ui:placeholder": "Enter Box Name",
        "maxLength": 50
    },
    "type": {
        "ui:placeholder": "Select Box Type",
    },
    "kgPerCft": {
        "ui:placeholder": "Enter KG /CFT",
        "maxLength": 10
    },
    "dimensionUnit": {
        "ui:placeholder": "Select Unit",
    },
    "lbh": {
        "ui:placeholder": "",
        "ui:widget": "LBHWidget",
        "maxLength": 10
    }
}

const errorList = {
    "name": {
        "required": "Box name is mandatory!",
        pattern: "Box name accepts only characters (a-z,A-Z,0-9,_,-,:)",
    },
    "type": {
        "required": "Box Type is mandatory!"
    },
    "kgPerCft": {
        "required": "KG/ CFT is mandatory!"
    },
    "contractValidity": {
        "required": "Contract Validity is mandatory!"
    },
    "dimensionUnit": {
        "required": "Unit is mandatory!",        
    },
    "lbh": {
        "required": "LBH is mandatory!",        
    }
}

export default { schema, uiSchema, errorList };