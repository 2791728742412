import React, { Component } from 'react';
import './expected.scss';
import { fetchExpectedConsignments, updateConsignment } from './actions.js';
import { connect } from 'react-redux';
import DataCard from '../../shared-components/data-card/DataCard';
import { getSummary } from '../../../app/appAction.js';

import activeAwbIcon0 from './../../../assets/img/icons/images/2_4_Inbound.svg';
import activeAwbIcon1 from './../../../assets/img/icons/images/2_5_Inbound.svg';
import activeAwbIcon2 from './../../../assets/img/icons/images/2_6_Inbound.svg';
import { PageHeader, Row, Col, Button, Spin, Card, Table, Empty, Modal, Form, Select, Input, Tooltip, Icon } from 'antd';
import { canAllow, windowRedirect, dataGet, getLocationData } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import { Constants } from '../../../common/utils/constants';
import moment from 'moment';
import WaybillLink from '../../shared-components/waybill-link/index';

class Expected extends Component {
  constructor(props) {
    super(props);
    this.application = Constants.SELECTED_APPLICATION;
    this.inboundTag =
      this.application === "B2B"
        ? permissionTags.inboundView
        : permissionTags.inbound;
    this.state = {
      permissionList: {
       
        booking: canAllow(this.inboundTag.scan.create)
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          requireLineHaulDiff: true
        }
      },
      dataCardIcon: { activeAwbIcon0, activeAwbIcon1, activeAwbIcon2 },
      columns: [],
      searchValue: '',
      pageNo: 1,
      expandedRowKeys: '0',
      rtsAction: props.config['mark_rts_inventory'] ? true : false,
      waybillActionOptions: [],
      reasonList: [],
      confirmModalVisible: false,
      isReasonPreset: false,
      loadingModal: false,
      summaryLoader: true
    }
    this.InputSearchRef = React.createRef();
    this.props.getSummary('INBOUND_EXPECTED');
  }

  componentDidMount() {
    const { rtsAction } = this.state
    const columns = [
      {
        title: 'Waybill No',
        dataIndex: 'waybillNo',
        key: 'waybillNo',
        render: (data, row) => {
          return (
            <WaybillLink>{data}</WaybillLink>
          )
        }
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        render: (data, row) => {
          if (row.parentEntityType) {
            const source = dataGet(row, 'senderLocation.pincode');
            return <>{(source) ? getLocationData(source) : 'NA'}</>
          } else {
            let source = dataGet(row.consignmentBO, 'customerPickupLoc.pincode');
            source = (source) ? source : dataGet(row.consignmentBO, 'bookingOfficeLoc.address.pincode');
            return <>{(source) ? getLocationData(source) : 'NA'}</>
          }
        }
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination',
        render: (data, row) => {
          const destination = dataGet(row.consignmentBO, 'consignee.address.pincode')
          return <> {destination ? getLocationData(destination) : 'NA'} </>
        }
      },
      {
        title: 'Asset',
        dataIndex: 'vachileNumber',
        key: 'vachileNumber',
        render: (data, row) => 'NA'
      },
      {
        title: 'Shipments',
        dataIndex: 'shipments',
        key: 'shipments',
        render: (data, row) => (<> {row?.consignmentBO?.totalShipmentCount || 'NA'} </>)
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (data, row) => (<> {row?.consignmentBO?.serviceableType || 'NA'} </>)
      },
      {
        title: 'Flow Type',
        dataIndex: 'flowType',
        key: 'flowType',
        render: (data, row) => (<> {row?.consignmentBO?.flowType ? dataGet(row.consignmentBO, 'flowType') : 'NA'} </>)
      },
      {
        title: 'Parent Code',
        dataIndex: 'parentEntityType',
        key: 'parentEntityType',
        render: (data, row) => (data ? row.parentEntityCode : 'NA')
      },
      {
        title: 'ETA',
        dataIndex: 'eta',
        key: 'eta',
        render: (data, row) => (<div>{row?.consignmentBO?.expectedDeliveryDate ? moment(row.consignmentBO.expectedDeliveryDate).format('DD/MM/YYYY') : 'NA'} </div>)
      },
      {
        title: 'Created On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
      },
      {
        title: 'Action',
        dataIndex: 'consignmentBO',
        key: 'Status',
        width: 150,
        application: 'B2C',
        render: (data, row) => (
          (rtsAction) ? <Select style={{ width: '100%' }} onSelect={(value, opt) => this.markShipmentStatus(value, row, opt)} placeholder="Select Action" onFocus={() => this.getActionItems(row.consignmentBO.consignmentStatus, row)}>
            {
              this.state.waybillActionOptions.map(opt => (
                <Select.Option value={opt.value}>{opt.label}</Select.Option>
              ))
            }
          </Select> : null
        )
      },
    ];
    this.setState({
      columns
    })
    this.applyPagination({ pageNo: 1 });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataCardIcon } = this.state;
    const { summary } = this.props;
    if (summary !== prevProps.summary) {
      const DataCardList = [
        {
          title: 'Expected Today',
          documentType: 'EXPECTED_TODAY_LINE_HAUL_LOC',
          description: summary['EXPECTED_TODAY_LINE_HAUL_LOC'].count,
          icon: dataCardIcon[`activeAwbIcon${0}`]
        },
        {
          title: 'Available Trucks',
          documentType: 'AVAILABLE_TRUCKS',
          description: summary['AVAILABLE_TRUCKS'].count,
          icon: dataCardIcon[`activeAwbIcon${1}`]
        },
        {
          title: 'Live Pickups',
          documentType: 'LIVE_PICKUPS',
          description: summary['LIVE_PICKUPS'].count,
          icon: dataCardIcon[`activeAwbIcon${2}`]
        }
      ]
      this.setState({
        DataCardList,
        summaryLoader: false
      })
    }
  }

  createBookingRedirect = () => {
    if (this.inboundTag['booking'] && canAllow(
      this.inboundTag['booking']?.create
    )) {
      this.props.history.push('/appv2/inbound/form');
    } else {
      windowRedirect('inbound/form');
    }
  }

  setReasonData(reqData) {
    const { config } = this.props
    let reasonList = config['reasons'][reqData];
    if (reasonList && reasonList.length) {
      reasonList = reasonList.map((data, index) => ({
        value: Number(data.reasonCode),
        label: data.reasonDescription,
        id: Number(data.reasonCode)
      }));
      this.setState({
        reasonList
      }, () => this.showConfirm(true))
    }
  }

  markShipmentStatus(value, row, _ev) {
    const shipmentParams = {
      request: [
        {
          'consignment':
          {
            'id': row.id,
            'waybillNo': row.waybillNo,
            'consignmentStatus': value,
            'lastStatusReasonCode': null
          },
          'action': 'UPDATE_STATUS',
          'referenceId': new Date().getTime()
        }
      ]
    };
    this.setState({
      reasonList: [],
      shipmentParams
    }, () => {
      this.setReasonData(shipmentParams['request'][0]['consignment']['consignmentStatus']);
    })
  }

  showConfirm = (val) => {
    this.setState({
      confirmModalVisible: val
    })
  }

  onReasonSelected(value) {
    const shipmentParams = this.state.shipmentParams;
    shipmentParams['request'][0]['consignment']['lastStatusReasonCode'] = value
    this.setState({
      isReasonPreset: value,
      shipmentParams,
    })
  }

  confirmAction = () => {
    this.setState({
      loadingModal: true
    }, () => this.props.updateConsignment(this.state.shipmentParams))
  }

  getActionItems(value, rowData) {
    // this.waybillReasonSelection.clear();
    this.isReasonPreset = false;
    let waybillActionOptions = [];
    if (value === 'BOOKED') {
      if (this.state.rtsAction && (Number(rowData.consignmentBO.bookingOfficeLoc.id) === Number(Constants.userLocationId))) {
        waybillActionOptions.push({ 'label': 'Mark RTS', 'id': 1, 'value': 'RTS_RETURN_TO_SHIPPER' });
      }
    }

    this.setState({
      isReasonPreset: false,
      waybillActionOptions
    })
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    if (filters?.waybills) {
      this.setState({ searchValue: filters?.waybills })
      if (filters.waybills[0] === "") {
        delete filters.waybills;
        this.setState({ searchValue: null })

      }
    }
    this.props.fetchExpectedConsignments({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
  }

  reloadExpectedList = () => {
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedConsigment, } = this.props;
    if (!PaginatedConsigment ? !PaginatedConsigment : !PaginatedConsigment[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  render() {
    const { DataCardList, summaryLoader, permissionList, shipmentParams, columns, isReasonPreset, loadingModal, pageNo, searchValue } = this.state;
    const { expectedData, PaginatedConsigment, updateConsigment } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelAlign: "left",
      colon: false
    };
    const actionBtns = (
      <Spin spinning={expectedData?.loading} indicator={<div></div>}>
        <Row>
          <Col span={20}>
            <Input.Search allowClear size={'large'} placeholder="Search by Waybill No" ref={this.InputSearchRef} onSearch={value => this.applyPagination({ waybills: [value.trim()] }, true)} enterButton />
          </Col>
          <Col span={2}>
            <Tooltip placement="top" title={'Reload Awb List'}>
              <Button size={'large'} onClick={this.reloadExpectedList} type="primary">
                <Icon type="reload" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Spin>
    );

    return (
      <>
        <PageHeader
          style={{
            padding: 0,
            paddingBottom: '24px'
          }}
          onBack={false}
          ghost
        >
          <Row>
            <Col span={20}>
              <Spin spinning={summaryLoader}>
                {
                  DataCardList && DataCardList.length > 0 ? DataCardList.map((item, i) => (
                    <Col key={i} span={8} >
                      <DataCard {...item} />
                    </Col>
                  )) : null
                }
              </Spin>
            </Col>
            <Col span={4} style={{ marginTop: 10 }}>             
              {
                permissionList.booking && <Button type="primary" onClick={() => this.createBookingRedirect()} size="large" block>CREATE BOOKING</Button>
              }

            </Col>
          </Row>
        </PageHeader>
        <Card className="expected" title={<h2>Expected List</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            rowKey={(row => (row.id))}
            loading={expectedData?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: expectedData?.totalCount,
              position: 'bottom',
              current: pageNo,
            }}
            scroll={{ x: 800 }}
            dataSource={searchValue?.length > 0 ? expectedData.consignments : PaginatedConsigment ? PaginatedConsigment[pageNo] : []}
            columns={columns} />

        </Card>
        {
          shipmentParams && (
            <Modal
              title="PLEASE CONFIRM YOUR ACTION"
              visible={this.state.confirmModalVisible}
              onOk={() => this.confirmAction()}
              confirmLoading={loadingModal || updateConsigment?.loading}
              onCancel={() => this.showConfirm(false)}
              maskClosable={false}
              okButtonProps={{
                disabled: (loadingModal || !isReasonPreset)
              }}
              okText={`Yes, Mark shipment as ${shipmentParams['request'][0]['consignment']['consignmentStatus'] === 'RTS_RETURN_TO_SHIPPER' ? 'RTS' :
                'RTO'}`}
            >
              <p>Are you sure you want to mark the {shipmentParams['request'][0]['consignment']['waybillNo']} shipment as {shipmentParams['request'][0]['consignment']['consignmentStatus']}?</p>
              <Form.Item {...formItemLayout} label="Reason" >
                <Select style={{ width: '100%' }} onSelect={(val) => this.onReasonSelected(val)} placeholder="Select Action">
                  {
                    this.state.reasonList.map(opt => (
                      <Select.Option value={opt.value} key={opt.id}>{opt.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Modal>
          )
        }
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  summary: state.app.summary,
  expectedData: state.expected,
  PaginatedConsigment: state.expected.PaginatedConsigment,
  updateConsigment: state.expected.updateConsigment,
  config: state.app.configurations,
});
const mapDispatchToProps = dispatch => ({
  getSummary: page => dispatch(getSummary(page)),
  updateConsignment: payload => dispatch(updateConsignment(payload)),
  fetchExpectedConsignments: payload => dispatch(fetchExpectedConsignments(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Expected);