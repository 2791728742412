import * as _Constants from "./constants";
import { notifyApiError } from '../../common/utils/utils';
import SLAReasonsService from "../../services/sla-reasons/sla-reasons.service";
import CustomerService from "../../services/settings/customer.service";

const slaReasonsService = new SLAReasonsService();
// const customerService = new CustomerService();

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
        default:
            return true
    }
}

export const fetchSLAConsignments = (payload, isReset) => dispatch => {
    dispatch(fetchSLAConsignmentBegin());
    slaReasonsService.getSLAConsignments(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLAConsignmentSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchSLAConsignmentFailure(res ?.data));
        }
    });
};

function fetchSLAConsignmentBegin() {
    return {
        type: _Constants.FETCH_SLA_CONSIGNMENTS_BEGIN
    };
}

function fetchSLAConsignmentSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_SLA_CONSIGNMENTS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchSLAConsignmentFailure(error) {
    return {
        type: _Constants.FETCH_SLA_CONSIGNMENTS_FAILURE,
        payload: error
    };
}



export const fetchSLACustomers = (payload) => dispatch => {
    dispatch(fetchSLACustomerBegin());
    CustomerService.getCustomersFetch(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLACustomerSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchSLACustomerFailure(res ?.data ?.response));
        }
    });
};

function fetchSLACustomerBegin() {
    return {
        type: _Constants.FETCH_SLA_CUSTOMERS_BEGIN
    };
}

function fetchSLACustomerSuccess(payload) {
    return {
        type: _Constants.FETCH_SLA_CUSTOMERS_SUCCESS,
        payload: payload
    };
}

function fetchSLACustomerFailure(error) {
    return {
        type: _Constants.FETCH_SLA_CUSTOMERS_FAILURE,
        payload: error
    };
}


export const fetchSLAReasons = (payload) => dispatch => {
    dispatch(fetchSLAReasonBegin());
    slaReasonsService.getReasonsFetch(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLAReasonSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchSLAReasonFailure(res ?.data ?.response));
        }
    });
};

function fetchSLAReasonBegin() {
    return {
        type: _Constants.FETCH_SLA_REASONS_BEGIN
    };
}

function fetchSLAReasonSuccess(payload) {
    return {
        type: _Constants.FETCH_SLA_REASONS_SUCCESS,
        payload: payload
    };
}

function fetchSLAReasonFailure(error) {
    return {
        type: _Constants.FETCH_SLA_REASONS_FAILURE,
        payload: error
    };
}


export const setSLAReasons = (payload) => dispatch => {
    dispatch(setSLAReasonBegin());
    slaReasonsService.setReasons(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(setSLAReasonSuccess(res ?.data));
        } else {
            dispatch(setSLAReasonFailure(res ?.data));
        }
    });
};

function setSLAReasonBegin() {
    return {
        type: _Constants.SET_SLA_REASONS_BEGIN
    };
}

function setSLAReasonSuccess(payload) {
    return {
        type: _Constants.SET_SLA_REASONS_SUCCESS,
        payload: payload
    };
}

function setSLAReasonFailure(error) {
    return {
        type: _Constants.SET_SLA_REASONS_FAILURE,
        payload: error
    };
}


export const fetchSLAConsignmentsSummary = (payload) => dispatch => {
    dispatch(fetchSLAConsignmentSummaryBegin());
    slaReasonsService.getSLAConsignmentsSummary(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLAConsignmentSummarySuccess(res ?.data ?.response));
        } else {
            dispatch(fetchSLAConsignmentSummaryFailure(res ?.data ?.response));
        }
    });
};

function fetchSLAConsignmentSummaryBegin() {
    return {
        type: _Constants.FETCH_SLA_CONSIGNMENTS_SUMMARY_BEGIN
    };
}

function fetchSLAConsignmentSummarySuccess(payload) {
    return {
        type: _Constants.FETCH_SLA_CONSIGNMENTS_SUMMARY_SUCCESS,
        payload: payload
    };
}

function fetchSLAConsignmentSummaryFailure(error) {
    return {
        type: _Constants.FETCH_SLA_CONSIGNMENTS_SUMMARY_FAILURE,
        payload: error
    };
}


export const fetchSLAConsignmentsExport = (payload) => dispatch => {
    dispatch(fetchSLAConsignmentExportBegin());
    slaReasonsService.getSLAConsignmentsExport(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLAConsignmentExportSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchSLAConsignmentExportFailure(res ?.data));
        }
    });
};

function fetchSLAConsignmentExportBegin() {
    return {
        type: _Constants.FETCH_SLA_EXPORT_BEGIN
    };
}

function fetchSLAConsignmentExportSuccess(payload) {
    return {
        type: _Constants.FETCH_SLA_EXPORT_SUCCESS,
        payload: payload
    };
}

function fetchSLAConsignmentExportFailure(error) {
   
    return {
        type: _Constants.FETCH_SLA_EXPORT_FAILURE,
        payload: error
    };
}




export const fetchSLALocations= (payload) => dispatch => {
    dispatch(fetchSLALocationBegin());
    slaReasonsService.getSLAConsignmentsLocations(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLALocationSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchSLALocationFailure(res ?.data ?.response));
        }
    });
};

function fetchSLALocationBegin() {
    return {
        type: _Constants.FETCH_SLA_LOCATIONS_BEGIN
    };
}

function fetchSLALocationSuccess(payload) {
    return {
        type: _Constants.FETCH_SLA_LOCATIONS_SUCCESS,
        payload: payload
    };
}

function fetchSLALocationFailure(error) {
    return {
        type: _Constants.FETCH_SLA_LOCATIONS_FAILURE,
        payload: error
    };
}
