import { Icon, Tag, Tooltip } from "antd";
import moment from "moment";
import { Mixpanel } from "../../../../../utils/mixpanel";
import React from "react";
import { permissionTags } from "../../../../../configs/permissionTags";
import { canAllow } from "../../../../../common/utils/utils";
import { tranformStatus } from "./indent.listing.util";
import _ from 'lodash';

const formatValidity = (date, format='DD/MM/YYYY') => (moment(date).format(format));

export default function getIndentsColumns(IndentEvents) {
    return [
        {
            title: 'Indent Code',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 120,
            sorter: true,
            // defaultSortOrder: 'ascend',
            // sortOrder: IndentEvents.sortOrder,
            sortDirections: ['ascend', 'descend'],
            render: (data, row, index) => (
                <a type="link" className="visited-link" onClick={() => {
                    Mixpanel.track("Clicking Indent Code in Indent Tab", { 'IndentView': moment().valueOf() });
                    IndentEvents.showIndent(row.id, index)
                }}>
                    { row.indentCode }
                    <>
                        {row?.isAdhoc &&
                            <Tag>Adhoc</Tag>
                        }
                    </>
                </a>
            ),

        },
        {
            title: 'Indent Date',
            dataIndex: 'indentDate',
            key: 'indentDate',
            sorter: true,
            // defaultSortOrder: 'descend',
            // sortDirections: ['ascend', 'descend'],
            width: 120,
            render: data => formatValidity(data)

        },
        {
            title: 'Shipper',
            dataIndex: 'shipperBO_name',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],       
            key: 'shipperBO_name',

        },
        {
            title: 'Service Type',
            dataIndex: 'serviceTypeBO_lovValue',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],     
            key: 'serviceTypeBO_lovValue',

        },
        {
            title: 'Pickup Location',
            dataIndex: 'pickupLocationBO_name',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],     
            key: 'pickupLocationBO_name',

        },
        {
            title: 'Vh. Type',
            dataIndex: 'vehicleTypeBO_name',
            //sorter: true,
            //sortDirections: ['ascend', 'descend'],     
            key: 'vehicleTypeBO_name',

        },
        {
            title: 'Total Load',
            dataIndex: 'destinationLoads',
            // sorter: true,
            // sortDirections: ['ascend', 'descend'],     
            key: 'destinationLoads',

        },
        {
            title: 'Indent Status',
            dataIndex: 'status',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            key: 'status',
            render: text => tranformStatus(text)
        },
        {
            title: 'Placement Cut-off',
            dataIndex: 'placementCutOffBO_cutOffAt',
            //sorter: true,
            //sortDirections: ['ascend', 'descend'],
            key: 'placement',
            render: (data, row) => ["APPROVAL_PENDING", "APPROVED", "LEAD_CREATED"].indexOf(row.status) > -1 ? formatValidity(data, 'DD/MM/YYYY hh:mm A') : "--"
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (data, row) =>
                <div className="flex-box align-items-center justify-content-center flex-gap-l">
                    {
                        canAllow(permissionTags.indent.indent.create) &&
                        <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
                            <Tooltip placement="top" title={"Copy"}>
                                <Icon type="copy" onClick={() => {
                                    Mixpanel.track("Clicking clone indent button in Indent Detailed Tab", { 'IndentClonedDetailed': moment().valueOf() });
                                    // IndentEvents.editIndent(data, true)
                                    IndentEvents.copyIndent(row.id)
                                }} />
                            </Tooltip>
                        </div>
                    }
                </div>
        },
    ]
}