import React, { Component } from 'react';
import './mail.scss';
import { getCustomers, sendMail } from './actions.js';
import { connect } from 'react-redux';
import { Card, Form, Select, Input, Row, Col, Button, Spin } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { notifyApiSuccess, notifyApiError } from '../../../common/utils/utils';

class MailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromList: [],
      toList: [],
      modules: {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image'],
          ['clean']
        ],
      },
      error: {
        isBodyErr: 'success',
        bodyMsg: ''
      },
      formats: [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ],
      filterPayload: {
        filters: {
          customerTypes: ['CUSTOMER'],
          status: ["ACTIVE"],
          fetchMode: 'BASIC'
        }
      }

    }

    this.props.getCustomers(this.state.filterPayload)
  }

  componentDidMount() {
    const { config } = this.props;
    if (config['communication_meta']) {
      this.setState({
        fromList: config.communication_meta.fromList.map(address => {
          return {
            value: address,
            label: address,
            id: new Date().getTime().toString()
          };
        })
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.emailStatus !== prevProps.emailStatus) {
      if (this.props.emailStatus[0].sync) {
        notifyApiSuccess('Mail sent successfully', 'Mail');
        this.props.history.push('/appv2/communication/dashboard/mail')
      } else {
        notifyApiError(this.props.emailStatus[0].reasonCode, 'Error')
      }
    }
    if (this.props.customers !== prevProps.customers) {
      if (this.props.customers.length > 0) {
        let list = this.props.customers.map(customer => {
          return {
            value: customer.emailId,
            label: customer.emailId,
            id: customer.id
          };
        })

        this.setState({
          toList: list
        })
      }
    }
  }

  handleBodyContent = body => {
    this.props.form.setFieldsValue({ body })
    this.setState({
      error: {
        isBodyErr: (!body) ? 'error' : 'success',
        bodyMsg: (!body) ? 'Please enter some content' : ''
      }
    })
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({
        error: {
          isBodyErr: (err ?.body ?.errors ?.length > 0) ? 'error' : 'success',
          bodyMsg: (err ?.body ?.errors ?.length > 0) ? 'Please enter some content' : ''
        }
      })
      if (!err) {
        const { fromAddresses, toAddresses, ccAddresses, subject, body } = values;
        const params = {
          request: [{
            referenceId: new Date().getTime(),
            email: {
              fromAddress: fromAddresses,
              toList: toAddresses,
              ccList: ccAddresses,
              attachmentList: [],
              emailSubject: subject,
              emailBody: body
            }
          }]
        };
        this.props.sendMail(params);
      }
    })
  }

  render() {
    const { fromList, toList, modules, formats, error } = this.state;
    const { loading } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('body', {
      rules: [
        {
          required: true,
        },
      ],
    });
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      labelAlign: "left",
      colon: false,
    };
    return (
      <Form {...formItemLayout}>
        <Card title={<h2>Compose Mail</h2>}>
          <Spin spinning={false}>
            <Form.Item label="From Addresses">
              {getFieldDecorator('fromAddresses', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter from addresses!',
                  },
                ],
              })(
                <Select loading={loading} placeholder={"Select Sender Address"} showSearch filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } showArrow onChange={this.onPartnerChange}>
                  {fromList ?.map(org => (
                    <Select.Option
                      key={org.id}
                      value={org.value}
                      label={org.label}
                    >
                      {org.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="To Addresses">
              {getFieldDecorator('toAddresses', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter to addresses!',
                  },
                ],
              })(
                <Select mode="multiple" loading={loading} placeholder={"Select Reciever Address"} showSearch filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } showArrow onChange={this.onPartnerChange}>
                  {toList ?.map(cust => (
                    <Select.Option
                      key={cust.id}
                      value={cust.value}
                      label={cust.label}
                    >
                      {cust.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Cc Addresses">
              {getFieldDecorator('ccAddresses', {
                rules: [
                  {
                    required: false,
                    message: 'Please input cc addresses!',
                  },
                ],
              })(
                <Select mode="multiple" loading={loading} placeholder={"Select Reciever Address"} showSearch filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } showArrow onChange={this.onPartnerChange}>
                  {toList ?.map(cust => (
                    <Select.Option
                      key={cust.id}
                      value={cust.value}
                      label={cust.label}
                    >
                      {cust.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Subject">
              {getFieldDecorator('subject', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter subject!',
                  },
                ],
              })(
                <Input
                  placeholder="Enter Subject"
                />
              )}
            </Form.Item>
            <Form.Item required label="Email Body" validateStatus={error.isBodyErr} help={error.bodyMsg}>
              <ReactQuill style={{ height: '300px', marginBottom: '4.5em' }} onChange={this.handleBodyContent} theme="snow" modules={modules} formats={formats} />
            </Form.Item>
          </Spin>
        </Card>
        <Card>
          <Spin spinning={loading}>
            <Row>
              <Col span={2} offset={20} >
                <Button size="large" onClick={e => this.props.history.push('/appv2/communication/dashboard/mail')} >CANCEL</Button>
              </Col>
              <Col span={2}>
                <Button size="large" onClick={e => this.handleSubmit(e)} type="primary">Submit</Button>
              </Col>
            </Row>
          </Spin>
        </Card>
      </Form>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  config: state.app.configurations,
  customers: state.communicationMail.customers,
  loading: state.communicationMail.loading,
  emailStatus: state.communicationMail.emailStatus,
});
const mapDispatchToProps = dispatch => ({
  getCustomers: (payload) => dispatch(getCustomers(payload)),
  sendMail: (payload) => dispatch(sendMail(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Mail', onValuesChange: (props, val) => console.log(props) })(MailForm));