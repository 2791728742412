import React, { Component } from "react";
import {
    Card,
    Row,
    Col,
    Input,
    Checkbox,
    Switch,
    Icon,
    Button,
    Table,
    Spin,
    Modal,
    List,
    Tooltip,
    Select
} from "antd";
import * as moment from "moment";
import _ from "lodash";
import { permissionTags } from '../../../../configs/permissionTags';
import { canAllow, windowRedirect, notifyApiSuccess, notifyApiError } from '../../../../common/utils/utils';
import { Mixpanel } from "../../../../utils/mixpanel";
import { apiRequest } from '../../../../common/utils/apiGateway';


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

class VehiclesList extends Component {
    state = {
        columns: [
            {
                title: "Model",
                dataIndex: "vehicleType",
                key: "vehicleType"
            },
            {
                title: "Capacity (tonnes)",
                dataIndex: "vehicleCapacity",
                key: "vehicleCapacity",
                render: (data, row) => <>{data}</>
            },
            {
                title: "Body",
                dataIndex: "bodyType",
                key: "bodyType",
                render: (data, row) => <>{data}</>
            },
            {
                title: "Length (Ft.)",
                dataIndex: "length",
                key: "length",
                render: (data, row) => <>{data}</>
            },
            {
                title: "Breadth (Ft.)",
                dataIndex: "breadth",
                key: "breadth",
                render: (data, row) => <>{data}</>
            },
            {
                title: "Height (Ft.)",
                dataIndex: "height",
                key: "height",
                render: (data, row) => <>{data}</>
            },
            {
                title: "Default",
                dataIndex: "isDefault",
                key: "isDefault",
                render: (data, record) =>
                    <>
                        {this.state.vehiclePermission?.update && record?.isActive ?
                            <Checkbox
                                checked={data}
                                onChange={(e) => this.updtaeCategoryKey(e.target.checked, record, "isDefault")}
                            /> :
                            <Checkbox
                                checked={data}
                                disabled
                            // onChange={(e) => this.updtaeCategoryKey(e.target.checked, record, "isDefault")}
                            />
                        }
                    </>
            },
            {
                title: "Active",
                dataIndex: "isActive",
                key: "isActive",
                render: (data, record) =>
                    <>
                        {this.state.vehiclePermission?.update ?
                            <Switch
                                onChange={(e) => this.updtaeCategoryKey(e, record, "isActive")}
                                checked={data}
                            />
                            : <Switch
                                disabled
                                // onChange={(e) => this.updtaeCategoryKey(e, record, "isActive")}
                                checked={data}
                            />
                        }
                    </>
            },


            {
                title: "Actions",
                key: "action",
                render: (text, record) => (
                    <>
                        <Row gutter={6}>
                            <Col span={12}>
                                {this.state.vehiclePermission.update && record?.isActive ?
                                    <Icon type="edit" onClick={() => this.props.history.push('/appv2/settings/form/asset/categories', { id: record?.id })} />
                                    : <Icon type="edit" style={{ cursor: 'not-allowed', opacity: '0.5' }} />
                                }


                            </Col>
                            <Col span={12}>
                                {this.state.vehiclePermission.view ?
                                    <Icon type="eye" onClick={() => this.openViewModal(record)} />
                                    : <Icon type="eye" style={{ cursor: 'not-allowed', opacity: '0.5' }} />
                                }
                            </Col>
                        </Row>
                    </>
                )
            }
        ],
        categories: [],
        dataLoading: false,
        vehiclePermission: {
            read: canAllow(permissionTags.settings.vehicle.read),
            update: canAllow(permissionTags.settings.vehicle.update),
            create: canAllow(permissionTags.settings.vehicle.create),
            view: canAllow(permissionTags.settings.vehicle.view)
        },
        vehicleCapacities: undefined,
        vehicleTypes: undefined,
        selectedFilter: 'ALL'
    }

    componentDidMount() {
        this.reload({});
        const user = JSON.parse(atob(localStorage.getItem("user")));
        const loggedPartnerId = localStorage.getItem("loggedPartnerId");
        Mixpanel.identify(loggedPartnerId);
        Mixpanel.people.set({
            $first_name: user?.name,
            $email: user?.email,
            $phone_number: user?.number,
            $partner_id: user?.partner?.id,
            $partner_name: user?.partner?.name
        });
        Mixpanel.track("Vehicle Category Page Initiated", {
            ERPVehicleCategory: moment().valueOf()
        });
    }

    openViewModal = record => {

        Modal.info({
            title: 'Vehicle Category',
            content: (
                <div>
                    <List
                        size="small"
                        bordered
                        dataSource={[record]}
                        renderItem={item =>
                            <>
                                <List.Item>
                                    Vehicle Type: <b>{item?.vehicleType}</b>
                                </List.Item>
                                <List.Item>
                                    Vehicle Capacity: <b>{item?.vehicleCapacity} {item?.vehicleCapacityUnit}</b>
                                </List.Item>
                                <List.Item>
                                    Breadth: <b>{item?.breadth} {item?.lbhUnit}</b>
                                </List.Item>
                                <List.Item>
                                    Height: <b>{item?.height} {item?.lbhUnit}</b>
                                </List.Item>
                                <List.Item>
                                    Length: <b>{item?.length} {item?.lbhUnit}</b>
                                </List.Item>
                                <List.Item>
                                    Default: <b>{item?.isDefault ? "Yes" : "No"}</b>
                                </List.Item>
                            </>
                        }
                    />
                </div>
            ),
            onOk() { },
        });
    }

    reload = (filter, bool) => {
        if (bool) {
            this.setState({ dataLoading: true, vehicleCapacities: undefined, vehicleTypes: undefined })
        } else {
            this.setState({ dataLoading: true })
        }
        if (_.isEmpty(filter)) {
            this.setState({ selectedFilter: 'ALL' })
        }

        apiRequest({
            url: `/b2b/v1/vehicle-types/getAllVehicleTypes`,
            method: 'POST',
            data: {
                filter
            }
        }).then(res => {

            if (checkHttpStatus(res?.data?.status)) {
                let { categories } = this.state;
                categories = res?.data?.response?.vehicleTypes;
                this.setState({ categories }, () => {
                    this.setState({ dataLoading: false })
                })
            } else {
                this.setState({ dataLoading: false })
            }
        }).catch(err => {
            this.reload({});
        })
    }

    updtaeCategoryKey = (checked, record, key) => {
        let isDefault = checked;
        record = {
            ...record,
            [key]: isDefault
        }
        const payload = {
            "vehicleTypesBOS": [
                record
            ]
        }
        Mixpanel.track(`Vehicle Category Updated key ${key}`, {
            ERPVehicleKey: moment().valueOf(),
            categoryId: record?.id
        });
        this.updateCategory(payload);
    }

    updateCategory = payload => {
        this.setState({ dataLoading: true });
        apiRequest({
            url: `/b2b/v1/vehicle-types/update`,
            method: 'POST',
            data: payload
        }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                this.setState({ dataLoading: false });
                notifyApiSuccess("Vehicle Type Updated Successfully!", "SUCCESS");
                let filter = {};                
                if (this.state.selectedFilter === 'ACTIVE') {
                    filter = {
                        "isActive": true
                    }
                } else if (this.state.selectedFilter === 'INACTIVE') {
                    filter = {
                        "isActive": false
                    }
                } else {
                    filter = {};
                }
                
                this.setState({vehicleCapacities: undefined, vehicleTypes: undefined})
                this.reload(filter);
            } else {
                this.setState({ dataLoading: false });
            }
        });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    changeCategory = (value, key) => {
        if (!value) {
            this.searchCategory(value, key)
        }
        this.setState({
            [key]: value
        })

    }

    changeActiveFilter = value => {
        this.setState({ selectedFilter: value })
        let filter = {};
        if (value === 'ALL') {
            this.reload({});
        } else if (value === 'ACTIVE') {
            filter = {
                "isActive": true
            }
            this.reload({ ...filter });
        } else {
            filter = {
                "isActive": false
            }
            this.reload({ ...filter });
        }

    }

    searchCategory = (value, key) => {
        let filter = {};
        if (value) {
            filter = {
                [key]: [value]
            }
        } else {
            filter = {
                [key]: []
            }
        }


        this.reload({ ...filter });
    }

    render() {

        const data = [];
        const actions = (
            <Row gutter={6}>
                <Col span={this.state.vehiclePermission.create ? 6 : 8} offset={0}>
                    <Input
                        size="large"
                        allowClear
                        value={this.state.vehicleTypes}
                        onChange={(e) => this.changeCategory(e.target.value, "vehicleTypes")}
                        onPressEnter={(e) => this.searchCategory(e.target.value, "vehicleTypes")}
                        placeholder={"Search Category"}
                    />
                </Col>
                <Col span={this.state.vehiclePermission.create ? 6 : 8}>
                    <Input
                        size="large"
                        allowClear
                        value={this.state.vehicleCapacities}
                        onChange={(e) => this.changeCategory(e.target.value, "vehicleCapacities")}
                        onPressEnter={(e) => this.searchCategory(e.target.value, "vehicleCapacities")}
                        placeholder={"Search Capacity"}
                    />
                </Col>
                <Col span={this.state.vehiclePermission.create ? 4 : 5}>
                    <Tooltip title="Default">
                        <Select
                            style={{ width: '100%' }}
                            size="large"
                            // allowClear={this.state.selectedFilter !== 'ALL'}
                            onChange={this.changeActiveFilter}
                            value={this.state.selectedFilter}
                        >
                            <Select.Option value={'ALL'}> All</Select.Option>
                            <Select.Option value={'ACTIVE'}> Active</Select.Option>
                            <Select.Option value={'INACTIVE'}> Inactive</Select.Option>
                        </Select>
                    </Tooltip>
                </Col>
                <Col span={this.state.vehiclePermission.create ? 2 : 3}>
                    <Tooltip placement="top" title={'Reload Vehicle List'}>
                        <Button block size={'large'} onClick={() => this.reload({}, true)} type="primary">
                            <Icon type="reload" />
                        </Button>
                    </Tooltip>
                </Col>
                {this.state.vehiclePermission.create &&
                    <Col span={6}>
                        <Button block size={'large'} type="primary" onClick={() => this.props.history.push('/appv2/settings/form/asset/categories')} >Add new Category</Button>
                    </Col>
                }


            </Row >
        )
        return (
            <Card
                title={<h2><Icon type="arrow-left" onClick={() => this.goBack()} /> Categories</h2>}
                extra={actions}
            >
                <Spin spinning={this.state.dataLoading}>
                    <Table
                        pagination={{
                            total: this.state?.categories?.length,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`

                        }}
                        bordered
                        columns={this.state.columns}
                        dataSource={this.state.categories}
                    />
                </Spin>
            </Card>

        )
    }
}

export default VehiclesList;