import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Switch } from "react-router-dom";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RouteWithTitle from "../RouteWithTitle";
import NopermissionHeader from "../../components/shared/header/NopermissionHeader";
import "../app.scss";
import { canAllow } from "../../common/utils/utils";
import AccessDenied from "../../components/authentication/access/AccessDenied";

class NosidebarLayout extends Component {

  render() {
    const { path, component, name, access} = this.props;
    return (
      <>
              <NopermissionHeader {...this.props}></NopermissionHeader>
              <Layout className="padding-container">
                <Layout>
                  <Switch>
                    {path && <RouteWithTitle key={name} exact={true} path={path} 
                    component={access === undefined || canAllow(access) ? component : AccessDenied } name={name}/>}
                  </Switch>
                </Layout>
              </Layout>
      </>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(NosidebarLayout))
);