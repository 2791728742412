import React, { Component } from 'react';
import B2bLiteBooing from '../inbound-create/b2b-lite-booking/b2b-lite-booking';
import { Constants } from '../../../common/utils/constants';
import { permissionTags } from '../../../configs/permissionTags';
import B2CBooking from '../inbound-create/b2c-booking';
import { canAllow } from '../../../common/utils/utils';

class InboundPTLForm extends Component {

    componentDidMount() {
        // console.log(this.props.match.params);

    }

    render() {
        const inboundTag = Constants.SELECTED_APPLICATION === 'B2B' ? permissionTags.inboundView : permissionTags.inbound;
        if (inboundTag['booking'] ? canAllow(inboundTag['booking']?.create) : inboundTag['booking'])
            return (<B2bLiteBooing key={1} formId={this.props.match.params.id ? this.props.match.params.id : 0} />)
        else
            return (<B2CBooking key={2} formId={this.props.match.params.id ? this.props.match.params.id : 0} />)
    }
}

export default InboundPTLForm;