import { Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../../../common/utils/apiGateway';
import "../dockets/print-dockets.scss";
import { checkAPIHttpStatus } from '../../../../common/utils/utils';
import { reverseManifestColumns } from './reverse.manifest.columns';

const ReverseManifestTemplate = props =>{

    const [data, SetData] = useState(false);
    const [dataSource, SetDataSource] = useState([]);


    useEffect(() => {
        if (props?.consignmentId) {
            apiRequest({
                url    : "/b2b/v1/thc/fetch",
                method : "POST",
                data   : {
                    filters: {
                        fetchObjects: [
                            "MANIFEST"
                        ],
                        isActive: true,
                        thcIds: [ props?.consignmentId ]
                    }
                }
            }).then(response => {
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('status') && 
                    checkAPIHttpStatus(response?.data?.status)) {
                    var thcs = response.data.response.thcs;
                    var manifestCodes = [];
                    if (thcs && thcs.length > 0 && thcs[0].manifests.length > 0) {
                        thcs[0].manifests.forEach(manifest => {
                            manifestCodes.push(manifest.manifestCode);
                        })
                    }
                    apiRequest({
                        url     : "/b2b/v1/consignments/fetchDetails",
                        method  : "POST",
                        data    : {
                            filters : {
                                entityTypes : [ "MANIFEST" ],
                                entityCodes : manifestCodes,
                                isOrgLevel  : false
                            }
                        }
                    }).then(response => {
                        SetDataSource(response.data.response.consignments)
                        SetData(response.data.response);
                    })
                }
                else {
                    SetData(true);
                }
            })
        }
    }, [ props.consignmentId ] );

    reverseManifestColumns.forEach(col => {
        col["render"] = data => <div style={{ maxWidth: 100, wordBreak: "break-all" }}> {data} </div>
    });

    const tableCols = [
        {
            title: () => "",
            children: reverseManifestColumns
        }
    ]

    return (
        <>
            {
                data ?
                <>
                    <div style={{marginTop:25}}>
                        <Table className="print-table"
                            columns={tableCols}
                            dataSource={dataSource}
                            bordered={true}
                            pagination={false}
                            rowKey={"id"} />
                    </div>
                </> :
                <div className="flex-box justify-content-center">
                    <Spin spinning={!data}/>
                </div>
            }
        </>
    )
}

export default ReverseManifestTemplate;