const schema = {
    "type"       : "object",
    "title"      : "Other Charges",
    "properties" : {
        "insuranceTerms": {
            "type": "string",
            "title": "Insurance Terms"
        },
        "deps": {
            "type": "string",
            "title": "DEPS"
        },
        "remarks": {
            "type": "string",
            "title": "Remarks"
        }
    }
}

const uiSchema = {
    "classNames": "multi-row-field-objects",
    "insuranceTerms" : {
        "ui:placeholder"    : "Enter Insurance terms"
    },
    "deps" : {
        "ui:placeholder"    : "Enter DEPS"
    },
    "remarks" : {
        "ui:placeholder"    : "Enter Remarks"
    }
}

const errorList = {
    "insuranceTerms" : {
        "required"    : "Insurance terms cannot be empty",
        "format"      : "Insurance terms has to contain alphabets and number alone!",
    },
    "deps" : {
        "required"    : "DEPS cannot be empty",
        "format"      : "DEPS has to contain alphabets and number alone!",
    },
    "remarks" : {
        "required"    : "Remarks cannot be empty",
        "format"      : "Remarks has to contain alphabets and number alone!",
    }
}

export default { schema, uiSchema, errorList };