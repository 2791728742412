import React, { useState, useEffect } from 'react';
import { Button, Tag, Modal, Icon, Checkbox } from 'antd';
import FormWrapper from '../../../ui/formComponent/form';
import shipperForm from './shipper.form';
import { updateShipper, fetchPincode } from "../common/services/index";
import _ from 'lodash';
import "./shipper.scss";
import { Constants } from '../../../../common/utils/constants';
import { notifyApiSuccess } from '../../../../common/utils/utils';

const { confirm } = Modal;

function ShipperFormModal(props) {
    const [schema, SetSchema] = useState(shipperForm.schema);
    const [uiSchema, setUISchema] = useState(shipperForm.uiSchema);
    const [formData, SetFormData] = useState({});
    const [readOnly, setReadonly] = useState(props.readOnly ? props.readOnly : false);
    // const [createAnother, setCreateAnother] = useState(false);
    const [isCancel, setCancel] = useState(false);
    const { shipperRef, onSave, customerId, setRefresh, customer, showModal, createAnother, setCreateAnother, openHelpLink } = props;
    const onSubmit = (formProps) => {
        if (formProps) {
            // SetLoaded(false);
            confirm({
                title: 'Are you sure to submit?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    const { contactShortCode, shipperName, contactPersonName, contactNumber, email, gstin, isMandatory, shipperAddress, pinCode, lat, lng } = formProps.shipperDetails;
                    let code = contactShortCode;
                    if (!readOnly && !props?.shippers?.id) {
                        code = `SHI-${customer.code}-${contactShortCode}`;
                    } else if (!readOnly && props?.shippers?.id) {
                        code = `SHI-${customer.code}-${contactShortCode}`;
                    }
                    let params = {
                        contactNumber,
                        contactPersonName,
                        contactShortCode: code.toUpperCase(),
                        entityType: "CUSTOMER",
                        email,
                        shipperName,
                        partnerId: Constants.PARTNER_ID,
                        locationId: Constants.userLocationId,
                        entityId: customerId,
                        ...(gstin && { gstNumber: gstin }),
                        address: {
                            address1: shipperAddress,
                            "pincode": {
                                "zipcode": pinCode
                            },
                            "latitude": lat,
                            "longitude": lng
                        },
                        validationRequired: true

                    }

                    const create = async () => {
                        params = {
                            ...params,
                            isMandatory: true,
                        }
                        const data = await updateShipper(params);
                        // SetLoaded(true);
                        if (data) {
                            notifyApiSuccess("Shipper Created!!", "SUCCESS");
                            props.cancelShipperModal();
                            setRefresh(true);
                            props.setReadOnly(false);
                            if (createAnother) {

                                showModal(true);
                                setCreateAnother(true);
                                // setCreateAnother(true);

                            }
                        }


                    }
                    const update = async () => {
                        params = {
                            ...params,
                            id: props?.shippers?.id,
                            isActive: props?.shippers.isActive,
                            status: props?.shippers.status
                        }
                        const data = await updateShipper(params);
                        // SetLoaded(true);
                        if (data) {
                            notifyApiSuccess("Shipper Updated!!", "SUCCESS");
                            props.cancelShipperModal();
                            setRefresh(true);
                            // if (createAnother) {
                            //     props.setReadOnly(false);
                            //     showModal(true);

                            //     setCreateAnother(false);
                            // }
                        }



                    }


                    if (!readOnly && props?.shippers?.id) {
                        update();
                    } else if (!readOnly && !props?.shippers?.id) {
                        create();
                    }
                }
            })


            // setCurrentStep(currentStep + 1)
        }
    }


    useEffect(() => {
        if (props.shippers) {
            let code = props.shippers.contactShortCode;
            if (!readOnly) {
                // var splitter = code.replace(`SHI-${customer.code}-`, '');
                var splitter = code.split("-");
                if (splitter.length > 1) {
                    code = splitter[splitter.length - 1];
                } else {
                    code = props.shippers.contactShortCode;
                }
            }
            const address = props?.shippers?.address;
            // const setcity = props?.shippers?.address?.pincode?.city?.state + '/' + props?.shippers?.address?.pincode?.city?.name;
            const setcity = (props?.shippers?.address?.pincode?.city?.state && props?.shippers?.address?.pincode?.city?.name) && props?.shippers?.address?.pincode?.city?.state + '/' + props?.shippers?.address?.pincode?.city?.name;
            SetFormData({
                "shipperDetails": {
                    ...props.shippers,
                    contactShortCode: code,
                    ...(address && { shipperAddress: props?.shippers?.address?.address1 }),
                    ...(address && { pinCode: props?.shippers?.address?.pincode?.zipcode ? Number(props?.shippers?.address?.pincode?.zipcode) : undefined }),
                    ...(address && { stateCity: setcity ? setcity : undefined }),
                    gstin: props?.shippers?.gstNumber
                },
            })
        }


    }, [props?.shippers?.id, readOnly])

    useEffect(() => {

        if (!readOnly && !props?.shippers?.id) {

            const code = `SHI-${customer.code}-`;
            setUISchema({
                "shipperDetails": {
                    ...uiSchema.shipperDetails,
                    "contactShortCode": {
                        ...uiSchema.shipperDetails.contactShortCode,
                        "addonBefore": code
                    }
                }



            })

        } else if (!readOnly && props?.shippers?.id) { // Needs to implement
            const code = `SHI-${customer.code}-`;
            setUISchema({
                "shipperDetails": {
                    ...uiSchema.shipperDetails,
                    "contactShortCode": {
                        ...uiSchema.shipperDetails.contactShortCode,
                        "addonBefore": code,
                        disabled: true
                    }
                }



            })

        }

    }, [readOnly])

    useEffect(() => {
        if (!props.readOnly) {
            // const form = formData;

            // const { contactShortCode } = form?.shipperDetails;

            setUISchema(sch => {
                sch.shipperDetails.shipperAddress.onSelection = (pincode, selectedAddress, lat, lng, selectedCity, cityCode, selectedState) => {


                    const oldFormData = { ...shipperRef.current.formReference.current.state.formData };
                    getPincode(pincode).then(response => {
                        if (!_.isEmpty(response)) {
                            const setcity = (response?.city?.state && response?.city?.name) && response?.city?.state + '/' + response?.city?.name;

                            SetFormData({
                                shipperDetails: {
                                    // ...oldFormData.shipperDetails,
                                    ...formData.shipperDetails,
                                    pinCode: response.zipcode ? Number(response.zipcode) : undefined,
                                    shipperAddress: selectedAddress,
                                    stateCity: setcity ? setcity : undefined,
                                    lat,
                                    lng,
                                }
                            })
                        } else {
                            SetFormData({
                                shipperDetails: {
                                    // ...oldFormData.shipperDetails,
                                    ...formData.shipperDetails,
                                    shipperAddress: selectedAddress,
                                    stateCity: undefined,
                                    lat,
                                    lng,
                                }
                            })
                        }

                    })



                }

                return sch;
            });


        }
    })
    const onChange = params => {

        if (params.shipperDetails.contactShortCode) {
            setCancel(true);
            SetFormData({
                shipperDetails: {
                    ...params.shipperDetails,
                    contactShortCode: params.shipperDetails.contactShortCode ? params.shipperDetails.contactShortCode.toUpperCase() : params.shipperDetails.contactShortCode
                }


            })
        } else {
            setCancel(true);
            SetFormData(params)
        }
    }
    const getPincode = pincode => {
        return fetchPincode({ pincode }).then(res => {
            if (res.pincodedetails && res.pincodedetails[pincode]) {
                return res.pincodedetails[pincode];
            } else {
                return {};
            }

        })
    }
    const checkAnother = e => {
        // const oldFormData = { ...shipperRef.current.formReference.current.state.formData };
        setCreateAnother(e.target.checked)

        // SetFormData({
        //     shipperDetails: {
        //         ...oldFormData.shipperDetails,
        //     }
        // })
    }

    const onBlur = (id, value) => {

        if (id === 'shipperAddress') {

            SetFormData({
                ...formData,
                shipperDetails: {
                    ...formData.shipperDetails,
                    shipperAddress: value
                }


            })

        }

        if (id === 'pinCode') {
            getPincode(value).then(response => {
                if (!_.isEmpty(response)) {
                    const setcity = (response?.city?.state && response?.city?.name) && response?.city?.state + '/' + response?.city?.name;
                    SetFormData({
                        ...formData,
                        shipperDetails: {
                            ...formData.shipperDetails,
                            pinCode: response.zipcode ? Number(response.zipcode) : undefined,
                            stateCity: setcity ? setcity : undefined,
                        }
                    })
                } else {
                    SetFormData({
                        ...formData,
                        shipperDetails: {
                            ...formData.shipperDetails,
                            stateCity: undefined,
                        }
                    })
                }

            })
        }
    }

    return (

        <Modal
            visible={props.shipperModal}
            title={
                <>
                    <div className="flex-box flex-column flex-gap-xl shipper-modal">
                        <div>
                            <div className="flex-4 flex-box align-items-center justify-content-between flex-gap-xl">
                                <div className="flex-5 flex-box flex-gap-l">
                                    <div>
                                        <Icon type="arrow-left" onClick={() => {
                                            if (isCancel && !_.isEmpty(formData.shipperDetails)) {
                                                confirm({
                                                    title: 'Are you sure to cancel?',
                                                    okText: 'Yes',
                                                    cancelText: 'No',
                                                    onOk: () => {
                                                        setCreateAnother(false);
                                                        props.cancelShipperModal()
                                                    }
                                                })
                                            } else {
                                                setCreateAnother(false);
                                                props.cancelShipperModal()
                                            }
                                        }} />
                                    </div>
                                    <div>
                                        {(!props.shippers && !readOnly) ?
                                            <div className="flex-box">
                                                <div>
                                                    Add Shipper
                                                </div>

                                            </div>
                                            :
                                            <>{props?.shippers?.contactShortCode} : {props?.shippers?.contactPersonName}</>
                                        }

                                    </div>


                                </div>
                                <div className="flex-1 flex-box justify-content-flex-end flex-gap-m">

                                    {(!readOnly) &&
                                        <Tag className="cursor-pointer" color="#87d068" onClick={openHelpLink}><Icon type="question-circle" /> Help</Tag>
                                    }
                                    {
                                        (readOnly && props.shipperPermission.shipperUpdate && props.shippers.id) &&
                                        <div>
                                            <Button block className="lsn-btn-primary" onClick={() => setReadonly(false)}>
                                                Edit
                                            </Button>
                                        </div>

                                    }

                                    {readOnly &&
                                        <div>
                                            <Button block type="default" onClick={props.cancelShipperModal}>
                                                Close
                                        </Button>
                                        </div>
                                    }
                                </div>
                            </div>


                        </div>

                    </div>


                </>
            }
            onCancel={props.cancelShipperModal}
            width={'85%'}
            closable={false}
            style={{ top: 20 }}
            footer={
                !readOnly &&
                <div className="flex-box flex-gap-m justify-content-end align-items-center shipper-modal">
                    <div className="flex-box">
                        {(!readOnly && !props.shippers) &&
                            <Checkbox
                                checked={createAnother}
                                onChange={checkAnother}
                            >
                                Create Another
                            </Checkbox>
                        }

                    </div>
                    <div className="flex-box">
                        <Button block onClick={() => {
                            if (isCancel && !_.isEmpty(formData.shipperDetails)) {
                                confirm({
                                    title: 'Are you sure to cancel?',
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk: () => {
                                        props.cancelShipperModal()
                                    }
                                })
                            } else {
                                props.cancelShipperModal()
                            }

                        }} >
                            Cancel
                        </Button>
                    </div>
                    <div className="flex-box">
                        <Button block className="lsn-btn-primary" onClick={() => onSave()}>
                            {props.shippers ? 'Save' : 'Add'}
                        </Button>
                    </div>



                </div>

            }
        >

            <FormWrapper
                schema={schema}
                uiSchema={uiSchema}
                errorList={shipperForm.errorList}
                validations={shipperForm.validations}
                formData={formData}
                onSubmit={onSubmit}
                onChange={onChange}
                readonly={readOnly}
                fieldsPerRow={2}
                showColumnSplitter={false}
                bordered={true}
                ref={shipperRef}
                onBlur={onBlur}
                hideSubmit={true}

            />




        </Modal >


    )
}

export default ShipperFormModal;
