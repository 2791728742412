
import React from 'react';
import { Upload, Icon, Modal, Empty } from 'antd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import _ from 'lodash';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ImageViewer extends React.Component {

    constructor(props) {
        super(props);           
        this.state = {
            photoIndex: 0,
            previewVisible: false,
            previewImage: '',
            fileList: props.fileList || [],
            mode: props.mode || 'ICON',
            setImages: false
        };
    }

    // componentDidMount() {
    //     let { fileList } = this.state;
    //     console.log("file", fileList)
    //     if(fileList.length > 0) {
    //         fileList = fileList.map((fl, index) => {
    //             console.log("fl", fl);
    //             if(_.hasIn(fl, 'imgLink')) {
    //                 fl['url'] = fl['imgLink'];
    //                 fl['name'] = 'LR' + index;
    //                 fl['uid'] = fl['id'];
    //                 fl['status'] = 'done';
    //             };
    //             return fl;
    //         });
    //         this.setState({fileList})
    //     }
    // }

    componentDidUpdate(prevProps) {
        
        if(this.props.fileList && this.props.fileList.length >= 0 && JSON.stringify(prevProps?.fileList) !== JSON.stringify(this.props.fileList)) {
                 
            this.setState({fileList: this.props.fileList, setImages: true})
        }
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        console.log(file)
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState(state => ({
            previewImage: file.url || file.preview,
            previewVisible: true,
            photoIndex: state.fileList.findIndex(f => f.uid === file.uid)
        }));
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    onRemove = (file) => {
        //pass it to parent
        console.log(file)
        return false;
    }
    render() {
        const { mode, previewVisible, previewImage, fileList, photoIndex } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const imageSet = fileList.map(file => file?.url);
        
        return (
            <div className="clearfix">
                {
                    mode === 'GRID'
                        ?
                        <>
                            {fileList && fileList.length > 0 ?
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    onRemove={this.props.onRemoveFile ? this.props?.onRemoveFile : this.onRemove}

                                >
                                    {/* {fileList.length >= 8 ? null : uploadButton} */}
                                </Upload>
                                : <Empty />
                            }
                        </>
                        : fileList.length > 0 ? <><div style={{ cursor: 'pointer' }} onClick={e => this.setState({ photoIndex: 0, previewVisible: true })}> <Icon type="file-image" /> {fileList.length} </div></>
                        : <div> <Icon type="file-image" /> {fileList.length} </div>
                }
                {
                    previewVisible && <Lightbox
                        mainSrc={imageSet[photoIndex]}
                        nextSrc={imageSet[(photoIndex + 1) % imageSet.length]}
                        prevSrc={imageSet[(photoIndex + imageSet.length - 1) % imageSet.length]}
                        onCloseRequest={() => this.setState({ previewVisible: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + imageSet.length - 1) % imageSet.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % imageSet.length,
                            })
                        }
                    />
                }
            </div>
        );
    }
}

export default ImageViewer;