import * as React from 'react';
import L from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet';
import './map-widget.css';
import greenMarker from '../../../assets/img/icons/Location-Pin-Selected.png';
import yellowMarker from '../../../assets/img/icons/Location-Pin.png';
const pinkicon = L.icon({
  iconUrl: greenMarker,
  iconSize: [16, 31],
  iconAnchor: [10, 30],
});

const blueMarker = L.icon({
  iconUrl: yellowMarker,
  iconSize: [16, 31],
  iconAnchor: [10, 30],
});



class Pin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idMarkers: [],
    };
  }
  getTripRoute = (id) => {
    // console.log(id);
    // let markerBox = [...this.state.idMarkers];
    // if(!markerBox.includes(id)){
    //   markerBox.push(id)
      this.props.addDataToDraft(id, this.props.zoomSet);
    // }
    // this.setState({idMarkers : markerBox})
  }

  render() {
    const { position, title, id, markerbox } = this.props;
    return (
      <>
      <Marker
        className=""
        position={position}
        interactive={true}
        keyboard={true}
        icon={markerbox === "blueMarker" ? blueMarker : pinkicon}
        onClick={() => this.getTripRoute(id)}>
      <Tooltip width={150} direction="auto" className="leadPopform">
        <div className="tooltiplsn">
          <div className="tooltipLeads"><b>{title}</b></div>
        </div>
      </Tooltip>
      </Marker>
      </>
    );
  }
}

export default Pin;