import React, { Component } from "react";
import DocketLeft from "./docket-details-left";
import DocketRight from "./docket-details-right";
import { Row, Col, Spin, Modal, Icon, Button, Select, Table, Checkbox, Form, Input, InputNumber, DatePicker } from "antd";
import * as apiUtils from "../../../common/utils/apiGateway";
import _ from "lodash";
import "./trips.scss";
import { openNotification } from "../../ui/notification/Notification";
import { Constants } from "../../../common/utils/constants";
import { notifyApiError, checkAPIHttpStatus, notifyApiSuccess } from "../../../common/utils/utils";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SAVED_ITEMS } from "./column.d";
import moment from 'moment';
import LocationService from '../../../services/settings/location.service';
const locationService = new LocationService;
import * as actions from "../../shared/header/action";
function checkHttpStatus(status) {
  switch (status?.code) {
    case 403:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 401:
      notifyApiError(status?.message, "ERROR:");
      // window.location.href = '/';
      return false;
    case 500:
    case 503:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 200:
    case 201:
    case 202:
      return true;
  }
}

let id = 1;
const { confirm } = Modal;
const { TextArea } = Input;
class VerificationDocketsDetails extends Component {
  state = {
    consignmentId: this.props.location.state?.consignmentId,
    loading: false,
    consignmentData: {},
    shipperDetails: {},
    shipmentDetails: {},
    shipmentBoxes: [],
    shipmentBoxTypes: [],
    otherDetails: {},
    userDetails: {},
    financeDetails: {},
    invoiceData: [],
    consignmentFilterBO: {},


    originalConsignmentData: {},
    originalShipperDetails: {},
    originalShipmentDetails: {},
    originalShipmentBoxes: [],
    originalShipmentBoxTypes: [],
    originalOtherDetails: {},
    originalUserDetails: {},
    originalFinanceDetails: {},
    originalInvoiceData: [],
    originalConsignmentFilterBO: {},

    consignmentVerifications: [],
    originalConsignmentVerifications: [],

    shippersData: [],
    locationData: {},
    destinations: [],
    goodsTypes: [],
    boxTypes: [],

    listingFilters: this.props.location.state?.filters,
    totalCount: this.props.location.state?.totalCount,
    currentPageSize: this.props.location.state?.currentPageSize,
    currentPageNo: this.props.location.state?.currentPageNo,
    totalDockets: this.props.location.state?.totalDockets,
    index: this.props.location.state?.index,
    consignments: this.props.location.state?.consignments,
    maxPage: this.props.location.state?.maxPage,
    leftDisabled: false,
    rightDisabled: false,
    waybillNo: undefined,

    consignmentUpdatedDetails: {},
    savedItems: [],
    showModal: false,
    updateReasons: [],
    invoiceUpdateReasons: [],

    selectedUpdatedReason: undefined,
    updatedApplicableToAll: true,

    showRejectModal: false,
    rejectedDocument: {
      index: undefined,
      documentType: undefined,
      slider: false,
      photoIndex: undefined
    },
    rejectSelectedValue: [],
    rejectComment: undefined,

    showTicketDialog: false,
    fields: {

    },
    ticketDocketProofCheck: false,
    ticketInvoiceProofCheck: false,
    ticketLocations: [],
    selectedRCA: undefined,
    locations: [],
    isFetchingLocation: false,
    customerType: {
      "CUSTOMER": "Customer",
      "ADOHC_CUSTOMER": "Adhoc Customer"
    },
    photoIndex: 0
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.reload();
    this.fetchRejectReasons();
    this.fetchInvoiceRejectedReasons();
    let { consignments, totalDockets } = this.state;

    if (totalDockets <= 1) {
      this.setState({ leftDisabled: true, rightDisabled: true });
      return;
    } else {
      this.setState({ leftDisabled: false, rightDisabled: false });
    }
  }

  reload = () => {
    this.setState({ loading: true, consignmentData: {}, shipperDetails: {} });
    apiUtils.apiRequest({
      url: `/b2b/v1/consignments/${this.state.consignmentId}`,
      method: "GET"
    }).then(res => {
      if (checkHttpStatus(res?.data?.status)) {
        const consignmentData = res?.data?.response?.consignments[0];
        console.log("consignmentData", consignmentData)
        const waybillNo = consignmentData?.waybillNo;
        const originalConsignmentData = res?.data?.response?.consignments[0];
        const shipperDetails = {
          customerType: this.state.customerType[consignmentData?.customer?.customerType],
          name: consignmentData?.customerShipper?.shipperName,
          shipperId: consignmentData?.customerShipper?.id,
          shipperIsBlue: false,
          origin: consignmentData?.customerPickupLoc?.name,
          isMandatory: consignmentData?.customerShipper?.isMandatory,
          // originId: consignmentData?.customerPickupLoc?.address?.pincode?.id,
          originId: consignmentData?.customerPickupLoc?.id,
          originIsBlue: false,
          originCity: consignmentData?.customerPickupLoc?.address?.pincode?.city?.name,
          originPicnode: consignmentData?.customerPickupLoc?.address?.pincode?.zipcode,
          destination: consignmentData?.consignee?.name,
          destinationID: consignmentData?.consignee?.id,
          destinationInBlue: false,
          destinationCity: consignmentData?.consignee?.address?.pincode?.city?.name,
          destinationPincode: consignmentData?.consignee?.address?.pincode?.zipcode,
        }
        const originalShipperDetails = {
          customerType: consignmentData?.shipper?.contactType,
          name: consignmentData?.customerShipper?.shipperName,
          shipperId: consignmentData?.customerShipper?.id,
          origin: consignmentData?.customerPickupLoc?.name,
          isMandatory: consignmentData?.customerShipper?.isMandatory,
          // originId: consignmentData?.customerPickupLoc?.address?.pincode?.id,
          originId: consignmentData?.customerPickupLoc?.id,
          originCity: consignmentData?.customerPickupLoc?.address?.pincode?.city?.name,
          originPicnode: consignmentData?.customerPickupLoc?.address?.pincode?.zipcode,
          destination: consignmentData?.consignee?.name,
          destinationID: consignmentData?.consignee?.id,
          destinationCity: consignmentData?.consignee?.address?.pincode?.city?.name,
          destinationPincode: consignmentData?.consignee?.address?.pincode?.zipcode,
        }
        const shipmentDetails = {
          totalWeight: consignmentData?.totalWeight,
          totalWeightIsBlue: false,
          totalChargableWeight: consignmentData?.totalChargableWeight,
          totalChargableWeightIsBlue: false,
          totalShipmentCount: consignmentData?.totalShipmentCount,
          totalShipmentCountIsBlue: false,
          goodsId: consignmentData?.attributes?.goodsType,
          goodsType: consignmentData?.attributes?.goodsTypeName,
          goodsTypeIsBlue: false,
          allowSelectingEmpty: _.isUndefined(consignmentData?.attributes?.goodsTypeName)
        }
        const originalShipmentDetails = {
          totalWeight: consignmentData?.totalWeight,
          totalChargableWeight: consignmentData?.totalChargableWeight,
          totalShipmentCount: consignmentData?.totalShipmentCount,
          goodsType: consignmentData?.attributes?.goodsTypeName,
          goodsId: consignmentData?.attributes?.goodsType,
        }
        const shipmentBoxes = [
          {
            noOfBoxes: consignmentData?.boxTypes?.length,
            noOfPcs: consignmentData?.totalShipmentCount,
          }
        ];

        const originalShipmentBoxes = [
          {
            noOfBoxes: consignmentData?.boxTypes?.length,
            noOfPcs: consignmentData?.totalShipmentCount,
          }
        ]

        const originalShipmentBoxTypes = consignmentData?.boxTypes;
        const shipmentBoxTypes = consignmentData?.boxTypes;

        let otherDetails = consignmentData?.attributes;
        otherDetails = {
          ...otherDetails,
          shipmentIdentifierIsBlue: false,
          RDSCodeIsBlue: false,
          RDSNameIsBlue: false,
          RDSPinCodeIsBlue: false,
          RDSCityIsBlue: false
        }
        const originalOtherDetails = consignmentData?.attributes;

        let consignmentFilterBO = consignmentData?.consignmentFilterBO;
        consignmentFilterBO = {
          ...consignmentFilterBO,
          threePlReferenceNumberIsBlue: false
        }
        const originalConsignmentFilterBO = consignmentData?.consignmentFilterBO;

        const userDetails = {
          createdBy: consignmentData?.user?.name,
          mobileID: consignmentData?.user?.contactNumber,
          mailID: consignmentData?.user?.email
        }

        const originalUserDetails = {
          createdBy: consignmentData?.user?.name,
          mobileID: consignmentData?.user?.contactNumber,
          mailID: consignmentData?.user?.email
        }

        const financeDetails = {
          paymentType: otherDetails?.paymentType,
          paymentTypeIsBlue: false,
          totalAmount: otherDetails?.totalAmount,
          totalAmountIsBlue: false,
          collectedAmount: otherDetails?.collectedAmount,
          collectedAmountIsBlue: false
        }

        const originalFinanceDetails = {
          paymentType: otherDetails?.paymentType,
          totalAmount: otherDetails?.totalAmount,
          collectedAmount: otherDetails?.collectedAmount
        }



        let consignmentVerifications = consignmentData?.consignmentVerifications;
        consignmentVerifications = consignmentVerifications.map(cv => {
          if (cv?.verificationType === "INVOICE") {
            cv['add_type'] = 'saved';
          }
          return cv;
        })
        const originalConsignmentVerifications = consignmentData?.consignmentVerifications;

        const invoiceLength = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        let ewayCount = 0;
        let invoiceValue = 0;
        invoiceLength.map(inv => {
          if (inv?.ewaybillBO) {
            ewayCount += 1;
          }
          invoiceValue += inv?.invoiceValue;
        })
        const invoiceData = [
          {
            noOfInvoices: invoiceLength.length,
            ewaybills: ewayCount,
            totalInvoiceValue: invoiceValue
          }
        ]

        const originalInvoiceData = [
          {
            noOfInvoices: 1,
            ewaybills: 1,
            totalInvoiceValue: 1
          }
        ]
        this.setState({
          loading: false,
          consignmentData,
          shipperDetails,
          shipmentDetails,
          shipmentBoxTypes,
          shipmentBoxes,
          otherDetails,
          userDetails,
          financeDetails,
          invoiceData,
          shipmentBoxTypes,
          originalConsignmentData,
          originalShipperDetails,
          originalShipmentDetails,
          originalShipmentBoxTypes,
          originalShipmentBoxes,
          originalOtherDetails,
          originalUserDetails,
          originalFinanceDetails,
          originalInvoiceData,
          consignmentVerifications,
          originalConsignmentVerifications,
          waybillNo,
          consignmentFilterBO,
          originalConsignmentFilterBO,
          consignmentUpdatedDetails: {},
          photoIndex: 0
        }, () => {
          this.getBoxTypes();
          this.getDestinations();
          this.getShipperLocations();
        });
      } else {
        const emptyObject = {};
        const consignmentData = shipperDetails = shipmentDetails = otherDetails = userDetails = financeDetails = emptyObject;
        const originalConsignmentData = {},
          originalShipperDetails = {},
          originalShipmentDetails = {},
          originalShipmentBoxes = [],
          originalOtherDetails = {},
          originalUserDetails = {},
          originalFinanceDetails = {},
          originalInvoiceData = [],
          originalConsignmentVerifications = [],
          consignmentVerifications = [];
        this.setState({
          loading: false,
          consignmentData,
          shipperDetails,
          shipmentDetails,
          shipmentBoxes: [],
          otherDetails,
          userDetails,
          financeDetails,
          invoiceData: [],
          shipmentBoxTypes: [],
          originalConsignmentData,
          originalShipperDetails,
          originalShipmentDetails,
          originalShipmentBoxes,
          originalOtherDetails,
          originalUserDetails,
          originalFinanceDetails,
          originalInvoiceData,
          originalConsignmentVerifications,
          consignmentVerifications,
          waybillNo: undefined,
          consignmentFilterBO: {},
          originalConsignmentFilterBO: {}
        });
      }



    });
  };

  notifyCopyEvent = text => {
    openNotification({
      message: "Copied",
      description: `${text} has been copied to clipboard`,
      className: "warning"
    });
  };

  getShippers = () => {
    if (this.state.shippersData.length <= 0) {
      const data = {
        entityType: "CUSTOMER",
        entityId: this.state.originalConsignmentData?.customer?.id
      };
      apiUtils
        .apiRequest({
          url: `/b2b/v1/customer/shipper-mapping/fetch`,
          method: "POST",
          data
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            this.setState({ shippersData: res?.data?.response?.mappingBOs });
          } else {
            this.setState({ shippersData: [] });
          }
        });
    }
  };

  updateShipper = shipper => {
    let { shipperDetails, originalShipperDetails, consignmentUpdatedDetails, updateReasons, shippersData } = this.state;
    let newShipper = shippersData.filter(sh => sh.id == shipper?.key)[0];
    shipperDetails = {
      ...shipperDetails,
      name: shipper?.label,
      shipperId: shipper?.key,
      isMandatory: newShipper?.isMandatory,
      shipperIsBlue: shipper?.key !== originalShipperDetails?.shipperId
    };
    this.setState({ shipperDetails }, () => {
      if (shipperDetails?.shipperIsBlue) {
        consignmentUpdatedDetails['shipper'] = {
          "currentValue": shipperDetails?.name,
          "previousValue": originalShipperDetails?.name,
          'reasonId': updateReasons[0]?.id

        }
      } else {
        delete consignmentUpdatedDetails['shipper'];
      }
      this.setState({ consignmentUpdatedDetails });

    });
  };

  getShipperLocations = () => {
    if (_.isEmpty(this.state.locationData)) {
      const data = {
        filters: {
          roles: ["DELIVERY", "PICKUP"],
          entityTypes: ["CUSTOMER"],
          deviceFilter: { deviceType: "DESKTOP", view: "LITE_LOCATION_LIST" },
          fetchObjects: ["LOCATION_SHORTCODE", "ADDRESS"],
          entityIds: [this.state.originalConsignmentData?.customer?.id],
          status: true
        }
      };
      apiUtils
        .apiRequest({
          url: `/b2b/v1/partners/${Constants.PARTNER_ID}/location-groups/fetch`,
          method: "POST",
          data
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            let { locationData } = this.state;
            let locationsGroupedByRole =
              res?.data?.response?.locationsGroupedByRole;
            locationData = {
              origin: _.hasIn(locationsGroupedByRole, 'PICKUP') ? locationsGroupedByRole["PICKUP"] : []
            };
            this.setState({ locationData });
          } else {
            this.setState({ locationData: {} });
          }
        });
    }
  };

  updateOrigin = origin => {
    let { shipperDetails, originalShipperDetails, consignmentUpdatedDetails, updateReasons } = this.state;

    shipperDetails = {
      ...shipperDetails,
      origin: origin?.label,
      originId: origin?.key,
      originIsBlue: origin?.key !== originalShipperDetails?.originId
    };
    this.setState({ shipperDetails }, () => {
      if (shipperDetails?.originIsBlue) {
        consignmentUpdatedDetails['origin'] = {
          "currentValue": shipperDetails?.origin,
          "previousValue": originalShipperDetails?.origin,
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['origin'];
      }
      this.setState({ consignmentUpdatedDetails });
    });
  };

  getDestinations = () => {
    if (this.state.destinations.length <= 0) {
      const data = {
        filters: {
          customerIds: [this.state.originalConsignmentData?.customer?.id],
          deviceFilter: {
            deviceType: "DESKTOP"
          },
          fetchObjects: [
            "CONTACT_FAV_CONSIGNMENT_CONTACT",
            "ADDRESS",
            "PINCODE",
            "CONSIGNMENT_CONTACT_PINCODE",
            "CONSIGNMENT_CONTACT_ADDRESS",
            "CONTACT_FAV_LOCATION",
            "ADDRESS_PINCODE"
          ],
          status: true
        }
      };
      apiUtils
        .apiRequest({
          url: `/b2b/v1/contacts/favs/fetch`,
          method: "POST",
          data
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            let { destinations } = this.state;
            destinations = res?.data?.response?.consignmentContactFavs;
            this.setState({ destinations });
          } else {
            this.setState({ destinations: [] });
          }
        });
    }
  };

  updateDestination = destination => {

    let { shipperDetails, originalShipperDetails, consignmentUpdatedDetails, updateReasons } = this.state;

    shipperDetails = {
      ...shipperDetails,
      destination: destination?.label,
      destinationID: destination?.key,
      destinationInBlue:
        destination?.key !== originalShipperDetails?.destinationID
    };
    this.setState({ shipperDetails }, () => {
      if (shipperDetails?.destinationInBlue) {
        consignmentUpdatedDetails['destination'] = {
          "currentValue": shipperDetails?.destination,
          "previousValue": originalShipperDetails?.destination,
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['destination'];
      }
      this.setState({ consignmentUpdatedDetails });
    });
  };

  editOtherDetails = (key, value) => {
    let { otherDetails, originalOtherDetails, consignmentUpdatedDetails, updateReasons } = this.state;
    let blueTag = `${key}IsBlue`;
    otherDetails = {
      ...otherDetails,
      [key]: value,
      [blueTag]: otherDetails[key] !== originalOtherDetails[key]
    };
    this.setState({ otherDetails }, () => {
      if (otherDetails?.shipmentIdentifierIsBlue) {
        consignmentUpdatedDetails['shipmentIdentificationCode'] = {
          "currentValue": otherDetails['shipmentIdentifier'],
          "previousValue": originalOtherDetails['shipmentIdentifier'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['shipmentIdentificationCode'];
      }

      if (otherDetails?.RDSCodeIsBlue) {
        consignmentUpdatedDetails['rdsCode'] = {
          "currentValue": otherDetails['RDSCode'],
          "previousValue": originalOtherDetails['RDSCode'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['rdsCode'];
      }

      if (otherDetails?.RDSNameIsBlue) {
        consignmentUpdatedDetails['rdsName'] = {
          "currentValue": otherDetails['RDSName'],
          "previousValue": originalOtherDetails['RDSName'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['rdsName'];
      }

      if (otherDetails?.RDSPinCodeIsBlue) {
        consignmentUpdatedDetails['rdsPincode'] = {
          "currentValue": otherDetails['RDSPinCode'],
          "previousValue": originalOtherDetails['RDSPinCode'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['rdsPincode'];
      }

      if (otherDetails?.RDSCityIsBlue) {
        consignmentUpdatedDetails['rdsCity'] = {
          "currentValue": otherDetails['RDSCity'],
          "previousValue": originalOtherDetails['RDSCity'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['rdsCity'];
      }


      this.setState({ consignmentUpdatedDetails });
    });
  };
  updateThreePL = (key, value) => {
    let { consignmentFilterBO, originalConsignmentFilterBO, consignmentUpdatedDetails, updateReasons } = this.state;
    let blueTag = `${key}IsBlue`;

    if (consignmentFilterBO && _.hasIn(consignmentFilterBO, 'threePlReferenceNumber')) {
      consignmentFilterBO = {
        ...consignmentFilterBO,
        'threePlReferenceNumber': value,
        [blueTag]: originalConsignmentFilterBO && _.hasIn(originalConsignmentFilterBO, 'threePlReferenceNumber') ? consignmentFilterBO['threePlReferenceNumber'] !== originalConsignmentFilterBO['threePlReferenceNumber'] : true
      };
    } else {
      consignmentFilterBO['threePlReferenceNumber'] = value;
      consignmentFilterBO['threePlReferenceNumberIsBlue'] = true;
    }

    this.setState({ consignmentFilterBO }, () => {
      if (consignmentFilterBO?.threePlReferenceNumberIsBlue) {
        consignmentUpdatedDetails['threePl'] = {
          "currentValue": consignmentFilterBO['threePlReferenceNumber'],
          "previousValue": _.hasIn(originalConsignmentFilterBO, 'threePlReferenceNumber') ? originalConsignmentFilterBO['threePlReferenceNumber'] : '',
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['threePl'];
      }



      this.setState({ consignmentUpdatedDetails });
    });
  };

  editShipmentDetails = (key, value) => {
    let {
      shipmentDetails,
      originalShipmentDetails,
      shipmentBoxes,
      consignmentUpdatedDetails,
      updateReasons
    } = this.state;
    let blueTag = `${key}IsBlue`;
    // if(key === 'totalShipmentCount') {
    //     shipmentBoxes[0]['noOfPcs'] = shipmentDetails[key]
    // }
    shipmentDetails = {
      ...shipmentDetails,
      [key]: value,
      [blueTag]: shipmentDetails[key] != originalShipmentDetails[key]
    };
    this.setState({ shipmentDetails, shipmentBoxes }, () => {

      if (shipmentDetails?.totalWeightIsBlue) {
        consignmentUpdatedDetails['actualWeight'] = {
          "currentValue": shipmentDetails['totalWeight'],
          "previousValue": originalShipmentDetails['totalWeight'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['actualWeight'];
      }

      if (shipmentDetails?.totalChargableWeightIsBlue) {
        consignmentUpdatedDetails['chargeableWeight'] = {
          "currentValue": shipmentDetails['totalChargableWeight'],
          "previousValue": originalShipmentDetails['totalChargableWeight'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['chargeableWeight'];
      }

      if (shipmentDetails?.totalShipmentCountIsBlue) {
        consignmentUpdatedDetails['noOfPieces'] = {
          "currentValue": shipmentDetails['totalShipmentCount'],
          "previousValue": originalShipmentDetails['totalShipmentCount'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['noOfPieces'];
      }


      this.setState({ consignmentUpdatedDetails });
    });
  };

  getGoodsTypes = () => {
    if (this.state.goodsTypes.length <= 0) {
      const data = {
        partnerIds: [Constants.PARTNER_ID],
        customerIds: [this.state.originalConsignmentData?.customer?.id],
        isActive: true
      };
      apiUtils
        .apiRequest({
          url: `/b2b/v1/goods-types/get`,
          method: "POST",
          data
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            this.setState({ goodsTypes: res?.data?.response?.goodsTypes });
          } else {
            this.setState({ goodsTypes: [] });
          }
        });
    }
  }
  updateGoodsType = goods => {

    let { updateReasons, shipmentDetails, originalShipmentDetails, consignmentUpdatedDetails } = this.state;

    if (goods?.key === -1) {
      shipmentDetails = {
        ...shipmentDetails,
        goodsType: undefined,
        goodsId: goods?.key,
        goodsTypeIsBlue: false
      };
      this.setState({ shipmentDetails }, () => {
        delete consignmentUpdatedDetails['goodsType'];


        this.setState({ consignmentUpdatedDetails });
      });
    } else {
      shipmentDetails = {
        ...shipmentDetails,
        goodsType: goods?.label,
        goodsId: goods?.key,
        goodsTypeIsBlue: goods?.key != originalShipmentDetails?.goodsId
      };

      this.setState({ shipmentDetails }, () => {
        if (shipmentDetails?.goodsTypeIsBlue) {
          consignmentUpdatedDetails['goodsType'] = {
            "currentValue": shipmentDetails['goodsType'],
            "previousValue": originalShipmentDetails['goodsType'],
            'reasonId': updateReasons[0]?.id
          }
        } else {
          delete consignmentUpdatedDetails['goodsType'];
        }


        this.setState({ consignmentUpdatedDetails });
      });
    }

  };

  getBoxTypes = () => {
    if (this.state.boxTypes.length <= 0) {
      const data = {
        partnerIds: [Constants.PARTNER_ID],
        isAdhocBox: false,
        customerIds: [this.state.originalConsignmentData?.customer?.id],
        isActive: true
      };
      apiUtils
        .apiRequest({
          url: `/b2b/v1/consignments/getBoxTypes`,
          method: "POST",
          data
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            this.setState({ boxTypes: res?.data?.response?.boxTypes });
          } else {
            this.setState({ boxTypes: [] });
          }
        });
    }
  };

  updateBoxTypes = boxTypes => {
    let { originalShipmentBoxes, originalShipmentBoxTypes, consignmentUpdatedDetails, updateReasons, shipment } = this.state;
    let boxNo = boxTypes.length
      ? boxTypes.map(item => item.count).reduce((a, b) => a + b)
      : 0;
    if (shipment?.totalShipmentCount < boxNo) {
      notifyApiError("BoxTypes count can't be greater than shipment", "Error");
      this.setState({ shipmentBoxTypes: originalShipmentBoxTypes });
    } else {
      delete consignmentUpdatedDetails['boxType'];
      this.setState({ shipmentBoxTypes: boxTypes }, () => {
        consignmentUpdatedDetails['boxType'] = {
          "currentValue": boxNo,
          "previousValue": '',
          'reasonId': updateReasons[0]?.id
        }
      });

      this.setState({ consignmentUpdatedDetails });
    }
  };

  editInvoice = index => {
    let { consignmentVerifications } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
    invoicesData[index]['add_type'] = 'add';
    consignmentVerifications = [
      ...docketData,
      ...invoicesData
    ];
    // invoicesData = invoicesData.map(cv => {
    //     if(cv?.verificationType === "INVOICE") {
    //         cv['add_type'] = 'add';
    //     }                    
    //     return cv;
    // })

    this.setState({ consignmentVerifications });
  }

  addInvoice = () => {
    let { consignmentVerifications } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
    let opendInvoices = invoicesData.filter(inv => inv?.add_type === 'add');
    if (opendInvoices && opendInvoices?.length > 0) {
      Modal.error({
        title: 'Please save the open invoices before adding new'
      });

      return;
    }
    invoicesData.push({
      'add_type': 'add',
      'invoiceValue': undefined,
      'invoiceNumber': undefined,
      'verificationType': 'INVOICE',
      consignmentVerificationDocumentMappings: [],
    });

    consignmentVerifications = [
      ...docketData,
      ...invoicesData
    ];
    this.setState({ consignmentVerifications });
  }

  saveInvoice = index => {
    this.setState({ loading: true });
    let { consignmentVerifications, consignmentData } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");

    let newInvoice = invoicesData[index];
    newInvoice.invoiceValue = newInvoice?.invoiceValue ? Number(newInvoice?.invoiceValue) : undefined;

    if (_.isUndefined(newInvoice?.invoiceNumber) || _.isEmpty(newInvoice?.invoiceNumber) || _.isUndefined(newInvoice?.invoiceValue)) {
      Modal.error({
        title: 'Invoice number and Invoice value is mandatory'
      });
      this.setState({ loading: false });
      return;
    }
    if (newInvoice?.invoiceValue >= 50000 && (!newInvoice?.ewaybillBO?.ewaybillNo && !newInvoice?.ewaybillNo)) {

      Modal.error({
        title: 'Ewaybill no is mandatory invoice value >= 50000'
      });
      this.setState({ loading: false });
      return;
    }

    newInvoice = {
      ...newInvoice,

      ...(newInvoice?.ewaybillBO?.ewaybillN || newInvoice?.ewaybillNo) && {
        "ewaybillBO": {
          ewaybillNo: newInvoice?.ewaybillBO?.ewaybillNo ? newInvoice?.ewaybillBO?.ewaybillNo : Number(newInvoice?.ewaybillNo)
        }
      }
    }
    // invoicesData[index]['add_type'] = 'saved';
    delete newInvoice['add_type'];
    delete newInvoice?.ewaybillNo;
    invoicesData[index] = newInvoice;
    delete invoicesData[index]['add_type'];

    const payload = {
      "consignmentId": consignmentData?.id,
      "updateEwaybill": true,
      "consignmentVerifications": [
        ...invoicesData,
        ...docketData
      ]
    }

    this.updateInvoice(payload);

    // invoicesData[index]['add_type'] = 'saved';
    // consignmentVerifications = [
    //   ...docketData,
    //   ...invoicesData
    // ];

    // this.setState({ consignmentVerifications });
  }

  removeInvoice = (index, invNo) => {
    const _this = this;

    confirm({
      title: `Are you sure to delete the invoice ${invNo} ?`,
      onOk: () => {
        _this.setState({ loading: true });
        let { consignmentVerifications } = _this.state;
        let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");


        if (invoicesData[index]?.id) {
          invoicesData.splice(index, 1);
          const payload = {
            "consignmentId": _this.state.consignmentData?.id,
            "consignmentVerifications": [
              ...invoicesData,
              ...docketData
            ]
          }

          this.updateInvoice(payload);
        } else {
          consignmentVerifications = [
            ...docketData,
            ...invoicesData
          ];

          _this.setState({ consignmentVerifications, loading: false });
        }


      }
    })

  }
  rejectInvoiceDocket = () => {
    this.setState({ loading: true });
    let { consignmentVerifications, rejectedDocument, rejectSelectedValue, rejectComment } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
    let invoices = {}, dockets = {};

    if (rejectedDocument?.documentType === 'INVOICE') {
      if (rejectedDocument?.slider) {
        invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
          return {
            id: rIds
          }
        });
        delete invoicesData[rejectedDocument?.index]['add_type'];
        invoices = {
          consignmentVerificationDocumentMappings: invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings']
        }
        this.submitRejectedDocument(invoices);

      } else {
        // invoicesData[rejectedDocument?.index]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
        //   return {
        //     id: rIds
        //   }
        // });       
        // invoicesData[rejectedDocument?.index]['documentStatus'] = 'REJECTED';
        // invoicesData[rejectedDocument?.index]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
        //   return {
        //     id: rIds
        //   }
        // });
        invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'].map(cv => {
          cv.documentStatus = 'REJECTED';
          cv.message = rejectComment;
          cv.rejectedReasons = rejectSelectedValue.map(rIds => {
            return {
              id: rIds
            }
          });
          return cv;
        })
      }
      delete invoicesData[rejectedDocument?.index]['add_type'];
      invoices = {
        consignmentVerificationDocumentMappings: invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings']
      }
      this.submitRejectedDocument(invoices);
    } else {
      docketData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['documentStatus'] = 'REJECTED';
      docketData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['message'] = rejectComment;
      docketData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
        return {
          id: rIds
        }
      });
      dockets = {
        consignmentVerificationDocumentMappings: docketData[rejectedDocument?.index]['consignmentVerificationDocumentMappings']
      }

      this.submitRejectedDocument(dockets);
    }


  }

  submitRejectedDocument = data => {

    apiUtils.apiRequest({
      url: `/b2b/v1/consignmentVerifications/verifyDocument`,
      method: "POST",
      data
    }).then(res => {
      if (checkHttpStatus(res?.data?.status)) {
        this.setState({ loading: false });
        this.cancelRejectModal();
        this.reload();
      } else {
        this.setState({ loading: false });
      }
    });
  }

  openRejectModal = (index, documentType, slider, photoIndex = undefined) => {
    let { rejectedDocument } = this.state;
    rejectedDocument = {
      index,
      documentType,
      slider,
      photoIndex
    }

    this.setState({ showRejectModal: true, rejectedDocument });

  }

  cancelRejectModal = () => {
    let { rejectedDocument } = this.state;
    rejectedDocument = {
      index: undefined,
      documentType: undefined,
      slider: false,
      photoIndex: undefined
    }
    this.setState({ showRejectModal: false, rejectedDocument, rejectSelectedValue: [], rejectComment: undefined });
  }

  updateRejectReason = values => {
    this.setState({ rejectSelectedValue: values })
  }

  changeRejectComments = e => {
    this.setState({ rejectComment: e.target.value })
  }

  updateInvoice = payload => {
    apiUtils
      .apiRequest({
        url: `/b2b/v1/consignmentVerifications/update`,
        method: "POST",
        data: payload
      })
      .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
          this.setState({ loading: false });
          this.reload();
        } else {
          this.setState({ loading: false });
        }
      })


  }

  onChangeInvoice = (index, key, value) => {
    let { consignmentVerifications } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");

    invoicesData[index][key] = value;
    if (key === "ewaybillNo" && (value === "" || _.isEmpty(value))) {
      delete invoicesData[index]['ewaybillBO'];
    }
    consignmentVerifications = [
      ...docketData,
      ...invoicesData
    ];
    this.setState({ consignmentVerifications });
  }

  onChangeEway = (index, value) => {
    let { consignmentVerifications } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");

    invoicesData[index]['ewaybillBO'] = {
      ewaybillNo: value
    };

    // invoicesData[index][eval(key)] = value;
    if (value === "") {
      delete invoicesData[index]['ewaybillBO'];
    }
    // console.log("invoices", value === "" || _.isEmpty(value))
    consignmentVerifications = [
      ...docketData,
      ...invoicesData
    ];
    this.setState({ consignmentVerifications });
  }

  DocketUpload = (index, key, file) => {
    let { consignmentVerifications } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
    if (docketData.length > 0) {
      let docketImages = docketData[index][key];
      docketImages = [
        ...docketImages,
        {
          "s3Url": file
        }
      ];
      docketData[index][key] = docketImages;
    } else {
      docketData = [
        {
          "verificationType": "DOCKET",
          "consignmentVerificationDocumentMappings": [
            {
              "s3Url": file
            }
          ]
        }
      ]
    }

    consignmentVerifications = [
      ...docketData,
      ...invoicesData
    ];
    this.setState({ consignmentVerifications }, () => {
      let { consignmentVerifications, consignmentData } = this.state;
      let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
      let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
      const payload = {
        "consignmentId": consignmentData?.id,
        "consignmentVerifications": [
          ...invoicesData,
          ...docketData
        ]
      }

      this.updateInvoice(payload);
    });
  }
  RemoveDocket = (index, key, fileIndex) => {
    const _this = this;
    confirm({
      title: 'Are you sure to delete this proof?',
      onOk: () => {
        let { consignmentVerifications } = _this.state;
        let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
        if (docketData.length > 0) {
          let docketImages = docketData[index][key];

          docketData[index][key] = docketImages?.filter((item, index) => (index !== fileIndex));;
        }

        consignmentVerifications = [
          ...docketData,
          ...invoicesData
        ];
        _this.setState({ consignmentVerifications }, () => {
          let { consignmentVerifications, consignmentData } = _this.state;
          let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
          let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
          const payload = {
            "consignmentId": consignmentData?.id,
            "consignmentVerifications": [
              ...invoicesData,
              ...docketData
            ]
          }

          _this.updateInvoice(payload);
        });
      }
    })

  }

  InvoiceUpload = (index, key, file) => {
    let { consignmentVerifications } = this.state;
    let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
    let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
    let invoiceImages = invoicesData[index][key];
    invoiceImages = [
      ...invoiceImages,
      {
        's3Url': file
      }
    ];
    invoicesData[index][key] = invoiceImages;

    consignmentVerifications = [
      ...docketData,
      ...invoicesData
    ];
    this.setState({ consignmentVerifications });
  }

  gotoInvoiceDetails = (index, photoIndex) => {
    let { consignmentVerifications, consignmentData } = this.state;


    let invoicesData = consignmentVerifications.filter(cv => cv?.verificationType === "INVOICE");
    if (!invoicesData[index]?.id || !invoicesData[index]['consignmentVerificationDocumentMappings'][photoIndex]?.id) {
      Modal.error({
        title: "Please Save the data!!"
      });
      return;
    }
    let isValid = true;
    invoicesData[index]['consignmentVerificationDocumentMappings'].map(img => {
      if (!img?.id) {
        isValid = false;
      }
    });

    if (!isValid) {
      Modal.error({
        title: "Please Save the data!!"
      });
      return;
    }

    this.props.history.push(
      '/appv2/verification/form/invoice',
      {
        currentIndex: index,
        photoIndex, invoicesData,
        consignmentDataId: consignmentData?.id,
        consignmentVerifications,
        consignmentId: consignmentData?.id,
        updateReasons: this.state.invoiceUpdateReasons,
        status: consignmentData?.consignmentFilterBO?.verificationStatus,

        filters: this.state.listingFilters,
        totalCount: this.state.totalCount,
        currentPageSize: this.state?.currentPageSize,
        currentPageNo: this.state?.currentPageNo,
        totalDockets: this.state?.totalDockets,
        index: this.state?.index,
        consignments: this.state?.consignments,
        maxPage: this.state?.maxPage
      }
    );
  }

  goBack = () => {
    // this.props.history.goBack();
    this.props.history.push('/appv2/verification/dashboard/docket')
  }

  getListingData = (pageNo, pageSize) => {
    let { index, consignmentId, photoIndex, waybillNo } = this.state;
    let { listingFilters } = this.state;
    let { filter } = listingFilters;
    listingFilters = {
      ...listingFilters,
      filter: {
        ...filter,
        pageNo,
        pageSize
      }
    }
    this.setState({ loading: true, listingFilters });
    apiUtils.apiRequest({
      url: `/b2b/v1/consignments/list`,
      data: listingFilters,
      method: "POST"
    })
      .then(response => {

        if (checkAPIHttpStatus(response?.data?.status)) {

          let { docketData } = this.state;
          docketData = response?.data?.response?.consignments;
          photoIndex = 0;
          index = 0;
          consignmentId = docketData[index]?.id;
          waybillNo = docketData[index]?.waybillNo;
          this.setState({ consignments: docketData, loading: false, photoIndex, index, consignmentId, currentPageNo: pageNo, waybillNo }, () => {
            this.reload();
          })
        } else {
          this.setState({ loading: false })
        }

      });
  }


  navigate = type => {
    let { index, consignments, currentPageNo, currentPageSize, consignmentId, photoIndex, totalDockets, maxPage, waybillNo } = this.state;
    if (totalDockets <= 1) {
      this.setState({ leftDisabled: true, rightDisabled: true });
      return;
    } else {
      this.setState({ leftDisabled: false, rightDisabled: false });
    }


    if (type === 'left') {
      index -= 1;

      if (index < 0 && currentPageNo === 1) {
        this.setState({ leftDisabled: true });
        return;
      }
      if (index < 0 && currentPageNo > 1) {
        this.getListingData(currentPageNo - 1, currentPageSize);
        return;
      }

      photoIndex = 0;

      consignmentId = consignments[index]?.id;
      waybillNo = consignments[index]?.waybillNo;

      this.setState({ index, consignmentId, photoIndex, waybillNo }, () => {
        this.reload();
      });

    } else {
      index += 1;
      if (maxPage === currentPageNo && index === consignments?.length) {
        this.setState({ rightDisabled: true });
        return;
      }
      if (index >= consignments?.length) {
        this.getListingData(currentPageNo + 1, currentPageSize);
        return;
      }
      photoIndex = 0;
      consignmentId = consignments[index]?.id;
      waybillNo = consignments[index]?.waybillNo;
      this.setState({ index, consignmentId, photoIndex, waybillNo }, () => {
        this.reload();
      });

    }
  }

  editfinanceDetails = (key, value) => {

    let {
      financeDetails,
      originalFinanceDetails,
      consignmentUpdatedDetails,
      updateReasons
    } = this.state;
    let blueTag = `${key}IsBlue`;

    // if(key === 'totalfinanceCount') {
    //     financeBoxes[0]['noOfPcs'] = financeDetails[key]
    // }
    financeDetails = {
      ...financeDetails,
      [key]: value,
      [blueTag]: value != originalFinanceDetails[key]
    };


    if (financeDetails?.paymentType === "TO_BE_BILLED") {
      if (originalFinanceDetails.paymentType === 'PAID') {
        delete financeDetails?.totalAmount;
        financeDetails['totalAmountIsBlue'] = false;

      }
      if (originalFinanceDetails.paymentType === "TO_PAY" || originalFinanceDetails.paymentType === "TOPAY") {
        delete financeDetails?.totalAmount;
        delete financeDetails?.collectedAmount;
        financeDetails['totalAmountIsBlue'] = false;
        financeDetails['collectedAmountIsBlue'] = false;

      }
      if (originalFinanceDetails.paymentType === 'TO_BE_BILLED') {
        delete financeDetails?.collectedAmount;
        delete financeDetails?.totalAmount;
        financeDetails['totalAmountIsBlue'] = false;
        financeDetails['collectedAmountIsBlue'] = false;
      }
    }
    if (financeDetails?.paymentType === "PAID") {
      if (originalFinanceDetails.paymentType === "TO_PAY" || originalFinanceDetails.paymentType === "TOPAY") {
        delete financeDetails?.collectedAmount;
        financeDetails['collectedAmountIsBlue'] = false;
      }
      if (originalFinanceDetails.paymentType === 'PAID') {
        delete financeDetails?.collectedAmount;
        financeDetails['collectedAmountIsBlue'] = false;
      }
    }
    this.setState({ financeDetails }, () => {

      if (financeDetails?.paymentTypeIsBlue) {
        consignmentUpdatedDetails['paymentType'] = {
          "currentValue": financeDetails['paymentType'],
          "previousValue": originalFinanceDetails['paymentType'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['paymentType'];
      }

      if (financeDetails?.totalAmountIsBlue) {
        consignmentUpdatedDetails['totalAmount'] = {
          "currentValue": financeDetails['totalAmount'],
          "previousValue": originalFinanceDetails['totalAmount'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['totalAmount'];
      }

      if (financeDetails?.collectedAmountIsBlue) {
        consignmentUpdatedDetails['amountCollected'] = {
          "currentValue": financeDetails['collectedAmount'],
          "previousValue": originalFinanceDetails['collectedAmount'],
          'reasonId': updateReasons[0]?.id
        }
      } else {
        delete consignmentUpdatedDetails['amountCollected'];
      }


      this.setState({ consignmentUpdatedDetails });
    });
  };

  validateBeforeSaveDetails = () => {
    let { consignmentData, consignmentUpdatedDetails, savedItems, updateReasons, financeDetails } = this.state;
    if (financeDetails?.paymentType === 'PAID' && (Number(financeDetails?.totalAmount) <= 0 || _.isUndefined(financeDetails?.totalAmount))) {
      Modal.error({
        title: 'Freight Amount is Mandatory for Paid Payment Type'
      });

      return;
    }
    if ((financeDetails?.paymentType === 'TOPAY' || financeDetails?.paymentType === 'TO_PAY') && ((Number(financeDetails?.totalAmount) <= 0 || Number(financeDetails?.collectedAmount) <= 0) || _.isUndefined(financeDetails?.collectedAmount) || _.isUndefined(financeDetails?.collectedAmount))) {
      Modal.error({
        title: 'Freight Amount and Advance Amount is Mandatory for To Pay Payment Type'
      });
      return;
    }
    if (consignmentData?.movementType === 'PTL') {

      let p1 = this.validatePTLBeforeSaveDetails();
      let isCheck = false;
      p1.then(res => {
        isCheck = res;
        if (isCheck) {
          const updatedKeys = Object.keys(consignmentUpdatedDetails);
          if (updatedKeys.length > 0) {
            savedItems = [];
            updatedKeys.forEach((item) => {

              savedItems.push({
                'details': SAVED_ITEMS[item],
                'key': item,
                'previousValue': consignmentUpdatedDetails[item]?.previousValue,
                'currentValue': consignmentUpdatedDetails[item]?.currentValue,
                'reasonId': consignmentUpdatedDetails[item]?.reasonId,
              })
            });

            this.setState({ savedItems, showModal: true, selectedUpdatedReason: updateReasons[0]?.id })
          } else {
            Modal.confirm({
              title: 'No changes made!!'
            })
          }

        }
      })



    } else {
      let isCheck = this.validateFTLBeforeSaveDetails();
      if (isCheck) {
        const updatedKeys = Object.keys(consignmentUpdatedDetails);
        if (updatedKeys.length > 0) {
          savedItems = [];
          updatedKeys.forEach((item) => {

            savedItems.push({
              'details': SAVED_ITEMS[item],
              'key': item,
              'previousValue': consignmentUpdatedDetails[item]?.previousValue,
              'currentValue': consignmentUpdatedDetails[item]?.currentValue,
              'reasonId': consignmentUpdatedDetails[item]?.reasonId,
            })
          });

          this.setState({ savedItems, showModal: true, selectedUpdatedReason: updateReasons[0]?.id })
        } else {
          Modal.confirm({
            title: 'No changes made!!'
          })
        }
      }

    }


  }

  validatePTLBeforeSaveDetails = () => {

    let promise = new Promise(resolve => {
      let { shipperDetails, consignmentData, shipmentDetails, shipmentBoxTypes, locationData, destinations } = this.state;
      let { customerId } = consignmentData;
      let { originId, shipperId, destinationID } = shipperDetails;

      let origin = locationData?.origin.filter(loc => loc?.id === originId)[0]?.address?.pincode?.id;
      let destination = destinations.filter(loc => loc?.consignmentContactBO?.id === destinationID)[0]?.consignmentContactBO?.address?.pincode?.id;
      // console.log("locations", origin, destination)
      if (shipperDetails?.isMandatory) {
        const params = {
          "customerShipperId": shipperId,
          "originPincodeId": origin,
          "customerId": customerId,
          "destinationPincodeId": destination,
        }
        apiUtils.apiRequest({
          method: 'POST',
          url: `/b2b/v1/rateCard/getRateCardMandatoryField`,
          data: params
        }).then(response => {

          if (response?.data?.response?.isRateCardAvailable) {
            let mandatoryFields = response?.data?.response?.mandatoryFields;
            if (mandatoryFields.includes('GOODS_TYPE')) {
              if (_.isUndefined(shipmentDetails?.goodsType) || _.isUndefined(shipmentDetails?.goodsId)) {
                Modal.error({
                  title: 'Action Blocked',
                  content: 'goods type is mandatory',
                });
                resolve(false);
              }
            }
            if (mandatoryFields.includes('GOODSTYPE_AND_DIMENSIONS')) {
              if (_.isUndefined(shipmentDetails?.goodsType) || _.isUndefined(shipmentDetails?.goodsId) || shipmentBoxTypes?.length <= 0) {
                Modal.error({

                  title: 'Action Blocked',
                  content: 'goods type  and box type is mandatory',
                });
                resolve(false);
              }
            }
            if (mandatoryFields.includes('DIMENSIONS')) {
              if (shipmentBoxTypes?.length <= 0) {
                Modal.error({

                  title: 'Action Blocked',
                  content: 'Box type is mandatory',
                });
                resolve(false);
              }
            }


            let count = shipmentBoxTypes.length
              ? shipmentBoxTypes.map(item => item.count).reduce((a, b) => a + b)
              : 0;

            if (shipmentDetails?.totalShipmentCount < count) {
              Modal.error({
                title: 'Action Blocked',
                content: "BoxTypes count can't be greater than shipment",
              });

              resolve(false);
            }


            resolve(true);

          } else {
            Modal.error({
              title: 'Action Blocked',
              content: 'No rate cards available for the selected Customer/ Shipper&Origin / Destination',
            });
            resolve(false);
          }


        });

      } else {
        resolve(true);
      }

    });

    return promise;




    // return true;

  }

  validateFTLBeforeSaveDetails = () => {
    return true;

  }

  cancelUpdateModal = () => {
    this.setState({ showModal: false, savedItems: [] })
  }

  changeUpdatedReason = (e, row) => {
    let { savedItems, consignmentUpdatedDetails } = this.state;
    savedItems.map(item => {
      if (item.details === row.details) {
        item.reasonId = e;

        consignmentUpdatedDetails[item.key]['reasonId'] = e;
      }
      return item;
    });



    this.setState({ savedItems, consignmentUpdatedDetails });
  }

  changeApplicableToAll = e => {
    let { savedItems, selectedUpdatedReason } = this.state;
    if (e.target.checked) {

      savedItems.map(item => {

        item.reasonId = selectedUpdatedReason;

        return item;
      });
    }
    this.setState({ updatedApplicableToAll: e.target.checked, savedItems })
  }

  updateMainReason = (e) => {
    let { savedItems } = this.state;
    savedItems.map(item => {

      item.reasonId = e;

      return item;
    });

    this.setState({ savedItems, selectedUpdatedReason: e });
  }

  saveConsignment = () => {
    // console.log("this", this.state.destinations)
    this.setState({ loading: true });
    let { consignmentData, shipperDetails, shipmentBoxTypes, shipmentDetails, originalShipmentBoxTypes, financeDetails, otherDetails, consignmentUpdatedDetails, consignmentFilterBO, originalShipmentDetails } = this.state;


    let shipmentCount = shipmentBoxTypes.length
      ? shipmentBoxTypes.map(item => item.count).reduce((a, b) => a + b)
      : 0;
    let originalShipmentCount = originalShipmentBoxTypes.length
      ? originalShipmentBoxTypes.map(item => item.count).reduce((a, b) => a + b)
      : 0;



    const payload = {
      request: [
        {
          consignment: {
            id: consignmentData?.id.toString(),
            consignee: this.state.destinations.filter(list => list.consignmentContactBO.id === shipperDetails['destinationID'])[0]['consignmentContactBO'],
            consignmentVerifications: consignmentData?.consignmentVerifications,
            customerPickupLoc: {
              id: shipperDetails?.originId
            },
            boxTypes: shipmentBoxTypes,
            totalChargableWeight: Number(shipmentDetails['totalChargableWeight']),
            // goodsType: shipmentDetails['goodsType'],
            totalShipmentCount: Number(shipmentDetails['totalShipmentCount']),
            totalWeight: Number(shipmentDetails['totalWeight']),
            // pickupDate: consignmentData?.pickupDate,
            // bookingDate: consignmentData?.bookingDate,
            attributes: {
              consignmentFlowType: consignmentData?.flowType,
              goodsTypeName: shipmentDetails['goodsType'],
              ignoreBarcodeCreation: _.hasIn(consignmentData['attributes'], 'ignoreBarcodeCreation') ? consignmentData['attributes']['ignoreBarcodeCreation'] : false, // Helped by @dharma and @shreyas
              // invoiceNumber: _formData['InvoiceNo'],
              // invoiceAmount: _formData['InvoiceValue'],
              // invoice: _formData['InvoiceNo'] ? true : false,
              goodsType: shipmentDetails['goodsId'],
              paymentType: financeDetails['paymentType'],
              totalAmount: financeDetails['totalAmount'],
              collectedAmount: financeDetails['collectedAmount'],
              "RDSPinCode": otherDetails['RDSPinCode'],
              "RDSName": otherDetails['RDSName'],
              "RDSCode": otherDetails['RDSCode'],
              "RDSCity": otherDetails['RDSCity'],
              'shipmentIdentifier': otherDetails['shipmentIdentifier']
              // "manifest": _formData['checkList'].filter(c => c === 'Manifest').length > 0,
              // "docket": _formData['checkList'].filter(c => c === 'Docket').length > 0,
              // "invoice": _formData['checkList'].filter(c => c === 'Invoice').length > 0,
            },
            bookingOfficeLoc: {
              id: consignmentData['bookingOfficeLoc']['id'].toString()
            },
            waybillNo: consignmentData['waybillNo'],
            customer: {
              id: consignmentData?.customer?.id
            },
            ..._.hasIn(consignmentFilterBO, 'threePlReferenceNumber') && {
              "consignmentFilterBO": {
                "threePlReferenceNumber": consignmentFilterBO['threePlReferenceNumber']
              }
            },
            "customerShipper": {
              "id": shipperDetails['shipperId']
            },
            // ignoreBarcodeCreation: shipmentCount === originalShipmentCount ? (Number(shipmentDetails?.totalShipmentCount) === Number(originalShipmentDetails?.totalShipmentCount) ? true : false) : false,
            ignoreBarcodeCreation: _.hasIn(consignmentData['attributes'], 'ignoreBarcodeCreation') ? consignmentData['attributes']['ignoreBarcodeCreation'] : false, // Helped by @dharma and @shreyas
            "movementType": consignmentData['movementType'],
            "partnerId": localStorage.getItem('loggedPartnerId'),
          },
          referenceId: Date.now(),
          consignmentUpdatedDetails,
          isLiteRequest: true,
          // allowInscan: false,
          action: "UPDATE"
        }
      ]
    };


    apiUtils.apiRequest({
      method: 'post',
      url: `/b2b/v1/consignments/lite/update`,
      data: payload
    })
      .then(response => {

        if (checkHttpStatus(response.data?.status)) {
          this.setState({ loading: false });
          if (response.data?.response?.responses[0].sync) {

            notifyApiSuccess('Booking Successfully Updated.', 'SUCCESS');
            this.reload();
            this.cancelUpdateModal()
          } else {
            notifyApiError(response.data?.response?.responses[0].reason, 'FAILED')
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }

      })



  }

  fetchRejectReasons = () => {
    apiUtils.apiRequest({
      method: 'GET',
      url: `/b2b/v1/consignmentVerifications/rejectedReasons/DOCKET`,
    })
      .then(response => {
        if (checkHttpStatus(response.data?.status)) {
          let { updateReasons } = this.state;
          updateReasons = response.data?.response?.consignmentVerificationRejectedReasonsList;
          this.setState({ updateReasons });
        }
      })
  }

  fetchInvoiceRejectedReasons = () => {
    apiUtils.apiRequest({
      method: 'GET',
      url: `/b2b/v1/consignmentVerifications/rejectedReasons/IMAGE`,
    })
      .then(response => {
        if (checkHttpStatus(response.data?.status)) {
          let { invoiceUpdateReasons } = this.state;
          invoiceUpdateReasons = response.data?.response?.consignmentVerificationRejectedReasonsList;
          this.setState({ invoiceUpdateReasons });
        }
      })
  }

  openCreateTickeDialog = () => {
    this.setState({ showTicketDialog: true, locations: [] }, () => {
      const { configurations } = this.props;
      let { ticketLocations, destinations } = this.state;
      const { zendeskCreateTicketConfig } = configurations;

      const fieldMappings = zendeskCreateTicketConfig.map(field => (
        {
          id: field?.id,
          value: field?.userMistakeRca
        }
      ));
      console.log("Destina", destinations)
      ticketLocations = destinations.map(loc => (

        {
          label: `${loc?.consignmentContactBO?.name} (${loc?.contactCode})`,
          value: loc?.favLocationId,
        }
      ))

      this.setState({ fieldMappings, ticketLocations }, () => {
        this.renderTicketFields(fieldMappings[0]?.id)
      })

    });
  }

  changeDeliveryStatus = value => {
    let { fields } = this.state;
    let { deliveryDate } = fields;
    fields = {
      ...fields,
      "deliveryDate": {
        ...deliveryDate,
        required: value === 'DELIVERED'
      }
    }
    this.setState({ fields });
  }

  renderTicketFields = (id) => {
    let { fields, ticketLocations } = this.state;
    const { configurations, form } = this.props;
    const { zendeskCreateTicketConfig } = configurations;
    form.resetFields();
    fields = [];
    zendeskCreateTicketConfig.map(field => {

      if (field?.id === id) {
        if (field?.actualLocationId === true || field?.actualLocationId === "true") {
          fields = {
            ...fields,
            "actualLocationId": {
              label: "Actual Location",
              value: undefined,
              type: "select",
              values: ticketLocations,
              placeholder: "Select Actual Location",
              order: 1
            }
          }
        } else {
          delete fields['actualLocationId'];
        }
        // if(field?.actualCity || field?.actualCity === "true") {
        //   fields = {
        //     ...fields,
        //     "actualCity": {
        //       label: "Actual City",
        //       value: undefined
        //     }
        //   }
        // }
        if (field?.actualPincode === true || field?.actualPincode === "true") {
          fields = {
            ...fields,
            "actualPincode": {
              label: "Actual Pincode",
              value: undefined,
              type: "number",
              placeholder: "Enter Actual Picode",
              order: 2
            }
          }
        } else {
          delete fields['actualPincode'];
        }
        if (field?.actualTripIdList === true || field?.actualTripIdList === "true" || field?.actualTripIdList === "true_multiple") {
          fields = {
            ...fields,
            "actualTripIdList": {
              label: "Actual Trip",
              value: undefined,
              type: "number",
              placeholder: "Enter Actual Trip",
              order: 3
            }
          }
        } else {
          delete fields['actualTripIdList'];
        }
        if (field?.deliveryStatus === true || field?.deliveryStatus === "true") {
          fields = {
            ...fields,
            "deliveryStatus": {
              label: "Delivery Status",
              value: undefined,
              order: 4,
              type: "select",
              onChange: (value) => this.changeDeliveryStatus(value),
              placeholder: "Select Delivery Status",
              values: [{
                "value": "DELIVERED",
                "label": "Delivered"
              }, {
                "value": "PENDING",
                "label": "Pending"
              }]
            }
          }
        } else {
          delete fields['deliveryStatus'];
        }
        if (field?.deliveryDate === true || field?.deliveryDate === "true") {
          fields = {
            ...fields,
            "deliveryDate": {
              label: "Delivery Date",
              value: undefined,
              type: "date",
              placeholder: "Enter Delivery Date",
              required: true,
              order: 5
            }
          }
        } else {
          delete fields['deliveryDate'];
        }


        if (field.tripIdTransitLocation === "true_multiple") {
          fields = {
            ...fields,
            "tripIdTransitLocation": {

              order: 6,
              type: "select_multiple",


            }
          }
        } else {
          delete fields['tripIdTransitLocation'];
        }
      }


    });
    fields = {
      ...fields,
      "remarks": {
        label: "Remarks",
        value: undefined,
        type: "textarea",
        placeholder: "Enter Remarks",
        order: 10
      }
    }


    // fields = objs.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.last_nom) ? -1 : 0))
    // [].concat(Object.keys(fields))
    //   .sort((a, b) => a.order < b.order ? 1 : -1);

    this.setState({
      fields,
      selectedRCA: id
    })
  }

  cancelCreateDialog = () => {
    this.setState({ showTicketDialog: false });
  }

  changeFieldValue = (value, field) => {
    let { fields } = this.state;
    fields[field] = {
      ...fields[field],
      "value": value
    }
    this.setState({ fields })
  }

  changeProof = (value, field) => {
    this.setState({
      [field]: value
    })
  }

  createTicket = () => {
    const { fields, fieldMappings, selectedRCA, consignmentId } = this.state;
    const { zendeskCreateTicketConfig } = this.props.configurations;
    const _this = this;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const keys = Object.keys(fields);
      _this.setState({ loading: true });
      let payload = {};
      keys.map(k => {
        payload = {
          ...payload,
          [k]: values[k]
        }
      })

      delete payload['tripIdTransitLocation'];
      payload['consignmentId'] = consignmentId;
      payload['tripTransitLocations'] = _.compact(values['tripTransitLocations']);
      payload['deliveryDate'] = moment(payload['deliveryDate']).format('YYYY-MM-DD');
      payload['attachDocketProof'] = values['attachDocketProof'];
      payload['attachInvoiceProof'] = values['attachInvoiceProof'];
      payload['userMistakeRCA'] = zendeskCreateTicketConfig[selectedRCA].userMistakeRca;
      payload['ticketCategory'] = zendeskCreateTicketConfig[selectedRCA].ticketCategory;
      payload['ticketReason'] = zendeskCreateTicketConfig[selectedRCA].ticketReason;
      // console.log("payload", payload)
      // _this.setState({ loading: false });

      apiUtils.apiRequest({
        method: 'POST',
        url: `/b2b/v1/zendesk/create`,
        data: payload
      })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            Modal.success({
              title:
                <>
                  Ticket Created Successfully! <br />
                Ticket ID: <b>{res?.data?.response?.ticketId}</b>
                  <br />
                Ticket Status: <b>{res?.data?.response?.ticketStatus}</b>
                </>,
              onOk: () => {
                _this.setState({ loading: false });
                _this.cancelCreateDialog();
                _this.reload();
              }
            })

          } else {
            this.setState({ loading: false });
          }
        })
    });

  }

  verifyDocket = () => {
    const _this = this;
    Modal.confirm({
      title: 'Are you sure you want to verify docket?',
      onOk: () => {
        const { consignmentVerifications } = _this.state;

        _this.setState({ loading: true });
        let docket = consignmentVerifications?.filter(cv => cv?.verificationType === 'DOCKET');
        if (docket.length > 0 && _.hasIn(docket[0], 'consignmentVerificationDocumentMappings')) {
          let consignmentVerificationDocumentMappings = docket[0]?.consignmentVerificationDocumentMappings;
          consignmentVerificationDocumentMappings.map(dc => {

            dc.documentStatus = "VERIFIED";
            return dc;
          })

          const data = {
            "consignmentVerificationDocumentMappings": consignmentVerificationDocumentMappings
          }



          apiUtils.apiRequest({
            url: `/b2b/v1/consignmentVerifications/verifyDocument`,
            method: "POST",
            data
          }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
              Modal.success({
                title: 'Docket Verified Successfully!',
                onOk: () => {
                  _this.setState({ loading: false });
                  _this.reload();
                }
              })
            } else {
              this.setState({ loading: false });
            }
          });
        } else {
          Modal.error({
            title: 'Upload docket Image to verify docket!'
          });
          this.setState({ loading: false });
          return;
        }

      }
    })

  }


  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const key = keys.filter(key => key.id !== keys[k].id);
    form.setFieldsValue({
      keys: key,
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat({ id: ++id });
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  fetchLocations = (value) => {
    const that = this
    this.setState({
      isFetchingLocation: true
    }, () => {
      locationService.getLocationsFetch({
        "filters": {
          status: true,
          fetchObjects: [
          ],
          entityTypes: [
            "PARTNER"
          ],
          partialContentSearch: value
        }
      }).then(resp => {
        if (resp.data) {
          that.setState({
            locations: resp.data?.response?.locations || [],
            isFetchingLocation: false
          })
        }
      })
    })

  }

  render() {
    const { loading, consignmentData, shipperDetails, goodsTypes, shipmentBoxTypes, boxTypes, waybillNo,
      shipmentDetails, shipmentBoxes, otherDetails, locationData, destinations, index, consignments, consignmentId, locations, isFetchingLocation,
      userDetails, financeDetails, invoiceData, shippersData, consignmentVerifications, consignmentFilterBO, fields } = this.state;
    const { configurations } = this.props;

    const { zendeskCreateTicketConfig } = configurations;

    const rightBoardEvents = {
      notifyCopyEvent: this.notifyCopyEvent,
      getShippers: this.getShippers,
      updateShipper: this.updateShipper,
      updateOrigin: this.updateOrigin,
      getShipperLocations: this.getShipperLocations,
      updateDestination: this.updateDestination,
      getDestinations: this.getDestinations,
      editOtherDetails: this.editOtherDetails,
      updateThreePL: this.updateThreePL,
      editShipmentDetails: this.editShipmentDetails,
      getGoodsTypes: this.getGoodsTypes,
      updateGoodsType: this.updateGoodsType,
      getBoxTypes: this.getBoxTypes,
      getOriginalShipmentBoxes: () => (shipmentBoxes),
      updateBoxTypes: this.updateBoxTypes,
      editfinanceDetails: this.editfinanceDetails,
      getOriginalFinanceDetails: () => (this.state.originalFinanceDetails),
      validateBeforeSaveDetails: this.validateBeforeSaveDetails,
      openCreateTickeDialog: this.openCreateTickeDialog
    }

    const leftBoardEvent = {
      editInvoice: this.editInvoice,
      saveInvoice: this.saveInvoice,
      onChangeInvoice: this.onChangeInvoice,
      onChangeEway: this.onChangeEway,
      addInvoice: this.addInvoice,
      InvoiceUpload: this.InvoiceUpload,
      removeInvoice: this.removeInvoice,
      gotoInvoiceDetails: this.gotoInvoiceDetails,
      DocketUpload: this.DocketUpload,
      RemoveDocket: this.RemoveDocket,
      updateInvoice: this.updateInvoice,
      openRejectModal: this.openRejectModal
    }


    const updatedColumns = [
      {

        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        width: '25%'
      },
      {
        title: 'Old Value',
        dataIndex: 'previousValue',
        key: 'previousValue',
        width: '25%'
      },
      {
        title: 'New Value',
        dataIndex: 'currentValue',
        key: 'currentValue',
        width: '25%'

      },
      {
        title: 'Reason',
        dataIndex: 'reasonId',
        key: 'reasonId',
        width: '25%',
        render: (data, row) =>

          <>


            <Select
              showSearch
              style={{ width: "100%" }}
              optionLabelProp="label"
              optionLabelProp="label"
              disabled={this.state.updatedApplicableToAll}
              // onBlur={() => shipmentEvents.disableShipmentDetails("goods")}
              placeholder={"Select Reason"}
              onChange={(e) => this.changeUpdatedReason(e, row)}
              optionFilterProp="children"
              value={data}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toString()
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) >= 0
              }
            >
              {this.state.updateReasons?.map(
                reason =>
                  (
                    <Select.Option
                      key={reason?.id}
                      value={reason?.id}
                      label={reason?.documentRejectedReason}

                    >

                      {reason?.documentRejectedReason}

                    </Select.Option>
                  )
              )}
            </Select>
          </>
      }
    ];



    const { getFieldDecorator, getFieldValue, setFields } = this.props.form;
    getFieldDecorator('keys', { initialValue: [{ id }] });
    const keys = getFieldValue('keys');
    const formItems = keys.length > 0 ? keys.map((k, index) => (
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item colon={false} label={`Actual Trip ID`}>
            {getFieldDecorator(`tripTransitLocations[${(k.id)}]['actualTripId']`, {
              rules: [
                {
                  required: true,
                  message: 'Please input Actual Trip ID!',
                },
              ],
            })(
              <InputNumber
                placeholder="Actual Trip ID"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item colon={false} label={"Direct Delivery"}>
            {getFieldDecorator(`tripTransitLocations[${(k.id)}]['isDirectDelivery']`, {
              initialValue: false,
              rules: [
                {
                  required: true,
                  message: 'Please input Consignment ID!',
                },
              ],
            })(

              <Checkbox></Checkbox>
            )}
          </Form.Item>
        </Col>
        {
          getFieldValue(`tripTransitLocations[${(k.id)}]['isDirectDelivery']`) === false &&
          <Col span={8}>
            <Form.Item colon={false} label={`Transit Location`}>
              {getFieldDecorator(`tripTransitLocations[${(k.id)}]['transitLocationId']`, {
                rules: [
                  {
                    required: getFieldValue(`tripTransitLocations[${(k.id)}]['isDirectDelivery']`) === false,
                    message: 'Please select Transit Location!',
                  },
                ],
              })(

                <Select
                  allowClear
                  showSearch
                  disabled={getFieldValue(`tripTransitLocations[${(k.id)}]['isDirectDelivery']`) === true}
                  placeholder="Transit Location"
                  style={{ width: '100%' }}
                  notFoundContent={isFetchingLocation ? <center><Spin size="small" /></center> : <div>Search Location</div>}
                  onSearch={this.fetchLocations}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    locations.map(loc => {
                      return <Select.Option value={loc.id}>{loc.name}</Select.Option>
                    })
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        }

        <Col span={3}>
          <Form.Item colon={false} label={<>&nbsp;</>}>
            <Col span={12}>
              <Icon
                className="dynamic-delete-button"
                type="plus-circle"
                // style={{ marginRight: 10, marginLeft: 10 }}
                onClick={() => this.add()}
              />
            </Col>
            {
              keys.length > 1 && <Col span={12}>
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  // style={{ marginRight: 10, marginLeft: 10 }}
                  onClick={() => this.remove(index)}
                />
              </Col>
            }
          </Form.Item>
        </Col>
      </Row>
    )) : null

    return (
      <Spin spinning={loading} wrapperClassName={"docket-spin"}>
        <div className="docket-verify-left-navigation">
          <Col span={8} style={{ zIndex: 99 }}>
            <div className="docket-verify-left-navigation-back" onClick={this.goBack}>

              <span className="docket-verify-left-navigation-back-icon"><Icon type="arrow-left" /></span>
              <span className="docket-verify-left-navigation-back-text">Back</span>

            </div>
          </Col>

          <Col span={8}>
            <div className="docket-verify-left-navigation-pagination">
              <span
                style={{ padding: 10 }}
                className="docket-verify-left-navigation-pagination-left"
              >
                <Button disabled={this.state.leftDisabled} className="docket-verify-left-navigation-pagination-cta" onClick={() => this.navigate("left")}>
                  <Icon type="left" onClick={() => this.navigate("left")} />
                </Button>

              </span>
              <span className="docket-verify-left-navigation-pagination-center">

                <span>{waybillNo} </span>
                <br />
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: '300'
                  }}
                >{index + 1} of {consignments?.length}</div>

              </span>
              <span
                style={{ padding: 10 }}
                className="docket-verify-left-navigation-pagination-right">
                <Button disabled={this.state.rightDisabled} className="docket-verify-left-navigation-pagination-cta" onClick={() => this.navigate("right")}>
                  <Icon type="right" />
                </Button>
              </span>



            </div>
          </Col>
          <Col span={8}>

          </Col>

        </div>
        <Row gutter={24}>
          <Col md={17}>
            <DocketLeft
              {...this.props}
              photoIndex={this.state.photoIndex}
              consignmentData={consignmentData}
              consignmentVerifications={consignmentVerifications}
              leftBoardEvent={leftBoardEvent}
              toggleWaybillModal={this.props.toggleWaybillModal}
            />
          </Col>
          <Col md={7}>
            <DocketRight
              {...this.props}
              consignmentVerifications={consignmentVerifications}
              verifyDocket={this.verifyDocket}
              consignmentData={consignmentData}
              shipperDetails={shipperDetails}
              consignmentFilterBO={consignmentFilterBO}
              shippersData={shippersData}
              locationData={locationData}
              destinations={destinations}
              shipmentDetails={shipmentDetails}
              shipmentBoxes={shipmentBoxes}
              shipmentBoxTypes={shipmentBoxTypes}
              goodsTypes={goodsTypes}
              boxTypes={boxTypes}
              otherDetails={otherDetails}
              userDetails={userDetails}
              financeDetails={financeDetails}
              invoiceData={invoiceData}
              rightBoardEvents={rightBoardEvents}
            />
          </Col>
        </Row>

        <Modal
          visible={this.state.showModal}
          title="Please confirm to save the following changes"

          onCancel={this.cancelUpdateModal}
          width={800}
          footer={[
            <Button loading={this.state.loading} className="docket-verify-right-ctas-primary" onClick={this.saveConsignment}>Save</Button>,
            <Button disabled={this.state.loading} onClick={this.cancelUpdateModal} type="default">Cancel</Button>,
          ]}
        >
          <>
            <Row gutter={6}>
              <Col span={6}>
                <Form.Item colon={false} label="Reason">
                  <Select
                    showSearch
                    style={{ width: "100%" }}

                    disabled={!this.state.updatedApplicableToAll}
                    placeholder={"Select Reason"}
                    onChange={(e) => this.updateMainReason(e)}
                    optionFilterProp="children"
                    value={this.state.selectedUpdatedReason}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toString()
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {this.state.updateReasons?.map(
                      reason =>
                        (
                          <Select.Option
                            key={reason?.id}
                            value={reason?.id}
                            label={reason?.documentRejectedReason}

                          >

                            {reason?.documentRejectedReason}

                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>

              </Col>
              <Col span={8}>
                <Form.Item label="Applicable to All?" colon={false}>
                  <Checkbox
                    checked={this.state.updatedApplicableToAll}
                    onChange={this.changeApplicableToAll}
                  >

                  </Checkbox>
                </Form.Item>

              </Col>
            </Row>


            <Table
              bordered
              pagination={false}
              columns={updatedColumns}
              dataSource={this.state.savedItems}

            />
          </>
        </Modal>
        <Modal
          visible={this.state.showRejectModal}
          title="Reject"

          onCancel={this.cancelRejectModal}
          width={436}
          footer={[
            <Button disabled={this.state.rejectSelectedValue.length <= 0} loading={this.state.loading} type="danger" onClick={this.rejectInvoiceDocket}>Reject</Button>,
            <Button disabled={this.state.loading} onClick={this.cancelRejectModal} type="default">Cancel</Button>,
          ]}
        >
          <Row gutter={[6, 0]}>
            <Col span={24}>
              <Form.Item colon={false} label="Reason(s) for rejection">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder={"Select Reason"}
                  onChange={(e) => this.updateRejectReason(e)}
                  optionFilterProp="children"
                  mode="multiple"
                  value={this.state.rejectSelectedValue}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toString()
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {this.state.invoiceUpdateReasons?.map(
                    reason =>
                      (
                        <Select.Option
                          key={reason?.id}
                          value={reason?.id}
                          label={reason?.documentRejectedReason}

                        >

                          {reason?.documentRejectedReason}

                        </Select.Option>
                      )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item colon={false} label="Comments">
                <TextArea
                  value={this.state.rejectComment}
                  onChange={this.changeRejectComments}
                  placeholder={"Comments"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
        <Modal
          visible={this.state.showTicketDialog}
          title="Create Ticket"
          style={{ top: 20 }}
          onCancel={this.cancelCreateDialog}
          width={500}
          footer={[
            <Button loading={this.state.loading} className="docket-verify-right-ctas-primary" onClick={this.createTicket}>Create</Button>,
            <Button disabled={this.state.loading} onClick={this.cancelCreateDialog} type="default">Cancel</Button>,
          ]}
        >
          <div className="modal-create-ticket">
            <Row gutter={[6, 0]}>
              <Col span={24}>
                <Form.Item colon={false} label="User Mistake RCA">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={"Select User Mistake RCA"}
                    // onChange={(e) => this.updateRejectReason(e)}
                    optionFilterProp="children"
                    onChange={(e) => this.renderTicketFields(e)}
                    value={this.state.selectedRCA}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toString()
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {this.state.fieldMappings?.map(
                      fields =>
                        (
                          <Select.Option
                            key={fields?.id}
                            value={fields?.id}
                            label={fields?.value}

                          >

                            {fields?.value}

                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>
                {
                  Object.keys(fields)

                    .map((field, index) => {
                      if (fields[field]?.type === 'select') {
                        return (

                          <Form.Item colon={false} label={fields[field]?.label}>
                            {getFieldDecorator(`${field}`, {
                              rules: [
                                {
                                  required: true,
                                  message: `Please select ${fields[field]?.label}!`,
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={fields[field]?.placeholder}
                                // onChange={(e) => this.changeFieldValue(e, field)}
                                onChange={(e) => fields[field]?.onChange ? fields[field]?.onChange(e) : () => { }}
                                optionFilterProp="children"
                                // value={fields[field]?.value}
                                filterOption={(input, option) =>
                                  option?.props?.children
                                    ?.toString()
                                    ?.toLowerCase()
                                    .indexOf(input?.toLowerCase()) >= 0
                                }
                              >
                                {fields[field]?.values?.map(
                                  fields =>
                                    (
                                      <Select.Option
                                        key={fields?.label}
                                        value={fields?.value}
                                        label={fields?.label}

                                      >

                                        {fields?.label}

                                      </Select.Option>
                                    )
                                )}
                              </Select>
                            )}
                          </Form.Item>
                        )
                      }
                      if (fields[field]?.type === 'select_multiple') {
                        return (
                          <>
                            {formItems}
                          </>
                        )

                      }
                      if (fields[field]?.type === 'number') {
                        return (
                          <Form.Item colon={false} label={fields[field]?.label}>
                            {getFieldDecorator(`${field}`, {
                              rules: [
                                {
                                  required: true,
                                  message: `Please select ${fields[field]?.label}!`,
                                },
                              ],
                            })(
                              <InputNumber
                                style={{ width: '100%' }}
                                // onChange={(e) => this.changeFieldValue(e, field)}
                                placeholder={fields[field]?.placeholder}
                              // value={fields[field]?.value}
                              />
                            )}
                          </Form.Item>
                        )
                      }

                      if (fields[field]?.type === 'text') {
                        return (
                          <Form.Item colon={false} label={fields[field]?.label}>
                            {getFieldDecorator(`${field}`, {
                              rules: [
                                {
                                  required: true,
                                  message: `Please select ${fields[field]?.label}!`,
                                },
                              ],
                            })(
                              <Input
                                placeholder={fields[field]?.placeholder}
                                // onChange={(e) => this.changeFieldValue(e.target.value, field)}
                                style={{ width: '100%' }}
                              // value={fields[field]?.value}
                              />
                            )}
                          </Form.Item>
                        )
                      }
                      if (fields[field]?.type === 'date') {
                        return (

                          <Form.Item colon={false} label={fields[field]?.label}>
                            {getFieldDecorator(`${field}`, {
                              rules: [
                                {
                                  required: fields[field]?.required,
                                  message: `Please select ${fields[field]?.label}!`,
                                },
                              ],
                            })(
                              <DatePicker
                                // onChange={(e) => this.changeFieldValue(e, field)}
                                style={{ width: '100%' }}
                              // value={fields[field]?.value}
                              />
                            )}
                          </Form.Item>
                        )
                      }
                      if (fields[field]?.type === 'textarea') {
                        return (
                          <Form.Item colon={false} label={fields[field]?.label}>
                            {getFieldDecorator(`${field}`, {
                              rules: [
                                {
                                  required: false,
                                  message: `Please select ${fields[field]?.label}!`,
                                },
                              ],
                            })(
                              <TextArea
                                // onChange={(e) => this.changeFieldValue(e.target.value, field)}
                                placeholder={fields[field]?.placeholder}
                              // value={fields[field]?.value}
                              />
                            )}
                          </Form.Item>
                        )
                      }

                    })
                }
                <Row>
                  <Col span={12}>
                    <Form.Item colon={false} label="">
                      {getFieldDecorator(`attachDocketProof`, {
                        initialValue: false,
                        valuePropName: 'checked',
                        rules: [
                          {
                            required: false,

                          },
                        ],
                      })(
                        <Checkbox
                        >
                          Attach Docket Proof
                      </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item colon={false} label="">
                      {getFieldDecorator(`attachInvoiceProof`, {
                        initialValue: false,
                        valuePropName: 'checked',
                        rules: [
                          {
                            required: false,

                          },
                        ],
                      })(
                        <Checkbox

                        >
                          Attach Invoice Proof
                      </Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>


              </Col>

            </Row>

          </div>

        </Modal>



      </Spin>
    )
  }
};




// export default VerificationDocketsDetails;
const mapStateToProps = state => ({
  configurations: state.app.configurations
});

const mapDispatchToProps = dispatch => ({
  toggleWaybillModal: (waybill, isOpen = false) => dispatch(actions.openWaybillModal(waybill, isOpen))
});

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerificationDocketsDetails));
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'DocketEdit', onValuesChange: (props, val) => { } })(VerificationDocketsDetails));