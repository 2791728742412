import { Alert, Button, Icon } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { notifyApiSuccess } from "../../../../../common/utils/utils";
import { updateIndentStatus } from "../../../common/services/indent";
import { IndentListingConstants as _Constants } from '../../listing/state/indent.listing.constants';

export default function IndentNonServicable(props) {

    const dispatch = useDispatch();

    const checkServicability = _ => {
        dispatch({
            type: _Constants.INDENT_LISTING_LOADING_START
        });
        const postData = {
            indentId : props.indentId,
            status   : "SERVICEABLE"
        }
        updateIndentStatus(postData).then(_ => {
            dispatch({
                type: _Constants.INDENT_LISTING_LOADING_STOP
            }); 
        });
    }

    const escalateToKAM = _ => {
        notifyApiSuccess("Action completed!", "Success")
    }

    return ( 
        <div>
            <Alert type={"error"} message={
                <div className="flex-box flex-gap-l justify-content-space-between align-items-center">
                    <div className="flex-box flex-gap-m align-items-center">
                        <Icon type="warning" className="font-size-l-1 red-icon-wrapper" theme="filled" />
                        <div> No customer contract exists. Please reach out to the respective KAM to resolve it! </div>
                        <div onClick={escalateToKAM} className="cursor-pointer error-color text-decoration-underline text-semi-bold">
                            Click here to escalate to the KAM
                        </div>
                    </div>
                    <div>
                        <Button className="secondary-btn" onClick={checkServicability}>
                            <Icon type="reload" /> Refresh
                        </Button>
                    </div>
                </div>
            } />
        </div>
    )
}