import { Icon, Spin, Modal, Button, Empty, Checkbox, Tag } from "antd";
import React, { useEffect, useState } from "react";
import LocationService from "../../../../services/settings/location.service";
import FormWrapper from '../../../ui/formComponent/form';
import locationForm from './location.form.d';
import LocationMap from '../../../maps/LocationMap';
import _ from 'lodash';
import { fetchPincode, fetchLocationZones, createLocation, updateLocation } from "../common/services/index";
import "./location.scss";
import { permissionTags } from "../../../../configs/permissionTags";
import { canAllow } from "../../../../common/utils/utils";

const locationService = new LocationService();
const { confirm } = Modal;

export default function CustomerLocationForm(props) {

    // const [locationData, SetLocationData] = useState({});
    const [schema, SetSchema] = useState(locationForm.schema);
    const [uiSchema, setUISchema] = useState(locationForm.uiSchema);
    const [formData, SetFormData] = useState({});
    const [loading, SetLoading] = useState(true);

    const [fullScreenMode, setFullScreenMode] = useState(false);
    const [readonly, setReadonly] = useState(props.readOnly ? props.readOnly : false);
    const [createLoader, setCreateLoader] = useState(false);
    const [mapHeight, setMapHeight] = useState(200);

    const { openModal, customer, locationRef, setModal, setRefresh, refresh, setCreateAnother, createAnother, setCancel, openHelpLink } = props;
    const { draggable, setDraggable } = props;

    const locationPermission = {
        locationsCreate: canAllow(permissionTags.contract.locations.create),
        locationsUpdate: canAllow(permissionTags.contract.locations.update),
    }

    const onSubmit = formProps => {
        if (formProps) {
            confirm({
                title: 'Are you sure to submit?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    const { name, code, address, pincode, latitude, longitude } = formProps.locationDetails;
                    let shortCode = `LOC-${customer.code}-${code}`;
                    shortCode = shortCode.toUpperCase();
                    let params = {
                        name,
                        shortCode,
                        "entityId": customer.id,
                        "entityType": "CUSTOMER",
                        "address": {
                            "address1": address,
                            "pincode": {
                                "zipcode": pincode
                            },
                            "latitude": latitude,
                            "longitude": longitude
                        }
                    }
                    const create = async () => {
                        setCreateLoader(true)
                        const data = await createLocation(params);
                        // SetLoaded(true);
                        if (data) {
                            setModal(false);
                            if (!props.createLocationPopup) {
                                setRefresh(!refresh);
                                props.setReadOnly(false);
                            }
                            if (typeof props.onLocationCreate == "function") {
                                props.onLocationCreate({
                                    id   : data.locations[0].id,
                                    name : params.name
                                });
                            }
                            if (createAnother) {

                                setModal(true);

                                setCreateAnother(true);

                            }
                            setCreateLoader(false);
                        } else {
                            setCreateLoader(false);
                        }


                    }
                    const update = async () => {
                        // params.id = props?.id;
                        setCreateLoader(true)
                        const data = await updateLocation(props?.id, params);
                        // SetLoaded(true);

                        if (data) {
                            setModal(false);
                            if (!props.createLocationPopup) {
                                setRefresh(!refresh);
                            }
                            setCreateLoader(false);
                        } else {
                            setCreateLoader(false);
                        }



                    }

                    if (!readonly && props?.id) {
                        update();
                    } else if (!readonly && !props?.id) {
                        create();
                    }
                }
            })

        }
    }
    useEffect(() => {
        if (props.id) {
            locationService.getLocationDetails(props.id).then(res => {
                const { response } = res?.data;
                const stateCity = response?.address?.pincode?.city?.state + "/" + response?.address?.pincode?.city?.name;
                let code = response.shortCode;
                if (!readonly) {
                    var splitter = response.shortCode.split("-");
                    if (splitter.length > 1) {
                        code = splitter[splitter.length - 1]
                    }
                }

                const data = {
                    "locationDetails": {
                        "name": response?.name,
                        "address": response?.address?.address1,
                        "pincode": Number(response?.address?.pincode?.zipcode),
                        "latitude": response?.address?.latitude,
                        "longitude": response?.address?.longitude,
                        "workingHours": undefined,
                        "state": stateCity,
                        "code": code,
                        "zone": response?.zoneBOS?.map(z => z.name)?.join(",")
                    },
                    id: response?.id
                }
                SetFormData(data);

            })
        }

    }, [readonly]);
    useEffect(() => {
        if (formData.id == props.data) {
            SetLoading(false);
        }
    }, [formData]);

    useEffect(() => {
        if (!readonly) {
            let code = null;
            if (props.id && formData && formData.locationDetails) {
                // var splitter = formData.locationDetails.code.split("-");
                // code = `LOC-${customer.code}-${splitter[2]}`;
                setUISchema({
                    "locationDetails": {
                        ...uiSchema.locationDetails,
                        "code": {
                            ...uiSchema.locationDetails.code,
                            // "addonBefore": `${splitter[0]}-${splitter[1]}`,
                            "addonBefore": `LOC-${customer.code}-`,
                            disabled: true
                        }
                    }
                });

            } else {
                code = `LOC-${customer.code}-`;
                setUISchema({
                    "locationDetails": {
                        ...uiSchema.locationDetails,
                        "code": {
                            ...uiSchema.locationDetails.code,
                            "addonBefore": code
                        }
                    }
                });
            }



            setUISchema(sch => {
                sch.locationDetails.address.onSelection = (pincode, selectedAddress, latitude, longitude, selectedCity, cityCode, selectedState) => {
                    if (pincode) {
                        setPincodeZones(pincode).then(response => {

                            if (!_.isEmpty(response[0])) {

                                const setcity = response[0].city.state + '/' + response[0].city.name;
                                SetFormData({
                                    ...formData,
                                    locationDetails: {
                                        ...formData.locationDetails,
                                        // ...oldFormData.locationDetails,
                                        pincode: Number(response[0].zipcode),
                                        address: selectedAddress,
                                        zone: response[1],
                                        // ...(!_.isEmpty(response[1]) ? { zone: response[1] } : { zone: undefined }),
                                        state: setcity,
                                        latitude,
                                        longitude,
                                    }
                                })
                            }
                            else {
                                
                                SetFormData({
                                    ...formData,
                                    locationDetails: {
                                        ...formData.locationDetails,
                                        // pincode: Number(response.zipcode),
                                        address: selectedAddress,
                                        state: undefined,
                                        zone: response[1],
                                        // ...(!_.isEmpty(response[1]) ? { zone: response[1] } : { zone: '' }),
                                        latitude,
                                        longitude,
                                    }
                                })
                            }


                        });


                    } else {
                        Modal.error({
                            title: 'No Pincode found!'
                        })
                        SetFormData({
                            ...formData,
                            locationDetails: {
                                ...formData.locationDetails,
                                pincode: undefined,
                                state: undefined,
                                zone: undefined,
                            }
                        })
                    }

                }

                return sch;
            });
        }

    }, [readonly, formData])
    const checkAnother = e => {
        const oldFormData = { ...locationRef.current.formReference.current.state.formData };
        setCreateAnother(e.target.checked)

        SetFormData({
            locationDetails: {
                ...oldFormData.locationDetails,
            }
        })
    }
    const getPincode = pincode => {

        // if (new RegExp(validationRules.pincode).test(pincode)) {
        return fetchPincode({ pincode }).then(res => {
            if (res.pincodedetails && res.pincodedetails[pincode]) {
                return res.pincodedetails[pincode];
            } else {
                return {};
            }

        })
        // } else {
        //     return new Promise(resolve => resolve({}));
        // }

    }

    const getZones = pincode => {
        const params = {
            zipCode: pincode,
            entityId: customer.id
        }
        return fetchLocationZones(params).then(res => {
            const zones = res.zones;
            if (zones && zones.length > 0) {
                return zones.map(zone => zone.name)?.join(",");
            } else {
                return "";
            }


        })
    }
    const setPincodeZones = async (pincode) => {

        return await Promise.all([getPincode(pincode), getZones(pincode)]);
    }

    const onBlur = (id, value) => {

        const oldFormData = { ...locationRef.current.formReference.current.state.formData };
        if (!readonly) {
            if (id === 'address') {

                SetFormData({
                    ...formData,
                    locationDetails: {
                        // ...oldFormData.locationDetails,
                        ...formData.locationDetails,
                        address: value
                    }


                })

            }

            else if (id === 'pincode') {

                if (value) {
                    setPincodeZones(value).then(response => {

                        if (!_.isEmpty(response[0])) {

                            const setcity = response[0].city.state + '/' + response[0].city.name;
                            SetFormData({
                                ...formData,
                                locationDetails: {
                                    ...formData.locationDetails,
                                    // ...oldFormData.locationDetails,
                                    pincode: Number(response[0].zipcode),
                                    // address: response[0].selectedAddress,
                                    zone: response[1],
                                    // ...(!_.isEmpty(response[1]) ? { zone: response[1] } : { zone: undefined }),
                                    state: setcity,
                                    // latitude: response[0].latitude,
                                    // longitude: response[0].longitude,
                                }
                            })
                        }

                    });
                } else {
                    SetFormData({
                        ...formData,
                        locationDetails: {
                            ...formData.locationDetails,
                            // ...oldFormData.locationDetails,
                            pincode: undefined,
                            // address: response[0].selectedAddress,
                            zone: undefined,
                            // ...(!_.isEmpty(response[1]) ? { zone: response[1] } : { zone: undefined }),
                            state: undefined,
                            // latitude: response[0].latitude,
                            // longitude: response[0].longitude,
                        }
                    })
                }





            } else {
                SetFormData(formData)
            }
        }
    }
    const onChange = params => {

        if (params.locationDetails.code) {
            if (!_.isEmpty(formData)) {
                setCancel(true);
            }

            SetFormData({
                ...params,
                locationDetails: {
                    ...params.locationDetails,
                    code: params.locationDetails.code ? params.locationDetails.code.toUpperCase() : params.shipperDetails.contactShortCode
                }


            })

        } else {
            if (!_.isEmpty(formData)) {
                setCancel(true);
            }
            SetFormData(params)
        }
    }
    const fullScreenCallback = () => {
        setMapHeight(500);
        const screenFull = !fullScreenMode;

        setFullScreenMode(screenFull);
    }

    const updateMarker = (cordinates) => {
        SetFormData({
            ...formData,
            locationDetails: {
                ...formData.locationDetails,
                latitude: cordinates.lat,
                longitude: cordinates.lng,
            }
        })
    }


    return (
        <Modal
            visible={openModal}
            width={'85%'}
            closable={false}
            style={{ top: 20 }}
            footer={false}
            title={
                <div className="flex-box align-items-center flex-gap-m location-modal">
                    <Icon onClick={props.goBack}
                        style={{ marginRight: 8 }}
                        type="arrow-left"
                    />
                    <div className="text-bold font-size-m-3">

                        {(!props.id && !readonly) ?
                            <>Add location</> :
                            <>{readonly ? formData?.locationDetails?.code : (formData?.locationDetails?.code && `LOC-${customer.code}-${formData?.locationDetails?.code}`)} </>
                        }
                    </div>
                    <div className="flex-1 flex-box justify-content-flex-end flex-gap-m">
                        {(!readonly) &&
                            <Tag className="cursor-pointer" color="#87d068" onClick={openHelpLink}><Icon type="question-circle" /> Help</Tag>
                        }
                        {(readonly && locationPermission.locationsUpdate) &&
                            <div>
                                <Button block className="lsn-btn-primary" onClick={() => { setReadonly(false); setDraggable(true) }}>
                                    Edit
                                </Button>
                            </div>
                        }
                        {/* <div>
                            <Button block type="default" onClick={props.goBack}>
                                Close
                            </Button>
                        </div> */}
                    </div>
                </div>
            }

        >
            {!fullScreenMode ?
                <div className=" flex-column flex-gap-xl">


                    <div className="flex-box flex-gap-l">

                        <div className="flex-6">
                            <Spin spinning={loading}>
                                {

                                    <FormWrapper
                                        schema={schema}
                                        uiSchema={uiSchema}
                                        errorList={locationForm.errorList}
                                        validations={locationForm.validations}
                                        formData={formData}
                                        onSubmit={onSubmit}
                                        hideSubmit={true}
                                        readonly={readonly}
                                        fieldsPerRow={2}
                                        showColumnSplitter={false}
                                        bordered={true}
                                        ref={locationRef}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                    />
                                }
                            </Spin>
                        </div>
                        <div className="flex-3">

                            {
                                !loading && formData?.locationDetails?.latitude && formData?.locationDetails?.longitude ?
                                    <>
                                        {draggable ?
                                            <LocationMap
                                                draggable={true}
                                                lat={formData?.locationDetails?.latitude}
                                                lng={formData?.locationDetails?.longitude}
                                                selectedAddress={formData?.locationDetails?.address}
                                                showCordinates={true}
                                                showFullScreen={true}
                                                fullScreenCallback={fullScreenCallback}
                                                fullScreenHeight={mapHeight}
                                                updateMarker={updateMarker}
                                            />
                                            :

                                            <LocationMap
                                                draggable={false}
                                                lat={formData?.locationDetails?.latitude}
                                                lng={formData?.locationDetails?.longitude}
                                                selectedAddress={formData?.locationDetails?.address}
                                                showCordinates={true}
                                                showFullScreen={true}
                                                fullScreenCallback={fullScreenCallback}
                                                fullScreenHeight={mapHeight}
                                                updateMarker={updateMarker}
                                            />
                                        }
                                    </>

                                    :
                                    <Empty
                                        description={
                                            <span> Enter location address </span>
                                        }
                                    />


                            }

                        </div>
                    </div>

                    {!readonly &&
                        <div className="flex-box flex-gap-m justify-content-end align-items-center location-modal">
                            <div className="flex-box">
                                {(!readonly && !props.id && !props.disableCreateAnother) &&
                                    <Checkbox
                                        checked={createAnother}
                                        onChange={checkAnother}
                                    >
                                        Create Another
                            </Checkbox>
                                }

                            </div>
                            <div className="flex-box">
                                <Button type="submit" block onClick={props.goBack} >
                                    Cancel
                        </Button>
                            </div>
                            <div className="flex-box">
                                <Button loading={createLoader} type="button" block className="lsn-btn-primary" onClick={() => locationRef.current.formReference.current.submit()}>
                                {props.id ? 'Save' : 'Add'}
                        </Button>
                            </div>



                        </div>}

                </div>
                :
                <div>
                    {
                        !loading && formData?.locationDetails?.latitude && formData?.locationDetails?.longitude ?
                            <LocationMap
                                draggable={false}
                                lat={formData?.locationDetails?.latitude}
                                lng={formData?.locationDetails?.longitude}
                                selectedAddress={formData?.locationDetails?.address}
                                showCordinates={true}
                                showFullScreen={true}
                                fullScreenCallback={fullScreenCallback}
                                fullScreenHeight={mapHeight}

                            />
                            :
                            <Empty
                                description={
                                    <span>
                                        No Map
                                        </span>
                                }
                            />
                    }
                </div>
            }
        </Modal>

    )
}