import moment from "moment";
import { indentCreateActionTypes } from "./actions";

const initialState = {
  indentData: {
    details: {
      indentDate: moment().valueOf(),
      hubLocationId: Number(localStorage.getItem("currentPartnerLocationId")),
    },
    remarksObj: {},
    indentLoads: [
      {
        location: {},
        weight: "",
        quantity: "",
        volume: "",
      },
    ],
    reasonBOList: [],
  },
  indentLoadEdit: false,
};

export const IndentCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case indentCreateActionTypes.UPDATE_INDENT_DATA:
      return updateIndentData(state, action);

    default:
      return state;
  }
};

const updateIndentData = (state, action) => {
  return {
    ...state,
    indentData: action.payload,
  };
};
