import React from 'react';
import Sparkline from "../../../../shared/sparkline/Sparkline";


function IndentSummary(props) {
    return (
        <Sparkline {...props} />
    )
}

export default IndentSummary;