import React, { Component } from "react";
import { Row, Col, Icon, Button, Modal, Form, Input, Select, Spin } from "antd";
import "./trips.scss";
import ImageViewNew from "./ImageViewerNew";
import { canAllow, checkHttpStatus, dataGet, formatCurrency } from "../../../common/utils/utils";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as apiUtils from "../../../common/utils/apiGateway";
import scannerIcon from '../../../assets/img/icons/scanner.svg';
import { permissionTags } from "../../../configs/permissionTags";

const { confirm } = Modal;
const { TextArea } = Input;
export const formatDate = date => (moment(Number(date)).format('DD/MM/YYYY'));
class VerificationInvoice extends Component {
    state = {
        photoIndex: this.props.location.state?.photoIndex,
        currentIndex: this.props?.locaion?.state?.currentIndex
            ? this.props?.locaion?.state?.currentIndex
            : 0,
        invoiceData: [],
        invoiceName: undefined,
        headerInvoiceData: [],
        consignmentVerifications: this.props.location.state?.consignmentVerifications,
        consignmentDataId: this.props.location.state?.consignmentDataId,
        safeUpdated: false,
        consignmentId: this.props.location.state?.consignmentId,
        showRejectModal: false,
        rejectedDocument: {
            index: undefined,
            documentType: undefined,
            slider: false,
            photoIndex: undefined
        },
        rejectSelectedValue: [],
        rejectComment: undefined,
        updateReasons: this.props.location?.state?.updateReasons,
        status: this.props.location.state?.status,
        verificationPermissions: {
            update: canAllow(permissionTags.verification.docket.update),
            upload: canAllow(permissionTags.verification.docket.upload),
        },
        loading: false
    };
    componentDidMount(prevProps, prevState) {

        if (
            this.props.location.state?.invoicesData?.length > 0 ||
            this.state.consignmentVerifications !== prevState.consignmentVerifications
        ) {
            let data = this.props.location.state?.invoicesData?.filter(
                cv => cv?.verificationType === "INVOICE"
            );
            let invoiceData =
                data[this.props.location.state?.currentIndex]
                    ?.consignmentVerificationDocumentMappings;

            this.setState({
                invoiceData,
                headerInvoiceData: data,
                invoiceName:
                    data[this.props.location.state?.currentIndex]?.invoiceNumber,
                currentIndex: this.props.location.state?.currentIndex,
                photoIndex: this.props.location.state?.photoIndex
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        //   console.log("this.state?.consignmentVerifications", this.state?.consignmentVerifications)
        // if (
        //   (this.state?.consignmentVerifications !==
        //   prevState?.consignmentVerifications) && !this.state.safeUpdated
        // ) {
        //   let data = this.state.consignmentVerifications?.filter(
        //     cv => cv?.verificationType === "INVOICE"
        //   );
        //   let invoiceData =
        //     data[this.props.location.state?.currentIndex]
        //       ?.consignmentVerificationDocumentMappings;

        //   this.setState({
        //     invoiceData,
        //     headerInvoiceData: data,
        //     invoiceName:
        //       data[this.props.location.state?.currentIndex]?.invoiceNumber,
        //     currentIndex: this.props.location.state?.currentIndex,
        //     photoIndex: this.props.location.state?.photoIndex,
        //     safeUpdated: true
        //   });
        // }
    }
    goBack = () => {
        // this.props.history.goBack()
        const {
            filters,
            totalCount, 
            currentPageSize,
            currentPageNo,
            totalDockets,
            index,
            consignments,
            maxPage
        } = this.props.location?.state;
        const { consignmentId } = this.state;
        this.props.history.push('/appv2/verification/form/docket',
        {
            consignmentId, filters, totalCount, currentPageSize, currentPageNo, totalDockets, index, consignments, maxPage 
        }
        )
    };

    navigate = type => {
        if (type === "left") {
            let { currentIndex, invoiceData, photoIndex, invoiceName } = this.state;
            currentIndex -= 1;
            photoIndex = 0;
            invoiceName = this.props.location.state?.invoicesData[currentIndex]
                ?.invoiceNumber;
            invoiceData = this.props.location.state?.invoicesData[currentIndex]
                ?.consignmentVerificationDocumentMappings;
            this.setState({ currentIndex, invoiceData, photoIndex, invoiceName });
        } else {
            let { currentIndex, invoiceData, photoIndex, invoiceName } = this.state;
            currentIndex += 1;
            photoIndex = 0;
            invoiceName = this.props.location.state?.invoicesData[currentIndex]
                ?.invoiceNumber;
            invoiceData = this.props.location.state?.invoicesData[currentIndex]
                ?.consignmentVerificationDocumentMappings;
            this.setState({ currentIndex, invoiceData, photoIndex, invoiceName });
        }
    };

    InvoiceUpload = (index, key, file) => {
        let { consignmentVerifications } = this.state;
        let invoicesData = consignmentVerifications?.filter(
            cv => cv?.verificationType === "INVOICE"
        );
        let docketData = consignmentVerifications?.filter(
            cv => cv?.verificationType === "DOCKET"
        );
        let invoiceImages = invoicesData[index][key];
        invoiceImages = [
            ...invoiceImages,
            {
                s3Url: file
            }
        ];
        invoicesData[index][key] = invoiceImages;
        console.log("invoiceData", invoicesData);
        consignmentVerifications = [...docketData, ...invoicesData];
        this.setState({ consignmentVerifications }, () => {
            let { consignmentVerifications, consignmentDataId } = this.state;
            let invoicesData = consignmentVerifications?.filter(
                cv => cv?.verificationType === "INVOICE"
            );
            let docketData = consignmentVerifications?.filter(
                cv => cv?.verificationType === "DOCKET"
            );
            const payload = {
                consignmentId: consignmentDataId,
                consignmentVerifications: [...invoicesData, ...docketData]
            };

            this.updateInvoice(payload);
        });
    };

    RemoveInvoiceImage = (index, key, fileIndex) => {
        const _this = this;
        confirm({
            title: 'Are you sure to delete this proof?',
            onOk: () => {
                let { consignmentVerifications } = _this.state;
                let invoicesData = consignmentVerifications?.filter(
                    cv => cv?.verificationType === "INVOICE"
                );
                let docketData = consignmentVerifications?.filter(
                    cv => cv?.verificationType === "DOCKET"
                );
                let invoiceImages = invoicesData[index][key];
                invoicesData[index][key] = invoiceImages?.filter((item, index) => (index !== fileIndex));
                consignmentVerifications = [...docketData, ...invoicesData];
                _this.setState({ consignmentVerifications }, () => {
                    let { consignmentVerifications, consignmentDataId } = _this.state;
                    let invoicesData = consignmentVerifications?.filter(
                        cv => cv?.verificationType === "INVOICE"
                    );
                    let docketData = consignmentVerifications?.filter(
                        cv => cv?.verificationType === "DOCKET"
                    );
                    const payload = {
                        consignmentId: consignmentDataId,
                        consignmentVerifications: [...invoicesData, ...docketData]
                    };

                    _this.updateInvoice(payload);
                });
            }
        })

    };

    updateInvoice = payload => {
        apiUtils
            .apiRequest({
                url: `/b2b/v1/consignmentVerifications/update`,
                method: "POST",
                data: payload
            })
            .then(response => {
                if (checkHttpStatus(response?.data?.status)) {
                    this.setState({ loading: false, safeUpdated: false });
                    this.reload();

                } else {
                    this.setState({ loading: false });
                }
            });
    };

    replaceImage = (index, type, e) => {
        const { currentIndex, consignmentVerifications, consignmentId } = this.state;
        let invoicesData = [];
        let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === 'DOCKET');
        let data = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        let payload = {};
        const _this = this;
        if (data?.length > 0) {
            invoicesData = data[currentIndex];

            invoicesData['consignmentVerificationDocumentMappings'][index] = {
                s3Url: e
            }
            invoicesData['verificationType'] = type;
            console.log("docle", [invoicesData])
            data[currentIndex] = invoicesData
            data = [
                ...data,
            ]

            console.log("data", data)
            // let imageIndex = docketData[index];
            // imageIndex.s3Url = e;
            // imageIndex.verificationType = 'DOCKET';
            // console.log("image", imageIndex);
            payload = {
                "consignmentId": consignmentId,
                "consignmentVerifications": [
                    ...data,
                    ...docketData
                ]
            }

            console.log("payload", payload)
            this.updateInvoice(payload);
        } else {
            invoicesData = [
                {
                    verificationType: type,
                    consignmentVerificationDocumentMappings: [
                        {
                            s3Url: e
                        }
                    ]
                }
            ];
            payload = {
                "consignmentId": consignmentId,
                "consignmentVerifications": [
                    ...invoicesData,
                    ...docketData
                ]
            }
            this.updateInvoice(payload);
        }

    }

    reload = () => {
        apiUtils.apiRequest({
            url: `/b2b/v1/consignments/${this.state.consignmentId}`,
            method: "GET"
        }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                const consignmentData = res?.data?.response?.consignments[0];
                let consignmentVerifications = consignmentData?.consignmentVerifications;
                this.setState({ consignmentVerifications }, () => {
                    let data = this.state.consignmentVerifications?.filter(
                        cv => cv?.verificationType === "INVOICE"
                    );
                    let invoiceData =
                        data[this.state.currentIndex]
                            ?.consignmentVerificationDocumentMappings;

                    this.setState({
                        invoiceData,

                        headerInvoiceData: data,
                        invoiceName: data[this.state.currentIndex]?.invoiceNumber,
                        currentIndex: this.state.currentIndex,
                        // currentIndex: this.props.location.state?.currentIndex,
                        photoIndex: this.state.photoIndex <= invoiceData.length ? this.state.photoIndex : 0,
                        //     safeUpdated: true
                    });
                })

            }
        });
    }

    openRejectModal = (index, documentType, slider, photoIndex = undefined) => {

        let { rejectedDocument } = this.state;
        rejectedDocument = {
            index,
            documentType,
            slider,
            photoIndex
        }
        this.setState({ showRejectModal: true, rejectedDocument });


    }

    cancelRejectModal = () => {
        let { rejectedDocument } = this.state;
        rejectedDocument = {
            index: undefined,
            documentType: undefined,
            slider: false,
            photoIndex: undefined
        }
        this.setState({ showRejectModal: false, rejectedDocument, rejectSelectedValue: [], rejectComment: undefined });
    }

    updateRejectReason = values => {
        this.setState({ rejectSelectedValue: values })
    }

    changeRejectComments = e => {
        this.setState({ rejectComment: e.target.value })
    }

    rejectInvoiceDocket = () => {
        this.setState({ loading: true });
        let { consignmentVerifications, rejectedDocument, rejectSelectedValue } = this.state;
        let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === "DOCKET");
        let invoices = {}, dockets = {};

        if (rejectedDocument?.documentType === 'INVOICE') {
            if (rejectedDocument?.slider) {
                invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['documentStatus'] = 'REJECTED';
                invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['message'] = this.state.rejectComment;
                invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
                    return {
                        "id": rIds.toString()
                    }
                });
                delete invoicesData[rejectedDocument?.index]['add_type'];
                invoices = {
                    consignmentVerificationDocumentMappings: invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings']
                }


            } else {
                // invoicesData[rejectedDocument?.index]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
                //   return {
                //     id: rIds
                //   }
                // });
                invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'].map(cv => {
                    cv.rejectedReasons = rejectSelectedValue.map(rIds => {
                        return {
                            id: rIds
                        }
                    });
                    return cv;
                })
            }
            delete invoicesData[rejectedDocument?.index]['add_type'];
            invoices = {
                consignmentVerificationDocumentMappings: invoicesData[rejectedDocument?.index]['consignmentVerificationDocumentMappings']
            }
            this.submitRejectedDocument(invoices);
        } else {

            docketData[rejectedDocument?.index]['consignmentVerificationDocumentMappings'][rejectedDocument?.photoIndex]['rejectedReasons'] = rejectSelectedValue.map(rIds => {
                return {
                    id: rIds
                }
            });
            dockets = {
                consignmentVerificationDocumentMappings: docketData[rejectedDocument?.index]['consignmentVerificationDocumentMappings']
            }

            this.submitRejectedDocument(dockets);
        }


    }

    submitRejectedDocument = data => {
        console.log("data", data)
        apiUtils.apiRequest({
            url: `/b2b/v1/consignmentVerifications/verifyDocument`,
            method: "POST",
            data
        }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                this.setState({ loading: false });
                this.cancelRejectModal();
                this.reload();
            } else {
                this.setState({ loading: false });
            }
        });
    }

    enableEdit = (index, field) => {
        let { headerInvoiceData } = this.state;
        headerInvoiceData[index][field] = true;
        this.setState({ headerInvoiceData });
    }

    changeInvoiceDetails = (value, index, key, isEway = false) => {
        let { headerInvoiceData } = this.state;
        if (!isEway) {
            headerInvoiceData[index][key] = value;
        } else {
            headerInvoiceData[index]['ewaybillBO'] = {
                ewaybillNo: value
            }
        }

        
        this.setState({ headerInvoiceData });
    }

    saveInvoice = () => {
        this.setState({loading: true});
        let { consignmentVerifications, consignmentId } = this.state;
        let docketData = consignmentVerifications?.filter(cv => cv?.verificationType === 'DOCKET');
        let invoiceData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        invoiceData[this.state.currentIndex] = this.state.headerInvoiceData[this.state.currentIndex];
        const payload = {
            "consignmentId": consignmentId,
            "updateEwaybill": true,
            "consignmentVerifications": [
                ...docketData,
                ...invoiceData
            ]
        }

        this.updateInvoice(payload)

    }

    updatePhotoIndex = photoIndex => {
        this.setState({ photoIndex })
    }



    render() {
        // const { consignmentData, consignmentVerifications, leftBoardEvent } = this.props;        
        const {
            invoiceName,
            currentIndex,
            invoiceData,
            headerInvoiceData,
            verificationPermissions,
            loading
        } = this.state;
        const invoiceAllowedImagesUpload = dataGet(
            this.props.configurations,
            "docketVerificationProofConfig.invoiceProofSize",
            0
        );


        let inv = headerInvoiceData[currentIndex];
        let expiryDate = null;

        if (inv?.ewaybillBO && inv?.ewaybillBO?.validUpto) {
            let validUpto = moment(inv?.ewaybillBO?.validUpto);
            let today = moment();
            let difference = validUpto.diff(today, 'hours');
            if (validUpto) {
                if (moment(validUpto) <= moment()) {
                    expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-red">Expired on {formatDate(inv?.ewaybillBO?.validUpto)}</span>
                } else if (difference <= this.props?.config?.ewaybill?.waringHours) {
                    expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-orange">Expires on {formatDate(inv?.ewaybillBO?.validUpto)}</span>
                } else {
                    expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-green">Expires on {formatDate(inv?.ewaybillBO?.validUpto)}</span>
                }
            } else {
                expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-green">Expires on {formatDate(inv?.ewaybillBO?.validUpto)}</span>

            }

        }

        const Pencil = () => (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24">
                <path fill="#474A5A" d="M19.15 10.8c-.47 0-.85.38-.85.85v6.8c0 .468-.38.85-.85.85H5.55c-.47 0-.85-.382-.85-.85V6.55c0-.468.38-.85.85-.85h6.8c.47 0 .85-.38.85-.85S12.82 4 12.35 4h-6.8C4.144 4 3 5.144 3 6.55v11.9C3 19.856 4.144 21 5.55 21h11.9c1.406 0 2.55-1.144 2.55-2.55v-6.8c0-.47-.38-.85-.85-.85z" />
                <path fill="#474A5A" d="M10.694 10.432c-.057.057-.095.13-.111.207l-.575 2.875c-.027.133.015.27.111.367.078.078.181.119.288.119.026 0 .053-.002.08-.008l2.873-.575c.08-.016.152-.054.208-.111L20 6.874 17.126 4l-6.432 6.432zM22.352 1.647c-.862-.863-2.266-.863-3.128 0L18 2.872 21.128 6l1.224-1.225c.418-.417.648-.972.648-1.563 0-.592-.23-1.147-.648-1.565z" />
            </svg>


        )


        return (
            <Spin spinning={loading}>
                <div className={"docket-verify-left"}>
                    <div className="docket-verify-left-navigation">
                        <div
                            className="docket-verify-left-navigation-back"
                            onClick={this.goBack}
                        >
                            <span className="docket-verify-left-navigation-back-icon">
                                <Icon type="arrow-left" />
                            </span>
                            <span className="docket-verify-left-navigation-back-text">
                                Back
                         </span>
                        </div>
                        <div className="docket-verify-left-navigation-pagination">
                            <span
                                style={{ padding: 10 }}
                                className="docket-verify-left-navigation-pagination-left"
                            >
                                <Button
                                    disabled={currentIndex === 0}
                                    className="docket-verify-left-navigation-pagination-cta"
                                    onClick={() => this.navigate("left")}
                                >
                                    <Icon type="left" />
                                </Button>
                            </span>
                            <span className="docket-verify-left-navigation-pagination-center">

                                <div>{invoiceName} </div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        fontWeight: '300'
                                    }}
                                >{currentIndex + 1} of{" "}{this.props.location.state?.invoicesData?.length}</div>
                            </span>
                            <span
                                style={{ padding: 10 }}
                                className="docket-verify-left-navigation-pagination-right">
                                <Button
                                    disabled={
                                        currentIndex ===
                                        this.props.location.state?.invoicesData?.length - 1
                                    }
                                    className="docket-verify-left-navigation-pagination-cta"
                                    onClick={() => this.navigate("right")}
                                >
                                    <Icon type="right" />
                                </Button>
                            </span>
                        </div>
                    </div>
                    <div className={"docket-verify-left-block"} style={{ width: "100%" }}>
                        <div
                            className="docket-verify-left-block-header"
                            style={{ width: "100%" }}
                        >
                            <Row>
                                <Col span={24}>
                                    <div
                                        className="docket-verify-left-block-header-block2"
                                        style={{ width: "100%", margin: "12px 0 16px" }}
                                    >
                                        <Row>
                                            <Col span={10}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={6}>
                                                                <label className="docket-verify-left-block-header-block2-label">
                                                                    Invoice Number :
                                                            </label>
                                                            </Col>
                                                            <Col span={8}>
                                                                <span className="docket-verify-left-block-header-block2-text">
                                                                    {
                                                                        headerInvoiceData[currentIndex]?.invoiceNumber_edit ?
                                                                            <Input
                                                                                placeholder="Invoice Number"
                                                                                value={headerInvoiceData[currentIndex]?.invoiceNumber}
                                                                                onChange={(e) => this.changeInvoiceDetails(e.target.value, currentIndex, 'invoiceNumber')}
                                                                            />
                                                                            :
                                                                            <>
                                                                                {headerInvoiceData[currentIndex]?.invoiceNumber}
                                                                            &nbsp;&nbsp;
                                                                            <Icon
                                                                                    style={{
                                                                                        fontSize: 14
                                                                                    }}
                                                                                    component={Pencil}
                                                                                    onClick={() => {
                                                                                        this.enableEdit(currentIndex, 'invoiceNumber_edit')
                                                                                    }}
                                                                                />
                                                                            </>
                                                                    }

                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Col span={6}>
                                                                    <label className="docket-verify-left-block-header-block2-label">
                                                                        Invoice Value :
                                                                </label>
                                                                </Col>
                                                                <Col span={6}>

                                                                    <span className="docket-verify-left-block-header-block2-text">
                                                                        {
                                                                            headerInvoiceData[currentIndex]?.invoiceValue_edit ?
                                                                                <Input
                                                                                    placeholder="Invoice Value"
                                                                                    value={headerInvoiceData[currentIndex]?.invoiceValue}
                                                                                    onChange={(e) => this.changeInvoiceDetails(e.target.value, currentIndex, 'invoiceValue')}
                                                                                />
                                                                                :
                                                                                <>
                                                                                    {formatCurrency(headerInvoiceData[currentIndex]?.invoiceValue)}
                                                                                &nbsp;&nbsp;
                                                                                <Icon
                                                                                        style={{
                                                                                            fontSize: 14
                                                                                        }}
                                                                                        component={Pencil}
                                                                                        onClick={() => {
                                                                                            this.enableEdit(currentIndex, 'invoiceValue_edit')
                                                                                        }}
                                                                                    />
                                                                                </>
                                                                        }
                                                                    </span>
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={1}>
                                                <div className="docket-verify-left-block-header-block2-divider"></div>
                                            </Col>
                                            <Col span={10}>
                                                <Row>
                                                    <Col span={24}>
                                                        {/* <Row>
                                                        <Col span={10}>
                                                            <label className="docket-verify-left-block-header-block2-text">
                                                                {
                                                                    headerInvoiceData[currentIndex]?.ewaybillNo_edit ?
                                                                        <Input
                                                                            placeholder="Ewaybill No"
                                                                            value={headerInvoiceData[currentIndex]?.ewaybillBO?.ewaybillNo}
                                                                            // onChange={(e) => this.changeInvoiceDetails(e.target.value, currentIndex, [ewaybillBO][ewaybillNo])}
                                                                            onChange={(e) => this.changeInvoiceDetails(e.target.value, currentIndex, null, true)}
                                                                        />
                                                                        :
                                                                        <>
                                                                            {headerInvoiceData[currentIndex]?.ewaybillBO?.ewaybillNo ?
                                                                                headerInvoiceData[currentIndex]?.ewaybillBO?.ewaybillNo
                                                                                : "-"
                                                                            }
                                                                            &nbsp;&nbsp;
                                                                            <Icon
                                                                                style={{
                                                                                    fontSize: 14
                                                                                }}
                                                                                component={Pencil}
                                                                                onClick={() => {
                                                                                    this.enableEdit(currentIndex, 'ewaybillNo_edit')
                                                                                }}
                                                                            />
                                                                        </>
                                                                }



                                                            </label>
                                                        </Col>
                                                    </Row> */}
                                                        <Row>
                                                            <Col span={4}>
                                                                <label className="docket-verify-left-block-header-block2-label">
                                                                    Ewaybill No :
                                                            </label>
                                                            </Col>
                                                            <Col span={8}>
                                                                <span className="docket-verify-left-block-header-block2-text">
                                                                    {
                                                                        headerInvoiceData[currentIndex]?.ewaybillNo_edit ?
                                                                            <Input
                                                                                placeholder="Ewaybill No"
                                                                                addonAfter={<img src={scannerIcon} />}
                                                                                value={headerInvoiceData[currentIndex]?.ewaybillBO?.ewaybillNo}
                                                                                // onChange={(e) => this.changeInvoiceDetails(e.target.value, currentIndex, [ewaybillBO][ewaybillNo])}
                                                                                onChange={(e) => this.changeInvoiceDetails(e.target.value, currentIndex, null, true)}
                                                                            />
                                                                            :
                                                                            <>
                                                                                {headerInvoiceData[currentIndex]?.ewaybillBO?.ewaybillNo ?
                                                                                    headerInvoiceData[currentIndex]?.ewaybillBO?.ewaybillNo
                                                                                    : "-"
                                                                                }
                                                                            &nbsp;&nbsp;
                                                                            <Icon
                                                                                    style={{
                                                                                        fontSize: 14
                                                                                    }}
                                                                                    component={Pencil}
                                                                                    onClick={() => {
                                                                                        this.enableEdit(currentIndex, 'ewaybillNo_edit')
                                                                                    }}
                                                                                />
                                                                            </>
                                                                    }

                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Col span={12}>
                                                                    <label className="docket-verify-left-block-header-block2-text">
                                                                        {expiryDate}
                                                                    </label>
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={3} style={{ float: 'right' }}>

                                                <Button disabled={this.state.status === 'VERIFIED' || !verificationPermissions?.update} block className="docket-verify-right-ctas-primary" onClick={this.saveInvoice}>Save</Button>


                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <ImageViewNew
                                title="Invoice Proofs"
                                imageType="INVOICE"
                                imageData={this.state.invoiceData}
                                updatePhotoIndex={(photoIndex) => this.updatePhotoIndex(photoIndex)}
                                // disabledAction={!verificationPermissions?.upload || this.state.status === 'VERIFIED'}
                                disableReject={this.state.status === 'VERIFIED' || !verificationPermissions?.update}
                                disableReplace={this.state.status === 'VERIFIED' || !verificationPermissions?.upload}
                                replaceImage={this.replaceImage}
                                openRejectModal={() => this.openRejectModal(this.state.currentIndex, "INVOICE", true, this.state.photoIndex)}
                                photoIndex={this.state.photoIndex}
                                uploadLimit={invoiceAllowedImagesUpload}
                                onFileUploaded={file =>
                                    this.InvoiceUpload(
                                        this.state.currentIndex,
                                        "consignmentVerificationDocumentMappings",
                                        file
                                    )
                                }
                                onFileRemoved={(index) => this.RemoveInvoiceImage(this.state.currentIndex, "consignmentVerificationDocumentMappings", index)}
                            />
                        </div>
                    </div>
                    <Modal
                        visible={this.state.showRejectModal}
                        title="Reject"

                        onCancel={this.cancelRejectModal}
                        width={436}
                        footer={[
                            <Button disabled={this.state.rejectSelectedValue?.length <= 0} loading={this.state.loading} type="danger" onClick={this.rejectInvoiceDocket}>Reject</Button>,
                            <Button disabled={this.state.loading} onClick={this.cancelRejectModal} type="default">Cancel</Button>,
                        ]}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item colon={false} label="Reason(s) for rejection">
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={"Select Reason"}
                                        onChange={(e) => this.updateRejectReason(e)}
                                        optionFilterProp="children"
                                        mode="multiple"
                                        value={this.state.rejectSelectedValue}
                                        filterOption={(input, option) =>
                                            option?.props?.children
                                                ?.toString()
                                                ?.toLowerCase()
                                                .indexOf(input?.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.updateReasons?.map(
                                            reason =>
                                                (
                                                    <Select.Option
                                                        key={reason?.id}
                                                        value={reason?.id}
                                                        label={reason?.documentRejectedReason}

                                                    >

                                                        {reason?.documentRejectedReason}

                                                    </Select.Option>
                                                )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item colon={false} label="Comments">
                                    <TextArea
                                        value={this.state.rejectComment}
                                        onChange={this.changeRejectComments}
                                        placeholder={"Comments"}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Modal>


                </div>
            </Spin>
        );
    }
}
const mapStateToProps = state => ({
    configurations: state.app.configurations
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VerificationInvoice));
