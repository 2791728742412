const schema = {
    "type": "object",
    "title": "Accessorial Charges",
    "properties": {
        "docketCharges": {
            "type": "number",
            "title": "Docket Charges"
        },
        "additionalCharges": {
            "type"       : "number",
            "title"      : "Additional Charges"
        },
        "minimumGuarantee": {
            "type"    : "integer",
            "title"   : "Minimum Guarantee"
        },
        "dropPointCharges": {
            "type"       : "number",
            "title"      : "Drop Point Charges"
        },
        "rov" : {
            "type"       : "object",
            "properties" : {
                "rovPercentage" : {
                    "type"      : "number",
                    "title"     : "ROV%"
                },
                "rovMinimum" : {
                    "type"   : "number",
                    "title"  : "ROV Minimum"
                },
                "rovMaximum" : {
                    "type"   : "number",
                    "title"  : "ROV Maximum"
                }
            },
            "dependencies": {
                "rovMinimum": [
                  "rovPercentage"
                ],
                "rovMaximum": [
                    "rovPercentage"
                ]
            }
        }
    }
}

const uiSchema = {
    "classNames"        : "multi-row-field-objects form-bold-title",
    "minimumGuarantee"  : {
        "ui:suffix"         : "Kg",
        "type"              : "number",
        "ui:placeholder"    : "Enter Docket Charges"
    },
    "docketCharges"     : {
        "type"              : "number",
        "ui:placeholder"    : "Enter Docket Charges",
        "ui:suffix"         : "Rs."
    },
    "minimumGuarantee"  : {
        "type"              : "number",
        "ui:placeholder"    : "Enter Minimum Guarantee"
    },
    "additionalCharges" : {
        "type"              : "number",
        "ui:placeholder"    : "Enter Additional Charges",
        "ui:suffix"         : "Rs."
    },
    "dropPointCharges"  : {
        "type"              : "number",
        "ui:placeholder"    : "Enter Drop Point Charges",
        "ui:suffix"         : "Rs."
    },
    "rov" : {
        "classNames"    : "hide-title row-object",
        "rovPercentage" : {
            "type"           : "number",
            "ui:placeholder" : "Enter ROV%",
            "classNames"     : "flex-2"
        },
        "rovMinimum"    : {
            "type"           : "number",
            "ui:placeholder" : "Enter ROV Minimum",
            "classNames"     : "flex-1 flex-column no-flex-gap font-size-m-1",
            "ui:suffix"      : "Rs."
        },
        "rovMaximum"    : {
            "type"           : "number",
            "ui:placeholder" : "Enter ROV Maximum",
            "classNames"     : "flex-1 flex-column no-flex-gap font-size-m-1",
            "ui:suffix"      : "Rs."
        }
    }
}

export const errorList = {
    "rov"   : {
        "rovPercentage" : {
            "dependencies"  : "ROV Minimum/Maximum cannot be updated with no ROV Percentage!"
        }
    }
}

export default { schema, uiSchema, errorList };