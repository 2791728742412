import React, { Component } from 'react';
import {
    Card, Icon, Divider,
    Row, Col, Button, Select,
    Form, Input, Alert, InputNumber, DatePicker, Radio
}
    from 'antd';
import * as moment from 'moment';
import '../../reports/ingest/Ingest.scss';
import FileUpload from './../../ui/file-upload/file.upload';
import { UPLOAD_BUTTON_TYPE, S3_REPORT_STATUS, validationRules } from '../../../common/utils/constants';
import * as actions from '../../reports/ingest/action';
import * as appActions from '../../../app/appAction';
import { connect } from 'react-redux';
import { notifyApiError, notifyApiSuccess, checkForDownloadReportStatus, dataGet } from '../../../common/utils/utils';
import { ReactDOM } from 'react-dom';
import FormSubmission from './FormSubmission';
import './FormBuilder.scss';
import { Constants } from '../../../common/utils/constants';
import debounce from 'lodash/debounce';


const { RangePicker } = DatePicker;


class FormBuilder extends Component {
    state = {
        acceptTypes: [".xlsx", ".xls"],
        sampleDownloadLink: '',
        isFormValid: false,
        reportType: '',
        s3FileLink: '',
        filename: '',
        clearFile: false,
        btnStatus: 'Submit',
        dropDownsList: [],
        inputsList: [],
        endPoint: null,
        payload: {},
        result: [],
        formList: [],
        showResponseFields: [],
        startDate: '',
        visibilityFilters: [],
        actionList: [],
        loading: false,
        locationCalled: true,
        locationList: []

    }

    componentDidMount() {
        this.props.getReportTypes(this.props.builderType)
        this.fetchLocations = debounce(this.fetchLocations, 800);

    }

    componentDidUpdate(oldProps,) {

        // this.props.form.validateFields((err, values) => {
        //     if(err) this.setState({disableAction: true});
        //     else this.setState({disableAction: false});
        // })
        // if (this.props.uploadedFileResp && oldProps.uploadedFileResp !== this.props.uploadedFileResp) {
        //     checkForDownloadReportStatus(this.props)
        //     this.setState({
        //         clearFile: true,
        //         reportType: ''
        //     })
        // }
        // if (this.props.customApiResp && oldProps.customApiResp !== this.props.customApiResp) {
        //     const resp = this.props.customApiResp
        //     let result = []
        //     if (this.state.responseDataFormat === 'JSON') {
        //         if (this.state.showResponseFields.length > 0) {
        //             this.state.showResponseFields.forEach(field => {
        //                 const fieldArr = field.split('.');
        //                 result.push({
        //                     key: this.capitalize(fieldArr[fieldArr.length - 1]),
        //                     value: dataGet(resp, field, 0),
        //                     type: resp ?.response ?.sync ? 'success' : 'error'
        //                 })
        //             })
        //         }
        //         this.setState({
        //             result,
        //             clearFile: true,
        //             reportType: '',
        //             ...this.getMetaDataProperties({})
        //         });

        //     } else if (this.state.responseDataFormat === 'FILE') {
        //         checkForDownloadReportStatus({ ...this.props, uploadedFileResp: { responseUrl: resp.response.responseUrl } })
        //         this.setState({
        //             clearFile: true,
        //             reportType: ''
        //         })
        //     }
        // }
        // window.open(this.props.uploadedFileResp.responseUrl)
    }


    fetchLocations = (value) => {
        if (value) {
            // console.log("form",this.props.form.getFieldValue("partnerIds"))
            const partners = this.props.form.getFieldValue("partnerIds").map(p => p.key.toString());
            // console.log("partner", partners)
            let params = { "filters": { "entityTypes": ["PARTNER"], "entityIds": partners, "isConsiderCreatorId": false, "fetchObjects": [], "status": true, partialContentSearch: value } };

            // this.setState({ sourceLocations: [], fetchingSource: true })

            // const data = {
            //     "filters": {
            //         "entityTypes": ["PARTNER"],
            //         "entityIds": [Constants.PARTNER_ID],
            //         "isConsiderCreatorId": false,
            //         "fetchObjects": [],
            //         "status": true
            //     }
            // }
            // this.props.getLocationList(data);
            // this.setState({ locationList: [{ name: "All", id: "101" }] }, () => {
            actions.getAllLocations(params).then(res => {
                let { locationList } = this.state;
                // console.log("location", locationList, res?.data?.response?.locations)
                let response = res?.data?.response?.locations;
                let newLocationList = [...this.state.locationList, ...response];
                // console.log("newLocationList", newLocationList)
                this.setState({ locationList: newLocationList })
                // this.setState({
                //     locationList: [...this.state.locationList, res?.data?.response?.locations]
                // })

                // locationList = locationList.concat(res?.data?.response?.locations );
                // this.setState({ locationList }, () => {


                // locationList = this.state.locationList;
                // let index = locationList.findIndex(x => x.id == "101")
                // if (index === -1) {
                //     locationList.unshift({ name: "All", id: "101" });
                // }

                // this.setState({locationList})



                // if (!this.props.locationList && fetchFor === 'locations' && this.state.locationCalled) {
                //     this.setState({ locationCalled: false });
                //     const keys = defaultValue.map(k => k['key']);
                //     this.callDropDownApi_v2(fetchFor, keys.join(","));
                // }
                // })
                // })
            })



            // slaService.getSLAConsignmentsLocations(params).then(res => {
            //     this.setState({ sourceLocations: res?.data?.response?.locations, fetchingSource: false })
            // })
        }

    }

    selectBlur = () => {
        this.setState({ locationList: [] })
    }



    seperateValueByDot(strArr) {
        let temp = [];
        temp.push(strArr.map(str => {
            const arr = str.split('.');
            return arr;
        }))
        return temp;
    }

    handleReportChange = (val) => {
        // this.sampleDownloadLink = val
        this.props.changeDisable(false);
        this.props.loadingForm(false);
        this.setState({
            reportType: val,
            result: []
        })
        this.props.reportTypes.map(type => {
            if (type.code === val) {
                this.setState({
                    ...this.getMetaDataProperties(type)
                }, this.callDropDownApi(type.metaInfo?.dropDowns))
                this.props.form.resetFields();
            }
        })
    }

    callDropDownApi = (dropDownsList) => {
        if (dropDownsList)
            dropDownsList.forEach((val, key) => {
                switch (val.type) {
                    case 'customers':
                        this.props.getCustomerList();
                        dropDownsList[key]['options'] = this.props.customerList
                        break;
                    case 'partners':
                        this.props.getCustomerList();
                        dropDownsList[key]['options'] = this.props.partnerList
                        break;
                    case 'locations':
                        this.props.getCustomerList();
                        dropDownsList[key]['options'] = this.props.locationList
                        break;
                    default: break;
                }
            })
        this.setState({
            dropDownsList: dropDownsList
        })
    }
    callDropDownApi_v2 = (type, val = "268") => {
        
        switch (type) {
            // case 'customers':
            //     this.props.getCustomerList();
            //     break;
            // case 'feemployees':
            //     this.props.getEmplyeesList();
            //     break;
            case 'locations':
                const data = {
                    "filters": {
                        "entityTypes": ["PARTNER"],
                        "entityIds": [Constants.PARTNER_ID],
                        "isConsiderCreatorId": false,
                        "fetchObjects": [],
                        "status": true
                    }
                }
                this.props.getLocationList(data);
                // this.props.getLocationList(val, "PARTNER");
                break;
            default: break
        }
    }

    getMetaDataProperties = (type) => {
        const formList = type.metaInfo?.formList.map(value => {
            if (value.type == 'button') {
                value.actions.map((action, k) => {
                    action['callback'] = this.formSubmission;
                })

                this.setState({ actionList: value.actions });

            }
            return value;
        })


        return {
            sampleDownloadLink: type.metaInfo?.sampleFormate,
            formList: formList ? formList : [],
            acceptTypes: type.metaInfo?.requestFileTypes,
            dropDownsList: type.metaInfo?.dropDowns || [],
            inputsList: type.metaInfo?.inputs || [],
            endPoint: type.metaInfo?.endPoint,
            payload: { ...type.metaInfo?.endPoint?.payload },
            showResponseFields: type.metaInfo?.showResponseFields,
            responseDataFormat: type.metaInfo?.responseDataFormat,
            visibilityFilters: type.metaInfo?.visibilityFilter

        }
    }

    onUploadFinish = (e, type, name) => {
        
        this.setState({
            s3FileLink: e,
            isFormValid: true,
            filename: name
        })

        this.props.form.setFieldsValue({
            [type]: e,
            "filename": name
        })
    }

    // postToUploadApi = () => {
    //     const _this = this;
    //     this.props.form.validateFieldsAndScroll((err, values) => {
    //         if (!err) {
    //             _this.setState({
    //                 btnStatus: 'PROCESSING...'
    //             })
    //             const payload = {
    //                 ..._this.state.payload,
    //                 s3FileLink: _this.state.s3FileLink,
    //                 filename: _this.state.filename,
    //                 ...values
    //             }
    //             if (_this.state.reportType) {
    //                 if (_this.state.endPoint) {
    //                     if (_this.state.endPoint.payloadProperty) {
    //                         delete payload.s3FileLink;
    //                     }
    //                     _this.props.getCustomApiCall(_this.state.endPoint.url, _this.state.endPoint.method, { ...payload, [_this.state.endPoint.payloadProperty]: _this.state.s3FileLink });
    //                 } else {
    //                     _this.props.uploadIngestFile(_this.state.reportType, payload)
    //                 }
    //             } else {
    //                 notifyApiError("One or more Field are empty.", "Error:")
    //             }
    //             console.log('Received values of form: ', values);
    //         }
    //     });
    // }

    formSubmission = (action) => {
        // const { getFieldValue, getFieldsValue } = this.props.form;        
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            this.props.changeDisable(true);
            // this.setState({disableAction: true})
            const data = this.props.form.getFieldsValue();

            const val = { ...values };
            val['report_type'] = this.state.reportType;
            val['filename'] = this.state?.filename;
            if (!val['filename']) delete val['filename'];
            this.props.formSubmission(this.state.payload, val, action);
        })
    }

    capitalize(comp) {
        return comp[0].toUpperCase() + comp.substring(1, comp.length);
    }

    optionHtmlCreator = (type, defaultValue, fetchFor) => {

        // if (!this.props.locationList && fetchFor === 'locations' && this.state.locationCalled) {
        //     this.setState({ locationCalled: false });
        //     const keys = defaultValue.map(k => k['key']);
        //     this.callDropDownApi_v2(fetchFor, keys.join(","));
        // }

        switch (type) {
            case 'customers':
                if (!this.props.customerList) {
                    this.callDropDownApi_v2(type);
                }
                return (
                    this.props?.customerList?.map(cust => (
                        <Select.Option value={cust.id} key={cust.id} label={cust.name} >
                            {cust.name}
                        </Select.Option>
                    ))
                )
            case 'partners':
                return (
                    this.props?.partnerList?.map(partner => {
                        if (partner.linkPartnerBO.name) {
                            return (
                                <Select.Option
                                    key={partner.linkPartnerBO.id}
                                    value={partner.linkPartnerBO.id}
                                    label={partner.linkPartnerBO.name}
                                >
                                    {partner.linkPartnerBO.name}
                                </Select.Option>
                            )
                        }
                    }

                    )
                )
            case 'locations':

                // let locationList = [];
                // if (this.props.locationList && this.props.locationList.length > 0) {
                //     locationList = this.props.locationList;
                //     let index = locationList.findIndex(x => x.id == "101")
                //     if (index === -1) {
                //         locationList.unshift({ name: "All", id: "101" });
                //     }

                // }

                // return (
                //     locationList?.map(loc => (
                //         <Select.Option key={loc.id} value={loc.id} label={loc.name}>
                //             {loc.name}
                //         </Select.Option>
                //     ))
                // )
                break;
            case 'feemployees':
                if (!this.props.employeeList) {
                    this.callDropDownApi_v2(type);
                }
                return (
                    this.props?.employeeList?.map(loc => (
                        <Select.Option key={loc.id} value={loc.id} label={loc.name}>
                            {loc.name}
                        </Select.Option>
                    ))
                )

            default:
                break;
        }
    }


    radioCreator = options => {
        let radios = [];
        let option = options[0];
        let keys = Object.keys(option);
        keys.map((v, i) => {
            radios.push(
                <Radio value={keys[i]} key={v + i}>{option[v]['value']}</Radio>
            )
        })

        return radios;
    }

    disabledDate = current => {

        if (this.state.startDate) {
            const dayStart = moment(this.state.startDate).add(-31, 'days');
            const dayEnd = moment(this.state.startDate).add(31, 'days');
            if (dayEnd.isAfter(moment().endOf('day'))) {
                return !(dayStart.isSameOrBefore(current) && current < moment().endOf('day'));
            } else {
                return !(dayStart.isSameOrBefore(current) && dayEnd.isAfter(current));
            }


        } else {
            // return current && current > moment().endOf('day');
        }

    }

    onDateChange = (date, dateString) => {
        if (date[0]) {
            this.setState({ startDate: date[0] })
        }
    }

    clearDate = property => {
        const { resetFields } = this.props.form;
        resetFields([property]);
        this.setState({ startDate: '' })
    }

    onChange = (val, fetchFor) => {
        switch (fetchFor) {
            case "locations":
                const locations = [...val];
                const values = locations.map(v => {
                    return v.key.toString();
                })
                console.log("values", values)
                const data = {
                    "filters": {
                        "entityTypes": ["PARTNER"],
                        "entityIds": values,
                        "isConsiderCreatorId": false,
                        "fetchObjects": [],
                        "status": true
                    }
                }
                // this.props.getLocationList(data);

                // this.props.getLocationList(values.join(","), "PARTNER");
                break;
            case "feemployees":
                this.props.getEmplyeesList();
                break;
            default:
                break;
        }
    }

    formHtmlCreator = (list, vFilters) => {
        let showSchemas = [];
        const { getFieldDecorator, getFieldValue, getFieldsValue } = this.props.form;
        return list.map((item, key) => {

            if (vFilters.length > 0 && vFilters?.includes(item.id)) {
                let payloadProperty = item.payloadProperty;
                let propertyValue = getFieldValue(payloadProperty) || item?.default;
                if (item.type === "radio") {
                    let option = item.radioOptions[0];
                    let keys = Object.keys(option);
                    if (keys.includes(propertyValue)) {
                        showSchemas = option[propertyValue]['visible'];
                    }

                }

            }
            switch (item.type) {
                case 'text':
                case 'email':
                    return <Row key={key}>
                        <Col span={24}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    rules: [
                                        {
                                            required: item.required,
                                            message: `Please enter your ${item.title}`
                                        },
                                        { pattern: item.type === 'email' ? new RegExp(validationRules.email) : '', message: 'Please input a valid email!' }
                                    ]
                                })(
                                    <Input type={item.type} placeholder={item.title} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>;

                case 'number':
                    return <Row key={key}>
                        <Col span={24}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.title, {
                                    rules: item.validations
                                })(
                                    <InputNumber />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>;

                case 'select':
                    if (showSchemas.length == 0 || showSchemas.includes(item.id)) {
                        let defaultValue = [];

                        if (item.fetchFor === "locations" && item?.defaultValue?.length > 0) {
                            item.defaultValue.map(v => {

                                if (v['key'] instanceof Array) {
                                    const partnerId = Constants.PARTNER_ID.toString();
                                    let index = v["key"].indexOf(partnerId);
                                    if (index >= 0) {

                                        defaultValue.push({ "key": partnerId, "label": v["label"] });
                                    }
                                } else {

                                    defaultValue.push(v);
                                }
                            })
                        }
                        if (item.fetchOption === "locations") {
                            // console.log("this", defaultValue)
                            return <Row key={key}>
                                <Col span={24}>
                                    <Form.Item label={this.capitalize(item.title)}>
                                        {getFieldDecorator(item.payloadProperty, {
                                            initialValue: defaultValue ? defaultValue : [],
                                            rules: item.validations
                                        })(
                                            <Select
                                                // onChange={item?.callback ? (val) => this.onChange(val, item.fetchFor) : () => { }}
                                                onSearch={this.fetchLocations}
                                                onBlur={this.selectBlur}
                                                showSearch
                                                labelInValue={true}
                                                mode={item.multiple ? 'multiple' : 'single'}
                                                placeholder={`Select ${item.title}`}
                                                style={{ width: '100%' }}
                                                // filterOption={(input, option) =>
                                                //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                // }
                                                filterOption={false}

                                            >
                                                <Select.Option key={101} value={101} label={"All"}>
                                                    All
                                                </Select.Option>
                                                {this.state.locationList?.map(loc => (
                                                    <Select.Option key={loc.id} value={loc.id} label={loc.name}>
                                                        {loc.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        } else {
                            return <Row key={key}>
                                <Col span={24}>
                                    <Form.Item label={this.capitalize(item.title)}>
                                        {getFieldDecorator(item.payloadProperty, {
                                            initialValue: defaultValue ? defaultValue : [],
                                            rules: item.validations
                                        })(
                                            <Select
                                                onChange={item?.callback ? (val) => this.onChange(val, item.fetchFor) : () => { }}
                                                showSearch
                                                labelInValue={true}
                                                mode={item.multiple ? 'multiple' : 'single'}
                                                placeholder={`Select ${item.title}`}
                                                style={{ width: '100%' }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }


                                            >
                                                {
                                                    this.optionHtmlCreator(item.fetchOption, defaultValue, item?.fetchFor)
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        }


                    } else {
                        return <></>;
                    }



                case 'file':
                    if (showSchemas.length == 0 || showSchemas.includes(item.id)) {
                        return <Row key={key}>
                            <Col span={24}>
                                {
                                    this.state.sampleDownloadLink ?
                                        <Row>
                                            <br />
                                            <div className="download-sample-data">
                                                <h5>For accurate results</h5>
                                                <p>
                                                    Download sample upload format
                                                    <a href={this.state.sampleDownloadLink} target='_blank'> here</a>
                                                </p>
                                            </div>
                                        </Row> : ''
                                }
                            </Col>
                            <Col span={24} className={item.required ? 'ant-form-item-required' : ''}>Select file:</Col>
                            <Col span={24}>
                                <Form.Item>
                                    {getFieldDecorator(item.payloadProperty, {
                                        rules: item.validations
                                    })(
                                        <FileUpload
                                            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                            directUpload={true}
                                            disableUploadButton={false}
                                            onUploadFinish={($events, name) => this.onUploadFinish($events, item.payloadProperty, name)}
                                            path="reports"
                                            acceptTypes={this.state.acceptTypes}
                                            objKey="reports"
                                            clearFileAfterUpload={this.state.clearFile}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    } else {
                        return <></>;
                    }

                case 'date':
                    if (showSchemas.length == 0 || showSchemas.includes(item.id)) {
                        return <Row key={key}>
                            <Col span={24}>
                                <Form.Item label={this.capitalize(item.title)}>
                                    {getFieldDecorator(item.payloadProperty, {
                                        rules: item.validations
                                    })(
                                        <RangePicker
                                            ranges={{}}
                                            allowClear={false}
                                            onCalendarChange={this.onDateChange}
                                            disabledDate={this.disabledDate}
                                            format="YYYY-MM-DD"
                                            renderExtraFooter={() => <Button type="primary" onClick={() => this.clearDate(item.payloadProperty)}>Clear</Button>}
                                        />

                                    )}
                                </Form.Item>
                            </Col>
                        </Row>;
                    } else {
                        return <></>
                    }

                case 'radio':
                    return <Row key={key}>
                        <Col span={24}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    initialValue: item?.default,
                                    rules: item.validations
                                })(
                                    <Radio.Group>
                                        {
                                            this.radioCreator(item.radioOptions)
                                        }
                                    </Radio.Group>

                                )}
                            </Form.Item>
                        </Col>
                    </Row>;
                default:
                    return null;
            }
        })
    }

    render() {
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <>


                <Row>
                    {
                        this.state.result.length > 0
                            ? this.state.result.map(result => (
                                <Alert style={{ margin: 10 }} message={`${result.key}: ${result.value ? result.value : 0}`} type={result.type} />
                            ))
                            : null
                    }
                </Row>
                <Form {...formItemLayout}>
                    <Row>
                        {/* <Col span={4}></Col> */}

                        <Col span={24}>

                            <Form.Item label={`Report Type:`}>
                                <Select
                                    showSearch
                                    placeholder="Select Report Type"
                                    style={{ width: '100%' }}
                                    onChange={this.handleReportChange}
                                    value={this.state.reportType}
                                >
                                    {
                                        this.props?.reportTypes?.map(type => (
                                            <Select.Option value={type.code} key={type.code} >{type.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>
                    {
                        this.formHtmlCreator(this.state.formList, this.state.visibilityFilters)
                    }

                    <Row>
                        <Col span={24}>
                            {/* <Button type="primary" onClick={() => this.postToUploadApi()} className="margin-top-15" disabled={!this.state.isFormValid || this.props.s3SyncStatus === S3_REPORT_STATUS.BEGIN}> */}
                            {/* <Button type="primary" onClick={() => this.postToUploadApi()} className="margin-top-15">
                                {
                                    this.props.s3SyncStatus === S3_REPORT_STATUS.BEGIN
                                        ? 'PROCESSING...'
                                        : this.props.s3SyncStatus === S3_REPORT_STATUS.FAILURE
                                            ? 'Re-Sync'
                                            : 'Submit'
                                }
                            </Button> */}
                            <FormSubmission loading={this.props.loadingDoc} isDisabled={this.props.disableAction} actions={this.state.actionList} />
                        </Col>
                    </Row>
                </Form>





            </>
        )
    }
}

const mapStateToProps = state => {

    return {
        reportTypes: state.ingest.reportType,
        // customerList: state.ingest.customerList,
        partnerList: state.header?.partnerHeaderDetails?.partners,
        locationList: state.ingest.locationList,
        // employeeList: state.ingest.employeeList,
        uploadedFileResp: state.ingest.uploadedFileResp,
        s3SyncStatus: state.app.s3Status,
        customApiResp: state.ingest.customApiResp,
        loading: state.ingest.loading
    }

};

const mapDispatchToProps = dispatch => ({
    uploadIngestFile: (param1, param2) => dispatch(actions.uploadIngestFile(param1, param2)),
    getReportTypes: (type) => dispatch(actions.getReportTypes(type)),
    setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    // getCustomerList: () => dispatch(actions.getCustomerList()),
    // getLocationList: (val, etypes) => dispatch(actions.getLocationList(val, etypes)),
    getLocationList: (data) => dispatch(actions.getLocationList(data)),

    getCustomApiCall: (url, method, payload) => dispatch(actions.customUrlApiCall(url, method, payload)),

    // getEmplyeesList: () => dispatch(actions.getEmplyeesList())
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ingest' })(FormBuilder));