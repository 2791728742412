import * as CONSTANTS from './constants';



const initialState = {
    pendingDocketLoading: true
};

export const coloaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.GET_ROUTES_LIST_BEGIN: return getRoutesBegin(state);
        case CONSTANTS.GET_ROUTES_LIST_SUCCESS: return getRoutesSuccess(state, action);
        case CONSTANTS.GET_ROUTES_LIST_FAILURE: return getRoutesFailure(state);

        case CONSTANTS.GET_ASSETS_LIST_BEGIN: return getAssetsBegin(state);
        case CONSTANTS.GET_ASSETS_LIST_SUCCESS: return getAssetsSuccess(state, action);
        case CONSTANTS.GET_ASSETS_LIST_FAILURE: return getAssetsFailure(state);

        case CONSTANTS.GET_CUSTOMER_LIST_BEGIN: return getCustomerListBegin(state);
        case CONSTANTS.GET_CUSTOMER_LIST_SUCCESS: return getCustomerListSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_LIST_FAILURE: return getCustomerListFailure(state);


        case CONSTANTS.GET_CUSTOMER_SHIPPER_LIST_BEGIN: return getCustomerShipperListBegin(state);
        case CONSTANTS.GET_CUSTOMER_SHIPPER_LIST_SUCCESS: return getCustomerShipperListSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_SHIPPER_LIST_FAILURE: return getCustomerShipperListFailure(state);


        case CONSTANTS.GET_COLOADER_LIST_BEGIN: return getColoaderListBegin(state);
        case CONSTANTS.GET_COLOADER_LIST_SUCCESS: return getColoaderListSuccess(state, action);
        case CONSTANTS.GET_COLOADER_LIST_FAILURE: return getColoaderListFailure(state);

        case CONSTANTS.GET_SIMPROVIDERS_LIST_BEGIN: return getServiceProvidersBegin(state);
        case CONSTANTS.GET_SIMPROVIDERS_LIST_SUCCESS: return getServiceProvidersSuccess(state, action);
        case CONSTANTS.GET_SIMPROVIDERS_LIST_FAILURE: return getServiceProvidersFailure(state);

        case CONSTANTS.GET_DOCKETS_LIST_BEGIN: return getDocketListBegin(state);
        case CONSTANTS.GET_DOCKETS_LIST_SUCCESS: return getDocketListSuccess(state, action);
        case CONSTANTS.GET_DOCKETS_LIST_FAILURE: return getDocketListFailure(state, action);

        case CONSTANTS.GET_CO_LOADER_DOCKETS_LIST_BEGIN: return getCoLoaderDocketListBegin(state);
        case CONSTANTS.GET_CO_LOADER_DOCKETS_LIST_SUCCESS: return getCoLoaderDocketListSuccess(state, action);
        case CONSTANTS.GET_CO_LOADER_DOCKETS_LIST_FAILURE: return getCoLoaderDocketListFailure(state, action);


        case CONSTANTS.GET_DOCKETS_SUMMARY_BEGIN: return getDocketSummaryBegin(state);
        case CONSTANTS.GET_DOCKETS_SUMMARY_SUCCESS: return getDocketSummarySuccess(state, action);
        case CONSTANTS.GET_DOCKETS_SUMMARY_FAILURE: return getDocketSummaryFailure(state, action);

        case CONSTANTS.GET_TRIP_CREATE_BEGIN: return getTripCreateBegin(state);
        case CONSTANTS.GET_TRIP_CREATE_SUCCESS: return getTripCreateSuccess(state, action);
        case CONSTANTS.GET_TRIP_CREATE_FAILURE: return getTripCreateFailure(state, action);

        case CONSTANTS.GET_TRIP_LIST_BEGIN: return getTripListBegin(state);
        case CONSTANTS.GET_TRIP_LIST_SUCCESS: return getTripListSuccess(state, action);
        case CONSTANTS.GET_TRIP_LIST_FAILURE: return getTripListFailure(state, action);

        case CONSTANTS.GET_TRIP_HANDOVER_BEGIN: return getTripHandoverBegin(state);
        case CONSTANTS.GET_TRIP_HANDOVER_SUCCESS: return getTripHandoverSuccess(state, action);
        case CONSTANTS.GET_TRIP_HANDOVER_FAILURE: return getTripHandoverFailure(state, action);

        case CONSTANTS.GET_TRIP_MARKDEL_BEGIN: return getTripMarkDelBegin(state);
        case CONSTANTS.GET_TRIP_MARKDEL_SUCCESS: return getTripMarkDelSuccess(state, action);
        case CONSTANTS.GET_TRIP_MARKDEL_FAILURE: return getTripMarkDelFailure(state, action);


        case CONSTANTS.GET_TRIP_START_BEGIN: return getTripStartBegin(state);
        case CONSTANTS.GET_TRIP_START_SUCCESS: return getTripStartSuccess(state, action);
        case CONSTANTS.GET_TRIP_START_FAILURE: return getTripStartFailure(state, action);


        case CONSTANTS.GET_TRIP_START_MANIFEST_BEGIN: return getTripStartManifestBegin(state);
        case CONSTANTS.GET_TRIP_START_MANIFEST_SUCCESS: return getTripStartManifestSuccess(state, action);
        case CONSTANTS.GET_TRIP_START_MANIFEST_FAILURE: return getTripStartManifestFailure(state, action);

        case CONSTANTS.GET_TRIP_END_BEGIN: return getTripEndBegin(state);
        case CONSTANTS.GET_TRIP_END_SUCCESS: return getTripEndSuccess(state, action);
        case CONSTANTS.GET_TRIP_END_FAILURE: return getTripEndFailure(state, action);

        case CONSTANTS.GET_VENDORS_LIST_BEGIN: return getVendorsListBegin(state);
        case CONSTANTS.GET_VENDORS_LIST_SUCCESS: return getVendorsListSuccess(state, action);
        case CONSTANTS.GET_VENDORS_LIST_FAILURE: return getVendorsListFailure(state, action);

        case CONSTANTS.GET_VENDOR_BLOCKED_REASON_BEGIN: return getVendorBlockBegin(state);
        case CONSTANTS.GET_VENDOR_BLOCKED_REASON_SUCCESS: return getVendorBlockSuccess(state, action);
        case CONSTANTS.GET_VENDOR_BLOCKED_REASON_FAILURE: return getVendorBlockFailure(state, action);


        case CONSTANTS.GET_LOCATION_GROUPS_BEGIN: return getLocationGroupsBegin(state);
        case CONSTANTS.GET_LOCATION_GROUPS_SUCCESS: return getLocationGroupsSuccess(state, action);
        case CONSTANTS.GET_LOCATION_GROUPS_FAILURE: return getLocationGroupsFailure(state, action);


        case CONSTANTS.GET_PARTNER_ASSETS_BEGIN: return getPartnerAssetBegin(state);
        case CONSTANTS.GET_PARTNER_ASSETS_SUCCESS: return getPartnerAssetSuccess(state, action);
        case CONSTANTS.GET_PARTNER_ASSETS_FAILURE: return getPartnerAssetFailure(state, action);


        case CONSTANTS.GET_EDIT_TRIP_UPDATE_BEGIN: return getTripEditUpdateBegin(state);
        case CONSTANTS.GET_EDIT_TRIP_UPDATE_SUCCESS: return getTripEditUpdateSuccess(state, action);
        case CONSTANTS.GET_EDIT_TRIP_UPDATE_FAILURE: return getTripEditUpdateFailure(state, action);

        case CONSTANTS.FETCH_TRIP_SETTLEMENTS_BEGIN: return fetchTripSettlementsBegin(state);
        case CONSTANTS.FETCH_TRIP_SETTLEMENTS_SUCCESS: return fetchTripSettlementsSuccess(state, action);
        case CONSTANTS.FETCH_TRIP_SETTLEMENTS_FAILURE: return fetchTripSettlementsFailure(state, action);


        case CONSTANTS.GET_TRIP_CONSOLIDATE_BEGIN: return getTripConsolidateBegin(state);
        case CONSTANTS.GET_TRIP_CONSOLIDATE_SUCCESS: return getTripConsolidateSuccess(state, action);
        case CONSTANTS.GET_TRIP_CONSOLIDATE_FAILURE: return getTripConsolidateFailure(state, action);
        case CONSTANTS.GET_CONSOLIDATE_RESET: return getConsolidateReset(state);


        case CONSTANTS.GET_TRIP_PRINT_BEGIN: return getTripPrintBegin(state);
        case CONSTANTS.GET_TRIP_PRINT_SUCCESS: return getTripPrintSuccess(state, action);
        case CONSTANTS.GET_TRIP_PRINT_FAILURE: return getTripPrintFailure(state, action);
        case CONSTANTS.GET_TRIP_PRINT_RESET: return getTripPrintReset(state);


        case CONSTANTS.GET_DOCKET_LIST_LOADING_FALSE: return getCloseDocketList(state);
        case CONSTANTS.GET_DOCKET_LIST_LOADING_TRUE: return getCloseDocketListTrue(state);

        case CONSTANTS.GET_COLOADER_PARTNERS_LIST_BEGIN: return getCoLoaderPartnersListBegin(state);
        case CONSTANTS.GET_COLOADER_PARTNERS_LIST_SUCCESS: return getCoLoaderPartnersListSuccess(state, action);
        case CONSTANTS.GET_COLOADER_PARTNERS_LIST_FAILURE: return getCoLoaderPartnersListFailure(state, action);

        case CONSTANTS.GET_RESET_CREATE_TRIP_RESET: return resetCreateTrip(state);

        case CONSTANTS.SET_INITIAL_STATE: return setInitialState();



        case CONSTANTS.GET_TRIP_RATE_CARD_BEGIN: return getTripRateCardBegin(state);
        case CONSTANTS.GET_TRIP_RATE_CARD_SUCCESS: return getTripRateCardSuccess(state, action);
        case CONSTANTS.GET_TRIP_RATE_CARD_FAILURE: return getTripRateCardFailure(state, action);





        default:
            return state;
    }
}

function setInitialState() {
    return {}
}

function getTripRateCardBegin(state) {
    return {
        ...state,
        rateCardLoading: true
    }
}

function getTripRateCardSuccess(state, action) {
    return {
        ...state,
        rateCard: action.payload
    }
}

function getTripRateCardFailure(state) {
    return {
        ...state,
        rateCardLoading: false
    }
}

function getRoutesBegin(state) {
    return {
        ...state,
        routeLoading: true
    }
}

function getRoutesSuccess(state, action) {
    return {
        ...state,
        routes: action.payload
    }
}

function getRoutesFailure(state) {
    return {
        ...state,
        routeLoading: false
    }
}

function getAssetsBegin(state) {
    return {
        ...state,
        assetLoading: true
    }
}

function getAssetsSuccess(state, action) {    
    return {
        ...state,
        assets: action.payload,
        assetLoading: false
    }
}

function getAssetsFailure(state) {
    return {
        ...state,
        assetLoading: false
    }
}

function getCustomerListBegin(state) {
    return {
        ...state,
        customerLoading: true
    }
}

function getCustomerListSuccess(state, action) {
    return {
        ...state,
        customers: action.payload,
        customerLoading: false
    }
}

function getCustomerListFailure(state) {
    return {
        ...state,
        customerLoading: false
    }
}


function getCustomerShipperListBegin(state) {
    return {
        ...state,
        shipperLoading: true
    }
}

function getCustomerShipperListSuccess(state, action) {
    return {
        ...state,
        shippers: action.payload,
        shipperLoading: false
    }
}

function getCustomerShipperListFailure(state) {
    return {
        ...state,
        shipperLoading: false
    }
}


function getColoaderListBegin(state) {
    return {
        ...state,
        coloaderloading: true
    }
}

function getColoaderListSuccess(state, action) {
    return {
        ...state,
        coloaders: action.payload,
        coloaderloading: false
    }
}

function getColoaderListFailure(state) {
    return {
        ...state,
        coloaderloading: false
    }
}

function getServiceProvidersBegin(state) {
    return {
        ...state,
        sprovidersLoading: true
    }
}

function getServiceProvidersSuccess(state, action) {
    return {
        ...state,
        sproviders: action.payload,
        sprovidersLoading: false
    }
}

function getServiceProvidersFailure(state) {
    return {
        ...state,
        sprovidersLoading: false
    }
}

function getCloseDocketList(state) {
    // console.log("state->>", state.pendingDocketLoading)
    return {
        ...state,
        pendingDocketLoading: false
    }
}

function getCloseDocketListTrue(state) {
    // console.log("state->>", state.pendingDocketLoading)
    return {
        ...state,
        pendingDocketLoading: true
    }
}

function getDocketListBegin(state) {
    return {
        ...state,
        docketLoading: true,        
        dockets: [],
        docketError: null
    }
}

function getDocketListSuccess(state, action) {
    return {
        ...state,
        dockets: action.payload,
        docketLoading: false,
        docketError: null
    }
}

function getDocketListFailure(state, action) {
    return {
        ...state,
        docketLoading: false,
        docketError: action.payload
    }
}

function getCoLoaderDocketListBegin(state) {
    return {
        ...state,
        docketLoading: true,
        dockets: [],
        docketError: null
    }
}

function getCoLoaderDocketListSuccess(state, action) {
    return {
        ...state,
        dockets: action.payload,
        docketLoading: false,
        docketError: null
    }
}

function getCoLoaderDocketListFailure(state, action) {
    return {
        ...state,
        docketLoading: false,
        docketError: action.payload
    }
}

function getDocketSummaryBegin(state) {
    return {
        ...state,
        docketSummaryLoading: true,
        docketsSummary: [],
        docketSummaryError: null
    }
}

function getDocketSummarySuccess(state, action) {
    return {
        ...state,
        docketsSummary: action.payload,
        docketSummaryLoading: false,
        docketSummaryError: null
    }
}

function getDocketSummaryFailure(state, action) {
    return {
        ...state,
        docketSummaryLoading: false,
        docketSummaryError: action.payload
    }
}


function getTripCreateBegin(state) {
    return {
        ...state,
        createTripLoading: true,
        createTripError: null
    }
}

function getTripCreateSuccess(state, action) {
    console.log("flow", state)
    return {
        ...state,
        createTripFlow: action.payload,
        createTripLoading: false,
        createTripError: null
    }
}

function getTripCreateFailure(state, action) {
    return {
        ...state,
        createTripLoading: false,
        createTripError: action.payload
    }
}


function getTripListBegin(state) {
    return {
        ...state,
        tripListLoading: true,
        endTripData: undefined,
        tripListError: null
    }
}

function getTripListSuccess(state, action) {
    
    return {
        ...state,
        tripsLists: action.payload,
        tripListLoading: false,
        tripListError: null
    }
}

function getTripListFailure(state, action) {
    return {
        ...state,
        tripListLoading: false,
        tripListError: action.payload
    }
}

function getTripHandoverBegin(state) {
    return {
        ...state,
        handOverLoading: true,
        handOverError: null
    }
}

function getTripHandoverSuccess(state, action) {
    return {
        ...state,
        handOverData: action.payload,
        handOverLoading: false,
        handOverError: null
    }
}

function getTripHandoverFailure(state, action) {
    return {
        ...state,
        handOverLoading: false,
        handOverError: action.payload
    }
}


function getTripMarkDelBegin(state) {
    return {
        ...state,
        markDelLoading: true,
        markDelError: null
    }
}

function getTripMarkDelSuccess(state, action) {
    return {
        ...state,
        markDelData: action.payload,
        markDelLoading: false,
        markDelError: null
    }
}

function getTripMarkDelFailure(state, action) {
    return {
        ...state,
        markDelLoading: false,
        markDelError: action.payload
    }
}

function getTripStartBegin(state) {
    return {
        ...state,
        startTripLoading: true,
        startTripError: null
    }
}

function getTripStartSuccess(state, action) {
    return {
        ...state,
        startTripData: action.payload,
        startTripLoading: false,
        startTripError: null
    }
}

function getTripStartFailure(state, action) {
    return {
        ...state,
        startTripLoading: false,
        startTripError: action.payload
    }
}


function getTripEndBegin(state) {
    return {
        ...state,
        EndTripLoading: true,
        endTripError: null
    }
}

function getTripEndSuccess(state, action) {
    return {
        ...state,
        endTripData: action.payload,
        EndTripLoading: false,
        endTripError: null
    }
}

function getTripEndFailure(state, action) {
    return {
        ...state,
        EndTripLoading: false,
        endTripError: action.payload
    }
}


function getTripStartManifestBegin(state) {
    return {
        ...state,
        startTripManifestLoading: true,
        startTripError: null
    }
}

function getTripStartManifestSuccess(state, action) {
    return {
        ...state,
        startTripManifestData: action.payload,
        startTripManifestLoading: false,
        startTripManifestError: null
    }
}

function getTripStartManifestFailure(state, action) {
    return {
        ...state,
        startTripManifestLoading: false,
        startTripManifestError: action.payload
    }
}

function getVendorsListBegin(state) {
    return {
        ...state,
        vendorsLoading: true,
        vendorData: [],
        vendorError: null
    }
}

function getVendorsListSuccess(state, action) {
    return {
        ...state,
        vendorData: action.payload,
        vendorsLoading: false,
        vendorError: null
    }
}

function getVendorsListFailure(state, action) {
    return {
        ...state,
        vendorsLoading: false,
        vendorError: action.payload
    }
}


function getVendorBlockBegin(state) {
    return {
        ...state,
        vendorBlockLoading: true,
        vendorBlockReason: {},        
    }
}

function getVendorBlockSuccess(state, action) {
    return {
        ...state,
        vendorBlockReason: action.payload,
        vendorBlockLoading: false        
    }
}

function getVendorBlockFailure(state, action) {
    return {
        ...state,
        vendorBlockLoading: false        
    }
}

function getLocationGroupsBegin(state) {
    return {
        ...state,
        locationGroupLoading: true,
        locationGroups: [],
        locationGroupError: null
    }
}

function getLocationGroupsSuccess(state, action) {
    return {
        ...state,
        locationGroups: action.payload,
        locationGroupLoading: false,
        locationGroupError: null
    }
}

function getLocationGroupsFailure(state, action) {
    return {
        ...state,
        locationGroupLoading: false,
        locationGroupError: action.payload
    }
}

function getPartnerAssetBegin(state) {
    return {
        ...state,
        partnerAssetLoading: true,
        partnerAssets: [],
        partnerAssetError: null
    }
}

function getPartnerAssetSuccess(state, action) {
    return {
        ...state,
        partnerAssets: action.payload,
        partnerAssetLoading: false,
        partnerAssetError: null
    }
}

function getPartnerAssetFailure(state, action) {
    return {
        ...state,
        partnerAssetLoading: false,
        partnerAssetError: action.payload
    }
}

function getTripEditUpdateBegin(state) {
    return {
        ...state,
        editTripUpdateLoding: true,
        editTripUpdtaeData: [],
        editTripUpdateError: null
    }
}

function getTripEditUpdateSuccess(state, action) {
    return {
        ...state,
        editTripUpdtaeData: action.payload,
        editTripUpdateLoding: false,
        editTripUpdateError: null
    }
}

function getTripEditUpdateFailure(state, action) {
    return {
        ...state,
        editTripUpdateLoding: false,
        editTripUpdateError: action.payload
    }
}

function fetchTripSettlementsBegin(state) {
    return {
        ...state,
        tripSettlements: {
            tripSettlementLoding: true,
            tripSettlementsBO: []
        },
    }
}

function fetchTripSettlementsSuccess(state, action) {
    return {
        ...state,
        tripSettlements: {
            tripSettlementLoding: false,
            tripSettlementError: null,
            ...action.payload
        },
    }
}

function fetchTripSettlementsFailure(state, action) {
    return {
        ...state,
        tripSettlements: {
            tripSettlementLoding: false,
            tripSettlementError: action.payload,
            tripSettlementsBO: []
        },
    }
}

function getTripConsolidateBegin(state) {
    return {
        ...state,
        consolidateLoading: true
    }
}

function getTripConsolidateSuccess(state, action) {
    return {
        ...state,
        consolidateLoading: false,
        consolidateData: action.payload
    }
}

function getTripConsolidateFailure(state, action) {
    return {
        ...state,
        consolidateLoading: false,
        consolidateError: action.payload
    }
}

function getConsolidateReset(state) {
    return {
        ...state,
        consolidateData: undefined
    }
}

function getTripPrintBegin(state) {
    return {
        ...state,
        printEwayLoading: true
    }
}

function getTripPrintSuccess(state, action) {
    return {
        ...state,
        printEwayLoading: false,
        printEwayData: action.payload
    }
}

function getTripPrintFailure(state, action) {
    return {
        ...state,
        printEwayLoading: false,
        printEwayError: action.payload
    }
}

function getTripPrintReset(state) {
    return {
        ...state,
        printEwayLoading: false,
        printEwayData: undefined
    }
}


function getCoLoaderPartnersListBegin(state) {
    return {
        ...state,
        coloaderPartnerLoading: true,
        partners: []        
    }
}

function getCoLoaderPartnersListSuccess(state, action) {    
    return {
        ...state,
        partners: action.payload
    }
}

function getCoLoaderPartnersListFailure(state, action) {
    return {
        ...state,
        coloaderPartnerLoading: false,
        coloadpartnerError: action.payload
    }
}

function resetCreateTrip(state) {
    
    return {
        ...state,
        createTripFlow: undefined,
        createTripError: undefined
    }
}