import React from "react";
import { Icon, Modal } from "antd";

export const ChargesTitle = props => {
    return (
        <div className="flex-box flex-gap-l">
            <div onClick={_ => {
                if (props.readonly) {
                    return props.onBack();
                }
                Modal.confirm({
                    title   : props.chargesType,
                    content : "Are you sure to cancel?",
                    onOk    : _ => {
                        Modal.destroyAll();
                        props.onBack();
                    }
                })
            }} className="cursor-pointer">  <Icon type="arrow-left" /> </div>
            <div> { props.chargesType } </div>
            <div className="font-size-header light-grey-text" style={{marginTop:"-6px"}}> . </div>
            <div className="light-grey-text"> {props.name} </div>
        </div>
    )
}

export const validateSLARules = slaRuleBOs => {

    const validationObjects = [];

    var maxFromIndex = 0;
    var maxFrom = -1;
    if (slaRuleBOs && slaRuleBOs?.length > 0) {
        slaRuleBOs.map((slaRule, index) => {
            if (maxFrom < slaRule.fromTime) {
                maxFrom = slaRule.fromTime;
                maxFromIndex = index;
            }
            
            if (slaRule.fromTime >= slaRule.toTime) {
                validationObjects.push([ slaBOErrors.FROM_GREATER, "slaRuleBOs." + index + ".fromTime"]);
                validationObjects.push([ slaBOErrors.FROM_GREATER, "slaRuleBOs." + index + ".toTime"]);
            }
    
            slaRuleBOs.map((checkRate, checkIndex) => {
                if (checkRate.fromTime <= slaRule.fromTime && checkRate.toTime >= slaRule.fromTime && index != checkIndex) {
                    validationObjects.push([ slaBOErrors.INVALID_FROM, "slaRuleBOs." + index + ".fromTime"]);
                }
            })
        })
        
        const maxtoTime = "slaRuleBOs." + maxFromIndex + ".toTime";
        validationObjects.map((v, index) => {
            if (v[1] == maxtoTime && v[0] === slaBOErrors.TO_MISSING) {
                validationObjects[index] = [false, false]
            }
        })
    }
    else {
        validationObjects.push([ slaBOErrors.RATEBO_EMPTY, "slaRuleBOs"]);
    }

    return validationObjects;
}

export const isLaneMappingBOsUpdated = (original, updated) => {
    if (original?.length !== updated?.length) {
        return true;
    }
    var laneUpdated = false;
    original.map(lane => {
        if (!lane?.id) {
            laneUpdated = true;
        }
        const indexedLane = updated.find(l => l.index === lane.index);
        if (indexedLane?.entityBO?.id !== lane?.entityBO?.id) {
            laneUpdated = true;
        }
    })
    return laneUpdated;
}

const slaBOErrors = {
    SLA_TIME         : "SLA time cannot be empty!",
    FROM_MISSING     : "From time of a SLA Rule cannot be empty!",
    TO_MISSING       : "To time of a SLA Rule cannot be empty!",
    INVALID_FROM     : "SLA slab is overlapping!",
    FROM_GREATER     : "To time cannot be eariler than From time!"
}