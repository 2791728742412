import React, { Component } from 'react'
import { Form, Card, Row, Col, Steps, DatePicker, Divider, Select, Input, Button, Radio, InputNumber, Collapse, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { getProductTypes } from './actions';
import { generateWaybillID } from '../b2b-lite-booking/actions';
import { Constants, validationRules } from '../../../../common/utils/constants';
import { notifyApiError } from '../../../../common/utils/utils';
import ScanService from '../../../../services/inbound/scan.service';
import CustomerService from '../../../../services/settings/customer.service';
import { withRouter } from 'react-router-dom';
import MiscService from '../../../../services/misc.service';
import { isEmpty } from 'lodash';

const scanService = new ScanService;
const miscService = MiscService
const customerService = CustomerService

class B2CBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productTypes: [],
            paymentTypes: Constants.b2cPaymentTypes,
            modes: Constants.modes,
            billToOptions: Constants.billTo,
            pickupDateRange: {
                from: moment().subtract('1', 'month'),
                to: moment()
            },
            isCodAmtRequired: false,
            formId: null,
            redirectionUrl: '/appv2/inbound/dashboard/scan',
            formLoading: false
        }
    }

    componentDidMount() {
        this.getProductTypes()
        this.getCustomers()
        this.onPaymentTypeChange();
        this.setState({
            formId: Number(this.props.match?.params?.id),
            redirectionUrl: !!Number(this.props.match?.params?.id) ? '/appv2/inventory/dashboard/awb' : '/appv2/inbound/dashboard/scan'
        }, () => {
            if (this.state.formId) {
                scanService.getConsignment(this.state.formId).then((resp) => {
                    if (resp.data && resp.data.response.consignments[0]) {
                        const consignment = resp.data.response.consignments[0];
                        this.setState({
                            consignmentData: consignment
                        })
                        const shipmentData = consignment.shipmentBOs[0];
                        const codAmount = (consignment.payableAmount != null && consignment.payableAmount > 0) ?
                            consignment.payableAmount : null;
                        this.props.form.setFieldsValue({
                            pickupDate: moment(new Date(consignment.pickupDate)),
                            waybillNo: consignment.waybillNo,
                            productType: parseInt(shipmentData.productType, 0),
                            orderRefNo: consignment.orderRefNo,
                            paymentType: (consignment.payableAmount != null && consignment.payableAmount > 0) ?
                                this.state.paymentTypes[1].value : this.state.paymentTypes[0].value,
                            consignmentMode: consignment.consignmentMode,
                            description: consignment.description
                        });
                        // this.pickupDate = moment(new Date(consignment.pickupDate));
                        this.patchContactForm(consignment.shipper, 'shipper');
                        this.onPincodeChange('shipper', { target: { value: consignment.shipper.pincode.zipcode } })
                        this.props.form.setFieldsValue({
                            phoneNumber: consignment.shipper.phoneNumber,
                            customer: consignment.customer.id
                        });
                        this.patchContactForm(consignment.consignee, 'consignee');
                        this.onPincodeChange('consignee', { target: { value: consignment.consignee.pincode.zipcode } })
                        let billTo;
                        if (consignment.shipper.hashVaue === consignment.biller.hashVaue) {
                            billTo = this.state.billToOptions[0].value;
                        } else {
                            billTo = this.state.billToOptions[1].value;
                        }
                        this.props.form.setFieldsValue({
                            cphoneNumber: consignment.consignee.phoneNumber,
                            billTo: billTo
                        });
                        let dimensions = [];
                        if (shipmentData.dimension != null) {
                            dimensions = shipmentData.dimension.split('*');
                        }
                        this.props.form.setFieldsValue({
                            value: shipmentData.shipmentAmount,
                            count: shipmentData.count,
                            l: (dimensions.length) ? dimensions[0] : 0,
                            b: (dimensions.length) ? dimensions[1] : 0,
                            h: (dimensions.length) ? dimensions[2] : 0,
                            weight: shipmentData.weight,
                            codAmount
                        });
                        this.dimensionChange();
                    }
                });
            }
        })
    }

    allowedDates = (currentDate) => {
        const { from, to } = this.state.pickupDateRange;
        return (currentDate <= from || currentDate >= to)
    }

    onPickupDateChange = (value) => {
        const difference = moment(value).diff(moment(), 'days', true);
        if ((-31 < difference) && (difference < 0)) {
        } else {
            notifyApiError('Pickup date should be today or less than 30 days');
            this.props.form.setFieldsValue({
                pickupDate: undefined
            })
        }
        this.props.form.setFieldsValue(
            { pickupDate: moment(value).subtract(1, 'month') }
        )
    }

    componentDidUpdate(prevProps) {

    }

    getProductTypes = () => {
        this.props.getProductTypes().then(resp => {
            if (resp.data.response.productTypes)
                this.setState({
                    productTypes: resp.data.response.productTypes
                })
        })
    }

    getCustomers() {
        customerService.getCustomers().then((resp) => {
            if (resp.data != null) {
                this.setState({
                    customers: resp.data.response?.customers
                })
            }
        });
    }

    onGenerateWaybill = () => {
        if (this.state.editForm) { return false; }
        const productType = this.props.form.getFieldValue('productType');
        if (!productType) {
            notifyApiError('Please select product type before generating waybill', 'Validation');
            return false;
        }
        const payload = { productTypeId: productType };
        scanService.getAwbCode(payload).then((response) => {
            if (response.data != null) {
                this.props.form.setFieldsValue({
                    'waybillNo': response.data.response.waybill
                });
            }
        });
    }

    onPaymentTypeChange = () => {
        const payableAmount = Number(this.props.form.getFieldValue('value'));
        if (payableAmount != null && payableAmount > 0) {
            this.setState({
                isCodAmtRequired: true
            })
            //     codAmountObj.setValidators([Validators.required, Validators.pattern(Constants.validationRules.moreThanZero)]);
        } else {
            this.setState({
                isCodAmtRequired: false
            })
            //     codAmountObj.setValidators([Validators.pattern(Constants.validationRules.moreThanZero)]);
        }
    }

    onBookingSubmit = e => {
        const that = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const formAction = (that.state.formId) ? 'UPDATE' : 'CREATE';
                const sessionUser = Constants.sessionUser;
                const consignee = {
                    'contactType': 'CUSTOMER',
                    'name': values.cname,
                    'emailId': values.cemailId,
                    'address': {
                        'address1': values.caddress1,
                        'address2': values.caddress2,
                        'address3': values.caddress3,
                        'landmark': values.clandmark,
                        'pincode': that.state.cpincodeObj,
                    },
                    'phoneNumber': values.cphoneNumber,
                    'pincode': that.state.cpincodeObj
                };
                const shipperAddress = values.address.split(',');
                const address = {};
                address['address1'] = shipperAddress[0].trim();
                if (shipperAddress.length > 1) {
                    address['landmark'] = shipperAddress[shipperAddress.length - 1].trim();
                    address['address2'] = (shipperAddress.length > 2) ? shipperAddress[1].trim() : null;
                    address['address3'] = (shipperAddress.length > 3) ? shipperAddress[2].trim() : null;
                }
                address['pincode'] = that.state.pincodeObj;
                const shipper = {
                    'contactType': 'CUSTOMER',
                    'name': values.name,
                    'emailId': values.emailId,
                    'address': address,
                    'phoneNumber': values.phoneNumber,
                    'pincode': that.state.pincodeObj
                };
                if (that.state.formId) {
                    consignee['id'] = that.state.consignmentData.consignee.id;
                    shipper['id'] = that.state.consignmentData.shipper.id;
                }
                const shipment = [{
                    'count': values.count,
                    'goodsType': null,
                    'productType': values.productType,
                    'dimension': values.l + '*' + values.b + '*' + values.h,
                    'dimensionUnit': 'cm',
                    'weight': values.weight,
                    'weightUnit': 'kg',
                    'shipmentCurrency': 'INR',
                    'shipmentStatus': 'BOOKED',
                    'shipmentAmount': values.value
                }];
                if (that.state.formId) {
                    shipment[0]['id'] = that.state.consignmentData.shipmentBOs[0].id;
                }
                const customer = (values.customer == null) ? { id: 1 } : { id: values.customer };
                const bookingDate = new Date();
                const waybillNo = values.waybillNo.trim();
                const payloadData = {
                    'request': [{
                        'consignment': {
                            'waybillNo': waybillNo,
                            'location': sessionUser.location,
                            'partner': sessionUser.partner,
                            'customer': customer,
                            'consignee': consignee,
                            'biller': (values.billTo === 'SHIPPER') ? shipper : consignee,
                            'shipper': shipper,
                            'pickupDate': moment(values.pickupDate).valueOf(),
                            'bookingOfficeLoc': sessionUser.location,
                            'productType': values.productType,
                            'pincode': sessionUser.location.address.pincode,
                            'consignmentStatus': 'BOOKED',
                            'paymentType': null,
                            'consignmentMode': values.consignmentMode,
                            'description': values.description,
                            'consignmentCurrency': 'INR',
                            'shipmentBOs': shipment,
                            'orderRefNo': values.orderRefNo,
                            'payableAmount': (values.paymentType === that.state.paymentTypes[1].value) ? values.codAmount : 0,
                            'payableCurrency': 'INR',
                            'consignmentAmount': values.value,
                            'consignmentType': 'B2C'
                        },
                        'action': formAction,
                        'referenceId': waybillNo
                    }]
                };
                if (that.state.formId) {
                    payloadData['request'][0]['consignment']['id'] = that.state.formId;
                } else {
                    payloadData['request'][0]['consignment']['bookingDate'] = bookingDate.getTime();
                }
                scanService.consignmentUpdate(payloadData).then((response) => {
                    if (response.data != null) {
                        if (miscService.validateSyncData(response.data.response.responses) || response.data.response.length > 1) {
                            // that.router.navigateByUrl(that.state.redirectionUrl);
                            that.props.history.push(that.state.redirectionUrl)
                        }
                    }
                });
            }

            this.setState({
                formLoading: false
            })
        })
    }

    onPhoneNumberChange(type, e) {
        const phoneNumberObj = e.target.value
        if (phoneNumberObj?.length === 10)
            miscService.getContactDetails(phoneNumberObj).then(resp => {
                const contactDetails = resp.data.response?.recentContactBO ? resp.data.response?.recentContactBO : {};
                if (!isEmpty(contactDetails)) {
                    this.patchContactForm(contactDetails, type);
                }
            });
    }

    onPincodeChange = (type, e) => {
        const pincodeObj = e.target.value;
        if (pincodeObj?.length === 6) {
            miscService.getPincodeDetails(pincodeObj).then(resp => {
                if (resp && resp.data.response.pincodedetails) {
                    const pincodeDetails = resp.data.response.pincodedetails[pincodeObj];
                    this.props.form.setFieldsValue({
                        [(type === 'shipper') ? 'city' : 'ccity']: pincodeDetails.city.name,
                        [(type === 'shipper') ? 'state' : 'cstate']: pincodeDetails.city.state,
                    });
                    this.setState({
                        [(type === 'shipper') ? 'pincodeObj' : 'cpincodeObj']: pincodeDetails
                    })
                } else {
                    this.props.form.setFieldsValue({ city: null, state: null, pincodeObj: null, pincode: null });
                    notifyApiError('Invalid Pincode');
                }
            });
        }
    }

    dimensionChange() {
        if (!this.props.configurations) { return false; }
        let volumetricWeight = null;
        let chargebleWeight = null;
        const count = Number(this.props.form.getFieldValue('count'));
        const l = Number(this.props.form.getFieldValue('l'));
        const b = Number(this.props.form.getFieldValue('b'));
        const h = Number(this.props.form.getFieldValue('h'));
        const weight = Number(this.props.form.getFieldValue('weight'));
        if (l && b && h && weight && count) {
            volumetricWeight = (
                Number(l) *
                Number(b) *
                Number(h) *
                Number(count)) / Number(this.props.configurations.volumetricConversion || 1);
            chargebleWeight = (weight > volumetricWeight) ? this.props.form.getFieldValue('weight') : volumetricWeight;
        } else {
            this.props.form.validateFieldsAndScroll(['count', 'l', 'b', 'h', 'weight']);
        }
        this.props.form.setFieldsValue({
            volumetricWeight: volumetricWeight,
            chargebleWeight: chargebleWeight
        });
    }

    patchContactForm = (contactDetails, type) => {
        if (isEmpty(contactDetails)) {
            return;
        }
        if (type === 'shipper') {
            this.props.form.setFieldsValue({
                name: contactDetails.name,
                emailId: contactDetails.emailId,
                pincode: contactDetails.pincode.zipcode,
                city: contactDetails.pincode.city.name,
                state: contactDetails.pincode.city.state,
                pincodeObj: contactDetails.pincode
            });
            let address = contactDetails.address.address1;
            if (contactDetails.address.address2) {
                address += ', ' + contactDetails.address.address2;
            }
            if (contactDetails.address.address3) {
                address += ', ' + contactDetails.address.address3;
            }
            if (contactDetails.address.landmark) {
                address += ', ' + contactDetails.address.landmark;
            }
            this.props.form.setFieldsValue({ address: address });
        } else {
            this.props.form.setFieldsValue({
                cname: contactDetails.name,
                cemailId: contactDetails.emailId,
                cpincode: contactDetails.pincode.zipcode,
                ccity: contactDetails.pincode.city.name,
                cstate: contactDetails.pincode.city.state,
                cpincodeObj: contactDetails.pincode,
                caddress1: contactDetails.address.address1,
                caddress2: contactDetails.address.address2,
                caddress3: contactDetails.address.address3,
                clandmark: contactDetails.address.landmark
            });
        }
        this.onPincodeChange(type, { target: { value: contactDetails.pincode.zipcode } })
    }

    handleCancel = () => {
        this.props.history.goBack();
    }


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { productTypes, paymentTypes, modes, billToOptions, formId, customers, formLoading } = this.state;
        return (
            <div className="b2c-lite-booking">
                <Form className="" {...formItemLayout}>
                    <Spin spinning={formLoading}>
                        <Card className='booking' title={`${formId ? 'Edit' : 'Create'} Booking`}>
                            <Row>
                                {/* <Col sm={2} md={2} lg={2} xl={2}>
                                <Steps direction="vertical" current={0}>
                                    <Steps.Step />
                                    <Steps.Step style={{ marginTop: 100 }} />
                                    <Steps.Step />
                                </Steps>
                            </Col> */}
                                <Col sm={24} md={12} lg={12} xl={12}>
                                    <Divider orientation="left">Booking Entry</Divider>
                                    <Form.Item label="Pickup Date">
                                        {getFieldDecorator('pickupDate', {
                                            initialValue: moment(),
                                            rules: [{ required: true, message: 'Can\'t be blank' }],
                                        })(
                                            <DatePicker onChange={this.onPickupDateChange} disabledDate={this.allowedDates} style={{ width: '100%' }} showToday={false} />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Product Type">
                                        {getFieldDecorator('productType', {
                                            rules: [{ required: true, message: 'Can\'t be blank' }],
                                        })(
                                            <Select
                                                style={{ width: '100%' }}
                                                allowClear={false}
                                                placeholder="Select Product Type"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    productTypes?.length > 0
                                                        ? productTypes?.map(list => {
                                                            return <Select.Option value={list?.id}>{list?.name}</Select.Option>
                                                        })
                                                        : null
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Waybill Number">
                                        <Col span={18}>
                                            {getFieldDecorator('waybillNo', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Waybill Number"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Col>
                                        <Col span={4}>
                                            <Button onClick={this.onGenerateWaybill} disabled={!!getFieldValue('waybillNo')} type="primary" size="default" >Generate</Button>
                                        </Col>
                                    </Form.Item>
                                    <Form.Item label="Order No./Ref No">
                                        {getFieldDecorator('orderRefNo', {
                                            rules: [{ required: false, message: 'Can\'t be blank' }],
                                        })(
                                            <Input
                                                placeholder="Enter Order No./Ref No"
                                                size="default"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Payment Type">
                                        {getFieldDecorator('paymentType', {
                                            initialValue: paymentTypes.find(item => item.checked).value,
                                            rules: [{ required: true, message: 'Can\'t be blank' }],
                                        })(
                                            <Radio.Group onChange={this.onPaymentTypeChange}>
                                                {
                                                    paymentTypes.map((type, i) => (
                                                        <Radio key={(i + 1)} value={type.value}>{type.name}</Radio>
                                                    ))
                                                }
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    {
                                        getFieldValue('paymentType') === 'COD' && <Form.Item label="COD Amount">
                                            {getFieldDecorator('codAmount', {
                                                rules: [
                                                    { required: true, message: 'Can\'t be blank' },
                                                ],
                                            })(
                                                <InputNumber
                                                    placeholder="Enter COD Amount"
                                                    min="1"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    }
                                    <Form.Item label="Mode">
                                        {getFieldDecorator('consignmentMode', {
                                            initialValue: modes.find(item => item.checked).value,
                                            rules: [{ required: true, message: 'Can\'t be blank' }],
                                        })(
                                            <Radio.Group>
                                                {
                                                    modes.map((type, i) => (
                                                        <Radio key={(i + 1)} value={type.value}>{type.name}</Radio>
                                                    ))
                                                }
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Product Description">
                                        {getFieldDecorator('description', {
                                            rules: [{ required: false, message: 'Can\'t be blank' }],
                                        })(
                                            <Input
                                                placeholder="Enter Product Description"
                                                size="default"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                    <Divider orientation="left">Shipper Details</Divider>
                                    <Col span={16} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 8 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 12, offset: 4 },
                                            }}
                                            label="Phone number"
                                        >

                                            {getFieldDecorator('phoneNumber', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Can\'t be blank',
                                                    },
                                                    {
                                                        pattern: validationRules.mobile,
                                                        max: 10,
                                                        min: 10,
                                                        message: "Please enter valid mobile number"
                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Phone number"
                                                    maxLength={10}
                                                    key="phone1"
                                                    onChange={e => this.onPhoneNumberChange('shipper', e)}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="no-padding">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('customer', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Select
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    allowClear={false}
                                                    placeholder="Select Customer"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        customers?.length > 0
                                                            ? customers?.map(list => {
                                                                return <Select.Option value={list?.id}>{list?.name}</Select.Option>
                                                            })
                                                            : null
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Shipper Name">
                                            {getFieldDecorator('name', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Shipper Name"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Email ID">
                                            {getFieldDecorator('emailId', {
                                                rules: [
                                                    { required: false, message: 'Can\'t be blank' },
                                                    {
                                                        pattern: validationRules.email,
                                                        message: "Please enter valid email"
                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Email ID"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Address">
                                            {getFieldDecorator('address', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Address"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Pincode">
                                            {getFieldDecorator('pincode', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Pincode"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.onPincodeChange('shipper', e)}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 8 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 12, offset: 4 },
                                            }}
                                            label="City / State"
                                        >

                                            {getFieldDecorator('city', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter City"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="no-padding pl-8">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('state', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter State"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Divider orientation="left">Consignee Details</Divider>
                                    <Col span={16} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 8 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 12, offset: 4 },
                                            }}
                                            label="Phone number"
                                        >

                                            {getFieldDecorator('cphoneNumber', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Can\'t be blank',
                                                    },
                                                    {
                                                        pattern: validationRules.mobile,
                                                        max: 10,
                                                        min: 10,
                                                        message: "Please enter valid mobile number"
                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Phone number"
                                                    maxLength={10}
                                                    onChange={e => this.onPhoneNumberChange('consignee', e)}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="no-padding">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('ccustomer', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Select
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    allowClear={false}
                                                    placeholder="Select Customer"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        customers?.length > 0
                                                            ? customers?.map(list => {
                                                                return <Select.Option value={list?.id}>{list?.name}</Select.Option>
                                                            })
                                                            : null
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Consignee Name">
                                            {getFieldDecorator('cname', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Consignee Name"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Email ID">
                                            {getFieldDecorator('cemailId', {
                                                rules: [
                                                    { required: false, message: 'Can\'t be blank' },
                                                    {
                                                        pattern: validationRules.email,
                                                        message: "Please enter valid email"
                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Email ID"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 8 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 12, offset: 4 },
                                            }}
                                            label="Address"
                                        >

                                            {getFieldDecorator('caddress1', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Flat/House No."
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="no-padding pl-8">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('caddress2', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Street/Road/Cross"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} offset={8} className="no-padding">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('caddress3', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Locality Area"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} offset={8} className="no-padding">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('clandmark', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Landmark"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Pincode">
                                            {getFieldDecorator('cpincode', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Pincode"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.onPincodeChange('consignee', e)}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 8 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 12, offset: 4 },
                                            }}
                                            label="City / State"
                                        >

                                            {getFieldDecorator('ccity', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter City"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="no-padding pl-8">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('cstate', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter State"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Bill To">
                                            {getFieldDecorator('Mode', {
                                                initialValue: billToOptions.find(item => item.checked).value,
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Radio.Group>
                                                    {
                                                        billToOptions.map((type, i) => (
                                                            <Radio key={(i + 1)} value={type.value}>{type.name}</Radio>
                                                        ))
                                                    }
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Divider orientation="left">Shipment Details</Divider>
                                    <Form.Item label="Shipment value">
                                        {getFieldDecorator('value', {
                                            rules: [{ required: false, message: 'Can\'t be blank' }],
                                        })(
                                            <Input
                                                placeholder="Enter Shipment value"
                                                size="default"
                                                style={{ width: '100%' }}
                                                onChange={this.onPaymentTypeChange}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Shipment Count">
                                        {getFieldDecorator('count', {
                                            initialValue: 1,
                                            rules: [{ required: true, message: 'Can\'t be blank' }],
                                        })(
                                            <Input
                                                placeholder="Enter Shipment Count"
                                                size="default"
                                                style={{ width: '100%' }}
                                                disabled
                                            />
                                        )}
                                    </Form.Item>
                                    <Col span={13} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 9 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 9, offset: 6 },
                                            }}
                                            label="L*B*H"
                                        >

                                            {getFieldDecorator('l', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="length"
                                                    addonAfter="cm"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.setState({ length: e.target.value }, () => this.dimensionChange())}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} className="no-padding">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('b', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Breath"
                                                    addonAfter="cm"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    onChange={e => this.setState({ breath: e.target.value }, () => this.dimensionChange())}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="no-padding">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('h', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Height"
                                                    addonAfter="cm"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 8 }}
                                                    onChange={e => this.setState({ heigth: e.target.value }, () => this.dimensionChange())}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={13} className="no-padding">
                                        <Form.Item
                                            labelCol={{
                                                xs: { span: 12 },
                                                sm: { span: 9 },
                                            }}
                                            wrapperCol={{
                                                xs: { span: 24 },
                                                sm: { span: 9, offset: 6 },
                                            }}
                                            label="Shipment Weight"
                                        >

                                            {getFieldDecorator('weight', {
                                                rules: [{ required: true, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Enter Weight"
                                                    size="default"
                                                    style={{ width: '100%' }}
                                                    onChange={e => this.setState({ weight: e.target.value }, () => this.dimensionChange())}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} className="no-padding pl-8">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('volumetricWeight', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Volumetric Weight"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 10 }}
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="no-padding pl-8">
                                        <Form.Item wrapperCol={{
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        }}>

                                            {getFieldDecorator('chargebleWeight', {
                                                rules: [{ required: false, message: 'Can\'t be blank' }],
                                            })(
                                                <Input
                                                    placeholder="Chargeble Weight"
                                                    size="default"
                                                    style={{ width: '100%', paddingLeft: 10 }}
                                                    disabled
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Button size="large" className="pull-right" style={{ float: 'right' }} disabled={this.state.isSubmitDisabled} key="submit" type="primary" onClick={e => this.setState({ formLoading: true }, () => this.onBookingSubmit(e))}>
                                            {formId ? 'Update' : 'Create'}
                                        </Button>
                                        <Button size="large" className="pull-right mr-3" style={{ float: 'right' }} key="back" onClick={this.handleCancel}>
                                            Cancel
                                    </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </Card>
                    </Spin>
                </Form>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    genaratedWaybill: state.b2bLiteBooking.genaratedWaybill,
    configurations: state.app.configurations
});

const mapDispatchToProps = dispatch => ({
    getProductTypes,
    generateWaybillID: () => dispatch(generateWaybillID()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({
    name: 'B2cBooingForm',
    onFieldsChange: (props, newChanges, allFields) => {
        // console.log(allFields);
    },
    onValuesChange: (props, newChanges, allFields) => {
        // console.log(allFields);
    }
})(withRouter(B2CBooking)));