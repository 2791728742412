const schema = {
    "type"      : "object",
    "required"  : ["chargeBasisValue", "amount"],
    "properties": {
        "chargeBasisValue" : {
            "type"    : "string",
            "title"   : "Vehicle Type",
            "searchEnabled" : true
        },
        "amount"   : {
            "title" : "Amount (in Rs.)",
            "type"  : "number"
        }
    }
}

const uiSchema = {
    "classNames": "hide-title",
    "chargeBasisValue" : {
        "ui:placeholder"   : "Select Vehicle Type"
    },
    "amount" : {
        "ui:placeholder" : "Enter Amount",
        "ui:prefix"      : "Rs. "
    }
}

const errorList = {
    "chargeBasisValue" : {
        "required" : "Vehicle Type is mandatory!"
    },
    "amount" : {
        "required" : "Amount is mandatory!"
    }
}


export default { schema, uiSchema, errorList };