import React, { Component } from 'react'
import EwayOrange from '../../../../assets/img/icons/collect_money.svg';
import { Modal, Table, Button, Tooltip } from 'antd';
import { formatCurrency } from '../../../../common/utils/utils';

export default class TripSummaryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Docket',
                    key: 'waybillNo',
                    dataIndex: 'waybillNo'
                },
                {
                    title: 'Amount',
                    key: 'amountToBePaid',
                    dataIndex: 'amountToBePaid',
                    render: data => formatCurrency(data)
                },
            ],
            isModalOpen: false
        }
    }
    toggleTripSummary = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }
    render() {
        let data = this.props.data ? [...this.props.data] : [];
        if(!this.props.title) {
            let totalAmt = this.props.data.map(item => (item.amountToBePaid)).reduce((a, b) => a + b)
            data = [...data,{ waybillNo: 'Total', amountToBePaid: totalAmt }];
        }
        return (
            <>
                {this.props.children ? <div style={{ cursor: 'pointer' }} onClick={this.toggleTripSummary}> {this.props.children} </div> : <Tooltip placement="top" title={this.props.title || "Trip Summary"}> <img width={22} src={EwayOrange} style={{ cursor: 'pointer' }} onClick={this.toggleTripSummary} /> </Tooltip>}
                <Modal
                    onCancel={this.toggleTripSummary}
                    maskClosable={false}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.toggleTripSummary}>
                            {'Ok'}
                        </Button>
                    ]}
                    visible={this.state.isModalOpen}
                    title={this.props.title || "Trip Summary"}
                >
                    <Table pagination={false} columns={this.state.columns} dataSource={data} />
                </Modal>
            </>
        )
    }
}
