import * as _Constants from "./constants";
import { notifyApiError } from '../../../common/utils/utils';
import LiveLoadsService from "../../../services/customer-panel/live-loads.service";

const liveLoadService = new LiveLoadsService();

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
        default:
            return true
    }
}

export const fetchLiveLoads = (payload, isReset) => dispatch => {
    dispatch(fetchLiveLoadsBegin());
    liveLoadService.getLiveLoads(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchLiveLoadsSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchLiveLoadsFailure(res ?.data ?.response));
        }
    });
};

function fetchLiveLoadsBegin() {
    return {
        type: _Constants.FETCH_DELIVER_LOADS_BEGIN
    };
}

function fetchLiveLoadsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_DELIVER_LOADS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchLiveLoadsFailure(error) {
    return {
        type: _Constants.FETCH_DELIVER_LOADS_FAILURE,
        payload: error
    };
}

export const fetchLiveLoadsSummary = (payload) => dispatch => {
    dispatch(fetchLiveLoadsSummaryBegin());
    liveLoadService.getLiveLoadsSummary(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchLiveLoadsSummarySuccess(res ?.data ?.response));
        } else {
            dispatch(fetchLiveLoadsSummaryFailure(res ?.data ?.response));
        }
    });
};

function fetchLiveLoadsSummaryBegin() {
    return {
        type: _Constants.FETCH_DELIVER_LOADS_SUMMARY_BEGIN
    };
}

function fetchLiveLoadsSummarySuccess(payload) {
    return {
        type: _Constants.FETCH_DELIVER_LOADS_SUMMARY_SUCCESS,
        payload: payload
    };
}

function fetchLiveLoadsSummaryFailure(error) {
    return {
        type: _Constants.FETCH_DELIVER_LOADS_SUMMARY_FAILURE,
        payload: error
    };
}

export const exportLiveLoads = (payload) => dispatch => {
    dispatch(exportLiveLoadsBegin());
    liveLoadService.getExportReports('CUSTOMER_DELIVERED_LOAD_REPORT', payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(exportLiveLoadsSuccess(res ?.data ?.response));
        } else {
            dispatch(exportLiveLoadsFailure(res ?.data ?.response));
        }
    });
};

function exportLiveLoadsBegin() {
    return {
        type: _Constants.EXPORT_DELIVER_LOADS_BEGIN
    };
}

function exportLiveLoadsSuccess(payload) {
    return {
        type: _Constants.EXPORT_DELIVER_LOADS_SUCCESS,
        payload: payload
    };
}

function exportLiveLoadsFailure(error) {
    return {
        type: _Constants.EXPORT_DELIVER_LOADS_FAILURE,
        payload: error
    };
}


export const downloadPOD = (payload) => dispatch => {
    dispatch(downloadPODBegin());
    liveLoadService.getDownloadPOD(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            
            dispatch(downloadPODSuccess(res ?.data ?.response));
        } else {
            dispatch(downloadPODFailure(res ?.data ?.response));
        }
    });
};

function downloadPODBegin() {
    return {
        type: _Constants.DOWNLOAD_POD_LOADS_BEGIN
    };
}

function downloadPODSuccess(payload) {
    
    return {
        type: _Constants.DOWNLOAD_POD_LOADS_SUCCESS,
        payload: payload
    };
}

function downloadPODFailure(error) {
    return {
        type: _Constants.DOWNLOAD_POD_LOADS_FAILURE,
        payload: error
    };
}

export const locationLiveLoads = (payload) => dispatch => {
    dispatch(locationLiveLoadsBegin());
    liveLoadService.fetchLocations(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(locationLiveLoadsSuccess(res ?.data ?.response));
        } else {
            dispatch(locationLiveLoadsFailure(res ?.data ?.response));
        }
    });
};

function locationLiveLoadsBegin() {
    return {
        type: _Constants.LOCATION_DELIVER_LOADS_BEGIN
    };
}

function locationLiveLoadsSuccess(payload) {
    return {
        type: _Constants.LOCATION_DELIVER_LOADS_SUCCESS,
        payload: payload,
    };
}

function locationLiveLoadsFailure(error) {
    return {
        type: _Constants.LOCATION_DELIVER_LOADS_FAILURE,
        payload: error
    };
}