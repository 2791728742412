const schema = {
    "type": "object",
    "required": [ "shipperId", "serviceType", "isOneTimeContract", "contractValidity" ],
    "properties": {
        "code": {
            "type"  : "string",
            "title" : "Contract Code"
        },
        "name": {
            "type": "string",
            "title": "Contract Name"
        },
        "shipperId": {
            "type"      : "number",
            "title"     : "Shipper",
            "searchEnabled" : true
        },
        "serviceType": {
            "type": "string",
            "title": "Service Type",
            "enum": ["FTL", "PTL", "LDV"]
        },
        "movementType": {
            "type": "string",
            "title": "Movement Type",
            "enum": ["FORWARD", "REVERSE"],
            "enumNames": ["Forward", "Reverse"]
        },
        "isOneTimeContract": {
            "type"  : "boolean",
            "title" : "One Time Contract"
        },
        "contractValidity": {
            "type": "string",
            "title": "Contract Validity"
        }
    },
    "dependencies": {
        "isOneTimeContract": {
            "oneOf": [
                {
                    "required"  : [ "indentLimit" ],
                    "properties": {
                        "isOneTimeContract": {
                            "enum": [ true ]
                        },
                        "indentLimit" : {
                            "type"    : "number",
                            "title"   : "Indent Limit",
                            "minimum" : 1
                        }
                    }
                },
                {
                    "properties": {
                        "isOneTimeContract": {
                            "enum": [ false ]
                        }
                    }
                }
            ]
        }
    }
}

const uiSchema = {
        "classNames": "multi-row-field-objects hide-title",
        "code": {
            "ui:placeholder": "(System generated)",
            "disabled"      : true
        },
        "name": {
            "ui:placeholder"    : "(System generated)",
            "showValueOnHover"  : true,
            "disabled"          : true
        },
        "shipperId": {
            "ui:placeholder"      : "Select Shipper",
            "ui:widget"           : "SelectAPI",
            "dropdownPlaceholder" : "Start Typing to search (min 3 charachters)...",
            "noContentFound"      : "No shippers found",
            "labelInValue"        : true
        },
        "serviceType": {
            "ui:placeholder": "Select Service Type",
            "ui:widget": "SelectWidget"            
        },
        "movementType": {
            "ui:placeholder": "Select Movment Type"
        },
        "isOneTimeContract": {
            "ui:placeholder": "Enter One Time Contract",
            "ui:widget"     : "SwitchWidget"
        },
        "contractValidity": {
            "ui:fromPlaceHolder" : "Contract From",
            "ui:toPlaceHolder"   : "Contract To",
            "ui:widget"          : "DateRangeWidget",
            "format"             : "DD/MM/YYYY"
        },
        "indentLimit": {
            "ui:placeholder": "Enter Indent Limit",
            "type"  : "number",
        }
}

const errorList = {
    "shipperId"  : {
        "required"  : "Shipper is mandatory!"
    },
    "serviceType": {
        "required"  : "Service Type is mandatory!"
    },
    "movementType": {
        "required"  : "Movement Type is mandatory!"
    },
    "contractValidity": {
        "required"  : "Contract Validity is mandatory!"
    },
    "indentLimit"  : {
        "required"  : "Indent Limit is mandatory!",
        "minimum"   : "Indent Limit has to be greater than 0!"
    }
}

export default { schema, uiSchema, errorList };