import React, { useState, useEffect, useRef } from 'react';
import "./sparkline.scss";
import PropTypes from 'prop-types';

/**
 * desc
 * @date 2022-05-03
 * @param {data, clickable, selected, onUpdatedSummary} parm1
 */
function Sparkline({data, clickable, selected, onUpdatedSummary}) {
    // const { data, clickable } = props;
    const [keys, setKeys] = useState(selected);
    const didMount = useRef(false);

    
    /**
     * desc
     * @date 2022-05-03
     * @param {index} index
     */
    const updateSummary = index => {
        const selectedKeyItem = data[index].lovCode;
        // if (defaultSelected.indexOf(selectedKeyItem) >= 0) return;
        if (keys.indexOf(selectedKeyItem) >= 0) {
            const newIndex = keys.findIndex(key => key === selectedKeyItem);
            const tempKeys = [...keys];
            tempKeys.splice(newIndex, 1);

            setKeys(tempKeys);
            // props.onUpdatedSummary(tempKeys);

        } else {
            const tempKeys = [...keys];
            tempKeys.push(selectedKeyItem);
            setKeys(tempKeys);
            // props.onUpdatedSummary(tempKeys);
        }


    }

    useEffect(() => {
       
        if (clickable && didMount.current) {
            onUpdatedSummary(keys);
        }
        if (!didMount.current) {
            didMount.current = true;
        }

    }, [keys])

    useEffect(() => {
        const lengthCheck = selected.length != keys.length;
        const keysCheck = selected.filter(s => keys.indexOf(s) < 0).length > 0;
        if (lengthCheck || keysCheck) {
            setKeys(selected);
        }
    }, [selected])

    return (
        <div className="flex-box flex-gap-m">
            {data.map((summary, index) => (
                <SummaryItem
                    key={summary.lovCode}
                    keys={keys} index={index}
                    summary={summary}
                    clickable={clickable && !summary.nonClickable}
                    onClickIndentItem={clickable ? updateSummary : () => { }}
                />
            ))}
        </div>
    )
}

/**
 * desc
 * @date 2022-05-03
 * @param {summary, defaultSelected, keys, clickable} parm1
 */
function SummaryItem({summary, defaultSelected, keys, clickable, index, onClickIndentItem}) {
    // const { summary, defaultSelected, keys, clickable } = props; 
    
    return (

        <div
            onClick={() => clickable ? onClickIndentItem(index) : () => { }}
            className={
                `summary_box flex-1 flex-column align-items-center justify-content-center flex-gap-m ${clickable ? 'cursor-pointer' : ''} ${(keys?.indexOf(summary?.lovCode) >= 0 || defaultSelected?.indexOf(summary?.lovCode) >= 0) ? 'summary_box-selected' : ''}`
            }
        >
            <div
                className={`summary_box-title ${(keys?.indexOf(summary?.lovCode) >= 0 || defaultSelected?.indexOf(summary?.lovCode) >= 0) ? 'summary_box-title_selected' : ''}`}
            >
                {summary?.name}
            </div>

            <div
                className={`summary_box-value ${(keys?.indexOf(summary?.lovCode) >= 0 || defaultSelected?.indexOf(summary?.lovCode) >= 0) ? 'summary_box-value_selected' : ''} `}
            >
                {summary?.value}
            </div>
        </div>

    )
}
Sparkline.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        lovCode: PropTypes.number.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired
          ]),
        name: PropTypes.string.isRequired
    })),
    defaultSelected: PropTypes.array,
    onUpdatedSummary: PropTypes.func,
    clickable: PropTypes.bool
};

SummaryItem.propTypes = {
    summary: PropTypes.object,
    defaultSelected: PropTypes.array,
    keys: PropTypes.array,
}

export default Sparkline;