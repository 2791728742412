import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const pickupAssistanceReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.GET_INVENTORY_BEGIN: return getAssistedBegin(state);
        case _Constants.GET_INVENTORY_SUCCESS: return getAssistedSuccess(state, action);
        case _Constants.GET_INVENTORY_FAILURE: return getAssistedFailure(state);
        case _Constants.GET_CLIENT_BEGIN: return getClientsBegin(state);
        case _Constants.GET_CLIENT_SUCCESS: return getClientsSuccess(state, action);
        case _Constants.GET_CLIENT_FAILURE: return getClientsFailure(state);
        default:
            return state;
    }
}

function getClientsBegin(state, action) {
    return {
        ...state,
    };
}

function getClientsSuccess(state, action) {
    return {
        ...state,
        ...action ?.payload
    };
}

function getClientsFailure(state, action) {
    return {
        ...state,
        error: action ?.payload,
        customersData: []
    };
}


function getAssistedBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function getAssistedSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedPickups: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedPickups ? state.PaginatedPickups : {}, action ?.payload ?.consignmentGroups , action.isReset),
        ...action.payload
    };
}

function getAssistedFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        consignmentGroups: []
    };
}
