
export const initialState = {
}

export const FETCH_EXCEPTION_BEGIN = "FETCH_EXCEPTION_BEGIN";
export const FETCH_EXCEPTION_SUCCESS = "FETCH_EXCEPTION_SUCCESS";
export const FETCH_EXCEPTION_FAILURE = "FETCH_EXCEPTION_FAILURE";

export const FETCH_SERVICEABLE_AREA_PINCODES_BEGIN = "FETCH_SERVICEABLE_AREA_PINCODES_BEGIN";
export const FETCH_SERVICEABLE_AREA_PINCODES_SUCCESS = "FETCH_SERVICEABLE_AREA_PINCODES_SUCCESS";
export const FETCH_SERVICEABLE_AREA_PINCODES_FAILURE = "FETCH_SERVICEABLE_AREA_PINCODES_FAILURE";

export const UPDATE_BOOKING_SERVICE_BEGIN = "UPDATE_BOOKING_SERVICE_BEGIN";
export const UPDATE_BOOKING_SERVICE_SUCCESS = "UPDATE_BOOKING_SERVICE_SUCCESS";
export const UPDATE_BOOKING_SERVICE_FAILURE = "UPDATE_BOOKING_SERVICE_FAILURE";