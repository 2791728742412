import React from 'react';
import Barcode from '../../../ui/barcode';

const InventoryRunsheetInfo = (props) => {
  const mainContext = !props ?.runSheetDetails ? null : props ?.runSheetDetails ?.map((runSheetObject, rIndex) => (
    <div className="lead-information" key={rIndex}>
      {
        runSheetObject.leads
          ? runSheetObject.leads.map((lead, index) => {
            return (
              <div className="text" key={index}>
                {
                  (lead.consignments && lead.consignments.length > 0)
                    ? lead.consignments.map((consignment, cIndex) => {
                      return (
                        <div className="page-breaker" key={lead.code}>
                          {/* Customer Copy */}
                          <p className="card-mini-header">Customer Copy</p>
                          <div className="">
                            <table className='table-runsheet' style={{ width: '100%' }}>
                              <td className="td-runsheet">
                                {
                                  lead.code && <p>Request reference: {lead.code}</p>
                                }
                                {
                                  lead.customer ?.organisation ?.name && <p>Requested by: {lead.customer ?.organisation ?.name}</p>
                              }
                              </td>
                              <td className="td-runsheet no-bottom-border" rowspan="2">
                                {
                                  consignment ?.description && <p>Item: {consignment ?.description}</p>
                              }
                                {
                                  consignment ?.totalWeight && <p>Weight: {consignment ?.totalWeight} {consignment ?.shipmentBOs ?.length > 0 ? consignment ?.shipmentBOs[0].weightUnit : ''} </p>
                              }
                                {
                                  consignment ?.totalShipmentCount && <p>Pieces: {consignment ?.totalShipmentCount}</p>
                              }
                                {
                                  consignment ?.consignmentAmount && <p>Declared value: {consignment ?.consignmentCurrency}. {consignment ?.consignmentAmount}/-</p>
                              }

                              </td>

                              <tr className="tr-runsheet">
                                <td className="td-runsheet">
                                  {
                                    lead.asset.driverName && <p>Picked by: {lead.asset.driverName}</p>
                                  }
                                  {
                                    runSheetObject ?.partner ?.name && <p>Logistics partner: {runSheetObject ?.partner ?.name}</p>
                                }
                                </td>
                              </tr>
                              <tr className="tr-runsheet">
                                {
                                  (consignment.docketType && (consignment.docketType === 'REVERSE' || consignment.docketType === 'EXCHANGE'))
                                    ? (
                                      <td className="td-runsheet">
                                        {
                                          consignment ?.shipper ?.name && <p>Collected from: {consignment ?.shipper ?.name}</p>
                                      }
                                        {
                                          consignment ?.shipper ?.address ?.address1 && <p>{consignment ?.shipper ?.address ?.address1}</p>
                                      }
                                        {
                                          consignment ?.shipper ?.address ?.address2 && <p>{consignment ?.shipper ?.address ?.address2}</p>
                                      }
                                        {
                                          consignment ?.shipper ?.address ?.landmark && <p>Landmark: {consignment ?.shipper ?.address ?.landmark}</p>
                                      }
                                        {
                                          consignment ?.shipper ?.pincode ?.zipcode && <p>Pincode: {consignment ?.shipper ?.pincode ?.zipcode}</p>
                                      }
                                        {
                                          consignment ?.shipper ?.phoneNumber && <p>Contact: {consignment ?.shipper ?.phoneNumber}</p>
                                      }
                                      </td>
                                    )
                                    : (
                                      <td className="td-runsheet">
                                        {
                                          consignment ?.consignee ?.name && <p>Collected from: {consignment ?.consignee ?.name}</p>
                                      }
                                        {
                                          consignment ?.consignee ?.address ?.address1 && <p>{consignment ?.consignee ?.address ?.address1}</p>
                                      }
                                        {
                                          consignment ?.consignee ?.address ?.address2 && <p>{consignment ?.consignee ?.address ?.address2}</p>
                                      }
                                        {
                                          consignment ?.consignee ?.address ?.landmark && <p>Landmark: {consignment ?.consignee ?.address ?.landmark}</p>
                                      }
                                        {
                                          consignment ?.consignee ?.pincode ?.zipcode && <p>Pincode: {consignment ?.consignee ?.pincode ?.zipcode}</p>
                                      }
                                        {
                                          consignment ?.consignee ?.phoneNumber && <p>Contact: {consignment ?.consignee ?.phoneNumber}</p>
                                      }
                                      </td>
                                    )
                                }

                                <td className="td-runsheet no-top-border">
                                  <p>Sign: </p>
                                  <p>Name:</p>
                                  <p>Date:</p>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <hr />
                          <p className="card-mini-header">Shipper Copy</p>
                          <div>
                            <table className="table-runsheet" style={{ width: '100%' }}>
                              {
                                consignment ?.waybillNo && (
                                  <td className="td-runsheet">
                                    Waybill Number: {consignment ?.waybillNo} <br />
                                    <Barcode width={3} format="CODE128" showText={false} value={consignment ?.waybillNo} />
                                  </td>
                                )
                            }
                              {
                                (consignment.docketType === 'REVERSE' || consignment.docketType === 'EXCHANGE')
                                  ? (
                                    <td className="td-runsheet">
                                      {
                                        consignment ?.consignee ?.name && <p>Collected from: {consignment ?.consignee ?.name}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.address ?.address1 && <p>{consignment ?.consignee ?.address ?.address1}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.address ?.address2 && <p>{consignment ?.consignee ?.address ?.address2}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.address ?.landmark && <p>Landmark: {consignment ?.consignee ?.address ?.landmark}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.address ?.pincode ?.city ?.name && <p>City: {consignment ?.consignee ?.address ?.pincode ?.city ?.name}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.address ?.pincode ?.city ?.state && <p>State: {consignment ?.consignee ?.address ?.pincode ?.city ?.state}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.pincode ?.zipcode && <p>Pincode: {consignment ?.consignee ?.pincode ?.zipcode}</p>
                                      }
                                      {
                                        consignment ?.consignee ?.phoneNumber && <p>Contact: {consignment ?.consignee ?.phoneNumber}</p>
                                      }
                                    </td>
                                  )
                                  : (
                                    <td className="td-runsheet">
                                      {
                                        consignment ?.shipper ?.name && <p>Collected from: {consignment ?.shipper ?.name}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.address ?.address1 && <p>{consignment ?.shipper ?.address ?.address1}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.address ?.address2 && <p>{consignment ?.shipper ?.address ?.address2}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.address ?.landmark && <p>Landmark: {consignment ?.shipper ?.address ?.landmark}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.address ?.pincode ?.city ?.name && <p>City: {consignment ?.shipper ?.address ?.pincode ?.city ?.name}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.address ?.pincode ?.city ?.state && <p>State: {consignment ?.shipper ?.address ?.pincode ?.city ?.state}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.pincode ?.zipcode && <p>Pincode: {consignment ?.shipper ?.pincode ?.zipcode}</p>
                                      }
                                      {
                                        consignment ?.shipper ?.phoneNumber && <p>Contact: {consignment ?.shipper ?.phoneNumber}</p>
                                      }
                                    </td>
                                  )
                              }
                              <tr className="tr-runsheet">
                                <td className="td-runsheet">
                                  {
                                    consignment ?.description && <p>Item: {consignment.description}</p>
                              }
                                  {
                                    consignment ?.totalWeight && <p>Weight: {consignment.shipmentBOs[0].weight} {consignment.shipmentBOs[0].weightUnit} </p>
                              }
                                  {
                                    consignment ?.totalShipmentCount && <p>Pieces: {consignment.totalShipmentCount}</p>
                              }
                                  {
                                    consignment ?.consignmentAmount && <p>Declared value: {consignment ?.consignmentCurrency}. {consignment ?.consignmentAmount}/-</p>
                              }
                                </td>
                                <td className="td-runsheet no-top-border">Item is not for sale</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      )
                    })
                    : <p className="card-mini-header">Lead Id {lead.id} with status {lead.status} does not have any consignments, Please contact loadshare.</p>
                }
              </div>
            )
          })
          : <p className="card-mini-header">Leads not available for the partcular runsheet</p>
      }
    </div>
  ));
  return mainContext
}

export default InventoryRunsheetInfo;