import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import DatePickerInput from '../ui/datepicker/DatePicker';
import { cloneObject } from '../../common/utils/utils';
function ReportFilters(props) {
    let { visible, setVisible, filters } = props;
    const { applyFilters, resetFilters, setFilters } = props;
    const [endOpen, setEndOpen] = useState(false);
    const disableEndDate = endValue => {
        const { validFrom } = filters;
        if (!endValue || !validFrom) {
            return false;
        }
        return endValue.valueOf() <= validFrom.valueOf();
    }
    const disableStartDate = startValue => {
        const { validTo } = filters;
        if (!startValue || !validTo) {
            return false;
        }
        return startValue.valueOf() >= validTo.valueOf();
    }
    const setFilteredData = (filterKey, filteredData) => {
        var tempFilters = { ...filters };
        tempFilters[filterKey] = filteredData;
        setFilters(tempFilters);
    }
    return (
        <Modal
            visible={visible}
            width={'30%'}
            closable={true}
            onCancel={() => setVisible(false)}
            style={{ top: 20 }}
            footer={[
                <Button key="back" onClick={_ => resetFilters()}> Reset </Button>,
                <Button key="submit" type="primary" onClick={applyFilters}> Apply </Button>
            ]}
            title="Filters">
            <div className="flex-box flex-column flex-gap-l contract-filters">
                <div>
                    <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Document Status</div>
                        <div className="box-filters flex-box justify-content-between flex-gap-m">
                            {filters?.documentStatuses?.map((item, index) => (
                                <div
                                    key={index}
                                    className={item.value === false ? "boxType" : "boxTypeSelected"}
                                    onClick={_ => {
                                        let statusFilters = cloneObject(filters);
                                        statusFilters['documentStatuses'].map(status => {
                                            if (status.id === index) {
                                                status.value = !status.value;
                                            }
                                        })
                                        setFilteredData('documentStatuses', statusFilters['documentStatuses'])
                                    }}
                                >
                                    {item.storedValue}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex-box justify-content-between flex-gap-m">
                        <div className="flex-box flex-column justify-content-between flex-gap-m">
                            <div className="u-font-bold font-size-m-1">Report Created At</div>
                            <div>
                                <DatePickerInput
                                    disabledDate={disableStartDate}
                                    type="date"
                                    placeholder="From"
                                    format="YYYY-MM-DD"
                                    value={filters.validFrom ? filters.validFrom : undefined}
                                    onChange={value => {
                                        setFilteredData('validFrom', value ? value.startOf('day') : undefined);
                                    }}
                                    style={{ width: '100%' }}
                                    onOpenChange={(open) => {
                                        if (!open)
                                            setEndOpen(true)
                                    }}


                                />
                            </div>
                        </div>

                        <div className="flex-box flex-column justify-content-between flex-gap-m">
                            <div className="u-font-bold font-size-m-1">&nbsp;</div>
                            <div>
                                <DatePickerInput
                                    disabledDate={disableEndDate}
                                    type="date"
                                    format="YYYY-MM-DD"
                                    placeholder="To"
                                    value={filters.validTo ? filters.validTo : undefined}
                                    open={endOpen}
                                    onChange={value => {
                                        setFilteredData('validTo', value ? value.endOf('day') : undefined);
                                    }}
                                    style={{ width: '100%' }}
                                    onOpenChange={(open) => setEndOpen(open)}


                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ReportFilters;