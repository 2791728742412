import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { notifyApiError } from '../../../common/utils/utils';


function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}


export const getTripCompletedList = (params) => dispatch => {
    dispatch(tripListBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/consignmentpod/fetch/lite-trips`,
        data: params
    })
        .then(response => {
            // checkHttpStatus(response.data ?.status)
            dispatch(tripListSuccess(response.data?.viewResponse))
        })
        .catch(error => dispatch(tripListFailure(error)));
}

export const approveAccept = (params) => dispatch => {
    dispatch(approveBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/podapproval`,
        data: params
    })
        .then(response => {
            
            dispatch(approveSuccess(response.data?.response.responses))
        })
        .catch(error => dispatch(approveFailure(error)));
}

export const getRejectReasons = (params) => dispatch => {
    dispatch(rejectReasonsBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2c/v2/reasons/fetch`,
        data: params
    })
        .then(response => {
            
            dispatch(rejectReasonsSuccess(response.data?.response))
        })
        .catch(error => dispatch(rejectReasonsFalure(error)));
}


function tripListBegin() {
    return {
        type: CONSTANTS.FETCH_COMPLETED_LIST_BEGIN,
        payload: null
    }
}

function tripListSuccess(response) {    
    return {
        type: CONSTANTS.FETCH_COMPLETED_LIST_SUCCESS,
        payload: response
    }
}

function tripListFailure(response) {
    return {
        type: CONSTANTS.FETCH_COMPLETED_LIST_SUCCESS,
        payload: response
    }
}

function approveBegin() {
    return {
        type: CONSTANTS.FETCH_APPROVE_BEGIN,
        payload: null
    }
}

function approveSuccess(response) {    
    return {
        type: CONSTANTS.FETCH_APPROVE_SUCCESS,
        payload: response
    }
}

function approveFailure(response) {
    return {
        type: CONSTANTS.FETCH_APPROVE_FAILURE,
        payload: response
    }
}

function rejectReasonsBegin() {
    return {
        type: CONSTANTS.FETCH_REASONS_LIST_BEGIN,
        payload: null
    }
}

function rejectReasonsSuccess(response) {    
    return {
        type: CONSTANTS.FETCH_REASONS_LIST_SUCCESS,
        payload: response
    }
}

function rejectReasonsFalure(response) {
    return {
        type: CONSTANTS.FETCH_REASONS_LIST_FAILURE,
        payload: response
    }
}
