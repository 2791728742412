import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import * as apiUtils from "../common/utils/apiGateway";
import { notifyApiError } from "../common/utils/utils";
import "./login.scss";
import { LsnModules } from "../configs/permissionTags";
import { validationRules } from "../common/utils/constants";
import cookie from "react-cookies";
import NumberFormat from "react-number-format";
import { Mixpanel } from "../utils/mixpanel";
import moment from "moment";
import { useInterval } from "./CustomHookUseInterval";
import logo from "../assets/img/logoBlue.png";

function login(props) {
  const [isValidOTP, setIsValidOTP] = useState(false);
  const [otpError, setOtpError] = useState(undefined);
  // const [showCounter, setShowCounter] = useState(false)
  const [count, setCount] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(2);
  const [showOTPPanel, setShowOTPPanel] = useState(true);
  const [clickedResendOTP, setClickedResendOTP] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(
    atob(localStorage.getItem("application"))
  );
  const [nopermission, setNopermission] = useState(true);
  const [loginMethod, setLoginMethod] = useState("otp");
  const [redirectURL, setRedirectURL] = useState("");
  const [loginButtonDisable, setLoginButtonDisable] = useState(false);
  const [showTimer, setShowTimer] = useState(false);

  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
    getFieldValue,
  } = props.form;

  let mobile = getFieldValue("mobileno");
  let otp = getFieldValue("otp");

  useEffect(() => {
    props.form.validateFields(["mobileno"]);

    if (!!localStorage.getItem("accessToken")) {
      props.history.push(getRedirectedUrl());
    }
  }, []);

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const checkHttpStatus = (status) => {
    switch (status?.code) {
      case 403:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 400:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 401:
        notifyApiError(status?.message, "ERROR:");
        // window.location.href = '/';
        return false;
      case 500:
      case 503:
        notifyApiError(status?.message, "ERROR:");
        return false;
      case 200:
      case 201:
      case 202:
        return true;
      default:
        break;
    }
  };

  // const ToArray = (enumme) => {
  //   const StringIsNumber = (value) => isNaN(Number(value)) === false;
  //   return Object.keys(enumme)
  //     .filter(StringIsNumber)
  //     .map((key) => enumme[key]);
  // };

  const getAllPersmission = () => {
    const permissions = localStorage.getItem("permissions");
    if (!permissions) {
      return null;
    }
    let selectedApplication = atob(localStorage.getItem("application"));
    setSelectedApplication(selectedApplication);
    console.log("102 " + selectedApplication);
    return JSON.parse(atob(permissions));
  };

  const store = (permissions) => {
    if (permissions == null) {
      return null;
    }
    localStorage.setItem("permissions", btoa(JSON.stringify(permissions)));
    var _permissions = getAllPersmission();
    const allApplications = Object.keys(_permissions);
    let selectedApplication = allApplications[0];
    setSelectedApplication(selectedApplication);
    localStorage.setItem("application", btoa(selectedApplication));
    console.log("113 " + selectedApplication);
  };

  const parsePermission = (permissions) => {
    if (!permissions) {
      return null;
    }
    const pObj = {};
    permissions.forEach((permission) => {
      if (!pObj[permission.application]) {
        pObj[permission.application] = {};
      }

      if (!pObj[permission.application][permission.module]) {
        pObj[permission.application][permission.module] = { permissions: [] };
      }

      if (permission.submodule == null) {
        pObj[permission.application][permission.module].permissions.push(
          permission.action
        );
      } else {
        console.log("136 pObj " + pObj);
        if (
          !pObj[permission.application][permission.module][permission.submodule]
        ) {
          pObj[permission.application][permission.module][
            permission.submodule
          ] = { permissions: [] };
          pObj[permission.application][permission.module][
            permission.submodule
          ] = { permissions: [] };
        }
        pObj[permission.application][permission.module][
          permission.submodule
        ].permissions.push(permission.action);
      }
    });
    return pObj;
  };

  const getRedirectedUrl = () => {
    const permissions = getAllPersmission();
    // console.log("permissions " + permissions);
    const application = atob(localStorage.getItem("application"));
    const pages = Object.keys(permissions[application]);
    console.log("160 " + pages);
    const redirectionOrder = {
      TRACKING: "tracking",
      SETTINGS: "settings",
    };
    let url = "";
    Object.keys(redirectionOrder).forEach((order) => {
      if (url === "" && pages.indexOf(order) !== -1) {
        url = redirectionOrder[order];
      }
    });
    if (url !== "") {
      url = url + "/dashboard";
    } else {
      url = "authentication/login";
    }
    localStorage.setItem("defaultUrl", url);
    return url;
  };

  const getApplicationState = (permission) => {
    if (!permission[0] || !permission[0].application) {
      return null;
    }
    return permission[0].application;
  };

  const handleOTPLogin = (e) => {
    e.preventDefault();
    // this.setState({ loginButtonDisable: true });
    setLoginButtonDisable(true);
    props.form.validateFields(["mobileno", "otp"], (err, values) => {
      if (!err) {
        let mobile = values.mobileno;
        let mobileNo = mobile.replace(/-/gi, "");
        let params = {
          otp: values.otp,
          number: mobileNo,
          callingSystem: "B2B",
        };
        apiUtils
          .apiRequest({
            method: "POST",
            url: "/v1/login/otp",
            // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
            data: params,
          })
          .then((response) => {
            let _response = response;
            Mixpanel.identify(response?.data?.response?.user?.id);
            Mixpanel.people.set({
              $first_name: response?.data?.response?.user?.name,
              $email: response?.data?.response?.user?.email,
              $phone_number: response?.data?.response?.user?.number,
              $customer_id:
                response?.data?.response?.user?.location?.entity?.id,
              $customer_name:
                response?.data?.response?.user?.location?.entity?.custObject
                  ?.name,
            });
            Mixpanel.track("Password Login Successfully done", {
              loginIdentity: moment().valueOf(),
            });
            if (checkHttpStatus(response?.data?.status)) {
              const response = _response.data.response;
              console.log("227 " + response);
              let modulenames = Object.keys(LsnModules);
              const permissionData = response.user.permissions;

              //----Going into infinite loop. Need to look later
              // for (let i = 0; i <= permissionData.length; i++) {
              //   if (permissionData[i]) {
              //     if (modulenames.includes(permissionData[i]["module"])) {
              //       setNopermission(true)
              //       break;
              //     }
              //   }
              // }

              if (!nopermission) {
                localStorage.setItem("isSassProduct", false);
              }

              // if (Constants.ENV === "local") {
              //   const data = {
              //     accessToken: response.token.accessToken,
              //     refreshToken: response.token.refreshToken,
              //     tokenId: response.token.tokenId,
              //     user: btoa(JSON.stringify(response.user)),
              //     permissions: btoa(
              //       JSON.stringify(
              //         this.parsePermission(response.user.permissions)
              //       )
              //     ),
              //     application: btoa(
              //       this.getApplicationState(response.user.permissions)
              //     ),
              //     loginMethod: "oldLogin",
              //     isSassProduct: false,
              //   };
              //   // this.generateIndexFiles(data).then((res) => {
              //   //   // console.log("res->>", res)
              //   // });
              // }

              store(parsePermission(response.user.permissions));
              cookie.save("LOGGED_IN", true, { path: "/" });
              localStorage.setItem(
                "primaryAccessToken",
                response.token.accessToken
              );
              localStorage.setItem("primaryTokenId", response.token.tokenId);
              localStorage.setItem("accessToken", response.token.accessToken);
              localStorage.setItem("refreshToken", response.token.refreshToken);
              localStorage.setItem("tokenId", response.token.tokenId);
              localStorage.setItem("user", btoa(JSON.stringify(response.user)));
              localStorage.setItem(
                "refreshFlag",
                JSON.stringify(new Date().valueOf())
              );
              console.log("282 " + nopermission);
              if (!redirectURL) {
                let redirectURL = getRedirectedUrl();
                setRedirectURL(redirectURL);
                // props.history.push(redirectURL)
                window.location.href = redirectURL;
              }
              //----need to resolve nopermission
              // if (nopermission === false) {
              //   props.history.push("nopermission/dashboard");

              // } else {
              //   props.history.push(redirectURL)
              // }
            }
          })
          .catch((error) => {
            console.log("error", error);
            setLoginButtonDisable(false);
          });
      } else {
        setLoginButtonDisable(false);
      }
    });
  };

  const interval = useInterval(
    () => {
      if (seconds === 0) {
        setShowTimer(false);
        clearInterval(interval.current);
      } else {
        setSeconds((s) => s - 1);
      }
    },
    showTimer ? 1000 : null
  );

  useEffect(() => {
    return function clearup() {
      clearInterval(interval.current);
    };
  }, []);

  const submitOTP = () => {
    props.form.validateFields(["mobileno"], (err, values) => {
      if (!err) {
        // const { getFieldValue } = this.props.form;
        let mobile = values["mobileno"];
        let mobileNo = mobile.replace(/-/gi, "");
        apiUtils
          .apiRequest({
            method: "GET",
            url: `/v1/otp/${mobileNo}`,
            // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
          })
          .then((response) => {
            if (checkHttpStatus(response?.data?.status)) {
              if (response.data.status && response.data.status.code === 200) {
                setIsValidOTP(true);
                setShowTimer(true);
              }
            }
          });
      }
    });
  };

  const resendOTP = () => {
    // const { getFieldValue } = props.form;
    let mobile = getFieldValue("mobileno");
    let mobileNo = mobile.replace(/-/gi, "");
    // this.setState({ otpError: undefined });
    setOtpError(undefined);
    apiUtils
      .apiRequest({
        method: "GET",
        // url: `/v1/otp/${getFieldValue("mobileNo")}`,
        url: `/v1/otp/${mobileNo}`,
        // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
      })
      .then((response) => {
        if (checkHttpStatus(response?.data?.status)) {
          setShowTimer(false);
          if (response.data.status && response.data.status.code === 200) {
            setIsValidOTP(true);
            setCount((count) => count + 1);
            setShowTimer(true);
            setClickedResendOTP(true);
            setSeconds(2);
          }
        } else {
          setOtpError(response.data.status.message);
        }
      });
  };

  const hideOTPPanel = () => {
    setShowOTPPanel(false);
    // setState({ showOTPPanel: false });
  };

  return (
    <>
      <div className="outermost-div-otp">
        <div className="outer-left-div-otp">
          <img className="logo-otp" src={logo} alt="logo"></img>
          <h1 id="title-opt">Login</h1>
          <Form
            autoComplete="off"           
            //   onSubmit={onSubmit}
            style={{ color: "blue" }}
          >
            <Form.Item
              tab="OTP"
              key="1"
              name="otpLogin"
              style={{ margin: "0px 0 15px", textAlign: "left" }}
              rules={[{ required: true }]}
            >
              {getFieldDecorator("mobileno", {
                rules: [
                  //   {
                  //     pattern: validationRules.mobile,
                  //     message: "Please enter valid mobile number",
                  //   },
                ],
              })(
                <NumberFormat
                  // value={mobile}
                  disabled={isValidOTP}
                  format="###-###-####"
                  type="tel"
                  mask=" "
                  placeholder="Phone Number"
                  className="login-tabpane-otp"
                />
              )}
            </Form.Item>
            <span style={{ color: "red" }}>{otpError}</span>
            {/* <>time: {minutes}:{seconds}</> */}

            {!isValidOTP ? (
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "95%",
                    height: "40px",
                    flexGrow: "0",
                    marginLeft: "10px",
                    borderRadius: "4px",
                    backgroundImage:
                      "linear-gradient(to top, #1665d8 98%, #1f6fe5 2%)",
                  }}
                  disabled={hasErrors(getFieldsError())}
                  onClick={submitOTP}
                >
                  <span className="confirm-otp">Submit</span>
                </Button>
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  tab="OTP"
                  key="1"
                  name="otpLogin"
                  style={{ margin: "0px 0 15px", textAlign: "left" }}
                  rules={[{ required: true }]}
                >
                  {getFieldDecorator(
                    "otp",
                    {}
                  )(
                    <NumberFormat
                      value={otp}
                      min={4}
                      // disabled={validOTP}
                      placeholder="Enter OTP Received"
                      className="login-tabpane-otp-received"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "95%",
                      height: "40px",
                      flexGrow: "0",
                      marginLeft: "10px",
                      padding: "9px 177px 9px 178px",
                      borderRadius: "4px",
                      backgroundImage:
                        "linear-gradient(to top, #1665d8 98%, #1f6fe5 2%)",
                    }}
                    disabled={hasErrors(getFieldsError())}
                    onClick={handleOTPLogin}
                  >
                    <span className="confirm">SIGN IN</span>
                  </Button>
                  {showOTPPanel ? (
                    <div className="showOTPPanel">
                      {count <= 3 ? (
                        <>
                          {!showTimer ? (
                            <>
                              <Button
                                type="link"
                                onClick={resendOTP}
                                style={{
                                  float: "left",
                                  color: "#051c57",
                                  margin: "10px 0 0 0px",
                                }}
                              >
                                {" "}
                                Resend OTP{" "}
                              </Button>
                            </>
                          ) : (
                            <>
                              <>
                                <Button
                                  type="link"
                                  style={{
                                    float: "left",
                                    color: "gray",
                                    margin: "10px 0 0 0px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Resend OTP: {minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </Button>
                                {/* <b>Resend OTP: {0}:{seconds < 3 ? `0${seconds}` : seconds}</b> */}
                              </>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <span>Maximum Count exceeded</span>
                        </>
                      )}
                      {clickedResendOTP && (
                        // <div className='clickedResendOTP'>
                        <Button
                          type="link"
                          onClick={hideOTPPanel}
                          style={{
                            float: "right",
                            color: "rgb(5, 28, 87)",
                            margin: "10px 0px 0px 0px",
                          }}
                        >
                          {" "}
                          Get OTP on Call
                        </Button>
                        // </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <span style={{ color: "#1665d8", marginLeft: "12px" }}>
                        Please call on 08047096347 to get the latest OTP
                      </span>
                    </div>
                  )}
                </Form.Item>
              </>
            )}
          </Form>
        </div>
        <div className="outer-right-div">
          {/* <img src={img} className="img" alt="img"></img> */}
        </div>
      </div>
    </>
  );
}

const LoginForm = Form.create({ name: "login" })(login);

export default LoginForm;
