import React, { useState, useEffect } from 'react';
import { Table, Input, Switch, Button, Icon } from 'antd';
import { notifyApiError } from '../../../../common/utils/utils';
import { getZonesColumn } from "../common/columns/index";
import { destroyNotification } from '../../../ui/notification/Notification';
import { fetchZones } from '../../../../services/common/fetch.services';

function ZoneList(props) {
    const { customerId, zonePermissions, setZoneStepper } = props;
    const [zones, setZones] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isActive, setStatus] = useState(true);
    const [partialContentSearch, setPartialContentSearch] = useState(undefined);
    const [searchData, setSearchData] = useState(undefined);
    const { setModal, setReadOnly, refresh, readOnly } = props;
    useEffect(() => {
        const fetchData = async () => {
            try {
                destroyNotification();
                if (!partialContentSearch) {
                    setSearchData("");
                }
                const params = {
                    pageNo,
                    pageSize,
                    isActive,
                    partialContentSearch,
                    entityIds: [customerId]
                }

                // if (isActive === false) {
                //     delete params.isActive;
                // }

                setZones([]);                
                const response = await fetchZones(params);
                const data = await response;
                if (data.maxPage < data.currentPageNo) {
                    setPageNo(data.maxPage);
                }
                else {                                        
                    if (!readOnly && data?.zones.length <= 0 && zonePermissions.zoneCreate) {
                        // setShipperAction('ADD')
                        setReadOnly(false);
                        setModal(true);
                        setZoneStepper(false);
                    } else {
                        setZones(data);
                        setZoneStepper(true);
                    }
                   
                }
            } catch (error) {
                notifyApiError(error, "Error")
            }
        };

        fetchData();
    }, [pageSize, isActive, pageNo, partialContentSearch, refresh]);


    useEffect(() => {
        if (zones?.zones) {
            document.getElementById("zoneSearchBar")?.focus()
        }
    }, [zones])


    return (
        <div className="flex-box flex-column flex-gap-xl">
            <div className="flex-box align-items-center justify-content-between flex-gap-m">
                <div className="flex-4 flex-box align-items-center justify-content-between flex-gap-xl">
                    <div className="flex-5">
                        <Input
                            prefix={<Icon type="search" />}
                            placeholder={"Search by Zone name, State, City or Pincode"}
                            value={searchData}
                            suffix={
                                searchData &&
                                <Icon type="close-circle"
                                    onClick={() => {
                                        setSearchData(undefined);
                                        setPartialContentSearch(undefined)

                                    }} />
                            }
                            id={"zoneSearchBar"}
                            disabled={zones.length == 0}
                            onChange={(e) => {
                                setSearchData(e.target.value)
                                if (e.target.value.trim() === "") {
                                    setPartialContentSearch(undefined)
                                }
                            }}
                            onPressEnter={(e) => {
                                if (typeof e.target.value !== "undefined" && e.target.value.trim() !== "") {
                                    setPartialContentSearch(e.target.value)
                                }
                                else {
                                    setPartialContentSearch(undefined)
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1 flex-box align-items-center flex-gap-l">
                        <div> Show Active </div>
                        <Switch
                            checked={isActive}
                            onChange={checked => setStatus(checked)}
                        />
                    </div>
                </div>
                <div className="flex-1 flex-box justify-content-flex-end">
                    {zonePermissions.zoneCreate &&
                        <div className="">
                            <Button block className="lsn-btn-primary" onClick={() => {
                                props.showZoneDetails(false);
                                setReadOnly(false);
                                setModal(true);
                            }}>Add Zone</Button>
                        </div>
                    }
                </div>
            </div>
            <div>
                <Table columns={getZonesColumn({
                    showZoneDetails: props.showZoneDetails,
                    setModal: setModal
                })}
                    loading={zones.length == 0}
                    dataSource={zones?.zones}
                    rowKey={'id'}
                    bordered
                    pagination={{
                        onChange: (pageNo) => setPageNo(pageNo),
                        total: zones?.totalCount,
                        showTotal: (total, range) => `${total} Zone(s)`,
                        pageSize: zones?.currentPageSize,
                        current: zones?.currentPageNo,
                        showSizeChanger: true,
                        onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
                    }} />
            </div>
        </div>
    )
}

export default ZoneList;