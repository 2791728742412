import React from "react";
import { Constants } from "./common/utils/constants";
import Tracking from "../src/components/tracking/Tracking";
import PartnersOnboarding from "../src/components/settings/partners-onboarding/PartnersOnboarding";
import Reports from "../src/components/reports/reports";
import Ingest from "../src/components/reports/ingest/Ingest";
import Pincode from "../src/components/settings/pincode/Pincode";
import NewTracking from "../src/components/tracking/VehicleTracking/VehicleTracking";
import gmapTracking from "../src/components/tracking/GmapVehicleTracking/GmapVehicleTracking";
import RouteTracking from "../src/components/tracking/RouteTracking/RouteTracking";
import heliosTracking from "./components/tracking/HeliosTracking/HeliosTracking";
import ShowDetails from "../src/components/tracking/ShowDetails/ShowDetails";
import Import from "../src/components/import-export/import/import";
import HeliosHistoryTracking from "../src/components/tracking/HeliosHistoryTracking/HeliosHistoryTracking";
import Export from "../src/components/import-export/export/export";
import InboundForm from "../src/components/inbound/form/inbound-form";
import CustomerDashboard from "../src/components/settings/customer/CustomerDashboard";
import CustomerForm from "../src/components/settings/customer/CustomerForm";
import LocationDashboard from "../src/components/settings/location/LocationDashboard";
import LocationForm from "../src/components/settings/location/LocationForm";
import RouteDashboard from "../src/components/settings/route/RouteDashboard";
import RouteForm from "../src/components/settings/route/RouteForm";
import NoPermission from "../src/components/shared/NoPermission/NoPermission";
import TrackingContainerPop from "../src/components/TrackingPopup/TrackingContainerPop";
import UserDashboard from "./components/settings/user/UserDashboard";
import UserForm from "./components/settings/user/UserForm";
import Trip from "./components/inventory/trip/trip";
import TripCreate from "./components/inventory/trip/trip.create";
import CompletedTrips from "./components/trips/completed/completed-trips";
import PendingTrips from "./components/trips/pending/pending-trips";
import ZoneDashboard from "./components/shared-components/zone/ZoneDashboard";
import ZoneForm from "./components/shared-components/zone/ZoneForm";
import InventoryAwb from "./components/inventory/awb/index";
import Print from "./components/print/print";
import Expected from "./components/inbound/expected";
import Exception from "./components/inbound/exception";
import InboundScan from "./components/inbound/scan";
import MailDashboard from "./components/communication/mail/MailDashboard";
import MailForm from "./components/communication/mail/MailForm";
import LMSDashboard from "./components/lms/lmsDashboard";
import LMSUserDashboard from "./components/lms/userDashboard";
import Asset from "./components/settings/asset/Asset";
import AssetCreate from "./components/settings/asset/Asset.create";
import Login from "./components/authentication/login";
import CustomerUsers from "./components/customer-users/users";
import LiveLoads from "./components/customer-panel/live-loads";
import DeliverLoads from "./components/customer-panel/delivered-loads";
import CustomerTracking from "./components/customer-tracking/tracking";
import ForgetPassword from "./components/authentication/forget-password";
import ResetPassword from "./components/authentication/reset-password";
import CustomerVehicleTracking from "../src/components/customer-tracking/VehicleTracking/VehicleTracking";
import CustomerRouteTracking from "../src/components/customer-tracking/RouteTracking/RouteTracking";
import DashboardApp from "./components/zoho-dashboard/Dashboard";
import CustomerInviteUsers from "../src/components/settings/customer-invite/users";
import PickupAssistance from "../src/components/inventory/pickupAssist/PickupAssistance";
import DrsDraft from "./components/inventory/drs-draft/DrsDraft";
import InvoiceCustomerSettlement from "./components/invoice/customer-settlement"
import TripModule from "./components/trips/trip.module";
import ZohoDashboard from "./components/settings/zoho-dashboard/zohoDashboard";
import ZohoForm from "./components/settings/zoho-dashboard/zohoForm";
import VendorList from "./components/settings/vendor/Vendor.list";
import VendorCreate from "./components/settings/vendor/Vendor.create";
import SLADashboard from "./components/sla-reasons/index";
import InboundModule from "./components/inbound/inbound.module";
import LoadModule from "./components/inbound/load.module";
import SLARulesDashboard from "./components/trips/sla-rules/slaRulesDashboard";
import VendorBlock from "./components/settings/vendor/Vendor.block";
import ShipperDashboard from "./components/settings/customer/shipper/shipperDashboard";
import CustomConfig from "../src/components/settings/config/config";
import ConfigEditor from "../src/components/settings/config/configEditor";
import InboundPTLForm from "../src/components/inbound/form/inbound-ptl-form";
import DownloadFile from "./components/shared-components/download-file";
import ManifestSheet from "./components/reports/manifest-sheet";
import PrintHistory from "./components/inbound/print-history";
import CoLoadertrip from "./components/inventory/trip/co-loader-trip/trip.create";
import B2CForm from "./components/inventory/drs/forms/b2c-form";
import B2BForm from "./components/inventory/drs/forms/b2b-form";
import VerificationModule from "./components/verification/verification.module";
import VerificationTrips from "./components/verification/trips/trip-list";
import VerificationDocket from "./components/verification/docket/docket-list";
import VerificationDocketsDetails from "./components/verification/docket/docket-details";
import VerificationPods from "./components/verification/pod/pod-list";
import PodDetailsPage from "./components/verification/pod/pod-details-page";
import TripDetails from "./components/verification/trips/trip-details";
import TripFinish from "./components/import-export/trip-finish";
import VehiclesList from "./components/settings/asset/vehicles";
import CategoryForm from "./components/settings/asset/vehicles/CategoryForm";
import VerificationInvoice from "./components/verification/docket/invoice-details";
import EwayModule from "./components/ewaybill/eway.module";
import PartB from "./components/ewaybill/partb/partb";
import PartBUpdate from "./components/ewaybill/partb/partb-update";

import RateCardCustomConfig from "./components/settings/ratecard/ratecard-config";
import RateCardConfigEditor from "../src/components/settings/ratecard/ratecard-configEditor";
import AtlasTracking from "./components/tracking/AtlasTracking/AtlasTracking";
import AtlasHistoryTracking from "../src/components/tracking/AtlasHistoryTracking/AtlasHistoryTracking";

import CustomerDetails from "./components/settings/contract/index";
import ContractDetails from "./components/settings/contract/contracts/details/contract.details";
import VerificationContracts from "./components/verification/contracts/contracts.verification";
import ContractsNavigation from "./components/verification/contracts/contracts.navigation";

import IndentListingContainer from "./components/indent/management/listing/indent.listing.container";
import IndentCreatePage from './components/indent/management/create/indent.create.page';
import IndentViewPage from './components/indent/management/view/page/indent.view.page';

import { permissionTags } from './configs/permissionTags';
import ReportsContainer from './components/reports/reports.container';

import dummy from "./components/inventory/awb/Dummy";
import ManifestPdf from "./components/reports/Manifest-pdf/manifest-pdf-html";
import SLAListing from "./components/sla-monitoring/listing/SLAListing";
import SingleListing from "./components/sla-monitoring/single-list/SingleListing";

const communicationRoutes = [
    {
        path: "/appv2/communication/dashboard/mail",
        component: MailDashboard,
        name: "Communication",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/communication/form/mail",
        component: MailForm,
        name: "Communication",
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/communication/dashboard",
        component: MailDashboard,
        name: "Communication",
        layout: "MainLayout",
        isReact: true
    }
]

const inventoryRoutes = [    
    {
        path: "/appv2/inventory/dashboard/awb",
        component: InventoryAwb,
        name: "Awb",
        layout: "MainLayout",
        isReact: true,
        access: permissionTags.inventory.awb.read,     
    },
    {
        path: "/appv2/inventory/dashboard",
        component: InventoryAwb,
        name: "Awb",
        layout: "MainLayout",
        isReact: true,
        access: permissionTags.inventory.awb.read,        
    },
]





const inboundRoutes = [
    {
        path: "/appv2/inbound/form/:id?",
        component: InboundForm,
        name: "Inbound",
        layout: "ToggleSidebarLayout",
        access: permissionTags.inventory.awb.create
    },
    {
        path: "/appv2/inbound/ptl/form",
        component: InboundPTLForm,
        name: "Inbound",
        layout: "ToggleSidebarLayout",
        access: permissionTags.inventory.awb.create
    },
    {
        path: "/appv2/inbound/dashboard/print_history",
        name: "Inbound",
        component: PrintHistory,
        layout: "MainLayout",
        access: permissionTags.inbound.print_history.read,
        isReact: true
    },
    {
        path: "/appv2/inbound/dashboard/scan",
        component: InboundModule,
        name: "Inbound",
        isReact: true,
        redirect: true,
        layout: "MainLayout",
        access: Constants.SELECTED_APPLICATION === "B2B"
            ? permissionTags.inboundView.scan.read
            : permissionTags.inbound.scan.read
    },
    {
        path: "/appv2/inbound/dashboard/expected",
        component: Expected,
        name: "Inbound",
        layout: "MainLayout",
        access: permissionTags.inbound.expected.read,
        isReact: true
    },
    {
        path: "/appv2/inbound/dashboard/exceptions",
        component: Exception,
        name: "Inbound",
        layout: "MainLayout",
        access: permissionTags.inbound.exceptions.read,
        isReact: true
    },
    {
        path: "/appv2/inbound/dashboard",
        component: InboundModule,
        name: "Inbound",
        isReact: true,
        redirect: true,
        layout: "MainLayout",
        access: Constants.SELECTED_APPLICATION === "B2B"
            ? permissionTags.inboundView.scan.read
            : permissionTags.inbound.scan.read
    },
]

const loadRoutes = [
    {
        path: "/appv2/load/dashboard/expected",
        component: Expected,
        name: "Load View",
        layout: "MainLayout",
        isReact: true
    },

    {
        path: "/appv2/load/dashboard/exceptions",
        component: Exception,
        name: "Load View",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/load/dashboard",
        component: LoadModule,
        name: "Load View",
        redirect: true,
        isReact: true,
        layout: "MainLayout"
    },
]

const trackingRoutes = [
    {
        path: "/appv2/tracking/popup/:waybillNo?",
        name: "Waybill Tracking",
        component: TrackingContainerPop,
        layout: "BlankLayout"
    },
    {
        path: "/appv2/tracking/dashboard/tracking",
        access: permissionTags.tracking.tracking.read,
        name: "Tracking",
        component: Tracking,
        layout: "MainLayout"
    },
    {
        path: "/appv2/tracking/dashboard/vehicle-tracking",
        name: "Vehicle Tracking",
        component: NewTracking,
        layout: "MainLayout"
    },
    {
        path: "/appv2/tracking/dashboard/g-map-vehicle-tracking",
        name: "Beecon Vehicle Tracking",
        component: gmapTracking,
        layout: "MainLayout"
    },
    {
        path: "/appv2/tracking/dashboard/route-tracking/:id",
        name: "Route Tracking",
        component: RouteTracking,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/tracking/dashboard/atlas-tracking",
        access: permissionTags.tracking.heliosVehicleTracking.read,
        name: "Atlas Tracking",
        component: AtlasTracking,
        layout: "MainLayout"
    },
    {
        path: "/appv2/tracking/dashboard/atlas-history-tracking/:id",
        access: permissionTags.tracking.heliosVehicleTracking.read,
        name: "Atlas History Tracking",
        component: AtlasHistoryTracking,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/tracking/dashboard/helios-tracking",
        name: "Helios Tracking",
        component: heliosTracking,
        layout: "MainLayout"
    },
    {
        path: "/appv2/tracking/dashboard/helios-history-tracking/:id",
        name: "Helios History Tracking",
        component: HeliosHistoryTracking,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/tracking/dashboard/show-details/:id",
        name: "Show Details",
        component: ShowDetails,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/tracking/dashboard",
        name: "Tracking",
        access: permissionTags.tracking.tracking.read,
        component: Tracking,
        layout: "MainLayout"
    },
]



const reportRoutes = [
    {
        path: "/appv2/reports/dashboard/download/report",
        name: "Reports",
        component: Reports,
        isReact: true,
        layout: "MainLayout",
        access: permissionTags.reports.new_reports.read
    },
    {
        path: "/appv2/reports/dashboard/download",
        name: "Reports",
        component: ReportsContainer,
        isReact: true,
        layout: "MainLayout",
        access: permissionTags.reports.new_reports.read
    },
    {
        path: "/appv2/reports/dashboard/ingest",
        name: "Reports",
        component: Ingest,
        layout: "MainLayout",
        access: permissionTags.reports.ingest.read
    },
    {
        path: "/appv2/reports/dashboard/manifest-sheet",
        name: "Reports",
        component: ManifestSheet,
        layout: "MainLayout",
        access: permissionTags.reports.manifestSheet.read
    },
    {
        path: "/appv2/reports/dashboard",
        name: "Reports",
        component: permissionTags.reports.reports.read ? ReportsContainer : (permissionTags.reports.new_reports.read ? ReportsContainer : (permissionTags.reports.ingest.read ? Ingest : ReportsContainer)),
        layout: "MainLayout",
        access: [permissionTags.reports.reports.read, permissionTags.reports.new_reports.read, permissionTags.reports.ingest.read, permissionTags.reports.manifestSheet.read]
    },
    {
        path: "/appv2/reports/manifest-pdf/:id",
        name: "Manifest PDF",
        component: ManifestPdf,
        layout: "BlankLayout"
    },
]







const settingRoutes = [
    {
        path: "/appv2/settings/dashboard/pincode",
        name: "Pincode",
        component: Pincode,
        access: permissionTags.settings.pincode.read,
        layout: "MainLayout"
    },
    {
        path: "/appv2/settings/dashboard/partners-onboarding",
        name: "Partners Onboarding",
        access: permissionTags.settings.partners_onboarding.read,
        component: PartnersOnboarding,
        layout: "MainLayout"
    },
    {
        path: "/appv2/settings/dashboard/route",
        component: RouteDashboard,
        access: permissionTags.settings.route.read,
        name: "Settings",
        layout: "MainLayout"
    },
    {
        path: "/appv2/settings/form/route/:id?",
        component: RouteForm,
        name: "Settings",
        access: permissionTags.settings.route.read,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/settings/dashboard/location",
        component: LocationDashboard,
        access: permissionTags.settings.location.read,
        name: "Settings",
        layout: "MainLayout"
    },
    {
        path: "/appv2/settings/form/location/:id?",
        component: LocationForm,
        access: permissionTags.settings.location.read,
        name: "Settings",
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/settings/dashboard/customer/details/:customerId?/:page?",
        component: CustomerDetails,
        name: "Contracts",
        layout: "ContractTabsLayout",
        access: permissionTags.contract.customer.view,
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/customer/contracts/:customerId?/:contractId?",
        component: ContractDetails,
        access: permissionTags.contract.contractdetails.view,
        name: "Contracts",
        layout: "ContractTabsLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/customer/shippers",
        component: ShipperDashboard,
        access: permissionTags.settings.customer.shippers,
        name: "Settings",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/customer/invite-user",
        component: CustomerInviteUsers,
        access: permissionTags.settings.customer.invite,
        name: "Settings",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/customer",
        component: CustomerDashboard,
        access: permissionTags.settings.customer.read,
        name: "Settings",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/custom-config/edit",
        component: ConfigEditor,
        // access: permissionTags.settings.custom_config.read,
        name: "Settings",
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/custom-config",
        component: CustomConfig,
        name: "Settings",
        // access: permissionTags.settings.custom_config.read,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/ratecard-config/edit",
        component: RateCardConfigEditor,
        name: "Settings",
        layout: "ToggleSidebarLayout",
        access: permissionTags.settings.ratecard_config.read,
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/ratecard-config",
        component: RateCardCustomConfig,
        access: permissionTags.settings.ratecard_config.read,
        name: "Settings",
        layout: "MainLayout",
        isReact: true
    },

    {
        path: "/appv2/settings/form/customer/:id?",
        component: CustomerForm,
        access: permissionTags.settings.customer.create,
        name: "Settings",
        layout: "ToggleSidebarLayout",
        isReact: true
    },

    {
        path: "/appv2/settings/dashboard/zoho-dashboard",
        component: ZohoDashboard,
        name: "Settings",
        access: permissionTags.settings.zoho.read,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/form/zoho-dashboard/:id?",
        component: ZohoForm,
        access: permissionTags.settings.zoho.read,
        name: "Settings",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/vendor/blocked/:id",
        component: VendorBlock,
        access: permissionTags.settings.vendor.read,
        name: "Vendor",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/vendor",
        component: VendorList,
        access: permissionTags.settings.vendor.read,
        name: "Vendor",
        redirect: true,
        layout: "MainLayout"
    },
    {
        path: "/appv2/settings/form/vendor/:id?",
        component: VendorCreate,
        access: permissionTags.settings.vendor.read,
        name: "Vendor Management",
        redirect: true,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/settings/dashboard/user",
        component: UserDashboard,
        access: permissionTags.settings.user.read,
        name: "Settings",
        layout: "MainLayout",
        isReact: true
    },

    {
        path: "/appv2/settings/dashboard/asset/categories",
        component: VehiclesList,
        name: "Asset",
        access: permissionTags.settings.asset.read,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/form/asset/categories",
        component: CategoryForm,
        name: "Asset",
        layout: "MainLayout",
        access: permissionTags.settings.asset.read,
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/asset",
        component: Asset,
        access: permissionTags.settings.asset.read,
        name: "Asset",
        layout: "MainLayout",
        isReact: true
    },


    {
        path: "/appv2/settings/form/asset",
        component: AssetCreate,
        access: permissionTags.settings.asset.read,
        name: "Asset Create",
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/form/user/:id?",
        component: UserForm,
        name: "Settings",
        access: permissionTags.settings.user.read,
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/zone",
        component: () => <ZoneDashboard parent="settings" />,
        name: "Settings",
        layout: "MainLayout",
        access: permissionTags.settings.zone.read,
        isReact: true
    },
    {
        path: "/appv2/settings/form/zone",
        component: ZoneForm,
        name: "Settings",
        access: permissionTags.settings.zone.read,
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard/sla-rules",
        component: SLARulesDashboard,
        name: "Settings",
        layout: "MainLayout",
        access: permissionTags.settings.slaRules.read,
        isReact: true
    },
    {
        path: "/appv2/settings/dashboard",
        component: CustomerDashboard,
        name: "Settings",
        // access: permissionTags.settings.customer.read, //TODO: to look at later
        layout: "MainLayout"
    },
]

const dashboardRoutes = [
    {
        path: "/appv2/dashboard/dashboard",
        name: "Dashboard",
        component: DashboardApp,
        layout: "MainLayout"
    },
    // {
    //     path: "/appv2/settlements/dashboard",
    //     name: "Settlements",
    //     component: Settlements,
    //     redirect: true,
    // },

    // {
    //     path: "/appv2/dashboard/dashboard/branch-summary",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/waybill-data",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/csat-feedback",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/escal-csat",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/sla",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/pod",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/revenue",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/cooling-period",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/gps-tracking",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
    // {
    //     path: "/appv2/dashboard/dashboard/serviceability",
    //     name: "Dashboard",
    //     component: Dashboard,
    //     layout: "MainLayout"
    // },
]

const tripRoutes = [
    {
        path: "/appv2/trips/dashboard/trips",
        component: Trip,
        access: permissionTags.trips.trips.read,
        name: "Trip",
        layout: "MainLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/trips/form/trips/coloader",
        component: CoLoadertrip,
        access: permissionTags.trips.trips.coloader,
        name: "Inbound",
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/trips/form/trips",
        component: TripCreate,
        access: permissionTags.trips.trips.read,
        name: "Trip",
        redirect: true,
        layout: "ToggleSidebarLayout",
        isReact: true
    },
    {
        path: "/appv2/trips/dashboard/completed-trips",
        component: CompletedTrips,
        access: permissionTags.trips.completed.read,
        name: "Trip",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/trips/dashboard/sla-reason",
        component: SLADashboard,
        access: permissionTags.trips.sla.read,
        name: "SLA",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/trips/dashboard/pending-trips",
        component: PendingTrips,
        access: permissionTags.trips.pending.read,
        name: "Trip",
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/trips/dashboard",
        component: TripModule,
        access: permissionTags.trips.trips.read,
        name: "Trip",
        layout: "MainLayout",
        redirect: true,
        isReact: true
    },
]

const verifictionRoutes = [
    {
        path: "/appv2/verification/form/pod",
        component: PodDetailsPage,
        access: permissionTags.verification.pod.read,
        name: "Verification",
        layout: "ToggleSidebarLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/dashboard/pod",
        component: VerificationPods,
        access: permissionTags.verification.pod.read,
        name: "Verification",
        layout: "MainLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/form/invoice",
        component: VerificationInvoice,
        name: "Verification",
        access: permissionTags.verification.docket.read,
        layout: "MainLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/form/docket",
        component: VerificationDocketsDetails,
        name: "Verification",
        access: permissionTags.verification.docket.read,
        layout: "ToggleSidebarLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/dashboard/docket",
        component: VerificationDocket,
        name: "Verification",
        layout: "MainLayout",
        access: permissionTags.verification.docket.read,
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/dashboard/contract/details",
        component: ContractsNavigation,
        name: "Verification",
        access: permissionTags.verification.contract.read,
        layout: "ToggleSidebarLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/dashboard/contract",
        component: VerificationContracts,
        name: "Verification",
        layout: "MainLayout",
        access: permissionTags.verification.contract.read,
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/dashboard/vtrips",
        component: VerificationTrips,
        name: "Verification",
        layout: "MainLayout",
        access: permissionTags.verification.trips.read,
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/verification/form/vtrips",
        component: TripDetails,
        name: "Verification",
        redirect: true,
        layout: "ToggleSidebarLayout",
        access: permissionTags.verification.trips.read,
        isReact: true
    },
    {
        path: "/appv2/verification/dashboard",
        component: VerificationModule,
        name: "Verification",
        layout: "MainLayout",
        redirect: true,
        isReact: true
    },
]

const loginRoutes = [
    {
        path: "/appv2/login",
        name: "Customer Login",
        component: Login,
        layout: "BlankLayout"
    },
    {
        path: "/appv2/forget-password",
        name: "Customer Forget Password",
        component: ForgetPassword,
        layout: "BlankLayout"
    },
    {
        path: "/appv2/reset-password",
        name: "Customer Reset Password",
        component: ResetPassword,
        layout: "BlankLayout"
    },
]

const importExportRoutes = [
    {
        path: "/appv2/import-export/dashboard/docket-to-end-trip",
        component: TripFinish,
        name: "Import/Export",
        layout: "MainLayout",
        access: permissionTags.importExport.docketToFinishTrip.create,
        isReact: true
    },
    {
        path: "/appv2/import-export/dashboard/import",
        name: "Import/Export",
        component: Import,
        layout: "MainLayout",
        access: permissionTags.importExport.import.create,
        isReact: true
    },
    {
        path: "/appv2/import-export/dashboard/export",
        name: "Import/Export",
        component: Export,
        layout: "MainLayout",
        access: permissionTags.importExport.export.create,
        isReact: true
    },
    {
        path: "/appv2/import-export/dashboard",
        name: "Import/Export",
        component: Import,
        layout: "MainLayout",
        access: permissionTags.importExport.import.create
    },
]



const lmsRoutes = [
    {
        path: "/appv2/lms/users/dashboard",
        name: "Users",
        access: permissionTags.lms.setup.read,
        component: LMSUserDashboard,
        layout: "MainLayout"
    },
    {
        path: "/appv2/lms/dashboard",
        name: "LMS Training Module",
        component: LMSDashboard,
        layout: "MainLayout",
        access: permissionTags.lms.setup.read,
        isReact: true
    },
]




const invoiceRoutes = [
    {
        path: "/appv2/invoice/dashboard/customer-settlement",
        name: "Customer Settlement",
        component: InvoiceCustomerSettlement,
        layout: "MainLayout",
        isReact: true
    },

    {
        path: "/appv2/invoice/dashboard",
        name: "Customer Settlement",
        component: InvoiceCustomerSettlement,
        layout: "MainLayout",
        isReact: true
    },
]

const customerTrackingRoutes = [
    {
        path: "/appv2/customer-tracking/dashboard/tracking",
        name: "Tracking",
        component: CustomerTracking,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/customer-tracking/dashboard/customer-vehicle-tracking",
        name: "Vehicle Tracking",
        component: CustomerVehicleTracking,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/customer-tracking/dashboard/customer-route-tracking/:id",
        name: "Route Tracking",
        component: CustomerRouteTracking,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/customer-tracking/dashboard",
        name: "Tracking",
        component: CustomerTracking,
        layout: "MainLayout",
        isReact: true
    },
]

const customerPanelRoutes = [
    {
        path: "/appv2/customer-users/dashboard",
        name: "Users",
        component: CustomerUsers,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/live-loads/dashboard",
        name: "Live Loads",
        component: LiveLoads,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/deliver-loads/dashboard",
        name: "Deliver Loads",
        component: DeliverLoads,
        layout: "MainLayout",
        isReact: true
    },
]

const miscRoutes = [
    {
        path: "/appv2/download",
        component: DownloadFile,
        name: "Download",
        layout: "BlankLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/dummy",
        component: dummy ,
        name: "Dummy",
        layout: "BlankLayout",
        isReact: true
    },
    {
        path: "/appv2/print_information",
        component: Print,
        name: "Print",
        layout: "BlankLayout",
        isReact: true
    },
    {
        path: "/appv2/noPermission/dashboard",
        name: "No Permission",
        component: NoPermission,
        layout: "NosidebarLayout"
    },
]

const ewaybillRoutes = [
    {
        path: "/appv2/ewaybill/dashboard/partb/update",
        name: "Ewaybill",
        component: PartBUpdate,
        access: permissionTags.ewaybill.partb.update,
        layout: "ToggleSidebarLayout"
    },
    {
        path: "/appv2/ewaybill/dashboard/partb",
        name: "Ewaybill",
        component: PartB,
        access: permissionTags.ewaybill.partb.read,
        layout: "MainLayout"
    },
    {
        path: "/appv2/ewaybill/dashboard/dashboard",
        name: "Ewaybill",
        component: EwayModule,
        access: permissionTags.ewaybill.ewaybill.read,
        layout: "MainLayout"
    },
    {
        path: "/appv2/ewaybill/dashboard",
        name: "Ewaybill",
        component: permissionTags.ewaybill.ewaybill.read ? EwayModule : (permissionTags.ewaybill.partb.read ? PartB : PartB),
        access: [permissionTags.ewaybill.ewaybill.read, permissionTags.ewaybill.partb.read],
        layout: "MainLayout"
    }
]

const indentRoutes = [
    {
        path: "/appv2/indent/dashboard",
        name: "Indent",
        component: IndentListingContainer,
        access: permissionTags.indent.indent.view,
        layout: "MainLayout"
    },
    {
        path: "/appv2/indent/create",
        name: "Indent",
        component: IndentCreatePage,
        access: permissionTags.indent.indent.create,
        layout: "MainLayout"
    },
    {
        path: "/appv2/indent/view",
        name: "Indent",
        component: IndentViewPage,
        access: permissionTags.indent.indent.view,
        layout: "MainLayout"
    }
];
const SLAMonitoringRoutes = [
    {
        path: "/appv2/sla-monitoring/detailed",
        name: "Docket SLA Monitoring",
        component: SingleListing,
        access: permissionTags.sla_monitoring.sla_monitoring.update,
        layout: "MainLayout"
    },
    {
        path: "/appv2/sla-monitoring/dashboard",
        name: "Docket SLA Monitoring",
        component: SLAListing,
        access: permissionTags.sla_monitoring.sla_monitoring.read,
        layout: "MainLayout"
    }
]

const routes = [
    ...loginRoutes,
    ...inboundRoutes,
    ...loadRoutes,
    ...inventoryRoutes,
    ...trackingRoutes,    
    ...reportRoutes,    
    ...settingRoutes,
    ...dashboardRoutes,
    ...tripRoutes,
    ...verifictionRoutes,
    ...importExportRoutes,    
    ...lmsRoutes,    
    ...invoiceRoutes,
    ...customerTrackingRoutes,
    ...customerPanelRoutes,
    ...ewaybillRoutes,    
    ...communicationRoutes,
    ...miscRoutes,    
    ...indentRoutes,
    ...SLAMonitoringRoutes

];

export default routes;
