import { IndentListingConstants as _Constants } from './indent.listing.constants';
import data from "../data/list.json";

export const IndentListingReducer = (state = {}, action) => {
    switch (action.type) {
        case _Constants.FETCH_INDENTS_LISTING_BEGIN: return fetchListingBeing(state);
        case _Constants.FETCH_INDENTS_LISTING_SUCCESS: return fetchListingSuccess(state, action);
        case _Constants.FETCH_INDENTS_LISTING_FAILURE: return fetchListingFailure(state, action);
        case _Constants.INDENT_LISTING_LOADING_START: return fetchListingBeing(state);
        case _Constants.INDENT_LISTING_LOADING_STOP: return stopIndentLoading(state);

        case _Constants.FETCH_LOVS_BEGIN: return fetchLovsBegin(state);
        case _Constants.FETCH_LOVS_SUCCESS: return fetchLovsSuccess(state, action);
        case _Constants.FETCH_LOVS_FAILURE: return fetchLovsFailure(state, action);

        case _Constants.SET_INDENT_COPY_BEGIN: return setCopyBegin(state);
        case _Constants.SET_INDENT_COPY_SUCCESS: return setCopySuccess(state, action);
        case _Constants.SET_INDENT_COPY_FAILURE: return setCopyFailure(state, action);
        default:
            return state;
    }
}

function fetchListingBeing(state) {
    return {
        ...state,
        loading: true,
        copiedIndent: {}
    };
}

function stopIndentLoading(state) {
    return {
        ...state,
        loading: false
    };
}

function fetchListingSuccess(state, action) {
    return {
        ...state,
        loading: false,
        indets: action.payload,
        error: null
    };
}

function fetchListingFailure(state, action) {
    return {
        ...state,
        loading: false,
        // indets: action.payload,
        indets: data.response, // Mock Data
        error: null // mock data
        // indents: null,
        // error: action.payload
    };
}

function fetchLovsBegin(state) {
    return {
        ...state,
        loading: true,
        lovs: []
    };
}

function fetchLovsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        lovs: action.payload.lovs
    };
}

function fetchLovsFailure(state, action) {
    return {
        ...state,
        loading: false,
        lovs: []
    };
}

function setCopyBegin(state) {
    return {
        ...state,
        loading: true,
        copiedIndent: {}
    };
}

function setCopySuccess(state, action) {    
    return {
        ...state,
        loading: false,
        copiedIndent: action.payload
    };
}

function setCopyFailure(state, action) {
    return {
        ...state,
        loading: false,
        copiedIndent: {}
    };
}
