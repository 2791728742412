import React, { Component } from 'react'
import '../drs.scss';
import { Row, Col, Card, Form, Input, Divider, Icon, Select, Button, Radio, Table, Alert } from 'antd';
import { connect } from 'react-redux';
import { FlowTypes, Constants, ApiActions } from '../../../../common/utils/constants';
import ScanService from '../../../../services/inbound/scan.service';
import { isScanedOrKeyboardEntry, notifyApiError, validateSyncData, trimByConfig, canAllow } from '../../../../common/utils/utils';
import DrsService from '../../../../services/inventory/drs.service';
import WaybillLink from '../../../shared-components/waybill-link';
import UserService from '../../../../services/settings/user.service';
import moment from 'moment';
import { SoundNotificationService } from '../../../../services/sound.service';
import MiscService from '../../../../services/misc.service';
import { permissionTags } from '../../../../configs/permissionTags';
import BulkOperation from '../../../shared-components/bulk-operation';
import { Mixpanel } from '../../../../utils/mixpanel';

const soundNotificationService = new SoundNotificationService;
class B2CForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAwbColumns: [
                {
                    title: 'AWB No.',
                    dataIndex: 'waybillNo',
                    key: 1,
                    render: data => <WaybillLink>{data}</WaybillLink>
                },
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 2,
                    render: (data, row) => (row?.customer?.name || 'NA')
                },
                {
                    title: 'Destination',
                    dataIndex: 'pincode',
                    key: 3,
                    render: (data, row) => (<div>{row.pincode.city.cityCode} - {row.pincode.zipcode}</div>)
                },
                {
                    title: 'Action',
                    dataIndex: 'customer',
                    key: 4,
                    render: (data, row, index) => (this.state.drsId && this.state.isAllowedToAddWaybills) ? (<Icon onClick={e => this.removeSelected(index)} type="delete" />) : ''
                },
            ],
            pendingAwbColumns: [
                {
                    title: 'AWB No.',
                    dataIndex: 'waybillNo',
                    key: 1,
                    render: data => <WaybillLink>{data}</WaybillLink>
                },
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 2,
                    render: (data, row) => (row?.customer?.name || 'NA')
                },
                {
                    title: 'Destination',
                    dataIndex: 'pincode',
                    key: 3,
                    render: (data, row) => (<div>{row.pincode.city.cityCode} - {row.pincode.zipcode}</div>)
                },
                {
                    title: 'Action',
                    dataIndex: 'customer',
                    key: 4,
                    render: (data, row, index) => (this.state.drsId && this.state.isAllowedToAddWaybills) ? (<Icon onClick={e => this.addSelected({ ...row, index })} type="plus" />) : ''
                },
            ],
            isAllowedToAddWaybills: true,
            drsId: props.match.params.drsId,
            selectedAwb: [],
            pendingAwb: [],
            drs: { awb: "" },
            disableSB: false,
            drsIdInputDisable: false,
            loading: { pending: true, selected: true },
            feRestriction: false,
            scanResponse: {},
            flowTypes: FlowTypes,
            flowType: FlowTypes.FORWARD,
            filterPayload: {
                filters: {
                    pageNo: 0,
                    pageSize: 10,
                    totalCount: 0
                }
            },
            scannedConsignment: {},
            configrations: null,
            scanEntry: false,
            lastScanTime: 0,
            currentScanEntryTime: 0,
            inputEntryTimeList: [],
            disableRemoveFromSelectedWaybill: false,
            sampleBookingDownloadLinkIcici: `https://loadshare-v2.s3.ap-south-1.amazonaws.com/dev/DRS_Bulk_upload.xlsx`,
            uploadBookingErrorMessage: "",
            showBookingFileName: true,
            redirectBulkBookingUrl:
                (Constants.SELECTED_APPLICATION == "B2B" ? "load" : "inbound") +
                "/dashboard/scan",

            newBookingOptions: [
                { id: 0, label: "Create Booking" },
                { id: 1, label: "Bulk Booking" }
            ],
            organizationFilteredValues: Constants.organizationFilteredValues,
            uploadBookingMethod: (new ScanService).uploadBookingDrs,
            uploadBookingMethodParams: {
                drsCode: null,
                drsId: null
            },
            drsPermissions: {
                read: canAllow(permissionTags.inventory.drs.read),
                update: canAllow(permissionTags.inventory.drs.update),
                drsBulkCreate: canAllow(permissionTags.inventory.drs.bulk_create),
            },
            alert: { visible: false, type: "", message: "" }
        }
        this.inputEl = React.createRef();
        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        Mixpanel.identify(loggedPartnerId);
        Mixpanel.people.set({
            $first_name: user?.name,
            $email: user?.email,
            $phone_number: user?.number,
            $partner_id: user?.partner?.id,
            $partner_name: user?.partner?.name,
        });
    }
    componentDidMount() {
        this.getConsignments();
        if (this.state.drsId) {
            Mixpanel.track("B2C DRS Update from initialed", { 'ERPInventoryDRSFormInit': moment().valueOf(), drsId: this.state.drsId });
            (new DrsService)
                .getDrs(this.state.drsId)
                .then(({ data }) => {
                    this.setState(state => ({
                        loading: {
                            ...state.loading,
                            selected: false
                        }
                    }), () => {
                        if (data !== null) {
                            let drsData = data.response.drses[0];
                            this.setState(state => ({
                                drsData,
                                uploadBookingMethodParams: {
                                    ...state.uploadBookingMethodParams,
                                    drsCode: drsData.drsCode,
                                    drsId: drsData.id
                                }
                            }), () => {
                                this.patchDrsForm();
                                const difference = moment(drsData.effectiveDate).diff(
                                    moment().startOf("day"),
                                    "seconds",
                                    true
                                );
                                if (difference < 0) {
                                    this.setState({
                                        isAllowedToAddWaybills: false,
                                    }, () => {
                                        notifyApiError(
                                            "Your allowed to manage waybills when drs was created today!!",
                                            "Validation",
                                        );
                                    })
                                }
                            })
                        }
                    })
                });
        } else {
            Mixpanel.track("B2C DRS Create from initialed", { 'ERPInventoryDRSFormInit': moment().valueOf() });
            this.setState(state => ({
                loading: {
                    ...state.loading,
                    selected: false
                }
            }))
        }
        this.getUsers();
        this.setFeRestrictionFlag();
    }

    removeSelected = (awbIndex) => {
        const waybill = {
            waybillNo: this.state.selectedAwb[awbIndex].waybillNo,
            id: this.state.selectedAwb[awbIndex].id
        };
        this.manageWaybill(waybill, ApiActions.delink, awbIndex);
    }

    addSelected = (row) => {
        this.setState(state => ({
            loading: {
                ...state.loading,
                selected: true
            },
            scanEntry: isScanedOrKeyboardEntry(
                this.state.inputEntryTimeList
            )
        }))
        const waybill = {
            waybillNo: row.waybillNo,
            id: row.id
        };
        this.manageWaybill(waybill, ApiActions.link, row.index);
    }

    focusInput = () => {
        setTimeout(() => this.inputEl.current.input.focus(), 0);
    }

    scanWaybill = (value) => {
        value = value ? value.trim() : value;
        if (!value) {
            notifyApiError(
                "Waybill shouldn't  not be empty",
                "No Shipment",
            );
            this.inputEntryTimeList = [];
            this.focusInput();
            soundNotificationService.playWarning();
            return false;
        }
        if (this.props.configrations !== null) {
            this.setState({
                drs: {
                    awb: trimByConfig(
                        value,
                        this.props.configrations["waybillLengthRestriction"]
                    )
                }
            })
        }
        this.scanEntry = isScanedOrKeyboardEntry(
            this.state.inputEntryTimeList
        );
        value = value.toUpperCase();
        const awbIndex = this.getScanIndex(this.state.drs.awb);
        const waybill = { waybillNo: this.state.drs.awb };
        if (awbIndex !== -1) {
            waybill["id"] = this.state.pendingAwb[awbIndex].id;
        } else {
            this.setState({
                inputEntryTimeList: [],
            })
        }
        this.manageWaybill(waybill, ApiActions.link, awbIndex);
        this.setState({
            drs: { awb: null }
        })
    }

    setAlertMessage = (type, message) => {
        this.setState({
            alert: { visible: true, type: type, message: message }
        })
    }

    manageWaybill(waybill, action, index) {
        this.setState({
            disableScan: true
        })
        waybill["scanInputType"] = this.state.scanEntry ? "BARCODE_SCANNER" : "KEYBOARD";
        const payload = {
            request: [
                {
                    drs: {
                        drsCode: this.state.drsData.drsCode,
                        id: this.state.drsId,
                        updatedAt: Date.now(),
                        consignments: [waybill]
                    },
                    drsCode: this.state.drsData.drsCode,
                    referenceId: this.state.drsData.drsCode,
                    action: action
                }
            ]
        };
        this.scanResponse = {};
        this.updateDrs(payload).then(result => {
            this.setState({
                disableScan: true
            })
            if (result) {
                let lastScanTime = 0;
                let scanEntry = false;
                let currentScanEntryTime = new Date().getTime();
                let inputEntryTimeList = [];
                let selectedAwb = [...this.state.selectedAwb]
                let pendingAwb = [...this.state.pendingAwb]
                let filterPayload = { ...this.state.filterPayload }
                if (action === ApiActions.link) {
                    const consignment =
                        index === -1 ? this.state.scannedConsignment : this.state.pendingAwb[index];
                    this.setAlertMessage(
                        "success",
                        consignment.waybillNo + " scanned successfully"
                    );
                    selectedAwb.push(consignment);
                    if (index > -1) {
                        pendingAwb.splice(index, 1);
                    }
                    filterPayload.filters.totalCount--;
                } else if (action === ApiActions.delink) {
                    pendingAwb.unshift(this.selectedAwb[index]);
                    selectedAwb.splice(index, 1);
                    filterPayload.filters.totalCount++;
                }
                pendingAwb = [...pendingAwb];
                selectedAwb = [...selectedAwb];
                Mixpanel.track("B2C DRS waybill (AWB) " + action, { 'ERPInventoryDRSFormAWBAction': moment().valueOf(), waybill });
                this.setState(state => ({
                    disableScan: false,
                    loading: {
                        ...state.loading,
                        selected: false
                    },
                    lastScanTime,
                    scanEntry,
                    currentScanEntryTime,
                    inputEntryTimeList,
                    selectedAwb,
                    pendingAwb,
                    filterPayload,
                }), () => {
                    if (
                        this.state.filterPayload.filters.totalCount > 10 &&
                        this.state.pendingAwb.length < 4
                    ) {
                        this.getConsignments();
                    }
                    this.focusInput();
                })
            } else {
                this.setState(state => ({
                    disableScan: false,
                    loading: {
                        ...state.loading,
                        selected: false
                    }
                }))
            }
        });
    }

    getScanIndex(awb) {
        let index = -1;
        const filteredObj = this.state.pendingAwb.find(function (item, i) {
            if (item.waybillNo.toUpperCase() === awb) {
                index = i;
                return i;
            }
        });
        return index;
    }

    getDRSCode = () => {
        if (this.state.drsId) {
            return false;
        }
        MiscService.getCode("DRS").then(({ data }) => {
            if (data != null) {
                this.props.form.setFieldsValue({
                    drsId: data.response.code
                });
                this.setState(state => ({
                    uploadBookingMethodParams: {
                        ...state.uploadBookingMethodParams,
                        drsCode: data.response.code
                    }
                }))
            }
        });
    }

    setFeRestrictionFlag = () => {
        if (this.props.configrations.hasOwnProperty("is_asset_fe_restriction")) {
            this.setState({
                feRestriction: JSON.parse(this.props.configrations["is_asset_fe_restriction"])
            })
        }
    }

    checkFeRestriction = (_event) => {
        if (!this.state.feRestriction) {
            return;
        }
        if (_event.hasOwnProperty("verified") && !_event.verified) {
            notifyApiError(
                `User is not verified,
                Please verify the user from the mobile and refresh the page`,
                "Error",
            );
            this.props.form.resetFields('userId');
        }
    }

    patchDrsForm = (updateSelectedAwb = true) => {
        let selectedAwb = [...this.state.selectedAwb];
        if (updateSelectedAwb) {
            selectedAwb =
                this.state.drsData.consignments == null ? [] : this.state.drsData.consignments;
        }
        this.props.form.setFieldsValue({
            drsId: this.state.drsData.drsCode,
            userId: this.state.drsData.drsUser.id
        });
        this.setState({
            selectedAwb,
            drsIdInputDisable: true
        })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    getConsignments = () => {
        const payload = {
            filters: {
                ...this.state.filterPayload.filters,
                isArrived: true,
                isBookedOnly: true,
                flowTypes: [this.state.flowType]
            }
        }

        this.setState(state => ({
            pendingAwb: [],
            loading: { ...state.loading, pending: true },
        }))
        payload.filters["deviceFilter"] = {
            deviceType: "DESKTOP"
        };
        (new ScanService).fetchConsigmentUnassigned(payload).then(({ data }) => {
            const pendingAwb = [];
            if (data != null) {
                const consignments = data.response.consignments;
                consignments.forEach(element => {
                    if (element.consignmentBO != null && element.arrived) {
                        pendingAwb.push(element.consignmentBO);
                    }
                });

                this.setState(state => ({
                    filterPayload: {
                        filters: {
                            ...state.filterPayload.filters,
                            totalCount:
                                data.response.totalCount,
                            pageNo:
                                data.response.currentPageNo,
                        }
                    },
                    allConsignments: consignments,
                    pendingAwb,
                    loading: { ...state.loading, pending: false },
                }))
            }
        });
    }

    getUsers = () => {
        (new UserService)
            .getUsersByLocation(Constants.userLocationId)
            .then(({ data }) => {
                if (data) {
                    this.setState({
                        users: data.response.users
                    })
                }
            });
    }

    updateDrs = (payload) => {
        this.setState(state => ({
            alert: {
                ...state.alert,
                visible: false
            }
        }))
        const promise = new Promise((resolve, reject) => {
            (new DrsService)
                .updateDrs(payload)
                .then(({ data }) => {
                    const onlyCreateUpdate =
                        payload.request[0].action === ApiActions.update ||
                        payload.request[0].action === ApiActions.create;
                    if (
                        validateSyncData(
                            data.response.responses
                        ) ||
                        data.response.length > 1
                    ) {
                        const drs = data.response.responses[0].drsBO;
                        if (drs.consignments && drs.consignments.length) {
                            this.setState({
                                scannedConsignment: drs.consignments[0]
                            })
                            // this.scanResponse = {
                            //   'waybillNo': drs.consignments[0]['waybillNo'],
                            //   'criticalShipment': drs.consignments[0]['isCritical'] ? 'YES' : 'NO',
                            //   'attempts': drs.consignments[0]['attempts'],
                            //   'pincode': drs.originatedLoc.address.pincode.zipcode,
                            //   'updateOn': drs.updatedAt,
                            //   'rescheduleDate': drs.consignments[0]['rescheduleDate'],
                            //   'bookingDate': drs.consignments[0]['bookingDate'] ? drs.consignments[0]['bookingDate'] : 'NA',
                            //   'undelReason': drs.consignments[0]['lastReason.reasonDescription']
                            //     ? drs.consignments[0]['lastReason.reasonDescription'] : 'NA',
                            //   'cpd': drs.consignments[0]['expectedDeliveryDate'],
                            // };
                        }
                        if (onlyCreateUpdate) {
                            this.setState(state => ({
                                drsData: drs,
                                drsId: drs.id,
                                uploadBookingMethodParams: {
                                    ...state.uploadBookingMethodParams,
                                    drsCode: drs.drsCode,
                                    drsId: drs.id,
                                }
                            }), () => {
                                this.patchDrsForm(false);
                            })
                        }
                        soundNotificationService.playSuccess();
                        resolve(true);
                    } else {
                        if (!onlyCreateUpdate) {
                            soundNotificationService.playWarning();
                            this.setAlertMessage(
                                "error",
                                data.response.responses[0].reason
                            );
                        }
                        resolve(false);
                    }
                });
        });
        return promise;
    }

    scanDrsByKeyboard = (event) => {
        const waybillNmber = event.target.value.trim();
        if (!waybillNmber || waybillNmber.length === 0) {
            this.setState({
                scanEntry: false
            })
        }

        let lastScanTime = this.state.currentScanEntryTime
            ? this.state.currentScanEntryTime
            : new Date().getTime();
        let currentScanEntryTime = new Date().getTime();
        let inputEntryTimeList = this.state.inputEntryTimeList
        if (waybillNmber && waybillNmber.length > 1) {
            inputEntryTimeList.push(
                currentScanEntryTime - lastScanTime
            );
        }

        this.setState({
            lastScanTime,
            currentScanEntryTime,
            inputEntryTimeList,
            drs: {
                awb: waybillNmber
            }
        })
    }

    backToDrsList = () => {
        if (
            this.state.drsId &&
            (this.props.form.getFieldValue('userId') == null ||
                this.state?.drsData?.drsUser?.id !== this.props.form.getFieldValue('userId'))
        ) {
            notifyApiError(
                "There are some unsaved changes in form",
                "Error",
            );
        } else {
            this.goBack();
            // this.router.navigateByUrl("inventory/dashboard/drs");
        }
    }

    submitDrs(action) {
        this.props.form.validateFieldsAndScroll((err, drs) => {
            this.setState({
                disableSB: true
            }, () => {
                const user = Constants.sessionUser;
                let payload;
                if (this.state.drsId) {
                    payload = {
                        request: [
                            {
                                drs: {
                                    drsCode: this.state.drsData.drsCode,
                                    drsUser: { id: drs.userId },
                                    id: this.state.drsId,
                                    updatedAt: Date.now()
                                },
                                drsCode: this.state.drsData.drsCode,
                                referenceId: this.state.drsData.drsCode,
                                action: action
                            }
                        ]
                    };
                } else {
                    payload = {
                        request: [
                            {
                                drs: {
                                    drsCode: drs.drsId,
                                    drsUser: { id: drs.userId },
                                    createdAt: Date.now(),
                                    drsType: Constants.SELECTED_APPLICATION,
                                    partner: { id: Constants.PARTNER_ID },
                                    originatedLoc: { id: Constants.userLocationId },
                                    drsStatus: "PENDING",
                                    consignments: []
                                },
                                drsCode: drs.drsId,
                                referenceId: drs.drsId,
                                action: action
                            }
                        ]
                    };
                }
                this.updateDrs(payload).then(resp => {
                    if (resp) {
                        Mixpanel.track("B2C DRS form submit successfull", { 'ERPInventoryDRSFormSubmitSuccess': moment().valueOf() });
                    } else {
                        Mixpanel.track("B2C DRS form submit failed", { 'ERPInventoryDRSFormSubmitFail': moment().valueOf() });
                    }
                    this.setState({
                        disableSB: false
                    })
                });
            })
        })
    }

    getPaginatedData = (pageNo) => {
        this.setState(state => ({
            filterPayload: {
                filters: {
                    ...state.filterPayload.filters,
                    pageNo
                }
            }
        }), () => { this.getConsignments() });
    }

    render() {
        const { isAllowedToAddWaybills, selectedAwb, pendingAwb, loading, users, drsPermissions, sampleBookingDownloadLinkIcici } = this.state;
        const { selectedAwbColumns, pendingAwbColumns, drsIdInputDisable, drsId, drsData, drs, alert, disableScan } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        getFieldDecorator('awbs', {
            rules: [
                {
                    required: false,
                    message: 'Please select awbs'
                }
            ]
        })
        return (
            <div className="b2c-form">
                <Form {...formItemLayout} >
                    <Row gutter={12}>
                        <Col span={14}>
                            <Card title={`${drsId ? 'Update ' : 'Create '}DRS`} style={{ height: '100vh' }}>
                                <Form.Item label="DRS ID">
                                    {getFieldDecorator('drsId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input DRS ID!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter or Generate DRS ID"
                                            disabled={drsIdInputDisable}
                                            suffix={
                                                <Icon onClick={e => !drsIdInputDisable ? this.getDRSCode() : {}} type="sync" style={{ cursor: drsIdInputDisable ? 'block' : 'pointer', color: 'rgba(0,0,0,.45)', '&:hover': { color: 'black' } }} />
                                            }
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Employee">
                                    {getFieldDecorator('userId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input Employee"
                                            },
                                            {
                                                validator: (rule, value) => { this.checkFeRestriction(users.find(e => e.id === value)); return true; }
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Employee" showSearch filterOption={(input, option) => { return option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 }
                                        } showArrow disabled={drsIdInputDisable}>
                                            {users?.map(e => (
                                                <Select.Option
                                                    key={e.id}
                                                    value={e.id}
                                                >
                                                    {e.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Row gutter={[12, 12]}>
                                    <Col span={4} offset={15} >
                                        <Button size="large" block style={{ float: 'right' }} onClick={this.goBack} >CANCEL</Button>
                                    </Col>
                                    <Col span={5}>
                                        <Button size="large" block style={{ float: 'right' }} htmlType="button" onClick={() => this.submitDrs(drsId ? ApiActions.update : ApiActions.create)} type="primary">{drsId ? 'UPDATE' : 'CREATE'} DRS</Button>
                                    </Col>
                                </Row>
                                <Divider />
                                {
                                    (drsId && isAllowedToAddWaybills) && <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <h3>Scan the waybills for {drsData?.drsCode || '-'}</h3>
                                        </Col>
                                        <Col span={16}>
                                            <form onSubmit={e => { e.preventDefault(); this.scanWaybill(drs.awb) }}>
                                                <Input
                                                    size="large"
                                                    placeholder={`Scan Waybill`}
                                                    onChange={e => this.scanDrsByKeyboard(e)}
                                                    ref={this.inputEl}
                                                    value={drs.awb}
                                                    disabled={disableScan}
                                                />
                                            </form>
                                        </Col>
                                        <Col span={2} className="text-center">
                                            {
                                                drsPermissions.drsBulkCreate && <div className="element-splitter">
                                                    {'OR'}
                                                </div>
                                            }
                                        </Col>
                                        <Col span={4}>
                                            {
                                                drsPermissions.drsBulkCreate && <BulkOperation
                                                    buttonLabel="Bulk DRS"
                                                    title="Bulk DRS"
                                                    bulkType="Drsscan"
                                                    sampleDownloadLink={sampleBookingDownloadLinkIcici}
                                                    objKey="drsscan"
                                                    path="bulk_drsscans"
                                                    syncToServerAction="UPLOAD_DRSSCAN"
                                                    uploadMethod={this.state.uploadBookingMethod}
                                                    uploadMethodParams={this.state.uploadBookingMethodParams}
                                                    callback={this.callbackAfterBulkInscan}
                                                />
                                            }
                                        </Col>
                                        {
                                            alert.visible && <Col span={24}> <Alert message={alert.message} type={alert.type} /> </Col>
                                        }
                                        <Col span={3} offset={18} >
                                            <Button size="large" block style={{ float: 'right' }} onClick={this.backToDrsList} >DONE</Button>
                                        </Col>
                                        <Col span={3}>
                                            <Button size="large" block style={{ float: 'right' }} htmlType="button" onClick={() => this.scanWaybill()} type="primary">ADD</Button>
                                        </Col>
                                    </Row>
                                }
                            </Card>
                        </Col>
                        <Col span={10}>
                            <Card title={`Selected AWB ${selectedAwb.length ? "( Count-" + selectedAwb.length + " )" : ""} `}>
                                <Table
                                    columns={selectedAwbColumns}
                                    loading={loading.selected}
                                    dataSource={selectedAwb}
                                    rowKey={row => (row.id)}
                                    pagination={{
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                    }}
                                />
                            </Card>
                            <div style={{ margin: 10 }}></div>
                            <Card title={`Pending AWB ${pendingAwb.length ? "( Count-" + pendingAwb.length + " )" : ""} `}>
                                <Table
                                    columns={pendingAwbColumns}
                                    loading={loading.pending}
                                    dataSource={pendingAwb}
                                    rowKey={row => (row.id)}
                                    pagination={{
                                        onChange: this.getPaginatedData,
                                        total: this.state.filterPayload.filters.totalCount,
                                        position: 'bottom',
                                        current: this.state.filterPayload.filters.pageNo,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    configrations: state?.app?.configurations,
});
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'B2B DRS form', onValuesChange: (props, val) => { } })(B2CForm));