import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Layout, Icon, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsPane from "../../components/shared/TabsPane/TabsPane";
import Lsnalert from "../../components/shared/Lsnalert/Lsnalert";
import Sidebar from "../../components/shared/sidebar/Sidebar";
import Header from "../../components/shared/header/Header";
import RouteWithTitle from "../RouteWithTitle";
import "../app.scss";
import AuditNotice from "../../components/inventory/audit/audit-notice";
import { canAllow } from "../../common/utils/utils";
import AccessDenied from "../../components/authentication/access/AccessDenied";

class MainLayout extends Component {
  // enterEvent = (event) => {
  //   // console.log("eveb", event)
  //   const node = document.querySelector(".sidebar-collapse-icon");
  //   node.style["transition"] = "opacity 0.5s linear";
  //   node.style["opacity"] = "1";
  // };
  // exitEvent = (event) => {
  //   // console.log("eveb", event)
  //   const node = document.querySelector(".sidebar-collapse-icon");
  //   node.style["transition"] = "opacity 0.5s linear";
  //   node.style["opacity"] = "0";
  // };
  render() {
    const { path, component, name, access } = this.props;

    return (
      <>
        <Header {...this.props}></Header>
        <Layout>
        <Sidebar
              {...this.props}
              collapsed={this.props.collapsed}
              onCollapse={this.props.onCollapse}
            />
            
          <Layout className="padding-container">
            <AuditNotice />
            {/* <Lsnalert></Lsnalert> */}
            <Layout className="tabs-container">
              <TabsPane {...this.props} />
            </Layout>
            <Layout>
              {path && component && (
                <RouteWithTitle
                  key={name}
                  exact={true}
                  path={path}
                  component={
                    access === undefined || canAllow(access, false, true)
                      ? component
                      : AccessDenied
                  }
                  name={name}
                />
              )}
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  configurations: state.app.configurations,
  // header: state.header,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(MainLayout))
);
