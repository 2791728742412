import React, { PureComponent } from 'react';
import * as actions from './actions';
// import './dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Tooltip, Tag, Spin, Form, Select } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect, notifyApiSuccess, notifyApiError } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { Constants } from '../../../common/utils/constants';
import debounce from 'lodash/debounce';
import SLAReasonsService from '../../../services/sla-reasons/sla-reasons.service';
import { apiRequest } from '../../../common/utils/apiGateway';

import PromiseTime from '../../shared-components/customer-promise-time/PromiseTime';
import _ from 'lodash';
let slaService = new SLAReasonsService();

const { confirm } = Modal;

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

class SLARulesDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            slaRulesPermission: {
                read: canAllow(permissionTags.settings.slaRules.read),
                update: canAllow(permissionTags.settings.slaRules.update),
                create: canAllow(permissionTags.settings.slaRules.create),
                deactivate: canAllow(permissionTags.settings.slaRules.deactivate),
            },
            filterPayload: {
                filter: {
                    "sourceEntityIds": [],
                    "sourceCityIds": [],
                    "destinationCityIds": [],
                    "sourceEntityTypes": ["LOCATION"],
                    "fetchObjects": [
                        "CUSTOMER_PROMISE_TIME_RULE_MAPPING",
                        "LOCATION_STRICT_BASIC",
                        "CUSTOMER_STRICT_BASIC",
                        "PARTNER"
                    ],
                    "destinationEntityIds": [],
                    "destinationEntityTypes": ["LOCATION"],
                    "isActive": 1,
                    "pageNo": 1,
                    "pageSize": 10
                }
            },
            source: [],
            destination: [],
            sourceLocations: [],
            destinationLocations: [],
            fetchingSource: false,
            fetchingDestination: false,
            customers: [],
            selectedCustomer: undefined,
            locationId: '',
            rulesToDeActivate: [],
            selectedRowKeys: [],
            sourceCity: undefined,
            destinationCity: undefined,
            sourceCities: [],
            selectSourceCities: [],
            destinationCities: [],
            selectDestinationCities: [],

        }
        this.fetchSourceLocation = debounce(this.fetchSourceLocation, 800);
        this.fetchDestinationLocation = debounce(this.fetchDestinationLocation, 800);
        this.fetchSourceCitiesLocation = debounce(this.fetchSourceCitiesLocation, 800);
        this.fetchDestinationCitiesLocation = debounce(this.fetchDestinationCitiesLocation, 800);
        this.fetchCustomers();

        this.fetchCities();

    }

    componentDidMount() {


        this.setState(state => ({

            columns: [
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 'customer',
                    render: (data, row) => row?.cptRuleMapping?.customer?.name
                },
                {
                    title: 'Source',
                    dataIndex: 'source',
                    key: 'source',
                    width: 140,
                    render: (data, row) => row?.cptRuleMapping?.sourceEntityBO?.name
                },
                {
                    title: 'Destination',
                    dataIndex: 'destination',
                    key: 'destination',
                    width: 140,
                    render: (data, row) => row?.cptRuleMapping?.destinationEntityBO?.name
                },
                {
                    title: 'Rule Type',
                    dataIndex: 'ruleType',
                    key: 'ruleType',
                },
                {
                    title: 'Cut off Time',
                    dataIndex: 'cutoffTime',
                    key: 'cutoffTime',
                    width: 120,
                    render: data => (<div>{data && moment(data).isValid() && moment(data).format('DD/MM/YYYY HH:mm')}  {data && !moment(data).isValid() && data} </div>)
                },
                {
                    title: 'Promise Time',
                    dataIndex: 'promiseTime',
                    key: 'promiseTime',
                    width: 120,
                    render: data => (<div>{data && moment(data).isValid() && moment(data).format('DD/MM/YYYY HH:mm')} {data && !moment(data).isValid() && data} </div>)
                },
                {
                    title: 'Base Type',
                    dataIndex: 'baseType',
                    key: 'baseType',
                    width: 100,
                },
                {
                    title: 'Days / ETA - Unit',
                    dataIndex: 'days',
                    key: 'days',
                    width: 100,
                    render: (data, row) => row?.attributes ? `${row?.attributes?.eta} ${row?.attributes?.unit}` : row?.days
                },


                // {
                //     title: 'Active',
                //     dataIndex: 'isActive',
                //     key: 'isActive',
                //     render: (data) => data ? <Tag color="green">True</Tag> : <Tag color="red">False</Tag>
                // },
                {
                    title: 'Created On',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Updated on',
                    dataIndex: 'updatedAt',
                    key: 'updatedAt',
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Action',
                    key: 'action',
                    width: 80,
                    render: (text, record) =>
                        <>
                            {this.state.slaRulesPermission.update &&
                                <Row>
                                    <Col span={12}>
                                        <Tooltip title="CPT rules">
                                            <Icon type="edit" onClick={() => this.openCPTRules(record)} />
                                        </Tooltip>

                                    </Col>
                                </Row>
                            }
                        </>

                },


            ],
            visibleCutOff: false,
            customerPromisTime: {},
            editCustomerPromise: {},
            locationGroup: ''
        }));

        this.getPaginatedData(this.state.pageNo)
        // this.fetchLocation();
    }

    setCustomerPromise = (customerPromisTime) => {
        this.setState({ customerPromisTime: customerPromisTime, visibleCutOff: false, cpthasSet: true, locationGroup: '' }, () => {
            let payload = {
                "locationId": this.state.locationId,
                "customerPromiseTimeRuleMappingBOS": customerPromisTime,
            }
            // console.log("payload", payload)
            apiRequest({
                url: `/b2b/v1/customerPromiseTime/rules`,
                method: 'POST',
                data: payload
            }).then(res => {
                this.getPaginatedData(this.state.pageNo)
                console.log("resp", res)
            })
        })
    }

    openCPTRules = (record) => {

        let { cptRuleMapping } = record;
        let editCustomerPromise = [];
        if (record?.ruleType === "CPT_ETA_BOOKING") {
            editCustomerPromise = [{
                "customerId": cptRuleMapping?.customer?.id,
                "customerPromiseTimeRuleBOS": [{
                    baseType: record?.baseType,
                    customerId: cptRuleMapping?.customer?.id,
                    customerPromiseTimeRuleMappingId: record?.customerPromiseTimeRuleMappingId,
                    cutoffTime: record?.cutoffTime,
                    days: record?.days,
                    attributes: record?.attributes,
                    id: record?.id,
                    isActive: cptRuleMapping?.isActive,
                    locationType: cptRuleMapping?.ruleType === "CPT_ETA_BOOKING" ? "ETA" : "CPT",
                    name: cptRuleMapping?.sourceEntityId,
                    partnerId: cptRuleMapping?.partnerId,
                    promiseTime: record?.promiseTime,
                    ruleType: record?.ruleType
                }],
                "destinationEntityId": cptRuleMapping?.destinationEntityId,
                "destinationEntityType": cptRuleMapping?.destinationEntityType,
                "id": cptRuleMapping?.id,
                "isActive": record?.isActive,
                "partnerId": cptRuleMapping?.partnerId,
                "sourceEntityId": cptRuleMapping?.sourceEntityId,
                "sourceEntityName": cptRuleMapping?.sourceEntityBO?.name,
                "sourceEntityType": cptRuleMapping?.sourceEntityType,
            }]
        } else {
            editCustomerPromise = [{
                "customerId": cptRuleMapping?.customer?.id,
                "customerPromiseTimeRuleBOS": [{
                    baseType: record?.baseType,
                    customerId: cptRuleMapping?.customer?.id,
                    customerPromiseTimeRuleMappingId: record?.customerPromiseTimeRuleMappingId,
                    cutoffTime: record?.cutoffTime,
                    days: record?.days,
                    id: record?.id,
                    isActive: cptRuleMapping?.isActive,
                    locationType: cptRuleMapping?.ruleType === "CPT_ETA_BOOKING" ? "ETA" : "CPT",
                    name: cptRuleMapping?.sourceEntityId,
                    partnerId: cptRuleMapping?.partnerId,
                    promiseTime: record?.promiseTime,
                    ruleType: record?.ruleType
                }],
                "destinationEntityId": cptRuleMapping?.destinationEntityId,
                "destinationEntityType": cptRuleMapping?.destinationEntityType,
                "id": cptRuleMapping?.id,
                "isActive": record?.isActive,
                "partnerId": cptRuleMapping?.partnerId,
                "sourceEntityId": cptRuleMapping?.sourceEntityId,
                "sourceEntityName": cptRuleMapping?.sourceEntityBO?.name,
                "sourceEntityType": cptRuleMapping?.sourceEntityType,
            }]
        }

        this.setState({ visibleCutOff: true, editCustomerPromise, locationGroup: cptRuleMapping?.customer?.id, locationId: cptRuleMapping?.sourceEntityId })
        // return <PromiseTime cutomerPromise={this.setCustomerPromise} initialData={editCustomerPromise} edit={true} />
    }

    handleOkshowCutoff = e => {
        this.setState({ visibleCutOff: false, editCustomerPromise: {} });
    };

    handleCancelshowCutoff = () => {
        this.setState({ loading: true, visibleCutOff: true, editCustomerPromise: {}, locationId: '' });
    };

    fetchCustomers = () => {
        const payload = {
            "filters": {
                "customerTypes": ["CUSTOMER"],
                "status": ["ACTIVE"],
                "fetchMode": "STRICT_BASIC",
                "partnerIds": [Constants.PARTNER_ID]
            }
        }
        slaService.getCustomers(payload).then(res => {
            this.setState({ customers: res?.data?.response?.customers })
        })
    }

    fetchSourceLocation = (value) => {
        if (value) {
            let params = {};
            let level = Constants.sessionUser.userLevel;
            this.setState({ sourceLocations: [], fetchingSource: true })
            if (_.isUndefined(this.state.selectedCustomer)) {
                params = { "filters": { "entityTypes": ["CUSTOMER"], "entityIds": [], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }
            } else {
                params = { "filters": { "entityTypes": ["CUSTOMER"], "entityIds": [this.state.selectedCustomer], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }
            }


            slaService.getSLAConsignmentsLocations(params).then(res => {
                this.setState({ sourceLocations: res?.data?.response?.locations, fetchingSource: false })
            })
        }

    }

    fetchDestinationLocation = (value) => {
        if (value) {
            let params = {};
            // let level = Constants.sessionUser.userLevel;    
            this.setState({ destinationLocations: [], fetchingDestination: true })
            if (_.isUndefined(this.state.selectedCustomer)) {
                params = { "filters": { "entityTypes": ["CUSTOMER"], "entityIds": [], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }
            } else {
                params = { "filters": { "entityTypes": ["CUSTOMER"], "entityIds": [this.state.selectedCustomer], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }
            }


            slaService.getSLAConsignmentsLocations(params).then(res => {
                this.setState({ destinationLocations: res?.data?.response?.locations, fetchingDestination: false })
            })
        }

    }

    fetchCities = () => {
        slaService.getCities().then(res => {

            let sourceCities = res?.data?.response?.citiesDetails;
            let destinationCities = res?.data?.response?.citiesDetails;
            this.setState({
                sourceCities,
                destinationCities
            })
        })

    }

    fetchSourceCitiesLocation = value => {
        if (value) {
            let cities = this.state.sourceCities.filter(c => c?.name?.toLowerCase().indexOf(value.toLowerCase()) >= 0);

            let selectSourceCities = cities;
            this.setState({ selectSourceCities });
        }
    }

    fetchDestinationCitiesLocation = (value) => {
        if (value) {
            let cities = this.state.destinationCities.filter(c => c?.name?.toLowerCase().indexOf(value.toLowerCase()) >= 0);

            let selectDestinationCities = cities;
            this.setState({ selectDestinationCities });
        }
    }



    applyPagination = (filter, isReset = false) => {
        if (isReset) {
            this.setState({
                pageNo: 1
            })
        }
        this.props.fetchSLARules({ filter: { ...this.state.filterPayload.filter, ...filter } });
    }

    handleSource = source => {
        if (source.length === 0) {
            this.setState({ sourceLocations: [] })

        }
        this.setState({ source })

    }

    handleDestination = destination => {
        if (destination.length === 0) {
            this.setState({ destination: [] })

        }
        this.setState({ destination })

    }

    handleSourceCity = sourceCity => {
        if (sourceCity.length === 0) {
            this.setState({ selectSourceCities: [] });

        }
        this.setState({ sourceCity })

    }

    handleDestinationCity = destinationCity => {
        if (destinationCity.length === 0) {
            this.setState({ selectDestinationCities: [] });

        }
        this.setState({ destinationCity })
    }






    getPaginatedData = (pageNo, pageSize = 10) => {
        // console.log("page", pageNo,paginatedData)
        // const { PaginatedUsers, } = this.props;
        // if (!PaginatedUsers ? !PaginatedUsers : !PaginatedUsers[pageNo]) {
        //   this.applyPagination({ pageNo, partialContentSearch: this.state.partialContentSearch })
        // }
        this.applyPagination({ pageNo, pageSize })
        this.setState({
            pageNo
        });
    }

    changeSizeChanger = (current, size) => {
        this.applyPagination({ pageSize: size });
        this.setState({ pageSize: size, pageNo: 1 })
        // console.log("current", current, size)
    }

    applyfilters = () => {
        let filterPayload = {
            filter: {
                "sourceEntityIds": this.state.source,
                "sourceEntityTypes": ["LOCATION"],
                "fetchObjects": [
                    "CUSTOMER_PROMISE_TIME_RULE_MAPPING",
                    "LOCATION_STRICT_BASIC",
                    "CUSTOMER_STRICT_BASIC",
                    "PARTNER"
                ],
                "destinationEntityIds": this.state.destination,
                ...(this.state.selectedCustomer && { "customerIds": [this.state.selectedCustomer] }),
                "destinationEntityTypes": ["LOCATION"],
                "sourceCityIds": this.state.sourceCity,
                "destinationCityIds": this.state.destinationCity,
                "isActive": 1,
                "pageNo": 1,
                "pageSize": this.state.pageSize
            }
        }

        this.setState({ filterPayload, pageNo: 1 }, () => {

            this.applyPagination({
                "sourceEntityIds": this.state.source,
                "sourceEntityTypes": ["LOCATION"],
                "destinationEntityIds": this.state.destination,
                "sourceCityIds": this.state.sourceCity,
                "destinationCityIds": this.state.destinationCity,
                "destinationEntityTypes": ["LOCATION"],
                ...(this.state.selectedCustomer && { "customerIds": [this.state.selectedCustomer] }),
                "isActive": 1,
                "pageNo": 1,
                "pageSize": this.state.pageSize
            })
        })

    }

    resetFilters = e => {
        let filterPayload = {
            filter: {
                "sourceEntityIds": [],
                "sourceCityIds": [],
                "destinationCityIds": [],
                "sourceEntityTypes": ["LOCATION"],
                "fetchObjects": [
                    "CUSTOMER_PROMISE_TIME_RULE_MAPPING",
                    "LOCATION_STRICT_BASIC",
                    "CUSTOMER_STRICT_BASIC",
                    "PARTNER"
                ],
                "destinationEntityIds": [],
                "destinationEntityTypes": ["LOCATION"],
                "isActive": 1,
                "pageNo": 1,
                "pageSize": 10
            }
        }
        this.setState({ pageSize: 10, source: undefined, destination: undefined, selectedCustomer: undefined, sourceLocations: [], destinationLocations: [], rulesToDeActivate: [], selectedRowKeys: [], selectDestinationCities: [], selectSourceCities: [], sourceCity: undefined, destinationCity: undefined, filterPayload }, () => {
            this.applyPagination({
                pageNo: 1
            }, true)
        })

    }

    handleCustomer = selectedCustomer => {

        this.setState({ selectedCustomer, source: undefined, destination: undefined, sourceLocations: [], destinationLocations: [], selectSourceCities: [], selectDestinationCities: [] })
    }

    deactivate = () => {
        // console.log("doing deativate");
        // console.log("rows", this.state.selectedRowKeys)


        if (this.state.rulesToDeActivate.length > 0) {
            const { confirm } = Modal;
            const _this = this;
            const payload = {
                ruleIds: this.state.selectedRowKeys
            }

            const rulesLength = this.state.selectedRowKeys.length;
            confirm({
                title: 'Are you sure?',
                content: 'You want to deactivate selected rules?',
                onOk: () => {
                    apiRequest({
                        url: `/b2b/v1/customerPromiseTime/rules/de-activate`,
                        method: 'POST',
                        data: payload
                    }).then(res => {
                        if (checkHttpStatus(res?.data?.status)) {
                            notifyApiSuccess(`${rulesLength} ${rulesLength == 1 ? `Rule` : 'Rules'} Deactivated successfully!!`, "SUCCESS");
                            this.setState({ rulesToDeActivate: [], selectedRowKeys: [] }, () => {
                                this.getPaginatedData(this.state.pageNo)

                            })

                            // _this.resetFilters();
                        }
                    })
                }
            })


        } else {
            Modal.error({
                title: 'Rules',
                content: 'Please select rules to deactivate',
            });
        }

    }



    render() {
        const { columns, userPermission, pageNo, sourceLocations, fetchingSource, destinationLocations, fetchingDestination, customers, selectSourceCities, selectDestinationCities, slaRulesPermission } = this.state;
        const { PaginatedUsers, rules, loading } = this.props;
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {

                let { rulesToDeActivate } = this.state;
                rulesToDeActivate = selectedRows;
                this.setState({ rulesToDeActivate, selectedRowKeys });
            },
            getCheckboxProps: record => ({
                // disabled: record.name === 'Disabled User', // Column configuration not to be checked
                id: record.id,
                name: record.name,
            }),
        };

        return (
            <>
                <Card
                    title={
                        <Row gutter={[0, 24]}>

                            <Row>
                                <Col span={24}>
                                    <h2>CUSTOMER SLAs</h2>
                                </Col>
                            </Row>
                            <Row gutter={6}>
                                <Col span={3}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Customer">
                                        <Select
                                            size="large"
                                            style={{ width: '100%' }}
                                            placeholder="All Customers"
                                            value={this.state.selectedCustomer}
                                            allowClear
                                            showSearch
                                            onChange={this.handleCustomer}
                                            filterOption={(input, option) => {
                                                // console.log("ito", option)
                                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                            }
                                            }
                                        >
                                            {
                                                customers?.map(status => (
                                                    <Select.Option value={status.id} key={status.id}>
                                                        <Tooltip title={status.name}>
                                                            {status.name}
                                                        </Tooltip>

                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Source City">
                                        <Select
                                            size="large"
                                            style={{ width: '100%' }}
                                            placeholder="All Cities"
                                            mode="multiple"
                                            value={this.state.sourceCity}
                                            notFoundContent={fetchingSource ? <Spin size="small" /> : <div>Search Location</div>}
                                            maxTagCount="1"
                                            onSearch={this.fetchSourceCitiesLocation}
                                            onChange={this.handleSourceCity}
                                            filterOption={(input, option) => {
                                                // console.log("ito", option)
                                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                            }
                                            }
                                        >
                                            {
                                                selectSourceCities?.map((city, key) => (
                                                    <Select.Option value={city?.id} key={city.id}>
                                                        <Tooltip title={city.name}>
                                                            {city.name}
                                                        </Tooltip>

                                                    </Select.Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Source Location">
                                        <Select
                                            size="large"
                                            style={{ width: '100%' }}
                                            placeholder="All Locations"
                                            mode="multiple"
                                            value={this.state.source}
                                            notFoundContent={fetchingSource ? <Spin size="small" /> : <div>Search Location</div>}
                                            maxTagCount="1"
                                            onSearch={this.fetchSourceLocation}
                                            onChange={this.handleSource}
                                            filterOption={(input, option) => {
                                                // console.log("ito", option)
                                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                            }
                                            }
                                        >
                                            {
                                                sourceLocations?.map(status => (
                                                    <Select.Option value={status.id} key={status.id}>
                                                        <Tooltip title={status.name}>
                                                            {status.name}
                                                        </Tooltip>

                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Destination City">
                                        <Select
                                            size="large"
                                            style={{ width: '100%' }}
                                            placeholder="All Cities"
                                            mode="multiple"
                                            value={this.state.destinationCity}
                                            notFoundContent={fetchingSource ? <Spin size="small" /> : <div>Search Location</div>}
                                            maxTagCount="1"
                                            onSearch={this.fetchDestinationCitiesLocation}
                                            onChange={this.handleDestinationCity}
                                            filterOption={(input, option) => {
                                                // console.log("ito", option)
                                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                            }
                                            }
                                        >
                                            {
                                                selectDestinationCities?.map((city, key) => (
                                                    <Select.Option value={city?.id} key={city.id}>
                                                        <Tooltip title={city.name}>
                                                            {city.name}
                                                        </Tooltip>

                                                    </Select.Option>
                                                ))
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4} offset={0}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Destination Location">
                                        <Select
                                            size="large"
                                            style={{ width: '100%' }}
                                            placeholder="All Locations"
                                            mode="multiple"
                                            value={this.state.destination}
                                            maxTagCount="1"
                                            onChange={this.handleDestination}
                                            notFoundContent={fetchingDestination ? <Spin size="small" /> : <div>Search Location</div>}
                                            onSearch={this.fetchDestinationLocation}
                                            filterOption={(input, option) => {
                                                // console.log("ito", option)
                                                return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                            }
                                            }
                                        >
                                            {
                                                destinationLocations?.map(status => (
                                                    <Select.Option value={status.id} key={status.id}>
                                                        <Tooltip title={status.name}>
                                                            {status.name}
                                                        </Tooltip>

                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>



                                <Col span={2} offset={0}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="">
                                        <Button size="large" loading={loading} style={{ top: 28, marginLeft: 4 }} onClick={e => this.applyfilters()} type="primary">Apply</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="">
                                        <Button size="large" loading={loading} onClick={e => this.resetFilters(e)} style={{ top: 28, backgroundColor: '#f2f2f5' }}>Reset</Button>
                                    </Form.Item>
                                </Col>
                                {slaRulesPermission?.deactivate &&
                                    <Col span={2}>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="">

                                            <Button style={{ top: 28 }} size="large" type="danger" onClick={this.deactivate}>Deactivate</Button>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>

                        </Row>
                    }
                >

                    <Table
                        bordered
                        locale={{
                            emptyText: (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }}
                        loading={loading}
                        pagination={{
                            showSizeChanger: true,
                            onShowSizeChange: this.changeSizeChanger,
                            onChange: this.getPaginatedData,
                            total: rules?.totalCount,
                            pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
                            position: 'bottom',
                            current: pageNo,

                            pageSize: rules?.currentPageSize,
                            // pageSize: rules?.pageSize,

                        }}
                        rowSelection={slaRulesPermission?.deactivate ? rowSelection : null}
                        scroll={{ x: 1250 }}
                        rowKey={(row) => (row.id)}
                        dataSource={rules?.rules}
                        columns={columns} />



                    <Modal
                        visible={this.state.visibleCutOff}
                        title="Add CutOff / CPT"
                        onOk={this.handleOkshowCutoff}
                        onCancel={this.handleOkshowCutoff}
                        width={1200}
                        footer={null}
                    >
                        <PromiseTime
                            removeIndex={1}
                            buttonLabel={"Update"}
                            getAllLocations={true}
                            pickupLocationLabel={"Source"}
                            cutoffTimeLabel={"Cut off Time"}
                            promiseTimeLabel={"Promise Time"}
                            ruleTypeLabel={"Rule Type"}

                            showReset={false}
                            pickupLocationDisable={true}
                            showAddition={false}
                            showAddingLocation={false}
                            cutomerPromise={this.setCustomerPromise}
                            locationGroup={this.state.locationGroup}
                            initialData={this.state.editCustomerPromise}
                            edit={true}
                        />
                    </Modal>
                </Card>

            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    rules: state?.slaRules?.rules,
    loading: state?.slaRules?.loading
});
const mapDispatchToProps = dispatch => ({
    fetchSLARules: (param) => dispatch(actions.fetchSLARules(param)),

});
export default connect(mapStateToProps, mapDispatchToProps)(SLARulesDashboard);