import { FETCH_PRINT_RUNSHEET_BEGIN, FETCH_PRINT_RUNSHEET_SUCCESS, FETCH_PRINT_RUNSHEET_FAILURE } from './constants';
import { notifyApiError } from '../../common/utils/utils';
import LeadsService from '../../services/leads/leads.service';

const LeadService = new LeadsService

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchPrintRunSheet = (payload) => dispatch => {
    dispatch(fetchRunSheetBegin());
    LeadService.runSheetListAll(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchRunSheetSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchRunSheetFailure(res ?.data ?.response));
        }
    });
};

function fetchRunSheetBegin() {
    return {
        type: FETCH_PRINT_RUNSHEET_BEGIN
    };
}

function fetchRunSheetSuccess(payload, isReset) {
    return {
        type: FETCH_PRINT_RUNSHEET_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchRunSheetFailure(error) {
    return {
        type: FETCH_PRINT_RUNSHEET_FAILURE,
        payload: error
    };
}