
import { initialState, FETCH_CONSIGMENTS_BEGIN, FETCH_CONSIGMENTS_SUCCESS, FETCH_CONSIGMENTS_FAILURE, FETCH_CONSIGMENT_BEGIN, FETCH_CONSIGMENT_SUCCESS, FETCH_CONSIGMENT_FAILURE, UPDATE_CONSIGMENT_BEGIN, UPDATE_CONSIGMENT_SUCCESS, UPDATE_CONSIGMENT_FAILURE } from './constants';
import { storePaginatedData } from '../../../common/utils/utils';


export const AwbReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONSIGMENTS_BEGIN: return fetchConsigmentsBegin(state);
        case FETCH_CONSIGMENTS_SUCCESS: return fetchConsigmentsSuccess(state, action);
        case FETCH_CONSIGMENTS_FAILURE: return fetchConsigmentsFailure(state, action);

        case FETCH_CONSIGMENT_BEGIN: return fetchConsigmentBegin(state);
        case FETCH_CONSIGMENT_SUCCESS: return fetchConsigmentSuccess(state, action);
        case FETCH_CONSIGMENT_FAILURE: return fetchConsigmentFailure(state, action);

        case UPDATE_CONSIGMENT_BEGIN: return updateConsigmentBegin(state);
        case UPDATE_CONSIGMENT_SUCCESS: return updateConsigmentSuccess(state, action);
        case UPDATE_CONSIGMENT_FAILURE: return updateConsigmentFailure(state, action);

        default:
            return state;
    }
}

function fetchConsigmentsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchConsigmentsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedConsigment: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedConsigment ? state.PaginatedConsigment : {}, action ?.payload ?.consignments || action ?.payload ?.viewResponse, action.isReset),
        ...action.payload
    };
}

function fetchConsigmentsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        consignments: []
    };
}

function fetchConsigmentBegin(state, action) {
    return {
        ...state,
        selectedConsigment: {
            ...state.selectedConsigment,
            loading: true
        }
    };
}

function fetchConsigmentSuccess(state, action) {
    return {
        ...state,
        selectedConsigment: {
            ...state.selectedConsigment,
            loading: false,
            [action.payload.consignments[0].id]: {
                consignments: action.payload.consignments[0]
            }
        }
    };
}

function fetchConsigmentFailure(state, action) {
    return {
        ...state,
        selectedConsigment: {
            ...state.selectedConsigment,
            loading: false,
            error: action.payload
        }
    };
}

function updateConsigmentBegin(state, action) {
    return {
        ...state,
        updateConsigment: {
            loading: true
        }
    };
}

function updateConsigmentSuccess(state, action) {
    return {
        ...state,
        updateConsigment: {
            loading: false,
            ...action.payload
        }
    };
}

function updateConsigmentFailure(state, action) {
    return {
        ...state,
        updateConsigment: {
            loading: false,
            error: action.payload,
            responses: []
        }
    };
}