import { Progress } from "antd";
import { chunk } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ScanService from "../../../../services/inbound/scan.service";
import DocketsTemplate from "./dockets.template";

const scanService = new ScanService;

export default function MultipleDocketsChallans(props) {

    const [ loadedCount, SetLoadedCount ] = useState(0);
    const [ totalCount, SetTotalCount ] = useState(0);
    const [ loading, SetLoading ] = useState(true);
    const [ consignmentData, SetConsignmentData ] = useState([]);

    const fetchConsignmentDetails = id => new Promise((resolve, reject) => {
        scanService.getConsignment(id).then(response => {
            SetLoadedCount(c => ++c);
            resolve(response?.data?.response.consignments[0]);
        }).catch(e => reject(e))
    })

    useEffect(() => {
        const fetchBatchConsignmentDetails = async ids => {
            const fetchPromises = [];
            ids.map(id => {
                if (id != "") {
                    fetchPromises.push(fetchConsignmentDetails(id))
                }
            })
            const temp = await Promise.all(fetchPromises)
            SetConsignmentData(c => [...c, ...temp]);
            return temp;
        }

        const fetchAllConsignmentDetails = async idsByBatch => {
            for (let i = 0; i < idsByBatch.length; i++) {
                await fetchBatchConsignmentDetails(idsByBatch[i])
            }
        }
        const ids = atob(props.consignmentIds).split(",").filter(i => i != "");
        SetTotalCount(ids.length);
        const idsByBatch = chunk(ids, 20);
        fetchAllConsignmentDetails(idsByBatch);

    }, []);

    useEffect(() => {
        if (loadedCount === totalCount && totalCount > 0) {
            SetLoading(false);
        }
    }, [ loadedCount ])

    return (
        <div className="multiple-challans">
            {
                loading ?
                <div className="flex-box justify-content-center set-screen-height align-items-center">
                    <div className="flex-column flex-gap-l align-items-center">
                        <Progress type="circle" percent={parseInt((loadedCount/totalCount) * 100)} 
                        strokeColor={{
                            '0%': '#1c6ce1',
                            '100%': '#50b154',
                        }} />
                        <div className="flex-box justify-content-center font-size-l-1"> Fetching consignment details... </div>
                     </div>
                </div> :
                consignmentData.map(c => (
                    <DocketsTemplate consignments={c} />
                ))
            }
        </div>
    )
}