import React, { useState, useEffect } from "react";
import { Card, Table, Spin } from "antd";

import moment from "moment";
import { columns } from "./columns";
import { fetchManifest } from "./manifest.service";

function ManifestPdf(props) {
  const [manifest, setManifest] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const tripID = props.match?.params?.id;

  useEffect(() => {
    setLoading(true);
    fetchManifest(tripID).then((response) => {
      setManifest(response);
      const { consignments } = response;

      const newData = consignments.map((con, index) => {
        return {
          id: index,
          shipper: con?.customer?.name,
          origin: con?.shipper?.pincode?.city?.name,
          consignee: con?.customer?.name,
          destination: con?.destinationLocationName,
          docketnos: con?.waybillNo,
          qty: con?.totalShipmentCount,
          weight: con?.totalChargableWeight?.toFixed(2),
        };
      });
      setDataSource(newData);
      setLoading(false);
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="flex-box justify-center" style={{ height: "100vh" }}>
        <Card>
          <div className=" grid grid-cols-6 mb-12 items-center">
            <div className="col-span-1">
              <img src={manifest?.companyLogo} className=" w-64 h-40" />
            </div>
            <div className=" col-start-3 col-end-5 ...">
              Company Name : <b>{manifest?.companyName}</b>
            </div>
            <div className=" col-end-7 col-span-1 ...">
              GST No : <b>{manifest?.companyGstNo}</b>
            </div>
          </div>
          <div className=" text-center mb-6">
            <h4>{manifest?.titleFrame}</h4>
          </div>
          <div className=" text-center mb-6">
            <h4>Vendor : </h4>
          </div>
          <div className=" grid grid-cols-2 gap-8 mb-2">
            <div className=" w-full pl-4 pr-4 py-2 bg-gray-100">
              Trip ID : <b>{manifest?.tripId}</b>
            </div>

            <div className=" w-full pl-4 pr-4 py-2 bg-gray-100">
              Date :{" "}
              <b>
                {manifest?.createdAt
                  ? moment(manifest?.createdAt).format("DD/MM/YYYY HH:mm")
                  : undefined}
              </b>
            </div>
          </div>
          <div className=" grid grid-cols-2 gap-8 mb-2">
            <div className=" w-full pl-4 pr-4 py-2 bg-gray-100">
              Created By : <b>{manifest?.creatorName}</b>
            </div>

            <div className=" w-full pl-4 pr-4 py-2 bg-gray-100">
              Total Dockets / Weight :{" "}
              <b>
                {" "}
                {manifest?.waybillCount} /{manifest?.totalConsignmentWeights}{" "}
              </b>
            </div>
          </div>
          <div className=" grid grid-cols-2 gap-8 mb-2">
            <div className=" w-full pl-4 pr-4 py-2 bg-gray-100 break-normal">
              Route : <b>{manifest?.route?.name}</b>
            </div>

            <div className=" w-full pl-4 pr-4 py-2 bg-gray-100">
              Partner / Customer : <b> </b>
            </div>
          </div>
          <div className=" grid grid-cols-2 gap-8 mb-2">
            <div className=" col-start-2 w-full pl-4 pr-4 py-2 bg-gray-100">
              Destination Hub : <b> </b>
            </div>
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered
            rowKey={record => record.id}
            pagination={false}
          />
          <div className=" grid grid-cols-3 mt-4">
            <div className=" col-end-7 col-span-1">
              <Card style={{ width: 300 }}>
                <p>
                  <b>Name : </b>
                </p>
                <p>
                  <b>Mobile : </b>
                </p>
              </Card>
            </div>
          </div>
        </Card>
      </div>
    </Spin>
  );
}
// class ManifestPdf extends Component {
//     constructor(props){
//         super(props);
//         this.state = {
//           loading: false,
//           companyName: '',
//           companyGstNo: '',
//           titleFrame: '',
//           tripId:'',
//           creatorName:'',
//           manifest: {},
//           createdDate: '',
//           dataSource: [
//             {
//               key: '1',
//               shipper: 'Pidilite',
//               origin: 'Channapatna',
//               consignee: 'Pidilite',
//               destination: '',
//               docketnos: '',
//               qty: '',
//               weight: '',
//               counts: '',
//               sign: ''
//             },
//             {
//               key: '2',
//               shipper: 'Pidilite',
//               origin: 'Channapatna',
//               consignee: 'Pidilite',
//               destination: '',
//               docketnos: '',
//               qty: '',
//               weight: '',
//               counts: '',
//               sign: ''
//             },
//           ]
//         }
//       }

//       componentDidMount()
//       {
//         this.setState({ loading: true });
//         apiUtils
//         .apiRequest({
//           url: `/b2b/v1/trip/manifest-sheet/192499`,
//           method: "GET"
//         })
//         .then(res => {
//             console.log('res');
//             console.log(res);
//             const manifest = res?.data?.response?.manifestSheet;
//             this.setState({
//                 loading: false,
//                 manifest,
//                 createdDate: manifest?.createdAt ? moment(manifest?.createdAt).format("DD/MM/YYYY HH:mm") : undefined,

//               });
//             console.log('this.state');
//             console.log(this.state);
//         });
//       }

//     render(){

//         return(

//         );
//     }
// }

export default ManifestPdf;
