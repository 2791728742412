import React, { useState } from 'react';
import ContractList from './listing/index';
import ContractFilters from "./listing/filters";
import BoxTypeListing from "./boxtypes/boxtype.listing";
import _ from "lodash";
import moment from "moment";

const defaultFilters = {
    shipperFilters: [],
    locationFilter: [],
    zoneFilter: [],
    basis: undefined,
    validFrom: '',
    validTo: '',
    serviceTypeData: [
        { id: 0, name: "FTL", value: false, storedValue: "FTL" },
        { id: 1, name: "PTL", value: false, storedValue: "PTL" },
        { id: 2, name: "LDV", value: false, storedValue: "LDV" }
    ],
    status: [
        { id: 0, name: "DRAFT", value: false, storedValue: "Draft" },
        { id: 1, name: "SUBMITTED_FOR_APPROVAL", value: false, storedValue: "Submitted for Approval" },
        { id: 2, name: "APPROVED", value: false, storedValue: "Approved" },
        { id: 3, name: "REJECTED", value: false, storedValue: "Rejected" },
        { id: 4, name: "DISCARDED", value: false, storedValue: "Discarded" },
        { id: 5, name: "FORCECLOSED", value: false, storedValue: "Force Closed" },
        { id: 6, name: "APPROVAL_IN_PROCESS", value: false, storedValue: "Approval in Process" }
    ],
    fuelStatus: [
        { id: 0, name: "ALARM", value: false, storedValue: "Alarm" },
        { id: 1, name: "ACTIVE", value: false, storedValue: "Active" },
        { id: 2, name: "INACTIVE", value: false, storedValue: "Inactive" },
        { id: 3, name: "NOT_AVAILABLE", value: false, storedValue: "Not Available" }
    ]

}
function ContractsTab(props) {
    const { customerId } = props;

    const defaultParams = {
        "filter": {
            "ids": [],
            "isActive": true,
            "shipperIds": [],
            "serviceTypes": [],
            "zoneIds": [],
            "locationIds": [],
            "basis": [],
            "fuelStatuses": [],
            "validFrom": "",
            "validTo": "",
            "pageNo": 1,
            "pageSize": 10,
            "status": [],
            "customerIds": [customerId]
        }
    }

    const [filterModal, setFilterModal] = useState(false);
    const [filterIndicator, setFilterIndicator] = useState(props?.filterParams ? JSON.stringify(props?.filterParams) != JSON.stringify(defaultParams) : false);

    const [filters, setFilters] = useState(props?.filters ? props?.filters : { ...defaultFilters });

    const [filterData, setFilterData] = useState(props?.filterData ? props?.filterData : {
        shipperData: [],
        locationData: [],
        zoneData: [],
    })

    let [params, setParams] = useState(props?.filterParams ? props?.filterParams : { ...defaultParams });
    const [boxTypeModal, setBoxTypeModal] = useState(false);


    const applyFilters = () => {
        const { filter } = params;

        const stype = filters.serviceTypeData.map(s => { if (s.value) return s.name });
        const fuel = filters.fuelStatus.map(s => { if (s.value) return s.name });
        const status = filters.status.map(s => { if (s.value) return s.name });

        setFilterData({
            shipperData: [],
            locationData: [],
            zoneData: [],
        });

        params = {
            ...params,
            filter: {
                ...filter,
                shipperIds: filters?.shipperFilters ? filters?.shipperFilters.map(shipper => shipper.key) : [],
                locationIds: filters?.locationFilter ? filters?.locationFilter.map(location => location.key) : [],
                zoneIds: filters?.zoneFilter ? filters?.zoneFilter.map(zone => zone.key) : [],
                basis: filters?.basis ? filters?.basis.map(b => b.key) : [],
                serviceTypes: _.compact(stype),
                fuelStatuses: _.compact(fuel),
                status: _.compact(status),
                validFrom: filters.validFrom ? moment(filters.validFrom).valueOf() : "",
                validTo: filters.validTo ? moment(filters.validTo).valueOf() : ""
            }
        }
        setParams(params);
        setFilterModal(false);
        setFilterIndicator(JSON.stringify(params) != JSON.stringify(defaultParams));
    }

    const resetFilters = () => {
        setFilters({ ...defaultFilters })

        params = {
            ...params,
            ...defaultParams
        }
        setParams(params);
        setFilterModal(false);
        setFilterIndicator(false);
    }

    const showContractDetails = (contractData) => {
        props.redirectToCustomerDetals(contractData.id, params, filterData, filters)
    }

    const createContract = () => {
        props.createContract();
    }

    const openBoxTypes = () => {
        setBoxTypeModal(true);
    }

    const editContractDetails = (contractData, clone) => {
        props.editContractDetails(contractData.id, params, filterData, filters, clone)
    }
    return (
        <>
            <ContractList
                customerId={customerId}
                setFilterModal={setFilterModal}
                params={params}
                setContractStepper={props?.setContractStepper}
                setParams={setParams}
                filterIndicator={filterIndicator}
                showContractDetails={showContractDetails}
                editContractDetails={editContractDetails}
                setFilterIndicator={setFilterIndicator}
                createContract={createContract}
                readOnly={props.readOnly}
                backFromDetails={props.backFromDetails}
                openBoxTypes={openBoxTypes}
            />
            {
                filterModal &&
                <ContractFilters
                    customerId={customerId}
                    visible={filterModal}
                    setFilterModal={setFilterModal}

                    filters={filters}
                    setFilters={setFilters}

                    filterData={filterData}
                    setFilterData={setFilterData}
                    resetFilters={resetFilters}
                    applyFilters={applyFilters}
                />
            }
            {
                boxTypeModal &&
                <BoxTypeListing
                    visible={boxTypeModal}
                    customer={props.customer}
                    onCancel={() => {
                        setBoxTypeModal(false);
                    }}
                    customerId={customerId}
                    setRefresh={props.setRefresh}
                    refresh={props.refresh}
                />
            }
        </>
    )
}

export default ContractsTab;