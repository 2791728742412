import { Switch } from 'antd';
import React, { useState } from 'react';
import FormWidgets from '../formWidgets';

export default function ToggleField(props) {

    const defaultChecked = props.formData?.toString().length > 0
    const [checked, SetChecked] = useState(defaultChecked);    
    return (
        <>
            <label className="control-label" htmlFor={"root_" + props.name}>
                <Switch checked={checked} onChange={SetChecked} disabled={props.disabled}
                    className={props.readonly ? "read-only" : ""} />
                <label style={{ marginLeft: '5px' }}> {props.schema.title} </label>
                {props.required &&
                    <span className="required">*</span>
                }
            </label>
            <FormWidgets.TextWidget {...props} value={props.formData} readonly={!checked} />
        </>
    )
}