import { Modal } from "antd";
import React, { useRef } from "react";
import FormWrapper from "../../../../../../ui/formComponent/form";
import { saveContractCharges } from "../../contract.details.services";
import ftlFreightSchema from "./ftl.freight.schema";

export default function FTLFreightCharges({freightCharge, SetFreightCharge, readonly, contractData, onSaveSuccess, freightChargesBO}) {

    var freightFormRef = useRef();

    return (
        <Modal visible={true} title={"Add Freight Charge"} width={600}
         onOk={_ => freightFormRef?.current?.formReference?.current?.submit()}
         onCancel={_ => SetFreightCharge(undefined)} okText={"Save"}
         footer={readonly ? false : undefined} >
            <FormWrapper {...ftlFreightSchema} ref={freightFormRef} id="ptlCharges"
             formData={freightCharge} hideSubmit={true} 
             readonly={readonly} bordered={true}
             onSubmit={data => {
                const boxExists = freightChargesBO.find(charge => charge?.chargeBasisValue === data?.chargeBasisValue)
                const isCreate  = data.id === undefined;
                if (boxExists && isCreate) {
                    return Modal.warn({
                        title : "Charge for vehicle type already exists. Choose specific row to edit its charge."
                    })
                }
                const chargesBO = [{
                    id : data.id,
                    action : data.id ? "EDIT" : "ADD",
                    chargeType  : "FREIGHT_CHARGE",
                    chargeBasis : "PER_TRIP",
                    chargeBasisValue : data?.chargeBasisValue,
                    itemRatesBO : {
                        rateStructure : "FIXED",
                        rateBOs : [{
                            fromRange : 0,
                            amount    : data?.amount,
                            rateUnitType : "TRIP"
                        }]
                    }
                }];
                saveContractCharges(contractData?.id, chargesBO)
                .then(onSaveSuccess)
                .catch(exception => {
                    console.log(exception);
                })
             }}
             onChange={SetFreightCharge} />
        </Modal>
    )
}