import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import UserService from '../../../services/settings/user.service';
import LocationService from '../../../services/settings/location.service';
import { fetchLocations } from '../../../services/common/fetch.services';

import { removeDuplicateLocations, notifyApiError } from '../../../common/utils/utils';

const userService = new UserService;
const locationService = new LocationService;

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchUsers = (payload, isReset) => dispatch => {
    dispatch(fetchUsersBegin());
    userService.getUsersfetch(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchUsersSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchUsersFailure(res ?.data ?.response));
        }
    });
};

export const deleteUser = (id, payload) => dispatch => {
    dispatch(fetchUserBegin());
    userService.deleteUser(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            fetchUsers(payload, true);
        } else {
            dispatch(fetchUserFailure(res ?.data ?.response));
        }
    });
}

function fetchUsersBegin() {
    return {
        type: _Constants.FETCH_USERS_BEGIN
    };
}

function fetchUsersSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_USERS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchUsersFailure(error) {
    return {
        type: _Constants.FETCH_USERS_FAILURE,
        payload: error
    };
}

export const userForceLogout = (id) => dispatch => {
    dispatch(doForceLogoutBegin());
    userService.userForceLogout(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(doForceLogoutSuccess(res ?.data ?.status ?.message));
        } else {
            dispatch(doForceLogoutFailure(res ?.data ?.status ?.message));
        }
    });
}

function doForceLogoutBegin() {
    return {
        type: _Constants.DO_FORCE_LOGOUT_BEGIN
    };
}

function doForceLogoutSuccess(payload) {
    return {
        type: _Constants.DO_FORCE_LOGOUT_SUCCESS,
        payload: payload
    };
}

function doForceLogoutFailure(error) {
    return {
        type: _Constants.DO_FORCE_LOGOUT_FAILURE,
        payload: error
    };
}

export const createUser = (payload) => dispatch => {
    dispatch(userModificationBegin());
    userService.createUser(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(userModificationSuccess(res ?.data ?.response));
        } else {
            dispatch(userModificationFailure(res ?.data ?.response));
        }
    });
}

export const updateUser = (id, payload) => dispatch => {
    dispatch(userModificationBegin());
    userService.updateUser(id, payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(userModificationSuccess(res ?.data ?.response));
        } else {
            dispatch(userModificationFailure(res ?.data ?.response));
        }
    });
}

function userModificationBegin() {
    return {
        type: _Constants.USER_MODIFICATION_BEGIN
    };
}

function userModificationSuccess(payload) {
    return {
        type: _Constants.USER_MODIFICATION_SUCCESS,
        payload: payload
    };
}

function userModificationFailure(error) {
    return {
        type: _Constants.USER_MODIFICATION_FAILURE,
        payload: error
    };
}

export const getUser = (id) => dispatch => {
    dispatch(fetchUserBegin());
    userService.getUser(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchUserSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchUserFailure(res ?.data ?.response));
        }
    });
}

export const clearSelectedUserData = () => dispatch => {
    dispatch(fetchUserSuccess(undefined));
    dispatch(fetchUsersSuccess(undefined));
}

function fetchUserBegin() {
    return {
        type: _Constants.FETCH_USER_BEGIN
    };
}

function fetchUserSuccess(payload) {
    return {
        type: _Constants.FETCH_USER_SUCCESS,
        payload: payload
    };
}

function fetchUserFailure(error) {
    return {
        type: _Constants.FETCH_USER_FAILURE,
        payload: error
    };
}

export const getLocations = (payload) => dispatch => {
    dispatch(fetchLocationsBegin());
    locationService.getLocationsFetch(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchLocationsSuccess(res ?.data ?.viewResponse?.viewResponse));
        } else {
            dispatch(fetchLocationsFailure(res ?.data ?.response));
        }
    });
}

function fetchLocationsBegin() {
    return {
        type: _Constants.FETCH_USERS_LOCATIONS_BEGIN
    };
}

function fetchLocationsSuccess(payload) {
    return {
        type: _Constants.FETCH_USERS_LOCATIONS_SUCCESS,
        payload: payload
    };
}

function fetchLocationsFailure(error) {
    return {
        type: _Constants.FETCH_USERS_LOCATIONS_FAILURE,
        payload: error
    };
}


export const resetUser = () => dispatch => {
    dispatch({
        type: _Constants.RESET_USER_CREATE
    })
}

export const getUserPickupLocation = (id) => dispatch => {
    dispatch(fetchUserPickupLocationBegin());
    userService.getUserPickupLocation(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchUserPickupLocationSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchUserPickupLocationFailure(res ?.data ?.response));
        }
    });
}


function fetchUserPickupLocationBegin() {
    return {
        type: _Constants.FETCH_USERS_PICKUP_LOCATION_BEGIN
    };
}

function fetchUserPickupLocationSuccess(payload) {
    return {
        type: _Constants.FETCH_USERS_PICKUP_LOCATION_SUCCESS,
        payload: payload
    };
}

function fetchUserPickupLocationFailure(error) {
    return {
        type: _Constants.FETCH_USERS_PICKUP_LOCATION_FAILURE,
        payload: error
    };
}




export const getCustomerPickupLocations = (params) => dispatch => {
    
    dispatch(fetchCustomerPickupLocationsBegin());
    fetchLocations(params).then(data => {        
        dispatch(fetchCustomerPickupLocationsSuccess(data?.locations));
    })  
}


function fetchCustomerPickupLocationsBegin() {
    return {
        type: _Constants.FETCH_CUSTOMER_PICKUP_LOCATIONS_BEGIN
    };
}

function fetchCustomerPickupLocationsSuccess(payload) {
    return {
        type: _Constants.FETCH_CUSTOMER_PICKUP_LOCATIONS_SUCCESS,
        payload: payload
    };
}

function fetchCustomerPickupLocationsFailure(error) {
    return {
        type: _Constants.FETCH_CUSTOMER_PICKUP_LOCATIONS_FAILURE,
        payload: error
    };
}