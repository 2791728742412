
export const initialState = {
}

export const FETCH_DELIVER_LOADS_BEGIN = "FETCH_DELIVER_LOADS_BEGIN";
export const FETCH_DELIVER_LOADS_SUCCESS = "FETCH_DELIVER_LOADS_SUCCESS";
export const FETCH_DELIVER_LOADS_FAILURE = "FETCH_DELIVER_LOADS_FAILURE";

export const FETCH_DELIVER_LOADS_SUMMARY_BEGIN = "FETCH_DELIVER_LOADS_SUMMARY_BEGIN";
export const FETCH_DELIVER_LOADS_SUMMARY_SUCCESS = "FETCH_DELIVER_LOADS_SUMMARY_SUCCESS";
export const FETCH_DELIVER_LOADS_SUMMARY_FAILURE = "FETCH_DELIVER_LOADS_SUMMARY_FAILURE";

export const EXPORT_DELIVER_LOADS_BEGIN = "EXPORT_DELIVER_LOADS_BEGIN";
export const EXPORT_DELIVER_LOADS_SUCCESS = "EXPORT_DELIVER_LOADS_SUCCESS";
export const EXPORT_DELIVER_LOADS_FAILURE = "EXPORT_DELIVER_LOADS_FAILURE";


export const DOWNLOAD_POD_LOADS_BEGIN = "DOWNLOAD_POD_LOADS_BEGIN";
export const DOWNLOAD_POD_LOADS_SUCCESS = "DOWNLOAD_POD_LOADS_SUCCESS";
export const DOWNLOAD_POD_LOADS_FAILURE = "DOWNLOAD_POD_LOADS_FAILURE";


export const LOCATION_DELIVER_LOADS_BEGIN = "LOCATION_DELIVER_LOADS_BEGIN";
export const LOCATION_DELIVER_LOADS_SUCCESS = "LOCATION_DELIVER_LOADS_SUCCESS";
export const LOCATION_DELIVER_LOADS_FAILURE = "LOCATION_DELIVER_LOADS_FAILURE";