import { Input } from 'antd';
import React , { Component } from 'react';

class VerificationRemarks extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            updateVerificationRemarks: false,
            verificationRemarks: props.consignment?.verificationRemarks,
            consignmentId: props.consignment?.consignmentId
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.consignmentId != nextProps.consignment.consignmentId){
            return {
                ...prevState,
                verificationRemarks: nextProps.consignment?.verificationRemarks,
                consignmentId: nextProps.consignment?.consignmentId
            }
        }
        return prevState;
    }

    componentDidUpdate(){
        this.textAreaAdjust();
    }

    onVerificationRemarksUpdate = () => {
        this.props?.onVerificationRemarksUpdate('verificationRemarks', this.state.verificationRemarks)
    }

    textAreaAdjust = _ => {
        const verificationRemarksInput = document.getElementById("verificationRemarksInput");
        if(verificationRemarksInput == null) return;
        verificationRemarksInput.style.height = "1px";
        verificationRemarksInput.style.height = (5 + verificationRemarksInput.scrollHeight) + "px";
    }
    

    render(){

        const { verificationRemarks } = this.state;
        const podVerified = this.props.consignment?.podVerificationStatus == "POD_VERIFIED"

        return (
            <div className="flex-3 flex-column flex-gap-m">
                <div>Verification Remarks</div>
                <div className="spacer-xs flex-box align-items-center justify-content-space-between">
                    <Input.TextArea id="verificationRemarksInput"
                        value={verificationRemarks} style={{width: '100%'}}
                        placeholder={!this.props.editPermissions || podVerified ? "" : "Enter Verification Remarks"}
                        disabled={!this.props.editPermissions || podVerified}
                        onChange={e => {
                            let remarks = e.target.value;
                            if(remarks.length > 128){
                                remarks = remarks.substr(0,127);
                            }
                            this.setState({ 
                                verificationRemarks: remarks 
                            }, this.onVerificationRemarksUpdate);
                        }}
                        onKeyUp={this.textAreaAdjust}
                        onLoad={this.textAreaAdjust}
                        maxLength={128}
                    />
                </div>
            </div>
        )
    }
}

export default VerificationRemarks;