import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';

export default class SLAReasonsService {

  getSLAConsignments(payload) {
    const url = `/b2b/v1/sla-delay-reason/consignments`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getSLAConsignmentsSummary(payload) {
    const url = `/b2b/v1/sla-delay-reason/summary`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getSLAConsignmentsExport(payload) {
    const url = `/b2b/v1/reports/SLA_DELAY_REASON_REPORT/get`;
    return apiRequest({
      url,
      method: "POST",
      data: payload,
      baseUrlType: 'REPORTS'
    });
  }

  getSLAConsignmentsLocations(payload) {
    const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getReasonsFetch(payload) {
    const url = `/b2c/v2/reasons/categories/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  setReasons(payload) {
    const url = `/b2b/v1/sla-delay-reason`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getCustomers(payload) {
    const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  getCities() {
    const url = `/b2b/v1/cities`;
    return apiRequest({
      url,
      method: "GET"      
    });
  }

}
