import { Icon, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import ZonesSelect from "../../../common/ui/zones.select";
import LocationsSelect from "../../../common/ui/location.select";
import { capitalizeString } from "../../../../../../common/utils/utils";
import DetentionCharges from "../detention/detention.charges";
import PlacementSLA from "../placementsla/placement.sla";
import TransitSLA from "../transitsla/transit.sla";
import { ChargesTitle } from "./lane-utils";

export default function LaneRow(props) {

    const [ entity, SetEntity ] = useState(props.lane?.entityBO);

    const entityName = capitalizeString(props.laneType.toLowerCase());

    //Modal box handlers
    const [ detentionModal, SetDetentionModal] = useState(false);
    const [ placementSLAModal, SetPlacementSLAModal ] = useState(false);
	const [ transitSLAModal, SetTransitSLAModal] = useState(false);

    useEffect(() => {
        SetEntity(props.lane?.entityBO);
    }, [ props.lane?.entityBO ]);

    const onEntitySelect = entity => {
        const entityBO = {
            id   : entity?.key,
            name : entity?.label
        } 
        SetEntity(entityBO);
        props.onEntitySelect(entityBO, props.index)
    }
    
    const onLaneDataSaveSuccess = savedLaneDetails => {
        Modal.success({
            title   : <> Successfully saved <strong> {savedLaneDetails} </strong> for <em> {entity?.name} </em>! </>
        })
    }

    return (
        <>
            <div className="flex-box flex-1 align-items-center">

                <div className="flex-1">
                    {
                        props.source ? <> Source {entityName} </> :
                        props.destination ? <> Destination {entityName} </>:
                        <> {entityName} {props.index} </>
                    }
                    <span className="color-red">*</span> 
                </div>

                {
                    props.laneType === "ZONE" ?
                    <ZonesSelect selectedZone={entity} readonly={props.readonly} index={props.index} selectedEntities={props.selectedEntities} searchNameOnly={props.laneOnly}
                    unselectedError={props.unselectedEntitiesIndex?.indexOf(props.index) > -1} customerId={props.customerId} onZoneSelect={onEntitySelect}
                    zoneTypes={props.laneOnly || props.contractDetails?.serviceType === "PTL" ? [ "RATECARD" ] : [ "OPERATIONAL" ]}/> :
                    
                    props.laneType === "LOCATION" ?
                    <LocationsSelect selectedLocation={entity} index={props.index} readonly={props.readonly} selectedEntities={props.selectedEntities}
                     unselectedError={props.unselectedEntitiesIndex?.indexOf(props.index) > -1} customerId={props.customerId} onLocationSelect={onEntitySelect}
                     unselectedErrorMessage={props.laneOnly ? "Cannot create lanes with empty locations!" : false} searchNameOnly={props.laneOnly} /> :

                    <Input disabled={true} placeholder={"Choose a lane mapping type!"}/>
                
                }
                
            </div>
            <div className={"flex-box align-items-center flex-gap-xl" + (props.laneOnly ? "" : " flex-1")} >
                {
                    !props.laneOnly &&
                    <>
                        <div className={"lane-charges" + (!entity.id ? " disabled-option" : "")} 
                            onClick={() => {
                                props.source ?
                                SetPlacementSLAModal(entity?.id ? true : false) :
                                SetTransitSLAModal(entity?.id ? true : false)
                            }}>
                            { props.source ? "Placement SLA" : "Transit SLA" }
                        </div>

                        <div className={"lane-charges" + (!entity.id ? " disabled-option" : "")} 
                        onClick={() => SetDetentionModal(entity?.id ? true : false)}> Detention </div>
                    </>
                }
                {/* {
                    !props.readonly &&
                    <div className="flex-box flex-gap-l align-items-center" style={{fontSize:18, minWidth:50}}>
                        {
                            (props.laneOnly || props.contractDetails.serviceType != "PTL") && 
                            <Icon type="plus-circle" theme="twoTone" twoToneColor="#438af3" onClick={() => props.addEntity(props.index)}/>
                        }
                        {
                            ( (!props.source && !props.destination)  || props.index > 1 ) &&
                            <Icon type="minus-circle" theme="twoTone" twoToneColor="#e6492d" onClick={() => props.removeEntity(props.index)}/>
                        }
                    </div>
                } */}
                
            </div>

            {
                !props.laneOnly &&
                <>
                    {/* Detention Charges */}
                    <Modal centered={true}
                        width={600} closable={false} footer={false}
                        visible={detentionModal}
                        title={ <ChargesTitle readonly={props.readonly} chargesType={"Detention"} name={entity?.name} onBack={() => SetDetentionModal(false)}/>}>
                        {
                            detentionModal &&
                            <DetentionCharges readonly={props.readonly} laneMappingID={props.lane?.id} contractId={props.contractId}
                            onLaneDataSaveSuccess={onLaneDataSaveSuccess}
                            onCancel={() => SetDetentionModal(false)} generateLaneMappingID={props.generateLaneMappingID} index={props.index}/>
                        }
                    </Modal>

                    {/* Placement SLA */}
                    <Modal centered={true}
                        width={1200} closable={false} footer={false}
                        visible={placementSLAModal}
                        title={ <ChargesTitle readonly={props.readonly} chargesType={"Placement SLA"} name={entity?.name} onBack={() => SetPlacementSLAModal(false)}/>}>

                        {
                            placementSLAModal && 
                            <PlacementSLA readonly={props.readonly} laneMappingID={props.lane?.id} index={props.index}
                            contractId={props.contractId} contractDetails={props.contractDetails} onLaneDataSaveSuccess={onLaneDataSaveSuccess}
                            onCancel={() => SetPlacementSLAModal(false)} generateLaneMappingID={props.generateLaneMappingID}/>
                        }
                        
                    </Modal>

                    {/* Transit SLA */}
                    <Modal centered={true}
                        width={1200} footer={false}
                        closable={false} visible={transitSLAModal}
                        title={ <ChargesTitle readonly={props.readonly} chargesType={"Transit SLA"} name={entity?.name} onBack={() => SetTransitSLAModal(false)}/>}>
                        {
                            transitSLAModal &&
                            <TransitSLA readonly={props.readonly} laneMappingID={props.lane?.id} index={props.index}
                                contractId={props.contractId} contractDetails={props.contractDetails} onLaneDataSaveSuccess={onLaneDataSaveSuccess}
                                onCancel={() => SetTransitSLAModal(false)} generateLaneMappingID={props.generateLaneMappingID}/>
                        }
                    </Modal>
                </>
            }

        </>
    )
}