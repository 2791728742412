import React, { Component } from "react";
import { PodStatusTransformer } from "../pod-status.transformer";
import { Button, Icon } from "antd";
import NameValuePair from "../../components/name-value-pair";

class PodHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const { bookingOffcLocation, podVerificationStatus, customer, destinationLocation, threePLNumber } = this.props.consignment;

        return (
            <div className="flex-box align-items-stretch flex-gap-xl border-radius-m justify-content-space-between">
                <div className="flex-1 border-grey spacer-s border-radius-m flex-box align-items-center">
                    {PodStatusTransformer(podVerificationStatus)}
                </div>

                <div className="flex-4 flex-box border-grey spacer-m border-radius-m">
                    <div className="flex-2 flex-column justify-content-space-between">
                        <NameValuePair name="Customer" value={customer} />
                        <NameValuePair name="3PL Docket No" value={threePLNumber} />
                    </div>
                    <div className="flex-2 flex-column justify-content-space-between vertical-borders spacer-hl border-grey">
                        <NameValuePair name="Booking Office" value={bookingOffcLocation} />
                        <NameValuePair name="Delivery Location" value={destinationLocation} />
                    </div>
                    <div className="flex-1 text-align-center">
                        <button className="seconadary-grey-button" onClick={this.props.handleHistoryClick}>
                            <Icon type="history" />
                            <span className="spacer-hs">View History</span>
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}

export default PodHeader;