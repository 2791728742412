import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Switch, Router, Redirect, Route, withRouter, matchPath } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";
import { Permissions } from "../src/services/permissions.service";
import {
  LOCAL_STORAGE_STATE,
  USER_LOGGEDIN_INFO
} from "../src/common/utils/constants";
import { localStorageUtils, reloadApp } from "../src/common/utils/utils";
import { getConfigurations, getLoggedInUserInfo, storeGetDataLocalStorage, userLogout, getPermissions, setSidebarState } from "../src/app/appAction";
import MainLayout from "./app/layouts/MainLayout";

import NosidebarLayout from "./app/layouts/NosidebarLayout";
import BlankLayout from "./app/layouts/BlankLayout";
import ToggleSidebarLayout from "./app/layouts/ToggleSidebarLayout";
import routes from "./routes";
import { ConfigProvider } from "antd";
import en_US from "antd/lib/locale-provider/en_US";
import history from "./utils/history";
import FreshChat from "react-freshchat";
import { Constants } from "./common/utils/constants";
import queryString from "query-string";
import ContractTabsLayout from "./app/layouts/ContractTabsLayout";
import PageNotFound from "./components/authentication/access/PageNotFound";


class AppRoute extends Component {
  
  
  state = {
    refreshFlag: null,
    configSet: null,
    payoutWebview: null    
  };
  // onCollapse = () => {
  //   // console.log('collapsed');
  //   this.props.setSidebarState(!this.props.collapsedSidebar);
  // };
  componentDidMount() {    
    
    if (
      window.location.pathname === "/appv2/digital-payment/dashboard/payout" ||
      window.location.pathname === "/appv2/digital-payment/dashboard/rate-card"
    ) {
      let params = queryString.parse(window.location.search);
      localStorage.setItem("accessToken", params.token);
      localStorage.setItem("tokenId", params.tokenid);
      localStorage.setItem("feuserId", params.feuserId);
      document.cookie = "LOGGED_IN=true;path=/";
      this.setState({ configSet: true, payoutWebview: false });
    } else {
      this.props.getConfigurations();
      // this.props.getLoggedInUserInfo();
      // this.props.storeGetDataLocalStorage();
      this.setRefreshFlag();
      window.addEventListener(
        "focus",
        event => {
          this.comapareRefreshFlag();
        },
        false
      );
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configurations !== this.props.configurations) {
      localStorageUtils({
        type: LOCAL_STORAGE_STATE.SET,
        key: USER_LOGGEDIN_INFO._CONFIGURATIONS,
        value: btoa(JSON.stringify(this.props.configurations))
      });
      this.setState({ configSet: true });
    }

    if (prevProps.login !== this.props.login) {
      Permissions.storeLoginData({
        token: this.props.token,
        user: this.props.user
      });
    }
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onfocus());
  }

  comapareRefreshFlag = () => {
    if (
      Number(this.state.refreshFlag) !==
      Number(
        localStorageUtils({
          key: USER_LOGGEDIN_INFO.REFRESH_FLAG,
          type: LOCAL_STORAGE_STATE.GET
        })
      )
    ) {
      reloadApp();
    }
  };

  setRefreshFlag() {
    const val = localStorageUtils({
      key: USER_LOGGEDIN_INFO.REFRESH_FLAG,
      type: LOCAL_STORAGE_STATE.GET
    });
    if (val) {
      this.setState({
        refreshFlag: val
      });
    } else {
      const time = new Date().getTime();
      localStorageUtils({
        key: USER_LOGGEDIN_INFO.REFRESH_FLAG,
        type: LOCAL_STORAGE_STATE.SET,
        value: time
      });
      this.setState({
        refreshFlag: time
      });
    }
  }

  getLayouts = routes => {
    let setRoutes = routes.map((prop, key) => {
      // console.log("prop.layout " + prop.layout);
      if (prop.layout === "NosidebarLayout") {
        return (
          <NosidebarLayout
            {...this.props}
            key={key}
            path={prop.path}
            component={prop.component}
            name={prop.name}
            access={prop.access}
          />
        );
      } else if (prop.layout === "ToggleSidebarLayout") {
        return (
          <ToggleSidebarLayout
            {...this.props}
            key={key}
            path={prop.path}
            component={prop.component}
            name={prop.name}
            access={prop.access}
            collapsed={this.props.collapsedSidebar}
            onCollapse={this.onCollapse}
          />
        );
      } else if (prop.layout === "BlankLayout") {
        return (
          <BlankLayout
            {...this.props}
            key={key}
            path={prop.path}
            component={prop.component}
            name={prop.name}
            access={prop.access}
          />
        );
      } else if (prop.layout === "MainLayout") {
        return (
          <MainLayout
            {...this.props}
            key={key}
            path={prop.path}
            component={prop.component}
            name={prop.name}
            access={prop.access}
            collapsed={this.props.collapsedSidebar}
            onCollapse={this.onCollapse}
          />
        );      
      } else if (prop.layout === "ContractTabsLayout") {
        return (
          <ContractTabsLayout
            {...this.props}
            key={key}
            path={prop.path}
            component={prop.component}
            name={prop.name}
            access={prop.access}
          />
        );
      }else {
        return null;
      }
    });
    return setRoutes;
  };

  render() {
    return (
      <>
        <Router history={history}>
          <ConfigProvider locale={en_US}>
            <div className="App">
              <Layout>
                {this.state.configSet && routes && (
                  <Switch>
                    {this.getLayouts(routes)}
                    <Route component={withRouter(PageNotFound)} />
                  </Switch>
                )}
                {this.state?.payoutWebview && (
                  <div>
                    {Constants.SELECTED_APPLICATION === "B2C" && (
                      <FreshChat
                        token={Constants.FRESHCHAT_TOKEN}
                        tags={["atlaserp"]}
                        email={Constants.sessionUser.email}
                        firstName={Constants.sessionUser.name}
                        phone={Constants.sessionUser.number}
                        onInit={widget => {
                          widget.user.setProperties({
                            externalId: Constants.sessionUser.id,
                            email: Constants.sessionUser.email,
                            parnter: Constants.sessionUser.partner.name,
                            location: Constants.sessionUser.location.name,
                            phone: Constants.sessionUser.number
                          });
                        }}
                      />
                    )}
                  </div>
                )}
              </Layout>
            </div>
          </ConfigProvider>
        </Router>
      </>
    );
  }
}
const mapStateToProps = state => ({
  configurations: state.app.configurations,
  loading: state.app.loading,
  // header: state.header,  
  // collapsedSidebar: state.app.collapsedSidebar
});

const mapDispatchToProps = dispatch => ({
  getConfigurations: () => dispatch(getConfigurations()),
  getLoggedInUserInfo: () => dispatch(getLoggedInUserInfo()),
  storeGetDataLocalStorage: () => dispatch(storeGetDataLocalStorage()),
  userLogout: () => dispatch(userLogout()),
  getPermissions: () => dispatch(getPermissions())
  // setSidebarState: (state) => dispatch(setSidebarState(state))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(AppRoute));
