import { Icon, Input, Modal, Tooltip } from 'antd';;
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LocationsSelect from '../../../settings/contract/common/ui/location.select';
import CreateLocationPopup from '../../../settings/contract/locations/create.location';

export default function DestinationLoadRow(props) {

    const [ location, SetLocation ] = useState(props.load.location);
    const [ weight, SetWeight ]     = useState(props.load.weight);
    const [ volume, SetVolume ]     = useState(props.load.volume);
    const [ quantity, SetQuantity ] = useState(props.load.quantity);
    const [locationModal, toggleLocationModal] = useState(false);

    const formData = useSelector(state => state.indentCreateReducer.indentData)

    useEffect(() => {
        SetLocation(props.load.location);
        SetWeight(props.load.weight);
        SetVolume(props.load.volume);
        SetQuantity(props.load.quantity);
    }, [ props.load  ])

    const onLoadUpdate = (key, value) => {
        var updatedLoad = { ...props.load };
        updatedLoad[key] = value;
        props.onSave(updatedLoad, props.index)
    }

    const onEntitySelect = l => {
        var selectedLoc = l;
        if (formData.details.pickupLocation && formData.details.pickupLocation.key == l?.key) {
            Modal.warn({
                title : "Location already selected as Pickup Location. Kindly select a different location."
            })
            selectedLoc = {
                key   : location?.id,
                label : location?.name
            }
        }
        const updatedLocation = {
            id   : selectedLoc?.key,
            name : selectedLoc?.label
        }
        SetLocation(updatedLocation);
        onLoadUpdate("location", updatedLocation);
    }

    const onLocationCreate = data => {
        onEntitySelect({
            key   : data.id,
            label : data.name
        });
        toggleLocationModal(false);
    }

    return (
        <>
            <div className="flex-box flex-gap-xl spacer-m grey-bg border-radius-s">
                <div className="flex-3 flex-box flex-gap-l align-items-center">
                    <div className="flex-6">
                        <LocationsSelect selectedLocation={location} selectedEntities={props.selectedEntities} readonly={props.readonly} searchNameOnly={true}
                        customerId={typeof formData.details?.customer?.key === "string" ? formData.details?.customer?.key.split("___")[0] : formData.details?.customer?.key} 
                        onLocationSelect={onEntitySelect} removeMargin={true} disableText={"Location already chosen for a destination location!"} />
                    </div>
                    {
                        !props.readonly && formData.details?.customer?.key && 
                        <Icon className="font-size-l-1" type="plus-circle" theme="twoTone"
                         twoToneColor="#50b154" onClick={_ => toggleLocationModal(true)}/>
                    }
                </div>

                <div className="flex-3">
                    <Input type={"number"} placeholder={"Enter Expected Weight"} readOnly={props.readonly}
                    onChange={e => {
                        SetWeight(e.currentTarget.value)
                        onLoadUpdate("weight", e.currentTarget.value);
                    }} value={weight}/>
                </div>

                <div className="flex-3">
                    <Input type={"number"} placeholder={"Enter Expected Volume"} readOnly={props.readonly}
                    onChange={e => {
                        SetVolume(e.currentTarget.value)
                        onLoadUpdate("volume", e.currentTarget.value);
                    }} value={volume}/>
                </div>

                <div className="flex-3">
                    <Input type={"number"} placeholder={"Enter Expected Quantity"} readOnly={props.readonly}
                    onChange={e => {
                        SetQuantity(e.currentTarget.value)
                        onLoadUpdate("quantity", e.currentTarget.value);
                    }} value={quantity}/>
                </div>

                {
                    !props.readonly &&
                    <div className="flex-1 flex-box align-items-center justify-content-end flex-gap-l">
                        <div className="spacer-xs flex-box dark-grey-bg border-radius-s font-size-m-3">
                            <Tooltip placement="top" title={"Delete"}>
                                <Icon type="delete" theme="twoTone" twoToneColor="#f44337"
                                onClick={() => props.deleteDestinationLoad(props.index)} />
                            </Tooltip>
                        </div>
                    </div>
                }

            </div>
        { 
            locationModal && !props.readonly && formData.details?.customer?.key &&
            <CreateLocationPopup 
                openModal={locationModal} setModal={toggleLocationModal}
                onLocationCreate={onLocationCreate}
                disableCreateAnother={true}
                customer={{
                id   : formData.details.customer.key.split("___")[0],
                code : formData.details.customer.key.split("___")[1]
            }}/>
        }
        </>
    )
}