import * as CONSTANTS from './constants';
import * as actions from './action';
// import axios from 'axios';
export const suspiciousReducer = (state = {}, action) => {
    switch (action.type) {

        case CONSTANTS.GET_SUSPICIOUS_WAYBILLS_BEGIN: return getSuspiciousWaybillsBegin(state);
        case CONSTANTS.GET_SUSPICIOUS_WAYBILLS_SUCCESS: return getSuspiciousWaybillsSuccess(state, action);
        case CONSTANTS.GET_SUSPICIOUS_WAYBILLS_FAILURE: return getSuspiciousWaybillsFailure(state);
        case CONSTANTS.GET_SUSPICIOUS_WAYBILL_DETAILS_BEGIN: return getSuspiciousWaybillDetailsBegin(state);
        case CONSTANTS.GET_SUSPICIOUS_WAYBILL_DETAILS_SUCCESS: return getSuspiciousWaybillDetailsSuccess(state, action);
        case CONSTANTS.GET_SUSPICIOUS_WAYBILL_DETAILS_FAILURE: return getSuspiciousWaybillDetailsFailure(state)
        default: return state;
    }
}


function getSuspiciousWaybillDetailsFailure(state) {
    return {
        ...state,
        loading: false,
        consignmentPodUpdate: undefined
    }
}
function getSuspiciousWaybillDetailsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        waybillDetails: action.payload,
        consignmentPodUpdate: undefined
    }
}

function getSuspiciousWaybillDetailsBegin(state) {
    return {
        ...state,
        loading: true
    }
}
function getSuspiciousWaybillsFailure(state) {
    return {
        ...state,
        loading: false
    }
}

function getSuspiciousWaybillsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        suspiciousWaybills: action.payload
    }
}

function getSuspiciousWaybillsBegin(state) {
    return {
        ...state,
        loading: true,
    }
}