
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';

export default class AssetService {

    getAssets() {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/assets`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    getAssetsfetch(filter) {
        const url = `/b2b/v1/assets/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: filter
        });
    }

    createAsset(location) {
        const url = `/b2b/v2/partners/${Constants.PARTNER_ID}/assets`;
        return apiRequest({
            url,
            method: "POST",
            data: location
        });
    }

    updateAsset(locationId, location) {
        const url = `/b2b/v2/partners/${Constants.PARTNER_ID}/assets/${locationId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: location
        });
    }

    deleteAsset(locationId) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/assets/${locationId}`;
        return apiRequest({
            url,
            method: "DELETE",
        });
    }

    getAsset(locationId) {
        const url = `/b2b/v2/partners/${Constants.PARTNER_ID}/assets/${locationId}`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    getAssetsByRoute(routeId) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/route/${routeId}/assets`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    getAssetsByLocation(locationId) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/location/${locationId}/assets`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    fetchAssets(filters) {
        const url = `/b2b/v1/assets/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: filters
        });
    }

    /**
     * Get all available devices for the users location.
     */
    getAvailableDevices() {
        const url = `/b2b/v1/locations/${Constants.userLocationId}/devices/sendable`;
        return apiRequest({
            url,
            method: "GET",
        });
    }

    /**
     * @param imeis string[]
     */
    getDeviceStatus(imeis) {
        const url = `/b2b/v1/asset/device/status`;
        const params = { imeiList: '' };

        if (imeis && imeis.length > 0) {
            params.imeiList = imeis.join(',');
        }

        return apiRequest({
            url,
            method: "GET",
            params
        });
    }


}
