import React , { Component } from 'react';
import addImage from '../../../../assets/img/icons/add_image.svg';
import FileUpload from '../../../ui/file-upload/file.upload';
import { UPLOAD_BUTTON_TYPE } from "../../../../common/utils/constants";
import { Icon, Modal } from 'antd';
import ImageGallery from '../../components/image-gallery/image-gallery';

class DepsImages extends Component{

    depsImageMaxCount = JSON.parse(atob(localStorage.getItem('_configrations'))).imageValidation?.deps?.maxCount ?
                        JSON.parse(atob(localStorage.getItem('_configrations'))).imageValidation?.deps?.maxCount :
                        5
    constructor(props){
        super(props);
        this.state = {
            imagesModalOpen : false,
            goToIndex: false
        }
    }

    onImageClick = (index) => {
        if (this.props.disableEdit) {
            return Modal.error({
                title:'Save or Reset the open DEPS!'
            })
        }
        this.setState({
            imagesModalOpen: true,
            goToIndex: false
        }, () => {
            this.setGoToIndex(index);
        })
    }

    setGoToIndex(index) {
        setTimeout(() => {
            this.setState({ goToIndex: index });
            setTimeout(() => {
                this.setState({ goToIndex: false });
            }, 50);
        }, 0);
    }

    deleteDepsImage(index) {
        Modal.confirm({
            title: "Delete DEPS Image",
            content: "Are you sure you want to remove this DEPS image?",
            onOk: () => this.props.onImageDelete(index)
        });
    }

    render(){

        const { createPermissions, deletePermissions } = this.props.verificationPermissions;

        return (
            <div>
                <div className="flex-box flex-gap-l">
                    {
                        this.props?.images?.length <= 0 && !this.props.edit && 
                        <span className={"text-bold" + (this.props.defaultImages?.join(',') != this.props.images?.join(',') ? " info-color" : "")}>
                            --
                        </span>
                    }
                    {
                        this.props?.images?.length > 0 &&
                        this.props.images.map((image, index) => {
                            return (
                                <div className={"deps-images-wrapper" + (this.props.defaultImages?.join(',') != this.props.images?.join(',') ? " updated-deps" : "")}
                                 key={index}>
                                    <img className="cursor-pointer" onClick={() => this.onImageClick(index)}
                                    src={image}/> 
                                    {
                                        this.props.edit && deletePermissions &&
                                        <Icon type='delete' className='delete-icon' onClick={() => { this.deleteDepsImage(index) }}/>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        this.props.edit && this.props?.images?.length < this.depsImageMaxCount && createPermissions &&
                        <div className="deps-images-wrapper upload-icon flex-box align-items-center justify-content-center">
                            <FileUpload showToastMessage={false} showLoader={true}
                                fileSizeLimit={5} fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                uploadButtonHtml={
                                    <div className='thumbnail-image'>
                                        <img className="cursor-pointer" src={addImage} alt="Add Image"
                                         width='25px' height='auto' style={{minWidth: 'auto'}}/>
                                    </div>
                                }
                                directUpload={true} disableUploadButton={true} clearFileAfterUpload={true}
                                onUploadFinish={e => {
                                        this.props.onImageUpload(e);
                                    }
                                }
                                path="verification" acceptTypes={[".PNG", ".png", ".jpg", ".JPEG", ".jpeg", ".JPG"]}
                                objKey="pod" multiple={false} />
                        </div>
                    }
                </div>

                <Modal
                 title="DEPS Images"
                 width='75%' footer={false}
                 maskClosable={false} style={{ top: 20 }}
                 visible={this.state.imagesModalOpen}
                 onCancel={() => { this.setState({ imagesModalOpen: false }) }}
                 >
                    <ImageGallery imageArray={this.props?.images?.length>0 ? this.props.images : []} goToIndex={this.state.goToIndex}
                        deleteEnabled={deletePermissions && !this.props.verifiedPod} uploadEnabled={false} height="400px" galleryId={this.props.imageGalleryId}
                        onDeleteImage={this.deleteDepsImage.bind(this)} hideThumbnailViewer={true}/>
                </Modal>
            </div>
        )
    }
}

export default DepsImages;