
export const initialState = {
}

export const GET_CUSTOMER_LIST_BEGIN = 'GET_CUSTOMER_LIST_BEGIN';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const GET_SHIPPER_LIST_BEGIN = 'GET_SHIPPER_LIST_BEGIN';
export const GET_SHIPPER_LIST_SUCCESS = 'GET_SHIPPER_LIST_SUCCESS';
export const GET_SHIPPER_LIST_FAILURE = 'GET_SHIPPER_LIST_FAILURE';

export const GET_INVOICE_LIST_BEGIN = 'GET_INVOICE_LIST_BEGIN';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAILURE = 'GET_INVOICE_LIST_FAILURE';

export const GET_INVOICE_PAYMENTS_LIST_BEGIN = 'GET_INVOICE_PAYMENTS_LIST_BEGIN';
export const GET_INVOICE_PAYMENTS_LIST_SUCCESS = 'GET_INVOICE_PAYMENTS_LIST_SUCCESS';
export const GET_INVOICE_PAYMENTS_LIST_FAILURE = 'GET_INVOICE_PAYMENTS_LIST_FAILURE';

export const GET_CREDIT_DEBIT_NOTE_BEGIN = 'GET_CREDIT_DEBIT_NOTE_BEGIN';
export const GET_CREDIT_DEBIT_NOTE_SUCCESS = 'GET_CREDIT_DEBIT_NOTE_SUCCESS';
export const GET_CREDIT_DEBIT_NOTE_FAILURE = 'GET_CREDIT_DEBIT_NOTE_FAILURE';

export const GET_INVOICE_DOCKET_LIST_BEGIN = 'GET_INVOICE_DOCKET_LIST_BEGIN';
export const GET_INVOICE_DOCKET_LIST_SUCCESS = 'GET_INVOICE_DOCKET_LIST_SUCCESS';
export const GET_INVOICE_DOCKET_LIST_FAILURE = 'GET_INVOICE_DOCKET_LIST_FAILURE';

export const DOWNLOAD_ANNEXURE_BEGIN = 'DOWNLOAD_ANNEXURE_BEGIN';
export const DOWNLOAD_ANNEXURE_SUCCESS = 'DOWNLOAD_ANNEXURE_SUCCESS';
export const DOWNLOAD_ANNEXURE_FAILURE = 'DOWNLOAD_ANNEXURE_FAILURE';

export const CREATE_OPEN_INVOICE_BEGIN = 'CREATE_OPEN_INVOICE_BEGIN';
export const CREATE_OPEN_INVOICE_SUCCESS = 'CREATE_OPEN_INVOICE_SUCCESS';
export const CREATE_OPEN_INVOICE_FAILURE = 'CREATE_OPEN_INVOICE_FAILURE';

export const UPLOAD_ANNEXURE_BEGIN = 'UPLOAD_ANNEXURE_BEGIN';
export const UPLOAD_ANNEXURE_SUCCESS = 'UPLOAD_ANNEXURE_SUCCESS';
export const UPLOAD_ANNEXURE_FAILURE = 'UPLOAD_ANNEXURE_FAILURE';

export const UPDATE_INVOICE_DUE_DATE_BEGIN = 'UPDATE_INVOICE_DUE_DATE_BEGIN';
export const UPDATE_INVOICE_DUE_DATE_SUCCESS = 'UPDATE_INVOICE_DUE_DATE_SUCCESS';
export const UPDATE_INVOICE_DUE_DATE_FAILURE = 'UPDATE_INVOICE_DUE_DATE_FAILURE';

export const UPLOAD_CUSTOMER_INVOICE_BEGIN = 'UPLOAD_CUSTOMER_INVOICE_BEGIN';
export const UPLOAD_CUSTOMER_INVOICE_SUCCESS = 'UPLOAD_CUSTOMER_INVOICE_SUCCESS';
export const UPLOAD_CUSTOMER_INVOICE_FAILURE = 'UPLOAD_CUSTOMER_INVOICE_FAILURE';

export const EXPORT_INVOICE_BEGIN = 'EXPORT_INVOICE_BEGIN';
export const EXPORT_INVOICE_SUCCESS = 'EXPORT_INVOICE_SUCCESS';
export const EXPORT_INVOICE_FAILURE = 'EXPORT_INVOICE_FAILURE';

export const UPLOAD_PAYMENT_BEGIN = 'UPLOAD_PAYMENT_BEGIN';
export const UPLOAD_PAYMENT_SUCCESS = 'UPLOAD_PAYMENT_SUCCESS';
export const UPLOAD_PAYMENT_FAILURE = 'UPLOAD_PAYMENT_FAILURE';