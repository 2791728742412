import React, { useEffect, useRef, useState } from "react";
import FormWrapper from '../../../../../ui/formComponent/form';
import billingTermSchema from "./billing.form.d";

function BillingTermsDetails(props) {

    var formRef = useRef();
    const [formData, setFormData] = useState(false);
    
    useEffect(() => {
        const { contractData } = props;
        var basicForm = {};
        if (contractData.contractBillingBO) {
            basicForm = {
                ...contractData.contractBillingBO,
                billingRequirements : contractData.contractBillingBO.billingRequirements ? contractData.contractBillingBO.billingRequirements.split(",") : []
            }
        }
        setFormData(basicForm)
    }, [])        
    
    useEffect(() => {
		if (props.retrieveBillingTerms) {
			formRef.current.formReference?.current?.submit()
		}
    }, [props.retrieveBillingTerms]);

    const transformErrors = errors => {
        var emptyFields = 0;
        errors.map(error => {
            if (error.name === "required") {
                emptyFields++;
            }
        })
        if (emptyFields === 2 && !props.submitedForApproval) {
            errors.map(error => {
                error.message = undefined;
            })
        }
        return errors;
    }

    return (
        <>
            {
                formData &&
                <FormWrapper
                    id={"BillingTerms"}
                    {...billingTermSchema}
                    formData={formData}
                    readonly={props.readonly}
                    fieldsPerRow={2}
                    showColumnSplitter={false}
                    loading={props.loading}
                    bordered={true}
                    hideSubmit={true}
                    onSubmit={_ => props.getBillingTerms(formData, false)}
                    onError={_ => props.getBillingTerms(formData, true)}
                    onChange={data => {
                        setFormData(data)
                    }}
                    transformErrors={transformErrors}
                    ref={formRef}
                />
            }
        </>
    );
}
export default BillingTermsDetails;