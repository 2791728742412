const schema = {
    "type"  : "object",
    "properties": {
        "slaRuleBOs" : {
            "type"      : "array",
            "title"     : "SLA Rule",
            "items"     : {
                "required"      : ["fromTime", "toTime", "placementSlaDay", "placementSlaTime"],
                "properties"    : {
                    "fromTime"  : {
                        "type"  : "number",
                        "title" : "Dispatch Time"
                    },
                    "toTime"    : {
                        "type"  : "number"
                    },
                    "placementSlaDay"   : {
                        "type"      : "number",
                        "title"     : "Delivery SLA",
                        "minimum"   : 0
                    },
                    "placementSlaTime"   : {
                        "type"  : "number"
                    }
                }
            }
        },
        "penalties" : {
            "type"      : "object",
            "title"     : "Delivery SLA Penalties",
            "required"  : [ "breachPenalty" ],
            "properties"    : {
                "breachPenalty" : {
                    "type"      : "string",
                    "title"     : "Breach Placement"
                }
            }
        }
    }
}

const uiSchema = {
    "slaRuleBOs"           : {
        "classNames"    : "array-columns",
        "ui:options": {
            "orderable" : false
        },
        "items"         : {
            "classNames" : "flex-1",
            "fromTime"    : {
                "classNames" : "flex-column flex-1",
                "ui:widget"  : "TimePickerWidget",
                "ui:placeholder" : "Select From Time"
            },
            "toTime" : {
                "classNames" : "flex-column flex-1 hide-label",
                "ui:widget"  : "TimePickerWidget",
                "ui:placeholder" : "Select To time"
            },
            "placementSlaDay" : {
                "classNames"     : "flex-column flex-1 hide-spinners",
                "ui:placeholder" : "Enter Day(s)",
                "ui:suffix"      : "Day(s)",
                "ui:type"        : "number",
                "ui:min"         : 0
            },
            "placementSlaTime" : {
                "classNames" : "flex-column flex-1 hide-label",
                "ui:widget"  : "TimePickerWidget"
            }
        }
    },
    "penalties" : {
        "breachPenalty"   : {
            "placeholder"   : "Enter breach penalty charges",
            "classNames"    : "half-flex-basis"
        }
    }
}

const errorList = {
    slaRuleBOs : {
        fromTime: {
            required  : "Dispath from time cannot be empty!"
        },
        toTime : {
            required  : "Indent to time cannot be empty!"
        },
        placementSlaTime : {
            required  : "SLA Time cannot be empty!"
        }
    },
    penalties   : {
        breachPenalty : {
            required  : "Breach penalty cannot be empty!"
        }
    }
}

export default { schema, uiSchema, errorList };