import React from "react";
import moment from "moment";
import _ from 'lodash';
import { currentStatus, nextAction, transformBreachedStatus } from "./sla-transformer";
import { Icon } from "antd";
const formatValidity = (date) =>
  moment(Number(date)).format("DD/MM/YYYY HH:mm");
export function getPotentialBrachColumns(slaEvents) {
  return [
    {
      title: "Waybill No",
      dataIndex: "waybillNo",
      key: "waybillNo",
      render: (data) => (
        <div
          style={{
            fontSize: 14,
            color: "#1E6FE4",
            cursor: "pointer",
          }}
          onClick={() => slaEvents.onSearchDocket(data)}
        >
          {data}
        </div>
      ),
    },
    {
      title: "Picked Up At",
      dataIndex: "pickupDate",
      key: "pickupDate",
      render: (data) => (data ? formatValidity(data) : ""),
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      render: (data, row) =>
        (data && data.length > 0 && !row.isUnknownPath) ? currentStatus(data) : "Unknown route",
    },
    {
      title: "CPD",
      dataIndex: "customerPromiseDate",
      key: "customerPromiseDate",
      render: (data,  row) => ((data && !row.isUnknownPath) ? formatValidity(data) : "-"),
    },
    {
      title: "Breach/ Potential Breach",
      dataIndex: "breachTime",
      key: "breachTime",
      render: (data, row) =>
        transformBreachedStatus(
          row.isUnknownPath,
          row.breachTime,
          row.slaBreachStatus
        ),
    },
    {
      title: "Ideal Next Stop",
      dataIndex: "idealNextStop",
      key: "idealNextStop",
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Delivery Location",
      dataIndex: "destinationLocationName",
      key: "destinationLocationName",
    },
  ];
}

export function AtRiskColumns(slaEvents) {
  return [
    {
      title: "Waybill No",
      dataIndex: "waybillNo",
      key: "waybillNo",
      render: (data) => (
        <div
          style={{
            fontSize: 14,
            color: "#1E6FE4",
            cursor: "pointer",
          }}
          onClick={() => slaEvents.onSearchDocket(data)}
        >
          {data}
        </div>
      ),
    },
    {
      title: "Picked Up At",
      dataIndex: "pickupDate",
      key: "pickupDate",
      render: (data) => (data ? formatValidity(data) : ""),
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      render: (data, row) =>
        data && data.length > 0 ? currentStatus(data) : data,
    },
    {
      title: "CPD",
      dataIndex: "customerPromiseDate",
      key: "customerPromiseDate",
      render: (data) => (data ? formatValidity(data) : ""),
    },
    {
      title: "Time to next Action",
      dataIndex: "timeToNextEvent",
      key: "timeToNextEvent",
      render: (data, row) => nextAction(data, row.nextActionableEvent)
    },
    {
      title: "Ideal Next Stop",
      dataIndex: "idealNextStop",
      key: "idealNextStop",
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Delivery Location",
      dataIndex: "destinationLocationName",
      key: "destinationLocationName",
    },
  ];
}

export function OnTimeColumns(slaEvents) {
  return [
    {
      title: "Waybill No",
      dataIndex: "waybillNo",
      key: "waybillNo",
      render: (data) => (
        <div
          style={{
            fontSize: 14,
            color: "#1E6FE4",
            cursor: "pointer",
          }}
          onClick={() => slaEvents.onSearchDocket(data)}
        >
          {data}
        </div>
      ),
    },
    {
      title: "Picked Up At",
      dataIndex: "pickupDate",
      key: "pickupDate",
      render: (data) => (data ? formatValidity(data) : ""),
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      render: (data, row) =>
        data && data.length > 0 ? currentStatus(data) : data,
    },
    {
      title: "CPD",
      dataIndex: "customerPromiseDate",
      key: "customerPromiseDate",
      render: (data) => (data ? formatValidity(data) : ""),
    },
    {
      title: "Time To Next Action",
      dataIndex: "timeToNextEvent",
      key: "timeToNextEvent",
      render: (data, row) => nextAction(data, row.nextActionableEvent)
    },
    {
      title: "Ideal Next Stop",
      dataIndex: "idealNextStop",
      key: "idealNextStop",
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Delivery Location",
      dataIndex: "destinationLocationName",
      key: "destinationLocationName",
    },
  ];
}
