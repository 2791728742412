import React, { Component } from "react";
import {
    Card,
    Row,
    Col,
    Input,
    Checkbox,
    Switch,
    Icon,
    Button,
    Table,
    Spin,
    Form,
    InputNumber,
    Select,
    Tooltip
} from "antd";
import * as moment from "moment";
import { permissionTags } from '../../../../configs/permissionTags';
import { canAllow, windowRedirect, notifyApiSuccess, notifyApiError } from '../../../../common/utils/utils';
import { Mixpanel } from "../../../../utils/mixpanel";
import { validationRules } from "../../../../common/utils/constants";
import { apiRequest } from '../../../../common/utils/apiGateway';

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

class CategoryForm extends Component {

    state = {
        createLoader: false,
        isEdit: false,
        isSet: false,
        editData: []
    }

    componentDidMount() {

        if (this.props.location?.state?.id) {
            Mixpanel.track("Vehicle Category Edit Initiated", {
                ERPVehicleUpdate: moment().valueOf(),
                categoryId: this.props.location?.state?.id
            });
            this.setState({ createLoader: true });
            const payload = {
                "filter": {
                    "ids": [this.props.location?.state?.id]
                }
            }
            apiRequest({
                url: `/b2b/v1/vehicle-types/getAllVehicleTypes`,
                method: 'POST',
                data: payload
            }).then(res => {
                if (checkHttpStatus(res?.data?.status)) {                    
                    this.setState({ createLoader: false, editData: res?.data?.response?.vehicleTypes });
                } else {
                    this.setState({ createLoader: false });
                }
            });
        } else {
            Mixpanel.track("Vehicle Category Create Initiated", {
                ERPVehicleCreate: moment().valueOf()
            });
        }
    }

    componentDidUpdate(prevProps) {

        if (this.state.editData.length > 0 && !this.state.isSet) {
            this.setState({ isEdit: true }, () => {
                const { form } = this.props;
                const { setFieldsValue } = form;

                const { vehicleType, vehicleCapacity, length, breadth, height, isDefault, bodyType } = this.state.editData[0];
                setFieldsValue({
                    vehicleType,
                    vehicleCapacity,
                    isDefault,
                    bodyType,
                    length: String(length),
                    breadth: String(breadth),
                    height: String(height)
                }, () => {
                    this.setState({ isSet: true })
                })
            })
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {

            if (err) return;
            this.setState({ createLoader: true });
            if (this.state.isEdit) {

                const payload = {
                    "vehicleTypesBOS": [
                        {
                            ...this.state.editData[0],
                            ...values,
                        }

                    ]
                };
                Mixpanel.track("Vehicle Category Update Successfully", {
                    ERPVehicleUpdatedSuccess: moment().valueOf(),
                    categoryId: this.props.location?.state?.id
                });
                this.updateCategory(payload, "updated");
            } else {
                const payload = {
                    "vehicleTypesBOS": [
                        values
                    ]
                };
                Mixpanel.track("Vehicle Category Create Successfully", {
                    ERPVehicleCreatedSuccess: moment().valueOf(),
                });
                this.updateCategory(payload, "created")
            }

        });
    }

    updateCategory = (payload, action) => {
        apiRequest({
            url: `/b2b/v1/vehicle-types/update`,
            method: 'POST',
            data: payload
        }).then(res => {
            if (checkHttpStatus(res?.data?.status)) {
                this.setState({ createLoader: false });
                notifyApiSuccess(`Vehicle Type ${action} Successfully!`, "SUCCESS");
                this.goBack();
            } else {
                this.setState({ createLoader: false });
            }
        });
    }

    render() {

        const formItemLayout = {
            labelCol: {
                xs: { span: 12 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 12 },
                sm: { span: 8 },
            },
            labelAlign: "left",
            colon: false
        };
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Card
                title={<h2><Icon type="arrow-left" onClick={() => this.goBack()} /> {this.state.isEdit ? 'Update' : 'Create'} Category</h2>}
            >
                <Spin spinning={this.state.createLoader}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Model">
                                    {getFieldDecorator('vehicleType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input model name!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter model name"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Capacity (in Tonnes)">
                                    {getFieldDecorator('vehicleCapacity', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input capacity!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter capacity"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Body Type">
                                    {getFieldDecorator('bodyType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input body type!',
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Body Type"
                                            showArrow
                                            showSearch
                                            // onSelect={this.selectCapacity}
                                            filterOption={(input, option) =>
                                                option.props.children?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >

                                            <Select.Option
                                                key={"OPEN"}
                                                value={"OPEN"}
                                                label={"OPEN"}

                                            >
                                                Open
                                            </Select.Option>
                                            <Select.Option
                                                key={"CLOSED"}
                                                value={"CLOSED"}
                                                label={"CLOSED"}

                                            >
                                                Closed
                                            </Select.Option>

                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Length">
                                    {getFieldDecorator('length', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input length!',
                                            },
                                            {
                                                pattern: validationRules.lbh,
                                                message: "Please enter only decimal number"

                                            }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter length"
                                            addonAfter={"Ft."}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Breadth">
                                    {getFieldDecorator('breadth', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input breadth!',
                                            },
                                            {
                                                pattern: validationRules.lbh,
                                                message: "Please enter only decimal number"

                                            }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter breadth"

                                            addonAfter={"Ft."}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Height">
                                    {getFieldDecorator('height', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input height!',
                                            },
                                            {
                                                pattern: validationRules.lbh,
                                                message: "Please enter only decimal number"

                                            }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter height"
                                            addonAfter={"Ft."}

                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Default">
                                    {getFieldDecorator('isDefault', {
                                        valuePropName: 'checked',
                                        initialValue: true
                                    })(
                                        <Checkbox />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row style={{ float: 'right' }}>
                                    <Col span={12}>
                                        <Form.Item label="">
                                            <Button
                                                type="danger"
                                                size={"large"}
                                                onClick={this.goBack}
                                            >
                                                Cancel
                                        </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="">
                                            <Button
                                                htmlType="submit"
                                                size={"large"}
                                                type="primary"
                                            >
                                                {this.state.isEdit ? 'Update' : 'Create'}
                                            </Button>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Card>

        )
    }
}


export default Form.create({ name: 'Category', onValuesChange: (props, val) => { } })(CategoryForm);