import React, { PureComponent } from 'react';
import * as actions from './actions';
// import './dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Tooltip, Tag, Spin, Form, DatePicker, Table } from 'antd';
import { canAllow, windowRedirect, notifyApiSuccess } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { Constants } from '../../../common/utils/constants';
import debounce from 'lodash/debounce';
import SLAReasonsService from '../../../services/sla-reasons/sla-reasons.service';
import _ from 'lodash';
let slaService = new SLAReasonsService();

const { confirm } = Modal;

class EwayDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            userPermission: {
                read: canAllow(permissionTags.settings.user.read),
                update: canAllow(permissionTags.settings.user.update),
                create: canAllow(permissionTags.settings.user.create),
                delete: canAllow(permissionTags.settings.user.delete),
                view: canAllow(permissionTags.settings.user.view),
            },
            toDate: moment(),
            fromDate: moment().subtract(1, 'days'),
            ewaybill: undefined
        }

        this.applyfilters();


    }

    componentDidMount() {


        this.setState(state => ({

            columns: [
                {
                    title: 'EwayBill Number',
                    dataIndex: 'ewayBillNumber',
                    key: 'ewayBillNumber',
                    width: 120,
                },
                {
                    title: 'Generated Date',
                    dataIndex: 'ewaybillDate',
                    key: 'ewaybillDate',
                    width: 120,
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Valid Till',
                    dataIndex: 'ewaybillValidDate',
                    key: 'ewaybillValidDate',
                    width: 120,
                    render: data => data ? (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>) : ''
                },
                {
                    title: 'Document Number',
                    dataIndex: 'documentNumber',
                    key: 'documentNumber',
                    width: 120,
                },
                {
                    title: 'Document Date',
                    dataIndex: 'documentDate',
                    key: 'documentDate',
                    width: 120,
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'Issuer GSTIN',
                    dataIndex: 'gstinOfGenerator',
                    key: 'gstinOfGenerator',
                    width: 130,
                },
                {
                    title: 'Pincode of Delivery',
                    dataIndex: 'pincodeOfDelivery',
                    key: 'pincodeOfDelivery',
                    width: 120,
                },
                {
                    title: 'Place of Delivery',
                    dataIndex: 'placeOfDelivery',
                    key: 'placeOfDelivery',
                    width: 120,
                },

            ]
        }));

    }

    applyfilters = () => {
        this.props.fetchEway(this.state.fromDate.format("YYYY-MM-DD"), this.state.toDate.format("YYYY-MM-DD"));
    }

    resetFilters = () => {
        this.setState({
            toDate: moment(),
            fromDate: moment().subtract(1, 'days')
        }, () => {
            this.applyfilters();
        })
    }

    changeFromDate = fromDate => {
        this.setState({ fromDate })
    }


    changeToDate = toDate => {
        this.setState({ toDate })
    }

    disableToDate = current => {

        // return current && moment(this.state.fromDate).endOf('day') > current;
        if (this.state.fromDate) {
            const dayStart = moment(this.state.fromDate);
            const dayEnd = moment().endOf('day');
            return !current.isBetween(dayStart.startOf('day'), dayEnd)
        } else {
            return current && current > moment().endOf('day');
        }
    }

    disableFromDate = current => {
        return current && current > moment().endOf('day');
    }

    changeEwayBill = e => {
        this.setState({ewaybill: e.target.value})
    }

    searchWayBill = value => {
        if(value) {
            this.props.searchEway(value);
        } else {
            this.resetFilters();
        }
        
    }

    render() {
        const { columns, fromDate, toDate } = this.state;
        const { ewayBills, loading } = this.props;
        return (
            <>
                <Card
                    title={
                        <>

                            <Row gutter={12}>
                                <Col span={2}>
                                    <h2>Ewaybills Issued</h2>
                                </Col>
                            </Row>
                            <Row>                               
                                <Col span={4}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="From">
                                        <DatePicker
                                            onChange={this.changeFromDate}
                                            value={fromDate}
                                            format={"YYYY-MM-DD"}
                                            allowClear={false}
                                            disabledDate={this.disableFromDate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4} offset={0}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="To">
                                        <DatePicker
                                            onChange={this.changeToDate}
                                            value={toDate}
                                            allowClear={false}
                                            disabledDate={this.disableToDate}
                                            format={"YYYY-MM-DD"}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={2} offset={0}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="">
                                        <Button size="large" loading={loading} style={{ top: 30 }} onClick={e => this.applyfilters()} type="primary">Apply</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="">
                                        <Button size="large" loading={loading} onClick={e => this.resetFilters(e)} style={{ top: 30, backgroundColor: '#f2f2f5' }}>Reset</Button>
                                    </Form.Item>
                                </Col>

                                <Col span={4} offset={8}>
                                    <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Search EwayBill">
                                        <Input.Search
                                            placeholder="Search"
                                            value={this.state.ewaybill}
                                            onChange={this.changeEwayBill}
                                            allowClear
                                            onSearch={this.searchWayBill}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                >

                    <Table
                        bordered
                        locale={{
                            emptyText: (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }}
                        loading={loading}
                        scroll={{ x: 1250 }}
                        dataSource={ewayBills}
                        columns={columns} />
                </Card>

            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    ewayBills: state?.eway?.ewayBills,
    loading: state?.eway?.loading
});
const mapDispatchToProps = dispatch => ({
    fetchEway: (from, to) => dispatch(actions.fetchEway(from, to)),
    searchEway: (value) => dispatch(actions.searchEway(value))

});
export default connect(mapStateToProps, mapDispatchToProps)(EwayDashboard);