import { Alert, Tag } from "antd";
import React from "react";
import { capitalizeString } from "../../../../common/utils/utils";

export const addArrayElem = (array, template, index) => {
    var newElem = {...template};
    return [...array.slice(0,index), newElem, ...array.slice(index)]
}

export const removeArrayElem = (array, index) => {
    return [...array.slice(0,index), ...array.slice(index+1)]
}

export const getUserContractApprovalLevel = _ => {
    if (JSON.parse(window.atob(localStorage.getItem("_configrations")))?.contractApprovalLevels) {
        return JSON.parse(window.atob(localStorage.getItem("_configrations"))).contractApprovalLevels
    }
    return [];
}

export const contractStatusToTag = status => {

    var tagClassName = "";
    if (["APPROVED"].indexOf(status) != -1) {
        tagClassName = "success-tag";
    }
    else if (["FORCECLOSED", "SUBMITTED_FOR_APPROVAL"].indexOf(status) != -1) {
        tagClassName = "warning-tag";
    }
    else if (["DISCARDED", "EXPIRED", "REJECTED"].indexOf(status) != -1) {
        tagClassName = "error-tag";
    }
    else if (["DRAFT"].indexOf(status) != -1) {
        tagClassName = 'info-tag';
    }
    else if (!status) {
        return <></>
    }
    return (
        <Tag className={"ant-table-tag text-align-center " + tagClassName}> 
            {
                (status.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
            }
        </Tag>
    )
}

export const headerError = (submitErrors, verificationView, saveError, serviceType, freightBasis) => {
    
    return <>
        {
            submitErrors ?
                verificationView ?
                    <Alert type={"error"} showIcon message={
                        <>
                            Please fix the errors highlighted below to approve the contract. <strong>Note:</strong> Kindly use help section if you need any clarification
                        </>
                    } /> :
                    <Alert type={"error"} showIcon message={
                        <>
                            Please fix the errors highlighted below to submit the contract for approval. <strong>Note:</strong> Kindly use help section if you need any clarification
                        </>
                    } />  :
            saveError ?
                <>
                    <Alert type={"error"} showIcon message={
                        <>
                            Please fix the errors highlighted below to save the contract details. <strong>Note:</strong> Kindly use help section if you need any clarification
                        </>
                    } />
                </> :
            <></>
        }
        {
            ( freightBasis !== undefined && freightBasis != "" && 
            (serviceType === "FTL" && freightBasis !== "PER_INDENT") ||
            (serviceType === "PTL" && freightBasis === "PER_INDENT") ) &&
            <Alert type={"error"} showIcon message={
                serviceType === "FTL" ?
                "Per Indent is the only applicable basis for FTL Service Type! Kindly update the basis." :
                "Per Indent is not applicable for PTL Service Type! Kindly update the basis."
            } />
        }
    </>
}

export const generateErrorObjects = (errorsArray) => {
    return (
        <div className="flex-column flex-gap-l">
            {
                errorsArray.length > 0 && errorsArray.map((e, i) => {
                    return <div key={i}> {e} </div>
                })
            }
        </div>
    )
}

export const convertSubmitErrors = (submitErrors, errorKey) => {
    if (submitErrors[errorKey] && submitErrors[errorKey].length > 0)
    return <Alert type={"error"} showIcon message={
                errorKey.split("_").map(st => {
                    return capitalizeString(st.toLowerCase())
                }).join(" ")
            }
            description={generateErrorObjects(submitErrors[errorKey])} />
}