import { InputNumber, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { indentCreateActionTypes } from "../state/actions";

function AdhocIndent(props) {
  const formData = useSelector((state) => state.indentCreateReducer.indentData);
  const dispatch = useDispatch();
  const [isAdhoc, setAdhoc] = useState(
    formData?.details?.isAdhoc ? formData?.details?.isAdhoc : false
  );
  const [revenue, setRevenue] = useState(
    formData?.details?.revenue ? formData?.details?.revenue : undefined
  );

  const [selectedLocation, SetSelectedLocation] = useState(
    formData?.details?.hubLocationId
      ? Number(formData?.details?.hubLocationId)
      : Number(localStorage.getItem("currentPartnerLocationId"))
  );
  const [notFoundContent, SetNotFoundContent] = useState(
    "Enter minimum of three characters to search locations."
  );

  const onLocationSelect = (location) => {
    SetSelectedLocation(location);
    updateAdhocDetails("hubLocationId", location);
  };

  const updateAdhoc = (checked) => {
    setAdhoc(checked);
    updateAdhocDetails("isAdhoc", checked);
    if (!checked) {
      setRevenue(undefined);
    }
  };

  const updateRevenue = (revenue) => {
    setRevenue(revenue);
    updateAdhocDetails("revenue", revenue);
  };

  const updateAdhocDetails = (key, value) => {
    dispatch({
      type: indentCreateActionTypes.UPDATE_INDENT_DATA,
      payload: {
        ...formData,
        details: {
          ...formData.details,
          [key]: value,
        },
      },
    });
  };

  return (
    <div
      className="spacer-l flex-column flex-gap-l"
      style={{ marginTop: 20 }}
    >
      <div className="flex-box flex-gap-xl text-bold font-size-sm-2 spacer-hm">
        <div className="flex-1 spacer-xs mt-4">
          <Switch
            checked={isAdhoc}
            onChange={updateAdhoc}
            disabled={props.readonly}
          />{" "}
          Adhoc Indent
        </div>
        {isAdhoc && (
          <div className="flex-3">
            <label>
              Revenue
              <InputNumber
                value={revenue}
                onChange={updateRevenue}
                disabled={props.readonly}
                placeholder="Enter Revenue"
                style={{ width: "100%" }}
                min={1}
              />
            </label>
          </div>
        )}
        <div className="flex-3">
          <label>
            Hub
            <Select
              disabled={props.readonly}
              mode={"single"}
              style={{ width: "100%" }}
              className={props.readonly ? "read-only" : ""}
              value={
                props.hubsData && props.hubsData.length > 0 && selectedLocation
                  ? selectedLocation
                  : undefined
              }
              showSearch={true}
              notFoundContent={notFoundContent}
              placeholder={"Select hub"}
              onSelect={onLocationSelect}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.hubsData?.map((location) => (
                <Select.Option
                  key={location.id ? location.id : location.name}
                  value={location.id}
                  label={location.name}
                >
                  {location.name}
                </Select.Option>
              ))}
            </Select>
          </label>
        </div>
      </div>
    </div>
  );
}
export default AdhocIndent;
