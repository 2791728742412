import React, { Component } from 'react';
import { fetchConsigmentUnassigned, getConsignment, updateConsignment } from './actions.js';
import './awb.scss';
import { connect } from 'react-redux';
import { PageHeader, Row, Col, Button, Table, Empty, Switch, Alert, DatePicker, Card, Spin, Icon, Tooltip, Input, Select, Tag, Modal, Form, InputNumber } from 'antd';
import { Dropdown, Menu, Radio } from 'antd';
import DataCard from '../../shared-components/data-card/DataCard';
import { getSummary } from '../../../app/appAction.js';
import activeAwbIcon0 from './../../../assets/img/icons/images/3_1_Inventory.svg';
import activeAwbIcon1 from './../../../assets/img/icons/images/3_2_Inventory.svg';
import activeAwbIcon2 from './../../../assets/img/icons/images/3_3_Inventory.svg';
import { permissionTags } from '../../../configs/permissionTags.js';
import { canAllow, dataGet, downloadJsonData, notifyApiError, windowRedirect, notifyApiSuccess } from '../../../common/utils/utils.js';
import { Constants } from '../../../common/utils/constants.js';
import moment from 'moment';
import { destroyNotification } from '../../ui/notification/Notification.js';
import WaybillLink from '../../shared-components/waybill-link/index.js';
import EwayGreen from '../../../assets/img/icons/eway_green.svg';
import EwayRed from '../../../assets/img/icons/eway_red.svg';
import EwayGrey from '../../../assets/img/icons/eway_grey.svg';
import EwayOrange from '../../../assets/img/icons/eway_orange.svg';
import * as apiUtils from '../../../common/utils/apiGateway';
import FileUpload from "../../ui/file-upload/file.upload";
import { UPLOAD_BUTTON_TYPE } from '../../../common/utils/constants';





import EwayPartAGreen from '../../../assets/img/icons/eway_parta_green.svg';
import EwayPartARed from '../../../assets/img/icons/eway_parta_red.svg';
import EwayPartAOrange from '../../../assets/img/icons/eway_parta_orange.svg';
import TripSummaryModal from '../trip/trip-summary/index.js';

function checkHttpStatus(status) {
  switch (status?.code) {
    case 403:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 401:
      notifyApiError(status?.message, "ERROR:");
      // window.location.href = '/';
      return false;
    case 500:
    case 503:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 200:
    case 201:
    case 202:
      return true
  }
}

class Awb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          deviceFilter: { deviceType: "DESKTOP", view: "LITE_UNASSIGNED_WAYBILLS_LIST" },
          isArrived: true,
          isBookedOnly: true
        }
      },
      application: Constants.SELECTED_APPLICATION,
     
      dataCardIcon: { activeAwbIcon0, activeAwbIcon1, activeAwbIcon2 },
      DataCardList: [],
      summaryLoader: true,
      columns: [],
      searchValue: '',
      pageNo: 1,
      expandedRowKeys: '0',
      rtsAction: props.config['mark_rts_inventory'] ? true : false,
      rtoAction: props.config['mark_rto_inventory'] ? true : false,
      mark_rto_customer_list: (props.config['mark_rto_customer_list'] && props.config['mark_rto_customer_list'].length) ? props.config['mark_rto_customer_list'] : [],
      waybillActionOptions: [],
      reasonList: [],
      confirmModalVisible: false,
      isReasonPreset: false,
      loadingModal: false,
      vendorType: Constants.sessionUser?.partner?.partnerTypes,
      shipmentScan: false,
      shipmentBarCodes: [],
      scannedBarCodes: [],
      barcode: undefined,
      barcodeModal: false,
      shipmentScans: [],
      manifestId: undefined,
      markdelError: undefined,
      markDelFilters: { podCreatedDate: undefined },
      markDeliveryVisible: false,
      clearFileMarkDelivery: false,
      markDelLoading: false,
      podDate: undefined,
      deps: false,
      deliveryDateTime: {
        validateStatus: 'success',
        help: ''
      },
      shipmentTableColumns: [
        {
          title: 'Sr. No.',
          dataIndex: 'sr',
          key: 'sr',
          render: (data, row, index) => index + 1
        },
        {
          title: 'Refrence No',
          dataIndex: 'shipmentCode',
          key: 'shipmentCode',
        },
        {
          title: 'Status',
          dataIndex: 'action',
          key: 'action',
          render: (data, row, index) => (

            <Row gutter={12}>
              <Col span={6}>
                <Icon onClick={() => this.deleteShipmentCode(data, row, index)} type="delete"></Icon>
              </Col>
              <Col span={6}>
                <Icon style={{ color: 'green' }} type="check-circle"></Icon>
              </Col>
            </Row>



          )
        },
      ],
      showFilterModal: false
    }
    this.InputSearchRef = React.createRef();
    this.props.getSummary('INVENTORY_AWB');
  }

  componentDidMount() {
    const { rtsAction, rtoAction, application, vendorType } = this.state
    const { config } = this.props
    let EWAY_ENUMS = {
      EwayGreen: 0,
      EwayRed: 0,
      EwayOrange: 0,
      EwayPartARed: 0,
      EwayPartAGreen: 0,
      EwayPartAOrange: 0
    };
    let ewayKey = null;
    const columns = [
      {
        title: 'Waybill No',
        dataIndex: 'waybillNo',
        key: 'waybillNo',
        application: 'BOTH',
        width: '20%',
        colSpan: (Constants.SELECTED_APPLICATION === 'B2B') ? 3 : '',
        render: (data, row) => {
          return (
            <>
              <WaybillLink>{row.consignmentBO.waybillNo}</WaybillLink> &nbsp;
              {
                row.consignmentBO?.returnConsignmentBos?.length > 0 ?
                  <Tag color="#108ee9">Return ({row.consignmentBO?.returnConsignmentBos?.length}) </Tag>
                  :
                  row.consignmentBO?.undelConsignmentBos?.length > 0 ?
                    <Tag color="#108ee9">Undel ({row.consignmentBO?.undelConsignmentBos?.length}) </Tag>
                    : <></>

              }
            </>
          )
        }
      },
      {
        title: 'test',
        dataIndex: 'ewaybill',
        key: 'ewaybill',
        colSpan: 0,
        width: 50,
        application: 'B2B',
        render: (data, row) => (
          <>
            {
              row.consignmentBO.ewaybillBOs && row.consignmentBO.ewaybillBOs.length > 0 ?
                <>{row.consignmentBO.ewaybillBOs.map(eway => {
                  var validUpto = moment(eway.validUpto);
                  // waybll = <></>;
                  var today = moment();
                  var difference = validUpto.diff(today, 'hours');
                  // console.log("difference->>", difference, this.props.config, this.props.config.ewaybill.waringHours)
                  if (eway.validUpto) {
                    if (moment(eway.validUpto) <= moment()) {
                      EWAY_ENUMS.EwayRed += 1;
                      // return (
                      //     <img src={EwayRed} />
                      // )
                    } else if (difference <= this.props.config.ewaybill.waringHours) {
                      EWAY_ENUMS.EwayOrange += 1;
                      // return <img src={EwayOrange} />
                    } else {
                      EWAY_ENUMS.EwayGreen += 1;
                      // return <img src={EwayGreen} />
                    }
                  } else {
                    if (eway.generatedAt && eway.noOfDaysValid) {
                      const noDaysMilliseconds = moment.duration({ days: eway.noOfDaysValid }).asMilliseconds();
                      const warningHours = parseInt(this.props?.app?.configurations?.ewaybill?.warningHoursPartA);
                      const limitHoursMilliseconds = moment.duration({ hours: warningHours }).asMilliseconds();
                      const calculateTimeStamp = (eway.generatedAt + noDaysMilliseconds) - limitHoursMilliseconds;


                      const validUpto = eway.generatedAt + noDaysMilliseconds;
                      const warning = noDaysMilliseconds - limitHoursMilliseconds;


                      if (warning > moment().valueOf()) {
                        EWAY_ENUMS.EwayPartAGreen += 1;
                      } else if (moment().valueOf() >= warning && moment().valueOf() < validUpto) {
                        EWAY_ENUMS.EwayPartAOrange += 1;
                      } else {
                        EWAY_ENUMS.EwayPartARed += 1;
                      }

                    } else {
                      EWAY_ENUMS.EwayPartAGreen += 1;
                    }
                  }

                }),

                  ewayKey = Object.keys(EWAY_ENUMS).reduce((a, b) => EWAY_ENUMS[a] > EWAY_ENUMS[b] ? a : b),
                  // console.log("eway->>", ewayKey, EWAY_ENUMS),
                  EWAY_ENUMS = {
                    EwayGreen: 0,
                    EwayRed: 0,
                    EwayOrange: 0,
                    EwayPartARed: 0,
                    EwayPartAGreen: 0,
                    EwayPartAOrange: 0
                  },
                  ewayKey == 'EwayRed'
                    ? <Tooltip placement="top" title={row.consignmentBO.ewaybillBOs.length}>
                      <img src={EwayRed} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(row.consignmentBO.ewaybillBOs)} />
                    </Tooltip>
                    : ewayKey == 'EwayOrange'
                      ? <Tooltip placement="top" title={row.consignmentBO.ewaybillBOs.length}>
                        <img src={EwayOrange} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(row.consignmentBO.ewaybillBOs)} />
                      </Tooltip>
                      : ewayKey == 'EwayGreen'
                        ? <Tooltip placement="top" title={row.consignmentBO.ewaybillBOs.length}>
                          <img src={EwayGreen} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(row.consignmentBO.ewaybillBOs)} />
                        </Tooltip>
                        : ewayKey == 'EwayPartARed'
                          ? <Tooltip placement="top" title={row.consignmentBO.ewaybillBOs.length}>
                            <img src={EwayPartARed} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(row.consignmentBO.ewaybillBOs)} />
                          </Tooltip>
                          :
                          ewayKey == 'EwayPartAGreen'
                            ? <Tooltip placement="top" title={row.consignmentBO.ewaybillBOs.length}>
                              <img src={EwayPartAGreen} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(row.consignmentBO.ewaybillBOs)} />
                            </Tooltip>
                            :
                            ewayKey == 'EwayPartAOrange'
                              ? <Tooltip placement="top" title={row.consignmentBO.ewaybillBOs.length}>
                                <img src={EwayPartAOrange} style={{ cursor: 'pointer' }} onClick={() => this.showAllEwayBills(row.consignmentBO.ewaybillBOs)} />
                              </Tooltip>
                              : <Tooltip placement="top" title={"No Ewaybill"}>
                                <img src={EwayGrey} style={{ cursor: 'not-allowed' }} />
                              </Tooltip>


                }
                  &nbsp;</>
                : <Tooltip placement="top" title={"No Ewaybill"}>
                  <img src={EwayGrey} style={{ cursor: 'not-allowed' }} />
                </Tooltip>
            }
          </>
        )
      },
      {
        title: 'test',
        dataIndex: 'Docket_Summary',
        key: 'Docket_Summary',
        colSpan: 0,
        width: 50,
        application: 'B2B',
        render: (data, row) => {
          
          return (row?.consignmentBO?.attributes?.paymentType === 'TO_PAY' || row?.consignmentBO?.attributes?.paymentType === 'TOPAY') && (
            <TripSummaryModal title="Docket Summary" data={[{ waybillNo: row.consignmentBO.waybillNo, amountToBePaid: ((row?.consignmentBO?.attributes?.totalAmount - row?.consignmentBO?.attributes?.collectedAmount) > 0) ? (row?.consignmentBO?.attributes?.totalAmount - row?.consignmentBO?.attributes?.collectedAmount) : 0 }]} >
            </TripSummaryModal>
          )
        }
      },
      {
        title: 'Booking Type',
        dataIndex: 'consignmentBO',
        key: 'bookingType',
        application: 'B2B',
        render: (data, row) => (<Tag color={'rgba(59, 90, 152, 0.9)'}>{row?.consignmentBO?.movementType || 'NA'}</Tag>)
      },
      {
        title: 'Attempts',
        dataIndex: 'consignmentBO',
        key: 'Attempts',
        application: 'B2C',
        render: (data, row) => (row.consignmentBO?.attempts ? row.consignmentBO?.attempts : 'NA')
      },
      {
        title: 'Destination',
        dataIndex: 'consignmentBO',
        key: 'Destination',
        application: 'BOTH',
        render: (data, row) => (`${row?.consignmentBO.consignee.address.pincode.city.cityCode}-${row?.consignmentBO.consignee.address.pincode.zipcode}`)
      },
      {
        title: 'Type',
        dataIndex: 'consignmentBO',
        key: 'Type',
        application: 'BOTH',
        render: (data, row) => (row?.consignmentBO !== null ? 'Line Haul' : 'NA')
      },
      {
        title: 'Customer',
        dataIndex: 'consignmentBO',
        key: 'Customer',
        application: 'B2B',
        render: (data, row) => (row?.consignmentBO !== null ? row.consignmentBO.customer.name : 'NA')
      },
      {
        title: 'Payment',
        dataIndex: 'consignmentBO',
        key: 'Payment',
        application: 'B2C',
        render: (data, row) => row?.consignmentBO?.payableAmount ? Constants.b2cPaymentTypes[1].name : Constants.b2cPaymentTypes[0].name
      },
      {
        title: 'Shipments',
        dataIndex: 'consignmentBO',
        key: 'Shipments',
        application: 'B2B',
        render: (data, row) => (row?.consignmentBO !== null ? row.consignmentBO.totalShipmentCount : 'NA')
      },
      {
        title: 'CPD',
        dataIndex: 'consignmentBO',
        key: 'CPD',
        application: 'BOTH',
        render: (data, row) => row?.consignmentBO?.expectedDeliveryDate ? <div>{moment(row.consignmentBO.expectedDeliveryDate).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.consignmentBO.expectedDeliveryDate).fromNow()}</small></div> : <div>NA</div>
      },
      {
        title: 'Booking Date',
        dataIndex: 'consignmentBO',
        key: 'Booking_Date',
        application: 'BOTH',
        render: (data, row) => row?.consignmentBO?.bookingDate ? <div>{moment(row.consignmentBO.bookingDate).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.consignmentBO.bookingDate).fromNow()}</small></div> : <div>NA</div>
      },
      {
        title: 'Status',
        dataIndex: 'consignmentBO',
        key: 'Status',
        application: 'B2C',
        render: (data, row) => row?.consignmentBO?.consignmentStatus ? <Tag color="#FFB64D"> {row?.consignmentBO?.consignmentStatus}</Tag> : 'NA'
      },
      {
        title: 'Action',
        dataIndex: 'consignmentBO',
        key: 'Status',
        application: 'B2B',
        width: '20%',
        render: (data, row) =>
          // true ? (
          //   <>
          //     {
          //       application === 'B2B' && <a href={`/appv2/print_information?consignmentId=${row.consignmentBO.id}&formtype=consignment`}
          //         target="_blank"> <Icon type="eye" ></Icon> </a>
          //     }
          //     <Tag color="#87d068">Download</Tag>
          //     {
          //       (rtsAction || rtoAction)
          //         ? <Select style={{ width: '100%' }} onSelect={(value, opt) => this.markShipmentStatus(value, row, opt)} placeholder="Select Action" onFocus={() => this.getActionItems(row.consignmentBO.consignmentStatus, row)}>
          //           {
          //             this.state.waybillActionOptions.map(opt => (
          //               <Select.Option value={opt.value}>{opt.label}</Select.Option>
          //             ))
          //           }
          //         </Select>
          //         : null
          //     }
          //   </>
          // ) : (
          <Row gutter={[24, 12]}>
            {
              application === 'B2B' && <Col span={4}>
                <a href={`/appv2/print_information?consignmentId=${row.consignmentBO.id}&formtype=consignment`}
                  target="_blank"> <Icon type="eye" ></Icon> </a>

              </Col>
            }
            <Col span={12} onClick={() => this.downloadWaybillData(row)}>
              <Tag color="#87d068">Download</Tag>
            </Col>

            {
              (rtsAction || rtoAction)
                ? <Col span={12}>
                  <Select style={{ width: '100%' }} onSelect={(value, opt) => this.markShipmentStatus(value, row, opt)} placeholder="Select Action" onFocus={() => this.getActionItems(row.consignmentBO.consignmentStatus, row)}>
                    {
                      this.state.waybillActionOptions.map(opt => (
                        <Select.Option value={opt.value}>{opt.label}</Select.Option>
                      ))
                    }
                  </Select>
                </Col>
                : null
            }
            {
              vendorType?.includes("LAST_MILE") &&
              <Col span={12}>
                <Button onClick={() => this.markDel(data, { paymentType: row?.consignmentBO?.attributes?.paymentType, waybillNo: row.consignmentBO.waybillNo, amountToBePaid: (row?.consignmentBO?.attributes?.totalAmount - row?.consignmentBO?.attributes?.collectedAmount) })}>Mark Delivery</Button>
              </Col>

            }

          </Row >
        // )
      },
      {
        title: 'Action',
        dataIndex: 'consignmentBO',
        key: 'Status',
        width: '20%',
        application: 'B2C',
        render: (data, row) => (
          (rtsAction || rtoAction) ? <Select style={{ width: '100%' }} onSelect={(value, opt) => this.markShipmentStatus(value, row, opt)} placeholder="Select Action" onFocus={() => this.getActionItems(row.consignmentBO.consignmentStatus, row)}>
            {
              this.state.waybillActionOptions.map(opt => (
                <Select.Option value={opt.value}>{opt.label}</Select.Option>
              ))
            }
          </Select> : null
        )
      },
    ];
    this.setState({
      columns: [...columns.filter(col => (col.application === this.state.application || col.application === 'BOTH'))]
    })

    this.applyPagination({ pageNo: 1 }, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { summary, selectedConsigment, updateConsigment } = this.props;
    const { dataCardIcon, currentAction, currentConsigmentId } = this.state;
    if (summary !== prevProps.summary) {
      const DataCardList = [
        {
          title: 'Pending AWB',
          documentType: "PENDING_CONSIGNMENT_LOC",
          description: summary['PENDING_CONSIGNMENT_LOC'].count,
          icon: dataCardIcon[`activeAwbIcon${0}`]
        },
        {
          title: 'SLA Breach Approaching',
          documentType: 'SLA_BREACH_APPROACHING_CONSIGNMENTS',
          description: summary['SLA_BREACH_APPROACHING_CONSIGNMENTS'].count,
          icon: dataCardIcon[`activeAwbIcon${1}`]
        },
        {
          title: 'SLA Breached',
          documentType: 'SLA_BREACH_CONSIGNMENTS',
          description: summary['SLA_BREACH_CONSIGNMENTS'].count,
          icon: dataCardIcon[`activeAwbIcon${2}`]
        }
      ]
      this.setState({
        DataCardList,
        summaryLoader: false
      })
    }
    if (updateConsigment !== prevProps.updateConsigment) {
      if (updateConsigment.hasOwnProperty('responses')) {
        if (updateConsigment.responses[0].sync) {
          this.reloadAwbList();
          this.showConfirm(false);
          this.setState({
            loadingModal: false,
            shipmentParams: undefined
          })
          notifyApiSuccess('Successfully marked the waybill', 'Success')
        } else {
          this.showConfirm(false);
          this.setState({
            loadingModal: false,
            shipmentParams: undefined
          })
          notifyApiError(updateConsigment.responses[0].reason ? updateConsigment.responses[0].reason : 'Something went wrong, please contact loadshare', 'Error')
        }
      }
    }
    if (selectedConsigment !== prevProps.selectedConsigment) {
      if (currentAction === 'DOWNLOAD' && selectedConsigment[currentConsigmentId]) {
        this.prepareDownloadAction(selectedConsigment[currentConsigmentId])
      }
    }
  }


  setReasonData(reqData) {
    const { config } = this.props
    let reasonList = config['reasons'][reqData];
    if (reasonList && reasonList.length) {
      reasonList = reasonList.map((data, index) => ({
        value: Number(data.reasonCode),
        label: data.reasonDescription,
        id: Number(data.reasonCode)
      }));
      this.setState({
        reasonList
      }, () => this.showConfirm(true))
    }
  }

  markShipmentStatus(value, row, _ev) {
    const shipmentParams = {
      request: [
        {
          'consignment':
          {
            'id': row.id,
            'waybillNo': row.waybillNo,
            'consignmentStatus': value,
            'lastStatusReasonCode': null
          },
          'action': 'UPDATE_STATUS',
          'referenceId': new Date().getTime()
        }
      ]
    };
    this.setState({
      reasonList: [],
      shipmentParams
    }, () => {
      this.setReasonData(shipmentParams['request'][0]['consignment']['consignmentStatus']);
    })
  }

  showConfirm = (val) => {
    this.setState({
      confirmModalVisible: val
    })
  }

  onReasonSelected(value) {
    const shipmentParams = this.state.shipmentParams;
    shipmentParams['request'][0]['consignment']['lastStatusReasonCode'] = value
    this.setState({
      isReasonPreset: value,
      shipmentParams,
    })
  }

  confirmAction = () => {
    this.setState({
      loadingModal: true
    }, () => this.props.updateConsignment(this.state.shipmentParams))
  }

  getActionItems(value, rowData) {
    // this.waybillReasonSelection.clear();
    this.isReasonPreset = false;
    let waybillActionOptions = [];
    if (value === 'IN' || value === 'BOOK') {
      if (this.state.rtsAction && (Number(rowData.consignmentBO.bookingOfficeLoc.id) === Number(Constants.userLocationId))) {
        waybillActionOptions.push({ 'label': 'Mark RTS', 'id': 1, 'value': 'RTS_RETURN_TO_SHIPPER' });
      }
    } else if (value === 'INWARD') {
      // no set exist
    }
    if (this.state.mark_rto_customer_list.indexOf((rowData.consignmentBO.customer.id).toString()) >= 0
      && this.state.rtoAction) {
      waybillActionOptions.push({ 'label': 'Mark RTO', 'id': 2, 'value': 'RTO_RETURN_TO_ORIGIN' });
    }

    this.setState({
      isReasonPreset: false,
      waybillActionOptions
    })
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    if (filters?.waybills) {
      if (filters.waybills[0] === "")
        delete filters.waybills;
    }
    this.props.fetchConsigmentUnassigned({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
  }

  reloadAwbList = () => {
    // this.setState({
    //   pageNo: 1
    // })
    // this.InputSearchRef.current.input.state.value = '';
    // this.applyPagination({ pageNo: 1 }, true);
    this.cancelFilterModal();
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedConsigment, } = this.props;
    if (!PaginatedConsigment ? !PaginatedConsigment : !PaginatedConsigment[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  getExpandedRow = (parentRow) => {
    const { selectedConsigment } = this.props
    const columns = [
      {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'Weight',
        render: (data, row) => (
          data
        )
      },
      {
        title: 'Barcode',
        dataIndex: 'barcodes',
        key: 'Barcode',
        render: (data, row) => (
          row.barcodes.length ? row.barcodes[0].barcode : null
        )
      },
      {
        title: 'Goods Type',
        dataIndex: 'goodsTypeBO',
        key: 'goodsTypeBO',
        render: (data, row) => (
          row.goodsTypeBO?.code
        )
      },
      {
        title: 'Dimension',
        dataIndex: 'dimension',
        key: 'dimension',
        render: (data, row) => (
          data
        )
      },
      {
        title: (
          <a href={`/appv2/print_information?consignmentId=${parentRow.consignmentBO.id}&formtype=downloadInventoryWaybill&requestedBarCode=all`}
            target="_blank"> <Tag color="#87d068">Download All Barcodes</Tag> </a>
        ),
        dataIndex: 'barcodes',
        key: 'Weight',
        render: (data, row) => (
          <a href={`/appv2/print_information?consignmentId=${parentRow.consignmentBO.id}&formtype=downloadInventoryWaybill&requestedBarCode=${row.barcodes[0].barcode}`}
            target="_blank"> <Tag color="#87d068">Download Barcode</Tag> </a>
        )
      },
    ];
    return (
      <Card>
        <Table
          loading={selectedConsigment.loading}
          columns={columns}
          dataSource={selectedConsigment[parentRow.consignmentBO.id]?.consignments?.shipmentBOs}
        />
      </Card>
    )
  }

  downloadWaybillData = (row) => {
    this.setState({
      expandedRowKeys: '0',
      currentAction: 'DOWNLOAD',
      currentConsigmentId: row.consignmentBO.id
    });
    destroyNotification()
    const { selectedConsigment } = this.props
    if (!selectedConsigment[row.consignmentBO.id]) {
      this.props.getConsignment(row.consignmentBO.id)
    } else {
      this.prepareDownloadAction(selectedConsigment[row.consignmentBO.id])
    }
  }

  isAllField = (obj) => {
    if (obj == null) {
      return false;
    }
    let returnValue = true;
    Object.keys(obj).forEach(key => {
      if (obj[key] == null) {
        returnValue = false;
      }
    });
    return returnValue;
  }

  prepareDownloadAction = (rowData) => {
    const consignment = rowData.consignments;
    const itemsList = [];
    let isShipmentAttributesField = true;
    consignment.shipmentBOs.forEach((shipment, index) => {
      if (shipment.attributes == null) {
        shipment.attributes = {};
      }
      itemsList.push({
        'itemNo': index + 1,
        'productName': consignment.productTypeBO.name,
        'productDesc': shipment.description,
        'hsnCode': shipment.attributes.hsnCode,
        'quantity': shipment.count,
        'qtyUnit': 'CMS',
        'taxableAmount': shipment.attributes.amount,
        'sgstRate': shipment.attributes.sgst,
        'cgstRate': shipment.attributes.cgst,
        'igstRate': shipment.attributes.igst,
        'cessRate': shipment.attributes.cessValue
      });
      if (isShipmentAttributesField) {
        isShipmentAttributesField = this.isAllField(shipment.attributes);
      }
    });
    if (consignment.attributes == null) {
      consignment.attributes = {};
    }
    const waybillData = {
      'version': '1.0.0123',
      'billLists': [
        {
          'genMode': 'Excel',
          'userGstin': consignment.customer.gstin,
          'supplyType': 'I',
          'subSupplyType': 1,
          'docType': 'INV',
          'docNo': consignment.attributes.invoiceNo,
          'docDate': moment(consignment.attributes.invoiceDate).format('DD/MM/YYYY'),
          'fromGstin': consignment.customerPickupLoc.gst,
          'fromTrdName': consignment.customerPickupLoc.name,
          'fromAddr1': consignment.customerPickupLoc.address.address1,
          'fromAddr2': consignment.customerPickupLoc.address.address2,
          'fromPlace': consignment.customerPickupLoc.address.pincode.city.name,
          'fromPincode': consignment.customerPickupLoc.address.pincode.zipcode,
          'fromStateCode': dataGet(consignment,
            'customerPickupLoc.address.pincode.city.stateBO.attributes.STATE_EWAYBILL_NO', null),
          'toGstin': consignment.consignee.gst,
          'toTrdName': consignment.consignee.name,
          'toAddr1': consignment.consignee.address.address1,
          'toAddr2': consignment.consignee.address.address2,
          'toPlace': consignment.consignee.address.pincode.city.name,
          'toPincode': consignment.consignee.address.pincode.zipcode,
          'toStateCode': dataGet(consignment, 'consignee.address.pincode.city.stateBO.attributes.STATE_EWAYBILL_NO', null),
          'totalValue': consignment.consignmentAmount,
          'cgstValue': consignment.attributes.cgstValue,
          'sgstValue': consignment.attributes.sgstValue,
          'igstValue': consignment.attributes.igstValue,
          'cessValue': consignment.attributes.cessValue,
          'transMode': 1,
          'transDistance': consignment.attributes.transDistance,
          'transporterName': '',
          'transporterId': '',
          'transDocNo': '',
          'transDocDate': '',
          'vehicleNo': '',
          'itemList': itemsList
        }
      ]
    };
    if (this.isAllField(consignment.attributes) && isShipmentAttributesField && consignment.consignmentAmount != null) {
      const fileName = `${rowData.waybillNo}.json`;
      downloadJsonData(waybillData, fileName);
    } else {
      notifyApiError('Please update the all invoice details to download e-waybill', 'Error')
    }
  }

  showAllEwayBills = (eways) => {
    this.setState({ ewayBillsData: eways, showEwayBillsModal: true });
  }

  showAllEwayTripBills = (eways) => {
    this.setState({ ewayData: eways, showEwayModal: true, waybiltrip: <><h3>Hello</h3></> });
  }

  cancelEwayBillsModal = () => {
    this.setState({ ewayBillsData: [], showEwayBillsModal: false });
  }


  onChangeDeps = value => {

    this.setState({ deps: value })
  }

  changeBarcode = (e) => {
    this.setState({ barcode: e.target.value })
  }

  submitBarcode = () => {
    const barcodeErr = []
    if (this.state.scannedBarCodes.indexOf(this.state.barcode) >= 0) {
      notifyApiError('Barcode already have scanned', 'Error:')
      return;
    } else {
      console.log("bardo", this.state.shipmentBarCodes)
      let scan = this.state.shipmentBarCodes?.map(shipment => {
        return shipment.barcodes?.map(barcode => {
          console.log("bardo", barcode)
          if (this.state.barcode === barcode.barcode) {
            barcodeErr.push(true)
            this.setState(prevState => ({
              barcode: undefined,
              scannedBarCodes: [...prevState.scannedBarCodes, this.state.barcode],
              shipmentScans: [...prevState.shipmentScans, {
                "shipmentCodeType": "BARCODE",
                "scanInputType": "BARCODE_SCANNER",
                "shipmentCode": this.state.barcode,
                "shipmentId": shipment.id,
                "entityType": "MANIFEST",
                "consignmentId": this.state?.docketNo,
                "entityId": this.state?.manifestId,
                "scanType": "POD_SCAN"
              }]
            }));
          } else {
            barcodeErr.push(false)
          }

        })
      })

      if (!barcodeErr.includes(true)) {
        notifyApiError('No such barcode found', 'Error:');
      }
    }
  }

  deleteShipmentCode = (docket, row, index) => {


    let scanCode = [...this.state.scannedBarCodes];
    let shipmentCode = [...this.state.shipmentScans];
    scanCode.splice(index, 1);
    shipmentCode.splice(index, 1);

    this.setState({
      shipmentScans: shipmentCode,
      scannedBarCodes: scanCode
    })
  }

  onChangePod = (date, dateString) => {

    let { markDelFilters } = this.state;
    let { requests } = markDelFilters;
    let { podCreatedDate } = markDelFilters;
    podCreatedDate = date;

    requests[0].consignmentPodBO.podCreatedDate = date.valueOf().toString();
    requests[0].consignmentPodBO.podUpdatedDate = date.valueOf().toString();
    markDelFilters.requests = requests;
    this.setState({ markDelFilters: { ...this.state.markDelFilters, podCreatedDate } });


  }


  onUploadFinish = e => {

    let { markDelFilters } = this.state;
    let { requests } = markDelFilters;

    requests[0].consignmentPodBO.podImpLink = e;
    markDelFilters.requests = requests;
    this.setState({ markDelFilters });
  }

  markDel = (docket, tripToPayBO) => {
    this.setState({
      markDeliveryVisible: true,
      deps: false,
      clearFileMarkDelivery: false,
      docketNo: docket.waybillNo,
      tripToPayBO,
      manifestId: docket?.manifestId
    });
    console.log("docket", docket)
    if (this.props?.config?.barcode?.forward?.includes(docket?.customer?.id?.toString())) {
      this.setState({ shipmentBarCodes: docket?.shipmentBOs, shipmentScan: true })
    }





    // let filters = {
    //   "request": [
    //     {
    //       "action": "CREATE",
    //       "tripId": docket.tripId,
    //       "wayBillNo": docket.waybillNo,
    //       "consignmentPodBO": {
    //         "entityCode": docket.manifestCode,
    //         "entityType": "MANIFEST",
    //         "shipmentCount": docket.totalShipmentCount,
    //         "consignmentId": docket.id,
    //         "podImpLink": null,
    //         "entityId": docket.manifestId,
    //         "podCreatedDate": this.state.podDate,
    //         "partnerId": this.props.partnerId,
    //         "podUpdatedDate": this.state.podDate,
    //         "waybillNo": docket.waybillNo,
    //         "shipmentStatus": "DEL",
    //         "deliveryUserId": this.props.header.loggedInUser.id
    //       },
    //       "referenceId": docket.waybillNo
    //     }


    //   ]
    // }

    let filters = {
      "isTripUpdateRequired": true,
      "requests": [
        {
          "action": "CREATE",
          "wayBillNo": docket?.waybillNo,
          "consignmentPodBO": {
            "podImpLink": null,
            "wayBillNo": docket?.waybillNo,
            "podCreatedDate": this.state.podDate,
            "podUpdatedDate": this.state.podDate,
            "shipmentStatus": "DEL"
          },
          "referenceId": docket?.waybillNo
        }
      ],
      "ignoreServicibilityCheck": true
    }



    this.setState({ markDelFilters: filters });
  }

  markDeliverOk = () => {

    // if (this.state.shipmentScan) {
    //   if (this.state?.shipmentBarCodes?.length == this.state?.shipmentScans?.length) {
    //     this.setState({ markdelError: undefined });
    //   } else {
    //     this.setState({ markdelError: "Please scan all barcodes" });
    //     return;
    //   }
    // }

    let { markDelFilters } = this.state;
    let { requests } = markDelFilters;
    let { consignmentPodBO } = requests[0];
    requests[0] = {
      ...requests[0],
      consignmentPodBO: {
        ...consignmentPodBO,
        // "attributes": {
        //   "ignoreBarcodeCreation": "true",
        //   "manifest": "false",
        //   "invoice": "false",
        //   "DEPS": this.state.deps,
        //   "docket": "true"
        // }
      },

    }
    if (this.state.tripToPayBO.amountToBePaid)
      if (this.state.toPayAmtColledtedError === "") {
        requests[0] = {
          ...requests[0],
          consignmentPodBO: {
            ...consignmentPodBO,
            amountPaidOnDelivery: this.state.toPayAmtColledted
          },

        }
      } else {
        return false
      }

    if (requests[0]?.consignmentPodBO?.podCreatedDate == null) {
      let { deliveryDateTime } = this.state;
      deliveryDateTime = {
        validateStatus: 'error',
        help: 'Field is Required'
      }


      this.setState({ deliveryDateTime });
      return;
    } else {
      let { deliveryDateTime } = this.state;
      deliveryDateTime = {
        validateStatus: 'success',
        help: ''
      }
      this.setState({ deliveryDateTime, markDelLoading: true });
    }

    // let filters = this.state.markDelFilters.request;
    let filters = {
      ...markDelFilters,
      requests: [this.state.markDelFilters.requests[0]]
    }

    if (this.state.shipmentScan) {
      let requests = this.state.markDelFilters.requests[0];
      requests = {
        ...requests,
        // shipmentScans: this.state.shipmentScans
      }

      filters = {
        ...markDelFilters,
        requests: [requests]
      }


    }
    delete filters?.podCreatedDate;

    console.log("filter", filters)

    // console.log("filters", filters);
    // filters = {
    //   ...filters,
    //   requests: [
    //     ...requests[0],

    //   ]

    // }

    apiUtils.apiRequest({
      url: `/b2b/v1/partner/${Constants.PARTNER_ID}/otc`,
      data: filters,
      method: "POST"
    })
      .then(response => {

        if (checkHttpStatus(response.data?.status)) {
          notifyApiSuccess("Mark Delivered", "SUCCESS:");
          let { deliveryDateTime, markDelFilters } = this.state;
          markDelFilters.podCreatedDate = undefined;
          deliveryDateTime = {
            validateStatus: 'success',
            help: ''
          }
          this.setState({
            deliveryDateTime,
            markDelLoading: false,
            markDeliveryVisible: false,
            markDelFilters,
            deps: false,
            shipmentScan: false,
            shipmentBarCodes: [],
            clearFileMarkDelivery: true,
            scannedBarCodes: [],
            shipmentScans: [],
            barcode: undefined,
            manifestId: undefined,
            markdelError: undefined

          }, () => {
            this.reloadAwbList();
            // this.props.paginatedFilters(10);
          });
        }

        else {
          notifyApiError(response?.data?.message, "ERROR:");
          this.markDeliverCancel();
        }
      })
      .catch(error => notifyApiError(error, "ERROR:"));


    // this.props.markDel(filters);
    // setTimeout(() => {
    //     this.markDeliverCancel();
    // }, 7000)


  }




  markDeliverCancel = () => {
    let { deliveryDateTime, markDelFilters } = this.state;
    markDelFilters.podCreatedDate = undefined;
    deliveryDateTime = {
      validateStatus: 'success',
      help: ''
    }
    this.setState({
      deliveryDateTime,
      markDelLoading: false,
      markDeliveryVisible: false,
      deps: false,
      clearFileMarkDelivery: true,
      markDelFilters,
      shipmentScan: false,
      shipmentBarCodes: [],
      scannedBarCodes: [],
      barcode: undefined,
      shipmentScans: [],
      manifestId: undefined,
      markdelError: undefined
    }, () => {
      // this.reloadAwbList
      // this.props.paginatedFilters(10);
    });
  }

  disable = current => {
    return current && current > moment().endOf('second');
  }

  cancelFilterModal = () => {
    const { filterPayload } = this.state;
    const { filters } = filterPayload;
    if (filters?.movementTypes) {
      delete filters?.movementTypes;
    }
    this.InputSearchRef.current.input.state.value = '';

    if (filters?.destinationLocationIds) {
      delete filters?.destinationLocationIds;
    }

    if (filters?.locationIds) {
      delete filters?.locationIds;
    }

    if (filters?.bookingDate) {
      delete filters?.bookingDate;
    }


    this.setState({
      showFilterModal: false,
      bookingDate: undefined,
      bookingType: undefined,
      pickupLoc: undefined,
      destinationLoc: undefined,
      filterPayload: {
        ...filterPayload,
        filters: {
          ...filters
        }
      }
    }, this.applyPagination({}, true))
  }

  applyFilter = () => {
    const filter = {};
    const { filterPayload } = this.state;
    const { filters } = filterPayload;
    if (this.state.bookingType) {
      if (this.state.bookingType !== 'ALL')
        filter['movementTypes'] = [`${this.state.bookingType}`]
      if (this.state.bookingType === 'ALL')
        filter['movementTypes'] = [];
    }
    if (this.state.destinationLoc) {
      filter['destinationLocationIds'] = [`${this.state.destinationLoc}`]
    }
    if (this.state.pickupLoc) {
      filter['locationIds'] = [`${this.state.pickupLoc}`]
    }
    if (this.state.bookingDate) {
      filter['bookingDate'] = this.state.bookingDate
    }


    this.setState({
      filterPayload: {
        ...filterPayload,
        filters: {
          ...filters,
          ...filter
        }
      }
    })


    this.setState({
      showFilterModal: false
    }, this.applyPagination(filter, true))
  }

  onTopayAmtChange = val => {
    const self = this;
    this.setState({
      toPayAmtColledted: val,
      toPayAmtColledtedError: ''
    }, () => {
      const { tripToPayBO, docketNo, toPayAmtColledted } = self.state
      let toPayObj = tripToPayBO.amountToBePaid
      let toPayAmtColledtedError = '';
      if (toPayObj) {
        if (toPayObj > toPayAmtColledted) {
          toPayAmtColledtedError = `Outstanding Amount is received less, please collect the full amount Rs. ${toPayObj}`
        } else if (toPayObj < toPayAmtColledted) {
          toPayAmtColledtedError = `Outstanding Amount is received more, please collect only Rs. ${toPayObj}`
        } else {
          toPayAmtColledtedError = ''
        }
      }
      self.setState({
        toPayAmtColledtedError
      })
    })
  }

  render() {
    const { DataCardList,columns, pageNo, awbList, searchValue, application, expandedRowKeys, loadingModal, isReasonPreset, shipmentParams, summaryLoader } = this.state
    const { awbData, PaginatedConsigment, updateConsigment } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelAlign: "left",
      colon: false
    };
    const actionBtns = (
      <Spin spinning={awbData?.loading} indicator={<div></div>}>
        <Row>
          <Col span={15}>
            <Input.Search allowClear size={'large'} placeholder="Search By Waybill" ref={this.InputSearchRef} onSearch={value => this.applyPagination({ waybills: [value.trim()] }, true)} enterButton />
          </Col>
          <Col span={2}>
            <Tooltip placement="top" title={'Reload Awb List'}>
              <Button size={'large'} onClick={this.reloadAwbList} type="primary">
                <Icon type="reload" />
              </Button>
            </Tooltip>
          </Col>
          <Col span={2} offset={1}>
            <Tooltip placement="top" title={'Filter'}>
              <Button size={'large'} onClick={e => { this.setState({ showFilterModal: true }) }} type="primary">
                <Icon type="filter" />
              </Button>
            </Tooltip>
          </Col>
          <Col span={2} offset={1}>
            <Tooltip placement="top" title={'Create booking'}>
              <Dropdown overlay={<Menu>
                <Menu.Item key={1} onClick={e => this.props.history.push(`/appv2/inbound/form`)}>
                  <span>
                    <Tooltip placement="top" title={'Create booking'}>
                      FTL
                    </Tooltip>
                  </span>
                </Menu.Item>
                <Menu.Item key={2} onClick={e => this.props.history.push(`/appv2/inbound/ptl/form`)}>
                  <span>
                    <Tooltip placement="top" title={'Create booking'}>
                      PTL
                    </Tooltip>
                  </span>
                </Menu.Item>
              </Menu>} trigger={['click']} placement="bottomCenter">
                <Button size={'large'} type="primary">
                  <Icon type="plus" />
                </Button>
              </Dropdown>
            </Tooltip>
          </Col>
        </Row>
      </Spin>
    );
    return (
      <>
        <PageHeader
          style={{
            padding: 0,
            paddingBottom: '24px'
          }}
          onBack={false}
          ghost
        >
          <Row>
            <Col span={20} className={summaryLoader ? "text-center" : ''}>
              <Spin spinning={summaryLoader} >
                {
                  DataCardList && DataCardList.length > 0 ? DataCardList.map(item => (
                    <Col span={8} >
                      <DataCard {...item} />
                    </Col>
                  )) : null
                }
              </Spin>
            </Col>            
          </Row>
        </PageHeader>
        <Card className="inventoryawb" title={<h2>Awb List</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            rowKey={(row => (row.id))}
            expandedRowRender={application === 'B2B' ? this.getExpandedRow : null}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, row) => { this.setState({ expandedRowKeys: expanded ? row.id.toString() : "0", currentAction: 'EXPANDED', currentConsigmentId: row.consignmentBO.id }); if (!this.props.selectedConsigment[row.consignmentBO.id]) { this.props.getConsignment(row.consignmentBO.id) } }}
            loading={awbData?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: awbData?.totalCount,
              position: 'bottom',
              current: pageNo,
            }}
            dataSource={searchValue.length > 0 ? awbList ? awbList[pageNo] : [] : PaginatedConsigment ? PaginatedConsigment[pageNo] : []}
            scroll={{ x: 1300 }}
            columns={columns} />
        </Card>
        {
          shipmentParams && (
            <Modal
              title="PLEASE CONFIRM YOUR ACTION"
              visible={this.state.confirmModalVisible}
              onOk={() => this.confirmAction()}
              confirmLoading={loadingModal || updateConsigment?.loading}
              onCancel={() => this.showConfirm(false)}
              maskClosable={false}
              okButtonProps={{
                disabled: (loadingModal || !isReasonPreset)
              }}
              okText={`Yes, Mark shipment as ${shipmentParams['request'][0]['consignment']['consignmentStatus'] === 'RTS_RETURN_TO_SHIPPER' ? 'RTS' :
                'RTO'}`}
            >
              <p>Are you sure you want to mark the {shipmentParams['request'][0]['consignment']['waybillNo']} shipment as {shipmentParams['request'][0]['consignment']['consignmentStatus']}?</p>
              <Form.Item {...formItemLayout} label="Reason" >
                <Select style={{ width: '100%' }} onSelect={(val) => this.onReasonSelected(val)} placeholder="Select Action">
                  {
                    this.state.reasonList.map(opt => (
                      <Select.Option value={opt.value} key={opt.id}>{opt.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Modal>
          )
        }
        <Modal
          title={"EwayBill"}
          visible={this.state.showEwayBillsModal}
          onCancel={this.cancelEwayBillsModal}
          footer={[
            <Button key="submit" type="primary" onClick={this.cancelEwayBillsModal}>
              OK
            </Button>,
          ]}
        >
          <p>
            <ul>
              {this.state.ewayBillsData && this.state.ewayBillsData.length > 0 ? this.state.ewayBillsData.map(eway => (
                <li>Ewaybill No: {eway.ewaybillNo} -
                  {
                    eway.validUpto ?
                      <>
                        {eway.validUpto && moment(eway.validUpto) < moment() ? ' Expired on: ' : ' Valid Upto: '}
                        {moment(eway.validUpto).format("DD-MMM-YYYY H:mm")}
                      </>
                      : !eway.validUpto && !eway.noOfDaysValid ?
                        <> Validity Date not available</>
                        : !eway.validUpto && eway.noOfDaysValid ?
                          <> Validity upto {eway.noOfDaysValid} {eway.noOfDaysValid > 1 ? 'days' : 'day'}</>
                          : <></>

                  }</li>
              )) : <></>}
            </ul>
          </p>

        </Modal>
        <Modal
          title={`Mark Delivery : Docket No - ${this.state.docketNo}`}
          visible={this.state.markDeliveryVisible}
          onCancel={this.markDeliverCancel}
          width={500}
          footer={[
            <Button key="back" onClick={this.markDeliverCancel}>
              Cancel
                            </Button>,
            <Button key="submit" loading={this.state.markDelLoading} disabled={this.state.markDelLoading} type="primary" onClick={this.markDeliverOk}>
              Confirm
                            </Button>,
          ]}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {/* <span>DEPS <br />(D - Damages; E-Excess; P - Pilferage; S - Shortage)</span> */}
              <Form.Item
                className="form-item-row"
                label={<>DEPS (D - Damages; E-Excess; P - Pilferage; S - Shortage)</>}
              >
                <Switch checked={this.state.deps} onChange={this.onChangeDeps} />
              </Form.Item>
            </Col>
            <Col span={24}>


              <Form.Item
                className="form-item-row"
                label="Delivery Date and Time"
                {...this.state.deliveryDateTime}
              >
                <DatePicker
                  disabledDate={this.disable}
                  value={this.state.markDelFilters.podCreatedDate}
                  showTime
                  style={{ width: '100%' }}
                  onChange={this.onChangePod}
                />
              </Form.Item>

            </Col>
            {
              this.state?.tripToPayBO?.paymentType === 'TO_PAY' && <Col span={24}>
                <Form.Item
                  className="form-item-row"
                  label="To Pay Amount Collected"
                >
                  <InputNumber onChange={val => this.onTopayAmtChange(val)} style={{ width: '100%' }} />
                  {!!this.state.toPayAmtColledtedError && <small style={{ color: 'red' }}> {this.state.toPayAmtColledtedError}  </small>}
                </Form.Item>

              </Col>
            }
            {this.state?.shipmentScan &&

              <Col span={24}>
                <div onClick={() => this.setState({ barcodeModal: true })} style={{ cursor: 'pointer' }}><b>Scan bags to recieve</b><span style={{ float: 'right' }}>{this.state?.scannedBarCodes?.length} / {this.state?.shipmentBarCodes?.length} <Icon type="scan" /></span></div>
              </Col>
            }
            <Col span={24}>
              <span className="form-label-text">POD Images</span>
              <FileUpload
                fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                directUpload={true}
                disableUploadButton={false}
                onUploadFinish={($events) => this.onUploadFinish($events)}
                path="trips"
                acceptTypes={this.state.acceptTypes}
                objKey="trips"
                clearFileAfterUpload={this.state.clearFileMarkDelivery}
              />
            </Col>
          </Row>
          {this.state?.markdelError &&
            <Alert message={this.state?.markdelError} type={"error"} />
          }

          {this.props.markDelData && this.props.markDelData.length > 0 ?
            this.props.markDelData.map(res => {
              return <><br /><Alert message={res.reason} type={res.sync ? "success" : "error"} /></>
            }) : <></>
          }
        </Modal>
        <Modal
          title={`Docket No - ${this.state.docketNo}`}
          visible={this.state.barcodeModal}
          maskClosable={false}
          closable={false}
          width={500}
          footer={[
            <Button key="submitmark" onClick={() => this.setState({ barcodeModal: false })} type="primary" >
              OK
                            </Button>,
          ]}
        >
          <Row gutter={4}>
            <Col span={24}>
              <Input
                onChange={this.changeBarcode}
                value={this.state.barcode}
                onPressEnter={this.submitBarcode}
                suffix={<Icon type="scan" />}
                style={{ width: '100%' }}
              />
              <p>Use Barcode Scanner for faster process or enter manually</p>
            </Col>
            <Col span={24}>
              <div>
                <span>Recent Scans</span>
                <span style={{ float: 'right' }}>{this.state?.scannedBarCodes?.length} / {this.state?.shipmentBarCodes?.length}</span>
              </div>
            </Col>
            {this.state?.scannedBarCodes?.length > 0 &&
              <Col span={24}>
                <Table
                  pagination={false}
                  columns={this.state.shipmentTableColumns}
                  dataSource={this.state?.shipmentScans}
                />
              </Col>
            }
          </Row>
        </Modal>
        <Modal
          title={"Filters"}
          visible={this.state.showFilterModal}
          onCancel={() => { this.setState({ showFilterModal: false }) }}
          width={400}
          footer={[
            <Button key="clear" onClick={this.cancelFilterModal}>
              Clear
            </Button>,
            <Button key="submit" type="primary" onClick={this.applyFilter}>
              Apply
            </Button>,
          ]}
        >

          <Form.Item label="Booking Type" style={{ padding: 0, margin: 0 }}>
            <Radio.Group value={this.state.bookingType} buttonStyle="solid" onChange={e => this.setState({ bookingType: e.target.value })}>
              <Radio.Button value="ALL">All</Radio.Button>
              <Radio.Button value="FTL">FTL</Radio.Button>
              <Radio.Button value="PTL">PTL</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Destination Location" style={{ padding: 0, margin: 0 }}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a destination location"
              value={this.state.destinationLoc}
              onChange={val => this.setState({ destinationLoc: val })}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                this.props?.locations?.map(loc => (
                  <Select.Option value={loc.id}>{loc.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="Pickup Location" style={{ padding: 0, margin: 0 }}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a pickup location"
              value={this.state.pickupLoc}
              onChange={val => this.setState({ pickupLoc: val })}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                this.props?.locations?.map(loc => (
                  <Select.Option value={loc.id}>{loc.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="Booking Date" style={{ padding: 0, margin: 0 }}>
            <DatePicker.RangePicker
              value={this.state.bookingDate ? [moment(this.state.bookingDate.from), moment(this.state.bookingDate.to)] : [undefined, undefined]}
              onChange={val => this.setState({ bookingDate: { from: val[0].startOf('day').valueOf(), to: val[1].endOf('day').valueOf() } })}
            />
          </Form.Item>
        </Modal>

      </>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  awbData: state.inventoryAwb,
  selectedConsigment: state.inventoryAwb.selectedConsigment,
  updateConsigment: state.inventoryAwb.updateConsigment,
  PaginatedConsigment: state.inventoryAwb.PaginatedConsigment,
  summary: state.app.summary,
  config: state.app.configurations,
  header: state?.header,
  locations: state.header.locationHeaderDetails,
  auditStatus: state.audit?.auditStatus,
});
const mapDispatchToProps = dispatch => ({
  getSummary: page => dispatch(getSummary(page)),
  fetchConsigmentUnassigned: (payload, isReset) => dispatch(fetchConsigmentUnassigned(payload, isReset)),
  getConsignment: payload => dispatch(getConsignment(payload)),
  updateConsignment: payload => dispatch(updateConsignment(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Awb);