import React, { Component } from "react";
import './magnifier.scss';

class Magnifier extends Component {
    
    constructor(props){
        super(props);
        const componentDOM = document.createElement("div");
        componentDOM.id = props.imageId + "_magnifier";
        componentDOM.classList.add("image-magnifier");
        componentDOM.classList.add("border-grey");
        document.getElementById("root").appendChild(componentDOM);
        this.state = {
            magnifierId : props.imageId + "_magnifier"
        }
    }
    
    componentDidUpdate(){
        const magnifierId = '#' + this.state.magnifierId;
        const imageId = '#' + this.props.imageId;
        const magnifier = document.querySelector(magnifierId);
        const image = document.querySelector(imageId);
        if(magnifier && image){
            magnifier.style.background = "url('"+ image?.src +"') no-repeat #FFF";
        }
    }

    componentDidMount(){
        const imageId = '#' + this.props.imageId;
        const magnifierId = this.state.magnifierId;
        document.querySelector(imageId).classList.add("cursor-zoom-in");
        let magnified = document.querySelector('#' + magnifierId), style = magnified.style;
        style.background = "url('"+ document.querySelector(imageId)?.src +"') no-repeat #FFF";
        document.querySelector(imageId)?.addEventListener('mousemove', function(e) {
            let image = document.querySelector(imageId),
                magnified = document.querySelector('#' + magnifierId),
                style = magnified.style,
                x = e.offsetX,
                y = e.offsetY,
                imgWidth = this.offsetWidth,
                imgHeight = this.offsetHeight,
                xperc = ((x/imgWidth) * 100),
                yperc = ((y/imgHeight) * 100);

            if(x > (.01 * imgWidth)) {
                xperc += (.15 * xperc);
            };

            if(y >= (.01 * imgHeight)) {
                yperc += (.15 * yperc);
            };

            magnified.style.backgroundSize = image.offsetWidth * 2 + 'px ' + image.offsetHeight * 2 + 'px';

            style.display = 'block';
            let rotateTransform  = document.querySelector(imageId).style.transform;
            let startIndex = rotateTransform.indexOf('rotate(');
            let endIndex = rotateTransform.indexOf(')', startIndex) + 1;
            rotateTransform = rotateTransform.substr(startIndex, endIndex);
            style.transform = rotateTransform;
            style.backgroundPositionX = (xperc - 9) + '%';
            style.backgroundPositionY = (yperc - 9) + '%';

            style.left = (e.pageX - 175) + 'px';
            style.top = (e.pageY - 175) + 'px';

        });

        document.querySelector(imageId)?.addEventListener('mouseout', function(e) {
            let magnified = document.querySelector('#' + magnifierId),
                style = magnified.style;
            style.display = 'none';
        })
    }
      
    render(){
        return <></>
    }
}

export default Magnifier;