import React, { PureComponent } from 'react'
import { canAllow } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import { Constants } from '../../common/utils/constants';
import InboundScan from './scan/index';
import Expected from './expected/index';
import Exception from './exception/index';



class InboundModule extends PureComponent {

    constructor(props) {
        super(props)
        const inboundTag =
            Constants.SELECTED_APPLICATION === "B2B"
                ? permissionTags.inboundView
                : permissionTags.inbound;
        this.state = {
            pageOrder: ['scan', 'expected', 'exceptions'],
            inboundPermissions: {
                scan: {
                    read: canAllow(inboundTag.scan.read),
                    create: canAllow(inboundTag.scan.create)
                },
                expected: canAllow(permissionTags.inbound.expected.read),
                exceptions: canAllow(
                    permissionTags.inbound.exceptions.read
                ),
                printHistory: canAllow(
                    permissionTags.inbound.print_history.read
                )
            },
        }
    }

    render() {

        if (this.state.inboundPermissions.scan.read) {
            return <InboundScan {...this.props} />
        } else if (this.state.inboundPermissions.expected) {
            return <Expected {...this.props} />
        } else if (this.state.inboundPermissions.exceptions) {
            return <Exception {...this.props} />
        }



        return (
            <></>
        )
    }
}

export default InboundModule;