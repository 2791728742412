import * as CONSTANTS from './constants';
import { removeDuplicateLocations } from '../../common/utils/utils';

let initialState = {
    tracking: {
        loading: false
    }
}
export const CustomerTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.GET_ACCESS_KEYS_BEGIN: return getKeysBegin(state);
        case CONSTANTS.GET_ACCESS_KEYS_SUCESS: return getKeysSuccess(state, action);
        case CONSTANTS.GET_ACCESS_KEYS_FAILURE: return getKeysFailure(state);


        case CONSTANTS.GET_TRACKING_DETAILS_BEGIN: return getTrackingBegin(state);
        case CONSTANTS.GET_TRACKING_DETAILS_SUCCESS: return getTrackingSuccess(state, action);
        case CONSTANTS.GET_TRACKING_DETAILS_FAILURE: return getTrackingFailure(state);
        case CONSTANTS.CLEAR_TRACKING: return clearTracking(state)

        case CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_BEGIN: return getVehicleBegin(state);
        case CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_SUCCESS: return getVehicleSuccess(state, action);
        case CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_FAILURE: return getVehicleFailure(state);

        case CONSTANTS.GET_THC_DETAILS_BEGIN: return getThcBegin(state);
        case CONSTANTS.GET_THC_DETAILS_SUCCESS: return getThcSuccess(state, action);
        case CONSTANTS.GET_THC_DETAILS_FAILURE: return getThcFailure(state);

        case CONSTANTS.GET_ROUTE_DETAILS_BEGIN: return getRouteBegin(state);
        case CONSTANTS.GET_ROUTE_DETAILS_SUCCESS: return getRouteSuccess(state, action);
        case CONSTANTS.GET_ROUTE_DETAILS_FAILURE: return getRouteFailure(state);

        case CONSTANTS.GET_HELIOS_VEHICLE_TRACKING_DETAILS_SUCCESS: return getHeliosRouteTrackingData(state, action); 


       
        case CONSTANTS.RESET_FIELDS: return resetFields(state);
        default:
            return state;
    }
}

function resetFields(state) {
    return {};
}


function getKeysBegin(state) {
    return {
        ...state,
        keys: {
            loading: true
        },       
    }
}

function getKeysSuccess(state, action) {
    return {
        ...state,
        keys: {
            keysData: action.payload,
            loading: false
        },        
    }
}

function getKeysFailure(state) {
    return {
        ...state,
        keys: {
            loading: false
        }
    }
}

function getTrackingBegin(state) {
    return {
        ...state,
        tracking: {
            loading: true
        },       
    }
}

function clearTracking(state) {
    return {
        ...state,
        tracking: {
            trackingData: {trackingResponses:[]},
            loading: false
        }
    }
}

function getTrackingSuccess(state, action) {
    if(action?.payload?.trackingResponses)
    action.payload.trackingResponses[0].trackingEvents.reverse()
    return {
        ...state,
        tracking: {
            trackingData: action.payload,
            loading: false
        },        
    }
}

function getTrackingFailure(state) {
    return {
        ...state,
        tracking: {
            loading: false
        }
    }
}



function getThcBegin(state) {
    return {
        ...state,
        thc:{
            trackingLoading: true
        }
    }
}

function getThcSuccess(state, action) {
    return {
        ...state,
        thc:{
            thcData: action.payload,
            trackingLoading: true
        }
             
    }
}

function getThcFailure(state) {
    return {
        ...state,
        thc:{
            trackingLoading: false
        }
    }
}


function getRouteBegin(state) {
    return {
        ...state,
        routeLoading: true,
    }
}

function getRouteSuccess(state, action) {
    return {
        ...state,
        routingData: action.payload,
        routeLoading: true,      
    }
}

function getHeliosRouteTrackingData(state, action){
    return {
        ...state,
        heliosRouteData: action.payload
    }
}


function getRouteFailure(state) {
    return {
        ...state,
        routeLoading: false,
    }
}


function getVehicleBegin(state) {
    return {
        ...state,
        vehicleLoading: true,
    }
}

function getVehicleSuccess(state, action) {
    return {
        ...state,
        vehicleData: action.payload,
        vehicleLoading: true,      
    }
}

function getVehicleFailure(state) {
    return {
        ...state,
        vehicleLoading: false,
    }
}