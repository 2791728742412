export const GET_WAYBILL_DETAILS_BEGIN = 'GET_WAYBILL_DETAILS_BEGIN';
export const GET_WAYBILL_DETAILS_SUCCESS = 'GET_WAYBILL_DETAILS_SUCCESS';
export const GET_WAYBILL_DETAILS_FAILURE = 'GET_WAYBILL_DETAILS_FAILURE';

export const GET_WAYBILL_TRACKING_DETAILS_BEGIN = 'GET_WAYBILL_TRACKING_DETAILS_BEGIN';
export const GET_WAYBILL_TRACKING_DETAILS_SUCCESS = 'GET_WAYBILL_TRACKING_DETAILS_SUCCESS';
export const GET_WAYBILL_TRACKING_DETAILS_FAILURE = 'GET_WAYBILL_TRACKING_DETAILS_FAILURE';

export const GET_VEHICLE_TRACKING_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_DETAILS_SUCCESS';
export const GET_ROUTE_TRSCKING_DETAILS_SUCCESS ='GET_ROUTE_TRSCKING_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_SIM_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_SIM_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_SIM_DETAILS_BEGIN = 'GET_VEHICLE_TRACKING_SIM_DETAILS_BEGIN';
export const GET_VEHICLE_TRACKING_SIM_DETAILS_FAILURE = 'GET_VEHICLE_TRACKING_SIM_DETAILS_FAILURE';
export const GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SOCKET = 'GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SOCKET';
export const GET_VEHICLE_TRACKING_HISTORIC_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_HISTORIC_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_SIM_HISTORIC_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_SIM_HISTORIC_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_TYPE = 'GET_VEHICLE_TRACKING_TYPE';
export const GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_BEGIN = 'GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_BEGIN';
export const GET_HELIOS_INITIAL_DATA = 'GET_HELIOS_INITIAL_DATA';
export const GET_HELIOS_CUSTOMERS_DATA = 'GET_HELIOS_CUSTOMERS_DATA';
export const GET_HELIOS_FILTERED_DATA = 'GET_HELIOS_FILTERED_DATA';
export const GET_HELIOS_TRACKING_DATA_BEGIN = 'GET_HELIOS_TRACKING_DATA_BEGIN';
export const GET_HELIOS_VEHICLE_HISTORY_DATA = 'GET_HELIOS_VEHICLE_HISTORY_DATA';
export const GET_HELIOS_DISTANCE_DATA = 'GET_HELIOS_DISTANCE_DATA';
export const HELIOS_TRACKING_BEGIN = 'HELIOS_TRACKING_BEGIN';
export const HELIOS_TRACKING_END ='HELIOS_TRACKING_END';
export const GET_HELIOS_EMPTY_DATA = "GET_HELIOS_EMPTY_DATA";
export const GET_HELIOS_DATA_LOADING = "GET_HELIOS_DATA_LOADING";

export const GET_MANIFESTS_EVENTS_BEGIN = "GET_MANIFESTS_EVENTS_BEGIN";
export const GET_MANIFESTS_EVENTS_SUCCESS = "GET_MANIFESTS_EVENTS_SUCCESS";
export const GET_MANIFESTS_EVENTS_FAILURE = "GET_MANIFESTS_EVENTS_FAILURE";