import { Button, Icon, Tag, Tooltip } from "antd";
import moment from "moment";
import { canAllow, capitalizeString } from "../../../../../common/utils/utils";
import { Mixpanel } from "../../../../../utils/mixpanel";
import React from "react";
import { permissionTags } from "../../../../../configs/permissionTags";
import { contractStatusToTag } from "../../common/utils";

const formatValidity = date => (moment(Number(date)).format('DD/MM/YYYY'));

export default function boxTypeColumns(boxTypeEvents) {
    return [
        {
            title: 'Box Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Box Type',
            dataIndex: 'type',
            key: 'type',

        },
        {
            title: 'LBH',
            dataIndex: 'lbh',
            key: 'lbh',
            render: (data, row) => {
                if (row.type === "BOX") {
                    return <>{row.length} * {row.breadth} * {row.height}</>
                }
            }

        },
        {
            title: 'Unit',
            dataIndex: 'dimensionUnit',
            key: 'dimensionUnit',

        },
        {
            title: 'Kg/ CFT',
            dataIndex: 'kgPerCft',
            key: 'kgPerCft',

        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: data => data ? 'Active' : 'Not Active'

        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',

            render: (data, row) =>
                <div className="flex-box align-items-center justify-content-end flex-gap-l">
                    {
                        <div className="spacer-xs flex-box dark-grey-bg border-radius-s">

                            <Icon type="eye" onClick={() => {
                                Mixpanel.track("Clicking Edit button in BoxType listing", { 'ContractBoxTypeListing': moment().valueOf() });
                                // contractEvents.editContractDetails(data, false)
                                const data = {
                                    ...row,
                                    lbh: [row.length, row.breadth, row.height].join(","),

                                }
                                boxTypeEvents.setReadOnly(true);
                                boxTypeEvents.setFormData(data);
                                boxTypeEvents.setBoxForm(true);
                            }} />

                        </div>
                    }
                    {
                        row.isActive &&
                        <div className="spacer-xs flex-box dark-grey-bg border-radius-s">

                            <Icon type="edit" onClick={() => {
                                Mixpanel.track("Clicking Edit button in BoxType Edit", { 'ContractBoxTypeEditing': moment().valueOf() });
                                const data = {
                                    ...row,
                                    lbh: [row.length, row.breadth, row.height].join(","),

                                }
                                boxTypeEvents.setReadOnly(false);
                                boxTypeEvents.setFormData(data);
                                boxTypeEvents.setBoxForm(true);
                            }} />

                        </div>
                    }
                </div>
        },
    ]
}