import React, { Component } from 'react';
import {
    Select, Row, Col, Steps,
    Descriptions, Table, Spin, Switch, Icon
} from 'antd';
import { Mixpanel } from '../../../utils/mixpanel';

import _ from 'lodash';
const { Option } = Select;

class TripSummary extends Component {

    constructor(props) {
        super(props);
        this.totalPcs = 0;
        this.totalKgs = 0;
        this.defaultVal = {};
        this.state = {
            docketDataSummary: [],
            totalKgs: 0,
            totalPcs: 0,
            tansit: true,
            hubLocation: {},
            hubLocationMap: {},
            createdTrip: false,
            switchSelectDisabled: false,
        }
        this.switchTransit = this.switchTransit.bind(this);
    }



    componentDidUpdate(oldProps) {
        if (this.props.docketsSummary?.docketLocationAllocations &&
            (this.state.docketDataSummary.length <= 0 ||
                (oldProps.docketsSummary.docketLocationAllocations.length !== this.props.docketsSummary.docketLocationAllocations.length))) {
            this.setState({ docketDataSummary: this.props.docketsSummary.docketLocationAllocations }, () => {
                this.props.onChangeDocketsSummary(this.state.docketDataSummary)
            });
            let hubLocation = {};
            let hubLocationMap = {};
            this.props.docketsSummary.docketLocationAllocations.map((hubs, key) => {
                console.log("hubs->>', hubs")
                hubs.candidateLocations.map(hub => {

                    if (hub.isDirectDelivery) {

                        // if (!this.state.hubLocation[hubs['waybillNo']]) {
                        if (this.props.fields.loadtype.value === 'FTL') {
                            hubLocation = Object.assign({}, hubLocation, { [hubs['waybillNo']]: 'Direct Delivery' })
                            hubLocationMap = Object.assign({}, hubLocationMap, { [hub['consignmentId']]: hub.deliveryPartnerLocationId.toString() })

                        }
                        this.setState({ switchSelectDisabled: true })

                        // hubLocation = {
                        //     [hubs['waybillNo']]: 'Direct Delivery'
                        // };

                        // hubLocationMap = {
                        //     [hubs['consignmentId']]: hub.deliveryPartnerLocationId.toString()
                        // }



                        // this.setState({
                        //     hubLocation: {
                        //         [hubs['waybillNo']]: 'Direct Delivery'
                        //     },
                        //     hubLocationMap: {
                        //         [hubs['consignmentId']]: _.hasIn(hub.destinationLocation, 'id') ? hub.destinationLocation.id.toString() : undefined
                        //     }
                        // }, () => {
                        //     this.props.changeCreateTripDisabled(false);
                        //     this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                        // })
                        // this.setState({

                        // })
                        // }
                    } else {
                        if (hubs.candidateLocations.length === 1) {
                            let destinationLocation = hubs.candidateLocations[0].destinationLocation;
                            let isBlocked = false;

                            if (destinationLocation) {
                                if (destinationLocation.entityType === 'PARTNER' && destinationLocation.entity.custObject && destinationLocation.entity.custObject.partnerVendorBO) {
                                    if (destinationLocation.entity.custObject.partnerVendorBO.isBlocked) {
                                        isBlocked = true;
                                    } else {
                                        isBlocked = false;
                                    }
                                }
                            }

                            if (isBlocked) {
                                this.props.getVendorBlockReason(destinationLocation.entity.custObject.partnerVendorBO.id);
                                return;
                            }
                            hubLocation = {
                                ...hubLocation,
                                [hubs.candidateLocations[0]['waybillNo']]: destinationLocation.name
                            }
                            hubLocationMap = {
                                ...hubLocationMap,
                                [hubs.candidateLocations[0]['consignmentId']]: destinationLocation.id.toString()
                            }
                        }
                    }
                })



                if (hubs.candidateLocations.length === 1 && hubs.candidateLocations.filter(item => item.isDirectDelivery).length === 1) {
                    hubLocation = Object.assign({}, hubLocation, { [hubs['waybillNo']]: 'Direct Delivery' })
                    hubLocationMap = Object.assign({}, hubLocationMap, { [hubs.candidateLocations[0]['consignmentId']]: hubs.candidateLocations[0].deliveryPartnerLocationId.toString() })
                }
            });
            this.setState({
                hubLocation: hubLocation,
                hubLocationMap: hubLocationMap
            }, () => {
                if (Object.keys(hubLocation).length === this.props.docketsSummary.docketLocationAllocations.length) {
                    this.props.changeCreateTripDisabled(false);
                }

                this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
            })
        }

        if (this.props.createTripFlow && this.props.createTripFlow.responses && this.props.createTripFlow.responses.length > 0 && !this.state.createdTrip) {
            this.props.createTripFlow.responses.map(res => {
                if (res.sync === true) {
                    this.setState({ createdTrip: true }, () => {
                        Mixpanel.track("Create trip Finished", { 'tripIdentity': this.props.trackingIdentity })
                        this.props.history.push('/appv2/trips/dashboard');
                        this.props.setInitialState()
                    })
                }
            })
        }



    }

    changeHub = (value, option, waybill, consignmentId) => {

        let destinationLocation = null;
        let isBlocked = false;
        this.props.docketsSummary.docketLocationAllocations.map((hubs, key) => {
            hubs.candidateLocations.map(hub => {
                if (hub?.destinationLocation?.name === value) {
                    destinationLocation = hub.destinationLocation;
                }
            })
        });

        if (destinationLocation) {
            if (destinationLocation.entityType === 'PARTNER' && destinationLocation.entity.custObject && destinationLocation.entity.custObject.partnerVendorBO) {
                if (destinationLocation.entity.custObject.partnerVendorBO.isBlocked) {
                    isBlocked = true;
                } else {
                    isBlocked = false;
                }
            }
        }

        if (isBlocked) {
            this.props.getVendorBlockReason(destinationLocation.entity.custObject.partnerVendorBO.id);
            return;
        }


        this.setState({
            hubLocation: {
                ...this.state.hubLocation,
                [waybill]: value
            },
            hubLocationMap: {
                ...this.state.hubLocationMap,
                [consignmentId]: option.key.toString()
            }
        }, () => {
            console.log(this.state.hubLocation)
            this.props.onChangeDocketSummaryHub(this.state.hubLocationMap);
            if (this.props.docketsSummary.docketLocationAllocations.length == Object.keys(this.state.hubLocationMap).length) {
                let checkIfEmpty = false;

                for (let val in this.state.hubLocationMap) {
                    if (_.isUndefined(this.state.hubLocationMap[val])) {
                        checkIfEmpty = true;
                    }
                }

                if (!checkIfEmpty) {
                    this.props.changeCreateTripDisabled(false);
                } else {
                    this.props.changeCreateTripDisabled(true);
                }


            }
            let keys = Object.keys(this.state.hubLocation);
            // console.log("keys", keys)
            let disableCreateTrip = false;
            if (keys && keys.length > 0) {
                keys.map(k => {
                    // console.log("k", k, this.state.hubLocation[k])
                    if (this.state.hubLocation[k] === undefined) {
                        disableCreateTrip = true;
                    }
                });
            }

            if (keys && keys.length < this.state.docketDataSummary.length) {
                disableCreateTrip = true;
            }


            this.props.changeCreateTripDisabled(disableCreateTrip);
        });


    }

    switchTransit = (value, wayBill, row, index) => {
        // console.log("value, value", value, index)
        this.setState({ [wayBill]: value }, () => {
            // console.log("this.state", this.state)
            let keys = Object.keys(this.state.hubLocation);
            // console.log("keys", keys)
            let disableCreateTrip = false;
            if (keys && keys.length > 0) {
                keys.map(k => {
                    // console.log("k", k, this.state.hubLocation[k])
                    if (this.state.hubLocation[k] === undefined) {
                        disableCreateTrip = true;
                    }
                });
            }

            if (keys && keys.length < this.state.docketDataSummary.length) {
                disableCreateTrip = true;
            }


            this.props.changeCreateTripDisabled(disableCreateTrip);
        });


        // if (!value) {
        //     this.setState({
        //         hubLocation: {
        //             ...this.state.hubLocation,
        //             [wayBill]: 'Direct Delivery'
        //         }
        //     })

        // }


        row.candidateLocations.map(hub => {

            if (!value) {
                if (hub.isDirectDelivery) {
                    this.setState({
                        hubLocation: {
                            ...this.state.hubLocation,
                            [wayBill]: 'Direct Delivery'
                        },
                        hubLocationMap: {
                            ...this.state.hubLocationMap,
                            [hub['consignmentId']]: hub.deliveryPartnerLocationId.toString()
                        },
                        switchSelectDisabled: true
                    }, () => {
                        this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                    })
                } else {
                    // this.setState({
                    //     hubLocation: {
                    //         ...this.state.hubLocation,
                    //         [wayBill]: undefined
                    //     },
                    //     hubLocationMap: {
                    //         ...this.state.hubLocationMap,
                    //         [hub['consignmentId']]: undefined
                    //     }
                    // }, () => {
                    //     this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                    // })
                }
            } else {
                this.setState({
                    hubLocation: {
                        ...this.state.hubLocation,
                        [wayBill]: undefined
                    },
                    hubLocationMap: {
                        ...this.state.hubLocationMap,
                        [hub['consignmentId']]: undefined
                    },
                    switchSelectDisabled: false
                }, () => {
                    this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                })
            }

            console.log("hub", this.state.hubLocationMap)

            // if (hub.isDirectDelivery) {
            //     if (!value) {
            //         this.setState({
            //             hubLocation: {
            //                 ...this.state.hubLocation,
            //                 [wayBill]: 'Direct Delivery'
            //             }
            //         })

            //     } else {
            //         this.setState({
            //             hubLocation: {
            //                 ...this.state.hubLocation,
            //                 [wayBill]: undefined
            //             }
            //         })
            //     }

            // } else {
            //     if(!value) {
            //         this.setState({
            //             hubLocation: {
            //                 ...this.state.hubLocation,
            //                 [wayBill]: undefined
            //             }
            //         })
            //     }
            // }
        });


    }



    renderDocketsSummary() {

        const columns = [
            {
                title: 'S No',
                dataIndex: 'index',
                key: 'index'
            },
            {
                title: 'Docket ID',
                dataIndex: 'waybillNo',
                key: 'waybillNo'
            },
            {
                title: 'Origin',
                dataIndex: 'origin',
                key: 'origin'
            },
            {
                title: 'Destination',
                dataIndex: 'destination',
                key: 'destination'
            },
            {
                title: <>Quantity / <br />Shipment Counts / <br />Total chargeable wt.</>,
                dataIndex: 'quantityInKgs',
                key: 'quantityInKgs'
            },
            {
                title: 'Direct / Transit Via',
                dataIndex: 'transit',

                key: 'transit',
                render: (hubs, row) => {
                    if (hubs.candidateLocations.length <= 0) {
                        return <b>No Locations</b>
                    }
                    if (hubs.candidateLocations.length > 0 && hubs.candidateLocations.filter(cl => !cl.isDirectDelivery).length > 0) {
                        console.log('if', hubs);
                        return (
                            <Row>
                                <Col span={24}>

                                    <Select
                                        showSearch
                                        placeholder="Select Hub"
                                        disabled={
                                                _.hasIn(this.state, hubs['waybillNo']) && this.state[hubs['waybillNo']] === false ? true : false
                                            //  _.hasIn(this.state, hubs['waybillNo'] && console.log(this.state,hubs['waybillNo'], this.state[hubs['waybillNo']]) && !this.state[hubs['waybillNo']])
                                        }
                                        // disabled={row.candidateLocations.length <= 0}
                                        // disabled={!this.state[hubs['waybillNo']]}
                                        onSelect={(value, option) => this.changeHub(value, option, hubs['waybillNo'], hubs['consignmentId'])}
                                        defaultActiveFirstOption
                                        defaultValue={this.state.hubLocation[hubs['waybillNo']]}
                                        value={this.state.hubLocation[hubs['waybillNo']]}
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            hubs.candidateLocations.map((hub, key) => {
                                                if (hub.isDirectDelivery) {
                                                    // console.log(this.state)
                                                    // if(!this.state.hubLocation[hubs['waybillNo']]) {
                                                    //     this.setState({
                                                    //         hubLocation: {
                                                    //             [hubs['waybillNo']]: 'Direct Delivery'
                                                    //         }
                                                    //     })
                                                    // }

                                                    // this.setState({[hubLocation[hubs['waybillNo']]]: 'Direct Delivery'})
                                                    // return (
                                                    //     <Option
                                                    //         value="Direct Delivery"
                                                    //     >
                                                    //         Direct Delivery
                                                    //             </Option>
                                                    // )

                                                } else {
                                                    // console.log("way->>", this.state.hubLocation[hubs['waybillNo']])
                                                    // if(!this.state.hubLocation[hubs['waybillNo']]) {
                                                    //     this.setState({
                                                    //         hubLocation: {
                                                    //             [hubs['waybillNo']]: 'Direct'
                                                    //         }
                                                    //     })
                                                    // }

                                                    return (
                                                        <Option
                                                            value={hub.destinationLocation.name}
                                                            label={hub.destinationLocation.name}
                                                            key={hub.destinationLocation.id}
                                                        >
                                                            {hub.destinationLocation.name}
                                                        </Option>
                                                    )
                                                }

                                            })
                                        }
                                    </Select>

                                </Col>
                                {/* <Col span={8} offset={2}>
    
    
                                <span>Transit Via</span><Switch size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row)} />
                            </Col> */}
                            </Row>
                        )

                    } else if (hubs.candidateLocations.length == 1 && hubs.candidateLocations.filter(cl => cl.isDirectDelivery).length == 1) {
                        return (
                            <Row>
                                <Col span={24}>

                                    <Select
                                        showSearch
                                        placeholder="Select Hub"
                                        // disabled={row.candidateLocations.length <= 0}
                                        disabled={true}
                                        onSelect={(value, option) => this.changeHub(value, option, hubs['waybillNo'], hubs['consignmentId'])}
                                        defaultActiveFirstOption
                                        defaultValue={'Direct Delivery'}
                                        value={'Direct Delivery'}
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            hubs.candidateLocations.map((hub, key) => {
                                                if (hub.isDirectDelivery) {
                                                    // console.log(this.state)
                                                    // if(!this.state.hubLocation[hubs['waybillNo']]) {
                                                    //     this.setState({
                                                    //         hubLocation: {
                                                    //             [hubs['waybillNo']]: 'Direct Delivery'
                                                    //         }
                                                    //     })
                                                    // }

                                                    // this.setState({[hubLocation[hubs['waybillNo']]]: 'Direct Delivery'})
                                                    // return (
                                                    //     <Option
                                                    //         value="Direct Delivery"
                                                    //     >
                                                    //         Direct Delivery
                                                    //             </Option>
                                                    // )

                                                } else {
                                                    // console.log("way->>", this.state.hubLocation[hubs['waybillNo']])
                                                    // if(!this.state.hubLocation[hubs['waybillNo']]) {
                                                    //     this.setState({
                                                    //         hubLocation: {
                                                    //             [hubs['waybillNo']]: 'Direct'
                                                    //         }
                                                    //     })
                                                    // }

                                                    return (
                                                        <Option
                                                            value={hub.destinationLocation.name}
                                                            label={hub.destinationLocation.name}
                                                            key={hub.destinationLocation.id}
                                                        >
                                                            {hub.destinationLocation.name}
                                                        </Option>
                                                    )
                                                }

                                            })
                                        }
                                    </Select>

                                </Col>
                                {/* <Col span={8} offset={2}>


                            <span>Transit Via</span><Switch size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row)} />
                        </Col> */}
                            </Row>
                        )
                    }
                    else if (hubs.candidateLocations.length > 0 && hubs.candidateLocations.filter(cl => !cl.isDirectDelivery).length <= 0) {
                        console.log("else if", this.state.hubLocation)
                        return (
                            <Row>
                                <Col span={24}>

                                    <Select
                                        showSearch
                                        placeholder="Select Hub"
                                        // disabled={row.candidateLocations.length <= 0}
                                        disabled={true}
                                        onSelect={(value, option) => this.changeHub(value, option, hubs['waybillNo'], hubs['consignmentId'])}
                                        defaultActiveFirstOption
                                        defaultValue={this.state.hubLocation[hubs['waybillNo']]}
                                        value={this.state.hubLocation[hubs['waybillNo']]}
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            hubs.candidateLocations.map((hub, key) => {
                                                if (hub.isDirectDelivery) {
                                                    // console.log(this.state)
                                                    // if(!this.state.hubLocation[hubs['waybillNo']]) {
                                                    //     this.setState({
                                                    //         hubLocation: {
                                                    //             [hubs['waybillNo']]: 'Direct Delivery'
                                                    //         }
                                                    //     })
                                                    // }

                                                    // this.setState({[hubLocation[hubs['waybillNo']]]: 'Direct Delivery'})
                                                    // return (
                                                    //     <Option
                                                    //         value="Direct Delivery"
                                                    //     >
                                                    //         Direct Delivery
                                                    //             </Option>
                                                    // )

                                                } else {
                                                    // console.log("way->>", this.state.hubLocation[hubs['waybillNo']])
                                                    // if(!this.state.hubLocation[hubs['waybillNo']]) {
                                                    //     this.setState({
                                                    //         hubLocation: {
                                                    //             [hubs['waybillNo']]: 'Direct'
                                                    //         }
                                                    //     })
                                                    // }

                                                    return (
                                                        <Option
                                                            value={hub.destinationLocation.name}
                                                            label={hub.destinationLocation.name}
                                                            key={hub.destinationLocation.id}
                                                        >
                                                            {hub.destinationLocation.name}
                                                        </Option>
                                                    )
                                                }

                                            })
                                        }
                                    </Select>

                                </Col>
                                {/* <Col span={8} offset={2}>


                            <span>Transit Via</span><Switch size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row)} />
                        </Col> */}
                            </Row>
                        )
                    }
                }

            },
            {
                key: 'action',
                render: (hubs, row, index) => {
                    if (hubs.candidateLocations.length <= 0) {
                        return <><span>Transit Via </span><Switch disabled={true} /> <span onClick={e => this.removeDocketItem(hubs)} style={{ color: 'red', marginLeft: 5, cursor: 'pointer' }}><Icon type="close-circle" /></span> </>
                    }
                    if (this.props.fields.loadtype.value === 'FTL') {
                        return <><span>Transit Via </span><Switch disabled={true} /></>
                    }
                    if (hubs.candidateLocations.length == 1 && hubs.candidateLocations.filter(cl => cl.isDirectDelivery).length == 1) {
                        return <><span>Transit Via </span><Switch disabled={true} /></>
                    }
                    if (hubs.candidateLocations.length > 0 && hubs.candidateLocations.filter(cl => cl.isDirectDelivery).length > 0) {
                        return <><span>Transit Via </span><Switch defaultChecked onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row, index)} /></>
                    }
                    if (hubs.candidateLocations.length > 0 && hubs.candidateLocations.filter(cl => !cl.isDirectDelivery).length > 0) {
                        return <><span>Transit Via </span><Switch disabled={true} defaultChecked /></>
                    }


                    return <><span>Transit Via </span><Switch defaultChecked disabled={(hubs.candidateLocations <= 0 || !(hubs.candidateLocations.length > 0 && hubs.candidateLocations.filter(cl => !cl.isDirectDelivery).length > 0))} size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row)} /></>

                }
                // <><span>Transit Via </span><Switch  disabled={(hubs.candidateLocations <= 0 || !(hubs.candidateLocations.length > 0 && hubs.candidateLocations.filter(cl => !cl.isDirectDelivery).length > 0))} size="small" onChange={(value) => this.switchTransit(value, hubs['waybillNo'], row)} /></>

            }
        ];
        const data = [];
        let totalPcs = 0, totalKgs = 0;
        this.state.docketDataSummary.map((docket, key) => {

            let origin = docket.consignment?.customerPickupLoc?.address?.pincode?.city?.name ? docket.consignment?.customerPickupLoc?.address?.pincode?.city?.name : docket.consignment?.bookingOfficeLoc?.address?.pincode?.city?.name;
            let destination = docket.consignment?.destinationShortCode?.favLocationBO?.address?.pincode?.city?.name ? docket.consignment?.destinationShortCode?.favLocationBO?.address?.pincode?.city?.name : docket.consignment?.pincode?.city?.name;
            totalPcs += docket.consignment.totalShipmentCount;
            totalKgs += docket.consignment.totalWeight;
            console.log("totalOcs", totalPcs, totalKgs)
            this.totalKgs = totalKgs;
            this.totalPcs = totalPcs;
            // this.setState({totalPcs: totalPcs, totalKgs: totalKgs})
            data.push(
                {
                    'waybillNo': docket.waybillNo,
                    'index': key + 1,
                    'origin': origin,
                    'destination': destination,
                    'quantityInKgs': <>{docket.consignment.totalShipmentCount} Pcs  <small>({docket.consignment.totalWeight} Kgs)</small></>,
                    'transit': docket,
                    ...docket
                }
            );
        });

        // this.props.changeTotalPrice(totalPcs, totalKgs)





        return (
            <Table
                bordered
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        )
    }

    removeDocketItem = row => {
        console.log(row)
        const { docketDataSummary } = this.state
        let hubLocation = {};
        let hubLocationMap = {};
        let switchSelectDisabled = false

        this.setState({
            docketDataSummary: docketDataSummary.filter(item => item.waybillNo !== row.waybillNo)
        }, () => {
            this.props.onChangeDocketsSummary(this.state.docketDataSummary)
            if (this.state.docketDataSummary.length <= 0) {
                this.props.goBack()
            } else
                this.state.docketDataSummary.map(hub => {
                    hub.candidateLocations.map(hub => {
                        if (hub.isDirectDelivery) {
                            hubLocation = {
                                ...this.state.hubLocation,
                                [hub.waybillNo]: 'Direct Delivery'
                            }
                            hubLocationMap = {
                                ...this.state.hubLocationMap,
                                [hub['consignmentId']]: hub.deliveryPartnerLocationId.toString()
                            }
                            switchSelectDisabled = true
                        } else {
                            hubLocation = {
                                ...this.state.hubLocation,
                                [hub.waybillNo]: undefined
                            }
                            hubLocationMap = {
                                ...this.state.hubLocationMap,
                                [hub['consignmentId']]: undefined
                            }
                            switchSelectDisabled = false
                        }

                    });
                    this.setState({
                        hubLocation,
                        hubLocationMap,
                        switchSelectDisabled
                    }, () => {
                        this.props.onChangeDocketSummaryHub(this.state.hubLocationMap)
                        let keys = Object.keys(this.state.hubLocation);
                        let disableCreateTrip = false;
                        if (keys && keys.length > 0) {
                            keys.map(k => {
                                if (this.state.hubLocation[k] === undefined) {
                                    disableCreateTrip = true;
                                }
                            });
                        }

                        if (keys && keys.length < this.state.docketDataSummary.length) {
                            disableCreateTrip = true;
                        }

                        this.props.changeCreateTripDisabled(disableCreateTrip);
                    })
                })
        })
    }
    render() {

        return (
            <>
                {this.state.docketDataSummary.length > 0 ?
                    <>
                        <Descriptions layout="vertical" size="small" colon={false} column={{ xs: 24, sm: 16, md: 6 }}>
                            {this.props.fields.dispatched.value === 'DV' ? <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Route</small>}>
                                <strong>{this.props.fields.route.value.label}</strong>
                            </Descriptions.Item> : <></>}

                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small">Total Dockets</small>}>
                                <strong>{this.state.docketDataSummary.length}</strong>
                            </Descriptions.Item>
                            <Descriptions.Item
                                className="trip-descriptions-item"
                                label={<small className="trip-description-small"><>Quantity / Shipment Counts / <br />Total chargeable wt.</></small>}>
                                <strong>{this.totalPcs} Pcs ({this.totalKgs} Kgs)</strong>
                            </Descriptions.Item>

                        </Descriptions>
                        <br />
                        {this.renderDocketsSummary()}
                    </>
                    : <Spin />}
            </>


        )
    }
}

export default TripSummary;