import * as CONSTANTS from './constants';
import * as apiUtils from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
import { notifyApiError, notifyApiSuccess } from '../../common/utils/utils';
// import * as trackingService from './trackingService';

const partnerId = localStorage.getItem('loggedPartnerId');


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getAccessKeys = () => dispatch => {
    dispatch(getKeysBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/users/access-keys`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getKeysBeginSuccess(response.data?.response))
        })
        .catch(error => dispatch(getKeysBeginFailure(error)));
}

function getKeysBegin() {
    return {
        type: CONSTANTS.GET_ACCESS_KEYS_BEGIN,
        payload: null
    }
}



function getKeysBeginSuccess(response) {
    return {
        type: CONSTANTS.GET_ACCESS_KEYS_SUCESS,
        payload: response
    }
}

function getKeysBeginFailure(response) {
    return {
        type: CONSTANTS.GET_ACCESS_KEYS_FAILURE,
        payload: response
    }
}


export const getRoutDate = (tripID) => dispatch => {
    // return function(dispatch) {
    //   return trackingService.getRouteValues(data).then((response) => {
    //         dispatch(routeTrackingData(response.data.response));
    //     })
    // };
    dispatch(getRouteDataBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/thcRouteMapping/v2/${tripID}`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(routeHeliosTrackingData(response.data?.response))
        })
        .catch(error => dispatch(getRouteDataFailure(error)));
}



function getRouteDataBegin() {
    return {
        type: CONSTANTS.GET_ROUTE_DETAILS_BEGIN,
        payload: null
    }
}

function getRouteDataSuccess(response) {
    return {
        type: CONSTANTS.GET_ROUTE_DETAILS_SUCCESS,
        payload: response
    }
}

function getHeliosRouteDataSuccess(response) {
    return {
        type: CONSTANTS.GET_HELIOS_ROUTE_DETAILS_SUCCESS,
        payload: response
    }
}

function getRouteDataFailure(response) {
    return {
        type: CONSTANTS.GET_ROUTE_DETAILS_FAILURE,
        payload: response
    }
}


export const getTrackingData = (partnerIds, customerIds) => dispatch => {
    // return function (dispatch) {
    //     return trackingService.getTrackingValues(data).then((response) => {
    //         dispatch(vehicleTrackingData(response.data.response));
    //     })
    // };
    // let url = `b2b/v1/vehicleTracker/get?partnerIds=${payload.partnerIds}`;
    // if (payload.customerIds) {
    //     url += `&customerIds=${payload.customerIds}`;
    // }
    dispatch(getTrackingDataBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/vehicleTracker/v2/get?partnerIds=${partnerIds}&customerIds=${customerIds}`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getTrackingDataSuccess(response.data?.response))
        })
        .catch(error => dispatch(getTrackingDataFailure(error)));
}

function getTrackingDataBegin() {
    return {
        type: CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_BEGIN,
        payload: null
    }
}

function getTrackingDataSuccess(response) {
    return {
        type: CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_SUCCESS,
        payload: response
    }
}

function getTrackingDataFailure(response) {
    return {
        type: CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_FAILURE,
        payload: response
    }
}




export const getTrackingDetails = (partnerId, clientId, waybill, isInvoice = false) => dispatch => {
    dispatch(getTrackingBegin());
    dispatch(getThcBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/tracking/public/partner/${partnerId}/client/${clientId}/waybill/${waybill}?is_invoice=${isInvoice}`
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getTrackingSuccess(response.data?.response))
        })
        .catch(error => dispatch(getTrackingFailure(error)));
}

function getTrackingBegin() {
    return {
        type: CONSTANTS.GET_TRACKING_DETAILS_BEGIN,
        payload: null
    }
}

export function clearTracking() {
    return {
        type: CONSTANTS.CLEAR_TRACKING
    }
}

function getTrackingSuccess(response) {
    return {
        type: CONSTANTS.GET_TRACKING_DETAILS_SUCCESS,
        payload: response
    }
}

function getTrackingFailure(response) {
    return {
        type: CONSTANTS.GET_TRACKING_DETAILS_FAILURE,
        payload: response
    }
}



export const getThcDetails = (waybill) => dispatch => {
    dispatch(getThcBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/thcRouteMapping/v2/waybill/${waybill}`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getThcSuccess(response.data?.response))
        })
        .catch(error => dispatch(getThcFailure(error)));
}

function getThcBegin() {
    return {
        type: CONSTANTS.GET_THC_DETAILS_BEGIN,
        payload: null
    }
}

function getThcSuccess(response) {
    return {
        type: CONSTANTS.GET_THC_DETAILS_SUCCESS,
        payload: response
    }
}

function getThcFailure(response) {
    return {
        type: CONSTANTS.GET_THC_DETAILS_FAILURE,
        payload: response
    }
}

export const resetFields = () => dispatch => {
    dispatch({
        type: CONSTANTS.RESET_FIELDS
    })
}




function routeHeliosTrackingData(res){
    return {
      type: CONSTANTS.GET_HELIOS_VEHICLE_TRACKING_DETAILS_SUCCESS,
      payload: res
    }
  }
