import { Table } from "antd";
import React from "react";

const SLAListingTable = ({ columns, data, setPagination, pagination }) => {
  
  return (
    <Table
      rowKey={(data) => (data ? data.waybillNo : "id")}
      columns={columns}
      bordered
      dataSource={data?.slaBreachDocketResponses}
      scroll={{ x: 1250 }}
      pagination={{
        current: pagination.pageNo,
        pageSize: pagination.pageSize,
        total: data.totalCount,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} dockets`,
        onChange: (page, pageSize) => {
          setPagination((pagination) => {
            return {
              ...pagination,
              pageNo: page,
            };
          });
        },
        onShowSizeChange: (current, size) => {
          setPagination((pagination) => {
            return {
              ...pagination,
              pageSize: size,
            };
          });
        },
      }}
    />
  );
};

export default SLAListingTable;
