import React from "react";
import { Route } from "react-router-dom";
import { withRouter, matchPath } from "react-router-dom";
import PageNotFound from "../components/authentication/access/PageNotFound";

const RouteWithTitle = ({ component, path, name, exact }) => {
  document.title = getDocumentTitle(name);
  /**
     * Start
     *
     * Reason: This piece of code was written to remove the localStorage ["hyperlocalCityId", "hyperlocalLocationIds"] on unloading of hyperlocal module.
     *         It is used to maintain the filters throughout the hyperlocal routes.
     * Note: We need to revisit this code and think about better way of handling any logic at module level in this case we need Filters at module level, whatever the filters are applied, are used in all submodules.
     */
  if (!window.location.pathname.includes("/appv2/hyperlocal/")) {
    localStorage.removeItem("hyperlocalCityId");
    localStorage.removeItem("hyperlocalLocationIds");
  }
  /** end */
  const match = matchPath(window.location.pathname, {
    path: path,
    exact: true,
   strict: false
 });
  return match === undefined || match == null ? <Route component={withRouter(PageNotFound)} /> : <Route component={withRouter(component)} path={path} exact={exact} />;
  // return <Route component={withRouter(component)} path={path} exact={exact} />;
};
function getDocumentTitle(name) {
  let titleParts = [];  
  titleParts[0] = document.title.split("|")[0];
  titleParts[1] = ` ${name} `;  
  titleParts.splice(2, titleParts.length);  
  return titleParts.join(" |");
}

export default RouteWithTitle;
