import { Input } from "antd";
import React from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";

export default function LBHWidget(props) {
    
    const uiAttrs = schemaToAttrs(props.uiSchema);
    var LValue = undefined;
    var BValue = undefined;
    var HValue = undefined;
    if (props?.value && typeof props?.value == "string") {
        [LValue, BValue, HValue] = props.value.split(",");
    } else if (props?.value && props?.value instanceof Array) {
        [LValue, BValue, HValue] = [props.value[0], props.value[1], props.value[2]];
    }

    return (
        <div className="input-wrapper">
            <div className="flex-box flex-gap-m">
                <div>
                    <span>Length</span>
                    <Input

                        value={LValue}

                        onChange={e => {
                            const value = e.target.value;
                            if (value) {
                                if (new RegExp("^[.\\d]+$").test(value)) {
                                    props.onChange([value, BValue ? BValue : undefined, HValue ? HValue : undefined].join(","))
                                }
                            } else {
                                props.onChange([value, BValue ? BValue : undefined, HValue ? HValue : undefined].join(","))
                            }

                        }
                        }


                        onBlur={(e) => props.onBlur(props.id, props.value)}
                        onFocus={props.onFocus}
                        disabled={props.disabled} readOnly={props.readonly} {...uiAttrs} />
                </div>
                <div>
                    <span>Breadth</span>
                    <Input

                        value={BValue}
                        onChange={e => {
                            const value = e.target.value;
                            if (value) {
                                if (new RegExp("^[.\\d]+$").test(value)) {
                                    props.onChange([LValue ? LValue : undefined, value, HValue ? HValue : undefined].join(","))
                                }
                            } else {
                                props.onChange([LValue ? LValue : undefined, value, HValue ? HValue : undefined].join(","))
                            }

                        }
                        }

                        onBlur={(e) => props.onBlur(props.id, props.value)} onFocus={props.onFocus}
                        disabled={props.disabled} readOnly={props.readonly} {...uiAttrs} />
                </div>
                <div>
                    <span>Height</span>
                    <Input

                        value={HValue}
                        onChange={e => {
                            const value = e.target.value;
                            if (value) {
                                if (new RegExp("^[.\\d]+$").test(value)) {
                                    props.onChange([LValue ? LValue : undefined, BValue ? BValue : undefined, value].join(","))
                                }
                            } else {
                                props.onChange([LValue ? LValue : undefined, BValue ? BValue : undefined, value].join(","))
                            }
                            
                        }
                        }

                        onBlur={(e) => props.onBlur(props.id, props.value)} onFocus={props.onFocus}
                        disabled={props.disabled} readOnly={props.readonly} {...uiAttrs} />
                </div>
            </div>



            {
                props.rawErrors?.length > 0 &&
                <div className="error-text">
                    {checkForDuplicateErrors(props.rawErrors).join(" ")}
                </div>
            }
        </div>
    )
}