export const pModules = {
  inboud: {
    title: "INBOUND",
    subModule: {
      scan: "SCAN",
      expected: "EXPECTED",
      exceptions: "EXCEPTIONS",
      newscan: "NEW_SCAN",
      print_history: "PRINT_HISTORY",
    },
  },
  inboudView: {
    title: "INBOUND_VIEW",
    subModule: {
      scan: "SCAN",
      newscan: "NEW_SCAN",
      booking: "BOOKING",
    },
  },
  load: {
    title: "LOAD_VIEW",
    subModule: {
      expected: "EXPECTED",
      exceptions: "EXCEPTIONS",
    },
  },
  inventory: {
    title: "INVENTORY",
    subModule: {
      awb: "AWB",
    },
  },
  tracking: {
    title: "TRACKING",
    subModule: {
      tracking: "TRACKING",
      heliosVehicleTracking: "HELIOS_VEHICLE_TRACKING",
    },
  },
  trips: {
    title: "TRIPS",
    subModule: {
      trips: "TRIPS",
      completed: "COMPLETE_POD",
      pending: "PENDING_POD",
      sla: "SLA_REASON",
    },
  },
  dashboard: {
    title: "DASHBOARD",
    subModule: {
      BRANCH_SUMMARY: "BRANCH_SUMMARY",
      SLA: "SLA",
      COOLING_PERIOD: "COOLING_PERIOD",
      POD: "POD",
      WAYBILL_DATA: "WAYBILL_DATA",
      CSAT_FEEDBACK: "CSAT_FEEDBACK",
      ESCAL_CSAT: "ESCAL_CSAT",
      REVENUE: "REVENUE",
      GPS_TRACKING: "GPS_TRACKING",
      SERVICEABILITY: "SERVICEABILITY",
      CFT: "CFT",
      EwaybillAvailability: "EwaybillAvailability",
      OPSKPI: "OPSKPI",
      LASTMILE_USAGE: "LASTMILE_USAGE",
      REALTIME_USAGE: "REALTIME_USAGE",
      USER_MISTAKES: "USER_MISTAKES",
      POD_HEALTH: "POD_HEALTH",
    },
  },

  invoice: {
    title: "INVOICE",
    subModule: {
      customer_settlement: "CUSTOMER_SETTLEMENT",
    },
  },
  settings: {
    title: "SETTINGS",
    subModule: {
      customer: "CUSTOMER",
      vendor: "VENDOR",
      route: "ROUTE",
      user: "USER",
      location: "LOCATION",
      asset: "ASSET",
      vehicle: "VEHICLE",
      zone: "ZONE",
      pincode: "PINCODE",
      slaRules: "CUSTOMER_SLAS",
      customer_invite: "CUSTOMER_INVITE",
      zoho: "ZOHO",
      custom_config: {
        title: "PARTNERS ONBOARDING",
        configKey: "authorizedUserIdsToUpdateApplicationConfig",
      },
      ratecard_config: "RATECARD_CONFIG",
      partners_onboarding: {
        title: "PARTNERS ONBOARDING",
        configKey: "partnerCreation",
      },
    },
  },
  lms: {
    title: "LMS",
    subModule: {
      setup: "SETUP",
    },
  },

  importExport: {
    title: "IMPORT_EXPORT",
    subModule: {
      import: "IMPORT",
      export: "EXPORT",
      docketToFinishTrip: "DOCKET_TO_FINISH_TRIP",
    },
  },
  reports: {
    title: "REPORTS",
    subModule: {
      reports: "REPORTS",
      ingest: "INGEST",
      manifestSheet: "MANIFEST_SHEET",
      new_reports: "NEW_REPORTS",
    },
  },
  customer_settings: {
    title: "CUSTOMER_SETTINGS",
    subModule: {
      user_settings: "USERS",
    },
  },
  // userDashboard: {
  //     title: 'USER DASHBOARD'
  // },
  communication: {
    title: "COMMUNICATION",
    subModule: {
      mail: "EMAIL",
    },
  },
  live_loads: {
    title: "LIVE_LOADS",
    subModule: {
      live_loads: "LIVE_LOADS",
    },
  },
  deliver_loads: {
    title: "DELIVERY_LOADS",
    subModule: {
      deliver_loads: "DELIVERY_LOADS",
    },
  },
  customer_tracking: {
    title: "CUSTOMER_TRACKING",
    subModule: {
      docket_tracking: "DOCKET_TRACKING",
      vehicle_tracking: "VEHICLE_TRACKING",
    },
  },
  ewaybill: {
    title: "EWAYBILL",
    subModule: {
      ewaybill: "EWAYBILL",
      partb: "PARTB",
    },
  },
  verification: {
    title: "VERIFICATION",
    subModule: {
      trips: "TRIPS",
      docket: "DOCKET",
      pod: "POD",
      contract: "CONTRACT",
    },
  },
  contract: {
    title: "CONTRACT",
    subModule: {
      customer: "CUSTOMERBASIC",
      locations: "LOCATIONS",
      zones: "ZONES",
      shippers: "SHIPPERS",
      lanes: "LANES",
      contractdetails: "CONTRACTDETAILS",
    },
  },
  indent: {
    title: "INDENT",
    subModule: {
      indent: "INDENT",
    },
  },
  sla_monitoring: {
    title: "SLA_MONITORING",
    subModule: {
      sla_monitoring: "SLA_MONITORING",
    },
  },
};

export const paTags = {
  read: "READ",
  create: "CREATE",
  update: "UPDATE",
  delete: "DELETE",
  view: "VIEW",
  bulkCreate: "BULK_CREATE",
  bulkUpdate: "BULK_UPDATE",
  waybill_scan: "WAYBILL_SCAN",
  barcode_scan: "BARCODE_SCAN",
  orn_scan: "ORN_SCAN",
  bulk_booking: "BULK_BOOKING",
  bulk_inscan: "BULK_INSCAN",
  flipkart_inscan_api: "FLIPKART_INSCAN_API",
  print_waybill: "PRINT_WAYBILL",
  locationSelection: "LOCATION_SELECTION",
  bulk_pod: "BULK_POD",
  readERP: "READ_ERP",
  invite: "INVITE",
  hpod: "HPOD",
  view_unblock: "VIEW_UNBLOCK",
  view_shippers: "SHIPPERS_VIEW",
  create_shippers: "SHIPPERS_CREATE",
  update_shippers: "SHIPPERS_UPDATE",
  status_shippers: "SHIPPERS_STATUS",
  ratecard_shippers: "SHIPPERS_RATECARD",
  undel_return: "UNDEL_RETRUN",
  coloader: "COLOADER",
  deactivate: "DEACTIVATE",
  refresh: "REFRESH",
  reverify: "REVERIFY",
  upload: "UPLOAD",
  approve: "APPROVE",
  edit: "EDIT",
  contract_view: "CONTRACT_VIEW",
  reuseManifest: "REUSE_MANIFEST",
  forwardManifest: "FORWARD_MANIFEST",
};

export const permissionTags = {
  inbound: {
    module: { module: pModules.inboud.title },
    scan: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.read,
      },
      create: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.create,
      },
      delete: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.delete,
      },
      update: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.update,
      },
    },
    expected: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.expected,
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.expected,
        action: paTags.read,
      },
    },
    exceptions: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.exceptions,
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.exceptions,
        action: paTags.read,
      },
    },
    print_history: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
      },
      create: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.create,
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.read,
      },
      delete: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.delete,
      },
      update: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.update,
      },
    },
    newscan: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.read,
      },
      waybill_scan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.waybill_scan,
      },
      barcode_scan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.barcode_scan,
      },
      orn_scan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.orn_scan,
      },
      bulk_booking: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.bulk_booking,
      },
      bulk_inscan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.bulk_inscan,
      },
      flipkart_inscan_api: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.flipkart_inscan_api,
      },
      print_waybill: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.print_waybill,
      },
      locationSelection: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.locationSelection,
      },
      bulk_pod: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.bulk_pod,
      },
    },
  },
  inboundView: {
    module: { module: pModules.inboudView.title },
    scan: {
      subModule: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.scan,
      },
      read: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.scan,
        action: paTags.read,
      },
      create: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.scan,
        action: paTags.create,
      },
      delete: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.scan,
        action: paTags.delete,
      },
      update: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.scan,
        action: paTags.update,
      },
    },
    newscan: {
      subModule: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
      },
      read: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.read,
      },
      waybill_scan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.waybill_scan,
      },
      barcode_scan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.barcode_scan,
      },
      orn_scan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.orn_scan,
      },
      bulk_booking: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.bulk_booking,
      },
      bulk_inscan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.bulk_inscan,
      },
      flipkart_inscan_api: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.flipkart_inscan_api,
      },
      print_waybill: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.print_waybill,
      },
      locationSelection: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.locationSelection,
      },
      bulk_pod: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.bulk_pod,
      },
    },
    booking: {
      subModule: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.booking,
      },
      read: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.booking,
        action: paTags.read,
      },
      create: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.booking,
        action: paTags.create,
      },
      delete: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.booking,
        action: paTags.delete,
      },
      update: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.booking,
        action: paTags.update,
      },
    },
  },
  load: {
    module: { module: pModules.load.title },
    expected: {
      subModule: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.expected,
      },
      read: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.expected,
        action: paTags.read,
      },
    },
    exceptions: {
      subModule: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.exceptions,
      },
      read: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.exceptions,
        action: paTags.read,
      },
    },
  },
  inventory: {
    module: { module: pModules.inventory.title },
    awb: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.create,
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.read,
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.delete,
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.update,
      },
    },
  },
  tracking: {
    module: { module: pModules.tracking.title },
    tracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.tracking,
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.tracking,
        action: paTags.read,
      },
    },
    newtracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.newTracking,
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.newTracking,
        action: paTags.read,
      },
    },
    heliosVehicleTracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.heliosVehicleTracking,
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.heliosVehicleTracking,
        action: paTags.read,
      },
    },
  },
  trips: {
    module: { module: pModules.trips.title },
    trips: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
        action: paTags.read,
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
        action: paTags.create,
      },
      delete: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
        action: paTags.delete,
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
        action: paTags.update,
      },
      undel_return: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
        action: paTags.undel_return,
      },
      coloader: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.trips,
        action: paTags.coloader,
      },
    },
    completed: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.completed,
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.completed,
        action: paTags.read,
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.completed,
        action: paTags.create,
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.completed,
        action: paTags.update,
      },
    },
    pending: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.pending,
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.pending,
        action: paTags.read,
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.pending,
        action: paTags.create,
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.pending,
        action: paTags.update,
      },
    },
    sla: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.sla,
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.sla,
        action: paTags.read,
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.sla,
        action: paTags.create,
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.sla,
        action: paTags.update,
      },
    },
  },
  verification: {
    module: { module: pModules.verification.title },
    trips: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.read,
      },
      create: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.create,
      },
      delete: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.delete,
      },
      update: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.update,
      },
      reverify: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.reverify,
      },
    },
    docket: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
        action: paTags.read,
      },
      upload: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
        action: paTags.upload,
      },
      update: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
        action: paTags.update,
      },
    },
    contract: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
        action: paTags.read,
      },
      update: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
        action: paTags.update,
      },
      approve: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
        action: paTags.approve,
      },
    },
    pod: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.read,
      },
      edit: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.edit,
      },
      delete: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.delete,
      },
      create: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.create,
      },
      approve: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.approve,
      },
    },
  },
  contract: {
    module: { module: pModules.contract.title },
    customer: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.view,
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.create,
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.update,
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.delete,
      },
    },
    shippers: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.view,
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.create,
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.update,
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.delete,
      },
    },
    zones: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.view,
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.create,
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.update,
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.delete,
      },
    },
    locations: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.view,
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.create,
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.update,
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.delete,
      },
    },
    lanes: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.view,
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.create,
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.update,
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.delete,
      },
    },
    contractdetails: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.view,
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.create,
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.update,
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.delete,
      },
    },
  },

  invoice: {
    module: { module: pModules.invoice.title },
    customer_settlement: {
      subModule: {
        module: pModules.invoice.title,
        subModule: pModules.invoice.subModule.customer_settlement,
      },
      create: {
        module: pModules.invoice.title,
        subModule: pModules.invoice.subModule.customer_settlement,
        action: paTags.create,
      },
      update: {
        module: pModules.invoice.title,
        subModule: pModules.invoice.subModule.customer_settlement,
        action: paTags.update,
      },
      delete: {
        module: pModules.invoice.title,
        subModule: pModules.invoice.subModule.customer_settlement,
        action: paTags.delete,
      },
      read: {
        module: pModules.invoice.title,
        subModule: pModules.invoice.subModule.customer_settlement,
        action: paTags.read,
      },
    },
  },
  lms: {
    module: { module: pModules.lms.title },
    setup: {
      subModule: {
        module: pModules.lms.title,
        subModule: pModules.lms.subModule.setup,
      },
      create: {
        module: pModules.lms.title,
        subModule: pModules.lms.subModule.setup,
        action: paTags.create,
      },
      read: {
        module: pModules.lms.title,
        subModule: pModules.lms.subModule.setup,
        action: paTags.read,
      },
      delete: {
        module: pModules.lms.title,
        subModule: pModules.lms.subModule.setup,
        action: paTags.delete,
      },
      update: {
        module: pModules.lms.title,
        subModule: pModules.lms.subModule.setup,
        action: paTags.update,
      },
    },
  },

  settings: {
    module: { module: pModules.settings.title },
    customer: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.view,
      },
      invite: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.invite,
      },
      shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.view_shippers,
      },
      create_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.create_shippers,
      },
      update_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.update_shippers,
      },
      status_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.status_shippers,
      },
      ratecard_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.ratecard_shippers,
      },
      contract_view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.contract_view,
      },
    },
    vendor: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.read,
      },
      unblock: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.view_unblock,
      },
    },
    route: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.view,
      },
      refresh: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.refresh,
      },
    },
    user: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.view,
      },
    },
    location: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.view,
      },
    },
    asset: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.read,
      },
    },
    vehicle: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.view,
      },
    },
    zone: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.view,
      },
    },
    zoho: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
        action: paTags.update,
      },

      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
        action: paTags.read,
      },
    },
    pincode: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.pincode,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.pincode,
        action: paTags.update,
      },
    },
    partners_onboarding: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.partners_onboarding,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.partners_onboarding,
        action: paTags.create,
      },
    },
    customer_invite: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.read,
      },
    },
    custom_config: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.read,
      },
    },
    ratecard_config: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.ratecard_config,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.ratecard_config,
        action: paTags.update,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.ratecard_config,
        action: paTags.read,
      },
    },
    slaRules: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.read,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.update,
      },
      deactivate: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.deactivate,
      },
    },
  },
  importExport: {
    module: { module: pModules.importExport.title },
    import: {
      subModule: {
        module: pModules.importExport.title,
        subModule: pModules.importExport.subModule.import,
      },
      create: {
        module: pModules.importExport.title,
        subModule: pModules.importExport.subModule.import,
        action: paTags.read,
      },
    },
    export: {
      subModule: {
        module: pModules.importExport.title,
        subModule: pModules.importExport.subModule.export,
      },
      create: {
        module: pModules.importExport.title,
        subModule: pModules.importExport.subModule.export,
        action: paTags.read,
      },
    },
    docketToFinishTrip: {
      subModule: {
        module: pModules.importExport.title,
        subModule: pModules.importExport.subModule.docketToFinishTrip,
      },
      create: {
        module: pModules.importExport.title,
        subModule: pModules.importExport.subModule.docketToFinishTrip,
        action: paTags.read,
      },
    },
  },
  reports: {
    module: { module: pModules.reports.title },
    reports: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.reports,
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.reports,
        action: paTags.read,
      },
    },
    ingest: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.ingest,
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.ingest,
        action: paTags.read,
      },
    },
    manifestSheet: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.manifestSheet,
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.manifestSheet,
        action: paTags.read,
      },
    },
    new_reports: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.new_reports,
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.new_reports,
        action: paTags.read,
      },
    },
  },
  dashboard: {
    module: { module: pModules.dashboard.title },
    BRANCH_SUMMARY: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.BRANCH_SUMMARY,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.BRANCH_SUMMARY,
        action: paTags.read,
      },
    },
    SLA: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.SLA,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.SLA,
        action: paTags.read,
      },
    },
    COOLING_PERIOD: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.COOLING_PERIOD,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.COOLING_PERIOD,
        action: paTags.read,
      },
    },
    POD: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.POD,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.POD,
        action: paTags.read,
      },
    },
    WAYBILL_DATA: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.WAYBILL_DATA,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.WAYBILL_DATA,
        action: paTags.read,
      },
    },
    CSAT_FEEDBACK: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.CSAT_FEEDBACK,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.CSAT_FEEDBACK,
        action: paTags.read,
      },
    },
    ESCAL_CSAT: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.ESCAL_CSAT,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.ESCAL_CSAT,
        action: paTags.read,
      },
    },
    REVENUE: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.REVENUE,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.REVENUE,
        action: paTags.read,
      },
    },
    GPS_TRACKING: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.GPS_TRACKING,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.GPS_TRACKING,
        action: paTags.read,
      },
    },
    SERVICEABILITY: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.SERVICEABILITY,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.SERVICEABILITY,
        action: paTags.read,
      },
    },
    OPSKPI: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.OPSKPI,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.OPSKPI,
        action: paTags.read,
      },
    },
    EwaybillAvailability: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.EwaybillAvailability,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.EwaybillAvailability,
        action: paTags.read,
      },
    },
    CFT: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.CFT,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.CFT,
        action: paTags.read,
      },
    },
    LASTMILE_USAGE: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.LASTMILE_USAGE,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.LASTMILE_USAGE,
        action: paTags.read,
      },
    },
    REALTIME_USAGE: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.REALTIME_USAGE,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.REALTIME_USAGE,
        action: paTags.read,
      },
    },
    USER_MISTAKES: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.USER_MISTAKES,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.USER_MISTAKES,
        action: paTags.read,
      },
    },
    POD_HEALTH: {
      subModule: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.POD_HEALTH,
      },
      read: {
        module: pModules.dashboard.title,
        subModule: pModules.dashboard.subModule.POD_HEALTH,
        action: paTags.read,
      },
    },
  },

  communication: {
    module: { module: pModules.communication.title },
    mail: {
      subModule: {
        module: pModules.communication.title,
        subModule: pModules.communication.subModule.mail,
      },
      create: {
        module: pModules.communication.title,
        subModule: pModules.communication.subModule.mail,
        action: paTags.create,
      },
      read: {
        module: pModules.communication.title,
        subModule: pModules.communication.subModule.mail,
        action: paTags.read,
      },
    },
  },
  userDashboard: {
    module: { module: "" },
  },

  customer_settings: {
    module: { module: pModules.customer_settings.title },
    user_settings: {
      subModule: {
        module: pModules.customer_settings.title,
        subModule: pModules.customer_settings.subModule.user_settings,
      },
      read: {
        module: pModules.customer_settings.title,
        subModule: pModules.customer_settings.subModule.user_settings,
        action: paTags.read,
      },
      create: {
        module: pModules.customer_settings.title,
        subModule: pModules.customer_settings.subModule.user_settings,
        action: paTags.create,
      },
      update: {
        module: pModules.customer_settings.title,
        subModule: pModules.customer_settings.subModule.user_settings,
        action: paTags.update,
      },
    },
  },
  customer_tracking: {
    module: { module: pModules.customer_tracking.title },
    docket_tracking: {
      subModule: {
        module: pModules.customer_tracking.title,
        subModule: pModules.customer_tracking.subModule.docket_tracking,
      },
      read: {
        module: pModules.customer_tracking.title,
        subModule: pModules.customer_tracking.subModule.docket_tracking,
        action: paTags.read,
      },
    },
    vehicle_tracking: {
      subModule: {
        module: pModules.customer_tracking.title,
        subModule: pModules.customer_tracking.subModule.vehicle_tracking,
      },
      read: {
        module: pModules.customer_tracking.title,
        subModule: pModules.customer_tracking.subModule.vehicle_tracking,
        action: paTags.read,
      },
    },
  },
  live_loads: {
    module: { module: pModules.live_loads.title },
    live_loads: {
      subModule: {
        module: pModules.live_loads.title,
        subModule: pModules.live_loads.subModule.live_loads,
      },
      read: {
        module: pModules.live_loads.title,
        subModule: pModules.live_loads.subModule.live_loads,
        action: paTags.read,
      },
    },
  },
  deliver_loads: {
    module: { module: pModules.deliver_loads.title },
    deliver_loads: {
      subModule: {
        module: pModules.deliver_loads.title,
        subModule: pModules.deliver_loads.subModule.deliver_loads,
      },
      read: {
        module: pModules.deliver_loads.title,
        subModule: pModules.deliver_loads.subModule.deliver_loads,
        action: paTags.read,
      },
      hpod: {
        module: pModules.deliver_loads.title,
        subModule: pModules.deliver_loads.subModule.deliver_loads,
        action: paTags.hpod,
      },
    },
  },
  ewaybill: {
    module: { module: pModules.ewaybill.title },
    ewaybill: {
      subModule: {
        module: pModules.ewaybill.title,
        subModule: pModules.ewaybill.subModule.ewaybill,
      },
      read: {
        module: pModules.ewaybill.title,
        subModule: pModules.ewaybill.subModule.ewaybill,
        action: paTags.read,
      },
    },
    partb: {
      subModule: {
        module: pModules.ewaybill.title,
        subModule: pModules.ewaybill.subModule.partb,
      },
      read: {
        module: pModules.ewaybill.title,
        subModule: pModules.ewaybill.subModule.partb,
        action: paTags.read,
      },
      update: {
        module: pModules.ewaybill.title,
        subModule: pModules.ewaybill.subModule.partb,
        action: paTags.update,
      },
    },
  },
  indent: {
    module: { module: pModules.indent.title },
    indent: {
      subModule: {
        module: pModules.indent.title,
        subModule: pModules.indent.subModule.indent,
      },
      view: {
        module: pModules.indent.title,
        subModule: pModules.indent.subModule.indent,
        action: paTags.view,
      },
      create: {
        module: pModules.indent.title,
        subModule: pModules.indent.subModule.indent,
        action: paTags.create,
      },
      update: {
        module: pModules.indent.title,
        subModule: pModules.indent.subModule.indent,
        action: paTags.update,
      },
      approve: {
        module: pModules.indent.title,
        subModule: pModules.indent.subModule.indent,
        action: paTags.approve,
      },
    },
  },
  sla_monitoring: {
    module: { module: pModules.sla_monitoring.title },
    sla_monitoring: {
      subModule: {
        module: pModules.sla_monitoring.title,
        subModule: pModules.sla_monitoring.subModule.sla_monitoring,
      },
      read: {
        module: pModules.sla_monitoring.title,
        subModule: pModules.sla_monitoring.subModule.sla_monitoring,
        action: paTags.read,
      },
      create: {
        module: pModules.sla_monitoring.title,
        subModule: pModules.sla_monitoring.subModule.sla_monitoring,
        action: paTags.create,
      },
      update: {
        module: pModules.sla_monitoring.title,
        subModule: pModules.sla_monitoring.subModule.sla_monitoring,
        action: paTags.update,
      },      
    },
  },
};

export const LsnModules = {
  INBOUND: "INBOUND",
  LOAD: "LOAD",
  INVENTORY: "INVENTORY",
  TRACKING: "TRACKING",
  DASHBOARD: "DASHBOARD",
  SETTINGS: "SETTINGS",
  REPORTS: "REPORTS",
  USERDASHBOARD: "USERDASHBOARD",
  LIVE_LOADS: "LIVE_LOADS",
  DIGITAL_PAYMENT: "DIGITAL_PAYMENT",
};
