import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import PendingTripList from './pending-trips-list';
import { Spin } from 'antd';
import * as tripAction from '../../inventory/trip/actions';
import _ from 'lodash';


const locationId = localStorage.getItem('currentPartnerLocationId');

class PendingTrips extends Component {

    componentDidMount() {

        this.refreshData();
        const locationFilters = { "filters": { "roles": ["DELIVERY", "PICKUP"], "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_LOCATION_LIST" }, "status": true } }
        if (!this.props.locationGroups?.response?.locationsGroupedByRole) this.props.getLocationGroups(locationFilters);
    }

    refreshData = (page = 1, pageSize = 10, waybills = '', bookingFilters = {}, mapFilter = [], isEligibleToComplete = null, locations = null, entityIds = [], pickUpLocations = null) => {
        let consignmentFilter = {};
        if (waybills.length > 0) {
            waybills = [`${waybills}`];
        }

        if (!_.isNull(pickUpLocations)) {
            consignmentFilter = {
                ...consignmentFilter,
                customerPickupLocationIds: [`${pickUpLocations}`]
            }
        }



        let mapFilters = [];
        if (!_.isEmpty(mapFilter)) {
            mapFilters = mapFilter;
        }
        if (!_.isEmpty(bookingFilters)) {
            consignmentFilter = { ...consignmentFilter, bookingFilters };
        }
        if (!_.isNull(locations)) {
            locations = locations;
        } else {
            locations = locationId
        }

        let filters = {
            "filters": {
                "isEmptyPodImgLinks": true,
                "pageNo": page,
                "locationIds": [`${locations}`],
                "pageSize": pageSize,
                "tripIds": mapFilters,
                consignmentFilter,
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_TRIP_LIST",
                    "requestingFrom": "PENDING_POD"
                },
                "fetchObjects": ["THC_ROUTE", "THC_ASSET", "CONSIGNMENTS_CUSTOMER", "THCUSER", "CONSIGNMENT_CONTACTS", "LOCATIONS", "ADDRESS", "POD_APPROVAL"]
            }
        }

        if (!_.isNull(isEligibleToComplete)) {
            filters['filters']['isEligibleToComplete'] = isEligibleToComplete;
        }
        if (entityIds.length > 0) {
            filters['filters']['entityIds'] = entityIds;
        }
        if (waybills.length > 0) {
            filters['filters']['waybills'] = [`${waybills}`];
        }

        this.props.getTripList(filters);
    }

    upload = params => {
        this.props.getPODUpload(params)
    }



    render() {
        // console.log("props->>", this.props.pendingTripData, this.props.pendingTripData == 'undefined' || this.props.pendingTripData == null )
        return (
            <>
                <h3>Pending Trips {this.props.pendingTripData?.totalCount ? <>({this.props.pendingTripData?.totalCount})</> : <></>}</h3>
                <Spin spinning={this.props.tripLoading}>
                   
                        <PendingTripList
                            upload={this.upload}
                            refreshData={this.refreshData}
                            {...this.props}
                        />
                    

                </Spin>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    tripLoading: state?.pendingTripList?.tripLoading,
    pendingTripData: state?.pendingTripList?.pendingTripData,
    podUploadLoading: state?.pendingTripList?.podUploadLoading,
    podUploadData: state?.pendingTripList?.podUploadData,
    locationGroups: state?.trips?.locationGroups,
    partnerAssets: state?.trips?.partnerAssets,
    header: state?.header,
    tripSettlements: state?.trips?.tripSettlements,
});
const mapDispatchToProps = dispatch => ({
    getTripList: (params) => dispatch(actions.getTripPendingList(params)),
    getPODUpload: (params) => dispatch(actions.getPendingPODUpload(params)),
    getLocationGroups: (filters) => dispatch(tripAction.getLocationGroups(filters)),
    fetchTripSettlements: (payload) => dispatch(tripAction.fetchTripSettlements(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PendingTrips);