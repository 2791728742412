import React from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const DatePickerInput = props => {
  const type = props.type ? props.type : "date";
  switch (type) {
    case "date":
      return <DatePicker {...props} />;
    case "range":
      return <RangePicker {...props} />;
    default:
      return <DatePicker {...props} />;
  }
};

export default DatePickerInput;
