import React, { useState } from "react";
import { Modal, Button, Select } from "antd";
import DatePickerInput from "../../../../ui/datepicker/DatePicker";
import { cloneObject } from "../../../../../common/utils/utils";
import _ from "lodash";
import {
  fetchShippers,
  fetchCustomers,
  fetchLocations,
  fetchHubLocations,
} from "../../../../../services/common/fetch.services";
import { Constants } from "../../../../../common/utils/constants";

const Option = Select.Option;
function IndentFilters(props) {
  let { visible, setFilterModal, filters, filterData } = props;
  const { setFilterData, applyFilters, resetFilters, setFilters } = props;
  const [endOpen, setEndOpen] = useState(false);

  const [notFoundContentShippers, SetNotFoundContentShippers] = useState(
    "Start Typing to search (min 3 characters)..."
  );
  const [notFoundContentCusomters, SetNotFoundContentCustomers] = useState(
    "Start Typing to search (min 3 characters)..."
  );
  const [notFoundContentLocation, SetNotFoundContentLocation] = useState(
    "Start Typing to search (min 3 characters)..."
  );

  const disableEndDate = (endValue) => {
    const { validFrom } = filters;
    if (!endValue || !validFrom) {
      return false;
    }
    return endValue.valueOf() <= validFrom.valueOf();
  };
  const disableStartDate = (startValue) => {
    const { validTo } = filters;
    if (!startValue || !validTo) {
      return false;
    }
    return startValue.valueOf() >= validTo.valueOf();
  };
  const getParams = (value) => {
    return {
      entityIds: filters?.customerFilters?.map((c) => c.key),
      isActive: true,
      partialContentSearch: value,
    };
  };

  const resetFilterOptions = () => {
    setFilterData({
      locationData: [],
      customersData: [],
      shipperData: [],
    });
    SetNotFoundContentLocation("Start Typing to search (min 3 characters)...");
    SetNotFoundContentShippers("Start Typing to search (min 3 characters)...");
    SetNotFoundContentCustomers("Start Typing to search (min 3 characters)...");
  };

  const callShippers = _.debounce((value) => {
    if (value.trim() === "" || value.trim()?.length < 3) {
      SetNotFoundContentShippers(
        "Start Typing to search (min 3 characters)..."
      );
      return resetFilterOptions();
    }
    fetchShippers(getParams(value)).then((data) => {
      SetNotFoundContentShippers("No shippers found");
      setFilterData({
        ...filterData,
        shipperData: data?.entityShipperMappingBOList,
      });
    });
  }, 800);

  const callCustomers = _.debounce((value) => {
    if (value.trim() === "" || value.trim()?.length < 3) {
      SetNotFoundContentCustomers(
        "Start Typing to search (min 3 characters)..."
      );
      return resetFilterOptions();
    }
    fetchCustomers(getParams(value)).then((data) => {
      SetNotFoundContentCustomers("No Customer found");
      setFilterData({
        ...filterData,
        customersData: data?.customers,
      });
    });
  }, 800);

  const callLocations = _.debounce((value) => {
    if (value.trim() === "" || value.trim()?.length < 3) {
      SetNotFoundContentLocation(
        "Start Typing to search (min 3 characters)..."
      );
      return resetFilterOptions();
    }
    const params = {
      entityIds: [Constants.PARTNER_ID],
      isActive: true,
      entityTypes: ["PARTNER"],
      partialContentSearch: value,
    };
    fetchHubLocations(params).then((data) => {
      SetNotFoundContentLocation("No Locations found.");
      setFilterData({
        ...filterData,
        locationData: data?.locations,
      });
    });
  }, 800);

  const setFilteredData = (filterKey, filteredData) => {
    var tempFilters = { ...filters };
    tempFilters[filterKey] = filteredData;
    if (filterKey == "customerFilters" && filteredData?.length === 0) {
      tempFilters.shipperFilters = [];
      tempFilters.locationFilter = [];
    }
    setFilters(tempFilters);
  };
  return (
    <Modal
      visible={visible}
      width={"30%"}
      closable={true}
      onCancel={() => setFilterModal(false)}
      style={{ top: 20 }}
      footer={[
        <Button key="back" onClick={(_) => resetFilters()}>
          {" "}
          Reset{" "}
        </Button>,
        <Button key="submit" type="primary" onClick={applyFilters}>
          {" "}
          Apply{" "}
        </Button>,
      ]}
      title="Filters"
    >
      <div className="flex-box flex-column flex-gap-l contract-filters">
        <div>
          <div className="flex-box flex-column justify-content-between flex-gap-m">
            <div className="u-font-bold font-size-m-1">Customers</div>
            <Select
              mode={"multiple"}
              style={{ width: "100%" }}
              value={filters.customerFilters ? filters.customerFilters : []}
              onChange={(value) => {
                resetFilterOptions();
                setFilteredData("customerFilters", value);
              }}
              onSearch={callCustomers}
              notFoundContent={notFoundContentCusomters}
              showSearch={true}
              showArrow={true}
              placeholder={"Select Customer"}
              onBlur={resetFilterOptions}
              filterOption={(input, option) =>
                option.props.children
                  ?.toLowerCase()
                  .indexOf(input?.trim()?.toLowerCase()) >= 0
              }
              labelInValue={true}
            >
              {filterData?.customersData?.map((cust) => (
                <Option key={cust.id} value={cust.id}>
                  {cust.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="flex-box flex-column justify-content-between flex-gap-m">
            <div className="u-font-bold font-size-m-1">Shipper</div>
            <Select
              mode={"multiple"}
              style={{ width: "100%" }}
              value={filters.shipperFilters ? filters.shipperFilters : []}
              onChange={(value) => {
                resetFilterOptions();
                setFilteredData("shipperFilters", value);
              }}
              disabled={
                filters.customerFilters === null ||
                filters.customerFilters === undefined ||
                filters.customerFilters?.length === 0
              }
              onBlur={resetFilterOptions}
              onSearch={callShippers}
              notFoundContent={notFoundContentShippers}
              showSearch={true}
              showArrow={true}
              placeholder={"Select Shippers"}
              filterOption={(input, option) =>
                option.props.children
                  ?.toLowerCase()
                  .indexOf(input?.trim()?.toLowerCase()) >= 0
              }
              labelInValue={true}
            >
              {filterData?.shipperData?.map((shipper) => (
                <Option key={shipper.id} value={shipper.id}>
                  {shipper.shipperName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="flex-box flex-column justify-content-between flex-gap-m">
            <div className="u-font-bold font-size-m-1">Service Type</div>
            <div className="box-filters flex-box justify-content-between flex-gap-m">
              {filters?.serviceTypeData?.map((item, index) => (
                <div
                  key={index}
                  className={
                    item.value === false ? "boxType" : "boxTypeSelected"
                  }
                  onClick={(_) => {
                    let statusFilters = cloneObject(filters);
                    statusFilters["serviceTypeData"].map((status) => {
                      if (status.id === index) {
                        status.value = !status.value;
                      }
                    });
                    setFilteredData(
                      "serviceTypeData",
                      statusFilters["serviceTypeData"]
                    );
                  }}
                >
                  {item.storedValue}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          {/* <div className="flex-box flex-column justify-content-between flex-gap-m">
                        <div className="u-font-bold font-size-m-1">Pickup Location</div>
                        <Select
                            mode={'multiple'}
                            style={{ width: "100%" }}
                            value={filters.locationFilter ? filters.locationFilter: []}
                            onChange={value => {
                                resetFilterOptions();
                                setFilteredData('locationFilter', value)
                            }}
                            disabled={filters.customerFilters === null || filters.customerFilters === undefined || filters.customerFilters?.length === 0}
                            onBlur={resetFilterOptions}
                            onSearch={callLocations}
                            notFoundContent={notFoundContentLocation}
                            showSearch={true} showArrow={true}
                            placeholder={"Select Locations"}
                            filterOption={(input, option) => option.props.children?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0}
                            labelInValue={true}
                        >
                            {filterData?.locationData?.map(location => (
                                <Option key={location.id} value={location.id}>{location.name}</Option>
                            ))}
                        </Select>
                    </div> */}
          <div className="flex-box flex-column justify-content-between flex-gap-m">
            <div className="u-font-bold font-size-m-1">Hub Location</div>
            <Select
              mode={"multiple"}
              style={{ width: "100%" }}
              value={filters.locationFilter ? filters.locationFilter : []}
              onChange={(value) => {
                resetFilterOptions();
                setFilteredData("locationFilter", value);
              }}
              //   disabled={
              //     filters.customerFilters === null ||
              //     filters.customerFilters === undefined ||
              //     filters.customerFilters?.length === 0
              //   }
              onBlur={resetFilterOptions}
              onSearch={callLocations}
              notFoundContent={notFoundContentLocation}
              showSearch={true}
              showArrow={true}
              placeholder={"Select Hub Locations"}
              filterOption={(input, option) =>
                option.props.children
                  ?.toLowerCase()
                  .indexOf(input?.trim()?.toLowerCase()) >= 0
              }
              labelInValue={true}
            >
              {filterData?.locationData?.map((location) => (
                <Option key={location.id} value={location.id}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="flex-box justify-content-between flex-gap-m">
            <div className="flex-box flex-column justify-content-between flex-gap-m">
              <div className="u-font-bold font-size-m-1">Indent Date</div>
              <div>
                <DatePickerInput
                  disabledDate={disableStartDate}
                  type="date"
                  placeholder="From"
                  format="YYYY-MM-DD"
                  value={filters.validFrom ? filters.validFrom : undefined}
                  onChange={(value) => {
                    setFilteredData(
                      "validFrom",
                      value ? value.startOf("day") : undefined
                    );
                  }}
                  style={{ width: "100%" }}
                  onOpenChange={(open) => {
                    if (!open) setEndOpen(true);
                  }}
                />
              </div>
            </div>

            <div className="flex-box flex-column justify-content-between flex-gap-m">
              <div className="u-font-bold font-size-m-1">&nbsp;</div>
              <div>
                <DatePickerInput
                  disabledDate={disableEndDate}
                  type="date"
                  format="YYYY-MM-DD"
                  placeholder="To"
                  value={filters.validTo ? filters.validTo : undefined}
                  open={endOpen}
                  onChange={(value) => {
                    setFilteredData(
                      "validTo",
                      value ? value.endOf("day") : undefined
                    );
                  }}
                  style={{ width: "100%" }}
                  onOpenChange={(open) => setEndOpen(open)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default IndentFilters;
