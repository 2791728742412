import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Select, Input, Card, Icon, Button, Radio, Menu, DatePicker, Dropdown, Table } from "antd";
import L from 'leaflet';
import { Map, TileLayer, LayersControl, Marker, Popup, Tooltip, FeatureGroup } from 'react-leaflet'
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Control from 'react-leaflet-control';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Pins from '../../maps/Pins.js';
import 'leaflet/dist/leaflet.css';
import './AtlasTracking.scss'
import { carIcon, truckIcon, createCustomMarker, createPartnerMarker, createCustomerMarker, createCustomMarkerHtmlHelios } from '../../maps/CustomIcon'
import * as utils from '../../../common/utils/utils';
import { beeconGoogleMapKey, liveFeedToken } from "../../../environments/node-react-environment";
// import io from "socket.io-client";
// import VehicleSocket from '../../maps/VehicleSocket';
import ReactDOMServer from 'react-dom/server';
import callIcon from '../../../assets/img/call-icon.svg'
import { connect } from "react-redux";
import { Constants, validationRules } from '../../../common/utils/constants';
import { onSearchTrackingDataAction, getHeliosInitialDataAction, getHeliosCustomerBasedOnLocationAction, getHeliosFilteredDataAction, getHeliosListDataAction, onSearchByVehicle } from '../action';
import img from '../../../assets/img/spinner.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../ui/notification/Notification';
import { Mixpanel } from "../../../utils/mixpanel";

var spiderfyStartTime = "";
var lastUpdateTime = "";
var spiderfyLayer;
var spiderfyOpen = false;
var pendingUpdate = [];
var pendingLisUpdate = [];
var socket;
var isClustered = true;
var defaultStartDate = new Date();
defaultStartDate = new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), 1);
defaultStartDate.setHours(0, 0, 0, 0);
const defaultEndDate = new Date();
defaultEndDate.setHours(23, 59, 59, 999);
const dateFormat = "DD-MM-YYYY HH:mm";
const dateFormatCard = "DD-MM-YYYY HH:mm";
class AtlasTracking extends Component {

  constructor(props) {
    super(props)
    this.defaultPartnerId = Constants.PARTNER_ID;
    this.partnerIds = localStorage.getItem('loggedPartnerId');
    this.defaultLocationId = Constants.LOGGED_PARTNER_LOCATION_ID;
    this.state = {
      defaultLocationIds: [],
      markerIndex: '',
      curIndex: undefined,
      lat: 13.0827,
      lng: 80.2707,
      finalVehicles: [],
      liveFeedVehicles: [],
      vehicleRegNo: null,
      vehicleStatusValue: "",
      searchType: "vehicle",
      searchValue: "",
      tripType: ["FTL", "PTL"],
      filterType: 'outbound',
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      currentLocations: [],
      currentCustomers: [],
      address: {},
      tripStatusType: 'onTrip',
      mapView: true,
      partnerMarker: true,
      customerMarker: true,
      featureGroup: true,
      dataLoading: false,

      selectedFilter: "vehicle"

    }
    this.showHideMarkerClustering = this.showHideMarkerClustering.bind(this);
    this.onSelectLocationBlur = this.onSelectLocationBlur.bind(this);
    this.loadDefaultFilter = this.loadDefaultFilter.bind(this);
    this.onViewButtonClick = this.onViewButtonClick.bind(this);
    this.onGoToHistory = this.onGoToHistory.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearch = this.onSearch.bind(this);
    //this.onFilterSubmit = this.onFilterSubmit(this);
    this.markerRefs = [];
    this.listRefs = [];
    this.layerSpiderfied = false;
    this.isClustered = true;
  }
  showHideMarkerClustering(isClustered) {
    this.isClustered = isClustered;
    if (!utils.isEmpty(this.leafletMarkerCluster)) {
      if (isClustered) {
        this.leafletMarkerCluster.enableClustering();
      } else {
        this.leafletMarkerCluster.disableClustering();
      }
    }
  }
  onSearchType = e => {
    //console.log('radio checked', e.target.value);
    this.setState({
      searchType: e.target.value,
    });
  };
  onSearch = e => {
    //console.log('radio checked', e.target.value);
    this.setState({
      searchValue: e.target.value,
    });
  };
  onSearchClick = () => {
    if (this.state.searchValue) {
      // let data = {
      //   "tripId": this.state.searchType && this.state.searchType == 'tripid' ? this.state.searchValue : null,
      //   "vehicleNumber": this.state.searchType && this.state.searchType == 'vehicle' ? this.state.searchValue : null,
      //   "docketNumber": this.state.searchType && this.state.searchType == 'docket' ? this.state.searchValue : null
      // }
      Mixpanel.track("Vehicle Tracking Page Searched", { SearchValue: this.state.searchValue, SearchType: this.state.selectedFilter });
      if (this.state.selectedFilter === 'vehicle') {
        let data = {
          "vehicleNumber": this.state.searchValue.trim(),
          "pageNo": 0,
          "pageSize": 10
        }
        this.props.onSearchByVehicle(data)
      } else {
        let tripUrl = 'trip-' + this.state.searchValue.trim();
        this.props.history.push(`/appv2/tracking/dashboard/atlas-history-tracking/${tripUrl}`, { "navigator": "trips" });
      }

    }
  }
  onSelectFilterType(value) {
    //console.log("the select filter type",value)
    this.setState({
      filterType: value,
    });
  }
  onSelectLocation(value) {
    //console.log("the select Location",value)
    this.setState({
      currentLocations: value,
    });
  }
  onSelectCustomers(value) {
    //console.log("the select Customers",value)
    this.setState({
      currentCustomers: value,
    });
  }
  onSelectTripType(value) {
    //console.log("the select filter type",value)
    this.setState({
      tripType: value,
    });
  }
  onSelectLocationBlur(value) {
    this.props.getHeliosCustomerBasedOnLocation({ partnerId: this.defaultPartnerId, partners: [this.defaultPartnerId], locations: value });
  }
  onBlur(index) {
  }
  onFocus() {
  }
  onSearchInput(val) {
  }
  onGoToHistory(query) {
    this.props.history.push(`/appv2/tracking/dashboard/atlas-history-tracking/${query}`, { "navigator": "vehicle" });
  }
  onGoToTrip = query => {
    this.props.history.push(`/appv2/tracking/dashboard/atlas-history-tracking/${query}`, { navigator: 'trips' });
  }
  loadDefaultFilter() {
    this.setState({
      searchValue: "",
    });
    const filters = {
      "inbound": this.state.filterType && this.state.filterType == 'inbound' ? true : false,
      "outbound": this.state.filterType && this.state.filterType == 'outbound' ? true : false,
      "tripLocationIds": [this.defaultLocationId],
      "trackingRange": { "from": this.state.startDate.getTime(), "to": this.state.endDate.getTime() },
      "customerIds": [],
      "tripType": this.state.tripType
    }
    //console.log("the customers",filters)
    if (this.defaultPartnerId && this.defaultLocationId) {
      this.props.getHeliosInitialData({ partnerId: this.defaultPartnerId, locationId: this.defaultLocationId, filters: filters }).then(() => {
        this.setState({
          currentLocations: [Number(this.defaultLocationId)],
        })
      });
    }
  }
  onFilterSubmit() {
    //alert("hi")
    if (this.state.mapView) {
      const filters = {
        "inbound": this.state.filterType && this.state.filterType == 'inbound' ? true : false,
        "outbound": this.state.filterType && this.state.filterType == 'outbound' ? true : false,
        "tripLocationIds": this.state.currentLocations,
        "trackingRange": { "from": this.state.startDate.getTime(), "to": this.state.endDate.getTime() },
        "customerIds": this.state.currentCustomers,
        "tripType": this.state.tripType
      }
      //console.log("the customers",filters)
      if (this.defaultPartnerId && this.defaultLocationId) {
        this.props.getHeliosFilteredData({ partnerId: this.defaultPartnerId, locationId: this.defaultLocationId, filters: filters });
      }
    } else {
      this.props.getHeliosListData({ partnerId: this.defaultPartnerId, range: { "from": this.state.startDate.getTime(), "to": this.state.endDate.getTime() } })
    }
  }
  componentDidMount() {
    const user = JSON.parse(atob(localStorage.getItem("user")));
    const loggedPartnerId = localStorage.getItem("loggedPartnerId");
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name
    });
    Mixpanel.track("Vehicle Tracking Page Initiated", { ERPAtlasTrackingTime: moment().valueOf() });
    // this.loadDefaultFilter();
    // let _this = this;
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ username: 'loadshare@beecon.in', password: 'lsnbee@123' })
    // };
    // let loginUrl = "https://beecon.in/api/login";
    // if (window && window.location && window.location.protocol !== 'https:') {
    //   loginUrl = "http://beecon.in/api/login";
    // }
    // fetch(loginUrl, requestOptions)
    //   .then(response => response.json())
    //   .then(data => {
    //     //console.log("the data",data);
    //     const options = {
    //       forceNew: true,
    //       autoConnect: true,
    //       pingInterval: 6000,
    //       pingTimeout: 10000,
    //       transports: ['websocket'],
    //       agent: false, // [2] Please don't set this to true
    //       query: "token=" + data.token + "&deviceId=" + "profile_organization_J46SFFAE",
    //     };
    //     if (socket) {
    //       socket.disconnect();
    //       socket.close();
    //     }
    //     let url = "https://www.beecon.in";
    //     if (window && window.location && window.location.protocol !== 'https:') {
    //       url = "http://www.beecon.in";
    //     }
    //     socket = io.connect(url + "/webSocket", options);
    //     socket.on('connect', function (data) {
    //       //console.log("ON WEBSOCKET History Tracking");
    //       lastUpdateTime = new Date();
    //     });
    //     socket.on('error', function (data) {
    //       //console.log(data);
    //     });
    //     socket.on("vehicletrackingEvent", function (data) {
    //       if (data) {
    //         //console.log("the dd",_this.state.finalVehicles)
    //         //_this.onLiveFeedDataChange(data);
    //         /*this.setState({
    //           vehicles: data,
    //         });*/
    //         /*const vehicle_data = vehicleTrackingData[data.id]
    //         if(!isEmpty(vehicle_data)){
    //             //console.log("Updated vehicle "+vehicle_data.id+" from location "+vehicle_data.telematicData+" to location"+data.telematicData )
    //             vehicle_data.telematicData = data.telematicData
    //         }*/
    //       }
    //     });
    //     socket.on("vehicleTelematicEvent", function (data) {
    //       if (data) {
    //         //console.log("the socket dd",data)
    //         _this.onLiveFeedDataChange(data);
    //       }
    //     });
    //   });

  }
  handlePopupClose = (e) => {
    //console.log(e.popup)
    //console.log("Popup is closed")
  }
  vehicleList(data) {

  }
  handleMarkerRef = (index, node) => {
    this.markerRefs[index] = node;
  };
  handleListRef = (x, index, node) => {
    this.listRefs[x.heliosVehicleId] = node;
  };
  markerClick(index) {
    const leafletMap = (!utils.isEmpty(this.leafletMap) && !utils.isEmpty(this.leafletMap.leafletElement)) ? this.leafletMap.leafletElement : "";
    if (index) {
      if (this.markerRefs[index]) {
        this.markerRefs[index].leafletElement.openPopup();
      }
    }
  }
  getBounds = () => {
    const { heliosTrackingData } = this.props;
    let jsonData = [];
    if (heliosTrackingData) {
      for (const key in heliosTrackingData) {
        if (heliosTrackingData.hasOwnProperty(key)) {
          const element = heliosTrackingData[key];
          if (element && element.latitude && element.longitude) {
            jsonData.push([Number(element.latitude), Number(element.longitude)]);
          }
        }
      }
    }
    return jsonData;
  }
  onTripTypeChange(e) {
    //console.log(`radio checked:${e.target.value}`);
    this.setState({ tripStatusType: e.target.value })
  }
  onIgnitionChange = e => {
    this.setState({
      tripStatusType: e.target.value,
    });
  };
  onLiveFeedDataChange(data) {
    console.log(" the  onLiveFeedDataChange", data);
    if (data && data.id) {
      const { heliosTrackingData } = this.props;
      let existingIndex = utils.findArrayObjectIndex("heliosVehicleId", data.id, heliosTrackingData);
      if (existingIndex) {
        //console.log("the data",data)
        let existingData = heliosTrackingData[existingIndex];
        //console.log("the data",existingData)
        if (data && data.telematicData && data.telematicData.gps && data.telematicData.gps.location && data.telematicData.gps.location.lat && data.telematicData.gps.location.long) {
          data.vehicleLatitude = data.telematicData.gps.location.lat;
          data.vehicleLongitude = data.telematicData.gps.location.long;
          data.vehicleAngle = data.telematicData.gps.angle;
          if (data && data.telematicData && data.telematicData.ignition != 0) {
            data.igni = "ON";
          } else if (data && data.telematicData && data.telematicData.ignition == 0) {
            data.igni = "OFF";
          }
          existingData.latitude = data.telematicData.gps.location.lat;
          existingData.longitude = data.telematicData.gps.location.long;
          if (data && data.telematicData && data.telematicData.ignition != 0) {
            existingData.ignition = true;
          } else if (data && data.telematicData && data.telematicData.ignition == 0) {
            existingData.ignition = false;
          }
          if (data && data.telematicData && data.telematicData.gps && data.telematicData.gps.speed) {
            existingData.speed = data.telematicData.gps.speed;
          }
          if (data && data.telematicData && data.telematicData.unixTimeStamp) {
            existingData.lastKnownTime = data.telematicData.unixTimeStamp;
          }
        }
        if (!utils.isEmpty(pendingLisUpdate)) {
          let clonePendingList = [...pendingLisUpdate];
          pendingLisUpdate = [];
          clonePendingList.forEach(data => {
            if (this.listRefs[data.id]) {
              let inner = this.vehicleInfo(existingData);
              //this.listRefs[data.id].innerHTML =ReactDOMServer.renderToStaticMarkup(inner[0]);
            }
          });
        }
        if (this.listRefs[data.id]) {
          let inner = this.vehicleInfo(existingData);
          //console.log("the inner",inner)
          //this.listRefs[data.id].innerHTML =ReactDOMServer.renderToStaticMarkup(inner[0]);
        } else {
          pendingLisUpdate.push(data);
        }
        if (this.markerRefs[existingIndex]) {
          let regNo = existingData && existingData.assetBO && existingData.assetBO.vehicleNumber ? existingData.assetBO.vehicleNumber : "";
          if (!utils.isEmpty(spiderfyLayer)) {
            pendingUpdate.push({
              i: existingIndex,
              data: data
            });
            if (new Date() - lastUpdateTime > 10 * 1000 && !utils.isEmpty(pendingUpdate)) {
              if (!utils.isEmpty(spiderfyLayer)) {
                spiderfyLayer.unspiderfy();
              }
              spiderfyOpen = false;
              const clonePendingUpdate = [...pendingUpdate];
              pendingUpdate = [];
              for (const key in clonePendingUpdate) {
                if (clonePendingUpdate.hasOwnProperty(key)) {
                  const element = clonePendingUpdate[key];
                  if (!utils.isEmpty(element.i) && !utils.isEmpty(element.data)) {
                    this.markerRefs[element.i].leafletElement.setLatLng([element.data.vehicleLatitude, data.vehicleLongitude]);
                    this.markerRefs[existingIndex].leafletElement.setIcon(createCustomMarkerHtmlHelios(existingData.vehicleAngle ? existingData.vehicleAngle : 0, existingData.ignition, regNo));
                    this.markerRefs[existingIndex].leafletElement._popup.setLatLng([element.data.vehicleLatitude, element.data.vehicleLongitude]);
                    this.markerRefs[existingIndex].leafletElement._popup.setContent(`<div>${ReactDOMServer.renderToString(this.getPopUpContent(existingData))}</div>`);
                    if (!utils.isEmpty(this.leafletMarkerCluster)) {
                      this.leafletMarkerCluster.refreshClusters(this.markerRefs[existingIndex].leafletElement);
                    }
                  }
                }
              }
              lastUpdateTime = new Date();
              spiderfyOpen = true;
              spiderfyLayer = undefined;
              if (!utils.isEmpty(this.leafletMarkerCluster)) {
                this.leafletMarkerCluster.refreshClusters();
              }
              //this.leafletMarkerCluster.refreshClusters();
              //spiderfyLayer.spiderfy();
            }
          } else {
            this.markerRefs[existingIndex].leafletElement.setLatLng([data.vehicleLatitude, data.vehicleLongitude]);
            this.markerRefs[existingIndex].leafletElement.setIcon(createCustomMarkerHtmlHelios(existingData.vehicleAngle ? existingData.vehicleAngle : 0, existingData.ignition, regNo));
            this.markerRefs[existingIndex].leafletElement._popup.setLatLng([data.vehicleLatitude, data.vehicleLongitude]);
            this.markerRefs[existingIndex].leafletElement._popup.setContent(`<div>${ReactDOMServer.renderToString(this.getPopUpContent(existingData))}</div>`);
            if (!utils.isEmpty(this.leafletMarkerCluster)) {
              this.leafletMarkerCluster.refreshClusters(this.markerRefs[existingIndex].leafletElement);
              this.leafletMarkerCluster.refreshClusters();
            }
          }
        }
      }
    }
  }
  getPopUpContent = (x) => {
    let url = "";
    let regNo = x && x.assetBO && x.assetBO.vehicleNumber ? x.assetBO.vehicleNumber : "";
    return (<div id="fleet-home-iw" className="fleet-home-iw">
      <div id="regNo">
        <label>Reg No :&nbsp;</label>
        <strong><span><a onClick={() => this.childHandlerData(url)}>{regNo}</a>&nbsp;{!utils.isEmpty(x.isSimTracking) && x.isSimTracking && <img className="simIcon" src={require('../../../assets/img/sim-card.png')} />}</span></strong>
        <br />
      </div>
      <div id="name">
        <label>Name :&nbsp;</label>		<span>{x.assetBO && x.assetBO.vehicleType ? x.assetBO.vehicleType : "N/A"}</span><br /></div>
      <div id="telematicData_ignition_mod"><label>Ignition :&nbsp;</label><span><span className={(x.ignition) ? 'statusRunning1' : 'statusStopped1'}>{(x.ignition) ? "ON" : "OFF"}</span></span><br /></div>
      <div id="telematicData_speed_mod"><label>Speed :&nbsp;</label><span>{x.speed ? x.speed : 0} Km/hr</span><br /></div>
      <div id="telematicData_unixTimeStamp_mod"><label>Updated :&nbsp;</label><span>{moment(x.lastKnownTime).format(dateFormatCard)}&nbsp;</span><br /></div>
    </div>)
  }
  vehicleInfo(data) {
    let x = data;
    let url = "";
    let regNo = x && x.vehicleNumber ? x.vehicleNumber : "";
    let driverName = x && x.driverName ? x.driverName : "";
    let driverContact = x && x.contactPersonMobile ? x.contactPersonMobile : "";
    let vehicleStatus = x && x.status ? x.status : "";
    let sim = x.simTracker ? 'sim' : 'gps';
    url = 'vehicle-' + regNo + '-' + sim;
    let tripUrl = 'trip-' + x.tripId + '-' + sim;
    return (<div key={x.heliosVehicleId} className="helios-vehicle-card">
      <Row className="helios-card-row">
        <Col xs={24}>
          <p>
            <span className="helios-vehicle-num" onClick={() => { this.onGoToHistory(url) }}>{regNo}</span>
            <span className="helio-trip-status"><span className={x.onTrip ? 'helio-trip-status-icon-on' : 'helio-trip-status-icon-off'}></span>&nbsp;{x.onTrip ? 'On Trip' : 'Off Trip'}</span>
            <span className="helios-track-type">{x.isSimTracking ? 'Sim Tracking' : 'GPS'}</span>
          </p>
        </Col>
      </Row>
      <Row className="helios-card-row">
        <Col xs={12}>
          <label className="helios-label">Driver Name</label>
          <p className="helios-label-value">Jitu</p>
        </Col>
        <Col xs={12} className="helios-right">
          <img className="helios-call-icon" src={callIcon}></img>
        </Col>
      </Row>
      <Row className="helios-card-row">
        <Col xs={12}>
          <label className="helios-label">Trip ID</label>
          {x.tripId && <p className="helios-label-value helios-trip-id" onClick={(event) => { this.onGoToHistory(tripUrl) }}>{x.tripId} (FTL)</p>}
          {!x.tripId && <p className="helios-label-value">N/A</p>}
        </Col>
        <Col xs={12} className="helios-right">
          <label className="helios-label helios-right">Last Updated Time</label>
          <p className="helios-label-value helios-right">{moment(x.lastKnownTime).format(dateFormatCard)}</p>
        </Col>
      </Row>
      <Row className="helios-card-row">
        <Col xs={12}>
          <label className="helios-label">From</label>
          <p className="helios-label-value">{x.startLocation ? x.startLocation : 'N/A'}</p>
        </Col>
        <Col xs={12} className="helios-right">
          <label className="helios-label">Speed</label>
          <p className="helios-label-value">{x.speed ? x.speed + ' Km/hr' : 'N/A'}</p>
        </Col>
      </Row>
      <Row className="helios-card-bottom-row">
        <Col xs={12}>
          <p className="helios-vehicle-status">{vehicleStatus}</p>
        </Col>
        <Col xs={12} className="helios-right">
          <p className="helios-vehicle-ignition"><span></span>&nbsp; {x.ignition ? 'On' : 'Off'}</p>
        </Col>
      </Row></div>);
  }
  onViewButtonClick() {
    this.setState({ mapView: !this.state.mapView });
    //console.log("thee dsdsd",this.state.mapView)
    if (this.state.mapView) {
      //alert("the")
      this.props.getHeliosListData({ partnerId: this.defaultPartnerId, range: { "from": this.state.startDate.getTime(), "to": this.state.endDate.getTime() } })
    }
  }
  showCustomerLocationsOnly() {

  }
  showPartnersLocationOnly() {

  }
  onSelectDateRange(range, dateStrings) {
    if (range[0] && range[1]) {
      const valueOfInput1 = range[0].format();
      const valueOfInput2 = range[1].format();
      this.setState({ startDate: new Date(valueOfInput1), endDate: new Date(valueOfInput2) })
      //console.log('start date',valueOfInput1);
      //console.log("end date",valueOfInput2);
    }

  }
  tripFilterCheck(status, data) {
    /*if (!utils.isEmpty(data) && !utils.isEmpty(status) && status == 'onTrip') {
      return data.filter(x => x.onTrip);
    }else if (!utils.isEmpty(data) && !utils.isEmpty(status) && status =='offTrip') {
      return data.filter(x => !x.onTrip);
    }else {
      return data;
    }*/
    return data;
  }
  notifyCopyEvent = () => {
    openNotification({
      message: 'Copied',
      description: 'Phone number has been copied to clipboard',
      className: 'warning',
    })
  }
  vehicleList(data) {
    return data && data.map((x, key) => {
      let url = "";
      let regNo = x && x.vehicleNumber ? x.vehicleNumber : "";
      let driverName = x && x.driverName ? x.driverName : "";
      let driverContact = x && x.contactPersonMobile ? x.contactPersonMobile : "";
      let vehicleStatus = x && x.status ? x.status : "";
      let sim = x.isSimTracking ? 'sim' : 'gps';
      url = 'vehicle-' + regNo + '-' + sim;
      let tripUrl = 'trip-' + x.metaData.tripId + '-' + sim;

      const driverMenu = () => (
        <Menu>

          <Menu.Item key="1">
            <CopyToClipboard text={driverContact}
              onCopy={this.notifyCopyEvent}>
              <div>
                {driverContact}
                <Icon type="copy" />
              </div>
            </CopyToClipboard>
          </Menu.Item >

        </Menu >
      )
      /*if(x.onTrip){
        url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
      }*/
      return (<div key={x.heliosVehicleId} ref={this.handleListRef.bind(this, x, key)} className="helios-vehicle-card">
        <Row className="helios-card-row">
          <Col xs={24}>
            <p>
              <span className="helios-vehicle-num" onClick={() => { this.onGoToHistory(url) }}>{regNo}</span>

              <span className="helios-track-type">{x.isSimTracking ? 'Sim Tracking' : 'GPS'}</span>
            </p>
          </Col>
        </Row>
        <Row className="helios-card-row">
          <Col xs={12}>
            <label className="helios-label">Driver Name</label>
            <p className="helios-label-value">{driverName}</p>
          </Col>
          <Col xs={12} className="helios-right">

            <Dropdown overlay={driverMenu()} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {/* <Icon type="phone" /> */}
                <img className="helios-call-icon" src={callIcon}></img>
              </a>

            </Dropdown>
          </Col>
        </Row>
        <Row className="helios-card-row">
          <Col xs={12}>
            <label className="helios-label">Trip ID</label>
            {x.metaData.tripId && <p className="helios-label-value helios-trip-id" onClick={(event) => { this.onGoToTrip(tripUrl) }}>{x.metaData.tripId} ({x.metaData.tripType})</p>}
            {!x.metaData.tripId && <p>{x.onTrip ? 'On Trip' : 'Off Trip'}</p>}
            {/* {!x.tripId && <p className="helios-label-value">N/A</p>} */}
          </Col>
          <Col xs={12} className="helios-right">
            <label className="helios-label helios-right">Last Updated Time</label>
            <p className="helios-label-value helios-right">{x?.lastKnownLocation?.lastKnownTime ? moment(x?.lastKnownLocation?.lastKnownTime).format(dateFormatCard): 'NA'}</p>
          </Col>
        </Row>

        {/* <Row className="helios-card-bottom-row">
          <Col xs={12}>
            <p className="helios-vehicle-status">{vehicleStatus}</p>
          </Col>
          <Col xs={12} className="helios-right">
            <p className="helios-vehicle-ignition"><span><Icon type="key" style={{ color: '#2d9d78' }} /></span>&nbsp; {x?.lastKnownLocation?.ignition ? 'On' : 'Off'}</p>
          </Col>
        </Row> */}
      </div>)
    })
  }
  render() {

    const { Option } = Select;
    const { MonthPicker, RangePicker } = DatePicker;
    let defaultBounds = [[23.63936, 68.14712], [28.20453, 97.34466]];

    const columns = [
      {
        title: 'Date',
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: text => <p>{moment(Number(text)).format('MM/DD/YYYY')}</p>,
      },
      {
        title: 'Vehicle No',
        dataIndex: 'regNo',
        key: 'regNo',
      },
      {
        title: 'Driver Name',
        dataIndex: 'driverName',
        key: 'driverName',
      },
      {
        title: 'Vendor Name',
        dataIndex: 'vendorName',
        key: 'vendorName',
      },
      {
        title: 'Distance',
        dataIndex: 'distance',
        key: 'distance',
        render: text => <p>{text.toFixed(2) + ' Km'}</p>,
      },
      {
        title: 'Idle Time',
        dataIndex: 'idleDuration',
        key: 'idleDuration',
        render: text => <p>{utils.getTimeAsDuration(Number(text), "small")}</p>,
      },
      {
        title: 'Run Time',
        dataIndex: 'runTime',
        key: 'runTime',
        render: text => <p>{utils.getTimeAsDuration(Number(text), "small")}</p>,
      }
    ];
    //console.log("the dsddssdds",this.props)
    const { allClientsData, heliosTrackingData, heliosLocationData, heliosClientsData, heliosDistanceListData, onDataLoad } = this.props;

    const tableLength = heliosDistanceListData ? heliosDistanceListData.length : 0;
    const size = 'large'
    const filterTypes = [{ value: "inbound", display: "In Bound" }, { value: "outbound", display: "Out Bound" }];
    const tripTypes = [{ value: "FTL", display: "FTL" }, { value: "PTL", display: "PTL" }]
    //console.log("the user",this.user)
    var _this = this;
    let vehicleList;
    vehicleList = this.vehicleList(this.tripFilterCheck(this.state.tripStatusType, heliosTrackingData));
    const finalVehicleMarkers = heliosTrackingData && this.tripFilterCheck(this.state.tripStatusType, heliosTrackingData).map((x, key) => {

      let url = "";
      let regNo = x && x.vehicleNumber ? x.vehicleNumber : "";
      /*let url = x.id+'-'+regNo+(!utils.isEmpty(x.sim) && x.sim ? '-simtrack':'')+(!utils.isEmpty(x.contractId)? '-'+x.contractId:'');
      if(x.onTrip){
        url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
      }*/
      if (x && x?.lastKnownLocation && x?.lastKnownLocation?.latitude && x?.lastKnownLocation?.longitude) {
        return (
          <Marker
            key={key}
            position={[Number(x?.lastKnownLocation?.latitude), Number(x?.lastKnownLocation?.longitude)]}
            interactive={true}
            keyboard={true}
            ref={this.handleMarkerRef.bind(this, key)}
            onclick={() => { _this.markerClick(key) }}
            icon={createCustomMarkerHtmlHelios(x.vehicleAngle ? x.vehicleAngle : 0, x?.lastKnownLocation?.ignition, regNo)}
          >
            <Popup key={`popup-vehicle-${x.key}`} position={[Number(x?.lastKnownLocation?.latitude), Number(x?.lastKnownLocation?.longitude)]}>
              <div id="fleet-home-iw" className="fleet-home-iw">
                <div id="regNo">
                  <label>Reg No :&nbsp;</label>
                  <strong><span><a onClick={() => this.childHandlerData(url)}>{regNo}</a>&nbsp;{!utils.isEmpty(x?.lastKnownLocation?.isSimTracking) && x?.lastKnownLocation?.isSimTracking && <img className="simIcon" src={require('../../../assets/img/sim-card.png')} />}</span></strong>
                  <br />
                </div>
                <div id="name">
                  <label>Name :&nbsp;</label>		<span>{x.vehicleType ? x.vehicleType : "N/A"}</span><br /></div>
                <div id="telematicData_ignition_mod"><label>Ignition :&nbsp;</label><span><span className={(x?.lastKnownLocation?.ignition) ? 'statusRunning1' : 'statusStopped1'}>{(x?.lastKnownLocation?.ignition) ? "ON" : "OFF"}</span></span><br /></div>
                <div id="telematicData_speed_mod"><label>Speed :&nbsp;</label><span>{x?.lastKnownLocation?.speed ? x?.lastKnownLocation?.speed : 0} Km/hr</span><br /></div>
                <div id="telematicData_unixTimeStamp_mod"><label>Updated :&nbsp;</label><span>{moment(x?.lastKnownLocation?.lastKnownTime).format(dateFormatCard)}&nbsp;</span><br /></div>
              </div>
            </Popup>
          </Marker>
        )
      }

    });

    let isShowLoader = !heliosTrackingData;
    if (onDataLoad) {
      return (<Row><div className="noData"><img className="loading" src={img} alt="Loading" /></div></Row>)
    }


    if (heliosTrackingData && heliosTrackingData.length > 0) {

      // defaultBounds = [[23.63936, 68.14712], [28.20453, 97.34466]];
      let fitstIndex = heliosTrackingData[0];
      let lastIndex = heliosTrackingData[heliosTrackingData?.length - 1];

      if (!lastIndex?.lastKnownLocation) {
        lastIndex = fitstIndex
      }
      if (!fitstIndex?.lastKnownLocation) {
        defaultBounds = [[23.63936, 68.14712], [28.20453, 97.34466]];
      } else {
        defaultBounds = [];
        heliosTrackingData.map(tracking => {
          if (tracking?.lastKnownLocation) {
            defaultBounds.push(
              [tracking?.lastKnownLocation?.latitude, tracking?.lastKnownLocation?.longitude]
            )
          }

        })
        // defaultBounds = [[fitstIndex?.lastKnownLocation?.latitude, fitstIndex?.lastKnownLocation?.longitude], [lastIndex?.lastKnownLocation?.latitude, lastIndex?.lastKnownLocation?.longitude]];
      }

      // console.log("this.leafletMarkerCluster", this.leafletMarkerCluster)
      // this.leafletMarkerCluster?._map.getCenter();
      const map = this.leafletMarkerCluster?._map;  //get native Map instance
      const group = this.leafletMarkerCluster; //get native featureGroup instance

      if (!_.isEmpty(group?.getBounds())) {
        // map.setZoom(5)
        // console.log("group", map.getZoom())
        if (map.getZoom() > 12) {
          map.setZoom(12)
        }
      }



    }
    return (<>
      <Row>
        <Col xs={18}>
          <Row gutter={12}>
            <Col xs={5}>
              <Select
                showSearch
                placeholder="Select Filter"
                onSelect={(value) => {
                  this.setState({ selectedFilter: value })
                }}
                // size="large"
                value={this.state.selectedFilter}
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >

                <Option
                  value={"vehicle"}
                  label={"Vehicle No"}
                  key={"Vehicle No"}
                >
                  {"Vehicle No"}
                </Option>
                <Option
                  value={"trip"}
                  label={"Trip ID"}
                  key={"Trip ID"}
                >
                  {"Trip ID"}
                </Option>

              </Select>
            </Col>
            <Col xs={15}>
              <Input
                value={this.state.searchValue}
                disabled={!this.state.mapView ? true : false}
                placeholder={`Search by ${this.state.selectedFilter}`}
                onPressEnter={this.onSearchClick}
                onChange={(e) => this.onSearch(e)} />
            </Col>
            <Col xs={4}>
              <Button onClick={this.onSearchClick} className="helios-track">
                <Icon type="search" /> Track
              </Button>
            </Col>
          </Row>
          {/* <Radio.Group onChange={this.onSearchType} className="helios-search-type" style={{marginLeft:5}} value={this.state.searchType}>
              <Radio value={'vehicle'}>Vehicle</Radio>
              <Radio value={'docket'}>Docket No</Radio>
              <Radio value={'tripid'}>Trip ID</Radio>
          </Radio.Group> */}
        </Col>
        {/* <Col xs={6}>
          <div className="buttongroup">
           
            <Button size='large' onClick={this.onViewButtonClick} className="helios-list-view">{this.state.mapView ? 'List View' : 'Card View'}</Button>
            
          </div>
        </Col> */}
      </Row>

      {this.state.mapView && <Row style={{ marginTop: 15 }}>
        <Col xs={8} className="helios-list-column">
          {!utils.isEmpty(vehicleList) && vehicleList}
          {/* {utils.isEmpty(vehicleList) && <div className="noData">No Data Available</div>} */}
          {(utils.isEmpty(vehicleList)) && !this.props.heliosDataLoading && <Row><div className="noData">No Data Available</div></Row>}
          {(utils.isEmpty(vehicleList)) && this.props.heliosDataLoading && <Row><div className="noData">Loading Data...</div></Row>}
        </Col>
        <Col xs={16} className="helios-map-column">
          <div className="boxgooglemap">
            {!utils.isEmpty(heliosTrackingData) && heliosTrackingData.length > 0 ?
              <Map bounds={defaultBounds} style={{ height: 600 + 'px' }} ref={m => { this.leafletMap = m; }} onPopupClose={this.handlePopupClose}>
                <TileLayer url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png' />
                <MarkerClusterGroup
                  chunkedLoading={false}
                  maxClusterRadius={60}
                  animate={true}
                  ref={(markerClusterGroup) => { this.leafletMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement : ''); }}
                  showCoverageOnHover={false}
                // onClusterMouseOver={cluster => { lastUpdateTime = new Date(); spiderfyOpen = true; cluster.layer.spiderfy(); spiderfyLayer = cluster.target._spiderfied; }}
                // offClusterMouseOver={cluster => { }} onSpiderfied={cluster => { }}
                >
                  <FeatureGroup>
                    {finalVehicleMarkers}
                  </FeatureGroup>
                </MarkerClusterGroup>
                {/* <MarkerClusterGroup chunkedLoading={true} maxClusterRadius={60} animate={true} ref={(markerClusterGroup) => { this.leafletPartnerMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement : ''); }} showCoverageOnHover={false} onClusterMouseOver={cluster => { cluster.layer.spiderfy(); }}
                offClusterMouseOver={cluster => { }} onSpiderfied={cluster => { }}>
                {this.state.partnerMarker && heliosLocationData && heliosLocationData.map((x, key) => {
                  if (!utils.isEmpty(x) && !utils.isEmpty(x.lastKnownLocation.latitude) && !utils.isEmpty(x.lastKnownLocation.longitude)) {
                    return (<Marker key={`marker-partner-${x.id}`} position={[x.lastKnownLocation.latitude, x.lastKnownLocation.longitude]} icon={createPartnerMarker()}>
                      <Popup>
                        <span>{x.name}</span>
                      </Popup>
                    </Marker>);
                  }
                })}
              </MarkerClusterGroup> */}
                {/* <MarkerClusterGroup chunkedLoading={true} maxClusterRadius={60} animate={true} ref={(markerClusterGroup) => { this.leafletCustomerMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement : ''); }} showCoverageOnHover={false} onClusterMouseOver={cluster => { cluster.layer.spiderfy(); }}
                offClusterMouseOver={cluster => { }} onSpiderfied={cluster => { }}>
                {this.state.customerMarker && heliosClientsData && heliosClientsData.map((x, key) => {
                  console.log("x ->>>>>>>>", x)
                  let existingIndex = utils.findArrayObjectIndex("id", x.id, allClientsData);
                  if (existingIndex) {
                    let customer = allClientsData[existingIndex];
                    if (!utils.isEmpty(customer) && !utils.isEmpty(customer.lastKnownLocation.latitude) && !utils.isEmpty(customer.lastKnownLocation.longitude)) {
                      return (<Marker key={`marker-partner-${customer.id}`} position={[customer.lastKnownLocation.latitude, customer.lastKnownLocation.longitude]} icon={createCustomerMarker()}>
                        <Popup>
                          <span>{customer.locationName}</span>
                        </Popup>
                      </Marker>);
                    }
                  }
                })}
              </MarkerClusterGroup> */}
                <Control position="topleft" >
                  <button onClick={() => { this.showHideMarkerClustering(!this.isClustered) }} className="cluster-control"><Icon type="codepen" style={{ color: 'black' }}></Icon></button>
                </Control>
                {/* <Control position="topright" >
                  <button className="location-control-btn" onclick={this.showCustomerLocationsOnly.bind(this)}>Customers Locations</button>
                  <button className="location-control-btn" onclick={this.showPartnersLocationOnly.bind(this)}>Partners Locations</button>
              </Control> */}
                {/* <Control position="bottomleft" >
                  <button className="status-control-btn layer-all">All({heliosTrackingData?heliosTrackingData.length:0})</button>
                  <button className="status-control-btn">On</button>
              </Control> */}
              </Map> :
              <Map bounds={[[23.63936, 68.14712], [28.20453, 97.34466]]} style={{ height: 600 + 'px' }} ref={m => { this.leafletMap = m; }} onPopupClose={this.handlePopupClose}>
                <TileLayer url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png' />
                <MarkerClusterGroup
                  chunkedLoading={false}
                  maxClusterRadius={60}
                  animate={true}
                  ref={(markerClusterGroup) => { this.leafletMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement : ''); }}
                  showCoverageOnHover={false}
                // onClusterMouseOver={cluster => { lastUpdateTime = new Date(); spiderfyOpen = true; cluster.layer.spiderfy(); spiderfyLayer = cluster.target._spiderfied; }}
                // offClusterMouseOver={cluster => { }} onSpiderfied={cluster => { }}
                >

                </MarkerClusterGroup>
                {/* <MarkerClusterGroup chunkedLoading={true} maxClusterRadius={60} animate={true} ref={(markerClusterGroup) => { this.leafletPartnerMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement : ''); }} showCoverageOnHover={false} onClusterMouseOver={cluster => { cluster.layer.spiderfy(); }}
              offClusterMouseOver={cluster => { }} onSpiderfied={cluster => { }}>
              {this.state.partnerMarker && heliosLocationData && heliosLocationData.map((x, key) => {
                if (!utils.isEmpty(x) && !utils.isEmpty(x.lastKnownLocation.latitude) && !utils.isEmpty(x.lastKnownLocation.longitude)) {
                  return (<Marker key={`marker-partner-${x.id}`} position={[x.lastKnownLocation.latitude, x.lastKnownLocation.longitude]} icon={createPartnerMarker()}>
                    <Popup>
                      <span>{x.name}</span>
                    </Popup>
                  </Marker>);
                }
              })}
            </MarkerClusterGroup> */}
                {/* <MarkerClusterGroup chunkedLoading={true} maxClusterRadius={60} animate={true} ref={(markerClusterGroup) => { this.leafletCustomerMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement : ''); }} showCoverageOnHover={false} onClusterMouseOver={cluster => { cluster.layer.spiderfy(); }}
              offClusterMouseOver={cluster => { }} onSpiderfied={cluster => { }}>
              {this.state.customerMarker && heliosClientsData && heliosClientsData.map((x, key) => {
                console.log("x ->>>>>>>>", x)
                let existingIndex = utils.findArrayObjectIndex("id", x.id, allClientsData);
                if (existingIndex) {
                  let customer = allClientsData[existingIndex];
                  if (!utils.isEmpty(customer) && !utils.isEmpty(customer.lastKnownLocation.latitude) && !utils.isEmpty(customer.lastKnownLocation.longitude)) {
                    return (<Marker key={`marker-partner-${customer.id}`} position={[customer.lastKnownLocation.latitude, customer.lastKnownLocation.longitude]} icon={createCustomerMarker()}>
                      <Popup>
                        <span>{customer.locationName}</span>
                      </Popup>
                    </Marker>);
                  }
                }
              })}
            </MarkerClusterGroup> */}
                <Control position="topleft" >
                  <button onClick={() => { this.showHideMarkerClustering(!this.isClustered) }} className="cluster-control"><Icon type="codepen" style={{ color: 'black' }}></Icon></button>
                </Control>
                {/* <Control position="topright" >
                <button className="location-control-btn" onclick={this.showCustomerLocationsOnly.bind(this)}>Customers Locations</button>
                <button className="location-control-btn" onclick={this.showPartnersLocationOnly.bind(this)}>Partners Locations</button>
            </Control> */}
                {/* <Control position="bottomleft" >
                <button className="status-control-btn layer-all">All({heliosTrackingData?heliosTrackingData.length:0})</button>
                <button className="status-control-btn">On</button>
            </Control> */}
              </Map>
            }
            {/* {(utils.isEmpty(heliosTrackingData)) && !this.props.heliosDataLoading && <Row><div className="noData">No Data Available</div></Row>}
            {(utils.isEmpty(heliosTrackingData)) && this.props.heliosDataLoading && <Row><div className="noData">Loading Data...</div></Row>} */}
          </div>
        </Col>
      </Row>}
      {!this.state.mapView && <Row>
        <Col xs={24} style={{ marginTop: 15 }}>
          <Card bordered={false} className="TrackingDetails Card gutter">
            <Table footer={() => `${tableLength} Total`} size='middle' bordered columns={columns} dataSource={heliosDistanceListData} />
          </Card>
        </Col>
      </Row>}
    </>);
  }

}
const mapStateToProps = state => ({
  heliosDataLoading: state.tracking.heliosDataLoading,
  heliosTrackingData: state.tracking.heliosTrackingData,
  heliosLocationData: state.tracking.heliosLocationData,
  heliosClientsData: state.tracking.heliosClientsData,
  heliosClientsLocationData: state.tracking.heliosClientsLocationsData,
  heliosDistanceListData: state.tracking.heliosDistanceListData,
  onDataLoad: state.tracking.loadingHeliosTrack,
  allClientsData: state.tracking.heliosAllClientsData
});
const mapDispatchToProps = dispatch => ({
  getHeliosInitialData: param => dispatch(getHeliosInitialDataAction(param)),
  getHeliosCustomerBasedOnLocation: param => dispatch(getHeliosCustomerBasedOnLocationAction(param)),
  getHeliosFilteredData: param => dispatch(getHeliosFilteredDataAction(param)),
  getHeliosListData: param => dispatch(getHeliosListDataAction(param)),
  onSearchTrackingData: param => dispatch(onSearchTrackingDataAction(param)),
  onSearchByVehicle: data => dispatch(onSearchByVehicle(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AtlasTracking);
