import * as _Constants from './constants';
import RoleService from '../../../services/settings/role.service';
import { Constants } from '../../../common/utils/constants';
import { notifyApiError } from '../../../common/utils/utils';


const roleService = new RoleService

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const resetRoles = () => dispatch => {
    dispatch({
        type: _Constants.RESET_ROLES
    })
}

export const getRoles = () => dispatch => {
    dispatch(fetchRolesBegin());
    roleService.getRoles().then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchRolesSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchRolesFailure(res ?.data ?.response));
        }
    });
}

function fetchRolesBegin() {
    return {
        type: _Constants.FETCH_ROLES_BEGIN
    };
}

function fetchRolesSuccess(payload) {
    return {
        type: _Constants.FETCH_ROLES_SUCCESS,
        payload: payload
    };
}

function fetchRolesFailure(error) {
    return {
        type: _Constants.FETCH_ROLES_FAILURE,
        payload: error
    };
}

export const getRole = (id) => dispatch => {
    dispatch(fetchRoleBegin());
    roleService.getRole(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchRoleSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchRoleFailure(res ?.data ?.response));
        }
    });
}

function fetchRoleBegin() {
    return {
        type: _Constants.FETCH_ROLE_BEGIN
    };
}

function fetchRoleSuccess(payload) {
    return {
        type: _Constants.FETCH_ROLE_SUCCESS,
        payload: payload
    };
}

function fetchRoleFailure(error) {
    return {
        type: _Constants.FETCH_ROLE_FAILURE,
        payload: error
    };
}

export const createRole = (payload) => dispatch => {
    dispatch(modifyRoleBegin());
    roleService.createRole(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(modifyRoleSuccess(res ?.data ?.response));
            setTimeout(() => {
                dispatch(modifyRoleSuccess(null));
            }, 5000);
        } else {
            dispatch(modifyRoleFailure(res ?.data ?.response));
        }
    });
}

export const updateRole = (payload) => dispatch => {
    dispatch(modifyRoleBegin());
    roleService.updateRole(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(modifyRoleSuccess(res ?.data ?.response));
        } else {
            dispatch(modifyRoleFailure(res ?.data ?.response));
        }
    });
}

function modifyRoleBegin() {
    return {
        type: _Constants.MODIFY_ROLE_BEGIN
    };
}

function modifyRoleSuccess(payload) {
    return {
        type: _Constants.MODIFY_ROLE_SUCCESS,
        payload: payload
    };
}

function modifyRoleFailure(error) {
    return {
        type: _Constants.MODIFY_ROLE_FAILURE,
        payload: error
    };
}

export const getPermissions = () => dispatch => {
    dispatch(fetchPermissionsBegin());
    roleService.getPermissions().then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchPermissionsSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchPermissionsFailure(res ?.data ?.response));
        }
    });
}

function fetchPermissionsBegin() {
    return {
        type: _Constants.FETCH_PERMISSIONS_BEGIN
    };
}

function fetchPermissionsSuccess(payload) {
    return {
        type: _Constants.FETCH_PERMISSIONS_SUCCESS,
        payload: payload
    };
}

function fetchPermissionsFailure(error) {
    return {
        type: _Constants.FETCH_PERMISSIONS_FAILURE,
        payload: error
    };
}