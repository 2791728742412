import * as _Constants from "./constants";
import { notifyApiError } from '../../../common/utils/utils';
import CommunictionService from '../../../services/communication/communiction.service';
import { Constants } from "../../../common/utils/constants";
import CustomerService from '../../../services/settings/customer.service';

const communictionService = new CommunictionService;

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchMails = (payload, isReset) => dispatch => {
    dispatch(fetchMailsBegin());
    communictionService.fetchEmails(payload, Constants.SELECTED_APPLICATION.toLowerCase()).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchMailsSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchMailsFailure(res ?.data ?.response));
        }
    });
};

function fetchMailsBegin() {
    return {
        type: _Constants.FETCH_MAILS_BEGIN
    };
}

function fetchMailsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_MAILS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchMailsFailure(error) {
    return {
        type: _Constants.FETCH_MAILS_FAILURE,
        payload: error
    };
}

export const getCustomers = (payload) => dispatch => {
    dispatch(getCustomersBegin());
    CustomerService.getCustomersFetch(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(getCustomersSuccess(res ?.data ?.response));
        } else {
            dispatch(getCustomersFailure(res ?.data ?.response));
        }
    });
};

function getCustomersBegin() {
    return {
        type: _Constants.GET_CUSTOMERS_BEGIN
    };
}

function getCustomersSuccess(payload, isReset) {
    return {
        type: _Constants.GET_CUSTOMERS_SUCCESS,
        payload: payload,
        isReset
    };
}

function getCustomersFailure(error) {
    return {
        type: _Constants.GET_CUSTOMERS_FAILURE,
        payload: error
    };
}

export const sendMail = (payload) => dispatch => {
    dispatch(sendMailBegin());
    communictionService.sendEmail(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(sendMailSuccess(res ?.data ?.response.responses));
        } else {
            dispatch(sendMailFailure(res ?.data ?.response));
        }
    });
};

function sendMailBegin() {
    return {
        type: _Constants.SEND_MAIL_BEGIN
    };
}

function sendMailSuccess(payload, isReset) {
    return {
        type: _Constants.SEND_MAIL_SUCCESS,
        payload: payload,
        isReset
    };
}

function sendMailFailure(error) {
    return {
        type: _Constants.SEND_MAIL_FAILURE,
        payload: error
    };
}