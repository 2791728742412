import { Col, Icon, Tooltip, Input, Select, Alert, InputNumber } from "antd";
import React from "react";
import { dataGet, formatCurrency } from "../../../common/utils/utils";
import moment from "moment";
import ImageViewer from "./ImageViewer";
import editPencil from '../../../assets/img/icons/edit_box.svg';
import { CopyToClipboard } from "react-copy-to-clipboard";

export const formatDate = date =>
  moment(Number(date)).format("DD MMM YY hh:mm:ss");

export function getShipperColumn(
  isBool,
  shipperEditModes,
  shipperEvents,
  shippersData,
  locationData,
  destinations,
  shipperRefs
) {
  return [
    {
      label: "Customer Type",
      dataIndex: "customerType",
      key: 1
    },
    {
      label: "Shipper",
      dataIndex: "name",
      key: 2,
      render: (item, shipper) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipperEditModes?.shipper ? (
              <Select
                showSearch
                ref={shipperRefs.shipper}
                style={{ width: "100%" }}
                labelInValue
                onBlur={() => shipperEvents.disableShipperMode("shipper")}
                placeholder={"Select Shipper"}
                onChange={shipperEvents.updateShipper}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {shippersData.map(newShipper => (
                  <Select.Option
                    key={newShipper.id}
                    value={newShipper.id}
                    disabled={newShipper.id === shipper?.shipperId}
                  >
                    {newShipper.shipperName}
                  </Select.Option>
                ))}
              </Select>
            ) : (
                <span
                  style={{
                    wordWrap: "break-word",
                    color: shipper?.shipperIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {shipper?.name || "-"}
                </span>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipperEditModes?.shipper ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipperEvents?.enableShipperMode("shipper")}
              />
            ) : (


                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"

                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "Origin",
      dataIndex: "origin",
      key: 3,
      render: (item, shipper) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipperEditModes?.origin ? (
              <Select
                showSearch
                style={{ width: "100%" }}
                ref={shipperRefs.origin}
                labelInValue
                onBlur={() => shipperEvents.disableShipperMode("origin")}
                placeholder={"Select Origin"}
                onChange={shipperEvents.updateOrigin}
                optionLabelProp="label"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toString()
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {locationData["origin"]?.map(origin => (
                  <Select.Option
                    key={origin.id}
                    value={origin.id}

                    label={`${origin.name} (${origin.shortCode})`}
                    disabled={origin.id === shipper?.id}
                  >
                    {origin.name} ({origin.shortCode})
                  </Select.Option>
                ))}
              </Select>
            ) : (
                <span
                  style={{
                    wordWrap: "break-word",
                    color: shipper?.originIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {shipper?.origin || "-"}
                </span>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipperEditModes?.origin ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipperEvents?.enableShipperMode("origin")}
              />
            ) : (

                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"

                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "Origin City",
      dataIndex: "originCity",
      key: 4
    },
    {
      label: "Origin Pincode",
      dataIndex: "originPicnode",
      key: 5
    },
    {
      label: "Destination",
      dataIndex: "destination",
      key: 6,
      render: (item, shipper) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipperEditModes?.destination ? (
              <Select
                showSearch
                style={{ width: "100%" }}
                ref={shipperRefs.destination}
                labelInValue
                optionLabelProp="label"
                onBlur={() => shipperEvents.disableShipperMode("destination")}
                placeholder={"Select Destination"}
                onChange={shipperEvents.updateDestination}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toString()
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {destinations?.map(origin => (
                  <Select.Option
                    key={origin?.consignmentContactBO?.id}
                    value={origin?.consignmentContactBO?.id}
                    label={
                      `${origin?.consignmentContactBO?.name} (${origin?.contactCode})`
                    }
                    disabled={
                      origin?.consignmentContactBO?.id ===
                      shipper?.destinationID
                    }
                  >
                    <Tooltip
                      title={`${origin?.consignmentContactBO?.name} (${origin?.contactCode})`}
                    >
                      {origin?.consignmentContactBO?.name} (
                      {origin?.contactCode})
                    </Tooltip>
                  </Select.Option>
                ))}
              </Select>
            ) : (
                <span
                  style={{
                    wordWrap: "break-word",
                    color: shipper?.destinationInBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {shipper?.destination || "-"}
                </span>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipperEditModes?.destination ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipperEvents?.enableShipperMode("destination")}
              />
            ) : (

                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "Destination City",
      dataIndex: "destinationCity",
      key: 7
    },
    {
      label: "Destination PIN",
      dataIndex: "destinationPincode",
      key: 8
    }
  ];
}

export function getShipmentColumn(
  isBool,
  shipmentModes,
  shipmentEvents,
  goodsTypes,
  shipmentRefs
) {
  return [
    {
      label: "Total Weight",
      dataIndex: "totalWeight",
      key: 1,
      render: (item, shipment) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipmentModes?.tWeight ? (
              <>
                <InputNumber
                  ref={shipmentRefs.tWeight}
                  placeholder="Enter Total Weight"
                  min="0"
                  style={{ width: '100%' }}
                  defaultValue={shipment?.totalWeight}
                  onChange={value =>
                    shipmentEvents?.updateShipmentDetails(
                      "totalWeight",
                      value
                    )
                  }
                  onPressEnter={e => {
                    if (e.target.value && e.target.value <= shipment?.totalChargableWeight) {
                      shipmentEvents?.updateShipmentDetails(
                        "totalWeight",
                        e.target.value
                      );
                      shipmentEvents?.disableShipmentDetails("tWeight");
                    }
                  }}
                  suffix={"Kg"}
                  onBlur={(e) => {
                    if ((e.target.value || shipment?.totalWeight) && (e.target.value <= shipment?.totalChargableWeight || shipment?.totalWeight <= shipment?.totalChargableWeight)) {
                      shipmentEvents?.updateShipmentDetails(
                        "totalWeight",
                        e.target.value ?
                          (e.target.value > 1 ? e.target.value : 1)
                          : shipment?.totalWeight
                      );
                      shipmentEvents?.disableShipmentDetails("tWeight")
                    }

                  }
                  }
                />
                {
                  !shipment?.totalWeight &&
                  <Alert type="error" message="Field is mandatory" />
                }
                {
                  shipment?.totalChargableWeight < shipment?.totalWeight &&
                  <Alert type="error" message="total weight cannot be greater than chargeable weight" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: shipment?.totalWeightIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {shipment?.totalWeight || "-"} Kg
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipmentModes?.tWeight ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipmentEvents?.enableShipmentDetails("tWeight")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "Chargeable weight",
      dataIndex: "totalChargableWeight",
      key: 2,
      render: (item, shipment) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipmentModes?.cWeight ? (
              <>
                <InputNumber
                  ref={shipmentRefs.cWeight}
                  placeholder="Enter Chargeable Weight"
                  min="1"
                  style={{ width: '100%' }}
                  defaultValue={shipment?.totalChargableWeight}
                  onChange={value => {
                    shipmentEvents?.updateShipmentDetails(
                      "totalChargableWeight",
                      value > 1 ? value : 1
                    );

                  }

                  }
                  onPressEnter={e => {
                    if (e.target.value && e.target.value >= shipment?.totalWeight) {
                      shipmentEvents?.updateShipmentDetails(
                        "totalChargableWeight",
                        e.target.value > 1 ? e.target.value : 1
                      );
                      shipmentEvents?.disableShipmentDetails("cWeight");
                    }

                  }}
                  suffix={"Kg"}
                  // onBlur={() => shipmentEvents?.disableShipmentDetails("cWeight")}
                  onBlur={(e) => {
                    if ((e.target.value || shipment?.totalChargableWeight) && (e.target.value >= shipment?.totalWeight || shipment?.totalChargableWeight >= shipment?.totalWeight)) {
                      shipmentEvents?.updateShipmentDetails(
                        "totalChargableWeight",
                        e.target.value ?
                          (e.target.value > 1 ? e.target.value : 1)
                          : shipment?.totalChargableWeight
                      );
                      shipmentEvents?.disableShipmentDetails("cWeight")
                    }

                  }
                  }
                />
                {
                  shipment?.totalChargableWeight < shipment?.totalWeight &&
                  <Alert type="error" message="total weight cannot be greater than chargeable weight" />
                }
                {
                  !shipment?.totalChargableWeight &&
                  <Alert type="error" message="Field is mandatory" />
                }


              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: shipment?.totalChargableWeightIsBlue
                      ? "#1e6fe4"
                      : "#000000"
                  }}
                >
                  {shipment?.totalChargableWeight || "-"} Kg
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipmentModes?.cWeight ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipmentEvents?.enableShipmentDetails("cWeight")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "No of Pcs",
      dataIndex: "totalShipmentCount",
      key: 3,
      render: (item, shipment) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipmentModes?.tCount ? (
              <>
                <InputNumber
                  ref={shipmentRefs.tCount}
                  placeholder="Enter No of Pcs"
                  style={{ width: '100%' }}
                  defaultValue={shipment?.totalShipmentCount}
                  onChange={value =>
                    shipmentEvents?.updateShipmentDetails(
                      "totalShipmentCount",
                      value
                    )
                  }
                  onPressEnter={e => {
                    shipmentEvents?.updateShipmentDetails(
                      "totalShipmentCount",
                      e.target.value
                    );
                    shipmentEvents?.disableShipmentDetails("tCount");
                  }}
                  onBlur={(e) => {
                    if (e.target.value || shipment?.totalShipmentCount) {
                      shipmentEvents?.updateShipmentDetails(
                        "totalShipmentCount",
                        e.target.value ? e.target.value : shipment?.totalShipmentCount
                      );
                      shipmentEvents?.disableShipmentDetails("tCount")
                    }

                  }}
                />
                {
                  !shipment?.totalShipmentCount &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: shipment?.totalShipmentCountIsBlue
                      ? "#1e6fe4"
                      : "#000000"
                  }}
                >
                  {shipment?.totalShipmentCount || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipmentModes?.tCount ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipmentEvents?.enableShipmentDetails("tCount")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "Goods Type",
      dataIndex: "goodsType",
      key: 4,
      render: (item, shipment) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {shipmentModes?.goods ? (
              <Select
                showSearch
                ref={shipmentRefs.goods}
                style={{ width: "100%" }}
                labelInValue
                optionLabelProp="label"
                allowClear
                onBlur={() => shipmentEvents.disableShipmentDetails("goods")}
                placeholder={"Select Goods Type"}
                onChange={shipmentEvents.updateGoodsType}
                optionFilterProp="label"
                filterOption={(input, option) => 
                  option.props.label?.toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Select.Option
                  key={-1}
                  value={-1}
                  label={"Select Goods type"}
                  disabled={
                    !shipment.allowSelectingEmpty ? true :
                      shipment.allowSelectingEmpty && (_.isUndefined(shipment?.goodsId) || shipment?.goodsId === -1) ? true : false
                  }
                >
                  Select Goods type
                </Select.Option>
                {goodsTypes?.map(
                  goods =>
                    goods?.isActive && (
                      <Select.Option
                        key={goods?.id}
                        value={goods?.id}
                        label={goods?.code}
                        disabled={goods?.id == shipment?.goodsId}
                      >
                        <Tooltip title={`${goods?.code}`}>
                          {goods?.code}
                        </Tooltip>
                      </Select.Option>
                    )
                )}

              </Select>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: shipment?.goodsTypeIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {shipment?.goodsType || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !shipmentModes?.goods ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => shipmentEvents?.enableShipmentDetails("goods")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    }
  ];
}

export function getShipmentTableColumns() {
  return [
    {
      title: "Total Box Type",
      dataIndex: "noOfBoxes",
      key: "noOfBoxes"
    },
    {
      title: "No of pcs",
      dataIndex: "noOfPcs",
      key: "noOfPcs"
    }
  ];
}

export function getShipmentBoxTypeTableColumns(
  isBool,
  boxTypeEditModes,
  boxTypeEvents
) {
  return [
    {
      title: "Box Type",
      dataIndex: "id",
      key: "id",
      width: "50%",
      render: (data, row, index) => {

        return (
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={"Select Box"}
            // disabled={!boxTypeEditModes?.count?.includes(row.id)}
            onChange={val => {

              boxTypeEvents.updateBoxType(
                "id",
                boxTypeEvents.getBoxTypes.find(item => item.id === val),
                // boxTypeEvents.boxTypesList.findIndex(item => item.id === val),
                index,
                row.count
              )

            }

            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={data}
          >
            {boxTypeEvents.getBoxTypes.map(box => (
              <Select.Option
                key={box.id}
                value={box.id}
                disabled={box.id === row?.id || !!boxTypeEvents.boxTypesList.find(item => item.id === box?.id)}
              >
                {box.name}
              </Select.Option>
            ))}
          </Select>
        );
      }
    },
    {
      title: "No of Boxes",
      dataIndex: "count",
      key: "count",
      width: "50%",
      render: (data, row) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            {boxTypeEditModes?.count?.includes(row.id) ? (
              <InputNumber
                placeholder="Enter Shipment Identification Code"
                type="number"
                min={1}
                defaultValue={data}
                onChange={e =>
                  boxTypeEvents?.updateBoxType(
                    "id",
                    { ...row, count: Number(e) },
                    boxTypeEvents.boxTypesList.findIndex(
                      item => item.id === row.id
                    ),
                    data
                  )
                }
                onPressEnter={e => {
                  if (e.target.value > 0) {
                    boxTypeEvents?.updateBoxType(
                      "id",
                      { ...row, count: Number(e.target.value) },
                      boxTypeEvents.boxTypesList.findIndex(
                        item => item.id === row.id
                      )
                    );
                    boxTypeEvents?.disableBoxType("count", row.id);
                  }
                }

                }

                onBlur={e => {
                  if (e.target.value > 0) {
                    boxTypeEvents?.updateBoxType(
                      "id",
                      { ...row, count: Number(e.target.value) },
                      boxTypeEvents.boxTypesList.findIndex(
                        item => item.id === row.id
                      )
                    );
                    boxTypeEvents?.disableBoxType("count", row.id);
                  }
                }}
                // onBlur={() => boxTypeEvents?.disableBoxType("count", row.id)}
                style={{ width: "85%" }}
              />
            ) : (
                data || "-"
              )}
            {isBool && !boxTypeEditModes?.count?.includes(row.id) ? (


              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => boxTypeEvents?.enableBoxType("count", row.id)}
              />
            ) : (

                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />

              )}
          </div>
        );
      }
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (data, row) => (
        <Icon
          type="minus-circle"
          style={{ color: "red" }}
          onClick={e => boxTypeEvents.removeBoxType(row)}
          theme="filled"
        />
      )
    }
  ];
}

export function getOtherDetails(isBool, otherEditModes, otherEvents, show3PLDocket, consignmentFilterBO, otherRefs, allowedThreePL) {

  return [
    {
      label: "Shipment Identification Code",
      dataIndex: "shipmentIdentifier",
      key: 1,
      render: (item, other) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {otherEditModes?.identifier ? (
              <>
                <Input
                  placeholder="Enter Shipment Identification Code"
                  ref={otherRefs.identifier}
                  defaultValue={other?.shipmentIdentifier}
                  onChange={e =>
                    otherEvents?.updateOtherDetails(
                      "shipmentIdentifier",
                      e.target.value
                    )
                  }
                  onPressEnter={e => {
                    otherEvents?.updateOtherDetails(
                      "shipmentIdentifier",
                      e.target.value
                    );
                    otherEvents?.disableOtherDetails("identifier");
                  }}
                  onBlur={(e) => {
                    if (e.target.value || other?.shipmentIdentifier) {
                      otherEvents?.updateOtherDetails(
                        "shipmentIdentifier",
                        e.target.value
                      );
                      otherEvents?.disableOtherDetails("identifier")
                    }

                  }
                  }
                />
                {
                  !other?.shipmentIdentifier &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: other?.shipmentIdentifierIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {other?.shipmentIdentifier || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && otherEditModes?.identifier === false ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => {
                  console.log("i am in enable", other?.shipmentIdentifier, otherEditModes?.identifier)
                  otherEvents?.enableOtherDetails("identifier")
                }

                }
              />

            ) : (
                <img
                  src={editPencil}
                  className={
                    "docket-verify-right-shipper-details-edit-disabled"
                  }
                // onClick={
                //   () => {
                //     console.log("I am clicked here", (isBool && otherEditModes?.identifier && !_.isEmpty(other?.shipmentIdentifier)));

                //     (isBool && otherEditModes?.identifier && !_.isEmpty(other?.shipmentIdentifier)) &&
                //       otherEvents?.disableOtherDetails("identifier")
                //   }
                // }
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "Reference Number",
      dataIndex: "consignmentFilterBO",
      key: 2,
      render: (item, other) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {otherEditModes?.threePl ? (
              <>
                <Input
                  placeholder="Reference Number"
                  ref={otherRefs.threePl}
                  defaultValue={consignmentFilterBO?.threePlReferenceNumber}
                  onChange={e =>
                    otherEvents?.updateThreePL(
                      "threePlReferenceNumber",
                      e.target.value
                    )
                  }
                  onPressEnter={e => {
                    otherEvents?.updateOtherDetails(
                      "threePlReferenceNumber",
                      e.target.value
                    );
                    otherEvents?.disableOtherDetails("threePl");
                  }}
                  onBlur={(e) => {
                    if (show3PLDocket && (e.target.value || consignmentFilterBO?.threePlReferenceNumber)) {
                      otherEvents?.updateThreePL(
                        "threePlReferenceNumber",
                        e.target.value
                      )
                      otherEvents?.disableOtherDetails("threePl")
                    } else {
                      otherEvents?.updateThreePL(
                        "threePlReferenceNumber",
                        e.target.value
                      )
                      otherEvents?.disableOtherDetails("threePl")
                    }

                  }}
                />
                {
                  show3PLDocket && !consignmentFilterBO?.threePlReferenceNumber &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: consignmentFilterBO?.threePlReferenceNumberIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {consignmentFilterBO?.threePlReferenceNumber || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {allowedThreePL && !otherEditModes?.threePl ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => otherEvents?.enableOtherDetails("threePl")}
              />

            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "RDS Code",
      dataIndex: "RDSCode",
      key: 3,
      render: (item, other) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {otherEditModes?.rdsCode ? (
              <>
                <Input
                  ref={otherRefs.rdsCode}
                  placeholder="Enter RDS Code"
                  defaultValue={other?.RDSCode}
                  onChange={e =>
                    otherEvents?.updateOtherDetails("RDSCode", e.target.value)
                  }
                  onPressEnter={e => {
                    otherEvents?.updateOtherDetails("RDSCode", e.target.value);
                    otherEvents?.disableOtherDetails("rdsCode");
                  }}
                  onBlur={(e) => {
                    if (e.target.value || other?.RDSCode) {
                      otherEvents?.updateOtherDetails("RDSCode", e.target.value ? e.target.value : other?.RDSCode)
                      otherEvents?.disableOtherDetails("rdsCode")
                    }

                  }}
                />
                {
                  !other?.RDSCode &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: other?.RDSCodeIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {other?.RDSCode || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !otherEditModes?.rdsCode ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => {
                  otherEvents?.enableOtherDetails("rdsCode");
                }}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "RDS name",
      dataIndex: "RDSName",
      key: 4,
      render: (item, other) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {otherEditModes?.rdsName ? (
              <>
                <Input
                  placeholder="Enter RDS Name"
                  ref={otherRefs.rdsName}
                  defaultValue={other?.RDSName}
                  onChange={e =>
                    otherEvents?.updateOtherDetails("RDSName", e.target.value)
                  }
                  onPressEnter={e => {
                    otherEvents?.updateOtherDetails("RDSName", e.target.value);
                    otherEvents?.disableOtherDetails("rdsName");
                  }}
                  onBlur={(e) => {
                    if (e.target.value || other?.RDSName) {
                      otherEvents?.updateOtherDetails("RDSName", e.target.value ? e.target.value : other?.RDSName)
                      otherEvents?.disableOtherDetails("rdsName")
                    }

                  }
                  }
                />
                {
                  !other?.RDSName &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: other?.RDSNameIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {other?.RDSName || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !otherEditModes?.rdsName ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => otherEvents?.enableOtherDetails("rdsName")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "RDS Pincode",
      dataIndex: "RDSPinCode",
      key: 5,
      render: (item, other) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {otherEditModes?.rdsPin ? (
              <>
                <Input
                  placeholder="Enter RDS Pincode"
                  ref={otherRefs.rdsPin}
                  defaultValue={other?.RDSPinCode}
                  onChange={e =>
                    otherEvents?.updateOtherDetails("RDSPinCode", e.target.value)
                  }
                  onPressEnter={e => {
                    otherEvents?.updateOtherDetails("RDSPinCode", e.target.value);
                    otherEvents?.disableOtherDetails("rdsPin");
                  }}
                  onBlur={(e) => {
                    if (e.target.value || other?.RDSPinCode) {
                      otherEvents?.updateOtherDetails("RDSPinCode", e.target.value ? e.target.value : other?.RDSPinCode)
                      otherEvents?.disableOtherDetails("rdsPin")
                    }

                  }
                  }
                />
                {
                  !other?.RDSPinCode &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: other?.RDSPinCodeIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {other?.RDSPinCode || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !otherEditModes?.rdsPin ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => otherEvents?.enableOtherDetails("rdsPin")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      label: "RDS City",
      dataIndex: "RDSCity",
      key: 6,
      render: (item, other) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {otherEditModes?.rdsCity ? (
              <>
                <Input
                  placeholder="Enter RDS City"
                  ref={otherRefs.rdsCity}
                  defaultValue={other?.RDSCity}
                  onChange={e =>
                    otherEvents?.updateOtherDetails("RDSCity", e.target.value)
                  }
                  onPressEnter={e => {
                    otherEvents?.updateOtherDetails("RDSCity", e.target.value);
                    otherEvents?.disableOtherDetails("rdsCity");
                  }}
                  onBlur={(e) => {
                    if (e.target.value || other?.RDSCity) {
                      otherEvents?.updateOtherDetails("RDSCity", e.target.value ? e.target.value : other?.RDSCity)
                      otherEvents?.disableOtherDetails("rdsCity")
                    }
                  }
                  }
                />
                {
                  !other?.RDSCity &&
                  <Alert type="error" message="Field is mandatory" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: other?.RDSCityIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {other?.RDSCity || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !otherEditModes?.rdsCity ? (
              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => otherEvents?.enableOtherDetails("rdsCity")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    }
  ];
}

export function getUserDetails(isBool, notifyEvent) {
  return [
    {
      label: "Created By",
      dataIndex: "createdBy",
      key: 1
    },
    {
      label: "Mobile No.",
      dataIndex: "mobileID",
      key: 2,
      render: (item, user) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            <b style={{ wordWrap: "break-word" }}>{user?.mobileID || "-"}</b>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={user?.mobileID}
              onCopy={() => notifyEvent("Mobile Number")}
            >
              <Icon type="copy" />
            </CopyToClipboard>
          </Col>
        </>
      )
    },
    {
      label: "Mail ID",
      dataIndex: "mailID",
      key: 3,
      render: (item, user) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            <b style={{ wordWrap: "break-word" }}>{user?.mailID || "-"}</b>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={user?.mailID}
              onCopy={() => notifyEvent("Email")}
            >
              <Icon type="copy" />
            </CopyToClipboard>
          </Col>
        </>
      )
    }
  ];
}

export function getFinanceDetails(isBool, financeEditModes, financeEvents, financeRefs) {
  return [
    {
      isShown: true,
      label: "Freight Amount",
      dataIndex: "totalAmount",
      key: 1,
      render: (item, finance) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {financeEditModes?.totalAmount ? (
              <>
                <InputNumber
                  ref={financeRefs.totalAmount}
                  placeholder="Enter Freight Amount"
                  style={{ width: '100%' }}
                  defaultValue={finance?.totalAmount}
                  onChange={value =>
                    financeEvents?.updateFinanceDetails("totalAmount", value)
                  }
                  onPressEnter={e => {
                    const value = e.target.value;
                    if (value) {
                      if ((finance?.paymentType === 'TO_PAY' || finance?.paymentType === 'TOPAY') && value > Number(finance?.collectedAmount)) {
                        financeEvents?.updateFinanceDetails("totalAmount", value);
                        financeEvents?.disableFinanceDetails("totalAmount");
                      } else {
                        financeEvents?.updateFinanceDetails("totalAmount", value);
                        financeEvents?.disableFinanceDetails("totalAmount");
                      }
                    }

                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    console.log("value", value, finance?.collectedAmount)
                    if (value && (finance?.paymentType === 'TO_PAY' || finance?.paymentType === 'TOPAY') && value > Number(finance?.collectedAmount ? finance?.collectedAmount : 0)) {
                      financeEvents?.updateFinanceDetails("totalAmount", value);
                      financeEvents?.disableFinanceDetails("totalAmount")
                    }

                    else {
                      financeEvents?.updateFinanceDetails("totalAmount", value);
                      financeEvents?.disableFinanceDetails("totalAmount");
                    }

                  }}
                />
                {
                  !finance?.totalAmount &&
                  <Alert type="error" message="Field is mandatory" />
                }
                {
                  (finance?.paymentType === 'TO_PAY' || finance?.paymentType === 'TOPAY') && finance?.totalAmount && Number(finance?.collectedAmount) >= Number(finance?.totalAmount) &&
                  <Alert type="error" message="Advance Amount should be less than Freight Amount" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: finance?.totalAmountIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {formatCurrency(Number(finance?.totalAmount)) || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !financeEditModes?.totalAmount ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => financeEvents?.enableFinanceDetails("totalAmount")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    },
    {
      isShown: true,
      label: "Advance Amount",
      dataIndex: "collectedAmount",
      key: 2,
      render: (item, finance) => (
        <>
          <Col span={10} className="docket-verify-right-shipper-details-title">
            {item.label}:
          </Col>
          <Col
            span={12}
            className="docket-verify-right-shipper-details-dataIndex"
          >
            {financeEditModes?.collectedAmount ? (
              <>
                <InputNumber
                  style={{ width: '100%' }}
                  ref={financeRefs.collectedAmount}
                  placeholder="Enter Advance Amount"
                  defaultValue={finance?.collectedAmount}
                  onChange={value =>
                    financeEvents?.updateFinanceDetails("collectedAmount", value)
                  }
                  onPressEnter={e => {
                    const value = e.target.value;
                    if ((finance?.paymentType === 'TO_PAY' || finance?.paymentType === 'TOPAY') && value < Number(finance?.totalAmount)) {
                      financeEvents?.updateFinanceDetails("collectedAmount", value);
                      financeEvents?.disableFinanceDetails("collectedAmount");
                    } else {
                      financeEvents?.updateFinanceDetails("collectedAmount", value);
                      financeEvents?.disableFinanceDetails("collectedAmount");
                    }

                  }}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if ((finance?.paymentType === 'TO_PAY' || finance?.paymentType === 'TOPAY') && value < Number(finance?.totalAmount)) {
                      financeEvents?.updateFinanceDetails("collectedAmount", value);
                      financeEvents?.disableFinanceDetails("collectedAmount")
                    } else {
                      financeEvents?.updateFinanceDetails("collectedAmount", value);
                      financeEvents?.disableFinanceDetails("collectedAmount")
                    }

                  }}
                />
                {
                  !finance?.collectedAmount &&
                  <Alert type="error" message="Field is mandatory" />
                }
                {
                  (finance?.paymentType === 'TO_PAY' || finance?.paymentType === 'TOPAY') && finance?.collectedAmount && Number(finance?.collectedAmount) >= Number(finance?.totalAmount) &&
                  <Alert type="error" message="Advance Amount should be less than Freight Amount" />
                }
              </>
            ) : (
                <b
                  style={{
                    wordWrap: "break-word",
                    color: finance?.collectedAmountIsBlue ? "#1e6fe4" : "#000000"
                  }}
                >
                  {formatCurrency(Number(finance?.collectedAmount)) || "-"}
                </b>
              )}
          </Col>
          <Col span={2}>
            {isBool && !financeEditModes?.collectedAmount ? (

              <img
                src={editPencil}
                className="docket-verify-right-shipper-details-edit"
                onClick={() => financeEvents?.enableFinanceDetails("collectedAmount")}
              />
            ) : (
                <img
                  src={editPencil}
                  className="docket-verify-right-shipper-details-edit-disabled"
                />
              )}
          </Col>
        </>
      )
    }
  ];
}

export function getFinanceTableColumns() {
  return [
    {
      title: "No of invoice",
      dataIndex: "noOfInvoices",
      key: "noOfInvoices"
    },
    {
      title: "E-waybills",
      dataIndex: "ewaybills",
      key: "ewaybills"
    },
    {
      title: "Total Invoice Value",
      dataIndex: "totalInvoiceValue",
      key: "totalInvoiceValue",
      render: (data) => formatCurrency(data)
    }
  ];
}

export function getInvoiceColumns() {
  return [
    {
      title: 'Invoice No',
      width: '15%',
      dataIndex: 'invoiceNumber',
      isRightBorder: true,
      colSpan: 3
    },
    {
      title: 'Invoice Value',
      width: '15%',
      dataIndex: 'invoiceValue',
      isRightBorder: true,
      colSpan: 4
    },
    {
      title: 'Ewaybill No',
      dataIndex: 'eway',
      width: '15%',
      isRightBorder: true,
      colSpan: 4
    },
    {
      title: 'Invoice Proof',
      dataIndex: 'consignmentVerificationDocumentMappingss',
      width: '20%',
      isRightBorder: true,
      colSpan: 6
    },
    {
      title: 'Reject reason',
      dataIndex: 'rejected',
      width: '15%',
      isRightBorder: true,
      colSpan: 3
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      width: '15%',
      isRightBorder: false,
      colSpan: 4
    },

  ]
}

export function getHistoryTable() {
  return [
    {
      title: 'Updated Date & Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Changes details',
      dataIndex: 'message',
      key: 'message'
    }
  ]
}

export const SAVED_ITEMS = {
  "shipper": "Shipper",
  "rdsCity": "RDS City",
  "rdsPincode": "RDS Pincode",
  "rdsName": "RDS Name",
  "rdsCode": "RDS Code",
  "threePl": "Reference Number",
  "shipmentIdentificationCode": "Shipment Code",
  "amountCollected": "Advance Amount",
  "totalAmount": "Freight Amount",
  "boxType": "Box Type",
  "goodsType": "Goods Type",
  "noOfPieces": "No of Pieces",
  "chargeableWeight": "Chargeable Weight",
  "actualWeight": "Total Weight",
  "destination": "Destination",
  "origin": "Origin",
  "paymentType": "Payment Type"
}