import * as apiUtils from '../../../../../common/utils/apiGateway';
import { BASE_URL_TYPES, Constants } from '../../../../../common/utils/constants';
import { checkHttpStatus, notifyApiError } from '../../../../../common/utils/utils';

export const fetchLaneDetails = contractId => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url: `/b2b/v1/contracts/lanes?contractId=${contractId}`,
        method: "GET",
        showMessage: false
    }).
    then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            const { laneBO } = response.data.response;
            if (!laneBO) {
                reject(response.data);
            }
            else {
                resolve(laneBO);
            }
        }
        else {
            reject(response.data);
        }
    })
})

export const postLanes = (lanesBO, noContractLane) => new Promise( (resolve, reject) => {
    if (noContractLane) {
        lanesBO.laneBO["partnerId"] = Constants.PARTNER_ID;
    }
    apiUtils.apiRequest({
        url         : noContractLane ? `/util/v1/lanes/save` : `/b2b/v1/contracts/lane/save`,
        method      : "POST",
        data        : lanesBO,
        baseUrlType : noContractLane ? BASE_URL_TYPES.DEV : undefined
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response);
        }
        else {
            reject(response?.data?.status?.message);
        }
    })
})

export const fetchFreightCharges = contractId => fetchContractCharges({
    filter  : {
        contractId  : contractId,
        chargeTypes : [ "FREIGHT_CHARGE" ]
    }
})

export const fetchLabourCharges = contractId => fetchContractCharges({
    filter  : {
        contractId  : contractId,
        chargeTypes : [ "LOADING_CHARGE" ]
    }
})

export const fetchPTLCharges = contractId => fetchContractCharges({
    filter  : {
        contractId    : contractId,
        chargeTypes : [ "LOADING_CHARGE", "FREIGHT_CHARGE" ]
    }
})

export const fetchContractCharges = data => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url    : `/b2b/v1/contracts/charges`,
        method : "POST",
        data   : data
    }).then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            const { contractCharges } = response.data.response;
            if (!contractCharges) {
                reject("Unable to fetch freight charges");
            }
            else {
                resolve(contractCharges);
            }
        }
        else {
            reject(response?.data?.status?.message);
        }
    })
})

export const saveContractCharges = (contractId, chargesBO) => new Promise((resolve, reject) => {
    apiUtils.apiRequest({
        url     : "/b2b/v1/contracts/charges/save",
        method  : "POST",
        data    : {
            contractV1BO : {
                id : contractId,
                charges : { chargesBO }
            }
        },
        showMessage  : false,
        ignoreStatus : true
    }).then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            if (response?.data?.response) {
                resolve(response?.data?.response);
            }
            else {
                reject(response?.data);
            }
        }
        else {
            reject(response?.data?.response);
        }
    }).catch(response => {
        reject(response)
    })
});

export const removeCharges = (contractId, chargeIds) => {
    const chargesBO = chargeIds.map(charge => ({
                        id     : charge,
                        action : "REMOVE"
                      })).filter(x => x.id !== undefined)
    return saveContractCharges(contractId, chargesBO);
}

export const fetchItems = ( customerId, searchText ) => new Promise((resolve, reject) => {
    
    apiUtils.apiRequest({
        url     : "/b2b/v1/contracts/fetch/box",
        method  : "POST",
        data    : {
            customerId    : customerId,
            searchText    : searchText
        }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response)
        }
        else {
            reject([]);
        }
    })
    .catch(e => reject(e))
})

export const itemsListToSelectList = ( customerId, searchText ) => new Promise((resolve, reject) => {
    fetchItems(customerId, searchText)
    .then( ({ items }) => {
        const itemList = items.reduce((list, iterator) => {
            var name = iterator.itemType;
            if (iterator.itemType === "BOX") {
                name = iterator?.boxTypeBO?.name ? iterator?.boxTypeBO?.name : iterator.itemType;
            }
            else if (iterator.itemType === "GOODS") {
                name = iterator?.goodsTypesBO?.code ? iterator?.goodsTypesBO?.code : iterator.itemType;
            }
            list.push({
                key     : iterator.uniqueId ? iterator.uniqueId : iterator.itemType + "_" + iterator.itemId,
                value   : iterator.uniqueId ? iterator.uniqueId : iterator.itemType + "_" + iterator.itemId,
                label   : name
            })
            return list;
        }, []);
        if ("Default".indexOf(searchText) > -1) {
            itemList.push({
                value   : "OTHER_0",
                key     : "OTHER_0",
                label   : "Default"
            })
        }
        resolve(itemList);
    })
    .catch(e => {
        reject(e)
        notifyApiError(e)
    })
})

export const getCities = searchText => new Promise((resolve, reject) => {
    if (!searchText) {
        searchText = "";
    }
    apiUtils.apiRequest({
        url     : `/b2b/v1/cities?searchText=${searchText}`,
        method  : "GET"
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response)
        }
        else {
            reject([]);
        }
    })
    .catch(e => reject(e))
})

export const getStates = searchText => new Promise((resolve, reject) => {
    if (!searchText) {
        searchText = "";
    }
    apiUtils.apiRequest({
        url     : `/b2b/v1/states?searchText=${searchText}`,
        method  : "GET"
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status, false)) {
            resolve(response?.data?.response)
        }
        else {
            reject([]);
        }
    })
    .catch(e => reject(e))
})