import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import IndentListing from './pages/indent.listing';
import { fetchIndentListing, fetchLov, copyIndent } from "./state/indent.listing.action";
import moment from "moment";
import _ from 'lodash';
import { checkForDownloadReportStatus, notifyApiError, notifyApiSuccess } from '../../../../common/utils/utils';
import { downloadIndentList } from '../../common/services/indent';
import * as appActions from '../../../../app/appAction';
import { SoundNotificationService } from '../../../../services/sound.service';
import { IndentStatusLOV } from './state/indent.listing.constants';

const soundNotificationService = new SoundNotificationService;

function IndentListingContainer(props) {
    const [placementCutOff, setPlacementCutOff] = useState({
        max: null,
        min: null
    })
    const [selectedSummaryIds, setSelectedSummaryIds] = useState(Object.keys(IndentStatusLOV).map(sum => Number(sum)).concat([30010]));
    const [filtersIndicator, setFiltersIndicator] = useState(false);
    useEffect(() => {
        const params = {
            "filters": {
                "lovCategories": ["PLACEMENT_CUT_OFF", "ServiceType"]
            }
        }
        props.fetchLov(params)
    }, []);

    const defaultParams = {
        "orderBy": {
            "orderByParams": [
                {
                    "column": "id",
                    "direction": "DESC"
                }
            ]
        },
        "pageNo": 1,
        "pageSize": 10,
        "summaryIds": ["30010", "30020", "30030", "30040", "30050", "30060", "30070", "30080", "30090", "30100", "30110", "30120"],
        "customerIds": null,
        "status": [],
        "shipperIds": null,
        "serviceTypeIds": [10110, 10100],
        "hubLocationIds": null,
        "indentDateRange": {
            "from": moment().subtract(6, 'days').startOf('day').valueOf(),
            "to": moment().endOf('day').valueOf()
        },
        "partialContentSearch": null

    }
    const defaultFilters = {
        shipperFilters: null,
        locationFilter: null,
        customerFilters: null,
        validFrom: moment().subtract(6, 'days').startOf('day'),
        validTo: moment().endOf('day'),
        serviceTypeData: [
            { id: 0, name: "FTL", value: true, storedValue : "FTL", lovCode : "10110" },
            { id: 1, name: "PTL", value: true, storedValue : "PTL", lovCode : "10100" },
        ],


    }
    const [filters, setFilters] = useState({ ...defaultFilters });    
    useEffect(() => {
        if (props.lovs && props.lovs.length > 0) {

            const serviceTypes = props.lovs.filter(lov => lov.lovCategory === "ServiceType");
            const placement = props.lovs.filter(lov => lov.lovCategory === "PLACEMENT_CUT_OFF");

            const maxMin = [];
            placement.map(pl => {
                maxMin.push(pl.lovValue);
            })
            setPlacementCutOff({
                max: Math.max(...maxMin),
                min: Math.min(...maxMin),
            })
            const { serviceTypeData } = filters;
            serviceTypes.map(service => {
                serviceTypeData.map(sType => {
                    if (sType.name === service.lovValue) {
                        sType.lovCode = service.lovCode;
                    }
                    return sType;
                })
            });
            setFilters({
                ...filters,
                serviceTypeData
            })
        }
    }, [props.lovs])
    let [params, setParams] = useState({ ...defaultParams });
    const [filterModal, setFilterModal] = useState(false);
    const [filterData, setFilterData] = useState({
        shipperData: [],
        locationData: [],
        cusotmerData: [],
    })
    const applyFilters = () => {
        let appliedFilters = filters;
        // const { filters } = params;

        setFilterData({
            shipperData: [],
            locationData: [],
            cusotmerData: [],
        });
        const stype = appliedFilters.serviceTypeData.map(s => { if (s.value) return Number(s.lovCode) });
        params = {
            ...params,
            pageNo : 1,
            customerIds: appliedFilters?.customerFilters ? appliedFilters?.customerFilters.map(customer => customer.key) : null,
            shipperIds: appliedFilters?.shipperFilters ? appliedFilters?.shipperFilters.map(shipper => shipper.key) : null,
            hubLocationIds: appliedFilters?.locationFilter ? appliedFilters?.locationFilter.map(location => location.key) : null,
            serviceTypeIds: _.compact(stype).length > 0 ? _.compact(stype) : null,
            // summaryIds: params.summaryIds,
            "indentDateRange":
                ((filters.validFrom && filters.validTo) ?
                    {
                        "from": filters.validFrom ? moment(filters.validFrom).valueOf() : "",
                        "to": filters.validTo ? moment(filters.validTo).valueOf() : ""
                    } : null),

        }
        setParams(params);
        setFilterModal(false);


    }

    const resetFilters = () => {
        setFilters({ ...defaultFilters })

        params = {
            ...params,
            ...defaultParams
        }
        setParams(params);
        setSelectedSummaryIds((Object.keys(IndentStatusLOV).map(sum => Number(sum)).concat([30010])))
        setFilterModal(false);

    }

    useEffect(() => {
        
        delete params['summaryIds'];              
        // dispatch to get Data
        
        var _defaultParams = JSON.parse(JSON.stringify(defaultParams));
        var _params = JSON.parse(JSON.stringify(params));
        delete _defaultParams.orderBy;
        delete _defaultParams.status;
        delete _defaultParams.summaryIds;
        delete _params.orderBy;
        delete _params.status;
        delete _defaultParams.pageNo;
        delete _params.pageNo;
        delete _defaultParams.pageSize;
        delete _params.pageSize;
        setFiltersIndicator(!_.isEqual(_defaultParams, _params));

        props.fetchIndents(params);
    }, [params]);

    useEffect(() => {
        if (params.pageNo > props.indentData?.maxPage) {
            setParams({
                ...params,
                pageNo : 1
            })
        }
    }, [ props.indentData ])

    useEffect(() => {
        if (props.copiedIndent?.id) {
            notifyApiSuccess("Indent copied successfully", props.copiedIndent?.indentCode)
            resetFilters();
        }

    }, [props.copiedIndent?.id])

    useEffect(() => {
        if (props.error) {
            notifyApiError(props.error, 'ERROR!!');
        }

    }, [props.error]);

    const IndentCopy = (id) => {
        props.indentCopy(id);
    }

    useEffect(() => {
        if (props.downloadStatus === "SUCCESS" || props.downloadStatus === "FAILURE") {


            if (props.downloadStatus === "SUCCESS") {
                soundNotificationService.playSuccess();
            }
            if (props.downloadStatus === "FAILURE") {
                soundNotificationService.playWarning();
            }


            props.setS3SyncStatusReset();

        }
    }, [props.downloadStatus])

    const download = () => {
        delete params['summaryIds'];
        downloadIndentList(params).then(res => {            
            const reportData = {
                's3Url': res,
                'status': true
            };

            localStorage.setItem(
                "downloadReportInitiated",
                JSON.stringify(reportData)
            );
            const prop = {
                setS3SyncStatus: props.setS3SyncStatus,
                uploadedFileResp: {
                    responseUrl: res
                }
            }
            checkForDownloadReportStatus(prop, "_blank", true);
        })


    }
    
    return (
        <IndentListing
            filterModal={filterModal}
            placementCutOff={placementCutOff}
            setFilterModal={setFilterModal}
            filterData={filterData}
            setFilterData={setFilterData}
            filters={filters}
            setFilters={setFilters}
            params={params}
            setParams={setParams}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
            indentData={props.indentData}
            summaries={props.summary}
            loading={props.loading}            
            copyIndent={IndentCopy}
            download={download}
            selectedSummaryIds={selectedSummaryIds}
            setSelectedSummaryIds={setSelectedSummaryIds}
            filtersIndicator={filtersIndicator}
            {...props}
        />
    )
}

const mapStateToProps = (state, ownProps) => {

    return {
        summary: state.indentList?.indets?.summary ? state.indentList?.indets?.summary : [],
        indentData: state.indentList.indets,
        error: state.indentList.error,
        loading: state.indentList.loading,
        lovs: state.indentList.lovs,
        copiedIndent: state.indentList.copiedIndent,
        downloadStatus: state?.app?.s3Status
    };
};
const mapDispatchToProps = dispatch => ({
    fetchIndents: (params) => dispatch(fetchIndentListing(params)),
    fetchLov: (params) => dispatch(fetchLov(params)),
    indentCopy: (id) => dispatch(copyIndent(id)),
    setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    setS3SyncStatusReset: () => dispatch(appActions.setS3SyncStatusReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(IndentListingContainer);