import { validationRules } from "../../../../common/utils/constants"



const customerDetails = {
    "type": "object",
    "required": ["name", "code", "contactPerson", "phoneNumber", "emailId"],
    "properties": {
        "name": {
            "type": "string",
            "title": "Customer Name",
            pattern: validationRules.nameNumber
        },
        "code": {
            "type": "string",
            "title": "Customer Code",
            minLength: 3,
            maxLength: 4,
            pattern: validationRules.shortCode
        },
        "contactPerson": {
            "type": "string",
            "title": "Contact Person",
            pattern: validationRules.nameNumberContactPerson
        },
        "emailId": {
            "type": "string",
            "title": "Email ID",
            pattern: validationRules.contractEmail
        },
        "phoneNumber": {
            "type": "string",
            "title": "Phone Number",
            pattern: validationRules.mobile
        },
        "gstin": {
            "type": "string",
            "title": "GST",
            pattern: validationRules.gstNumber
        },
        // "organisationId": {
        //     "type"     : "number",
        //     "title"    : "Organization",
        //     "searchEnabled" : true
        // },
        "attributes": {
            "type": "object",
            "properties": {
                "online": {
                    "type": "boolean",
                    "title": "Online"
                },
                "threePlReferenceNumber": {
                    "type": "boolean",
                    "title": "3PL Docket Required"
                }
            }
        }
    }
}


const schema = {
    "type": "object",
    "properties": {
        "customerDetails": customerDetails
    }
}

const uiSchema = {
    "customerDetails": {
        "classNames": "multi-row-field-objects hide-title",
        "name": {
            "ui:placeholder": "Enter Customer Name",
            "maxLength": 50
        },
        "code": {
            "ui:placeholder": "Enter Customer Code",
            "minLength": 3,
            "maxLength": 4,
        },
        "contactPerson": {
            "ui:placeholder": "Enter Contact Person",
            "maxLength": 50,
        },
        "emailId": {            
            "ui:placeholder": "Enter Contact Person Email ID",
            "maxLength": 50,
        },
        "phoneNumber": {
            "ui:placeholder": "Enter Contact Person Phone Number",
            "maxLength": 10,
        },
        "gstin": {
            "ui:placeholder": "Enter GST number if applicable",
            "maxLength": 15
        },
        // "organisationId"     : {
        //     "ui:widget" : "select"
        // },
        "attributes": {
            "classNames": "half-field-wrapper",
            "threePlReferenceNumber": {
                "ui:widget": "SwitchWidget",
                "type": "boolean",
                "classNames": "half-field"
            },
            "online": {
                "ui:widget": "SwitchWidget",
                "type": "boolean",
                "classNames": "half-field"
            }
        }
    }
}

const errorList = {
    "customerDetails": {
        name: {
            required: "Customer name cannot be empty!",
            pattern: "Customer name accepts only characters (a-z, A-Z, _,-,:)"
        },
        code: {
            required: "Customer code cannot be empty!",
            pattern: "Customer code accepts only characters (a-z,A-Z,_,-,:)",
            minLength: "Customer code should be 3-4 characters"
        },
        emailId: {
            required: "Email ID cannot be empty!",
            pattern: "Invalid e-mail address!"
        },
        contactPerson: {
            required: "Contact Person cannot be empty!",
            pattern: "Contact person accepts only characters (a-z, A-Z)"
        },
        phoneNumber: {
            required: "Phone Number cannot be empty!",
            pattern: "Enter valid Phone number",
            minLength: "Phone Number cannot be less then 10 digits"
        },
        gstin: {
            pattern: "Enter valid GSTIN"
        }
    }

}

const validations = {

    name: {
        required: true,
        minLength: 3
    },
    code: {
        required: true,
        minLength: 3
    },
    emailId: {
        // required: true,
        pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    contactPerson: {
        required: true,
        minLength: 3
    },
    phoneNumber: {
        required: true,
        pattern: validationRules.mobile
    },
    gstin: {
        pattern: validationRules.gstNumber
    }


}

export default { schema, uiSchema, errorList, validations };