export const FETCH_CUSTOMER_INVOICE_BEGIN = "FETCH_CUSTOMER_INVOICE_BEGIN";
export const FETCH_CUSTOMER_INVOICE_SUCCESS = "FETCH_CUSTOMER_INVOICE_SUCCESS";
export const FETCH_CUSTOMER_INVOICE_FAILURE = "FETCH_CUSTOMER_INVOICE_FAILURE";

export const FETCH_CUSTOMER_INVOICE_DETAILS_BEGIN = 'FETCH_CUSTOMER_INVOICE_DETAILS_BEGIN';
export const FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS = 'FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE = 'FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE';

export const SETTLE_INVOICE_BEGIN = 'SETTLE_INVOICE_BEGIN';
export const SETTLE_INVOICE_SUCCESS = 'SETTLE_INVOICE_SUCCESS';
export const SETTLE_INVOICE_FAILURE = 'SETTLE_INVOICE_FAILURE';

export const FETCH_CUSTOMER_VIEW_ACTIVITY_BEGIN = "FETCH_CUSTOMER_VIEW_ACTIVITY_BEGIN";
export const FETCH_CUSTOMER_VIEW_ACTIVITY_SUCCESS = "FETCH_CUSTOMER_VIEW_ACTIVITY_SUCCESS";
export const FETCH_CUSTOMER_VIEW_ACTIVITY_FAILURE = "FETCH_CUSTOMER_VIEW_ACTIVITY_FAILURE";

export const UPLOAD_DEBIT_NOTE_BEGIN = "UPLOAD_DEBIT_NOTE_BEGIN";
export const UPLOAD_DEBIT_NOTE_SUCCESS = "UPLOAD_DEBIT_NOTE_SUCCESS";
export const UPLOAD_DEBIT_NOTE_FAILURE = "UPLOAD_DEBIT_NOTE_FAILURE";
