
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

const initialState = {
    loading: false
}

export const zohoReducer = (state = initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_ZOHO_DASHBOARD_LIST_BEGIN: return fetchZohoListBegin(state);
        case _Constants.FETCH_ZOHO_DASHBOARD_LIST_SUCCESS: return fetchZohoListSuccess(state, action);
        case _Constants.FETCH_ZOHO_DASHBOARD_LIST_FAILURE: return fetchZohoListFailure(state, action);

        case _Constants.FETCH_ZOHO_PERMISSIONS_LIST_BEGIN: return fetchZohoPermissionsBegin(state);
        case _Constants.FETCH_ZOHO_PERMISSIONS_LIST_SUCCESS: return fetchZohoPermissionsSuccess(state, action);
        case _Constants.FETCH_ZOHO_PERMISSIONS_LIST_FAILURE: return fetchZohoPermissionsFailure(state, action);
       
        case _Constants.FETCH_ZOHO_STATUS_BEGIN: return fetchZohoStatusBegin(state);
        case _Constants.FETCH_ZOHO_STATUS_SUCCESS: return fetchZohoStatusSuccess(state, action);
        case _Constants.FETCH_ZOHO_STATUS_FAILURE: return fetchZohoStatusFailure(state, action);

        case _Constants.FETCH_ZOHO_CREATE_BEGIN: return createZohoBegin(state);
        case _Constants.FETCH_ZOHO_CREATE_SUCCESS: return createZohoSuccess(state, action);
        case _Constants.FETCH_ZOHO_CREATE_FAILURE: return createZohoFailure(state, action);


        case _Constants.FETCH_ZOHO_UPDATE_BEGIN: return updateZohoBegin(state);
        case _Constants.FETCH_ZOHO_UPDATE_SUCCESS: return updateZohoSuccess(state, action);
        case _Constants.FETCH_ZOHO_UPDATE_FAILURE: return updateZohoFailure(state, action);


        case _Constants.FETCH_ZOHO_DASHBOARD_BYID_BEGIN: return fetchZohoByIDBegin(state);
        case _Constants.FETCH_ZOHO_DASHBOARD_BYID_SUCCESS: return fetchZohoByIDSuccess(state, action);
        case _Constants.FETCH_ZOHO_DASHBOARD_BYID_FAILURE: return fetchZohoByIDFailure(state, action);
       
        default:
            return state;
    }
}

function fetchZohoByIDBegin(state, action) {
    return {
        ...state,
        zohoLoading: true,  
        zohoData: undefined     
    };
}

function fetchZohoByIDSuccess(state, action) {
    return {
        ...state,
        zohoLoading: false,
        zohoData: action.payload,
    };
}

function fetchZohoByIDFailure(state, action) {
    return {
        ...state,
        zohoLoading: false,
        error: action.payload,
    };
}


function fetchZohoListBegin(state, action) {
    return {
        ...state,
        loading: true,
        zohoStatus: null,
        zohoCreate: null, 
        zohoUpdate: null
    };
}

function fetchZohoListSuccess(state, action) {
    return {
        ...state,
        loading: false,
        zohoList: action.payload,
        zohoStatus: null,
        ...action.payload
    };
}

function fetchZohoListFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        zohoList: []
    };
}


function fetchZohoStatusBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchZohoStatusSuccess(state, action) {
    return {
        ...state,
        loading: false,
        zohoStatus: action.payload,
        ...action.payload
    };
}

function fetchZohoStatusFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,      
    };
}


function createZohoBegin(state, action) {
    return {
        ...state,
        crateZohoLoading: true
    };
}

function createZohoSuccess(state, action) {
    return {
        ...state,
        crateZohoLoading: false,
        zohoCreate: action.payload,
        ...action.payload
    };
}

function createZohoFailure(state, action) {
    return {
        ...state,
        crateZohoLoading: false,
        error: action.payload,      
    };
}


function updateZohoBegin(state, action) {
    return {
        ...state,
        updateZohoLoading: true
    };
}

function updateZohoSuccess(state, action) {
    return {
        ...state,
        updateZohoLoading: false,
        zohoUpdate: action.payload,
        ...action.payload
    };
}

function updateZohoFailure(state, action) {
    return {
        ...state,
        updateZohoLoading: false,
        error: action.payload,      
    };
}


function fetchZohoPermissionsBegin(state, action) {
    return {
        ...state,
        loading: true        
    };
}

function fetchZohoPermissionsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        permissions: action.payload,        
    };
}

function fetchZohoPermissionsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload       
    };
}

