import * as CONSTANTS from './constants';

export const RateCardCustomConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.RATE_CARD_FETCH_CUSTOM_CONFIG_BEGIN: return getCustomConfigBegin(state);
        case CONSTANTS.RATE_CARD_FETCH_CUSTOM_CONFIG_SUCCESS: return getCustomConfigSuccess(state, action);
        case CONSTANTS.RATE_CARD_FETCH_CUSTOM_CONFIG_FAILURE: return getCustomConfigFailure(state);


        case CONSTANTS.RATE_CARD_UPDATE_CUSTOM_CONFIG_BEGIN: return updateCustomConfigBegin(state);
        case CONSTANTS.RATE_CARD_UPDATE_CUSTOM_CONFIG_SUCCESS: return updateCustomConfigSuccess(state, action);
        case CONSTANTS.RATE_CARD_UPDATE_CUSTOM_CONFIG_FAILURE: return updateCustomConfigFailure(state, action);


        case CONSTANTS.RATE_CARD_RESET_UPDATED_DATA: return resetUpdatedState(state);

        default:
            return state;
    }
}



function getCustomConfigBegin(state) {
    return {
        ...state,
        config: {
            loading: true
        },
    }
}

function getCustomConfigSuccess(state, action) {
    return {
        ...state,
        config: {
            configData: action.payload,
            loading: false
        }
    }
}

function getCustomConfigFailure(state) {
    return {
        ...state,
        config: {
            loading: false
        }
    }
}


function updateCustomConfigBegin(state) {
    return {
        ...state,
        updatedConfig: {
            loading: true
        },
    }
}

function updateCustomConfigSuccess(state, action) {
    return {
        ...state,
        updatedConfig: {
            configData: action.payload,
            loading: false
        }
    }
}

function updateCustomConfigFailure(state, action) {
    return {
        ...state,
        updatedConfig: {
            loading: false,
            error: action.payload
        }
    }
}

function resetUpdatedState(state) {
    return {
        ...state,
        updatedConfig: {}
    }
}