import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { apiRequest } from "../../../../common/utils/apiGateway";
import { consignmentColumns } from "./consignment.columns";
import "./print-dockets.scss";

const DocketsConsignments = (props) => {
  const [dataSource, SetDataSource] = useState(false);

  useEffect(() => {
    if (props?.consignmentId) {
      apiRequest({
        url: "/b2b/v1/consignmentDetails",
        method: "POST",
        data: {
          filters: {
            waybills: [props?.consignmentId],
          },
        },
      }).then((response) => {
        const consignments = response.data.response?.consignments;
        var shipmentBOs = [];
        if (consignments) {
          shipmentBOs = consignments[0]?.shipmentBOs
            ? consignments[0]?.shipmentBOs
            : [];
        }
        var data = [];
        shipmentBOs.map((shipment) => {
          shipment.barcodes.map((barcode) => {
            if (barcode.consignmentBOList) {
              data = [...data, ...barcode.consignmentBOList];
            }
          });
        });
        SetDataSource(data);
      });
    }
  }, [props.consignmentId]);

  consignmentColumns.forEach((col) => {
    col["render"] = (data) => (
      <div style={{ wordBreak: "break-all" }}> {data} </div>
    );
  });

  const tableCols = [
    {
      title: () => (
        <div className="flex-column">
          <div className="text-bold">
            <div className="print-header">
              Docket No: {props?.consignmentId}
            </div>
          </div>
          <div className="text-bold">Shipment Count: {dataSource.length}</div>
        </div>
      ),
      children: consignmentColumns,
    },
  ];

  return (
    <>
      {dataSource ? (
        <Table
          className="print-table"
          columns={tableCols}
          dataSource={dataSource}
          bordered={true}
          pagination={false}
          rowKey={"bagId"}
        />
      ) : (
        <Spin
          spinning={!dataSource}
          className="flex-box justify-content-center spacer-l"
        />
      )}
    </>
  );
};

export default DocketsConsignments;
