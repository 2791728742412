import React, { Component } from "react";
import { Icon, Button } from "antd";
import debounce from 'lodash/debounce';

class DetailsNavigation extends Component{
    constructor(props){
        super(props);
    }

    handleKeyBoardEvents = e => {
        if(e.keyCode == 39 && !this.props.rightDisabled){
            this.navigate(NAV_DIRECTION.RIGHT);
        }
        else if(e.keyCode == 37 && !this.props.leftDisabled){
            this.navigate(NAV_DIRECTION.LEFT);
        }
    }

    keyboardListener = debounce(this.handleKeyBoardEvents, 250);

    componentDidMount(){
        if(!this.props.restrictKeyboardNav){
            window.addEventListener("keyup", this.keyboardListener);
        }
    }

    componentWillUnmount(){
        if(!this.props.restrictKeyboardNav){
            window.removeEventListener("keyup", this.keyboardListener);
        }
    }

    navigate = params => {
        if(this.props?.navigate){
            this.props.navigate(params);   
        }
    }

    goBack = () => {
        if(this.props?.goBack){
            this.props.goBack();   
        }
        else if(this.props?.goBackURL){
            this.props.history.push(this.props.goBackURL);
        }
    }
    
    render() {
        return (
            <div>
                <div className="detail-naviagtion flex-box align-items-center">
                    <div className="flex-1">
                        <div className="detail-naviagtion-back flex-box flex-gap-m align-items-center cursor-pointer" onClick={this.goBack}>
                            <span className="detail-naviagtion-back-icon icon-size-xl"><Icon type="arrow-left"/></span>
                            <span className="detail-naviagtion-back-text font-size-l-1 text-bold">Back</span>
                        </div>
                    </div>

                    <div className="flex-3">
                        <div className="detail-naviagtion-pagination flex-box flex-gap-xl align-items-center">
                            <span className="detail-naviagtion-left detail-naviagtion-button" style={{flexBasis:'5%',height:'20px'}}>
                                <Button disabled={this.props.leftDisabled} 
                                    className="detail-naviagtion-cta" onClick={() => this.navigate(NAV_DIRECTION.LEFT)}>
                                    <Icon type="left" onClick={() => this.navigate("left")} />
                                </Button>
                            </span>
                            <span className="detail-naviagtion-center text-align-center" style={{flexBasis:'50%'}}>
                                {
                                    this.props?.centerBlock ?

                                    this.props.centerBlock  :
                                    <div className="font-size-m-2">{this.props.index} of {this.props.totalCount}</div>
                                }
                            </span>
                            <span className="detail-naviagtion-right detail-naviagtion-button" style={{flexBasis:'5%',height:'20px'}}>
                                <Button disabled={this.props.rightDisabled} 
                                    className="detail-naviagtion-cta" onClick={() => this.navigate(NAV_DIRECTION.RIGHT)}>
                                    <Icon type="right" />
                                </Button>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default DetailsNavigation;

export const NAV_DIRECTION = {
    LEFT:'LEFT', 
    RIGHT: 'RIGHT'
}