import React, { PureComponent } from 'react'
import { canAllow } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import { Constants } from '../../common/utils/constants';
import Expected from './expected'
import Exception from './exception'

class LoadModule extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            pageOrder: ['expected', 'exceptions'],
            loadPermissions: {
                expected: canAllow(permissionTags.load.expected.read),
                exceptions: canAllow(
                    permissionTags.load.exceptions.read
                )
            },
        }
    }

    render() {
        if (this.state.loadPermissions.expected) {
            return <Expected {...this.props} />
        } else if (this.state.loadPermissions.exceptions) {
            return <Exception {...this.props} />
        }



        return (
            <></>
        )
    }
}

export default LoadModule;