import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const PrintReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_PRINT_HISTORY_BEGIN: return fetchPrintHistoryBegin(state);
        case _Constants.FETCH_PRINT_HISTORY_SUCCESS: return fetchPrintHistorySuccess(state, action);
        case _Constants.FETCH_PRINT_HISTORY_FAILURE: return fetchPrintHistoryFailure(state, action);
        default:
            return state;
    }
}

function fetchPrintHistoryBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchPrintHistorySuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedPrintHistory: storePaginatedData(action?.payload?.currentPageNo, state.PaginatedPrintHistory ? state.PaginatedPrintHistory : {}, action?.payload?.stickerResponse, action.isReset),
        ...action.payload
    };
}

function fetchPrintHistoryFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        PrintHistory: []
    };
}