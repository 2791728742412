import { Card, Checkbox, Col, Form, Icon, Input, Row, Select, Spin, Button, DatePicker, AutoComplete } from 'antd';
import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Constants } from '../../../common/utils/constants';
import LocationService from '../../../services/settings/location.service';
import SupportService from '../../../services/support.service';

let id = 1;
const locationService = new LocationService
const supportService = new SupportService

class TripFinish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            loading: false,
            isFetchingLocation: false,
            transitLocation: ''
        }
    }

    componentDidMount() {
        // this.fetchLocations()
    }
    fetchLocations = (value) => {
        const that = this
        this.setState({
            isFetchingLocation: true
        }, () => {
            locationService.getLocationsFetch({
                "filters": {
                    status: true,
                    fetchObjects: [
                    ],
                    entityTypes: [
                        "PARTNER"
                    ],
                    partialContentSearch: value
                }
            }).then(resp => {
                if (resp.data) {
                    that.setState({
                        locations: resp.data?.response?.locations || [],
                        isFetchingLocation: false
                    })
                }
            })
        })

    }
    remove = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const key = keys.filter(key => key.id !== keys[k].id);
        form.setFieldsValue({
            keys: key,
        });
    };

    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat({ id: ++id });
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const self = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                self.setState({
                    loading: true
                }, () => {
                    console.log(values)
                    const docketLocationMapping = {}
                    values.consignment.map(item => {
                        docketLocationMapping[item.consignmentId] = item.isDirectDelivery ? Constants.userLocationId : item.locationId
                    })
                    const payload = {
                        requests: [
                            {
                                docketLocationMapping,
                                action: "UPDATE",
                                tripId: values.tripId,
                                assetId: values.assetId,
                                routeId: values.routeId,
                                isFinishedTripCorrection: true
                            }
                        ]
                    }
                    console.log('payload', payload)
                    supportService.tripCorrectionLoadDockets(payload).then(resp => {
                        console.log('load-docket api', resp.data)
                        if (resp.data) {
                            values.consignment.map(item => {
                                const newPayload = item.isDirectDelivery
                                    ? {
                                        request: [
                                            {
                                                tripId: values.tripId,
                                                wayBillNo: item.waybillNo,
                                                consignmentPodBO: {
                                                    podCreatedDate: moment(item.podDate).valueOf(),
                                                    podUpdatedDate: moment(item.podDate).valueOf(),
                                                    isMarkDelForTripCorrectionDocket: true
                                                }
                                            }
                                        ]
                                    }
                                    : {
                                        request: [
                                            {
                                                tripId: values.tripId,
                                                waybillNo: item.waybillNo,
                                            }
                                        ]
                                    }
                                const apiCall = item.isDirectDelivery ? supportService.tripCorrectionMarkDel : supportService.tripCorrectionHangover
                                console.log('newPayload', newPayload)
                                apiCall(newPayload).then(resp => {
                                    console.log(resp.data)
                                })
                            })
                            self.setState({
                                loading: false
                            })
                        } else {
                            self.setState({
                                loading: false
                            })
                        }
                    }).catch(err => {
                        self.setState({
                            loading: false
                        })
                    })
                })
            }
        })
    }
    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            labelAlign: "left",
            colon: false
        };
        const { locations, loading, isFetchingLocation, transitLocation } = this.state;
        const { getFieldDecorator, getFieldValue, setFields } = this.props.form;
        getFieldDecorator('keys', { initialValue: [{ id }] });
        const keys = getFieldValue('keys');
        const formItems = keys.length > 0 ? keys.map((k, index) => (
            <Row gutter={[12, 12]}>
                <Col span={8}>
                    <Form.Item label={`Consignment ${(k.id)}`}>
                        {getFieldDecorator(`consignment[${(k.id)}]['consignmentId']`, {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Consignment ID!',
                                },
                            ],
                        })(
                            <Input
                                placeholder="Consignment ID"
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<>&nbsp;</>}>
                        {getFieldDecorator(`consignment[${(k.id)}]['waybillNo']`, {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Waybill No!',
                                },
                            ],
                        })(

                            <Input
                                placeholder="Waybill No"
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item label={<>&nbsp;</>}>
                        {getFieldDecorator(`consignment[${(k.id)}]['isDirectDelivery']`, {
                            initialValue: false,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input Consignment ID!',
                                },
                            ],
                        })(

                            <Checkbox>Direct Delivery</Checkbox>
                        )}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<>&nbsp;</>}>
                        {
                            getFieldValue(`consignment[${(k.id)}]['isDirectDelivery']`)
                                ? <Col span={18}>
                                    {getFieldDecorator(`consignment[${(k.id)}]['podDate']`, {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Date!',
                                            },
                                        ],
                                    })(

                                        <DatePicker placeholder="Delivery Date" style={{ width: '100%' }} />
                                    )}
                                </Col>
                                : <Col span={18}>
                                    {getFieldDecorator(`consignment[${(k.id)}]['locationId']`, {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Consignment ID!',
                                            },
                                        ],
                                    })(
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Transit Location"
                                            notFoundContent={isFetchingLocation ? <center><Spin size="small" /></center> : <div>Search Location</div>}
                                            onSearch={this.fetchLocations}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                        >
                                            {
                                                locations.map(loc => {
                                                    return <Select.Option value={loc.id}>{loc.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Col>
                        }

                        <Col span={2}>
                            <Icon
                                className="dynamic-delete-button"
                                type="plus-circle"
                                style={{ marginRight: 10, marginLeft: 10 }}
                                onClick={() => this.add()}
                            />
                        </Col>
                        {
                            keys.length > 1 && <Col span={2}>
                                <Icon
                                    className="dynamic-delete-button"
                                    type="minus-circle-o"
                                    style={{ marginRight: 10, marginLeft: 10 }}
                                    onClick={() => this.remove(index)}
                                />
                            </Col>
                        }

                    </Form.Item>
                </Col>
            </Row>
        )) : null
        return (
            <div className="trip-finish">
                <h2>Add Dockets to Finished Trip</h2>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Card className="" style={{ minHeight: 600 }}>
                        <Spin spinning={loading}>
                            <Row gutter={[12, 12]}>
                                <Col span={8}>
                                    <Form.Item label="Trip ID">
                                        {getFieldDecorator('tripId', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input Trip ID!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Trip ID"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Asset ID">
                                        {getFieldDecorator('assetId', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input Asset ID!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Asset ID"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Route ID">
                                        {getFieldDecorator('routeId', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input Route ID!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Route ID"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {formItems}
                            <Row gutter={[12, 12]}>
                                <Col span={8}>
                                    <Button className="" key="submit" type="primary" onClick={this.handleSubmit}>
                                        Submit
                        </Button>
                                    <Button className="mr-3" key="back" onClick={() => {
                                        id = 1
                                        this.props.form.resetFields()
                                    }}>
                                        Reset
                        </Button>
                                </Col>
                            </Row>
                        </Spin>
                    </Card>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({

});
const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'User', onValuesChange: (props, val) => { console.log(props.form.getFieldsValue()); } })(TripFinish));