import React, { Component } from "react";
import { Button, Modal, Row, Col, Form, Select, Input } from 'antd';

const { TextArea } = Input;

class ContractRejectionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectSelectedValue: [],
            rejectComment: undefined
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (nextProps.loading === false) {
    //         return {
    //             ...prevState,
    //             rejectSelectedValue: [],
    //             rejectComment: undefined
    //         }
    //     }
    //     return prevState;
    // }

    filterFunction = (input, option) => option?.props?.children?.toString()?.toLowerCase().indexOf(input?.toLowerCase()) >= 0

    onCancelRejection = _ => {
        this.setState({
            rejectSelectedValue: [],
            rejectComment: undefined
        }, this.props.cancelRejectModal)
    }

    render() {

        const { rejectSelectedValue } = this.state;
        const { loading } = this.props;

        return (
            <Modal
                visible={this.props.showRejectModal}
                title="Reject Contract" width={450}
                onCancel={this.props.cancelRejectModal}
                footer={[
                    <Button disabled={rejectSelectedValue.length <= 0} loading={loading} key={'RejectButton'} type="danger"
                        onClick={() => this.props.rejectContract(this.state.rejectSelectedValue, this.state.rejectComment)} >
                        Reject
                    </Button>,

                    <Button disabled={loading} onClick={this.onCancelRejection}
                        type="default" key={'CancelButton'}> Cancel </Button>
                ]}>
                <Row gutter={[6, 0]}>
                    <Col span={24}>
                        <Form.Item colon={false} label="Select reasons for rejection">
                            <Select
                                style={{ width: "100%" }}
                                placeholder={"Select Reason"}
                                onChange={rejectSelectedValue => this.setState({ rejectSelectedValue })}
                                optionFilterProp="children" mode="multiple"
                                value={this.state.rejectSelectedValue}
                                filterOption={this.filterFunction}>
                                {this.props.contractRejectedReasons?.map(reason =>
                                    <Select.Option key={reason?.id} value={reason?.id}
                                        label={reason?.documentRejectedReason}>
                                        {reason?.documentRejectedReason}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item colon={false} label="Rejection Remarks">
                            <TextArea value={this.state.rejectComment}
                                onChange={e => this.setState({ rejectComment: e.target.value })}
                                placeholder={"Comments"}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal >
        )
    }

}

export default ContractRejectionModal;