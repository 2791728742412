import React, { Component } from 'react'
import { Row, Col, Card, Form, Input, Divider, Icon, Select, Button, Radio, Table, Alert } from 'antd';
import { connect } from 'react-redux';
import { FlowTypes, Constants, ApiActions } from '../../../../common/utils/constants';
import AssetService from '../../../../services/settings/asset.service';
import ScanService from '../../../../services/inbound/scan.service';
import { isScanedOrKeyboardEntry, notifyApiSuccess, notifyApiError, dataGet, getRouteName, validateSyncData, getCodeParam } from '../../../../common/utils/utils';
import DrsService from '../../../../services/inventory/drs.service';
import WaybillLink from '../../../shared-components/waybill-link';
import MiscService from '../../../../services/misc.service';
import moment from 'moment';
import { Mixpanel } from '../../../../utils/mixpanel';
class B2BForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAwbColumns: [
                {
                    title: 'AWB No.',
                    dataIndex: 'waybillNo',
                    key: 1,
                    render: data => <WaybillLink>{data}</WaybillLink>
                },
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 2,
                    render: (data, row) => (row?.customer?.name || 'NA')
                },
                {
                    title: 'Destination',
                    dataIndex: 'pincode',
                    key: 3,
                    render: (data, row) => (<div>{row.pincode.city.cityCode} - {row.pincode.zipcode}</div>)
                },
                {
                    title: 'Action',
                    dataIndex: 'customer',
                    key: 4,
                    render: (data, row, index) => this.isActionAllowed() ? (<Icon onClick={e => this.removeSelected(this.state.selectedAwb.findIndex(i => (i.id === row.id)))} type="delete" />) : ''
                },
            ],
            pendingAwbColumns: [
                {
                    title: 'AWB No.',
                    dataIndex: 'waybillNo',
                    key: 1,
                    render: data => <WaybillLink>{data}</WaybillLink>
                },
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    key: 2,
                    render: (data, row) => (row?.customer?.name || 'NA')
                },
                {
                    title: 'Destination',
                    dataIndex: 'pincode',
                    key: 3,
                    render: (data, row) => (<div>{row.pincode.city.cityCode} - {row.pincode.zipcode}</div>)
                },
                {
                    title: 'Action',
                    dataIndex: 'customer',
                    key: 4,
                    render: (data, row, index) => this.isActionAllowed() ? (<Icon onClick={e => this.addSelected(this.state.pendingAwb.findIndex(i => (i.id === row.id)))} type="plus" />) : ''
                },
            ],
            scanAllowed: true,
            drsId: props.match.params.drsId,
            selectedAwb: [],
            pendingAwb: [],
            drs: { awb: "" },
            assets: [],
            scanAllowed: true,
            flowTypes: FlowTypes,
            flowType: FlowTypes.FORWARD,
            devices: [],
            dropDownText: { devices: "Select a device" },
            loading: { pending: true, selected: true },
            scanEntry: false,
            lastScanTime: 0,
            currentScanEntryTime: 0,
            inputEntryTimeList: [],
            alert: { visible: false, type: "", message: "" },
            drsIdInputDisable: false
        }
        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        Mixpanel.identify(loggedPartnerId);
        Mixpanel.people.set({
            $first_name: user?.name,
            $email: user?.email,
            $phone_number: user?.number,
            $partner_id: user?.partner?.id,
            $partner_name: user?.partner?.name,
        });
    }
    componentDidMount() {
        if (this.props.configrations.portablegps) {
            this.getDevices()
        }
        if (this.state.drsId) {
            Mixpanel.track("B2B DRS Update from initialed", { 'ERPInventoryDRSFormInit': moment().valueOf(), drsId: this.state.drsId });
            (new DrsService).getDrs(this.state.drsId).then(({ data }) => {
                this.setState(state => ({
                    loading: {
                        ...state.loading,
                        selected: false
                    }
                }), () => {
                    if (data !== null) {
                        const drsData = data.response.drses[0]
                        this.setState({
                            drsData,
                            selectedAwb:
                                drsData.consignments == null ? [] : drsData.consignments,
                            drsIdInputDisable: true
                        }, () => {
                            this.props.form.setFieldsValue({
                                drsId: this.state.drsData.drsCode,
                                awbs: this.state.selectedAwb.length,
                                flowType: this.state.drsData.flowType,
                            });
                            if (dataGet(this.state.drsData, "asset.imei")) {
                                this.patchDeviceList(this.state.drsData.asset.imei);
                            }
                            this.getConsignments();
                            this.getAssets().then(assetData => {
                                this.state.assets.forEach(element => {
                                    if (element.id === this.state.drsData.asset.id) {
                                        this.props.form.setFieldsValue({ assetId: element.id })
                                        if (element.imei) {
                                            this.props.form.setFieldsValue({ deviceImei: element.imei })
                                        }
                                    }
                                });
                            });
                        })
                    }
                })
            });
        } else {
            Mixpanel.track("B2B DRS Create from initialed", { 'ERPInventoryDRSFormInit': moment().valueOf() });
            this.getAssets();
            this.getConsignments();
            this.setState(state => ({
                loading: { ...state.loading, selected: false }
            }))
        }
    }
    getAssets = () => {
        const promise = new Promise(resolve => {
            (new AssetService)
                .getAssetsByLocation(Constants.userLocationId)
                .then(({ data }) => {
                    if (data != null) {
                        this.setState({
                            assets: data.response.assets,
                            assetIdToAssignedDrsMap:
                                data.response.assetIdToAssignedDrsMap,
                            assetIdToAssignedTHCMap:
                                data.response.assetIdToAssignedTHCMap,
                        })
                    }
                    resolve(data);
                });
        });
        return promise;
    }
    getConsignments() {
        const payload = {
            filters: {
                isArrived: true,
                isBookedOnly: true,
                flowTypes: [this.state.flowType]
            }
        };
        if (this.state.flowType === FlowTypes.RTO) {
            payload.filters["statuses"] =
                this.state.flowType === FlowTypes.RTO
                    ? ["RTO_RETURN_TO_ORIGIN", "RTO_IN"]
                    : ["RTS_RETURN_TO_SHIPPER"];
            // payload.filters['bookingOfficeLocationIds'] = [Constants.userLocationId];
        } else {
            payload.filters["destinationLocationIds"] = [Constants.userLocationId];
        }
        this.setState(state => ({
            pendingAwb: [],
            loading: { ...state.loading, pending: true },
        }))
        payload.filters["deviceFilter"] = {
            deviceType: "DESKTOP"
        };
        (new ScanService).fetchConsigmentUnassigned(payload).then(({ data }) => {
            const pendingAwb = [];
            if (data != null) {
                const consignments = data.response.consignments;
                consignments.forEach(element => {
                    if (element.consignmentBO != null && element.arrived) {
                        pendingAwb.push(element.consignmentBO);
                    }
                });

                this.setState(state => ({
                    pendingAwb,
                    loading: { ...state.loading, pending: false },
                }))
            }
        });
    }
    goBack = () => {
        this.props.history.goBack()
    }
    getDevices = () => {
        this.setState(state => ({
            dropDownText: {
                ...state.dropDownText,
                device: "Loading..."
            }
        }), () => {
            (new AssetService).getAvailableDevices().then(({ data }) => {
                if (
                    data &&
                    data.response.trackerDevices &&
                    data.response.trackerDevices.length > 0
                ) {
                    return this.populateDevices(data.response.trackerDevices);
                } else {
                    this.patchDropDownMessage();
                }
            });
        })
    }

    populateDevices = (data) => {
        if (data == null || data.length <= 0) {
            return;
        }

        this.updateDevices(data);
    }
    patchDeviceList(imei) {
        const param = {
            _id: imei
        };
        this.updateDevices([param]);
    }
    updateDevices = (param) => {
        const tempDevices = this.state.devices.concat(param);
        this.setState({
            devices: [...tempDevices]
        })
        this.patchDropDownMessage();
    }
    patchDropDownMessage = () => {
        this.setState({
            dropDownText: {
                device: this.state.devices.length
                    ? "Select a device"
                    : "No devices found"
            }
        });
    }
    isActionAllowed = () => {
        return this.state.scanAllowed && !this.state.loading.pending && !this.state.loading.selected;
    }
    removeSelected = (index) => {
        let { pendingAwb, selectedAwb } = { ...this.state };

        pendingAwb.push(selectedAwb[index]);
        pendingAwb = [...pendingAwb];
        selectedAwb.splice(index, 1);
        selectedAwb = [...selectedAwb];

        this.setState({
            pendingAwb,
            selectedAwb
        }, () => {
            Mixpanel.track("B2B DRS waybill (AWB) removed", { 'ERPInventoryDRSFormAWBRemoved': moment().valueOf() });
            this.checkSelectedAwbValidation();
        })
    }

    addSelected = (index = -1) => {
        console.log(index)
        if (index === -1) {
            this.state.drs.awb = this.state.drs.awb ? this.state.drs.awb.trim() : "";
            index = this.getScanIndex(this.state.drs.awb);
        }
        let scanEntry = isScanedOrKeyboardEntry(
            this.state.inputEntryTimeList
        );
        if (index >= 0) {
            this.scanAllowed = false;
            const validatePayload = {
                id: this.state.pendingAwb[index].id,
                waybillNo: this.state.pendingAwb[index].waybillNo,
                flowType: this.state.flowType
            };
            this.validateConsignment(validatePayload).then(({ data }) => {
                if (data.response.sync) {
                    let { pendingAwb, selectedAwb } = this.state;
                    selectedAwb.push(pendingAwb[index]);
                    selectedAwb = [...selectedAwb];
                    pendingAwb.splice(index, 1);
                    pendingAwb = [...pendingAwb];
                    this.setState({
                        pendingAwb,
                        selectedAwb,
                        lastScanTime: 0,
                        scanEntry: false,
                        currentScanEntryTime: new Date().getTime(),
                        inputEntryTimeList: [],
                    }, () => {
                        Mixpanel.track("B2B DRS waybill (AWB) added", { 'ERPInventoryDRSFormAWB': moment().valueOf() });
                        this.setAlertMessage(
                            'success',
                            <>Last scanned waybill: <b>{
                                this.state.selectedAwb[this.state.selectedAwb.length - 1].waybillNo
                            }</b></>
                        );
                    })

                } else {
                    notifyApiError(
                        data.response.reason,
                        "Validation",
                    );
                }
                this.setState({
                    scanAllowed: true
                })
                this.checkSelectedAwbValidation();
            });
        } else {
            this.setAlertMessage(
                'error',
                <div><strong>{this.state.drs.awb}</strong> waybill not found.This error can happen
            either if the AWB has not inscanned or added to an another DRS</div>
            );
            this.checkSelectedAwbValidation();
        }
        this.setState({
            drs: { awb: "" }
        })
    }

    setAlertMessage(type, message) {
        this.setState({
            alert: { visible: true, type: type, message: message }
        })
    }

    validateConsignment = (waybill) => {
        const payload = {
            drs: {
                consignments: [waybill],
                partner: { id: Constants.PARTNER_ID },
                originatedLoc: { id: Constants.userLocationId },
                flowType: this.state.flowType
            },
            referenceId: waybill.waybillNo,
            action: ApiActions.link
        };
        return (new DrsService).validateConsignment(payload);
    }

    checkSelectedAwbValidation = () => {
        const awbsValue = this.state.selectedAwb.length ? this.state.selectedAwb.length : null;
        this.props.form.setFieldsValue({
            awbs: awbsValue
        });
    }

    getScanIndex = (awb) => {
        let index = -1;
        const filteredObj = this.state.pendingAwb.find((item, i) => {
            if (item.waybillNo === awb) {
                index = i;
                return i;
            }
        });
        return index;
    }

    onAssetChange = (assetId) => {
        if (!assetId) {
            assetId = this.props.form.getFieldValue('assetId')
        }
        const assetObj = this.state.assets.find(a => a.id === assetId);
        let errorMessage = null;
        if (!assetId) {
            setTimeout(this.props.form.setFieldsValue({ assetId: undefined }), 100)
            return;
        }
        const isSameAsset = !(
            this.state.drsId && assetId === this.state.drsData.asset.id
        );
        if (isSameAsset) {
            if (this.state.assetIdToAssignedDrsMap[assetId]) {
                errorMessage = `${assetObj.vehicleNumber} - ${
                    assetObj.vehicleType
                    } already mapped with active DRS`;
            } else if (this.state.assetIdToAssignedTHCMap[assetId]) {
                errorMessage = `${assetObj.vehicleNumber} - ${
                    assetObj.vehicleType
                    } already mapped with active THC`;
            }
        }
        if (errorMessage) {
            notifyApiError(errorMessage, 'Validation');
            setTimeout(this.props.form.setFieldsValue({
                assetId: undefined
            }), 100)
        }
    }

    getDRSCode = () => {
        if (this.state.drsId) {
            return false;
        }
        MiscService
            .getCode(getCodeParam(this.state.flowType, "DRS"))
            .then(({ data }) => {
                if (data != null) {
                    this.props.form.setFieldsValue({
                        drsId: data.response.code
                    });
                }
            });
    }

    onReturnTypeChange = (e) => {
        this.setState({
            flowType: e.target.value
        }, () => {
            this.getConsignments();
            this.clearSelectedConsignments();
        })
    }

    clearSelectedConsignments = () => {
        const selectedWaybillsLength = this.selectedAwb.length;
        if (selectedWaybillsLength) {
            for (let i = selectedWaybillsLength - 1; i >= 0; i--) {
                setTimeout(() => this.removeSelected(i), 100)
            }
        }
    }

    submitDrs = (e) => {
        e.preventDefault();
        const that = this;
        this.props.form.validateFieldsAndScroll((err, drs) => {
            if (!err) {
                this.setState({
                    disableSB: true
                })
                let drsCode = drs.drsId;
                if (this.state.drsId) {
                    drsCode = this.state.drsData.drsCode;
                }
                const user = Constants.sessionUser;
                const assetId = this.state.assets.find(a => a.id === drs.assetId)
                const waybills = [];
                const flowType =
                    this.state.flowType === FlowTypes.RTO ? drs.flowType : this.state.flowType;
                this.state.selectedAwb.forEach(waybill => {
                    waybills.push({
                        id: waybill.id,
                        waybillNo: waybill.waybillNo,
                        flowType: flowType
                    });
                });
                const drsData = {
                    request: [
                        {
                            drs: {
                                drsCode: drsCode,
                                asset: {
                                    id: assetId.id,
                                    imei: drs.deviceImei
                                },
                                drsUser: {
                                    id: assetId.assignedUserId
                                },
                                consignments: waybills,
                                flowType: flowType
                            },
                            drsCode: drsCode,
                            referenceId: drsCode
                        }
                    ]
                };
                if (this.state.drsId) {
                    drsData.request[0]["action"] = "UPDATE";
                    drsData.request[0].drs["id"] = this.state.drsId;
                    drsData.request[0].drs["updatedAt"] = Date.now();
                } else {
                    drsData.request[0]["action"] = "CREATE";
                    const drsPayload = {
                        drsStatus: "PENDING",
                        originatedLoc: { id: Constants.userLocationId },
                        createdAt: Date.now(),
                        partner: { id: Constants.PARTNER_ID },
                        drsType: Constants.SELECTED_APPLICATION
                    };
                    drsData.request[0]["drs"] = Object.assign(
                        drsData.request[0]["drs"],
                        drsPayload
                    );
                }

                (new DrsService)
                    .updateDrs(drsData)
                    .then(({ data }) => {
                        if (
                            validateSyncData(data.response.responses) ||
                            data.response.length > 1
                        ) {
                            Mixpanel.track("B2B DRS form submit successfull", { 'ERPInventoryDRSFormSubmitSuccess': moment().valueOf() });
                            this.props.history.push(
                                `/appv2${getRouteName(
                                    this.state.flowType,
                                    "drs",
                                    'dashboard'
                                )}`
                            );
                        } else {
                            Mixpanel.track("B2B DRS form submit failed", { 'ERPInventoryDRSFormSubmitFail': moment().valueOf() });
                            this.setState({
                                disableSB: false
                            })
                        }
                    });
            }
        })

    }

    render() {
        const { scanAllowed, flowTypes, flowType, selectedAwb, pendingAwb, dropDownText, loading, assets } = this.state;
        const { selectedAwbColumns, pendingAwbColumns, alert, drsIdInputDisable, drsId, devices } = this.state;
        const { configrations } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        getFieldDecorator('awbs', {
            rules: [
                {
                    required: false,
                    message: 'Please select awbs'
                }
            ]
        })
        return (
            <div className="b2b-form">
                <Form {...formItemLayout} >
                    <Row gutter={12}>
                        <Col span={14}>
                            <Card title={`${drsId ? 'Update ' : 'Create '}${(flowType === flowTypes.RTO) ? 'Return ' : ''}DRS`} style={{ height: '100vh' }}>
                                <Form.Item label="Scan">
                                    <Input.Search
                                        placeholder="Scan waybill"
                                        readOnly={!scanAllowed}
                                        onChange={e => this.setState({ drs: { awb: e.target.value } })}
                                        value={this.state.drs.awb}
                                        enterButton
                                        onSearch={val => this.setState({ drs: { awb: val } }, () => this.addSelected())}
                                    />
                                </Form.Item>
                                {
                                    alert.visible && <Alert message={alert.message} type={alert.type} />
                                }
                                <Divider />
                                <Form.Item label="DRS ID">
                                    {getFieldDecorator('drsId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input DRS ID!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter or Generate DRS ID"
                                            disabled={drsIdInputDisable}
                                            suffix={
                                                <Icon onClick={e => !drsIdInputDisable ? this.getDRSCode() : {}} type="sync" style={{ cursor: drsIdInputDisable ? 'block' : 'pointer', color: 'rgba(0,0,0,.45)', '&:hover': { color: 'black' } }} />
                                            }
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Asset">
                                    {getFieldDecorator('assetId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input Asset"
                                            },
                                            {
                                                validator: (rule, value) => { this.onAssetChange(value); return true; }
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Asset" showSearch filterOption={(input, option) => { return option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 }
                                        } showArrow>
                                            {assets?.map(a => (
                                                <Select.Option
                                                    key={a.id}
                                                    value={a.id}
                                                >
                                                    {`${a.vehicleType}${(a.vehicleNumber) ? ' - ' + a.vehicleNumber : ''}`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                {
                                    flowType === flowTypes.RTO && <Form.Item label="Return Type">
                                        {getFieldDecorator('flowType', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input return type!',
                                                },
                                            ],
                                        })(
                                            <Radio.Group name="radiogroup" onChange={e => this.onReturnTypeChange(e)}>
                                                <Radio value={flowTypes.RTO}>{flowTypes.RTO}</Radio>
                                                <Radio value={flowTypes.RTS}>{flowTypes.RTS}</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                }
                                {
                                    configrations.portablegps && <Form.Item label="Device">
                                        {getFieldDecorator('deviceImei', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Please input device"
                                                }
                                            ],
                                        })(
                                            <Select onChange={e => this.onAssetChange()} placeholder={dropDownText.devices} showSearch filterOption={(input, option) =>
                                                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                            } showArrow>
                                                {devices?.map(d => (
                                                    <Select.Option
                                                        key={d.id}
                                                        value={d._id}
                                                        label={d.id}
                                                    >
                                                        {d.id}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>

                                }
                                <Divider />
                                <Row gutter={12}>
                                    <Col span={4} offset={15} >
                                        <Button size="large" onClick={this.goBack} >CANCEL</Button>
                                    </Col>
                                    <Col span={5}>
                                        <Button size="large" htmlType="button" onClick={this.submitDrs} type="primary">{drsId ? 'UPDATE' : 'CREATE'} DRS</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={10}>
                            <Card title={`Selected AWB ${selectedAwb.length ? "( Count-" + selectedAwb.length + " )" : ""} `}>
                                <Table
                                    columns={selectedAwbColumns}
                                    loading={loading.selected}
                                    dataSource={selectedAwb}
                                    rowKey={row => (row.waybillNo)}
                                    pagination={{
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                    }}
                                />
                            </Card>
                            <div style={{ margin: 10 }}></div>
                            <Card title={`Pending AWB ${pendingAwb.length ? "( Count-" + pendingAwb.length + " )" : ""} `}>
                                <Table
                                    columns={pendingAwbColumns}
                                    loading={loading.pending}
                                    dataSource={pendingAwb}
                                    rowKey={row => (row.waybillNo)}
                                    pagination={{
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    configrations: state?.app?.configurations,
});
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'B2B DRS form', onValuesChange: (props, val) => { } })(B2BForm));