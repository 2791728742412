import React, { Component } from 'react';
import {
    Card, Select, Form, Radio, Button,
    Divider, Input, Icon, Row, Col, InputNumber, Steps,
    Descriptions, Table, Spin, Modal, DatePicker, Switch
} from 'antd';


import './trip.scss';
import { connect } from 'react-redux';

import * as action from "./actions";
import moment from 'moment';

import _ from 'lodash';
import TripCreateStep1 from './trip.step1';
import LoadTrip from './trip.step2';
import TripSummary from './trip.step3';
import { ScanDocketBarcode } from './trip.step4';
import { Mixpanel } from '../../../utils/mixpanel';
import * as apiUtils from '../../../common/utils/apiGateway';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import { IdentityStore } from 'aws-sdk';


const { Step } = Steps;

const { confirm } = Modal;


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}


class TripCreate extends Component {

    constructor(props) {
        super(props);
        this.refForm = React.createRef();
        this.state = {
            pendingTripFilters: {},
            current: 0,
            assetId: null,
            page: 1,
            buttonDisabled: false,
            createTripDisabled: true,
            totalPcs: 0,
            totalKgs: 0,
            totalShipmentPcs: 0,
            showAssetsModal: false,
            ClChargebalePices: undefined,
            showCoLoaderFinal: true,
            fields: {
                destination: { value: undefined },
                pcs: { value: undefined },
                dispatched: { value: 'DV' },
                triptype: { value: 'PLANNED' },
                loadtype: { value: 'PTL' },
                vehiclenumber: { value: undefined },
                gpsdevice: { value: 'pgps' },
                mobilenumber: { value: undefined },
                gpsid: { value: undefined },
                drivername: { value: undefined },
                route: { value: undefined },
                reverse: { value: false },
                customer: { value: undefined },
                coloadpartner: { value: undefined },
                trackingnumber: { value: undefined },
                serviceprovider: { value: undefined },
                pickupTime: { value: undefined },
                vendor: { value: undefined },
                vehiclePic: { value: undefined },
                assetContactName: { value: undefined },
                assetDrivertName: { value: undefined },
                assetVehicleNumber: { value: undefined },
                assetVehicleType: { value: undefined },
                assetCapacityUnits: { value: undefined },
                assetCapacityTons: { value: undefined },
                assetPhoneNumber: { value: undefined },
                selectedBillingModel: { value: undefined },
                vehicleCapacity: { value: undefined },
                dimensionFeet: { value: undefined },
                bodyType: { value: undefined },
                shipper: { value: undefined },
                reasonCategory: { value: undefined },
                reason: { value: undefined },
                vehicleBillingType: { value: undefined },
                replace: { value: false },
                replacementAssetId: { value: undefined }

            },
            searchedVehicle: undefined,
            dockets: [],
            pendingConsigments: [],
            docketSummary: [],
            rowKeys: [],
            docketSummaryHub: {},
            shipmentScans: [],
            scannerMode: false,
            shipmentScansCount: 0,
            loadSelectedDockets: [],
            trackingIdentity: null,
            loadTripId: null,
            pendingCalled: 0,

            reasonCategories: [],
            reasons: [],
            sim_tracking_disabled: false,
            definedTrackingNumber: undefined,
            searchedAssetsEnabled: false,
            billingModel: [],
            allBillingModels: [],
            allCapacities: [],
            allDimensions: [],
            bodyTypes: [],
            createAsset: false,
            tripBillingModel: undefined,
            actualBillingModel: undefined,
            billingVehiclesList: []
        };
        this.loadDockets = this.loadDockets.bind(this);
        this.cancelTrip = this.cancelTrip.bind(this);
    }

    componentDidUpdate(oldProps) {
        if (this.props.vendorBlockReason && this.props.vendorBlockReason.blockedReason && this.props.vendorBlockReason !== oldProps.vendorBlockReason) {
            Modal.error({
                title: 'Action Blocked',
                content: <>{this.props.vendorBlockReason.blockedReason}</>,
                onOk() {

                }
            })
        }
    }

    componentDidMount() {
        Mixpanel.identify(this.props.header?.userInfo?.loggedPartnerId);
        Mixpanel.people.set({
            $first_name: this.props?.header?.userInfo?.loggedPartnerId
        });
        this.setState({ trackingIdentity: moment().valueOf() }, () => {
            Mixpanel.track("Create trip initiated", { 'tripIdentity': this.state.trackingIdentity })
        })


        if (!this.props.location?.state?.pending && !this.props.location?.state?.completed) {
            this.props.getRoutes();
            this.props.getVendorsList();
            this.getBillingModel();
        }

        let selectedDockets = [];
        if (this.props.location?.state?.pending && this.state.pendingCalled == 0) {

            let trip = this.props.location.state.pending;
            // console.log("trip->>", trip)
            let routes = trip.attributes;
            let filter = {
                filters: {
                    "revLocRouteId": routes.routeId,
                    "routeId": routes.routeId,
                    "flowTypes": ["FORWARD"],
                    "pageNo": 1,
                    "tripLoadType": routes.tripLoadType,
                    "locationIds": [`${this.props.header.userInfo.currentPartnerLocationId}`],
                    "pageSize": 10,
                    "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_UNASSIGNED_WAYBILLS_LIST" },
                    "isArrived": true,
                    "isBookedOnly": true
                }
            }
            let pendingCalled = this.state.pendingCalled + 1;
            this.setState({ pendingTripFilters: filter, pendingCalled }, () => {
                console.log("I am also here", pendingCalled, this.props.pendingDocketLoading);
                if (pendingCalled == 1 && this.props.pendingDocketLoading);
                this.next();
                // this.props.docketloadingFalse();

            });
        }

        if (this.props.location?.state?.completed && this.state.pendingCalled == 0) {

            let trip = this.props.location.state.completed;
            // console.log("trip->>", trip)
            let routes = trip.attributes;
            let filter = {
                filters: {
                    "revLocRouteId": routes.routeId,
                    "routeId": routes.routeId,
                    "flowTypes": ["FORWARD"],
                    "pageNo": 1,
                    "tripLoadType": routes.tripLoadType,
                    "locationIds": [`${this.props.header.userInfo.currentPartnerLocationId}`],
                    "pageSize": 10,
                    "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_UNASSIGNED_WAYBILLS_LIST" },
                    "isArrived": true,
                    "isBookedOnly": true
                }
            }
            let pendingCalled = this.state.pendingCalled + 1;
            this.setState({ pendingTripFilters: filter, pendingCalled }, () => {
                if (pendingCalled == 1 && this.props.pendingDocketLoading);
                this.next();
            });
        }


        if (this.props.location?.state?.props) {
            let { fields } = this.state;
            let tripProps = localStorage.getItem("props");
            let attrubutes = JSON.parse(tripProps);
            let assets = attrubutes.asset;
            console.log("attributes->>", attrubutes)
            if (attrubutes.tripType == 'CL') {
                fields = {
                    ...fields,
                    loadtype: { value: attrubutes.tripLoadType },
                    tripType: { value: attrubutes.tripLoadType === 'DV' ? 'PLANNED' : 'ADHOC' },
                    destination: { value: attrubutes.coloaderDestLocationId },
                    pcs: { value: attrubutes.chargeablePieces },
                    dispatched: { value: 'CL' },
                    pickupTime: { value: moment(Date(attrubutes.pickupTime)) },
                    vehiclePic: { value: attrubutes.podLink }
                    // pickupTime: {value: attributes.}
                    // route: {value: {"key": attrubutes.routeId, "label": attrubutes.routeName}},
                    // vehicleNumber: {value: this.props.location.state.props.routeName.asset.vehicleNumber},
                    // mobileNumber: {value: attrubutes.mobileNumber},
                    // drivername: {value: attrubutes.name}

                };

                let thcbos = attrubutes.thcbos;
                let drsbos = attrubutes.drsbos;
                let hubselection = {};

                if (thcbos.length > 0) {
                    thcbos.map(thc => {
                        thc.manifests && thc.manifests.length > 0 && thc.manifests.map(manifest => {
                            manifest.consignments && manifest.consignments.length > 0 && manifest.consignments.map(consign => {
                                selectedDockets.push(consign);
                                // hubselection = {
                                //     ...hubselection,
                                //     [consign['id']] : consign.bookingOfficeLoc.id
                                // }
                            })
                        })
                    })
                }

                if (drsbos.length > 0) {
                    drsbos.map(drb => {
                        drb.consignments && drb.consignments.length > 0 && drb.consignments.map(consign => {
                            selectedDockets.push(consign);
                            // hubselection = {
                            //     ...hubselection,
                            //     [consign['id']] : consign.bookingOfficeLoc.id
                            // }
                        });
                    })
                }
                console.log("selectedDockets->>", selectedDockets, attrubutes.id);
                this.setState(({ loadSelectedDockets }) => ({
                    loadSelectedDockets: [...selectedDockets],
                    loadTripId: attrubutes.id
                }));
            } else if (attrubutes.tripType == 'DV') {
                console.log("ati", attrubutes)
                fields = {
                    ...fields,
                    loadtype: { value: attrubutes.tripLoadType },
                    tripType: { value: attrubutes.tripLoadType === 'DV' ? 'PLANNED' : 'ADHOC' },
                    route: { value: { "key": attrubutes.routeId, "label": attrubutes.routeName } },
                    vehiclenumber: { value: assets.id },
                    mobilenumber: { value: attrubutes.mobileNumber },
                    drivername: { value: attrubutes.name },
                    pickupTime: { value: moment(Date(attrubutes.pickupTime)) },
                    vendor: { value: assets?.vendorId ? { "key": assets.vendorId } : { "key": -1, "label": "Self" } },
                    gpsdevice: { value: _.hasIn(assets, 'deviceDetails') ? 'pgps' : (attrubutes.is_sim_tracking === 'false' ? 'nodevice' : 'sim') },
                    gpsid: { value: _.hasIn(assets, 'deviceDetails') ? assets.deviceDetails.imei : undefined },
                    trackingnumber: { value: _.hasIn(assets, 'deviceDetails') ? assets.deviceDetails.mobilenumber : undefined },
                    customer: { value: { "key": attrubutes.customerId } },
                    shipper: { value: { "key": attrubutes.customerShipperId } }
                };

                if (fields?.gpsdevice?.value === 'nodevice') {
                    fields = {
                        ...fields,
                        reasonCategory: {
                            value: {
                                label: attrubutes?.noDeviceReasonCategoryName,
                                key: attrubutes?.noDeviceReasonCategoryId
                            }
                        },
                        reason: {
                            value: {
                                label: attrubutes?.noDeviceResonName,
                                key: attrubutes?.noDeviceResonId
                            }
                        },

                    }
                }



                let thcbos = attrubutes.thcbos;
                let drsbos = attrubutes.drsbos;
                let hubselection = {};
                console.log("thcbos->>", thcbos)
                if (thcbos.length > 0) {
                    thcbos.map(thc => {
                        thc.manifests && thc.manifests.length > 0 && thc.manifests.map(manifest => {
                            manifest.consignments && manifest.consignments.length > 0 && manifest.consignments.map(consign => {
                                selectedDockets.push(consign);
                                // hubselection = {
                                //     ...hubselection,
                                //     [consign['id']] : consign.bookingOfficeLoc.id
                                // }
                            })
                        })
                    })
                }

                if (drsbos.length > 0) {
                    drsbos.map(drb => {
                        drb.consignments && drb.consignments.length > 0 && drb.consignments.map(consign => {
                            selectedDockets.push(consign);
                            // hubselection = {
                            //     ...hubselection,
                            //     [consign['id']] : consign.bookingOfficeLoc.id
                            // }
                        });
                    })
                }
                console.log("selectedDockets->>", selectedDockets, attrubutes.id);
                this.setState(({ loadSelectedDockets }) => ({
                    loadSelectedDockets: [...selectedDockets],
                    loadTripId: attrubutes.id
                }));
                // this.onChangeDockets(selectedDockets);
                // console.log("hubSlection->>", hubselection);
                // this.onChangeDocketSummaryHub(hubselection);


            }



            this.setState({ fields, dockets: [...selectedDockets] }, () => {
                console.log("dockets->>", this.state.dockets)
                this.next();
            })

        }
    }

    componentWillUnmount() {
        localStorage.removeItem("props");
    }

    changeButtonDisabled = value => {
        this.setState({ buttonDisabled: value })
    }

    changeCreateTripDisabled = value => {
        this.setState({ createTripDisabled: value })
    }





    handleFormChange = changedFields => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    onChangeReverse = value => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                reverse: {
                    value: value
                },
            }
        }));
    }

    onChangeReplace = value => {
        if (value) {
            this.setState(state => ({
                fields: {
                    ...state.fields,
                    replace: {
                        value: value
                    },
                    vehicleBillingType: {
                        value: undefined
                    },
                    vendor: {
                        value: undefined
                    },
                    replacementAssetId: {
                        value: undefined
                    }

                }
            }), () => {
                if (this.state.billingVehiclesList.length <= 0) {
                    apiUtils.apiRequest({
                        url: `/b2b/v1/assets/ratecard`,
                        method: 'GET',
                    }).then(res => {
                        let billingVehiclesList = [];

                        billingVehiclesList = res?.data?.response?.assets;
                        this.setState({ billingVehiclesList })


                    });
                }

            });
        } else {
            this.setState(state => ({
                fields: {
                    ...state.fields,
                    replace: {
                        value: value
                    },
                }
            }), () => {
                console.log("this.state", this.state.fields)
                this.selectVehicle(this.state.fields.vehiclenumber.value)
            });
        }

    }

    onChangeRoute = value => {

        this.setState(state => ({
            fields: {
                ...state.fields,
                vehiclenumber: {
                    value: undefined
                },
                vehicleBillingType: {
                    value: undefined
                },
                vendor: {
                    value: undefined
                },
                drivername: {
                    value: undefined
                },
                mobilenumber: {
                    value: undefined
                }
            }
        }));
        // this.refForm.current.resetFields(['vehiclenumber'])
        // this.props.getAllAssets(value.key);
        if (this.state.fields?.loadtype?.value === 'PTL') {
            const params = { "isAvailable": true, "tripLoadType": this.state.fields?.loadtype?.value }
            this.props.getAllSimTrackingAssets(params);
        } else {
            const params = { "isAvailable": true, "tripLoadType": this.state.fields?.loadtype?.value, customerId: this.state?.fields?.customer?.value?.key }
            this.props.getAllSimTrackingAssets(params);
            // this.props.getAllAssets(value.key);
        }

    }

    searchVehicle = () => {
        // const vehicle = this.props.form.getFieldValue('vehiclenumber');
        // console.log("this", this.state.searchedVehicle);
        if (this.state.searchedVehicle.length >= 4) {
            this.setState({ createAsset: false });
            this.props.getSearchedAssets({ partialVehicleNumbers: [this.state.searchedVehicle], tripLoadType: this.state?.fields?.loadtype?.value })
        } else {
            Modal.error({
                title: 'Please enter 4 Characters'
            })
        }

        // const vehicle = this.state.fields.vehiclenumber.value;
        // console.log("vehicle", vehicle)
    }

    setVehicle = value => {
        this.setState({ searchedVehicle: value })
    }

    changeTotalPrice = (pcs, kgs, totalShipmentPcs) => {
        this.setState({ totalPcs: pcs, totalKgs: kgs, totalShipmentPcs })
    }

    selectCustomer = option => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                shipper: {
                    value: undefined
                },
                route: {
                    value: undefined
                }
            }
        }))
        const filters = {
            "entityType": "CUSTOMER",
            "entityId": option.key
        }
        this.props.getShippers(filters);
        this.props.getRoutes(option.key);
    }

    selectShipper = option => {
        // console.log("opt", option)

    }

    getBillingModel = () => {
        let { billingModel, allBillingModels } = this.state;

        if (billingModel?.length <= 0 || allBillingModels?.length <= 0) {
            apiUtils.apiRequest({
                url: `/b2b/v1/vehicle-types/getAllVehicleTypes`,
                method: 'POST',
                data: {
                    filter: {}
                }
            }).then(res => {
                let categories = [];

                categories = res?.data?.response?.vehicleTypes?.filter(cat => cat?.isDefault && cat?.isActive);
                this.setState({ billingModel: categories })


            });
        }

    }



    selectBillingModelCreate = bm => {
        this.setState(state => ({
            tripBillingModel: bm
        }))
    }

    selectBillingVehicle = vehicleId => {
        let vendorData = null;
        let blocked = false;

        this.setState(state => ({
            fields: {
                ...state.fields,
                replacementAssetId: {
                    value: vehicleId
                },
            }
        }), () => {
            this.state.billingVehiclesList.map(val => {
                if (val["id"] === vehicleId) {
                    this.setState(state => ({
                        fields: {
                            ...state.fields,
                            vehicleBillingType: {
                                value: val?.vehicleTypesBO?.id
                            }
                        },
                        tripBillingModel: val?.vehicleTypesBO?.id
                    }))
                    let d = this.props?.vendorData.map(vendor => {
                        if (val["vendorId"] == vendor.id) {
                            if (vendor.isBlocked) {
                                this.props.getVendorBlockReason(vendor.id);
                                blocked = true;
                            } else {

                                vendorData = vendor;
                                blocked = false;
                            }

                        }



                    })
                }
            })
            if (this.state.fields?.loadtype?.value === 'PTL' || this.state.fields?.loadtype?.value === 'FTL') {
                return this.state.billingVehiclesList?.map(val => {
                    if (val["id"] === vehicleId && !blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                drivername: {
                                    value: val?.userBO?.name ? val?.userBO?.name : val?.driverName
                                },
                                mobilenumber: {
                                    value: val?.userBO?.contactNumber ? val?.userBO?.contactNumber : val?.contactNumber
                                },
                                vendor: {
                                    value: vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                                },
                            },
                        }))
                        // break;
                    } else if (val["id"] === vehicleId && blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vehiclenumber: {
                                    value: undefined
                                },
                                drivername: {
                                    value: undefined
                                },
                                mobilenumber: {
                                    value: undefined
                                },
                                trackingnumber: {
                                    value: undefined
                                },
                                vendor: {
                                    value: undefined
                                }
                            },
                        }));
                    }
                })
            } else {
                return this.state.billingVehiclesList?.map(val => {
                    if (val["id"] === vehicleId && !blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                drivername: {
                                    value: val?.userBO?.name ? val?.userBO?.name : val?.driverName
                                },
                                mobilenumber: {
                                    value: val?.userBO?.contactNumber ? val?.userBO?.contactNumber : val?.contactNumber
                                },
                                trackingnumber: {
                                    value: val?.userBO?.contactNumber ? val?.userBO?.contactNumber : val?.driverMobile
                                },
                                vendor: {
                                    value: vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                                }
                            },
                        }))
                        // break;
                    } else if (val["id"] === vehicleId && blocked) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vehiclenumber: {
                                    value: undefined
                                },
                                drivername: {
                                    value: undefined
                                },
                                mobilenumber: {
                                    value: undefined
                                },
                                trackingnumber: {
                                    value: undefined
                                },
                                vendor: {
                                    value: undefined
                                }
                            },
                        }));
                    }
                })
            }
        })
    }

    selectVehicle = vehicleId => {

        let vendorData = null;
        let blocked = false;
        this.setState(state => ({
            
            fields: {
                ...state.fields,
                replacementAssetId: {
                    value: vehicleId
                }
            },
            // actualBillingModel: val?.vehicleTypesBO?.id,            
        }))
        // let billingModel = [];
        if (this.state.fields?.loadtype?.value === 'PTL' || this.state.fields?.loadtype?.value === 'FTL') {
            let { billingModel } = this.state;

            this.props.assets.map(val => {

                if (val["assetId"] === vehicleId) {
                    // if (val?.vehicleTypesBO) {
                    //     billingModel = [val?.vehicleTypesBO];
                    // }
                    if (!this.state.fields.replace.value) {
                        this.getBillingModel();
                        let findVehicleCategory = billingModel?.find(bm => bm?.id === val?.vehicleTypesBO?.id);
                        if (!val?.vehicleTypesBO?.isDefault && _.isUndefined(findVehicleCategory)) {
                            billingModel.push(val?.vehicleTypesBO);
                        }
                        this.setState(state => ({
                            billingModel,
                            fields: {
                                ...state.fields,
                                vehicleBillingType: {
                                    value: val?.vehicleTypesBO?.id
                                }
                            },
                            // actualBillingModel: val?.vehicleTypesBO?.id,
                            tripBillingModel: val?.vehicleTypesBO?.id,
                        }))


                        // this.setState({ billingModel });
                        let d = this.props?.vendorData.map(vendor => {
                            if (val["vendorId"] == vendor.id) {
                                if (vendor.isBlocked) {
                                    this.props.getVendorBlockReason(vendor.id);
                                    blocked = true;
                                } else {

                                    vendorData = vendor;
                                    blocked = false;
                                }

                            }



                        })
                    }

                    if (_.hasIn(val, 'deviceDetails')) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                gpsdevice: {
                                    value: 'nodevice'
                                }
                            }
                        }))
                    } else {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                gpsdevice: {
                                    value: 'pgps'
                                }
                            }
                        }))
                    }

                    if (_.hasIn(val, 'simTrackingConsentStatus') && val?.simTrackingConsentStatus === "ALLOWED") {
                        let index = -1;
                        let providers = [];
                        this.props.getServiceProviders();
                        apiUtils.apiRequest({
                            url: `/b2c/v1/simtracking/providers/fetch`,
                        })
                            .then(response => {
                                providers = response?.data;

                                let provider = providers?.filter(p => p?.providerName === val?.driverMobileProviderName)[0];
                                this.setState(state => ({
                                    fields: {
                                        ...state.fields,
                                        serviceprovider: {
                                            value: { label: provider?.providerName, key: provider?.id }
                                        }
                                    },
                                    sim_tracking_disabled: true
                                }))
                            })


                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                gpsdevice: {
                                    value: 'sim'
                                },

                                trackingnumber: {
                                    value: val?.driverMobile
                                }

                            },
                            sim_tracking_disabled: true,
                            definedTrackingNumber: val?.driverMobile
                        }))
                    } else {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                gpsdevice: {
                                    value: 'pgps'
                                },
                                trackingnumber: {
                                    value: undefined
                                },
                                serviceprovider: {
                                    value: undefined
                                }
                            },
                            sim_tracking_disabled: false,
                            definedTrackingNumber: undefined
                        }))
                    }
                }
            });
        } else {
            this.props.assets.map(val => {
                if (!this.state.fields.replace.value) {
                    if (val["id"] === vehicleId) {
                        let d = this.props?.vendorData.map(vendor => {
                            if (val["vendorId"] == vendor.id) {
                                if (vendor.isBlocked) {
                                    this.props.getVendorBlockReason(vendor.id);
                                    blocked = true;
                                } else {

                                    vendorData = vendor;
                                    blocked = false;
                                }

                            }



                        })
                    }

                    if (_.hasIn(val, 'deviceDetails')) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                gpsdevice: {
                                    value: 'nodevice'
                                }
                            }
                        }))
                    } else {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                gpsdevice: {
                                    value: 'pgps'
                                }
                            }
                        }))
                    }
                }
            });
        }


        if (this.state.fields?.loadtype?.value === 'PTL' || this.state.fields?.loadtype?.value === 'FTL') {
            return this.props?.assets?.map(val => {
                if (val["assetId"] === vehicleId && !blocked) {
                    this.setState(state => ({
                        fields: {
                            ...state.fields,
                            drivername: {
                                value: val?.userBO?.name ? val?.userBO?.name : val?.driverName
                            },
                            mobilenumber: {
                                value: val?.userBO?.contactNumber ? val?.userBO?.contactNumber : val?.contactNumber
                            }
                        },
                    }))
                    if (!this.state.fields.replace.value) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vendor: {
                                    value: vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                                },
                            },
                        }))
                    }
                    // break;
                } else if (val["assetId"] === vehicleId && blocked) {
                    this.setState(state => ({
                        fields: {
                            ...state.fields,
                            vehiclenumber: {
                                value: undefined
                            },
                            drivername: {
                                value: undefined
                            },
                            mobilenumber: {
                                value: undefined
                            },
                            trackingnumber: {
                                value: undefined
                            },
                        },
                    }));
                    if (!this.state.fields.replace.value) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vendor: {
                                    value: undefined
                                }
                            },
                        }));
                    }
                }
            })
        } else {
            return this.props?.assets?.map(val => {
                if (val["id"] === vehicleId && !blocked) {
                    this.setState(state => ({
                        fields: {
                            ...state.fields,
                            drivername: {
                                value: val?.userBO?.name ? val?.userBO?.name : val?.driverName
                            },
                            mobilenumber: {
                                value: val?.userBO?.contactNumber ? val?.userBO?.contactNumber : val?.contactNumber
                            },
                            trackingnumber: {
                                value: val?.userBO?.contactNumber ? val?.userBO?.contactNumber : val?.driverMobile
                            },
                        },
                    }))
                    if (!this.state.fields.replace.value) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vendor: {
                                    value: vendorData ? { "key": val.vendorId, label: vendorData.name } : { "key": -1, label: "Self" }
                                }
                            },
                        }))
                    }
                    // break;
                } else if (val["id"] === vehicleId && blocked) {
                    this.setState(state => ({
                        fields: {
                            ...state.fields,
                            vehiclenumber: {
                                value: undefined
                            },
                            drivername: {
                                value: undefined
                            },
                            mobilenumber: {
                                value: undefined
                            },
                            trackingnumber: {
                                value: undefined
                            },

                        },
                    }));
                    if (!this.state.fields.replace.value) {
                        this.setState(state => ({
                            fields: {
                                ...state.fields,

                                vendor: {
                                    value: undefined
                                }
                            },
                        }));
                    }
                }
            })
        }


    }

    onSelectVendor = vendor => {
        const { vendorData } = this.props;
        const vendorFetchData = vendorData.filter(v => v.id === vendor.key);
        if (vendorFetchData && vendorFetchData.length > 0) {
            if (vendorFetchData[0].isBlocked) {
                this.props.getVendorBlockReason(vendor.key)
                this.setState(state => ({
                    fields: {
                        ...state.fields,
                        vendor: {
                            value: undefined
                        }
                    },
                }));
            } else {
                this.setState(state => ({
                    fields: {
                        ...state.fields,
                        vendor: {
                            value: vendor
                        }
                    },
                }));
            }
        }


    }

    disabledDate = current => {
        const numberOfDays = this.props.app?.configurations?.restrictTripCreation?.pickupDate?.dayOfClosingMonth;
        return current && !(current > moment().subtract(numberOfDays ? numberOfDays : 30, "days") && current < moment().endOf("day"));
    }

    selectServiceProvider = value => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                serviceprovider: {
                    value: value
                }
            },
        }));
    }

    onSelectVehicleType = vehicle => {
        let units = undefined;
        let assetCapacity = this.props.appConfig.assetTypes.map(asset => {
            if (asset.vehicleType == vehicle) {
                units = asset.vehicleCapacity
                // setFieldsValue({assetCapacityUnits: asset.vehicleCapacity})
            }
        });




        this.setState(state => ({
            fields: {
                ...state.fields,
                assetVehicleType: {
                    value: vehicle
                },
                assetCapacityUnits: {
                    value: units
                }
            },
        }));
    }

    changePickupTime = (date, dateString) => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                pickuptime: {
                    value: date
                }
            },
        }));
    }

    changeLoadType = e => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                customer: {
                    value: undefined
                },
                route: {
                    value: undefined
                },
                shipper: {
                    value: undefined
                },
                vehiclenumber: {
                    value: undefined
                },
                vendor: {
                    value: undefined
                },
                mobilenumber: {
                    value: undefined
                },
                drivername: {
                    value: undefined
                },
                gpsdevice: { value: 'pgps' },
                reasonCategory: {
                    value: undefined
                },
                reason: {
                    value: undefined
                }
            },
        }))
        if (e.target.value === 'FTL' && !this.props.customers) {
            this.props.getAllCustomers();
        }

        if (e.target.value === 'PTL') {
            this.props.getRoutes();
        }
    }

    onUploadFinish = (e, type) => {

        this.setState(state => ({
            fields: {
                ...state.fields,
                vehiclePic: {
                    value: e
                }
            },
        }));


    }

    selectPartner = value => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                destination: {
                    value: undefined
                }
            },
        }));
        const vendorFetchData = this.props.partners.filter(p => p.linkPartnerBO.id === value);
        let isBlocked = false;
        if (vendorFetchData && vendorFetchData.length > 0) {
            if (vendorFetchData[0]?.linkPartnerBO?.partnerVendorBO) {
                if (vendorFetchData[0]?.linkPartnerBO?.partnerVendorBO?.isBlocked) {
                    isBlocked = true;
                } else {
                    isBlocked = false;
                }
            }
        }
        if (isBlocked) {
            this.props.getVendorBlockReason(vendorFetchData[0]?.linkPartnerBO?.partnerVendorBO.id);
            this.setState(state => ({
                fields: {
                    ...state.fields,
                    coloadpartner: {
                        value: undefined
                    }
                },
            }));

        } else {
            this.props.getCoLoaders(value);
        }

    }

    changeGPSTracking = e => {
        const value = e.target.value;
        if (value === "sim" && !this.props.sproviders) {
            this.props.getServiceProviders();
        }

        this.setState(state => ({
            fields: {
                ...state.fields,
                reasonCategory: {
                    value: undefined
                },
                reason: {
                    value: undefined
                }
            },
        }));

        if (value === 'nodevice' && this.state.reasonCategories.length <= 0) {
            apiUtils.apiRequest({
                url: `/b2c/v2/reasons/categories/fetch`,
                data: { "categoryFilters": { "types": ["NOT_TRACKED"], "isActive": true, "fetchObjects": ["PARTNER_REASONS"] } },
                method: "POST"
            })
                .then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        const reasonCategories = response?.data?.response?.reasonCategories;
                        this.setState({ reasonCategories });
                    }
                    else {
                        notifyApiError(response?.data?.message, "ERROR:");
                    }
                })
                .catch(error => notifyApiError(error, "ERROR:"));
        }

    }

    onSelectReasonCategory = option => {
        this.setState(state => ({
            fields: {
                ...state.fields,
                reason: {
                    value: undefined
                }
            },
        }));
        this.setState({ reasons: [] }, () => {
            const reasons = this.state.reasonCategories.filter(v => v.id === option.key)[0]?.reasons;
            this.setState({ reasons })
        })

    }


    onChangeDockets = value => {
        this.setState(({ dockets }) => ({
            dockets: value,
        }));
    }

    onChangeShipmentScans = value => {
        this.setState(({ shipmentScans }) => ({
            shipmentScans: [...value],
        }));
    }

    changeShipmentScansCount = val => {
        console.log(val)
        this.setState(({ shipmentScansCount }) => ({
            shipmentScansCount: shipmentScansCount + val
        }))
    }

    onChangeRowKeys = value => {

        this.setState((rowKeys) => ({
            rowKeys: value,
        }));
    }


    onChangeDocketsSummary = value => {
        this.setState(({ docketSummary }) => ({
            docketSummary: [...value],
        }));
    }

    onChangePendingDockets = value => {
        // this.setState(({ pendingConsigments }) => ({
        //     pendingConsigments: [...pendingConsigments, ...value],
        // }));

        this.setState(({ pendingConsigments }) => ({
            pendingConsigments: value
        }));
    }

    onChangeDocketSummaryHub = value => {

        this.setState(({ docketSummaryHub }) => ({
            docketSummaryHub: { ...value },
        }));
    }

    onChangeCharge = e => {
        this.setState({ ClChargebalePices: e })
    }

    getRefreshDockets = () => {
        let filter = {
            filters: {

                "flowTypes": ["FORWARD"],
                "pageNo": 1,

                "locationIds": [
                    `${this.props.header.userInfo.currentPartnerLocationId}`
                ],
                "pageSize": 10,
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_UNASSIGNED_WAYBILLS_LIST"
                },
                "isArrived": true,
                "isBookedOnly": true
            }
        }

        if (this.state.fields.dispatched.value === 'DV') {
            let { filters } = filter;
            filter = {
                filters: {
                    ...filters,
                    "routeId": `${this.state.fields.route.value.key}`,
                    "tripLoadType": `${this.state.fields.loadtype.value}`,
                }
            }
        }

        if (this.state.fields.loadtype.value === 'FTL') {
            let { filters } = filter;
            filter = {
                filters: {
                    ...filters,
                    "customerIds": [
                        `${this.state.fields.customer.value.key}`
                    ]
                }
            }
        }

        if (this.state.fields.dispatched.value === 'CL') {
            let { filters } = filter;


            filter = {
                filters: {
                    ...filters,
                    "destinationLocationIds": [
                        `${this.state.fields.destination.value}`
                    ]
                }
            }
        }


        if (this.state.fields.dispatched.value == 'DV') {
            this.props.getDockets(filter);
        } else {
            this.props.getCoLoaderDockets(filter);
        }
    }

    next = () => {
        // console.log("form->>", this.refForm.current, this.refs);
        // const current = this.state.current + 1;
        // this.setState({ current });

        // console.log("gps->>")

        if (this.refForm.current && this.state.current === 0) {

            if (this.props.location?.state?.pending || this.props.location?.state?.completed) {
                if (this.state.pendingCalled == 1) {
                    let pendingCalled = this.state.pendingCalled + 1;
                    this.setState({ pendingCalled }, () => {
                        // console.log("i m here",pendingCalled, this.props.pendingDocketLoading )
                        if (this.props.pendingDocketLoading) {
                            this.props.docketloadingFalse();
                            this.props.getDockets(this.state.pendingTripFilters);

                        }

                    })

                }

                const current = this.state.current + 1;

                this.setState({ current, rowKeys: [], page: 1 });
            }

            if (this.props.location?.state?.props) {
                let filter = {
                    filters: {

                        "flowTypes": ["FORWARD"],
                        "pageNo": 1,

                        "locationIds": [
                            `${this.props.header.userInfo.currentPartnerLocationId}`
                        ],
                        "pageSize": 10,
                        "deviceFilter": {
                            "deviceType": "DESKTOP",
                            "view": "LITE_UNASSIGNED_WAYBILLS_LIST"
                        },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                }
                if (this.state.fields.dispatched.value === 'DV') {
                    let { filters } = filter;
                    filter = {
                        filters: {
                            ...filters,
                            "routeId": `${this.state.fields.route.value.key}`,
                            "tripLoadType": `${this.state.fields.loadtype.value}`,
                            "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                        }
                    }
                }

                if (this.state.fields.loadtype.value === 'FTL') {
                    let { filters } = filter;
                    filter = {
                        filters: {
                            ...filters,
                            "customerIds": [
                                `${this.state.fields.customer.value.key}`
                            ],
                            "customerShipperIds": [
                                `${this.state.fields.shipper.value.key}`
                            ]
                        }
                    }
                }

                if (this.state.fields.dispatched.value === 'CL') {
                    let { filters } = filter;


                    filter = {
                        filters: {
                            ...filters,
                            // "destinationLocationIds": [
                            //     `${this.state.fields.destination.value}`
                            // ],
                            "tripLoadType": `${this.state.fields.loadtype.value}`,
                            "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                        }
                    }
                }


                if (this.state.fields.dispatched.value == 'DV') {
                    this.props.getDockets(filter);
                } else {
                    this.props.getCoLoaderDockets(filter);
                }

                const current = this.state.current + 1;
                this.setState({ current, rowKeys: [] });
            } else {
                this.refForm.current.validateFieldsAndScroll((err, values) => {
                    if (err) return;

                    let filter = {
                        filters: {

                            "flowTypes": ["FORWARD"],
                            "pageNo": 1,

                            "locationIds": [
                                `${this.props.header.userInfo.currentPartnerLocationId}`
                            ],
                            "pageSize": 10,
                            "deviceFilter": {
                                "deviceType": "DESKTOP",
                                "view": "LITE_UNASSIGNED_WAYBILLS_LIST"
                            },
                            "isArrived": true,
                            "isBookedOnly": true
                        }
                    }

                    if (this.state.fields.dispatched.value === 'DV') {
                        let { filters } = filter;
                        filter = {
                            filters: {
                                ...filters,
                                "routeId": `${this.state.fields.route.value.key}`,
                                "tripLoadType": `${this.state.fields.loadtype.value}`,
                                "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                            }
                        }
                    }

                    if (this.state.fields.loadtype.value === 'FTL') {
                        let { filters } = filter;
                        filter = {
                            filters: {
                                ...filters,
                                "customerIds": [
                                    `${this.state.fields.customer.value.key}`
                                ],
                                "customerShipperIds": [
                                    `${this.state.fields.shipper.value.key}`
                                ]
                            }
                        }
                    }

                    if (this.state.fields.dispatched.value === 'CL') {
                        let { filters } = filter;


                        filter = {
                            filters: {
                                ...filters,
                                "destinationLocationIds": [
                                    `${this.state.fields.destination.value}`
                                ],
                                // "tripLoadType": `${this.state.fields.loadtype.value}`,
                                // "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                            }
                        }
                    }


                    if (this.state.fields.dispatched.value == 'DV') {

                        if (this.state.sim_tracking_disabled) {
                            if (this.state.definedTrackingNumber === values?.trackingnumber) {

                                this.props.getDockets(filter);
                                const current = this.state.current + 1;

                                this.setState({ current, rowKeys: [], page: 1 });
                            } else {
                                const _this = this;
                                confirm({
                                    title: 'Tracking Consent is already approved.',
                                    content: 'Do you want to change the tracking number?',
                                    onOk: () => {

                                        _this.props.getDockets(filter);
                                        const current = _this.state.current + 1;

                                        _this.setState({ current, rowKeys: [], page: 1 });
                                    },
                                    onCancel: () => {

                                    },
                                    okText: 'Confirm',
                                    cancelText: 'Cancel'
                                })
                            }
                        } else {

                            this.props.getDockets(filter);
                            const current = this.state.current + 1;

                            this.setState({ current, rowKeys: [], page: 1 });
                        }


                    } else {

                        this.props.getCoLoaderDockets(filter);



                        const current = this.state.current + 1;

                        this.setState({ current, rowKeys: [], page: 1 });

                    }





                    // const current = this.state.current + 1;
                    // this.setState({ current });


                });
            }


        } else if (this.state.current === 1) {
            // console.log("dockets->>>>>", this.state.dockets)

            if (this.props?.location?.state?.props) {
                let { loadSelectedDockets } = this.state;
                // dockets.concat(loadSelectedDockets);
                // console.log("dockets->>", this.state.dockets, loadSelectedDockets)
                this.setState(prevState => ({
                    dockets: [...prevState.dockets, ...loadSelectedDockets]
                }), () => {
                    // console.log("this.state->>", this.state.dockets)
                    let filters = {
                        // "route": { "id": `${this.state.fields.route.value.key}` },
                        "action": "UPDATE",
                        "tripId": this.state.loadTripId,
                        "id": this.state.loadTripId,
                        "attributes": {
                            "tripType": `${this.state.fields.dispatched.value}`,

                        },

                        "consignments": this.state.dockets.map(d => {
                            return {
                                "id": d.id
                            }
                        })
                        // "attributes": {
                        //     "tripType": `${this.state.fields.dispatched.value}`,
                        //     "routeId": `${this.state.fields.route.value.key}`,
                        //     "routeName": `${this.state.fields.route.value.label}`,
                        //     "tripLoadType": `${this.state.fields.loadtype.value}`,
                        //     "mobileNumber": `${this.state.fields.mobilenumber.value}`,
                        //     "name": `${this.state.fields.drivername.value}`,
                        //     "isAdhocTrip": this.state.fields.triptype.value === 'ADHOC' ? true : false,
                        //     "is_sim_tracking": false,
                        // },

                        // "asset": {
                        //     "id": `${this.state.fields.vehiclenumber.value}`
                        // },
                        // "consignments": this.state.dockets.map(d => {
                        //     return {
                        //         "id": d.id
                        //     }
                        // })

                    };

                    if (this.state.fields.dispatched.value === 'CL') {
                        let { attributes } = filters;
                        attributes = {
                            ...attributes,
                            "chargeablePieces": `${this.state.fields.pcs.value}`,
                            "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                            "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                            "podLink": `${this.state.fields.vehiclePic.value}`
                        }
                        filters = {

                            ...filters,
                            "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                            attributes


                        }
                    }

                    if (this.state.fields.dispatched.value === 'DV') {
                        let { attributes } = filters;
                        attributes = {
                            ...attributes,
                            "routeId": `${this.state.fields.route.value.key}`,
                            "tripLoadType": `${this.state.fields.loadtype.value}`,
                            "mobileNumber": `${this.state.fields.mobilenumber.value}`,
                            "name": `${this.state.fields.drivername.value}`,
                            "isAdhocTrip": this.state.fields.dispatched.value === 'DV' && this.state.fields.triptype.value === 'ADHOC' ? true : false,
                            "is_sim_tracking": false,

                        }
                        if (this.state.fields.gpsdevice.value === 'nodevice') {
                            attributes = {
                                ...attributes,
                                "noDeviceReasonCategoryId": `${this.state.fields.reasonCategory.value.key}`,
                                "noDeviceReasonCategoryName": `${this.state.fields.reasonCategory.value.label}`,
                                "noDeviceResonId": `${this.state.fields.reason.value.key}`,
                                "noDeviceResonName": `${this.state.fields.reason.value.label}`,
                            }
                        }
                        if (this.state.fields.loadtype.value === 'FTL') {
                            attributes = {
                                ...attributes,
                                "customerShipperId": `${this.state.fields.shipper.value.key}`
                            }
                            filters = {
                                ...filters,
                                "customerId": `${this.state.fields.customer.value.key}`
                            }
                        }
                        filters = {

                            ...filters,
                            "route": { "id": `${this.state.fields.route.value.key}` },
                            attributes,
                            "asset": {
                                "id": `${this.state.fields.vehiclenumber.value}`
                            },

                        }
                    }

                    if (this.state.createAsset) {
                        if (!_.isNull(this.state.assetId))
                            filters['asset']['id'] = this.state.assetId;
                        else
                            filters['asset']['id'] = this.state.fields.vehiclenumber.value;
                    }
                    if (this.state.fields.dispatched.value === 'CL') {
                        let isConfirmed = false;
                        let _self = this;
                        confirm({
                            title: 'Chargeable Pcs',
                            content: <InputNumber style={{ width: '100%' }} placeholder='Charegable pcs' onChange={_self.onChangeCharge} value={_self.state.ClChargebalePices} />,
                            onOk() {
                                isConfirmed = true;
                                let { attributes } = filters;
                                // console.log("thisstate->>", _self.state, _self.state.ClChargebalePices)
                                attributes = {
                                    ...attributes,
                                    "chargeablePieces": _self.state.ClChargebalePices
                                }
                                filters = {
                                    ...filters,
                                    attributes
                                }
                                // console.log("cl->>", filters)
                                let _this = _self;
                                confirm({
                                    title: 'Delivering directly through the Co-loading partner',
                                    content: 'Selecting No, you have to choose a transit location for each docket',
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk() {

                                        // console.log("Ok Ok")
                                        _this.setState({ showCoLoaderFinal: false })
                                        _this.createTripFinalStep();
                                        // isConfirmed = true;
                                        // let { attributes } = filters;
                                        // // console.log("thisstate->>", _self.state, _self.state.ClChargebalePices)
                                        // attributes = {
                                        //     ...attributes,
                                        //     "chargeablePieces": _self.state.ClChargebalePices
                                        // }
                                        // filters = {
                                        //     ...filters,
                                        //     attributes
                                        // }
                                        // // console.log("cl->>", filters)


                                        // _self.props.docketSummaryAllocation(filters);
                                        // let test = !(_self.isSelectedDocketAsBarcode() && _self.state.scannerMode) ? 2 : 1
                                        // let current = _self.state.current + test;
                                        // // console.log(current);
                                        // _self.setState({ current, createTripDisabled: true });

                                    },
                                    onCancel() {
                                        // console.log("Cancel")
                                        _this.setState({ showCoLoaderFinal: true });
                                        let test = !(_this.isSelectedDocketAsBarcode() && _this.state.scannerMode) ? 2 : 1
                                        let current = _this.state.current + test;
                                        if (test == 2) {
                                            _this.props.docketSummaryAllocation(filters);
                                        }
                                        // console.log(current);
                                        _this.setState({ current, createTripDisabled: true });
                                    }
                                })







                                // _self.props.docketSummaryAllocation(filters);
                                // let test = !(_self.isSelectedDocketAsBarcode() && _self.state.scannerMode) ? 2 : 1
                                // let current = _self.state.current + test;
                                // // console.log(current);
                                // _self.setState({ current, createTripDisabled: true });

                            }
                        })
                    } else {
                        this.props.docketSummaryAllocation(filters);
                        let test = !(this.isSelectedDocketAsBarcode() && this.state.scannerMode) ? 2 : 1
                        let current = this.state.current + test;
                        // console.log(current);
                        this.setState({ current, createTripDisabled: true });
                    }




                })

                // this.setState({dockets: [...dockets, ...dockets]});
            } else {
                if (this.state.dockets.length > 0) {
                    let filters = {


                        "action": "CREATE",
                        "attributes": {
                            "tripType": `${this.state.fields.dispatched.value}`,

                        },

                        "consignments": this.state.dockets.map(d => {
                            return {
                                "id": d.id
                            }
                        })

                    };
                    if (this.state.fields.dispatched.value === 'CL') {
                        let { attributes } = filters;
                        attributes = {
                            ...attributes,
                            "chargeablePieces": `${this.state.fields.pcs.value}`,
                            "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                            "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                            "podLink": `${this.state.fields.vehiclePic.value}`
                        }
                        filters = {

                            ...filters,
                            "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                            attributes


                        }
                    }

                    if (this.state.fields.dispatched.value === 'DV') {
                        let { attributes } = filters;
                        attributes = {
                            ...attributes,
                            "routeId": `${this.state.fields.route.value.key}`,
                            "tripLoadType": `${this.state.fields.loadtype.value}`,
                            "mobileNumber": `${this.state.fields.mobilenumber.value}`,
                            "name": `${this.state.fields.drivername.value}`,
                            "isAdhocTrip": this.state.fields.dispatched.value === 'DV' && this.state.fields.triptype.value === 'ADHOC' ? true : false,
                            "is_sim_tracking": false,

                        }

                        if (this.state.fields.gpsdevice.value === 'nodevice') {
                            attributes = {
                                ...attributes,
                                "noDeviceReasonCategoryId": `${this.state.fields.reasonCategory.value.key}`,
                                "noDeviceReasonCategoryName": `${this.state.fields.reasonCategory.value.label}`,
                                "noDeviceResonId": `${this.state.fields.reason.value.key}`,
                                "noDeviceResonName": `${this.state.fields.reason.value.label}`,
                            }
                        }

                        if (this.state.fields.loadtype.value === 'FTL') {
                            attributes = {
                                ...attributes,
                                "customerShipperId": `${this.state.fields.shipper.value.key}`
                            }
                            filters = {
                                ...filters,
                                "customerId": `${this.state.fields.customer.value.key}`
                            }
                        }
                        filters = {

                            ...filters,
                            "route": { "id": `${this.state.fields.route.value.key}` },
                            attributes,
                            "asset": {
                                "id": `${this.state.fields.vehiclenumber.value}`
                            },

                        }
                    }




                    if (this.state.createAsset) {
                        if (!_.isNull(this.state.assetId))
                            filters['asset']['id'] = this.state.assetId;
                        else
                            filters['asset']['id'] = this.state.fields.vehiclenumber.value;
                    }

                    if (this.state.fields.dispatched.value === 'CL') {
                        let _self = this;
                        confirm({
                            title: 'Delivering directly through the Co-loading partner',
                            content: 'Selecting No, you have to choose a transit location for each docket',
                            okText: 'Yes',
                            cancelText: 'No',
                            onOk() {

                                // console.log("Ok Ok")
                                _self.setState({ showCoLoaderFinal: false })
                                _self.createTripFinalStep();
                                // isConfirmed = true;
                                // let { attributes } = filters;
                                // // console.log("thisstate->>", _self.state, _self.state.ClChargebalePices)
                                // attributes = {
                                //     ...attributes,
                                //     "chargeablePieces": _self.state.ClChargebalePices
                                // }
                                // filters = {
                                //     ...filters,
                                //     attributes
                                // }
                                // // console.log("cl->>", filters)


                                // _self.props.docketSummaryAllocation(filters);
                                // let test = !(_self.isSelectedDocketAsBarcode() && _self.state.scannerMode) ? 2 : 1
                                // let current = _self.state.current + test;
                                // // console.log(current);
                                // _self.setState({ current, createTripDisabled: true });

                            },
                            onCancel() {
                                // console.log("Cancel")
                                _self.setState({ showCoLoaderFinal: true });
                                let test = !(_self.isSelectedDocketAsBarcode() && _self.state.scannerMode) ? 2 : 1
                                let current = _self.state.current + test;
                                if (test == 2) {
                                    _self.props.docketSummaryAllocation(filters);
                                }
                                // console.log(current);
                                _self.setState({ current, createTripDisabled: true });
                            }
                        })
                    } else {
                        // console.log("ifilters->>", filters)
                        // this.props.docketSummaryAllocation(filters);
                        let test = !(this.isSelectedDocketAsBarcode() && this.state.scannerMode) ? 2 : 1
                        console.log("test->>", test)
                        if (test == 2) {
                            this.props.docketSummaryAllocation(filters);
                        }
                        let current = this.state.current + test;
                        // console.log(current);
                        this.setState({ current, createTripDisabled: true });
                    }


                }


            }
        } else if (this.state.current === 2) {
            // console.log("dockets->>>>>", this.state.dockets)

            let _self = this;
            if (this.state.shipmentScansCount !== this.state.totalShipmentPcs) {
                let isConfirmed = false;
                confirm({
                    title: 'Do you Want to skip these items?',
                    content: `${this.state.totalShipmentPcs - this.state.shipmentScansCount} out ${this.state.totalShipmentPcs} bags are not scanned`,
                    onOk() {
                        isConfirmed = true;
                        if (_self.state.dockets.length > 0) {
                            // const filters = {
                            //     "route": { "id": `${_self.state.fields.route.value.key}` },
                            //     "action": "CREATE",
                            //     "attributes": {
                            //         "tripType": `${_self.state.fields.dispatched.value}`,
                            //         "routeId": `${_self.state.fields.route.value.key}`,
                            //         "tripLoadType": `${_self.state.fields.loadtype.value}`,
                            //         "mobileNumber": `${_self.state.fields.mobilenumber.value}`,
                            //         "name": `${_self.state.fields.drivername.value}`,
                            //         "isAdhocTrip": _self.state.fields.triptype.value === 'ADHOC' ? true : false,
                            //         "is_sim_tracking": false,
                            //     },

                            //     "asset": {
                            //         "id": `${_self.state.fields.vehiclenumber.value}`
                            //     },
                            //     "consignments": _self.state.dockets.map(d => {
                            //         return {
                            //             "id": d.id
                            //         }
                            //     })

                            // };
                            let filters = {


                                "action": "CREATE",
                                "attributes": {
                                    "tripType": `${_self.state.fields.dispatched.value}`,

                                },

                                "consignments": _self.state.dockets.map(d => {
                                    return {
                                        "id": d.id
                                    }
                                })

                            };
                            if (_self.state.fields.dispatched.value === 'CL') {
                                let { attributes } = filters;
                                attributes = {
                                    ...attributes,
                                    "chargeablePieces": `${_self.state.fields.pcs.value}`,
                                    "coloaderDestLocationId": `${_self.state.fields.destination.value}`,
                                    "pickupTime": `${_self.state.fields.pickupTime.value.valueOf()}`,
                                    "podLink": `${_self.state.fields.vehiclePic.value}`
                                }
                                filters = {

                                    ...filters,
                                    "coloaderDestLocationId": `${_self.state.fields.destination.value}`,
                                    attributes


                                }
                            }

                            if (_self.state.fields.dispatched.value === 'DV') {
                                let { attributes } = filters;
                                attributes = {
                                    ...attributes,
                                    "routeId": `${_self.state.fields.route.value.key}`,
                                    "tripLoadType": `${_self.state.fields.loadtype.value}`,
                                    "mobileNumber": `${_self.state.fields.mobilenumber.value}`,
                                    "name": `${_self.state.fields.drivername.value}`,
                                    "isAdhocTrip": _self.state.fields.triptype.value === 'ADHOC' ? true : false,
                                    "is_sim_tracking": false,

                                }

                                if (_self.state.fields.gpsdevice.value === 'nodevice') {
                                    attributes = {
                                        ...attributes,
                                        "noDeviceReasonCategoryId": `${_self.state.fields.reasonCategory.value.key}`,
                                        "noDeviceReasonCategoryName": `${_self.state.fields.reasonCategory.value.label}`,
                                        "noDeviceResonId": `${_self.state.fields.reason.value.key}`,
                                        "noDeviceResonName": `${_self.state.fields.reason.value.label}`,
                                    }
                                }

                                if (_self.state.fields.loadtype.value === 'FTL') {
                                    attributes = {
                                        ...attributes,
                                        "customerShipperId": `${_self.state.fields.shipper.value.key}`
                                    }
                                    filters = {
                                        ...filters,
                                        "customerId": `${_self.state.fields.customer.value.key}`
                                    }
                                }
                                filters = {

                                    ...filters,
                                    "route": { "id": `${_self.state.fields.route.value.key}` },
                                    attributes,
                                    "asset": {
                                        "id": `${_self.state.fields.vehiclenumber.value}`
                                    },

                                }
                            }


                            console.log("create Asset", _self.state.createAsset, _self.state.assetId)

                            if (_self.state.createAsset) {
                                if (!_.isNull(_self.state.assetId))
                                    filters['asset']['id'] = _self.state.assetId;
                                else
                                    filters['asset']['id'] = _self.state.fields.vehiclenumber.value;
                            }

                            _self.props.docketSummaryAllocation(filters);
                            const current = _self.state.current + 1;
                            _self.setState({ current, createTripDisabled: true });
                        }
                    },
                    onCancel() {
                        isConfirmed = false
                    },
                });

                if (!isConfirmed) {
                    return 0;
                }
            } else {
                let filters = {


                    "action": "CREATE",
                    "attributes": {
                        "tripType": `${this.state.fields.dispatched.value}`,

                    },

                    "consignments": this.state.dockets.map(d => {
                        return {
                            "id": d.id
                        }
                    })

                };
                if (this.state.fields.dispatched.value === 'CL') {
                    let { attributes } = filters;
                    attributes = {
                        ...attributes,
                        "chargeablePieces": `${this.state.fields.pcs.value}`,
                        "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                        "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                        "podLink": `${this.state.fields.vehiclePic.value}`
                    }
                    filters = {

                        ...filters,
                        "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                        attributes


                    }
                }

                if (this.state.fields.dispatched.value === 'DV') {
                    let { attributes } = filters;
                    attributes = {
                        ...attributes,
                        "routeId": `${this.state.fields.route.value.key}`,
                        "tripLoadType": `${this.state.fields.loadtype.value}`,
                        "mobileNumber": `${this.state.fields.mobilenumber.value}`,
                        "name": `${this.state.fields.drivername.value}`,
                        "isAdhocTrip": this.state.fields.triptype.value === 'ADHOC' ? true : false,
                        "is_sim_tracking": false,

                    }

                    if (this.state.fields.gpsdevice.value === 'nodevice') {
                        attributes = {
                            ...attributes,
                            "noDeviceReasonCategoryId": `${this.state.fields.reasonCategory.value.key}`,
                            "noDeviceReasonCategoryName": `${this.state.fields.reasonCategory.value.label}`,
                            "noDeviceResonId": `${this.state.fields.reason.value.key}`,
                            "noDeviceResonName": `${this.state.fields.reason.value.label}`,
                        }
                    }
                    filters = {

                        ...filters,
                        "route": { "id": `${this.state.fields.route.value.key}` },
                        attributes,
                        "asset": {
                            "id": `${this.state.fields.vehiclenumber.value}`
                        },

                    }
                }


                if (this.state.createAsset) {
                    if (!_.isNull(this.state.assetId))
                        filters['asset']['id'] = this.state.assetId;
                    else
                        filters['asset']['id'] = this.state.fields.vehiclenumber.value;
                }

                _self.props.docketSummaryAllocation(filters);
                const current = _self.state.current + 1;
                _self.setState({ current, createTripDisabled: true });
            }


        }



    }

    loadDockets(page, pageSize, wayBill = null) {
        if (wayBill == null) {
            this.setState({ page });
            if (this.props.location?.state?.pending) {
                let trip = this.props.location.state.pending;
                // console.log("trip->>", trip)
                let routes = trip.attributes;
                let filter = {
                    filters: {
                        "revLocRouteId": routes.routeId,
                        "routeId": routes.routeId,
                        "flowTypes": ["FORWARD"],
                        "pageNo": page,
                        "tripLoadType": routes.tripLoadType,
                        "locationIds": [`${this.props.header.userInfo.currentPartnerLocationId}`],
                        "pageSize": 10,
                        "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_UNASSIGNED_WAYBILLS_LIST" },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                };

                this.props.getDockets(filter);

            } else if (this.props.location?.state?.completed) {
                let trip = this.props.location.state.completed;
                // console.log("trip->>", trip)
                let routes = trip.attributes;
                let filter = {
                    filters: {
                        "revLocRouteId": routes.routeId,
                        "routeId": routes.routeId,
                        "flowTypes": ["FORWARD"],
                        "pageNo": page,
                        "tripLoadType": routes.tripLoadType,
                        "locationIds": [`${this.props.header.userInfo.currentPartnerLocationId}`],
                        "pageSize": 10,
                        "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_UNASSIGNED_WAYBILLS_LIST" },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                };

                this.props.getDockets(filter);
            } else {
                let filter = {
                    filters: {

                        "flowTypes": ["FORWARD"],
                        "pageNo": page,

                        "locationIds": [
                            `${this.props.header.userInfo.currentPartnerLocationId}`
                        ],
                        "pageSize": 10,
                        "deviceFilter": {
                            "deviceType": "DESKTOP",
                            "view": "LITE_UNASSIGNED_WAYBILLS_LIST"
                        },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                }

                if (this.state.fields.dispatched.value === 'DV') {
                    let { filters } = filter;
                    filter = {
                        filters: {
                            ...filters,
                            "routeId": `${this.state.fields.route.value.key}`,
                            "tripLoadType": `${this.state.fields.loadtype.value}`,
                            "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                        }
                    }
                }

                if (this.state.fields.loadtype.value === 'FTL') {
                    let { filters } = filter;
                    filter = {
                        filters: {
                            ...filters,
                            "customerIds": [
                                `${this.state.fields.customer.value.key}`
                            ],
                            "customerShipperIds": [
                                `${this.state.fields.shipper.value.key}`
                            ]
                        }
                    }
                }

                if (this.state.fields.dispatched.value === 'CL') {
                    let { filters } = filter;

                    if (this.props.location?.state?.props) {
                        filter = {
                            filters: {
                                ...filters,
                                // "destinationLocationIds": [
                                //     `${this.state.fields.destination.value}`
                                // ],
                                "tripLoadType": `${this.state.fields.loadtype.value}`,
                                "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                            }
                        }
                    } else {
                        filter = {
                            filters: {
                                ...filters,
                                "destinationLocationIds": [
                                    `${this.state.fields.destination.value}`
                                ],
                                // "tripLoadType": `${this.state.fields.loadtype.value}`,
                                // "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                            }
                        }
                    }

                }


                if (this.state.fields.dispatched.value == 'DV') {
                    this.props.getDockets(filter);
                } else {
                    this.props.getCoLoaderDockets(filter);
                }
            }
        } else {
            if (this.props.location?.state?.pending) {
                let trip = this.props.location.state.pending;
                // console.log("trip->>", trip)
                let routes = trip.attributes;
                let filter = {
                    filters: {
                        "revLocRouteId": routes.routeId,
                        "routeId": routes.routeId,
                        "waybills": [wayBill],
                        "flowTypes": ["FORWARD"],
                        "pageNo": 1,
                        "tripLoadType": routes.tripLoadType,
                        "locationIds": [`${this.props.header.userInfo.currentPartnerLocationId}`],
                        "pageSize": 10,
                        "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_UNASSIGNED_WAYBILLS_LIST" },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                };

                this.props.getDockets(filter);

            } else if (this.props.location?.state?.completed) {
                let trip = this.props.location.state.completed;
                // console.log("trip->>", trip)
                let routes = trip.attributes;
                let filter = {
                    filters: {
                        "revLocRouteId": routes.routeId,
                        "routeId": routes.routeId,
                        "waybills": [wayBill],
                        "flowTypes": ["FORWARD"],
                        "pageNo": 1,
                        "tripLoadType": routes.tripLoadType,
                        "locationIds": [`${this.props.header.userInfo.currentPartnerLocationId}`],
                        "pageSize": 10,
                        "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_UNASSIGNED_WAYBILLS_LIST" },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                };

                this.props.getDockets(filter);
            } else {
                let filter = {
                    filters: {

                        "flowTypes": ["FORWARD"],


                        "locationIds": [
                            `${this.props.header.userInfo.currentPartnerLocationId}`
                        ],
                        "deviceFilter": {
                            "deviceType": "DESKTOP",
                            "view": "LITE_UNASSIGNED_WAYBILLS_LIST"
                        },
                        "isArrived": true,
                        "isBookedOnly": true
                    }
                }

                if (this.state.fields.dispatched.value === 'DV') {
                    let { filters } = filter;
                    filter = {
                        filters: {
                            ...filters,
                            "routeId": `${this.state.fields.route.value.key}`,
                            "tripLoadType": `${this.state.fields.loadtype.value}`,
                            "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                        }
                    }
                }

                if (this.state.fields.loadtype.value === 'FTL') {
                    let { filters } = filter;
                    filter = {
                        filters: {
                            ...filters,
                            "customerIds": [
                                `${this.state.fields.customer.value.key}`
                            ],
                            "customerShipperIds": [
                                `${this.state.fields.shipper.value.key}`
                            ]
                        }
                    }
                }

                if (this.state.fields.dispatched.value === 'CL') {
                    let { filters } = filter;


                    if (this.props.location?.state?.props) {
                        filter = {
                            filters: {
                                ...filters,
                                // "destinationLocationIds": [
                                //     `${this.state.fields.destination.value}`
                                // ],
                                "tripLoadType": `${this.state.fields.loadtype.value}`,
                                "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                            }
                        }
                    } else {
                        filter = {
                            filters: {
                                ...filters,
                                "destinationLocationIds": [
                                    `${this.state.fields.destination.value}`
                                ],
                                // "tripLoadType": `${this.state.fields.loadtype.value}`,
                                // "movementTypes": this.state.fields.loadtype.value ? [`${this.state.fields.loadtype.value}`] : [],
                            }
                        }
                    }
                }

                filter['filters']['waybills'] = [wayBill]
                if (this.state.fields.dispatched.value == 'DV') {
                    this.props.getDockets(filter);
                } else {
                    this.props.getCoLoaderDockets(filter);
                }
            }

        }





        // this.setState({ page });
        // let filters = {};

        // if(this.state.fields.dispatched.value === 'DV') {
        //     filters = {
        //         filters: {
        //             "routeId": `${this.state.fields.route.value.key}`,
        //             "flowTypes": ["FORWARD"],
        //             "pageNo": page,
        //             "tripLoadType": `${this.state.fields.loadtype.value}`,
        //             "locationIds": [
        //                 `${this.props.header.userInfo.loggedPartnerLocationId}`
        //             ],
        //             "pageSize": pageSize,
        //             "deviceFilter": {
        //                 "deviceType": "DESKTOP",
        //                 "view": "LITE_UNASSIGNED_WAYBILLS_LIST"
        //             },
        //             "isArrived": true,
        //             "isBookedOnly": true
        //         }
        //     }
        // }

        // if (this.state.fields.loadtype.value === 'FTL') {
        //     filters['filters']["customerIds"] = [`${this.state.fields.customer.value.key}`];
        // }

        // this.props.getDockets(filters);
    }

    prev = () => {
        let current = 1;
        // console.log("prev->>", this.state.current, this.state.scannerMode)
        if (this.state.current == 3 && !this.state.scannerMode) {
            current = this.state.current - 2;
            if (this.state.scannerMode) {
                this.setState({ rowKeys: [] })
            }
            this.loadDockets(1, 10);
        } else {
            current = this.state.current - 1;
        }

        if (this.state.current == 2) {
            this.loadDockets(1, 10);
        }

        if (current === 0) {
            this.setState({ buttonDisabled: false })
        }

        this.setState({ current });

        // console.log(this.props.form.getFieldsValue(["destination", "pcs", "drivername"]))
    }

    cancelTrip() {
        if (this.props.location?.state?.pending) {
            this.props.docketloadingTrue();
            this.props.history.push('/appv2/trips/dashboard/pending-trips');
        }
        else if (this.props.location?.state?.completed) {
            this.props.docketloadingTrue();
            this.props.history.push('/appv2/trips/dashboard/completed-trips');
        } else {
            this.props.history.push('/appv2/trips/dashboard');
        }

    }

    createTripFinalStep() {
        if (this.props.location?.state?.pending) {
            let trip = this.props.location?.state?.pending;
            let params = {
                "requests": [
                    {
                        "addShipmentsToCompletedTrip": true,
                        "route": {
                            "id": trip.attributes.routeId
                        },
                        "shipmentScans": [],
                        "action": "UPDATE",
                        "tripId": trip.id,
                        "attributes": trip.attributes,
                        "id": trip.id,
                        "asset": {
                            "id": trip.asset.id
                        },
                        "consignments": this.state.pendingConsigments
                    }
                ]
            };

            // console.log("params->>", params);
            this.props.docketloadingTrue();
            this.props.createTrip(params);

        } else if (this.props.location?.state?.completed) {
            let trip = this.props.location?.state?.completed;
            let params = {
                "requests": [
                    {
                        "addShipmentsToCompletedTrip": true,
                        "route": {
                            "id": trip.attributes.routeId
                        },
                        "shipmentScans": [],
                        "action": "UPDATE",
                        "tripId": trip.id,
                        "attributes": trip.attributes,
                        "id": trip.id,
                        "asset": {
                            "id": trip.asset.id
                        },
                        "consignments": this.state.pendingConsigments
                    }
                ]
            };

            // console.log("params->>", params);
            this.props.docketloadingTrue();
            this.props.createTrip(params);
        } else {
            if (this.state.fields.dispatched.value == 'DV') {

                if (this?.props?.location?.state?.props) {
                    // console.log("docket summary hub ->>", this.state.docketSummaryHub);
                    let docketLocationMapping = {};
                    for (const property in this.state.docketSummaryHub) {

                        docketLocationMapping[`${property}`] = this.state.docketSummaryHub[property];

                    }





                    let selectedConsignments = [];
                    let indexes = [];

                    let { docketSummary, loadSelectedDockets } = this.state;


                    let data = docketSummary.map((ds, index) => {
                        loadSelectedDockets.map(lds => {
                            if (ds.consignmentId == lds.id) indexes.push(index);
                        })
                    });

                    docketSummary.map((ds, index) => {
                        if (indexes.indexOf(index) < 0) {
                            selectedConsignments.push({
                                "id": ds.consignmentId
                            })
                        }
                    })





                    // this.setState({docketSummary})



                    // console.log("fields->>", this.state.fields, this.state.loadSelectedDockets, indexes, selectedConsignments)


                    // this.state.docketSummary.map(docket => {
                    //    docket.candidateLocations.map(hub => )
                    // })
                    let filters = {
                        "requests": [
                            {
                                "route": {
                                    "id": `${this.state.fields.route.value.key}`
                                },
                                "shipmentScans": this.state.shipmentScans,
                                "tripId": this.state.loadTripId,
                                docketLocationMapping,
                                "id": this.state.loadTripId,
                                "action": "UPDATE",
                                "attributes": {
                                    "tripType": "DV",
                                    "routeId": `${this.state.fields.route.value.key}`,
                                    "tripLoadType": `${this.state.fields.loadtype.value}`,
                                    "mobileNumber": `${this.state.fields.mobilenumber.value}`,
                                    "is_sim_tracking": false,
                                    "name": `${this.state.fields.drivername.value}`,
                                    "isAdhocTrip": this.state.fields.triptype.value === 'ADHOC' ? true : false,
                                    "routeName": `${this.state.fields.route.value.label}`,
                                    "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                                    "vendorId": `${this.state.fields.vendor.value.key}`,
                                    "vendorName": `${this.state.fields.vendor.value.label}`
                                },
                                "asset": {
                                    "id": `${this.state.fields.vehiclenumber.value}`
                                },
                                "consignments": selectedConsignments

                            }
                        ]
                    };

                    if (this.state.fields.gpsdevice.value === 'nodevice') {
                        filters['requests'][0]['attributes']['noDeviceReasonCategoryId'] = `${this.state.fields.reasonCategory.value.key}`;
                        filters['requests'][0]['attributes']['noDeviceReasonCategoryName'] = `${this.state.fields.reasonCategory.value.label}`;
                        filters['requests'][0]['attributes']['noDeviceResonId'] = `${this.state.fields.reason.value.key}`;
                        filters['requests'][0]['attributes']['noDeviceResonName'] = `${this.state.fields.reason.value.key}`;
                    }

                    if (this.state.fields.triptype.value === 'ADHOC') {
                        if (!_.isNull(this.state.assetId))
                            filters['requests'][0]['asset']['id'] = this.state.assetId;
                        else
                            filters['requests'][0]['asset']['id'] = this.state.fields.vehiclenumber.value;
                    }

                    if (this.state.fields.vendor.value.key == -1) {
                        delete filters['requests'][0]['attributes']['vendorId'];
                        delete filters['requests'][0]['attributes']['vendorName'];
                    }

                    if (this.state.fields.gpsdevice.value == "sim") {
                        filters['requests'][0]['attributes']['is_sim_tracking'] = true;
                        filters['requests'][0]['asset']['deviceDetails'] = { "provider": this.state.fields.serviceprovider.value.label, "imei": this.state.fields.trackingnumber.value };
                        filters['requests'][0]['asset']['imei'] = this.state.fields.trackingnumber.value;
                    }

                    if (this.state.fields.gpsdevice.value === "pgps") {
                        filters['requests'][0]['asset']['deviceDetails'] = { "imei": this.state.fields.trackingnumber.value };
                    }

                    if (this.state.fields.loadtype.value === 'FTL') {
                        filters['requests'][0]['customerId'] = `${this.state.fields.customer.value.key}`;
                        filters['requests'][0]['attributes']['customerShipperId'] = `${this.state.fields.shipper.value.key}`;
                    }

                    console.log("Filters->>", filters)


                    this.props.createTrip(filters);
                } else {
                    let docketLocationMapping = {};
                    for (const property in this.state.docketSummaryHub) {

                        docketLocationMapping[`${property}`] = this.state.docketSummaryHub[property];

                    }




                    // this.state.docketSummary.map(docket => {
                    //    docket.candidateLocations.map(hub => )
                    // })
                    let filters = {
                        "requests": [
                            {
                                "route": {
                                    "id": `${this.state.fields.route.value.key}`
                                },
                                "shipmentScans": this.state.shipmentScans,
                                docketLocationMapping,
                                "action": "CREATE",
                                "attributes": {
                                    "tripType": "DV",
                                    "routeId": `${this.state.fields.route.value.key}`,
                                    "tripLoadType": `${this.state.fields.loadtype.value}`,
                                    "mobileNumber": `${this.state.fields.mobilenumber.value ? this.state.fields.mobilenumber.value : this.state.fields.assetPhoneNumber.value}`,
                                    "is_sim_tracking": false,
                                    "name": `${this.state.fields.drivername.value ? this.state.fields.drivername.value : this.state.fields.assetDrivertName.value}`,
                                    "isAdhocTrip": this.state.fields.triptype.value === 'ADHOC' ? true : false,
                                    "routeName": `${this.state.fields.route.value.label}`,
                                    "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                                    "vendorId": `${this.state.fields.vendor.value.key}`,
                                    "vendorName": `${this.state.fields.vendor.value.label}`,
                                    // "actualBillingModel": `${this.state.actualBillingModel}`,
                                    "tripBillingModel": `${this.state.tripBillingModel}`,
                                },
                                "asset": {
                                    "id": `${this.state.fields.vehiclenumber.value}`,
                                    "vehicleBillingType": {
                                        "id": `${this.state.fields.vehicleBillingType.value}`
                                    }
                                },
                                "consignments": this.state.docketSummary.map(d => {
                                    return {
                                        "id": d.consignmentId
                                    }
                                })

                            }
                        ]
                    };

                    if (this.state.createAsset) {
                        if (!_.isNull(this.state.assetId))
                            filters['requests'][0]['asset']['id'] = this.state.assetId;
                        else
                            filters['requests'][0]['asset']['id'] = this.state.fields.vehiclenumber.value;
                    }

                    if (this.state.fields.vendor.value.key == -1) {
                        delete filters['requests'][0]['attributes']['vendorId'];
                        delete filters['requests'][0]['attributes']['vendorName'];
                    }

                    if (this.state.fields.gpsdevice.value === 'nodevice') {
                        filters['requests'][0]['attributes']['noDeviceReasonCategoryId'] = `${this.state.fields.reasonCategory.value.key}`;
                        filters['requests'][0]['attributes']['noDeviceReasonCategoryName'] = `${this.state.fields.reasonCategory.value.label}`;
                        filters['requests'][0]['attributes']['noDeviceResonId'] = `${this.state.fields.reason.value.key}`;
                        filters['requests'][0]['attributes']['noDeviceResonName'] = `${this.state.fields.reason.value.key}`;
                    }
                    if (this.state.fields.replace.value) {
                        filters['requests'][0]['attributes']['billingAssetId'] = `${this.state.fields.replacementAssetId.value}`;
                    }

                    if (this.state.fields.gpsdevice.value == "sim") {
                        filters['requests'][0]['attributes']['is_sim_tracking'] = true;
                        filters['requests'][0]['asset']['deviceDetails'] = { "provider": this.state.fields.serviceprovider.value.label, "imei": this.state.fields.trackingnumber.value };
                        filters['requests'][0]['asset']['imei'] = this.state.fields.trackingnumber.value;
                    }

                    if (this.state.fields.gpsdevice.value === "pgps") {
                        filters['requests'][0]['asset']['deviceDetails'] = { "imei": this.state.fields.trackingnumber.value };
                    }

                    if (this.state.fields.loadtype.value === 'FTL') {
                        filters['requests'][0]['customerId'] = `${this.state.fields.customer.value.key}`;
                        filters['requests'][0]['attributes']['customerShipperId'] = `${this.state.fields.shipper.value.key}`;
                    }

                    console.log("Filters->>", filters)
                    Mixpanel.track("Create trip Started", { 'tripFilters': filters, 'seacrhedViaAssets': this.state.searchedAssetsEnabled })

                    this.props.createTrip(filters);
                }


            } else {

                if (this.props.location?.state?.props) {
                    let docketLocationMapping = {};
                    for (const property in this.state.docketSummaryHub) {

                        docketLocationMapping[`${property}`] = this.state.docketSummaryHub[property];

                    }

                    let selectedConsignments = [];
                    let indexes = [];

                    let { docketSummary, loadSelectedDockets } = this.state;


                    let data = docketSummary.map((ds, index) => {
                        loadSelectedDockets.map(lds => {
                            if (ds.consignmentId == lds.id) indexes.push(index);
                        })
                    });

                    docketSummary.map((ds, index) => {
                        if (indexes.indexOf(index) < 0) {
                            selectedConsignments.push({
                                "id": ds.consignmentId
                            })
                        }
                    })


                    let filters = null;
                    if (this.props.appConfig.handover_to_coloader == 'false') {
                        filters = {
                            "requests": [
                                {
                                    "shipmentScans": this.state.shipmentScans,
                                    "tripId": this.state.loadTripId,
                                    "id": this.state.loadTripId,
                                    "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                    "action": "UPDATE",
                                    "attributes": {
                                        "tripType": `${this.state.fields.dispatched.value}`,
                                        "tripLoadType": `${this.state.fields.loadtype.value}`,
                                        "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                        "chargeablePieces": `${this.state.fields.pcs.value}`,
                                        "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                                        "podLink": `${this.state.fields.vehiclePic.value}`
                                    },
                                    "consignments": selectedConsignments

                                }
                            ]
                        };
                    } else {
                        filters = {
                            "requests": [
                                {
                                    "shipmentScans": this.state.shipmentScans,
                                    "tripId": this.state.loadTripId,
                                    "id": this.state.loadTripId,
                                    "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                    "action": "UPDATE",
                                    "attributes": {
                                        "tripType": `${this.state.fields.dispatched.value}`,
                                        "tripLoadType": `${this.state.fields.loadtype.value}`,
                                        "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                        "chargeablePieces": `${this.state.fields.pcs.value}`,
                                        "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                                        "podLink": `${this.state.fields.vehiclePic.value}`
                                    },
                                    "consignments": selectedConsignments,
                                    docketLocationMapping

                                }
                            ]
                        };
                    }

                    if (this.state.fields.reverse.value) {
                        filters['requests'][0]['type'] = "REVERSE";
                    }



                    console.log("Filters->>", filters)

                    this.props.createTrip(filters);
                } else {
                    let docketLocationMapping = {};
                    for (const property in this.state.docketSummaryHub) {

                        docketLocationMapping[`${property}`] = this.state.docketSummaryHub[property];

                    }
                    let filters = null;
                    if (this.props.appConfig.handover_to_coloader == 'false') {
                        filters = {
                            "requests": [
                                {
                                    "shipmentScans": this.state.shipmentScans,
                                    "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                    "action": "CREATE",
                                    "attributes": {
                                        "tripType": `${this.state.fields.dispatched.value}`,
                                        "tripLoadType": `${this.state.fields.loadtype.value}`,
                                        "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                        "chargeablePieces": `${this.state.fields.pcs.value}`,
                                        "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                                        "podLink": `${this.state.fields.vehiclePic.value}`
                                    },
                                    "consignments": this.state.dockets.map(d => {
                                        return {
                                            "id": d.id
                                        }
                                    }),

                                }
                            ]
                        };
                    } else {
                        filters = {
                            "requests": [
                                {
                                    "shipmentScans": this.state.shipmentScans,
                                    "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                    "action": "CREATE",
                                    "attributes": {
                                        "tripType": `${this.state.fields.dispatched.value}`,
                                        "tripLoadType": `${this.state.fields.loadtype.value}`,
                                        "coloaderDestLocationId": `${this.state.fields.destination.value}`,
                                        "chargeablePieces": `${this.state.fields.pcs.value}`,
                                        "pickupTime": `${this.state.fields.pickupTime.value.valueOf()}`,
                                        "podLink": `${this.state.fields.vehiclePic.value}`
                                    },
                                    "consignments": this.state.dockets.map(d => {
                                        return {
                                            "id": d.id
                                        }
                                    }),
                                    docketLocationMapping

                                }
                            ]
                        };
                    }

                    if (this.state.fields.reverse.value) {
                        filters['requests'][0]['type'] = "REVERSE";
                    }

                    console.log("Filters->>", filters)


                    this.props.createTrip(filters);

                }


            }
        }


    }

    selectBillingModel = bm => {
        const allCapacities = this.state?.allBillingModels?.filter(b => b?.vehicleType === bm)[0];
        // this.setState({ allCapacities: allCapacities?.capacityBOS });
        this.setState(state => ({
            fields: {
                ...state.fields,

                vehicleCapacity: {
                    value: undefined
                },
                bodyType: {
                    value: undefined
                },
                dimensionFeet: {
                    value: undefined
                }
            },
            allCapacities: allCapacities?.capacityBOS
            // bodyTypes: bodies?.bodyTypeBOS
            // allDimensions: allDimensions?.lbhDetailsBOS

        }));

    }

    selectCapacity = cp => {
        // const allDimensions = this.state?.allCapacities?.filter(b => b?.vehicleCapacity === cp)[0];
        const bodies = this.state?.allCapacities?.filter(b => b?.vehicleCapacity === cp)[0];
        this.setState(state => ({
            fields: {
                ...state.fields,

                vehicleCapacity: {
                    value: cp
                },
                bodyType: {
                    value: undefined
                },
                dimensionFeet: {
                    value: undefined
                }
            },
            bodyTypes: bodies?.bodyTypeBOS
            // allDimensions: allDimensions?.lbhDetailsBOS

        }), () => {
            const bodyType = this.state.bodyTypes[0]?.bodyType;
            this.selectBodyType(bodyType)
            this.setState(state => ({
                fields: {
                    ...state.fields,

                    bodyType: {
                        value: bodyType
                    }
                },

            }));
        });
    }

    selectBodyType = body => {
        const allDimensions = this.state?.bodyTypes?.filter(b => b?.bodyType === body)[0];
        this.setState(state => ({
            fields: {
                ...state.fields,

                bodyType: {
                    value: body
                },
                dimensionFeet: {
                    value: undefined
                }
            },
            allDimensions: allDimensions?.lbhDetailsBOS

        }), () => {
            const dimensionFeet = this.state.allDimensions.filter(d => d?.isDefault);
            if (dimensionFeet?.length > 0) {
                this.setState(state => ({
                    fields: {
                        ...state.fields,

                        dimensionFeet: {
                            value: dimensionFeet[0]?.id
                        }
                    },

                }));
            }
        });
    }

    getAssetBillingModel = () => {
        apiUtils.apiRequest({
            method: 'GET',
            url: `/b2b/v1/vehicle-types/getVehicleTypes`
        }).then(res => {
            let { allBillingModels } = this.state;
            if (allBillingModels.length <= 0) {


                allBillingModels = res?.data?.response?.vehicleTypesGroupedBOS;

                this.setState({ allBillingModels })
            }


        })
    }

    openAssetModal = () => {
        // if (this.state.allBillingModels.length <= 0) {
        //     apiUtils.apiRequest({
        //         method: 'GET',
        //         url: `/b2b/v1/vehicle-types/getVehicleTypes`
        //     }).then(res => {
        //         let { allBillingModels } = this.state;

        //         allBillingModels = res?.data?.response?.vehicleTypesGroupedBOS;
        //         console.log("allBillingModels", allBillingModels)
        //         this.setState({ allBillingModels }, () => {
        //             this.setState({ showAssetsModal: true });
        //         })

        //     })
        // } else {
        //     this.setState({ showAssetsModal: true });
        // }

        // this.getBillingModel();
        this.getAssetBillingModel();
        this.setState({ showAssetsModal: true });


    }


    cancelAssetModal = () => {

        this.setState({ showAssetsModal: false });
    }
    submitAsset = () => {
        if (this.state.fields.assetPhoneNumber.value && this.state.fields.assetContactName.value && this.state.fields.assetContactName.value &&
            this.state.fields.vendor.value.label && this.state.fields.assetVehicleNumber.value && this.state.fields.assetVehicleNumber.value &&
            this.state.fields.assetDrivertName.value && this.state.fields.selectedBillingModel.value && this.state.fields.vehicleCapacity.value &&
            this.state.fields.bodyType.value && this.state.fields.dimensionFeet.value
        ) {
            let filters = {
                "adhocRequest": {
                    "userBO": {
                        "password": "Ton",
                        "contactNumber": `${this.state.fields.assetPhoneNumber.value}`,
                        "name": `${this.state.fields.assetDrivertName.value}`,
                        "email": `${this.state.fields.assetDrivertName.value}@gmail.com`,
                        "username": `${this.state.fields.assetDrivertName.value}`
                    }
                },
                "asset": {
                    "contactPersonName": `${this.state.fields.assetContactName.value}`,
                    "ownerName": `${this.state.fields.vendor.value.label}`,
                    "contactPersonMobile": `${this.state.fields.assetPhoneNumber.value}`,
                    "registrationNumber": `${this.state.fields.assetVehicleNumber.value}`,
                    "vendorId": `${this.state.fields.vendor.value.key}`,
                    "vehicleNumber": `${this.state.fields.assetVehicleNumber.value}`,
                    "driverName": `${this.state.fields.assetDrivertName.value}`,
                    "attributes": {
                        "assetSubType": "MARKET_ASSET"
                    },
                    // "vehicleType": `${this.state.fields.selectedBillingModel.value}`,
                    "capacityUnit": "Ton",
                    // "capacity": `${this.state.fields.vehicleCapacity.value}`,
                    "assetType": "ADHOC_ASSET",
                    "vehicleTypesBO": {
                        "id": `${this.state.fields.dimensionFeet.value}`
                    }
                }
            }
            // console.log("asset", filters)

            action.createAsset(filters)
                .then(res => {

                    if (res?.data?.status.code == 200) {
                        let response = res?.data?.response.assets[0];

                        notifyApiSuccess("Asset Created successfully!!", "SUCCESS!!");
                        this.setState(state => ({
                            fields: {
                                ...state.fields,
                                vehiclenumber: {
                                    value: response.vehicleNumber
                                }
                            },
                            showAssetsModal: false,
                            assetId: response.id,
                            createAsset: true
                        }));
                        let { billingModel } = this.state;

                        if (response?.vehicleTypesBO) {
                            // console.log("response?.vehicleTypesBO?.id", response?.vehicleTypesBO?.id)
                            // billingModel.push(response?.vehicleTypesBO);
                            let findBillingModel = this.state.billingModel.find(bm => bm.id === response?.vehicleTypesBO?.id);
                            // console.log("findBillingModel", findBillingModel)
                            if (_.isUndefined(findBillingModel)) {
                                billingModel.push(response?.vehicleTypesBO);

                            }
                            this.setState({
                                // actualBIllingModel: response?.vehicleTypesBO?.id,
                                tripBillingModel: response?.vehicleTypesBO?.id,
                                // billingModel
                            }, () => {
                                this.setState(state => ({
                                    fields: {
                                        ...state.fields,
                                        vehicleBillingType: {
                                            value: response?.vehicleTypesBO?.id
                                        }
                                    }
                                }));
                            })
                        }

                        this.setState({ createAsset: true });
                    }
                })
        } else {
            notifyApiError("All fields are mandatory", "Error!!")
        }

    }

    isSelectedDocketAsBarcode = () => {
        const { dockets } = this.state
        let isIt = false;
        if (dockets.length === 0) return isIt;
        dockets.map(doc => {
            if (doc.shipmentBOs?.length > 0) isIt = true;
        })

        return isIt;

    }

    changeScannerMode = val => {
        this.setState({
            scannerMode: val
        })
    }
    handleIMEI = _.debounce((rule, value, callback) => {

        if (!this.props.location?.state?.pending && !this.props.location?.state?.completed) {
            if (!value) {
                callback('Field cannot be blank!');
            }

            const validateIMEI = action.validateIMEI(value);

            validateIMEI.then(res => {

                if (
                    res && res.data &&
                    (res.data.status.code === 422 || res.data.status.code === 400)
                ) {
                    callback(res.data.status.message);
                } else {
                    callback();
                }
            })

        }

    }, 1000)

    // handleIMEI = (rule, value, callback) => {



    // }

    changeDispatched = e => {
        if (e.target.value == 'CL') {
            if (!this.props.partners) {
                let partnerId = localStorage.getItem('currentPartnerId');
                if (partnerId == "null") {
                    partnerId = localStorage.getItem('loggedPartnerId');
                }
                const filters = { "filters": { "sourcePartnerIds": [partnerId], "partnerType": ["CO_LOADER"], "fetchObjects": ["PARTNER", "VENDOR"] } }
                this.props.getColoaderPartners(filters);
            }

        }
    }



    render() {

        const { current, fields } = this.state;

        const formItemLayout = {
            layout: 'vertical'
        };
        let steps = null;

        if (this.props.location?.state?.pending || this.props.location?.state?.completed) {
            steps = [
                {
                    title: '',
                    content: <>
                        <Form {...formItemLayout} >
                            <TripCreateStep1
                                ref={this.refForm}
                                {...this.props}
                                {...fields}
                                selectVehicle={this.selectVehicle}
                                selectServiceProvider={this.selectServiceProvider}
                                onChangeRoute={this.onChangeRoute}
                                onChange={this.handleFormChange}
                                changeLoadType={this.changeLoadType}
                                selectPartner={this.selectPartner}
                                changeGPSTracking={this.changeGPSTracking}
                                onUploadFinish={this.onUploadFinish}
                                onSelectVendor={this.onSelectVendor}
                                changePickupTime={this.changePickupTime}
                                showAssetsModal={this.state.showAssetsModal}
                                openAssetModal={this.openAssetModal}
                                cancelAssetModal={this.cancelAssetModal}
                                submitAsset={this.submitAsset}
                                disabledDate={this.disabledDate}
                                handleIMEI={this.handleIMEI}
                                onSelectVehicleType={this.onSelectVehicleType}
                                changeDispatched={this.changeDispatched}
                                reasonCategories={this.state.reasonCategories}
                                onSelectReasonCategory={this.onSelectReasonCategory}
                                reasons={this.state.reasons}
                            />
                        </Form>
                    </>,
                }, {
                    title: 'Load Dockets',
                    content: <LoadTrip
                        fields={fields}
                        onChangeRowKeys={this.onChangeRowKeys}
                        rowKeys={this.state.rowKeys}
                        page={this.state.page}
                        changeTotalPrice={this.changeTotalPrice}
                        docketSummary={this.state.docketSummary}
                        {...this.props}
                        changeButtonDisabled={this.changeButtonDisabled}
                        onChangeShipmentScans={this.onChangeShipmentScans}
                        changeCreateTripDisabled={this.changeCreateTripDisabled}
                        changeScannerMode={this.changeScannerMode}
                        onChange={this.onChangeDockets}
                        loadDockets={this.loadDockets}
                        goBack={this.prev}
                        onChangePendingDockets={this.onChangePendingDockets}
                        pendingConsigments={this.state.pendingConsigments}
                    />,
                }
            ]
        } else {
            // if (fields.dispatched.value == 'CL' && this.props.appConfig.handover_to_coloader == 'false') {
            if (fields.dispatched.value == 'CL' && this.state.showCoLoaderFinal) {
                steps = [
                    {
                        title: 'Create Trip',
                        content: <>
                            <Form {...formItemLayout} >
                                <TripCreateStep1
                                    ref={this.refForm}
                                    {...this.props}
                                    {...fields}
                                    selectVehicle={this.selectVehicle}
                                    selectServiceProvider={this.selectServiceProvider}
                                    onChangeRoute={this.onChangeRoute}
                                    onChange={this.handleFormChange}
                                    changeLoadType={this.changeLoadType}
                                    selectPartner={this.selectPartner}
                                    changeGPSTracking={this.changeGPSTracking}
                                    onUploadFinish={this.onUploadFinish}
                                    onSelectVendor={this.onSelectVendor}
                                    changePickupTime={this.changePickupTime}
                                    showAssetsModal={this.state.showAssetsModal}
                                    openAssetModal={this.openAssetModal}
                                    cancelAssetModal={this.cancelAssetModal}
                                    submitAsset={this.submitAsset}
                                    disabledDate={this.disabledDate}
                                    handleIMEI={this.handleIMEI}
                                    onSelectVehicleType={this.onSelectVehicleType}
                                    changeDispatched={this.changeDispatched}
                                    selectCustomer={this.selectCustomer}
                                    selectShipper={this.selectShipper}
                                    reasonCategories={this.state.reasonCategories}
                                    onSelectReasonCategory={this.onSelectReasonCategory}
                                    reasons={this.state.reasons}

                                />
                            </Form>
                        </>,
                    },
                    {
                        title: 'Load Dockets',
                        content: <LoadTrip
                            fields={fields}
                            onChangeRowKeys={this.onChangeRowKeys}
                            rowKeys={this.state.rowKeys}
                            page={this.state.page}
                            changeTotalPrice={this.changeTotalPrice}
                            docketSummary={this.state.docketSummary}
                            {...this.props}
                            changeButtonDisabled={this.changeButtonDisabled}
                            onChangeShipmentScans={this.onChangeShipmentScans}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            changeScannerMode={this.changeScannerMode}
                            onChange={this.onChangeDockets}
                            loadDockets={this.loadDockets}
                            goBack={this.prev}
                            getRefreshDockets={this.getRefreshDockets}
                        />,
                    },
                    {
                        title: 'Scan Shipments',
                        content: <ScanDocketBarcode
                            fields={fields}
                            totalPcs={this.state.totalPcs}
                            totalKgs={this.state.totalKgs}
                            selectedDockets={this.state.dockets}
                            totalShipmentPcs={this.state.totalShipmentPcs}
                            changeButtonDisabled={this.changeButtonDisabled}
                            onChangeShipmentScans={this.onChangeShipmentScans}
                            changeShipmentScansCount={this.changeShipmentScansCount}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            scannerMode={this.state.scannerMode}
                            page={this.state.page}
                            {...this.props}
                            goBack={this.prev}
                            onSkip={this.next}
                        />
                    },
                    {
                        title: 'Trip Summary',
                        content: <TripSummary
                            {...this.props}
                            fields={fields}
                            totalPcs={this.state.totalPcs}
                            totalKgs={this.state.totalKgs}
                            onChangeDocketSummaryHub={this.onChangeDocketSummaryHub}
                            onChangeDocketsSummary={this.onChangeDocketsSummary}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            trackingIdentity={this.state.trackingIdentity}
                            getVendorBlockReason={this.props.getVendorBlockReason}
                        />,
                    },
                ];
            } else if (fields.dispatched.value == 'CL' && !this.state.showCoLoaderFinal) {
                steps = [
                    {
                        title: 'Create Trip',
                        content: <>
                            <Form {...formItemLayout} >
                                <TripCreateStep1
                                    ref={this.refForm}
                                    {...this.props}
                                    {...fields}
                                    selectVehicle={this.selectVehicle}
                                    selectServiceProvider={this.selectServiceProvider}
                                    onChangeRoute={this.onChangeRoute}
                                    onChange={this.handleFormChange}
                                    changeLoadType={this.changeLoadType}
                                    selectPartner={this.selectPartner}
                                    changeGPSTracking={this.changeGPSTracking}
                                    onUploadFinish={this.onUploadFinish}
                                    onSelectVendor={this.onSelectVendor}
                                    changePickupTime={this.changePickupTime}
                                    showAssetsModal={this.state.showAssetsModal}
                                    openAssetModal={this.openAssetModal}
                                    cancelAssetModal={this.cancelAssetModal}
                                    submitAsset={this.submitAsset}
                                    disabledDate={this.disabledDate}
                                    handleIMEI={this.handleIMEI}
                                    onSelectVehicleType={this.onSelectVehicleType}
                                    selectCustomer={this.selectCustomer}
                                    selectShipper={this.selectShipper}
                                    disabledDate={this.disabledDate}
                                    handleIMEI={this.handleIMEI}
                                    onSelectVehicleType={this.onSelectVehicleType}
                                    changeDispatched={this.changeDispatched}
                                    reasonCategories={this.state.reasonCategories}
                                    onSelectReasonCategory={this.onSelectReasonCategory}
                                    reasons={this.state.reasons}
                                />
                            </Form>
                        </>,
                    },
                    {
                        title: 'Load Dockets',
                        content: <LoadTrip
                            fields={fields}
                            onChangeRowKeys={this.onChangeRowKeys}
                            rowKeys={this.state.rowKeys}
                            page={this.state.page}
                            changeTotalPrice={this.changeTotalPrice}
                            docketSummary={this.state.docketSummary}
                            {...this.props}
                            changeButtonDisabled={this.changeButtonDisabled}
                            onChangeShipmentScans={this.onChangeShipmentScans}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            changeScannerMode={this.changeScannerMode}
                            onChange={this.onChangeDockets}
                            loadDockets={this.loadDockets}
                            goBack={this.prev}
                            getRefreshDockets={this.getRefreshDockets}
                        />,
                    }
                ];
            } else {
                // if (fields.dispatched.value == 'DV') {
                steps = [
                    {
                        title: 'Create Trip',
                        content: <>
                            <Form {...formItemLayout} >
                                <TripCreateStep1
                                    ref={this.refForm}
                                    {...this.props}
                                    {...fields}
                                    selectVehicle={this.selectVehicle}
                                    selectServiceProvider={this.selectServiceProvider}
                                    onChangeRoute={this.onChangeRoute}
                                    searchVehicle={this.searchVehicle}
                                    setVehicle={this.setVehicle}
                                    onChange={this.handleFormChange}
                                    changeLoadType={this.changeLoadType}
                                    selectPartner={this.selectPartner}
                                    changeGPSTracking={this.changeGPSTracking}
                                    onUploadFinish={this.onUploadFinish}
                                    onSelectVendor={this.onSelectVendor}
                                    changePickupTime={this.changePickupTime}
                                    showAssetsModal={this.state.showAssetsModal}
                                    openAssetModal={this.openAssetModal}
                                    cancelAssetModal={this.cancelAssetModal}
                                    submitAsset={this.submitAsset}
                                    disabledDate={this.disabledDate}
                                    handleIMEI={this.handleIMEI}
                                    onSelectVehicleType={this.onSelectVehicleType}
                                    changeDispatched={this.changeDispatched}
                                    selectCustomer={this.selectCustomer}
                                    selectShipper={this.selectShipper}
                                    reasonCategories={this.state.reasonCategories}
                                    onSelectReasonCategory={this.onSelectReasonCategory}
                                    reasons={this.state.reasons}
                                    sim_tracking_disabled={this.state.sim_tracking_disabled}
                                    billingModel={this.state.billingModel}
                                    allBillingModels={this.state.allBillingModels}
                                    bodyTypes={this.state.bodyTypes}
                                    allCapacities={this.state.allCapacities}
                                    allDimensions={this.state.allDimensions}
                                    selectBillingModel={this.selectBillingModel}
                                    selectBillingModelCreate={this.selectBillingModelCreate}
                                    selectCapacity={this.selectCapacity}
                                    selectBodyType={this.selectBodyType}
                                    billingVehiclesList={this.state.billingVehiclesList}
                                    selectBillingVehicle={this.selectBillingVehicle}
                                    onChangeReplace={this.onChangeReplace}
                                />
                            </Form>
                        </>,
                    },
                    {
                        title: 'Load Dockets',
                        content: <LoadTrip
                            fields={fields}
                            onChangeRowKeys={this.onChangeRowKeys}
                            rowKeys={this.state.rowKeys}
                            page={this.state.page}
                            changeTotalPrice={this.changeTotalPrice}
                            docketSummary={this.state.docketSummary}
                            {...this.props}
                            changeButtonDisabled={this.changeButtonDisabled}
                            onChangeShipmentScans={this.onChangeShipmentScans}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            changeScannerMode={this.changeScannerMode}
                            onChange={this.onChangeDockets}
                            loadDockets={this.loadDockets}
                            goBack={this.prev}
                            getRefreshDockets={this.getRefreshDockets}
                        />,
                    },
                    {
                        title: 'Scan Shipments',
                        content: <ScanDocketBarcode
                            fields={fields}
                            totalPcs={this.state.totalPcs}
                            totalKgs={this.state.totalKgs}
                            selectedDockets={this.state.dockets}
                            totalShipmentPcs={this.state.totalShipmentPcs}
                            changeButtonDisabled={this.changeButtonDisabled}
                            onChangeShipmentScans={this.onChangeShipmentScans}
                            changeShipmentScansCount={this.changeShipmentScansCount}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            scannerMode={this.state.scannerMode}
                            page={this.state.page}
                            {...this.props}
                            goBack={this.prev}
                            onSkip={this.next}
                        />
                    },
                    {
                        title: 'Trip Summary',
                        content: <TripSummary
                            {...this.props}
                            fields={fields}
                            changeTotalPrice={this.changeTotalPrice}
                            totalPcs={this.state.totalPcs}
                            totalKgs={this.state.totalKgs}
                            onChangeDocketSummaryHub={this.onChangeDocketSummaryHub}
                            onChangeDocketsSummary={this.onChangeDocketsSummary}
                            changeCreateTripDisabled={this.changeCreateTripDisabled}
                            trackingIdentity={this.state.trackingIdentity}
                            getVendorBlockReason={this.props.getVendorBlockReason}
                            goBack={this.prev}
                        />,
                    },
                ];
                // }
                // else {
                //     steps = [
                //         {
                //             title: 'Create Trip',
                //             content: <>
                //                 <Form {...formItemLayout} >
                //                     <TripCreateStep1
                //                         ref={this.refForm}
                //                         {...this.props}
                //                         {...fields}
                //                         selectServiceProvider={this.selectServiceProvider}
                //                         selectVehicle={this.selectVehicle}
                //                         onChangeRoute={this.onChangeRoute}
                //                         onChange={this.handleFormChange}
                //                         changeLoadType={this.changeLoadType}
                //                         selectPartner={this.selectPartner}
                //                         changeGPSTracking={this.changeGPSTracking}
                //                         onUploadFinish={this.onUploadFinish}
                //                         onSelectVendor={this.onSelectVendor}
                //                         changePickupTime={this.changePickupTime}
                //                         disabledDate={this.disabledDate}
                //                         handleIMEI={this.handleIMEI}
                //                         onSelectVehicleType={this.onSelectVehicleType}
                //                     />
                //                 </Form>
                //             </>,
                //         },
                //         {
                //             title: 'Load Trips',
                //             content: <LoadTrip
                //                 fields={fields}
                //                 onChangeRowKeys={this.onChangeRowKeys}
                //                 rowKeys={this.state.rowKeys}
                //                 page={this.state.page}
                //                 changeTotalPrice={this.changeTotalPrice}
                //                 docketSummary={this.state.docketSummary}
                //                 {...this.props}
                //                 onChangeShipmentScans={this.onChangeShipmentScans}
                //                 changeButtonDisabled={this.changeButtonDisabled}
                //                 changeCreateTripDisabled={this.changeCreateTripDisabled}
                //                 changeScannerMode={this.changeScannerMode}
                //                 onChange={this.onChangeDockets}
                //                 loadDockets={this.loadDockets}
                //                 goBack={this.prev}
                //             />,
                //         }
                //     ];
                // }

                // if (this.isSelectedDocketAsBarcode() && this.state.scannerMode) {
                //     steps = [
                //         {
                //             title: 'Create Trip',
                //             content: <>
                //                 <Form {...formItemLayout} >
                //                     <TripCreateStep1
                //                         ref={this.refForm}
                //                         {...this.props}
                //                         {...fields}
                //                         selectVehicle={this.selectVehicle}
                //                         selectServiceProvider={this.selectServiceProvider}
                //                         onChangeRoute={this.onChangeRoute}
                //                         onChange={this.handleFormChange}
                //                         changeLoadType={this.changeLoadType}
                //                         selectPartner={this.selectPartner}
                //                         changeGPSTracking={this.changeGPSTracking}
                //                         onUploadFinish={this.onUploadFinish}
                //                         onSelectVendor={this.onSelectVendor}
                //                         changePickupTime={this.changePickupTime}
                //                         showAssetsModal={this.state.showAssetsModal}
                //                         openAssetModal={this.openAssetModal}
                //                         cancelAssetModal={this.cancelAssetModal}
                //                         submitAsset={this.submitAsset}
                //                     />
                //                 </Form>
                //             </>,
                //         },
                //         {
                //             title: 'Load Trips',
                //             content: <LoadTrip
                //                 fields={fields}
                //                 onChangeRowKeys={this.onChangeRowKeys}
                //                 rowKeys={this.state.rowKeys}
                //                 page={this.state.page}
                //                 changeTotalPrice={this.changeTotalPrice}
                //                 docketSummary={this.state.docketSummary}
                //                 {...this.props}
                //                 changeButtonDisabled={this.changeButtonDisabled}
                //                 onChangeShipmentScans={this.onChangeShipmentScans}
                //                 changeCreateTripDisabled={this.changeCreateTripDisabled}
                //                 changeScannerMode={this.changeScannerMode}
                //                 onChange={this.onChangeDockets}
                //                 loadDockets={this.loadDockets}
                //                 goBack={this.prev}
                //             />,
                //         },
                //         {
                //             title: 'Scan Docket',
                //             content: <ScanDocketBarcode
                //                 fields={fields}
                //                 totalPcs={this.state.totalPcs}
                //                 totalKgs={this.state.totalKgs}
                //                 selectedDockets={this.state.dockets}
                //                 totalShipmentPcs={this.state.totalShipmentPcs}
                //                 changeButtonDisabled={this.changeButtonDisabled}
                //                 onChangeShipmentScans={this.onChangeShipmentScans}
                //                 changeCreateTripDisabled={this.changeCreateTripDisabled}
                //                 scannerMode={this.state.scannerMode}
                //                 page={this.state.page}
                //                 {...this.props}
                //                 goBack={this.prev}
                //                 onSkip={this.next}
                //             />
                //         },
                //         {
                //             title: 'Trip Summary',
                //             content: <TripSummary
                //                 {...this.props}
                //                 fields={fields}
                //                 totalPcs={this.state.totalPcs}
                //                 totalKgs={this.state.totalKgs}
                //                 onChangeDocketSummaryHub={this.onChangeDocketSummaryHub}
                //                 onChangeDocketsSummary={this.onChangeDocketsSummary}
                //                 changeCreateTripDisabled={this.changeCreateTripDisabled}
                //             />,
                //         },
                //     ];
                // }
            }
        }








        return (
            <>
                <h2><Icon type="arrow-left" onClick={this.cancelTrip} /> {this.props.location?.state?.pending || this.props?.location?.state?.completed ? 'Load Dockets' : 'Create Trip'} </h2>
                <Card
                    actions={[
                        <div style={{ float: 'left' }}>
                            {current > 2 && this.props?.location?.state?.props && (
                                <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                    Back
                                </Button>
                            )}
                            {current > 0 && !this.props?.location?.state?.props && !this.props.location?.state?.pending && !this.props.location?.state?.completed && (
                                <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                    Back
                                </Button>
                            )}
                        </div>,
                        <div style={{ float: 'right' }}>
                            {current === steps.length - 1 && (
                                <Button type="primary" loading={this.props.createTripLoading} onClick={() => this.createTripFinalStep()} disabled={this.state.createTripDisabled}>
                                    {!this.props.location?.state?.pending && !this.props.location?.state?.completed ? 'Create Trip' : 'Load'}
                                </Button>
                            )}
                            {current >= 0 && (
                                <Button style={{ marginLeft: 8 }} onClick={this.cancelTrip}>
                                    Cancel
                                </Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={() => this.next()} disabled={this.state.buttonDisabled}>
                                    {current === 1
                                        ? (this.isSelectedDocketAsBarcode() && this.state.scannerMode)
                                            ? "Scan Barcode"
                                            : "Load Dockets"
                                        : current === 2
                                            ? "Load Dockets"
                                            : "Proceed"}
                                </Button>
                            )}
                        </div>,

                    ]}
                >
                    <Steps size={"small"} current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <Divider />
                    <div className="steps-content">{steps[current].content}</div>
                </Card>
            </>

        )
    }
}


const mapStateToProps = state => ({
    routes: state?.trips?.routes,
    assets: state?.trips?.assets,
    assetLoading: state?.trips?.assetLoading,
    customers: state?.trips?.customers,
    // partners: state?.header?.partnerHeaderDetails?.partners,
    coloaders: state?.trips?.coloaders,
    coloaderloading: state?.trips?.coloaderloading,
    sproviders: state?.trips?.sproviders,
    header: state?.header,
    dockets: state?.trips?.dockets,
    docketLoading: state?.trips?.docketLoading,
    pendingDocketLoading: state?.trips?.pendingDocketLoading,
    docketError: state?.trips?.docketError,
    docketsSummary: state?.trips?.docketsSummary,
    createTripLoading: state?.trips?.createTripLoading,
    createTripFlow: state?.trips?.createTripFlow,
    appConfig: state?.app?.configurations,
    vendorData: state?.trips?.vendorData,
    vendorBlockReason: state?.trips?.vendorBlockReason,
    app: state?.app,
    partners: state?.trips?.partners,
    shipperLoading: state?.trips?.shipperLoading,
    shippers: state?.trips?.shippers
});

const mapDispatchToProps = dispatch => ({
    getRoutes: (customerId) => dispatch(action.getAllRoutes(customerId)),
    getAllAssets: (routeId) => dispatch(action.getAllAssets(routeId)),
    getAllSimTrackingAssets: (params) => dispatch(action.getAllSimTrackingAssets(params)),
    getSearchedAssets: (payload) => dispatch(action.getSearchedAssets(payload)),
    getAllCustomers: () => dispatch(action.getAllCustomers()),
    getCoLoaders: (partnerId) => dispatch(action.getCoLoaders(partnerId)),
    getServiceProviders: () => dispatch(action.getSimProviders()),
    getDockets: (filters) => dispatch(action.getDocketsList(filters)),
    getCoLoaderDockets: (filters) => dispatch(action.getCoLoaderDocketsList(filters)),
    docketSummaryAllocation: (filters) => dispatch(action.docketSummaryAllocation(filters)),
    createTrip: (filters) => dispatch(action.createTripFlow(filters)),
    getVendorsList: () => dispatch(action.getVendorsList()),
    setInitialState: () => dispatch(action.setInitialState()),
    docketloadingFalse: () => dispatch(action.docketloadingFalse()),
    docketloadingTrue: () => dispatch(action.docketloadingTrue()),
    getColoaderPartners: (filters) => dispatch(action.getColoaderPartners(filters)),
    getVendorBlockReason: (id) => dispatch(action.getVendorBlockReason(id)),
    getShippers: (filters) => dispatch(action.getShippers(filters)),

});
export default connect(mapStateToProps, mapDispatchToProps)(TripCreate);