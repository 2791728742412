
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class UserService {

    getUsers(partnerId = Constants.PARTNER_ID) {
        const url = `/b2b/v1/partners/${partnerId}/users`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getUsersfetch(filters) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/users/fetch`;
        return apiRequest({
            url,
            method: "POST",
            data: filters
        });
    }

    getUsersByLocation(locationId, params) {
        const dataParams = params ? params : {};
        dataParams['loggedInUserTokken'] = true;

        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${locationId}/users`;
        return apiRequest({
            url,
            method: "GET",
            params: dataParams
        });
    }

    createUser(user) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/users`;
        return apiRequest({
            url,
            method: "POST",
            data: user
        });
    }

    updateUser(userId, user) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/users/${userId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: user
        });
    }

    deleteUser(userId) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/users/${userId}`;
        return apiRequest({
            url,
            method: "DELETE"
        });
    }

    getUser(userId) {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/users/${userId}`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    userForceLogout(userId) {
        const url = `/v1/force/logout/${userId}`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getVehiclesTypes() {
        const url = `/b2b/v1/vehicle-types/`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getReferrerTypes(type) {
        const url = `/b2b/v1/user/getreferrertype`;
        return apiRequest({
            url,
            method: "GET"
        });
    }
    getReferrers(type) {
        const url = `/b2b/v1/user/${type}/all`;
        return apiRequest({
            url,
            method: "GET"
        });
    }
    getUserPickupLocation(userId) {
        const url = `/b2b/v1/indent/user-pickup-location/get-user-pickup-location-mapping/${userId}`;
        return apiRequest({
            url,
            method: "GET"
        });
    }
}