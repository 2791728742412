import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import ContractList from "../../settings/contract/contracts/listing";
import ContractFilters from "../../settings/contract/contracts/listing/filters";
import { Card, Modal } from "antd";
import { approveRejectContract, rejectReasons } from "./services";
import ContractRejectionModal from "./contract.rejection-modal";
import { notifyApiError } from "../../../common/utils/utils";

const defaultFilters = {
  customerFilters: [],
  shipperFilters: [],
  locationFilter: [],
  requestedFilters: [],
  zoneFilter: [],
  basis: undefined,
  validFrom: "",
  validTo: "",
  serviceTypeData: [
    { id: 0, name: "FTL", value: false, storedValue: "FTL" },
    { id: 1, name: "PTL", value: false, storedValue: "PTL" },
    { id: 2, name: "LDV", value: false, storedValue: "LDV" }
  ],
  fuelStatus: [
    { id: 0, name: "ALARM", value: false, storedValue: "Alarm" },
    { id: 1, name: "ACTIVE", value: false, storedValue: "Active" },
    { id: 2, name: "INACTIVE", value: false, storedValue: "Inactive" },
    { id: 3, name: "NOT_AVAILABLE", value: false, storedValue: "Not Available" }
  ],
  contractTypesData: [
    {
      id: 0,
      "storedValue": "Standard",
      "name": "STANDARD",
      value: false,
    },
    {
      id: 1,
      "storedValue": "One time",
      "name": "ONE_TIME",
      value: false,
    }
  ]
};
function VerificationContracts(props) {

  const defaultParams = {
    filter: {
      ids: [],
      isActive: true,
      shipperIds: [],
      serviceTypes: [],
      zoneIds: [],
      locationIds: [],
      basis: [],
      fuelStatuses: [],
      validFrom: "",
      validTo: "",
      pageNo: 1,
      pageSize: 10,
      customerIds: [],
      billingCycles: [],
      contractTypes: [],
      requestedBy: [],
      status: ["SUBMITTED_FOR_APPROVAL", "APPROVAL_IN_PROCESS"],
      approvalPendingContracts: 1
    }
  };

  const [filterModal, setFilterModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [contractRejectedReasons, setReasons] = useState([]);
  const [selectedContracts, setContracts] = useState([]);
  const [filterIndicator, setFilterIndicator] = useState(
    props?.filterParams
      ? JSON.stringify(props?.filterParams) != JSON.stringify(defaultParams)
      : false
  );

  const [filters, setFilters] = useState(
    props?.filters ? props?.filters : { ...defaultFilters }
  );

  const [filterData, setFilterData] = useState(
    props?.filterData
      ? props?.filterData
      : {
        shipperData: [],
        locationData: [],
        zoneData: [],
        usersData: []
      }
  );

  let [params, setParams] = useState(
    props?.filterParams ? props?.filterParams : { ...defaultParams }
  );

  const applyFilters = () => {
    const { filter } = params;

    const stype = filters.serviceTypeData.map(s => {
      if (s.value) return s.name;
    });
    const fuel = filters.fuelStatus.map(s => {
      if (s.value) return s.name;
    });
    const contract = filters.contractTypesData.map(s => {
      if (s.value) return s.name;
    });

    setFilterData({
      shipperData: [],
      locationData: [],
      zoneData: []
    });

    params = {
      ...params,
      filter: {
        ...filter,
        customerIds: filters?.customerFilters
          ? filters?.customerFilters.map(shipper => shipper.key)
          : [],
        shipperIds: filters?.shipperFilters
          ? filters?.shipperFilters.map(shipper => shipper.key)
          : [],
        locationIds: filters?.locationFilter
          ? filters?.locationFilter.map(location => location.key)
          : [],
        zoneIds: filters?.zoneFilter
          ? filters?.zoneFilter.map(zone => zone.key)
          : [],
        requestedBy: filters?.requestedFilters
        ? filters?.requestedFilters.map(requested => requested.key)
        : [],
        basis: filters?.basis ? filters?.basis.map(b => b.key) : [],
        billingCycles: filters?.billingCycles ? filters?.billingCycles.map(b => b.key) : [],
        serviceTypes: _.compact(stype),
        fuelStatuses: _.compact(fuel),
        contractTypes: _.compact(contract),
        validFrom: filters.validFrom ? moment(filters.validFrom).valueOf() : "",
        validTo: filters.validTo ? moment(filters.validTo).valueOf() : ""
      }
    };
    setParams(params);
    setFilterModal(false);
    setFilterIndicator(JSON.stringify(params) != JSON.stringify(defaultParams));
  };

  const resetFilters = () => {
    setFilters({ ...defaultFilters });

    params = {
      ...params,
      ...defaultParams
    };
    setParams(params);
    setFilterModal(false);
    setFilterIndicator(false);
  };

  const showContractDetails = (contractData, index) => {

    props.history.push(
      "/appv2/verification/dashboard/contract/details",
      {
        contractid: contractData.id,
        customerId: contractData.customerId,
        name: contractData.name,
        view: true,
        index,
        params,
        filterData,
        filters
      }
    );
  };

  const approveContract = (selected, status) => {
    if (selected.length > 0) {
      setContracts(selected);
      if (status === 'APPROVED') {
        Modal.confirm({
          title: `Are you sure to approve the selected ${selected.length} contracts?`,
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            selected.map(contract => {
              const data = {
                contractBO: {
                  id: contract.id,
                  status: status
                }
              }
              approveRejectContract(data)
                .then(response => {
                  if (response.approveRejectSuccess) {
                    applyFilters();
                  }
                  else if (response.approveRejectSuccess === false) {
                    if( response.dataValidations["RATE_CARD_DUPLICATION_CHECK"] &&
                        response.dataValidations["RATE_CARD_DUPLICATION_CHECK"]?.length > 0 ) {
                        notifyApiError( response.dataValidations["RATE_CARD_DUPLICATION_CHECK"].join("! "), contract.code + " - Approval contract  failed!")
                    }
                    else {
                      notifyApiError("Approval of the " + contract.code + " contract failed!", "Approve Contract failed!")
                    }
                  }
                })
            })
          }
        })

      } else {
        rejectReasons().then(response => {

          if (response.length > 0) {
            setReasons(response);
            setRejectModal(true);
          }
        })
      }
    } else {
      Modal.error({
        title: 'Select Contracts to make this action',
      });
    }

  };

  const rejectContract = (rejectSelectedValue, rejectComment) => {

    setRejectLoader(true);
    const rejectedContractsReasonsArray = contractRejectedReasons.filter(reason => rejectSelectedValue.includes(reason.id));
    let postObjReasons = rejectedContractsReasonsArray.map(reason => {
      return {
        id: reason.id,
        reasonDescription: reason.documentRejectedReason
      }
    });
    selectedContracts.map(contract => {
      const data = {
        "contractBO": {
          "id": contract.id,
          "status": "REJECTED"
        },
        "reasonBO": {
          "remarks": rejectComment,
          reasons: postObjReasons
        }
      }
      approveRejectContract(data).then(response => {
        if (response) {
          setRejectLoader(false);
          setRejectModal(false);
          applyFilters();
        } else {
          setRejectLoader(false);
          setRejectModal(false);
        }
      })

    })



  }

  return (
    <Card className="contract-management">
      <ContractList
        setFilterModal={setFilterModal}
        params={params}
        setParams={setParams}
        filterIndicator={filterIndicator}
        showContractDetails={showContractDetails}
        editContractDetails={_ => { }}
        setFilterIndicator={setFilterIndicator}
        approveContract={approveContract}
        flowType={"VERIFICATION"}
      />
      {filterModal && (
        <ContractFilters
          visible={filterModal}
          setFilterModal={setFilterModal}
          filters={filters}
          setFilters={setFilters}
          filterData={filterData}
          setFilterData={setFilterData}
          resetFilters={resetFilters}
          applyFilters={applyFilters}
          flowType={"VERIFICATION"}
        />
      )}
      {showRejectModal &&
        <ContractRejectionModal
          showRejectModal={showRejectModal}
          contractRejectedReasons={contractRejectedReasons}
          cancelRejectModal={() => setRejectModal(false)}
          loading={rejectLoader}
          rejectContract={rejectContract}
        />
      }
    </Card>

  );
}

export default VerificationContracts;
