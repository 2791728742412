import { Modal } from "antd";
import React, { useRef, useState } from "react";
import { s3Connections } from "../../../../environments/node-react-environment";
import CustomerLocationForm from "./location.form";

export default function CreateLocationPopup(props) {

    const locationRef = useRef();
    const { openModal, setModal } = props;
    const [createAnother, setCreateAnother] = useState(false);
    const [isCancel, setCancel] = useState(false);
    const [draggable, setDraggable] = useState(false);

    const locationDetails = false;

    const openLocationHelpLink = _ => {
        window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/customer-location-create-guide.pdf`, '_blank').focus();
    }
    
    const onLocationCreate = data => {
        if (typeof props.onLocationCreate == "function") {
            props.onLocationCreate(data);
        }
    }
    
    return (
        <>
            { 
                openModal &&
                <CustomerLocationForm
                    {...locationDetails}
                    openModal={openModal}
                    setModal={setModal}
                    customer={props.customer}
                    setCancel={setCancel}
                    isCancel={isCancel}
                    goBack={() => {
                        Modal.confirm({
                            title  : "Are you sure to cancel location creation?",
                            onOk   : _ => {
                                Modal.destroyAll();
                                setModal(false)
                            },
                            okText : "Yes",
                            cancelText : "No"
                        })
                    }}
                    locationRef={locationRef}
                    createAnother={createAnother}
                    setCreateAnother={setCreateAnother}
                    openHelpLink={openLocationHelpLink}
                    draggable={draggable}
                    setDraggable={setDraggable}
                    createLocationPopup={true}
                    onLocationCreate={onLocationCreate}
                    disableCreateAnother={props.disableCreateAnother}
                />
            }
        </>
    )
}