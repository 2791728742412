import React, { PureComponent } from 'react';
import * as actions from './actions';
import { getRoles } from '../settings/role/actions';

import { connect } from 'react-redux';
import {
    Card, Button, Input, Row, Col,
    Empty, Tag, Icon, Modal, Badge,
    Tooltip, Table, Switch, Form, Select, Radio, DatePicker, Spin, Checkbox
} from 'antd';
// import { table as Table } from "../../ui/table/Table";
import moment from 'moment';
import _ from 'lodash';
import './lms.scss';
import LMSUsers from '../../assets/img/icons/users.svg';
import LMSEdit from '../../assets/img/icons/lmsedit.svg';
import { notifyApiError } from '../../common/utils/utils';

class LMSDashboard extends PureComponent {
    state = {
        showLMSForm: false,
        editMode: false,
        showUsers: false,
        
        editId: undefined,
        setMode: false,
        filtersVisible: false,
        selectedRole: [],
        selectedModuleCourse: undefined,
        show: false,
        filterApplied: false,
        errorNotified: false,
        page:1,
        params: {
            "filter": {
                "pageNo": this.getPage(),
                "pageSize": 10,
                "fetchObjects": [
                    "ROLES",
                    "USER_STRICT_BASIC"
                ],
                ...(this.state?.selectedModuleCourse && {partialContentSearch: this.state.selectedModuleCourse}),
                ...(this.state?.selectedRole.length > 0 && { "roleIds": this.state.selectedRole.map(role => role.key)})
            }
        },
    }

    getPage() {
        return this.state?.page ? this.state?.page : 1;
    }


    componentDidMount() {

        this.props.getLMSList(this.state.params);
        this.props.getRoles();
    }

    changePage = (page) => {
        this.setState({page});
        let params = {
            "filter": {
                "pageNo": page,
                "pageSize": 10,
                "fetchObjects": [
                    "ROLES",
                    "USER_STRICT_BASIC"
                ],
                ...(this.state.selectedModuleCourse && {partialContentSearch: this.state.selectedModuleCourse}),
                ...(this.state.selectedRole.length > 0 && { "roleIds": this.state.selectedRole.map(role => role.key)})
                
            }
        };

        this.props.getLMSList(params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.lmsCreate) {

            this.props.form.resetFields();

            if (this.state.showLMSForm) {
                setTimeout(() => {
                    this.props.getLMSList(this.state.params);
                }, 500)

            }
            this.setState({ showLMSForm: false });

        }
        
        if (this.props?.lmsEditData && this.state.editMode && this.state.setMode) {
            if (this.props.lmsEditData.lms && this.props.lmsEditData.lms.length > 0) {
                this.props.form.resetFields();
                const { setFieldsValue } = this.props.form;
                let someData = this.props?.lmsEditData?.lms?.map(data => {

                    setFieldsValue({
                        moduleName: data['name'],
                        courseID: data['courseId'],
                        trainingCompletion: _.hasIn(data, 'trainingMandatoryDate') ? 'training' : false,
                        // trainingMandatoryDate: moment(data['trainingMandatoryDate']),
                        handsonCompletion: _.hasIn(data, 'handsOnMandatoryDate') ? 'handson' : false,


                        // handsonCompletion: false,
                        roles: data['roles'].map(role => {
                            return {
                                "key": role['id'],
                                "label": role['name']
                            }
                        })
                    }, () => {
                        if (_.hasIn(data, 'handsOnMandatoryDate')) {
                            setFieldsValue({
                                mandatoryFrom: moment(data['handsOnMandatoryDate']),
                            })
                        }

                        if (_.hasIn(data, 'trainingMandatoryDate')) {
                            setFieldsValue({
                                trainingMandatoryDate: moment(data['trainingMandatoryDate']),
                            })
                        }
                    })


                });
                this.setState({ setMode: false });
            } else {
                
                if (!this.state.errorNotified) {
                    notifyApiError("Something went wrong", "Error: ");
                    this.props.form.resetFields();

                    if (this.state.showLMSForm) {
                        this.props.getLMSList(this.state.params);
                    }
                    this.setState({ showLMSForm: false, errorNotified: true });
                }

            }

        }
    }

    showCreateModule = (mode = 'create', data = {}) => {
        if (mode == 'edit') {

            let params = {
                "filter": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "ids": [data.id],
                    "fetchObjects": [
                        "ROLES",
                        "USER_STRICT_BASIC"
                    ]
                }
            };
            this.props.getLMSEditList(params);

            this.setState({ showLMSForm: true, editMode: true, editId: data.id, setMode: true, errorNotified: false });
            // const { setFieldsValue } = this.props.form;
            // setFieldsValue({
            //     module: data['module'],
            //     courseID: data['courseID'],
            //     created: moment(data['mandatory']).valueOf(),
            //     mandatory: moment(data['mandatory']).valueOf(),
            // });

        } else {
            this.setState({ showLMSForm: true, editMode: false, errorNotified: false });
        }

    }

    handleLMSCancel = () => {
        this.setState({ showLMSForm: false, editId: undefined, setMode: false });
        this.props.getEditReset();
        this.props.form.resetFields();
    }

    showUsers = (data) => {
        // let params = {
        //     "filter": {
        //         "pageNo": 1,
        //         "pageSize": 10,
        //         "ids": [data.id],
        //         "fetchObjects": [
        //             "ROLES",
        //             "USER_STRICT_BASIC",
        //             "USER_LMS"
        //         ]
        //     }
        // }

        // this.props.getLMSUsersList(params);
        // this.setState({ showUsers: true });
        
        this.props.history.push('/appv2/lms/users/dashboard', { userID: data.id });
    }

    cancelShowUsers = () => {
        this.setState({ showUsers: false });
    }

    handleCreateOk = () => {

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            let roles = [];
            if (values['roles'] && values['roles'].length > 0) {
                roles = values['roles'].map(r => {
                    return { "id": r.key }
                })
            }
            let params = {};
            if (values['mandatoryFrom']) {
                params = {
                    "action": "CREATE",
                    "lms": {
                        "name": values['moduleName'],
                        "courseId": values['courseID'],                        
                        "handsOnMandatoryDate": values['mandatoryFrom'].valueOf(),
                        "approvedDate":  values['mandatoryFrom'].valueOf(),
                        roles
                    }

                }

            }
            if (values['trainingMandatoryDate']) {
                params = {
                    "action": "CREATE",
                    "lms": {
                        "name": values['moduleName'],
                        "courseId": values['courseID'],
                        "trainingMandatoryDate": values['trainingMandatoryDate'].valueOf(),
                        roles
                    }

                }
            }
            if(values['trainingMandatoryDate'] && values['mandatoryFrom']) {
                params = {
                    "action": "CREATE",
                    "lms": {
                        "name": values['moduleName'],
                        "courseId": values['courseID'],
                        "handsOnMandatoryDate": values['mandatoryFrom'].valueOf(),
                        "trainingMandatoryDate": values['trainingMandatoryDate'].valueOf(),
                        roles
                    }

                }
            }
            if (!values['trainingMandatoryDate'] && !values['mandatoryFrom']) {
                params = {
                    "action": "CREATE",
                    "lms": {
                        "name": values['moduleName'],
                        "courseId": values['courseID'],
                        roles
                    }

                }
            }

            this.props.getLMSCreate(params, "create");
        });




    }

    handleUpdateOk = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            let roles = [];
            if (values['roles'] && values['roles'].length > 0) {
                roles = values['roles'].map(r => {
                    return { "id": r.key }
                })
            }
            let params = {};
            
            if (values['mandatoryFrom']) {
                params = {
                    "action": "UPDATE",
                    "lms": {
                        "id": this.state.editId,
                        "name": values['moduleName'],
                        "courseId": values['courseID'],                        
                        "handsOnMandatoryDate": values['mandatoryFrom'].valueOf(),
                        "approvedDate":  values['mandatoryFrom'].valueOf(),
                        roles
                    }

                }
            }
            if (values['trainingMandatoryDate']) {
                params = {
                    "action": "UPDATE",
                    "lms": {
                        "id": this.state.editId,
                        "name": values['moduleName'],
                        "courseId": values['courseID'],
                        "trainingMandatoryDate": values['trainingMandatoryDate'].valueOf(),
                        roles
                    }

                }
            }
            if(values['mandatoryFrom'] && values['trainingMandatoryDate']) {
                params = {
                    "action": "UPDATE",
                    "lms": {
                        "id": this.state.editId,
                        "name": values['moduleName'],
                        "courseId": values['courseID'],
                        "handsOnMandatoryDate": values['mandatoryFrom'].valueOf(),
                        "trainingMandatoryDate": values['trainingMandatoryDate'].valueOf(),
                        roles
                    }

                }
            }

            if (!values['trainingMandatoryDate'] && !values['mandatoryFrom']) {
                params = {
                    "action": "UPDATE",
                    "lms": {
                        "name": values['moduleName'],
                        "id": this.state.editId,
                        "courseId": values['courseID'],
                        roles
                    }

                }
            }



            this.props.getLMSCreate(params, "update");
            this.props.getEditReset();
        });
    }

    reloadLMS = () => {
        this.props.getLMSList(this.state.params);
    }

    showFilters = () => {
        this.setState({ filtersVisible: true });
    }

    hideFilters = () => {
        this.setState({ filtersVisible: false });
    }

    changeStatus = (checked, data) => {
        let params = {
            "action": "UPDATE",
            "lms": {
                "id": data.id,
                "status": checked ? 'ACTIVE' : 'INACTIVE'
            }
        }

        this.props.updateStatus(params);
        setTimeout(() => {
            const params = {
                "filter": {
                    "pageNo": this.getPage(),
                    "pageSize": 10,
                    "fetchObjects": [
                        "ROLES",
                        "USER_STRICT_BASIC"
                    ],
                    ...(this.state?.selectedModuleCourse && {partialContentSearch: this.state.selectedModuleCourse}),
                    ...(this.state?.selectedRole.length > 0 && { "roleIds": this.state.selectedRole.map(role => role.key)})
                }
            }
            this.props.getLMSList(params);
        }, 1000)

    }



    selectRole = value => {
        this.setState({ selectedRole: value })
    }

    changeModuleCourse = e => {
        if (e.target.value)
            this.setState({ selectedModuleCourse: e.target.value.trim() });
        else
            this.setState({ selectedModuleCourse: '' });
    }

    clearSearch = () => {
        this.setState({selectedModuleCourse: '', filterApplied: false}, () => {
            this.props.getLMSList(this.state.params);
        })
    }

    applyFilters = () => {


        let params = {
            "filter": {
                "pageNo": 1,
                "pageSize": 10,
                "fetchObjects": [
                    "ROLES"
                ]
            }

        };


        if (this.state.selectedRole.length > 0 || this.state.selectedModuleCourse && this.state.selectedModuleCourse.length > 0) {
            let { filter } = params;
            if (this.state.selectedRole.length > 0) {
                filter = {
                    ...filter,
                    "roleIds": this.state.selectedRole.map(role => role.key)
                }
                params = {
                    filter: {
                        ...filter
                    }

                }
                

            }

            if (this.state.selectedModuleCourse && this.state.selectedModuleCourse.length > 0) {
                params = {
                    filter: {
                        ...filter,
                        "partialContentSearch": this.state.selectedModuleCourse
                    }
                }
            }

            this.props.getLMSList(params);
            this.setState({ filtersVisible: false, show: true, filterApplied: true });
        }


        if (this.state.selectedRole.length <= 0 && (_.isUndefined(this.state.selectedModuleCourse) || this.state.selectedModuleCourse == '') && this.state.filterApplied) {
            this.props.getLMSList(this.state.params);
            this.setState({ filtersVisible: false, show: false });
        }



    }

    resetFilters = () => {
        this.setState({ selectedModuleCourse: undefined, selectedRole: [], show: false }, () => {
            this.props.getLMSList(this.state.params);
        });
    }

    deselect = (value) => {


        if (this.state.selectedRole.length == 1) {
            this.setState({ selectedRole: [] }, () => {
                this.applyFilters();
                this.setState({ filterApplied: false })
            });

        } else {
            this.applyFilters();
        }
    }

    render() {
        const columns = [
            {
                title: 'Module name',
                dataIndex: 'module',
                key: 'module',
                width: 70
            },
            {
                title: 'Course ID',
                dataIndex: 'courseID',
                key: 'courseID',
                width: 80
            },
            {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                width: 130
            },
            // {
            //     title: 'Created on',
            //     dataIndex: 'createdon',
            //     key: 'createdon',
            //     width: 80
            // },
            // {
            //     title: 'Mandatory Required',
            //     dataIndex: 'completion',
            //     key: 'completion',
            //     width: 80
            // },
            {
                title: 'Created on',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 80
            },
            {
                title: 'Mandatory From',
                dataIndex: 'mandatory',
                key: 'mandatory',
                width: 80
            },
            {
                title: 'Hands on Required and Date',
                dataIndex: 'handson',
                key: 'handson',
                width: 90
            },
            // {
            //     title: 'Hands on Date',
            //     dataIndex: 'created',
            //     key: 'created',
            //     width: 80
            // },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 70
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'action',
                width: 50,
                render: (data, row) => (<Row gutter={[8, 8]}>
                    <Col span={8}><Tooltip placement="top" title={'Users'}>
                        <img src={LMSUsers} style={{ cursor: 'pointer' }} onClick={() => this.showUsers(data)} />
                        {/* <Icon type="team" onClick={() => this.showUsers(data)} /> */}
                    </Tooltip>
                    </Col>
                    <Col span={8}><Tooltip placement="top" title={'Edit'}>
                        <img src={LMSEdit} style={{ cursor: 'pointer' }} onClick={() => this.showCreateModule("edit", data)} />
                        {/* <Icon type="edit" onClick={() => this.showCreateModule("edit", data)} /> */}
                    </Tooltip>
                    </Col>
                    <Col span={8}><Tooltip placement="top" title={row.lmsStatus == 'ACTIVE' ? 'Enabled' : 'Disabled'}>
                        <Switch size="small" checked={row.lmsStatus == 'ACTIVE' ? true : false} onChange={(checked) => this.changeStatus(checked, data)} /></Tooltip>
                    </Col>
                </Row >)
            },
        ];

        const data = [];
        let lmsData = this.props?.lms?.lmsData?.lms?.map(lms => {

            data.push(
                {
                    module: lms.name,
                    courseID: lms.courseId,
                    roles: lms.roles.map(role => role.name ? role.name : null).filter(Boolean).join(", "),
                    completion: _.hasIn(lms, 'trainingMandatoryDate') ? 'Yes' : 'No',
                    createdAt: moment(lms.createdAt).format("DD-MMM-YYYY HH:mm"),
                    mandatory: <>{_.hasIn(lms, 'trainingMandatoryDate') ? moment(lms.trainingMandatoryDate).format("DD-MMM-YYYY HH:mm") : 'N/A'}</>,
                    handson: _.hasIn(lms, 'handsOnMandatoryDate') ? <>Yes<br /> {moment(lms.handsOnMandatoryDate).format("DD-MMM-YYYY HH:mm")}</> : 'No',
                    created: <> {lms.handsOnMandatoryDate ? <>{moment(lms.handsOnMandatoryDate).format("DD-MMM-YYYY HH:MM")}<br /> {moment(lms.handsOnMandatoryDate).fromNow()}</> : 'N/A'}   </>,


                    id: lms.id,
                    lmsStatus: lms.status,
                    status: <>{lms.status == 'ACTIVE' ? <Tag color="blue">Active</Tag> : <Tag>Inactive</Tag>}</>
                    // status: 'Active'
                }
            );



        });




        const actionBtns = (
            <Row style={{ marginRight: 30 }}>
                <Col span={11}>
                    {/* <Input.Search size={'large'} placeholder="Search" allowClear enterButton /> */}
                    <Badge dot={this.state.show} offset={[25, 0]}>
                        <Button block size={'large'} onClick={this.showFilters} type="primary"  ><Icon type="filter" /> Search and Filter</Button>
                    </Badge>

                </Col>
                <Col span={4}>
                    <Tooltip placement="top" title={'Refresh'}>
                        <Button block size={'large'} onClick={() => this.reloadLMS()} type="primary" style={{ marginLeft: 6 }}>
                            <Icon type="reload" />
                        </Button>
                    </Tooltip>
                </Col>

                <Col span={9}>
                    <Button size={'large'} type="primary" style={{ marginLeft: 12 }} onClick={() => this.showCreateModule()}>Create Module</Button>
                </Col>

            </Row>
        );

        const formItemLayout = {
            labelCol: {
                span: 24,
                offset: 1
            },
            wrapperCol: {
                span: 16,
            },


            layout: 'vertical',
            labelAlign: "left",
            colon: false
        };



        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { Option } = Select;
        const handsOnComplete = getFieldsValue(['handsonCompletion']);
        const trainingCompletion = getFieldsValue(['trainingCompletion']);


        return (
            <Card className="LMSDashBoard" title={<h2>LMS Training Module</h2>} >
                <Row gutter={[8, 8]}>
                    <Col span={8}>
                        <Input.Search
                            value={this.state.selectedModuleCourse}
                            placeholder="Search by module name or course ID"
                            onChange={this.changeModuleCourse}
                            onSearch={this.applyFilters}
                            suffix={<>{this.state.selectedModuleCourse && <Icon style={{cursor: 'pointer'}} onClick={this.clearSearch} type="close-circle" />}</>}

                        />
                    </Col>
                    <Col span={8}>
                        <Select
                            showSearch
                            labelInValue
                            mode="multiple"
                            style={{ width: '100%' }}
                            onChange={this.selectRole}
                            value={this.state.selectedRole}
                            placeholder="Select Roles"
                            onBlur={this.applyFilters}
                            onDeselect={this.deselect}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.props?.roles?.map(role => (
                                <Option
                                    value={role.id}
                                    label={role.name ? role.name : role.partner.name}
                                    key={role.id}
                                >
                                    {role.name ? role.name : role.partner.name}
                                </Option>
                            ))}


                        </Select>
                    </Col>
                    <Col span={6} push={2} offset={2}>
                        <Button type="primary" style={{ marginLeft: 12 }} onClick={() => this.showCreateModule()}>Create Module</Button>
                    </Col>
                </Row>
                <Spin spinning={this.props?.lms?.loading}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total: this.props.lms?.lmsData?.totalCount,
                            current: this.props.lms?.lmsData?.currentPageNo,
                            onChange: this.changePage
                        }}
                    />
                </Spin>

                <Modal
                    title={this.state.editMode ? 'Update LMS Training Module' : 'Create LMS Training Module'}
                    visible={this.state.showLMSForm}
                    width={900}
                    style={{ top: 25 }}
                    closable={false}
                    footer={[
                        <Button key="back" onClick={this.handleLMSCancel}>
                            Cancel
                        </Button>,
                        <>
                            {this.state.editMode ?
                                <Button key="submit" type="primary" onClick={this.handleUpdateOk}>
                                    Update
                                </Button>
                                :
                                <Button key="submit" type="primary" onClick={this.handleCreateOk}>
                                    Create
                                </Button>
                            }

                        </>
                    ]}
                >

                    <h4>Module Informations</h4>
                    <Form>
                        <Row gutter={[1, 1]}>
                            <Col span={24}>
                                <Row gutter={[5, 0]}>
                                    <Col span={12}>
                                        <Form.Item label={<span className="form-label-text">Training Module Name</span>}>
                                            {getFieldDecorator('moduleName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input module name!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    disabled={this.state.editMode}
                                                    placeholder="Enter module name"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={<span className="form-label-text">Course ID ( Notice Board ) </span>}>
                                            {getFieldDecorator('courseID', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input course ID!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    disabled={this.state.editMode}
                                                    placeholder="Enter Course ID"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label={<span className="form-label-text">Roles</span>}>
                                            {getFieldDecorator('roles', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please Select Role!',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    mode="multiple"
                                                    placeholder="Select Roles"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {this.props?.roles?.map(role => {
                                                        if (role.name)
                                                            return <Option
                                                                value={role.id}
                                                                label={role.name}
                                                                key={role.id}
                                                            >
                                                                {role.name}
                                                            </Option>
                                                    }
                                                    )}


                                                </Select>

                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        {/* <Form.Item label={<span className="form-label-text">Training Mandatory Date</span>}>
                                            {getFieldDecorator('trainingMandatoryDate', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please Select training Date!',
                                                    },
                                                ],
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime
                                                />
                                            )}
                                        </Form.Item> */}



                                        <Form.Item>
                                            {getFieldDecorator('trainingCompletion', {
                                                initialValue: 'training'
                                            })(
                                                <Checkbox.Group style={{ width: '100%' }}>
                                                    <Row>
                                                        {/* <Col span={12}>
                                            <Checkbox value="completion">Completion Required</Checkbox>
                                        </Col> */}
                                                        <Col span={12}>
                                                            <Checkbox value={"training"}>Training Required</Checkbox>
                                                        </Col>



                                                    </Row>
                                                </Checkbox.Group>
                                                // <Radio.Group name="radiogroup">
                                                //     <Radio value={"completion"}>Completion Required</Radio>
                                                //     <Radio value={"handson"}>Hands on Required</Radio>

                                                // </Radio.Group>

                                            )}
                                        </Form.Item>
                                        {trainingCompletion.trainingCompletion == 'training' ?

                                            <Form.Item label={<span className="form-label-text">Training Mandatory From</span>}>
                                                {getFieldDecorator('trainingMandatoryDate', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please Select Mandatory From!',
                                                        },
                                                    ],
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        format={"DD-MMM-YYYY HH:mm"}
                                                        showTime
                                                    />
                                                )}
                                            </Form.Item>
                                            :
                                            <></>
                                        }





                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item>
                                            {getFieldDecorator('handsonCompletion', {
                                                initialValue: false
                                            })(
                                                <Checkbox.Group style={{ width: '100%' }}>
                                                    <Row>
                                                        {/* <Col span={12}>
                                            <Checkbox value="completion">Completion Required</Checkbox>
                                        </Col> */}
                                                        <Col span={12}>
                                                            <Checkbox value="handson">Hands on Required</Checkbox>
                                                        </Col>



                                                    </Row>
                                                </Checkbox.Group>
                                                // <Radio.Group name="radiogroup">
                                                //     <Radio value={"completion"}>Completion Required</Radio>
                                                //     <Radio value={"handson"}>Hands on Required</Radio>

                                                // </Radio.Group>

                                            )}
                                        </Form.Item>
                                        {handsOnComplete.handsonCompletion == 'handson' ?

                                            <Form.Item label={<span className="form-label-text">Hands on Mandatory From</span>}>
                                                {getFieldDecorator('mandatoryFrom', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please Select Mandatory From!',
                                                        },
                                                    ],
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        format={"DD-MMM-YYYY HH:mm"}
                                                        showTime
                                                    />
                                                )}
                                            </Form.Item>
                                            :
                                            <></>
                                        }

                                    </Col>
                                </Row>

                            </Col>
                        </Row>








                    </Form>

                </Modal>

                {/* <Modal
                    title="View User Details"
                    width="80%"
                    visible={this.state.showUsers}
                    onCancel={this.cancelShowUsers}
                    footer={[]}
                >
                    <Table
                        bordered
                        columns={userColumns}
                        dataSource={userDataSource}
                    />
                </Modal> */}

                <Modal
                    title={'Search and Filter'}
                    width={400}
                    visible={this.state.filtersVisible}
                    onCancel={this.hideFilters}
                    footer={[

                        <Button key="back" onClick={this.hideFilters}>
                            Cancel
                        </Button>,
                        <Button key="reset" type="danger" onClick={this.resetFilters}>
                            Clear
                        </Button>,
                        <Button key="submit" type="primary" disabled={this.state.selectedRole.length <= 0 && (_.isUndefined(this.state.selectedModuleCourse) || this.state.selectedModuleCourse == '' || this.state.selectedModuleCourse.length < 3)} onClick={this.applyFilters}>
                            Apply
                        </Button>

                    ]}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div className="form-label-text">Module</div>
                            <Input
                                value={this.state.selectedModuleCourse}
                                placeholder="Search by module name or course ID"
                                onChange={this.changeModuleCourse}

                            />
                        </Col>
                        <Col span={24}>
                            <div className="form-label-text">Role</div>
                            <Select
                                showSearch
                                labelInValue
                                mode="multiple"
                                style={{ width: '100%' }}
                                onChange={this.selectRole}
                                value={this.state.selectedRole}
                                placeholder="Select Roles"

                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.props?.roles?.map(role => (
                                    <Option
                                        value={role.id}
                                        label={role.name ? role.name : role.partner.name}
                                        key={role.id}
                                    >
                                        {role.name ? role.name : role.partner.name}
                                    </Option>
                                ))}


                            </Select>

                        </Col>
                    </Row>
                </Modal>

            </Card>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    lms: state?.lms?.lms,
    lmsCreate: state?.lms?.lmsCreate,
    lmsUsers: state?.lms?.lmsUsers,
    lmsEditData: state?.lms?.lmsEditData,
    roles: state?.settingsRoles?.roles,
});
const mapDispatchToProps = dispatch => ({
    getLMSList: (params) => dispatch(actions.getLMSList(params)),
    getLMSCreate: (params, text) => dispatch(actions.getLMSCreate(params, text)),
    getLMSUsersList: (params) => dispatch(actions.getLMSUsersList(params)),
    getLMSEditList: (params) => dispatch(actions.getLMSEditList(params)),
    getEditReset: () => dispatch(actions.getEditReset()),
    getRoles: () => dispatch(getRoles()),
    updateStatus: (params) => dispatch(actions.updateStatus(params))
});


export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'LMS', onValuesChange: (props, val) => console.log(props) })(LMSDashboard));
// export default connect(mapStateToProps, mapDispatchToProps)(LMSDashboard);