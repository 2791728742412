import React from "react";
import SLARouteEvent from "./sla-route-event.component";
import "./sla-component.scss";
const SLARouteLocation = ({
  location,
  nextLocation,
  previousLocation,
  isActivePath,
  currentPath,
}) => {
  const slaRouteStyle = {
    border: "1px solid #DCDCDC",
    borderRadius: 6,
    // maxHeight: 90,
  };
  const timelineStylePassive = {
    position: "absolute",
    width: 5,
    height: 57,
    border: "1px solid #D9D9D9",
    // top: 73,
    left: 20,
    background: "#D9D9D9",
    right: 0,
  };
  const timelineStyleActive = {
    position: "absolute",
    width: 5,
    height: 57,
    border: "1px solid #008556",
    // top: 73,
    left: 20,
    background: "#008556",
    right: 0,
  };
  const timelineStyleRoute = {
    position: "absolute",
    width: 0,
    height: 57,
    border: "2px dashed #D9D9D9",
    // top: 73,
    left: 20,
    background: "transparent",
    right: 0,
  };
  let isActive = timelineStylePassive;
  let isTooltip = false;
  if (isActivePath) {
    if (
      ((previousLocation &&
        previousLocation.events[previousLocation.events.length - 1]
          .isActionDone) ||
        (location &&
          location.events[location.events.length - 1].isActionDone)) &&
      nextLocation &&
      nextLocation.events[0].isActionDone
    ) {
      isActive = timelineStyleActive;
      isTooltip = false;
    }
    if (
      ((previousLocation &&
        previousLocation.events[previousLocation.events.length - 1]
          .isActionDone) ||
        (location &&
          location.events[location.events.length - 1].isActionDone)) &&
      nextLocation &&
      !nextLocation.events[0].isActionDone
    ) {
      isActive = timelineStylePassive;
      isTooltip = false;
    }
  } else {
    if (
      ((previousLocation &&
        previousLocation.events[previousLocation.events.length - 1]
          .isActionDone) ||
        (location &&
          location.events[location.events.length - 1].isActionDone)) &&
      nextLocation &&
      !nextLocation.events[0].isActionDone
    ) {
      isActive = timelineStyleRoute;
      isTooltip = true;
    }
    if (
      ((previousLocation &&
        !previousLocation.events[previousLocation.events.length - 1]
          .isActionDone) ||
        (location &&
          !location.events[location.events.length - 1].isActionDone)) &&
      nextLocation &&
      !nextLocation.events[0].isActionDone
    ) {
      isActive = timelineStylePassive;
      isTooltip = false;
    }
  }
  return (
    <>
      <div
        className="spacer-s flex-gap-l flex-column sla-location_events"
        style={slaRouteStyle}
      >
        <div className="flex-column flex-gap-l">
          {location.events.map((event, index) => {
            return (
              <SLARouteEvent
                event={event}
                locationName={location.locationName}
                key={index}
              />
            );
          })}
        </div>
      </div>
      {nextLocation && (
        <div style={isActive}>
          {isTooltip && (
            <div className="sla-tooltip">
              <div className="sla-tooltip_text">
                <p>Docket has taken {currentPath}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default SLARouteLocation;
