import * as CONSTANTS from './constants';

export const completedListReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.FETCH_COMPLETED_LIST_BEGIN: return getCompletedTripListBegin(state);
        case CONSTANTS.FETCH_COMPLETED_LIST_SUCCESS: return getCompletedTripListSuccess(state, action);
        case CONSTANTS.FETCH_COMPLETED_LIST_FAILURE: return getCompletedTripListFailure(state, action);
       
        case CONSTANTS.FETCH_APPROVE_BEGIN: return getApproveBegin(state);
        case CONSTANTS.FETCH_APPROVE_SUCCESS: return getApproveSuccess(state, action);
        case CONSTANTS.FETCH_APPROVE_FAILURE: return getApproveFailure(state, action);


        case CONSTANTS.FETCH_REASONS_LIST_BEGIN: return getRejectReasonBegin(state);
        case CONSTANTS.FETCH_REASONS_LIST_SUCCESS: return getRejectReasonSuccess(state, action);
        case CONSTANTS.FETCH_REASONS_LIST_FAILURE: return getRejectReasonFailure(state, action);


       

        default:
            return state;
    }
}

function getCompletedTripListBegin(state) {
    return {
        ...state,
        tripLoading: true,
        completedTripData: null,
        completedTripError: null
    }
}
function getCompletedTripListSuccess(state, action) {
    return {
        ...state,
        tripLoading: false,
        completedTripData: action.payload,
        completedTripError: null
    }
}
function getCompletedTripListFailure(state, action) {
    return {
        ...state,
        tripLoading: false,
        completedTripError: action
        
    }
}

function getApproveBegin(state) {
    return {
        ...state,
        approveLoading: true,
        approveData: null,
        approveError: null
    }
}
function getApproveSuccess(state, action) {
    return {
        ...state,
        approveLoading: false,
        approveData: action.payload,
        approveError: null
    }
}
function getApproveFailure(state, action) {
    return {
        ...state,
        approveLoading: false,
        approveError: action
        
    }
}

function getRejectReasonBegin(state) {
    return {
        ...state,
        rejectReasonLoading: true,
        rejectReasons: null,
        rejectReasonError: null
    }
}
function getRejectReasonSuccess(state, action) {
    return {
        ...state,
        rejectReasonLoading: false,
        rejectReasons: action.payload,
        rejectReasonError: null
    }
}
function getRejectReasonFailure(state, action) {
    return {
        ...state,
        rejectReasonLoading: false,
        rejectReasonError: action
        
    }
}
