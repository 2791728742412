import { getValueFromKey } from "../../../../../../common/utils/utils";

export const groupChargesByBox = (charges, ftlLabourCharge) => {
    var groupedCharges = groupBy(charges, "itemsBO.uniqueIdentifier");
    groupedCharges = Object.keys(groupedCharges).reduce((current, next) => [...current, groupedCharges[next]], [])
    groupedCharges = groupedCharges.map(boxChargesArray => {
        var freightCharge, labourCharge;
        boxChargesArray.map(charge => {
            if (charge.chargeType === "FREIGHT_CHARGE") {
                freightCharge = {
                    ...charge.itemRatesBO,
                    amount     : charge.itemRatesBO.rateStructure === "FIXED" ? charge.itemRatesBO.rateBOs[0].amount : undefined,
                    rateBOs    : charge.itemRatesBO.rateStructure !== "FIXED" ? charge.itemRatesBO.rateBOs :[{}],
                    chargeId   : charge.id,
                    chargeType : charge.chargeType,
                    isMultipartRate  : charge.isMultipartRate,
                    weightMultiplier : charge.itemRatesBO.weightMultiplier
                }
            }
            else if (charge.chargeType === "LOADING_CHARGE") {
                labourCharge = {
                    ...charge.itemRatesBO,
                    amount     : charge.itemRatesBO.rateStructure === "FIXED" ? charge.itemRatesBO.rateBOs[0].amount : undefined,
                    rateBOs    : charge.itemRatesBO.rateStructure !== "FIXED" ? charge.itemRatesBO.rateBOs :[{}],
                    chargeId   : charge.id,
                    chargeType : charge.chargeType,
                    isMultipartRate : charge.isMultipartRate
                }
            }
        });
        return {
            details : {
                itemsBO     : {
                    ...boxChargesArray[0].itemsBO,
                    label : getBoxLabel(boxChargesArray[0].itemsBO),
                    key   : boxChargesArray[0].itemsBO?.uniqueIdentifier,
                    value : boxChargesArray[0].itemsBO?.uniqueIdentifier
                },
                chargeBasis : boxChargesArray[0].chargeBasis,
                chargeBasisValue : boxChargesArray[0].chargeBasisValue,
                weightMultiplier : freightCharge?.weightMultiplier
            },
            freightCharge : ftlLabourCharge ? undefined : freightCharge,
            labourCharge  : labourCharge
        }
    })
    return groupedCharges.filter(charge => charge !== undefined);
}

export const formChargesArray = (data, ftlLabourCharge, isMultipartRate = false) => {
    var freightChargeBO, labourChargeBO;
    if (!ftlLabourCharge && data?.freightCharge?.rateStructure) {
        freightChargeBO = {
            action      : data?.freightCharge.chargeId ? "EDIT" : "ADD",
            id          : data?.freightCharge.chargeId,
            itemRatesBO : getRatesBO(data.freightCharge, data?.details?.weightMultiplier, data?.details?.chargeBasis),
            chargeType  : "FREIGHT_CHARGE",
            itemsBO     : getItemsBO(data?.details?.itemsBO),
            chargeBasis : data?.details?.chargeBasis,
            chargeBasisValue : data?.details?.chargeBasisValue,
            isMultipartRate  : isMultipartRate
        }
    }
    if ((data?.labourCharge && data?.labourCharge?.rateStructure) || ftlLabourCharge) {
        labourChargeBO = {
            action      : data?.labourCharge.chargeId ? "EDIT" : "ADD",
            id          : data?.labourCharge.chargeId,
            itemRatesBO : getRatesBO(data.labourCharge, data?.details?.weightMultiplier, data?.details?.chargeBasis),
            chargeType  : "LOADING_CHARGE",
            itemsBO     : getItemsBO(data?.details?.itemsBO),
            chargeBasis : data?.details?.chargeBasis,
            chargeBasisValue : data?.details?.chargeBasisValue
        }
    }
    if (ftlLabourCharge) {
        return [ labourChargeBO ]
    }
    if (freightChargeBO && labourChargeBO) {
        return [ freightChargeBO, labourChargeBO ]
    }
    if (labourChargeBO) {
        return [ labourChargeBO ]
    }
    if (freightChargeBO) {
        return [ freightChargeBO ]
    }
}

const groupBy = (jsonArray, groupBy) => {
  return jsonArray.reduce((current, next) => {
    const value = getValueFromKey(next, groupBy);
    (current[value] = current[value] || []).push(next);
    return current;
  }, {});
}

const getBoxLabel = itemsBO => {
    if (itemsBO.itemType === "BOX") {
        return itemsBO.boxTypeBO?.name
    }
    else if (itemsBO.itemType === "GOODS") {
        return itemsBO.goodsTypesBO?.code
    }
    else if (itemsBO.itemType === "OTHER") {
        return "Default"
    }
}

const getRatesBO = (itemRatesBO, weightMultiplier, chargeBasis) => {
    if (itemRatesBO?.rateStructure === "FIXED") {
        return {
            rateStructure : itemRatesBO?.rateStructure,
            rateBOs : [{
                fromRange : 0,
                amount    : itemRatesBO?.amount,
                rateUnitType : chargeBasis.split("_")[1]
            }],
            weightMultiplier : weightMultiplier
        }  
    }
    return {
        ...itemRatesBO,
        rateBOs : itemRatesBO?.rateBOs?.map(rate => ({
            ...rate,
            rateUnitType : chargeBasis.split("_")[1]
        })),
        weightMultiplier : weightMultiplier
    }
}

const getItemsBO = itemBO => {
    const [ itemType, itemId ] = itemBO.key.split("_");
    var resultObj = { itemType, itemId };
    if (itemType === "BOX") {
        resultObj = {
            ...resultObj,
            boxTypeBO : {
                id    : itemId,
                name  : itemBO.label
            }
        }
    }
    else if (itemType === "GOODS") {
        resultObj = {
            ...resultObj,
            goodsTypesBO : {
                id       : itemId,
                code     : itemBO.label
            }
        }
    }
    else if (itemType === "OTHER" || itemId == 0) {
        resultObj = {
            ...resultObj,
            boxTypeBO : {
                id    : 0,
                name  : "Default"
            }
        }
    }
    return resultObj;
}