import React, { Component } from 'react';
// import '../login.scss'
import './forget-password.scss';
import { Card, Button, Input, Row, Col, Form } from 'antd';
import Login1 from '../../../assets/img/login-1.png';
import Login2 from '../../../assets/img/login-2.png';
import email from '../../../assets/img/icons/email.svg';
import Loadshare from '../../../assets/img/loadshare-logo.svg';
import { Constants, validationRules } from '../../../common/utils/constants';
import { apiRequest } from '../../../common/utils/apiGateway';
import { checkHttpStatus, notifyApiError } from '../../../common/utils/utils';
import { Mixpanel } from '../../../utils/mixpanel';
import moment from 'moment';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    Mixpanel.track("Forget Password Initialized", { 'forgetPasswordIdentity': moment().valueOf() })
    this.state = {
      isSuccess: false
    }
  }

  handleForgetPasswordn = (e) => {
    e.preventDefault();
    const that = this
    this.props.form.validateFieldsAndScroll((err, { email }) => {
      if (!err) {
        apiRequest({
          url: `/b2b/v1/user/forgot-password`,
          method: "GET",
          params: {
            email
          }
        }).then(({ data }) => {
          if (checkHttpStatus(data?.status) && data.response?.emailGenerated) {
            Mixpanel.track("Sent invite Successfully", { 'forgetPasswordEmail': moment().valueOf() })
            that.setState({
              isSuccess: true
            })
          } else {
            notifyApiError(data.response.exception, 'Error');
            that.props.form.setFieldsValue({
              email: ''
            })
          }
        });
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isSuccess } = this.state;
    return (
      <div className="containers">
        <Row>
          <Col span={12} style={{
            background: `url(${Login1}) no-repeat center center`,
            backgroundSize: 'cover'
          }}>
            <div
              className="centered-1"

            />
          </Col>
          <Col span={12} className="login-form" style={{
            background: `url(${Login2}) no-repeat center center`,
            backgroundSize: 'cover'
          }}>
            <div
              className="centered"

            >
              {/* <Card style={{ width: 100, heigth: 100 }}>Name</Card> */}
              <Card
                className="login-card"
                style={{
                  width: "410px",
                  borderRadius: '10px'
                }}
              >
                <h1 className="logo">

                  <img src={Loadshare} />

                </h1>
                <br />


                {
                  !isSuccess
                    ? <div>
                      <div
                        className="login-title"
                      >Forgot Pasword ?</div>
                      <small className="small-text">Enter the registered email address below. We will sent a link to reset password.</small>
                      <Form style={{ marginTop: '20px' }} layout="vertical" onSubmit={this.handleForgetPasswordn}>
                        <Form.Item label={<span className="form-label-text">E-mail</span>}>
                          {getFieldDecorator('email', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input email!'
                              },
                              {
                                pattern: validationRules.email,
                                message: "Please enter valid email"

                              }
                            ],
                          })(
                            <Input
                              placeholder="Enter your Email"
                            />,
                          )}
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" className="login-button" htmlType="submit">
                            {'Submit'}
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                    : <>
                      <h1 className="message-logo">

                        <img src={email} />

                      </h1>
                      <small className="small-text-sent">We have sent a password reset link to your email. Please check mail and click link to proceed.</small>
                      <Button onClick={e => { window.location.href = "/appv2/login" }} type="defult" className="ghost-button" block>
                        {'Close'}
                      </Button>
                    </>
                }


              </Card>

            </div>
            <div className="copywrite-text">
              © 2020 Loadshare Networks Pvt Ltd
            </div>
          </Col>
        </Row>
      </div>

    )
    // return (
    //   <Row className="forget-password">
    //     <Col span={12}>
    //       <div className="login-1">
    //         <img src={Login1} style={{ objectFit: 'cover', width: '100%' }} />
    //       </div>
    //     </Col>
    //     <Col span={12}>
    //       <div className="container">
    //         <div className="login-2">
    //           <img src={Login2} style={{ objectFit: 'cover', width: '100%' }} />
    //         </div>
    //         <Card
    //           className="login-card"
    //           style={{
    //             width: "410px",
    //             borderRadius: '10px'
    //           }}
    //         >
    //           <h1 className="logo">

    //             <img src={Loadshare} />

    //           </h1>
    //           {
    //             !isSuccess
    //               ? <div>
    //                 <br />
    //                 <h3>Forgot Pasword ?</h3>
    //                 <small className="small-text">Enter the registered email address below. We will sent a link to reset password.</small>
    //                 <Form style={{ marginTop: '20px' }} layout="vertical" onSubmit={this.handleForgetPasswordn}>
    //                   <Form.Item label={<span className="form-label-text">E-mail</span>}>
    //                     {getFieldDecorator('email', {
    //                       rules: [
    //                         {
    //                           required: true,
    //                           message: 'Please input email!'
    //                         },
    //                         {
    //                           pattern: validationRules.email,
    //                           message: "Please enter valid email"

    //                         }
    //                       ],
    //                     })(
    //                       <Input
    //                         placeholder="Enter your Email"
    //                       />,
    //                     )}
    //                   </Form.Item>
    //                   <Form.Item>
    //                     <Button type="primary" className="login-button" htmlType="submit">
    //                       {'Submit'}
    //                     </Button>
    //                   </Form.Item>
    //                 </Form>
    //               </div>
    //               : <>
    //                 <h1 className="message-logo">

    //                   <img src={email} />

    //                 </h1>
    //                 <small className="small-text">We have sent a password reset link to your email. Please check mail and click link to proceed.</small>
    //                 <Button onClick={e => { window.location.href = "/appv2/login" }} type="primary" className="ghost-button" ghost block>
    //                   {'Close'}
    //                 </Button>
    //               </>
    //           }


    //         </Card>
    //       </div>


    //     </Col>
    //   </Row>
    // )
  }
}

export default Form.create({ name: 'ForgetPassword' })(ForgetPassword);