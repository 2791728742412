import { callVehicleTypesAPI } from "../../services/common/fetch.services";
import store from "../../utils/store" ;
import { apiRequest } from "../utils/apiGateway";
import { BASE_URL_TYPES } from "../utils/constants";
import { checkAPIHttpStatus } from "../utils/utils";
import lovActionConstatns from "./lovActionConstants";

const dispatch = store.dispatch;
const { lovCodes, lovConstants } = store.getState().lovStore;

export const fetchLOVs = (category, refresh) => new Promise((resolve, reject) => {
    if (refresh || !lovCodes.hasOwnProperty(category)) {
        fetchLovsCall(category)
        .then(lovs => {
            dispatch({
                type : lovActionConstatns.SAVE_LOV_CODES,
                category,
                lovs
            });
            var constants = generateLOVConstants(category,lovs);
            resolve({ lovs, constants })
        })
        .catch(e => reject(e));
    }
    else {
        resolve({lovs : lovCodes[category], constants: lovConstants[category] });
    }
})

const fetchLovsCall = category => new Promise((resolve, reject) => {
    apiRequest({
        url         : "/util/v1/lov/fetch",
        method      : "POST",
        baseUrlType : BASE_URL_TYPES.DEV,
        data        : {
            filters : {
                lovCategories : [ category ]
            }
        }
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status) && response?.data?.response?.lovs) {
            resolve(response?.data?.response?.lovs);
        }
        else {
            reject({
                status  : 500,
                message : "Error in fetching LOVs!"
            });
        }
    }).catch(e => reject(e))
})

const generateLOVConstants = (category, lovs) => {
    var tempLovsConstatns = {};
    lovs.map(l => {
        tempLovsConstatns[l.lovValue] = l.lovCode;
    })
    dispatch({
        type    : lovActionConstatns.SAVE_LOV_CONSTANTS,
        category,
        lovConstants: tempLovsConstatns
    })
    return tempLovsConstatns;
}

export const fetchVehicleTypes = refresh => new Promise((resolve, reject) => {
    if (refresh || store.getState().lovStore.vehicleTypes.length == 0) {
        callVehicleTypesAPI()
        .then(response => {
            dispatch({
                type    : lovActionConstatns.SAVE_VEHICLE_TYPES,
                payload : response.vehicleTypes
            });
            resolve(response.vehicleTypes)
        })
        .catch(e => reject(e));
    }
    else {
        resolve(store.getState().lovStore.vehicleTypes);
    }
})