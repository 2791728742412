import { Input } from "antd";
import React, { Component } from "react";
import editPencil from '../../../../assets/img/icons/edit_box.svg';

class UserRemarks extends Component {

    constructor(props){
        super(props);
        this.state = {
            updateUserRemarks:false,
            userRemarks: props.consignment.userRemarks,
            consignmentId: props.consignment?.consignmentId
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(prevState.consignmentId != nextProps.consignment.consignmentId){
            return {
                ...prevState,
                userRemarks: nextProps.consignment?.userRemarks,
                consignmentId: nextProps.consignment?.consignmentId
            }
        }
        return prevState;
    }
    
    render(){
        const podVerified = this.props.consignment?.podVerificationStatus == "POD_VERIFIED"
        return (
            <div className='flex-column flex-gap-l'>
                <div className="text-bold">User Remarks</div>
                <Input value={this.state.userRemarks} id="podUserRemarks"
                    placeholder={!this.props.updatePermissions || podVerified ? "" : "Enter Verification Remarks"}
                    maxLength={128}
                    onChange={e => {
                        let remarks = e.target.value;
                        if(remarks.length > 128){
                            remarks = remarks.substr(0,127);
                        }
                        this.setState({ userRemarks: remarks }, () => this.props.onRemarksUpdate('userRemarks', this.state.userRemarks))
                    }}
                    disabled={!this.props.updatePermissions || podVerified}
                /> 
            </div>
        )
    }
}

export default UserRemarks;