import { Switch } from 'antd';
import React, { useState } from 'react';
import { schemaToAttrs } from '../form.utils';

export default function SwitchField(props){

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);

    const [checked, SetChecked] = useState(props.formData?.toString() === 'true')

    return (
        <>
            <Switch
                onChange={value => {
                    if(!props.readonly){
                        SetChecked(value);
                        props.onChange(value);
                    }
                }}
            disabled={props.disabled} checked={checked} {...uiAttrs}>
            </Switch>
            <label>
                {props.schema.title}
            </label>
        </>
    )
}