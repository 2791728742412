import React, { Component } from 'react';
import ImportExportBuilder from '../../shared-components/import-export-builder/import-export-builder';

class Export extends Component {

    componentDidCatch() {
    }

    render() {
        return (
            <div>
                <ImportExportBuilder builderTitle="Export" builderType="SETTINGS_EXPORT" />
            </div>
        )
    }
}

export default Export;