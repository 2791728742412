
export const DashboardReducer = (state = { loading: false, urlList: [] }, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_BEGIN': return {
            ...state,
            loading: true
        };
        case 'GET_DASHBOARD_SUCCESS': return {
            ...state,
            loading: false,
            urlList: action.payload
        };
        case 'GET_DASHBOARD_FAILURE': return {
            ...state,
            loading: false,
            urlList: [],
            error: action.payload
        };
        default: return state;
    }
}