import React from "react";
import { capitalizeString } from "../../../common/utils/utils";

export const getGenericErrorMessages = (errorType, fieldKey) => {
    var fieldText = fieldKey ? fieldKey : "This property";
    fieldText = capitalizeString(fieldText);
    switch (errorType) {
        case "enum"         : return undefined;
        case "required"     : return fieldText + " is mandatory!"

        case "exclusiveMinimum":
        case "minimum"         :
        case "min"             : return fieldText + " is less than minimum acceptable value!"

        case "maximum"      :
        case "max"          : return fieldText + " is more than maximum acceptable value!"

        case "pattern"      : return fieldText + " does not match acceptable pattern!"

        case "minLength"    : return fieldText + " is shorter than minimum acceptable length!"
        
        case "maxLength"    : return fieldText + " is longer than maximun acceptable length!"

        default             : return fieldText + " is invalid!"
    }
}

export const checkForDuplicateErrors = errors => {
    const errorMessages =errors.reduce((current, next) => {
        if (current.indexOf(next) < 0) {
            current.push(next)
        }
        return current;
    }, []);
    return errorMessages;
}

export const setSubmitButtonProps = (addButton, options) => {
    addButton.classList.add("lsn-btn-primary");
    addButton.classList.add("ant-btn");
    addButton.classList.remove("btn");
    
    if (options.className) {
        addButton.classList.add(...options.className.split(" "));
    }
    if (options.submitText !== undefined) {
        addButton.innerHTML = options.submitText;
    }
    if (options.alignEnd) {
        addButton.parentElement.classList.add("flex-box","justify-content-end", "spacer-m")
    }
}

export const addCancelButton = (addButton, options, cancel) => {
    const cancelButton = document.createElement("button");
    cancelButton.classList.add("ant-btn");
    cancelButton.type = "reset";
    if (options.className) {
        cancelButton.classList.add(...options.className.split(" "));
    }
    if (options.cancelText !== undefined) {
        cancelButton.innerHTML = options.cancelText;
    }
    if (typeof cancel === "function") {
        cancelButton.onclick = cancel;
    }
    addButton.parentElement.prepend(cancelButton)
}

const getUiOptions = (schema, key) => {
    if (schema && schema.hasOwnProperty(key)) {
        return schema[key]
    }
    return false;
}

export const getSubmitButtonOptions = schema => {
    const defaultSubmitOptions = {
        props: {
            disabled  : false,
            className : "ant-btn"
        },
        norender      : false,
        submitText    : "Submit"
    }
    return Object.assign({}, defaultSubmitOptions, getUiOptions(schema, "ui:submitButtonOptions"), getUiOptions(schema, "submitButtonOptions"))
}

export const getCancelButtonOptions = schema => {
    const defaultCancelOptions = {
        props: {
            disabled  : false,
            className : "ant-btn"
        },
        norender      : false,
        cancelText    : "Cancel"
    }
    return Object.assign({}, defaultCancelOptions, getUiOptions(schema, "ui:cancelButtonOptions"), getUiOptions(schema, "cancelButtonOptions"))
}
 
export const schemaToAttrs = (uiSchema, addReadOnly) => {
    const uiAttrs = {};
    if (uiSchema) {
        Object.keys(uiSchema).map(uiProp => {
            var key = uiProp;
            if (uiProp.indexOf(":") > -1) {
                key = uiProp.substr(uiProp.indexOf(":") + 1);
            }
            if (key == "classNames") {
                key = "className";
            }
            uiAttrs[key] = uiSchema[uiProp];
        });
    }
    if (addReadOnly) {
        uiAttrs.placeholder = "--";
        if (uiAttrs?.className) {
            uiAttrs.className += " read-only";
        }
        else {
            uiAttrs.className = "read-only";
        }
    }
    return uiAttrs;
}