import { validationRules } from "../../../../common/utils/constants";

const schema = {
    "type": "object",
    "properties": {
        "shipperDetails": {
            "type": "object",
            "required": ["shipperName", "contactShortCode", "shipperAddress", "pinCode", "stateCity"],
            "properties": {
                "contactShortCode": {
                    "type": "string",
                    "title": "Shipper Code",
                    minLength: 3,
                    maxLength: 5,
                    pattern: validationRules.shortCode
                },
                "shipperName": {
                    "type": "string",
                    "title": "Shipper Name", // add validation                    
                    pattern: validationRules.nameNumber
                },
                "shipperAddress": {
                    "type": "string",
                    "title": "Shipper Address"
                },
                "pinCode": {
                    "type": "number",
                    "title": "Shipper Pincode",
                    pattern: validationRules.pincode
                },
                "stateCity": {
                    "type": "string",
                    "title": "Shipper State/City"
                },
                "contactPersonName": {
                    "type": "string",
                    "title": "Contact Name",
                    pattern: validationRules.nameNumberContactPerson,
                    minLength: 1,
                    maxLength: 50
                },
                "contactNumber": {
                    "type": "string",
                    "title": "Contact Phone Number",
                    pattern: validationRules.mobile,
                },
                "email": {
                    "type": "string",
                    "title": "Contact Email",
                    pattern: validationRules.contractEmail
                },
                "gstin": {
                    "type": "string",
                    "title": "GST",
                    pattern: validationRules.gstNumber
                },
                "isMandatory": {
                    "type": "boolean",
                    "title": "Rate Card Validation"
                }
            }
        },

    }
}

const uiSchema = {
    "shipperDetails": {
        "classNames": "multi-row-field-objects hide-title",
        "shipperName": {
            "ui:placeholder": "Enter Shipper Name",
            "maxLength": 50

        },
        "contactShortCode": {
            // "ui:placeholder": "Enter Shipper Code",
            // "ui:addonBefore": "SHI"
            "minLength": 3,
            "maxLength": 5,
        },
        "shipperAddress": {
            "ui:widget": "GoogleAutoFillAddress",
            "ui:placeholder": "Enter Address",
            "maxLength": 500


        },
        "pinCode": {
            "ui:placeholder": "Enter Pincode",
            maxLength: 6
        },
        "stateCity": {
            "ui:placeholder": "(System generated)",
            "disabled": true
        },
        "contactPersonName": {
            "ui:placeholder": "Enter Contact Person Name",
            "maxLength": 50
        },
        "contactNumber": {
            "ui:placeholder": "Enter Contact Person Phone Number",
            "maxLength": 10,
            "minLenth": 10
        },
        "email": {
            "ui:placeholder": "Enter Contact Person Email"
        },
        "gstin": {
            "ui:placeholder": "Enter GST number if applicable",
            "maxLength": 15
            // "ui:field": "ToggleField"
        },
        "isMandatory": {
            "ui:placeholder": "Is mandatory",
            "ui:widget": "SwitchWidget",
            "disabled": true,
            "checked": true
        }
    }
}

const errorList = {
    "shipperDetails": {
        shipperName: {
            required: "Shipper name cannot be empty!",
            pattern: "Shipper name accepts only characters (a-z,A-Z,_,-,:)",
        },
        contactShortCode: {

            maxLength: "Shipper code length 3-5 characters",
            minLength: "Shipper code length 3-5 characters",
            required: "Shipper code cannot be empty!",
            pattern: "Shipper code accepts only characters (a-z,A-Z,_,-,:)",
        },
        contactPersonName: {
            pattern: "Contact name accepts only characters (a-z, A-Z)"
        },
        email: {
            pattern: "Enter valid Email ID"
        },
        gstin: {
            pattern: "Enter valid GSTIN"
        },
        contactNumber: {
            pattern: "Enter valid Phone Number"
        },
        pinCode: {
            required: "Pincode cannot be empty!",
        },
        shipperAddress: {
            required: "Address cannot be empty!",
        },
        stateCity: {
            required: "State/City cannot be empty!",
        }
    }

}

const validations = {
    shipperName: {
        required: true,
        minLength: 3
    },
    contactShortCode: {
        required: true,
        minLength: 3
    }
}

export default { schema, uiSchema, errorList, validations };