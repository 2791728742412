import { Select, Button, Card, Form, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { notifyApiError } from "../../../../../common/utils/utils";
import {
  getIndentCancelReasons,
  submitExpiredReasons,
} from "../../../common/services/search.services";
import { IndentListingConstants as _Constants } from "../../listing/state/indent.listing.constants";
import _ from 'lodash';

export default function IndentExpired(props) {
  const dispatch = useDispatch();
  const [reasons, setReasons] = useState([]);
  const [editedReasons, setEditedReasons] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [isAllowed, setAllowed] = useState(true);  
  useEffect(() => {
    setLoading(true);
    
    if(props.status === 'CANCELLED') {
        setAllowed(false);
    }
    getIndentCancelReasons()
      .then((response) => {
        setReasons(response);
       
        setLoading(false);
      })
      .catch((_) => {
        setReasons([]);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if(props.reasonBOList && props.reasonBOList.length > 0) {
        setAllowed(false);
        const reasonToBeSet = props?.reasonBOList[0] ? props?.reasonBOList[0] : undefined;               
        setEditedReasons({key: reasonToBeSet?.reasonCode, label: reasonToBeSet?.desc});
    }
  }, [props.reasonBOList])
  const submitReason = () => {    
    Modal.confirm({
      title: "Are you sure you want to submit?",
      onOk: () => {
        const params = {
          indentId: props.indentId,
          status: "EXPIRED",
          reasonBOList: [
            {
              reasonCode: editedReasons.key,
              reasonDesc: editedReasons.label,
            }
          ],
        };
        submitExpiredReasons(params)
          .then((response) => {
            setDisable(true);
            props.updatedExpiredReasonFetch();
          })
          .catch((_) => {
            notifyApiError("Error Reason Submit Failed", "ERROR!!");
          });
      },
    });
  };
  return (
    <Card>
      <div className="flex-box flex-box flex-gap-m">
        <div style={{ width: "30%" }} className="flex-box flex-gap-s">
          <label style={{ width: "35%", marginTop: 6 }}>
            Reason for {props.status === 'CANCELLED' ? 'cancel' : 'expiring'}
          </label>
          <Select
            style={{ width: "100%" }}
            loading={loading}
            value={editedReasons}
            onChange={(value) => {
              setEditedReasons(value);
            }}
            disabled={isDisable}
            placeholder={loading ? "Loading..." : "Select Reasons"}
            showSearch
            labelInValue
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showArrow
          >
            {reasons?.map((reason) => (
              <Select.Option
                key={reason.reasonCode}
                value={reason.reasonCode}
                label={reason.reasonDescription}
                title={reason.reasonDescription}
              >
                {reason.reasonDescription}
              </Select.Option>
            ))}
          </Select>
        </div>
        <>
          {isAllowed && (
            <>
              {isDisable ? (
                <div>
                  <Button onClick={() => setDisable(false)}>Edit Reason</Button>
                </div>
              ) : (
                <div>
                  <Button
                    disabled={_.isEmpty(editedReasons)}
                    onClick={submitReason}
                    className="lsn-btn-primary"
                  >
                    Submit Reason
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      </div>
    </Card>
  );
}
