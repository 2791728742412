import React from 'react';
import './consignments.scss'
import moment from 'moment';


const Consignments = (props) => {

  const data = {
    gstNumber: props ?.consignments ?.customer ?.organisation ?.gstNumber,
    bookingCity: props ?.consignments ?.bookingOfficeLoc ?.address ?.pincode ?.city ?.cityCode,
    bookingState: props ?.consignments ?.bookingOfficeLoc ?.address ?.pincode ?.city ?.stateBO ?.state,
    bookingAddress: props ?.consignments ?.bookingOfficeLoc ?.address ?.address1,
    sourceInfo: props ?.consignments ?.customer ?.organisation ?.name,
    destinationState: props ?.consignments ?.consignee ?.address ?.pincode ?.city ?.state,
    destinationCityname: props ?.consignments ?.consignee ?.address ?.pincode ?.city ?.name,
  }

  return (
    <div className="lead-information Consignments clearfix">
      <table className="table" style={{ float: 'left', width: '49%', marginRight: '10px' }}>
        <tbody><tr>
          <td>
            <img width="200px" src="https://s3-ap-southeast-1.amazonaws.com/beta.loadshare.net/images/Icons/logo-loadshare.png" />
          </td>
          <td>
            <div style={{ textAlign: 'right' }}>GST NO: {data ?.gstNumber}</div>
          </td>
        </tr>
          <tr>
            <td>
              <div>
                Booking Office: {data.bookingState ?.toUpperCase()} <br />
                Booking Branch: {data.bookingAddress}
              </div>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              <div className="mini-box">
                Consignor
                </div>
            </td>
            <td>
              <div className="mini-box">
                Consignee
                </div>
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <th colSpan={2} className="borders table-header" style={{ textAlign: 'left', padding: '10px' }}>
                    SHIPMENT DETAILS
                      </th>
                </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      Weight
                      </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      {props ?.consignments ?.totalWeight}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      {props ?.consignments ?.shipmentBOs.length === 1 ? 'L*B*H' : 'Volumentric Weight'}
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      {props ?.consignments ?.shipmentBOs ?.length === 1 ? props ?.consignments ?.shipmentBOs[0] ?.dimension : props ?.consignments ?.totalVolumentricWeight}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      Chargable Wt
                      </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      {props ?.consignments ?.totalChargableWeight ? `${props.consignments.totalChargableWeight} KG` : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      Count
                      </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      {props.consignments ?.totalShipmentCount}
                    </td>
                  </tr>
                </tbody></table>
            </td>
            <td>
              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <th colSpan={2} className="borders table-header" style={{ textAlign: 'left', padding: '10px' }}>
                    DOD DETAILS
                      </th>
                </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      DOD Instrument
                      </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      1200
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      DOD Amount
                      </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      {props.consignments ?.payableAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      -
                      </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      -
                      </td>
                  </tr>
                  <tr>
                    <td className style={{ textAlign: 'left', padding: '10px' }}>
                      &nbsp;
                      </td>
                    <td className style={{ textAlign: 'left', padding: '10px' }}>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>
              <p>
                Special Instructions:-
                </p>
            </td>
            <td>
              <textarea readOnly rows={5} />
            </td>
          </tr>
          <tr className="col-3">
            <td colSpan={2}>
              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <th colSpan={3} className="borders table-header" style={{ textAlign: 'left', padding: '10px' }}>
                    For use at time of delivery
                      </th>
                </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div style={{ height: '50px' }}>
                        Name:-
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div style={{ height: '50px' }}>
                        Signature
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div style={{ height: '50px' }}>
                        Seal
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div style={{ height: '50px' }}>
                        Date/Time
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div style={{ height: '50px' }}>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
        </tbody></table>
      <table className="table page-breaker" style={{ float: 'right', width: '47%', marginLeft: '10px' }}>
        <tbody><tr>
          <td className="border-bottom" colSpan={2} style={{ padding: '10px 0' }}>
            <div className="h3">
              {props.consignments ?.waybillNo}
            </div>
            <div>
              Date: {moment(props.consignments ?.bookingDate).format('DD/MM/YYYY HH:mm')} hrs
                </div> <br />
          </td>
        </tr>
          <tr>
            <td className="border-bottom" style={{ padding: '10px 0' }}>
              <div>
                Source: <b>{data.sourceInfo}</b>
              </div> <br />
            </td>
            <td className="border-bottom" style={{ padding: '10px 0', textAlign: 'right' }}>
              <div>
                Destination: <b>{data.destinationState} - {data.destinationCityname}</b>
              </div> <br />
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px 0' }}>
              <p>Mode: <b> {props.consignments ?.consignmentAttributes ?.shipment_mode} </b></p>
              <p>Payment Type: <b> {props.consignments ?.consignmentAttributes ?.payment_type} </b></p>
            </td>
            <td style={{ padding: '10px 0', textAlign: 'right' }}>
              <p>Service Type: <b> {props.consignments ?.consignmentAttributes ?.service_type} </b> </p>
              <p> &nbsp; </p>
            </td>
          </tr>
          <tr className="col-3">
            <td colSpan={2}>
              <table style={{ width: '100%' }}>
                <tbody><tr>
                  <th colSpan={3} className="borders table-header" style={{ textAlign: 'left', padding: '10px' }}>
                    Charges
                      </th>
                </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        Docket Charges
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        {props.consignments ?.consignmentAttributes ?.docket_charges}
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        DPH - {props.consignments ?.consignmentAttributes ?.dph}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        FOV
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        {props.consignments ?.consignmentAttributes ?.fov}
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      Other changes - {props.consignments ?.consignmentAttributes ?.docket_charges}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        COD/DOD charges
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        {props.consignments ?.consignmentAttributes ?.cod_charges}
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      ODA Charges - {props ?.consignments ?.consignmentAttributes ?.oda_charges}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        Carrier Risk charges
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        {props ?.consignments ?.consignmentAttributes ?.carrier_risk}
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      DOOR Delivery - {props ?.consignments ?.consignmentAttributes ?.door_delivery}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        Hammaii Charges
                        </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                        {props ?.consignments ?.consignmentAttributes ? props ?.consignments ?.consignmentAttributes['hammai-charges'] : ''}
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      Sub-Total - {props ?.consignments ?.consignmentAttributes ? props ?.consignments ?.consignmentAttributes['sub-total'] : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      SGST - {props ?.consignments ?.consignmentAttributes ?.sgst}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      ISGST - {props ?.consignments ?.consignmentAttributes ?.isgst}
                    </td>
                  </tr>
                  <tr>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      <div>
                      </div>
                    </td>
                    <td className="borders" style={{ textAlign: 'left', padding: '10px' }}>
                      Grand Total - {props ?.consignments ?.consignmentAttributes ?.grand_total}
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
        </tbody></table>
    </div>
  )
}

export default Consignments;