import React, { Component } from 'react'
import { Card, List, Row, Col, Button } from 'antd'
import { dataGet } from '../../../common/utils/utils'



class DocketDataCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { summaryData } = this.props;

        return (
            <Card>
                {/* <Row gutter={12}>
                    <Col span={24}> */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}
                >
                {
                    summaryData && summaryData?.length > 0 &&
                    summaryData?.map(docket => (
                        // <Col span={24 / summaryData?.length}>
                        //     <div className={"docket-sparkline"}>

                        //         <span className={docket?.title === this.props?.newtitle ? `title ${this.props.className}` : 'title'}>{docket.title}</span>

                        //         <div className="highlight">{docket.value}</div>
                        //     </div>
                        //     </Col>

                        <div className={"docket-sparkline"}>

                            <div className={'title'}>{docket.title}</div>

                            <div className="highlight">{docket.value}</div>
                        </div>

                    ))
                }
                </div>
                {/* </Col>
                </Row> */}
            </Card >
        )
    }
}

export default DocketDataCard