export const GET_LMS_LIST_BEGIN = 'GET_LMS_LIST_BEGIN';
export const GET_LMS_LIST_SUCCESS = 'GET_LMS_LIST_SUCCESS';
export const GET_LMS_LIST_FAILURE = 'GET_LMS_LIST_FAILURE';


export const GET_LMS_EDIT_LIST_BEGIN = 'GET_LMS_EDIT_LIST_BEGIN';
export const GET_LMS_EDIT_LIST_SUCCESS = 'GET_LMS_EDIT_LIST_SUCCESS';
export const GET_LMS_EDIT_LIST_FAILURE = 'GET_LMS_EDIT_LIST_FAILURE';
export const GET_LMS_EDIT_LIST_RESET = 'GET_LMS_EDIT_LIST_RESET';


export const GET_LMS_CREATE_BEGIN = 'GET_LMS_CREATE_BEGIN';
export const GET_LMS_CREATE_SUCCESS = 'GET_LMS_CREATE_SUCCESS';
export const GET_LMS_CREATE_FAILURE = 'GET_LMS_CREATE_FAILURE';


export const GET_LMS_USERS_LIST_BEGIN = 'GET_LMS_USERS_LIST_BEGIN';
export const GET_LMS_USERS_LIST_SUCCESS = 'GET_LMS_USERS_LIST_SUCCESS';
export const GET_LMS_USERS_LIST_FAILURE = 'GET_LMS_USERS_LIST_FAILURE';


export const GET_LMS_UPDATE_STATUS_BEGIN = 'GET_LMS_UPDATE_STATUS_BEGIN';
export const GET_LMS_UPDATE_STATUS_SUCCESS = 'GET_LMS_UPDATE_STATUS_SUCCESS';
export const GET_LMS_UPDATE_STATUS_FAILURE = 'GET_LMS_UPDATE_STATUS_FAILURE';




export const GET_LMS_USER_UPDATE_STATUS_BEGIN = 'GET_LMS_USER_UPDATE_STATUS_BEGIN';
export const GET_LMS_USER_UPDATE_STATUS_SUCCESS = 'GET_LMS_USER_UPDATE_STATUS_SUCCESS';
export const GET_LMS_USER_UPDATE_STATUS_FAILURE = 'GET_LMS_USER_UPDATE_STATUS_FAILURE';
export const GET_USER_RESET = 'GET_USER_RESET';


export const GET_LMS_USER_REFRESH_STATUS_BEGIN = 'GET_LMS_USER_REFRESH_STATUS_BEGIN';
export const GET_LMS_USER_REFRESH_STATUS_SUCCESS = 'GET_LMS_USER_REFRESH_STATUS_SUCCESS';
export const GET_LMS_USER_REFRESH_STATUS_FAILURE = 'GET_LMS_USER_REFRESH_STATUS_FAILURE';

