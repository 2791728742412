export const FETCH_COMPLETED_LIST_BEGIN = "FETCH_COMPLETED_LIST_BEGIN";
export const FETCH_COMPLETED_LIST_SUCCESS = "FETCH_COMPLETED_LIST_SUCCESS";
export const FETCH_COMPLETED_LIST_FAILURE = "FETCH_COMPLETED_LIST_FAILURE";

export const FETCH_APPROVE_BEGIN = "FETCH_APPROVE_BEGIN";
export const FETCH_APPROVE_SUCCESS = "FETCH_APPROVE_SUCCESS";
export const FETCH_APPROVE_FAILURE = "FETCH_APPROVE_FAILURE";

export const FETCH_REASONS_LIST_BEGIN = "FETCH_REASONS_LIST_BEGIN";
export const FETCH_REASONS_LIST_SUCCESS = "FETCH_REASONS_LIST_SUCCESS";
export const FETCH_REASONS_LIST_FAILURE = "FETCH_REASONS_LIST_FAILURE";


