import React, {Component} from 'react';

import { Card, Button, Icon } from 'antd';

import { Link } from 'react-router-dom';
import errorimg from '../../../assets/img/error404.png'




function PageNotFound (props)
{
    const navigateback = () => {
        props.history.goBack()
    }
    const cururl = window.location.pathname;
    return (
        
        <div className='flex-box page-wrapper justify-content-center' style={{height:"100vh"}}>
            <div className="flex-column justify-content-center">
                
                <div className="flex-box font-size-m-3 spacer-vs"><b>404. </b> <div className='litetext'>That's an error.</div></div>
                <div className="flex-box font-size-m-3 font-text">The requested page {cururl} was not found.</div>
                <div className="flex-box font-size-m-3 litetext">That's all we know.</div>
                
                <div className='flex-box v-docket-list spacer-vs'><Button className='atlas-primary-button' type="primary" onClick={navigateback}>Go Back </Button></div>
            </div>
            <div className="flex-column justify-content-center">
                <img src={errorimg} className='spacer-hl spacer-vl errorimg'/>
            </div>
        </div>
        
        )
}



export default PageNotFound;