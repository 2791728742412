import React, { PureComponent } from 'react'
import { canAllow, windowRedirect } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import VerificationTrips from './trips/trip-list';
import VerificationDocket from "./docket/docket-list";
import VerificationPods from './pod/pod-list';
import VerificationContracts from './contracts/contracts.verification';

class VerificationModule extends PureComponent {

    constructor(props) {
        super(props)
    }
    state = {
        verificationPermissions: {
            tripRead: canAllow(permissionTags.verification.trips.read),
            docketRead: canAllow(permissionTags.verification.docket.read),
            podRead: canAllow(permissionTags.verification.pod.read),
            contractRead: canAllow(permissionTags.verification.contract.read)
        },
    }
    render() {
        const { verificationPermissions } = this.state;
        if (verificationPermissions.tripRead) {
            return <VerificationTrips {...this.props} />
        }
        if(verificationPermissions.docketRead) {
            return <VerificationDocket {...this.props} />
        }
        if(verificationPermissions.podRead){
            return <VerificationPods  {...this.props} />
        }
        if(verificationPermissions.contractRead){
            return <VerificationContracts  {...this.props} />
        }
        return (
            <></>
        )
    }
}

export default VerificationModule;