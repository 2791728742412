import * as CONSTANTS from './constants';

export const pendingListReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.FETCH_PENDING_LIST_BEGIN: return getPendingTripListBegin(state);
        case CONSTANTS.FETCH_PENDING_LIST_SUCCESS: return getPendingTripListSuccess(state, action);
        case CONSTANTS.FETCH_PENDING_LIST_FAILURE: return getPendingTripListFailure(state, action);

        case CONSTANTS.GET_UPLOAD_PENDING_POD_BEGIN: return getPendingPODUploadBegin(state);
        case CONSTANTS.GET_UPLOAD_PENDING_POD_SUCCESS: return getPendingPODUploadSuccess(state, action);
        case CONSTANTS.GET_UPLOAD_PENDING_POD_FAILURE: return getPendingPODUploadFailure(state, action);
       

        default:
            return state;
    }
}

function getPendingTripListBegin(state) {
    return {
        ...state,
        tripLoading: true,
        podUploadData: null,
        pendingTripData: null,
        pendingTripError: null
    }
}
function getPendingTripListSuccess(state, action) {    
    return {
        ...state,
        tripLoading: false,
        pendingTripData: action.payload,
        pendingTripError: null
    }
}
function getPendingTripListFailure(state, action) {
    return {
        ...state,
        tripLoading: false,
        pendingTripError: action
        
    }
}

function getPendingPODUploadBegin(state) {
    return {
        ...state,
        podUploadLoading: true,
        podUploadData: null,
        podUploadError: null
    }
}
function getPendingPODUploadSuccess(state, action) {    
    return {
        ...state,
        podUploadLoading: false,
        podUploadData: action.payload,
        podUploadError: null
    }
}
function getPendingPODUploadFailure(state, action) {
    return {
        ...state,
        podUploadLoading: false,
        podUploadError: action
        
    }
}
