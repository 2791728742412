
import { initialState, FETCH_DRSS_BEGIN, FETCH_DRSS_SUCCESS, FETCH_DRSS_FAILURE } from './constants';
import { storePaginatedData } from '../../../common/utils/utils';


export const DrsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DRSS_BEGIN: return fetchDrsBegin(state);
        case FETCH_DRSS_SUCCESS: return fetchDrsSuccess(state, action);
        case FETCH_DRSS_FAILURE: return fetchDrsFailure(state, action);

        default:
            return state;
    }
}

function fetchDrsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchDrsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedDrs: storePaginatedData(action?.payload?.currentPageNo, state.PaginatedDrs ? state.PaginatedDrs : {}, action?.payload?.drses || action?.payload?.viewResponse, action.isReset),
        ...action.payload
    };
}

function fetchDrsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        consignments: []
    };
}