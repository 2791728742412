import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsNavigation, { NAV_DIRECTION } from "../../../../verification/components/details-navigation";
import IndentFormContainer from "../../../common/components/indent.form.container"
import CancelIndent from "../../../common/components/indent.cancel";
import { fetchIndentListing } from "../../listing/state/indent.listing.action";
import { tranformStatus } from "../../listing/state/indent.listing.util";
import IndentActions from "./indent.actions";
import RejectIndent from "../../../common/components/indent.reject";
import { IndentListingConstants as _Constants } from '../../listing/state/indent.listing.constants';
import IndentNonServicable from "./indent.non.servicable";
import { submitDraftIndent } from "../../../common/services/indent";
import { notifyApiSuccess } from "../../../../../common/utils/utils";
import IndentExpired from "./indent.expired";

export default function IndentsNavigation(props) {

    const { index, goBackToListing } = props;

    const [leftDisabled, SetLeftDisabled] = useState(false);
    const [rightDisabled, SetRightDisabled] = useState(false);
    const [currentIndex, SetCurrentIndex] = useState(index);
    const [readonly, SetReadonly] =  useState(true);
    const [params, SetParams] = useState(props.params);
    const [cancelModal, SetCancelModal] = useState(false);
    const [rejectModal, SetRejectModal] = useState(false);
    
    const indentsData = useSelector(state => state.indentList?.indets)
    const loading     = useSelector(state => state.indentList.loading);

    const dispatch = useDispatch();

    const formData = useSelector(state => state.indentCreateReducer.indentData)

    useEffect(() => fetchIndentListing(params)(dispatch), [])

    useEffect(() => {
        if (indentsData?.indents?.length > 0) {
            SetRightDisabled(indentsData?.offset + 1 === indentsData?.totalCount)
            SetLeftDisabled(indentsData.offset === 0 && currentIndex === 0)
        }
        else if (indentsData?.indents?.length == 0) {
            goBackToListing();
        }
        else if (indentsData?.indents?.length - 1 < currentIndex) {
            SetCurrentIndex(indentsData?.indents?.length - 1);
        }
    }, [ indentsData ])

    const updateIndentDetails = (nextPage, index) => {
        params.pageNo = nextPage;
        SetCurrentIndex(index !== undefined ? index : 0)
        SetParams(params);
        fetchIndentListing(params)(dispatch);
        return;
    }

    const updatedExpiredReasonFetch = () => {
        fetchIndentListing(params)(dispatch);
    }

    const disableNav = navDirection => {
        Modal.confirm({
            title   : "Are you sure to leave without saving made changes?",
            onOk    : _ => {
                SetReadonly(true);
                setTimeout(() => {
                    Modal.destroyAll();
                    navigate(navDirection, true);
                }, 0);
            }
        })
    }
    const navigate = (navDirection, proceed = false) => {
        if (!readonly && !proceed) {
            return disableNav();
        }
        var nextIndex = currentIndex;
        if (navDirection === NAV_DIRECTION.RIGHT) {
            nextIndex = nextIndex + 1;
        }
        else if (navDirection === NAV_DIRECTION.LEFT) {
            nextIndex = nextIndex - 1;
        }
        if (nextIndex + indentsData?.offset + 1 <= indentsData?.totalCount) {
            SetRightDisabled(nextIndex + indentsData?.offset + 1 === indentsData?.totalCount)
            SetLeftDisabled(nextIndex + indentsData?.offset + 1 === 1)
            if (nextIndex == indentsData?.currentPageSize) {
                updateIndentDetails(params.pageNo + 1);       
            }
            else if (nextIndex == -1) {
                updateIndentDetails(params.pageNo - 1);
            }
            else {
                SetCurrentIndex(nextIndex);
            }
        }
        else {
            goBackToListing();
        }
    }

    const centerBlock = _ => {
        var currentIndent = indentsData?.indents[currentIndex];
        if (currentIndent) {
            var currentStatus = formData.id == currentIndent.id ? formData.status : currentIndent.status;
            return (
                <div className="flex-column flex-gap-m justify-content-center align-items-center">
                    {
                        currentIndent && 
                        <div className="flex-box flex-gap-l justify-content-center align-items-center">
                            <div className="font-size-l-1"> <strong> {currentIndent.indentCode} </strong> </div> 
                            -
                            <div> {tranformStatus(currentStatus)} </div>
                        </div>
                    }
                    <div className="flex-box justify-content-center align-items-center">
                        <div className="font-size-m-2">{ indentsData?.offset + currentIndex + 1 } of {indentsData?.totalCount}</div>
                    </div>
                </div>
            )
        }
        else {
            goBackToListing();
        }
    }

    if (loading || !indentsData?.indents) {
        return <></>
    }

    const closeModalAndGoNext = (modalFunc, action) => {
        modalFunc(false);
        Modal.success({
            title  : "Indent " + action + " successfully!",
            okText : (currentIndex + indentsData?.offset == indentsData?.totalCount - 1 ? "Go back to Indents Listing" : "Go to next Indent"),
            onOk   : _ => {
                Modal.destroyAll();
                navigate(NAV_DIRECTION.RIGHT)
            }
        })
    }
    
    return (
        <>
            <div className="flex-column flex-gap-xl">
                <DetailsNavigation goBack={goBackToListing} navigate={navigate} restrictKeyboardNav={true}
                    totalCount={indentsData?.totalCount} index={currentIndex + indentsData?.offset} goBack={_ =>{
                        if (readonly) {
                            props.history.push("/appv2/indent/dashboard");
                        }
                        else {
                            Modal.confirm({
                                title   : "Are you sure to cancel edit? Changes made will be lost.",
                                onOk    : _ => {
                                    Modal.destroyAll();
                                    props.history.push("/appv2/indent/dashboard");
                                }
                            })
                        }
                    }}
                    leftDisabled={leftDisabled} rightDisabled={rightDisabled} centerBlock={centerBlock()}
                />

                {
                    ( formData.id == indentsData?.indents[currentIndex].id ?
                      formData.status : 
                      indentsData?.indents[currentIndex].status) !== "CANCELLED" && 
                    <IndentActions readonly={readonly} SetReadonly={SetReadonly}
                    status={formData.id == indentsData?.indents[currentIndex]?.id ? formData.status :indentsData?.indents[currentIndex]?.status}
                    indentId={indentsData?.indents[currentIndex]?.id}
                    onIndentSubmit={_ => closeModalAndGoNext(s => s, "submitted")}
                    onIndentApprove={_ => navigate(NAV_DIRECTION.RIGHT)}
                    onReject={_ =>  Modal.confirm({
                        title      : "Are you sure to Reject?",
                        okText     : "Yes",
                        cancelText : "No",
                        onOk       : _ => {
                            Modal.destroyAll();
                            SetRejectModal(true)
                        }
                    })}
                    onCancel={_ => {
                        Modal.confirm({
                            title      : "Are you sure to cancel?",
                            okText     : "Yes",
                            cancelText : "No",
                            onOk       : _ => {
                                Modal.destroyAll();
                                SetCancelModal(true);
                            }
                        })
                    }}
                    onEdit={_ => SetReadonly(false)} />
                }

                {
                    ( formData.id == indentsData?.indents[currentIndex].id ?
                      formData.status : 
                      indentsData?.indents[currentIndex].status) === "NON_SERVICEABLE" && readonly &&
                    <IndentNonServicable indentId={indentsData?.indents[currentIndex]?.id} />
                }
                {
                    ( formData.status === 'EXPIRED' || formData.status === 'CANCELLED') && 
                    <IndentExpired 
                        indentId={indentsData?.indents[currentIndex]?.id} 
                        updatedExpiredReasonFetch={updatedExpiredReasonFetch} 
                        status={indentsData?.indents[currentIndex].status} 
                        reasonBOList={formData.reasonBOList}                       
                    />
                }

                <Spin spinning={loading}>
                    <IndentFormContainer hideHeader={true} history={props.history}
                        indentId={indentsData?.indents[currentIndex]?.id}
                        readonly={readonly} onCancel={_ => SetReadonly(true)}
                        onIndentCreateSuccess={(response,submit) => {
                        SetReadonly(true);
                        if (submit) {
                            submitDraftIndent(indentsData?.indents[currentIndex]?.id).then(_ => {
                                dispatch({
                                    type: _Constants.INDENT_LISTING_LOADING_START
                                });
                                notifyApiSuccess("Indent Submitted successfully!!", undefined);
                                setTimeout(() => {
                                    dispatch({
                                        type: _Constants.INDENT_LISTING_LOADING_STOP
                                    }); 
                                }, 100);
                            });
                        }
                    }} />
                </Spin>
            </div>

            { cancelModal && <CancelIndent cancelModal={cancelModal} onCancel={_ => SetCancelModal(false)}
             indentId={indentsData?.indents[currentIndex]?.id} code={indentsData?.indents[currentIndex]?.indentCode}
             onIndentCancel={_ => closeModalAndGoNext(SetCancelModal, "cancelled")} /> }
            { rejectModal && <RejectIndent openRejectModal={rejectModal} onCancel={_ => SetRejectModal(false)}
             indentId={indentsData?.indents[currentIndex]?.id} code={indentsData?.indents[currentIndex]?.indentCode}
             onIndentReject={_ => closeModalAndGoNext(SetRejectModal, "rejected")} /> }
        </>
    )
}