import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Table, Button, Empty, Row, Col, Select, Tooltip } from 'antd';
import moment from 'moment';
import { fetchPrintHistory, getPrinthistoryById } from './actions';
import PrintOperation from '../../shared-components/print-operation';
import { Constants, DownloadTags } from '../../../common/utils/constants';
import { notifyApiError, reportsDownload } from '../../../common/utils/utils';

class PrintHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Batch Id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: 'Count',
                    dataIndex: 'count',
                    key: 'count',
                },
                {
                    title: 'User',
                    dataIndex: 'user',
                    key: 'user',
                    render: (data, row) => (row?.user?.name)
                },
                {
                    title: 'Sticker Type',
                    dataIndex: 'sticker',
                    key: 'sticker',
                },
                {
                    title: 'Date/Time',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (data, row) => <div>{moment(data).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.updatedAt).fromNow()}</small></div>
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (data, row) => (
                        <Row gutter={12}>
                            <Col span={12}>
                                <Select onChange={(value) => this.onPrintSelect(value, row)} style={{ width: '100%' }} placeholder="Select Awb Sticker" showArrow>
                                    {Constants.shipmentFormat.map(format => (
                                        <Select.Option
                                            key={format.name}
                                            value={format.value}
                                            label={format.name}
                                        >
                                            <Tooltip title={format.name} placement="right">
                                                {format.name}
                                            </Tooltip>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Button onClick={e => this.confirmPrintById(row)} type="primary">Print</Button>
                            </Col>
                        </Row>
                    )
                },
            ],
            pageNo: 1,
            filterPayload: {
                filters: {
                    pageNo: 0,
                    pageSize: 10,
                    totalCount: 0
                }
            },
            selectedShipmentType: {},
            disablePrint: false
        }
        this.getPaginatedData(1)
    }

    onPrintSelect = (value, row) => {
        const selectedShipmentType = { ...this.state.selectedShipmentType }

        selectedShipmentType[row.id] = value;

        this.setState({
            selectedShipmentType
        })
    }
    applyPagination = (filters, isReset = false) => {
        if (isReset) {
            this.setState({
                pageNo: 1
            })
        }
        this.props.fetchPrintHistory({ ...this.state.filterPayload.filters, ...filters }, isReset);
    }
    reloadCustomerList = () => {
        this.setState({
            pageNo: 1
        })
        this.InputSearchRef.current.input.state.value = '';
        this.applyPagination({ pageNo: 1 }, true);
    }
    getPaginatedData = (pageNo) => {
        const { PaginatedPrintHistory, } = this.props;
        if (!PaginatedPrintHistory ? !PaginatedPrintHistory : !PaginatedPrintHistory[pageNo]) {
            this.applyPagination({ pageNo })
        }
        this.setState({
            pageNo
        });
    }
    confirmPrintById = (waybill) => {
        if (!waybill) {
            return;
        }

        if (!this.state.selectedShipmentType[waybill.id]) {
            notifyApiError(
                "Please choose the Shipment type",
                "Validation",
            );
            return;
        }

        // disable all print
        const that = this;
        this.setState({
            disablePrint: true
        }, () => {
            let data = {
                commCode: waybill.shipmentType,
                locationId: Constants.userLocationId,
                userId: waybill.userId,
                documentType: waybill.shipmentType,
                partnerId: Constants.CURRENT_USER_ID,
                batchId: waybill.id,
                shipmentType: that.state.selectedShipmentType[waybill.id]
            };
            that.getPrinthistoryById(data);
        })
    }
    getPrinthistoryById = (params) => {
        const that = this;
        this.setState({
            disablePrint: true
        }, () => {
            that.state.getPrinthistoryById(params).then(resp => {
                this.setState({
                    disablePrint: false
                })
                if (resp.data.response && resp.data.response.url) {
                    reportsDownload(
                        resp.data.response.url,
                        DownloadTags.s3
                    );
                }
            });
        })
    }
    render() {
        const actionBtns = (<PrintOperation shipmentFormat={Constants.shipmentFormat} buttonLabel="Print By AWB" />);
        const { columns, pageNo } = this.state;
        const { PaginatedPrintHistory, printData } = this.props;
        return (
            <>
                <Card className="PrintHistory" title={<h2>Print History</h2>} extra={actionBtns} >
                    <Table
                        bordered
                        locale={{
                            emptyText: (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }}
                        loading={false}
                        pagination={{
                            onChange: this.getPaginatedData,
                            total: printData?.totalCount,
                            position: 'bottom',
                            current: pageNo,
                            pageSize: printData?.pageSize
                        }}
                        dataSource={PaginatedPrintHistory ? PaginatedPrintHistory[pageNo] : []}
                        columns={columns} />
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    printData: state.printHistory,
    PaginatedPrintHistory: state.printHistory?.PaginatedPrintHistory,
    header: state?.header,
});
const mapDispatchToProps = dispatch => ({
    fetchPrintHistory: (param, isReset = false) => dispatch(fetchPrintHistory(param, isReset)),
    getPrinthistoryById: (param) => dispatch(getPrinthistoryById(param)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PrintHistory);