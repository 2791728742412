import React, { Component } from "react";
import "./trips.scss";
import {
  Card,
  Col,
  Divider,
  Icon,
  List,
  Row,
  Button,
  Input,
  Spin,
  Modal,
  DatePicker,
  Form,
  InputNumber,
  Checkbox,
  Select,
  Alert, Empty
} from "antd";
import {
  getColumn,
  getColumn2,
  getCostColumn,
  PODColumns,
  paymentColumns
} from "./column.d";
import {
  dataGet,
  formatCurrency,
  notifyApiError,
  notifyApiSuccess
} from "../../../common/utils/utils";
import { table as Table } from "../../../components/ui/table/Table";
import ImageViewer from "./ImageViewer";
import FileUpload from "../../ui/file-upload/file.upload";
import {
  Constants,
  UPLOAD_BUTTON_TYPE,
  TripVerificationStatus
} from "../../../common/utils/constants";
import * as apiUtils from "../../../common/utils/apiGateway";
import _ from "lodash";
import moment from "moment";


const { confirm } = Modal;
function checkHttpStatus(status) {
  switch (status?.code) {
    case 403:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 401:
      notifyApiError(status?.message, "ERROR:");
      // window.location.href = '/';
      return false;
    case 500:
    case 503:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 200:
    case 201:
    case 202:
      return true;
  }
}

class TripDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editAllowed:
        props.location.state?.editAllowed &&
        (props.location.state?.status === "verify" ||
          props.location.state?.status === "reverify"),
      isPaymentRequested: props.location.state?.status === "request_payment",
      tripId: this.props.location.state?.trip,
      loading: false,
      LRImages: [],
      VendorSlipsImages: [],
      CustomerSlipImages: [],
      costRevenuesImages: [],
      clearLR: false,
      showHistoryModal: false,
      showTransactionModal: false,
      history: [],
      trip: {},
      tripTransactionCategory: "COST",
      transactionModal: "Cost",
      columns: [
        {
          title: "Timestamp",
          dataIndex: "timestamp",
          key: "timestamp"
        },
        {
          title: "User",
          dataIndex: "user",
          key: "user"
        },
        {
          title: "Changes",
          dataIndex: "message",
          key: "message"
        }
      ],
      podData: [],
      filteredData: [],
      searchPOD: "",
      showDurationModal: false,
      startDuration: undefined,
      endDuration: undefined,
      durationLoading: false,
      durationError: undefined,

      showAssistingPersonModal: false,
      nameAssistingPerson: undefined,
      mobileAssistingPerson: undefined,
      assistingpersonLoading: false,
      assistingpersonError: undefined,

      showDistanceModal: false,
      startDistance: undefined,
      endDistance: undefined,
      distanceLoading: undefined,
      distanceError: undefined,

      baseCost: undefined,
      baseCostLoading: false,

      categoryError: undefined,
      showCategoryModal: false,
      selectedCategory: undefined,
      categories: [],
      costTransactions: [],
      revenueTransactions: [],
      removedTransaction: false,
      showRemoveModal: false,
      removeCost: undefined,
      removeRevenue: undefined,
      showLinkedText: false,
      isLoadboardTripCreated: false,
      paymentRequests: [],
      showPaymentModal: false,
      paymentType: undefined,
      paymentAmount: undefined,
      paymentError: undefined,

      LRError: undefined,
      vendorSlipError: undefined,
      customerSlipError: undefined,
      acceptTypes: [".jpg", ".JPG", ".JPEG", ".jpeg", ".png", ".PNG", ".pdf", ".PDF"]
    };
    this.customerRef = React.createRef();
    this.vendorRef = React.createRef();
    this.lrGCNRef = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.reload();
    
  }

  componentDidUpdate(prevProp, prevState) {
    // if (
    //   prevState.trip.verificationStatus !==
    //   this.state.trip.verificationStatus &&
    //   this.state.trip.verificationStatus === "VERIFIED"
    // ) {
    //   this.setState({
    //     editAllowed: false
    //   });
    // }
  }

  reload = () => {
    if (this.props.location.state?.trip) {
      this.setState({ loading: true });
      apiUtils
        .apiRequest({
          url: `/b2b/v1/trip-verification/view/${this.props.location.state?.trip}`,
          method: "GET"
        })
        .then(res => {
          const trip = res?.data?.response?.basicDetails;
          const LRImages = res?.data?.response?.lrImgs;
          const vendorSlips = res?.data?.response?.vendorSlips;
          const customerSlips = res?.data?.response?.customerSlips;
          const podData = res?.data?.response?.podDetails;
          const costTransactions = res?.data?.response?.costTransactions;
          const revenueTransactions = res?.data?.response?.revenueTransactions;
          const paymentRequests = res?.data?.response?.paymentRequests;
          const isLoadboardTripCreated = trip?.isLoadboardTripCreated
            ? trip?.isLoadboardTripCreated
            : false;
          this.setState(
            {
              trip,
              costTransactions,
              revenueTransactions,
              startDuration: trip?.startTime
                ? moment(trip?.startTime)
                : undefined,
              endDuration: trip?.endTime ? moment(trip?.endTime) : undefined,
              startDistance: trip?.startKms,
              endDistance: trip?.endKms,
              isLoadboardTripCreated,
              paymentRequests,
              nameAssistingPerson: trip?.assistingPersonName,
              mobileAssistingPerson: trip?.assistingPersonMobile
            },
            () => {
              const lr = this.setImages(LRImages, "LR");

              const vs = this.setImages(vendorSlips, "VENDOR_SLIP");
              const cs = this.setImages(customerSlips, "CUSTOMER_SLIP");
              Promise.all([lr, vs, cs]).then(results => {
                this.setState({
                  clearLR: false,
                  loading: false,
                  podData,
                  filteredData: podData
                });
              });
              // this.setImages(LRImages, 'LR').then(res => {

              // })
              // this.setState({clearLR: false})
              // files = files.map((fl, index) => {

              //     if (_.hasIn(fl, 'imgLink')) {
              //         fl['url'] = fl['imgLink'];
              //         fl['name'] = 'LR' + index;
              //         fl['uid'] = fl['id'];
              //         fl['status'] = 'done';
              //     };
              //     return fl;

              // });
              // this.setState({ LRImages: files, clearLR: true }, () => {
              //     this.setState({ trip, clearLR: false })
              // })
            }
          );
        });
    }
  };

  setImages = (fls, type) => {
    return new Promise(resolve => {
      let files = fls?.map((fl, index) => {
        if (_.hasIn(fl, "imgLink")) {
          fl["url"] = fl["imgLink"];
          fl["name"] = type + index;
          fl["uid"] = fl["id"];
          fl["status"] = "done";
        }
        return fl;
      });

      switch (type) {
        case "LR":
          this.setState({ LRImages: files });
          break;
        case "VENDOR_SLIP":
          this.setState({ VendorSlipsImages: files });
          break;
        case "CUSTOMER_SLIP":
          this.setState({ CustomerSlipImages: files });
          break;
        default:
          break;
      }

      resolve(true);
    });
  };

  onRemoveFile = file => {
    const _this = this;
    confirm({
      title: "Are you sure delete this file?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        apiUtils
          .apiRequest({
            url: `/b2b/v1/trip-verification/remove-img/${file.id}`,
            method: "PUT"
          })
          .then(res => {
            if (res?.data?.status) {
              notifyApiSuccess(`file removed successfully!`, "SUCCESS");
              _this.reload();
            }
          });
      },
      onCancel() {
        return false;
        // console.log('Cancel');
      }
    });
    return false;
  };

  onUploadFinish = (e, type) => {
    // let { LRImages } = this.state;
    // const payload = {
    //     "imgLink": e,
    //     "url": e,
    //     "name": type+Math.random(),
    //     "uid": Math.random()
    // };
    // LRImages.push(payload);
    // this.setState({LRImages,clearLR: true }, () => {
    //     this.setState({clearLR: false});
    // })
    const payload = {
      imgLink: e,
      tripId: this.state.tripId,
      imgType: type
    };

    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/add-img`,
        method: "POST",
        data: payload
      })
      .then(res => {
        if (res?.data?.status) {
          this.setState({ clearLR: true });
          if (type == "LR") {
            this.setState({ LRError: undefined });
          } else if (type === "VENDOR_SLIP") {
            this.setState({ LRError: undefined });
          } else if (type === "CUSTOMER_SLIP") {
            this.setState({ customerSlipError: undefined });
          }
          notifyApiSuccess(`${type} add successfully!`, "SUCCESS");
          this.reload();
        } else {
          this.setState({ clearLR: true });
          this.reload();
        }
      });
  };

  showHistory = () => {
    this.setState(
      {
        history: []
      },
      () => {
        apiUtils
          .apiRequest({
            url: `b2b/v1/trip-verification/view-history/${this.props.location.state?.trip}`,
            method: "GET"
          })
          .then(res => {
            let history = [];

            let historyData = res?.data?.response?.history;
            if (historyData && historyData.length > 0) {
              history = historyData.map(hist => {
                return this.UpdateTypeMessages(hist);
              });
            }
            if (history.length > 0) {
              this.setState({ history }, () => {
                this.setState({ showHistoryModal: true });
              });
            }
          });
      }
    );
  };

  refreshRevenue = () => {
    apiUtils
          .apiRequest({
            url: `b2b/v1/rateCard/recalculateRatesForTrip/${this.props.location.state?.trip}`,
            method: "GET"
          })
          .then(res => {
            if (res?.data?.status) {
              notifyApiSuccess(`Refresh successfully!`, "SUCCESS");
              this.reload();
            } else {
              notifyApiError("Please Contact Support", "ERROR")
            }
          });
  }

  cancelHistory = () => {
    this.setState({ showHistoryModal: false });
  };

  onUploadFinishCostRevenue = (e, type) => {
    let { costRevenuesImages } = this.state;
    const payload = {
      imgLink: e,
      url: e,
      name: type + Math.random(),
      uid: Math.random()
    };
    costRevenuesImages.push(payload);
    this.setState({ costRevenuesImages, clearLR: true }, () => {
      this.setState({ clearLR: false }, () => {
        this.props.form.setFieldsValue({ attachmentLinks: costRevenuesImages });
      });
    });
  };

  onRemoveCostRevenueImage = e => {
    let { costRevenuesImages } = this.state;

    let findIndex = costRevenuesImages.findIndex(img => img?.url === e?.url);

    if (findIndex >= 0) {
      costRevenuesImages.splice(findIndex, 1);
    }

    this.setState({ costRevenuesImages: [...costRevenuesImages] }, () => {
      this.props.form.setFieldsValue({ attachmentLinks: costRevenuesImages });
    });
  };

  showTransaction = type => {
    this.setState({
      showTransactionModal: true,
      tripTransactionCategory: type,
      transactionModal: type === "COST" ? "Cost" : "Revenue"
    });
  };

  cancelTransactionModal = () => {
    this.props.form.resetFields();
    this.setState({
      showTransactionModal: false,
      costRevenuesImages: [],
      loading: false
    });
  };

  submitCostRevenue = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      this.setState({ loading: true });
      let attachmentLinks = this.state.costRevenuesImages?.map(v => v?.imgLink);
      let { isLoadboardTripCreated, tripTransactionCategory } = this.state;
      let checkforLoadboard = tripTransactionCategory === "REVENUE";

      let payload = {
        tripId: this.state.tripId,
        tripTransactionType: this.state.tripTransactionCategory,
        chargeType: values?.tripTransactionType,
        amount: values?.amount,
        transactionDate: values?.transactionDate.valueOf(),
        createLinkedTransaction: values?.createLinkedTransaction,
        attachmentLinks
      };

      if (values["createLinkedTransaction"]) {
        payload["linkedTxnAmount"] = values["linkedTxnAmount"];
      }

      if (
        !isLoadboardTripCreated &&
        ((checkforLoadboard && values["createLinkedTransaction"]) ||
          !checkforLoadboard)
      ) {
        this.createLoadBoardTrip(payload?.tripId).then(res => {
          if (res) {
            apiUtils
              .apiRequest({
                url: `/b2b/v1/trip-verification/add-txn`,
                method: "POST",
                data: payload
              })
              .then(res => {
                if (checkHttpStatus(res?.data?.status)) {
                  this.reload();
                  this.cancelTransactionModal();
                } else {
                  this.setState({ loading: false });
                }
              });
          } else {
            this.setState({ loading: false });
          }
        });
      } else {
        apiUtils
          .apiRequest({
            url: `/b2b/v1/trip-verification/add-txn`,
            method: "POST",
            data: payload
          })
          .then(res => {
            if (checkHttpStatus(res?.data?.status)) {
              this.reload();
              this.cancelTransactionModal();
            } else {
              this.setState({ loading: false });
            }
          });
      }
    });
  };

  createLoadBoardTrip = tripId => {
    let p1 = new Promise(resolve => {
      apiUtils
        .apiRequest({
          url: `/b2b/v1/trip-verification/loadboard/create-trip/${tripId}`,
          method: "POST"
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            this.setState({ isLoadboardTripCreated: true }, () => {
              this.reload();
              resolve(true);
            });
          } else {
            resolve(false);
          }
        });
    });

    return p1;
  };

  UpdateTypeMessages = data => {
    let user = data?.username;
    switch (data?.updateType) {
      case TripVerificationStatus.REMOVE_LR_IMG:
      case TripVerificationStatus.REMOVE_CUSTOMER_SLIP:
      case TripVerificationStatus.REMOVE_VENDOR_SLIP:
        let removeNewCase =
          data?.updateType === TripVerificationStatus.REMOVE_LR_IMG
            ? "LR"
            : data?.updateType === TripVerificationStatus.REMOVE_CUSTOMER_SLIP
              ? "Customer"
              : "Vendor";

        return {
          message: (
            <div>
              <span style={{ display: "inline-block" }}>
                Removed {`{${removeNewCase} ${data?.metaData?.imgLink.split('.').pop() === 'pdf' || data?.metaData?.imgLink.split('.').pop() === 'PDF' ? 'PDF' : 'Image'}}`}
              </span>{" "}
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  // top: 5
                }}
              >
                {data?.metaData?.imgLink.split('.').pop() === 'pdf' || data?.metaData?.imgLink.split('.').pop() === 'PDF' ?
                  <a href={data?.metaData?.imgLink} target="_blank">
                    <Icon type="file-pdf" />
                  </a>
                  :
                  <ImageViewer fileList={[{ url: data?.metaData?.imgLink }]} />
                }

              </span>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.ADD_LR_IMG:
      case TripVerificationStatus.ADD_CUSTOMER_SLIP:
      case TripVerificationStatus.ADD_VENDOR_SLIP:
        let addNewCase =
          data?.updateType === TripVerificationStatus.ADD_LR_IMG
            ? "LR"
            : data?.updateType === TripVerificationStatus.ADD_CUSTOMER_SLIP
              ? "Customer"
              : "Vendor";
        return {
          message: (
            <div>
              <span style={{ display: "inline-block" }}>
                {/* Added {`{${addNewCase} Image}`} */}
                Added {`{${addNewCase} ${data?.metaData?.imgLink.split('.').pop() === 'pdf' || data?.metaData?.imgLink.split('.').pop() === 'PDF' ? 'PDF' : 'Image'}}`}
              </span>{" "}
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  top: 5
                }}
              >
                {data?.metaData?.imgLink.split('.').pop() === 'pdf' || data?.metaData?.imgLink.split('.').pop() === 'PDF' ?
                  <a href={data?.metaData?.imgLink} target="_blank">
                    <Icon type="file-pdf" />
                  </a> :
                  <ImageViewer fileList={[{ url: data?.metaData?.imgLink }]} />
                }
              </span>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.UPDATE_BASE_COST:
      case TripVerificationStatus.UPDATE_BASE_REVENUE:
        return {
          message: (
            <div>
              Updated{" "}
              {data?.updateType === TripVerificationStatus.UPDATE_BASE_REVENUE
                ? "Revenue"
                : "Cost"}{" "}
              <b>{data?.metaData?.updatedCostRevenue}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.ADD_COST_TXN:
      case TripVerificationStatus.ADD_REVENUE_TXN:
        return {
          message: (
            <div>
              Added Trasaction
              <br />
              <b>
                Transaction Type: {data?.metaData?.txn?.transactionType}
                <br /> Charge Type: {data?.metaData?.txn?.chargeType}
                <br />
                Amount {data?.metaData?.txn?.amount}
              </b>
              {data?.metaData?.linkedTxn && (
                <div>
                  <Divider />
                  Linked Transaction <br />
                  <b>
                    Transaction Type:{" "}
                    {data?.metaData?.linkedTxn?.transactionType}
                    <br /> Charge Type: {data?.metaData?.linkedTxn?.chargeType}
                    <br />
                    Amount {data?.metaData?.linkedTxn?.amount}
                  </b>
                </div>
              )}
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;

      case TripVerificationStatus.REMOVE_COST_TXN:
      case TripVerificationStatus.REMOVE_REVENUE_TXN:
        return {
          message: (
            <div>
              Removed Trasaction <br />
              <b>
                Transaction Type: {data?.metaData?.txn?.transactionType} <br />{" "}
                Charge Type: {data?.metaData?.txn?.chargeType}
              </b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;

      case TripVerificationStatus.EDIT_START_KMS:
      case TripVerificationStatus.EDIT_END_KMS:
        return {
          message: (
            <div>
              Updated{" "}
              {data?.updateType === TripVerificationStatus.EDIT_START_KMS
                ? "Start KMs"
                : "End KMs"}{" "}
              <br />
              <b>Updated value: {data?.metaData?.updatedValue}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.EDIT_START_TIME:
      case TripVerificationStatus.EDIT_END_TIME:
        return {
          message: (
            <div>
              Updated{" "}
              {data?.updateType === TripVerificationStatus.EDIT_START_TIME
                ? "Start Time"
                : "End Time"}{" "}
              <br />
              <b>
                Updated value:{" "}
                {moment(new Date(Number(data?.metaData?.updatedValue))).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.EDIT_ASSISTING_PERSON_NAME:
        case TripVerificationStatus.EDIT_ASSISTING_PERSON_MOBILE:
          return {
            message: (
              <div>
                Updated{" "}
                {data?.updateType === TripVerificationStatus.EDIT_ASSISTING_PERSON_NAME
                  ? "Assisting Person name"
                  : "Assisting Person Mobile No"}{" "}
                <br />
                <b>
                  Updated value:{" "}
                  {data?.metaData?.updatedValue}
                </b>
              </div>
            ),
            user,
            timestamp: (
              <>
                {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
                <small>{moment(data?.createdAt).fromNow()}</small>
              </>
            )
          };
          break;
      case TripVerificationStatus.EDIT_VEHICLE_TYPE:
        return {
          message: (
            <div>
              Updated Vehicle Type <br />
              <b>Updated value: {data?.metaData?.vehicleType}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.MAKE_PAYMENT_REQUEST:
        return {
          message: (
            <div>
              Made Payment Request <br />
              <b>
                Request type:{" "}
                {data?.metaData?.paymentRequest?.paymentRequestType}
              </b>
              <br />
              <b>Amount: {data?.metaData?.paymentRequest?.amount}</b>
              <br />
              <b>Status: {data?.metaData?.paymentRequest?.status}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.UPDATE_PAYMENT_REQUEST_STATUS:
        return {
          message: (
            <div>
              Made Payment Request <br />
              <b>
                Request type:{" "}
                {data?.metaData?.paymentRequest?.paymentRequestType}
              </b>
              <br />
              <b>Amount: {data?.metaData?.paymentRequest?.amount}</b>
              <br />
              <b>Status: {data?.metaData?.paymentRequest?.status}</b>
              <br />
              <b>
                Transaction Ref:{" "}
                {data?.metaData?.paymentRequest?.transactionRef}
              </b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.VERIFY_TRIP:
        return {
          message: <b>Trip Verified</b>,
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;

      default:
        return {};
        break;
    }
  };

  showDurationModal = showDurationModal => {
    this.setState({
      showDurationModal,
      startDuration: moment(this.state.trip?.startTime),
      endDuration: moment(this.state.trip?.endTime)
    });
  };

  applyDuration = () => {
    const { startDuration, endDuration, trip } = this.state;
    if (trip?.status === "COMPLETED") {
      if (endDuration.valueOf() > startDuration.valueOf()) {
        this.setState({ durationLoading: true, durationError: undefined });

        let endPayload = {
          tripId: this.state.tripId,
          updateType: "EDIT_END_TIME",
          tripAttributeUpdatedValue: endDuration.valueOf()
        };

        apiUtils
          .apiRequest({
            url: `/b2b/v1/trip-verification/update-trip-attribute`,
            method: "PUT",
            data: endPayload
          })
          .then(res => {
            if (checkHttpStatus(res?.data?.status)) {
              let startPayload = {
                tripId: this.state.tripId,
                updateType: "EDIT_START_TIME",
                tripAttributeUpdatedValue: startDuration.valueOf()
              };

              this.submitDuration(startPayload);
            } else {
              this.setState({ durationLoading: false });
            }
          });
      } else {
        this.setState({
          durationError: "End duration should be greater than start duration!"
        });
      }
    } else {
      if (endDuration && endDuration.valueOf() < startDuration.valueOf()) {
        this.setState({
          durationError: "End duration should be greater than start duration!"
        });
      } else {
        let startPayload = {
          tripId: this.state.tripId,
          updateType: "EDIT_START_TIME",
          tripAttributeUpdatedValue: startDuration.valueOf()
        };
        this.submitDuration(startPayload);
      }
    }
  };

  submitDuration = payload => {
    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/update-trip-attribute`,
        method: "PUT",
        data: payload
      })
      .then(res => {
        if (checkHttpStatus(res?.data?.status)) {
          this.setState({ durationLoading: false });
          this.reload();
          this.cancelDurationModal();
        } else {
          this.setState({ durationLoading: false });
        }
      });
  };

  cancelDurationModal = () => {
    this.setState({
      showDurationModal: false,
      durationError: undefined
    });
  };

  showAssistingPersonModal = showAssistingPersonModal => {
    this.setState({
      showAssistingPersonModal,
      nameAssistingPerson: this.state.trip?.assistingPersonName,
      mobileAssistingPerson: this.state.trip?.assistingPersonMobile
    });
  };

  applyAssistingPerson = () => {
    const { nameAssistingPerson, mobileAssistingPerson, trip } = this.state;
    if ((nameAssistingPerson != '' && nameAssistingPerson != undefined) || (mobileAssistingPerson != '' && mobileAssistingPerson != undefined)) 
    {
      if (nameAssistingPerson != '' && nameAssistingPerson != undefined) 
      {
        let startPayload = {
        tripId: this.state.tripId,
        updateType: "EDIT_ASSISTING_PERSON_NAME",
        tripAttributeUpdatedValue: nameAssistingPerson.valueOf()
        };
        apiUtils
            .apiRequest({
            url: `/b2b/v1/trip-verification/update-trip-attribute`,
            method: "PUT",
            data: startPayload
            })
            .then(res => {
              console.log(checkHttpStatus(res?.data?.status));
            if (checkHttpStatus(res?.data?.status)) {
              let endPayload = {
                tripId: this.state.tripId,
                updateType: "EDIT_ASSISTING_PERSON_MOBILE",
                tripAttributeUpdatedValue: mobileAssistingPerson.valueOf()
              }; 
    
                this.submitAssistingPerson(endPayload);
            } else {
                this.setState({ assistingpersonLoading: false });
            }
        });
      }
      else if (mobileAssistingPerson != '' && mobileAssistingPerson != undefined) 
      {
        let endPayload = {
          tripId: this.state.tripId,
          updateType: "EDIT_ASSISTING_PERSON_MOBILE",
          tripAttributeUpdatedValue: mobileAssistingPerson.valueOf()
        };
        this.submitAssistingPerson(endPayload);
      }
    }
    else
    {
      let startPayload = {
        tripId: this.state.tripId,
        updateType: "EDIT_ASSISTING_PERSON_NAME",
        tripAttributeUpdatedValue: nameAssistingPerson.valueOf()
        };
        this.submitAssistingPerson(startPayload);
      // this.cancelAssistingPersonModal();
    }
    
  };

  submitAssistingPerson = payload => {
    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/update-trip-attribute`,
        method: "PUT",
        data: payload
      })
      .then(res => {
        if (checkHttpStatus(res?.data?.status)) {
          this.setState({ assistingpersonLoading: false });
          this.reload();
          this.cancelAssistingPersonModal();
        } else {
          this.setState({ assistingpersonLoading: false });
        }
      });
  };

  cancelAssistingPersonModal = () => {
    this.setState({
      showAssistingPersonModal: false,
      assistingpersonError: undefined
    });
  };

  changeState = (value, key) => {
    this.setState({
      [key]: value
    });
  };

  onChange = (e, key) => {
    const { nameAssistingPerson, mobileAssistingPerson } = this.state;
    this.setState({[key]: e.target.value})
  }

  showDistanceModal = showDistanceModal => {
    this.setState({
      showDistanceModal,
      startDistance: this.state.trip?.startKms,
      endDistance: this.state.trip?.endKms
    });
  };

  cancelDistanceModal = () => {
    this.setState({
      showDistanceModal: false,
      distanceError: undefined
    });
  };

  applyDistance = () => {
    const { startDistance, endDistance, trip } = this.state;
    if (trip?.status === "COMPLETED") {
      if (endDistance > startDistance) {
        this.setState({ distanceLoading: true, distanceError: undefined });
        let endPayload = {
          tripId: this.state.tripId,
          updateType: "EDIT_END_KMS",
          tripAttributeUpdatedValue: endDistance
        };
        this.submitDistance(endPayload).then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            let startPayload = {
              tripId: this.state.tripId,
              updateType: "EDIT_START_KMS",
              tripAttributeUpdatedValue: startDistance
            };
            this.submitDistance(startPayload).then(res => {
              this.setState({ distanceLoading: false });
              this.reload();
              this.cancelDistanceModal();
            });
          } else {
            this.setState({ distanceLoading: false });
          }
        });
      } else {
        this.setState({
          distanceError: "End distance should be greater than start distance!"
        });
      }
    } else {
      if (endDistance && endDistance < startDistance) {
        this.setState({
          distanceError: "End distance should be greater than start distance!"
        });
      } else {
        let startPayload = {
          tripId: this.state.tripId,
          updateType: "EDIT_START_KMS",
          tripAttributeUpdatedValue: startDistance
        };
        this.submitDistance(startPayload).then(res => {
          if (res?.data?.status) {
            this.setState({ distanceLoading: false });
            this.reload();
            this.cancelDistanceModal();
          } else {
            this.setState({ distanceLoading: false });
          }
        });
      }
    }
  };

  submitDistance = payload => {
    return apiUtils.apiRequest({
      url: `/b2b/v1/trip-verification/update-trip-attribute`,
      method: "PUT",
      data: payload
    });
  };

  openVehicleCategoryModal = showCategoryModal => {
    let { categories } = this.state;
    this.setState(
      {
        showCategoryModal,
        selectedCategory: undefined
      },
      () => {
        if (categories?.length <= 0) {
          apiUtils
            .apiRequest({
              url: `/b2b/v1/vehicle-types/getAllVehicleTypes`,
              method: "POST",
              data: {
                filter: {}
              }
            })
            .then(res => {
              if (checkHttpStatus(res?.data?.status)) {
                categories = res?.data?.response?.vehicleTypes?.filter(
                  cat => cat?.isDefault && cat?.isActive
                );
                this.setState({ categories });
              }
            });
        }
      }
    );
  };

  cancelCategoryModal = () => {
    this.setState({
      showCategoryModal: false
    });
  };

  selectCategory = category => {
    this.setState({
      selectedCategory: category
    });
  };

  openCost = type => {
    const _this = this;
    this.setState({ baseCostLoading: true });
    confirm({
      title: `Add Base ${type === "COST" ? "Cost" : "Revenue"}`,
      content: (
        <Input
          placeholder={`Enter base ${type === "COST" ? "Cost" : "Revenue"}`}
          onChange={e => _this.setState({ baseCost: e.target.value })}
        />
      ),
      okText: "Confirm",
      cancelText: "Cancel",
      iconType: "info-circle",
      onOk: () => this.submitBaseCostRevenue(type),
      onCancel: () => this.setState({ baseCostLoading: false }),
      okButtonProps: {
        loading: _this.state.baseCostLoading
      },
      cancelButtonProps: {
        disabled: _this.state.baseCostLoading
      }
    });
  };

  submitBaseCostRevenue = type => {
    let payload = {};
    if (type === "COST") {
      payload = {
        tripId: this.state.tripId,
        updatedBaseCostRevenue: this.state.baseCost,
        updateType: "UPDATE_BASE_COST"
      };
    } else {
      payload = {
        tripId: this.state.tripId,
        updatedBaseCostRevenue: this.state.baseCost,
        updateType: "UPDATE_BASE_REVENUE"
      };
    }

    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/update-base-cost-revenue`,
        method: "POST",
        data: payload
      })
      .then(res => {
        if (res?.data?.status) {
          this.reload();
          this.setState({ baseCostLoading: false });
        } else {
          this.setState({ baseCostLoading: false });
        }
      });
  };

  applyCategory = () => {
    let payload = {
      tripId: this.state.tripId,
      tripAttributeUpdatedValue: this.state.selectedCategory.toString(),
      updateType: "EDIT_VEHICLE_TYPE"
    };
    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/update-trip-attribute`,
        method: "PUT",
        data: payload
      })
      .then(res => {
        if (res?.data?.status) {
          this.reload();
          this.cancelCategoryModal();
        }
      });
  };

  removeCostRevenue = (trip, type) => {
    if (this.state.editAllowed) {
      const _this = this;
      let showLinkedText = false;
      let revenue = undefined;
      if (_.hasIn(trip, "linkedTransactionId")) {
        const { revenueTransactions, costTransactions } = this.state;
        if (type === "COST")
          revenue = revenueTransactions?.find(
            r => r.id === trip?.linkedTransactionId
          );
        else
          revenue = costTransactions?.find(
            r => r.id === trip?.linkedTransactionId
          );
        if (revenue) {
          showLinkedText = true;
        } else {
          showLinkedText = false;
        }
      }
      this.setState({
        showRemoveModal: true,
        removeCost: trip,
        removeRevenue: revenue,
        showLinkedText
      });
    } else {
      Modal.error({
        title: "Cannot delete in View Mode"
      });
    }
  };

  changeRemoveTrnsaction = e => {
    this.setState({ removedTransaction: e.target.checked });
  };

  confirmRemoveTransaction = () => {
    this.setState({ loading: true });
    let payload = {
      tripTransactionId: this.state?.removeCost?.id
    };
    if (this.state.showLinkedText)
      payload["removeLinkedTransaction"] = this.state.removedTransaction;
    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/remove-txn`,
        method: "PUT",
        data: payload
      })
      .then(res => {
        if (checkHttpStatus(res?.data?.status)) {
          this.setState({ loading: false });
          notifyApiSuccess("Removed Transaction", "SUCCESS");
          this.cancelRemoveTransaction();
          this.reload();
        } else {
          this.setState({ loading: false });
        }
      });
  };

  cancelRemoveTransaction = () => {
    this.setState({
      showRemoveModal: false,
      removeCost: undefined,
      removeRevenue: undefined,
      showLinkedText: false
    });
  };

  openPaymentModal = () => {
    this.setState({
      showPaymentModal: true,
      paymentType: undefined,
      paymentAmount: undefined,
      paymentError: undefined
    });
  };

  closePaymentModal = () => {
    this.setState({
      showPaymentModal: false,
      paymentType: undefined,
      paymentAmount: undefined,
      paymentError: undefined
    });
  };

  changePaymentAmount = paymentAmount => {
    this.setState({ paymentAmount });
  };

  changePaymentType = paymentType => {
    this.setState({ paymentType });
  };

  submitPayment = () => {
    this.setState({ loading: true });
    let { paymentAmount, paymentType } = this.state;
    let payload = {
      paymentRequestType: paymentType,
      amount: paymentAmount,
      transactionDate: moment().valueOf(),
      tripId: this.state.tripId
    };

    if (!this.state.isLoadboardTripCreated) {
      this.createLoadBoardTrip(payload?.tripId).then(res => {
        if (res) {
          apiUtils
            .apiRequest({
              url: `/b2b/v1/trip-verification/payment-request`,
              method: "POST",
              data: payload
            })
            .then(res => {
              if (checkHttpStatus(res?.data?.status)) {
                this.setState({ loading: false });
                this.closePaymentModal();
                this.reload();
              } else {
                this.setState({ loading: false });
                // this.closePaymentModal();
              }
            });
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      apiUtils
        .apiRequest({
          url: `/b2b/v1/trip-verification/payment-request`,
          method: "POST",
          data: payload
        })
        .then(res => {
          if (checkHttpStatus(res?.data?.status)) {
            this.setState({ loading: false });
            this.closePaymentModal();
            this.reload();
          } else {
            this.setState({ loading: false });
            // this.closePaymentModal();
          }
        });
    }
  };

  verifyTrip = () => {
    let {
      LRImages,
      VendorSlipsImages,
      CustomerSlipImages,
      LRError,
      vendorSlipError,
      customerSlipError,
      trip
    } = this.state;
    const _this = this;
    
    if (trip.tripLoadType === "FTL") {
      if (
        LRImages.length <= 0 ||
        VendorSlipsImages.length <= 0 ||
        CustomerSlipImages.length <= 0
      ) {
        LRError = undefined;
        vendorSlipError = undefined;
        customerSlipError = undefined;
        if (LRImages.length <= 0) {
          LRError = "LR/GCN Image is mandatory";
        }
        if (VendorSlipsImages.length <= 0) {
          vendorSlipError = "Vendor slip Image is mandatory";
        }
        if (CustomerSlipImages.length <= 0) {
          customerSlipError = "Customer slip Image is mandatory";
          // this.customerRef.current.scrollIntoView();
        }
        if (LRError) {
          _this.lrGCNRef.scrollIntoView();
        } else if (vendorSlipError) {
          _this.vendorRef.scrollIntoView();
        } else if (customerSlipError) {
          _this.customerRef.scrollIntoView();
        }
        this.setState({ LRError, vendorSlipError, customerSlipError });
        return;
      }
    } else if (trip.tripLoadType === "PTL") {
      if (
        LRImages.length <= 0 ||
        (VendorSlipsImages.length <= 0 && trip.tripPlacementType !== "PLANNED")
      ) {
        LRError = undefined;
        vendorSlipError = undefined;
        customerSlipError = undefined;
        if (LRImages.length <= 0) {
          LRError = "LR/GCN Image is mandatory";
        }
        if (
          VendorSlipsImages.length <= 0 &&
          trip.tripPlacementType !== "PLANNED"
        ) {
          vendorSlipError = "Vendor slip Image is mandatory";
        }
        if (LRError) {
          _this.lrGCNRef.scrollIntoView();
        } else if (vendorSlipError) {
          _this.vendorRef.scrollIntoView();
        } else if (customerSlipError) {
          _this.customerRef.scrollIntoView();
        }
        this.setState({ LRError, vendorSlipError, customerSlipError });
        return;
      }
    }

    this.setState({ loading: true });
    apiUtils
      .apiRequest({
        url: `/b2b/v1/trip-verification/verify-trip/${this.state.tripId}`,
        method: "POST"
      })
      .then(res => {
        if (checkHttpStatus(res?.data?.status)) {
          this.setState({ loading: false });
          Modal.success({
            content: "Trip Verified Successfully!"
          });
          this.reload();
        } else {
          this.setState({ loading: false });
        }
      });
  };

  goBack = () => {
    this.props.history.push('/appv2/verification/dashboard/vtrips', { filterApplied: this.props.location.state.filters, tags: this.props.location.state.tags,initalFilters: this.props.location.state.initalFilters })
  }

  render() {
    const {
      trip,
      fileList,
      VendorSlipsImages,
      CustomerSlipImages,
      customerSlipError,
      LRError,
      columns,
      history,
      podData,
      filteredData,
      editAllowed,
      isPaymentRequested,
      vendorSlipError,
      costTransactions,
      revenueTransactions,
      paymentRequests,
      acceptTypes,
      LRImages
    } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      formLayout: "vertical",
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    };

    const formStyle = {
      marginBottom: 0
    };
    const updateValues = {
      showDurationModal: this.showDurationModal,
      showDistanceModal: this.showDistanceModal,
      showAssistingPersonModal: this.showAssistingPersonModal,
      openVehicleCategoryModal: this.openVehicleCategoryModal,
      removeCostRevenue: this.removeCostRevenue
    };
    let LROnlyImages = LRImages.filter(img => (img.url.split('.').pop() !== 'pdf' && img.url.split('.').pop() !== 'PDF'));
    let LROnlyPDF = LRImages.filter(img => (img.url.split('.').pop() === 'pdf' || img.url.split('.').pop() === 'PDF'));

    let VenodrOnlyImages = VendorSlipsImages.filter(img => (img.url.split('.').pop() !== 'pdf' && img.url.split('.').pop() !== 'PDF'));
    let VendorOnlyPDF = VendorSlipsImages.filter(img => (img.url.split('.').pop() === 'pdf' || img.url.split('.').pop() === 'PDF'));

    let CustomerOnlyImages = CustomerSlipImages.filter(img => (img.url.split('.').pop() !== 'pdf' && img.url.split('.').pop() !== 'PDF'));
    let CustomerOnlyPDF = CustomerSlipImages.filter(img => (img.url.split('.').pop() === 'pdf' || img.url.split('.').pop() === 'PDF'));


    return (
      <div className="v-trip-detail">
        <Spin spinning={this.state.loading}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <h2>
                <Icon
                  size="large"
                  type="arrow-left"
                  style={{ cursor: "pointer" }}
                  // onClick={e => this.props.history.goBack()}
                  onClick={e => this.goBack()}
                />{" "}
                Trip No: {trip?.tripId}{" "}
              </h2>
            </Col>
            <Col span={24}>
              <Card>
                {[
                  getColumn(editAllowed, updateValues),
                  getColumn2(editAllowed, updateValues)
                ].map((data, key) => (
                  <Col key={key} span={7}>
                    <List
                      size="large"
                      bordered
                      dataSource={data}
                      renderItem={item => (
                        <List.Item>
                          {!item.render ? (
                            <>
                              <Col span={12}>
                                {!!item.title && (
                                  <h4>
                                    <b>{item.title}</b>
                                    <br />
                                  </h4>
                                )}
                                {item.label}:
                              </Col>
                              <Col span={12}>
                                {!!item.title && <br />}
                                <b style={{ wordWrap: "break-word" }}>
                                  {dataGet(trip, item.dataIndex) || "-"}
                                </b>
                              </Col>
                            </>
                          ) : (
                              item?.render(item, trip)
                            )}
                        </List.Item>
                      )}
                    />
                  </Col>
                ))}
                <Col span={7}>
                  <Col span={24}>
                    <h3 style={{ float: "left" }}> LR/GCN </h3>
                    <div
                      style={{ float: "right" }}
                      ref={ref => (this.lrGCNRef = ref)}
                    >
                      <FileUpload
                        showToastMessage={false}
                        fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                        uploadButtonHtml={
                          <>
                            <Button
                              disabled={!editAllowed}
                              icon="paper-clip"
                              type="default"
                            >
                              {" "}
                              Attach File{" "}
                            </Button>
                            {LRError && (
                              <Alert message={LRError} type="error" />
                            )}
                          </>
                        }
                        directUpload={true}
                        showLoader={true}
                        disableUploadButton={true}
                        clearFileAfterUpload={this.state.clearLR}
                        onUploadFinish={$events =>
                          this.onUploadFinish($events, "LR")
                        }
                        path="verification"
                        acceptTypes={acceptTypes}
                        objKey="trips"
                        multiple={false}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    {LROnlyImages && LROnlyImages?.length > 0 &&
                      <ImageViewer
                        mode="GRID"
                        fileList={LROnlyImages}
                        onRemoveFile={
                          editAllowed
                            ? this.onRemoveFile
                            : () => {
                              Modal.error({
                                title: "Cannot delete in View Mode"
                              });
                            }
                        }
                      />
                    }
                    {LROnlyPDF && LROnlyPDF.length > 0 && LROnlyPDF?.map(fl => (

                      <span style={{ display: 'inline-grid' }}>
                        <a href={fl?.url} target="_blank">
                          <Icon style={{ fontSize: 50 }} type="file-pdf" />
                        </a>
                        <Icon style={{ marginTop: 5 }} type="delete" onClick={() => this.onRemoveFile(fl)} />
                      </span>


                    ))

                    }
                    {LROnlyImages.length <= 0 && LROnlyPDF.length <= 0 &&
                      <Empty />
                    }
                  </Col>
                </Col>
                <Col span={3} className="flex-column flex-gap-m">
                  {/* <Button block type="primary">Save</Button> */}
                  <Button
                    disabled={!editAllowed}
                    block
                    type="primary"
                    onClick={this.verifyTrip}
                    className="btn-green"
                  >
                    Verify
                  </Button>
                  <Button block onClick={this.showHistory}>
                    View History
                  </Button>
                  { trip.tripPlacementType === "ADHOC" &&
                    <Button
                  disabled={!editAllowed}
                  block onClick={this.refreshRevenue}>
                    Refresh Revenue
                  </Button>
                  }
                  {editAllowed && (
                    <Button
                      block
                      type="primary"
                      className="btn-gray"
                      // onClick={e => this.props.history.goBack()}
                      onClick={e => this.goBack()}
                    >
                      Cancel
                    </Button>
                  )}
                </Col>
                <Divider />
                <Card
                  bordered={false}
                  bodyStyle={{ backgroundColor: "#f8f8f8" }}
                >
                  <Col span={17}>
                    <Col span={24}>
                      {" "}
                      <h3> Cost </h3>{" "}
                    </Col>
                    <Col span={20}>
                      Base:{" "}
                      <b>
                        {trip?.baseCost
                          ? `${formatCurrency(trip?.baseCost)}`
                          : ""}
                      </b>
                      {this.state.editAllowed && trip.status !== "COMPLETED" ? (
                        <Icon
                          type={"edit"}
                          onClick={() => this.openCost("COST")}
                        />
                      ) : (
                          <Icon
                            type={"edit"}
                            style={{ cursor: "not-allowed", opacity: "0.5" }}
                          />
                        )}
                    </Col>
                    <Col span={4}>
                      <Button
                        disabled={!this.state.editAllowed}
                        block
                        type="primary"
                        onClick={() => this.showTransaction("COST")}
                      >
                        Add transaction
                      </Button>
                    </Col>
                    {/* <Col span={24}> Total Cost: <b>{formatCurrency(230000)}</b> </Col> */}
                    <Col span={24}>
                      {" "}
                      Total Cost:{" "}
                      <b>
                        {trip?.totalCost
                          ? `${formatCurrency(trip?.totalCost)}`
                          : ""}
                      </b>{" "}
                    </Col>
                    <Col span={24}>
                      <Table
                        bordered={true}
                        dataSource={costTransactions}
                        columns={getCostColumn(
                          updateValues,
                          "COST",
                          editAllowed
                        )}
                        onRemoveFile={this.onRemoveFile}
                      />
                    </Col>
                  </Col>
                  <Col span={7}>
                    <Col span={24}>
                      <div
                        style={{ float: "right" }}
                        ref={ref => (this.vendorRef = ref)}
                      >
                        <FileUpload
                          showToastMessage={false}
                          fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                          uploadButtonHtml={
                            <>
                              <Button
                                disabled={
                                  editAllowed == false ||
                                  (trip?.tripLoadType === "PTL" &&
                                    trip?.tripPlacementType === "PLANNED") ||
                                  trip?.tripLoadType === "PICKUP"
                                }
                                icon="paper-clip"
                                type="default"
                              >
                                {" "}
                                Vendor Slip{" "}
                              </Button>
                              {vendorSlipError && (
                                <Alert message={vendorSlipError} type="error" />
                              )}
                            </>
                          }
                          disableAll={true}
                          directUpload={true}
                          showLoader={true}
                          disableUploadButton={true}
                          clearFileAfterUpload={this.state.clearLR}
                          onUploadFinish={$events =>
                            this.onUploadFinish($events, "VENDOR_SLIP")
                          }
                          path="verification"
                          acceptTypes={acceptTypes}
                          objKey="trips"
                          multiple={false}
                        />
                      </div>
                    </Col>

                    <Col span={24}>
                      {VenodrOnlyImages && VenodrOnlyImages.length > 0 &&
                        <ImageViewer
                          mode="GRID"
                          fileList={VenodrOnlyImages}
                          onRemoveFile={
                            editAllowed
                              ? this.onRemoveFile
                              : () => {
                                Modal.error({
                                  title: "Cannot delete in View Mode"
                                });
                              }
                          }

                        />
                      }
                      {VendorOnlyPDF && VendorOnlyPDF.length > 0 && VendorOnlyPDF?.map(fl => (

                        <span style={{ display: 'inline-grid' }}>
                          <a href={fl?.url} target="_blank">
                            <Icon style={{ fontSize: 50 }} type="file-pdf" />
                          </a>
                          <Icon style={{ marginTop: 5 }} type="delete" onClick={() => this.onRemoveFile(fl)} />
                        </span>


                      ))

                      }
                      {VenodrOnlyImages.length <= 0 && VendorOnlyPDF.length <= 0 &&
                        <Empty />
                      }
                    </Col>
                  </Col>
                </Card>
                <Divider />
                <Card
                  bordered={false}
                  bodyStyle={{ backgroundColor: "#f8f8f8" }}
                >
                  <Col span={17}>
                    <Col span={24}>
                      {" "}
                      <h3> Revenue </h3>{" "}
                    </Col>
                    <Col span={20}>
                      Base Revenue:{" "}
                      <b>
                        {trip?.baseRevenue
                          ? `${formatCurrency(trip?.baseRevenue)}`
                          : ""}
                      </b>{" "}
                      {trip?.tripLoadType === "FTL" &&
                        this.state.editAllowed &&
                        trip.status !== "COMPLETED" ? (
                          <Icon
                            type={"edit"}
                            onClick={() => this.openCost("REVENUE")}
                          />
                        ) : (
                          <Icon
                            type={"edit"}
                            style={{ cursor: "not-allowed", opacity: "0.5" }}
                          />
                        )}
                    </Col>
                    <Col span={4}>
                      <Button
                        disabled={
                          !(
                            trip?.tripLoadType === "FTL" &&
                            this.state.editAllowed
                          )
                        }
                        block
                        type="primary"
                        onClick={() => this.showTransaction("REVENUE")}
                      >
                        Add transaction
                      </Button>
                    </Col>
                    {/* <Col span={24}> Total Revenue: <b>{formatCurrency(230000)}</b> </Col> */}
                    <Col span={24}>
                      {" "}
                      Total Revenue:{" "}
                      <b>
                        {trip?.totalRevenue
                          ? `${formatCurrency(trip?.totalRevenue)}`
                          : ""}
                      </b>{" "}
                    </Col>
                    <Col span={24}>
                      <Table
                        bordered={true}
                        dataSource={revenueTransactions}
                        columns={getCostColumn(updateValues, "REVENUE")}
                      />
                    </Col>
                  </Col>
                  <Col span={7}>
                    <Col span={24}>
                      <div
                        style={{ float: "right" }}
                        ref={ref => (this.customerRef = ref)}
                      >
                        <FileUpload
                          showToastMessage={false}
                          fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                          uploadButtonHtml={
                            <>
                              <Button
                                disabled={
                                  trip?.tripLoadType == "PTL" ||
                                  editAllowed === false
                                }
                                icon="paper-clip"
                                type="default"
                              >
                                {" "}
                                Customer Slip{" "}
                              </Button>
                              {customerSlipError && (
                                <Alert
                                  message={customerSlipError}
                                  type="error"
                                />
                              )}
                            </>
                          }
                          directUpload={true}
                          showLoader={true}
                          disableUploadButton={true}
                          clearFileAfterUpload={this.state.clearLR}
                          onUploadFinish={$events =>
                            this.onUploadFinish($events, "CUSTOMER_SLIP")
                          }
                          path="verification"
                          acceptTypes={acceptTypes}
                          objKey="trips"
                          multiple={false}
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      {CustomerOnlyImages && CustomerOnlyImages.length > 0 &&
                        <ImageViewer
                          mode="GRID"
                          fileList={CustomerOnlyImages}
                          onRemoveFile={
                            editAllowed
                              ? this.onRemoveFile
                              : () => {
                                Modal.error({
                                  title: "Cannot delete in View Mode"
                                });
                              }
                          }
                        />
                      }
                      {CustomerOnlyPDF && CustomerOnlyPDF.length > 0 && CustomerOnlyPDF?.map(fl => (

                        <span style={{ display: 'inline-grid' }}>
                          <a href={fl?.url} target="_blank">
                            <Icon style={{ fontSize: 50 }} type="file-pdf" />
                          </a>
                          <Icon style={{ marginTop: 5 }} type="delete" onClick={() => this.onRemoveFile(fl)} />
                        </span>


                      ))

                      }
                      {CustomerOnlyImages.length <= 0 && CustomerOnlyPDF.length <= 0 &&
                        <Empty />
                      }
                    </Col>
                  </Col>
                </Card>
                <Divider />
                <Card
                  bordered={false}
                  bodyStyle={{ backgroundColor: "#f8f8f8" }}
                >
                  <Col span={24}>
                    {" "}
                    <h3> Payment Details </h3>{" "}
                  </Col>
                  <Col span={20}>
                    {/* <Col span={6}>Total Paid: <b>{formatCurrency(82000)}</b></Col>
                                        <Col span={6}>Total Pending: <b>{formatCurrency(1200000)}</b></Col> */}
                    <Col span={6}>
                      Total Paid:{" "}
                      <b>{formatCurrency(trip?.totalPaidPayment)}</b>
                    </Col>
                    <Col span={6}>
                      Total Pending:{" "}
                      <b>{formatCurrency(trip?.totalPendingPayment)}</b>
                    </Col>
                  </Col>
                  <Col span={4}>
                    <Button
                      disabled={
                        this.props.location.state?.status === "view"
                      }
                      block
                      type="primary"
                      onClick={this.openPaymentModal}
                    >
                      Request Payment
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Table
                      bordered={true}
                      dataSource={paymentRequests}
                      columns={paymentColumns}
                    />
                  </Col>
                </Card>
                <Divider />
                <Card
                  bordered={false}
                  bodyStyle={{ backgroundColor: "#f8f8f8" }}
                >
                  <Col span={24}>
                    {" "}
                    <h3> POD </h3>{" "}
                  </Col>
                  <Col span={6}>
                    <Input
                      prefix={<Icon type="search" />}
                      onChange={e => {
                        this.setState({ searchPOD: e.target.value });
                      }}
                      allowClear
                      placeholder="Search by Docket Number"
                    />
                  </Col>
                  <Col span={3}>
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        if (this.state.searchPOD) {
                          this.setState({
                            filteredData: podData.filter(
                              p => p?.waybillNo === this.state.searchPOD
                            )
                          });
                        } else {
                          this.setState({
                            filteredData: podData
                          });
                        }
                      }}
                    >
                      Search
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Table
                      bordered={true}
                      dataSource={filteredData}
                      columns={PODColumns}
                    />
                  </Col>
                </Card>
              </Card>
            </Col>
          </Row>
          <Modal
            visible={this.state.showHistoryModal}
            title="History"
            onOk={this.cancelHistory}
            onCancel={this.cancelHistory}
            width={1200}
            footer={null}
          >
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={history}
            />
          </Modal>

          <Modal
            visible={this.state.showTransactionModal}
            title={`Add ${this.state.transactionModal} Transaction`}
            closable={false}
            maskClosable={true}
            style={{ top: 20 }}
            footer={[
              <Button
                loading={this.state.loading}
                type="primary"
                onClick={this.submitCostRevenue}
              >
                Submit
              </Button>,
              <Button
                disabled={this.state.loading}
                type="default"
                onClick={this.cancelTransactionModal}
              >
                Back
              </Button>
            ]}
          >
            <Form {...formItemLayout}>
              <Row>
                <Col span={24} className="v-trip-transaction-form">
                  <Form.Item
                    colon={false}
                    label={<span className="form-label-text">Type</span>}
                  >
                    {getFieldDecorator("tripTransactionType", {
                      rules: [
                        {
                          required: true,
                          message: "Please select transaction type!"
                        }
                      ]
                    })(
                      <Select
                        placeholder="Select Type"
                        showArrow
                        allowClear
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option
                          key={"LOADING_UNLOADING_CHARGES"}
                          value={"LOADING_UNLOADING_CHARGES"}
                          label={"LOADING_UNLOADING_CHARGES"}
                        >
                          {"Loading and Unloading Charges"}
                        </Select.Option>
                        <Select.Option
                          key={"HALTING_CHARGES"}
                          value={"HALTING_CHARGES"}
                          label={"HALTING_CHARGES"}
                        >
                          {"Halting Charges"}
                        </Select.Option>
                        <Select.Option
                          key={"DAMAGES"}
                          value={"DAMAGES"}
                          label={"DAMAGES"}
                        >
                          {"Damages"}
                        </Select.Option>
                        <Select.Option
                          key={"PARKING_CHARGES"}
                          value={"PARKING_CHARGES"}
                          label={"PARKING_CHARGES"}
                        >
                          {"Parking Charge"}
                        </Select.Option>
                        <Select.Option
                          key={"LOADING_CHARGE"}
                          value={"LOADING_CHARGE"}
                          label={"LOADING_CHARGE"}
                        >
                          {"Loading Charge"}
                        </Select.Option>
                        <Select.Option
                          key={"UNLOADING_CHARGE"}
                          value={"UNLOADING_CHARGE"}
                          label={"UNLOADING_CHARGE"}
                        >
                          {"Unloading Charge"}
                        </Select.Option>
                        <Select.Option
                          key={"EXTRA_POINT_CHARGES"}
                          value={"EXTRA_POINT_CHARGES"}
                          label={"EXTRA_POINT_CHARGES"}
                        >
                          {"Extra Point Charges"}
                        </Select.Option>
                        <Select.Option
                          key={"EXTRA_WEIGHT_CHARGES"}
                          value={"EXTRA_WEIGHT_CHARGES"}
                          label={"EXTRA_WEIGHT_CHARGES"}
                        >
                          {"Extra Weight Charges"}
                        </Select.Option>
                        <Select.Option
                          key={"CLEAN_POD_CHARGES"}
                          value={"CLEAN_POD_CHARGES"}
                          label={"CLEAN_POD_CHARGES"}
                        >
                          {"Clean POD Charges"}
                        </Select.Option>
                        <Select.Option
                          key={"RECOVERY"}
                          value={"RECOVERY"}
                          label={"RECOVERY"}
                        >
                          {"Recovery"}
                        </Select.Option>
                        <Select.Option
                          key={"OTHER_CHARGES"}
                          value={"OTHER_CHARGES"}
                          label={"OTHER_CHARGES"}
                        >
                          {"Other Charges"}
                        </Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="v-trip-transaction-form">
                  <Form.Item
                    colon={false}
                    label={<span className="form-label-text">Amount</span>}
                  >
                    {getFieldDecorator("amount", {
                      rules: [
                        {
                          required: true,
                          message: "Please input amount"
                        }
                      ]
                    })(
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Enter Amount"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="v-trip-transaction-form">
                  <Form.Item
                    colon={false}
                    label={
                      <span className="form-label-text">Transaction Date</span>
                    }
                  >
                    {getFieldDecorator("transactionDate", {
                      rules: [
                        {
                          required: true,
                          message: "Please input date"
                        }
                      ]
                    })(
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Enter Date"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="v-trip-transaction-form">
                  <Form.Item colon={false} label={null}>
                    {getFieldDecorator("attachmentLinks", {
                      rules: [
                        {
                          required: true,
                          message: "Attach Images"
                        }
                      ]
                    })(
                      <FileUpload
                        showToastMessage={false}
                        fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                        uploadButtonHtml={
                          <Button icon="paper-clip" type="default">
                            {" "}
                            Attach Slips{" "}
                          </Button>
                        }
                        directUpload={true}
                        showLoader={true}
                        disableUploadButton={true}
                        clearFileAfterUpload={this.state.clearLR}
                        onUploadFinish={$events =>
                          this.onUploadFinishCostRevenue($events, "COST")
                        }
                        path="verification"
                        acceptTypes={acceptTypes}
                        objKey="trips"
                        multiple={false}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="v-trip-transaction-form">
                  <Form.Item
                    colon={false}
                    label={<span className="form-label-text"></span>}
                  >
                    <ImageViewer
                      mode="GRID"
                      fileList={this.state.costRevenuesImages}
                      onRemoveFile={this.onRemoveCostRevenueImage}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="v-trip-transaction-form">
                  <Form.Item
                    layout="inline"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    colon={false}
                    label={
                      <span className="form-label-text">
                        Billed to{" "}
                        {this.state.transactionModal === "Revenue"
                          ? "Vendor"
                          : "Customer"}
                      </span>
                    }
                  >
                    {getFieldDecorator("createLinkedTransaction", {
                      valuePropName: "checked",
                      rules: [
                        {
                          required: false,
                          message: "Please input amount"
                        }
                      ]
                    })(<Checkbox />)}
                  </Form.Item>
                </Col>
                {getFieldValue("createLinkedTransaction") && (
                  <Col span={24} className="v-trip-transaction-form">
                    <Form.Item
                      layout="inline"
                      labelCol={{ span: 0 }}
                      wrapperCol={{ span: 24 }}
                      colon={false}
                      label={<span className="form-label-text"></span>}
                    >
                      {getFieldDecorator("linkedTxnAmount", {
                        rules: [
                          {
                            required: true,
                            message: "Please input amount"
                          }
                        ]
                      })(
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Enter Amount"
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Modal>
          <Modal
            title={"Edit Duration"}
            visible={this.state.showDurationModal}
            onCancel={this.cancelDurationModal}
            width={300}
            footer={[
              <Button
                disabled={this.state.durationLoading}
                onClick={this.cancelDurationModal}
                key="durationCancel"
                type="default"
              >
                Cancel
              </Button>,
              <Button
                loading={this.state.durationLoading}
                key="durationApply"
                type="primary"
                onClick={this.applyDuration}
              >
                Apply
              </Button>
            ]}
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item colon={false} label="Start Time" style={formStyle}>
                  <DatePicker
                    placeholder="Enter Start Duration"
                    style={{ width: "100%" }}
                    showTime
                    value={this.state.startDuration}
                    onChange={date => this.changeState(date, "startDuration")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="End Time" colon={false} style={formStyle}>
                  <DatePicker
                    placeholder="Enter End Duration"
                    style={{ width: "100%" }}
                    showTime
                    disabled={this?.state?.trip?.status !== "COMPLETED"}
                    value={this.state.endDuration}
                    onChange={date => this.changeState(date, "endDuration")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="" colon={false} style={formStyle}>
                  {this.state.durationError && (
                    <Alert message={this.state.durationError} type="error" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Modal>

          <Modal
            title={"Edit Assisting Person"}
            visible={this.state.showAssistingPersonModal}
            onCancel={this.cancelAssistingPersonModal}
            width={300}
            footer={[
              <Button
                disabled={this.state.assistingpersonLoading}
                onClick={this.cancelAssistingPersonModal}
                key="assistingpersonCancel"
                type="default"
              >
                Cancel
              </Button>,
              <Button
                loading={this.state.assistingpersonLoading}
                key="assistingpersonApply"
                type="primary"
                onClick={this.applyAssistingPerson}
              >
                Apply
              </Button>
            ]}
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item colon={false} label="Name" style={formStyle}>
                {/* {getFieldDecorator("amount", {
                      rules: [
                        {
                          required: true,
                          message: "Please input Name"
                        }
                      ]
                    })( */}
                  <Input
                    placeholder="Enter Name"
                    value={this.state.nameAssistingPerson || ''}
                    onChange={mobile => this.onChange(mobile, "nameAssistingPerson")}
                  />
                    {/* )} */}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Mobile No" colon={false} style={formStyle}>
                  <Input
                    placeholder="Enter Mobile No"
                    style={{ width: "100%" }}
                    value={this.state.mobileAssistingPerson}
                    onChange={mobile => this.onChange(mobile, "mobileAssistingPerson")}
                     />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="" colon={false} style={formStyle}>
                  {this.state.assistingpersonError && (
                    <Alert message={this.state.assistingpersonError} type="error" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Modal>

          <Modal
            title={"Edit KM"}
            visible={this.state.showDistanceModal}
            onCancel={this.cancelDistanceModal}
            width={300}
            footer={[
              <Button
                disabled={this.state.distanceLoading}
                onClick={this.cancelDistanceModal}
                key="distanceCancel"
                type="default"
              >
                Cancel
              </Button>,
              <Button
                loading={this.state.distanceLoading}
                key="distanceApply"
                type="primary"
                onClick={this.applyDistance}
              >
                Apply
              </Button>
            ]}
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item colon={false} label="Start KM" style={formStyle}>
                  <InputNumber
                    placeholder="Enter Start KM"
                    style={{ width: "100%" }}
                    value={this.state.startDistance}
                    onChange={value => this.changeState(value, "startDistance")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="End KM" colon={false} style={formStyle}>
                  <InputNumber
                    placeholder="Enter End KM"
                    style={{ width: "100%" }}
                    disabled={this.state?.trip?.status !== "COMPLETED"}
                    value={this.state.endDistance}
                    onChange={value => this.changeState(value, "endDistance")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="" colon={false} style={formStyle}>
                  {this.state.distanceError && (
                    <Alert message={this.state.distanceError} type="error" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Modal>
          <Modal
            title={"Edit Vehicle Category"}
            visible={this.state.showCategoryModal}
            onCancel={this.cancelCategoryModal}
            width={400}
            footer={[
              <Button
                disabled={this.state.categoryLoading}
                onClick={this.cancelCategoryModal}
                key="categoryCancel"
                type="default"
              >
                Cancel
              </Button>,
              <Button
                loading={this.state.categoryLoading}
                key="categoryApply"
                type="primary"
                onClick={this.applyCategory}
              >
                Apply
              </Button>
            ]}
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item
                  colon={false}
                  label="Vehicle Categories"
                  style={formStyle}
                >
                  <Select
                    placeholder="Select Vehicle Category"
                    showArrow
                    showSearch
                    value={this.state.selectedCategory}
                    style={{ width: "100%" }}
                    onSelect={this.selectCategory}
                    // onSelect={props.selectBillingModel}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.categories?.map(bModel => {
                      return (
                        <Option
                          value={bModel?.id}
                          label={bModel?.vehicleType}
                          key={bModel?.vehicleType}
                        >
                          {bModel?.vehicleType} - {bModel?.vehicleCapacity} -{" "}
                          {bModel?.vehicleCapacityUnit} - {bModel?.bodyType}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="" colon={false} style={formStyle}>
                  {this.state.categoryError && (
                    <Alert message={this.state.categoryError} type="error" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={this.state.showRemoveModal}
            title="Remove transaction"
            closable={false}
            footer={[
              <Button
                loading={this.state.loading}
                type="primary"
                onClick={this.confirmRemoveTransaction}
              >
                Yes
              </Button>,
              <Button
                disabled={this.state.loading}
                type="default"
                onClick={this.cancelRemoveTransaction}
              >
                No
              </Button>
            ]}
          >
            <div>
              Are you sure you want to remove this transaction?
              <br />
              <b>
                {this.state?.removeCost?.chargeType}:{" "}
                {this.state?.removeCost?.amount}
              </b>
              {this.state.showLinkedText && (
                <>
                  <br />
                  Do you want to remove the linked <b>cost/revenue</b>{" "}
                  transaction{" "}
                  <Checkbox
                    checked={this.state.removedTransaction}
                    onChange={this.changeRemoveTrnsaction}
                  />
                  <br />
                  <b>
                    {this.state.removeRevenue?.chargeType}:{" "}
                    {this.state.removeRevenue?.amount}
                  </b>
                </>
              )}
            </div>
          </Modal>

          <Modal
            title={"Request Payment"}
            visible={this.state.showPaymentModal}
            onCancel={this.closePaymentModal}
            width={300}
            footer={[
              <Button
                disabled={this.state.loading}
                onClick={this.closePaymentModal}
                key="paymentCancel"
                type="default"
              >
                Cancel
              </Button>,
              <Button
                loading={this.state.loading}
                key="paymentApply"
                type="primary"
                onClick={this.submitPayment}
              >
                Submit
              </Button>
            ]}
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item colon={false} label="Type" style={formStyle}>
                  <Select
                    placeholder="Select Type"
                    showArrow
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    onChange={this.changePaymentType}
                    value={this.state.paymentType}
                    filterOption={(input, option) =>
                      option.props.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option
                      key={"RESIDUAL"}
                      value={"RESIDUAL"}
                      label={"Residual"}
                    >
                      {"Residual"}
                    </Select.Option>
                    <Select.Option
                      key={"ADVANCE"}
                      value={"ADVANCE"}
                      label={"Advance"}
                    >
                      {"Advance"}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item colon={false} label="Amount" style={formStyle}>
                  <InputNumber
                    placeholder="Enter Amount"
                    value={this.state.paymentAmount}
                    onChange={this.changePaymentAmount}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                                <Form.Item
                                    label="End Time"
                                    colon={false}
                                    style={formStyle}
                                >
                                    <DatePicker
                                        placeholder="Enter End Duration"
                                        style={{ width: '100%' }}
                                        showTime
                                        disabled={this?.state?.trip?.status !== 'FINISHED'}
                                        value={this.state.endDuration}
                                        onChange={(date) => this.changeState(date, 'endDuration')}
                                    />
                                </Form.Item>
                            </Col> */}
              <Col span={24}>
                <Form.Item label="" colon={false} style={formStyle}>
                  {this.state.paymentError && (
                    <Alert message={this.state.durationError} type="error" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Spin>
      </div>
    );
  }
}
export default Form.create({
  name: "Trip Details",
  onValuesChange: (props, val) => { }
})(TripDetails);
