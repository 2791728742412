import { apiRequest } from '../../common/utils/apiGateway';
export default class PrintOperationService {

    submitPrint(type, params) {
        const url = `/b2b/v1/reports/${type}/get`;
        return apiRequest({
            url,
            method: 'POST',
            data: params
        });
    }

    getPrinthistory(params) {
        const url = `/b2b/v1/batch_sticker/get`;
        return apiRequest({ url, method: 'GET', params });
    }

    getPrinthistoryById(params) {
        const url = `/b2b/v1/reports/${params.shipmentType}/get/`;
        return apiRequest({
            url,
            method: 'POST',
            data: params
        });
    }

    printHistoryByWaybill(params) {
        const url = `/b2b/v1/reports/${params.documentType}/get`;
        return apiRequest({
            url,
            method: 'POST',
            data: params
        });
    }
}