import React, { PureComponent } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, PageHeader, Tooltip, Spin, Switch } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';                                                                                                                           
import moment from 'moment';


import _ from 'lodash';



class ZohoDashboard extends PureComponent {
    componentDidMount() {
        this.props.fetchZohoList("ZOHO");
    }
    
    componentDidUpdate() {      
        if(this.props.zohoStatus) {
            // console.log("I amm here")
            this.props.fetchZohoList("ZOHO");
        }
    }
    
    changeStatus = (data,  status) => {
        const params = {
            "tpdashboardId": data.id,
            "isActive": status
        };
        
        this.props.updateStatus(params);
    }
    
    render() {
        const columns = [
            {
                title: 'Index',
                dataIndex: 'index',
                key: 'index',
            },
            {
                title: 'Permission Name',
                dataIndex: 'permissionName',
                key: 'permissionName',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (data) => moment(data).format("DD/MM/YYYY hh:mm")
            },
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
            },
            {
                title: 'Actions',
                key:'actions',
                render: (data, row) =>
                <Row>
                    <Col span={12}>
                    <Icon type="edit" onClick={() => this.props.history.push(`/appv2/settings/form/zoho-dashboard/${row.id}`)}/>
                    </Col>
                    <Col span={12}>
                    <Switch checked={row.isActive} onChange={(status) => this.changeStatus(data, status)} />
                    </Col>
                </Row> 
            }
        ]
        
        return (
            <Card
            title={"Zoho Dashboard List"}
            extra={
                <Button type="primary" onClick={() => this.props.history.push('/appv2/settings/form/zoho-dashboard')}>Create Dashboard</Button>
            }
            >
                <Spin spinning={this.props.loading}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={this.props?.zohoList?.dashboards}
                    
                    />
                </Spin>
            
            </Card>
            )
        }
    }
    
    
    const mapStateToProps = (state, ownProps) => ({
        zohoList: state.zoho?.zohoList,
        zohoStatus: state?.zoho?.zohoStatus,
        loading: state?.zoho?.loading,
        
    });
    const mapDispatchToProps = dispatch => ({
        fetchZohoList: (type, isReset = false) => dispatch(actions.getZohoList(type, isReset)),
        updateStatus: (params) => dispatch(actions.updateZohoStatus(params)),
    });
    export default connect(mapStateToProps, mapDispatchToProps)(ZohoDashboard);