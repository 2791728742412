
import * as _Constants from './constants';
import { storePaginatedData } from '../../common/utils/utils';

export const SLAReasonsReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_SLA_CONSIGNMENTS_BEGIN: return fetchSLAConsignmentBegin(state);
        case _Constants.FETCH_SLA_CONSIGNMENTS_SUCCESS: return fetchSLAConsignmentSuccess(state, action);
        case _Constants.FETCH_SLA_CONSIGNMENTS_FAILURE: return fetchSLAConsignmentFailure(state, action);

        case _Constants.FETCH_SLA_CONSIGNMENTS_SUMMARY_BEGIN: return fetchSLAConsignmentSummaryBegin(state);
        case _Constants.FETCH_SLA_CONSIGNMENTS_SUMMARY_SUCCESS: return fetchSLAConsignmentSummarySuccess(state, action);
        case _Constants.FETCH_SLA_CONSIGNMENTS_SUMMARY_FAILURE: return fetchSLAConsignmentSummaryFailure(state, action);


        case _Constants.FETCH_SLA_CUSTOMERS_BEGIN: return fetchSLACustomerBegin(state);
        case _Constants.FETCH_SLA_CUSTOMERS_SUCCESS: return fetchSLACustomerSuccess(state, action);
        case _Constants.FETCH_SLA_CUSTOMERS_FAILURE: return fetchSLACustomerFailure(state, action);


        case _Constants.FETCH_SLA_REASONS_BEGIN: return fetchSLAReasonBegin(state);
        case _Constants.FETCH_SLA_REASONS_SUCCESS: return fetchSLAReasonSuccess(state, action);
        case _Constants.FETCH_SLA_REASONS_FAILURE: return fetchSLAReasonFailure(state, action);
       
        
        case _Constants.FETCH_SLA_EXPORT_BEGIN: return fetchSLAExportBegin(state);
        case _Constants.FETCH_SLA_EXPORT_SUCCESS: return fetchSLAExportSuccess(state, action);
        case _Constants.FETCH_SLA_EXPORT_FAILURE: return fetchSLAExportFailure(state, action);


        case _Constants.FETCH_SLA_LOCATIONS_BEGIN: return fetchSLALocationBegin(state);
        case _Constants.FETCH_SLA_LOCATIONS_SUCCESS: return fetchSLALocationSuccess(state, action);
        case _Constants.FETCH_SLA_LOCATIONS_FAILURE: return fetchSLALocationFailure(state, action);


        case _Constants.SET_SLA_REASONS_BEGIN: return setSLAReasonBegin(state);
        case _Constants.SET_SLA_REASONS_SUCCESS: return setSLAReasonSuccess(state, action);
        case _Constants.SET_SLA_REASONS_FAILURE: return setSLAReasonFailure(state, action);

       

        default:
            return state;
    }
}

function fetchSLAConsignmentBegin(state, action) {
    return {
        ...state,
        slaLoading: true,
        slaDelayReasons: undefined
    };
}

function fetchSLAConsignmentSuccess(state, action) {
    return {
        ...state,
        slaLoading: false,
        PaginatedData: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedData ? state.PaginatedData : {}, action ?.payload ?.slaDelayReasonConsignments , action.isReset),
        ...action.payload
    };
}

function fetchSLAConsignmentFailure(state, action) {
    return {
        ...state,
        slaLoading: false,
        error: action.payload
    };
}



function fetchSLACustomerBegin(state, action) {
    return {
        ...state,
        slaCustomerLoading: true
    };
}

function fetchSLACustomerSuccess(state, action) {
    return {
        ...state,
        slaCustomerLoading: false,
        slaCustomers: action.payload,
    };
}

function fetchSLACustomerFailure(state, action) {
    return {
        ...state,
        slaCustomerLoading: false,
        error: action.payload
    };
}


function fetchSLAReasonBegin(state, action) {
    return {
        ...state,
        slaReasonLoading: true
    };
}

function fetchSLAReasonSuccess(state, action) {
    return {
        ...state,
        slaReasonLoading: false,
        slaBreachReasons: action.payload,
    };
}

function fetchSLAReasonFailure(state, action) {
    return {
        ...state,
        slaReasonLoading: false,
        error: action.payload
    };
}


function fetchSLAExportBegin(state, action) {
    return {
        ...state,
        slaExportLoading: true
    };
}

function fetchSLAExportSuccess(state, action) {
    return {
        ...state,
        slaExportLoading: false,
        slaExportData: action.payload,
    };
}

function fetchSLAExportFailure(state, action) {
   
    return {
        ...state,
        slaExportLoading: false,
        exportError: action.payload
    };
}



function setSLAReasonBegin(state, action) {
    return {
        ...state,
        slaDelayReasonLoading: true
    };
}

function setSLAReasonSuccess(state, action) {
   
    return {
        ...state,
        slaDelayReasonLoading: false,
        slaDelayReasons: action.payload,
    };
}

function setSLAReasonFailure(state, action) {
    return {
        ...state,
        slaDelayReasonLoading: false,
        error: action.payload
    };
}



function fetchSLAConsignmentSummaryBegin(state, action) {
    return {
        ...state,
        slaSummaryLoading: true
    };
}

function fetchSLAConsignmentSummarySuccess(state, action) {
    return {
        ...state,
        slaSummaryLoading: false,
        slaSummary: action.payload
       
    };
}

function fetchSLAConsignmentSummaryFailure(state, action) {
    return {
        ...state,
        slaSummaryLoading: false,
        error: action.payload
    };
}



function fetchSLALocationBegin(state, action) {
    return {
        ...state,
        slaLocationLoading: true
    };
}

function fetchSLALocationSuccess(state, action) {
    return {
        ...state,
        slaLocationLoading: false,
        slaLocations: action.payload,
    };
}

function fetchSLALocationFailure(state, action) {
    return {
        ...state,
        slaLocationLoading: false,
        error: action.payload
    };
}