import React, { Component } from 'react'
import { Card, Col, Input, Row, Icon, Button, Spin, Tag, Empty, Pagination, Modal, Badge, Select, Table, Tooltip } from 'antd'
import moment from 'moment'
import DatePickerInput from "../../ui/datepicker/DatePicker";
import FilterIcon from "../../../assets/img/icons/filter-icon.svg";
import DataCard from '../components/data-card';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { fetchCustomersUtil, fetchLocationsUtil, fetchDeliveryLocationsUtil } from "./../utils/verification.utils";
import { cloneObject } from '../../../common/utils/utils'
import { fetchPods } from './pods.utils';
import { PodStatusTransformer } from "./pod-status.transformer"
import { Mixpanel } from '../../../utils/mixpanel';

const Option = Select.Option;
class VerificationPods extends Component {

    constructor(props) {
        super(props);
        const defaultOrder = 'DESC';
        const defaultFilterPayload = {
            "filter": {
                "deliveredDateRange": {
                    "from": moment().subtract(7, 'd').startOf('day').valueOf(),
                    "to": moment().endOf().valueOf()
                },
                "destinationLocationIds": [],
                "podVerificationStatuses": [],
                "bookingOffcLocIds": [],
                "customerIds": [],
                "pageSize": 10,
                "pageNo": 1
            },
            "orderBy": defaultOrder
        }
        var filterPayload = cloneObject(defaultFilterPayload)
        var tags = [];
        var selectedCustomers = [];
        var selectedBookingOffices = [];
        var selectedDeliveryLocations = [];
        var startDate = moment().subtract(7, 'd').startOf('day');
        var endDate = moment();
        var searchWayBillNo = undefined;
        if(this.props?.location?.state?.filterPayload){
            filterPayload = this.props?.location?.state?.filterPayload;
            if(this.props?.location?.state?.filterPayload?.filter?.waybillNos?.length > 0){
                searchWayBillNo = this.props?.location?.state?.filterPayload?.filter?.waybillNos.join(",");
            }
            selectedCustomers = this.props?.location?.state?.selectedCustomers;
            selectedBookingOffices = this.props?.location?.state?.selectedBookingOffices;
            selectedDeliveryLocations = this.props?.location?.state?.selectedDeliveryLocations;
            startDate = this.props?.location?.state?.filterPayload?.filter?.deliveredDateRange?.from;
            endDate = this.props?.location?.state?.filterPayload?.filter?.deliveredDateRange?.to;
        }
        if(this.props?.location?.state?.tags){
            tags = this.props?.location?.state?.tags;
        }
        var statusFilters = cloneObject(defaultFilterValues);
        if (filterPayload?.filter?.podVerificationStatuses?.length > 0) {
            statusFilters.podVerificationStatuses.map(status => {
                if (filterPayload?.filter?.podVerificationStatuses.indexOf(status.storedValue) > -1) {
                    status.value = true;
                }
            })
        }
        this.state = {
            podsData: {},
            podVerificationSummary: {},
            loading: false,
            filterPayload: filterPayload,
            defaultFilterPayload: defaultFilterPayload,
            defaultPodVerificationStatuses: [
                { id: 0, name: "POD Document Pending", value: false, storedValue: "POD_DOCUMENT_PENDING" },
                { id: 1, name: "POD Verification Pending ", value: false, storedValue: "POD_VERIFICATION_PENDING" },
                { id: 2, name: "Rejected", value: false, storedValue: "POD_REJECTED" },
                { id: 3, name: "Verified", value: false, storedValue: "POD_VERIFIED" },
            ],
            defaultOrderBy: defaultOrder,
            startDate: startDate ? moment(startDate) :moment().subtract(7, 'd').startOf('day'),
            endDate: endDate ? moment(endDate) : moment(),
            tags: tags,
            searchWayBillNo,

            // Booking Offices dropdown
            bookingOfficeLoading: false,
            bookingOffices: [],
            selectedBookingOffices: selectedBookingOffices ? selectedBookingOffices : [],
            // Delivery Locations dropdown
            deliveryLocationsLoading: false,
            deliveryLocations: [],
            selectedDeliveryLocations: selectedDeliveryLocations ? selectedDeliveryLocations : [],
            // Customers dropdown
            customerLoading: false,
            customers: [],
            selectedCustomers: selectedCustomers ? selectedCustomers : [],
            visible: false,
            statusFilters: statusFilters,
            isEndDateOpen: false,
            columns: [],
            sortIcon: 'align-right'
        }

        Mixpanel.track("POD Verification: Page Launching", { "podVerificationPageLaunchend": moment().valueOf() })
        this.fetchLocations = debounce(this.fetchLocations, 800);
        this.fetchCustomers = debounce(this.fetchCustomers, 800);
        this.fetchDeliveryLocations = debounce(this.fetchDeliveryLocations, 800)
    }

    clearFilters = _ => {
        this.props.history.push('/appv2/verification/dashboard/pod', {});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.applyPagination(this.state.filterPayload, false);
        window.addEventListener("beforeunload", this.clearFilters);
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.clearFilters);
    }

    fetchLocations = (value) => {
        if (value) {
            this.setState({ bookingOffices: [], bookingOfficeLoading: true });
            fetchLocationsUtil(value).then(bookingOffices => {
                if (bookingOffices) {
                    bookingOffices.map( l => {
                        
                    })
                    this.setState({ bookingOffices, bookingOfficeLoading: false });
                }
            });
        }
    }

    resetLocations = () => {
        this.setState({ bookingOffices: [] });
    }

    resetDeliveryLocations = () => {
        this.setState({ deliveryLocations: [] });
    }

    fetchCustomers = value => {
        if (value) {
            this.setState({ customers: [], customerLoading: true });
            fetchCustomersUtil(value).then(customers => {
                if (customers) {
                    this.setState({ customers, customerLoading: false });
                }
            });
        }
    }

    fetchDeliveryLocations = value => {
        if (value) {
            this.setState({ deliveryLocations: [], deliveryLocationsLoading: true });
            var customerIds = this.state.selectedCustomers.reduce((current, next) => {
                current.push(next.key);
                return current;
            }, []);
            fetchDeliveryLocationsUtil(value, customerIds).then(deliveryLocations => {
                if (deliveryLocations) {
                    this.setState({ deliveryLocations, deliveryLocationsLoading: false });
                }
            });
        }
    }

    removeFilteredValue = key => {
        const filterPayload = { ...this.state.filterPayload }
        delete filterPayload.filters[key]

        this.setState({
            filterPayload,
            isFiltered: (Object.keys(filterPayload).length !== 0)
        }, () => this.applyPagination({ ...this.state.filterPayload }, true))
    }

    clearTag = t => {
        var tags = this.state.tags.filter(tag => tag?.key !== t?.key);

        const filterPayload = { ...this.state.filterPayload };
        delete filterPayload['filter'][t?.key];

        if (t?.key === "bookingOffcLocIds") {
            this.setState({
                selectedBookingOffices: [],
                bookingOffices: []
            })
        }

        if (t?.key === "destinationLocationIds") {
            this.setState({
                selectedDeliveryLocations: [],
                deliveryLocations: []
            })
        }

        if (t?.key === "customerIds") {
            tags = tags.filter(tag => tag?.key !== 'destinationLocationIds');
            delete filterPayload['filter']['destinationLocationIds'];
            this.setState({
                selectedCustomers: [],
                customers: [],
                selectedDeliveryLocations: [],
                deliveryLocations: []
            })
        }
        this.setState({
            tags, tripData: [],
            filterPayload,
            statusFilters: cloneObject(defaultFilterValues),
        }, () => this.applyPagination(this.state.filterPayload, true))
    }

    resetFilters = () => {
        this.setState({
            tags: [],
            startDate: moment().subtract(7, 'd').startOf('day'),
            endDate: moment(),
            selectedVendors: [],
            selectedBookingOffices: [],
            bookingOffices: [],
            selectedDeliveryLocations: [],
            deliveryLocations: [],
            searchWayBillNo: undefined,
            selectedCustomers: [],
            filterPayload: JSON.parse(JSON.stringify(this.state.defaultFilterPayload)),
            visible: false,
            statusFilters: {
                podVerificationStatuses: this.state.defaultPodVerificationStatuses,
            },
        }, () => this.applyPagination(this.state.filterPayload, true))
    }

    applyPagination = (newFilter, reset) => {
        if (reset) {
            let { filter } = newFilter;
            newFilter = {
                ...newFilter,
                filter: {
                    ...filter,
                    pageNo: 1
                }
            }
        }
        this.setState({ loading: true, podsData: {} });
        fetchPods(newFilter).then(podsData => {
            this.setState({ podsData, loading: false })
        });
    }

    changePage = (page, pageSize) => {
        this.setState({
            filterPayload: {
                ...this.state.filterPayload,
                filter: {
                    ...this.state.filterPayload.filter,
                    pageNo: page,
                    pageSize: pageSize
                }
            },
            podsData: []
        }, () => this.applyPagination(this.state.filterPayload, false))
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            filterPayload: {
                ...this.state.filterPayload,
                filter: {
                    ...this.state.filterPayload.filter,
                    pageNo: current,
                    pageSize: pageSize
                }

            },
            podsData: []
        }, () => this.applyPagination(this.state.filterPayload, false))
    }

    onBookingLocationChange = location => {
        let keys = location?.map(val => val.key);
        let values = location?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "bookingOffcLocIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.removeTag("bookingOffcLocIds");
        if (values.length > 0) {
            tags.push({
                "label": "Booking Office",
                "key": "bookingOffcLocIds",
                value: values
            })
        }

        this.setState({
            filterPayload: {
                ...this.state.filterPayload,
                filter: {
                    ...this.state.filterPayload.filter,
                    bookingOffcLocIds: keys
                }

            },
            selectedBookingOffices: location,
            tags
        });
    }

    onDeliveryLocationChange = location => {
        let keys = location?.map(val => val.key);
        let values = location?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "destinationLocationIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.removeTag("destinationLocationIds");
        if (values.length > 0) {
            tags.push({
                "label": "Delivery Locations'",
                "key": "destinationLocationIds",
                value: values
            })
        }

        this.setState({
            filterPayload: {
                ...this.state.filterPayload,
                filter: {
                    ...this.state.filterPayload.filter,
                    destinationLocationIds: keys
                }

            },
            selectedDeliveryLocations: location,
            tags
        });
    }
    onChangeCustomers = customer => {
        let keys = customer?.map(val => val.key);
        let values = customer?.map(val => val.label);
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === "customerIds");
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.removeTag("customerIds");
        if (values.length > 0) {
            tags.push({
                "label": "Customer",
                "key": "customerIds",
                value: values
            })
        }

        this.setState({
            filterPayload: {
                ...this.state.filterPayload,
                filter: {
                    ...this.state.filterPayload.filter,
                    customerIds: keys
                }

            },
            selectedCustomers: customer,
            tags

        });
    }

    resetCustomers = () => {
        this.setState({ customers: [] })
    }

    changeTrip = e => { //On input in search docket
        const value = e.target.value;

        if (!value) {
            this.setState({ searchWayBillNo: value }, () => {
                this.searchByInputAction(value)
            });

        } else {
            this.setState({ searchWayBillNo: value })
        }

    }

    filterValues = (type, key) => {
        let { statusFilters } = this.state;
        statusFilters[type].map(fl => {
            if (fl?.value) {
                key.push(fl?.storedValue);
            }
        });
        return key;
    }

    removeTag = (key) => {
        let { tags } = this.state;
        let findIndex = tags.findIndex(t => t?.key === key);
        if (findIndex >= 0) {
            tags.splice(findIndex, 1);
        }
        this.setState({ tags });
    }

    changeOrder = () => {

        let order = "DESC";
        let sortIcon = "align-right";

        if (this.state.defaultOrderBy === 'DESC') {
            order = 'ASC';
            sortIcon = 'align-left';
        } else {
            order = 'DESC';
            sortIcon = 'align-right';
        }
        let { columns } = this.state;
        columns.map(c => {
            if (c?.dataIndex === "lastUpdatedAt") {
                c.title = <>Last Updated <Icon onClick={() => this.changeOrder()} style={{ cursor: 'pointer', marginLeft: 25 }} type={sortIcon} /></>
            }
        });


        Mixpanel.track("POD Verification: Last Updated Sorting", { "podVerificationLastUpdatedSort": moment().valueOf() });

        this.setState({ defaultOrderBy: order, sortIcon, columns }, () => {

            let { filterPayload } = this.state;
            let { filter } = filterPayload;
            filterPayload = {
                ...filterPayload,
                orderBy: order,
                filter: {
                    ...filter
                }
            }
            this.setState({ filterPayload }, () => {
                this.applyPagination(this.state.filterPayload, true);
            })
        })
    }
    filterValuesChnages = (data, id) => {
        let statusFilters = cloneObject(this.state.statusFilters);
        statusFilters[data].map(x => {
            if (x.id === id) {
                x.value = !x.value;
            }
        })
        this.setState({ statusFilters: statusFilters })
    }
    handleFiltersApplied = () => {
        let { filterPayload, tags } = this.state;
        if (this.state.searchWayBillNo) {
            filterPayload = this.state.defaultFilterPayload;
        }
        let { filter } = filterPayload;
        let podVerificationStatuses = [];
        podVerificationStatuses = this.filterValues('podVerificationStatuses', podVerificationStatuses);

        this.removeTag("podVerificationStatuses");
        if (podVerificationStatuses.length > 0) {
            filter = {
                ...filter,
                podVerificationStatuses
            }
            filterPayload = {
                ...filterPayload,
                filter: {
                    ...filter,
                }

            }
            tags.push({
                "label": "Verification",
                "key": "podVerificationStatuses",
                value: [...podVerificationStatuses]
            })
        } else {
            delete filterPayload['filter']['podVerificationStatuses'];
        }
        delete filterPayload['filter']['waybillNos'];

        Mixpanel.track("POD Verification: Change Filters", { "podVerificationFiltersChanged": moment().valueOf() });
        this.setState({ filterPayload, visible: false, tags, podsData: [], searchWayBillNo: undefined }, () => {
            this.applyPagination(this.state.filterPayload, true);
        })

    }
    searchByInputAction = e => {
        let { filterPayload } = this.state

        if (this.state.searchWayBillNo) {
            this.searchByWaybillNo();
        } else {
            delete filterPayload['filter']['waybillNos'];
            this.setState({
                filterPayload: JSON.parse(JSON.stringify(this.state.defaultFilterPayload)),
                tags: [],
                selectedCustomers: [],
                customers: [],
                selectedBookingOffices: [],
                bookingOffices: [],
                selectedDeliveryLocations: [],
                deliveryLocations: [],
                statusFilters: {
                    podVerificationStatuses: this.state.defaultPodVerificationStatuses,
                }
            }, () => this.resetFilters())
        }

    }

    searchByWaybillNo = () => {
        let { filterPayload } = this.state
        if (this.state.searchWayBillNo) {
            Mixpanel.track("POD Verification: Search by Dockets", { "podVerificationDocketSearch": moment().valueOf() })
            delete filterPayload['filter']['deliveredDateRange'];
            delete filterPayload['filter']['bookingOffcLocIds'];
            delete filterPayload['filter']['destinationLocationIds'];
            delete filterPayload['filter']['customerIds'];
            delete filterPayload['filter']['podVerificationStatuses'];
            var commaSeparatedWaybillNos = this.state.searchWayBillNo.trim().split(",").reduce((current, next) => {
                current.push(next.trim());
                return current;
            }, []);
            var wayBillNos = [];
            commaSeparatedWaybillNos.map(wbill => {
                var tempWayBillArray = wbill.split(" ").reduce((current, next) => {
                    if (next.trim() != "") {
                        current.push(next.trim());
                    }
                    return current;
                }, [])
                wayBillNos.push(...tempWayBillArray)
            });
            filterPayload['filter']['waybillNos'] = wayBillNos
            this.setState({
                filterPayload,
                tags: [],
                selectedCustomers: [],
                customers: [],
                selectedBookingOffices: [],
                bookingOffices: [],
                selectedDeliveryLocations: [],
                deliveryLocations: [],
                statusFilters: {
                    podVerificationStatuses: this.state.defaultPodVerificationStatuses
                }
            }, () => this.applyPagination(this.state.filterPayload, true))
        }

    }

    handleCancel = () => {
        this.setState({ visible: false })
    }


    disabledStartDate = startValue => {
        const { startDate } = this.state;
        if (!startValue || !startDate) {
            return false;
        }

        return startValue.valueOf() && startValue.valueOf() > moment().endOf('day');
        // return startValue.valueOf() > startDate.valueOf();
    };

    disabledEndDate = endValue => {
        if (this.state.startDate) {
            const dayStart = moment(this.state.startDate);
            const dayEnd = moment(this.state.startDate).add(31, 'days');
            if (dayEnd.isAfter(moment().endOf('day'))) {

                return !(dayStart.isSameOrBefore(endValue) && endValue <= moment().endOf('day') && endValue >= moment(this.state.startDate).endOf('day'));
            } else {

                return !(dayStart.isSameOrBefore(endValue) && dayEnd.isAfter(endValue) && endValue.isSameOrBefore(dayEnd));
            }


        } else {
            // return current && current > moment().endOf('day');
        }
    }

    viewPodDetils = (row, index) => {
        const { filterPayload, podsData, tags, selectedBookingOffices, selectedDeliveryLocations, selectedCustomers } = this.state;
        Mixpanel.track("POD Verification: Clicking View Button", { "podVerificationViewButtonClick": moment().valueOf() })
        this.props.history.push('/appv2/verification/form/pod', {
            currentConsignmentId: row?.consignmentId,
            filters: filterPayload,
            tags, index, podsData,
            selectedBookingOffices, selectedDeliveryLocations, selectedCustomers
        })
    }

    render() {
        const { podsData, tags, startDate, endDate, isEndDateOpen } = this.state;

        const countsObject = podsData?.countsObject;

        const podsCardData = [
            {
                title: 'Total Delivered Dockets',
                value: countsObject?.totalCount
            },
            {
                title: 'POD Document Pending',
                value: countsObject?.pendingPODCount
            },
            {
                title: 'POD Verification Pending',
                value: countsObject?.verificationPendingPODCount
            },
            {
                title: 'POD Rejected',
                value: countsObject?.rejectedPODCount
            },
            {
                title: 'POD Verified',
                value: countsObject?.verifiedPODCount
            }
        ]

        const columns = [
            {
                title: 'Docket No.',
                dataIndex: 'waybillNumber',
                key: 'waybillNumber',
                fixed: 'left',
                width: 150,
            },
            {
                title: '3PL Docket No',
                dataIndex: 'threePLNumber',
                width: 150,
                key: 'threePLNumber'
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                width: 150,
                key: 'customer'
            },
            {
                title: 'Booking Office',
                dataIndex: 'bookingOffcLocation',
                width: 150,
                key: 'bookingOffcLocation'
            },
            {
                title: 'Delivery Location',
                dataIndex: 'destinationLocation',
                width: 150,
                key: 'destinationLocation'
            },
            {
                title: 'Delivery Date and Time',
                dataIndex: 'podCreatedDate',
                width: 200,
                key: 'podCreatedDate',
                render: (data) => moment(data).format('DD/MM/YYYY hh:mm A')
            },
            {
                title: <>Last Updated <Icon onClick={() => this.changeOrder()} style={{ cursor: 'pointer', marginLeft: 25 }} type={this.state.sortIcon} /></>,
                width: 175,
                dataIndex: 'lastUpdatedAt',
                key: 'lastUpdatedAt',
                render: (data) => moment(data).format('DD/MM/YYYY HH:MM')
            },
            {
                title: 'POD Verification Status',
                dataIndex: 'podVerificationStatus',
                key: 'podVerificationStatus',
                width: 220,
                align: 'center',
                render: PodStatusTransformer

            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'action',
                fixed: 'right',
                width: 100,
                render: (data, row, index) => (
                    <Row>
                        {
                            <Col span={8}><Tooltip placement="top" title={'View'}>
                                <Button onClick={() => this.viewPodDetils(row, index)} type="primary">View</Button>
                            </Tooltip>
                            </Col>
                        }
                    </Row >
                )
            },
        ]

        return (
            <div className="v-docket-list">
                <Row gutter={[12, 12]}>
                    <Col>
                        <Card>
                            <Row gutter={[6, 12]}>
                                <Col span={14}>
                                    <Input
                                        onChange={this.changeTrip}
                                        allowClear
                                        value={this.state.searchWayBillNo}
                                        onPressEnter={this.searchByInputAction}
                                        prefix={<Icon type="search" />}
                                        placeholder="Search by Docket No."
                                        size={'large'}
                                    />
                                </Col>
                                <Col span={9} offset={1}>
                                    <div className="flex-box justify-content-space-evenly">
                                        <Button className='flex-1' type="default" block size={'large'} onClick={this.searchByWaybillNo}>
                                            Apply
                                        </Button>
                                        <div className='flex-1 badge-wrapper' >
                                            <Badge dot={this.state.tags.length > 0}>
                                                <Button onClick={() => this.setState({ visible: true })} type="default" block size={"large"}>
                                                    <img src={FilterIcon} alt="filter" />
                                                    Filters
                                                </Button>
                                            </Badge>

                                        </div>

                                    </div>
                                </Col>
                                <Col span={24}>
                                    {tags.map((t, index) => {
                                        return (
                                            <Tag key={index} style={{ marginBottom: 10 }} closable onClose={(e) => {
                                                e.preventDefault();
                                                this.clearTag(t);
                                            }}>
                                                {t.label} :
                                                <b>{" " + t.value?.join(", ")}</b>
                                            </Tag>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <div style={{ textAlign: 'center' }}>
                        <Spin spinning={this.state.loading} />
                    </div>
                    {
                        podsData && podsData?.consignmentDetails?.length > 0 ?
                            <>
                                <Col span={24}>
                                    <DataCard summaryData={podsCardData} />
                                </Col>
                                <Col span={24}>
                                    <Card>
                                        <Table
                                            columns={columns}
                                            rowKey={record => record?.id}
                                            bordered
                                            pagination={false}
                                            dataSource={podsData?.consignmentDetails}
                                            scroll={{ x: 1250 }}
                                        />
                                        <Col style={{ marginTop: 10, bottom: 10 }}>
                                            <Pagination
                                                onChange={this.changePage}
                                                total={podsData?.totalCount}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                                pageSize={podsData?.currentPageSize}
                                                current={podsData?.currentPageNo}
                                                showSizeChanger
                                                onShowSizeChange={this.onShowSizeChange}
                                            />
                                        </Col>
                                    </Card>
                                </Col>
                            </>
                            :
                            <>
                                {!this.state.loading &&
                                    <Empty />
                                }
                            </>
                    }
                </Row>

                <Modal
                    title="Filters"
                    visible={this.state.visible}
                    onCancel={() => { this.setState({ visible: false }) }}
                    maskClosable={false}
                    style={{ top: 20 }}
                    footer={[
                        <Button key="back" onClick={this.resetFilters}>
                            Reset
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleFiltersApplied}>
                            Apply
                        </Button>,
                    ]}
                    width={550}
                >
                    <div className="verification-status-filters">
                        <Row>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Delivery Date</div>
                                <Row gutter={12}>

                                    <Col span={12} style={{ bottom: 5 }}>
                                        <span className="form-label-text">From </span>
                                        <DatePickerInput
                                            disabledDate={this.disabledStartDate}
                                            type="date"
                                            format="YYYY-MM-DD"
                                            value={startDate}
                                            placeholder="From"
                                            style={{ width: '100%' }}
                                            onChange={value => {
                                                if (value) {
                                                    if (value.valueOf() >= moment().valueOf()) {
                                                        value = moment();
                                                    }
                                                    this.setState({
                                                        startDate: value,
                                                        filterPayload: {
                                                            ...this.state.filterPayload,
                                                            filter: {
                                                                ...this.state.filterPayload.filter,
                                                                "deliveredDateRange": {
                                                                    from: value.valueOf(),
                                                                    to: endDate.valueOf()
                                                                }
                                                            }

                                                        }
                                                    })
                                                } else {
                                                    this.setState({
                                                        filterPayload: {
                                                            ...this.state.filterPayload,
                                                            filter: {
                                                                ...this.state.filterPayload.filter,
                                                                "deliveredDateRange": {
                                                                    from: moment().subtract(7, 'd').startOf('day').valueOf(),
                                                                    to: moment().endOf().valueOf(),
                                                                }
                                                            }

                                                        }
                                                    })
                                                }
                                            }}
                                            onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }) : {}}
                                        />
                                    </Col>

                                    <Col span={12} style={{ bottom: 5 }}>
                                        <span className="form-label-text">To </span>
                                        <DatePickerInput
                                            type="date"
                                            disabledDate={this.disabledEndDate}
                                            format="YYYY-MM-DD"
                                            value={endDate}
                                            placeholder="To"
                                            style={{ width: '100%' }}
                                            open={isEndDateOpen}
                                            onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                                            onChange={value => {
                                                if (value) {
                                                    if (value.valueOf() >= moment().valueOf()) {
                                                        value = moment();
                                                    }
                                                    this.setState({
                                                        endDate: value,
                                                        filterPayload: {
                                                            ...this.state.filterPayload,
                                                            filter: {
                                                                ...this.state.filterPayload.filter,
                                                                "deliveredDateRange": {
                                                                    from: startDate.valueOf(),
                                                                    to: value.valueOf()
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    this.setState({
                                                        filterPayload: {
                                                            ...this.state.filterPayload,
                                                            filter: {
                                                                ...this.state.filterPayload.filter,
                                                                "deliveredDateRange": {
                                                                    from: moment().subtract(7, 'd').startOf('day').valueOf(),
                                                                    to: moment().endOf().valueOf(),
                                                                }
                                                            }
                                                        }
                                                    })
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Booking office</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedBookingOffices}
                                        onSearch={this.fetchLocations}
                                        showSearch
                                        loading={this.state.bookingOfficeLoading}
                                        notFoundContent={"Start Typing to search (3 charachters)..."}
                                        labelInValue
                                        onBlur={this.resetLocations}
                                        placeholder={"Select Booking office"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onBookingLocationChange}
                                    >
                                        {this.state?.bookingOffices?.map((location, key) => (
                                            <Option key={location.key ? location.key : location.value} value={location.value}>
                                                {location.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>
                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Customer</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        value={this.state.selectedCustomers}
                                        onSearch={this.fetchCustomers}
                                        showSearch
                                        onBlur={this.resetCustomers}
                                        loading={this.state.customerLoading}
                                        notFoundContent={"Start Typing to search (3 charachters)..."}
                                        labelInValue
                                        placeholder={"Select Customers"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onChangeCustomers}
                                    >
                                        {this.state?.customers?.map((customer, key) => (
                                            <Option key={customer.key ? customer.key : customer.value} value={customer.value}>
                                                {customer.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>

                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Delivery Locations</div>
                                <div className="boxfilterTitles">
                                    <Select
                                        mode={'multiple'}
                                        style={{ width: "100%" }}
                                        disabled={this.state.selectedCustomers.length == 0}
                                        value={this.state.selectedDeliveryLocations}
                                        onSearch={this.fetchDeliveryLocations}
                                        showSearch
                                        loading={this.state.deliveryLocationsLoading}
                                        notFoundContent={"Start Typing to search (3 charachters)..."}
                                        labelInValue
                                        onBlur={this.resetDeliveryLocations}
                                        placeholder={"Select Delivery Location"}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        onChange={this.onDeliveryLocationChange}
                                    >
                                        {this.state?.deliveryLocations?.map((location, key) => (
                                            <Option key={location.key ? location.key : location.value} value={location.value}>
                                                {location.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Col>

                            <Col span={24} style={{ bottom: 20 }}>
                                <div className="boxusertitles">Verification Status</div>
                                <div className="boxfilterTitles">
                                    {this.state?.statusFilters?.podVerificationStatuses?.map((item, index) => (
                                        <div key={index} className={item.value === false ? "boxLittle" : "boxLittleSelected"} onClick={(data, id) => this.filterValuesChnages("podVerificationStatuses", index)}>{item.name}</div>
                                    ))}
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Modal>

            </div >
        )
    }
}

export default VerificationPods;

export const defaultFilterValues = {
    podVerificationStatuses: [
        { id: 0, name: "POD Document Pending", value: false, storedValue: "POD_DOCUMENT_PENDING" },
        { id: 1, name: "POD Verification Pending ", value: false, storedValue: "POD_VERIFICATION_PENDING" },
        { id: 2, name: "POD Rejected", value: false, storedValue: "POD_REJECTED" },
        { id: 3, name: "POD Verified", value: false, storedValue: "POD_VERIFIED" },
    ]
}