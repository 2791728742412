export const GET_ACCESS_KEYS_BEGIN = 'GET_ACCESS_KEYS_BEGIN';
export const GET_ACCESS_KEYS_SUCESS = 'GET_ACCESS_KEYS_SUCESS';
export const GET_ACCESS_KEYS_FAILURE = 'GET_ACCESS_KEYS_FAILURE';


export const GET_TRACKING_DETAILS_BEGIN = 'GET_TRACKING_DETAILS_BEGIN';
export const GET_TRACKING_DETAILS_SUCCESS = 'GET_TRACKING_DETAILS_SUCCESS';
export const GET_TRACKING_DETAILS_FAILURE = 'GET_TRACKING_DETAILS_FAILURE';


export const GET_VEHICLE_TRACKING_DETAILS_BEGIN = 'GET_VEHICLE_TRACKING_DETAILS_BEGIN';
export const GET_VEHICLE_TRACKING_DETAILS_SUCCESS = 'GET_VEHICLE_TRACKING_DETAILS_SUCCESS';
export const GET_VEHICLE_TRACKING_DETAILS_FAILURE = 'GET_VEHICLE_TRACKING_DETAILS_FAILURE';



export const GET_THC_DETAILS_BEGIN = 'GET_THC_DETAILS_BEGIN';
export const GET_THC_DETAILS_SUCCESS = 'GET_THC_DETAILS_SUCCESS';
export const GET_THC_DETAILS_FAILURE = 'GET_THC_DETAILS_FAILURE';


export const GET_ROUTE_DETAILS_BEGIN = 'GET_ROUTE_DETAILS_BEGIN';
export const GET_ROUTE_DETAILS_SUCCESS = 'GET_ROUTE_DETAILS_SUCCESS';
export const GET_ROUTE_DETAILS_FAILURE = 'GET_ROUTE_DETAILS_FAILURE';
export const GET_HELIOS_ROUTE_DETAILS_SUCCESS = 'GET_HELIOS_ROUTE_DETAILS_SUCCESS';
export const GET_HELIOS_VEHICLE_TRACKING_DETAILS_SUCCESS = 'GET_HELIOS_VEHICLE_TRACKING_DETAILS_SUCCESS';


export const RESET_FIELDS = 'RESET_FIELDS';
export const CLEAR_TRACKING = 'CLEAR_TRACKING';