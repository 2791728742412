import { combineReducers } from 'redux';
import { trackingReducer } from '../components/tracking/reducer';
import { trackingPopupReducer } from '../components/TrackingPopup/reducer';
import { suspiciousReducer } from '../components/suspicious/reducer';
import { appReducer } from '../app/appReducer'
import { auditReducer } from '../components/inventory/audit/reducer'
import { headerReducer } from '../components/shared/header/reducer';
import { trackingListingReducer } from '../components/tracking/TrackingListing/reducer';
import { settingsReducer } from '../components/settings/settingsReducer';











import { ingestReducer } from '../components/reports/ingest/reducer';

import { lsnalertReducer } from '../components/shared/Lsnalert/reducer';

import { ReatilerCustomerReducer } from '../components/inbound/inbound-create/b2b-lite-booking/customer-management/reducer';
import { b2bLiteBookingReducer } from '../components/inbound/inbound-create/b2b-lite-booking/reducer';
import { tripReducer } from "../components/inventory/trip/reducer";
import { coloaderReducer } from "../components/inventory/trip/co-loader-trip/reducer";

import { CustomerReducer } from '../components/settings/customer/reducer';
import { ZoneReducer } from '../components/shared-components/zone/reducer';
import { ZoneMappingReducer } from '../components/shared-components/zone-mapping/reducer';

import { UserReducer } from '../components/settings/user/reducer';
import { RoleReducer } from '../components/settings/role/reducer';
import { completedListReducer } from '../components/trips/completed/reducer';
import { pendingListReducer } from '../components/trips/pending/reducer';
import { LocationReducer } from '../components/settings/location/reducer';
import { PromiseTimeReducer } from '../components/shared-components/customer-promise-time/reducer';

import { AwbReducer } from '../components/inventory/awb/reducer';

import { PrintReducer } from '../components/print/reducer';
import { ExpectedReducer } from '../components/inbound/expected/reducer';
import { ExceptionReducer } from '../components/inbound/exception/reducer';
import { ScanReducer } from '../components/inbound/scan/reducer';
import { MailReducer } from '../components/communication/mail/reducer';
import { LMSReducer } from '../components/lms/reducer';
import { assetsReducer } from '../components/settings/asset/reducer';
import { CustomerUserReducer } from '../components/customer-users/reducer'
import { LiveLoadsReducer } from '../components/customer-panel/live-loads/reducer';
import { DeliverLoadsReducer } from '../components/customer-panel/delivered-loads/reducer';
import { CustomerTrackingReducer } from '../components/customer-tracking/reducer';
import { DashboardReducer } from '../components/zoho-dashboard/reducer';
import { PartnerCustomerUserReducer } from '../components/settings/customer-invite/reducer';
import { pickupAssistanceReducer } from '../components/inventory/pickupAssist/reducer';
import { DrsdraftReducer } from '../components/inventory/drs-draft/reducer';
import { InvoiceCustomerSettlementReducer } from '../components/invoice/customer-settlement/reducer';
import { zohoReducer } from '../components/settings/zoho-dashboard/reducer';

import { vendorCreateReducer } from '../components/settings/vendor/reducer';
import { SLAReasonsReducer } from "../components/sla-reasons/reducer";
import { SLARulesReducer } from "../components/trips/sla-rules/reducer";
import { EwayReducer } from "../components/ewaybill/parta/reducer";
import { CustomConfigReducer } from "../components/settings/config/reducer";


import { B2cBookingReducer } from '../components/inbound/inbound-create/b2c-booking/reducer';
import { PrintReducer as PrintHistoryReducer } from '../components/inbound/print-history/reducer';
import { RouteReducer } from '../components/settings/route/reducer';
import { DrsReducer } from '../components/inventory/drs/reducer';
import { RateCardCustomConfigReducer } from "../components/settings/ratecard/reducer";

import { InventoryClosureReducer } from '../components/inventory/closure/reducer';

import { IndentCreateReducer } from '../components/indent/common/state/indent.reducer';
import { IndentListingReducer } from "../components/indent/management/listing/state/indent.listing.reducer";
import { lovStore } from '../common/lovStore/lovReducer';

const rootReducer = combineReducers({
    tracking: trackingReducer,
    trackingDetails: trackingPopupReducer,
    trackingListing: trackingListingReducer,
    suspicious: suspiciousReducer,
    app: appReducer,
    audit: auditReducer,
    header: headerReducer,
    settingsReducer: settingsReducer,
    
    
    
    
    
    
    
    
    
    
    
    ingest: ingestReducer,
    
    lsnalert: lsnalertReducer,
    
    b2bLiteBooking: b2bLiteBookingReducer,
    RetailerCustomer: ReatilerCustomerReducer,
    trips: tripReducer,
    coloader: coloaderReducer,
    
    settingsCustomers: CustomerReducer,
    zone: ZoneReducer,
    zoneMapper: ZoneMappingReducer,
    
    settingsUsers: UserReducer,
    settingsRoles: RoleReducer,
    completedList: completedListReducer,
    pendingTripList: pendingListReducer,
    settingLocation: LocationReducer,
    promiseTime: PromiseTimeReducer,
    
    inventoryAwb: AwbReducer,
    
    print: PrintReducer,
    expected: ExpectedReducer,
    exception: ExceptionReducer,
    scan: ScanReducer,
    communicationMail: MailReducer,
    lms: LMSReducer,
    assets: assetsReducer,
    customerUsers: CustomerUserReducer,
    liveLoads: LiveLoadsReducer,
    deliverLoads: DeliverLoadsReducer,
    customerTracking: CustomerTrackingReducer,
    dashboard: DashboardReducer,
    partnerCustomer: PartnerCustomerUserReducer,
    pickupAssist: pickupAssistanceReducer,
    drsDraft: DrsdraftReducer,
    invoiceCustomerSettlement: InvoiceCustomerSettlementReducer,
    zoho: zohoReducer,
    
    vendor: vendorCreateReducer,
    slaReasons: SLAReasonsReducer,
    slaRules: SLARulesReducer,
    eway: EwayReducer,
    customConfig: CustomConfigReducer,
    
    
    b2cBooking: B2cBookingReducer,
    printHistory: PrintHistoryReducer,
    settingsRoute: RouteReducer,
    inventoryDrs: DrsReducer,
    rateCardConfig: RateCardCustomConfigReducer,
    
    inventoryClosure: InventoryClosureReducer,

    indentCreateReducer: IndentCreateReducer,
    indentList: IndentListingReducer,
    lovStore : lovStore
});

export default rootReducer;
