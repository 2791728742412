import React, { Component } from 'react';
import './scan-sticker.scss';
import moment from 'moment';

class ScanSticker extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { scanResponseStatus, scanResponseDataCurated, isBarcode } = this.props;

    return (
      <>
        {
          isBarcode == "barcodeEnabled" ?
            <div className="ScanSticker">
              {
                scanResponseStatus?.success && scanResponseDataCurated?.waybillNo?.value
                  ? <div className="scan-box">
                    <ul>
                      {
                        (scanResponseDataCurated?.barcode?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.barcode.key}</div>
                          <div className="value">{scanResponseDataCurated.barcode.value}</div>
                        </li> : null
                      }

                      {
                        (scanResponseDataCurated?.waybillNo?.value) ? <li className="waybill">
                          <div className="key">{scanResponseDataCurated.waybillNo.key}</div>
                          <div className="value">{scanResponseDataCurated.waybillNo.value}</div>
                        </li> : null
                      }
                      {/* {
                        (scanResponseDataCurated?.shipmentValue?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.shipmentValue.key}</div>
                          <div className="value">
                            {
                              (scanResponseDataCurated.shipmentValue.value >= 5000) && <i className="ti-arrow-up text-red"></i>
                            }
                        ₹ {scanResponseDataCurated.shipmentValue.value}
                          </div>
                        </li> : null
                      } */}
                      {
                        (scanResponseDataCurated?.cpd?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.cpd.key}</div>
                          <div className="value">{moment(scanResponseDataCurated.cpd.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.cpd.value).fromNow()}</small></div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.lastPodConStatusWithReason?.value) ? <li>
                          <div className="key">
                            {scanResponseDataCurated.lastPodConStatusWithReason.key}</div>
                          <div className="value">
                            {scanResponseDataCurated.lastPodConStatusWithReason.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.destinationLocationName?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.destinationLocationName.key}</div>
                          <div className="value pincode">{scanResponseDataCurated.destinationLocationName.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.locationCode?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.locationCode.key}</div>
                          <div className="value pincode">{scanResponseDataCurated.locationCode.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.consigneeAddress?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.consigneeAddress.key}</div>
                          <div className="value address">{scanResponseDataCurated.consigneeAddress.value}</div>
                        </li> : null
                      }

                      {/* {
                        (scanResponseDataCurated?.shippmentDay?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.shippmentDay.key}</div>
                          <div className="value">{scanResponseDataCurated.shippmentDay.value} Days</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.rescheduleDate?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.rescheduleDate.key}</div>
                          <div className="value">{moment(scanResponseDataCurated.rescheduleDate.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.rescheduleDate.value).fromNow()}</small></div>
                         
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.isLocalServiceable?.value) ? <li>
                          <div className="key">local Servicibility:</div>
                          <div className="value">{scanResponseDataCurated.isLocalServiceable.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.nextLocation?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.nextLocation.key}</div>
                          <div className="value">{scanResponseDataCurated.nextLocation.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.lastAttemptDate?.value) ? <li>
                          <div className="key">
                            {scanResponseDataCurated.lastAttemptDate.key}
                          </div>
                          <div className="value">
                            {moment(scanResponseDataCurated.lastAttemptDate.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.lastAttemptDate.value).fromNow()}</small>
                          </div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.attemptCount?.value) ? <li>
                          <div className="key" >
                            {scanResponseDataCurated.attemptCount.key}</div>
                          <div className="value">
                            {scanResponseDataCurated.attemptCount.value}</div>
                        </li> : null
                      }

                      {
                        (scanResponseDataCurated?.shipperName?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.shipperName.key}</div>
                          <div className="value">{scanResponseDataCurated.shipperName.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.consigneeAddress?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.consigneeAddress.key}</div>
                          <div className="value address">{scanResponseDataCurated.consigneeAddress.value}</div>
                        </li> : null
                      } */}
                    </ul>
                    {/* <ul className="third-row-scan">
                      {
                        (scanResponseDataCurated?.primaryBucket?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.primaryBucket.key}</div>
                          <div className={`value scan-bucket
                    ${(scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                    ${scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                    ${(scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'NORMAL' || scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'LOW') ? 'background-normal' : ''}
            `}>{scanResponseDataCurated.primaryBucket.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.secondaryBucket?.value) ? <li>
                          <div className="key">&nbsp;</div>
                          <div className={`value scan-bucket
                    ${(scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                    ${scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                    ${(scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'NORMAL' || scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'LOW') ? 'background-normal' : ''}
            `}>{scanResponseDataCurated.secondaryBucket.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.tertiaryBucket?.value) ? <li>
                          <div className="key">&nbsp;</div>
                          <div className={`value scan-bucket
                    ${(scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                    ${scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                    ${(scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'NORMAL' || scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'LOW') ? 'background-normal' : ''}
            `}>{scanResponseDataCurated.tertiaryBucket.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated.isSuspiciousPod.value) ? <li>
                          <div className="key">&nbsp;</div>
                          <div className="value scan-bucket background-warning">
                            {scanResponseDataCurated.isSuspiciousPod.value ? 'SUSPICIOUS' : 'SUSPICIOUS'}</div>
                        </li> : null
                      }
                    </ul>
                   */}
                  </div>
                  : <div className="scan-box"></div>
              }
            </div> :


            // It's used for scanwaybill
            <div className="ScanSticker">
              {
                scanResponseStatus?.success && scanResponseDataCurated?.waybillNo?.value
                  ? <div className="scan-box">
                    <ul>
                      {
                        (scanResponseDataCurated?.barcode?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.barcode.key}</div>
                          <div className="value">{scanResponseDataCurated.barcode.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.locationCode?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.locationCode.key}</div>
                          <div className="value pincode">{scanResponseDataCurated.locationCode.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.waybillNo?.value) ? <li className="waybill">
                          <div className="key">{scanResponseDataCurated.waybillNo.key}</div>
                          <div className="value">{scanResponseDataCurated.waybillNo.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.shipmentValue?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.shipmentValue.key}</div>
                          <div className="value">
                            {
                              (scanResponseDataCurated.shipmentValue.value >= 5000) && <i className="ti-arrow-up text-red"></i>
                            }
                          ₹ {scanResponseDataCurated.shipmentValue.value}
                          </div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.cpd?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.cpd.key}</div>
                          <div className="value">{moment(scanResponseDataCurated.cpd.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.cpd.value).fromNow()}</small></div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.shippmentDay?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.shippmentDay.key}</div>
                          <div className="value">{scanResponseDataCurated.shippmentDay.value} Days</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.rescheduleDate?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.rescheduleDate.key}</div>
                          <div className="value">{moment(scanResponseDataCurated.rescheduleDate.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.rescheduleDate.value).fromNow()}</small></div>
                          {/* <div className="value">{scanResponseDataCurated.rescheduleDate.value | amDateFormat:'DD MMM YYYY'}</div> */}
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.isLocalServiceable?.value) ? <li>
                          <div className="key">local Servicibility:</div>
                          <div className="value">{scanResponseDataCurated.isLocalServiceable.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.nextLocation?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.nextLocation.key}</div>
                          <div className="value">{scanResponseDataCurated.nextLocation.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.lastAttemptDate?.value) ? <li>
                          <div className="key">
                            {scanResponseDataCurated.lastAttemptDate.key}
                          }</div>
                          <div className="value">
                            {moment(scanResponseDataCurated.lastAttemptDate.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.lastAttemptDate.value).fromNow()}</small>
                          </div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.attemptCount?.value) ? <li>
                          <div className="key" >
                            {scanResponseDataCurated.attemptCount.key}</div>
                          <div className="value">
                            {scanResponseDataCurated.attemptCount.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.lastPodConStatusWithReason?.value) ? <li>
                          <div className="key">
                            {scanResponseDataCurated.lastPodConStatusWithReason.key}</div>
                          <div className="value">
                            {scanResponseDataCurated.lastPodConStatusWithReason.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.shipperName?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.shipperName.key}</div>
                          <div className="value">{scanResponseDataCurated.shipperName.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.consigneeAddress?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.consigneeAddress.key}</div>
                          <div className="value address">{scanResponseDataCurated.consigneeAddress.value}</div>
                        </li> : null
                      }
                    </ul>
                    <ul className="third-row-scan">
                      {
                        (scanResponseDataCurated?.primaryBucket?.value) ? <li>
                          <div className="key">{scanResponseDataCurated.primaryBucket.key}</div>
                          <div className={`value scan-bucket
                      ${(scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                      ${scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                      ${(scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'NORMAL' || scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'LOW') ? 'background-normal' : ''}
              `}>{scanResponseDataCurated.primaryBucket.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.secondaryBucket?.value) ? <li>
                          <div className="key">&nbsp;</div>
                          <div className={`value scan-bucket
                      ${(scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                      ${scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                      ${(scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'NORMAL' || scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'LOW') ? 'background-normal' : ''}
              `}>{scanResponseDataCurated.secondaryBucket.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated?.tertiaryBucket?.value) ? <li>
                          <div className="key">&nbsp;</div>
                          <div className={`value scan-bucket
                      ${(scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                      ${scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                      ${(scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'NORMAL' || scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'LOW') ? 'background-normal' : ''}
              `}>{scanResponseDataCurated.tertiaryBucket.value}</div>
                        </li> : null
                      }
                      {
                        (scanResponseDataCurated.isSuspiciousPod.value) ? <li>
                          <div className="key">&nbsp;</div>
                          <div className="value scan-bucket background-warning">
                            {scanResponseDataCurated.isSuspiciousPod.value ? 'SUSPICIOUS' : 'SUSPICIOUS'}</div>
                        </li> : null
                      }
                    </ul>
                  </div>
                  : <div className="scan-box"></div>
              }
            </div>
        }
      </>

    )
  }
}

export default ScanSticker;