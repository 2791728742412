import { Alert } from "antd";
import React from "react";
import SLARouteLocation from "./sla-route-location.component";
import "./sla-component.scss";
const SLARouteTimeline = ({ path, currentPath }) => {
  const alertType = {
    AT_RISK: "custom-warning",
    ON_TIME: "custom-success",
    BREACHED: "custom-error",
  };
  const textCenter = {
    textAlign: "center",
  };

  return (
    <div className="flex-column  flex-gap-m sla-route">
      <div className="sla-alert spacer-m" style={textCenter}>
        {path.isActivePath && !path.errorMessage ? (
          <Alert
            message={path.responseMessage}
            type={alertType[path.finalBreachStatus]}
          />
        ) : (
          <Alert
            message={`This docket has taken ${currentPath}`}
            type="custom-info"
          />
        )}
      </div>
      {!path.errorMessage && (
        <div className="flex-box">
          <div className="flex-2 flex-box flex-gap-l">
            <div></div>
            <div></div>
          </div>
          <div className="flex-1 font-weight-600">Expected By</div>
          <div className="flex-1 font-weight-600">Actual</div>
          <div className="flex-1 font-weight-600">
            <div style={{ marginLeft: -15 }}>Breach</div>
          </div>
        </div>
      )}
      {path.errorMessage ? (
        <></>
      ) : (
        <div className="flex-column spacer-m flex-gap-2xl">
          {path.locations.map((loc, index) => (
            <div style={{ position: "relative" }}>
              <SLARouteLocation
                location={loc}
                nextLocation={path.locations[index + 1]}
                previousLocation={path.locations[index - 1]}
                key={index}
                isActivePath={path.isActivePath}
                currentPath={currentPath}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SLARouteTimeline;
