import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";

export default function DateRangeWidget(props) {

    var [ startDate, SetStartDate ] = useState(undefined);
    var [ endDate, SetEndDate ] = useState(undefined);

    useEffect(() => {
        if (props?.value && typeof props?.value == "string") {
            [startDate, endDate] = props.value.split(",");
            [startDate, endDate] = [parseInt(startDate), parseInt(endDate)];
        } else if (props?.value && props?.value instanceof Array) {
            [startDate, endDate] = [props.value[0], props.value[1]];
            [startDate, endDate] = [parseInt(startDate), parseInt(endDate)];
        }
        SetStartDate(startDate);
        SetEndDate(endDate);
    }, [ props?.value ]);

    const uiAttrs = schemaToAttrs(props.uiSchema);
    uiAttrs.className = uiAttrs.className ? uiAttrs.className + " ant-input" : "ant-input";
    if (props.readonly) {
        uiAttrs.className += " ant-date-input-readonly";
    }
    
    const disableEndDate = endValue => {
        if (!endValue || !startDate) {
            return false;
        }
        return endValue.valueOf() <= moment(startDate).valueOf();
    }
    const disableStartDate = startValue => {
        if (!startValue || !endDate) {
            return false;
        }
        return startValue.valueOf() >= moment(endDate).valueOf();
    }

    const onChange = (start, end) => {
        const updatedRange = [start ? moment(start).startOf("day").valueOf() : "", end ? moment(end).endOf("day").valueOf() : ""].join(",");
        props.onChange(updatedRange)
    }
    return (
        <div className="input-wrapper">
            <div className="flex-box flex-1 flex-gap-l align-items-center">
                <DatePicker value={startDate ? moment(startDate) : undefined}
                    disabledDate={disableStartDate}
                    onChange={value => onChange(value, endDate)}
                    disabled={props.disabled || props.readonly} {...uiAttrs}
                    className={uiAttrs.className ? uiAttrs.className + "" : ""}
                    placeholder={uiAttrs.fromPlaceHolder} />

                { uiAttrs.separator ? uiAttrs.separator : "to" }
                
                <DatePicker value={endDate ? moment(endDate) : undefined}
                    disabledDate={disableEndDate}
                    onChange={value => onChange(startDate, value)}
                    disabled={props.disabled || props.readonly} {...uiAttrs}
                    className={uiAttrs.className ? uiAttrs.className + "" : ""}
                    placeholder={uiAttrs.toPlaceHolder} />
            </div>
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
        </div>
    )
}