import { Icon } from "antd";
import React from "react";
import moment from "moment";
import "./sla-component.scss";
const formatValidity = (date) =>
  moment(new Date(date)).format("DD/MM/YYYY HH:mm");
const SLARouteEvent = ({ locationName, event }) => {
  const unDoneIconStyle = {
    width: 24,
    height: 24,
    left: 348,
    top: 488,
    background: "#D9D9D9",
    borderRadius: "50%",
  };
  const eventEnum = {
    BOOKING: "Docket Created At",
    TRIP_START: "Left ",
    MARK_ARRIVE: "Arrived At",
    DEL: "Delivered at",
    PICKUP: "",
  };
  
  return (
    <div className="flex-box ">
      <div className="flex-2 flex-box flex-gap-l">
        <div>
          {event.isActionDone ? (
            <Icon
              type="check-circle"
              theme="filled"
              style={{ fontSize: "24px", color: "#008556" }}
            />
          ) : (
            <div style={unDoneIconStyle}></div>
          )}
        </div>
        <div>
          {eventEnum[event.eventType]} {locationName}
        </div>
      </div>
      <div className="flex-1">
        {event?.expected ? formatValidity(event.expected) : "-"}
      </div>
      <div className="flex-1">
        {event?.actual ? formatValidity(event.actual) : "-"}
      </div>
      <div className="flex-1">
        {event?.breachStatus === "BREACHED" ? (
          event?.totalTime > 24 ? (
            <span className="sla-breached-text">
              {Math.floor(moment.duration(event?.totalTime, "hours").asDays())}{" "}
              days{" "}
              {Math.floor(moment.duration(event?.totalTime, "hours").hours())}{" "}
              hrs
            </span>
          ) : (
            <span className="sla-breached-text">{event?.totalTime} hrs</span>
          )
        ) : event?.breachStatus === "NONE" ? (
          "None"
        ) : (
          "-"
        )}
      </div>
    </div>
  );
};
export default SLARouteEvent;
