import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from "./actions";
import TripList from './trip.list';
import {
    Spin, Input, Modal
} from 'antd';

import _ from 'lodash';

let partnerId = localStorage.getItem('currentPartnerId');
const locationId = localStorage.getItem('currentPartnerLocationId');
if (partnerId == "null") {
    partnerId = localStorage.getItem('loggedPartnerId');
}
class Trip extends Component {
    state = {
        appliedFilters: {},
        page: 1,
        newAdhocCharges: []
    }
   
    componentDidUpdate(oldProps) {
        if (this.props.vendorBlockReason && this.props.vendorBlockReason.blockedReason && this.props.vendorBlockReason !== oldProps.vendorBlockReason) {
            Modal.error({
                title: 'Action Blocked',
                content: <>{this.props.vendorBlockReason.blockedReason}</>,
                onOk() {

                }
            })
        }
    }

    componentDidMount() {
        const locationFilters = { "filters": { "roles": ["DELIVERY", "PICKUP"], "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_LOCATION_LIST" }, "status": true } }
        this.refreshTripList();
        this.props.getVendorsList();
        if (!this.props.locationGroups?.response?.locationsGroupedByRole) this.props.getLocationGroups(locationFilters);
        this.props.getPartnerAssets();
    }

    addAdhocCharges = (value) => {
        let { newAdhocCharges } = this.state;
        let charges = {
            chargeLabel: value
        };
        charges.concat(newAdhocCharges);
        // newAdhocCharges.push(charges)
        this.setState({ newAdhocCharges });
    }


    printEwayBill = (trip) => {
        this.props.printEwayBill(trip.id);
    }


    consolidateWayBill = (trip) => {
        this.props.getConsolidateWayBill(trip.id);
    }

    loadTrips = (page, pageSize) => {
        const filter = {
            "filters": {
                "partnerIds": [
                    `${partnerId}`
                ],
                "pageNo": page,
                "locationIds": [
                    `${locationId}`
                ],
                "pageSize": pageSize,
                "statuses": [
                    "OPEN",
                    "STARTED"
                ],
                "consignmentFilter": {},
                "entityTypes": [
                    "ASSET"
                ],
                "isActive": true,
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_TRIP_LIST",
                    "requestingFrom": "TRIPS"
                },
                "fetchObjects": ["ROUTE", "MANIFEST", "CONSIGNMENTS", "ASSEST", "CONSIGNMENT_CONTACTS", "ADDRESS", "ASSEST_PARTNER", "CONSIGNMENTS_CUSTOMER", "THCUSER", "TRACKING_DEVICE", "DONOT_FETCH_CONSIGNMENTS", "DONOT_FETCH_VENDORS", "THC", "DRS", "HANDOVER_POD_ID", "PRS", "PRS_LEADS", "EWAYBILL", "VEHICLE_TYPE"]
            }
        };

        // if(!this.props.tripLists || this.props.tripLists.viewResponse.length < 0) {
        this.props.getTrips(filter);
        // }
    }

    paginatedFilters = (pageSize, page) => {
        let { filters } = this.state.appliedFilters;

        let filter = {
            filters: { ...filters }
        }
        filter['filters']['pageNo'] = page ? page : this.state.page;
        filter['filters']['pageSize'] = pageSize;
        this.props.getTrips(filter);
    }


    changePage = (page) => {
        this.setState({ page });
    }

    reload() {
        setTimeout(() => {
            this.location.reload();
        }, 2000)
    }

    handOver = filters => {
        this.props.getTripsHandover(filters);
        // this.refreshTripList();        
        // this.reload();
    }

    markDel = filters => {
        let markDel = this.props.getMarkDel(filters);
        // this.refreshTripList();
        // this.reload();
    }

    startTrip = (filters, manifestFilters) => {
        this.props.getTripsStart(filters, manifestFilters);
        // this.reload();
        // this.refreshTripList();
    }

    editTrip = (filters) => {
        this.props.editTrip(filters);
    }

    endTrip = (filters) => {
        this.props.getTripEnd(filters);
        // this.reload();
    }

    searchWayBills = (page = 1, pageSize = 10, waybills = '', bookingFilters = {}, mapFilter = {}, statuses = [], isEligibleToComplete = null, locations = null, entityIds = []) => {
        
        let consignmentFilter = {};
        if (waybills.length > 0) {
            consignmentFilter = { "waybills": [`${waybills}`] };
        }

        let mapFilters = {};
        if (!_.isEmpty(mapFilter)) {
            mapFilters = mapFilter;
        }


        if (bookingFilters.bookingDate && !_.isEmpty(bookingFilters.bookingDate.from) && !_.isEmpty(bookingFilters.bookingDate.to)) {
            consignmentFilter = bookingFilters;
        }

        if (statuses.length > 0) {
            statuses = statuses;
        } else {
            statuses = ["OPEN", "STARTED"]
        }

        if (!_.isNull(locations)) {
            locations = locations;
        } else {
            locations = locationId
        }



        let filter = {
            "filters": {
                "partnerIds": [
                    `${partnerId}`
                ],
                "pageNo": page,
                "locationIds": [
                    `${locations}`
                ],
                mapFilter: mapFilters,
                "pageSize": pageSize,
                statuses,
                "consignmentFilter": consignmentFilter,
                "entityTypes": [
                    "ASSET"
                ],
                "isActive": true,
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_TRIP_LIST",
                    "requestingFrom": "TRIPS"
                },
                "fetchObjects": [
                    "ROUTE",
                    "MANIFEST",
                    "CONSIGNMENTS",
                    "ASSEST",
                    "CONSIGNMENT_CONTACTS",
                    "ADDRESS",
                    "ASSEST_PARTNER",
                    "CONSIGNMENTS_CUSTOMER",
                    "THCUSER",
                    "TRACKING_DEVICE",
                    "CONSIGNMENTS_SHIPMENTS",
                    "CONSIGNMENTS_SHIPMENTS",
                    "SHIPMENTS_BARCODES",
                    "DONOT_FETCH_CONSIGNMENTS",
                    "DONOT_FETCH_VENDORS",
                    "THC",
                    "DRS",
                    "HANDOVER_POD_ID",
                    "PRS",
                    "PRS_LEADS",
                    "EWAYBILL",
                    "UNDEL_RETURN_CONSIGNMENTS",
                    "VEHICLE_TYPE"
                ]
            }
        };

        if (!_.isNull(isEligibleToComplete)) {
            filter['filters']['isEligibleToComplete'] = isEligibleToComplete;
        }
        if (entityIds.length > 0) {
            filter['filters']['entityIds'] = entityIds;
        }
        if (_.isEmpty(mapFilter)) {
            delete filter['filters']['mapFilter'];
        }
        this.setState({ appliedFilters: filter })
        // if(!this.props.tripLists || this.props.tripLists.viewResponse.length < 0) {
        this.props.getTrips(filter);
        // }
    }

    refreshTripList = () => {
        
        const filter = {
            "filters": {
                "partnerIds": [
                    `${partnerId}`
                ],
                "pageNo": 1,
                "locationIds": [
                    `${locationId}`
                ],
                "pageSize": 10,
                "statuses": [
                    "OPEN",
                    "STARTED"
                ],
                "consignmentFilter": {},
                "entityTypes": [
                    "ASSET"
                ],
                "isActive": true,
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_TRIP_LIST",
                    "requestingFrom": "TRIPS"
                },
                "fetchObjects": [
                    "ROUTE",
                    "MANIFEST",
                    "CONSIGNMENTS",
                    "ASSEST",
                    "CONSIGNMENT_CONTACTS",
                    "ADDRESS",
                    "ASSEST_PARTNER",
                    "CONSIGNMENTS_CUSTOMER",
                    "THCUSER",
                    "TRACKING_DEVICE",
                    "CONSIGNMENTS_SHIPMENTS",
                    "CONSIGNMENTS_SHIPMENTS",
                    "SHIPMENTS_BARCODES",
                    "DONOT_FETCH_CONSIGNMENTS",
                    "DONOT_FETCH_VENDORS",
                    "THC",
                    "DRS",
                    "HANDOVER_POD_ID",
                    "PRS",
                    "PRS_LEADS",
                    "EWAYBILL",
                    "UNDEL_RETURN_CONSIGNMENTS",
                    "VEHICLE_TYPE"
                ]
            }
        };
        this.setState({ appliedFilters: filter })
        this.props.getTrips(filter);

        // if(!this.props.tripLists || this.props.tripLists.viewResponse.length < 0) {


        // }
    }

    getManifestSheet = (filters) => {
        this.props.getManifestSheet(filters);
    }

    goCreatTrip = () => {       
        this.props.history.push("/appv2/trips/form/trips", {});
    }



    render() {
        return (
            <>
                <h3>Trips {this.props.tripLists?.totalCount ? <>({this.props.tripLists?.totalCount})</> : <></>} </h3>
                {/* <Spin spinning={this.props.loading}> */}
                    <TripList
                        {...this.props}
                        goCreatTrip={this.goCreatTrip}
                        partnerId={partnerId}
                        handOver={this.handOver}
                        header={this.props.header}
                        markDel={this.markDel}
                        startTrip={this.startTrip}
                        endTrip={this.endTrip}
                        loadTrips={this.loadTrips}
                        searchWayBills={this.searchWayBills}
                        vendorData={this.props.vendorData}
                        refreshTripList={this.refreshTripList}
                        getManifestSheet={this.getManifestSheet}
                        editTrip={this.editTrip}
                        paginatedFilters={this.paginatedFilters}
                        changePage={this.changePage}
                        printEwayBill={this.printEwayBill}
                        consolidateWayBill={this.consolidateWayBill}
                        addAdhocCharges={this.addAdhocCharges}
                        newAdhocCharges={this.state.newAdhocCharges}
                    />
                {/* </Spin> */}
            </>
        )
    }
}

const mapStateToProps = state => ({
    tripLists: state.trips.tripsLists,
    assets: state?.trips?.assets,
    handOverData: state?.trips?.handOverData,
    handOverLoading: state?.trips?.handOverLoading,
    startTripManifestData: state?.trips?.startTripManifestData,
    markDelData: state?.trips?.markDelData,
    startTripLoading: state?.trips?.startTripLoading,
    startTripData: state?.trips?.startTripData,
    markDelLoading: state?.trips?.markDelLoading,
    header: state?.header,
    app: state?.app,
    appConfig: state?.app?.configurations,
    vendorData: state?.trips?.vendorData,
    loading: state?.trips?.tripListLoading,
    EndTripLoading: state?.trips?.EndTripLoading,
    endTripData: state?.trips?.endTripData,
    startTripManifestData: state?.trips?.startTripManifestData,
    locationGroups: state?.trips?.locationGroups,
    partnerAssets: state?.trips?.partnerAssets,
    editTripUpdateLoding: state?.trips?.editTripUpdateLoding,
    sproviders: state?.trips?.sproviders,
    tripSettlements: state?.trips?.tripSettlements,
    consolidateData: state?.trips?.consolidateData,
    consolidateError: state?.trips?.consolidateError,
    consolidateLoading: state?.trips?.consolidateLoading,
    getConsolidateReset: state?.trips?.getConsolidateReset,
    printEwayData: state?.trips?.printEwayData,
    printEwayLoading: state?.trips?.printEwayLoading,
    vendorBlockReason: state?.trips?.vendorBlockReason,
    rateCard: state?.trips?.rateCard

});

const mapDispatchToProps = dispatch => ({
    getTrips: (filters) => dispatch(action.getTripsList(filters)),
    getAllAssets: (routeId) => dispatch(action.getAllAssets(routeId)),
    getTripsHandover: (filters) => dispatch(action.getTripsHandover(filters)),
    getMarkDel: (filters) => dispatch(action.getTripsMarkDel(filters)),
    getTripsStart: (filters, manifestFilters) => dispatch(action.getTripsStart(filters, manifestFilters)),
    getTripEnd: (filters) => dispatch(action.getTripsEnd(filters)),
    getVendorsList: () => dispatch(action.getVendorsList()),
    getManifestSheet: (filters) => dispatch(action.getTripsStartManifestGet(filters)),
    getLocationGroups: (filters) => dispatch(action.getLocationGroups(filters)),
    getPartnerAssets: () => dispatch(action.getPartnerAssets()),
    editTrip: (filters) => dispatch(action.editTrip(filters)),
    getServiceProviders: () => dispatch(action.getSimProviders()),
    fetchTripSettlements: (payload) => dispatch(action.fetchTripSettlements(payload)),
    getConsolidateWayBill: (tripID) => dispatch(action.getConsolidateWayBill(tripID)),
    printEwayBill: (tripID) => dispatch(action.printEwayBill(tripID)),
    clearPrintEway: () => dispatch(action.clearPrintEway()),
    getResetConsolidate: () => dispatch(action.getResetConsolidate()),
    getVendorBlockReason: (id) => dispatch(action.getVendorBlockReason(id)),
    getTripRateCard: (filters) => dispatch(action.getTripRateCard(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Trip);