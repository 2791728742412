
import { initialState } from './constants';
import { FETCH_EXPECTED_CONSIGMENTS_BEGIN, FETCH_EXPECTED_CONSIGMENTS_FAILURE, FETCH_EXPECTED_CONSIGMENTS_SUCCESS } from './constants';
import { UPDATE_CONSIGMENT_BEGIN, UPDATE_CONSIGMENT_SUCCESS, UPDATE_CONSIGMENT_FAILURE } from './constants';
import { storePaginatedData } from '../../../common/utils/utils';


export const ExpectedReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EXPECTED_CONSIGMENTS_BEGIN: return fetchExpectedConsigmentsBegin(state);
        case FETCH_EXPECTED_CONSIGMENTS_SUCCESS: return fetchExpectedConsigmentsSuccess(state, action);
        case FETCH_EXPECTED_CONSIGMENTS_FAILURE: return fetchExpectedConsigmentsFailure(state, action);

        case UPDATE_CONSIGMENT_BEGIN: return updateConsigmentBegin(state);
        case UPDATE_CONSIGMENT_SUCCESS: return updateConsigmentSuccess(state, action);
        case UPDATE_CONSIGMENT_FAILURE: return updateConsigmentFailure(state, action);

        default:
            return state;
    }
}

function fetchExpectedConsigmentsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchExpectedConsigmentsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedConsigment: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedConsigment ? state.PaginatedConsigment : {}, action ?.payload ?.consignments, action.isReset),
        ...action.payload
    };
}

function fetchExpectedConsigmentsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        consignments: []
    };
}

function updateConsigmentBegin(state, action) {
    return {
        ...state,
        updateConsigment: {
            loading: true
        }
    };
}

function updateConsigmentSuccess(state, action) {
    return {
        ...state,
        updateConsigment: {
            loading: false,
            ...action.payload
        }
    };
}

function updateConsigmentFailure(state, action) {
    return {
        ...state,
        updateConsigment: {
            loading: false,
            error: action.payload,
            responses: []
        }
    }
}
