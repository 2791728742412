import { Typography, Button, Icon, Spin } from "antd";
import React, { useEffect, useState } from "react";
import SLARouteComponent from "../components/sla-route.component";
import { getSLADocketFetch } from "../services/sla-monitoring.service";
import "../sla-monitoring.scss";
const { Title } = Typography;

const SingleListing = (props) => {
  const { waybillno } = props.location.state;
  const { filters } = props.location.state;
  console.log("filters insinde detailed", filters)
  const [docket, setDocket] = useState({});
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const payload = {
      waybillNo: waybillno,
    };
    getDocket(payload);
  }, [waybillno]);

  const getDocket = async (payload) => {
    setLoading(true);
    const singleDocket = await getSLADocketFetch(payload);
    setDocket(singleDocket);
    setLoading(false);
    // return dockets;
  };
  return (
    <Spin spinning={isLoading}>
      {docket ? (
        <div className="flex-column flex-gap-l">
          <div className="flex-box justify-content-flex-end">
            <Button
              onClick={() =>
                props.history.push("/appv2/sla-monitoring/dashboard/", {
                  filters: filters,
                })
              }
              className="single-list-back"
            >
              <Icon type="arrow-left" />
              Back
            </Button>
          </div>
          <div className="white-bg flex-column border-radius-s   white-bg  sla-monitoring flex-gap-l">
            <Title className="sla-title" level={3}>
              {waybillno}
            </Title>

            <div className="flex-box sla-customer_info">
              <div className="flex-column flex-1 flex-gap-m spacer-m">
                <div className="sla-customer_info-label">Customer</div>
                <div className="sla-customer_info-value">
                  {docket.customerName}
                </div>
              </div>
              <div className="flex-column flex-1 flex-gap-m spacer-m">
                <div className="sla-customer_info-label">Count of Boxes</div>
                <div className="sla-customer_info-value">{docket.boxCount}</div>
              </div>
              <div className="flex-column flex-3 flex-gap-m spacer-m">
                <div className="sla-customer_info-label">Chargeable weight</div>
                <div className="sla-customer_info-value">
                  {docket.chargeableWeight} KG
                </div>
              </div>
            </div>
            {docket.paths && docket.paths.length > 0 ? (
              <SLARouteComponent paths={docket.paths} />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </Spin>
  );
};

export default SingleListing;
