import React, { Component } from "react";
import { Modal, Spin } from "antd";
import DetailsNavigation, { NAV_DIRECTION } from "../components/details-navigation";
import { fetchPods } from "./pods.utils";
import PodDetails from "./pod-details";
import './pods.scss';
import { cloneObject } from "../../../common/utils/utils";
import { Mixpanel } from "../../../utils/mixpanel";
import moment from "moment";

class PodDetailsPage extends Component{
    constructor(props){
        super(props);
        const { index, podsData, filters, tags } = this.props.location.state;
        const { totalCount, currentPageSize, currentPageNo, maxPage, offset } = podsData;
        this.state = {
            //navigation handler
            leftDisabled: index + offset == 1,
            rightDisabled: index + offset == totalCount,
            loading: true,
            consignmentDetailsUpdated: false,
            uploadInProgress: false,

            podsData:podsData,

            //count and page details
            totalCount, currentPageSize, currentPageNo, maxPage, offset,
            filterPayload: filters,
            tags: tags
        }
    }

    disableNavigation = false
    unsaveWarningOpen = false
    uploadInProgress = false

    componentDidMount(){
        const { currentConsignmentId, podsData } = this.props.location.state;
        const { currentPageNo, offset, totalCount } = podsData;
        this.fetchPodsList(currentPageNo, newPodsData => {
            var currentConsignmentIndex = this.state.currentConsignment;
            const currentConsignment = newPodsData.consignmentDetails.find((consignment, index) => {
                if(consignment?.consignmentId == currentConsignmentId){
                    currentConsignmentIndex = index;
                    return true;
                }
            });
            if (currentConsignment == undefined || currentConsignment == null) {
                this.props.history.push('/appv2/verification/dashboard/pod', {filterPayload: this.state.filterPayload, tags: this.state.tags});
                return;
            }
            var stateObj = {
                podsData : newPodsData,
                loading : false,
                consignmentDetailsUpdated: true,

                currentConsignment: currentConsignment,
                currentConsignmentIndex,
                currentConsignmentId
            }
            var navigatorsStatus = this.toggleNavigators(currentConsignmentIndex, offset, totalCount);
            this.setState({
                ...stateObj,
                ...navigatorsStatus
            }, this.setConsignmentDetailsUpdated);
        })
    }
    
    generateNavBlock = (currentConsignment, index) => {
        return (
            <div className="flex-column text-bold">
                <div className="font-size-header">
                    { currentConsignment?.waybillNumber } - { index + this.state.offset } of { this.state.totalCount }
                </div>
            </div>
        );
    }
    
    fetchPodsList = (pageNo, onSuccessFunc) => {
        let { filterPayload } = this.state;
        let { filter } = filterPayload;
        filterPayload = {
            ...filterPayload,
            filter: {
                ...filter,
                pageNo
            }
        };
        fetchPods(filterPayload).then(fetchPodsResponse => {
            this.setState({ filterPayload }, () => onSuccessFunc(fetchPodsResponse));
        });
    }

    pauseNav = (callback, params) => {
        Modal.confirm({
            title:'Unsaved changes detected',
            content: 'Are you sure you want to leave without saving changes made?',
            okText:'Yes',
            cancelText:'No',
            onOk: () => {
                this.disableNavigation = false;
                this.unsaveWarningOpen = false;
                callback(params);
            },
            onCancel: () => {
                this.unsaveWarningOpen = false;
            }
        })
    }
    
    navigate = (navDirection) => {
        if(this.unsaveWarningOpen || this.state.uploadInProgress) return;
        if(this.disableNavigation) {
            this.pauseNav(this.navigate, navDirection);
            this.unsaveWarningOpen = true;
            return;
        }
        this.setState({ loading:true });
        let { currentPageSize, currentPageNo, maxPage, offset, podsData, currentConsignmentIndex, currentConsignment } = this.state;
        navDirection == NAV_DIRECTION.LEFT ? currentConsignmentIndex -= 1 : currentConsignmentIndex += 1;

        const updatePodsDetails = fetchPodsResponse => {
            currentPageSize = fetchPodsResponse.currentPageSize;
            currentPageNo = fetchPodsResponse.currentPageNo;
            offset = fetchPodsResponse.offset;
            podsData = fetchPodsResponse;
        }
        
        const updateConsignmentDetails = index =>  {
            currentConsignmentIndex = index;
            currentConsignment = cloneObject(podsData.consignmentDetails[currentConsignmentIndex]);
            var navigatorsStatus = this.toggleNavigators(currentConsignmentIndex, offset, this.state.totalCount);
            this.setState({
                ...navigatorsStatus,
                currentPageSize,
                currentPageNo,
                offset,
                currentConsignmentIndex,
                currentConsignment,
                podsData,
                currentConsignmentId : currentConsignment.consignmentId,
                consignmentDetailsUpdated: true,
                loading: false,
            }, () => {
                this.setConsignmentDetailsUpdated();
            });
        }

        if(currentConsignmentIndex >= 0 && currentConsignmentIndex < podsData.consignmentDetails?.length){
            currentConsignment = cloneObject(podsData.consignmentDetails[currentConsignmentIndex])
            updateConsignmentDetails(currentConsignmentIndex);
        }
        else if(currentConsignmentIndex < 0 && currentPageNo != 1){
            this.fetchPodsList(currentPageNo - 1, fetchPodsResponse =>{
                updatePodsDetails(fetchPodsResponse);
                updateConsignmentDetails(currentPageSize - 1);
            });
        }
        else if(currentConsignmentIndex >= currentPageSize && currentPageNo != maxPage){
            this.fetchPodsList(currentPageNo + 1, fetchPodsResponse =>{
                updatePodsDetails(fetchPodsResponse);
                updateConsignmentDetails(0);
            });
        }
        else{
            this.setState({ loading:false });
        }
    }

    goBack = () => {
        if(this.disableNavigation){
            this.pauseNav(this.goBack);
            this.unsaveWarningOpen = true;
            return;
        }
        Mixpanel.track("POD Verification: Clicking Exit Button from POD View", { "podVerificationClickingExitButton": moment().valueOf() });

        var selectedCustomers = this.props?.location?.state?.selectedCustomers;
        var selectedBookingOffices = this.props?.location?.state?.selectedBookingOffices;
        var selectedDeliveryLocations = this.props?.location?.state?.selectedDeliveryLocations;
        this.props.history.push('/appv2/verification/dashboard/pod', {
            filterPayload: this.state.filterPayload, tags: this.state.tags,
            selectedCustomers, selectedBookingOffices, selectedDeliveryLocations
        });
    }

    toggleNavigation = status => {
        this.disableNavigation = status;
        this.unsaveWarningOpen = false;
    } 

    onPodUpdate = updatedPodDetails => {
        this.setState({ loading: true });
        var podIndex = -1;
        this.state.podsData.consignmentDetails.find((pod, index) => {
            if(pod.consignmentId == updatedPodDetails.consignmentId) {
                podIndex = index;
            }
        });

        fetchPods({
            filter: {
                pageSize: 10,
                pageNo: 1,
                waybillNos: [ updatedPodDetails.waybillNumber ]
            },
            orderBy: "DESC"
        }).then(fetchPodsResponse => {
            this.setState(current => {
                current.currentConsignment = fetchPodsResponse.consignmentDetails[0];
                current.podsData.consignmentDetails[podIndex] = fetchPodsResponse.consignmentDetails[0];
                current.consignmentDetailsUpdated = true;
                current.loading = false;
                return current;
            }, this.setConsignmentDetailsUpdated);
            this.toggleNavigation(false);
        });
    }

    setConsignmentDetailsUpdated() {
        setTimeout(() => {
            this.setState({
                consignmentDetailsUpdated: false
            });
        }, 50);
    }

    toggleNavigators = (currentConsignmentIndex, offset, totalCount) => {
        return {
            leftDisabled: currentConsignmentIndex + offset == 1,
            rightDisabled: currentConsignmentIndex + offset == totalCount
        }
    }

    toggleImageUploadState = state => this.setState({ uploadInProgress:  state });

    render() {
        return (
            <Spin spinning={this.state.loading} wrapperClassName={"pod-spin"}>
                {
                    !this.state.loading && 
                    <>
                        <DetailsNavigation goBack={this.goBack} navigate={this.navigate} restrictKeyboardNav={true}
                            totalCount={this.state.totalPodCount} currnetIndex={this.state.currentConsignmentIndex}
                            leftDisabled={this.state.leftDisabled || this.state.uploadInProgress} rightDisabled={this.state.rightDisabled || this.state.uploadInProgress}
                            centerBlock={this.generateNavBlock(this.state.currentConsignment, this.state.currentConsignmentIndex, this.state.podsData.consignmentDetails?.length)}/>
        
                        <PodDetails consignmentDetailsUpdated={this.state.consignmentDetailsUpdated} navigate={this.navigate}
                            consignment={this.state.currentConsignment} consignmentIndex={this.state.currentConsignmentIndex}
                            toggleNavigation={this.toggleNavigation} onPodUpdate={this.onPodUpdate} goBack={this.goBack}
                            toggleImageUploadState={this.toggleImageUploadState}
                        />
                    </>
                }
            </Spin>
        )
    }
}

export default PodDetailsPage;