import React, { useEffect, useState } from "react";
import { Alert, Input, Modal, Radio } from "antd";
import LaneRow from "./lanes/lane-row";
import { addArrayElem, removeArrayElem } from "../../common/utils";
import { fetchLaneDetails, postLanes } from "./contract.details.services";
import { notifyApiError, notifyApiSuccess } from "../../../../../common/utils/utils";
import { isLaneMappingBOsUpdated } from "./lanes/lane-utils";
import { convertToNoContractLanes } from "../../../../indent/common/services/indent";

export default function LaneDetails(props) {

	const [ loading, SetLoading] = useState(true);
	const [ lanesData, SetLanesData] = useState(false); 
	const [ laneMappingBOs, SetLaneMappingBOs] = useState(false);
	const [ invalidLane, SetInvalidLane] = useState(false);
	const [ unselectedEntitiesIndex, SetUnselectedEntitiesIndex ] = useState([]);

	const onLaneEntityCountChange = props.onLaneEntityCountChange ? props.onLaneEntityCountChange : _ => _;

	const setLaneDetailsFromResponse = lanesDataResp => {
		if (!lanesDataResp.hasOwnProperty("laneType")) {
			lanesDataResp["laneType"] = lanesDataResp?.laneMappingBOs?.length >= 1 ? lanesDataResp?.laneMappingBOs[0]?.entityType || "ZONE" : "ZONE"
		}
		SetLanesData(lanesDataResp);
		var tempLaneMappingBOs = JSON.parse(JSON.stringify(lanesDataResp.laneMappingBOs))
		if (!tempLaneMappingBOs || tempLaneMappingBOs?.length === 0) {
			onLaneEntityCountChange(0);
			SetLaneMappingBOs([ { entityBO:{}, index: 0, laneType: "ZONE" }, { entityBO:{}, index: 1, laneType: "ZONE" } ]);
		}
		else if (tempLaneMappingBOs?.length == 1) {
			if (tempLaneMappingBOs[0].index === 0) {
				tempLaneMappingBOs.push({ entityBO:{}, entityType : lanesDataResp.laneType, index: 1 })
			}
			else if (tempLaneMappingBOs?.[0].index === 1) {
				tempLaneMappingBOs.unshift({ entityBO:{}, entityType : lanesDataResp.laneType, index: 0 })
			}
			onLaneEntityCountChange(tempLaneMappingBOs.length);
			SetLaneMappingBOs(tempLaneMappingBOs);
		}
		else {
			onLaneEntityCountChange(tempLaneMappingBOs.length);
			SetLaneMappingBOs(tempLaneMappingBOs);
		}
		SetLoading(false);
	}

	useEffect(() => {
		const { contractId } = props;
		const setDefaultValues = () => {
			SetLanesData({
				name		: "",
				laneType	: props.initLaneType ? props.initLaneType : "ZONE",
				laneMappingBOs : []
			});
			SetLaneMappingBOs([ { entityBO:{}, index: 0, laneType: "ZONE" }, { entityBO:{}, index: 1, laneType: "ZONE" } ]);
			SetLoading(false);
		}
		if (contractId) {
			SetLoading(true);
			fetchLaneDetails(contractId).then(lanesDataResp => {
				if (lanesDataResp) {
					setLaneDetailsFromResponse(lanesDataResp)
				}
				else {
					setDefaultValues();
				}
			}).catch(_ => {
				setDefaultValues();
			});
		}
		else {
			setDefaultValues();
		}
	}, [ props.contractId ])

	const saveLanes = callback => {
		SetInvalidLane(false);

		var showErrorOnUnselectedEntities = false, unselectedEntities = [];
		if (!props.laneOnly && laneMappingBOs.length > 2) {
			showErrorOnUnselectedEntities = true;
			if ( props.contractDetails.serviceType == "PTL") {
				SetInvalidLane("Service type PTL cannot have transit zones/locations. Remove them and retry.");
				if (typeof callback === "function") {
					callback(false);
				}
				return;
			}
		}

		const updatedLaneMappignsBO = laneMappingBOs.reduce( (current, lane, index) => {
			lane.index = index;
			if (lane?.entityBO && lane?.entityBO?.id) {
				current.push(lane);
			}
			else if (props.laneOnly || (index != 0 && index != laneMappingBOs.length - 1 && showErrorOnUnselectedEntities)) {
				unselectedEntities.push(index);
			}
			return current;
		}, []);
		
		if (unselectedEntities.length > 0) {
			SetUnselectedEntitiesIndex(unselectedEntities);
			if (typeof callback === "function") {
				callback(false);
			}
			return;
		}
		else {
			var postObject = {
				laneBO : {
					id				: lanesData.id ? lanesData.id : undefined,
					name			: lanesData.name ? lanesData.name : undefined,
					contractId		: props.contractId,
					laneMappingBOs  : updatedLaneMappignsBO,
					laneType		: lanesData.laneType
				}
			}
			if (props.submitedForApproval && updatedLaneMappignsBO.length < 2) {
				SetInvalidLane("The lane must contain a Source and a Destination to submit the contract for approval!");
				if (typeof callback === "function") {
					callback(false);
				}
				return;
			}
			if (isLaneMappingBOsUpdated(lanesData.laneMappingBOs, updatedLaneMappignsBO)) {
				if (props.noContractLane) {
					postObject = convertToNoContractLanes(postObject);
				}
				postLanes(postObject, props.noContractLane).then(response => {
					notifyApiSuccess("Lane details successfully updated!", "Lane details", true);
					setLaneDetailsFromResponse(response.laneBO)
					if (typeof callback === "function") {
						callback(response.laneBO.laneMappingBOs, response);
					}
				}).catch(exp => exp && notifyApiError(exp))
			}
			else {
				if (typeof callback === "function") {
					callback(laneMappingBOs);
				}
			}
		}
	}
    useEffect(() => {
		if (props.saveLanes) {
			saveLanes(props.lanesSaved);
		}
    }, [props.saveLanes])

	const getUpdatedLaneMappingBO = updatedArray => {
		SetUnselectedEntitiesIndex([]);
		return updatedArray.map((lane, index) => {
			lane.index = index;
			return lane;
		});
	}

	const addEntity = index =>{
		SetInvalidLane(false);
		var updatedArray = addArrayElem(laneMappingBOs, { entityBO:{}, entityType : lanesData.laneType }, index + 1)
		SetLaneMappingBOs(getUpdatedLaneMappingBO(updatedArray));
	}

	const removeEntity = index =>{
		SetInvalidLane(false);
		var updatedArray = removeArrayElem(laneMappingBOs, index)
		SetLaneMappingBOs(getUpdatedLaneMappingBO(updatedArray));
		onLaneEntityCountChange(updatedArray.length)
	}
	
	const updateEntity = (entity, index) => {
		SetInvalidLane(false);
		SetUnselectedEntitiesIndex([]);
		SetLaneMappingBOs(current => {
			current[index] = {
				...current[index],
				entityBO   : entity,
				index	   : index
			}
			onLaneEntityCountChange(current.length);
			return current;
		})
	}
	
    return (
        <div className="flex-column flex-gap-l" id="LaneDetails">
			{
				!props.laneOnly &&
				<div className="font-size-m-3">
					<strong> Lanes </strong>
				</div>
			}
			
			{
				!loading &&
				<div className={"flex-column flex-gap-xl spacer-m bg-white" + ( props.laneOnly ? "" : " border-grey border-radius-m")} >
					<div className="flex-box flex-gap-xl align-items-center">
						<div className="flex-box flex-1 align-items-center">
							<div className="flex-1"> Lane Name <span className="color-red">*</span> </div>
							<Input className={props.laneOnly ? "flex-1" : "flex-3"}  style={{
								marginLeft : (props.laneOnly ? "-15px" : 0)
							 }} value={lanesData.name} disabled={true} placeholder={"(System generated)"} />
						</div>
						<div className="flex-1"></div>
						
					</div>
	
					{
						invalidLane &&
						<Alert message={invalidLane} type="error" />
					}
					{
						laneMappingBOs.length > 0 && 
						laneMappingBOs.map((lane, index) => {
							return (
								<div className="flex-box flex-gap-xl align-items-center" key={index}>
									<LaneRow lane={lane} source={index == 0} contractDetails={props.contractDetails}
										destination={index == laneMappingBOs.length - 1} index={index} laneOnly={props.laneOnly}
										addEntity={addEntity} removeEntity={removeEntity} readonly={props.readonly}
										unselectedEntitiesIndex={unselectedEntitiesIndex}
										selectedEntities={[]}
										onEntitySelect={updateEntity} laneType={lane.entityType ? lane.entityType : lanesData.laneType}
										contractId={props.contractId} customerId={props.customerId} customer={props?.customer} generateLaneMappingID={saveLanes}/>
								</div>
							)
						})
					}
				</div>
			}
			
        </div>
    )
}