import React, { Component } from 'react';
import './scan.scss';
import { consigmentScan, uploadBooking, uploadInscan } from './actions.js';
import { connect } from 'react-redux';
import { PageHeader, Row, Col, Spin, Button, Dropdown, Icon, Menu } from 'antd';
import DataCard from '../../shared-components/data-card/DataCard';
import { getSummary, callTpApi } from '../../../app/appAction';
import activeAwbIcon0 from './../../../assets/img/icons/images/2_1_Inbound.svg';
import activeAwbIcon1 from './../../../assets/img/icons/images/2_2_Inbound.svg';
import activeAwbIcon2 from './../../../assets/img/icons/images/2_3_Inbound.svg';
import { Constants, TpEventTypes, TpOrganisation } from '../../../common/utils/constants';
import { canAllow, notifyApiError } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import BulkOperation from '../../shared-components/bulk-operation';
import NewScanUI from './new-scan-ui';
import ScanService from '../../../services/inbound/scan.service';

class InboundScan extends Component {
  constructor(props) {
    super(props);
    const inboundTag = Constants.SELECTED_APPLICATION === 'B2B' ? permissionTags.inboundView : permissionTags.inbound;
    this.state = {
      application: Constants.SELECTED_APPLICATION,
      DataCardList: [],
      newScanUi: false,
      oldScanUI: false,
      newScanRead: false,
      bulkBookingAllowed: false,
      print_waybill: false,
      summaryloader: true,
      newBookingOptions: [{ id: 0, label: "Create Booking" }],
      fetchBookingOptions: [
        { id: 0, label: "Fetch Flipkart Booking API" },
        { id: 1, label: "Fetch Flipkart In-Scan API" }
      ],
      dataCardIcon: { activeAwbIcon0, activeAwbIcon1, activeAwbIcon2 },
      permissionList: {
        create: canAllow(inboundTag.scan.create),
        inbound: canAllow(inboundTag['newscan']['subModule']),
        barcode_scan: canAllow(inboundTag.newscan.barcode_scan),
        waybill_scan: canAllow(inboundTag.newscan.waybill_scan),
        orn_scan: canAllow(inboundTag.newscan.orn_scan),
        bulk_booking: canAllow(inboundTag.newscan.bulk_booking),
        bulk_inscan: canAllow(inboundTag.newscan.bulk_inscan),
        flipkart_inscan_api: canAllow(inboundTag.newscan.flipkart_inscan_api),
        print_waybill: canAllow(inboundTag.newscan.print_waybill),
        locationSelection: canAllow(inboundTag.newscan.locationSelection),
      },
      sampleBookingDownloadLink: `${Constants.s3BucketLink}/documents/templates/xlsx/bulk-booking-template.xlsx`,
      uploadBookingMethodParams: {
        action: "UPLOAD_BOOKING",
        consignmentType: Constants.SELECTED_APPLICATION
      },
      uploadInscanMethodParams: {
        consignmentType: Constants.SELECTED_APPLICATION
      },
      redirectBulkBookingUrl: "inbound/dashboard/scan",
      addclasstogrid: 0
    }

    if (this.state.permissionList.inbound) {
      this.state.newScanUi = true;
      this.state.newBookingOptions = [
        ...this.state.newBookingOptions,
        {
          id: 1,
          label: (<BulkOperation
            buttonLabel="Bulk Booking"
            title="Bulk Booking"
            newUIModule={this.state.newScanUi}
            bulkType="Booking"
            sampleDownloadLink={this.state.sampleBookingDownloadLink}
            errorMessage={"uploadBookingErrorMessage"}
            objKey="waybills"
            path="bulk_bookings"
            syncToServerAction="'UPLOAD_BOOKING'"
            uploadMethod={(new ScanService).uploadBooking}
            uploadMethodParams={this.state.uploadBookingMethodParams}
            redirectUrl={this.state.redirectBulkBookingUrl}
            organizationFilteredValues={Constants.organizationFilteredValues}
          />)
        }
      ]

    } else {
      this.setState(state => ({
        oldScanUI: true,
        permissionList: {
          ...state.permissionList,
          waybill_scan: true,
          barcode_scan: Constants.SELECTED_APPLICATION === 'B2B' ? true : false
        }
      }))
    }
    this.props.getSummary('INBOUND_SCAN');
    this.InputSearchRef = React.createRef();
  }


  componentDidUpdate(prevProps, prevState) {
    const { dataCardIcon } = this.state;
    const { summary } = this.props;
    if (summary !== prevProps.summary) {
      const DataCardList = [
        {
          title: 'Inventory',
          documentType: 'PENDING_CONSIGNMENT_LOC',
          description: summary['PENDING_CONSIGNMENT_LOC'].count,
          icon: dataCardIcon[`activeAwbIcon${0}`]
        },
        {
          title: 'Shortage',
          documentType: 'SHORTAGE_CONSIGNMENT_LOC',
          description: summary['SHORTAGE_CONSIGNMENT_LOC'].count,
          icon: dataCardIcon[`activeAwbIcon${1}`]
        },
        {
          title: 'Overage',
          documentType: 'OVERAGE_CONSIGNMENT_LOC',
          description: summary['OVERAGE_CONSIGNMENT_LOC'].count,
          icon: dataCardIcon[`activeAwbIcon${2}`]
        }
      ]
      this.setState({
        DataCardList,
        summaryloader: false
      })
    }
  }

  fetchBookingOperation = (id, isBookingButton = true) => {
    if (!isBookingButton) {
      if (id === 1) {
        this.props.callTpApi(
          TpOrganisation.fk,
          TpEventTypes.inscan
        );
      } else if (id === 0) {
        this.props.callTpApi(
          TpOrganisation.fk,
          TpEventTypes.booking
        );
      }
    } else {
      if (id === 0) {
        const inboundTag = this.state.application === 'B2B' ? permissionTags.inboundView : permissionTags.inbound;
        const isAllowed = inboundTag[(this.state.application === 'B2B') ? 'booking' : 'scan']?.create ? canAllow(inboundTag[(this.state.application === 'B2B') ? 'booking' : 'scan']?.create) : false
        if (isAllowed) {
          this.props.history.push('/appv2/inbound/form');
        } else {
          notifyApiError('Booking is not permitted.')
          // this.router.navigateByUrl('/inbound/form');
        }
      }
    }
  }

  getFetchBookingOptions = () => {
    const { fetchBookingOptions } = this.state;
    return (
      <Menu>
        {
          fetchBookingOptions.map((opt, i) => (
            <Menu.Item key={i} onClick={e => this.fetchBookingOperation(opt.id, false)}>
              <span>
                {opt.label}
              </span>
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  getNewBookingOptions = () => {
    const { newBookingOptions } = this.state;
    return (
      <Menu>
        {
          newBookingOptions.map((opt, i) => (
            <Menu.Item key={i} onClick={e => this.fetchBookingOperation(opt.id, true)}>
              <span>
                {opt.label}
              </span>
            </Menu.Item>
          ))
        }
      </Menu>
    )
  }

  render() {
    const { DataCardList, summaryloader, newScanUi, permissionList } = this.state
    return (
      <>
      <div className="inbound-scan">
        <PageHeader
          style={{
            padding: 0,
            paddingBottom: '24px'
          }}
          onBack={false}
          ghost
        >
          <div className='w-full'>
            <Spin spinning={summaryloader}>
            <div className='max-w-[1240px] grid lg:grid-flow-col gap-2'>
                {
                  DataCardList && DataCardList.length > 0 ? DataCardList.map((item, i) => (
                    <div className=' sm:w-60 md:w-full lg:w-full flex flex-col duration-300'>
                      <DataCard {...item} width="100%"/>
                    </div>
                    // <Col key={i} span={8} >
                    //   <DataCard {...item} />
                    // </Col>
                  )) : <></>
                }

{
                !newScanUi
                  ? <div className=" sm:w-60 md:w-full lg:w-full flex flex-col duration-300">
                    {!!permissionList.create && <Button onClick={e => this.fetchBookingOperation(0, true)} type="primary" size="large" >CREATE BOOKING</Button>}
                  </div>
                  : <div className=" sm:w-60 md:w-full lg:w-full flex flex-col md:my-3 duration-300">
                    <Dropdown overlay={this.getNewBookingOptions()} trigger={['click']} placement="bottomCenter">
                      <Button block type="primary" size="large" className='text-3xl underline font-bold bg-black-500'>Choose Booking type  <Icon type="down" /></Button>
                    </Dropdown>
                    {
                      permissionList.flipkart_inscan_api && <Dropdown overlay={this.getFetchBookingOptions()} trigger={['click']} placement="bottomCenter">
                        <Button block size="large">Choose Fetch type  <Icon type="down" /></Button>
                      </Dropdown>
                    }

                  </div>
              }
              </div>
              </Spin>
              
            
          </div>
          {/* <Row>
            <Col span={19}>
              <Spin spinning={summaryloader}>
                {
                  DataCardList && DataCardList.length > 0 ? DataCardList.map((item, i) => (
                    <Col key={i} span={8} >
                      <DataCard {...item} />
                    </Col>
                  )) : <></>
                }
              </Spin>
            </Col>
            <Col span={5} style={{ marginTop: 10 }}>
              {
                !newScanUi
                  ? <div className="old-booking-ui">
                    {!!permissionList.create && <Button onClick={e => this.fetchBookingOperation(0, true)} type="primary" size="large" >CREATE BOOKING</Button>}
                  </div>
                  : <div className="new-booking-ui">
                    <Dropdown overlay={this.getNewBookingOptions()} trigger={['click']} placement="bottomCenter">
                      <Button block type="primary" size="large">Choose Booking type  <Icon type="down" /></Button>
                    </Dropdown>
                    {
                      permissionList.flipkart_inscan_api && <Dropdown overlay={this.getFetchBookingOptions()} trigger={['click']} placement="bottomCenter">
                        <Button block size="large">Choose Fetch type  <Icon type="down" /></Button>
                      </Dropdown>
                    }

                  </div>
              }
            </Col>
          </Row> */}
        </PageHeader>
        {
          !newScanUi
            ? <NewScanUI {...this.props} {...this.state} />
            : <NewScanUI {...this.props} {...this.state} />
        }

      </div>
      </>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  summary: state.app.summary,
  config: state.app.configurations,
  scanedInfo: state.scan.scanedInfo,
  scanLoading: state.scan.loading,
  bulkOperation: state.scan.bulkOperation,
  bulkOperationLoading: state.scan.bulkOperationLoading,
  auditStatus: state.audit?.auditStatus,
});
const mapDispatchToProps = dispatch => ({
  getSummary: page => dispatch(getSummary(page)),
  consigmentScan: payload => dispatch(consigmentScan(payload)),
  uploadBooking: payload => dispatch(uploadBooking(payload)),
  uploadInscan: payload => dispatch(uploadInscan(payload)),
  callTpApi: (ev1, ev2) => dispatch(callTpApi(ev1, ev2)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InboundScan);