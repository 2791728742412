import React from 'react';
import { Tag } from 'antd';
import { capitalizeString } from "../../../../../common/utils/utils";

export const tranformStatus = status => {
    var tagClassName = "";
    if (["APPROVED", "LEAD_CREATED"].indexOf(status) != -1) {
        tagClassName = "success-tag";
    }
    else if (["APPROVAL_PENDING"].indexOf(status) != -1) {
        tagClassName = "warning-tag";
    }
    else if (["REJECTED"].indexOf(status) != -1) {
        tagClassName = "error-tag";
    }
    else if (["DRAFT"].indexOf(status) != -1) {
        tagClassName = 'primary-info-tag';
    }
    else if (["EXPIRED"].indexOf(status) != -1) {
        tagClassName = 'secondary-info-tag';
    }
    else if (["NON_SERVICEABLE"].indexOf(status) != -1) {
        tagClassName = 'tertiary-info-tag';
    }
    else if (["CANCELLED"].indexOf(status) != -1) {
        tagClassName = 'secondary-info-tag';
    }   
    else if (!status) {
        return <></>
    }
    return (
        <Tag className={"ant-table-tag text-align-center " + tagClassName}>
            {
                (status.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
            }
        </Tag>
    )
}