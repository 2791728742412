import React from "react";
import moment from "moment";
import { Icon } from "antd";
import _ from "lodash";
export function transformBreachedStatus(
  isUnknown,
  breachTime,
  slaBreachStatus
) {
  const unknownStyle = {
    color: "#000000",
    fontSize: 14,
    fontWeight: 400,
  };
  const cpdBreachedStyle = {
    color: "#CA0000",
    fontSize: 14,
    fontWeight: 400,
  };
  const breachedStyle = {
    color: "#D88913",
    fontSize: 14,
    fontWeight: 400,
  };
  if (isUnknown) {
    return <div style={unknownStyle}>On an unknown path</div>;
  } else if (slaBreachStatus === "BREACHED") {
    return (
      <div style={breachedStyle}>
        {calculateDaysAndHours(breachTime)} potential breach
      </div>
    );
  } else if (slaBreachStatus === "CPD_BREACHED") {
    return (
      <div style={cpdBreachedStyle}>
        CPD Breached (next action breached by {calculateDaysAndHours(breachTime)})
      </div>
    );
  }
}

export function calculateDaysAndHours(hours) {
  if (hours > 24) {
    return `${Math.floor(
      moment.duration(hours, "hours").asDays()
    )} ${Math.floor(moment.duration(hours, "hours").asDays()) > 1 ? 'Days': 'Day'} ${Math.floor(moment.duration(hours, "hours").hours())} hrs`;
  } else {
    return `${hours} hrs`;
  }
}

export function currentStatus(status) {
  const newStatus = _.compact(status);
  return newStatus.map((st, i) => (
    <>
      {st}
      {i < newStatus.length - 1 ? (
        <Icon
          type="arrow-right"
          style={{ position: "relative", bottom: 2, left: 5 }}
        />
      ) : (
        ""
      )}
    </>
  ));
}

export function nextAction(hours, event) {
  if (event === "TRIP_START") {
    return <div>{calculateDaysAndHours(hours)} to dispatch</div>;
  } else if (event === "MARK_ARRIVE") {
    return <div>{calculateDaysAndHours(hours)} to mark arrived</div>;
  } else if (event === "DEL") {
    return <div>{calculateDaysAndHours(hours)} to be delivered</div>;
  }
}
