import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { Constants } from '../../../common/utils/constants';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';

const partnerId = localStorage.getItem('loggedPartnerId');

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}


export const setInitialState = () => dispatch => {
    dispatch({
        type: CONSTANTS.SET_INITIAL_STATE
    })
}

export const getAllRoutes = (customerID) => dispatch => {
    dispatch(getAllRoutesBegin());
    if (customerID) {
        apiUtils.apiRequest({
            url: `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/routes?view=LITE_ROUTE_LIST&customerId=${customerID}`,
        })
            .then(response => {
                if (checkHttpStatus(response.data?.status))
                    dispatch(getAllRoutesSuccess(response.data?.viewResponse?.viewResponse))
            })
            .catch(error => dispatch(getAllRoutesFailure(error)));
    } else {
        apiUtils.apiRequest({
            url: `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/routes?view=LITE_ROUTE_LIST`,
        })
            .then(response => {
                if (checkHttpStatus(response.data?.status))
                    dispatch(getAllRoutesSuccess(response.data?.viewResponse?.viewResponse))
            })
            .catch(error => dispatch(getAllRoutesFailure(error)));
    }

}


/* This is FTL method to get assets */
export const getAllAssets = (routeId) => dispatch => {
    dispatch(getAllAssetsBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/route/${routeId}/assets/fetch?view=LITE_ASSETS_LIST`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getAllAssetsSuccess(response.data?.viewResponse?.viewResponse))
        })
        .catch(error => dispatch(getAllAssetsFailure(error)));
}
/* This is PTL method to get assets */

export const getAllSimTrackingAssets = (params) => dispatch => {
    dispatch(getAllAssetsBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/assets/trip`,
        method: 'POST',
        data: params
    })
        .then(response => {

            if (checkHttpStatus(response.data?.status))
                dispatch(getAllAssetsSuccess(response.data?.response?.tripAssets))
        })
        .catch(error => dispatch(getAllAssetsFailure(error)));
}
export const getSearchedAssets = (params) => dispatch => {
    dispatch(getAllAssetsBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/assets/search`,
        method: 'POST',
        data: params
    })
        .then(response => {

            if (checkHttpStatus(response.data?.status))
                dispatch(getAllAssetsSuccess(response.data?.response?.tripAssets))
        })
        .catch(error => dispatch(getAllAssetsFailure(error)));
}

export const getAllCustomers = () => dispatch => {
    dispatch(getAllCustomersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/customers/fetch?view=LITE_CUSTOMER_LIST`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getAllCustomersSuccess(response.data?.viewResponse?.viewResponse))
        })
        .catch(error => dispatch(getAllCustomersFailure(error)));
}



export const getShippers = (filters) => dispatch => {
    dispatch(getShippersBegin());
    apiUtils.apiRequest({
        url: `b2b/v1/customer/shipper-mapping/fetch`,
        method: 'post',
        data: filters
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getShippersSuccess(response.data?.response))
        })
        .catch(error => dispatch(getShippersFailure(error)));
}



function getShippersBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_SHIPPER_LIST_BEGIN,
        payload: null
    }
}

function getShippersSuccess(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_SHIPPER_LIST_SUCCESS,
        payload: response
    }
}

function getShippersFailure(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_SHIPPER_LIST_FAILURE,
        payload: response
    }
}


export const getCoLoaders = (partnerID) => dispatch => {
    dispatch(getCoLoaderBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/locations/coloader/${partnerID}`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getColoaderSuccess(response.data?.response?.locations))
        })
        .catch(error => dispatch(getColoaderFailure(error)));
}

export const getSimProviders = () => dispatch => {
    dispatch(getServiceProvidersBegin());
    apiUtils.apiRequest({
        url: `/b2c/v1/simtracking/providers/fetch`,
        method: 'GET'
    })
        .then(res => {            
            dispatch(getServiceProvidersSuccess(res.data))
        })
        .catch(error => dispatch(getServiceProvidersFailure(error)));
}

export const getDocketsList = (filters) => dispatch => {
    dispatch(getDocketsListBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/consignments/route/unassigned`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getDocketsListSuccess(response.data?.viewResponse))
            else {
                dispatch(getDocketsListFailure(response.data))
            }
        })
        .catch(error => dispatch(getDocketsListFailure(error)));
}

export const getCoLoaderDocketsList = (filters) => dispatch => {
    dispatch(getCoLoaderDocketsListBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/consignments/unassigned`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getCoLoaderDocketsListSuccess(response.data?.viewResponse))
            else {
                dispatch(getCoLoaderDocketsListFailure(response.data))
            }
        })
        .catch(error => dispatch(getCoLoaderDocketsListFailure(error)));
}

export const docketSummaryAllocation = (filters) => dispatch => {
    dispatch(getDocketsAllocationtBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/trip/lite/docket-allocation-summary`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getDocketsAllocationtSuccess(response.data?.response))
            else {
                dispatch(getDocketsAllocationFailure(response.data))
            }
        })
        .catch(error => dispatch(getDocketsAllocationFailure(error)));
}

export const createTripFlow = (filters) => dispatch => {
    dispatch(getCreateTripFlowBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/thc/lite/update`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status)) {
                if (response.data?.status.code == 200 || response.data?.status.code == 202) {
                    // notifyApiSuccess(response.data?.status ?.message, "Success::");
                    if (response.data?.response && response.data?.response.responses && response.data?.response.responses.length > 0) {
                        if (response.data?.response.responses[0].sync) {
                            notifyApiSuccess(response.data?.status?.message, "Success::");
                            dispatch(getCreateTripFlowSuccess(response.data?.response))
                        } else {
                            notifyApiError(response.data?.response.responses[0].reason, "ERROR:");
                            dispatch(getCreateTripFlowFailure(response.data))
                        }
                    }

                }
            }
            else {
                dispatch(getCreateTripFlowFailure(response.data))
            }
        })
        .catch(error => dispatch(getCreateTripFlowFailure(error)));
}

export const editTrip = (filters) => dispatch => {
    dispatch(getEditTripUpdateBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/trip/lite/update`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status)) {
                if (response.data?.status.code == 200 || response.data?.status.code == 202) {
                    // notifyApiSuccess(response.data?.status ?.message, "Success::");
                    notifyApiSuccess(response.data?.status?.message, "Success::");
                    dispatch(getEditTripUpdateSuccess(response.data?.response));
                }
            }
            else {
                dispatch(getEditTripUpdateFailure(response.data))
            }
        })
        .catch(error => dispatch(getEditTripUpdateFailure(error)));
}


export const getTripsList = (filters, searchCriteria = null) => dispatch => {
    dispatch(getTripListBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/trip/fetch`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            
            if (checkHttpStatus(response.data?.status)) {
                if (response.data?.viewResponse)
                    dispatch(getTripListSuccess(response.data?.viewResponse))
                else
                    dispatch(getTripListSuccess(response.data?.response))
            }
            else {
                dispatch(getTripListFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripListFailure(error)));
}

export const getLocationGroups = (filters) => dispatch => {
    dispatch(getLocationGroupsBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/location-groups/fetch `,
        method: "POST",
        data: filters
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getLocationGroupsSuccess(response.data))
            else {
                dispatch(getLocationGroupsFailure(response.data))
            }
        })
        .catch(error => dispatch(getLocationGroupsFailure(error)));
}

export const getPartnerAssets = () => dispatch => {
    dispatch(getPartnerAssetsBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/assets/fetch?view=LITE_ASSETS_LIST `,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getPartnerAssetsSuccess(response.data?.viewResponse?.viewResponse))
            else {
                dispatch(getPartnerAssetsFailure(response.data))
            }
        })
        .catch(error => dispatch(getPartnerAssetsFailure(error)));
}

export const getTripsHandover = (filters) => dispatch => {
    dispatch(getTripHandOverBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/manifestpod/handover/update`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getTripHandOverSuccess(response.data))
            else {
                dispatch(getTripHandOverFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripHandOverFailure(error)));
}

export const getTripsMarkDel = (filters) => dispatch => {
    dispatch(getTripMarkDelBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/consignmentpod/lite/update`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status)) {
                dispatch(getTripMarkDelSuccess(response.data))
                return response.data;
            }

            else {
                dispatch(getTripMarkDelFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripMarkDelFailure(error)));
}

export const getVendorsList = () => dispatch => {
    dispatch(getVendorsListBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${partnerId}/vendors?fetch=STRICT_BASIC`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getVendorsListSuccess(response.data?.response?.vendors))
            else {
                dispatch(getVendorsListFailure(response.data))
            }
        })
        .catch(error => dispatch(getVendorsListFailure(error)));
}

export const getVendorBlockReason = (id) => dispatch => {
    dispatch(getVendorBlockBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/vendors/${id}/blocked?fetchMode=STRICT_BASIC`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getVendorBlockSuccess(response.data?.response))
            else {
                dispatch(getVendorBlockFailure(response.data))
            }
        })
        .catch(error => dispatch(getVendorBlockFailure(error)));
}


export const getConsolidateWayBill = (tripID) => dispatch => {
    dispatch(getConsolidateBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/consolidatedewaybill?trip_id=${tripID}`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getConsolidateSuccess(response.data?.response))
            else {
                dispatch(getConsolidateFailure(response.data?.status?.message))
            }
        })
        .catch(error => dispatch(getConsolidateFailure(error)));
}

export const printEwayBill = (tripId) => dispatch => {
    dispatch(getPrintBegin());
    return apiUtils.apiRequest({
        url: `/b2b/v1/ewaybill/pdflinks?trip_id=${tripId}`,
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getPrintSuccess(response.data?.response))
            else {
                dispatch(getPrintFailure(response.data?.status?.message))
            }
        })
        .catch(error => dispatch(getPrintFailure(error)));
}

export const getResetConsolidate = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_CONSOLIDATE_RESET
    })
}

export const clearPrintEway = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_TRIP_PRINT_RESET
    })
}
export const getColoaderPartners = (filters) => dispatch => {
    dispatch(getPartnersListBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/mapping/fetch`,
        method: 'post',
        data: filters
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getPartnersListSuccess(response.data?.response?.partners))
            else {
                dispatch(getPartnersListFailure(response.data))
            }
        })
        .catch(error => dispatch(getPartnersListFailure(error)));
}


export const updateLead = (params) => {
    return apiUtils.apiRequest({
        url:'/b2b/v1/leads/status',
        method: 'PUT',
        data: params
    })
}

export const getTripUpStatus = (id) => {
    return apiUtils.apiRequest({
        url: `/b2b/v1/thcRouteMapping/${id}`,
    })
}

export const validateGPS = (id) => {
    return apiUtils.apiRequest({
        url: `/b2b/v1/tracker-details/validate/${id}`,
    })
}

export const cancelTrip = (id) => {
    return apiUtils.apiRequest({
        url: `/b2b/v1/trip/cancel/${id}`,
        method: 'POST'
    })
}

export const createAsset = (filters) => {
    return apiUtils.apiRequest({
        url: `/b2b/v4/partners/${partnerId}/assets`,
        method: 'POST',
        data: filters
    })
}

export async function validateIMEI(imei) {
    return apiUtils.apiRequest({
        url: `b2b/v1/tracker-details/validate/${imei}`,
    });
}



export const updateETA = (filters) => {
    return apiUtils.apiRequest({
        url: `/b2b/v1/thcRouteMapping/update-eta`,
        method: 'POST',
        data: filters
    })
}

export const getTripsStart = (filters, manifestFilters) => dispatch => {
    dispatch(getTripStartBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/thc/status`,
        data: filters,
        method: "PUT"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status)) {
                dispatch(getTripStartSuccess(response.data));
                dispatch(getTripsStartManifestGet(manifestFilters));
            }
            else {
                dispatch(getTripStartFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripStartFailure(error)));
}

export const getTripsEnd = (filters) => dispatch => {
    dispatch(getTripEndBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/thc/status`,
        data: filters,
        method: "PUT"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status)) {
                dispatch(getTripEndSuccess(response.data));
            }
            else {
                dispatch(getTripEndFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripEndFailure(error)));
}

export const getTripsStartManifestGet = (filters) => dispatch => {
    dispatch(getTripStartManifestBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/reports/MANIFEST_SHEET_LITE/get`,
        data: filters,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getTripStartManifestSuccess(response.data))
            else {
                dispatch(getTripStartManifestFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripStartManifestFailure(error)));
}

export const fetchTripSettlements = (payload) => dispatch => {
    dispatch(fetchTripSettlementsBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/fetch/fetchTripSettlements`,
        data: payload,
        method: "POST"
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(fetchTripSettlementsSuccess(response.data.response))
            else {
                dispatch(fetchTripSettlementsFailure(response.data.response))
            }
        })
        .catch(error => dispatch(fetchTripSettlementsFailure(error)));
}


export const getTripRateCard = (filters) => dispatch => {
    dispatch(getTripRateCardBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/rateCard/getTripRates`,
        method: 'post',
        data: filters
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getTripRateCardSuccess(response.data?.response))
            else {
                dispatch(getPartnersListFailure(response.data))
            }
        })
        .catch(error => dispatch(getTripRateCardFailure(error)));
}


function getTripRateCardBegin() {
    return {
        type: CONSTANTS.GET_TRIP_RATE_CARD_BEGIN,
        payload: null
    }
}

function getTripRateCardSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_RATE_CARD_SUCCESS,
        payload: response
    }
}

function getTripRateCardFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_RATE_CARD_FAILURE,
        payload: response
    }
}





export const docketloadingFalse = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_DOCKET_LIST_LOADING_FALSE
    })
}

export const docketloadingTrue = () => dispatch => {
    dispatch({
        type: CONSTANTS.GET_DOCKET_LIST_LOADING_TRUE
    })
}


function getAllRoutesBegin() {
    return {
        type: CONSTANTS.GET_ROUTES_LIST_BEGIN,
        payload: null
    }
}

function getAllRoutesSuccess(response) {
    return {
        type: CONSTANTS.GET_ROUTES_LIST_SUCCESS,
        payload: response
    }
}

function getAllRoutesFailure(response) {
    return {
        type: CONSTANTS.GET_ROUTES_LIST_FAILURE,
        payload: response
    }
}


function getAllAssetsBegin() {
    return {
        type: CONSTANTS.GET_ASSETS_LIST_BEGIN,
        payload: null
    }
}

function getAllAssetsSuccess(response) {
    return {
        type: CONSTANTS.GET_ASSETS_LIST_SUCCESS,
        payload: response
    }
}

function getAllAssetsFailure(response) {
    return {
        type: CONSTANTS.GET_ASSETS_LIST_FAILURE,
        payload: response
    }
}

function getAllCustomersBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_BEGIN,
        payload: null
    }
}

function getAllCustomersSuccess(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_SUCCESS,
        payload: response
    }
}

function getAllCustomersFailure(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_FAILURE,
        payload: response
    }
}


function getCoLoaderBegin() {
    return {
        type: CONSTANTS.GET_COLOADER_LIST_BEGIN,
        payload: null
    }
}

function getColoaderSuccess(response) {
    return {
        type: CONSTANTS.GET_COLOADER_LIST_SUCCESS,
        payload: response
    }
}

function getColoaderFailure(response) {
    return {
        type: CONSTANTS.GET_COLOADER_LIST_FAILURE,
        payload: response
    }
}

function getServiceProvidersBegin() {
    return {
        type: CONSTANTS.GET_SIMPROVIDERS_LIST_BEGIN,
        payload: null
    }
}

function getServiceProvidersSuccess(response) {
    return {
        type: CONSTANTS.GET_SIMPROVIDERS_LIST_SUCCESS,
        payload: response
    }
}

function getServiceProvidersFailure(response) {
    return {
        type: CONSTANTS.GET_SIMPROVIDERS_LIST_FAILURE,
        payload: response
    }
}

function getDocketsListBegin() {
    return {
        type: CONSTANTS.GET_DOCKETS_LIST_BEGIN,
        payload: null
    }
}

function getDocketsListSuccess(response) {
    return {
        type: CONSTANTS.GET_DOCKETS_LIST_SUCCESS,
        payload: response
    }
}

function getDocketsListFailure(response) {
    return {
        type: CONSTANTS.GET_DOCKETS_LIST_FAILURE,
        payload: response
    }
}

function getCoLoaderDocketsListBegin() {
    return {
        type: CONSTANTS.GET_CO_LOADER_DOCKETS_LIST_BEGIN,
        payload: null
    }
}

function getCoLoaderDocketsListSuccess(response) {
    return {
        type: CONSTANTS.GET_CO_LOADER_DOCKETS_LIST_SUCCESS,
        payload: response
    }
}

function getCoLoaderDocketsListFailure(response) {
    return {
        type: CONSTANTS.GET_CO_LOADER_DOCKETS_LIST_FAILURE,
        payload: response
    }
}

function getDocketsAllocationtBegin() {
    return {
        type: CONSTANTS.GET_DOCKETS_SUMMARY_BEGIN,
        payload: null
    }
}

function getDocketsAllocationtSuccess(response) {
    return {
        type: CONSTANTS.GET_DOCKETS_SUMMARY_SUCCESS,
        payload: response
    }
}

function getDocketsAllocationFailure(response) {
    return {
        type: CONSTANTS.GET_DOCKETS_SUMMARY_FAILURE,
        payload: response
    }
}

function getCreateTripFlowBegin() {
    return {
        type: CONSTANTS.GET_TRIP_CREATE_BEGIN,
        payload: null
    }
}

function getCreateTripFlowSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_CREATE_SUCCESS,
        payload: response
    }
}

function getCreateTripFlowFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_CREATE_FAILURE,
        payload: response
    }
}


function getTripListBegin() {
    return {
        type: CONSTANTS.GET_TRIP_LIST_BEGIN,
        payload: null
    }
}

function getTripListSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_LIST_SUCCESS,
        payload: response
    }
}

function getTripListFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_LIST_FAILURE,
        payload: response
    }
}


function getTripHandOverBegin() {
    return {
        type: CONSTANTS.GET_TRIP_HANDOVER_BEGIN,
        payload: null
    }
}

function getTripHandOverSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_HANDOVER_SUCCESS,
        payload: response
    }
}

function getTripHandOverFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_HANDOVER_FAILURE,
        payload: response
    }
}


function getTripMarkDelBegin() {
    return {
        type: CONSTANTS.GET_TRIP_MARKDEL_BEGIN,
        payload: null
    }
}

function getTripMarkDelSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_MARKDEL_SUCCESS,
        payload: response
    }
}

function getTripMarkDelFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_MARKDEL_FAILURE,
        payload: response
    }
}

function getTripStartBegin() {
    return {
        type: CONSTANTS.GET_TRIP_START_BEGIN,
        payload: null
    }
}

function getTripStartSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_START_SUCCESS,
        payload: response
    }
}

function getTripStartFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_START_FAILURE,
        payload: response
    }
}

function getTripStartManifestBegin() {
    return {
        type: CONSTANTS.GET_TRIP_START_MANIFEST_BEGIN,
        payload: null
    }
}

function getTripStartManifestSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_START_MANIFEST_SUCCESS,
        payload: response
    }
}

function getTripStartManifestFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_START_MANIFEST_FAILURE,
        payload: response
    }
}

function fetchTripSettlementsBegin() {
    return {
        type: CONSTANTS.FETCH_TRIP_SETTLEMENTS_BEGIN,
        payload: null
    }
}

function fetchTripSettlementsSuccess(response) {
    return {
        type: CONSTANTS.FETCH_TRIP_SETTLEMENTS_SUCCESS,
        payload: response
    }
}

function fetchTripSettlementsFailure(response) {
    return {
        type: CONSTANTS.FETCH_TRIP_SETTLEMENTS_FAILURE,
        payload: response
    }
}

function getTripEndBegin() {
    return {
        type: CONSTANTS.GET_TRIP_END_BEGIN,
        payload: null
    }
}

function getTripEndSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_END_SUCCESS,
        payload: response
    }
}

function getTripEndFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_END_FAILURE,
        payload: response
    }
}

function getVendorsListBegin() {
    return {
        type: CONSTANTS.GET_VENDORS_LIST_BEGIN,
        payload: null
    }
}

function getVendorsListSuccess(response) {
    return {
        type: CONSTANTS.GET_VENDORS_LIST_SUCCESS,
        payload: response
    }
}

function getVendorsListFailure(response) {
    return {
        type: CONSTANTS.GET_VENDORS_LIST_FAILURE,
        payload: response
    }
}

function getVendorBlockBegin() {
    return {
        type: CONSTANTS.GET_VENDOR_BLOCKED_REASON_BEGIN,
        payload: null
    }
}

function getVendorBlockSuccess(response) {
    return {
        type: CONSTANTS.GET_VENDOR_BLOCKED_REASON_SUCCESS,
        payload: response
    }
}

function getVendorBlockFailure(response) {
    return {
        type: CONSTANTS.GET_VENDOR_BLOCKED_REASON_FAILURE,
        payload: response
    }
}

function getPartnersListBegin() {
    return {
        type: CONSTANTS.GET_COLOADER_PARTNERS_LIST_BEGIN,
        payload: null
    }
}

function getPartnersListSuccess(response) {
    return {
        type: CONSTANTS.GET_COLOADER_PARTNERS_LIST_SUCCESS,
        payload: response
    }
}

function getPartnersListFailure(response) {
    return {
        type: CONSTANTS.GET_COLOADER_PARTNERS_LIST_FAILURE,
        payload: response
    }
}

function getLocationGroupsBegin() {
    return {
        type: CONSTANTS.GET_LOCATION_GROUPS_BEGIN,
        payload: null
    }
}

function getLocationGroupsSuccess(response) {
    return {
        type: CONSTANTS.GET_LOCATION_GROUPS_SUCCESS,
        payload: response
    }
}

function getLocationGroupsFailure(response) {
    return {
        type: CONSTANTS.GET_LOCATION_GROUPS_FAILURE,
        payload: response
    }
}

function getPartnerAssetsBegin() {
    return {
        type: CONSTANTS.GET_PARTNER_ASSETS_BEGIN,
        payload: null
    }
}

function getPartnerAssetsSuccess(response) {
    return {
        type: CONSTANTS.GET_PARTNER_ASSETS_SUCCESS,
        payload: response
    }
}

function getPartnerAssetsFailure(response) {
    return {
        type: CONSTANTS.GET_PARTNER_ASSETS_FAILURE,
        payload: response
    }
}

function getEditTripUpdateBegin() {
    return {
        type: CONSTANTS.GET_EDIT_TRIP_UPDATE_BEGIN,
        payload: null
    }
}

function getEditTripUpdateSuccess(response) {
    return {
        type: CONSTANTS.GET_EDIT_TRIP_UPDATE_SUCCESS,
        payload: response
    }
}

function getEditTripUpdateFailure(response) {
    return {
        type: CONSTANTS.GET_EDIT_TRIP_UPDATE_FAILURE,
        payload: response
    }
}


function getConsolidateBegin() {
    return {
        type: CONSTANTS.GET_TRIP_CONSOLIDATE_BEGIN,
        payload: null
    }
}

function getConsolidateSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_CONSOLIDATE_SUCCESS,
        payload: response
    }
}

function getConsolidateFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_CONSOLIDATE_FAILURE,
        payload: response
    }
}

function getPrintBegin() {
    return {
        type: CONSTANTS.GET_TRIP_PRINT_BEGIN,
        payload: null
    }
}

function getPrintSuccess(response) {
    return {
        type: CONSTANTS.GET_TRIP_PRINT_SUCCESS,
        payload: response
    }
}

function getPrintFailure(response) {
    return {
        type: CONSTANTS.GET_TRIP_PRINT_FAILURE,
        payload: response
    }
}

