import { Button } from "antd";
import React, { useState } from "react";
import { fetchHistory } from "../../../common/services";
import ViewHistory from "../../../contarct-history";

export default function ContractsHistory (props) {
    
    const [showHistory, setShowHistory] = useState(false);
    const [history, setHistory] = useState([]);
    const [showRejectHistory, changeRejectHistory] = useState(false);

    const getHistory = (rejected) => {
        const params = {
            "customerId": props.customerId,
            "rejectedEventsToggle": rejected,
            "module": "CONTRACT",
            "contractIds": [props.contractDetails.id]
        }
        fetchHistory(params).then(response => {
            if (response.contractsHistoryList && response.contractsHistoryList.length > 0) {
                setShowHistory(true);
                setHistory(response.contractsHistoryList);
            } else {
                setShowHistory(true);
                setHistory([]);
            }
        })
    }

    return (
        <>
            <Button onClick={() => getHistory(false) } style={{margin: 0}}>View History</Button>
            {
                showHistory &&
                <ViewHistory
                    history={history}
                    showHistoryModal={showHistory}
                    cancelHistory={() => {
                        changeRejectHistory(false);
                        setShowHistory(false)
                        setHistory([]);

                    }}
                    showRejectHistory={showRejectHistory}
                    changeRejectHistory={(checked) => {
                        changeRejectHistory(checked);
                        getHistory(checked);
                    }}
                />
            }
        </>
    )
}