
export const initialState = {
    loading: false
}

export const FETCH_EWAY_BEGIN = "FETCH_EWAY_BEGIN";
export const FETCH_EWAY_SUCCESS = "FETCH_EWAY_SUCCESS";
export const FETCH_EWAY_FAILURE = "FETCH_EWAY_FAILURE";

export const FETCH_SEARCH_EWAY_BEGIN = "FETCH_SEARCH_EWAY_BEGIN";
export const FETCH_SEARCH_EWAY_SUCCESS = "FETCH_SEARCH_EWAY_SUCCESS";
export const FETCH_SEARCH_EWAY_FAILURE = "FETCH_SEARCH_EWAY_FAILURE";
