import * as _Constants from "./constants";
import { notifyApiError } from '../../../common/utils/utils';
import PrintOperationService from '../../../services/print-operation/print-operation.service';

const PrintHistoryService = new PrintOperationService;

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

function fetchPrintHistoryBegin() {
    return {
        type: _Constants.FETCH_PRINT_HISTORY_BEGIN
    };
}

function fetchPrintHistorySuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_PRINT_HISTORY_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchPrintHistoryFailure(error) {
    return {
        type: _Constants.FETCH_PRINT_HISTORY_FAILURE,
        payload: error
    };
}
export const fetchPrintHistory = (payload, isReset) => dispatch => {
    dispatch(fetchPrintHistoryBegin());
    PrintHistoryService.getPrinthistory(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchPrintHistorySuccess(res?.data?.response, isReset));
        } else {
            dispatch(fetchPrintHistoryFailure(res?.data?.response));
        }
    });
};

export const printHistoryByWaybill = (payload) => dispatch => {
    return PrintHistoryService.printHistoryByWaybill(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            return res;
        } else {
            return res;
        }
    });
};

export const getPrinthistoryById = (payload) => dispatch => {
    return PrintHistoryService.getPrinthistoryById(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            return res;
        } else {
            return res;
        }
    });
};