import { apiRequest } from "../../../common/utils/apiGateway";
import { Constants } from "../../../common/utils/constants";

import { checkHttpStatus, notifyApiError } from "../../../common/utils/utils";

export const getSLADocketFetch = (payload) => {
  return apiRequest({
    method: "GET",
    url: `/b2b/v1/sla-breach/docket/${payload.waybillNo}`,
  }).then((response) => {
    if (checkHttpStatus(response?.data?.status, false)) {
      return response.data.response;
      //   resolve(response?.data?.response);
    } else {
      return {};
      //   reject(response);
    }
  });
};

export const getSLAListing = (data) => {
  return apiRequest({
    method: "POST",
    url: `/b2b/v1/sla-breach/docket/fetch-all`,
    data,
  }).then((response) => {
    if (checkHttpStatus(response?.data?.status, false)) {
      return response.data.response;
      //   resolve(response?.data?.response);
    } else {
      return {};
      //   reject(response);
    }
  });
};

export const getSLASummary = (data) => {
  return apiRequest({
    method: "POST",
    url: `/b2b/v1/sla-breach/summary`,
    data,
  }).then((response) => {
    if (checkHttpStatus(response?.data?.status, false)) {
      return response.data.response;
      //   resolve(response?.data?.response);
    } else {
      return {};
      //   reject(response);
    }
  });
};

export const getSLACustomers = () => {
  const data = {
    filters: {
      customerTypes: ["CUSTOMER"],
      status: ["ACTIVE"],
      fetchMode: "BASIC",
      partnerIds: [Constants.PARTNER_ID],
    },
  };
  return apiRequest({
    method: "POST",
    url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`,
    data,
  }).then((response) => {
    if (checkHttpStatus(response?.data?.status, false)) {
      return response.data.response.customers;
      //   resolve(response?.data?.response);
    } else {
      return {};
      //   reject(response);
    }
  });
};

export const getSLALocations = () => {
  const data = {
    filters: {
      customerTypes: ["CUSTOMER"],
      status: ["ACTIVE"],
      fetchMode: "BASIC",
      partnerIds: [Constants.PARTNER_ID],
    },
  };
  return apiRequest({
    method: "GET",
    url: `/b2b/v2/partners/${Constants.PARTNER_ID}/location-with-partner-entity`,    
  }).then((response) => {
    if (checkHttpStatus(response?.data?.status, false)) {
      
      return response.data.response.locations;
      //   resolve(response?.data?.response);
    } else {
      return {};
      //   reject(response);
    }
  });
};

export const downloadSlAReport = (data) => {
 
  return apiRequest({
    method: "POST",
    url: `/b2b/v1/reports/SLA_BREACH_PRE_EMPTIVE_ALERT_REPORT/get`,  
    data  
  }).then((response) => {
    if (checkHttpStatus(response?.data?.status, false)) {
      
      return response.data.response;
      //   resolve(response?.data?.response);
    } else {
      return {};
      //   reject(response);
    }
  });
};
