import React from 'react';
import IndentFormContainer from '../../common/components/indent.form.container';

export default function IndentCreatePage (props) {

   const goBackToListing = _ => {
      props.history.push("/appv2/indent/dashboard")
   }

   return <IndentFormContainer {...props} indentId={props.location?.state?.indentId}
             onCancel={goBackToListing}
             onIndentCreateSuccess={goBackToListing} />
}