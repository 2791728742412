import {
  Button,
  Icon,
  Modal,
  Divider,
  Alert,
  Checkbox,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import FormWrapper from "../../../ui/formComponent/form";
import AdhocIndent from "./indent.adhoc";
import DestinationLoads from "./destination.loads";

export default function IndentForm(props) {
  const {
    schema,
    onChange,
    onSubmit,
    onLoadsUpadte,
    loading,
    noIndentLoadsError,
    createAnother,
    SetCreateAnother,
    saving,
    onError,
    validate,
  } = props;
  const formData = useSelector((state) => state.indentCreateReducer.indentData);
  const indentLoadEditOpen = useSelector(
    (state) => state.indentCreateReducer.indentLoadEdit
  );
  const header = useSelector((state) => state.header);

  const transformErrors = (errors) => {
    errors = errors.map((error) => {
      if (
        error.name === "type" ||
        error.name === "enum" ||
        error.name === "minItems"
      ) {
        error.message = undefined;
      }
      return error;
    });
    return errors;
  };

  return (
    <>
      <div className="flex-column spacer-m white-bg">
        {!props.hideHeader && (
          <>
            <div className="flex-gap-l flex-box align-items-center">
              <Icon
                type="arrow-left"
                className="cursor-pointer"
                onClick={(_) => {
                  if (props.readonly) {
                    props.history.push("/appv2/indent/dashboard");
                  } else {
                    Modal.confirm({
                      title: "Are you sure to cancel indent creation?",
                      onOk: (_) => {
                        Modal.destroyAll();
                        props.history.push("/appv2/indent/dashboard");
                      },
                    });
                  }
                }}
              />
              <span className="font-size-l-1"> Create indent </span>
            </div>
            <Divider />
          </>
        )}
        {noIndentLoadsError && (
          <Alert
            type={"error"}
            showIcon
            message={
              <div>
                <div>
                  At least one of the following columns to be filled to submit
                  the indent,
                </div>
                <ul>
                  <li> Expected Weight </li>
                  <li> Expected Quantity </li>
                  <li> Expected Volume </li>
                </ul>
              </div>
            }
          />
        )}
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <div className="flex-column flex-gap-l spacer-vl">
            <FormWrapper
              {...schema}
              id="IndentForm"
              hideSubmit={true}
              onChange={onChange}
              ref={props.formRef}
              bordered={true}
              readonly={props.readonly}
              fieldsPerRow={3}
              formData={formData}
              onSubmit={onSubmit}
              transformErrors={transformErrors}
              onError={onError}
              validate={validate}
            />
            <AdhocIndent
              {...props}
              readonly={props.readonly}
              hubsData={header.locationHeaderDetails}
            />
            <DestinationLoads
              {...props}
              loadsUpdated={onLoadsUpadte}
              readonly={props.readonly}
            />

            {!props.readonly && (
              <div className="flex-box justify-content-end align-items-center flex-gap-m">
                {!formData.id && (
                  <Checkbox
                    onChange={(e) => SetCreateAnother(e.target.checked)}
                    checked={createAnother}
                  >
                    {" "}
                    Create another indent
                  </Checkbox>
                )}
                <Button
                  className="secondary-btn"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          {" "}
                          {props.indentId
                            ? "Are you sure to cancel edit? Changes made will be lost."
                            : "Are you sure to cancel indent creation?"}{" "}
                        </>
                      ),
                      onOk: () => {
                        Modal.destroyAll();
                        props.cancelIndentCreation();
                      },
                    });
                  }}
                >
                  {" "}
                  {props.indentId
                    ? "Cancel Edit"
                    : "Cancel Indent creation"}{" "}
                </Button>
                {(!formData.status || formData.status === "DRAFT") && (
                  <Button
                    loading={saving}
                    className="secondary-btn"
                    onClick={(_) => props.saveIndent(false)}
                    disabled={indentLoadEditOpen || saving}
                  >
                    Save Draft
                  </Button>
                )}
                <Button
                  loading={saving}
                  className="lsn-btn-primary"
                  onClick={(_) => props.saveIndent(true)}
                  disabled={indentLoadEditOpen || saving}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
