import { Spin, Divider, Icon, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { fetchContracts } from "../../common/services";

import ContractBasicDetails from "./basic/contract.basic.detail";
import LaneDetails from "./lane.details";
import BillingTermsDetails from "./billingterms/billing.terms";
import AccessorialDetails from "./accessorial/accessorial.charges";
import FuelCharges from "./fuel/fuel.charges";
import OtherCharges from "./additional/other.charges";

import { fuelStatusTransformer } from "./fuel/fuel.services";
import { postContractInfoSave, postDiscardContract, sendContractForApproval } from "./contract.services";
import { canAllow, notifyApiSuccess, notifyCopyEvent } from "../../../../../common/utils/utils";
import { permissionTags } from "../../../../../configs/permissionTags";
import { s3Connections } from "../../../../../environments/node-react-environment";
import { contractStatusToTag, convertSubmitErrors, getUserContractApprovalLevel, headerError } from "../../common/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import DetailsActions from "./actions/details.actions";
import ApprovalActions from "./actions/approval.actions";
import ContractsHistory from "./history/contract.history";
import PTLCharges from "./charges/ptl.charges";
import FTLCharges from "./charges/ftl.charges";

function ContractDetails(props) {

    const [contractDetails, SetContractDetails] = useState({});
    const [loading, SetLoading] = useState(true);
    const [saving, SetSaving] = useState(false);
    const [submitedForApproval, SetSubmitedForApproval] = useState(false);

    const [cloning, SetCloning] = useState(false);

    const [saveContractHeader, SetSaveContractHeader] = useState(false);
    const [laneEntityCount, SetLaneEntityCount] = useState(2);

    const [retrieveBillingTerms, SetRetrieveBillingTerms] = useState(false);
    const [retrieveAccesorialCharges, SetRetrieveAccesorialCharges] = useState(false);
    const [retrieveOtherCharges, SetRetrieveOtherCharges] = useState(false);

    const [saveLanes, SetSaveLanes] = useState(false);
    const [saveFuelCharges, SetSaveFuelCharges] = useState(false);

    const [submitErrors, SetSubmitErrors] = useState(false);

    const [saveError, SetSaveError] = useState(false);
    const [restrictSubmitPost, SetRestrictSubmitPost] = useState(false);
    const [headerDetailsUpdated, SetHeaderDetailsUpdated] = useState(false);

    const [freightBasis, SetFreightBasis] = useState(false);

    const userContractApprovalLevel = getUserContractApprovalLevel();

    useEffect(() => {
        if ( !canAllow([ permissionTags.contract.contractdetails.view,
                         permissionTags.contract.contractdetails.create,
                         permissionTags.contract.contractdetails.update], 
                         false, true )) {
            goBackToListing(true)
        }
    }, [])
    useEffect(() => {

        if (!props.match?.params?.customerId) return;
        const { contractId, customerId } = props.match?.params;
        var name = customerId;
        if (props.location.state) {
            name = props.location.state;
            SetCloning(props.location.state.clone)
        }
        if (customerId && contractId) {
            SetLoading(true);
            fetchContracts({
                filter: {
                    ids         : [contractId],
                    customerIds : [customerId],
                    pageNo      : 1,
                    pageSize    : 10
                }
            }).then(response => {
                if (response && response.contracts.length >= 1) {
                    SetContractDetails(response.contracts[0]);
                    SetFreightBasis(response.contracts[0].freightBasis)
                    SetLoading(false);
                }
                else {
                    SetLoading(false);
                    if (props.verificationView) {
                        props.fetchContractFail()
                    }
                    else {
                        props.history.push(`/appv2/settings/dashboard/customer/details/${customerId}/contract`, { name })
                    }
                }
            });
        }
        else {
            SetLoading(false);
        }
    }, [props.match?.params?.contractId, props.match?.params?.customerId]);

    const refreshPageWithUpdatedDetails = (contractId) => {
        props.history.push(`/appv2/settings/dashboard/customer/contracts/${props.match?.params?.customerId}/${contractId}`, { ...props.location?.state, view: false, clone: false })
    }

    const  goBackToListing = skipConfirmation => {
        if (skipConfirmation || readonly) {
            props.history.push(`/appv2/settings/dashboard/customer/details/${props.match?.params?.customerId}/contract`, { ...props.location?.state, currentStep : props.location?.state?.readOnly === false ? 4 : undefined,  backFromDetails: true })
        }
        else if ((contractDetails.id || headerDetailsUpdated)) {
            Modal.confirm({
                width : 450,
                title : <div> Are you sure to cancel? </div>,
                onOk  : _ => {
                    Modal.destroyAll();
                    props.history.push(`/appv2/settings/dashboard/customer/details/${props.match?.params?.customerId}/contract`, { ...props.location?.state, currentStep : props.location?.state?.readOnly === false ? 4 : undefined,  backFromDetails: true })
                }
            })
        }
        else {
            props.history.push(`/appv2/settings/dashboard/customer/details/${props.match?.params?.customerId}/contract`, { ...props.location?.state, currentStep : props.location?.state?.readOnly === false ? 4 : undefined,  backFromDetails: true })
        }
    }

    const getBillingTerms = (params, errors) => {
        if (errors) {
            SetSaveError(true);
        }
        else {
            contractDetails.contractBillingBO = params;
            SetContractDetails(contractDetails);
        }
        SetRetrieveBillingTerms(false);
        SetRetrieveAccesorialCharges(true);
    }

    const getAccessorialCharges = (params, errors) => {
        if (errors) {
            SetSaveError(true || saveError);
        }
        else {
            contractDetails.accessorialCharges = {
                ...params,
                ...params.rov
            };
            if (laneEntityCount <= 2) {
                delete contractDetails.accessorialCharges.dropPointCharges;
            }
            delete contractDetails.accessorialCharges.rov;
            SetContractDetails(contractDetails);
        }
        SetRetrieveAccesorialCharges(false);
        SetRetrieveOtherCharges(true);
    }

    const getOtherCharges = (params, errors) => {
        SetRetrieveOtherCharges(false);
        if (errors || saveError) {
            SetSaveLanes(true);
            SetSaveError(true);
        }
        else {
            SetContractDetails({
                ...contractDetails,
                insuranceTerms: params.insuranceTerms,
                deps: params.deps,
                remarks: params.remarks,
            })
            setTimeout(() => {
                const infoPostObject = {
                    contractBO: {
                        id: contractDetails.id,
                        contractBillingBO: {
                            ...contractDetails.contractBillingBO,
                            billingRequirements: contractDetails?.contractBillingBO?.billingRequirements ? contractDetails.contractBillingBO.billingRequirements.join(',') : ""
                        },

                        accessorialChargesBO: {
                            ...contractDetails.accessorialCharges,
                            ...contractDetails.accessorialCharges.rov
                        },

                        insuranceTerms: params.insuranceTerms,
                        deps: params.deps,
                        remarks: params.remarks
                    }
                }
                postContractInfoSave(infoPostObject)
                .then(_ => {
                    SetSaveLanes(true);
                    notifyApiSuccess("Contract details, Billing terms, Accessorial charges and Other charges updated successfully!", contractDetails?.name, true);
                })
                .catch(e => {
                    SetSaving(false);
                    SetSubmitedForApproval(false);
                });
            }, 100);
        }
    }

    const onServiceTypeChange = serviceType => {
        SetContractDetails({
            ...contractDetails,
            serviceType: serviceType
        })
    }

    const saveDraftContract = submit => {
        Modal.destroyAll();
        if ( freightBasis !== undefined && freightBasis != "" && 
             (contractDetails.serviceType === "FTL" && freightBasis !== "PER_INDENT") ||
             (contractDetails.serviceType === "PTL" && freightBasis === "PER_INDENT") ) {
                window.scrollTo({ top: 0, behavior: "smooth" })
                SetSubmitedForApproval(false);
                SetSaveError(true);
        }
        else {
            SetSubmitedForApproval(submit);
            SetSaving(true);
            SetSaveError(false);
            SetRestrictSubmitPost(false);
            SetSubmitErrors(false);
            SetSaveContractHeader(true);
        }
    }

    const onDetailsUpdate = (errorSave, errorSubmit) => {
        if (errorSubmit) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            SetSaving(false);
            return;
        }
        else if (!submitedForApproval) {
            if (props.verificationView) {
                props.onDetailsUpdate();
            }
            else {
                refreshPageWithUpdatedDetails(contractDetails.id);
            }
        }
        else if (errorSave) {
            SetSubmitedForApproval(false);
            SetSaving(false);
        }
        else {
            sendContractForApproval(contractDetails.id)
            .then(response => {
                if (response.submitSuccess) {
                    if (props.verificationView) {
                        props.onDetailsUpdate();
                    }
                    else {
                        goBackToListing(true);
                    }
                }
                else {
                    window.scrollTo({ top: 0, behavior: "smooth" })
                    if (errorSave) {
                        SetSubmitErrors({
                            LANES : response.dataValidations["LANES"]
                        });
                    }
                    else {
                        SetSubmitErrors(response.dataValidations);
                    }
                    SetSubmitedForApproval(false);
                    SetSaving(false);
                }
            })
            .catch()
        }
    }

    const discardContract = _ => {
        Modal.destroyAll();
        postDiscardContract(contractDetails.id)
            .then(_ => {
                goBackToListing(true);
            })
            .catch()
    }

    const approveContract = response => {
        if (response.approveRejectSuccess) {
            props.approveSuccess(response);
        }
        else {
            SetSubmitErrors(response.dataValidations);
        }
    }

    const readonly = props.location?.state?.view;
    
    return (
        <div className="spacer-m contract-management" style={{ minHeight: "calc(100vh - 100px)" }}>
            {
                loading ?
                    <Spin spinning={loading} className="flex-box justify-content-center" /> :
                    <Spin spinning={saving} className="flex-box justify-content-center" >
                        <div className="flex-column spacer-l border-grey bg-white">
                            { headerError(submitErrors, props.verificationView, saveError, contractDetails.serviceType, freightBasis) }
                            { convertSubmitErrors(submitErrors, "RATE_CARD_DUPLICATION_CHECK") }
                            <div className="flex-box flex-gap-l align-items-center justify-content-space-between">
                                <div className="flex-box flex-gap-l align-items-center">
                                    {
                                        !props.verificationView &&
                                        <>
                                            <div className="cursor-pointer" onClick={_ => goBackToListing(false)}>
                                                <Icon type="arrow-left" />
                                            </div>
                                            <div className="font-size-l-1 flex-1"> {
                                                readonly ? "View Contract" :
                                                props.match?.params?.contractId ?
                                                (cloning ? "Copy Contract" : "Edit Contract")
                                                : "Add Contract"
                                            } </div>
                                        </>
                                    }
                                </div>
                                <div className="flex-box flex-gap-l align-items-center">
                                    {
                                        !props.verificationView && !readonly &&
                                        <div className="flex-box justify-content-flex-end">
                                            <Tag className="cursor-pointer" color="#87d068" onClick={() => window.open(`https://${s3Connections.default.bucketName}.s3.${s3Connections.default.region}.amazonaws.com/documents/templates/pdf/contract-create-guide.pdf`, '_blank').focus()}><Icon type="question-circle" /> Help</Tag>
                                        </div>
                                    }
                                    {
                                        contractDetails.id &&
                                        <div className="flex-box justify-content-flex-end align-items-center flex-gap-l">  
                                            {
                                                props.verificationView && canAllow(permissionTags.verification.contract.approve) &&
                                                <>
                                                    {
                                                        userContractApprovalLevel.indexOf(contractDetails.approvalLevel + 1) > -1 &&
                                                        ["SUBMITTED_FOR_APPROVAL", "APPROVAL_IN_PROCESS"].indexOf(contractDetails.status) > -1 ?
                                                        <ApprovalActions approveSuccess={approveContract} saveDraftContract={saveDraftContract}
                                                        cancelEdit={props.cancelEdit} editContract={props.editContract} rejectContract={props.rejectContract}
                                                        readonly={readonly} submitErrors={submitErrors} contractDetails={contractDetails} />: 
                                                        contractStatusToTag(contractDetails.status)
                                                    }
                                                </>
                                            }                                    
                                            <ContractsHistory contractDetails={contractDetails} customerId={props.match?.params?.customerId}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <Divider />
                            <ContractBasicDetails contractData={contractDetails} loading={loading} readonly={readonly}
                                saveContractHeader={saveContractHeader} onServiceTypeChange={onServiceTypeChange}
                                customerId={props.match?.params?.customerId} cancelCreate={_ => goBackToListing(false)} cloning={cloning}
                                submitedForApproval={submitedForApproval} SetHeaderDetailsUpdated={SetHeaderDetailsUpdated}
                                onContractCreate={contractID => {
                                    SetSaveContractHeader(false);
                                    if (contractID) {
                                        if (contractDetails.id == contractID) {
                                            SetRetrieveBillingTerms(true);
                                        }
                                        else {
                                            refreshPageWithUpdatedDetails(contractID);
                                        }
                                    }
                                    else {
                                        window.scrollTo({ top: 0, behavior: "smooth" })
                                        SetSaving(false);
                                        SetSubmitedForApproval(false);
                                    }
                                }} headerDetailsUpdated={headerDetailsUpdated} />
                            {
                                (readonly || (contractDetails.id && contractDetails.status !== "APPROVED" && !cloning)) &&
                                <>
                                    <Divider />
                                    { convertSubmitErrors(submitErrors, "LANES") }
                                    <LaneDetails contractDetails={contractDetails} contractId={contractDetails.id}
                                        lanesSaved={sync => {
                                            SetSaveLanes(false);
                                            SetSaveFuelCharges(true);
                                            if (!sync) {
                                                SetSaveError(true);
                                                SetRestrictSubmitPost(true);
                                            }
                                        }} submitedForApproval={submitedForApproval} onLaneEntityCountChange={count => SetLaneEntityCount(count)}
                                        readonly={readonly} customerId={props.match?.params?.customerId} customer={props.location?.state?.customer} saveLanes={saveLanes} />
                                    <Divider />
                                    { convertSubmitErrors(submitErrors, "BILLING_TERMS") }
                                    <BillingTermsDetails contractData={contractDetails} loading={loading} readonly={readonly}
                                        retrieveBillingTerms={retrieveBillingTerms} getBillingTerms={getBillingTerms} submitedForApproval={submitedForApproval} />
                                    <Divider />

                                    { convertSubmitErrors(submitErrors, "FREIGHT_CHARGES") }
                                    {
                                        contractDetails.serviceType === "PTL" ?
                                        <PTLCharges contractData={contractDetails} readonly={readonly} /> :
                                        contractDetails.serviceType === "FTL" ?
                                        <FTLCharges contractData={contractDetails} readonly={readonly} /> :
                                        <></>
                                    }
                                    <Divider />

                                    { convertSubmitErrors(submitErrors, "ACCESSORIAL_CHARGES") }
                                    <AccessorialDetails contractData={contractDetails} loading={loading} readonly={readonly} submitedForApproval={submitedForApproval}
                                        retrieveAccesorialCharges={retrieveAccesorialCharges} getAccessorialCharges={getAccessorialCharges} laneEntityCount={laneEntityCount} />
                                    <Divider />

                                    { convertSubmitErrors(submitErrors, "FUEL_CLAUSE") }
                                    <div className="flex-box align-items-center flex-gap-l">
                                        <div className="font-size-m-3"> <strong> Fuel Clause </strong> </div>
                                        <div> {
                                            contractDetails.fuelClausesBO?.fuelStatus &&
                                            fuelStatusTransformer(contractDetails.fuelClausesBO?.fuelStatus)
                                        } </div>
                                    </div>
                                    {
                                        (contractDetails.fuelClausesBO?.fuelStatus !== "NOT_AVAILABLE" || !readonly) &&
                                        <FuelCharges contractData={contractDetails} loading={loading} readonly={readonly}
                                            fuelChargesSaved={sync => {
                                                SetSaveError(!sync || saveError);
                                                SetRestrictSubmitPost(!sync || restrictSubmitPost)
                                                SetSaveFuelCharges(false);
                                                onDetailsUpdate(!sync || saveError, !sync || restrictSubmitPost);
                                            }}
                                            contractId={contractDetails.id} saveFuelCharges={saveFuelCharges} submitedForApproval={submitedForApproval} />
                                    }
                                    <Divider />

                                    { convertSubmitErrors(submitErrors, "OTHER_DETAILS") }
                                    <OtherCharges contractData={contractDetails} loading={loading} readonly={readonly} submitedForApproval={submitedForApproval}
                                        retrieveOtherCharges={retrieveOtherCharges} getOtherCharges={getOtherCharges} />
                                    <Divider />

                                    {
                                        props.verificationView && contractDetails.requestingUserBO &&
                                        <div className="flex-box flex-gap-l">
                                            <div className="flex-box flex-gap-m">
                                                <div> Requester Name: </div>
                                                <div> <strong> {contractDetails.requestingUserBO.name} </strong> </div>
                                            </div>
                                            <div className="flex-box flex-gap-m">
                                                <div> Mobile Number: </div>
                                                <div> 
                                                    <strong> {contractDetails.requestingUserBO.contactNumber} </strong>
                                                    <CopyToClipboard text={contractDetails.requestingUserBO.contactNumber}
                                                        onCopy={() => notifyCopyEvent("Mobile Number")}>
                                                        <Icon type="copy" />
                                                    </CopyToClipboard>    
                                                </div>
                                            </div>
                                            <div className="flex-box flex-gap-m">
                                                <div> E-Mail ID: </div>
                                                <div> 
                                                    <strong> {contractDetails.requestingUserBO.email} </strong>
                                                    <CopyToClipboard text={contractDetails.requestingUserBO.email}
                                                        onCopy={() => notifyCopyEvent("E-Mail ID")}>
                                                        <Icon type="copy" />
                                                    </CopyToClipboard>    
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        !readonly && !props.verificationView &&
                                        <DetailsActions discardContract={discardContract} saveDraftContract={saveDraftContract}
                                         goBackToListing={goBackToListing} contractDetails={contractDetails} />
                                    }
                                </>
                            }
                        </div>
                    </Spin>
            }
        </div>
    )
}

export default ContractDetails;