import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Icon,
    Table,
} from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { openNotification } from "../ui/notification/Notification";
import { formatDate, formatTime } from "../../common/utils/utils";
import { fetchManifestEvents } from "./action";

const ManifestTrackingPopUp = ({manifestNo, manifestEvents, fetchManifestEvents, stateData }) => {
    const events = manifestEvents?.events || [];
    const loading = manifestEvents?.loading || false;

    const columns = [
        {
            title: "DATE",
            dataIndex: "eventTime",
            render: (text) => formatDate(text)
        },
        {
            title: "TIME",
            dataIndex: "evTime",
            render: (text, record) => formatTime(record.eventTime)
        },
        {
            title: "EVENT",
            dataIndex: "eventType",
        },
        {
            title: "LOCATION",
            dataIndex: "location",
        },
        {
            title: "USER",
            dataIndex: "user",
        },
        {
            title: "ACTION",
            dataIndex: "action",
        },
    ]

    useEffect(() => {
        fetchManifestEvents(manifestNo);
        // fetchManifests();
    }, [manifestNo]);

    const notifyCopyEvent = () => {
        return openNotification({
            message: "copied",
            description: "Manifest no. has been copied to clipboard",
            className: "warning",
        });
    }
    return(
        <div className="TrackingPopup">
            <div className="header">Manifest No : <span>{manifestNo}</span>
                <CopyToClipboard text={manifestNo}
                    onCopy={notifyCopyEvent}>
                    <Icon type="copy" className="copy-icon" />
                </CopyToClipboard>
            </div>
            <Table 
                loading={loading}
                columns={columns}
                dataSource={events}
                bordered={true}
                pagination={false}
            />
        </div>
    )
};

const mapStateToProps = (state, props) => ({
    manifestEvents: state.tracking.manifestEvents,
    stateData: state.tracking
});

const mapDispatchToProps = dispatch => ({
    fetchManifestEvents: (manifestNo) => dispatch(fetchManifestEvents(manifestNo))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManifestTrackingPopUp);