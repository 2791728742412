const schema = {
    "type": "object",
    "required": ["billingCycle", "creditPeriod"],
    "title"   : "Billing Terms",
    "properties": {
        "billingCycle": {
            "type"       : "string",
            "title"      : "Billing Cycle",
            "enum"       : [ "DAILY", "WEEKLY", "BI_WEEKLY", "MONTHLY"],
            "enumNames"  : [ "Daily", "Weekly", "Bi-weekly", "Monthly"]
        },
        "creditPeriod": {
            "type"  : "string",
            "title" : "Credit Period",
            "enum"  : [ "0 days", "15 days", "30 days", "45 days", "60 days", "75 days", "90 days"]
        },
        "billingRequirements": {
            "type"      : "array",
            "title"     : "Billing Requirement",
            "items"     : {
                "type"      : "string",
                "enum"      : [ "Customer Invoice",  "E-POD",  "H-POD" ]
            },
            "uniqueItems"   : true,
            
        },
        "billingRemarks": {
            "type": "string",
            "title": "Remarks"
        }
    }
}

const uiSchema = {
    "classNames": "multi-row-field-objects",
    "billingCycle" : {
        "ui:widget": "select",
    },
    "billingRequirements": {
        "ui:widget"  : "checkboxes",
        "ui:options" : {
            "inline" : true
        }
    }
}

const errorList = {
    billingCycle    : {
        required    : "Billing Cycle cannot be empty!"
    },
    creditPeriod    : {
        required    : "Credit Period cannot be empty!"
    },
    billingRequirements    : {
        required           : "Billing Requirement cannot be empty!"
    }
}

export default { schema, uiSchema, errorList };