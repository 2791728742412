import React from "react";
import "./sla-listing.scss";
const SLASummary = ({ summary, onSummaryClick, breahcedStatus }) => {
  
  const summaryEnums = {
    breachedCount: "Potential SLA BREACH",
    atRiskCount: "AT RISK OF BREACH",
    onTimeCount: "GOING ON TIME",
  };
  const summaryClasses = {
    breachedCount: "BREACHED",
    atRiskCount: "AT_RISK",
    onTimeCount: "ON_TIME",
  };
  return (
    <div className="flex-box flex-gap-l justify-content-space-between sla-breahced_listing ">
      {Object.keys(summary).map((sum, key) => (
        <div
          className={`flex-box flex-gap-l justify-content-center align-items-center sla-breahced_listing-box ${
            breahcedStatus === summaryClasses[sum]
              ? `sla-breahced_listing-box-${breahcedStatus.toLowerCase()}`
              : ""
          } cursor-pointer`}
          key={key}
          onClick={() =>
            breahcedStatus != summaryClasses[sum] && onSummaryClick(sum)
          }
        >
          <div className={`sla-breahced_listing-value`}>
            {summaryEnums[sum]}
          </div>
          <div
            className={`sla-breahced_listing-value-${summaryClasses[
              sum
            ].toLowerCase()}`}
          >
            {summary[sum]} {summary[sum] > 1 ? "Dockets" : "Docket"}
          </div>
        </div>
      ))}
    </div>
  );
};
export default SLASummary;
