import { Constants } from '../../../../../common/utils/constants';
import { IndentListingConstants as _Constants } from './indent.listing.constants';
import { fetchIndents, fetchLovs, indentCopy } from "./indent.listing.services";


export const fetchIndentListing = (payload) => dispatch => {
    dispatch({
        type: _Constants.FETCH_INDENTS_LISTING_BEGIN
    });
    fetchIndents(payload).then(res => {
        if (res) {
            dispatch({
                type: _Constants.FETCH_INDENTS_LISTING_SUCCESS,
                payload: res
            });
        } else {
            dispatch({
                type: _Constants.FETCH_INDENTS_LISTING_FAILURE,
                payload: "Something went wrong"
            });
        }
    });
};

export const fetchLov = (payload) => dispatch => {
    dispatch({
        type: _Constants.FETCH_LOVS_BEGIN
    });
    fetchLovs(payload).then(res => {
        if (res) {
            dispatch({
                type: _Constants.FETCH_LOVS_SUCCESS,
                payload: res
            });
        } else {
            dispatch({
                type: _Constants.FETCH_LOVS_FAILURE,
                payload: "Something went wrong"
            });
        }
    });
};

export const copyIndent = (id) => dispatch => {
    dispatch({
        type: _Constants.SET_INDENT_COPY_BEGIN
    });
    indentCopy(id).then(res => {        
        if (res) {
            dispatch({
                type: _Constants.SET_INDENT_COPY_SUCCESS,
                payload: res
            });
        } else {
            dispatch({
                type: _Constants.SET_INDENT_COPY_FAILURE,
                payload: "Something went wrong"
            });
        }
    });
};