import { fetchLOVs } from "../../../../common/lovStore/lovService";
import { apiRequest } from "../../../../common/utils/apiGateway";
import {
  checkHttpStatus,
  notifyApiError,
} from "../../../../common/utils/utils";
import {
  fetchCustomerOptions,
  fetchLaneOptions,
  fetchLocationOptions,
  fetchShipperOptions,
} from "../../../../services/common/fetch.services";

const getParams = (value, customerId) => {
  return {
    isActive: true,
    entityIds: customerId ? [customerId] : [],
    partialContentSearch: value,
  };
};

const invalidSearchPhrase = (searchText) => {
  if (!/^[a-zA-Z0-9 _.-]*$/.test(searchText.trim())) {
    notifyApiError("Invalid search phrase entered!");
    return true;
  }
  return false;
};

export const searchCustomers = (value) => {
  if (value.trim() === "" || invalidSearchPhrase(value)) {
    return new Promise((resolve) => resolve([]));
  }
  return fetchCustomerOptions(getParams(value), true);
};

export const searchShipper = (customerId, searchNameOnly) => (value) => {
  if (value.trim() === "" || invalidSearchPhrase(value)) {
    return new Promise((resolve) => resolve([]));
  }
  return fetchShipperOptions({
    ...getParams(value, customerId),
    searchNameOnly,
  });
};

export const searchLocation = (customerId, searchNameOnly) => (value) => {
  if (value.trim() === "" || invalidSearchPhrase(value)) {
    return new Promise((resolve) => resolve([]));
  }
  return fetchLocationOptions({
    ...getParams(value, customerId),
    searchNameOnly,
  });
};

export const searchLanes = (customerId, shipperId, vehicleType) =>
  fetchLaneOptions({
    filters: {
      customerIds: [customerId],
      shipperIds: [shipperId],
      vehicleTypeIds: [vehicleType],
    },
  });

export const getServiceTypesLOVs = (_) =>
  new Promise((resolve, reject) => {
    fetchLOVs("ServiceType")
      .then((resp) => {
        var enums = [];
        var enumNames = [];
        resp?.lovs.map((lov) => {
          enums.push(lov.lovCode);
          enumNames.push(lov.lovValue);
        });
        resolve([enums, enumNames, resp?.constants]);
      })
      .catch((e) => {
        notifyApiError(e.toString());
        reject(e);
      });
  });

export const getIndentCancelReasons = () =>
  new Promise((resolve, reject) => {
    apiRequest({
      method: "POST",
      url: `/b2c/v2/reasons/categories/fetch`,
      data: {
        categoryFilters: {
          types: ["INDENT_CANCEL"],
          isActive: true,
          fetchObjects: ["PARTNER_REASONS"],
        },
      },
    }).then((response) => {
      if (checkHttpStatus(response?.data?.status)) {
        const { reasonCategories } = response.data.response;
        if (!reasonCategories) {
          reject(response.data);
        } else if (reasonCategories.length > 0) {
          resolve(reasonCategories[0].reasons);
        }
      } else {
        reject(response.data);
      }
    });
  });

export const getIndentRejectReasons = () =>
  new Promise((resolve, reject) => {
    apiRequest({
      method: "POST",
      url: `/b2c/v2/reasons/categories/fetch`,
      data: {
        categoryFilters: {
          types: ["INDENT_REJECT"],
          isActive: true,
          fetchObjects: ["PARTNER_REASONS"],
        },
      },
    }).then((response) => {
      if (checkHttpStatus(response?.data?.status)) {
        const { reasonCategories } = response.data.response;
        if (!reasonCategories) {
          reject(response.data);
        } else if (reasonCategories.length > 0) {
          resolve(reasonCategories[0].reasons);
        }
      } else {
        reject(response.data);
      }
    });
  });

export const submitExpiredReasons = (data) =>
  new Promise((resolve, reject) => {
    apiRequest({
      method: "POST",
      url: `/b2b/v1/indent/update-reasons`,
      data,
    }).then((response) => {
      if (checkHttpStatus(response?.data?.status)) {
        console.log("response", response.data.response);
        resolve(response.data.response);
      } else {
        reject(response.data);
      }
    //     const { reasonCategories } = response.data.response;
    //     if (!reasonCategories) {
    //       reject(response.data);
    //     } else if (reasonCategories.length > 0) {
    //       resolve(reasonCategories[0].reasons);
    //     }
    //   } else {
    //     reject(response.data);
    //   }
    });
  });
