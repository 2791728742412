export const manifestColumns = [
    {
        title: "Bag ID",
        dataIndex: "bagId",
        className: "long-column"
    },
    {
        title: "AWB",
        dataIndex: "waybillNo",
        className: "long-column"
    },
    {
        title: "Seller Name",
        dataIndex: "shipper.name"
    },
    {
        title: "Seller Address",
        dataIndex: "shipper.address.address1",
        render : (data, row) => {
            let address = row.shipper.address.address1;
            if (shipper.address.address2) {
                address += ', ' + shipper.address.address2;
            }
            if (shipper.address.address3) {
                address += ', ' + shipper.address.address3;
            }
            if (shipper.address.landmark) {
                address += ', ' + shipper.address.landmark;
            }
            return (
                <>  { address } </>
            )
        }
    },
    {
        title: "Seller State",
        dataIndex: "shipper.pincode.city.state",
        className: "short-column"
    },
    {
        title: "Seller GSTIN",
        dataIndex: "shipper.gst"
    },
    {
        title: "Consignee Name",
        dataIndex: "consignee.name",
        className: "short-column"
    },
    {
        title: "Consignee Address",
        dataIndex: "consignee.address.address1",
        render : (data, row) => {
            let address = row.consignee.address.address1;
            if (consignee.address.address2) {
                address += ', ' + consignee.address.address2;
            }
            if (consignee.address.address3) {
                address += ', ' + consignee.address.address3;
            }
            if (consignee.address.landmark) {
                address += ', ' + consignee.address.landmark;
            }
            return (
                <>  { address } </>
            )
        }
    },
    {
        title: "Consignee State",
        dataIndex: "consignee.pincode.city.state",
        className: "short-column"
    },
    {
        title: "Invoice No.",
        dataIndex: "orderRefNo"
    },
    {
        title: "Invoice Value",
        dataIndex: "consignmentAmount",
        className: "short-column"
    },
    {
        title: "Order Value",
        dataIndex: "attributes.ORDER_VALUE",
        className: "short-column"
    },
    {
        title: "Actual Amount",
        dataIndex: "payableAmount",
        className: "short-column"
    },
    {
        title: "CGST",
        dataIndex: "attributes.CGST",
        className: "short-column"
    },
    {
        title: "SGST",
        dataIndex: "attributes.SGST",
        className: "short-column"
    },
    {
        title: "IGST",
        dataIndex: "attributes.IGST",
        className: "short-column"
    },
    {
        title: "GST Total Tax",
        dataIndex: "attributes.TOTAL_TAX",
        className: "short-column"
    },
    {
        title: "Item Description",
        dataIndex: "description"
    }
]