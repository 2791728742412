const schema = {
    "type"        : "object",
    "properties"  : {
        "cancelReasonsObj" : {
            "required"    : [ "cancelReasons" ],
            "type"        : "object",
            "title"       : "Select Cancel Reason",
            "properties": {
                "cancelReasons": {
                    "type"          : "string",
                    "searchEnabled" : true,
                    "multiple"      : false
                }
            }
        },
        "remarksObj" : {
            "type"       : "object",
            "properties": {
                "remarks": {
                    "type"          : "string"
                }
            }
        }
    }
}

const uiSchema = {
    "cancelReasonsObj"    : {
        "cancelReasons": {
            "classNames"          : "hide-title flex-1",
            "ui:placeholder"      : "Select Cancel Reason"
        }
    },
    "remarksObj" : {
        "classNames"      : "hide-title",
        "remarks"         : {
            "classNames"  : "hide-title flex-1",
            "ui:widget"   : "TextareaWidget",
            "placeholder" : "Additional Remarks/Instructions",
            "rows"        : 3,
            "resize"      : false,
            "maxLength"   : 254
        }
    }
}

const errorList = {
    "cancelReasonsObj"    : {
        "cancelReasons"   : {
            "required": "Cancel Reasons are mandatory!"
        }
    }
}

export default { schema, uiSchema, errorList };