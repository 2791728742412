import React, { PureComponent } from 'react'
import { canAllow } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';

import EwayDashboard from "./parta/ewayDashboard";
import PartB from "./partb/partb";


class EwayModule extends PureComponent {

    constructor(props) {
        super(props)
    }
    state = {
       
        ewayPermissions: {
            partARead: canAllow(permissionTags.ewaybill.ewaybill.read),
            partBRead: canAllow(permissionTags.ewaybill.partb.read),
        }
    }
    render() {

        if(this.state.ewayPermissions.partARead) {
            return <EwayDashboard {...this.props} />
        } else if (this.state.ewayPermissions.partBRead) {
            return <PartB {...this.props} />
        }
        

        return (
            <></>
        )
    }
}

export default EwayModule;