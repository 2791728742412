import { Button, Icon, Tag, Tooltip } from "antd";
import moment from "moment";
import { canAllow, capitalizeString } from "../../../../../common/utils/utils";
import { Mixpanel } from "../../../../../utils/mixpanel";
import React from "react";
import { permissionTags } from "../../../../../configs/permissionTags";
import { contractStatusToTag } from "../../common/utils";

const formatValidity = date => (moment(Number(date)).format('DD/MM/YYYY'));

export default function getContractsColumns(contractEvents) {
    return [
        {
            title: 'Contract Code',
            dataIndex: '',
            key: 'id',
            fixed: 'left',
            width: 120,
            render: (data, row, index) => (
                <a type="link" className="visited-link" onClick={() => {
                    Mixpanel.track("Clicking Contract Code in Contracts Tab", { 'CustomerContractCodeClicked': moment().valueOf() });
                    contractEvents.showContractDetails(data, index)
                }}>
                    { data?.code ? data?.code : data?.id}
                </a>
            ),
            shouldShow: true
        },
        {
            title: 'Contract Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            shouldShow: true
        },
        {
            title: 'Shipper',
            dataIndex: 'shipperBO.shipperName',
            key: 'shipper',
            shouldShow: true
        },
        {
            title: 'Service Type',
            dataIndex: 'serviceType',
            key: 'service',
            shouldShow: true
        },
        {
            title: 'Fuel Status',
            dataIndex: 'fuelClausesBO.fuelStatus',
            key: 'fuel',
            shouldShow: contractEvents?.flowType !== "VERIFICATION",
            render: data => data ? (data.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ") : "Not Available"
        },

        {
            title: 'Contract Type',
            dataIndex: 'isOneTimeContract',
            key: 'isOneTimeContract',
            shouldShow: contractEvents?.flowType === "VERIFICATION",
            render: data => data ? 'One Time' : "Standard"
        },
        {
            title: 'Lane Name',
            dataIndex: 'laneBO',
            key: 'laneBO',
            shouldShow: contractEvents?.flowType === "VERIFICATION",
            render: data => !!data ? data.name : "--",
        },
        {
            title: 'Billing Cycle',
            dataIndex: 'contractBillingBO',
            key: 'contractBillingBO',
            shouldShow: contractEvents?.flowType === "VERIFICATION",
            render: data => !!data?.billingCycle ? data?.billingCycle : "--"
        },
        {
            title: 'Validity',
            dataIndex: 'validity',
            key: 'validity',
            render: (data, row) => (
                <>
                    { row?.validFrom ? formatValidity(row?.validFrom) : ""}
                    -
                    { row?.validTo ? formatValidity(row?.validTo) : ""}
                </>
            ),
            shouldShow: true
        },
        {
            title: 'Ageing',
            dataIndex: 'ageing',
            key: 'ageing',
            width: 120,
            shouldShow: contractEvents?.flowType === "VERIFICATION",
            render: (data) => data ? <>{data} Hours</>: "--"
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            shouldShow: true,
            width: contractEvents?.flowType === "VERIFICATION" ? 100 : undefined,
            fixed: contractEvents?.flowType === "VERIFICATION" ? "right" : undefined,
            render: contractStatusToTag
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 100,
            shouldShow: contractEvents?.flowType !== "VERIFICATION",
            render: data =>
                <div className="flex-box align-items-center justify-content-end flex-gap-l">
                    {
                        canAllow(permissionTags.contract.contractdetails.create) &&
                        <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
                            <Tooltip placement="top" title={"Copy"}>
                                <Icon type="copy" onClick={() => {
                                    Mixpanel.track("Clicking clone contracrt button in Contract Detailed Tab", { 'CustomerContractCloneClicked': moment().valueOf() });
                                    contractEvents.editContractDetails(data, true)
                                }} />
                            </Tooltip>
                        </div>
                    }
                    {
                        data.isActive && data.status !== "APPROVAL_IN_PROCESS" && canAllow(permissionTags.contract.contractdetails.update) &&
                        <div className="spacer-xs flex-box dark-grey-bg border-radius-s">
                            <Tooltip placement="top" title={data.isActive ? "Edit" : undefined}>
                                <Icon type="edit" onClick={() => {
                                    Mixpanel.track("Clicking Edit button in Contract Detailed Tab", { 'CustomerContractEditClicked': moment().valueOf() });
                                    contractEvents.editContractDetails(data, false)
                                }} />
                            </Tooltip>
                        </div>
                    }
                </div>
        },
    ].filter(i => !!i.shouldShow)
}