import React, { Component } from 'react';
import { Card } from 'antd';

class DataCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { summaryData } = this.props;

        return (
            <Card>
                <div className='flex-box justify-content-space-evenly'>
                {
                    summaryData && summaryData?.length > 0 &&
                    summaryData?.map((data, index) => (

                        <div className="docket-sparkline" key={index}>

                            <div className='title'>{data.title}</div>

                            <div className="highlight">{data.value}</div>
                        </div>

                    ))
                }
                </div>
            </Card >
        )
    }
}

export default DataCard;