import React, { Component } from 'react';
import './customer-management.scss';
import { Modal, Form, Input, Button, Icon, Select, Divider, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import GooglePlaceAutoComplete from '../../../../ui/google-place-autocomplete/google.place-autocomplete';
import { Constants, validationRules } from '../../../../../common/utils/constants';
import { validateServiceable, createCustomers, updateCustomers } from './actions';
import { notifyApiError } from '../../../../../common/utils/utils';

class RetailerFormModal extends Component {
    state = {
        isEdit: false,
        loading: false,
        selectedAddress: '',
        ValidationRules: {
            DistinationLocation: {
                validateStatus: "success",
            }
        },
        destinationAddress: undefined,
    }

    componentDidUpdate(oldProps) {
        if (this.props.serviceableResp && oldProps.serviceableResp !== this.props.serviceableResp) {
            if (this.props.serviceableResp.data['isServiceable']) {
                this.props.form.setFieldsValue({
                    DistinationLocation: this.props.serviceableResp.data
                })
            } else {
                notifyApiError('Currently selected pincode is not serviceable.');
            }
        }

        if (this.props.defaultData && oldProps.defaultData !== this.props.defaultData) {
            const { customer } = this.props.defaultData;
            // consignee.pincodeBO = customer?.destinationAddress?.pincodeBO;
            this.props.form.setFieldsValue({
                CustomerName: customer.name,
                PhoneNumber: customer.phoneNumber,
                ContactPerson: customer.contactPerson,
                email: customer.emailId,
                DistinationLocation: customer?.destinationAddress
            })
            this.setState({
                isEdit: true,
                selectedAddress: customer.retailerSelectedAddress
            })
        }

        if (this.props.customerCreationResp && oldProps.customerCreationResp !== this.props.customerCreationResp) {
            console.log(this.props.customerCreationResp);
            this.props.form.resetFields();
            this.setState({
                selectedAddress: ''
            });
            this.props.onSubmitRetailer({ ...this.props.customerCreationResp.data?.customer, retailerSelectedAddress: this.state.selectedAddress, destinationAddress: this.state.destinationAddress });
        }
    }

    handleCancel = e => {
        this.setState({
            ValidationRules: {
                DistinationLocation: {
                    validateStatus: "success",
                }
            },
            selectedAddress: ''
        })
        this.props.form.resetFields();
        this.props.onCanceled()
    }

    handleOk = e => {
        this.props.onCanceled()
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!values.DistinationLocation) {
                this.setState({
                    ValidationRules: {
                        DistinationLocation: {
                            validateStatus: "error",
                            help: "Please input your destination location!"
                        }
                    }
                })
            } else {
                this.setState({
                    ValidationRules: {
                        DistinationLocation: {
                            validateStatus: "success",
                        }
                    }
                })
            }
            if (!err) {
                this.createRetailerCustomer(values);
                // this.props.onSubmit(values)
            }
        });
    }

    createRetailerCustomer = val => {
        /* "pincode": {
                            "id": 32770,
                            "city": {
                                "id": 1
                            }
                        }˝ */
        const payload = {
            "customerName": val.CustomerName,
            "code": val.CustomerName.replace(/ /g, '_'),
            "contactNumber": val.PhoneNumber,
            "contactPerson": val.ContactPerson,
            "email": val.email,
            "paymentTypes": [
                "PREPAID",
                "TOPAY"
            ],
            "customerType": "ADOHC_CUSTOMER",
            "locations": [
                {
                    "id": val.DistinationLocation.id,
                    "name": val.CustomerName,
                    "roles": [
                        "PICKUP",
                        "DELIVERY",
                        "HEAD_OFFICE",
                        "BOOKING_OFFICE",
                        "BILLING"
                    ],
                    "address": {
                        "name": val.CustomerName,
                        "address1": this.state.selectedAddress,
                        "address2": "",
                        "landmark": "",
                        "pincode": val.DistinationLocation.pincodeBO
                    },
                    "serviableAreaMapping": [
                        {
                            "fromEntityId": val.DistinationLocation.pincodeBO.id,
                            "fromEntityType": "PINCODE",
                            "toEntityId": val.DistinationLocation.pincodeBO.id,
                            "toEntityType": "PINCODE",
                            "partnerId": Constants.PARTNER_ID,
                            "userId": Constants.sessionUser.id
                        }
                    ]
                }
            ]
        }

        this.setState({destinationAddress: val.DistinationLocation})

        if (this.state.isEdit) {
            payload.id = this.props.defaultData.customer.id;
            // delete payload.locations;
            this.props.updateCustomers(payload, this.props.defaultData.customer.id);
        } else {
            this.props.createCustomers(payload);
        }

    }

    getPincode(pincode, address) {
        this.setState({
            selectedAddress: address
        })
        const loggedLocId = Constants.LOGGED_PARTNER_LOCATION_ID
        const payload = {
            locationServiceableRequests: [
                {
                    pincode: {
                        zipcode: pincode
                    },
                    location: {
                        id: loggedLocId
                    },
                    referenceId: Date.now()
                }
            ]
        }
        this.props.validateServiceable(payload);
    }

    render() {
        const { loading, searchResult } = this.state;
        const { isVisible } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (

            <Modal
                visible={isVisible}
                className="customer-management"
                title="New Retail Customer"
                onOk={this.handleOk}
                closable={false}
                maskClosable={false}
                onCancel={this.handleCancel}
                footer={null}
            >
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item label="Customer Name">
                        {getFieldDecorator('CustomerName', {
                            rules: [{ required: true, message: 'Please input your Customer Name!' }],
                        })(
                            <Input
                                placeholder="Enter Customer Name"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Phone Number">
                        {getFieldDecorator('PhoneNumber', {
                            rules: [
                                { required: true, type: "string", len: 10, message: "Please input valid phone number" }
                            ],
                        })(
                            <Input addonBefore="+91" type="number" placeholder="Enter 10 digit number" maxLength="10" />
                        )}
                    </Form.Item>
                    {/* <Form.Item label="Origin Location">
                        {getFieldDecorator('OriginLocation', {
                            initialValue: 'lucy',
                            rules: [{ required: true, message: 'Please input your Origin Location!' }],
                        })(
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a Origin Location"
                            >
                                <Select.Option value="jack">Jack</Select.Option>
                                <Select.Option value="lucy">Lucy</Select.Option>
                                <Select.Option value="tom">Tom</Select.Option>
                            </Select>,
                        )}
                    </Form.Item> */}
                    <Form.Item
                        label="Destination Location"
                        validateStatus={this.state.ValidationRules.DistinationLocation.validateStatus}
                        help={this.state.ValidationRules.DistinationLocation.help}
                    >
                        {getFieldDecorator('DistinationLocation', {
                            rules: [{ required: true, message: 'Please input your destination location!' }],
                        })(
                            <GooglePlaceAutoComplete
                                onSelection={(pincode, address) => this.getPincode(pincode, address)}
                                onChange={value => this.setState({
                                    selectedAddress: value, ValidationRules: {
                                        DistinationLocation: {
                                            validateStatus: "success",
                                        }
                                    }
                                })}
                                selectedAddress={this.state.selectedAddress}
                            ></GooglePlaceAutoComplete>
                        )}
                    </Form.Item>
                    <Form.Item label="Contact Person">
                        {getFieldDecorator('ContactPerson', {
                            rules: [{ required: false, message: 'Please input your Contact Person!' }],
                        })(
                            <Input
                                placeholder="Contact Person"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="E-mail (Optional)">
                        {getFieldDecorator('email', {
                            rules: [
                                { required: false, pattern: new RegExp(validationRules.email), message: 'Please input a valid email!' }
                            ],
                        })(
                            <Input
                                placeholder="email"
                            />,
                        )}
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        <Button className="pull-right" key="submit" type="primary" htmlType="submit" loading={loading}>
                            Add Customer
                        </Button>
                        <Button className="pull-right mr-10" key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    customerCreationResp: state.RetailerCustomer.customerCreation,
    serviceableResp: state.RetailerCustomer.validServiceables,
});

const mapDispatchToProps = dispatch => ({
    createCustomers: (payload) => dispatch(createCustomers(payload)),
    validateServiceable: (payload) => dispatch(validateServiceable(payload)),
    updateCustomers: (payload, id) => dispatch(updateCustomers(payload, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({
    name: 'RetailerCustomerForm',
    onFieldsChange: (props, newChanges, allFields) => {
        // console.log(allFields);
    },
    onValuesChange: (props, newChanges, allFields) => {
        // console.log(allFields);
    }
})(RetailerFormModal));