import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import SLARulesService from "../../../services/sla-reasons/sla-rules.service";
import { removeDuplicateLocations, notifyApiError } from '../../../common/utils/utils';

const slaService = new SLARulesService;

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchSLARules = (payload) => dispatch => {
    dispatch(fetchSLARulesBegin());
    slaService.getSLARulesfetch(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchSLARulesSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchSLARulesFailure(res ?.data ?.response));
        }
    });
};


function fetchSLARulesBegin() {
    return {
        type: _Constants.FETCH_SLA_RULES_BEGIN
    };
}

function fetchSLARulesSuccess(payload) {
    return {
        type: _Constants.FETCH_SLA_RULES_SUCCESS,
        payload: payload       
    };
}

function fetchSLARulesFailure(error) {
    return {
        type: _Constants.FETCH_SLA_RULES_FAILURE,
        payload: error
    };
}

