import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../../ui/formComponent/form";
import indentRejectSchema from "../form/indent.reject.schema";
import { updateIndentStatus } from "../services/indent";
import { getIndentRejectReasons } from "../services/search.services";

export default function RejectIndent(props) {
    
    const { openRejectModal } = props;
    var formRef = useRef();

    const [schema, SetSchema] = useState(false)

    const rejectIndent = _ => {
        formRef.current.formReference?.current?.submit()
    }

    const onSubmit = data => {
        const postData = {
            indentId : props.indentId,
            status   : "REJECTED",
            reason   : data.rejectReasonsObj.rejectReasons.join(","),
            remarks  : data.remarksObj.remarks
        }
        updateIndentStatus(postData).then(_ => {
            props.onIndentReject();
        });
    }

    const transformErrors = errors => {
        errors = errors.map(error => {
            if (error.name === "type" || error.name === "enum") {
                error.message = undefined;
            }
            return error;
        });
        return errors;
    }

    useEffect(() => {
        getIndentRejectReasons()
        .then(response => {
            var enums = [];
            var enumNames = [];
            response.forEach(reason => {
                enums.push(reason.reasonCode)
                enumNames.push(reason.reasonDescription)
            });
            indentRejectSchema.schema.properties.rejectReasonsObj.properties.rejectReasons.enum = enums;
            indentRejectSchema.schema.properties.rejectReasonsObj.properties.rejectReasons.enumNames = enumNames;
            SetSchema(indentRejectSchema);
        })
        .catch(_ => {
            SetSchema(indentRejectSchema);
        })
    }, []);

    const formData = {};

    return (
        <Modal centered={true} width={600} closable={false}
         title={"Reject Indent"}
         footer={(
            <div className="flex-box flex-gap-m align-items-center justify-content-end">
                <Button className="secondary-btn" onClick={props.onCancel}> Cancel </Button>
                <Button className="lsn-btn-primary" onClick={rejectIndent}> Reject </Button>
            </div>
         )} visible={openRejectModal} >
            
            {
                schema &&
                <FormWrapper hideSubmit={true} {...schema} formData={formData}
                 onSubmit={onSubmit} ref={formRef} transformErrors={transformErrors} />
            }

        </Modal>
    )
}