import React, { Component } from 'react'
import { Card, List, Row, Col, Button } from 'antd'
import { canAllow, dataGet } from '../../../common/utils/utils'
import { withRouter } from 'react-router-dom'
import { getColumn2, Col3Data, Col4Data, getColumn, Col5Data, getColumn3 } from './column.d'
import { permissionTags } from '../../../configs/permissionTags'

class TripDataCard extends Component {
    constructor(props) {
        super(props)
        this.state = {

            Col3Data,
            Col4Data,
            verificationPermission: {
                update: canAllow(permissionTags.verification.trips.update),
                reverify: canAllow(permissionTags.verification.trips.reverify),
            },
        }
    }

    render() {
        const { trip } = this.props;
        const { verificationPermission } = this.state;
        
        // const { Col1Data, Col2Data, Col3Data, Col4Data } = this.state;
        return (
            <Card>
                <Row gutter={[12, 24]}>
                    {
                        [getColumn(false), getColumn3(false), Col3Data, Col5Data].map((data, key) => <Col key={key} span={6}>
                            <List
                                size="small"
                                bordered
                                dataSource={data}
                                renderItem={item => (
                                    <List.Item>
                                        {
                                            !item.render ? <>
                                                <Col span={12}>
                                                    {
                                                        !!item.title && <h4><b>{item.title}</b><br /></h4>
                                                    }
                                                    {item.label}:
                                </Col>
                                                <Col span={12}>
                                                    {
                                                        !!item.title && <br />
                                                    }
                                                    <b style={{ wordWrap: 'break-word' }}>{dataGet(trip, item.dataIndex) || '-'}</b>
                                                </Col>
                                            </>
                                                : item?.render(item, trip)
                                        }
                                    </List.Item>
                                )}
                            />
                        </Col>)
                    }
                    <Col span={24}>
                        <Row gutter={[9, 9]}>
                            <Col span={3}>
                                <Button block className="v-trip-view-button" onClick={e => this.props.history.push('/appv2/verification/form/vtrips', { trip: trip?.tripId, editAllowed: false, status: 'view', filters: this.props.filters, tags: this.props.tags, initalFilters: this.props.initalFilters })}>View</Button>
                            </Col>
                            {trip?.verificationStatus !== 'VERIFIED' &&
                                <Col span={3}>
                                    <Button disabled={trip?.verificationStatus === 'VERIFIED'} block type="primary" onClick={e => this.props.history.push('/appv2/verification/form/vtrips', { trip: trip?.tripId, editAllowed: this.state.verificationPermission.update, status: 'verify', filters: this.props.filters, tags: this.props.tags, initalFilters: this.props.initalFilters })}>{trip?.verificationStatus === 'VERIFIED' ? 'Verified' : 'Verify'}</Button>
                                </Col>
                            }
                            {trip?.verificationStatus === 'VERIFIED' && (verificationPermission?.reverify || verificationPermission?.update) &&
                                <Col span={4}>
                                    <Button block type="primary" onClick={e => this.props.history.push('/appv2/verification/form/vtrips', { trip: trip?.tripId, editAllowed: this.state.verificationPermission.update, status: 'request_payment', filters: this.props.filters, tags: this.props.tags, initalFilters: this.props.initalFilters })}>Request Payment</Button>
                                </Col>
                            }
                            {trip?.verificationStatus === 'VERIFIED' && verificationPermission?.reverify &&
                                <Col span={3}>
                                    <Button block type="primary" onClick={e => this.props.history.push('/appv2/verification/form/vtrips', { trip: trip?.tripId, editAllowed: this.state.verificationPermission.reverify, status: 'reverify', filters: this.props.filters, tags: this.props.tags, initalFilters: this.props.initalFilters })}>Re-Verify</Button>
                                </Col>

                            }

                        </Row>


                    </Col>
                </Row>
            </Card>
        )
    }
}

export default withRouter(TripDataCard)