import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Icon,
  List,
  Table,
  Form,
  Radio,
  Modal,
  Divider
} from "antd";
import {
  getShipperColumn,
  getShipmentColumn,
  getShipmentTableColumns,
  getOtherDetails,
  getUserDetails,
  getFinanceDetails,
  getFinanceTableColumns,
  getShipmentBoxTypeTableColumns,
  getHistoryTable
} from "./column.d";
import * as apiUtils from "../../../common/utils/apiGateway";
import _ from "lodash";
import {
  canAllow,
  dataGet,
  formatCurrency,
  notifyApiError,
  notifyApiSuccess
} from "../../../common/utils/utils";
import "./trips.scss";
import { TripVerificationStatus } from "../../../common/utils/constants";
import moment from "moment";
import ImageViewer from "./ImageViewer";
import { permissionTags } from "../../../configs/permissionTags";

let PAYMENT_TYPES = [
  {
    label: "To be billed",
    value: "TO_BE_BILLED",
    dataType: 'CREDIT',
    isVisible: false
  },
  {
    label: "To Pay",
    value: "TOPAY",
    dataType: 'TOPAY',
    isVisible: false
  },
  {
    label: "Paid",
    value: "PAID",
    dataType: "PREPAID",
    isVisible: false
  }
];

class VerificationDocketsDetailsRight extends Component {
  state = {
    history: [],
    showHistoryModal: false,
    shipperEditModes: {
      shipper: false,
      origin: false,
      destination: false
    },
    otherEditModes: {
      identifier: false,
      rdsCode: false,
      rdsName: false,
      rdsPin: false,
      rdsCity: false,
      threePl: false
    },
    shipmentModes: {
      tWeight: false,
      cWeight: false,
      tCount: false,
      goods: false
    },
    financeModes: {
      totalAmount: false,
      collectedAmount: false
    },
    show3PLDocket: false,
    disabledSave: false,
    otherRefs: {
      rdsCode: React.createRef(),
      identifier: React.createRef(),
      threePl: React.createRef(),
      rdsName: React.createRef(),
      rdsPin: React.createRef(),
      rdsCity: React.createRef()
    },
    shipperRefs: {
      shipper: React.createRef(),
      origin: React.createRef(),
      destination: React.createRef()
    },
    shipmentRefs: {
      tWeight: React.createRef(),
      cWeight: React.createRef(),
      tCount: React.createRef(),
      goods: React.createRef(),
    },
    financeRefs: {
      totalAmount: React.createRef(),
      collectedAmount: React.createRef(),
    },


    verificationPermissions: {
      update: canAllow(permissionTags.verification.docket.update),
      upload: canAllow(permissionTags.verification.docket.upload),
    },
  };

  componentDidUpdate(prevProp, prevState) {

    if (this.props.consignmentData && this.props.consignmentData.customer !== prevProp.consignmentData.customer) {

      const selectedCustomer = this.props.consignmentData.customer;

      PAYMENT_TYPES.map(ptype => {
        if (selectedCustomer?.paymentTypes.includes(ptype?.dataType)) {
          ptype.isVisible = true;
        }
        return ptype;
      })
      if (_.hasIn(selectedCustomer, 'attributes') && _.hasIn(selectedCustomer?.attributes, "threePlReferenceNumber") && selectedCustomer?.attributes?.threePlReferenceNumber === "true") {
        this.setState({ show3PLDocket: true });
      } else {
        this.setState({ show3PLDocket: false });
      }
    }
  }


  enableShipperMode = mode => {
    let { shipperEditModes, shipperRefs } = this.state;
    shipperEditModes = {
      ...shipperEditModes,
      [mode]: true
    };
    this.setState({ shipperEditModes }, () => {
      if (mode === "shipper") {
        this.props.rightBoardEvents?.getShippers();
        shipperRefs.shipper.current.focus();
      } else if (mode === "origin") {
        this.props.rightBoardEvents?.getShipperLocations();
        shipperRefs.origin.current.focus();
      } else if (mode === "destination") {
        this.props.rightBoardEvents?.getDestinations();
        shipperRefs.destination.current.focus();
      }
      this.disableCta(true);
    });
  };

  disableShipperMode = mode => {
    let { shipperEditModes } = this.state;
    shipperEditModes = {
      ...shipperEditModes,
      [mode]: false
    };
    this.setState({ shipperEditModes });
    this.disableCta(false);
  };

  updateShipper = shipper => {
    this.disableShipperMode("shipper");
    this.props.rightBoardEvents?.updateShipper(shipper);
  };

  updateOrigin = origin => {
    this.disableShipperMode("origin");
    this.props.rightBoardEvents?.updateOrigin(origin);
  };

  updateDestination = destination => {
    this.disableShipperMode("destination");
    this.props.rightBoardEvents?.updateDestination(destination);
  };

  enableOtherDetails = mode => {
    let { otherEditModes, otherRefs } = this.state;
    otherEditModes = {
      ...otherEditModes,
      [mode]: !otherEditModes[mode]
    };

    this.setState({ otherEditModes }, () => {
      otherRefs[mode].current.focus();
    });
    this.disableCta(true);
  };

  disableOtherDetails = mode => {
    let { otherEditModes } = this.state;
    otherEditModes = {
      ...otherEditModes,
      [mode]: false
    };
    this.setState({ otherEditModes });
    this.disableCta(false);
  };

  updateOtherDetails = (key, value) => {
    this.props.rightBoardEvents?.editOtherDetails(key, value);
  };

  updateThreePL = (key, value) => {
    this.props.rightBoardEvents?.updateThreePL(key, value);
  }

  enableShipmentDetails = mode => {
    let { shipmentModes, shipmentRefs } = this.state;
    shipmentModes = {
      ...shipmentModes,
      [mode]: true
    };
    this.setState({ shipmentModes }, () => {
      if (mode === "goods") {
        this.props.rightBoardEvents?.getGoodsTypes();
      }
      shipmentRefs[mode].current.focus();
      this.disableCta(true);
    });
  };

  disableShipmentDetails = mode => {
    let { shipmentModes } = this.state;
    shipmentModes = {
      ...shipmentModes,
      [mode]: false
    };
    this.setState({ shipmentModes });
    this.disableCta(false);
  };

  updateShipmentDetails = (key, value) => {
    this.props.rightBoardEvents?.editShipmentDetails(key, value);
  };

  updateGoodsType = goods => {
    this.disableShipmentDetails("goods");
    this.props.rightBoardEvents?.updateGoodsType(goods);
  };

  getBoxTypes = () => {
    this.props.rightBoardEvents.getBoxTypes();
  };

  updateBoxTypes = boxes => {
    this.props.rightBoardEvents?.updateBoxTypes(boxes);
  };

  enableFinanceDetails = mode => {
    let { financeModes, financeRefs } = this.state;
    financeModes = {
      ...financeModes,
      [mode]: true
    };


    this.setState({ financeModes }, () => {
      financeRefs[mode].current.focus();
    });
    this.disableCta(true);
  };

  disableFinanceDetails = mode => {
    let { financeModes } = this.state;
    financeModes = {
      ...financeModes,
      [mode]: false
    };
    this.setState({ financeModes });
    this.disableCta(false);
  };

  updateFinanceDetails = (key, value) => {
    this.props.rightBoardEvents?.editfinanceDetails(key, value);
  };

  UpdateTypeMessages = data => {
    let user = data?.user?.name;
    
    let s3 = data?.s3Url ? data?.s3Url : null;
    let historyData = {};
    historyData = {
      message: <>
        <span style={{ display: 'inline-block', position: 'relative' }}>{data?.message} </span>

        {s3 ?
          <span style={{ display: 'inline-block', position: 'relative', top: 7, left: 5 }}><ImageViewer fileList={[{ url: s3 }]} /></span>
          : <span style={{ display: 'inline-block', position: 'relative', top: 7, left: 5 }}></span>
        }
        



      </>,
      user,
      timestamp: (
        <>
          {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
          <small>{moment(data?.createdAt).fromNow()}</small>
        </>
      )
    }

    return historyData;

    switch (data?.updateType) {
      case TripVerificationStatus.REMOVE_LR_IMG:
      case TripVerificationStatus.REMOVE_CUSTOMER_SLIP:
      case TripVerificationStatus.REMOVE_VENDOR_SLIP:
        let removeNewCase =
          data?.updateType === TripVerificationStatus.REMOVE_LR_IMG
            ? "LR"
            : data?.updateType === TripVerificationStatus.REMOVE_CUSTOMER_SLIP
              ? "Customer"
              : "Vendor";

        return {
          message: (
            <div>
              <span style={{ display: "inline-block" }}>
                Removed {`{${removeNewCase} Image}`}
              </span>{" "}
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  top: 5
                }}
              >
                <ImageViewer fileList={[{ url: data?.metaData?.imgLink }]} />
              </span>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.ADD_LR_IMG:
      case TripVerificationStatus.ADD_CUSTOMER_SLIP:
      case TripVerificationStatus.ADD_VENDOR_SLIP:
        let addNewCase =
          data?.updateType === TripVerificationStatus.ADD_LR_IMG
            ? "LR"
            : data?.updateType === TripVerificationStatus.ADD_CUSTOMER_SLIP
              ? "Customer"
              : "Vendor";
        return {
          message: (
            <div>
              <span style={{ display: "inline-block" }}>
                Added {`{${addNewCase} Image}`}
              </span>{" "}
              <span
                style={{
                  display: "inline-block",
                  position: "relative",
                  top: 5
                }}
              >
                <ImageViewer fileList={[{ url: data?.metaData?.imgLink }]} />
              </span>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.UPDATE_BASE_COST:
      case TripVerificationStatus.UPDATE_BASE_REVENUE:
        return {
          message: (
            <div>
              Updated{" "}
              {data?.updateType === TripVerificationStatus.UPDATE_BASE_REVENUE
                ? "Revenue"
                : "Cost"}{" "}
              <b>{data?.metaData?.updatedCostRevenue}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.ADD_COST_TXN:
      case TripVerificationStatus.ADD_REVENUE_TXN:
        return {
          message: (
            <div>
              Added Trasaction
              <br />
              <b>
                Transaction Type: {data?.metaData?.txn?.transactionType}
                <br /> Charge Type: {data?.metaData?.txn?.chargeType}
                <br />
                Amount {data?.metaData?.txn?.amount}
              </b>
              {data?.metaData?.linkedTxn && (
                <div>
                  <Divider />
                  Linked Transaction <br />
                  <b>
                    Transaction Type:{" "}
                    {data?.metaData?.linkedTxn?.transactionType}
                    <br /> Charge Type: {data?.metaData?.linkedTxn?.chargeType}
                    <br />
                    Amount {data?.metaData?.linkedTxn?.amount}
                  </b>
                </div>
              )}
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;

      case TripVerificationStatus.REMOVE_COST_TXN:
      case TripVerificationStatus.REMOVE_REVENUE_TXN:
        return {
          message: (
            <div>
              Removed Trasaction <br />
              <b>
                Transaction Type: {data?.metaData?.txn?.transactionType} <br />{" "}
                Charge Type: {data?.metaData?.txn?.chargeType}
              </b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;

      case TripVerificationStatus.EDIT_START_KMS:
      case TripVerificationStatus.EDIT_END_KMS:
        return {
          message: (
            <div>
              Updated{" "}
              {data?.updateType === TripVerificationStatus.EDIT_START_KMS
                ? "Start KMs"
                : "End KMs"}{" "}
              <br />
              <b>Updated value: {data?.metaData?.updatedValue}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.EDIT_START_TIME:
      case TripVerificationStatus.EDIT_END_TIME:
        return {
          message: (
            <div>
              Updated{" "}
              {data?.updateType === TripVerificationStatus.EDIT_START_TIME
                ? "Start Time"
                : "End Time"}{" "}
              <br />
              <b>
                Updated value:{" "}
                {moment(new Date(Number(data?.metaData?.updatedValue))).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      case TripVerificationStatus.EDIT_VEHICLE_TYPE:
        return {
          message: (
            <div>
              Updated Vehicle Type <br />
              <b>Updated value: {data?.metaData?.vehicleType}</b>
            </div>
          ),
          user,
          timestamp: (
            <>
              {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data?.createdAt).fromNow()}</small>
            </>
          )
        };
        break;
      default:
        return {};
        break;
    }
  };

  showHistory = () => {
    this.setState(
      {
        history: []
      },
      () => {
        apiUtils
          .apiRequest({
            url: `/b2b/v1/consignmentVerifications/events/${this.props.consignmentData?.id}`,
            method: "GET"
          })
          .then(res => {
            let history = [];
            this.setState({ history }, () => {
              let historyData = res?.data?.response?.consignmentVerificationEvents;
              if (historyData && historyData.length > 0) {
                history = historyData.map(hist => {
                  return this.UpdateTypeMessages(hist);
                });
              }
              if (history.length > 0) {
                this.setState({ history }, () => {
                  this.setState({ showHistoryModal: true });
                });
              }
            })


          });
      }
    );
  };

  cancelHistory = () => {
    this.setState({ showHistoryModal: false });
  };

  disableCta = (isBool) => {
    this.setState({ disabledSave: isBool });
  }

  verifyDocket = () => {
    this.props.verifyDocket();
  }

  render() {
    const {
      consignmentData,
      rightBoardEvents,
      shippersData,
      locationData,
      destinations,
      goodsTypes
    } = this.props;
    const {
      shipperEditModes,
      otherEditModes,
      shipmentModes,
      financeModes,
      history,
      verificationPermissions
    } = this.state;

    const shipperEvents = {
      enableShipperMode: this.enableShipperMode,
      disableShipperMode: this.disableShipperMode,
      updateShipper: this.updateShipper,
      updateOrigin: this.updateOrigin,
      updateDestination: this.updateDestination
    };

    const otherEvents = {
      enableOtherDetails: this.enableOtherDetails,
      disableOtherDetails: this.disableOtherDetails,
      updateOtherDetails: this.updateOtherDetails,
      updateThreePL: this.updateThreePL,
      disableCta: this.disableCta
    };

    const shipmentEvents = {
      enableShipmentDetails: this.enableShipmentDetails,
      disableShipmentDetails: this.disableShipmentDetails,
      updateShipmentDetails: this.updateShipmentDetails,
      updateGoodsType: this.updateGoodsType,
      getBoxTypes: this.getBoxTypes,
      updateBoxTypes: this.updateBoxTypes,
      getOriginalShipmentBoxes: rightBoardEvents.getOriginalShipmentBoxes,
      disableCta: this.disableCta
    };

    const financeEvents = {
      enableFinanceDetails: this.enableFinanceDetails,
      disableFinanceDetails: this.disableFinanceDetails,
      updateFinanceDetails: this.updateFinanceDetails,
      getOriginalFinanceDetails: rightBoardEvents?.getOriginalFinanceDetails,
      disableCta: this.disableCta
    };

    return (
      <div className={"docket-verify-right"}>
        <div className={"docket-verify-right-block"}>
          <div className={"docket-verify-right-ctas"}>
            <Row gutter={[12, 24]}>
              <Col span={12}>
                <Button
                  disabled={
                    consignmentData?.consignmentStatus == 'BOOKED' ||
                    consignmentData?.consignmentStatus === 'IN' || consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions.update
                  }
                  onClick={rightBoardEvents?.openCreateTickeDialog}
                  className={"docket-verify-right-ctas-primary"}
                >
                  Create Ticket
                </Button>
              </Col>
              <Col span={12} className="docket-verify-right-ctas-right">
                <Button
                  onClick={rightBoardEvents?.validateBeforeSaveDetails}
                  className={"docket-verify-right-ctas-primary"}
                  disabled={this.state.disabledSave || consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions.update}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Button
                  className={"docket-verify-right-ctas-default"}
                  onClick={e => this.showHistory()}
                >
                  <Icon type="reload" />
                  View History
                </Button>
              </Col>
              <Col span={12} className="docket-verify-right-ctas-right">
                <Button
                  disabled={consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || consignmentData?.consignmentFilterBO?.verificationStatus === 'DOCUMENT_PENDING' || !verificationPermissions.update}
                  onClick={this.verifyDocket} className={"docket-verify-right-ctas-success"}>
                  Verify
                </Button>
              </Col>
            </Row>
          </div>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <div className={"docket-verify-right-shipper"}>
                <ShipperDetails
                  shipper={this.props?.shipperDetails}
                  shipperEditModes={shipperEditModes}
                  shipperEvents={shipperEvents}
                  shippersData={shippersData}
                  locationData={locationData}
                  destinations={destinations}
                  status={consignmentData?.consignmentStatus}
                  bookingOffice={consignmentData?.bookingOfficeLoc}
                  location={consignmentData?.location}
                  shipperRefs={this.state.shipperRefs}
                  verificationPermissions={verificationPermissions}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className={"docket-verify-right-shipper"}>
                <ShipmentDetails
                  shipment={this.props?.shipmentDetails}
                  boxes={this.props?.shipmentBoxes}
                  shipmentBoxTypes={this.props?.shipmentBoxTypes}
                  boxTypes={this.props?.boxTypes}
                  shipmentModes={shipmentModes}
                  shipmentEvents={shipmentEvents}
                  goodsTypes={goodsTypes}
                  shipmentRefs={this.state.shipmentRefs}
                  verificationPermissions={verificationPermissions}

                />
              </div>
            </Col>

            <Col span={24}>
              <div className={"docket-verify-right-shipper"}>
                <FinanceDetails
                  financeDetails={this.props?.financeDetails}
                  invoiceData={this.props?.invoiceData}
                  financeEvents={financeEvents}
                  financeModes={financeModes}
                  status={consignmentData?.consignmentStatus}
                  financeRefs={this.state.financeRefs}
                  verificationPermissions={verificationPermissions}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className={"docket-verify-right-shipper"}>
                <OtherDetails
                  otherDetails={this.props?.otherDetails}
                  consignmentFilterBO={this.props?.consignmentFilterBO}
                  otherEditModes={otherEditModes}
                  otherEvents={otherEvents}
                  customer={consignmentData?.customer}
                  configurations={this.props.configurations}
                  show3PLDocket={this.state.show3PLDocket}
                  otherRefs={this.state.otherRefs}
                  verificationPermissions={verificationPermissions}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className={"docket-verify-right-shipper"}>
                <UserDetails
                  userDetails={this.props?.userDetails}
                  notifyCopyEvent={rightBoardEvents?.notifyCopyEvent}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          visible={this.state.showHistoryModal}
          title="History"
          onOk={this.cancelHistory}
          onCancel={this.cancelHistory}
          width={1200}
          style={{ top: 20 }}
          footer={null}
        >
          <Table
            bordered
            pagination={false}
            columns={getHistoryTable()}
            dataSource={history}
          />
        </Modal>
      </div>
    );
  }
}

class ShipperDetails extends Component {
  render() {
    const {
      shipper,
      shipperEditModes,
      shipperEvents,
      shippersData,
      locationData,
      destinations,
      bookingOffice,
      location,
      status,
      shipperRefs,
      verificationPermissions
    } = this.props;
    const allowedStatus = ["BOOKED", "IN"];

    const isBool = bookingOffice?.id === location?.id && status === 'INWARD';

    return (
      <div>
        <h3>Shipper Details</h3>
        {[
          getShipperColumn(
            verificationPermissions?.update && allowedStatus.includes(status) || isBool,
            shipperEditModes,
            shipperEvents,
            shippersData,
            locationData,
            destinations,
            shipperRefs
          )
        ].map((data, key) => (
          <Col key={key} span={24}>
            <div className="docket-verify-right-shipper-details">
              <List
                size="small"
                bordered
                dataSource={data}
                renderItem={item => (
                  <List.Item>
                    {!item.render ? (
                      <>
                        <Col
                          span={10}
                          className="docket-verify-right-shipper-details-title"
                        >
                          {!!item.title && (
                            <h4>
                              <b>{item.title}</b>
                              <br />
                            </h4>
                          )}
                          {item.label}:
                        </Col>
                        <Col
                          span={14}
                          className="docket-verify-right-shipper-details-dataIndex"
                        >
                          {!!item.title && <br />}
                          {dataGet(shipper, item.dataIndex) || "-"}
                        </Col>
                      </>
                    ) : (
                        item?.render(item, shipper)
                      )}
                  </List.Item>
                )}
              />
            </div>
          </Col>
        ))}
      </div>
    );
  }
}

class ShipmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalDetailModalOpen: false,
      boxTypesList: [...props.shipmentBoxTypes],
      records: [
        {
          noOfBoxes: props.shipmentBoxTypes.length,
          noOfPcs: 0
        }
      ],
      boxTypeEditModes: {
        count: []
      }
    };
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.props.shipmentBoxTypes !== prevProp.shipmentBoxTypes) {
      this.setState({
        boxTypesList: [...this.props.shipmentBoxTypes],
        records: [
          {
            noOfBoxes: this.props.shipmentBoxTypes.length,
            noOfPcs: this.props.shipmentBoxTypes.length
              ? this.props.shipmentBoxTypes
                .map(item => item.count)
                .reduce((a, b) => Number(a) + Number(b))
              : 0
          }
        ]
      });
    }
    if (this.state.boxTypesList !== prevState.boxTypesList) {
      this.setState({
        records: [
          {
            noOfBoxes: this.state.boxTypesList.length,
            noOfPcs: this.state.boxTypesList.length
              ? this.state.boxTypesList
                .map(item => item.count)
                .reduce((a, b) => Number(a) + Number(b))
              : 0
          }
        ]
      });
    }

  }

  showModal = () => {
    this.setState({
      isModalDetailModalOpen: true
    });
  };

  handleModalOk = e => {

    let boxNo = this.state.boxTypesList.length
      ? this.state.boxTypesList.map(item => item.count).reduce((a, b) => a + b)
      : 0;
    let ids = this.state.boxTypesList.filter(box => box.id === undefined);

    if (ids && ids.length > 0 && ids[0].id === undefined) {
      // notifyApiError("Select Box type", "Error");
      Modal.error({
        title: 'Please select box type'
      })
      return;
    }

    if (
      this.props.shipment.totalShipmentCount < boxNo
    ) {
      notifyApiError("BoxTypes count can't be greater than shipment", "Error");
    } else {
      this.setState({
        isModalDetailModalOpen: false
      });
      this.props.shipmentEvents.updateBoxTypes &&
        this.props.shipmentEvents.updateBoxTypes(this.state.boxTypesList);
    }
  };

  handleModalCancel = e => {
    this.setState({
      boxTypesList: [...this.props.shipmentBoxTypes],
      isModalDetailModalOpen: false
    });
  };

  addNewBoxType = () => {
    const list = this.state.boxTypesList;
    const isUndefinedLists = list.filter(ls => ls?.id === undefined);
    if (isUndefinedLists && isUndefinedLists?.length > 0) {
      Modal.error({
        title: 'Please select box type'
      });
      return;
    }
    list.push({
      id: undefined,
      count: 1
    });
    this.setState({
      boxTypesList: list,
      records: [
        {
          noOfBoxes: this.state.boxTypesList.length,
          noOfPcs: this.state.boxTypesList.length
            ? this.state.boxTypesList
              .map(item => item.count)
              .reduce((a, b) => Number(a) + Number(b))
            : 0
        }
      ]
    });
  };

  render() {
    const {
      shipment,
      boxes,
      shipmentModes,
      shipmentEvents,
      goodsTypes,
      boxTypes,
      shipmentRefs,
      verificationPermissions
    } = this.props;

    const boxTypeEvents = {
      getBoxTypes: boxTypes,
      boxTypesList: this.state.boxTypesList,
      enableBoxType: (key, id) => {
        this.setState(state => ({
          boxTypeEditModes: {
            ...state.boxTypeEditModes,
            [key]: [...state?.boxTypeEditModes[key], id]
          }
        }));
      },
      disableBoxType: (key, id) =>
        this.setState(state => ({
          boxTypeEditModes: {
            ...state.boxTypeEditModes,
            [key]: state?.boxTypeEditModes[key]?.filter(item => item !== id)
          }
        })),
      removeBoxType: data => {
        this.setState({
          boxTypesList: this.state.boxTypesList.filter(
            item => item.id !== data.id
          )
        });
      },
      updateBoxType: (key, data, index, count) => {

        let isFound = this.state.boxTypesList.find(item => item.id === data.id);

        let boxTypesList = this.state.boxTypesList;
        if (isFound) {
          boxTypesList = boxTypesList.map(item =>
            item.id === data.id ? data : item
          );
        } else {
          if (key === "id" && index !== -1) {
            boxTypesList[index] = { ...data, count: (count === 0 || count === '0' || !count) ? 1 : count };

          }
        }
        this.setState({
          boxTypesList,
          records: [
            {
              noOfBoxes: this.state.boxTypesList.length,
              noOfPcs: this.state.boxTypesList.length
                ? this.state.boxTypesList
                  .map(item => item.count)
                  .reduce((a, b) => Number(a) + Number(b))
                : 0
            }
          ]
        });
      }
    };

    return (
      <div>
        <h3>Shipment Details</h3>
        {[
          getShipmentColumn(verificationPermissions?.update, shipmentModes, shipmentEvents, goodsTypes, shipmentRefs)
        ].map((data, key) => (
          <Col key={key} span={24}>
            <div className="docket-verify-right-shipper-details">
              <List
                size="small"
                bordered
                dataSource={data}
                renderItem={item => (
                  <List.Item>
                    {!item.render ? (
                      <>
                        <Col
                          span={10}
                          className="docket-verify-right-shipper-details-title"
                        >
                          {!!item.title && (
                            <h4>
                              <b>{item.title}</b>
                              <br />
                            </h4>
                          )}
                          {item.label}:
                        </Col>
                        <Col
                          span={14}
                          className="docket-verify-right-shipper-details-dataIndex"
                        >
                          {!!item.title && <br />}
                          {dataGet(shipment, item.dataIndex) || "-"}
                        </Col>
                      </>
                    ) : (
                        item?.render(item, shipment)
                      )}
                  </List.Item>
                )}
              />
              {boxes && boxes.length > 0 && (
                <div className="docket-verify-right-shipper-details-table">
                  <Table
                    dataSource={this.state.records}
                    columns={getShipmentTableColumns()}
                    pagination={false}
                    size={"small"}
                    borderd
                    footer={() => (
                      <div className="docket-verify-right-shipper-details-table-footer">
                        <Button
                          onClick={this.showModal}
                          className="docket-verify-right-ctas-primary"
                        >
                          View Details
                        </Button>
                      </div>
                    )}
                  />
                </div>
              )}
              {this.state.isModalDetailModalOpen && (
                <Modal
                  title={`No of Boxes : ${this.state?.records[0]?.noOfPcs ||
                    0}`}
                  visible={this.state.isModalDetailModalOpen}
                  onCancel={this.handleModalCancel}
                  onOk={this.handleModalOk}
                  okText="Save"
                  bodyStyle={{
                    padding: 10
                  }}
                >
                  <div className="docket-verify-right-shipper-details-table">
                    <Table
                      dataSource={this.state.boxTypesList}
                      columns={getShipmentBoxTypeTableColumns(
                        true,
                        this.state.boxTypeEditModes,
                        boxTypeEvents
                      )}
                      pagination={false}
                      size={"small"}
                      borderd
                      footer={() => (
                        <div className="">
                          <Button
                            onClick={this.addNewBoxType}
                            className="docket-verify-right-ctas-primary"
                            disabled={Boolean(
                              this.state.boxTypesList.find(
                                item =>
                                  item.id === undefined && item.count === 0
                              )
                            )}
                          >
                            Add Row
                          </Button>
                        </div>
                      )}
                    />
                  </div>
                </Modal>
              )}
            </div>
          </Col>
        ))}
      </div>
    );
  }
}

class FinanceDetails extends Component {
  render() {
    const {
      financeDetails,
      invoiceData,
      financeModes,
      financeEvents,
      status,
      financeRefs,
      verificationPermissions
    } = this.props;

    let isBool = ((financeDetails?.paymentType === 'TOPAY' || financeDetails?.paymentType === 'TO_PAY') && status === 'DELIVERED') ? false : true;
    return (
      <div>
        <h3>Finance Details</h3>
        {[getFinanceDetails(verificationPermissions?.update && isBool, financeModes, financeEvents, financeRefs)].map(
          (data = [], key) => {
            let records = data;

            switch (financeDetails?.paymentType) {
              case "TO_BE_BILLED":
              case "CREDIT":
                records = records.map(item => ({
                  ...item,
                  isShown: false
                }));
                // if (
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "PAID"
                // ) {
                //   records = records.map(item => ({
                //     ...item,
                //     isShown: item.dataIndex === "totalAmount" ? false : true
                //   }));
                // }
                // if (
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "TOPAY" ||
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "TO_PAY"
                // ) {
                //   records = records.map(item => ({
                //     ...item,
                //     isShown: false
                //   }));
                // }

                // if (
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "TO_BE_BILLED" || financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "CREDIT"
                // ) {
                //   records = records.map(item => ({
                //     ...item,
                //     isShown: false
                //   }));

                // }

                break;
              case "TOPAY":
              case "TO_PAY":
                records = records.map(item => ({
                  ...item,
                  isShown: true
                }));
                break;
              case "PAID":
              case "PREPAID":
                // if (
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "TO_BE_BILLED"
                // ) {
                //   records = records.map(item => ({
                //     ...item,
                //     isShown:
                //       item.dataIndex === "totalAmount" ? true : item.isShown,
                //     isShown:
                //       item.dataIndex === "collectedAmount"
                //         ? false
                //         : item.isShown
                //   }));
                // }
                // if (
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "TOPAY" ||
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "TO_PAY"
                // ) {
                //   records = records.map(item => ({
                //     ...item,
                //     isShown:
                //       item.dataIndex === "collectedAmount"
                //         ? false
                //         : item.isShown
                //   }));
                // }
                // if (
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "PAID" ||
                //   financeEvents.getOriginalFinanceDetails()?.paymentType ===
                //   "PREPAID"
                // ) {

                //   records = records.map(item => ({
                //     ...item,
                //     isShown:
                //       item.dataIndex === "collectedAmount"
                //         ? false
                //         : item.isShown
                //   }));
                // }
                records = records.map(item => ({
                  ...item,
                  isShown:
                    item.dataIndex === "totalAmount" ? true : item.isShown,
                  isShown:
                    item.dataIndex === "collectedAmount"
                      ? false
                      : item.isShown
                }));

                break;
              default:
                break;
            }
            let locale = {
              emptyText: <></>,
            };
            return (
              <Col key={key} span={24}>
                <div className="docket-verify-right-shipper-details">
                  <List
                    size="small"
                    header={
                      <div>
                        <div className="form-label-text">Payment Type :</div>
                        <Radio.Group
                          value={

                            financeDetails?.paymentType === 'CREDIT' ? 'TO_BE_BILLED' :
                              (financeDetails?.paymentType === 'PREPAID' ? 'PAID' :
                                (financeDetails?.paymentType === 'TOPAY' || financeDetails?.paymentType === 'TO_PAY' ? 'TOPAY' : financeDetails?.paymentType)
                              )
                          }
                          onChange={e =>
                            financeEvents?.updateFinanceDetails(
                              "paymentType",
                              e.target.value
                            )
                          }
                        >
                          {PAYMENT_TYPES.map(item => (
                            item?.isVisible &&
                            <Radio
                              value={item.value}
                              disabled={(item?.value === 'TOPAY' || item?.value === 'TO_PAY') && status === 'DELIVERED'}
                              style={{
                                color: financeModes?.paymentTypeIsBlue
                                  ? "#1e6fe4"
                                  : "#000000"
                              }}
                            >
                              {item.label}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </div>
                    }
                    bordered
                    dataSource={records.filter(item => item.isShown)}
                    locale={locale}
                    renderItem={item => {

                      return (
                        <List.Item>
                          {!item.render ? (
                            <>
                              <Col
                                span={10}
                                className="docket-verify-right-shipper-details-title"
                              >
                                {!!item.title && (
                                  <h4>
                                    <b>{item.title}</b>
                                    <br />
                                  </h4>
                                )}
                                {item.label}:
                            </Col>
                              <Col
                                span={14}
                                className="docket-verify-right-shipper-details-dataIndex"
                              >
                                {!!item.title && <br />}
                                {dataGet(financeDetails, item.dataIndex) || "-"}
                              </Col>
                            </>
                          ) : (
                              item?.render(item, financeDetails)
                            )}
                        </List.Item>
                      )
                    }
                    }
                  />
                  {invoiceData && invoiceData.length > 0 && (
                    <div className="docket-verify-right-shipper-details-table">
                      <Table
                        dataSource={invoiceData}
                        columns={getFinanceTableColumns()}
                        pagination={false}
                        borderd
                        size={"small"}
                      />
                    </div>
                  )}
                </div>
              </Col>
            );
          }
        )}
      </div>
    );
  }
}

class OtherDetails extends Component {
  render() {
    const { otherDetails, otherEditModes, otherEvents, customer, show3PLDocket, consignmentFilterBO, otherRefs, verificationPermissions } = this.props;
    let editRdsInfo = false;
    
    if (
      _.hasIn(this.props.configurations, "restrictBooking") &&
      _.hasIn(this.props.configurations?.restrictBooking, "sic")
    ) {
      const { restrictBooking } = this.props.configurations;
      const { sic } = restrictBooking;

      if (sic?.includes(customer?.id.toString())) {
        editRdsInfo = true;
      } else {
        editRdsInfo = false;
      }
    }

    const allowedThreePL = verificationPermissions?.update;
    return (
      <div>
        <h3>Other Details</h3>
        {[getOtherDetails(verificationPermissions?.update && editRdsInfo, otherEditModes, otherEvents, show3PLDocket, consignmentFilterBO, otherRefs, allowedThreePL)].map(
          (data, key) => (
            <Col key={key} span={24}>
              <div className="docket-verify-right-shipper-details">
                <List
                  size="small"
                  bordered
                  dataSource={data}
                  renderItem={item => (
                    <List.Item>
                      {!item.render ? (
                        <>
                          <Col
                            span={14}
                            className="docket-verify-right-shipper-details-title"
                          >
                            {!!item.title && (
                              <h4>
                                <b>{item.title}</b>
                                <br />
                              </h4>
                            )}
                            {item.label}:
                          </Col>
                          <Col
                            span={10}
                            className="docket-verify-right-shipper-details-dataIndex"
                          >
                            {!!item.title && <br />}
                            {dataGet(otherDetails, item.dataIndex) || "-"}
                          </Col>
                        </>
                      ) : (
                          item?.render(item, otherDetails)
                        )}
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          )
        )}
      </div>
    );
  }
}

class UserDetails extends Component {
  render() {
    const { userDetails, notifyCopyEvent } = this.props;

    return (
      <div>
        <h3>Docket Created By :</h3>
        {[getUserDetails(true, notifyCopyEvent)].map((data, key) => (
          <Col key={key} span={24}>
            <div className="docket-verify-right-shipper-details">
              <List
                size="small"
                dataSource={data}
                renderItem={item => (
                  <List.Item>
                    {!item.render ? (
                      <>
                        <Col
                          span={10}
                          className="docket-verify-right-shipper-details-title"
                        >
                          {!!item.title && (
                            <h4>
                              <b>{item.title}</b>
                              <br />
                            </h4>
                          )}
                          {item.label}:
                        </Col>
                        <Col
                          span={14}
                          className="docket-verify-right-shipper-details-dataIndex"
                        >
                          {!!item.title && <br />}
                          {dataGet(userDetails, item.dataIndex) || "-"}
                        </Col>
                      </>
                    ) : (
                        item?.render(item, userDetails)
                      )}
                  </List.Item>
                )}
              />
            </div>
          </Col>
        ))}
      </div>
    );
  }
}

export default VerificationDocketsDetailsRight;
