import { Button, Modal, Tooltip } from "antd";
import React from "react";
import { approveRejectContract } from "../../../../../verification/contracts/services";

export default function ApprovalActions (props) {
    
    const approveContract = _ => {
        Modal.confirm({
            title       : "Are you sure to approve?",
            okText      : "Yes",
            cancelText  : "No",
            onOk        : () => {
                const data = {
                    contractBO  : {
                        id      : props.contractDetails.id,
                        status  : "APPROVED"
                    }
                }
                approveRejectContract(data).then(response => {
                    props.approveSuccess(response);
                })

            }
        })
    }

    return (
        <>
            <Tooltip title={!props.readonly ? "Save changes made to reject contract." : undefined}>
                <Button type="danger" onClick={props.rejectContract} disabled={props.submitErrors || !props.readonly}>Reject</Button>
            </Tooltip>
            <Tooltip title={!props.readonly ? "Save changes made to approve contract." : undefined}>
                <Button className={props.submitErrors || !props.readonly ? "" : "lsn-btn-success"}
                 onClick={approveContract} disabled={props.submitErrors || !props.readonly}>Approve</Button>
            </Tooltip>
            {/* {
                props.readonly ?
                <Button className="lsn-btn-primary" onClick={props.editContract}>Edit</Button> :
                <>
                    <Button className="secondary-btn" onClick={_ => {
                        Modal.confirm({
                            title : "Are you sure to cancel?",
                            onOk: _ => {
                                Modal.destroyAll();
                                if (typeof props.cancelEdit === "function") {
                                    props.cancelEdit();
                                }
                            },
                            okText: "Yes"
                        })
                    }} > Cancel </Button>
                    <Button className="lsn-btn-primary" onClick={_ => {
                        Modal.confirm({
                            title  : "Are you sure to save?",
                            onOk   : _ => props.saveDraftContract(false),
                            okText : "Yes"
                        })
                    }} > Save </Button>
                </>
            } */}
        </>
    )
}