
import { apiRequest } from '../../common/utils/apiGateway';
import { Constants, BASE_URL_TYPES } from '../../common/utils/constants';
export default class ReportsService {

    downloadReports(params = {}, reportType, endPoint, type) {
        if (!params.hasOwnProperty('loggedInUserTokken')) {
            params['loggedInUserTokken'] = true;
        }

        const url = `/b2b/v1/${type}/${reportType}/${endPoint}`;
        return apiRequest({
            url,
            method: "POST",
            data: params,
            baseUrlType: BASE_URL_TYPES.REPORTS
        });
    }

    downloadZip(params = {}, reportType, endpoint, type) {
        if (!params.hasOwnProperty('loggedInUserTokken')) {
            params['loggedInUserTokken'] = true;
        }
        const url = `/b2b/v1/${type}/${reportType}/${endpoint}/`;
        return apiRequest({
            url,
            method: "POST",
            data: params,
            baseUrlType: BASE_URL_TYPES.REPORTS
        });
    }

    // getManifestSheet(params) {
    //   const url = `/b2b/v1/reports/MANIFEST_SHEET_LITE/get`;
    //   return this.apiGateway.post(url, params, BASE_URL_TYPES.REPORTS);
    // }

    getManifestSheet(params) {
        const url = `/b2b/v1/reports/MANIFEST_SHEET_LITE_GROUPED/get`;
        return apiRequest({
            url,
            method: "POST",
            data: params,
            baseUrlType: BASE_URL_TYPES.REPORTS
        });
    }
}