import React from 'react';

import 
    {     
       Row, Col, Button    
    } 
from 'antd';


const formSubmission = (props) => (
    <Row>
        {
            props.actions.map(action => {
                return (
                    <Col span={8}>
                        <Button 
                            disabled={props.isDisabled} 
                            type="primary" 
                            onClick={() => action.callback(action)} 
                            className="margin-top-15"
                            loading={props.loading ? props.loading : false}
                        >
                            {action.name}
                        </Button>
                    </Col>
                )
            })
        }
    
    </Row>
)

export default formSubmission;