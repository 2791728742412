import React, { useState } from "react";
import { Tabs } from "antd";
import SLARouteTimeline from "./sla-route.timeline";
import "./sla-component.scss";
const { TabPane } = Tabs;

const SLARouteComponent = ({ paths }) => {
  const defaultPath = paths?.findIndex((path) => path.isCurrentPath);
  const [activeKey, setActiveKey] = useState(defaultPath ? defaultPath : 1);
  const callback = (key) => {
    console.log("key", key);
    setActiveKey(key);
  };
  
  return (
    <>
      <Tabs
        defaultActiveKey={`${defaultPath ? defaultPath + 1 : 1}`}
        onChange={callback}
        className="sla-route-tabs"
      >
        {paths.map((path, index) => (
          <TabPane tab={`Path ${index + 1}`} key={`${index + 1}`}>
            {/* <TabPane
            tab={
              <RenderTab
                name={`Route ${index + 1}`}
                isSelected={activeKey === index + 1}
              />
            }
            key={`${index + 1}`}
          > */}
            <SLARouteTimeline
              path={path}
              key={index}
              currentPath={
                defaultPath >= 0
                  ? `path ${defaultPath + 1}`
                  : "an unknown path"
              }
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default SLARouteComponent;
