import React, { useState } from 'react';
import { Modal, Spin, Table, Switch } from 'antd';
import moment from 'moment';
function ViewHistory(props) {
    const NewlineText = (props) => {
        const text = props.text;
        const newText = text.split('\n').map(str => <div>{str}</div>);
        return newText;

    }
    const getHistoryTable = () => {
        return [
            {
                title: 'Updated Date & Time',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (data) => moment(data).format('DD/MM/YYYY HH:mm:ss')
            },
            {
                title: 'User',
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: 'Changes details',
                dataIndex: 'changeDetails',
                key: 'changeDetails',
                render: (data) => <NewlineText text={data} />
            }
        ]
    }
    console.log("props", props.history)
    return (
        <Modal
            visible={props.showHistoryModal}
            title="History"
            onCancel={props.cancelHistory}
            width={900}
            style={{ top: 20 }
            }
            footer={null}
        >
            {/* <Spin spinning={props.fetchingHistory}> */}
            <div className="flex-column flex-gap-l">
                <div className="flex-box flex-gap-l">
                    Rejection History
                    <Switch checked={props.showRejectHistory} onChange={props.changeRejectHistory} />
                </div>
                <div>
                    <Table
                        bordered
                        pagination={false}
                        columns={getHistoryTable()}
                        dataSource={props.history}
                    />
                </div>
            </div>
            {/* </Spin> */}
        </Modal >
    )
}

export default ViewHistory;