import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import CompletedTripsList from './completed-trips-list';
import * as tripAction from '../../inventory/trip/actions';
import _ from 'lodash';
import { Spin } from 'antd';
const locationId = localStorage.getItem('currentPartnerLocationId');

class CompletedTrips extends Component {

    componentDidMount() {
        let filters = {
            "filters": {
                "isEmptyPodImgLinks": false,
                "pageNo": 1,
                "locationIds": [`${locationId}`],
                "pageSize": 10,
                "consignmentFilter": {},
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_TRIP_LIST",
                    "requestingFrom": "COMPLETED_TRIPS"
                },
                "fetchObjects": ["THC_ROUTE", "THC_ASSET", "CONSIGNMENTS_CUSTOMER", "THCUSER", "CONSIGNMENT_CONTACTS", "LOCATIONS", "ADDRESS", "POD_APPROVAL"]
            }
        }

        let params = { "filters": { "isActive": true, "statusShortCodes": ["POD_UNCLEAN"] } };

        const locationFilters = { "filters": { "roles": ["DELIVERY", "PICKUP"], "deviceFilter": { "deviceType": "DESKTOP", "view": "LITE_LOCATION_LIST" }, "status": true } }
        if (!this.props.locationGroups?.response?.locationsGroupedByRole) this.props.getLocationGroups(locationFilters);
        console.log("this.props->>", this.props, this.props.rejectReasons?.reasons?.length <= 0)
        if (_.isUndefined(this.props.rejectReasons) || this.props.rejectReasons?.reasons?.length <= 0) this.props.getRejectReasons(params);
        this.props.getTripList(filters);
    }

    refreshData = (page = 1, pageSize = 10, waybills = '', bookingFilters = {}, mapFilter = [], isEligibleToComplete = null, locations = null, entityIds = [], pickUpLocations = null) => {
        let consignmentFilter = {};
        let wayBillsFilter = [];
        if (waybills.length > 0) {
            // consignmentFilter = {"waybills":[`${waybills}`]};
            wayBillsFilter.push(waybills)
        }
        if (!_.isNull(pickUpLocations)) {
            consignmentFilter = {
                ...consignmentFilter,
                customerPickupLocationIds: [`${pickUpLocations}`]
            }
        }

        let mapFilters = [];
        if (!_.isEmpty(mapFilter)) {
            mapFilters = mapFilter;
        }
        if (!_.isEmpty(bookingFilters)) {
            consignmentFilter = { ...consignmentFilter, bookingFilters };
        }
        if (!_.isNull(locations)) {
            locations = locations;
        } else {
            locations = locationId
        }

        let filters = {
            "filters": {
                "isEmptyPodImgLinks": false,
                "waybills": wayBillsFilter,
                "pageNo": page,
                "locationIds": [`${locations}`],
                "pageSize": pageSize,
                "tripIds": mapFilters,
                consignmentFilter,
                "deviceFilter": {
                    "deviceType": "DESKTOP",
                    "view": "LITE_TRIP_LIST",
                    "requestingFrom": "COMPLETED_TRIPS"
                },
                "fetchObjects": ["THC_ROUTE", "THC_ASSET", "CONSIGNMENTS_CUSTOMER", "THCUSER", "CONSIGNMENT_CONTACTS", "LOCATIONS", "ADDRESS", "POD_APPROVAL"]
            }
        }

        if (!_.isNull(isEligibleToComplete)) {
            filters['filters']['isEligibleToComplete'] = isEligibleToComplete;
        }
        if (entityIds.length > 0) {
            filters['filters']['entityIds'] = entityIds;
        }

        this.props.getTripList(filters);
    }

    approvePOD = params => {
        this.props.approveAccept(params);
    }

    render() {

        return (
            <>
                <h3>Completed Trips {this.props.completedTripData?.totalCount ? <>({this.props.completedTripData?.totalCount})</> : <></>}</h3>
                <Spin spinning={this.props.tripLoading}>
                    <CompletedTripsList
                        refreshData={this.refreshData}
                        approvePOD={this.approvePOD}
                        {...this.props}
                    />
                </Spin>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    tripLoading: state?.completedList?.tripLoading,
    completedTripData: state?.completedList?.completedTripData,
    locationGroups: state?.trips?.locationGroups,
    locationGroupLoading: state?.trips?.locationGroupLoading,
    partnerAssets: state?.trips?.partnerAssets,
    approveLoading: state?.completedList?.approveLoading,
    approveData: state?.completedList?.approveData,
    rejectReasons: state?.completedList?.rejectReasons,
    header: state?.header,
    tripSettlements: state?.trips?.tripSettlements,
});
const mapDispatchToProps = dispatch => ({
    getTripList: (params) => dispatch(actions.getTripCompletedList(params)),
    getLocationGroups: (filters) => dispatch(tripAction.getLocationGroups(filters)),
    getPartnerAssets: () => dispatch(tripAction.getPartnerAssets()),
    approveAccept: (params) => dispatch(actions.approveAccept(params)),
    getRejectReasons: (params) => dispatch(actions.getRejectReasons(params)),
    fetchTripSettlements: (payload) => dispatch(tripAction.fetchTripSettlements(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompletedTrips);