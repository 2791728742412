const schema = {
    "type"        : "object",
    "properties"  : {
        "details" : {
            "required"   : [ "customer", "shipper", "serviceType", "pickupLocation", "indentDate" ],
            "type"       : "object",
            "properties": {
                "customer": {
                    "type"          : "string",
                    "title"         : "Customer Name",
                    "searchEnabled" : true
                },
                "shipper": {
                    "type"          : "number",
                    "title"         : "Shipper",
                    "searchEnabled" : true
                },
                "serviceType"   : {
                    "type"      : "string",
                    "title"     : "Service Type",
                    "enum"      : [ "10100", "10110" ],
                    "enumNames" : [ "PTL", "FTL" ],
                    "disabled"  : true
                },
                "pickupLocation"      : {
                    "type"            : "string",
                    "title"           : "Pickup Location",
                    "searchEnabled"   : true,
                    "enableAddOption" : true
                },
                "vehicleType"   : {
                    "type"      : "string",
                    "title"     : "Vehicle Type",
                    "disabled"  : true,
                    "searchEnabled"   : true
                },
                "indentDate"    : {
                    "type"      : "number",
                    "title"     : "Indent Date"
                }
            }
        },
        "remarksObj" : {
            "type"       : "object",
            "title"      : "Remarks/Instructions",
            "properties": {
                "remarks": {
                    "type"          : "string",
                    "title"         : "Customer Name"
                }
            }
        }
    }
}

const uiSchema = {
    "details"    : {
        "classNames": "multi-row-field-objects hide-title",
        "customer": {
            "ui:placeholder"      : "Select Customer",
            "ui:widget"           : "SelectAPI",
            "dropdownPlaceholder" : "Start Typing to search (min 3 charachters)...",
            "noContentFound"      : "No customer found!",
            "labelInValue"        : true
        },
        "shipper": {
            "ui:placeholder"      : "Select Shipper",
            "ui:widget"           : "SelectAPI",
            "dropdownPlaceholder" : "Start Typing to search (min 3 charachters)...",
            "noContentFound"      : "No shipper found!",
            "labelInValue"        : true,
            "disabled"            : true
        },
        "serviceType" : {
            "labelInValue"        : true,
            "ui:placeholder"      : "Select Service Type"
        },
        "pickupLocation": {
            "ui:placeholder"      : "Select Pickup Location",
            "ui:widget"           : "SelectAPI",
            "dropdownPlaceholder" : "Start Typing to search (min 3 charachters)...",
            "noContentFound"      : "No location found!",
            "labelInValue"        : true,
            "disabled"            : true
        },
        "vehicleType": {
            "ui:placeholder"      : "Select Vehicle Type",
            "dropdownPlaceholder" : "Start Typing to search (min 3 charachters)...",
            "noContentFound"      : "No vehicle type found!",
            "labelInValue"        : true
        },
        "indentDate": {
            "ui:placeholder"      : "Select Indent date",
            "ui:widget"           : "DateTimeWidget",
            "showTime"            : true
        }
    },
    "remarksObj" : {
        "remarks"         : {
            "classNames"  : "hide-title flex-1",
            "ui:widget"   : "TextareaWidget",
            "placeholder" : "Additional Remarks/Instructions",
            "rows"        : 6,
            "resize"      : false,
            "maxLength"   : 255
        }
    },
    "ui:submitButtonOptions": {
        "props": {
           "disabled": false
        },
        "submitText" : "Create",
        "alignEnd"   : true
    }
}

const FTLSchema = {
    "type"          : "object",
    "required"      : [ "pickupAt" ],
    "properties"    : {
        "lane"      : {
            "type"            : "string",
            "title"           : "Lane",
            "enableAddOption" : false            
        },
        "pickupAt" : {
            "type"          : "number",
            "title"         : "Placement Cut-off"
        }
    }
}

const FTLUiSchema = {
    "classNames"   : " multi-row-field-objects hide-title",
    "lane"         : {
        "ui:placeholder"      : "Enter Lane",        
    },
    "pickupAt" : {
        "ui:placeholder"      : "Select Placement Cut-off",
        "ui:widget"           : "DateTimeWidget",
        "showTime"            : true
    }
}

const errorList = {
    "details"    : {
        "customer"  : {
            "required"  : "Customer Name is mandatory!"
        },
        "shipper": {
            "required"  : "Shipper is mandatory!"
        },
        "serviceType" : {
            "required"  : "Service Type is mandatory!"
        },
        "pickupLocation": {
            "required"  : "Pickup location is mandatory!"
        },
        "vehicleType": {
            "required"  : "Vehicle Type is mandatory!"
        },
        "indentDate": {
            "required"  : "Indent Date is mandatory!"
        }
    },
    "ftlData"   : {       
        "pickupAt" : {
            "required"  : "Placement Cut-off is mandatory!"
        }
    }
}
export default { schema, uiSchema, FTLSchema, FTLUiSchema, errorList }