const itemRatesBOProperties = {
  "properties": {
    "rateStructure": {
      "type"      : "string",
      "enum"      : [ "FIXED", "BREAK_SLAB", "SLIDE_SLAB" ],
      "enumNames" : [ "Fixed", "Break Slab", "Slide Slab" ]
    }
  },
  "dependencies": {
    "rateStructure": {
      "oneOf": [
        {
          "required" : [ "amount" ],
          "properties"      : {
            "rateStructure" : { "enum"  : [ "FIXED" ] },
            "amount"        : {
              "type"        : "number",
              "title"       : "Amount (in Rs.)"
            }
          }
        },
        {
          "properties"      : {
            "rateStructure" : { "enum"  : [ "BREAK_SLAB" ] },
            "rateBOs"       : { "$ref"  : "#/definitions/slabs" }
          }
        },
        {
          "properties"      : {
            "rateStructure" : { "enum"  : [ "SLIDE_SLAB" ] },
            "rateBOs"       : { "$ref"  : "#/definitions/slabs" }
          }
        }
      ]
    }
  }
}

const chargesUiSchema = {
  "ui:widget"     : "radio",
  "classNames"    : "array-columns",
  "rateStructure" : {
    "ui:widget"   : "radio",
    "ui:title"    : "Structure",
    "classNames"  : "spacer-hs"
  },
  "amount"        : {
    "ui:prefix"   : "Rs.",
    "style"       : {
      "maxWidth"  : 300
    },
    "classNames"     : "spacer-hs",
    "ui:placeholder" : "Enter Amount"
  },
  "rateBOs"       : {
    "classNames"  : "hide-title array-columns spacer-hs",
    "ui:options"  : {
      "orderable" : false
    },
    "items"        : {
      "classNames" : "flex-1 hide-title",
      "fromRange"  : {
        "classNames"     : "flex-column flex-1 hide-spinners",
        "ui:placeholder" : "From Kg",
        "ui:suffix"      : "Kg",
        "type"           : "number"
      },
      "toRange"    : {
        "classNames"     : "flex-column flex-1 hide-spinners",
        "ui:placeholder" : "To Kg",
        "ui:suffix"      : "Kg",
        "type"           : "number"
      },
      "amount"     : {
        "classNames"     : "flex-column flex-1 hide-spinners",
        "ui:placeholder" : "Enter Rate",
        "ui:prefix"      : "Rs",
        "type"           : "number"
      }
    }
  }
}

const details = {
  "type"  : "object",
  "required"   : [ "itemsBO", "chargeBasis"],
  "properties" : {
    "itemsBO"  : {
      "type"   : "string",
      "title"  : "Box Type",
      "searchEnabled" : true
    },
    "chargeBasis" : {
      "type"      : "string",
      "title"     : "Basis",
      "enum"      : [ "PER_KG", "PER_PIECE", "PER_CFT" ],
      "enumNames" : [ "Per KG", "Per Piece", "Per CFT" ]
    }
  },
  "dependencies": {
    "chargeBasis": {
      "oneOf": [
        {
          "required"   : [ "chargeBasisValue" ],
          "properties": {
            "chargeBasis": {
              "enum": [ "PER_KG" ]
            },
            "chargeBasisValue": {
              "type"      : "string",
              "title"     : "Basis Value",
              "enum"      : [ "Actual weight", "Chargeable Weight", "Max (Actual, Chargeable Wt)" ]
            },
            "weightMultiplier" : {
              "type": "number",
              "title": "Weight Multiplier"
            }
          }
        },
        {
          "properties": {
            "chargeBasis": {
              "enum": [ "PER_PIECE", "PER_CFT" ]
            }
          }
        }
      ]
    }
  }
}  

const definitions = {
  "slabs"     : {
    "type"  : "array",
    "items" : {
      "required"     : ["fromRange", "toRange", "amount"],
      "properties"   : {
        "fromRange"  : {
          "title"    : "From",
          "type"     : "number"
        },
        "toRange" : {
          "title" : "To",
          "type"  : "number"
        },
        "amount"  : {
          "title" : "Amount (in Rs.)",
          "type"  : "number"
        }
      }
    }
  }
}

const ptlSchemaObj = {
  "type"       : "object",
  "properties" : {
    "details" : details,
    "freightCharge" : {
      "title"    : "Freight Charges",
      "type"     : "object",
      ...itemRatesBOProperties
    },
    "labourCharge"  : {
      "title" : "Labour Charge",
      "type"  : "object",
      ...itemRatesBOProperties
    }
  },
  "definitions" : definitions
}

const ftlSchemaObj = {
  "type"       : "object",
  "properties" : {
    "details" : details,
    "labourCharge"  : {
      "title" : "Labour Charge",
      "type"  : "object",
      ...itemRatesBOProperties
    }
  },
  "definitions" : definitions
}

const uiSchema = {
  "details"    : {
    "classNames"    : "multi-row-field-objects hide-title",
    "itemsBO"  : {
      "ui:placeholder"      : "Search Box Type",
      "ui:widget"           : "SelectAPI",
      "dropdownPlaceholder" : "Start Typing to search",
      "noContentFound"      : "No boxes found",
      "labelInValue"        : true
    },
    "chargeBasis" : {
      "ui:placeholder"      : "Select Basis",
    },
    "chargeBasisValue" : {
      "ui:placeholder"      : "Select Basis Value",
    },
    "weightMultiplier" : {
      "ui:placeholder"      : "Enter Weight Multiplier",
    }
  },
  "freightCharge" : chargesUiSchema,
  "labourCharge"  : chargesUiSchema,

}

const errorList = {
  "details"    : {
    "itemsBO"  : {
      "required"   : "Box Type is mandatory!",
      "ignoreType" : true
    },
    "chargeBasis" : {
      "required"   : "Basis is mandatory!",
    },
    "chargeBasisValue" : {
      "required"   : "Basis Value is mandatory!",
    },
    "weightMultiplier" : {
      "required"   : "Weight Multiplier is mandatory!",
    }
  }
}

export const  ftlSchema = {
  schema : ftlSchemaObj,
  uiSchema, errorList
};

export const ptlSchema  = {
  schema : ptlSchemaObj,
  uiSchema, errorList
} 