import { apiRequest } from "../../../common/utils/apiGateway";
import { Constants } from '../../../common/utils/constants';
import { FETCH_CUSTOMER_INVOICE_BEGIN, FETCH_CUSTOMER_INVOICE_SUCCESS, FETCH_CUSTOMER_INVOICE_FAILURE, FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE, FETCH_CUSTOMER_INVOICE_DETAILS_BEGIN, FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS, SETTLE_INVOICE_BEGIN, SETTLE_INVOICE_SUCCESS, SETTLE_INVOICE_FAILURE, FETCH_CUSTOMER_VIEW_ACTIVITY_BEGIN, FETCH_CUSTOMER_VIEW_ACTIVITY_SUCCESS, FETCH_CUSTOMER_VIEW_ACTIVITY_FAILURE, UPLOAD_DEBIT_NOTE_BEGIN, UPLOAD_DEBIT_NOTE_SUCCESS, UPLOAD_DEBIT_NOTE_FAILURE } from "./constants";

export const fetchCustomerInvoices = payload => dispatch => {
    dispatch(fetchInvoicesBegin());
    apiRequest({
        url: "/b2b/v1/fetch/customerInvoices",
        method: "POST",
        data: payload
    }).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res.data.status.code) > -1) {
            dispatch(fetchInvoicesSuccess(res.data ?.response));
        } else {
            dispatch(fetchInvoicesFailure());
        }
    });
};

function fetchInvoicesBegin() {
    return {
        type: FETCH_CUSTOMER_INVOICE_BEGIN
    };
}

function fetchInvoicesSuccess(invoices) {
    return {
        type: FETCH_CUSTOMER_INVOICE_SUCCESS,
        payload: invoices
    };
}

function fetchInvoicesFailure(error) {
    return {
        type: FETCH_CUSTOMER_INVOICE_FAILURE,
        payload: error
    };
}

export const fetchCustomerInvoicesDetails = payload => dispatch => {
    dispatch(fetchInvoicesDetailsBegin());
    apiRequest({
        url: "/b2b/v1/fetch/invoiceDetails",
        method: "POST",
        data: payload
    }).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res.data.status.code) > -1) {
            dispatch(fetchInvoicesDetailsSuccess(payload.invoiceId, res ?.data ?.response ?.invoiceDetails));
        } else {
            dispatch(fetchInvoicesDetailsFailure());
        }
    });
};

function fetchInvoicesDetailsBegin() {
    return {
        type: FETCH_CUSTOMER_INVOICE_DETAILS_BEGIN
    };
}

function fetchInvoicesDetailsSuccess(invoiceId, invoiceDetails) {
    return {
        type: FETCH_CUSTOMER_INVOICE_DETAILS_SUCCESS,
        payload: {
            data: invoiceDetails,
            invoiceId: invoiceId
        }
    };
}

function fetchInvoicesDetailsFailure(error) {
    return {
        type: FETCH_CUSTOMER_INVOICE_DETAILS_FAILURE,
        payload: error
    };
}

export const settleCustomerInvoice = payload => dispatch => {
    dispatch(settleInvoiceBegin());
    apiRequest({
        url: "/b2b/v1/invoice/settle",
        method: "POST",
        data: payload
    }).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res.data.status.code) > -1) {
            dispatch(settleInvoiceSuccess('SUCCESS'));
        } else {
            dispatch(settleInvoiceFailure());
        }
    });
};

function settleInvoiceBegin() {
    return {
        type: SETTLE_INVOICE_BEGIN
    };
}

function settleInvoiceSuccess(invoiceDetails) {
    return {
        type: SETTLE_INVOICE_SUCCESS,
        payload: invoiceDetails
    };
}

function settleInvoiceFailure(error) {
    return {
        type: SETTLE_INVOICE_FAILURE,
        payload: error
    };
}

export const fetchCustomerActivity = (invoiceId) => dispatch => {
    dispatch({
        type: FETCH_CUSTOMER_VIEW_ACTIVITY_BEGIN
    });
    apiRequest({
        url: "/b2b/v1/invoice/tracking",
        method: "post",
        data: {
            invoiceId
        }
    }).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res.data.status.code) > -1) {
            dispatch({
                type: FETCH_CUSTOMER_VIEW_ACTIVITY_SUCCESS,
                payload: res.data.response.invoiceTransactions
            });
        } else {
            dispatch({
                type: FETCH_CUSTOMER_VIEW_ACTIVITY_FAILURE,
            });
        }
    })
}

export const uploadDebitNote = (payload) => dispatch => {
    dispatch({
        type: UPLOAD_DEBIT_NOTE_BEGIN
    });
    apiRequest({
        url: "/b2b/v1/uploads/consignmentDebits",
        method: "post",
        data: payload
    }).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res.data.status.code) > -1) {
            dispatch({
                type: UPLOAD_DEBIT_NOTE_SUCCESS,
                payload: res.data.response
            });
        } else {
            dispatch({
                type: UPLOAD_DEBIT_NOTE_FAILURE,
            });
        }
    })
}