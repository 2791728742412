import { Icon } from 'antd';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../ui/notification/Notification';

class NameValuePair extends Component{
    constructor(props){
        super(props)
    }

    notifyCopyEvent = (copiedParam) => {
        openNotification({
            message: 'Copied',
            description: copiedParam + ' has been copied to clipboard',
            className: 'warning'
        });
    }
    
    render(){
        const nameClass = this.props.hasOwnProperty("nameClass") ? this.props.nameClass : "grey-text";
        const valueClass = this.props.hasOwnProperty("nameClass") ? this.props.valueClass : "text-bold";
        return (
            <div className="flex-box flex-gap-m">
                <div className={nameClass}> { this.props.name } </div> :
                <div className={valueClass}> { this.props.value } </div>
                {
                    this.props.enableCopy && 
                    <CopyToClipboard text={this.props.value} onCopy={() => this.notifyCopyEvent(this.props.name)}>
                        <Icon type="copy" />
                    </CopyToClipboard>       
                }
            </div>
        );
    }
}

export default NameValuePair;