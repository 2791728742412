import { Button, Modal } from 'antd';
import moment from 'moment';
import React , { Component } from 'react';
import { cloneObject } from '../../../../common/utils/utils';
import { Mixpanel } from '../../../../utils/mixpanel';
import DepsRow from './deps-row';

class PodDeps extends Component{

    constructor(props) {
        super(props);
        this.state = {
            disableEdit: false,
            depsDeleted : false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.consignmentDetailsUpdated && nextProps?.consignment?.consignmentId) {
            var existingDepsTypes = [];
            if (nextProps.consignment?.deps?.length > 0) {
                existingDepsTypes = nextProps.consignment.deps.reduce((current, next) => {
                    current.push(next.depsType);
                    return current;
                }, []);
            }
            else{
                nextProps.consignment.deps = [];
            }
            return {
                consignment: cloneObject(nextProps.consignment),
                deps: cloneObject(nextProps.consignment.deps),
                disableEdit: false,
                depsDeleted : prevState.depsDeleted,
                existingDepsTypes
            }
        }
        return prevState;
    }

    addDeps = () => {
        Mixpanel.track("POD Verification: Adding DEPS", { "podVerificationAddingDeps": moment().valueOf() });
        if(this.state.disableEdit){
            return Modal.error({
                title: 'Unsaved DEPS',
                content: 'Save or reset DEPS changes to add another one!'
            })
        }
        this.setState(current => {
            current.deps.push({
                depsType: false,
                remarks: '',
                images: []
            });
            return current;
        });
    }

    onDepsDelete = (delIndex) => {
        this.setState(current => {
            current.deps[delIndex] = undefined;
            current.existingDepsTypes = current.existingDepsTypes.filter((deps, index) => index != delIndex );
            current.depsDeleted  = true;
            return current;
        }, () => {
            setTimeout(() => {
                this.setState({ depsDeleted: false});
            }, 250);
            this.props.onDepsDelete(this.state.deps);
        });
    }

    toggleEditDeps = status => {
        this.setState({ disableEdit: status });
        this.props.onEditEnabled(status);
    }

    onDepsUpdate = (updatedDepsItem, index, editStatus) => {
        this.setState(current => {
            current.existingDepsTypes[index] = updatedDepsItem.depsType;
            current.deps[index] = updatedDepsItem;
            return current;
        }, () => {
            this.toggleEditDeps(editStatus != undefined ? editStatus : false);
            this.props.onDepsUpdate(updatedDepsItem, index);
        });
    }
    
    render() {

        const { disableEdit, existingDepsTypes } = this.state;
        const { editPermissions, createPermissions, deletePermissions } = this.props.verificationPermissions;
        const verifiedPod = this.state.consignment.podVerificationStatus == "POD_VERIFIED";

        return (
            <div className="flex-column justify-content-space-evenly flex-gap-l border-radius-m border-grey spacer-m">
                <div className="font-size-m-3 text-bold"> DEPS </div>
                <div className="flex-box flex-gap-l text-bold align-items-center spacer-hm">
                    <div className="flex-2"> DEPS Type </div>
                    <div className="flex-2"> DEPS Remarks </div>
                    <div className="flex-4"> DEPS Images </div>
                    {
                        (createPermissions || editPermissions || deletePermissions) && 
                        <div className="flex-1 flex-box flex-gap-l justify-content-end align-items-center"> Actions </div>
                    }
                </div>
                {
                    this.state.deps.length > 0 && this.state.deps.filter(depsItem => depsItem!=undefined).length>0 ?
                    this.state.deps.map((depsItem, index) => {
                        return (
                            depsItem &&
                            <DepsRow depsItem={depsItem} key={index} existingDepsTypes={existingDepsTypes} disableEdit={disableEdit}
                                verifiedPod={verifiedPod}
                                onDepsUpdate={this.onDepsUpdate} toggleEditDeps={this.toggleEditDeps} index={index}
                                onDepsDeleteItem={this.onDepsDelete} consignmentDetailsUpdated={this.props.consignmentDetailsUpdated}
                                depsDeleted={this.state.depsDeleted} defaultDepsItem={this.state.consignment.deps[index]}
                                verificationPermissions={this.props.verificationPermissions} />
                        )
                    }) :
                    <div className="flex-box text-bold align-items-center justify-content-center dark-grey-bg spacer-l" style={{height:'45px'}}>
                        No DEPS added.
                    </div>
                }

                {
                    createPermissions && !verifiedPod &&
                    <div>
                        <Button type="primary" onClick={this.addDeps}> Add DEPS </Button>
                    </div>
                }

                <Modal/>
            </div>
        )
    }
}

export default PodDeps;