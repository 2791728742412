import lovActionConstants from "./lovActionConstants";

export const lovStore = (state = {
    lovCodes : {},
    lovConstants : {},
    vehicleTypes : []
}, action) => {

    switch (action.type) {
        case lovActionConstants.SAVE_LOV_CODES      : return storeLovCodes(state, action);
        case lovActionConstants.SAVE_LOV_CONSTANTS  : return saveLovConstants(state, action);
        case lovActionConstants.SAVE_VEHICLE_TYPES  : return saveVehicleTypes(state, action);
        default: return state;
    }
}

function storeLovCodes (state, action) {
    state.lovCodes[action.category] = action.lovs;
    return state;
}

function saveLovConstants (state, action) {
    state.lovConstants[action.category] = action.lovConstants;
    return state;
}

function saveVehicleTypes (state, action) {
    state.vehicleTypes = action.payload;
    return state;
}