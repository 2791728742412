import { localStorageUtils, parsePermission } from '../common/utils/utils'
import { LOCAL_STORAGE_STATE, USER_LOGGEDIN_INFO } from '../common/utils/constants'

export class Permissions{
  /**
   * to store into local storage
   * @param permissions
   */

  _permissions = null;
  _selectedApplication = '';

  static storeLoginData(permissions){
    if (permissions == null) { return null; }

    /*
      Should be updated only on login
      localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.accessToken, key: USER_LOGGEDIN_INFO.PRIMARY_ACCESS_TOKEN});
      localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.tokenId, key: USER_LOGGEDIN_INFO.PRIMARY_ACCESS_ID});
    */

    if (permissions
      && permissions.token
      ){

      // Store only if primary access token is no found, not while changing the header location
      if(!localStorageUtils({ type: LOCAL_STORAGE_STATE.GET, key: USER_LOGGEDIN_INFO.PRIMARY_ACCESS_TOKEN })){

        localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.accessToken, key: USER_LOGGEDIN_INFO.PRIMARY_ACCESS_TOKEN});
        localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.tokenId, key: USER_LOGGEDIN_INFO.PRIMARY_ACCESS_ID});

        // Donot set USER object from login data
        if(permissions
          && permissions.user){
            
            localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: btoa(JSON.stringify(parsePermission(permissions.user.permissions))), key: USER_LOGGEDIN_INFO.PERMISSION});
            localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: btoa(permissions.user.permissions[0][USER_LOGGEDIN_INFO.APPLICATION]), key: USER_LOGGEDIN_INFO.APPLICATION});
            localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: btoa(JSON.stringify(permissions.user)), key: USER_LOGGEDIN_INFO.USER});
        }
      }
      if(permissions?.updateUserPartner && permissions.user) {
        let user = JSON.parse(atob(localStorage.getItem('user')));
        user = {
          ...user,
          partner : {
            ...permissions.user.partner
          }
        };
        localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: btoa(JSON.stringify(user)), key: USER_LOGGEDIN_INFO.USER});
        
      }

      if(permissions.currentLocationName){
        localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.currentLocationName, key: USER_LOGGEDIN_INFO.CURRENT_LOCATION_NAME});
      }

      if(permissions.currentPartnerName){
        localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.currentPartnerName, key: USER_LOGGEDIN_INFO.CURRENT_PARTNER_NAME});
      }

      localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.accessToken, key: USER_LOGGEDIN_INFO.ACCESS_TOKEN});
      localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.tokenId, key: USER_LOGGEDIN_INFO.TOKEN_ID});
      localStorageUtils({ type: LOCAL_STORAGE_STATE.SET, value: permissions.token.refreshToken, key: USER_LOGGEDIN_INFO.REFRESH_TOKEN});
    }
  }

  /**
   * it will make all permission into groups with hashmap
   * group order will be "application => module => sub module"
   * @param permissions
   */

  // parsePermission(permissions) {
  //   if (!permissions) { return null; }
  //   const pObj = {};
  //   permissions.forEach((permission) => {

  //     if (!pObj[permission.application]) { pObj[permission.application] = {}; }

  //     if (!pObj[permission.application][permission.module]) { pObj[permission.application][permission.module] = { permissions: [] }; }

  //     if (permission.submodule == null) {
  //       pObj[permission.application][permission.module].permissions.push(permission.action);
  //     } else {
  //       if (!pObj[permission.application][permission.module][permission.submodule]) {
  //         pObj[permission.application][permission.module][permission.submodule] = { permissions: [] };
  //       }
  //       pObj[permission.application][permission.module][permission.submodule].permissions.push(permission.action);
  //     }
  //   });
  //   return pObj;
  // }

  /**
   * to get all permissions
   */
  static getAllPersmission() {
    const permissions = localStorage.getItem('permissions');
    if (!permissions) {
      alert('asking auth service to log out the user');
      return
    }
    this._selectedApplication = atob(localStorage.getItem('application'));
    return JSON.parse(atob(permissions));
  }

  /**
   * It checks permission allowed or not for logged in user
   * @param {IPermissionTags} tags
   * @returns {boolean}
   */
  static canAllowMenuItems(menuItem) {
    if (this._permissions == null) { this._permissions = this.getAllPersmission(); }
    if (this._permissions == null) { return null; }
    const pApplication = this._permissions[this._selectedApplication];

    if (pApplication && pApplication[menuItem.label]) {
      menuItem.children.map((item) => {
        if (pApplication[menuItem.label][item.label]){
            menuItem.canAllow = true;
            item.canAllow =  true;
        }
      })
    }
    return menuItem
  }

  static getPermittedMenuItems(menuitems) {
    menuitems.map((menuitem) => {
      this.canAllowMenuItems(menuitem)
    })
    return menuitems;
  }

  get currentUserPermissions() {
    if (this._permissions == null) {
      this._permissions = this.getAllPersmission();
    }
    return this._permissions;
  }

  get application() {
    return this._selectedApplication;
  }

  set permissions(permissions) {
    this._permissions = permissions;
  }


  
}
