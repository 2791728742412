import * as apiUtils from '../../../common/utils/apiGateway';
import { checkAPIHttpStatus } from '../../../common/utils/utils';
import { openNotification, destroyNotification } from '../../ui/notification/Notification';

export const fetchPods = (filterPayload) => new Promise(resolve => {
    apiUtils.apiRequest({
        url: `/b2b/v1/consignmentpod/verification/fetch`,
        data: filterPayload,
        method: "POST"
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            let podsData = response?.data?.response;
            if (podsData.consignmentDetails.length <= 0) {
                openNotification({
                    message: "Error",
                    description: "No dockets were founds for the selected criteria.",
                    className: "error-notification"
                });
                setTimeout(() => {
                    destroyNotification();
                }, 2500);
            }
            resolve(podsData);
        } else {
            resolve([]);
        }
    });
})

export const updatePodDetails = podData => {
    var postObj = getPostObject(podData);
    return postPodDetails(postObj);
}

export const postPodDetails = postRequest => new Promise(resolve => {
    apiUtils.apiRequest({
        url: 'b2b/v1/consignmentpod/lite/update/podImg',
        method: "POST",
        data: postRequest
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response);
        } else {
            reject(false);
        }
    });
})

export const verifyPod = (podData) => {
    var postObj = {
        request : [{
            ...getPostObject(podData).request[0],
            podApproval: {
                podApprovalStatus: "APPROVED",
                reasons: []
            }
        }]
    }
    return postPodDetails(postObj);
}

export const getPODHistory = (payload) => new Promise(resolve => {

    apiUtils.apiRequest({
        url: 'b2b/v1/consignmentpod/verification/fetch/events',
        method: "POST",
        data: payload
    }).then(response => {

        if (checkAPIHttpStatus(response?.data?.status)) {
            let res = response?.data?.response;
            resolve(response?.data?.response);
        } else {
            resolve({});
        }
    });
})

export const getRejectReasons = () => new Promise(resolve => {
    apiUtils.apiRequest({
        url: 'b2b/v1/consignmentVerifications/rejectedReasons/POD',
        method: "GET",
    }).then(response => {
        if (checkAPIHttpStatus(response?.data?.status)) {
            let res = response?.data?.response;
            resolve(res);
        } else {
            resolve({});
        }
    });
})

export const rejectPOD = (podData, rejectReasons, rejectComment) => {
    var postObj = {
        request : [{
            ...getPostObject(podData).request[0],
            podApproval : {
                podApprovalStatus : "REJECTED",
                reasons : rejectReasons,
                ...(rejectComment && {
                    rejectionRemarks : rejectComment
                })
            }
        }]
    }
    return postPodDetails(postObj);
}

const getPostObject = podData => {
    var depsBO = [];
    if(podData.deps.length > 0){
        podData.deps.map(deps => {
            if(deps && deps.depsType){
                depsBO.push({
                    id : deps.id ? deps.id : undefined,
                    consignmentId :podData.consignmentId,
                    waybillNo : podData.waybillNumber,
                    depsType : deps.depsType,
                    remarks : deps.remarks,
                    images : deps?.images ? deps.images : []
                });
            }
        })
    }
    let postRequest = {
        request: [{
            action: 'UPDATE',
            wayBillNo: podData.waybillNumber,
            referenceId: podData.waybillNumber,
            
            consignmentPodBO:{
                consignmentId: podData.consignmentId,
                waybillNo: podData.waybillNumber,
                podImpLink: podData?.podImages ? podData.podImages.join(',') : ""
            },
            userRemarks: podData.userRemarks,
            verificationRemarks: podData.verificationRemarks,
            deliveryDate: podData.podCreatedDate,
            depsBO: depsBO,
    
            addShipmentsToCompletedTrip: false,
            isHyperlocalConsignment: false,
            isPODUploadB2B:true,
            isBulkUploadPOD:false
        }]
    }
    return postRequest;
}