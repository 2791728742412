import React, { PureComponent } from 'react';

import {
    Card, Row, Col, Button, Icon, Table, Spin, Input, Dropdown, Modal, Collapse,
    Select, DatePicker, Empty
} from 'antd';

import Filters from '../../../assets/img/icons/filters.svg';
import _ from 'lodash';
import moment from 'moment';
import TimeTemplate from '../../cod/templates/time'
import CurrencyTemplate from '../../cod/templates/Currency'
import PickupIcon from '../../../assets/img/icons/pickup_icon.svg';
// import { ImageGroup, Image } from 'react-fullscreen-image'
import Lightbox from 'react-image-lightbox-rotate-fixed';
import 'react-image-lightbox/style.css'
import './completed.scss';

const { Panel } = Collapse;
const { Option } = Select;


class CompletedTripList extends PureComponent {

    state = {
        tableLoading: false,
        podData: {},
        visiblePODModal: false,
        showFilters: false,
        enableClose: false,
        listingFilters: {
            bookingFrom: '',
            bookingTo: '',
            tripId: '',
            tripStatusValue: '',
            location: undefined,
            assetFilter: undefined,
            pickuplocation: undefined,
            rejectPopup: false,
            selectedReason: undefined
        },
        showTripSettlement: false,
        isOpen: false,
        selectedTripId: 0,
        tripSettlementColumns: [
            {
                title: 'UTR No.',
                dataIndex: 'utr',
                key: 'utr',
            },
            {
                title: 'Date and Time',
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                render: data => <TimeTemplate time={data} />
            },
            {
                title: 'Activity',
                dataIndex: 'Activity',
                key: 'Activity',
                render: (data, row) => row?.vendor?.name
            },
            {
                title: 'User',
                dataIndex: 'User',
                key: 'User',
                render: (data, row) => row?.user?.name
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: amt => <CurrencyTemplate data={amt} />
            },
            {
                title: 'Payment Type',
                dataIndex: 'paymentMode',
                key: 'paymentMode',
            },
            {
                title: 'Remarks',
                dataIndex: 'paymentDescription',
                key: 'paymentDescription',
            },
        ]
    }

    customExpandIcon = props => {
        if (props.expanded) {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><Icon type="caret-down" /></a>
        } else {
            return <a style={{ color: 'black' }} onClick={e => {
                props.onExpand(props.record, e);
            }}><Icon type="caret-right" /></a>
        }
    }

    viewPOD = docket => {
        this.setState({ visiblePODModal: true, podData: docket });
    }

    cancelVisiblePODModal = () => {
        this.setState({ visiblePODModal: false, podData: {}, rejectPopup: false });
        this.props.refreshData(1, 10);
    }

    approve = () => {


        let params = {
            "requests": [
                {
                    "podApproval": {
                        "approvalStatus": "POD_CLEAN",
                        "entityCode": this.state.podData.waybillNo,
                        "entityType": "CONSIGNMENT",
                        "approvedBy": this.props.header.loggedInUser.id,
                        "entityId": this.state.podData.id,
                        "podId": this.state.podData.consignmentPODBO.map(pod => pod.id)[0]
                    },
                    "action": "CREATE",
                    "referenceId": this.state.podData.waybillNo
                }
            ]
        };


        this.props.approvePOD(params);
        setTimeout(() => {
            this.cancelVisiblePODModal();
        }, 2000)
    }

    expandedRowRender = (trip, index, indent, expanded) => {


        const columns = [
            {
                title: 'Docket No', dataIndex: 'waybillNo', key: 'waybillNo',
                render: (waybillNo, row) =>
                    <>
                        {waybillNo}
                        {
                            row.consignPODBO.length <= 0 ? <></> : (row.consignPODBO[0].approvalStatus == 'POD_UNCLEAN' ? <>&nbsp; <Icon style={{ color: 'red' }} type="warning" /></> : <></>)

                        }
                    </>
            },
            { title: 'Customer', dataIndex: 'customerName', key: 'customerName' },
            { title: 'Destination', dataIndex: 'destination', key: 'destinatino' },
            { title: 'Pieces/Weight', dataIndex: 'quantity', key: 'quantity' },
            { title: 'Date', dataIndex: 'date', key: 'date' },
            {
                title: 'Actions',
                key: 'operation',
                render: docket => <>
                    {

                        docket.consignmentStatus === "DELIVERED" ? <Col span={2}><Button onClick={() => this.viewPOD(docket)}>View POD</Button></Col> : <></>

                    }
                </>
            }
        ];

        const data = [];
        trip.thcbos && trip.thcbos.map(thc => {
            thc.manifests.map((manifest, index) => {
                return manifest?.consignments?.map(consign => {
                    console.log("consign->>", consign)
                    let consignPODBO = consign.consignmentPODBO.map(pod => pod.podApproval)[0];
                    // console.log("consign->>",consignPODBO)
                    data.push({
                        'key': trip.id + consign.waybillNo + index,
                        'waybillNo': consign.waybillNo,
                        'customerName': consign.customer.name,
                        'destination': <>{consign.customerPickupLoc.address.pincode.city.cityCode}-{consign.consignee.address.pincode.city.cityCode}</>,
                        'quantity': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                        'date': moment(consign.bookingDate).format("DD-MMM-YYYY HH:MM"),
                        'manifestCode': manifest.manifestCode,
                        'manifestId': manifest.id,
                        'assetId': trip.asset.id,
                        'tripId': trip.id,
                        'consignPODBO': consignPODBO,
                        ...consign
                    })


                })
            })
        })

        trip.drsbos && trip.drsbos.map(thc => {

            return thc?.consignments?.map(consign => {
                console.log("consign->>", consign)
                let consignPODBO = consign.consignmentPODBO.map(pod => pod.podApproval)[0];
                // console.log("consign->>",consignPODBO)
                data.push({
                    'key': trip.id + consign.waybillNo + index,
                    'waybillNo': consign.waybillNo,
                    'customerName': consign.customer.name,
                    'destination': <>{consign.customerPickupLoc.address.pincode.city.cityCode}-{consign.consignee.address.pincode.city.cityCode}</>,
                    'quantity': <>{`${consign.totalShipmentCount} Pcs`} / {`${consign.totalWeight} kgs`}</>,
                    'date': moment(consign.bookingDate).format("DD-MMM-YYYY HH:MM"),

                    'assetId': trip.asset.id,
                    'tripId': trip.id,
                    'consignPODBO': consignPODBO,
                    ...consign



                })
            })
        })

        return (
            <Card
            // style={{ backgroundColor: 'transparent' }}
            >
                <Table
                    bordered
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            </Card>
        )

    }

    searchWayBill = e => {
        let text = e.target.value;
        this.setState({ wayBillInput: text });
        if (text.length == 0) {
            this.setState({ enableClose: false });
            return;
        }

        this.setState({ enableClose: true });


    }

    keyPressed = e => {

        if (e.key === "Enter") {
            this.props.refreshData(1, 10, this.state.wayBillInput);
        }
    }

    clearSearch = () => {
        this.setState({ enableClose: false, wayBillInput: '' });
        this.props.refreshData(1, 10)
    }

    modalShowFilters = () => {
        this.setState({ showFilters: true })
    }

    cancelFilters = () => {
        this.setState({ showFilters: false })
    }

    changeFilterTripID = e => {
        let { tripId } = this.state.listingFilters;
        tripId = e.target.value;

        this.setState({ listingFilters: { ...this.state.listingFilters, tripId } });
    }

    selectBookingTo = (date, dateString) => {
        let { bookingTo } = this.state.listingFilters;
        bookingTo = date;

        this.setState({ listingFilters: { ...this.state.listingFilters, bookingTo } });
    }

    selectBookingFrom = (date, dateString) => {
        let { bookingFrom } = this.state.listingFilters;
        bookingFrom = date;



        this.setState({ listingFilters: { ...this.state.listingFilters, bookingFrom } });
    }

    selectLocationFilter = locations => {
        let { location } = this.state.listingFilters;
        location = locations;
        this.setState({ listingFilters: { ...this.state.listingFilters, location } });

    }

    setAssetFilters = asset => {
        let { assetFilter } = this.state.listingFilters;
        assetFilter = asset;
        this.setState({ listingFilters: { ...this.state.listingFilters, assetFilter } });
    }

    setPickupLocationFilter = locations => {
        let { pickuplocation } = this.state.listingFilters;
        pickuplocation = locations;
        this.setState({ listingFilters: { ...this.state.listingFilters, pickuplocation } });

    }


    applyFilters = () => {
        let bookingFilters = {};
        let isEligibleToComplete = null;

        if (_.isDate(new Date(this.state.listingFilters.bookingFrom)) || _.isDate(new Date(this.state.listingFilters.bookingTo))) {
            bookingFilters = {
                "bookingDate": {
                    "from": this.state.listingFilters.bookingFrom.valueOf(),
                    "to": this.state.listingFilters.bookingTo.valueOf(),
                }
            };
        }



        let mapFilter = [];

        if (!_.isEmpty(this.state.listingFilters.tripId)) {
            mapFilter.push(`${this.state.listingFilters.tripId}`);
        };

        let locations = null;
        if (!_.isUndefined(this.state.listingFilters.location)) {
            locations = this.state.listingFilters.location;
        }

        let pickUpLocations = null;
        if (!_.isUndefined(this.state.listingFilters.pickuplocation)) {
            pickUpLocations = this.state.listingFilters.pickuplocation;
        }

        let entityIds = [];
        if (!_.isUndefined(this.state.listingFilters.assetFilter)) {
            entityIds.push(this.state.listingFilters.assetFilter);
        }


        this.props.refreshData(1, 10, '', bookingFilters, mapFilter, isEligibleToComplete, locations, entityIds, pickUpLocations);
        this.setState({ showFilters: false })

    }

    resetFilters = () => {
        let { tripId, bookingFrom, bookingTo, location, assetFilter } = this.state.listingFilters;
        tripId = '';
        bookingFrom = '';
        bookingTo = '';
        location = undefined;
        assetFilter = undefined;

        this.setState({ listingFilters: { ...this.state.listingFilters, bookingFrom, bookingTo, tripId, location, assetFilter }, showFilters: false }, () => {
            this.props.refreshData(1, 10, '', {}, {}, []);
        })

    }

    changePage = (page, pageSize) => {

        this.setState({ tableLoading: true })
        this.props.refreshData(page, pageSize)
    }

    openRejectPopup = () => {
        this.setState({ rejectPopup: true });
    }

    selectRejectReason = value => {

        this.setState({ selectedReason: value })
    }

    confirmRejectReason = () => {


        let params = {
            "requests": [
                {
                    "podApproval": {
                        "approvalStatus": "POD_UNCLEAN",
                        "entityCode": this.state.podData.waybillNo,
                        "entityType": "CONSIGNMENT",
                        "reasonId": this.state.selectedReason,
                        "approvedBy": this.props.header.loggedInUser.id,
                        "entityId": this.state.podData.id,
                        "podId": this.state.podData.consignmentPODBO.map(pod => pod.id)[0]
                    },
                    "action": "CREATE",
                    "referenceId": this.state.podData.waybillNo
                }
            ]
        };


        this.props.approvePOD(params);
        setTimeout(() => {
            this.cancelVisiblePODModal();
        }, 1500)

    }

    addRTO = (trip) => {
        // console.log("trip.->>", trip, JSON.stringify(trip));

        this.props.history.push("/appv2/trips/form/trips", { "completed": trip });
    }
    toggleTripSettlememtModal = (isOpen = false) => {
        this.setState({
            showTripSettlement: isOpen
        })
    }

    fetchTripSettlements = row => {
        this.setState({
            selectedTripId: row.id
        })
        const payload = {
            "filters": {
                "ids": [],
                "tripIds": [row.id],
                "entityIds": [],
                "utrs": [

                ],
                "paymentTypes": [],
                "entityTypes": [],
                "paymentDate": {
                    "from": "",
                    "to": ""
                },
                "uploadIds": [],
                "amount": {
                    "min": "",
                    "max": ""
                }
            }
        }
        this.props.fetchTripSettlements(payload)
    }


    render() {

        let length = 0;
        let thcLength = 0, thcTotalWeights = 0, drbTotalWeights = 0, thcSinglePiece = 0, drbSinglePiece = 0, drbsLength = 0;
        const columns = [
            { title: 'Trip ID', dataIndex: 'id', key: 'id', width: 100 },
            {
                title: (<>Trip Type/ <br /> Load Type</>),
                dataIndex: 'tripType',
                render: (trip, data) => (
                    <>
                        {data?.attributes?.tripType !== 'CL' ? <> {data?.attributes?.isAdhocTrip === "true" ? 'ADHOC' : 'Planned'}</> : <></>}
                        <br />
                        {data?.attributes?.tripType}  {data?.attributes?.tripType !== 'CL' ? <> / {data?.attributes?.tripLoadType} </> : <></>}
                        <br />
                        {data?.prses?.length > 0 ? <img src={PickupIcon} /> : <></>}
                        <Icon type="info-circle" onClick={() => { this.fetchTripSettlements(data); this.toggleTripSettlememtModal(true) }} />
                    </>)
            },
            { title: 'Vehicle No.', dataIndex: 'vehicleNumber', key: 'vehicleNumber', width: 200 },
            {
                title: 'Route',
                dataIndex: 'routeName',
                key: 'routeName',
                width: 200
            },
            {
                title: 'Dockets', key: 'dockets', render: trip =>
                    // <>
                    //     {trip.dockets.thcbos && trip.dockets.thcbos.length > 0 && trip.dockets.thcbos.map(thc => {
                    //         length = 0;
                    //         thc.manifests.map(manifest => {
                    //             length += manifest?.consignments?.length ? manifest.consignments.length : 0;
                    //         })
                    //     })}

                    //     {trip.dockets.thcbos && trip.dockets.thcbos.length > 0 ? `${length} / ${length} Pcs` : `0 / 0 Pcs`}
                    // </>

                    <>
                        {trip.dockets.thcbos && trip.dockets.thcbos.length > 0 && trip.dockets.thcbos.map(thc => {
                            thcLength = 0;
                            thcSinglePiece = 0;
                            thcTotalWeights = 0;
                            thc.manifests.map(manifest => {
                                thcSinglePiece += manifest?.consignments?.length ? manifest.consignments.length : 0;
                                manifest.consignments && manifest.consignments.length > 0 && manifest.consignments.map(consign => {
                                    thcTotalWeights += consign.totalWeight;
                                    thcLength += consign.totalShipmentCount;
                                })
                            })

                        })}
                        {trip.dockets.drsbos && trip.dockets.drsbos.length > 0 && trip.dockets.drsbos.map(thc => {
                            drbSinglePiece = 0
                            drbsLength = 0;
                            drbTotalWeights = 0;
                            //  length = 0;

                            drbSinglePiece += thc?.consignments?.length ? thc.consignments.length : 0;
                            thc.consignments && thc.consignments.length > 0 && thc.consignments.map(consign => {
                                drbTotalWeights += consign.totalWeight;
                                drbsLength += consign.totalShipmentCount;
                            })

                        })}

                        {trip.dockets.thcbos && trip.dockets.thcbos.length > 0 ?
                            `${thcSinglePiece} / ${thcLength} Pcs` : trip.dockets.drsbos && trip.dockets.drsbos.length > 0 ? `${drbSinglePiece} / ${drbsLength} Pcs` :
                                `0 / 0 Pcs`
                        }
                        <br />
                        {trip.dockets.thcbos && trip.dockets.thcbos.length > 0 ? <span className="form-label-text">{thcTotalWeights.toFixed(2)} Kgs</span> :
                            trip.dockets.drsbos && trip.dockets.drsbos.length > 0 ? <span className="form-label-text">{drbTotalWeights.toFixed(2)} Kgs</span> : `0 Kgs`}
                    </>
            },
            {
                title: 'Created By', key: 'user', render: trip =>
                    <>{trip.user.thcbos && trip.user.thcbos.length > 0 ? trip.user.thcbos[0]?.thcUser?.name : trip?.user?.attributes?.name}</>, width: 150
            },
            {
                title: 'Actions',
                key: 'operation',
                width: 90,
                render: trip =>
                    <>
                        {trip.status === 'FINISHED' && trip.attributes.tripType == 'DV' ?
                            <Row type="flex" className="floting-left">

                                {/* <Col span={2}>
                                    <Button onClick={() => this.addRTO(trip)}>ADD RTO</Button>
                                </Col> */}
                            </Row>
                            : <></>
                        }

                        {/* <Row className="floating-right">
                            <Col span={2}>
                                <div>
                                    <Dropdown overlay={menu(trip)} trigger={['click']}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                            <Icon type="more" />
                                        </a>

                                    </Dropdown>
                                </div>

                            </Col>
                        </Row> */}
                    </>
            },
        ];
        let tripListing = null;

        let data = [];

        let tripsData = this.props.completedTripData?.viewResponse;
        if (this.state.tableLoading) {
            this.setState({ tableLoading: false })
        }

        if (tripsData && tripsData.length > 0) {

            tripsData.map(trip => {


                let name = trip.thcbos && trip.thcbos.length > 0 ?
                    trip.thcbos.map(thc => thc.route.name) :
                    trip.drsbos && trip.drsbos.length > 0 ?
                        trip.drsbos.map(drs => drs.attributes.routeName) :
                        <>{trip.asset.vehicleType} - {trip.asset.vehicleNumber}</>;
                data.push(
                    {
                        id: trip.id,
                        vehicleNumber: trip.asset.vehicleNumber,
                        routeName: _.isArray(name) ? name[0] : name,
                        dockets: trip,
                        user: trip,
                        ...trip
                    }
                )
            });



            tripListing = <Table
                bordered
                loading={this.state.tableLoading}
                expandIcon={(props) => this.customExpandIcon(props)}
                columns={columns}
                dataSource={data}
                expandedRowRender={this.expandedRowRender}
                pagination={{
                    total: this.props.completedTripData.totalCount,
                    current: this.props.completedTripData.currentPageNo,
                    onChange: this.changePage
                }}
            />
        }





        return (
            <>
                <Card>
                    <Row>
                        <Col span={10}>
                            <Input
                                onChange={this.searchWayBill}
                                onKeyPress={this.keyPressed}
                                value={this.state.wayBillInput}
                                prefix={<Icon type="search" />}
                                placeholder="Search by Docket"
                                suffix={this.state.enableClose ? <Icon onClick={this.clearSearch} type="close-circle" /> : <></>}
                            />
                        </Col>
                        <Col span={7} push={11}>
                            <Button onClick={this.modalShowFilters}><img src={Filters} />Filters</Button>
                        </Col>
                    </Row>
                    <br />
                    {this.props.completedTripData == 'undefined' || this.props.completedTripData == null ?
                        <Empty
                            description={
                                <span>
                                    <p>Please use the search by Docket or filter option to view the Docket Info, Trip Info or Vehicle info for faster operations.</p>
                                    <Button style={{ color: "#1890ff" }} type="link" onClick={this.modalShowFilters}>Show Filters</Button>
                                </span>
                            }
                        >

                        </Empty>
                        :
                        tripListing
                    }
                </Card>
                <Modal
                    style={{top: 20}}
                    title={`POD Docket No: ${this.state.podData.waybillNo}`}
                    visible={this.state.visiblePODModal}
                    onCancel={this.cancelVisiblePODModal}
                    footer={[
                        <>
                            {this.state?.podData?.consignmentPODBO?.map(pod => {
                                if (pod.podApproval.length <= 0 || pod.podApproval[0].approvalStatus === "POD_UNCLEAN") {
                                    if (!this.state.rejectPopup) {
                                        return (
                                            <>
                                                <Button key="reject" type="danger" onClick={this.openRejectPopup}>
                                                    Reject
                                            </Button>
                                                <Button key="submit" type="primary" onClick={this.approve} loading={this.props.approveLoading}>
                                                    Approve
                                            </Button>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <Button key="cancel" type="default" onClick={this.cancelVisiblePODModal}>
                                                    Cancel
                                            </Button>
                                                <Button key="confirm" type="primary" onClick={this.confirmRejectReason}>
                                                    Confirm
                                            </Button>
                                            </>
                                        )

                                    }


                                }
                                else {
                                    if (pod.podApproval[0].approvalStatus === "POD_CLEAN") return <span>Approved</span>
                                    // if (pod.podApproval[0].approvalStatus === "POD_UNCLEAN") return <span>Rejected</span>
                                }
                            })}

                        </>
                    ]}
                >
                    <Row gutter={[0, 4]}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {!this.state.rejectPopup ?
                                <>
                                    {this.state?.podData?.consignmentPODBO?.map(pod => {
                                        return (
                                            <>
                                                <div className="container">
                                                    <img src={pod.podImpLink} alt="POD" width={200} />
                                                    <Button className="img-pod" onClick={() => { this.setState({ isOpen: true }) }}><Icon type="fullscreen" /></Button>
                                                </div>
                                                {this.state.isOpen &&
                                                    <Lightbox
                                                        mainSrc={pod.podImpLink}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                    />
                                                }
                                            </>
                                        )
                                    })}
                                </> : <></>
                            }

                        </Col>
                        <Col span={24}>
                            <span className="form-label-text">Customer Name</span><br />
                            <b>{this.state.podData?.customer?.name}</b>
                        </Col>
                        <Col span={24}>
                            <span className="form-label-text">Destination</span><br />
                            <b>{this.state.podData?.customerPickupLoc?.address?.pincode?.city?.cityCode} - {this.state.podData?.consignee?.address?.pincode?.city?.cityCode}</b>
                        </Col>
                        <Col span={24}>
                            <span className="form-label-text">Pieces/Weight</span><br />
                            <b>{this.state.podData?.totalShipmentCount} PCs / {this.state.podData?.totalWeight} Kgs</b>
                        </Col>
                        <Col span={24}>
                            <span className="form-label-text">Date of POD upload</span><br />
                            <b>{moment(this.state.podData?.date).format("DD-MMM-YYYY kk:mm")}</b>
                        </Col>

                        {
                            this.state.rejectPopup ?
                                <>
                                    <Col span={12}>
                                        <span className="form-label-text">Select Reason</span>
                                        <Select
                                            showSearch
                                            placeholder="Select Reason"
                                            onSelect={this.selectRejectReason}
                                            value={this.state.selectedReason}
                                            style={{ width: '100%' }}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.props.rejectReasons?.reasons?.map((reason, key) => (
                                                <Option
                                                    value={reason.reasonId}
                                                    label={reason.reasonDescription}
                                                    key={reason.reasonId}
                                                >
                                                    {reason.reasonDescription}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </>

                                : <></>
                        }
                    </Row>


                </Modal>
                <Modal
                    title="Filters"
                    width={400}
                    visible={this.state.showFilters}
                    onCancel={this.cancelFilters}
                    footer={[
                        <Button key="applyfilters" type="primary" onClick={this.applyFilters}>
                            Apply
                            </Button>,
                        <Button key="resteFilters" type="primary" onClick={this.resetFilters}>
                            Reset Filters
                            </Button>
                    ]}
                >
                    <Collapse defaultActiveKey={['1']} accordion>
                        <Panel header="DATE AND TIME" key="1">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <span className="form-label-text">Booking Date (From)</span>
                                    <DatePicker style={{ width: '100%' }} onChange={this.selectBookingFrom} value={this.state.listingFilters.bookingFrom} />

                                </Col>
                                <Col span={24}>
                                    <span className="form-label-text">Booking Date (To)</span>
                                    <DatePicker style={{ width: '100%' }} onChange={this.selectBookingTo} value={this.state.listingFilters.bookingTo} />
                                </Col>
                                <Col span={24}>
                                    <span className="form-label-text">Trip ID</span>
                                    <Input
                                        placeholder="Trip ID"
                                        onChange={this.changeFilterTripID}
                                        value={this.state.listingFilters.tripId}
                                    />
                                </Col>
                                {/* <Col span={24}>
                                    <span className="form-label-text">Trip Status</span>
                                    <Radio.Group onChange={this.onChangeTripStatus} value={this.state.listingFilters.tripStatusValue}>
                                        <Radio value={'OPEN'}>Open</Radio>
                                        <Radio value={'STARTED'}>In Transit</Radio>
                                        <Radio value={'STARTED_NOT_FINISHED'}>Not Finished</Radio>
                                    </Radio.Group>
                                </Col> */}
                            </Row>
                        </Panel>
                        <Panel header="DESTINATION LOCATIONS" key="2">
                            <Select
                                showSearch
                                placeholder="Select Location"
                                loading={this.props.locationGroupLoading}
                                onSelect={this.selectLocationFilter}
                                value={this.state.listingFilters.location}
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.props.locationGroups?.response?.locationsGroupedByRole?.DELIVERY.concat(this.props.locationGroups?.response?.locationsGroupedByRole?.PICKUP).map((location, key) => (
                                    <Option
                                        value={location.id}
                                        label={location.name}
                                        key={location.id + key + location.name}
                                    >
                                        {location.name}
                                    </Option>
                                ))}
                            </Select>


                        </Panel>
                        <Panel header="ASSETS" key="3">
                            <Select
                                showSearch
                                placeholder="Select Location"
                                onSelect={this.setPickupLocationFilter}
                                value={this.state.listingFilters.pickuplocation}
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.props.locationGroups?.response?.locationsGroupedByRole?.DELIVERY.concat(this.props.locationGroups?.response?.locationsGroupedByRole?.PICKUP).map((location, key) => (
                                    <Option
                                        value={location.id}
                                        label={location.name}
                                        key={location.id + key + location.name}
                                    >
                                        {location.name}
                                    </Option>
                                ))}
                            </Select>
                        </Panel>
                    </Collapse>

                </Modal>
                <Modal
                    title={`Payment History - ${this.state.selectedTripId}`}
                    visible={this.state.showTripSettlement}
                    onCancel={() => { this.toggleTripSettlememtModal(false) }}
                    width={1000}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => { this.toggleTripSettlememtModal(false) }}>
                            CLOSE
                        </Button>,
                    ]}
                >
                    <Spin spinning={this.props?.tripSettlements?.tripSettlementLoding}>
                        <Table
                            bordered
                            columns={this.state.tripSettlementColumns}
                            dataSource={this.props.tripSettlements?.tripSettlementsBO}
                            pagination={false}
                        />
                    </Spin>

                </Modal>
            </>

        )
    }
}

export default CompletedTripList;