import React, { Component } from 'react';
import { Card, Row, Col, Tooltip, Tag, Icon, Button, Input, InputNumber } from 'antd';
import './trips.scss';
import moment from 'moment';
import ImageViewNew from "./ImageViewerNew";
import ImageViewer from '../../verification/trips/ImageViewer';
import { getInvoiceColumns } from './column.d';
import { canAllow, dataGet, formatCurrency, notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import ImageAdd from '../../../assets/img/icons/image_add.svg';
import addImage from '../../../assets/img/icons/add_image.svg';
import Ticket from '../../../assets/img/icons/ticket.svg';
import editPenCil from '../../../assets/img/icons/edit_pencil.svg';

import FileUpload from '../../ui/file-upload/file.upload';
import { Constants, UPLOAD_BUTTON_TYPE } from '../../../common/utils/constants';
import TrackingPopupModal from "../../shared-components/tracking-popup-modal";
import scannerIcon from '../../../assets/img/icons/scanner.svg';
import { permissionTags } from '../../../configs/permissionTags';


export const formatDate = date => (moment(Number(date)).format('DD/MM/YYYY'));

class VerificationDocketsDetailsLeft extends Component {
    state = {
        showTrackingPopup: false,
        status: {
            "DOCUMENT_PENDING": "Document Pending",
            "DOCUMENT_VERIFICATION_PENDING": "Verification Pending",
            "CORRECTION_REQUIRED": "Correction Required",
            "REJECTED": "Rejected",
            "VERIFIED": "Verified"
        },
        consignmentStatus: {
            "INWARD": "In Transit",
            "OFD": "In Transit",
            "DRS_LINK": "In Transit",
            "IN_TRANSIT": "In Transit",
            "BOOKED": "Booked",
            "IN": "Inventory",
            "DELIVERED": "Delivered"
        },
        verificationPermissions: {
            update: canAllow(permissionTags.verification.docket.update),
            upload: canAllow(permissionTags.verification.docket.upload),
        },
    }

    showPopup = () => {
        this.setState({
            showTrackingPopup: true
        });
    };

    handleCancel = e => {
        this.props.toggleWaybillModal('', false)
        this.setState({
            showTrackingPopup: false
        });
    };

    replaceImage = (index, type, e) => {
        const { consignmentVerifications, consignmentData, leftBoardEvent } = this.props;
        const _this = this;
        let docketData = [];
        let data = consignmentVerifications?.filter(cv => cv?.verificationType === 'DOCKET');
        let invoicesData = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
        let payload = {};

        if (data?.length > 0) {
            docketData = data[0];
            docketData['consignmentVerificationDocumentMappings'][index] = {
                s3Url: e
            }
            docketData['verificationType'] = type;
            console.log("docle", [docketData])
            // let imageIndex = docketData[index];
            // imageIndex.s3Url = e;
            // imageIndex.verificationType = 'DOCKET';
            // console.log("image", imageIndex);
            payload = {
                "consignmentId": consignmentData?.id,
                "consignmentVerifications": [
                    ...invoicesData,
                    ...[docketData]
                ]
            }

            console.log("payload", payload)
            leftBoardEvent?.updateInvoice(payload);
        } else {
            docketData = [
                {
                    verificationType: type,
                    consignmentVerificationDocumentMappings: [
                        {
                            s3Url: e
                        }
                    ]
                }
            ];
            payload = {
                "consignmentId": consignmentData?.id,
                "consignmentVerifications": [
                    ...invoicesData,
                    ...docketData
                ]
            }
            leftBoardEvent?.updateInvoice(payload);
        }


        // docketData[index] = imageIndex;

        console.log("docket", payload)


        // console.log("docket", imageIndex, payload)

        // leftBoardEvent?.updateInvoice(payload)




        // leftBoardEvent.DocketUpload(index, "consignmentVerificationDocumentMappings", e)
    }

    render() {
        const { consignmentData, consignmentVerifications, leftBoardEvent } = this.props;
        const { status, verificationPermissions, consignmentStatus } = this.state;

        let docketData = [], invoiceData = [];

        if (consignmentVerifications.length > 0) {
            const data = consignmentVerifications?.filter(cv => cv?.verificationType === 'DOCKET');
            const invoice_data = consignmentVerifications?.filter(cv => cv?.verificationType === "INVOICE");
            if (data?.length > 0) {
                docketData = data[0]?.consignmentVerificationDocumentMappings;
            }
            if (invoice_data?.length > 0) {
                invoiceData = invoice_data;
            }
        }
        let tooltipTitle = [];
        tooltipTitle = consignmentData?.zendeskTicketBOS?.map(ticket => (
            <li>{ticket?.zendeskTicketId} - {ticket?.zendeskTicketStatus}</li>
        ));



        const invoiceAllowedImagesUpload = dataGet(this.props.configurations, "docketVerificationProofConfig.invoiceProofSize", 0);
        const docketAllowedImagesUoload = dataGet(this.props.configurations, "docketVerificationProofConfig.docketProofSize", 0);

        return (
            <div className={"docket-verify-left"}>
                <div className={"docket-verify-left-block"}>
                    <div className="docket-verify-left-block-header">
                        <Row gutter={12}>
                            <Col span={7} md={7}>
                                <div className="docket-verify-left-block-header-block1">
                                    <Row gutter={12}>
                                        <Col span={14}>
                                            <div
                                                style={{
                                                    color: "rgba(255, 152, 0, 0.12)",
                                                    height: 50,
                                                    // margin: '0 8px 0 0',
                                                    padding: 8,
                                                    lineHeight: "30px",
                                                    textAlign: "center",
                                                    borderRadius: 4,
                                                    border: 'solid 1px #ff9800'
                                                }}
                                            >

                                                {consignmentData?.consignmentFilterBO?.verificationStatus &&

                                                    <p style={{
                                                        color: '#ff9800',
                                                        // padding: 5,
                                                        wordBreak: "break-word",
                                                        fontSize: 12,

                                                    }}>
                                                        {status[consignmentData?.consignmentFilterBO?.verificationStatus]}
                                                    </p>


                                                }
                                            </div>
                                        </Col>
                                        <Col span={10}>
                                            <Row gutter={[12, 6]}>
                                                <Col span={24}>
                                                    <Tag
                                                        color="#ebfff4"
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#37af47'
                                                            }}
                                                        >
                                                            {
                                                                consignmentStatus[consignmentData?.consignmentStatus]
                                                            }
                                                        </span>

                                                    </Tag>
                                                </Col>

                                                <Col span={24}>
                                                    <span
                                                        style={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                            fontStretch: 'normal',
                                                            fontStyle: 'normal',
                                                            lineHeight: '1.14',
                                                            letterSpacing: 'normal',
                                                            textAlign: 'left',
                                                            color: '#000000'
                                                        }}
                                                    >
                                                        Ticket No.
                                                        {
                                                            tooltipTitle ?
                                                                <Tooltip title={tooltipTitle}>
                                                                    <img src={Ticket} />
                                                                </Tooltip>
                                                                : <Tooltip title={"No ticket"}>
                                                                    <img src={Ticket} />
                                                                </Tooltip>

                                                        }

                                                    </span>
                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={17} md={17}>
                                <div className="docket-verify-left-block-header-block2">
                                    <Row>
                                        <Col span={9}>
                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={8}>
                                                            <label className="docket-verify-left-block-header-block2-label">Customer :</label>
                                                        </Col>
                                                        <Col span={16}>
                                                            <span className="docket-verify-left-block-header-block2-text">{consignmentData?.customer?.name}</span>
                                                        </Col>
                                                    </Row>



                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Col span={8}>
                                                        <label className="docket-verify-left-block-header-block2-label">Docket Type :</label>
                                                    </Col>
                                                    <Col span={16}>
                                                        <span className="docket-verify-left-block-header-block2-text">{consignmentData?.movementType}</span>
                                                    </Col>

                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={1}>
                                            <div className="docket-verify-left-block-header-block2-divider">

                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <Row>
                                                <Col span={24}>
                                                    <Col span={12}>
                                                        <label className="docket-verify-left-block-header-block2-label">Pickup Date :</label>
                                                    </Col>
                                                    <Col span={12}>
                                                        <span className="docket-verify-left-block-header-block2-text">{formatDate(consignmentData?.bookingDate)}</span>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Col span={12}>
                                                        <label className="docket-verify-left-block-header-block2-label">Flow Type :</label>
                                                    </Col>
                                                    <Col span={12}>
                                                        <span className="docket-verify-left-block-header-block2-text">{consignmentData?.flowType}</span>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={1}>
                                            <div className="docket-verify-left-block-header-block2-divider">
                                            </div>
                                        </Col>
                                        <Col span={7}>
                                            <Row>
                                                <Col span={24}>
                                                    <Col span={12}>
                                                        <label className="docket-verify-left-block-header-block2-label">Booking Office :</label>
                                                    </Col>
                                                    <Col span={12}>
                                                        <span className="docket-verify-left-block-header-block2-text" style={{ wordWrap: 'break-word' }}>{consignmentData?.bookingOfficeLoc?.name}</span>
                                                    </Col>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col span={19}>
                                {consignmentData?.consignmentContractMessage ?
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: '#b3d0fa',
                                            color: '#0247aa',
                                            height: 36,
                                            padding: '6px 198px',
                                            borderRadius: 4,
                                            boxShadow: '0 2px 4px 0 rgba(110, 112, 115, 0.2)',
                                            border: 'solid 1px #2568c8'

                                        }}
                                    >

                                        <Icon
                                            theme="twoTone"
                                            twoToneColor="#0247aa"
                                            type="info-circle"
                                        /> {consignmentData?.consignmentContractMessage}

                                    </div>
                                    :
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: '#b3d0fa',
                                            color: '#0247aa',
                                            height: 36,
                                            padding: '6px 198px',
                                            borderRadius: 4,
                                            boxShadow: '0 2px 4px 0 rgba(110, 112, 115, 0.2)',
                                            border: 'solid 1px #2568c8'

                                        }}
                                    >

                                        <Icon
                                            theme="twoTone"
                                            twoToneColor="#0247aa"
                                            type="info-circle"
                                        /> No Rate Card found for this customer

                                    </div>

                                }

                            </Col>
                            <Col span={1}>

                            </Col>
                            <Col span={4}>

                                <Button
                                    className="docket-verify-right-ctas-primary"
                                    onClick={() => this.showPopup()}
                                    block
                                >
                                    Docket Tracking
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <ImageViewNew
                            title="Docket Proofs"
                            replaceImage={this.replaceImage}
                            openRejectModal={this.props.leftBoardEvent?.openRejectModal}
                            imageType="DOCKET"
                            photoIndex={this.props.photoIndex ? this.props.photoIndex : 0}
                            imageData={docketData}
                            disableReject={consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions?.update}
                            disableReplace={consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions?.upload}
                            // disabledAction={!verificationPermissions?.upload || consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED'}
                            uploadLimit={docketAllowedImagesUoload}
                            onFileUploaded={file => leftBoardEvent.DocketUpload(0, "consignmentVerificationDocumentMappings", file)}
                            onFileRemoved={index => leftBoardEvent.RemoveDocket(0, "consignmentVerificationDocumentMappings", index)}
                        />
                    </div>
                </div>
                <div className="docket-verify-left-block-invoice">
                    <div className="docket-verify-left-block-invoice-title">Invoice Details</div>
                    <InvoiceRender
                        verificationPermissions={verificationPermissions}
                        config={this.props.configurations}
                        data={invoiceData}
                        leftBoardEvent={leftBoardEvent}
                        consignmentData={consignmentData}
                        allowedCustomerId={consignmentData?.customer?.id}
                        invoiceAllowedImagesUpload={Number(invoiceAllowedImagesUpload || 0)}
                    />
                    <Button disabled={consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions?.update} className="docket-verify-right-ctas-primary" onClick={this.props.leftBoardEvent?.addInvoice}>Add Invoice</Button>
                </div>
                <TrackingPopupModal onCancel={this.handleCancel} visible={this.state.showTrackingPopup} input={consignmentData?.waybillNo} waybillNo={consignmentData?.waybillNo}
                    source={Constants.TRACKING_CLASSNAME} />
            </div >
        )
    }
}

class InvoiceRender extends Component {
    state = {
        clearInvoice: true
    }

    uploadInvoicImage = (index, key, file) => {
        this.props.leftBoardEvent.InvoiceUpload(index, key, file)
    }

    render() {
        const { data, consignmentData, verificationPermissions, config, allowedCustomerId } = this.props;
        const allowedInvoices = dataGet(config, "restrictBooking.invoiceno", 0);

        const restrictDelete = allowedInvoices.includes(allowedCustomerId) || allowedInvoices.includes(allowedCustomerId?.toString());
        return (
            <>
                <div className="docket-verify-left-block-invoice-columns">
                    <Row>
                        {getInvoiceColumns()?.map(item => (
                            <>
                                <Col span={item?.colSpan}>
                                    <div className="docket-verify-left-block-invoice-header">

                                        <div className="docket-title">
                                            {item?.title}
                                            {
                                                item?.isRightBorder &&

                                                <div className="docket-verify-left-block-invoice-divider-title"></div>

                                            }
                                        </div>



                                        {/* {item?.isRightBorder ?
                                // <Divider type="vertical" />
                                <div className="docket-verify-left-block-invoice-divider-title"></div>
                                : <div className="docket-verify-left-block-invoice-data-divider" style={{ opacity: 0 }}></div>
                            } */}
                                    </div>


                                </Col>

                            </>
                        ))}
                    </Row>
                </div>
                {data?.map((inv, index) => {
                    let expiryDate = null;
                    if (inv?.ewaybillBO) {
                        let validUpto = moment(inv?.ewaybillBO?.validUpto);
                        let today = moment();
                        let difference = validUpto.diff(today, 'hours');
                        if (validUpto) {
                            if (moment(validUpto) <= moment()) {
                                expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-red">Expired on {formatDate(inv?.ewaybillBO?.validUpto)}</span>
                            } else if (difference <= this.props?.config?.ewaybill?.waringHours) {
                                expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-orange">Expires on {formatDate(inv?.ewaybillBO?.validUpto)}</span>
                            } else {
                                expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-green">Expires on {formatDate(inv?.ewaybillBO?.validUpto)}</span>
                            }
                        } else {
                            expiryDate = <span className="docket-verify-left-block-invoice-header-data-eway-green">Expires on {formatDate(inv?.ewaybillBO?.validUpto)}</span>

                        }

                    }

                    let reasons = [], reasonsIds = [];

                    inv?.consignmentVerificationDocumentMappings.map(co => {

                        if (_.hasIn(co, 'rejectedReasons')) {

                            co?.rejectedReasons.map(reason => {
                                if (reasonsIds.length <= 0 || !reasonsIds?.includes(reason.id)) {
                                    reasonsIds.push(reason?.id);
                                    reasons.push(<li className="docket-verify-left-block-invoice-header-data-reject">{reason?.documentRejectedReason}</li>);
                                }


                            })
                        }

                    });


                    return (
                        <div className="docket-verify-left-block-invoice-columns-data">
                            <Row gutter={24}>
                                <Col span={3} className="docket-verify-left-block-invoice-header-border">
                                    <div className="docket-verify-left-block-invoice-header-data">
                                        <div className="docket-title">
                                            {inv?.add_type === 'saved' ?
                                                <>
                                                    {inv?.invoiceNumber}
                                                </>

                                                : <Input
                                                    style={{ borderRadius: 8 }}
                                                    disabled={!verificationPermissions?.update}
                                                    placeholder="Invoice no"
                                                    value={inv?.invoiceNumber}
                                                    onChange={(e) => this.props.leftBoardEvent?.onChangeInvoice(index, 'invoiceNumber', e.target.value)}

                                                />
                                            }
                                        </div>
                                        <div className="dummy"></div>

                                        {/* <div className="docket-verify-left-block-invoice-data-divider"></div> */}
                                    </div>
                                </Col>

                                <Col span={4} className="docket-verify-left-block-invoice-header-border">
                                    <div className="docket-verify-left-block-invoice-header-data">
                                        <div className="docket-title">
                                            {inv?.add_type === 'saved'  ?
                                                <>
                                                    {formatCurrency(inv?.invoiceValue)}
                                                </>

                                                : <InputNumber

                                                    placeholder="Invoice Value"
                                                    style={{ borderRadius: 8, width: '100%' }}
                                                    min={0}
                                                    disabled={!verificationPermissions?.update}
                                                    value={inv?.invoiceValue}
                                                    formatter={value => `₹ ${value}`}

                                                    onChange={(e) => this.props.leftBoardEvent?.onChangeInvoice(index, 'invoiceValue', e)}

                                                />
                                            }
                                        </div>
                                        {/* <div className="docket-verify-left-block-invoice-data-divider"></div> */}
                                    </div>
                                </Col>
                                <Col span={4} className="docket-verify-left-block-invoice-header-border">
                                    <div className="docket-verify-left-block-invoice-header-data">
                                        <div className="docket-title">
                                            {inv?.add_type === 'saved' ?
                                                <>
                                                    {inv?.ewaybillBO &&
                                                        <>
                                                            {inv?.ewaybillBO?.ewaybillNo}
                                                            <br />
                                                            <br />
                                                            {expiryDate}
                                                        </>
                                                    }

                                                </>
                                                :
                                                <>
                                                    <InputNumber
                                                        placeholder="Ewaybill No"
                                                        addonAfter={<img src={scannerIcon} />}
                                                        // type="number"
                                                        disabled={!verificationPermissions?.update}
                                                        // onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                        // value={inv?.ewaybillBO?.ewaybillNo ? inv?.ewaybillBO?.ewaybillNo : inv?.ewaybillNo}
                                                        value={inv?.ewaybillBO?.ewaybillNo}
                                                        onChange={(e) => {

                                                            // if (e.target.value === e) return false;
                                                            this.props.leftBoardEvent?.onChangeEway(index, e)
                                                        }}

                                                    />
                                                    {/* {
                                                    inv?.invoiceValue > 50000 &&
                                                    <Alert type="error" message="Ewayno is mandatory for amount > 50000" />
                                                } */}
                                                </>
                                            }
                                        </div>
                                        {/* <div className="docket-verify-left-block-invoice-data-divider"></div> */}
                                    </div>
                                </Col>
                                <Col span={6} style={{ width: '24%' }} className="docket-verify-left-block-invoice-header-border">
                                    <div className="docket-verify-left-block-invoice-header-data">
                                        <div className="docket-title">
                                            <Row>
                                                <Col span={24}>
                                                    <div style={{ float: 'left' }}>
                                                        <ImageViewer
                                                            className="docket-verify-left-block-invoice-header-data-images"
                                                            onClick={(photoIndex) => this.props.leftBoardEvent?.gotoInvoiceDetails(index, photoIndex)}
                                                            mode="AVATAR" listType="picture" fileList={inv?.consignmentVerificationDocumentMappings}
                                                        />
                                                    </div>
                                                    {
                                                        (inv?.add_type === 'add' && this.props.invoiceAllowedImagesUpload > inv?.consignmentVerificationDocumentMappings?.length) &&
                                                        // <div span={6}>
                                                        //     <img src={ImageAdd} style={{ cursor: 'not-allowed', color: '#091c9b', opacity: '0.5' }} />
                                                        // </div>

                                                        // :
                                                        <div className="image-upload">
                                                            <FileUpload
                                                                showToastMessage={false}
                                                                showLoader={true}
                                                                fileSizeLimit={5}
                                                                fileUploadType={UPLOAD_BUTTON_TYPE.BUTTON}
                                                                uploadButtonHtml={(
                                                                    // <Icon type="folder-add" style={{ fontSize: 25, cursor: 'pointer' }} />

                                                                    <img
                                                                        src={addImage}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            color: '#091c9b',
                                                                            padding: 5,
                                                                            borderRadius: 4,
                                                                            border: "solid 1px #2568c8"
                                                                        }}
                                                                    />



                                                                )}
                                                                directUpload={true}
                                                                disableUploadButton={true}
                                                                clearFileAfterUpload={this.state.clearInvoice}
                                                                onUploadFinish={e => this.uploadInvoicImage(index, 'consignmentVerificationDocumentMappings', e)}
                                                                path="verification"
                                                                acceptTypes={this.state.acceptedFiles}
                                                                objKey="docket"
                                                                multiple={false}
                                                            />
                                                        </div>

                                                    }
                                                </Col>



                                            </Row>


                                            {/* {inv?.invoiceValue} */}
                                        </div>

                                        {/* <div className="docket-verify-left-block-invoice-data-divider"></div> */}
                                    </div>
                                </Col>
                                <Col span={3} className="docket-verify-left-block-invoice-header-border">
                                    <div className="docket-verify-left-block-invoice-header-data">
                                        <div className="docket-title">
                                            <ul style={{ listStyleType: "none", padding: 0 }}>
                                                {/* {
                                                    inv?.consignmentVerificationDocumentMappings[0]?.rejectedReasons?.map(reason => (
                                                        <li className="docket-verify-left-block-invoice-header-data-reject">{reason?.documentRejectedReason}</li>
                                                    ))
                                                } */}
                                                {
                                                    reasons
                                                }
                                            </ul>

                                        </div>

                                        {/* <div className="docket-verify-left-block-invoice-data-divider"></div> */}
                                    </div>
                                </Col>
                                <Col span={4} style={{ padding: 0 }}>
                                    <div className="docket-verify-left-block-invoice-header-data">
                                        <div className="action-me">
                                            <div className="edit-me">
                                                {inv?.add_type === 'saved' ?
                                                    <div className="docket-title">
                                                        {
                                                            verificationPermissions?.update || verificationPermissions?.upload || consignmentData?.consignmentFilterBO?.verificationStatus !== 'VERIFIED' ?
                                                                <img
                                                                    src={editPenCil}
                                                                    style={{ cursor: 'pointer', marginTop: 5, marginLeft: 5 }}
                                                                    onClick={() => this.props.leftBoardEvent?.editInvoice(index)}
                                                                />
                                                                :

                                                                <img
                                                                    src={editPenCil}
                                                                    style={{ cursor: 'pointer', marginTop: 5, marginLeft: 5, opacity: '0.5' }}

                                                                />
                                                        }
                                                        {/* {consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions?.update
                                                            ?

                                                            <img
                                                                src={editPenCil}
                                                                style={{ cursor: 'pointer', marginTop: 5, marginLeft: 5, opacity: '0.5' }}

                                                            /> :
                                                            <img
                                                                src={editPenCil}
                                                                style={{ cursor: 'pointer', marginTop: 5, marginLeft: 5 }}
                                                                onClick={() => this.props.leftBoardEvent?.editInvoice(index)}
                                                            />
                                                        } */}
                                                        {/* <Icon
                                                            type="edit"
                                                            style={{
                                                                fontSize: 14
                                                            }}
                                                            onClick={() => this.props.leftBoardEvent?.editInvoice(index)}
                                                        /> */}
                                                    </div> :
                                                    <div className="docket-title">
                                                        <Icon
                                                            type="check-circle"
                                                            theme="twoTone"
                                                            twoToneColor="#4caf50"
                                                            style={{
                                                                fontSize: 24
                                                            }}
                                                            onClick={() => this.props.leftBoardEvent?.saveInvoice(index)}
                                                        />


                                                    </div>
                                                }
                                            </div>
                                            <div className="reject-me">
                                                <Button
                                                    disabled={consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions?.update || inv?.add_type === 'add' || inv?.consignmentVerificationDocumentMappings?.length <= 0}
                                                    type="danger"
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                    onClick={() => this.props.leftBoardEvent?.openRejectModal(index, "INVOICE", false, undefined)}
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                            <div className="delete-me">
                                                {
                                                    consignmentData?.consignmentFilterBO?.verificationStatus === 'VERIFIED' || !verificationPermissions?.update
                                                        || (restrictDelete && data.length === 1)
                                                        ?
                                                        <Icon
                                                            type="minus-circle"

                                                            style={{
                                                                marginTop: 5,
                                                                marginLeft: 8,
                                                                color: '#e6492d',
                                                                fontSize: 24,
                                                                opacity: '0.5'
                                                            }}
                                                        /> :
                                                        <Icon
                                                            type="minus-circle"
                                                            onClick={() => this.props.leftBoardEvent?.removeInvoice(index, inv?.invoiceNumber)}
                                                            style={{
                                                                marginTop: 5,
                                                                marginLeft: 8,
                                                                color: '#e6492d',
                                                                fontSize: 24
                                                            }}
                                                        />

                                                }
                                            </div>
                                        </div>






                                        {/* <div className="docket-verify-left-block-invoice-data-divider" style={{ opacity: 0 }}></div> */}
                                    </div>
                                </Col>

                            </Row>


                            {/* <div className="docket-verify-left-block-invoice-divider"></div> */}


                            {/* <div className="docket-verify-left-block-invoice-divider"></div> */}


                            {/* <div className="docket-verify-left-block-invoice-divider"></div> */}


                            {/* <div className="docket-verify-left-block-invoice-divider"></div> */}



                            {/* <div className="docket-verify-left-block-invoice-divider"></div> */}



                            {/* <div className="docket-verify-left-block-invoice-divider"></div> */}

                        </div>
                    )
                }
                )}

            </>




        )
    }
}

export default VerificationDocketsDetailsLeft;