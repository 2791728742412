
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const DeliverLoadsReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_DELIVER_LOADS_BEGIN: return fetchLiveLoadsBegin(state);
        case _Constants.FETCH_DELIVER_LOADS_SUCCESS: return fetchLiveLoadsSuccess(state, action);
        case _Constants.FETCH_DELIVER_LOADS_FAILURE: return fetchLiveLoadsFailure(state, action);

        case _Constants.FETCH_DELIVER_LOADS_SUMMARY_BEGIN: return fetchLiveLoadsSummaryBegin(state);
        case _Constants.FETCH_DELIVER_LOADS_SUMMARY_SUCCESS: return fetchLiveLoadsSummarySuccess(state, action);
        case _Constants.FETCH_DELIVER_LOADS_SUMMARY_FAILURE: return fetchLiveLoadsSummaryFailure(state, action);

        case _Constants.EXPORT_DELIVER_LOADS_BEGIN: return exportLiveLoadsBegin(state);
        case _Constants.EXPORT_DELIVER_LOADS_SUCCESS: return exportLiveLoadsSuccess(state, action);
        case _Constants.EXPORT_DELIVER_LOADS_FAILURE: return exportLiveLoadsFailure(state, action);


        case _Constants.DOWNLOAD_POD_LOADS_BEGIN: return downloadPODBegin(state);
        case _Constants.DOWNLOAD_POD_LOADS_SUCCESS: return downloadPODSuccess(state, action);
        case _Constants.DOWNLOAD_POD_LOADS_FAILURE: return downloadPODFailure(state, action);

        case _Constants.LOCATION_DELIVER_LOADS_BEGIN: return locationLiveLoadsBegin(state);
        case _Constants.LOCATION_DELIVER_LOADS_SUCCESS: return locationLiveLoadsSuccess(state, action);
        case _Constants.LOCATION_DELIVER_LOADS_FAILURE: return locationLiveLoadsFailure(state, action);

        default:
            return state;
    }
}

function locationLiveLoadsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function locationLiveLoadsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        location: { ...action.payload.locationsGroupedByRole }
    };
}

function locationLiveLoadsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

function exportLiveLoadsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function exportLiveLoadsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        export: { ...action.payload }
    };
}

function exportLiveLoadsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}


function downloadPODBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function downloadPODSuccess(state, action) {
    
    return {
        ...state,
        loading: false,
        downladPOD: { ...action.payload }
    };
}

function downloadPODFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}


function fetchLiveLoadsSummaryBegin(state, action) {
    return {
        ...state,
        deliverSummaryloading: true
    };
}

function fetchLiveLoadsSummarySuccess(state, action) {
    return {
        ...state,
        deliverSummaryloading: false,
        ...action.payload
    };
}

function fetchLiveLoadsSummaryFailure(state, action) {
    return {
        ...state,
        deliverSummaryloading: false,
        error: action.payload,
    };
}

function fetchLiveLoadsBegin(state, action) {
    return {
        ...state,
        deliverLoadsloading: true
    };
}

function fetchLiveLoadsSuccess(state, action) {
    return {
        ...state,
        deliverLoadsloading: false,
        PaginatedData: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedData ? state.PaginatedData : {}, action ?.payload ?.consignments , action.isReset),
        ...action.payload
    };
}

function fetchLiveLoadsFailure(state, action) {
    return {
        ...state,
        deliverLoadsloading: false,
        error: action.payload,
        consignments: []
    };
}