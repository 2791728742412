import React, { Component } from 'react';
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, PageHeader, Tooltip, Tabs, Form } from 'antd';
import Login1 from '../../assets/img/login-1.png';
import Login2 from '../../assets/img/login-2.png';
import Loadshare from '../../assets/img/loadshare-logo.svg';
import * as apiUtils from '../../common/utils/apiGateway';
import { notifyApiError } from '../../common/utils/utils';
import OtpInput from 'react-otp-input';
import './login.scss';
import { LsnModules } from "../../configs/permissionTags";
import { Constants, validationRules } from '../../common/utils/constants';
import cookie from 'react-cookies';
import NumberFormat from 'react-number-format';
import { Mixpanel } from '../../utils/mixpanel';
import moment from 'moment';
const { TabPane } = Tabs;


class Login extends Component {

    state = {
        passwordType: "password",
        nopermission: false,
        selectedApplication: '',
        redirectURL: '',
        loginMethod: 'otp',
        validOTP: false,
        count: 0,
        minutes: 1,
        seconds: 0,
        showCounter: false,
        otpError: undefined,
        showOTPPanel: true,
        clickedResendOTP: false,
        loginButtonDisable: false
    }

    componentDidMount() {        
        this.props.form.validateFields(['mobileno']);
        
        localStorage.clear();
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    }

    constructor(props, context) {
        super(props, context)        
    }

    hasErrors = (fieldsError) => {        
        
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    checkHttpStatus = (status) => {
        switch (status?.code) {
            case 403:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 400:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 401:
                notifyApiError(status?.message, "ERROR:");
                // window.location.href = '/';
                return false;
            case 500:
            case 503:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 200:
            case 201:
            case 202:
                return true
        }
    }

    ToArray = (enumme) => {
        const StringIsNumber = value => isNaN(Number(value)) === false;
        return Object.keys(enumme)
            .filter(StringIsNumber)
            .map(key => enumme[key]);
    }

    generateIndexFiles = (params) => {

        return apiUtils.apiRequest({
            method: 'POST',
            url: `${Constants.ENV_NODE}store-local-data`,
            headers: {},
            // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
            data: params
        })
        // const url = `${Constants.ENV_REACT}/store-local-data`;
        // return this.http.post(url, params, { headers: new Headers() }).toPromise();
    }

    getAllPersmission = () => {
        const permissions = localStorage.getItem("permissions");
        if (!permissions) {
            return null;
        }
        let selectedApplication = atob(localStorage.getItem("application"));
        this.setState({ selectedApplication });
        return JSON.parse(atob(permissions));
    }

    store = (permissions) => {
        if (permissions == null) {
            return null;
        }
        localStorage.setItem("permissions", btoa(JSON.stringify(permissions)));
        this._permissions = this.getAllPersmission();
        const allApplications = Object.keys(this._permissions);
        let selectedApplication = allApplications[0];
        this.setState({ selectedApplication });
        localStorage.setItem("application", btoa(this.state.selectedApplication));
    }

    parsePermission = (permissions) => {
        if (!permissions) {
            return null;
        }
        const pObj = {};
        permissions.forEach((permission) => {
            if (!pObj[permission.application]) {
                pObj[permission.application] = {};
            }

            if (!pObj[permission.application][permission.module]) {
                pObj[permission.application][permission.module] = { permissions: [] };
            }

            if (permission.submodule == null) {
                pObj[permission.application][permission.module].permissions.push(
                    permission.action
                );
            } else {
                if (
                    !pObj[permission.application][permission.module][permission.submodule]
                ) {
                    pObj[permission.application][permission.module][
                        permission.submodule
                    ] = { permissions: [] };
                }
                pObj[permission.application][permission.module][
                    permission.submodule
                ].permissions.push(permission.action);
            }
        });
        return pObj;
    }

    getRedirectedUrl = () => {
        const permissions = this.getAllPersmission();
        const application = this.state.selectedApplication;

        // if (permissions == null) {
        //   return 'authentication/login';
        // }

        // if (permissions != null && permissions.length && application) {
        //   return 'authentication/login';
        // }        
        const pages = Object.keys(permissions[application]);
        const redirectionOrder = {
            LEADS: "leads",
            INBOUND: "inbound",
            INBOUND_VIEW: "inbound",
            LOAD_VIEW: "load",
            INVENTORY: "inventory",
            AUDITS: "inventory",
            TRACKING: "tracking",
            POD: "pod",
            BILLING: "billing",
            COD: "cod",
            REPORTS: "reports",
            RTO: "rto",
            RTS: "rts",
            SETTINGS: "settings",
            DASHBOARD: "dashboard",
            LIVE_LOADS: "live-loads",
            DELIVERY_LOADS: "delivery-loads"
        };
        let url = "";
        Object.keys(redirectionOrder).forEach(order => {
            if (url === "" && pages.indexOf(order) !== -1) {
                url = redirectionOrder[order];
            }
        });
        if (url !== "") {
            url = url + "/dashboard";
        } else {
            url = "authentication/login";
        }
        localStorage.setItem("defaultUrl", url);
        return url;
    }


    getApplicationState = (permission) => {
        if (!permission[0] || !permission[0].application) {
            return null;
        }
        return permission[0].application;
    }

    handleOTPLogin = e => {
        e.preventDefault();
        this.setState({ loginButtonDisable: true })
        this.props.form.validateFields(['mobileno', 'otp'], (err, values) => {            
            if (!err) {
                let mobile = values.mobileno;
                let mobileNo = mobile.replace(/-/gi, '');
                let params = {
                    "otp": values.otp,
                    "number": mobileNo,
                    "callingSystem": "B2B"
                }
                apiUtils.apiRequest({
                    method: 'POST',
                    url: `/v1/login/otp`,
                    // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
                    data: params
                })
                    .then(response => {

                        let _response = response;
                        Mixpanel.identify(response?.data?.response?.user?.id);
                        Mixpanel.people.set({
                            $first_name: response?.data?.response?.user?.name,
                            $email: response?.data?.response?.user?.email,
                            $phone_number: response?.data?.response?.user?.number,
                            $customer_id: response?.data?.response?.user?.location?.entity?.id,
                            $customer_name: response?.data?.response?.user?.location?.entity?.custObject?.name,
                        });
                        Mixpanel.track("Password Login Successfully done", { 'loginIdentity': moment().valueOf() })
                        if (this.checkHttpStatus(response?.data?.status)) {
                            const response = _response.data.response;
                            let modulenames = Object.keys(LsnModules);
                            const permissionData = response.user.permissions;
                            for (let i = 0; i <= permissionData.length; i++) {
                                if (permissionData[i]) {
                                    if (modulenames.includes(permissionData[i]['module'])) {
                                        this.setState({ nopermission: true });
                                        // this.nopermission = true;
                                        break;
                                    }
                                }
                            }

                            if (!this.state.nopermission) {
                                localStorage.setItem('isSassProduct', false);
                            }

                            if (Constants.ENV === 'local') {
                                const data = {
                                    'accessToken': response.token.accessToken,
                                    'refreshToken': response.token.refreshToken,
                                    'tokenId': response.token.tokenId,
                                    'user': btoa(JSON.stringify(response.user)),
                                    'permissions': btoa(JSON.stringify(this.parsePermission(response.user.permissions))),
                                    'application': btoa(this.getApplicationState(response.user.permissions)),
                                    'loginMethod': 'oldLogin',
                                    'isSassProduct': false
                                }
                                this.generateIndexFiles(data).then((res) => {
                                    // console.log("res->>", res)
                                });
                            }

                            this.store(this.parsePermission(response.user.permissions));
                            cookie.save('LOGGED_IN', true, { path: '/' });
                            localStorage.setItem('primaryAccessToken', response.token.accessToken);
                            localStorage.setItem('primaryTokenId', response.token.tokenId);
                            localStorage.setItem('accessToken', response.token.accessToken);
                            localStorage.setItem('refreshToken', response.token.refreshToken);
                            localStorage.setItem('tokenId', response.token.tokenId);
                            localStorage.setItem('user', btoa(JSON.stringify(response.user)));
                            localStorage.setItem('refreshFlag', JSON.stringify(new Date().valueOf()));
                            if (!this.state.redirectURL) {
                                let redirectURL = this.getRedirectedUrl();
                                this.setState({ redirectURL })
                            }
                            if (this.nopermission === false) {
                                // return this.helper.redirectToReact('/noPermission/dashboard');
                                this.props.history.push('nopermission/dashboard');
                                // this.router.navigateByUrl('nopermission/dashboard');
                            } else {
                                // console.log("this.state->>", this.state.redirectURL)
                                window.location.href = this.state.redirectURL;
                                // this.props.history.push(this.state.redirectURL);
                                // this.router.navigateByUrl(this.redierctURL);
                            }


                        }

                    })
                    .catch(error => {
                        this.setState({ loginButtonDisable: false })
                    })


            }

            else {
                this.setState({ loginButtonDisable: false })
            }
        });
    }
    handleLogin = e => {
        e.preventDefault();
        this.setState({ loginButtonDisable: true })
        this.props.form.validateFields(['username', 'password'], (err, values) => {
            if (!err) {

                let params = {
                    "username": values.username,
                    "password": values.password,
                    "isCustomer": true,
                    "callingSystem": "B2B"
                }
                apiUtils.apiRequest({
                    method: 'POST',
                    url: `/v1/login`,
                    headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },
                    data: params
                })
                    .then(response => {
                        this.setState({ loginButtonDisable: true })
                        let _response = response;
                        // console.log("response", response, response?.data?.response?.user?.location?.entity?.name)
                        Mixpanel.identify(response?.data?.response?.user?.id);
                        Mixpanel.people.set({
                            $first_name: response?.data?.response?.user?.name,
                            $email: response?.data?.response?.user?.email,
                            $phone_number: response?.data?.response?.user?.number,
                            $customer_id: response?.data?.response?.user?.location?.entity?.id,
                            $customer_name: response?.data?.response?.user?.location?.entity?.custObject?.name,
                        });
                        
                        Mixpanel.track("Password Login Successfully done", { 'loginIdentity': moment().valueOf() })

                        if (this.checkHttpStatus(response?.data?.status)) {
                            const response = _response.data.response;
                            let modulenames = Object.keys(LsnModules);
                            const permissionData = response.user.permissions;
                            for (let i = 0; i <= permissionData.length; i++) {
                                if (permissionData[i]) {
                                    if (modulenames.includes(permissionData[i]['module'])) {
                                        this.setState({ nopermission: true });
                                        // this.nopermission = true;
                                        break;
                                    }
                                }
                            }

                            if (!this.state.nopermission) {
                                localStorage.setItem('isSassProduct', false);
                            }

                            if (Constants.ENV === 'local') {
                                const data = {
                                    'accessToken': response.token.accessToken,
                                    'refreshToken': response.token.refreshToken,
                                    'tokenId': response.token.tokenId,
                                    'user': btoa(JSON.stringify(response.user)),
                                    'permissions': btoa(JSON.stringify(this.parsePermission(response.user.permissions))),
                                    'application': btoa(this.getApplicationState(response.user.permissions)),
                                    'loginMethod': 'oldLogin',
                                    'isSassProduct': false
                                }
                                this.generateIndexFiles(data).then((res) => {
                                    // console.log("res->>", res)
                                });
                            }

                            this.store(this.parsePermission(response.user.permissions));
                            cookie.save('LOGGED_IN', true, { path: '/' });
                            localStorage.setItem('primaryAccessToken', response.token.accessToken);
                            localStorage.setItem('primaryTokenId', response.token.tokenId);
                            localStorage.setItem('accessToken', response.token.accessToken);
                            localStorage.setItem('refreshToken', response.token.refreshToken);
                            localStorage.setItem('tokenId', response.token.tokenId);
                            localStorage.setItem('user', btoa(JSON.stringify(response.user)));
                            localStorage.setItem('refreshFlag', JSON.stringify(new Date().valueOf()));
                            if (!this.state.redirectURL) {
                                let redirectURL = this.getRedirectedUrl();
                                this.setState({ redirectURL })
                            }
                            if (this.nopermission === false) {
                                // return this.helper.redirectToReact('/noPermission/dashboard');
                                this.props.history.push('nopermission/dashboard');
                                // this.router.navigateByUrl('nopermission/dashboard');
                            } else {
                                // console.log("this.state->>", this.state.redirectURL)
                                window.location.href = this.state.redirectURL;
                                // this.context.history.push(this.state.redirectURL);
                                // this.router.navigateByUrl(this.redierctURL);
                            }


                        }

                    })
                    .catch(error => {
                        this.setState({ loginButtonDisable: false })
                    })

            } else {
                this.setState({ loginButtonDisable: false })
            }
        });
    };

    changeType = () => {
        let { passwordType } = this.state;
        if (passwordType === 'password') {
            passwordType = 'text';
        } else {
            passwordType = 'password';
        }

        this.setState({ passwordType });
    }

    changeLoginMethod = (key) => {
        this.props.form.resetFields();
        this.props.form.setFieldsValue({ 'mobileno': '' });        
        this.setState({ loginMethod: key });
        if(key == 'password') {
            this.props.form.validateFields(['username', 'password']);
        } else {
            this.props.form.validateFields(['mobileno']);
        }
    }

    submitOTP = () => {

        this.props.form.validateFields(['mobileno'], (err, values) => {
            if (!err) {
                // const { getFieldValue } = this.props.form;
                let mobile = values['mobileno'];
                let mobileNo = mobile.replace(/-/gi, '');
                apiUtils.apiRequest({
                    method: 'GET',
                    url: `/v1/otp/${mobileNo}`,
                    // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },            
                })
                    .then(response => {

                        if (this.checkHttpStatus(response?.data?.status)) {
                            if (response.data.status && response.data.status.code === 200) {
                                this.setState({ validOTP: true, showCounter: true }, () => {
                                    this.myInterval = setInterval(() => {
                                        const { seconds, minutes } = this.state

                                        if (seconds > 0) {
                                            this.setState(({ seconds }) => ({
                                                seconds: seconds - 1
                                            }))
                                        }
                                        if (seconds === 0) {
                                            if (minutes === 0) {
                                                clearInterval(this.myInterval);
                                                this.setState({ showCounter: false, minutes: 1, seconds: 0 })
                                            } else {
                                                this.setState(({ minutes }) => ({
                                                    minutes: minutes - 1,
                                                    seconds: 59
                                                }))
                                            }
                                        }
                                    }, 1000)
                                });
                            }
                        }

                    })
            }
        });

        // this.setState({validOTP: true});


    }

    resendOTP = () => {
        const { getFieldValue } = this.props.form;
        this.setState({ otpError: undefined })
        apiUtils.apiRequest({
            method: 'GET',
            url: `/v1/otp/${getFieldValue('mobileno')}`,
            // headers: { 'Content-Type': 'application/json', 'deviceId': '866409030924340' },            
        })
            .then(response => {

                if (this.checkHttpStatus(response?.data?.status)) {
                    if (response.data.status && response.data.status.code === 200) {
                        this.setState({ validOTP: true, count: this.state.count + 1, showCounter: true, clickedResendOTP: true });
                        this.myInterval = setInterval(() => {
                            const { seconds, minutes } = this.state

                            if (seconds > 0) {
                                this.setState(({ seconds }) => ({
                                    seconds: seconds - 1
                                }))
                            }
                            if (seconds === 0) {
                                if (minutes === 0) {
                                    clearInterval(this.myInterval);
                                    this.setState({ showCounter: false, minutes: 1, seconds: 0 })
                                } else {
                                    this.setState(({ minutes }) => ({
                                        minutes: minutes - 1,
                                        seconds: 59
                                    }))
                                }
                            }
                        }, 1000)
                    }
                } else {
                    this.setState({ otpError: response.data.status.message })
                }



            })
    }
    hideOTPPanel = () => {
        this.setState({ showOTPPanel: false })
    }



    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue } = this.props.form;
        let mobile = getFieldValue('mobileno');
        // Only show error after a field is touched.
        let usernameError = undefined, passwordError = undefined, mobileError = undefined, otpError = undefined;

        if (this.state.loginMethod == 'password') {
            usernameError = isFieldTouched('username') && getFieldError('username');
            passwordError = isFieldTouched('password') && getFieldError('password');
        } else {
            mobileError = isFieldTouched('mobileno') && getFieldError('mobileno');            
            otpError = isFieldTouched('otp') && getFieldError('otp');
        }
        const { minutes, seconds } = this.state



        return (
            <div className="containers">
                <Row>
                    <Col span={12} style={{
                        background: `url(${Login1}) no-repeat center center`,
                        backgroundSize: 'cover'
                    }}>
                        <div
                            className="centered-1"

                        />



                    </Col>
                    <Col span={12} className="login-form" style={{
                        background: `url(${Login2}) no-repeat center center`,
                        backgroundSize: 'cover'
                    }}>
                        <div
                            className="centered"

                        >
                            {/* <Card style={{ width: 100, heigth: 100 }}>Name</Card> */}
                            <Card
                                className="login-card"
                                style={{
                                    width: "410px",
                                    borderRadius: '10px'
                                }}
                            >
                                <h1 className="logo">

                                    <img src={Loadshare} />

                                </h1>
                                <br />
                                <div
                                    className="login-title"
                                >Login to Atlas</div>
                                {window.location.hostname != 'atlas.loadshare.net' ?

                                    <Tabs className="login-tabs" tabBarGutter={4} tabBarStyle={{ marginTop: 4 }} size="small" defaultActiveKey="otp" onChange={this.changeLoginMethod}>
                                        <TabPane className="login-tabpane" tab="OTP" key="1">
                                            <Form layout="vertical">
                                                <Form.Item validateStatus={mobileError ? 'error' : ''} help={mobileError || ''} label={<span className="form-label-text">MOBILE NUMBER</span>}>
                                                    {getFieldDecorator('mobileno', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Please input mobile no!'
                                                            },
                                                            {
                                                                pattern: validationRules.mobileWithRegex,
                                                                message: "Please enter valid mobile number"

                                                            }
                                                        ],
                                                    })(
                                                        // <Input
                                                        //     disabled={this.state.validOTP}
                                                        //     formatter={value => `${value}%`}

                                                        //     placeholder="Enter your Mobile Number"
                                                        // />,
                                                        <NumberFormat
                                                            value={mobile}
                                                            className="mobile-otp" type="tel" disabled={this.state.validOTP} format="###-###-####" mask=" " style={{
                                                                width: "100%",
                                                                height: "38px",
                                                                borderRadius: "4px",
                                                                border: "solid 1px #e2e5ed",
                                                                boxShadow: "inset 0 1px 2px 0 rgba(102, 113, 123, 0.21)",
                                                                borderSolid: "1px #e2e5ed"

                                                            }} />
                                                    )}
                                                </Form.Item>
                                                <span style={{ color: 'red' }}>{this.state.otpError}</span>
                                                {this.state.validOTP ?


                                                    <Form.Item validateStatus={otpError ? 'error' : ''} help={otpError || ''} label={<span className="form-label-text">One Time Password (OTP)</span>}>
                                                        {getFieldDecorator('otp', {
                                                            rules: [{ required: true, message: 'Please input mobile no!' }],
                                                        })(
                                                            // <Input

                                                            //     placeholder="Enter OTP Recieved"
                                                            // />,
                                                            <OtpInput
                                                                // onChange={this.handleChange}
                                                                numInputs={4}
                                                                containerStyle={{ padding: 15, height: "38px", borderRadius: 4, boxShadow: "inset 0 1px 2px 0 rgba(102, 113, 123, 0.21)", border: "solid 1px #e2e5ed" }}
                                                                focusStyle={{
                                                                    borderColor: "#40a9ff",
                                                                    outline: "0"
                                                                }}
                                                                inputStyle={{
                                                                    border: "none",
                                                                    width: "20px",
                                                                    height: "30px",
                                                                    fontFamily: "Roboto",
                                                                    fontSize: "20px",
                                                                    fontWeight: 500,
                                                                    fontStretch: "normal",
                                                                    fontStyle: "normal",
                                                                    lineHeight: "1.5",
                                                                    letterSpacing: "normal",
                                                                    color: "#3e3f42"

                                                                }}
                                                                isInputNum={true}

                                                                separator={<b>-</b>}
                                                            />
                                                        )}
                                                    </Form.Item> : <></>
                                                }
                                                <Form.Item>
                                                    {!this.state.validOTP ?
                                                        <Button type="primary" className="login-button" onClick={this.submitOTP} disabled={this.hasErrors(getFieldsError()) }>
                                                            GET OTP
                                                </Button> :
                                                        <>

                                                            <Button type="primary" className="login-button" onClick={this.handleOTPLogin} htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
                                                                SIGN IN
                                                    </Button>
                                                            {this.state.showOTPPanel ?
                                                                <Row>
                                                                    {this.state.count <= 3 ?
                                                                        <Col span={12}>
                                                                            {!this.state.showCounter ?
                                                                                <Button type="link" onClick={this.resendOTP}>Resend OTP</Button>
                                                                                : <div>
                                                                                    <b>Resend OTP: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</b>
                                                                                </div>
                                                                            }

                                                                        </Col>
                                                                        :
                                                                        <span>Maximum Count exceeded</span>
                                                                    }
                                                                    {this.state.clickedResendOTP &&
                                                                        <Col span={12} style={{ textAlign: 'right' }}>
                                                                            <Button type="link" onClick={this.hideOTPPanel}>Get OTP on Call</Button>
                                                                        </Col>
                                                                    }

                                                                </Row>
                                                                : <span style={{ color: '#1665d8' }}>Please call on 03339107816 to get the latest OTP</span>
                                                            }

                                                        </>}
                                                </Form.Item>
                                            </Form>

                                        </TabPane>

                                        <TabPane className="login-tabpane" tab="Password" key="password">
                                            <Form layout="vertical" onSubmit={this.handleLogin}>
                                                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''} label={<span className="form-label-text">E-MAIL or MOBILE NO</span>}>
                                                    {getFieldDecorator('username', {
                                                        rules: [{ required: true, message: 'Please input e-mail or mobile no!' }],
                                                    })(
                                                        <Input

                                                            placeholder="E-mail or phone number"
                                                        />,
                                                    )}
                                                </Form.Item>
                                                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} label={<span className="form-label-text">PASSWORD</span>}>
                                                    {getFieldDecorator('password', {
                                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                                    })(
                                                        <Input
                                                            type={this.state.passwordType}
                                                            placeholder="Password"
                                                            suffix={this.state.passwordType == 'password' ? <Icon type="eye" onClick={this.changeType} /> : <Icon type="eye-invisible" onClick={this.changeType} />}
                                                        />,
                                                    )}
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" className="login-button" htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
                                                        SIGN IN
                                            </Button>

                                                </Form.Item>
                                                <Form.Item>
                                                    <div className="forget-pass">
                                                        {/* <Button onClick={e => { this.props.history.push('/appv2/forget-password') }} type="link"> */}
                                                        <span onClick={e => { this.props.history.push('/appv2/forget-password') }} style={{ color: '#1665d8', cursor: 'pointer' }}>Forgot Password ?</span>
                                                        {/* </Button> */}
                                                    </div>
                                                </Form.Item>
                                            </Form>

                                        </TabPane>

                                    </Tabs>
                                    :
                                    <Form layout="vertical">
                                        <br />
                                        <Form.Item validateStatus={mobileError ? 'error' : ''} help={mobileError || ''} label={<span className="form-label-text">MOBILE NUMBER</span>}>
                                            {getFieldDecorator('mobileno', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input mobile no!'
                                                    },
                                                    {
                                                        pattern: validationRules.mobileWithRegex,
                                                        message: "Please enter valid mobile number"

                                                    }
                                                ],
                                            })(
                                                // <Input
                                                //     disabled={this.state.validOTP}
                                                //     placeholder="Enter your Mobile Number"
                                                // />,
                                                <NumberFormat className="mobile-otp" type="tel" disabled={this.state.validOTP} format="###-###-####" mask=" " style={{
                                                    width: "100%",
                                                    height: "38px",
                                                    borderRadius: "4px",
                                                    border: "solid 1px #e2e5ed",
                                                    boxShadow: "inset 0 1px 2px 0 rgba(102, 113, 123, 0.21)",
                                                    borderSolid: "1px #e2e5ed"

                                                }} />

                                            )}
                                        </Form.Item>
                                        <span style={{ color: 'red' }}>{this.state.otpError}</span>
                                        {this.state.validOTP ?


                                            <Form.Item validateStatus={otpError ? 'error' : ''} help={otpError || ''} label={<span className="form-label-text">One Time Password (OTP)</span>}>
                                                {getFieldDecorator('otp', {
                                                    rules: [{ required: true, message: 'Please input mobile no!' }],
                                                })(
                                                    // <Input

                                                    //     placeholder="Enter OTP Recieved"
                                                    // />,
                                                    <OtpInput
                                                        // onChange={this.handleChange}
                                                        numInputs={4}
                                                        containerStyle={{ padding: 15, height: "38px", borderRadius: 4, boxShadow: "inset 0 1px 2px 0 rgba(102, 113, 123, 0.21)", border: "solid 1px #e2e5ed" }}
                                                        focusStyle={{
                                                            borderColor: "#40a9ff",
                                                            outline: "0"
                                                        }}
                                                        inputStyle={{
                                                            border: "none",
                                                            width: "20px",
                                                            height: "30px",
                                                            fontFamily: "Roboto",
                                                            fontSize: "20px",
                                                            fontWeight: 500,
                                                            fontStretch: "normal",
                                                            fontStyle: "normal",
                                                            lineHeight: "1.5",
                                                            letterSpacing: "normal",
                                                            color: "#3e3f42"
                                                        }}
                                                        isInputNum={true}

                                                        separator={<b>-</b>}
                                                    />
                                                )}
                                            </Form.Item> : <></>
                                        }
                                        <Form.Item>
                                            {!this.state.validOTP ?
                                                <Button type="primary" className="login-button" onClick={this.submitOTP} disabled={this.hasErrors(getFieldsError()) }>
                                                    GET OTP
                                                </Button> :
                                                <>

                                                    <Button type="primary" className="login-button" onClick={this.handleOTPLogin} htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
                                                        SIGN IN
                                                    </Button>
                                                    {this.state.showOTPPanel ?
                                                        <Row>
                                                            {this.state.count <= 3 ?
                                                                <Col span={12}>
                                                                    {!this.state.showCounter ?
                                                                        <Button type="link" onClick={this.resendOTP}>Resend OTP</Button>
                                                                        : <div>
                                                                            <b>Resend OTP: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</b>
                                                                        </div>
                                                                    }

                                                                </Col>
                                                                :
                                                                <span>Maximum Count exceeded</span>
                                                            }
                                                            {this.state.clickedResendOTP &&
                                                                <Col span={12} style={{ textAlign: 'right' }}>
                                                                    <Button type="link" onClick={this.hideOTPPanel}>Get OTP on Call</Button>
                                                                </Col>
                                                            }

                                                        </Row>
                                                        : <span style={{ color: '#1665d8' }}>Please call on 03339107816 to get the latest OTP</span>
                                                    }

                                                </>}
                                        </Form.Item>
                                    </Form>

                                }
                            </Card>

                        </div>
                        <div className="copywrite-text">
                            © 2020 Loadshare Networks Pvt Ltd
                        </div>


                    </Col>

                </Row>


            </div>
            // <Row>
            //     <Col span={12}>
            //         <div className="login-1">
            //             <img src={Login1} style={{ objectFit: 'cover', width: '100%' }} />
            //         </div>
            //     </Col>
            //     <Col span={12}>
            //         <div className="container">
            //             {/* <div className="login-2">
            //                 <img src={Login2} style={{ objectFit: 'cover', width: '100%' }} />
            //             </div> */}
            //             <Card
            //                 className="login-card"
            //             >
            //                 <h1 className="logo">

            //                     <img src={Loadshare} />

            //                 </h1>
            //                 <h3>Login to Atlas</h3>
            //                 <Tabs defaultActiveKey="otp" onChange={this.changeLoginMethod}>
            //                     <TabPane tab="OTP" key="1">
            //                         <Form layout="vertical">
            //                             <Form.Item validateStatus={mobileError ? 'error' : ''} help={mobileError || ''} label={<span className="form-label-text">MOBILE NO</span>}>
            //                                 {getFieldDecorator('mobileno', {
            //                                     rules: [
            //                                         {
            //                                             required: true,
            //                                             message: 'Please input mobile no!'
            //                                         },
            //                                         {
            //                                             pattern: validationRules.mobile,
            //                                             message: "Please enter valid mobile number"

            //                                         }
            //                                     ],
            //                                 })(
            //                                     <Input
            //                                         disabled={this.state.validOTP}
            //                                         placeholder="Enter your Mobile Number"
            //                                     />,
            //                                 )}
            //                             </Form.Item>
            //                             <span style={{ color: 'red' }}>{this.state.otpError}</span>
            //                             {this.state.validOTP ?


            //                                 <Form.Item validateStatus={otpError ? 'error' : ''} help={otpError || ''} label={<span className="form-label-text">OTP</span>}>
            //                                     {getFieldDecorator('otp', {
            //                                         rules: [{ required: true, message: 'Please input mobile no!' }],
            //                                     })(
            //                                         <Input

            //                                             placeholder="Enter OTP Recieved"
            //                                         />,
            //                                     )}
            //                                 </Form.Item> : <></>
            //                             }
            //                             <Form.Item>
            //                                 {!this.state.validOTP ?
            //                                     <Button type="primary" className="login-button" onClick={this.submitOTP} disabled={this.hasErrors(getFieldsError())}>
            //                                         GET OTP
            //                                     </Button> :
            //                                     <>

            //                                         <Button type="primary" className="login-button" onClick={this.handleOTPLogin} htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
            //                                             SIGN IN
            //                                         </Button>
            //                                         {this.state.showOTPPanel ?
            //                                             <Row>
            //                                                 {this.state.count <= 3 ?
            //                                                     <Col span={12}>
            //                                                         {!this.state.showCounter ?
            //                                                             <Button type="link" onClick={this.resendOTP}>Resend OTP</Button>
            //                                                             : <div>
            //                                                                 <b>Resend OTP: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</b>
            //                                                             </div>
            //                                                         }

            //                                                     </Col>
            //                                                     :
            //                                                     <span>Maximum Count exceeded</span>
            //                                                 }
            //                                                 {this.state.clickedResendOTP &&
            //                                                     <Col span={12} style={{ textAlign: 'right' }}>
            //                                                         <Button type="link" onClick={this.hideOTPPanel}>Get OTP on Call</Button>
            //                                                     </Col>
            //                                                 }

            //                                             </Row>
            //                                             : <span style={{ color: '#1665d8' }}>Please call on 03339107816 to get the latest OTP</span>
            //                                         }

            //                                     </>}
            //                             </Form.Item>
            //                         </Form>

            //                     </TabPane>

            //                     <TabPane tab="Password" key="password">
            //                         <Form layout="vertical" onSubmit={this.handleLogin}>
            //                             <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''} label={<span className="form-label-text">E-MAIL or MOBILE NO</span>}>
            //                                 {getFieldDecorator('username', {
            //                                     rules: [{ required: true, message: 'Please input e-mail or mobile no!' }],
            //                                 })(
            //                                     <Input

            //                                         placeholder="Username"
            //                                     />,
            //                                 )}
            //                             </Form.Item>
            //                             <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} label={<span className="form-label-text">PASSWORD</span>}>
            //                                 {getFieldDecorator('password', {
            //                                     rules: [{ required: true, message: 'Please input your Password!' }],
            //                                 })(
            //                                     <Input
            //                                         type={this.state.passwordType}
            //                                         placeholder="Password"
            //                                         suffix={this.state.passwordType == 'password' ? <Icon type="eye" onClick={this.changeType} /> : <Icon type="eye-invisible" onClick={this.changeType} />}
            //                                     />,
            //                                 )}
            //                             </Form.Item>
            //                             <Form.Item>
            //                                 <Button type="primary" className="login-button" htmlType="submit" disabled={this.hasErrors(getFieldsError())}>
            //                                     SIGN IN
            //                                 </Button>
            //                                 <div className="forget-pass">
            //                                     <Button onClick={e => { this.props.history.push('/appv2/forget-password') }} type="link">
            //                                         <span style={{ color: '#1665d8' }}>Forgot Password ?</span>
            //                                     </Button>
            //                                 </div>

            //                             </Form.Item>
            //                         </Form>

            //                     </TabPane>

            //                 </Tabs>
            //             </Card>
            //         </div>


            //     </Col>
            // </Row>

        )
    }
}

const LoginForm = Form.create({ name: 'login' })(Login);


export default LoginForm;