import React, { useEffect, useRef, useState } from 'react';
import IndentForm from './indent.form';
import indentFormSchema from '../form/indent.form.schema';
import { useDispatch, useSelector } from 'react-redux';
import { indentCreateActionTypes } from '../state/actions';
import { fetchVehicleTypesEnums } from '../../../../services/common/fetch.services';
import { getServiceTypesLOVs, searchCustomers, searchLanes, searchLocation, searchShipper } from '../services/search.services';
import { createIndent, dataToPostObj, getIndentById, responseToData, validateIndentLoads } from '../services/indent';
import CreateLocationPopup from '../../../settings/contract/locations/create.location';
import { notifyApiSuccess } from '../../../../common/utils/utils';
import '../styles/indent.scss';
import CreateLanePopup from '../../../settings/contract/contracts/components/create.lanes';
import moment from 'moment';
import { Modal } from 'antd';

var submitIndent   = false;

/*
 ~ Developer Comments ~
 Clean up maybe required. 
 File length exceeds 400 lines as option in the form dynamically vary based on the other options selected.
 Can be optimized by writing individual widgets and redux store. Should rewrite after testing it in reports.
*/
export default function IndentFormContainer (props) {

    var formRef = useRef();
    const dispatch = useDispatch();
    const formData = useSelector(state => state.indentCreateReducer.indentData)
    const [schema, SetSchema]     = useState(JSON.parse(JSON.stringify(indentFormSchema)));
    const [loading, SetLoading]   = useState(true);
    const [saving, SetSaving]     = useState(false);
    const [noIndentLoadsError, SetNoIndentLoadsError] = useState(false);
    const [createAnother, SetCreateAnother] = useState(false); 

    var serviceTypeConstants = useSelector(state => state.lovStore.lovConstants["ServiceType"])
    
    const reloadForm = _ => {
        SetLoading(true);
        SetSchema(schema);
        setTimeout(() => SetLoading(false), 0);
    }
    
    const reloadPageToDefault = async _ => {
        SetLoading(true);
        const tempSchema =  await loadSchemaOptions(JSON.parse(JSON.stringify(indentFormSchema)));
        dispatch({
            type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
            payload : {
                details      : {
                    indentDate : moment().valueOf(),
                    hubLocationId: +localStorage.getItem('currentPartnerLocationId')
                },
                remarksObj   : {},
                indentLoads  : [{
                    location : {},
                    weight : "",
                    quantity : "",
                    volume   : ""
                }],
                reasonBOList: []
            }
        });
        SetSchema(tempSchema);
        setTimeout(() => SetLoading(false), 0);
    }

    const loadSchemaOptions = async tempSchema => {
        const [ vehicleTypes, serviceTypeLOVs ] = await Promise.all([ fetchVehicleTypesEnums(true), getServiceTypesLOVs() ]);
        tempSchema.schema.properties.details.properties.serviceType["enum"]      = serviceTypeLOVs[0];
        tempSchema.schema.properties.details.properties.serviceType["enumNames"] = serviceTypeLOVs[1];
        tempSchema.schema.properties.details.properties.serviceType.disabled     = false;
        tempSchema.schema.properties.details.properties.vehicleType["enum"]      = vehicleTypes[0];
        tempSchema.schema.properties.details.properties.vehicleType["enumNames"] = vehicleTypes[1];
        tempSchema.schema.properties.details.properties.vehicleType.disabled     = false;
        tempSchema.schema.properties.details.properties.customer.apiCall = searchCustomers;
        if (!serviceTypeConstants && serviceTypeLOVs[2]) {
            serviceTypeConstants = serviceTypeLOVs[2]
        }
        return tempSchema;
    }

    const loadPageDetails = async _ => {
        SetLoading(true);
        try {
            const tempSchema = await loadSchemaOptions(JSON.parse(JSON.stringify(indentFormSchema)));
            if (props.indentId) {
                getIndentById(props.indentId).then(response => {
                    dispatch({
                        type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
                        payload : responseToData(response)
                    });

                    tempSchema.uiSchema.details.customer.disabled     = true;
                    tempSchema.uiSchema.details.shipper.disabled      = true;
                    tempSchema.uiSchema.details.serviceType.disabled  = true;
                    tempSchema.schema.properties.details.properties.shipper.apiCall = searchShipper(response.customerBO.id, true);

                    tempSchema.uiSchema.details.pickupLocation.disabled = false;
                    tempSchema.schema.properties.details.properties.pickupLocation.onAddClick = openAddLocation;
                    tempSchema.schema.properties.details.properties.pickupLocation.apiCall = searchLocation(response.customerBO.id, true)
                    
                    searchLanes(response.customerBO.id, response.shipperBO.id, response.vehicleTypeBO.id).then(lanes => {
                        var enums = [];
                        var enumNames = [];
                        // lanes.map(l => {
                        //     enums.push(l.value);
                        //     enumNames.push(l.label);
                        // })
                        if (response.serviceTypeBO.lovCode == serviceTypeConstants.FTL) {
                            tempSchema.schema.properties["ftlData"] = schema.FTLSchema;
                            tempSchema.uiSchema["ftlData"] = schema.FTLUiSchema;
                            // tempSchema.schema.properties.ftlData.properties.lane.enum = enums;
                            // tempSchema.schema.properties.ftlData.properties.lane.enumNames = enumNames;
                            tempSchema.schema.properties.ftlData.properties.lane.onAddClick = openAddLanes
                            if (tempSchema.schema.properties.details.required.indexOf("vehicleType") < 0) {
                                tempSchema.schema.properties.details.required.push("vehicleType");
                            }
                        }
    
                        SetSchema(tempSchema);
                        setTimeout(() => SetLoading(false), 0);
                    })

                }).catch(_ => {
                    if (props.history && props.history?.push) {
                        props.history.push("/appv2/indent/dashboard")
                    }
                })
            }
            else {
                reloadPageToDefault();
            }
        }
        catch (e) {
            SetSchema(schema);
            setTimeout(() => SetLoading(false), 0);
        }
    }

    const onPageDestroy = _ => {
        dispatch({
            type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
            payload : {
                details      : {},
                remarksObj   : {},
                indentLoads  : [{
                    location : {},
                    weight : "",
                    quantity : "",
                    volume   : ""
                }]
            }
        });
    }

    useEffect(() => onPageDestroy, [])

    const indentIdRef = usePrevious(props.indentId);
    useEffect(() => {
        if (!props.indentId || props.indentId != indentIdRef) {
            loadPageDetails();
        }
    }, [ props.indentId ])

    useEffect(() => {
        SetNoIndentLoadsError(false);
    }, [ formData ])

    const checkIndentLoads = _ => {
        if ( formData.indentLoads?.length > 0 && validateIndentLoads(formData.indentLoads, formData?.details?.serviceType?.key == serviceTypeConstants.PTL, false)) {
            SetNoIndentLoadsError(false);
            return false;
        }
        else {
            SetNoIndentLoadsError(true);
            return true;
        }
    }

    const checkPickupLocationInLoads = _ => {
        var pickupPresentInLoads = false;
        if ( formData.indentLoads?.length > 0) {
            formData.indentLoads.forEach(element => {
                if (element.location && element.location?.id === formData?.details?.pickupLocation?.key) {
                    Modal.warn({
                        title   : "Pickup location cannot also be a destination location for indent load!"
                    })
                    pickupPresentInLoads =  true;
                }
            });
        }
        return pickupPresentInLoads;
    }
    
    const validate = (data, errorsObj) => {
        if (data.ftlData && data?.ftlData?.pickupAt && data?.ftlData?.pickupAt <= data?.details?.indentDate && errorsObj?.ftlData?.pickupAt) {
            errorsObj.ftlData.pickupAt.addError("Pickup Date & Time cannot be earlier than Indent Date!")
        }
        return errorsObj
    }

    const onSubmit = data => {
        console.log("data", data)
        if (data?.details?.serviceType?.key == serviceTypeConstants.PTL && submitIndent && checkIndentLoads()) {
            return;
        }
        if (checkPickupLocationInLoads()) {
            return;
        }
        SetSaving(true);
        // console.log("data", dataToPostObj(data, submitIndent))
        createIndent(dataToPostObj(data, submitIndent)).then(response => {
            if (response) {
                notifyApiSuccess("Indent created successfully!", response);
            }
            if (createAnother) {
                reloadPageToDefault();
            }
            else {
                if (typeof props.onIndentCreateSuccess === "function") {
                    props.onIndentCreateSuccess(response, submitIndent)
                }
                else {
                    props.onCancel();
                }    
            }
            SetSaving(false);
        }).catch(e => {
            if (typeof props.onIndentCreateFail === "function") {
                props.onIndentCreateFail(e, data, submitIndent)
            }
            SetSaving(false);
        })
    }

    const addFTLFields  = data => {
        if (schema.schema.properties.details.required.indexOf("vehicleType") < 0) {
            schema.schema.properties.details.required.push("vehicleType");
        }
        schema.schema.properties["ftlData"] = schema.FTLSchema;
        schema.uiSchema["ftlData"] = schema.FTLUiSchema;
        data["ftlData"] = {
            pickupAt : moment().add(1, 'day').valueOf()
        };
    }
    const removeFTLFields = data => {
        schema.schema.properties.details.required = schema.schema.properties.details.required.filter(r => r != "vehicleType");
        delete schema.schema.properties.ftlData;
        delete schema.uiSchema.ftlData;
        delete data.ftlData;
    }

    const disableLanesField  = _ => {
        if (schema.uiSchema.hasOwnProperty("ftlData")) {
            schema.uiSchema.ftlData.lane.disabled = true
        }
    }
    const enableLanesField   = data => new Promise(resolve => {
        if ( data.details.customer && data.details.customer?.key &&
             data.details.shipper && data.details.shipper?.key &&
             data.details.vehicleType && data.details.vehicleType?.key ) {
            if (schema.schema.properties.hasOwnProperty("ftlData") && schema.uiSchema.hasOwnProperty("ftlData")) {
                if (data.ftlData) {
                    delete data.ftlData.lane;
                }
                // var customerId  = parseInt(data.details.customer.key.split("___")[0])
                // var shipperId   = data.details.shipper?.key;
                // var vehicleType = data.details.vehicleType?.key;
                schema.schema.properties.ftlData.properties.lane.onAddClick = openAddLanes
                schema.uiSchema.ftlData.lane.disabled = false
                resolve(true)
                // searchLanes(customerId, shipperId, vehicleType).then(lanes => {
                //     var enums = [];
                //     var enumNames = [];
                //     lanes.map(l => {
                //         enums.push(l.value);
                //         enumNames.push(l.label);
                //     })
                //     schema.schema.properties.ftlData.properties.lane.enum = enums;
                //     schema.schema.properties.ftlData.properties.lane.enumNames = enumNames;
                //     schema.schema.properties.ftlData.properties.lane.onAddClick = openAddLanes
                //     schema.uiSchema.ftlData.lane.disabled = false
                //     resolve(true);
                // })
            }
            else {
                resolve(true);
            }
        }
        else {
            if (schema.schema.properties.hasOwnProperty("ftlData") && schema.uiSchema.hasOwnProperty("ftlData")) {
                schema.uiSchema.ftlData.lane.disabled = true;
            }
            resolve(false);
        }
    })
    
    const disablePickupField = _ => {
        schema.uiSchema.details.pickupLocation.disabled = true;
        delete schema.schema.properties.details.properties.pickupLocation.onAddClick;
        delete schema.schema.properties.details.properties.pickupLocation.apiCall;
    }

    const resetDestinationLoads = data => {
        data.indentLoads?.length > 0 &&
        data.indentLoads.map(load => {
            delete load.location;
            return load;
        })
    }

    const onCustomerChange = data => new Promise(resolve => {
        //Reset Shipper, Pickup Location and Lane if present.
        delete data.details.shipper;
        delete data.details.pickupLocation;
        if (data.ftlData) {
            delete data.ftlData.lane;
        }
        disablePickupField();
        disableLanesField();
        //Reset all destination Locations seleced
        resetDestinationLoads(data);

        schema.uiSchema.details.shipper.disabled = false;
        schema.schema.properties.details.properties.shipper.apiCall = searchShipper(data.details.customer?.key.split("___")[0], true);
        schema.uiSchema.details.pickupLocation.disabled = false;
        schema.schema.properties.details.properties.pickupLocation.onAddClick = openAddLocation;
        schema.schema.properties.details.properties.pickupLocation.apiCall = searchLocation(data.details.customer.key.split("___")[0], true)
        
        resolve(true);
    })

    const onShipperVehicleTypeChange = data => new Promise(resolve => {
        if (data.details.serviceType && data.details.serviceType.key == serviceTypeConstants.FTL) {
            resolve(enableLanesField(data));
        }
        resolve(false);
    })

    const onServiceTypeChange = data => new Promise(resolve =>{
        if (!data.details.serviceType || !data.details.serviceType?.key) resolve(false);
        if (data.details.serviceType.key == serviceTypeConstants.FTL) {
            addFTLFields(data);
            enableLanesField(data).then(_ => resolve(true));
        }
        else if (data.details.serviceType.key == serviceTypeConstants.PTL) {
            if (schema.schema.properties.ftlData) {
                removeFTLFields(data)   
            }
            resolve(true);
        }
    })

    const onChange = async data => {
        var shouldReloadForm = false;
        if (data.details.customer && (data.details.customer?.key !== formData.details.customer?.key)) {
            shouldReloadForm = await onCustomerChange(data);
        }
        if (data.details.shipper && (data.details.shipper?.key !== formData.details.shipper?.key)) {
            shouldReloadForm = await onShipperVehicleTypeChange(data);
        }
        if (data.details.serviceType && data.details.serviceType?.key != formData.details.serviceType?.key) {
            shouldReloadForm = await onServiceTypeChange(data);
        }
        if (data.details.vehicleType && data.details.vehicleType?.key != formData.details.vehicleType?.key) {
            shouldReloadForm = await onShipperVehicleTypeChange(data);
        }
        if (shouldReloadForm) {
            reloadForm();
        }
        dispatch({
            type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
            payload : data
        });
    }

    const [locationModal, toggleLocationModal] = useState(false);
    const openAddLocation = _ => toggleLocationModal(true);
    const onLocationCreate = data => {
        dispatch({
            type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
            payload : {
                ...formData,
                details : {
                    ...formData.details,
                    pickupLocation : {
                        value : data.id,
                        key   : data.id,
                        label : data.name
                    }
                }
            }
        });
        toggleLocationModal(false);
    }

    const [lanesModal, toggleLaneModal] = useState(false);
    const openAddLanes = _ => toggleLaneModal(true);
    const onLaneCreate =  data => {      
        
        dispatch({
            type    : indentCreateActionTypes.UPDATE_INDENT_DATA,
            payload : {
                ...formData,
                ftlData : {
                    ...formData.ftlData,
                    lane : data.name
                }
            }
        });
        toggleLaneModal(false);
    }
    
    const cancelIndentCreation = _ => {
        loadPageDetails();
        props.onCancel();
    }

    const onError = _ => {
        if (submitIndent && formData?.details?.serviceType?.key == serviceTypeConstants?.PTL ) {
            checkIndentLoads();
        }
    }

    return (
        <>
            <IndentForm schema={schema} onChange={onChange} formRef={formRef}
             onSubmit={onSubmit} loading={loading} readonly={props.readonly}
             saving={saving}
             indentId={props.indentId}
             validate={validate}
             saveIndent={submit => {
                submitIndent = submit;
                SetNoIndentLoadsError(false);
                formRef.current.formReference?.current?.submit()
             }} cancelIndentCreation={cancelIndentCreation}
             {...props} noIndentLoadsError={noIndentLoadsError} onError={onError}
             createAnother={createAnother} SetCreateAnother={SetCreateAnother}/>           

            { 
                locationModal && !props.readonly &&
                <CreateLocationPopup 
                 openModal={locationModal} setModal={toggleLocationModal}
                 onLocationCreate={onLocationCreate}
                 disableCreateAnother={true}
                 customer={{
                    id   : formData.details.customer.key.split("___")[0],
                    code : formData.details.customer.key.split("___")[1]
                 }}/>
            }

            {
                lanesModal && !props.readonly &&
                <CreateLanePopup disallowLaneTypeChange={true}
                 openModal={lanesModal} setModal={toggleLaneModal}
                 noContractLane={true}
                 customer={{
                    id   : formData.details.customer.key.split("___")[0],
                    code : formData.details.customer.key.split("___")[1]
                 }}
                 onLaneSave={lane =>  onLaneCreate(lane.laneBO) }/>
            }
        </>
    )
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}
  