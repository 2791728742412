import { Icon, Select, Tooltip } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { checkForDuplicateErrors, schemaToAttrs } from "../form.utils";

export default function SelectAPI(props) {

    const [ options, SetOptions ] = useState(props.options.enumOptions);
    const [ notFoundContent, SetNotFoundContent ] = useState(props.uiSchema.dropdownPlaceholder);
    const [ value, SetValue ] = useState(props.value);
    const [ loading, SetLoading ] = useState(true);
    
    const debouncedCallApi = debounce(value => callAPI(value), 800)
    const callAPI = value => {
        if (typeof props.schema.apiCall === "function") {
            props.schema.apiCall(value)
            .then(response => {
                if (response.length <= 0) {
                    SetNotFoundContent( value.trim() !== "" ? 
                                        props.uiSchema?.noContentFound ? props.uiSchema?.noContentFound : "No data found" :
                                        props.uiSchema.dropdownPlaceholder ? props.uiSchema.dropdownPlaceholder : "Enter a phrase to search");
                    SetOptions([{
                        label : props.uiSchema?.noContentFound ? props.uiSchema?.noContentFound : "No data found",
                        value : undefined
                    }]);
                } 
                else {
                    SetOptions(response);
                }
            })
            .catch();
        }
    }

    const uiAttrs = schemaToAttrs(props.uiSchema, props.readonly);
    const mode = props.schema.multiple ? "multiple" : "default";

    const disabled = props.disabled || props.readonly || uiAttrs.disabled;
    delete uiAttrs.disabled;

    useEffect(() => {
        var tempDefault = props.value;
        if(props.schema.multiple && !Array.isArray(props.value)) {
            tempDefault =[];
        }
        SetValue(props.value);
        SetLoading(false);
    }, [])

    useEffect(() => {
        SetValue(props.value);
    }, [ props.value ])

    return (
        <div className={"input-wrapper flex-column" + (props.schema.enableAddOption && typeof props.schema.onAddClick === "function" ? " add-option" : "")}>
            {
                !loading &&
                <Select onChange={value => {
                    var temp = value;
                    if (props.schema.expandOnHover) {
                        temp ={
                            key   : value.key,
                            label : value.label.props.children
                        }
                    }
                    SetValue(temp);
                    props.onChange(temp)
                }} showSearch={props.schema.searchEnabled}
                    filterOption={props.schema.searchEnabled ? filterFunciton() : undefined}
                    showArrow={true}
                    value={value}
                    disabled={disabled} className={uiAttrs.className}
                    {...uiAttrs} mode={mode} notFoundContent={notFoundContent}
                    onSearch={debouncedCallApi} onFocus={() => callAPI("")} onBlur={_ => SetOptions([])}
                >
                    {
                        options?.length > 0 &&
                        options?.map(option => {
                            if (option.value) {
                                return (
                                    <Select.Option value={option.value} key={option.value}>
                                        {
                                            props.schema.expandOnHover ?
                                            <Tooltip title={option.label}>
                                                {option.label}
                                            </Tooltip> :
                                            option.label 
                                        }
                                    </Select.Option>
                                )
                            }
                        })
                    }
                </Select>
            }
            {
                props.rawErrors?.length > 0 &&
                <div className="error-text"> {checkForDuplicateErrors(props.rawErrors).join(" ")} </div>
            }
            {
                props.schema.enableAddOption && typeof props.schema.onAddClick === "function" && !props.readonly &&
                <div className="option-add-button">
                    <Icon className="font-size-l-1" type="plus-circle" theme="twoTone" twoToneColor="#50b154" onClick={props.schema.onAddClick}/>
                </div>
            }
        </div>
    )

    function filterFunciton() {
        if (typeof props.schema.filterFunciton == "function") {
            return (input, option) => props.schema.filterFunciton(input, option);
        }
        else if (props.schema.expandOnHover) {
            return (input, option) => option.props.children.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
        }
        else {
            return (input, option) => {
                return option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
            }
        }
    }
}