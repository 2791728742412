import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Icon, Checkbox, Form } from "antd";
import FormWrapper from '../../../../ui/formComponent/form';
import boxTypeForm from './boxtype.form.d';
import { addUpdateBoxType } from "./boxtype.service";
import "./boxtype.scss";

import _ from 'lodash';
import { notifyApiSuccess } from '../../../../../common/utils/utils';

const { confirm } = Modal;

function BoxTypeForm(props) {
    const boxTypeRef = useRef();
    const [schema, SetSchema] = useState(boxTypeForm.schema);
    // const [uiSchema, setUISchema] = useState(boxTypeForm.uiSchema);
    const [formData, SetFormData] = useState(props.formData ? props.formData : {});
    const [readOnly, setReadOnly] = useState(props.readOnly ? props.readOnly : false);
    const [isCancel, setCancel] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setCreateAnother, createAnother } = props;
    const onSubmit = (formProps) => {

        setLoading(true);
        let lbh = [];
        if (formProps.type === 'BOX') {
            lbh = formProps.lbh.split(",");
        }

        let params = {
            "boxTypes": [
                {
                    "name": formProps.name,
                    "type": formProps.type,
                    ...(lbh.length > 2 && { "length": lbh[0] }),
                    ...(lbh.length > 2 && { "breadth": lbh[1] }),
                    ...(lbh.length > 2 && { "height": lbh[2] }),
                    ...(formProps.type === 'BOX' && { "dimensionUnit": formProps.dimensionUnit }),
                    "kgPerCft": formProps.kgPerCft,
                    "customerId": props.customerId
                }
            ],

        }
        if (formData.id) {
            params = {
                ...params,
                boxTypes: [{
                    ...params.boxTypes[0],
                    id: formData.id
                }],
                "action": "UPDATE"
            }
            onSave(params, "updated");
        } else {
            params = {
                ...params,
                "action": "CREATE"
            }
            onSave(params, "created");
        }


    }

    const onSave = (params, message) => {
        addUpdateBoxType(params).then(response => {

            if (response.boxTypes.length > 0) {
                setLoading(false);
                notifyApiSuccess(`Box Type ${message} successfully!!`, "SUCCESS");
                props.onCancel();
                if (typeof props.setRefresh === 'function') {
                    props.setRefresh(!props.refresh);
                }
                if (typeof props.setReadOnly === 'function') {
                    props?.setReadOnly(false);
                }

                if (typeof props.getCreatedBox === 'function') {
                    props.getCreatedBox(response.boxTypes[0]);
                }
                if (createAnother) {

                    props.setBoxForm(true);

                    setCreateAnother(true);


                }


            } else {
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false);
        })
    }
    const onChange = params => {
        SetFormData(params);
        if (!isCancel) {
            setCancel(true);
        }

    }

    const validate = (data, errorObj) => {

        const { lbh } = data;
        if (data.type === 'BOX') {

            if (lbh) {
                var value = lbh instanceof Array ? [lbh[0], lbh[1], lbh[2]] : lbh.split(",");
                if (_.isEmpty(value[0]) || _.isEmpty(value[1]) || _.isEmpty(value[2])) {
                    var errorKeyObj = errorObj['lbh'];
                    errorKeyObj.__errors = ["LBH is Mandatory"];
                }

            }
        }
        return errorObj;
    }

    useEffect(() => {
        if (!formData.id && !readOnly && props?.defaultKgCfg) {
            SetFormData({
                ...formData,
                kgPerCft: Number(props.defaultKgCfg)
            })
        }
    }, [])

    useEffect(() => {
        if (formData.dimensionUnit) {
           
            SetSchema({
                ...schema,
                "dependencies": {
                    ...schema.dependencies,
                    "type": {
                        ...schema.dependencies.type,
                        "oneOf": [

                            {...schema.dependencies.type.oneOf[0]},

                            {
                                ...schema.dependencies.type.oneOf[1],
                                "properties": {
                                    ...schema.dependencies.type.oneOf[1].properties,
                                    "lbh": {
                                        ...schema.dependencies.type.oneOf[1].properties.lbh,
                                        title: `LBH (${formData.dimensionUnit})`
                                    }
                                }
                            }

                        ]
                    }
                }




            })
        }

    }, [formData.dimensionUnit])
    console.log("schema", schema)

    return (
        <Modal
            closable={false}
            visible={props.visible}
            width={'60%'}
            style={{ top: 20 }}
            onCancel={props.onCancel}
            footer={
                !readOnly &&
                <div className="flex-box flex-gap-m justify-content-end align-items-center boxtype-modal">
                    <div className="flex-box">
                        {(!readOnly && _.isEmpty(props.formData)) &&
                            <Checkbox
                                checked={createAnother}
                                onChange={(e) => {
                                    const check = !createAnother;

                                    setCreateAnother(check)
                                }}
                            >
                                Create Another
                        </Checkbox>
                        }

                    </div>
                    <div className="flex-box">
                        <Button block onClick={() => {
                            if (isCancel && !_.isEmpty(formData)) {
                                confirm({
                                    title: 'Are you sure to cancel?',
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk: () => {
                                        props.onCancel()
                                    }
                                })
                            } else {
                                props.onCancel()
                            }

                        }} >
                            Cancel
                    </Button>
                    </div>
                    <div className="flex-box">
                        <Button loading={loading} block className="lsn-btn-primary" onClick={() => boxTypeRef.current.formReference.current.submit()}>
                            {formData.id ? 'Save Box' : 'Add Box'}
                        </Button>
                    </div>



                </div>


            }
        >
            <div className="flex-column">
                <div className="flex-box align-items-center">
                    <div>
                        <Icon className="cursor-pointer" type="arrow-left" onClick={() => {
                            if (isCancel && !_.isEmpty(formData)) {
                                confirm({
                                    title: 'Are you sure to cancel?',
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk: () => {
                                        props.onCancel()
                                    }
                                })
                            } else {
                                props.onCancel()
                            }
                        }} />
                    </div>
                </div>
                <div>
                    <FormWrapper
                        schema={schema}
                        uiSchema={boxTypeForm.uiSchema}

                        errorList={boxTypeForm.errorList}
                        validations={boxTypeForm.validations}
                        formData={formData}
                        onSubmit={onSubmit}
                        readonly={readOnly}
                        fieldsPerRow={1}
                        showColumnSplitter={false}
                        bordered={true}
                        hideSubmit={true}
                        onChange={onChange}
                        ref={boxTypeRef}
                        validate={validate}
                    // transformErrors={transformErrors}

                    />
                </div>
            </div>


        </Modal>
    )
}

export default BoxTypeForm;