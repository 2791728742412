
import { initialState, FETCH_SERVICEABLE_AREA_PINCODES_BEGIN, FETCH_SERVICEABLE_AREA_PINCODES_SUCCESS, FETCH_SERVICEABLE_AREA_PINCODES_FAILURE } from './constants';
import { FETCH_EXCEPTION_BEGIN, FETCH_EXCEPTION_SUCCESS, FETCH_EXCEPTION_FAILURE } from './constants';
import { UPDATE_BOOKING_SERVICE_BEGIN, UPDATE_BOOKING_SERVICE_FAILURE, UPDATE_BOOKING_SERVICE_SUCCESS } from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const ExceptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EXCEPTION_BEGIN: return fetchExpectionBegin(state);
        case FETCH_EXCEPTION_SUCCESS: return fetchExpectionSuccess(state, action);
        case FETCH_EXCEPTION_FAILURE: return fetchExpectionFailure(state, action);

        case FETCH_SERVICEABLE_AREA_PINCODES_BEGIN: return fetchServiceableAreaPinCodesBegin(state);
        case FETCH_SERVICEABLE_AREA_PINCODES_SUCCESS: return fetchServiceableAreaPinCodesSuccess(state, action);
        case FETCH_SERVICEABLE_AREA_PINCODES_FAILURE: return fetchServiceableAreaPinCodesFailure(state, action);

        case UPDATE_BOOKING_SERVICE_BEGIN: return fetchExpectionBegin(state);
        case UPDATE_BOOKING_SERVICE_SUCCESS: return fetchExpectionSuccess(state, action);
        case UPDATE_BOOKING_SERVICE_FAILURE: return fetchExpectionFailure(state, action);

        default:
            return state;
    }
}

function fetchExpectionBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchExpectionSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedException: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedException ? state.PaginatedException : {}, action ?.payload ?.data, action.isReset),
        ...action.payload
    };
}

function fetchExpectionFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        data: []
    };
}

function fetchServiceableAreaPinCodesBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchServiceableAreaPinCodesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function fetchServiceableAreaPinCodesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        serviceableArea: []
    };
}
