
export const initialState = {
}

export const FETCH_LIVE_LOADS_BEGIN = "FETCH_LIVE_LOADS_BEGIN";
export const FETCH_LIVE_LOADS_SUCCESS = "FETCH_LIVE_LOADS_SUCCESS";
export const FETCH_LIVE_LOADS_FAILURE = "FETCH_LIVE_LOADS_FAILURE";

export const FETCH_LIVE_LOADS_SUMMARY_BEGIN = "FETCH_LIVE_LOADS_SUMMARY_BEGIN";
export const FETCH_LIVE_LOADS_SUMMARY_SUCCESS = "FETCH_LIVE_LOADS_SUMMARY_SUCCESS";
export const FETCH_LIVE_LOADS_SUMMARY_FAILURE = "FETCH_LIVE_LOADS_SUMMARY_FAILURE";

export const EXPORT_LIVE_LOADS_BEGIN = "EXPORT_LIVE_LOADS_BEGIN";
export const EXPORT_LIVE_LOADS_SUCCESS = "EXPORT_LIVE_LOADS_SUCCESS";
export const EXPORT_LIVE_LOADS_FAILURE = "EXPORT_LIVE_LOADS_FAILURE";

export const LOCATION_LIVE_LOADS_BEGIN = "LOCATION_LIVE_LOADS_BEGIN";
export const LOCATION_LIVE_LOADS_SUCCESS = "LOCATION_LIVE_LOADS_SUCCESS";
export const LOCATION_LIVE_LOADS_FAILURE = "LOCATION_LIVE_LOADS_FAILURE";