import { apiRequest } from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
export default class PickupAssistService {

    fetchInventory(payload = {}){
        return apiRequest({
            url: `/b2b/v2/consignments/unassigned`,
            method: "POST",
            data: {
                filters: {
                    ...payload ?.filters,
                },
                excludeDraftedConsignments : true
            }
        })
    }
    
    fetchClients(){
        return apiRequest({
            url: `/b2c/v1/customers/inventory`,
            method: "POST",
            data: {}
        })
    }

    createDrsDrafts(payload = {}){
        return apiRequest({
            url: `/b2c/v1/runsheetdraft`,
            method: "POST",
            data: payload
        })
    }

    distanceCalculate(payload = {}){
        return apiRequest({
            url: `/b2c/v1/route/planning`,
            method: "POST",
            data: payload
        })
    }

}
