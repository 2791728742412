export const GET_ROUTES_LIST_BEGIN = 'GET_ROUTES_LIST_BEGIN';
export const GET_ROUTES_LIST_SUCCESS = 'GET_ROUTES_LIST_SUCCESS';
export const GET_ROUTES_LIST_FAILURE = 'GET_ROUTES_LIST_FAILURE';


export const GET_ASSETS_LIST_BEGIN = 'GET_TRIP_ASSETS_LIST_BEGIN';
export const GET_ASSETS_LIST_SUCCESS = 'GET_TRIP_ASSETS_LIST_SUCCESS';
export const GET_ASSETS_LIST_FAILURE = 'GET_TRIP_ASSETS_LIST_FAILURE';

export const GET_CUSTOMER_LIST_BEGIN = 'GET_CUSTOMER_LIST_BEGIN';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const GET_CUSTOMER_SHIPPER_LIST_BEGIN = 'GET_CUSTOMER_SHIPPER_LIST_BEGIN';
export const GET_CUSTOMER_SHIPPER_LIST_SUCCESS = 'GET_CUSTOMER_SHIPPER_LIST_SUCCESS';
export const GET_CUSTOMER_SHIPPER_LIST_FAILURE = 'GET_CUSTOMER_SHIPPER_LIST_FAILURE';


export const GET_COLOADER_LIST_BEGIN = 'GET_COLOADER_LIST_BEGIN';
export const GET_COLOADER_LIST_SUCCESS = 'GET_COLOADER_LIST_SUCCESS';
export const GET_COLOADER_LIST_FAILURE = 'GET_COLOADER_LIST_FAILURE';

export const GET_SIMPROVIDERS_LIST_BEGIN = 'GET_SIMPROVIDERS_LIST_BEGIN';
export const GET_SIMPROVIDERS_LIST_SUCCESS = 'GET_SIMPROVIDERS_LIST_SUCCESS';
export const GET_SIMPROVIDERS_LIST_FAILURE = 'GET_SIMPROVIDERS_LIST_FAILURE';

export const GET_DOCKETS_LIST_BEGIN = 'GET_DOCKETS_LIST_BEGIN';
export const GET_DOCKETS_LIST_SUCCESS = 'GET_DOCKETS_LIST_SUCCESS';
export const GET_DOCKETS_LIST_FAILURE = 'GET_DOCKETS_LIST_FAILURE';


export const GET_CO_LOADER_DOCKETS_LIST_BEGIN = 'GET_CO_LOADER_DOCKETS_LIST_BEGIN';
export const GET_CO_LOADER_DOCKETS_LIST_SUCCESS = 'GET_CO_LOADER_DOCKETS_LIST_SUCCESS';
export const GET_CO_LOADER_DOCKETS_LIST_FAILURE = 'GET_CO_LOADER_DOCKETS_LIST_FAILURE';


export const GET_DOCKETS_SUMMARY_BEGIN = 'GET_DOCKETS_SUMMARY_BEGIN';
export const GET_DOCKETS_SUMMARY_SUCCESS = 'GET_DOCKETS_SUMMARY_SUCCESS';
export const GET_DOCKETS_SUMMARY_FAILURE = 'GET_DOCKETS_SUMMARY_FAILURE';

export const GET_TRIP_CREATE_BEGIN = 'GET_TRIP_CREATE_BEGIN';
export const GET_TRIP_CREATE_SUCCESS = 'GET_TRIP_CREATE_SUCCESS';
export const GET_TRIP_CREATE_FAILURE = 'GET_TRIP_CREATE_FAILURE';


export const GET_TRIP_LIST_BEGIN = 'GET_TRIP_LIST_BEGIN';
export const GET_TRIP_LIST_SUCCESS = 'GET_TRIP_LIST_SUCCESS';
export const GET_TRIP_LIST_FAILURE = 'GET_TRIP_LIST_FAILURE';


export const GET_TRIP_HANDOVER_BEGIN = 'GET_TRIP_HANDOVER_BEGIN';
export const GET_TRIP_HANDOVER_SUCCESS = 'GET_TRIP_HANDOVER_SUCCESS';
export const GET_TRIP_HANDOVER_FAILURE = 'GET_TRIP_HANDOVER_FAILURE';


export const GET_TRIP_MARKDEL_BEGIN = 'GET_TRIP_MARKDEL_BEGIN';
export const GET_TRIP_MARKDEL_SUCCESS = 'GET_TRIP_MARKDEL_SUCCESS';
export const GET_TRIP_MARKDEL_FAILURE = 'GET_TRIP_MARKDEL_FAILURE';


export const GET_TRIP_START_BEGIN = 'GET_TRIP_START_BEGIN';
export const GET_TRIP_START_SUCCESS = 'GET_TRIP_START_SUCCESS';
export const GET_TRIP_START_FAILURE = 'GET_TRIP_START_FAILURE';


export const GET_TRIP_START_MANIFEST_BEGIN = 'GET_TRIP_START_MANIFEST_BEGIN';
export const GET_TRIP_START_MANIFEST_SUCCESS = 'GET_TRIP_START_MANIFEST_SUCCESS';
export const GET_TRIP_START_MANIFEST_FAILURE = 'GET_TRIP_START_MANIFEST_FAILURE';


export const GET_TRIP_END_BEGIN = 'GET_TRIP_END_BEGIN';
export const GET_TRIP_END_SUCCESS = 'GET_TRIP_END_SUCCESS';
export const GET_TRIP_END_FAILURE = 'GET_TRIP_END_FAILURE';


export const GET_VENDORS_LIST_BEGIN = 'GET_VENDORS_LIST_BEGIN';
export const GET_VENDORS_LIST_SUCCESS = 'GET_VENDORS_LIST_SUCCESS';
export const GET_VENDORS_LIST_FAILURE = 'GET_VENDORS_LIST_FAILURE';


export const GET_VENDOR_BLOCKED_REASON_BEGIN = 'GET_VENDOR_BLOCKED_REASON_BEGIN';
export const GET_VENDOR_BLOCKED_REASON_SUCCESS = 'GET_VENDOR_BLOCKED_REASON_SUCCESS';
export const GET_VENDOR_BLOCKED_REASON_FAILURE = 'GET_VENDOR_BLOCKED_REASON_FAILURE';


export const GET_LOCATION_GROUPS_BEGIN = 'GET_LOCATION_GROUPS_BEGIN';
export const GET_LOCATION_GROUPS_SUCCESS = 'GET_LOCATION_GROUPS_SUCCESS';
export const GET_LOCATION_GROUPS_FAILURE = 'GET_LOCATION_GROUPS_FAILURE';


export const GET_PARTNER_ASSETS_BEGIN = 'GET_PARTNER_ASSETS_BEGIN';
export const GET_PARTNER_ASSETS_SUCCESS = 'GET_PARTNER_ASSETS_SUCCESS';
export const GET_PARTNER_ASSETS_FAILURE = 'GET_PARTNER_ASSETS_FAILURE';

export const GET_EDIT_TRIP_UPDATE_BEGIN = 'GET_EDIT_TRIP_UPDATE_BEGIN';
export const GET_EDIT_TRIP_UPDATE_SUCCESS = 'GET_EDIT_TRIP_UPDATE_SUCCESS';
export const GET_EDIT_TRIP_UPDATE_FAILURE = 'GET_EDIT_TRIP_UPDATE_FAILURE';

export const FETCH_TRIP_SETTLEMENTS_BEGIN = 'FETCH_TRIP_SETTLEMENTS_BEGIN';
export const FETCH_TRIP_SETTLEMENTS_SUCCESS = 'FETCH_TRIP_SETTLEMENTS_SUCCESS';
export const FETCH_TRIP_SETTLEMENTS_FAILURE = 'FETCH_TRIP_SETTLEMENTS_FAILURE';


export const GET_TRIP_CONSOLIDATE_BEGIN = 'GET_TRIP_CONSOLIDATE_BEGIN';
export const GET_TRIP_CONSOLIDATE_SUCCESS = 'GET_TRIP_CONSOLIDATE_SUCCESS';
export const GET_TRIP_CONSOLIDATE_FAILURE = 'GET_TRIP_CONSOLIDATE_FAILURE';

export const GET_TRIP_PRINT_BEGIN = 'GET_TRIP_PRINT_BEGIN';
export const GET_TRIP_PRINT_SUCCESS = 'GET_TRIP_PRINT_SUCCESS';
export const GET_TRIP_PRINT_FAILURE = 'GET_TRIP_PRINT_FAILURE';
export const GET_TRIP_PRINT_RESET = 'GET_TRIP_PRINT_RESET';

export const GET_DOCKET_LIST_LOADING_FALSE = 'GET_DOCKET_LIST_LOADING_FALSE';
export const GET_DOCKET_LIST_LOADING_TRUE = 'GET_DOCKET_LIST_LOADING_TRUE';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const GET_CONSOLIDATE_RESET = 'GET_CONSOLIDATE_RESET';

export const GET_COLOADER_PARTNERS_LIST_BEGIN = 'GET_COLOADER_PARTNERS_LIST_BEGIN';
export const GET_COLOADER_PARTNERS_LIST_SUCCESS = 'GET_COLOADER_PARTNERS_LIST_SUCCESS';
export const GET_COLOADER_PARTNERS_LIST_FAILURE = 'GET_COLOADER_PARTNERS_LIST_FAILURE';


export const GET_TRIP_RATE_CARD_BEGIN = 'GET_TRIP_RATE_CARD_BEGIN';
export const GET_TRIP_RATE_CARD_SUCCESS = 'GET_TRIP_RATE_CARD_SUCCESS';
export const GET_TRIP_RATE_CARD_FAILURE = 'GET_TRIP_RATE_CARD_FAILURE';


export const GET_RESET_CREATE_TRIP_RESET = 'GET_RESET_CREATE_TRIP_RESET';