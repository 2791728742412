import React, { useEffect, useRef, useState } from "react";
import FormWrapper from '../../../../../ui/formComponent/form';
import { basisToText } from "../box-charges/box.charges.service";
import accessorialChargesSchema from "./accessorial.form.d";

function AccessorialDetails(props) {

    var formRef = useRef();
    const [loaded, setLoaded] = useState(true);
    const [formData, setFormData] = useState(false);
    const [schema, setSchema] = useState(JSON.parse(JSON.stringify(accessorialChargesSchema.schema)));
     
    const updateSchema = _ => {
        const tempSchema = JSON.parse(JSON.stringify(accessorialChargesSchema.schema))
        if (props.contractData.serviceType == "FTL") {
            delete tempSchema.properties.minimumGuarantee
            delete formData.minimumGuarantee;
            if (props.laneEntityCount > 2) {
                tempSchema.required = [ "dropPointCharges" ];
            }
            else {
                delete formData.dropPointCharges;
            }
        }
        else if (props.contractData.serviceType == "PTL") {
            delete tempSchema.properties.dropPointCharges;
            delete formData.dropPointCharges;
        }
        setLoaded(false);
        if (formData) {
            setFormData(formData);
        }
        setSchema(tempSchema);
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }
   
    useEffect(() => {
        if (props.contractData?.contractCharges?.basis) {
            var minimumGuaranteeSuffix = basisToText(props.contractData?.contractCharges?.basis);
            minimumGuaranteeSuffix = minimumGuaranteeSuffix.substr(minimumGuaranteeSuffix.indexOf(" ") + 1);
            accessorialChargesSchema.uiSchema.minimumGuarantee["ui:suffix"] = minimumGuaranteeSuffix;
        }

        if (props.contractData?.accessorialChargesBO) {
            var accCharge = JSON.parse(JSON.stringify(props.contractData?.accessorialChargesBO)); // Need to revert this
            Object.keys(accCharge).map(chargeKey => {
                if (accCharge[chargeKey]) { 
                    accCharge[chargeKey] = parseFloat(accCharge[chargeKey])
                }
            })
            accCharge = {
                ...accCharge,
                rov : {
                    ...accCharge
                }
            }
            setFormData(accCharge);
            updateSchema();
        }
        else {
            setFormData({});
            updateSchema();
        }
    }, [])        
    
    useEffect(() => {
		if (props.retrieveAccesorialCharges) {
			formRef.current.formReference?.current?.submit()
		}
    }, [props.retrieveAccesorialCharges])
    
    useEffect(() => {
        if (formData) {
            updateSchema();
        }
    }, [ props.contractData.serviceType, props.laneEntityCount ])

    const onSubmit = data => {
        data = {
            docketCharges      : data.docketCharges?.toFixed(2),
            minimumGuarantee   : data.minimumGuarantee,
            additionalCharges  : data.additionalCharges?.toFixed(2),
            dropPointCharges   : data.dropPointCharges?.toFixed(2),
            rov : {
                rovPercentage  : data.rov.rovPercentage?.toFixed(2),
                rovMinimum     : data.rov.rovMinimum?.toFixed(2),
                rovMaximum     : data.rov.rovMaximum?.toFixed(2)
            }
        }
        props.getAccessorialCharges(data, false)
    }

    const transformErrors = errors => {
        errors.map(error => {
            if (error.name === "required" && error.property === ".dropPointCharges" && !props.submitedForApproval) {
                error.message = undefined;
            }
        })
        return errors;
    }

    return (
        <div>
            {
                formData && loaded &&
                <FormWrapper
                    id={"AccessorialCharges"}
                    schema={schema}
                    uiSchema={accessorialChargesSchema.uiSchema}
                    errorList={accessorialChargesSchema.errorList}
                    formData={formData}
                    readonly={props.readonly}
                    fieldsPerRow={2}
                    showColumnSplitter={false}
                    loading={props.loading}
                    bordered={true}
                    hideSubmit={true}
                    onSubmit={onSubmit}
                    onError={_ => props.getAccessorialCharges(formData, true)}
                    onChange={data => {
                        setFormData(data)
                    }}
                    transformErrors={transformErrors}
                    ref={formRef}
                />
            }
        </div>
    );
}
export default AccessorialDetails;